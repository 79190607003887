import React from 'react';

import { CampaignEntityType, CampaignTask } from 'store/reducers/radarKits/types';
import { SelectedKit } from '../../ViewSequence';
import { formatFrequency } from '../../helpers';
import { DropdownMenu, DropdownMenuOptions } from '../DropdownMenu';
import { ChevronDownIcon } from '../icons';
import styles from './styles.module.scss';
import moment from 'moment';

type CardItemProps = {
  type: CampaignEntityType;
  item: SelectedKit | CampaignTask;
  icon: React.ReactNode;
  editMode?: boolean;
  options: DropdownMenuOptions[];
  dropdownOnSelect: (
    kitId: number,
    frequencyId: string,
    newFrequencyName: string,
    prevFrequenceName: string,
  ) => void;
};

export const CardItem: React.FC<CardItemProps> = ({
  type,
  item,
  icon,
  editMode = true,
  options,
  dropdownOnSelect,
}) => {
  const renderKits = (item: SelectedKit) => {
    const currentFrequency = item?.Kits?.EligibleFrequencies.find(
      (eligibleFreq) => eligibleFreq.Id === item.FrequencyId,
    );
    return (
      <div className={styles.cardItem}>
        <div className={styles.contentWrapper}>
          <div className={styles.iconWrapper}>{icon}</div>
          <div>
            <div className={styles.title}>{item?.Kits?.Name || ''}</div>
            <div className={styles.description}>
              <div>{formatFrequency(currentFrequency)}</div>
              <div className={styles.dot}></div>
              {currentFrequency && (
                <div className={styles.frequenceWrapper}>
                  <DropdownMenu
                    onSelect={(frequencyId, frequenceName, prevFrequenceName) =>
                      dropdownOnSelect(item.KitId, frequencyId, frequenceName, prevFrequenceName)
                    }
                    currentValue={currentFrequency.Id.toString()}
                    options={
                      item?.Kits?.EligibleFrequencies.map((item) => ({
                        label: item.FrequencyName,
                        id: item.Id.toString(),
                        prevFrequenceName: currentFrequency.FrequencyName,
                      })) || []
                    }
                    showValue={true}
                    btnIcon={<ChevronDownIcon />}
                    id={item.KitId.toString()}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <DropdownMenu options={options} id={item.KitId.toString()} />
      </div>
    );
  };

  const renderTasks = (item: CampaignTask) => {
    const assignees = item?.AssigneeList?.map(
      (assignee) => `${assignee?.Assignee?.FirstName} ${assignee?.Assignee?.LastName}`,
    );
    return (
      <div className={styles.cardItem}>
        <div className={styles.contentWrapper}>
          <div className={styles.iconWrapper}>{icon}</div>
          <div>
            <div className={styles.title}>{item?.Title || ''}</div>
            <div className={styles.description}>
              <div>
                Assigned to:{' '}
                <span className={styles.tooltipEllipsis} data-tooltip={assignees?.join(', ')}>
                  {assignees?.join(', ')}
                </span>
                {` `}
                Due: <span> {moment(item.DueDate).format('MM/DD/YYYY')}</span>
              </div>
            </div>
          </div>
        </div>
        <DropdownMenu options={options} id={item.Id.toString()} />
      </div>
    );
  };

  return (
    <div>
      {type === CampaignEntityType.Kit
        ? renderKits(item as SelectedKit)
        : renderTasks(item as CampaignTask)}
    </div>
  );
};
