import { AgentDocumentTypes } from 'types';
import { AvatarColumn, DateTimeColumn, DocumentNameColumn } from './TableColumns';
import { Options } from '../Options';

const docType = AgentDocumentTypes.Transactions;
export const transactionColumns = (optionUtils) => [
  {
    key: 'title',
    title: 'Name',
    dataIndex: 'title',
    width: 375,
    render: (title) => (
      <DocumentNameColumn name={title} docIconType={AgentDocumentTypes.PreApprovals} />
    ),
  },
  {
    key: 'uploadedBy',
    title: 'Uploaded By',
    width: 350,
    render: (row) => <AvatarColumn name={row?.transactionMeta?.uploadedBy} imgSrc={null} />,
  },
  {
    key: 'uploadedAt',
    title: 'Created',
    render: (row) => <DateTimeColumn date={row?.transactionMeta?.uploadedAt} />,
  },
  {
    key: 'updatedDate',
    title: 'Last Edited',
    render: (row) => <DateTimeColumn date={row?.transactionMeta?.updatedDate} />,
  },
  {
    key: 'options',
    title: '',
    render: (row) => <Options document_={row} type={docType} optionUtils={optionUtils} />,
  },
];
