import { useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const ListingDetailHeadInfo = ({
  price,
  addressLine1,
  addressLine2,
  baths,
  beds,
  square,
  sourceSection,
  mlsLastUpdate,
  mlsLastChecked,
  activityStatus,
  closingPrice,
}) => (
  <div className={styles.container}>
    <div className={styles.top}>
      <div testid="head_price" className={styles.price}>
        {closingPrice && activityStatus == 'Closed' ? closingPrice : price}
      </div>
      <div className={styles.right}>
        {!!activityStatus && (
          <span
            className={classNames(styles.activityStatus, styles[activityStatus?.replace(/ /g, '')])}
          >
            {activityStatus}
          </span>
        )}
      </div>
    </div>
    <div className={styles.shortNumbers}>
      {!!beds && (
        <>
          <span>
            <b testid="head_beds">{beds}</b>
            {Number(beds) === 1 ? 'bed' : 'beds'}
          </span>
          {baths || square ? <div className={styles.seperator} /> : null}
        </>
      )}
      {!!baths && (
        <>
          <span>
            <b testid="head_baths">{baths}</b>
            {Number(baths) === 1 ? 'bath' : 'baths'}
          </span>
          {!!square && <div className={styles.seperator} />}
        </>
      )}
      {!!square && (
        <span>
          <b testid="head_feet">{square}</b>
        </span>
      )}
    </div>
    <div testid="head_address" className={styles.address}>
      <p className={styles.lineOne}>{addressLine1}</p>
      <p className={styles.lineTwo}>{addressLine2}</p>
    </div>

    {!!sourceSection?.length && (
      <div testid="descr_info" className={styles.descriptionInfo}>
        {sourceSection.map((val, index) => (
          <span key={index}>{val}</span>
        ))}
      </div>
    )}
    {!!mlsLastUpdate && (
      <div testid="mls_last_update" className={styles.mlsLastUpdate}>
        {mlsLastUpdate}
      </div>
    )}
  </div>
);

ListingDetailHeadInfo.propTypes = {
  address: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  square: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  baths: PropTypes.number,
  beds: PropTypes.number,
  sourceSection: PropTypes.array,
};

ListingDetailHeadInfo.defaultProps = {
  square: null,
  baths: null,
  beds: null,
  sourceSection: [],
};

export default ListingDetailHeadInfo;
