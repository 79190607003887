import { Modal } from 'components-antd';
import styles from './style.module.scss';
import { Icons } from '../../Icons';
import { MyLibrary } from '../NewFormModal/FormStages';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getFormMetaEffect } from 'store/effects/formProcess';

type LibraryTransaction = {
  Id?: string;
  IsProject?: boolean;
};

type MyLibraryModalProps = {
  onSelectForm?: (data) => void;
  people?: number[];
  teams?: number[];
  locations?: object[];
  clientId?: number;
  open: boolean;
  onClose: () => void;
  transaction?: LibraryTransaction;
  propertyInfo?: any;
  setSelectedForm?: (data) => void;
};

export const MyLibraryModal = (props: MyLibraryModalProps) => {
  const { open, onClose, transaction, clientId, ...rest } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFormMetaEffect({ isProject: transaction?.IsProject }));
  }, []);

  return (
    <Modal
      open={open}
      width={875}
      footer={null}
      onCancel={() => {
        onClose();
      }}
      maskClosable={false}
      destroyOnClose
      className={styles.myLibraryModal}
      closeIcon={<Icons variant={'modal-close'} />}
    >
      <MyLibrary
        onCloseModal={onClose}
        transactionId={transaction?.Id}
        clientId={clientId}
        isProject={transaction?.IsProject}
        {...rest}
      />
    </Modal>
  );
};
