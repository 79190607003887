import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { FormattedPhone, Avatar } from 'components';
import { TitleWithButtons } from 'components/SettingsGroup';
import { Edit } from 'components/Icons';
import { getHrefLink, getPropertyAddress, getStringsSorter } from 'helpers';
import { ViewClientAddresses } from './ViewClientAddresses';

import {
  getCommonProfileData,
  getSpecificRoleProfileData,
  getAgentBrokerageSettingsData,
  getUserRolesMapSelector,
  isPartnerSelector,
  getUserDataSelector,
} from 'store/selectors/user';

import styles from '../styles.module.scss';
import { PageDisclaimer } from 'pages/Settings/components';

const SettingsFullProfileViewMode = ({ changeEditMode }) => {
  const { isAgent, isClient, isThirdParty } = useSelector(getUserRolesMapSelector);
  const isUserPartner = useSelector(isPartnerSelector);
  const { avatar, name, avatarPlaceholder, phones, email, timezone } =
    useSelector(getCommonProfileData);
  const {
    bio,
    links,
    address,
    suiteUnit,
    website,
    companyBusinessName,
    companyJobTitle,
    companyAddress,
    companySuiteUnit,
    isPartner,
    title,
  } = useSelector(getSpecificRoleProfileData);
  const user = useSelector(getUserDataSelector);

  const {
    brokerage: { name: brokerageName, address: brokerageAddress, suiteUnit: brokerageSuiteUnit },
    areasOfOperation = [],
  } = useSelector(getAgentBrokerageSettingsData);

  const officeAddress = useMemo(() => {
    const addressLine1 = brokerageAddress?.line1 || '';
    const officeSuiteUnit = brokerageSuiteUnit ? `Suite ${brokerageSuiteUnit}` : '';
    const city = brokerageAddress?.city || '';
    const state = brokerageAddress?.state || '';
    const zip = brokerageAddress?.zip || '';
    const statePart = `${state} ${zip}`.trim();

    return [addressLine1, officeSuiteUnit, city, statePart].filter((i) => !!i).join(', ');
  }, [brokerageAddress, brokerageSuiteUnit]);

  const citiesWithStates = useMemo(() => {
    const citiesOnly = areasOfOperation.filter(
      ({ Type: type }) => type === 'City' || type === 'Zipcode',
    );
    citiesOnly.sort(getStringsSorter(({ City }) => City));
    return citiesOnly;
  }, [areasOfOperation]);

  const neighborhoods = useMemo(() => {
    const neighborhoodsOnly = areasOfOperation.filter(
      ({ Type: type }) => type !== 'City' && type !== 'Zipcode',
    );
    neighborhoodsOnly.sort(getStringsSorter(({ PlaceName }) => PlaceName));
    return neighborhoodsOnly;
  }, [areasOfOperation]);

  return (
    <div testid="profile_view">
      <TitleWithButtons
        className={styles.title}
        title={isUserPartner ? 'Profile Settings' : 'Account Settings'}
      >
        <button testid="edit_button" onClick={changeEditMode} className={styles.editBtn}>
          <Edit />
        </button>
      </TitleWithButtons>
      <div className={styles.profileWrapper}>
        <div className={styles.leftBlock}>
          <Avatar src={avatar} placeholder={avatarPlaceholder} className={styles.avatar} />
        </div>
        <div className={styles.rightBlock}>
          {!!isUserPartner && (
            <>
              <h3 className={styles.sectionTitle}>Primary Contact Profile</h3>
              <PageDisclaimer text="This is what clients and collaborators will see on your personal contact card." />
            </>
          )}

          {!isUserPartner && <h3 className={styles.sectionTitle}>Personal Info</h3>}
          <div className={styles.inputBlock}>
            <span className={styles.label}>Name</span>
            <span testid="view_name" className={styles.infoBlockForOneLine}>
              {name}
            </span>
          </div>
          {isUserPartner && (
            <div className={styles.inputBlock}>
              <span className={styles.label}>Title</span>
              <span testid="view_title" className={styles.infoBlockForOneLine}>
                {companyJobTitle ? companyJobTitle : '-'}
              </span>
            </div>
          )}
          {!!email && (
            <div className={styles.inputBlock}>
              <span className={styles.label}>Email</span>
              <span testid="view_email" className={styles.infoBlockForOneLine}>
                {email}
              </span>
            </div>
          )}
          {phones && !!phones.length && (
            <div className={styles.inputBlock}>
              <span className={styles.label}>{phones.length > 1 ? 'Phones' : 'Phone'}</span>
              <div className={styles.infoBlockForMultipleLines}>
                {phones.map(({ PhoneNumber }) => (
                  <div className={styles.nonEditableValue} key={PhoneNumber}>
                    <FormattedPhone testid="view_phone">{PhoneNumber}</FormattedPhone>
                  </div>
                ))}
              </div>
            </div>
          )}
          {!!timezone && (
            <div className={styles.inputBlock}>
              <span className={styles.label}>Time Zone</span>
              <span testid="view_email" className={styles.infoBlockForOneLine}>
                {timezone}
              </span>
            </div>
          )}
          {!!address && isClient && <ViewClientAddresses addresses={address} />}
          {!!address && isAgent && (
            <div className={styles.inputBlock}>
              <span className={styles.label}>Address</span>
              <span testid="view_address" className={styles.infoBlockForOneLine}>
                {address}
              </span>
            </div>
          )}
          {!!suiteUnit && isAgent && (
            <div className={styles.inputBlock}>
              <span className={styles.label}>Apt / Unit</span>
              <span testid="view_suite_unit" className={styles.infoBlockForOneLine}>
                {suiteUnit}
              </span>
            </div>
          )}
          {links && !!links.length && (
            <div className={styles.inputBlock}>
              <span className={styles.label}>Website</span>
              <div className={styles.infoBlockForMultipleLines}>
                {links.map((link) => (
                  <div className={styles.nonEditableValue} key={link}>
                    <a testid="view_link" href={getHrefLink(link)}>
                      {link}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          )}

          {isAgent && (brokerageName || officeAddress) && (
            <div className={styles.brokerageWrapper}>
              <h3 className={styles.sectionTitle}>Brokerage</h3>
              {!!brokerageName && (
                <div className={styles.inputBlock}>
                  <span className={styles.label}>Brokerage Name</span>
                  <span testid="view_brokerage_name" className={styles.infoBlockForOneLine}>
                    {brokerageName}
                  </span>
                </div>
              )}
              {!!officeAddress && (
                <div className={styles.inputBlock}>
                  <span className={styles.label}>Office Address</span>
                  <span
                    testid="view_brokerage_office_address"
                    className={styles.infoBlockForOneLine}
                  >
                    {officeAddress}
                  </span>
                </div>
              )}
            </div>
          )}

          {isAgent && (citiesWithStates || neighborhoods) && (
            <div className={styles.areasOfOperationWrapper}>
              <h3 className={styles.sectionTitle}>Areas of Operation</h3>
              {!!citiesWithStates && (
                <div className={styles.inputBlock}>
                  <span className={styles.label}>Cities</span>
                  <span testid="view_cities_name" className={styles.infoBlockForOneLine}>
                    {citiesWithStates
                      ?.map((item) => {
                        if (item?.Type === 'Zipcode') {
                          return item?.PlaceName;
                        } else {
                          return `${item?.City}${item.State ? `, ${item.State}` : ''}`;
                        }
                      })
                      .join(', ')}
                  </span>
                </div>
              )}
              {!!neighborhoods && (
                <div className={styles.inputBlock}>
                  <span className={styles.label}>Neighborhoods</span>
                  <span
                    testid="view_brokerage_office_address"
                    className={styles.infoBlockForOneLine}
                  >
                    {neighborhoods.map((item) => item.PlaceName).join(', ')}
                  </span>
                </div>
              )}
            </div>
          )}

          {isThirdParty &&
            !isUserPartner &&
            (companyBusinessName || companyJobTitle || companyAddress || companySuiteUnit) && (
              <div className={styles.companyWrapper}>
                <h3 className={styles.sectionTitle}>Organization</h3>
                {!!companyBusinessName && (
                  <div className={styles.inputBlock}>
                    <span className={styles.label}>Organization Name</span>
                    <span testid="view_business_name" className={styles.infoBlockForOneLine}>
                      {companyBusinessName}
                    </span>
                  </div>
                )}
                {!!companyJobTitle && (
                  <div className={styles.inputBlock}>
                    <span className={styles.label}>Title</span>
                    <span testid="profile_job_title" className={styles.infoBlockForOneLine}>
                      {companyJobTitle}
                    </span>
                  </div>
                )}
                {!!companyAddress && (
                  <div className={styles.inputBlock}>
                    <span className={styles.label}>Office address</span>
                    <span testid="profile_company_address" className={styles.infoBlockForOneLine}>
                      {typeof companyAddress === 'string'
                        ? companyAddress
                        : getPropertyAddress(companyAddress)}
                    </span>
                  </div>
                )}
                {user.ThirdParty && user.ThirdParty.Company && user.ThirdParty.Company.Website ? (
                  <div className={styles.inputBlock}>
                    <span className={styles.label}>Website</span>
                    <span testid="view_website" className={styles.infoBlockForOneLine}>
                      {user.ThirdParty.Company.Website}
                    </span>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

SettingsFullProfileViewMode.propTypes = {
  changeEditMode: PropTypes.func.isRequired,
};

export default SettingsFullProfileViewMode;
