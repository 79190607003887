import { apiServer } from 'settings/web-services/api';
import {
  AddressValuationDetails,
  GetAddressNeighborhoodActivityPayload,
  GetAddressValuationPayload,
} from './types';

export const getAddressValuationDetails = (payload: GetAddressValuationPayload) => {
  return apiServer.get<AddressValuationDetails[]>('/propertyreferencedata/portfolio/client-data', {
    params: payload,
  });
};

export const getAddressNeighborhoodActivity = (payload: GetAddressNeighborhoodActivityPayload) => {
  return apiServer.get('/propertyreferencedata/portfolio/neighborhood-activity', {
    params: payload,
  });
};

export const getAddValuationHomeValue = ({ body, params }) => {
  return apiServer.post('/propertyreferencedata/portfolio/custom-home-value?' + params, body);
};

export const getAddValuationRentalValue = ({ body, params }) => {
  return apiServer.post('/propertyreferencedata/portfolio/custom-rental-value?' + params, body);
};
