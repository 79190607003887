import { criteriaTypes } from 'pages/Properties/CriteriaModal/constants';
import { handleActions } from 'redux-actions';
import { setCriteriaTypeAction } from 'store/actions/propertyCriteria';

const initialCriteriaState = {
  criteriaType: criteriaTypes.ForSale, // Default value
};

const criteriaReducer = handleActions(
  {
    [setCriteriaTypeAction]: (state, { payload }) => ({
      ...state,
      criteriaType: payload,
    }),
  },
  initialCriteriaState,
);

export default criteriaReducer;
