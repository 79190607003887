import { Row, Typography } from 'antd';
import classNames from 'classnames';
import Close from 'components/Close';
import { useSetSearch } from 'hooks';
import { parse } from 'qs';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getSearchCriteriaSelector } from 'store/selectors/search';
import { getUserRolesMapSelector } from 'store/selectors/user';
import SearchCard from '../Search/components/SearchCard';
import { Icons } from './Icons';
import { LocationSearch } from './LocationSearch';
import { useSavedSearches } from './savedSearches.hook';
import styles from './styles.module.scss';
import { setSubFilterTypeAction } from 'store/actions/feed';
import { subFilterTypes } from 'settings/constants/properties';
import { setSavedSearchTabAction } from 'store/actions/feedv3';
import { appOpenModalEffect } from 'store/effects';
import SearchesModal from '../AgentFeed/components/AgentHeader/components/SearchesModal';
import { getSavedSearchesFormattedSelector } from 'store/selectors/mySearches';
import { getFeedMetaSelectorV3 } from 'store/selectors/feedv3';

type Props = {
  isClientSearch?: boolean;
  hideCloseButton?: boolean;
  onClose: () => void;
  showSavedSearches?: boolean;
  setShowSavedSearches?: Dispatch<SetStateAction<boolean>>;
  isAgent?: boolean;
};

const ClientSearch: React.FC<Props> = ({
  isClientSearch = false,
  onClose,
  hideCloseButton = false,
  setShowSavedSearches: setShowSavedSearchesProp,
  showSavedSearches: showSavedSearchesProp,
  isAgent = false,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isClient } = useSelector(getUserRolesMapSelector);
  const searchParams = useMemo(() => parse(history.location.search?.slice(1)), []);
  const { agentSavedSearches } = useSelector(getSavedSearchesFormattedSelector);
  const { agentClientMode } = useSelector(getFeedMetaSelectorV3);

  const {
    showSavedSearches,
    isActiveSelected,
    setIsActiveSelected,
    searchesToShow,
    handleSavedSearchClick,
    getRenameHandler,
    getSearchHandler,
    getInactiveHandler,
    getActivateHandler,
    savedSearchesCount,
  } = useSavedSearches({ onClose, showSavedSearchesProp, setShowSavedSearchesProp });

  useEffect(() => {
    if (searchParams['view'] === 'saved') {
      handleSavedSearchClick();
      dispatch(setSubFilterTypeAction(subFilterTypes.ALL));
    }
  }, [searchParams]);

  const handleAgentSavedSearches = () => {
    dispatch(setSavedSearchTabAction({ savedSearchTab: 1 }));
    dispatch(appOpenModalEffect({ id: SearchesModal.id, open: true }));
  };

  return (
    <div className={styles.fullContainer}>
      {!hideCloseButton && (
        <div className={styles.closeContainer}>
          <Close onClick={onClose} />
        </div>
      )}
      <div className={styles.searchContainer}>
        {showSavedSearches ? (
          <Typography.Title level={2}>Saved Searches</Typography.Title>
        ) : (
          <Typography.Title level={2}>Start a new search...</Typography.Title>
        )}
        {!showSavedSearches && (
          <LocationSearch
            formClassname={styles.formWrapper}
            valuesWrapperClassName={styles.valuesWrapper}
            searchWrapperClassName={styles.searchWrapper}
            isSearchPage={false}
            userId={agentClientMode?.user?.Id}
          />
        )}
        {showSavedSearches && (
          <div className={styles.savedSearchesWrapper}>
            <div className={styles.header}>
              <div>Saved Searches</div>
              <div
                onClick={() => {
                  setShowSavedSearchesProp(false);
                }}
              >
                <Icons variant={Icons.CLOSE} />
              </div>
            </div>
            <div className={styles.pillsContainer}>
              <div
                className={classNames(styles.pill, { [styles.active]: isActiveSelected })}
                onClick={() => setIsActiveSelected(true)}
              >
                Active
              </div>
              <div
                className={classNames(styles.pill, { [styles.active]: !isActiveSelected })}
                onClick={() => setIsActiveSelected(false)}
              >
                Inactive
              </div>
            </div>
            <div className={styles.itemsContainer}>
              {searchesToShow?.length > 0 ? (
                searchesToShow.map((search, idx) => (
                  <Row key={idx} className={styles.CardsContainer}>
                    <SearchCard
                      testid="active_search_block"
                      photos={search.photos}
                      key={search.id}
                      title={search.name}
                      agentName={search.agentName}
                      status={search.status}
                      info={search?.info}
                      fullInfo={search?.fullInfo}
                      footer={`${search.total || 'no'} results`}
                      onDelete={null} //getDeleteHandler(search.id)
                      onEdit={getSearchHandler({
                        ...search,
                        ...(isClientSearch || isClient
                          ? { searchInstanceId: search.id }
                          : { saveSearchId: search.id }),
                      })}
                      onRename={getRenameHandler(search.id)}
                      onSearch={getSearchHandler({
                        ...search.criterias,
                        ...(isClientSearch || isClient
                          ? { searchInstanceId: search.id }
                          : { saveSearchId: search.id }),
                      })}
                      onClick={getSearchHandler({
                        ...search,
                        ...(isClientSearch || isClient
                          ? { searchInstanceId: search.id }
                          : { saveSearchId: search.id }),
                      })}
                      onInactive={getInactiveHandler(search.id)}
                      infoClassName={styles.infoDesc}
                      updatedDate={search?.updatedDate}
                      onActivate={getActivateHandler(search.id)}
                    />
                  </Row>
                ))
              ) : (
                <Typography.Text className={styles.message}>
                  No {isActiveSelected ? 'active' : 'inactive'} search available
                </Typography.Text>
              )}
            </div>
          </div>
        )}
        <div
          className={styles.subText}
          onClick={isAgent ? handleAgentSavedSearches : handleSavedSearchClick}
        >
          <div className={styles.iconWrapper}>
            {!showSavedSearches && <Icons variant={Icons.BOOKMARK} />}
            {showSavedSearches && <Icons variant={Icons.RETURN_ARROW} />}
          </div>
          <div className={styles.wrapper}>
            <Typography.Text className={styles.mainText}>
              {!showSavedSearches && (
                <>
                  Saved Searches{' '}
                  <span className={styles.mutedText}>
                    ({isAgent ? agentSavedSearches?.length : savedSearchesCount})
                  </span>
                </>
              )}
              {showSavedSearches && 'Back to New Search'}
            </Typography.Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientSearch;
