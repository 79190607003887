import { createAction } from 'redux-actions';

export const getTransactionAction = createAction('REQUEST/GET_TRANSACTION');
export const getTransactionAgentsCoordinatorsAction = createAction(
  'REQUEST/GET_TRANSACTION_AGENTS_COORDINATORS',
);
export const getKeyDatesAction = createAction('REQUEST/GET_KEY_DATES');
export const getFinancialPricingAction = createAction('REQUEST/GET_FINANCIAL_PRICING');
export const getTasksForCancelledTransactionFrAction = createAction(
  'REQUEST/GET_TRANSACTION_CANCELLATION_TASKS',
);
export const getTransactionClientAddressAction = createAction(
  'REQUEST/GET_TRANSACTION_CLIENT_ADDRESS',
);
export const resetTransactionAction = createAction('RESET_TRANSACTION');
export const getTransactionParticipantAction = createAction('REQUEST/GET_TRANSACTION_PARTICIPANT');
export const getTransactionRolesAction = createAction('GET_TRANSACTION_ROLES');
export const getTransactionContactAction = createAction('REQUEST/GET_TRANSACTION_CONTACT');
export const getTransactionDetailsAction = createAction('REQUEST/TRANSACTION_DETAILS');
export const requestGetTransactionPersonsAction = createAction('GET_TRANSACTION_PERSONS');
