import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { get, cloneDeep } from 'lodash-es';

import {
  requestGetAgentCoordinatorRelatedContactsAction,
  requestGetTransactionRelatedContactsAction,
  requestGetAllRelatedContactsAction,
  requestGetClientRelatedContactsAction,
} from 'store/actions/relatedContacts';

import { appLogoutAction } from 'store/actions/app';

const initialData = {
  state: IDLE,
  clientContacts: [],
  agentsCoordinatorContacts: [],
  transactionContacts: [],
  allContacts: [],
};

export default handleActions(
  {
    [requestGetClientRelatedContactsAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      clientContacts: get(payload, ['data', 'result']),
    }),
    [requestGetAgentCoordinatorRelatedContactsAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      agentsCoordinatorContacts: get(payload, ['data', 'result']),
    }),
    [requestGetTransactionRelatedContactsAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      transactionContacts: get(payload, ['data', 'result']),
    }),
    [requestGetAllRelatedContactsAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      allContacts: get(payload, ['data', 'result']),
    }),
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
