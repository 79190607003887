import classNames from 'classnames';
import { Input } from 'components';

import styles from '../../styles.module.scss';

interface MemberEmailProps {
  className?: string;
  inputHolderClassName?: string;
  value?: string;
  placeholder?: string;
  error?: string;
  onUpdate: (...arg) => void;
  onBlurInput?: (...arg) => void;
  variant?: string;
  disabled?: boolean;
}

export const MemberInput = ({
  className,
  inputHolderClassName,
  value,
  placeholder,
  error,
  onUpdate,
  onBlurInput,
  variant = Input.LIGHT,
  disabled = false,
}: MemberEmailProps) => (
  <Input
    inputHolderClassName={classNames(styles.nameInput, inputHolderClassName)}
    className={className}
    variant={variant}
    placeholder={placeholder}
    onChange={(e, val) => onUpdate(val)}
    onBlur={(e, val) => onBlurInput && onBlurInput(val)}
    value={value}
    error={error}
    errorClassName={styles.errorClass}
    disabled={disabled}
  />
);
