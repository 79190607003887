import PropTypes from 'prop-types';

const Close = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g clipPath="url(#clip0_63100_397084)">
      <path
        d="M15 5L5 15"
        stroke="#262626"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 5L15 15"
        stroke="#262626"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_63100_397084">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

Close.propTypes = {};

Close.defaultProps = {};

export default Close;
