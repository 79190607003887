export const showingsStatus = {
  PENDING: 'Pending',
  UPCOMING: 'Upcoming',
  PAST: 'Past',
  CANCELED: 'Canceled',
  DECLINED: 'Declined',
};

export const ShowingFilterStatus = {
  PENDING: 'Pending',
  UPCOMING: 'Upcoming',
};

export const ArchiveShowingsStatus = {
  PAST: 'Past',
  CANCELED: 'Canceled',
  DECLINED: 'Declined',
};

export const announcementType = {
  PUBLIC: 'Showing_Public_Message',
  APPOINTMENT: 'Showing_Private_Message',
};

export const feedbackQuestions = {
  QUESTION1: 'Question1',
  QUESTION2: 'Question2',
  QUESTION3: 'Question3',
  QUESTION4: 'Question4',
  QUESTION5: 'Question5',
  QUESTION6: 'Question6',
};

export const appointmentFeedback = {
  [feedbackQuestions.QUESTION1]: 'What was your overall impression of the home?',
  [feedbackQuestions.QUESTION2]: 'Do you feel the home is priced well?',
  [feedbackQuestions.QUESTION3]: 'What did you like most about the home?',
  [feedbackQuestions.QUESTION4]: 'What did you like least about the home?',
  [feedbackQuestions.QUESTION5]: 'Is your buyer interested in submitting an offer?',
  [feedbackQuestions.QUESTION6]: 'Any other comments or feedback?',
};

export const showingsFilters = {
  myShowings: 'My Showings',
  teamShowings: 'Team Showings',
  allShowings: 'All Showings',
};

export const listingsFilters = {
  myListings: 'My Listings',
  teamListings: 'Team Listings',
  allListings: 'All Listings',
};
