import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import View from './View';
import Form from './Form';
import {
  getTransactionTaskEffect,
  resetTransactionTaskEffect,
  setTransactionTaskParamsEffect,
} from 'store/effects/transactions';
import { TaskModal } from '../TaskModal';
import {
  getTransactionTaskParamsSelector,
  getIsTransactionFormTaskSelector,
} from 'store/selectors/transactionTask';
import { getCampaignTaskEffect } from 'store/effects/radarKits';

const Task = ({ className, onClose }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { isTaskForm, taskId, transactionId, campaignId, isTaskDetail } = useSelector(
    getTransactionTaskParamsSelector,
  );

  const { isFormTask } = useSelector(getIsTransactionFormTaskSelector);

  useEffect(() => {
    if (location?.state?.transactionId && location?.state?.taskId) {
      dispatch(
        getTransactionTaskEffect(
          {
            transactionId: location?.state?.transactionId,
            taskId: location?.state?.taskId,
          },
          { showError: false },
        ),
      );
      dispatch(setTransactionTaskParamsEffect({ isTaskForm: false, isTaskDetail: true }));
    }
    if (location?.state?.campaignId && location?.state?.taskId) {
      dispatch(
        getCampaignTaskEffect(
          {
            campaignId: location?.state?.campaignId,
            id: location?.state?.taskId,
          },
          { showError: false },
        ),
      );
      dispatch(setTransactionTaskParamsEffect({ isTaskForm: false, isTaskDetail: true }));
    }
  }, [location.state, dispatch]);

  useEffect(() => {
    if (taskId && transactionId) {
      dispatch(getTransactionTaskEffect({ transactionId, taskId }));
    } else if (taskId && campaignId) {
      dispatch(getCampaignTaskEffect({ campaignId, id: taskId }));
    }
  }, [transactionId, campaignId, taskId, dispatch]);

  useEffect(() => {
    return () => dispatch(resetTransactionTaskEffect());
  }, []);

  const onCloseModal = () => {
    onClose();
    dispatch(
      setTransactionTaskParamsEffect({ campaignId: null, isTaskForm: false, isTaskDetail: false }),
    );
    dispatch(resetTransactionTaskEffect());
  };

  return (
    <div className={className}>
      <TaskModal
        isOpen={isTaskForm}
        onCloseModal={onCloseModal}
        isNew={false}
        editData={null}
        isFormTask={isFormTask}
        campaignId={campaignId ? Number(campaignId) : null}
        isCampaignTask={!!campaignId}
      />
      {isFormTask ? (
        <Form isOpen={isTaskDetail} onCloseModal={onCloseModal} />
      ) : (
        <View isOpen={isTaskDetail} onCloseModal={onCloseModal} />
      )}
    </div>
  );
};

Task.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
};

Task.defaultProps = {
  className: '',
  onClose: () => {},
};

export default Task;
