import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { groupBy } from 'lodash-es';

import { ConfirmationWithReasonModal } from 'components-antd';
import {
  getShowingAggregateList,
  getShowingAggregateDataSelector,
  getShowingIsArchiveSelector,
  getShowingIsFilterSelector,
  getShowingFilterSelector,
  getShowingArchiveIdSelector,
} from 'store/selectors/showingAggregate';
import {
  requestUpdateShowingAppointmentApprovalEffect,
  requestUpdateShowingAppointmentEffect,
} from 'store/effects/showingAppointment';
import {
  getShowingAggregateByUserIdEffect,
  setShowingsIsArchiveEffect,
} from 'store/effects/showingAggregate';
import { getUserId } from 'store/selectors/user';
import { AppointmentStatus, ApprovalAction, ApprovalStatus } from 'types/showingAppointment';
import { showSuccessMessage } from 'helpers/success';
import { AppointmentDetailsModal } from '../AppointmentDetailsModal';
import { getShowingIdAndTypeFromUrl } from 'utils';

import { tableColumns } from './tableColumns';

import styles from './styles.module.scss';
import { Wrapper } from 'components';
import { WorkshopTableWrapper } from 'pages/Workshop/common/WorkshopTableWrapper';
import { CollapsibleTable } from 'pages/Workshop/common';
import { isThisWeek, isToday, isTomorrow, subtractTimeZone } from 'helpers';
import { link } from 'settings/navigation/link';

interface locationState {
  showingAppointmentUUID: string | null;
  triggerShowingsDetail: boolean;
}

interface ShowingsTableProps {
  isTransactionRoom?: boolean;
  transactionId?: string;
  showingTableClass?: string;
}

export const ShowingsTable = ({
  isTransactionRoom = false,
  transactionId,
  showingTableClass,
}: ShowingsTableProps) => {
  const params = useParams();
  const history = useHistory();
  const [data, setData] = useState({ UUID: 0 });
  const [showModal, setShowModal] = useState(false);
  const [appointmentModal, setAppointmentModal] = useState(false);
  const [actionConfig, setActionConfig] = useState({
    action: '',
  });
  const [pendingApprovers, setPendingApprovers] = useState([]);

  const userId = useSelector(getUserId);
  const { showingId } = useSelector(getShowingArchiveIdSelector);
  const showingAggregate = useSelector(getShowingAggregateDataSelector);
  const { isPending } = useSelector(getShowingAggregateList);
  const { isArchive } = useSelector(getShowingIsArchiveSelector);
  const { isFiltersApplied } = useSelector(getShowingIsFilterSelector);
  const { showingsFilter, listingsFilter } = useSelector(getShowingFilterSelector);
  const dispatch = useDispatch();
  const location = useLocation();
  const historyState = location.state as locationState;
  useEffect(() => {
    const showingDataSet = [
      ...(showingAggregate?.Canceled || []),
      ...(showingAggregate?.Declined || []),
      ...(showingAggregate?.Past || []),
      ...(showingAggregate?.Pending || []),
    ];
    let { showingId } = getShowingIdAndTypeFromUrl();

    showingId = historyState?.showingAppointmentUUID
      ? historyState.showingAppointmentUUID
      : showingId;

    if (showingId) {
      const record = showingDataSet.find((obj) => obj.UUID === showingId);
      if (record) {
        redirectToOverview(record);
      }
    }
  }, [showingAggregate]);

  useEffect(() => {
    if (params?.uuid) {
      setData({ UUID: params?.uuid });
      setAppointmentModal(true);
    }
  }, [params]);

  useEffect(() => {
    return () => {
      dispatch(setShowingsIsArchiveEffect(false));
    };
  }, []);

  const [isFeedback, setIsNewFeedback] = useState(false);
  const [isUserApproverForPendingApproval, setUserApproverForPendingApproval] = useState(false);

  const getPendingApproverNames = (data) => {
    let names: any = [];
    const approverGroupByStatus = groupBy(data.ShowingAppointmentApproval, 'ApprovalStatus');
    approverGroupByStatus?.Pending?.forEach((Approval) => {
      const { ApproverName, ApproverUserEmail, ApproverUserId } = Approval;
      const name =
        userId && userId === ApproverUserId
          ? 'You'
          : `${ApproverName?.FirstName} ${ApproverName?.LastName}`;
      names.push(ApproverName ? name : ApproverUserEmail);
    });

    return names;
  };

  const checkApproverSequence = (data): boolean => {
    const approverGroupByStatus = groupBy(data?.ShowingAppointmentApproval, 'ApprovalStatus');
    const pendingApprovers = approverGroupByStatus?.Pending;

    if (pendingApprovers?.length && pendingApprovers?.[0]?.ApprovalSequence) {
      for (let i = 0; i < pendingApprovers?.length; i++) {
        const approver = pendingApprovers[i];
        const prevApprover = i ? pendingApprovers[i - 1] : null;

        if (
          approver?.ApproverUserId === userId &&
          prevApprover?.ApprovalStatus === ApprovalStatus.Pending
        ) {
          return false;
        }
      }
    }
    return true;
  };

  const processData = (data) => {
    setData(data);
    const approvers = getPendingApproverNames(data);
    setIsNewFeedback(data?.IsNewFeedback);
    setPendingApprovers(approvers);
    setUserApproverForPendingApproval(approvers?.includes('You'));
  };

  const redirectToOverview = (record) => {
    processData(record);
    setAppointmentModal(true);
  };

  const onDetailsClose = () => {
    if (params?.uuid) {
      history.push(link.toTransactionShowings(transactionId));
    }
    setAppointmentModal(false);
    setData({ UUID: 0 });
    if (isFeedback) fetchShowingAggregate();
  };

  const isApprovalRequired = (data) => {
    return isUserApproverForPendingApproval && checkApproverSequence(data);
  };

  const fetchShowingAggregate = useCallback(() => {
    const transactionIdFilter = isTransactionRoom ? { PropertyTransactionId: transactionId } : {};
    dispatch(
      getShowingAggregateByUserIdEffect({
        id: userId,
        filters: { IsArchive: isArchive, id: showingId, ...transactionIdFilter },
      }),
    );
  }, [showingId, transactionId]);

  const showings = [
    ...(showingAggregate?.Pending || []),
    ...(showingAggregate?.Upcoming || []),
    ...(showingAggregate?.NewFeedback || []),
  ];

  const archiveShowings = [
    ...(showingAggregate?.Past || []),
    ...(showingAggregate?.Canceled || []),
    ...(showingAggregate?.Declined || []),
  ];

  const processAction = (action) => {
    setShowModal(true);
    setActionConfig({ ...actionConfig, action });
  };

  const onConfirm = () => {
    switch (actionConfig?.action) {
      case ApprovalAction.Approve:
        onSubmit(ApprovalStatus.Approved, ApprovalStatus.Pending);
        break;
      case ApprovalAction.Decline:
        onSubmit(ApprovalStatus.Rejected, ApprovalStatus.Pending);
        break;
      case ApprovalAction.Cancel:
        onCancel();
        break;
      default:
        break;
    }
    setShowModal(false);
  };

  const onCancel = () => {
    dispatch(
      requestUpdateShowingAppointmentEffect(
        { UUID: data && data?.UUID, AppointmentStatus: AppointmentStatus.Canceled },
        {},
        (err) => {
          if (!err) {
            onSuccess(AppointmentStatus.Canceled);
          }
        },
      ),
    );
  };

  const onSuccess = (status) => {
    showSuccessMessage(`Showing ${status} Successfully`);
    fetchShowingAggregate();
  };

  const validateApprover = (type, data) => {
    const { ShowingAppointmentApproval } = data;
    const approverGroupByStatus = groupBy(ShowingAppointmentApproval, 'ApprovalStatus');
    const approvers = approverGroupByStatus[type] || [];
    return approvers.find((a) => a.ApproverUserId === userId);
  };

  const onSubmit = (approvalStatus, approverStatus) => {
    const approver = validateApprover(approverStatus, data);
    if (approver) {
      dispatch(
        requestUpdateShowingAppointmentApprovalEffect(
          { UUID: approver.UUID, ApprovalStatus: approvalStatus },
          {},
          (err) => {
            if (!err) {
              onSuccess(approvalStatus);
            }
          },
        ),
      );
    }
  };

  const onConfirmationCancel = (e) => {
    e.stopPropagation();
    setShowModal(false);
  };

  const tableProps = {
    columns: tableColumns(
      isArchive,
      processAction,
      setData,
      getPendingApproverNames,
      isTransactionRoom,
    ),
    onRow: (record) => {
      return {
        onClick: (event) => {
          redirectToOverview(record);
        },
      };
    },
    ...(isTransactionRoom && { collapsibleClassName: styles.transactionShowingsCollapsibleTable }),
  };

  const filterShowings = (data) => {
    return data?.filter((item) => item.AppointmentStatus !== AppointmentStatus.Pending);
  };

  const todayShowings = () =>
    filterShowings(showings.filter((item) => isToday(subtractTimeZone(item.AppointmentDate))));

  const tomorrowShowings = () =>
    filterShowings(showings.filter((item) => isTomorrow(subtractTimeZone(item.AppointmentDate))));

  const thisWeekShowings = () =>
    filterShowings(
      showings.filter(
        (item) =>
          isThisWeek(subtractTimeZone(item.AppointmentDate)) &&
          !isToday(subtractTimeZone(item.AppointmentDate)) &&
          !isTomorrow(subtractTimeZone(item.AppointmentDate)),
      ),
    );

  const upcomingShowings = () =>
    filterShowings(
      showingAggregate?.Upcoming?.filter(
        (item) => !isThisWeek(subtractTimeZone(item.AppointmentDate)),
      ),
    );

  function getFilterAppliedShowingsTitle() {
    const count = [...showings, ...archiveShowings]?.length;
    return `${count} Showing${count > 1 ? 's' : ''}`;
  }

  return (
    <Wrapper className={classNames(styles.showingsTable, showingTableClass)} isPending={isPending}>
      <div testid="tableView" className={classNames(styles.tableView)}>
        <div
          className={classNames(styles.view, {
            [styles.transactionShowingsView]: isTransactionRoom,
          })}
        >
          {isFiltersApplied ? (
            <WorkshopTableWrapper
              data={[[...showings], [...archiveShowings]]}
              emptyText="No Showings."
            >
              <CollapsibleTable
                title={getFilterAppliedShowingsTitle()}
                dataSource={[...showings, ...archiveShowings]}
                disableCollapse
                {...tableProps}
              />
            </WorkshopTableWrapper>
          ) : isArchive ? (
            <WorkshopTableWrapper
              data={[
                showingAggregate?.Canceled || [],
                showingAggregate?.Declined || [],
                showingAggregate?.Past || [],
              ]}
              emptyText="No Showings."
            >
              <CollapsibleTable
                title="Cancelled"
                dataSource={showingAggregate?.Canceled || []}
                {...tableProps}
              />
              <CollapsibleTable
                title="Declined"
                dataSource={showingAggregate?.Declined || []}
                {...tableProps}
              />
              <CollapsibleTable
                title="Past"
                dataSource={showingAggregate?.Past || []}
                {...tableProps}
              />
            </WorkshopTableWrapper>
          ) : (
            <WorkshopTableWrapper
              data={[
                showingAggregate?.Pending || [],
                showingAggregate?.NewFeedback || [],
                todayShowings(),
                tomorrowShowings(),
                thisWeekShowings(),
                upcomingShowings() || [],
              ]}
              emptyText="No Showings."
            >
              {isTransactionRoom ? (
                <CollapsibleTable title="" disableCollapse dataSource={showings} {...tableProps} />
              ) : (
                <>
                  <CollapsibleTable
                    title="Pending"
                    dataSource={showingAggregate?.Pending || []}
                    {...tableProps}
                  />
                  <CollapsibleTable
                    title="New Feedback"
                    dataSource={showingAggregate?.NewFeedback || []}
                    {...tableProps}
                  />
                  <CollapsibleTable title="Today" dataSource={todayShowings()} {...tableProps} />
                  <CollapsibleTable
                    title="Tomorrow"
                    dataSource={tomorrowShowings()}
                    {...tableProps}
                  />
                  <CollapsibleTable
                    title="This Week"
                    dataSource={thisWeekShowings()}
                    {...tableProps}
                  />
                  <CollapsibleTable
                    title="Upcoming"
                    dataSource={upcomingShowings()}
                    {...tableProps}
                  />
                </>
              )}
            </WorkshopTableWrapper>
          )}
        </div>
      </div>
      <ConfirmationWithReasonModal
        open={showModal}
        confirmText={<span>Are you sure you want to {actionConfig?.action} this showing?</span>}
        placeholder={`Write a reason for ${
          actionConfig?.action === ApprovalAction.Decline ? 'declining' : 'cancelling'
        }...`}
        okText={actionConfig?.action === ApprovalAction.Cancel ? 'Confirm' : actionConfig?.action}
        showReason={!(actionConfig?.action === ApprovalAction.Approve)}
        onOk={onConfirm}
        onCancel={onConfirmationCancel}
      />
      {data !== null && (
        <AppointmentDetailsModal
          isOpen={appointmentModal}
          onClose={onDetailsClose}
          UUID={(data?.UUID || historyState?.showingAppointmentUUID)?.toString()}
          isApprovalRequired={isApprovalRequired}
          pendingApprovers={pendingApprovers}
          processAction={processAction}
          isFeedback={isFeedback}
          processData={processData}
        />
      )}
    </Wrapper>
  );
};
