import React from 'react';

export const PlansIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <rect x="4" y="5" width="9" height="8" rx="2" stroke="currentColor" strokeWidth="2" />
      <path
        d="M13 8H14C15.1046 8 16 8.89543 16 10V14C16 15.1046 15.1046 16 14 16H9C7.89543 16 7 15.1046 7 14V13"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M16 11H17C18.1046 11 19 11.8954 19 13V17C19 18.1046 18.1046 19 17 19H12C10.8954 19 10 18.1046 10 17V16"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
};
