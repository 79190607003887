import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LocalHeader } from './LocalHeader';
import {
  getShowingAggregateByUserIdEffect,
  resetShowingAggregateEffect,
  setShowingsIsArchiveEffect,
} from 'store/effects/showingAggregate';
import { getUserId } from 'store/selectors/user';
import { getShowingIsArchiveSelector } from 'store/selectors/showingAggregate';
import { AppointmentStatus } from 'types/showingAppointment';
import { ShowingsTable } from './components/ShowingsTable/ShowingsTable';
import { getViewModeSelector } from 'store/selectors/workshop';

import { getShowingIdAndTypeFromUrl } from 'utils';
import { PageWrapper } from 'pages/Workshop/PageWrapper';
import { LocationService } from 'services';
import moment from 'moment';

export const Showings = () => {
  const dispatch = useDispatch();
  const { isArchive } = useSelector(getShowingIsArchiveSelector);
  const userId = useSelector(getUserId);
  const viewMode = useSelector(getViewModeSelector);
  const locationSrv = new LocationService();
  locationSrv.setLocation(location);
  const query = locationSrv.getQuery();

  const getShowings = ({ archive }, filters) => {
    dispatch(
      getShowingAggregateByUserIdEffect({
        id: userId,
        IsArchive: archive,
        filters: filters,
      }),
    );
  };

  useEffect(() => {
    const { showingId } = getShowingIdAndTypeFromUrl();

    if (showingId) {
      dispatch(setShowingsIsArchiveEffect(true));
      getShowings({ archive: true }, {});
    }
  }, [isArchive]);

  useEffect(() => {
    if (Object.keys(query).length) {
      if (query.isArchive) dispatch(setShowingsIsArchiveEffect(true));

      const filterStatus = query.teamStats ? {} : { isMyShowings: true };
      const filters = {
        Status: query.status ? query.status.split(',') : [],
        statuses: query.statuses ? query.statuses.split(',') : [],
        dueFrom: query.dueToday ? moment() : query.dueWeek ? moment().startOf('week') : undefined,
        dueTo: query.dueToday ? moment() : query.dueWeek ? moment().endOf('week') : undefined,
      };

      getShowings({ archive: query.isArchive }, { ...filters, ...filterStatus });
    } else getShowings({ archive: false }, {});
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setShowingsIsArchiveEffect(null));
      dispatch(resetShowingAggregateEffect());
    };
  }, []);

  return (
    <PageWrapper isContainer={false}>
      <LocalHeader />
      <ShowingsTable />
    </PageWrapper>
  );
};
