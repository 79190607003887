import { Intel, Reminders } from 'pages/Intel';
import { routes } from 'settings/navigation/routes';
import { AGENT, CLIENT } from 'settings/constants/roles';

export default [
  {
    path: routes.intel,
    component: Intel,
    exact: true,
    roles: [CLIENT, AGENT],
  },
  {
    path: routes.intelProperties,
    component: Intel,
    exact: true,
    roles: [CLIENT],
  },
  {
    path: routes.intelNeighborhood,
    component: Intel,
    exact: true,
    roles: [CLIENT],
  },
  {
    path: routes.intelReminders,
    component: Reminders,
    exact: true,
    roles: [CLIENT],
  },
];
