import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  getAgentPropertiesList,
  getAgentPropertiesMarkersSelector,
  getFeedCriteriaSelectorV3,
} from 'store/selectors/feedv3';
import Map from '../common/Map';
import parentStyles from '../ClientFeed/styles.module.scss';
import { getFeedSortSelector, getSubFilterTypeSelector } from 'store/selectors/feed';
import { dispatch } from 'store';
import { setCriteriaAction, setCriteriaModeAction } from 'store/actions/feedv3';
import { CREATE } from 'settings/constants/mode';
import { useAgentSearchInstance } from '../AgentFeed/hooks/useAgentSearchInstance';
import { AgentHeader, PropertiesList } from '../AgentFeed/components';
import { getShareDrawerInfoSelector } from '../Feed/Drawers/ShareDrawer/selectors';
import { getClientsEffect, sortFeedEffect } from 'store/effects';
import { IDLE } from 'settings/constants/apiState';
import { setSubFilterTypeAction } from 'store/actions/feed';
import { subFilterTypes } from 'settings/constants/properties';
import { SORT_ASC } from 'settings/constants/sort';
import styles from './styles.module.scss';
import { ViewSwitcher } from '../AgentFeed/components/ViewSwitcher/ViewSwitcher';
import { LocationType } from 'types';
import { MapDrawControlsSearchWrapper } from '../SearchResults/components/MapDrawControlsSearchWrapper';
import { toggleSearchMapDrawingModeAction } from 'store/actions/searchResults';

const AgentResults = () => {
  const agentPropertiesMarkers = useSelector(getAgentPropertiesMarkersSelector);
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const sort = useSelector(getFeedSortSelector);
  const subFilterType = useSelector(getSubFilterTypeSelector);
  const { criteria } = useSelector(getFeedCriteriaSelectorV3);
  const polygonLocations =
    criteria?.Locations?.filter(({ Type }) => Type === LocationType.Polygon) ?? [];
  const getAgentSearchInstance = useAgentSearchInstance();
  const { properties, isPending, totalCount } = useSelector(getAgentPropertiesList);
  const [viewType, setViewType] = useState<'List Only' | 'Map Only' | 'Split View'>('Split View');
  const [isFavortiesSectionVisible, setIsFavortiesSectionVisible] = useState(false);

  const { clients } = useSelector(getShareDrawerInfoSelector);

  let propertyProps = {
    isPending: isPending,
    properties: properties,
  };

  const setDrawnPolygonDispatchFn = (polygon) => {
    const newCriteria = { ...criteria, Locations: [polygon] };
    dispatch(setCriteriaAction({ criteria: newCriteria }));
    getAgentSearchInstance({ criterias: newCriteria });
    dispatch(toggleSearchMapDrawingModeAction(false));
  };

  const setPolygonLocations = (payload) => {
    const nonPolygonLocations =
      criteria?.Locations?.filter(({ Type }) => Type !== LocationType.Polygon) ?? [];
    const newCriteria = { ...criteria, Locations: [...nonPolygonLocations, ...payload] };
    dispatch(setCriteriaAction({ criteria: newCriteria }));
    getAgentSearchInstance({ criterias: newCriteria });
  };

  let mapProps = {
    markers: agentPropertiesMarkers,
    properties: properties,
    totalCount: totalCount,
    setDrawnPolygonDispatchFn: setDrawnPolygonDispatchFn,
    polygonLocations: polygonLocations,
  };

  useEffect(() => {
    if (criteria && !firstRender) {
      getAgentSearchInstance({
        criterias: criteria,
      });
    }
  }, [subFilterType, sort]);

  useEffect(() => {
    dispatch(getClientsEffect({}, { silent: clients.state === IDLE }) as any);
    dispatch(setCriteriaModeAction({ mode: CREATE }));
    if (subFilterType !== SORT_ASC || sort !== subFilterTypes.ALL) {
      dispatch(sortFeedEffect({ order: SORT_ASC }) as any);
      dispatch(setSubFilterTypeAction(subFilterTypes.ALL));
    } else {
      getAgentSearchInstance({
        criterias: criteria,
      });
    }
    setFirstRender(false);
  }, []);

  const [showBlurBg, setShowBlurBg] = useState<boolean>(false);

  const getViewType = (value) => {
    setViewType(value);
  };

  return (
    <div className={styles.agentFeedWrapper}>
      <AgentHeader
        isSimpleHeaderTitleVisible={isFavortiesSectionVisible}
        setIsSimpleHeaderTitleVisible={setIsFavortiesSectionVisible}
        isSavedSearchResult={true}
        setShowBlurBg={(value) => setShowBlurBg(value)}
        isPending={isPending}
      />
      <Row className={parentStyles.clientFeed}>
        {showBlurBg && <div className={parentStyles.backdropBlur} />}
        {viewType !== 'List Only' && (
          <Col md={viewType === 'Split View' ? 10 : 24} className={parentStyles.mapContainer}>
            <Map {...mapProps} />
          </Col>
        )}
        {viewType !== 'Map Only' && (
          <Col
            md={viewType === 'Split View' ? 14 : 24}
            className={parentStyles.propertiesContainer}
          >
            <PropertiesList
              {...propertyProps}
              isSimpleHeaderTitleVisible={isFavortiesSectionVisible}
              isAgentType={!isFavortiesSectionVisible}
              viewType={viewType}
            />
          </Col>
        )}
      </Row>
      <div className={styles.mapSwitchers}>
        <ViewSwitcher getView={getViewType} />
        {viewType !== 'List Only' && (
          <MapDrawControlsSearchWrapper
            polygonLocations={polygonLocations}
            setPolygonLocations={setPolygonLocations}
          />
        )}
      </div>
    </div>
  );
};

export default AgentResults;
