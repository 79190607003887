import React, { FC, PropsWithChildren, ReactElement } from 'react';
import { Minus, Plus } from '../../../../../components/Icons';
import styles from './styles.module.scss';
import { Collapse, Panel } from '../../../../../components-antd';
import { CollapseProps } from 'antd';

type FilterSectionProps = {
  title: string;
  count?: number;
};

export const FilterSection: FC<PropsWithChildren<FilterSectionProps>> = (
  props: PropsWithChildren<FilterSectionProps>,
): ReactElement => {
  const { title, count, children } = props;

  const getCollapseIcon: CollapseProps['expandIcon'] = (panelProps) => {
    const { isActive } = panelProps;
    return isActive ? (
      <Minus className={styles.btnWrapper} />
    ) : (
      <Plus className={styles.btnWrapper} />
    );
  };

  return (
    <Collapse
      defaultActiveKey={['radarLogsFilter']}
      ghost
      expandIcon={getCollapseIcon}
      expandIconPosition="end"
      className={styles.filterSection}
    >
      <Panel
        key="radarLogsFilter"
        header={
          <div className={styles.header}>
            <div className={styles.title}>{title}</div>
            {count !== undefined && <div className={styles.count}>{`(${count})`}</div>}
          </div>
        }
      >
        {children}
      </Panel>
    </Collapse>
  );
};
