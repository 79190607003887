import classNames from 'classnames';
import { Wrapper as PendingWrapper } from 'components';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { link } from 'settings/navigation/link';
import { getTeamsDashboardEffect, setSelectedTeamNameEffect } from 'store/effects';
import {
  getTeamsDashboardSelector,
  getTeamsDataDashboardSelector,
} from 'store/selectors/adminPanel';
import Header from './Components/Header';
import { TeamsTable } from './Components/TeamsTable';
import styles from './styles.module.scss';
import { tableColumns } from './tableColumns';

export const Teams = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isPending, isIdle } = useSelector(getTeamsDashboardSelector);
  const teamData = useSelector(getTeamsDataDashboardSelector);
  useEffect(() => {
    if (isIdle) {
      dispatch(getTeamsDashboardEffect({}, {}));
    } else {
      dispatch(getTeamsDashboardEffect({}, { silent: true }));
    }
  }, []); // eslint-disable-line

  const tableProps = {
    columns: tableColumns(),
    onRow: (record) => {
      return {
        onClick: (event) => {
          dispatch(setSelectedTeamNameEffect(record?.TeamName));
          history.push(link.toAdminPanelTeamDetails(record?.TeamId));
        },
      };
    },
  };
  return (
    <div>
      <Header title="Teams" isPending={isPending} />
      <PendingWrapper
        isPending={isPending}
        className={classNames({ [styles.pendingWrapper]: isPending })}
      >
        <TeamsTable dataSource={teamData} {...tableProps} />
      </PendingWrapper>
    </div>
  );
};

export default Teams;
