import { useEffect, useState } from 'react';
import { Row, Col, Checkbox, DatePicker, InputNumber, Switch } from 'antd';
import moment from 'moment';
import classNames from 'classnames';

import { TimeInput } from '../../../../../../../components-antd/Input/Variants/TimeInput';
import { TimeZone } from '../../../../../../ShowingAppointment/BookingCalendar/TimeZone';
import { getTimeZone } from '../../../../../../../helpers';
import { getMergedDateTime, getLocaleDate } from 'helpers';
import styles from './styles.module.scss';
import { cloneDeep } from 'lodash-es';

const initState = {
  isIndefinite: false,
  isAddDays: false,
  isExpirationDateTime: false,
};

export const OfferExpiration = ({ data, counterOfferFormData, setCounterOfferFormData }) => {
  const [state, setState] = useState(initState);

  useEffect(() => {
    setState({ ...initState, isIndefinite: true });
  }, []);

  const onChange = (data) => {
    setState({ ...initState, ...data });
    if (data.isIndefinite) {
      setCounterOfferFormData({
        ...counterOfferFormData,
        OfferExpiryIndefinitely: true,
        OfferExpiryDays: null,
        OfferExpiryEndDate: null,
      });
    } else if (data.isAddDays) {
      setCounterOfferFormData({
        ...counterOfferFormData,
        OfferExpiryIndefinitely: false,
      });
    } else if (data.isExpirationDateTime) {
      setCounterOfferFormData({
        ...counterOfferFormData,
        OfferExpiryIndefinitely: false,
        OfferExpiryDays: null,
      });
    }
  };

  const setDeadline = (days, date) => {
    const data = days ? moment().add(days, 'd') : moment(date);
    setCounterOfferFormData({
      ...counterOfferFormData,
      OfferExpiryDays: days,
      OfferExpiryEndDate: data,
      OfferExpiryIndefinitely: false,
    });
  };

  const setDeadlineDateTime = (date) => {
    setCounterOfferFormData({
      ...counterOfferFormData,
      OfferExpiryEndDate: getMergedDateTime(
        counterOfferFormData?.OfferExpiryEndDate,
        cloneDeep(date),
      ),
    });
  };

  const disabledDate = (current) => {
    let today = moment(new Date()).format('YYYY-MM-DD');
    return current && current < moment(today, 'YYYY-MM-DD');
  };

  return (
    <div className={styles.offerLink}>
      <Row className={styles.content}>
        <Col
          span={24}
          className={styles.container}
          onClick={() => {
            onChange({ isIndefinite: true });
          }}
        >
          <Checkbox
            checked={state?.isIndefinite}
            defaultChecked={state?.isIndefinite}
            className={classNames(styles.checkbox, 'mosaikCheckbox')}
          >
            <span className={styles.static}>None</span>
          </Checkbox>
        </Col>
        <Col span={24} className={styles.container} onClick={() => onChange({ isAddDays: true })}>
          <Checkbox
            checked={state.isAddDays}
            defaultChecked={state.isAddDays}
            className={classNames(styles.checkbox, 'mosaikCheckbox')}
          >
            <InputNumber
              className={styles.daysInputHolder}
              min={1}
              placeholder="#"
              step={1}
              precision={0}
              onChange={(val) => setDeadline(val, null)}
            />
            <span className={styles.static}>Days</span>
          </Checkbox>
        </Col>
        <Col span={24} className={styles.container}>
          <div className={styles.offerExpiration__lastRow}>
            <Checkbox
              checked={state.isExpirationDateTime}
              defaultChecked={state.isExpirationDateTime}
              className={classNames(styles.checkbox, styles.checkboxExpiration, 'mosaikCheckbox')}
              onChange={() => onChange({ isExpirationDateTime: true })}
            />
            <div className={styles.deadlineDate}>
              <DatePicker
                className={styles.rangePicker}
                format={'MM/DD/YYYY'}
                placeholder={'MM/DD/YYYY'}
                onChange={(e: any) => {
                  setDeadline(null, e);
                }}
                disabledDate={disabledDate}
                getPopupContainer={(node) => node}
                popupClassName="mosaikCalendar"
              />
              <span className={styles.static}>at</span>
              <TimeInput
                onChange={(e: any) => {
                  setDeadlineDateTime(e);
                }}
                wrapperClassName={styles.timePicker}
                getPopupContainer={(node) => node}
              />
              <TimeZone timeZone={getTimeZone()} />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
