import { previewBundleFormDocument, previewDraftFormDocument } from 'api/formProcess';
import { PdfViewModal } from 'components';
import { showErrorMessage } from 'helpers';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { previewFormDocumentEffect } from 'store/effects/formProcess';
import { DocumentVaultResponseType } from 'types';

interface FormLibraryContent {
  formId: number;
  versionId: number;
  name: string;
  lastUpdatedDate: string;
  categories: string[];
  isCAR?: boolean;
  link?: string;
  bundleId?: string;
  templateLinks?: string[];
  Name: string;
}

export const FormFilePreview = ({ record, onClosePreview }) => {
  const [previewDocumentVaultUUID, setPreviewDocumentVaultUUID] = useState<string>();
  const [attachment, setAttachment] = useState<DocumentVaultResponseType>();

  const dispatch = useDispatch();

  useEffect(() => {
    if (record) {
      onPreviewDocument(record);
    }
  }, [record]);

  useEffect(() => {
    const documentVaultUUID = !record?.bundleId
      ? previewDocumentVaultUUID?.trim()
      : previewDocumentVaultUUID;

    if (documentVaultUUID && !record?.bundleId) {
      dispatch(
        previewFormDocumentEffect({ documentVaultUUID: documentVaultUUID }, {}, (err, response) => {
          if (!err) {
            setAttachment({
              DocumentBuffer: response,
              Filename: record?.name ? record.name : response.headers['file-name'],
            });
          }
        }),
      );
    }
  }, [previewDocumentVaultUUID]);

  const onPreviewDocument = (record: FormLibraryContent) => {
    const link = record.link || (record as any).DocumentLink;

    if (record.bundleId) {
      handleBundleFormPreview(
        record.bundleId,
        !!record.templateLinks?.length,
        record.Name || record.name,
      );
    } else if (!record.isCAR && link) {
      setPreviewDocumentVaultUUID(link);
    } else if (record.isCAR) {
      handleFormDocumentPreview(record.formId, record.versionId, record.name);
    }
  };

  const handleFormDocumentPreview = (formId, versionId, name) => {
    setPreviewDocumentVaultUUID(' ');
    previewDraftFormDocument({ formId, versionId, responseType: 'arraybuffer' })
      .then((buffer) => {
        setAttachment({
          DocumentBuffer: buffer,
          Filename: name,
        });
      })
      .catch((err) => {
        showErrorMessage(err);
      });
  };

  const handleBundleFormPreview = (bundleId, isTemplate, name) => {
    setPreviewDocumentVaultUUID(bundleId);

    previewBundleFormDocument({ bundleId, responseType: 'arraybuffer', isTemplate })
      .then((buffer) => {
        setAttachment({
          DocumentBuffer: buffer,
          Filename: name,
        });
      })
      .catch((err) => {
        showErrorMessage(err);
      });
  };

  const handlePreviewClose = (e) => {
    setPreviewDocumentVaultUUID(undefined);
    setAttachment(undefined);
    onClosePreview(e);
  };

  return (
    <div>
      {previewDocumentVaultUUID ? (
        <PdfViewModal
          isOpen={true}
          file={attachment}
          onClose={handlePreviewClose}
          showDownload={true}
          hideFileNameIfEmpty={true}
          showInCohesiveFlow={true}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
