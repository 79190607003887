import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import classNames from 'classnames';
import { Toggle } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import {
  requestGetCampaignByIdEffect,
  requestGetCampaignMembersByCampaignIdEffect,
  requestPutCampaignMasterSwitchEffect,
  requestUpdateCampaignEffect,
} from 'store/effects/radarKits';
import {
  selectedSequenceCampaignMembers,
  selectedSequenceStateSelector,
} from 'store/selectors/radarKits';
import { AssignClientsModal } from '../../AssignClientsModal';
import { PencilIcon, UsersIcon } from '../icons';
import styles from './styles.module.scss';
import { READY } from 'settings/constants/apiState';
import { isEqual } from 'lodash-es';

type Props = {
  editMode?: boolean;
  handleClick?: (e) => void;
  sequenceId: string;
  isActive: boolean;
  setCampaignMembersMasterSwitch: React.Dispatch<React.SetStateAction<boolean>>;
};

export const SequenceAssignedClients: React.FC<Props> = ({
  editMode = true,
  handleClick,
  sequenceId,
  isActive: initialIsActive,
  setCampaignMembersMasterSwitch,
}) => {
  const dispatch = useDispatch();
  const campaignMembers = useSelector(selectedSequenceCampaignMembers);
  const selectedSequenceState = useSelector(selectedSequenceStateSelector);
  const [isAssignClientsModalOpen, setIsAssignClientsModalOpen] = useState<boolean>(false);
  const isInitialized = useRef(false);
  const getSelectedFromCampaignMembers = useCallback((members) => {
    return (members || []).flatMap((client) =>
      client.Addresses.map((address) => ({
        ClientId: client.ClientId,
        AddressId: address.AddressId,
      })),
    );
  }, []);
  const [selectedClients, setSelectedClients] = useState<{ ClientId: number; AddressId: number }[]>(
    getSelectedFromCampaignMembers(campaignMembers),
  );
  const [isActive, setIsActive] = useState<boolean>(initialIsActive);

  useEffect(() => {
    const campaignSelectedClients = getSelectedFromCampaignMembers(campaignMembers);
    if (
      !isInitialized.current &&
      selectedSequenceState === READY &&
      campaignSelectedClients?.length &&
      !selectedClients?.length
    ) {
      setSelectedClients(campaignSelectedClients);
      isInitialized.current = true;
    }
  }, [selectedSequenceState, campaignMembers]);

  // update the state as parent take a render more to update isActive
  useEffect(() => {
    setIsActive(initialIsActive);
  }, [initialIsActive]);

  const countAssignedClients = useMemo(() => {
    if (!selectedClients.length) return 0;
    const clientIds = new Set(selectedClients?.map((client) => client.ClientId));
    return clientIds.size;
  }, [selectedClients]);

  return (
    <>
      <div className={styles.assignedClientWrapper}>
        <div
          className={styles.assignedClientContainer}
          onClick={(e) => {
            e.stopPropagation();
            if (handleClick) {
              handleClick(e);
            }
          }}
        >
          <div className={classNames(styles.iconWrapper, styles.bluebg)}>
            <UsersIcon />
          </div>
          <div className={styles.text}>
            Assigned to: <span>{countAssignedClients} Clients</span>
            {editMode && (
              <div
                className={classNames(styles.iconWrapper, styles.pointer)}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsAssignClientsModalOpen(true);
                }}
              >
                <PencilIcon />
              </div>
            )}
          </div>
        </div>
        <div className={styles.toogleWrapper}>
          <div>Active</div>
          <Toggle
            checked={isActive}
            className={styles.toggle}
            onChange={(e) => {
              e.stopPropagation();
              setIsActive((prev) => !prev);
              dispatch(
                requestPutCampaignMasterSwitchEffect(
                  {
                    CampaignId: Number(sequenceId),
                    Active: !isActive,
                  },
                  {},
                  (err, res) => {
                    if (!err && res.data.result.Id) {
                      setCampaignMembersMasterSwitch(!isActive);
                    }
                    if (err) {
                      setIsActive(initialIsActive);
                    }
                    setIsAssignClientsModalOpen(false);
                  },
                ),
              );
            }}
          />
        </div>
      </div>
      {isAssignClientsModalOpen && (
        <AssignClientsModal
          isOpen={isAssignClientsModalOpen}
          handleClose={() => {
            dispatch(
              requestUpdateCampaignEffect(
                {
                  Id: Number(sequenceId),
                  CampaignMembers: selectedClients,
                },
                {},
                (err, res) => {
                  dispatch(
                    requestGetCampaignByIdEffect(sequenceId, { silent: false }, (err, res) => {
                      if (!err) {
                        const sequence = res?.data?.result;
                        const members = sequence?.CampaignMembers;
                        if (Array.isArray(members)) {
                          setSelectedClients(getSelectedFromCampaignMembers(members));
                        }
                      }
                    }),
                  );
                  setIsAssignClientsModalOpen(false);
                },
              ),
            );
          }}
          closeDialog={() => {
            setSelectedClients(getSelectedFromCampaignMembers(campaignMembers));
            setIsAssignClientsModalOpen(false);
          }}
          subHeading="Assign clients to this kit"
          selected={selectedClients}
          setSelected={setSelectedClients}
        />
      )}
    </>
  );
};
