import React from 'react';

import { PageHeader } from 'components';

import parentStyles from '../styles.module.scss';
import styles from './styles.module.scss';
import { ReminderCard } from '../components/PropertyTab/components';

export const Reminders: React.FC = () => {
  return (
    <div className={parentStyles.pageWrapper}>
      <PageHeader title={`Reminders`} />
      <div className={styles.pageWrapper}>
        <div className={styles.section}>
          <div className={styles.heading}>
            Upcoming <span>(6)</span>
          </div>
          <ReminderCard variant="large" />
        </div>
        <div className={styles.section}>
          <div className={styles.heading}>
            Past <span>(6)</span>
          </div>
          <ReminderCard variant="large" />
        </div>
      </div>
    </div>
  );
};
