import { formatDate } from 'helpers';
import { capitalize } from 'lodash-es';
import moment from 'moment';
import { OfferStatus } from 'types';
import styles from './styles.module.scss';

export type expiryTypes = {
  expiryDays: string;
  expiryHours: string;
  expiryMinutes: string;
};

export const getExpiration = (iteration) => {
  return `${formatDate(iteration?.OfferExpiryEndDate, 'L')} at ${formatDate(
    iteration?.OfferExpiryEndDate,
    'LT',
  )}`;
};

export const keywordStatus = (iteration) => {
  const statuses = {
    [OfferStatus.Accepted]: 'accepted',
    [OfferStatus.Rejected]: 'rejected',
    [OfferStatus.Withdrawn]: 'withdrawn',
    [OfferStatus.Expired]: 'expired',
    [OfferStatus.Cancelled]: 'cancelled',
  };

  return statuses[iteration.IterationStatus];
};

export const getIterationStatusDate = (iteration) => {
  const { AcceptedOn, RejectedOn, WithdrawnOn, IterationStatus } = iteration;
  const { Accepted, Rejected, Withdrawn } = OfferStatus;

  const statusDate = {
    [Accepted]: AcceptedOn,
    [Rejected]: RejectedOn,
    [Withdrawn]: WithdrawnOn,
  };

  let date: string = statusDate[IterationStatus] || new Date().toISOString();

  return `${formatDate(date, 'L')} at ${formatDate(date, 'LT')}`;
};

export const getOfferStatuses = (iteration) => {
  const isAcceptedRejectedOrWithdrawnOrCancelled = [
    OfferStatus.Accepted,
    OfferStatus.Rejected,
    OfferStatus.Withdrawn,
    OfferStatus.Cancelled,
  ].includes(iteration.IterationStatus);

  const isRejectedOrWithdrawnOrCancelled = [
    OfferStatus.Rejected,
    OfferStatus.Withdrawn,
    OfferStatus.Cancelled,
  ].includes(iteration.IterationStatus);

  const isOfferAccepted = iteration.IterationStatus === OfferStatus.Accepted;
  const isRejected = iteration.IterationStatus === OfferStatus.Rejected;
  const isWithdrawn = iteration.IterationStatus === OfferStatus.Withdrawn;
  let isExpired = iteration.IterationStatus === OfferStatus.Expired;
  let isCancelled = iteration.IterationStatus === OfferStatus.Cancelled;

  const expiryEndTime = iteration.OfferExpiryEndDate;
  isExpired = isExpired || (expiryEndTime && moment(expiryEndTime) < moment());

  return {
    isAcceptedRejectedOrWithdrawnOrCancelled,
    isRejectedOrWithdrawnOrCancelled,
    isOfferAccepted,
    isExpired,
    isRejected,
    isWithdrawn,
    isCancelled,
  };
};

export const getStatusForStages = (iteration, isPreviousStage) => {
  const { status, IsViewed, IterationStatus } = iteration;
  let stageStatus = 'Submitted';

  const { Initial, Accepted, Outbound, Inbound, Rejected, Withdrawn, Pending, Cancelled, Expired } =
    OfferStatus;

  const isPending = IterationStatus === Pending;
  const isCancelled = IterationStatus === Cancelled;

  const statusText = {
    [Accepted]: 'Accepted',
    [Rejected]: 'Rejected',
    [Withdrawn]: 'Withdrawn',
    [Expired]: 'Expired',
    [Cancelled]: 'Cancelled',
  };

  if (statusText[IterationStatus]) {
    return statusText[IterationStatus];
  } else if (status === Initial && IsViewed) {
    stageStatus = 'Viewed';
  } else if (status === Inbound && isPreviousStage) {
    stageStatus = 'Received';
  } else if (status === Outbound && isPreviousStage) {
    stageStatus = 'Sent';
  } else if (status === Inbound && isPending) {
    stageStatus = 'Pending';
  } else if (status === Outbound && isPending && !IsViewed) {
    stageStatus = 'Submitted';
  } else if (status === Outbound && (isPending || isCancelled) && IsViewed) {
    stageStatus = 'Viewed';
  }

  return stageStatus;
};

const formatNumber = (deg) => ('0' + deg).slice(-2);

const calculateExpiration = (OfferExpiryEndDate) => {
  const expiry = moment(OfferExpiryEndDate);
  const now = moment();
  const duration = moment.duration(expiry.diff(now));

  const expiryDays = Math.floor(duration.asDays());
  const expiryHours = duration.hours();
  const expiryMinutes = duration.minutes();

  return {
    expiryDays: expiryDays ? formatNumber(expiryDays) : '',
    expiryHours: expiryHours ? formatNumber(expiryHours) : '',
    expiryMinutes: expiryMinutes ? formatNumber(expiryMinutes) : '',
  };
};

export const getOfferExpiry = (offerIteration) => {
  const { OfferExpiryEndDate, OfferExpiryDays } = offerIteration;

  if (OfferExpiryDays) {
    const { expiryDays, expiryHours, expiryMinutes } = calculateExpiration(OfferExpiryEndDate);

    const isLast =
      +expiryDays === OfferExpiryDays - 1 && +expiryMinutes === 59 && +expiryHours === 23;
    return {
      expiryDays: isLast ? formatNumber(OfferExpiryDays) : expiryDays,
      expiryHours: isLast ? '0' : expiryHours,
      expiryMinutes: isLast ? '0' : expiryMinutes,
    };
  } else if (OfferExpiryEndDate) {
    let { expiryMinutes, expiryDays, expiryHours } = calculateExpiration(OfferExpiryEndDate);

    const lastHourAndMinute = +expiryHours === 23 && +expiryMinutes === 59;
    const lastMinute = +expiryMinutes === 59;
    if (lastHourAndMinute) {
      expiryDays = formatNumber(+expiryDays + 1);
      expiryHours = '0';
      expiryMinutes = '0';
    } else if (lastMinute) {
      expiryHours = formatNumber(+expiryHours + 1);
      expiryMinutes = '0';
    } else {
      expiryMinutes = formatNumber(+expiryMinutes + 1);
    }

    return { expiryDays, expiryHours, expiryMinutes };
  }
};

export const prepareInitialIterations = (iteration) => {
  const { Id, status, IsViewed } = iteration;
  const {
    isOfferAccepted,
    isRejected,
    isExpired,
    isWithdrawn,
    isCancelled,
    isAcceptedRejectedOrWithdrawnOrCancelled,
  } = getOfferStatuses(iteration);

  let initialIterations = [
    {
      status,
      Id,
      title: 'Submitted',
      dotIcon: IsViewed ? 'grey-dot' : 'grey-tick-circle',
      initialIteration: true,
      disabled: IsViewed || isAcceptedRejectedOrWithdrawnOrCancelled || isExpired,
      isDot: IsViewed,
    },

    ...(IsViewed
      ? [
          {
            status,
            Id: Id + 1,
            title: 'Viewed',
            dotIcon: 'grey-tick-circle',
            initialIteration: true,
            disabled: isAcceptedRejectedOrWithdrawnOrCancelled || isExpired,
          },
        ]
      : []),

    ...(isAcceptedRejectedOrWithdrawnOrCancelled || isExpired
      ? [
          {
            status,
            Id: Id + 2,
            title: keywordStatus(iteration),
            dotIcon: isOfferAccepted
              ? 'accepted'
              : isRejected
              ? 'rejected'
              : isExpired || isWithdrawn || isCancelled
              ? 'cross-circle'
              : '',
            initialIteration: true,
            disabled: false,
          },
        ]
      : []),
  ];

  return initialIterations;
};

export const renderOfferExpiry = (
  iteration,
  options: { dayText?: string; hrText?: string; minText?: string; seperator?: string } = {},
) => {
  const { expiryDays, expiryHours, expiryMinutes } = getOfferExpiry(iteration) as expiryTypes;
  const { dayText = 'Day', hrText = 'Hr', minText = 'Min', seperator = ':' } = options;

  return (
    <div className={styles.expiryDuration}>
      {+expiryDays ? (
        <span className={styles.expiryCount}>
          {`${expiryDays}`}
          <span className={styles.smallText}>
            {dayText}
            {+expiryDays === 1 ? '' : 's'}
          </span>
        </span>
      ) : (
        <></>
      )}

      {+expiryDays && +expiryHours ? <span>{seperator}</span> : <></>}

      {+expiryHours ? (
        <span className={styles.expiryCount}>
          {`${expiryHours || '00'}`}
          <span className={styles.smallText}>
            {hrText}
            {+expiryHours === 1 ? '' : 's'}
          </span>
        </span>
      ) : (
        <></>
      )}

      {(+expiryHours || +expiryDays) && +expiryMinutes ? <span>{seperator}</span> : <></>}

      {+expiryMinutes ? (
        <span className={styles.expiryCount}>
          {`${expiryMinutes || '00'}`}
          <span className={styles.smallText}>
            {minText}
            {+expiryMinutes === 1 ? '' : 's'}
          </span>
        </span>
      ) : (
        <></>
      )}
    </div>
  );
};
