import classNames from 'classnames';

import { ClientAddress } from 'app-constants';

import styles from './styles.module.scss';
import containerStyles from '../styles.module.scss';

interface ViewClientAddressesProps {
  addresses: ClientAddress[];
}

export const ViewClientAddresses = ({ addresses }: ViewClientAddressesProps) =>
  addresses.map((clientAddress, index) => (
    <div
      key={`${clientAddress.address.delivery_line_1}-${index}`}
      className={containerStyles.inputBlock}
    >
      <div className={classNames(containerStyles.label, styles.viewPrimaryAddress)}>
        <span>{`Address${index ? ` ${index + 1}` : ''}`}</span>
        <span>{clientAddress.isPrimary ? '(Primary)' : ''}</span>
      </div>
      <span testid="view_address" className={containerStyles.infoBlockForOneLine}>
        {clientAddress.address.delivery_line_1}
      </span>
    </div>
  ));
