import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import {
  getFormattedListingDetailBuyerMatch,
  getLoadingListingData,
} from 'store/selectors/listingDetail';
import { Accordion, CircularProgressBar, Spinner } from 'components';
import MatchItemsList from './MatchItemsList';
import { getUserEmail, getUserInsight } from 'store/selectors/user';
import { insightUpdateData } from 'store/effects';

import styles from './styles.module.scss';
import { useEffect } from 'react';
import {
  requestGetPropertyAdditionalFeaturesEffect,
  requestGetPropertyInsightsEffect,
} from 'store/effects/mlsScores';
import { featureFlags } from 'utils/featureFlags';
import { getPropertyInsights, getAdditionalFeatures } from 'store/selectors/mlsScores';

const Title = ({ match, insight, onChange }) => (
  <div className={styles.titleContainer}>
    <CircularProgressBar size="32" percentage={match} />
    <div testid="match_title" className={styles.title}>{`${match}% Match`}</div>
    <div className={styles.insights}>
      <span>Insights: </span>
      <div
        className={styles.container}
        onClick={(e) => {
          e.stopPropagation();
          onChange(!insight);
        }}
      >
        <span>{insight ? 'On' : 'Off'}</span>
      </div>
    </div>
  </div>
);

Title.propTypes = {
  match: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

const Match = () => {
  const dispatch = useDispatch();
  const insight = useSelector(getUserInsight);
  const { propertyDetails, propertyDetailsWhitelist } = featureFlags;
  const { propertyInsightsData, isLoading } = useSelector(getPropertyInsights);
  const { additionalFeaturesData, isLoading: isLoadingAdditionalFeaturesData } =
    useSelector(getAdditionalFeatures);
  const { match, mustHave, wants, matchedCriterias, additionalFeatures } = useSelector(
    getFormattedListingDetailBuyerMatch({
      propertyInsights: {
        ...propertyInsightsData?.Needs,
        ...propertyInsightsData?.Wants,
      },
      additionalFeatures: additionalFeaturesData,
    }),
  );
  const { additionalMustHave, additionalWants } = additionalFeatures || {};
  const { dataId } = useSelector(getLoadingListingData);
  const userEmail = useSelector(getUserEmail);

  const onInsightChange = (value) => {
    dispatch(insightUpdateData(value));
  };

  // AI-feature testing
  useEffect(() => {
    if (match && propertyDetails && propertyDetailsWhitelist.includes(userEmail)) {
      const searchCriteria = JSON.parse(localStorage.getItem('searchCriteria'));
      dispatch(
        requestGetPropertyInsightsEffect({
          id: dataId,
          MatchScore: match,
          MatchedCriteria: matchedCriterias,
        }),
      );
      if (searchCriteria?.KeywordPrefs?.length) {
        dispatch(
          requestGetPropertyAdditionalFeaturesEffect({
            id: dataId,
            matchCriteria: searchCriteria?.KeywordPrefs ?? [],
          }),
        );
      }
    }
  }, [match]);

  if (match === null) {
    return null;
  }

  return (
    <div testid="match_container" className={styles.matchContainer}>
      {isLoading || isLoadingAdditionalFeaturesData ? (
        <Spinner />
      ) : (
        <Accordion
          open
          className={styles.accordion}
          title={<Title match={match} insight={insight} onChange={onInsightChange} />}
        >
          <div className={styles.matchContent}>
            {!!mustHave?.length && (
              <div testid="match_needs_block" className={styles.needsContainer}>
                <p className={styles.matchItemsTitle}>Needs</p>
                <MatchItemsList items={mustHave} insight={insight} />
              </div>
            )}
            {!!additionalMustHave?.length && (
              <div className={styles.needsContainer}>
                <p className={styles.matchItemsTitle}>Additional Features (Needs)</p>
                <MatchItemsList items={additionalMustHave} />
              </div>
            )}
            {!!wants?.length && (
              <div testid="match_wants_block" className={styles.wantsContainer}>
                <p className={styles.matchItemsTitle}>Wants</p>
                <MatchItemsList items={wants} insight={insight} />
              </div>
            )}
            {!!additionalWants?.length && (
              <div className={styles.needsContainer}>
                <p className={styles.matchItemsTitle}>Additional Features (Wants)</p>
                <MatchItemsList items={additionalWants} />
              </div>
            )}
            {propertyInsightsData?.Summary && (
              <>
                <p className={styles.matchItemsTitle}>Summary</p>
                <p>{propertyInsightsData?.Summary}</p>
              </>
            )}
          </div>
        </Accordion>
      )}
    </div>
  );
};

export default Match;
