import { fetchIsClientInvitedFromTransaction } from 'api/user';
import classNames from 'classnames';
import { Spinner, Stages } from 'components';
import AccountCompleteScreen from 'pages/OnBoarding/components/Forms/AccountCompleteScreen';
import MailingAddressForm from 'pages/OnBoarding/components/Forms/MailingAddressForm';
import ProfilePhotoForm from 'pages/OnBoarding/components/Forms/ProfilePhotoForm';
import AreasOfOperationForm from 'pages/OnBoarding/components/Forms/agent/AreasOfOperationForm';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AGENT, CLIENT, TEAM_ADMIN, TEAM_BASIC, THIRD_PARTY } from 'settings/constants/roles';
import { getSignUpRoleSelector } from 'store/selectors/loginGroup';
import {
  AboutForm,
  BrokerDetailsForm,
  OrganizationDetailsForm,
  Summary,
  VendorDirectoryForm,
  HomePrefsForm,
  LocationPrefsForm,
  StageMustHavePrefsForm,
} from '../Forms';

import { useHistory } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';
import { getAgentTeamDetailEffect } from 'store/effects';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { getClientInstancesSelector } from 'store/selectors/clientInstances';
import {
  getAgentTypeSelector,
  getUserDataSelector,
  getUserRolesMapSelector,
  isPartnerSelector,
} from 'store/selectors/user';
import IntroScreen from '../Forms/Teams/IntroScreen';
import MlsInfo from '../Forms/Teams/MlsInfo';
import { VerifyEmailStep } from '../Forms/VerifyEmail';
import AccountSetupIntroScreen from '../Forms/agent/AccountSetupIntroScreen';
import ContactInfoForm from '../Forms/agent/ContactInfoForm';
import WebsiteForm from '../Forms/agent/WebsiteForm';

import { AreasOfOperationPartnerForm } from '../Forms/AreasOfOperationPartnerForm';
import {
  BusinessAddressForm,
  BusinessContactForm,
  BusinessProfileForm,
  DescriptionForm,
  PartnerAboutForm,
  PartnerProfilePhotoForm,
  PartnerVendorDirectoryForm,
  AreasServedForm,
  BusinessPhotoForm,
  NotificationForm,
  PartnerCompleteScreen,
  ServiceForm,
} from '../Forms/PartnerOnboardingForms';
import { Header } from '../Header';

import styles from './styles.module.scss';
import { VerifyEmailOtp } from '../Forms/VerifyEmailOtp';

const Middle = ({ className }) => {
  const dispatch = useDispatch();

  const signUpRole = useSelector(getSignUpRoleSelector);
  const user = useSelector(getUserDataSelector);
  if (!user) return null;

  const { isAgent } = useSelector(getUserRolesMapSelector);
  const isPartner = useSelector(isPartnerSelector);

  useEffect(() => {
    if (isAgent) dispatch(getAgentTeamDetailEffect());
  }, [isAgent]);

  const { searchInstances, transactionInstances, isLoading } = useSelector(
    getClientInstancesSelector,
  );
  const agentType = useSelector(getAgentTypeSelector);
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const [stages, setStages] = useState([]);
  const [inviteStatusLoading, setInviteStatusLoading] = useState(false);
  const history = useHistory();

  const isClientInvitedFromTransactionFetch = async () => {
    try {
      const response = await fetchIsClientInvitedFromTransaction();
      if (response.status === 200 && response.data?.result) {
        history.push(routes.index);
      }
      setInviteStatusLoading(false);
    } catch (e) {
      setInviteStatusLoading(false);
    }
  };

  useEffect(() => {
    if (signUpRole === CLIENT) {
      setInviteStatusLoading(true);
      isClientInvitedFromTransactionFetch();
    }
  }, [signUpRole]);

  useEffect(() => {
    setStages(
      getStagesForRole(signUpRole, searchInstances, transactionInstances, agentRole, isPartner),
    );
  }, [signUpRole, searchInstances, transactionInstances, agentRole, isPartner]);

  return (
    <div className={classNames(styles.middle, className)}>
      {inviteStatusLoading || (signUpRole === CLIENT && isLoading) ? (
        <Spinner />
      ) : (
        <>
          <Header />
          <Stages
            classNameWrapper={classNames(styles.onBoardingStages, {
              [styles.client]: signUpRole === CLIENT,
            })}
            classNameProgress={styles.onBoardingProgress}
            stages={stages}
          />
        </>
      )}
    </div>
  );
};

const getStagesForRole = (role, searchInstances, transactionInstances, agentRole, isPartner) => {
  if (role === AGENT && agentRole) {
    if ([TEAM_ADMIN, TEAM_BASIC].includes(agentRole)) {
      return TEAM_AGENT_SIGNUP_STEPS;
    } else {
      return AGENT_SIGNUP_STEPS;
    }
  }

  if (role === THIRD_PARTY) {
    if (isPartner) return PARTNER_ONBOARDING_STEPS;
    return THIRD_PARTY_SIGNUP_STEPS;
  }

  if (role === CLIENT) {
    if (searchInstances?.[0]) {
      return BUYER_SIGNUP_STEPS;
    } else if (transactionInstances?.[0]) {
      return SELLER_SIGNUP_STEPS;
    } else {
      return RETENTION_CLIENT_SIGNUP_STEPS; // retention client
    }
  }

  return [];
};

export const agentSignupSteps = {
  VERIFY_EMAIL: 'verifyEmail',
  INTRO: 'intro',
  CONTACT_INFO: 'contactInfo',
  BROKER_DETAILS: 'brokerDetails',
  WEBSITE: 'website',
  AREAS_OF_OPERATION: 'areasOfOperation',
  UPLOAD_PHOTO: 'uploadPhoto',
  SELECT_PLAN: 'selectPlan',
  ACCOUNT_COMPLETE: 'accountComplete',
};

export const teamAgentSignupSteps = {
  INTRO: 'intro',
  CONTACT_INFO: 'contactInfo',
  UPLOAD_PHOTO: 'uploadPhoto',
  AREAS_OF_OPERATION: 'areasOfOperation',
  MLS_INFO: 'mlsInfo',
  ASSOCIATIONS_MEMBER: 'associationsMember',
  ACCOUNT_COMPLETE: 'accountComplete',
};

export const buyerSignupSteps = {
  SUMMARY: 'summary',
  HOME_PREFS: 'homePrefs',
  LOCATION_PREFS: 'locationPrefs',
  WISHLIST: 'wishlist',
  ABOUT: 'about',
  AUTO_COMPLETE: 'accountComplete',
};

export const sellerSignupSteps = {
  ABOUT: 'about',
  MAILING_ADDRESS: 'mailingAddress',
  AUTO_COMPLETE: 'accountComplete',
};

export const thirdPartySignupSteps = {
  ABOUT: 'about',
  ORGANIZATION: 'organization',
  UPLOAD_PHOTO: 'uploadPhoto',
  AUTO_COMPLETE: 'accountComplete',
  VENDOR_DIRECTORY: 'vendorDirectory',
  AREAS_OF_OPERATION: 'areasOfOperation',
};

export const partnerOnboardingSteps = {
  ABOUT: 'about',
  BUSINESS_PROFILE: 'businessProfile',
  BUSINESS_ADDRESS: 'businessAddress',
  BUSINESS_CONTACT: 'businessContact',
  DESCRIPTION: 'description',
  SERVICE: 'service',
  UPLOAD_PHOTO: 'uploadPhoto',
  VENDOR_DIRECTORY: 'vendorDirectory',
  AREAS_SERVED: 'areasServed',
  UPLOAD_BUSINESS_PHOTO: 'uploadBusinessPhoto',
  NOTIFICATION: 'notification',
  ACCOUNT_COMPLETE: 'accountComplete',
};

const AGENT_SIGNUP_STEPS = [
  {
    id: agentSignupSteps.VERIFY_EMAIL,
    // Component: VerifyEmailStep,
    Component: VerifyEmailOtp,
  },
  {
    id: agentSignupSteps.INTRO,
    Component: AccountSetupIntroScreen,
  },
  {
    id: agentSignupSteps.CONTACT_INFO,
    Component: ContactInfoForm,
  },
  {
    id: agentSignupSteps.BROKER_DETAILS,
    Component: BrokerDetailsForm,
  },
  {
    id: agentSignupSteps.WEBSITE,
    Component: WebsiteForm,
  },
  {
    id: agentSignupSteps.AREAS_OF_OPERATION,
    Component: AreasOfOperationForm,
  },
  {
    id: agentSignupSteps.UPLOAD_PHOTO,
    Component: ProfilePhotoForm,
  },
  {
    id: agentSignupSteps.ACCOUNT_COMPLETE,
    Component: AccountCompleteScreen,
  },
];

const TEAM_AGENT_SIGNUP_STEPS = [
  {
    id: teamAgentSignupSteps.INTRO,
    Component: IntroScreen,
  },
  {
    id: teamAgentSignupSteps.CONTACT_INFO,
    Component: ContactInfoForm,
  },
  {
    id: teamAgentSignupSteps.UPLOAD_PHOTO,
    Component: ProfilePhotoForm,
  },
  {
    id: teamAgentSignupSteps.AREAS_OF_OPERATION,
    Component: AreasOfOperationForm,
  },
  {
    id: teamAgentSignupSteps.MLS_INFO,
    Component: MlsInfo,
  },
  // TODO: To be implemented later
  // {
  //   id: teamAgentSignupSteps.ASSOCIATIONS_MEMBER,
  //   Component: AssociationsMember,
  // },
  {
    id: teamAgentSignupSteps.ACCOUNT_COMPLETE,
    Component: AccountCompleteScreen,
  },
];

const BUYER_SIGNUP_STEPS = [
  {
    id: buyerSignupSteps.SUMMARY,
    Component: Summary,
  },
  {
    id: buyerSignupSteps.HOME_PREFS,
    Component: HomePrefsForm,
  },
  {
    id: buyerSignupSteps.LOCATION_PREFS,
    Component: LocationPrefsForm,
  },
  {
    id: buyerSignupSteps.WISHLIST,
    Component: StageMustHavePrefsForm,
  },
  {
    id: buyerSignupSteps.ABOUT,
    Component: AboutForm,
  },
  {
    id: buyerSignupSteps.AUTO_COMPLETE,
    Component: AccountCompleteScreen,
  },
];

const SELLER_SIGNUP_STEPS = [
  {
    id: sellerSignupSteps.ABOUT,
    Component: AboutForm,
  },
  {
    id: sellerSignupSteps.MAILING_ADDRESS,
    Component: MailingAddressForm,
  },
  {
    id: sellerSignupSteps.AUTO_COMPLETE,
    Component: AccountCompleteScreen,
  },
];

const RETENTION_CLIENT_SIGNUP_STEPS = [
  {
    id: sellerSignupSteps.ABOUT,
    Component: AboutForm,
  },
  {
    id: sellerSignupSteps.MAILING_ADDRESS,
    Component: MailingAddressForm,
  },
  {
    id: sellerSignupSteps.AUTO_COMPLETE,
    Component: AccountCompleteScreen,
  },
];

const THIRD_PARTY_SIGNUP_STEPS = [
  {
    id: thirdPartySignupSteps.ABOUT,
    Component: AboutForm,
  },
  {
    id: thirdPartySignupSteps.ORGANIZATION,
    Component: OrganizationDetailsForm,
  },
  {
    id: thirdPartySignupSteps.UPLOAD_PHOTO,
    Component: ProfilePhotoForm,
  },
  {
    id: thirdPartySignupSteps.VENDOR_DIRECTORY,
    Component: VendorDirectoryForm,
  },
  {
    id: thirdPartySignupSteps.AREAS_OF_OPERATION,
    Component: AreasOfOperationPartnerForm,
  },
];

const PARTNER_ONBOARDING_STEPS = [
  {
    id: partnerOnboardingSteps.ABOUT,
    Component: PartnerAboutForm,
  },
  {
    id: partnerOnboardingSteps.BUSINESS_PROFILE,
    Component: BusinessProfileForm,
  },
  {
    id: partnerOnboardingSteps.BUSINESS_ADDRESS,
    Component: BusinessAddressForm,
  },
  {
    id: partnerOnboardingSteps.BUSINESS_CONTACT,
    Component: BusinessContactForm,
  },
  {
    id: partnerOnboardingSteps.DESCRIPTION,
    Component: DescriptionForm,
  },
  {
    id: partnerOnboardingSteps.SERVICE,
    Component: ServiceForm,
  },
  {
    id: partnerOnboardingSteps.AREAS_SERVED,
    Component: AreasServedForm,
  },
  {
    id: partnerOnboardingSteps.UPLOAD_BUSINESS_PHOTO,
    Component: BusinessPhotoForm,
  },
  {
    id: partnerOnboardingSteps.UPLOAD_PHOTO,
    Component: PartnerProfilePhotoForm,
  },
  {
    id: partnerOnboardingSteps.NOTIFICATION,
    Component: NotificationForm,
  },
  {
    id: partnerOnboardingSteps.VENDOR_DIRECTORY,
    Component: PartnerVendorDirectoryForm,
  },
  {
    id: partnerOnboardingSteps.ACCOUNT_COMPLETE,
    Component: PartnerCompleteScreen,
  },
];

Middle.propTypes = {
  className: PropTypes.string,
};

Middle.defaultProps = {
  className: '',
};

export default Middle;
