import React from 'react';
import { notificationTypes } from './notifications';
import moment from 'moment';

export const InAppNotificationsTemplates = {
  // INFO: p stands for payload
  [notificationTypes.NewListingAgent]: {
    TextMarkup: (p) => {
      return (
        <>
          There{' '}
          {p.IsASingleListingMatch ? (
            <>
              is <StrongTitle title="a new listing" /> that matches
            </>
          ) : (
            <>
              are <StrongTitle title={`${p.PropertyCount || p.Properties?.length} new listings`} />{' '}
              that match
            </>
          )}{' '}
          the search criteria for{' '}
          <StrongTitle title={p.ClientName || `${p.ClientFirstName} ${p.ClientLastName}`} />.{' '}
          {p.MinMatchScore === p.MaxMatchScore || p.MatchScore ? (
            <>
              Match scores are <StrongTitle title={`${p.MinMatchScore || p.MatchScore}%`} />
            </>
          ) : (
            <>
              Match scores range from{' '}
              <StrongTitle title={`${p.MinMatchScore}% - ${p.MaxMatchScore}%`} />
            </>
          )}
          .
        </>
      );
    },
  },
  [notificationTypes.NewListingWithHighMatchscoreAgent]: {
    TextMarkup: (p) => (
      <>
        There{' '}
        {p.IsASingleListingMatch ? (
          <>
            is <StrongTitle title="a new listing" /> that matches
          </>
        ) : (
          <>
            are <StrongTitle title={`${p.PropertyCount} new listings`} /> that match
          </>
        )}{' '}
        the search criteria for <StrongTitle title={p.ClientName} />.{' '}
        {p.MinMatchScore === p.MaxMatchScore ? (
          <>
            Match scores are <StrongTitle title={`${p.MinMatchScore}%`} />
          </>
        ) : (
          <>
            Match scores range from{' '}
            <StrongTitle title={`${p.MinMatchScore}% - ${p.MaxMatchScore}%`} />
          </>
        )}
        .
      </>
    ),
  },
  [notificationTypes.NewListingClient]: {
    TextMarkup: (p) => (
      <>
        There{' '}
        {p.IsASingleListingMatch ? (
          <>
            is <StrongTitle title="a new listing" /> that matches
          </>
        ) : (
          <>
            are <StrongTitle title={`${p.PropertyCount} new listings`} /> that match
          </>
        )}{' '}
        your search criteria.{' '}
        {p.MinMatchScore === p.MaxMatchScore ? (
          <>
            Match scores are <StrongTitle title={`${p.MinMatchScore}%`} />
          </>
        ) : (
          <>
            Match scores range from{' '}
            <StrongTitle title={`${p.MinMatchScore}% - ${p.MaxMatchScore}%`} />
          </>
        )}
        .
      </>
    ),
  },
  [notificationTypes.NewListingWithCustomMatchscoreClient]: {
    TextMarkup: (p) => (
      <>
        There{' '}
        {p.IsASingleListingMatch ? (
          <>
            is <StrongTitle title="a new listing" /> that matches
          </>
        ) : (
          <>
            are <StrongTitle title={`${p.PropertyCount} new listings`} /> that match
          </>
        )}{' '}
        your search criteria.{' '}
        {p.MinMatchScore === p.MaxMatchScore ? (
          <>
            Match scores are <StrongTitle title={`${p.MinMatchScore}%`} />
          </>
        ) : (
          <>
            Match scores range from{' '}
            <StrongTitle title={`${p.MinMatchScore}% - ${p.MaxMatchScore}%`} />
          </>
        )}
        .
      </>
    ),
  },
  [notificationTypes.NewMessage]: {
    TextMarkup: (p) => (
      <>
        {p.Count > 1 ? (
          <>
            <StrongTitle title={p.Count} /> new
          </>
        ) : (
          <>New</>
        )}{' '}
        message{`${p.Count > 1 ? 's' : ''}`} from{' '}
        <StrongTitle
          title={`${p.SenderFirstName} ${p.SenderLastName ? ` ${p.SenderLastName}` : ''}`}
        />
        .
      </>
    ),
  },
  [notificationTypes.NewTaskSelf]: {
    TextMarkup: (p) =>
      p.Count > 1 ? (
        <>
          <StrongTitle title={p.Count} /> new tasks were assigned to you.
        </>
      ) : (
        <>
          <StrongTitle
            title={`${p.Assignor?.FirstName}${
              p.Assignor?.LastName ? ` ${p.Assignor?.LastName}` : ''
            }`}
          />{' '}
          assigned a new task to you: <StrongTitle title={p.Title} />.
        </>
      ),
  },
  [notificationTypes.NewTaskCC]: {
    TextMarkup: (p) =>
      p.Count > 1 ? (
        <>
          You are {ccd} on <StrongTitle title={p.Count} /> new tasks.
        </>
      ) : (
        <>
          You were {ccd} on a new task assigned to{' '}
          <StrongTitle
            title={
              p.Assignee
                ? `${p.Assignee?.FirstName} ${p.Assignee?.LastName}`
                : p?.AssigneeNames || ''
            }
          />
          : {p.Title || p.TaskName}.
        </>
      ),
  },
  [notificationTypes.TaskCompletedSelfAssignee]: {
    TextMarkup: (p) =>
      p.Count > 1 ? (
        <>
          <StrongTitle title={p.Count} /> tasks that were assigned to you are now complete.
        </>
      ) : (
        <>
          <StrongTitle title={`${p.Completer?.FirstName}${getLastName(p.Completer?.LastName)}`} />{' '}
          completed the task assigned to you both: <StrongTitle title={p.Title} />.
        </>
      ),
  },
  [notificationTypes.TaskCompletedSelfAssignor]: {
    TextMarkup: (p) =>
      p.Count > 1 ? (
        <>
          <StrongTitle title={p.Count} /> tasks you assigned are now complete.
        </>
      ) : (
        <>
          <StrongTitle title={`${p.Completer?.FirstName}${getLastName(p.Completer?.LastName)}`} />{' '}
          completed the task you assigned: <StrongTitle title={p.Title} />.
        </>
      ),
  },
  [notificationTypes.TaskCompletedCC]: {
    TextMarkup: (p) =>
      p.Count > 1 ? (
        <>
          <StrongTitle title={p.Count} /> tasks you are {ccd} on are now complete.
        </>
      ) : (
        <>
          <StrongTitle title={`${p.Completer?.FirstName}${getLastName(p.Completer?.LastName)}`} />{' '}
          completed the task you were {ccd} on: <StrongTitle title={p.Title} />.
        </>
      ),
  },
  [notificationTypes.UpcomingDueDateSelfAssignee]: {
    TextMarkup: (p) =>
      p.Count > 1 ? (
        <>
          You have <StrongTitle title={p.Count} /> tasks due soon.
        </>
      ) : (
        <>
          Your task is due in <StrongTitle title={p.RemainingDays} /> days:{' '}
          <StrongTitle title={p.Title} />.
        </>
      ),
  },
  [notificationTypes.UpcomingDueDateSelfAssignor]: {
    TextMarkup: (p) =>
      p.Count > 1 ? (
        <>
          <StrongTitle title={p.Count} /> tasks that you assigned are due soon.
        </>
      ) : (
        <>
          The task you assigned to <StrongTitle title={p.TaskAssignedToName} /> is due in{' '}
          {p.RemainingDays} days: {p.Title}.
        </>
      ),
  },
  [notificationTypes.UpcomingDueDateCC]: {
    TextMarkup: (p) =>
      p.Count > 1 ? (
        <>
          <StrongTitle title={p.Count} /> tasks that you are {ccd} on are due soon.
        </>
      ) : (
        <>
          The task assigned to{' '}
          <StrongTitle title={`${p.Assignee?.FirstName}${getLastName(p.Assignee?.LastName)}`} />{' '}
          that you are {ccd} on is due in <StrongTitle title={p.RemainingDays} /> days:{' '}
          <StrongTitle title={p.Title} />.
        </>
      ),
  },
  [notificationTypes.OverdueTaskSelfAssignee]: {
    TextMarkup: (p) =>
      p.Count > 1 ? (
        <>
          You have <StrongTitle title={p.Count} /> tasks that are overdue.
        </>
      ) : (
        <>
          Your task is overdue: <StrongTitle title={p.TaskName} />. It was due on{' '}
          <StrongTitle title={p.TaskDueDate} />.
        </>
      ),
  },
  [notificationTypes.OverdueTaskSelfAssignor]: {
    TextMarkup: (p) =>
      p.Count > 1 ? (
        <>
          <StrongTitle title={p.Count} /> tasks that you assigned are now overdue.
        </>
      ) : (
        <>
          The task you assigned to
          <StrongTitle title={p.TaskAssignedToName} /> is overdue:{' '}
          <StrongTitle title={p.TaskName} />. It was due on <StrongTitle title={p.TaskDueDate} />.
        </>
      ),
  },
  [notificationTypes.OverdueTaskCC]: {
    TextMarkup: (p) =>
      p.Count > 1 ? (
        <>
          <StrongTitle title={p.Count} /> tasks that you are {ccd} on are now overdue.
        </>
      ) : (
        <>
          The task assigned to <StrongTitle title={p.TaskAssignedToName} /> that you are {ccd} on is
          overdue: <StrongTitle title={p.TaskName} />. It was due on{' '}
          <StrongTitle title={p.TaskDueDate} />.
        </>
      ),
  },
  [notificationTypes.TaskStuckSelfAssignee]: {
    TextMarkup: (p) =>
      p.Count > 1 ? (
        <>
          <StrongTitle title={p.Count} /> tasks that you are assigned to are stuck.
        </>
      ) : (
        <>
          <StrongTitle title={`${p.Assignee?.FirstName} ${p.Assignee?.LastName}`} /> is stuck on a
          task that is also assigned to you: <StrongTitle title={p.Title} />.
        </>
      ),
  },
  [notificationTypes.TaskStuckSelfAssignor]: {
    TextMarkup: (p) =>
      p.Count > 1 ? (
        <>
          <StrongTitle title={p.Count} /> tasks that you assigned are stuck.
        </>
      ) : (
        <>
          <StrongTitle title={`${p.Assignee?.FirstName} ${p.Assignee?.LastName}`} /> is stuck on a
          task that you assigned: <StrongTitle title={p.Title} />.
        </>
      ),
  },
  [notificationTypes.TaskStuckCC]: {
    TextMarkup: (p) =>
      p.Count > 1 ? (
        <>
          <StrongTitle title={p.Count} /> tasks that you are {ccd} on are stuck.
        </>
      ) : (
        <>
          <StrongTitle title={`${p.Assignee?.FirstName} ${p.Assignee?.LastName}`} /> is stuck on a
          task that you were {ccd} on: <StrongTitle title={p.Title} />.
        </>
      ),
  },
  [notificationTypes.NewTaskComment]: {
    TextMarkup: (p) =>
      p.Count > 1 ? (
        <>
          There are <StrongTitle title={p.Count} /> comments on the task{' '}
          <StrongTitle title={p.TaskName} />.
        </>
      ) : (
        <>
          <StrongTitle title={`${p.SenderFirstName} ${p.SenderLastName}`} /> left a comment on the
          task <StrongTitle title={p.TaskName} />.
        </>
      ),
  },
  [notificationTypes.NewTaskCommentTagged]: {
    TextMarkup: (p) =>
      p.Count > 1 ? (
        <>
          Your were tagged in <StrongTitle title={p.Count} /> comments on the task{' '}
          <StrongTitle title={p.TaskName} />.
        </>
      ) : (
        <>
          <StrongTitle title={`${p.FirstName} ${p.LastName}`} /> tagged you in a comment on the task{' '}
          <StrongTitle title={p.Title} />.
        </>
      ),
  },
  [notificationTypes.CounterReceivedExternal]: {
    TextMarkup: (p) => (
      <>
        You have a new counter on <StrongTitle title={`${p.AddressWithoutCityAndState}`} />.
      </>
    ),
  },
  [notificationTypes.SignDocumentRequestPending]: {
    TextMarkup: (p) => {
      return (
        <>
          The request to sign {p.FormName} has been pending for{' '}
          {moment.duration(moment(p.PendingDate).diff(moment())).humanize()}
        </>
      );
    },
  },
  [notificationTypes.CollaborateDocument]: {
    TextMarkup: (p) => {
      return (
        <>
          The request to sign {p.FormName} has been pending for{' '}
          {moment.duration(moment(p.PendingDate).diff(moment())).humanize()}
        </>
      );
    },
  },
};

const getLastName = (lastName: string) => {
  return lastName ? ` ${lastName}` : '';
};

const ccd = <>cc&apos;d</>;

const StrongTitle: React.FC<{ title: string; children?: React.ReactNode }> = ({
  title,
  children,
}) => {
  return (
    <strong>
      {title}
      {children}
    </strong>
  );
};
