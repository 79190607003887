import Api from 'store/effects/core/api';
import { requestGetClientsListAction } from 'store/actions/clientsList';
import { requestPostSendInvite, appResetClientDriverAction } from 'store/actions/app';
import { changeClientStatus, getAgentClientsList, postInviteUser } from 'api/clientsList';
import { getAgentInviteClientSendValues } from 'store/selectors/app';
import { TEAM_OWNER, TEAM_ADMIN, AGENT } from 'settings/constants/roles';
import { ClientCategory } from 'types';

import { getState } from 'store';

export const requestGetClientsListEffect = (cfg?, options: { silent?: boolean } = {}, cb?) => {
  const requestParams = { action: requestGetClientsListAction, method: getAgentClientsList };

  let sendRequest = Api.execBase(requestParams);
  if (options.silent) sendRequest = Api.execResult(requestParams);

  return sendRequest(cfg, options, cb);
};

export const handleSendClientInviteForm = ({ values }, cb) => {
  const sendRequest = Api.execBase({ action: requestPostSendInvite, method: postInviteUser });
  const state = getState();
  const sendValues = getAgentInviteClientSendValues(state, values);
  const role = state?.app?.clientsDrawer?.inviteClientValues?.clientType;
  return sendRequest(sendValues, {}, (err, resp, dispatch) => {
    if (!err) {
      const teamRole = state?.agentTeamDetail?.data?.TeamRole || '';
      const teamAgentIsActive = !!state?.agentTeamDetail?.data?.IsActive;

      const body =
        teamAgentIsActive && [TEAM_OWNER, TEAM_ADMIN].includes(teamRole)
          ? {
              clientId: resp.data.result.Client.Id,
              connectionAssign: {
                showAssignConnection: true,
                connectionAssignType: AGENT,
                isNewlyInvited: true,
              },
            }
          : { open: role !== ClientCategory.Seller && role !== ClientCategory.Both, activeTab: 1 };

      dispatch(appResetClientDriverAction(body));
      dispatch(requestGetClientsListEffect());
      cb?.();
    }
  });
};
