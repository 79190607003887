import { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import PropTypes, { any } from 'prop-types';
import { cloneDeep } from 'lodash-es';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import { getFilteredTasksSelector } from 'store/selectors/transactionTasks';
import { Collapse, Panel } from 'components-antd';
import { ViewTimeline } from 'components/Transactions';
import { PrivacyOptions } from './PrivacyOptions';
import {
  getTransactionSelector,
  getTransactionAccessSelector,
  getTransactionDateControlsSelector,
} from 'store/selectors/transaction';
import { updateTransactionMilestonesEffect } from 'store/effects/transactions';
import { DatePicker, Input } from 'components';
import Point from 'components/Transactions/ViewTimeline/Point';
import Actions from './Actions';
import { timelineDateControls } from 'settings/constants/transaction';
import { getCollapseIcon } from '../Summaries';
import { Lock } from 'components/Icons';
import { getKeyDatesEffect, updateTransactionEffect } from 'store/effects/transactions';
import styles from './styles.module.scss';
import summaryStyles from '../Summaries/styles.module.scss';
import { updateManyTransactionTasks } from 'api/transactions/tasks/update';
import { taskTemplateDateInitials } from 'app-constants/enums/taskTemplateDateInitials';
import { ProjectDateType, WorkshopType } from 'app-constants';
import { getDateOnly, subtractTimeZone } from 'helpers';
// import { convertToDateTime, validateControlOperatorOffset } from 'utils/templatesHelper';
// import { Popover } from 'components-antd';
import DueDate from './DueDate';
import { getAgentTeamIdSelector } from 'store/selectors/agentTeamDetail';
import { TransactionStatus } from 'types';

const contractDateType = 'contractDate';
const closingDateType = 'closingDate';
const listingExpireDateType = 'listingExpireDate';

const sortMilestones = (values) => {
  return values.sort((a, b) => {
    const aDueDate = moment(a.DueDate);
    const bDueDate = moment(b.DueDate);

    if (aDueDate.isSame(bDueDate, 'day')) {
      // If dates are the same, prioritize "Transaction/Project created in Mosaik"
      if (['Project created in Mosaik', 'Transaction created in Mosaik'].includes(a.Title))
        return -1;
      if (['Project created in Mosaik', 'Transaction created in Mosaik'].includes(b.Title))
        return 1;
    }
    // Sort by DueDate
    return aDueDate - bDueDate;
  });
};

// Remove Transaction created in Mosaik milestone for a specific team for Closed transactons
const validateTeamAndTransactionStatusForMilestones = (
  milestones = [],
  transaction,
  isProject,
  teamId,
) => {
  if (
    !isProject &&
    teamId &&
    teamId === 186 &&
    teamId === transaction?.CreatorTeamId &&
    transaction?.Status === TransactionStatus.Closed
  ) {
    return milestones;
  }

  return [
    ...milestones,
    {
      Id: uuidv4(),
      Title: `${isProject ? WorkshopType.Project : WorkshopType.Transaction} created in Mosaik`,
      DueDate: transaction?.CreatedDate
        ? moment(transaction.CreatedDate).format('MM/DD/YYYY')
        : null,
      ViewOnly: true,
    },
  ];
};

const addDateControlMilestones = (milestones, transaction, isProject, teamId) =>
  validateTeamAndTransactionStatusForMilestones(milestones, transaction, isProject, teamId).filter(
    (item) => !!item,
  );

const sortedMilestonesWithDateControls = (transaction, isProject, teamId) => {
  let milestones = sortMilestones(
    addDateControlMilestones(transaction?.Milestones || [], transaction, isProject, teamId),
  );
  milestones = milestones.map((m) => {
    return {
      ...m,
      DueDate: getDateOnly(m.DueDate),
    };
  });
  return milestones;
};
const Timeline = (props) => {
  const { className, isTransactionDrawer, isProject } = props;

  const dispatch = useDispatch();

  const { transaction, isUpdatePending } = useSelector(getTransactionSelector);
  const { fullAccess } = useSelector(getTransactionAccessSelector);
  const teamId = useSelector(getAgentTeamIdSelector);

  const [addMode, setAddMode] = useState(false);
  const [dueDate, setDueDate] = useState('');
  const [controlOperatorOffset, setControlOperatorOffset] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [milestonesValue, setMilestonesValue] = useState(
    sortedMilestonesWithDateControls(transaction, isProject, teamId),
  );
  const [addModeMilestone, setAddModeMilestone] = useState(null);
  const [timelineExpanded, setTimelineExpanded] = useState(false);
  const { tasks } = useSelector(getFilteredTasksSelector);
  const transactionStartDate = transaction?.CreatedDate;
  const transactionEndDate = transaction?.ClosingDate;

  useEffect(() => {
    setMilestonesValue(sortedMilestonesWithDateControls(transaction, isProject, teamId));
  }, [
    transaction?.Milestones,
    transaction?.EffectiveDate,
    transaction?.ClosingDate,
    transaction?.ListingExpireDate,
  ]);

  const onAdd = () => {
    setAddMode(true);
    setDueDate(null);
  };

  const onEdit = () => {
    setEditMode(!editMode);
  };

  const onFieldsChange = (data, index) => {
    setMilestonesValue((prevState) => {
      const clonedMilestones = cloneDeep(prevState);
      clonedMilestones[index] = {
        ...clonedMilestones[index],
        ...data,
      };
      return clonedMilestones;
    });
  };

  const updateTransactionTasksDates = () => {
    if (tasks?.length) {
      //Closing Date
      const closingDateRelatedActiveTasks = tasks.filter(
        (task) =>
          task.TaskTemplateInitial &&
          task.TaskTemplateOperator &&
          task.TaskTemplateDateOffset !== null &&
          task.TaskTemplateInitialOperatorOffset &&
          task.TaskTemplateInitial === taskTemplateDateInitials.ClosingDate &&
          task.TaskTemplateStatus !== 'Done',
      );
      const closingDate = milestonesValue?.find(
        (milestone) => milestone.Type === closingDateType,
      )?.DueDate;
      let closingTasksWithUpdatedDueDates = [];
      if (closingDate) {
        const dateClosing = new Date(closingDate);
        closingTasksWithUpdatedDueDates = closingDateRelatedActiveTasks.map((task) => {
          return {
            Id: task.Id,
            DueDate:
              task.TaskTemplateOperator === '+'
                ? moment(dateClosing)
                    .add(task.TaskTemplateDateOffset, 'days')
                    .utc()
                    .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
                : moment(dateClosing)
                    .subtract(task.TaskTemplateDateOffset, 'days')
                    .utc()
                    .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
          };
        });
      }
      //Contract Date
      const contractDateRelatedActiveTasks = tasks.filter(
        (task) =>
          task.TaskTemplateInitial &&
          task.TaskTemplateOperator &&
          task.TaskTemplateDateOffset !== null &&
          task.TaskTemplateInitialOperatorOffset &&
          task.TaskTemplateInitial === taskTemplateDateInitials.ContractDate &&
          task.TaskTemplateStatus !== 'Done',
      );
      const contractDate = milestonesValue?.find(
        (milestone) => milestone.Type === contractDateType,
      )?.DueDate;
      let contractTasksWithUpdatedDueDates = [];
      if (contractDate) {
        const dateContract = new Date(contractDate);
        contractTasksWithUpdatedDueDates = contractDateRelatedActiveTasks.map((task) => {
          return {
            Id: task.Id,
            DueDate:
              task.TaskTemplateOperator === '+'
                ? moment(dateContract)
                    .add(task.TaskTemplateDateOffset, 'days')
                    .utc()
                    .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
                : moment(dateContract)
                    .subtract(task.TaskTemplateDateOffset, 'days')
                    .utc()
                    .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
          };
        });
      }
      //Concatenate 2 arrays
      let tasksWithUpdatedDueDates = closingTasksWithUpdatedDueDates.concat(
        contractTasksWithUpdatedDueDates,
      );
      //Update API Call
      if (tasksWithUpdatedDueDates?.length) {
        updateManyTransactionTasks({
          transactionId: transaction.Id,
          config: { tasks: tasksWithUpdatedDueDates },
        });
      }
    }
  };

  const onSave = () => {
    const config = {
      milestones: [
        ...milestonesValue,
        ...(addModeMilestone
          ? [
              {
                Title: addModeMilestone.Title,
                DueDate: getDateOnly(moment(dueDate).format('YYYY-MM-DD'), 'YYYY-MM-DD'),
                IsPrivate: addModeMilestone.IsPrivate ?? false,
                ControlOperatorOffset: controlOperatorOffset,
              },
            ]
          : []),
      ].filter((milestone) => !milestone.ViewOnly),
    };

    dispatch(
      updateTransactionMilestonesEffect(config, {}, (err) => {
        if (!err) {
          setEditMode(false);
          setAddMode(false);
          setAddModeMilestone(null);
          if (transaction?.Id) {
            dispatch(getKeyDatesEffect({ transactionId: transaction.Id }, { silent: true }));
          }
        }
      }),
    );

    updateTransactionTasksDates();
  };

  const onCancel = () => {
    setMilestonesValue(sortedMilestonesWithDateControls(transaction, isProject, teamId));
    setEditMode(false);
    setAddMode(false);
    setAddModeMilestone(null);
  };

  const onRemoveMilestone = (index) => {
    const copyMilestones = cloneDeep(milestonesValue);
    copyMilestones.splice(index, 1);
    setMilestonesValue(sortMilestones(copyMilestones));
  };

  const onKeyPress = (event) => {
    // if (event.key === 'Enter') {
    //   onSave();
    // }
  };

  const isActionDisable = () => {
    if (addMode && (!addModeMilestone || !addModeMilestone?.Title || !dueDate)) {
      return true;
    }

    return (milestonesValue || []).some((val) => !val?.Title || !val?.DueDate);
  };

  return (
    <Collapse
      className={summaryStyles.collapse}
      ghost
      expandIcon={(panelProps) => getCollapseIcon(panelProps.isActive, setTimelineExpanded)}
      expandIconPosition="end"
      defaultActiveKey={'timeline'}
    >
      <Panel
        key="timeline"
        header={
          !isTransactionDrawer && (
            <div className={styles.inner}>
              <div className={styles.header}>
                <div className={styles.title}>Timeline</div>
              </div>

              {timelineExpanded && fullAccess && (
                <span onClick={(event) => event.stopPropagation()}>
                  <Actions
                    onAdd={onAdd}
                    onEdit={onEdit}
                    editMode={editMode}
                    addMode={addMode}
                    onSave={onSave}
                    onCancel={onCancel}
                    isUpdatePending={isUpdatePending}
                    milestonesLength={transaction?.Milestones?.length}
                    disabled={isActionDisable()}
                  />
                </span>
              )}
            </div>
          )
        }
      >
        <div testid="timeline" className={classNames(styles.timeline, className)}>
          <div>
            <ViewTimeline
              addMode={addMode}
              editMode={editMode}
              listItems={milestonesValue}
              onFieldsChange={onFieldsChange}
              onRemove={onRemoveMilestone}
              startDate={transactionStartDate}
              endDate={transactionEndDate}
              isAggregated={isTransactionDrawer}
              setDueDate={setDueDate}
            />
          </div>

          {addMode && (
            <div testid="add_mode" className={styles.addMode}>
              <DueDate
                addMode={addMode}
                setDueDate={setDueDate}
                setControlOperatorOffset={setControlOperatorOffset}
              />

              <div className={styles.pointWrapper}>
                <Point milestoneId={1} className={styles.point} />
                <div className={classNames(styles.line, { [styles.addMode]: addMode })} />
              </div>
              <Input
                className={styles.name}
                variant={Input.LIGHT}
                placeholder="Name"
                onChange={(e, val) =>
                  setAddModeMilestone({ ...(addModeMilestone || {}), Title: val })
                }
                onKeyPress={onKeyPress}
                autofocus
                testid="title_input"
                maxLength={255}
              />
              <div className={styles.lockIconContainer}>
                {addModeMilestone?.IsPrivate && <Lock stroke="#FF576D" width="18" height="18" />}
              </div>
              <PrivacyOptions
                isPrivate={addModeMilestone?.IsPrivate}
                onChange={(val) =>
                  setAddModeMilestone({ ...(addModeMilestone || {}), IsPrivate: val })
                }
              />
            </div>
          )}
        </div>
      </Panel>
    </Collapse>
  );
};

Timeline.propTypes = {
  className: PropTypes.string,
  isTransactionDrawer: PropTypes.bool,
  isProject: PropTypes.bool,
  rightSideRef: PropTypes.shape({
    current: PropTypes.shape({
      scrollHeight: PropTypes.number,
    }),
  }),
};

Timeline.defaultProps = {
  className: '',
  isTransactionDrawer: false,
  isProject: false,
  rightSideRef: undefined,
};

export default Timeline;
