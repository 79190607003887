import classNames from 'classnames';
import Button from 'components/Button';
import { useFormik } from 'formik';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getOnBoardingData } from 'pages/OnBoarding/selectors';
import { PENDING } from 'settings/constants/apiState';
import { THIRD_PARTY } from 'settings/constants/roles';
import { routes } from 'settings/navigation/routes';
import { onBoardingSignUpEffect } from 'store/effects';
import { getUserDataSelector, getUserRoleSelector, isPartnerSelector } from 'store/selectors/user';

import { Question } from 'pages/RequestQuote/components';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';

import { OnBoardingContainer } from 'pages/OnBoarding/components/OnBoardingContainer';
import { OnBoardingWrapper } from 'pages/OnBoarding/components/OnBoardingWrapper';
import { OnboardingContinueButton } from '../../agent/components/OnboardingContinueButton';
import styles from './styles.module.scss';

type Props = {
  className: string;
  onNext: () => {};
  stageIndex?: number;
  Controls: React.ElementType;
};

const PartnerVendorDirectoryForm: React.FC<Props> = (props) => {
  const { className, onNext, stageIndex, Controls } = props;
  const dispatch = useDispatch();
  const user = useSelector(getUserDataSelector);
  const userRole = useSelector(getUserRoleSelector);
  const { onBoarding } = useSelector(getOnBoardingData);
  const isPartner = useSelector(isPartnerSelector);
  const history = useHistory();

  const isPending = onBoarding.state === PENDING;
  const onSkip = () => (userRole === THIRD_PARTY ? history.push(routes.index) : onNext());

  const onSubmit = useCallback(
    (values) => {
      dispatch(
        onBoardingSignUpEffect(
          { ...values, stageIndex },
          { isPartner, isCommonInfo: true },
          (err) => {
            if (!err) onNext();
          },
        ),
      );
    },
    [(onNext, stageIndex, dispatch)],
  );

  const formik = useFormik({
    initialValues: {
      DirectoryPreference: null,
    },
    onSubmit,
  });

  return (
    <OnBoardingWrapper isPending={isPending}>
      <Controls
        onNext={() => formik.submitForm()}
        className={styles.controls}
        variant="lightFull"
      />

      <OnBoardingContainer className={styles.container}>
        <Question className={styles.title}>
          Do you want to be added to the Mosaik vendor directory?
        </Question>
        <p className={styles.partnerSubheader}>
          This will make your profile visible to all agents who use Mosaik in your area and who are
          either looking for services that you offer or vendors to recommend to their clients.
          <br />
          <br />
          There are no costs or fees associated with inclusion in the directory.
        </p>
        <AnswersContainer className={styles.answersContainer}>
          <Button
            className={classNames(styles.button, {
              [styles.active]: formik.values?.DirectoryPreference === true,
            })}
            title="Yes"
            onClick={() => formik.setFieldValue('DirectoryPreference', true)}
          ></Button>
          <Button
            className={classNames(styles.button, {
              [styles.active]: formik.values?.DirectoryPreference === false,
            })}
            title="No"
            onClick={() => formik.setFieldValue('DirectoryPreference', false)}
          ></Button>
        </AnswersContainer>
        <ButtonsContainer>
          <OnboardingContinueButton
            isPending={isPending}
            disabled={formik.values?.DirectoryPreference === null}
            testid="next"
            htmlType="submit"
            className={classNames(styles.submitBtn, {
              [styles.active]: formik.values?.DirectoryPreference !== null,
            })}
            onClick={() => formik.submitForm()}
          />
        </ButtonsContainer>
      </OnBoardingContainer>
    </OnBoardingWrapper>
  );
};

export { PartnerVendorDirectoryForm };
