import { HTMLAttributes, ReactElement } from 'react';
import classNames from 'classnames';
import { Header } from 'antd/lib/layout/layout';

import { Footer as CommonFooter, Layout } from 'components-antd';

import styles from './styles.module.scss';

const { Content } = Layout;

interface PageWrapperProps extends HTMLAttributes<HTMLElement> {
  SiderComponent?: any;
  RightSiderComponent?: any;
  showFooter?: boolean;
  Footer?: any;
  mainPageContentStyle?: string;
  version?: number;
  footerStyles?: string;
  ToolBar?: ReactElement;
  bgWhite?: boolean;
  bgGray?: boolean;
  pageContentClassName?: string;
}

export const PageWrapper = ({
  SiderComponent,
  RightSiderComponent,
  showFooter = true,
  Footer,
  children,
  mainPageContentStyle,
  footerStyles,
  ToolBar,
  version = 1,
  bgWhite = false,
  bgGray = false,
  pageContentClassName = '',
}: PageWrapperProps) => (
  <Layout
    hasSider={SiderComponent || RightSiderComponent ? true : false}
    className={classNames(styles.pageContainer, styles[`pageContainerV${version}`], {
      [styles.bgWhite]: bgWhite,
      [styles.bgGray]: bgGray,
    })}
  >
    {SiderComponent ? <SiderComponent /> : null}
    <Layout className={styles.contentContainer}>
      {ToolBar ? <Header className={styles.toolBar}>{ToolBar}</Header> : <></>}
      <Content
        className={classNames(styles.pageContent, pageContentClassName)}
        itemID="main-page-content"
      >
        <div className={classNames(styles.mainPageContainer, mainPageContentStyle)}>{children}</div>
        {showFooter &&
          (Footer ? (
            <Footer />
          ) : (
            <CommonFooter className={classNames(styles.footer, footerStyles)} />
          ))}
      </Content>
    </Layout>
    {RightSiderComponent ? <RightSiderComponent /> : null}
  </Layout>
);
