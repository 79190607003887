import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { getTransactionRolesSelector } from 'store/selectors/transactionRoles';
import { updateTransactionAgentsCoordinatorsEffect } from 'store/effects';

import { ContactField } from 'pages/Workshop/Transactions/components/ContactsView/ContactModalForm/ContactField';
import { PhoneNumber, InputLabel, enforceFormat, formatToPhone } from 'components';
import { Button, Col, Modal, Row } from 'components-antd';
import { Checkbox } from 'antd';

import { Role } from 'pages/Workshop/Transactions/components/ContactsView/ContactModalForm/Role';
import { createTransactionContactParticipant } from 'api/transactions';
import { useParams } from 'react-router-dom';
import { showErrorMessage } from 'helpers';

import styles from './styles.module.scss';

const initialContactData = {
  FirstName: '',
  LastName: '',
  Email: '',
  Phone: '',
  Role: '',
  Invite: false,
  RoleId: '',
};

export const AddParticipantModal = ({
  itemToPrefill,
  isOpen,
  onCloseModal,
  className = '',
  callback,
  fieldsDisable = false,
  isAgentInfo = false,
  isCoordinatorInfo = false,
  isBuyerTransaction = false,
  isSellerTransaction = false,
  isUpdateModalMode = false,
}) => {
  const params: any = useParams();
  const dispatch = useDispatch();
  const [isPending, setIsPending] = useState(false);
  const [isEmailFieldDisable, setIsEmailFieldDisable] = useState(false);
  const { transactionRoles } = useSelector(getTransactionRolesSelector);

  const onSubmit = async (values) => {
    if (isUpdateModalMode) {
      setIsPending(true);
      dispatch(
        updateTransactionAgentsCoordinatorsEffect(
          {
            transactionId: params?.id,
            id: itemToPrefill?.invitedOrUninvitedParticipantId,
            isParticipant: itemToPrefill?.isParticipant,
            roleId: values?.RoleId,
          },
          (err) => {
            if (!err) {
              callback();
              onCloseModal();
              setIsPending(false);
            }
            setIsPending(false);
          },
        ),
      );
    } else {
      try {
        setIsPending(true);
        const payload = {
          id: params?.id,
          FirstName: values?.FirstName,
          LastName: values?.LastName,
          Email: values?.Email || '',
          RoleId: values?.RoleId,
          Invite: values?.Invite,
          ...(values?.Phone ? { Phone: values?.Phone } : {}),
        };
        const resp = await createTransactionContactParticipant(payload);
        setIsPending(false);
        callback(resp?.data?.result);
        onCloseModal();
      } catch (e) {
        showErrorMessage(e);
        setIsPending(false);
      } finally {
        setIsPending(false);
      }
    }
  };

  useEffect(() => {
    const values = formik.initialValues;

    if (itemToPrefill) {
      formik.setValues({
        ...values,
        Email: itemToPrefill?.Email || '',
        FirstName: itemToPrefill?.FirstName,
        LastName: itemToPrefill?.LastName,
        Phone: itemToPrefill?.Phone,
        ...(isUpdateModalMode
          ? {
              Role:
                transactionRoles?.find((item) => item?.Title === itemToPrefill?.Role)
                  ?.DisplayTitle || '',
              RoleId: transactionRoles?.find((item) => item?.Title === itemToPrefill?.Role)?.Id,
            }
          : {}),
      });
      setIsEmailFieldDisable(itemToPrefill?.Email);
    }
  }, [itemToPrefill]);

  const getValidationSchema = (values) =>
    Yup.object().shape({
      FirstName: Yup.string().required('First Name is a required field'),
      LastName: Yup.string().required('Last Name is a required field'),
      Role: Yup.string().required('Role is a required field'),
      Email: values.Invite
        ? Yup.string().required('Email is a required field').email('Invalid Email')
        : Yup.string().nullable(), // Email is not required if Invite is false
      Phone: Yup.string().nullable(),
    });

  const formik = useFormik({
    initialValues: initialContactData,
    validationSchema: Yup.lazy((values) => getValidationSchema(values)),
    validateOnChange: true,
    onSubmit,
  });

  const insertRole = (val, roleId?) => {
    const inputValue = (val || '').trim();

    if (inputValue !== '') {
      formik.setFieldValue('Role', val);
    }
  };

  const onBlurRoleInput = (event) => {
    insertRole(event.target.value);
  };

  const onRoleKeyPress = (event) => {
    if (event.key === 'Enter') {
      const customRoleValue = event.target.value.trim();
      insertRole(customRoleValue);
      formik.setFieldValue('Role', customRoleValue);
      event.target.blur();
    }
  };

  return (
    <Modal
      open={isOpen}
      width={675}
      footer={null}
      onCancel={onCloseModal}
      maskClosable={false}
      destroyOnClose
      className={styles.addContactModal}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className={classNames(styles.content, className)}>
          <p className={styles.title}>{isUpdateModalMode ? 'Update' : 'Add'}</p>
          <Row gutter={24}>
            <Col span={12}>
              <ContactField
                value={formik.values.FirstName}
                label="First Name"
                onChange={(val) => formik.setFieldValue('FirstName', val)}
                error={formik.touched.FirstName ? formik.errors.FirstName : ''}
                disabled={fieldsDisable}
                required
              />
            </Col>
            <Col span={12}>
              <ContactField
                value={formik.values.LastName}
                label="Last Name"
                onChange={(val) => formik.setFieldValue('LastName', val)}
                error={formik.touched.LastName ? formik.errors.LastName : ''}
                disabled={fieldsDisable}
                required
              />
            </Col>
            <Col span={24}>
              <ContactField
                value={formik.values.Email}
                label="Email"
                onChange={(val) => formik.setFieldValue('Email', val)}
                error={formik.touched.Email ? formik.errors.Email : ''}
                disabled={isEmailFieldDisable}
                required={formik.values.Invite}
              />
            </Col>
            <Col span={24}>
              <InputLabel label="Phone Number" className={styles.phoneLabel} />
              <PhoneNumber
                className={styles.phoneField}
                value={formik.values.Phone}
                onUpdate={(val) => formik.setFieldValue('Phone', val)}
                onKeyDown={enforceFormat}
                onKeyUp={formatToPhone}
                error={formik.touched.Phone ? formik.errors.Phone : ''}
                disabled={fieldsDisable}
              />
            </Col>
            <Col span={24}>
              <div className={styles.rolesWrapper}>
                <Role
                  value={formik.values.Role}
                  onChange={(val) => {
                    formik.setFieldValue('RoleId', val?.roleId);
                    formik.setFieldValue('Role', val?.name);
                  }}
                  className={styles.roleField}
                  error={formik.touched.Role ? formik.errors.Role : ''}
                  onBlurRoleInput={onBlurRoleInput}
                  onRoleKeyPress={onRoleKeyPress}
                  isAgentInfo={isAgentInfo}
                  isCoordinatorInfo={isCoordinatorInfo}
                  isBuyerTransaction={isBuyerTransaction}
                  isSellerTransaction={isSellerTransaction}
                  required
                />
              </div>
            </Col>
            {!isUpdateModalMode && (
              <Col span={24}>
                <Checkbox
                  onChange={(e) => formik.setFieldValue('Invite', e.target.checked)}
                  className={classNames(styles.checkbox, 'mosaikCheckbox')}
                  value={formik.values.Invite}
                  checked={formik.values.Invite}
                >
                  Invite to Transaction
                </Checkbox>
              </Col>
            )}
          </Row>
          <div className={classNames(styles.footerButton, styles.addFooterButton)}>
            <div className={classNames(styles.footerBtnDiv)}>
              <Button
                className={classNames(styles.btn, styles.addBtn, {
                  [styles.addBtnDisabled]: isPending || !formik.values?.RoleId,
                })}
                disabled={isPending || !formik.values?.RoleId}
                loading={isPending}
                variant="secondary"
                htmlType="submit"
              >
                <span>
                  {isUpdateModalMode ? 'Update' : formik.values.Invite ? 'Invite' : 'Add'}
                </span>
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};
