import * as yup from 'yup';

export const schema = yup.object().shape({
  addresses: yup.array().of(
    yup.object().shape({
      address: yup.object().required('Location is a required field'),
      notes: yup.string(),
      radius: yup.number().required('Radius is a required field'),
      closingDate: yup.date(),
    }),
  ),
});
