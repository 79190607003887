import type { ColumnsType } from 'antd/es/table';
import classNames from 'classnames';
import moment from 'moment';

import { ArrowDownFull } from 'components/Icons';
import { Avatar } from 'components';
import { transactionRoles, Role } from 'settings/constants/roles';
import { Icons } from 'pages/Workshop/Icons';
import { displayListedStatus, listedStatusesArray } from 'settings/constants/transaction';

import styles from './styles.module.scss';
import { getInitials } from 'utils';
import NumberFormat from 'react-number-format';
import { formatCurrency } from 'pages/Workshop/Transactions/components/TransactionsAndProjectsTable/helper';
import { NamesContainer } from 'pages/Workshop/Transactions/components/NamesContainer';
import Notifications from 'pages/Workshop/Transactions/components/Notifications/Notifications';
import { TRANSACTION_STATUSES } from 'tests/ui/constants/ui_const.mjs';

interface DataType {
  Id: number;
  Property: {
    Address: {
      City?: string;
      Line1: string;
      PlaceName: string;
      ProviderPlaceId?: string;
      State?: string;
      Zip?: string;
    };
  };
  AgentsOrOwners: string[];
  Coordinators: string[];
  TransactionClients: string[];
  Name: string;
  Status: string;
  ProjectKickoffDate: string;
  ProjectCompletionDate: string;
  ProjectCategory: string;
  address: string;
  price: number;
  closingDate: string;
  isAlertVisible: boolean;
}

const sortableColumnTitle =
  (titleLabel: string, key: string) =>
  ({ sortColumns }) => {
    const sortedColumn = sortColumns?.find(({ column }) => column.key === key);
    const sortAsc = sortedColumn?.order === 'ascend';
    return (
      <div className={styles.sortedColumnTitle}>
        {titleLabel}
        {sortedColumn?.order && (
          <ArrowDownFull
            className={classNames(styles.sorterIcon, { [styles.sorterUp]: sortAsc })}
          />
        )}
      </div>
    );
  };

export function inferRoleCategory(row): string {
  const roles = row?.RepresentingRoles || [];
  return roles.includes(transactionRoles.SELLER) && roles.includes(transactionRoles.BUYER)
    ? 'Dual'
    : roles.includes(transactionRoles.SELLER)
    ? 'Listing'
    : 'Purchase';
}

const EmptyPlaceholderAvatar = () => (
  <div className={styles.clientField}>
    <Avatar avatarClassName={styles.avatar} src={null} placeholder={null} />
    <span>None</span>
  </div>
);

function inferStatus(row): string {
  return listedStatusesArray.includes(row?.status) ? displayListedStatus[row?.status] : row?.status;
}

export function tableColumns(): ColumnsType<DataType> {
  const columns: ColumnsType<DataType> = [
    {
      title: sortableColumnTitle('Transaction', 'transaction'),
      key: 'transaction',
      dataIndex: 'address',
      render: (_, { Property }) => {
        const address = Property?.Address;
        return (
          <div className={styles.transaction}>
            <Icons variant={Icons.OFFER} className={styles.icon} />
            <div className={styles.info}>
              <span className={styles.line1}>{address?.Line1}</span>
              <span className={styles.line2}>
                {address?.City + `, ` + address?.State + ` ` + address?.Zip}
              </span>
            </div>
          </div>
        );
      },
      sorter: (a, b) => a.address?.localeCompare(b.address),
    },
    // {
    //   title: sortableColumnTitle('Price', 'price'),
    //   key: 'price',
    //   dataIndex: 'price',
    //   render: (price) => (
    //     <div className={styles.priceText} title={formatCurrency(price, '$')}>
    //       <NumberFormat thousandSeparator displayType="text" value={price} prefix="$" />
    //     </div>
    //   ),
    //   sorter: (a, b) => a.price - b.price,
    // },
    {
      title: sortableColumnTitle('Type', 'category'),
      key: 'category',
      sorter: (a, b) => inferStatus(a).localeCompare(inferStatus(b)),
      render: (_, row) => (
        <div>
          <p className={styles.roleCategory}>{inferRoleCategory(row)}</p>
          <p className={styles.status}>{inferStatus(row)}</p>
        </div>
      ),
    },
    {
      title: 'Agent(s)',
      key: 'agent',
      dataIndex: 'name',
      render: (_, { AgentsOrOwners }) => {
        if (!AgentsOrOwners?.length) return <span>-</span>;

        return (
          <div className={styles.clientField}>
            <div>
              <NamesContainer names={AgentsOrOwners} maxDisplayCount={1} isWidthRestrict />
            </div>
          </div>
        );
      },
    },
    // {
    //   title: 'Coordinator(s)',
    //   key: 'coordinator',
    //   dataIndex: 'coordinator',
    //   render: (_, { Coordinators }) => {
    //     if (!Coordinators?.length) return <span>-</span>;

    //     return (
    //       <div className={styles.clientField}>
    //         <div>
    //           <NamesContainer names={Coordinators} maxDisplayCount={1} isWidthRestrict />
    //         </div>
    //       </div>
    //     );
    //   },
    // },
    {
      title: sortableColumnTitle('Closing/Expires', 'closingDate'),
      key: 'closingDate',
      dataIndex: 'closingDate',
      render: (endDate, { Status }) => {
        if (
          endDate &&
          ![TRANSACTION_STATUSES.CLOSED, TRANSACTION_STATUSES.CANCELED].includes(Status) &&
          new Date(endDate) < new Date()
        ) {
          return <span className={styles.closeClosingDate}>{endDate}</span>;
        } else {
          return <span>{endDate}</span>;
        }
      },
      sorter: (a, b) => {
        if (a.closingDate === 'N/A') return 1;
        if (b.closingDate === 'N/A') return -1;

        return moment(a.closingDate).diff(moment(b.closingDate), 'days');
      },
      defaultSortOrder: 'ascend',
    },
    {
      title: '',
      key: 'notifications',
      dataIndex: 'notifications',
      render: (_, row) =>
        row?.isAlertVisible && <Notifications transactionData={row} userRole={Role.Agent} />,
    },
  ];

  return columns;
}
