import { useMemo, useState } from 'react';
import classNames from 'classnames';

import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Modal } from 'components-antd';
import { Button } from 'components-antd';
import { Avatar } from 'components';
import { Checkbox } from 'antd';
import { Role } from 'pages/Workshop/Transactions/components/ContactsView/ContactModalForm/Role';
import {
  deleteTransactionContactEffect,
  removeTransactionParticipantEffect,
  updateTransactionAgentsCoordinatorsEffect,
} from 'store/effects';
import { getInitials } from 'utils';

import styles from './styles.module.scss';

export const AgentCoordinatorPrompt = ({ item, open, onClose, refetchAgentsCoordinators }) => {
  const params: { id?: string } = useParams();
  const [changeRoleCheckbox, setChangeRoleCheckbox] = useState(false);
  const [removeCheckbox, setRemoveCheckbox] = useState(false);
  const [roleId, setRoleId] = useState(null);
  const [role, setRole] = useState('');
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();

  const insertRole = (val, roleId?) => {
    const inputValue = (val || '').trim();

    if (inputValue !== '') {
      setRole(val);
    }
  };

  const onBlurRoleInput = (event) => {
    insertRole(event.target.value);
  };

  const onRoleKeyPress = (event) => {
    if (event.key === 'Enter') {
      const customRoleValue = event.target.value.trim();
      insertRole(customRoleValue);
      setRole(customRoleValue);
      event.target.blur();
    }
  };

  const isUpdateButtonDisabled = useMemo(() => {
    if ((!removeCheckbox && !changeRoleCheckbox) || (changeRoleCheckbox && !roleId)) {
      return true;
    }
  }, [removeCheckbox, changeRoleCheckbox, roleId]);

  const onDelete = () => {
    setIsPending(true);
    if (!item?.isParticipant) {
      dispatch(
        deleteTransactionContactEffect(
          {
            id: params?.id,
            contactId: item?.value,
          },
          (err) => {
            if (!err) {
              refetchAgentsCoordinators();
              closeModal();
            }
            setIsPending(false);
          },
        ),
      );
    } else {
      const config = { transactionId: params?.id, participantId: item?.participantId };
      dispatch(
        removeTransactionParticipantEffect(config, {}, (err, response) => {
          if (!err) {
            refetchAgentsCoordinators();
            closeModal();
          }
          setIsPending(false);
        }),
      );
    }
  };

  const updateRole = async () => {
    setIsPending(true);
    dispatch(
      updateTransactionAgentsCoordinatorsEffect(
        {
          transactionId: params?.id,
          id: item?.value,
          isParticipant: item?.isParticipant,
          roleId: roleId,
        },
        (err) => {
          if (!err) {
            refetchAgentsCoordinators();
            closeModal();
            setIsPending(false);
          }
          setIsPending(false);
        },
      ),
    );
  };

  const onUpdate = () => {
    if (removeCheckbox) onDelete();
    else updateRole();
  };

  const closeModal = () => {
    onClose();
    setRemoveCheckbox(false);
    setChangeRoleCheckbox(false);
    setRole('');
    setRoleId(null);
  };

  return (
    <Modal
      open={open}
      width={525}
      footer={null}
      onCancel={closeModal}
      className={styles.agentCoordinatorPromptModal}
      cancelButtonStyles={''}
      maskClosable={false}
      destroyOnClose={true}
    >
      <div className={styles.agentCoordinatorPromptContainer}>
        <div className={styles.header}>
          <Avatar
            avatarClassName={styles.avatar}
            src={item?.AvatarUrl}
            placeholder={<div className={styles.avatarPlaceholder}>{getInitials(item?.label)}</div>}
          />
        </div>
        <div className={styles.content}>
          <p className={styles.questionTitle}>
            Which action would you like to take for {item?.label}?
          </p>
          <div>
            <Checkbox
              onChange={(e) => {
                setChangeRoleCheckbox(e.target.checked);
                if (e.target.checked) {
                  setRemoveCheckbox(false);
                }
              }}
              className={classNames(styles.checkbox, 'mosaikCheckbox')}
              value={changeRoleCheckbox}
              checked={changeRoleCheckbox}
            >
              <span className={styles.label}>Change Role</span>
            </Checkbox>
          </div>
          {changeRoleCheckbox && (
            <Role
              value={role}
              onChange={(val) => {
                setRoleId(val?.roleId);
                setRole(val?.name);
              }}
              className={styles.roleField}
              onBlurRoleInput={onBlurRoleInput}
              onRoleKeyPress={onRoleKeyPress}
              placeholder="Select Role"
              hideLabel
            />
          )}
          <div>
            <Checkbox
              onChange={(e) => {
                setRemoveCheckbox(e.target.checked);
                if (e.target.checked) {
                  setChangeRoleCheckbox(false);
                  setRoleId(null);
                  setRole('');
                }
              }}
              className={classNames(styles.checkbox, 'mosaikCheckbox')}
              value={removeCheckbox}
              checked={removeCheckbox}
            >
              <span className={styles.label}>Remove from Transaction</span>
            </Checkbox>
          </div>
        </div>
        <div className={styles.footer}>
          <Button variant="hollow-bordered" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            variant="secondary"
            disabled={isUpdateButtonDisabled}
            className={classNames({ [styles.btnDisabled]: isUpdateButtonDisabled })}
            onClick={onUpdate}
            loading={isPending}
          >
            Update
          </Button>
        </div>
      </div>
    </Modal>
  );
};
