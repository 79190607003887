import { Row, Col, Button } from 'components-antd';

import styles from './styles.module.scss';

export const ClauseFormFooter = ({ isPending, viewMode }) => {
  return (
    <div className={styles.footerContainer}>
      <Row>
        <div className={styles.separator}></div>
      </Row>
      <Row justify="space-between">
        <Col>
          <Button variant="hollow-bordered" className={styles.footerButton} htmlType="reset">
            Cancel
          </Button>
        </Col>
        {!viewMode && (
          <Col>
            <Button
              variant="secondary"
              className={styles.footerButton}
              htmlType="submit"
              loading={isPending}
            >
              Save
            </Button>
          </Col>
        )}
      </Row>
    </div>
  );
};
