import { useCallback, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { CheckboxValueType } from 'antd/es/checkbox/Group';

import { InviteHeader } from 'components/ClientsDrawer/Headers';
import FormTitle from 'components/FormTitle';
import Footer from 'components/ClientsDrawer/Footer';
import RadioOptions from 'components/Form/RadioOptions';
import { OptionGroup } from 'pages/Settings/Notifications/components/OptionGroup';

import {
  getActiveClientPreApproval,
  getActiveClientTransactionInstance,
  getInvitedClientRole,
  getInvitedClientValues,
} from 'store/selectors/app';
import { YesNoOptions } from 'types/inviteClient';
import { NotificationSubVariants, mapItemTitles } from 'settings/constants/notifications';
import { ClientCategory } from 'types';
import {
  firstStepInputGroup,
  getInitialValues,
  getTheFirstStepOptions,
  getTheSecondStepOptions,
} from './helpers';

import styles from './styles.module.scss';
import { handleSendClientInviteForm } from 'store/effects/clientsList';
import { useHistory } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';

const enableNotificationsOptions = [
  {
    id: YesNoOptions.Yes,
    label: YesNoOptions.Yes,
    value: YesNoOptions.Yes,
  },
  {
    id: YesNoOptions.No,
    label: YesNoOptions.No,
    value: YesNoOptions.No,
  },
];

export const InviteClientNotifications = ({ onNext, stageIndex, onPrev }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { firstName } = useSelector(getInvitedClientValues);
  const activeClientTransactionInstance = useSelector(getActiveClientTransactionInstance);
  const {
    isPreApproved: isClientPreApproved,
    isManualDataInput,
    ...activeClientPreApproval
  } = useSelector(getActiveClientPreApproval);
  const role = useSelector(getInvitedClientRole);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    (values) => {
      setLoading(true);
      let notificationPreferences;
      if (values.isEnabled === YesNoOptions.Yes) {
        notificationPreferences = JSON.parse(JSON.stringify(values.NotificationPreferences));

        const propertyNotificationItems = notificationPreferences[0].NotificationItems;
        const newListingNotification = propertyNotificationItems.find((notification) =>
          notification.Text.includes('There is a new listing that matches'),
        );
        const suggestingNotification = propertyNotificationItems.find((notification) =>
          notification.Text.includes('I suggest a property for'),
        );
        // const priceDownNotification = propertyNotificationItems.find((notification) =>
        //   notification.Text.includes('There is a price reduction on a listing'),
        // );

        // Setting text values for Client side
        newListingNotification.Text = 'There is a new listing that matches my search criteria.';
        newListingNotification.SecondSubText = 'Let me know:';

        suggestingNotification.Text = 'My agent suggests a property.';
        delete suggestingNotification.SubText;

        // priceDownNotification.SecondSubText = 'Let me know:';
      }

      const bothRoles = [ClientCategory.Buyer, ClientCategory.Seller].every((value) =>
        role.includes(value),
      );

      const body = {
        ...(bothRoles ? activeClientTransactionInstance : {}),
        ...(values.isEnabled ? { NotificationPreferences: notificationPreferences } : {}),
      };
      dispatch(
        handleSendClientInviteForm({ values: body }, () => {
          if (bothRoles) history.push(routes.transactionCreate);
          setLoading(false);
        }),
      );
    },
    [activeClientTransactionInstance, activeClientPreApproval, isClientPreApproved],
  );

  const formik = useFormik({
    initialValues: getInitialValues(firstName),
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const handleIsEnabledChange = useCallback(
    (value) => {
      formik.setFieldValue('isEnabled', value);
    },
    [formik.setFieldValue],
  );

  const handleVariantChange = useCallback(
    ({ notificationItemIndex, checkedValues }) => {
      const notificationPreferences = JSON.parse(
        JSON.stringify(formik.values.NotificationPreferences),
      );
      const group = notificationPreferences[0];
      const notificationItem = group.NotificationItems[notificationItemIndex];
      const variants = notificationItem.Variants;

      Object.keys(variants).forEach((variant) => {
        variants[variant] = !!checkedValues.includes(variant);
      });

      formik.setFieldValue('NotificationPreferences', notificationPreferences);
    },
    [formik.values.NotificationPreferences, formik.setFieldValue],
  );

  const handleChangeSubVariant = useCallback(
    ({ notificationItemIndex, checkedValues, inputValue, selectedValue }) => {
      const notificationPreferences = JSON.parse(
        JSON.stringify(formik.values.NotificationPreferences),
      );
      const group = notificationPreferences[0];
      const notificationItem = group.NotificationItems[notificationItemIndex];
      const subVariants = notificationItem.SubVariants;
      const newSubVariants = subVariants.map((subVariant) => ({
        ...subVariant,
        ...(checkedValues ? { Checked: checkedValues.includes(subVariant.Key) } : {}),
        ...(inputValue !== null && checkedValues.includes(subVariant.Key)
          ? { Value: inputValue }
          : {}),
        ...(selectedValue && checkedValues.includes(subVariant.Key)
          ? { SelectedValue: selectedValue }
          : {}),
      }));

      notificationItem.SubVariants = newSubVariants;

      formik.setFieldValue('NotificationPreferences', notificationPreferences);
    },
    [formik.values.NotificationPreferences, formik.setFieldValue],
  );

  const handleChangeSecondSubVariant = useCallback(
    ({ checkedValues, inputValue, notificationItemIndex }) => {
      const notificationPreferences = JSON.parse(
        JSON.stringify(formik.values.NotificationPreferences),
      );
      const group = notificationPreferences[0];
      const notificationItem = group.NotificationItems[notificationItemIndex];
      const secondSubVariants = notificationItem.SecondSubVariants;
      const newSecondSubVariants = secondSubVariants.map((secondSubVariant) => ({
        ...secondSubVariant,
        ...(checkedValues ? { Checked: checkedValues.includes(secondSubVariant.Key) } : {}),
        ...(inputValue !== null &&
        inputValue !== undefined &&
        checkedValues.includes(secondSubVariant.Key)
          ? { Value: inputValue }
          : {}),
      }));

      notificationItem.SecondSubVariants = newSecondSubVariants;

      formik.setFieldValue('NotificationPreferences', notificationPreferences);
    },
    [formik.values.NotificationPreferences, formik.setFieldValue],
  );
  return (
    <form onSubmit={formik.handleSubmit} className={styles.notificationsForm}>
      <InviteHeader stageIndex={stageIndex} onPrev={onPrev} isValid={formik.isValid} />
      <div className={styles.formContainer}>
        <FormTitle
          className={styles.title}
        >{`Do you want to turn on property notifications for ${firstName}?`}</FormTitle>
        <RadioOptions
          options={enableNotificationsOptions}
          name="isEnabled"
          onChange={handleIsEnabledChange}
          value={formik.values.isEnabled}
          testid="is_enabled"
          className={styles.selectType}
          optionClassName={styles.selectOption}
          checkedClassName={styles.selectChecked}
          labelTextClassName={styles.labelText}
        />
        {formik.values.isEnabled === YesNoOptions.Yes && (
          <div className={styles.notificationItemsWrapper}>
            {formik.values.NotificationPreferences[0].NotificationItems.map(
              (notificationItem, notificationItemIndex) => (
                <OptionGroup
                  fieldTitle={notificationItem.Text}
                  firstStepBoxTitle={(notificationItem as any).SubText || ''}
                  secondStepBoxTitle={notificationItem.SecondSubText}
                  key={notificationItem.Types}
                  options={Object.keys(notificationItem.Variants).map((key) => {
                    return {
                      label: mapItemTitles.PerType[key]().Title,
                      value: key,
                      checked: notificationItem.Variants[key],
                    };
                  })}
                  hasFirstStep={!!notificationItem.SubVariants?.length}
                  hasSecondStep={!!notificationItem.SecondSubVariants?.length}
                  isFirstStepInputGroup={
                    !!notificationItem.SubVariants?.filter((subVar) =>
                      firstStepInputGroup.includes(subVar.Key as NotificationSubVariants),
                    ).length
                  }
                  isFirstStepDropDownGroup={
                    !!notificationItem.SubVariants?.filter(
                      (subVar) => subVar.Key === NotificationSubVariants.QuoteRequestRemind,
                    ).length
                  }
                  isSecondStepNumberGroup={
                    !!notificationItem.SecondSubVariants?.filter(
                      (secondSubVar) => secondSubVar.Key === NotificationSubVariants.Realtime,
                    ).length
                  }
                  firstStepOptions={getTheFirstStepOptions(notificationItem)}
                  secondStepOptions={getTheSecondStepOptions(notificationItem)}
                  onMainCheckboxChange={(checkedValues: CheckboxValueType[]) =>
                    handleVariantChange({ checkedValues, notificationItemIndex })
                  }
                  onStepOneCheckboxChange={(checkedValues, inputValue, selectedValue) =>
                    handleChangeSubVariant({
                      checkedValues,
                      inputValue,
                      selectedValue,
                      notificationItemIndex,
                    })
                  }
                  onStepTwoCheckboxChange={(
                    checkedValues: CheckboxValueType[],
                    inputValue: number,
                  ) =>
                    handleChangeSecondSubVariant({
                      checkedValues,
                      inputValue,
                      notificationItemIndex,
                    })
                  }
                />
              ),
            )}
          </div>
        )}
      </div>
      <Footer
        buttonType="submit"
        buttonTitle="Send Invitation"
        isValid={formik.isValid}
        testid="invite_buyer_footer_notifications"
        disabled={loading}
      />
    </form>
  );
};
