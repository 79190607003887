import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from 'pages/Workshop/Transactions/TransactionTasks/Icons';
import { Shared } from 'components/Icons';
import { useOutsideClick } from 'hooks';

import styles from './styles.module.scss';

const Options = (props) => {
  const { className, isPrivate, index, onPrivate, onDelete, allowFirstDelete, isKeyDate } = props;
  const [open, setOpen] = useState(false);
  const buttonRef = useRef();
  const optionsRef = useRef();

  useOutsideClick([buttonRef, optionsRef], () => setOpen(false));

  const onClickOptions = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  return (
    <div className={classNames(styles.optionsWrapper, className)}>
      <div testid="options" ref={buttonRef} onClick={onClickOptions} className={styles.optionsDots}>
        <Icon variant={Icon.DOTS} />
      </div>
      {open && (
        <div ref={optionsRef} className={styles.options}>
          <ul>
            {!isPrivate && (
              <li
                testid="private_action"
                className={styles.item}
                onClick={(e) => {
                  onPrivate(e);
                  onClickOptions(e);
                }}
              >
                <div className={styles.icon}>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_11288_167126)">
                      <path
                        d="M11.75 7.25H4.25C3.42157 7.25 2.75 7.92157 2.75 8.75V13.25C2.75 14.0784 3.42157 14.75 4.25 14.75H11.75C12.5784 14.75 13.25 14.0784 13.25 13.25V8.75C13.25 7.92157 12.5784 7.25 11.75 7.25Z"
                        stroke="#FF576D"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8 11.75C8.41421 11.75 8.75 11.4142 8.75 11C8.75 10.5858 8.41421 10.25 8 10.25C7.58579 10.25 7.25 10.5858 7.25 11C7.25 11.4142 7.58579 11.75 8 11.75Z"
                        stroke="#FF576D"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5 7.25V4.25C5 3.45435 5.31607 2.69129 5.87868 2.12868C6.44129 1.56607 7.20435 1.25 8 1.25C8.79565 1.25 9.55871 1.56607 10.1213 2.12868C10.6839 2.69129 11 3.45435 11 4.25V7.25"
                        stroke="#FF576D"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_11288_167126">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <span>Private</span>
              </li>
            )}
            {isPrivate && (
              <li
                testid="private_action"
                className={styles.item}
                onClick={(e) => {
                  onPrivate(e);
                  onClickOptions(e);
                }}
              >
                <div className={styles.icon}>
                  <Shared stroke="#747475" />
                </div>
                <span>Shared</span>
              </li>
            )}
            {!isKeyDate && (allowFirstDelete || index) ? (
              <li
                testid="delete_action"
                className={styles.item}
                onClick={(e) => {
                  onDelete(e);
                  onClickOptions(e);
                }}
              >
                <div className={styles.icon}>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_11240_162721)">
                      <path
                        d="M2.66797 4.66602H13.3346"
                        stroke="#FF576D"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.66797 7.33398V11.334"
                        stroke="#FF576D"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.33203 7.33398V11.334"
                        stroke="#FF576D"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.33203 4.66602L3.9987 12.666C3.9987 13.0196 4.13917 13.3588 4.38922 13.6088C4.63927 13.8589 4.97841 13.9993 5.33203 13.9993H10.6654C11.019 13.9993 11.3581 13.8589 11.6082 13.6088C11.8582 13.3588 11.9987 13.0196 11.9987 12.666L12.6654 4.66602"
                        stroke="#FF576D"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6 4.66667V2.66667C6 2.48986 6.07024 2.32029 6.19526 2.19526C6.32029 2.07024 6.48986 2 6.66667 2H9.33333C9.51014 2 9.67971 2.07024 9.80474 2.19526C9.92976 2.32029 10 2.48986 10 2.66667V4.66667"
                        stroke="#FF576D"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_11240_162721">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <span>Delete</span>
              </li>
            ) : null}
          </ul>
        </div>
      )}
    </div>
  );
};

Options.propTypes = {
  className: PropTypes.string,
  isPrivate: PropTypes.bool,
  onPrivate: PropTypes.func,
  onDelete: PropTypes.func,
  index: PropTypes.number,
  allowFirstDelete: PropTypes.bool,
  isKeyDate: PropTypes.bool,
};

Options.defaultProps = {
  className: '',
  isPrivate: false,
  onPrivate: () => {},
  onDelete: () => {},
  index: null,
  allowFirstDelete: false,
  isKeyDate: false,
};

export default Options;
