import { Avatar } from 'components-antd';
import { Locations } from 'components';
import styles from './styles.module.scss';
import { Checkbox } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { addClientAddress } from 'api/transactions';
import { useParams } from 'react-router-dom';
import { getAddressVerification } from 'api/address';
import { isCASSAddress } from 'components/ClientsDrawer/ProfileClient/Contact/components/Address/helpers';

type ClientAddressContainerPropsType = {
  ClientId: number;
  FName: string;
  LName: string | undefined;
  AvatarUrl: string | undefined | null;
  ClientAddress: any;
  Update: boolean;
  setCount: Function;
  latestClientAddressAdder: Function;
  clientAddressId?: number;
};

export const ClientAddressContainer = (props: ClientAddressContainerPropsType) => {
  const [primary, setPrimary] = useState<boolean>(false);
  const [updated, setUpdated] = useState<boolean>(false);
  const [address, setAddress] = useState<any>(props.ClientAddress);
  const isCASS = true;
  const params: { id?: string } = useParams();

  const updateClientAddress = () => {
    const requestObj = {
      id: params?.id,
      address: address,
      clientsStatus: [{ id: props.ClientId, isPrimary: primary }],
    };
    props.latestClientAddressAdder(props.ClientId, address);
    addClientAddress(requestObj);
  };

  useEffect(() => {
    if (props.Update && !updated) {
      props.setCount();
      setUpdated(true);
      updateClientAddress();
    }
  }, [props.Update]);

  useEffect(() => {
    async function getCassAddressFromLegacyAddress() {
      if (
        props?.ClientAddress.Line1 &&
        props?.ClientAddress?.ProviderPlaceId &&
        props?.ClientAddress.City
      ) {
        // find the cass address from the legacy address
        const cassAddress = await getAddressVerification({
          street_line: props?.ClientAddress.Line1,
          city: props?.ClientAddress.City,
          state: props?.ClientAddress.State,
          zipcode: props?.ClientAddress.Zip,
        });
        if (cassAddress?.data?.result?.[0]) {
          const newAddress = {
            address: cassAddress?.data?.result?.[0],
            neighbourhoodRadius: 2,
            notes: '',
          };
          setAddress(newAddress);
        }
      }
    }
    void getCassAddressFromLegacyAddress();
  }, [props?.ClientAddress]);

  const selectedValue: any = useMemo(() => {
    if (address?.address && isCASSAddress({ address: address?.address })) {
      return [address?.address];
    }

    return [];
  }, [address?.address]);

  const onAddressChange = (result, preparedData) => {
    const location = Array.isArray(result?.result) ? result?.result?.[0] : result?.result;
    if (location && isCASSAddress({ address: location })) {
      const newAddress = {
        address: location,
        neighbourhoodRadius: 2,
        notes: '',
      };
      setAddress(newAddress);
    }
  };

  return (
    <div className={styles.addressContainer}>
      <div className={styles.addressContainerHeader}>
        <Avatar
          className={''}
          size={'default'}
          src={props.AvatarUrl}
          alt={props.FName}
          shape={'circle'}
        >
          {props.FName[0] + (props.LName ? props.LName[0] : '')}
        </Avatar>
        <div className={styles.addressContainerHeaderName}>
          {props.FName + (props.LName ? ' ' + props.LName : '')}
        </div>
      </div>
      <div className={styles.addressContainerLocation}>
        <Locations
          name="listingAddress"
          types={['address']}
          onResult={(r, preparedData) => {
            onAddressChange(r, preparedData);
          }}
          placeholder="Enter Address"
          activeInputClassName={styles.locationInput}
          testid="listing_address"
          value={selectedValue}
          valuesWrapperClassName={styles.valuesWrapper}
          multiple={false}
          showPrefixIcon={true}
          activeInputIconClassName={styles.locationsIcon}
          showAsterisk={true}
          variant={Locations.LIGHT}
          shouldFilterStreetLevelResults={true}
          allowedSmartyAddress={isCASS}
          showOnlyCassAddresses={isCASS}
          setFullCassAddress={isCASS}
        />
      </div>
      <Checkbox
        type="checkbox"
        name="PrimaryAddressCheckbox"
        className={'mosaikCheckbox'}
        onChange={(e) => {
          setPrimary(!primary);
        }}
        checked={primary}
      >
        Set as primary address
      </Checkbox>
    </div>
  );
};
