import { ConfirmationDialog } from 'components';
import { showSuccessMessage } from 'helpers';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteClauseTemplateEffect, setClauseTemplateEffect } from 'store/effects/templates';

export const DeleteClause = ({ onClose, clauseTemplate, clauses }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const dispatch = useDispatch();

  const onRemoveClauseConfirm = () => {
    setIsDeleting(true);
    dispatch(
      deleteClauseTemplateEffect({ id: clauseTemplate.Id }, {}, (error) => {
        if (!error) {
          showSuccessMessage('Clause Template deleted successfully');
          const filteredClauses = clauses.filter(({ Id }) => Id !== clauseTemplate.Id);
          dispatch(setClauseTemplateEffect(filteredClauses));
          onClose();
        }
        setIsDeleting(false);
      }),
    );
  };

  const deleteTemplateContent = useMemo(
    () =>
      clauseTemplate ? (
        <div testid="modal_title">
          Are you sure that you want to <b>delete</b> the <b>{clauseTemplate.Name}</b> clause
          template?
        </div>
      ) : null,
    [clauseTemplate],
  );

  return (
    <ConfirmationDialog
      isOpen={!!clauseTemplate}
      onReject={onClose}
      onConfirm={onRemoveClauseConfirm}
      isPending={isDeleting}
    >
      {deleteTemplateContent}
    </ConfirmationDialog>
  );
};
