import { Fragment, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { useDispatch, useSelector } from 'react-redux';
import {
  getKeyDatesSelector,
  getTransactionAccessSelector,
  getTransactionSelector,
} from 'store/selectors/transaction';

import { Edit } from 'components/Icons';
import { Button } from 'components-antd';
import { Select } from 'components';

import commonStyles from './../styles.module.scss';
import styles from './styles.module.scss';

import NumberFormat from 'react-number-format';
import { TransactionBuySideCommissionResponsibility } from 'app-constants';
import { CommissionField } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/Questions/FinancingDetails/components';
import { getTransactionEffect } from 'store/effects';
import { showErrorMessage } from 'helpers';
import { updateCommissionResponsibility } from 'api/transactions';

interface CommissionsResponsibilityType {
  isDisableField: Function;
  setEditDetailId: Function;
  valueKey: string;
}

const buySideCommissionResponsibilityOptions = Object.values(
  TransactionBuySideCommissionResponsibility,
).map((type) => ({
  value: type,
  name: type,
}));

export const CommissionsResponsibility = ({
  valueKey,
  isDisableField,
  setEditDetailId,
}: CommissionsResponsibilityType) => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [hover, setHover] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const { fullAccess } = useSelector(getTransactionAccessSelector);
  const { transaction } = useSelector(getTransactionSelector);
  const keyDates = useSelector(getKeyDatesSelector);

  if (!fullAccess) return null;

  const transactionPrice = useMemo(
    () => keyDates?.ClosePrice || keyDates?.UnderContractPrice || keyDates?.ListingPrice,
    [keyDates],
  );

  const isTransactionUnderContract = transaction?.Status !== 'Under contract';
  const initialStateCommissionContribution = {
    Value: null,
    IsFeeTypePercentage: true,
  };
  const [isValidate, setValidate] = useState(true);
  const [isUpdateButtonDisable, setIsUpdateButtonDisable] = useState(false);

  // Show this only for buyer transaction & undercontract transaction.
  if (
    isTransactionUnderContract &&
    (transaction?.RepresentingRoles?.length !== 1 ||
      !transaction?.RepresentingRoles?.includes('Buyer'))
  ) {
    return null;
  }

  const isPriceWithinRange = (enteredGCI, _otherGCI) => {
    let compareToBeAmount = +enteredGCI + +_otherGCI;

    if (!isNaN(compareToBeAmount) && _otherGCI) {
      if (compareToBeAmount === transactionPrice || compareToBeAmount < transactionPrice) {
        setValidate(true);
        setIsUpdateButtonDisable(false);
      } else {
        setValidate(false);
        setIsUpdateButtonDisable(true);
      }
    }
  };

  const [buysideCommissionResponsibility, setBuysideCommissionResponsibility] = useState<any>('');
  const [buyerCommissionContribution, setBuyerCommissionContribution] = useState<any>(
    initialStateCommissionContribution,
  );
  const [sellerCommissionContribution, setSellerCommissionContribution] = useState<any>(
    initialStateCommissionContribution,
  );

  const emptyText = <span className={commonStyles.noneText}>None</span>;
  const setupDefaultValues = () => {
    const {
      BuySideCommissionResponsibility,
      BuyerCommissionContribution,
      SellerCommissionContribution,
    } = transaction?.TransactionCommission || {};
    setBuysideCommissionResponsibility(BuySideCommissionResponsibility || '');
    setSellerCommissionContribution(
      SellerCommissionContribution || initialStateCommissionContribution,
    );
    setBuyerCommissionContribution(
      BuyerCommissionContribution || initialStateCommissionContribution,
    );
  };

  useEffect(() => {
    if (transaction?.TransactionCommission) setupDefaultValues();
  }, [transaction]);

  const onEdit = () => {
    if (!fullAccess || isDisableField(valueKey)) return null;
    setIsEdit(true);
    setEditDetailId(valueKey);
  };

  const calculateGCI = (percentValue) => {
    return (percentValue / 100) * transactionPrice;
  };

  const updateFinancialField = async () => {
    setIsPending(true);
    const payload = {
      id: transaction?.Id,
      BuySideCommissionResponsibility: buysideCommissionResponsibility,
      ...(buyerCommissionContribution?.Value > 0
        ? { BuyerCommissionContribution: buyerCommissionContribution }
        : {}),
      ...(sellerCommissionContribution?.Value > 0
        ? { SellerCommissionContribution: sellerCommissionContribution }
        : {}),
    };
    try {
      await updateCommissionResponsibility(payload);
      dispatch(getTransactionEffect({ id: transaction?.Id }, { silent: true }));
    } catch (e) {
      setupDefaultValues();
      setIsPending(false);
      showErrorMessage(e);
    } finally {
      setIsPending(false);
      setIsEdit(false);
      setEditDetailId(undefined);
    }
  };

  const renderValue = (field) => {
    const showPercentage = field?.IsFeeTypePercentage;
    if (showPercentage) return `${field?.Value || 0}%`;
    else
      return (
        <NumberFormat prefix="$" displayType="text" thousandSeparator value={field?.Value || 0} />
      );
  };

  const onCancel = () => {
    setIsEdit(false);
    setEditDetailId(undefined);
    setupDefaultValues();
  };

  return (
    <>
      {!isEdit ? (
        <div
          className={classNames(commonStyles.cardWrapper, styles.fullWidth, {
            [commonStyles.disabledSection]: !!isDisableField(valueKey),
          })}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={onEdit}
        >
          <div className={styles.fieldsWrapper}>
            <div className={commonStyles.fieldContainer}>
              <p className={commonStyles.label}>Buy-Side Commission Responsibility:</p>
              {buysideCommissionResponsibility ? (
                <span className={commonStyles.value}>{buysideCommissionResponsibility}</span>
              ) : (
                emptyText
              )}
            </div>
            {buysideCommissionResponsibility ===
              TransactionBuySideCommissionResponsibility.Both && (
              <Fragment>
                <div className={commonStyles.fieldContainer}>
                  <p className={commonStyles.label}>Buyer Side Contribution:</p>
                  {buyerCommissionContribution ? (
                    <span className={commonStyles.value}>
                      {renderValue(buyerCommissionContribution)}
                    </span>
                  ) : (
                    emptyText
                  )}
                </div>
                <div className={commonStyles.fieldContainer}>
                  <p className={commonStyles.label}>Seller Side Contribution:</p>
                  {sellerCommissionContribution ? (
                    <span className={commonStyles.value}>
                      {renderValue(sellerCommissionContribution)}
                    </span>
                  ) : (
                    emptyText
                  )}
                </div>
              </Fragment>
            )}
          </div>
          {fullAccess && !isDisableField(valueKey) && (
            <Edit
              className={classNames(commonStyles.editIcon, { [commonStyles.hideIcon]: !hover })}
              stroke="#262626"
            />
          )}
        </div>
      ) : (
        <div className={commonStyles.editWrapper}>
          <Select
            value={buysideCommissionResponsibility}
            onSelect={(_, { value }) => {
              if (value !== TransactionBuySideCommissionResponsibility.Both) {
                setBuyerCommissionContribution(null);
                setSellerCommissionContribution(null);
                setValidate(true);
                setIsUpdateButtonDisable(false);
              }
              setBuysideCommissionResponsibility(value);
            }}
            options={buySideCommissionResponsibilityOptions}
            className={{
              wrapper: styles.financialInputField,
            }}
            placeholder={' '}
            isArrowIcon={true}
            arrowIconClassName={styles.arrowdownicon}
            variant={Select.LIGHT_ROUND}
            label="Buy-Side Commission Responsibility"
          />
          {buysideCommissionResponsibility === TransactionBuySideCommissionResponsibility.Both && (
            <>
              <CommissionField
                label="Buyer's Contribution"
                value={buyerCommissionContribution?.Value}
                IsFeeTypePercentage={buyerCommissionContribution?.IsFeeTypePercentage}
                update={(object) => {
                  const updatedObject = {
                    ...object,
                    Value: object?.Value === '' ? null : parseFloat(object?.Value),
                  };
                  const enteredGCI = object?.IsFeeTypePercentage
                    ? calculateGCI(object?.Value || 0)
                    : object?.Value;
                  const otherGCI = sellerCommissionContribution?.IsFeeTypePercentage
                    ? calculateGCI(sellerCommissionContribution?.Value || 0)
                    : sellerCommissionContribution?.Value;

                  isPriceWithinRange(enteredGCI, otherGCI);
                  setBuyerCommissionContribution(updatedObject);
                }}
                className={styles.financialInputField}
              />

              <CommissionField
                label="Seller's Contribution"
                value={sellerCommissionContribution?.Value}
                IsFeeTypePercentage={sellerCommissionContribution?.IsFeeTypePercentage}
                update={(object) => {
                  const updatedObject = {
                    ...object,
                    Value: object?.Value === '' ? null : parseFloat(object?.Value),
                  };
                  const enteredGCI = object?.IsFeeTypePercentage
                    ? calculateGCI(object?.Value || 0)
                    : object?.Value;
                  const otherGCI = buyerCommissionContribution?.IsFeeTypePercentage
                    ? calculateGCI(buyerCommissionContribution?.Value || 0)
                    : buyerCommissionContribution?.Value;

                  isPriceWithinRange(enteredGCI, otherGCI);
                  setSellerCommissionContribution(updatedObject);
                }}
                className={styles.financialInputField}
              />
            </>
          )}

          {!isValidate &&
            buysideCommissionResponsibility === TransactionBuySideCommissionResponsibility.Both && (
              <span className={styles.errorText}>
                Total commission can never be greater than price
              </span>
            )}

          <div className={commonStyles.buttons}>
            <Button
              variant="secondary"
              size="large"
              onClick={updateFinancialField}
              loading={isPending}
              disabled={isUpdateButtonDisable}
            >
              Update
            </Button>
            <Button variant="hollow-bordered" size="large" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
