export const isCASSAddress = (address) => {
  return !!address?.address?.delivery_line_1 && !!address?.address?.components;
};

export const normalizeAddress = (address) => {
  if (!isCASSAddress(address)) {
    // Handle legacy address format
    return {
      line1: address?.addressParsed?.Line1 || address?.address || '',
      city: address?.addressParsed?.City || address?.address?.split?.(',')?.[1]?.trim?.() || '',
      state: address?.addressParsed?.State || '',
      zip: address?.addressParsed?.Zip || '',
      country: 'USA',
      placeId: address?.placeId || null,
      isPrimary: address?.isPrimary || false,
      street: address?.addressParsed?.Street || '',
    };
  } else {
    // Handle CASS address format
    return {
      line1: address?.address?.delivery_line_1 || '',
      city: address?.address?.components?.city_name || '',
      state: address?.address?.components?.state_abbreviation || '',
      zip: address?.address?.components?.zipcode || '',
      country: 'USA',
      placeId: null,
      isPrimary: address?.isPrimary || false,
      street:
        (address?.address?.components?.street_name || '') +
        ' ' +
        (address?.address?.components?.street_suffix || ''),
    };
  }
};
