import { useDispatch } from 'react-redux';
import {
  deleteClientTransactionDocumentEffect,
  deleteTransactionDocumentEffect,
  deleteVaultDocumentEffect,
} from 'store/effects';
import { deleteDraftFormEffect } from 'store/effects/formProcess';
import { AgentDocumentTypes } from 'types';

export const useDeleteDocuments = () => {
  const dispatch = useDispatch();

  const deleteDraftDocument = ({ formProcessId }, cb) => {
    dispatch(
      deleteDraftFormEffect({ formProcessId }, () => {
        cb?.();
      }),
    );
  };

  const deleteUploadDocument = ({ documentVaultUUID }, cb) => {
    dispatch(
      deleteVaultDocumentEffect(
        {
          id: documentVaultUUID,
        },
        {},
        () => {
          cb?.();
        },
      ),
    );
  };

  const deleteTransactionDocument = (selectedDocument, cb) => {
    const {
      transactionMeta: { transactionId, documentId },
    } = selectedDocument;

    dispatch(
      deleteClientTransactionDocumentEffect({ transactionId, documentId }, {}, (err) => {
        if (!err) {
          cb?.();
        }
      }),
    );
  };

  const deleteProfileDocument = (document, type, cb) => {
    if (type === AgentDocumentTypes.Transactions) {
      const { formProcessId, isTransactionDocument } = document.transactionMeta;

      if (isTransactionDocument && !formProcessId) {
        deleteTransactionDocument(document, cb);
      } else if (formProcessId) {
        deleteDraftDocument(document.transactionMeta, cb);
      }
    } else if (type === AgentDocumentTypes.Drafts) {
      deleteDraftDocument(document.draftMeta, cb);
    } else if (type === AgentDocumentTypes.Uploads) {
      deleteUploadDocument({ documentVaultUUID: document?.documentVaultUUID }, cb);
    }
  };

  return { deleteProfileDocument };
};
