import { useSelector, useDispatch } from 'react-redux';
import { getUserId } from 'store/selectors/user';
import {
  getShowingAggregateByUserIdEffect,
  setShowingsFiltersAppliedEffect,
} from 'store/effects/showingAggregate';
import { FilterSections } from './FilterSections';
import { getShowingIsArchiveSelector } from 'store/selectors/showingAggregate';
import { ArchiveShowingsStatus, ShowingFilterStatus } from 'settings/constants/showings';

export const Filter = ({ setShowingFilters }) => {
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);
  const { isArchive } = useSelector(getShowingIsArchiveSelector);

  const doneHandler = (p) => {
    setShowingFilters({ ...p });
    dispatch(setShowingsFiltersAppliedEffect(true));
    dispatch(
      getShowingAggregateByUserIdEffect({
        id: userId,
        filters: {
          ...p,
        },
      }),
    );
  };

  const resetHandler = () => {
    setShowingFilters({});
    dispatch(setShowingsFiltersAppliedEffect(false));
    const filterStatuses = isArchive ? ArchiveShowingsStatus : ShowingFilterStatus;

    const statusValues = Object.values(filterStatuses);

    dispatch(
      getShowingAggregateByUserIdEffect({
        id: userId,
        filters: { Status: statusValues },
      }),
    );
  };

  return <FilterSections key={isArchive} onDone={doneHandler} onReset={resetHandler} />;
};
