import classNames from 'classnames';

import Icon from 'pages/Workshop/Transactions/TransactionCreate/Icons';
import { ValueObject } from 'components/Transactions/ClientInput/ClientInputView';

import styles from './styles.module.scss';
import { Cross } from 'components/Icons';
import ParticipantInput from 'components/Transactions/ParticipantInput';
import { ExternalMember } from '../ExternalMember';
import { TransactionUserObject } from 'components/Transactions/ParticipantInput/ParticipantInputView';
import { MemberEmail } from '../MemberEmail/MemberEmail';

interface ParticipantProps {
  value: ValueObject;
  participants?: ValueObject[];
  onDelete: (...args) => void;
  onChangeClient: (...args) => void;
  onBlurClientInput: (...args) => void;
  onRemoveData: (...args) => void;
  currentQuestionId?: string;
  participantIndex?: number;
  updateParticipantValues: (...args) => void;
}

const Participant = ({
  value,
  participants,
  onDelete,
  onChangeClient,
  onBlurClientInput,
  onRemoveData,
  currentQuestionId,

  participantIndex,
  updateParticipantValues,
}: ParticipantProps) => {
  const resetExternalView = participants?.length === 1 && value.externalMember;

  const manageExternalParticipant = (name, resetExternal = false) => {
    const [firstName, ...remainingName] = name.split(' ');

    updateParticipantValues({
      name: '',
      customName: name,
      firstName,
      lastName: remainingName.join(' '),
      customEmail: '',
      Email: '',
      externalMember: !resetExternal,
      ContactId: '',
      UserId: '',
      isContact: false,
      transactionAccess: false,
      error: '',
      phoneNoError: '',
    });
  };

  const deleteIcon = (
    <Icon
      onClick={() => (resetExternalView ? manageExternalParticipant('', true) : onDelete())}
      className={styles.iconDelete}
      variant={Icon.MINUS}
      testid="close_icon"
    />
  );

  return (
    <div className={styles.participantWrap}>
      {!value.externalMember ? (
        <div className={styles.participant}>
          <div className={styles.memberNameInputWrap}>
            <p className={styles.inputLabel}>{'Name'}</p>
            <ParticipantInput
              onBlur={onBlurClientInput}
              onChange={onChangeClient}
              value={value}
              dropdownContainer={styles.dropdownWrap}
              currentQuestionId={currentQuestionId}
              excludedMemberIds={participants
                ?.map((p) => p.id as number)
                .filter((value) => !!value)}
              className={classNames(styles.client, { [styles.clientHalf]: value.invite })}
              placeholder=" "
              allowExternalMember
              onAddExternalParticipant={manageExternalParticipant}
            />

            {value?.firstName ? (
              <Cross color="#262626" className={styles.removeButton} onClick={onRemoveData} />
            ) : null}
          </div>

          {value.isContact && !value.Email && (
            <MemberEmail
              participant={value}
              index={participantIndex}
              updateParticipantValues={updateParticipantValues}
            />
          )}
        </div>
      ) : (
        <ExternalMember
          participant={value}
          index={participantIndex}
          updateParticipantValues={updateParticipantValues}
        />
      )}

      {participants && (resetExternalView || participants.length > 1) ? deleteIcon : null}
    </div>
  );
};

export default Participant;
