import { get } from 'lodash-es';
import { createSelector } from 'reselect';
import { IDLE, PENDING } from 'settings/constants/apiState';

const localState = ({ adminPanel }) => adminPanel;

const teamState = createSelector(localState, ({ teams }) => teams);

export const getTeamsDashboardSelector = createSelector(teamState, ({ state, meta, ...rest }) => ({
  isIdle: state === IDLE,
  isPending: state === PENDING,
  isData: !!rest?.data,
  ...rest,
}));

export const getTeamsSelector = createSelector(teamState, (state) => get(state, 'data'));

export const getSelectedTeamNameSelector = createSelector(teamState, (state) =>
  get(state, 'teamName'),
);

export const teamSubscriptionDetailsSelector = createSelector(teamState, (state) =>
  get(state, 'subscription'),
);

export const getTeamSearchSelector = createSelector(teamState, (state) => get(state, 'search'));

const getSearchedTeams = (teams, search) => {
  let result = (teams || []).filter((data) => {
    if (search) {
      const formattedSearch = search.toLowerCase();
      let ownerName = `${data?.OwnerFirstName} ${data?.OwnerLastName}`;
      let teamName = data?.TeamName;
      let activeSeats = `${data?.TeamAgents?.length}`;
      let createdDate = data?.CreatedDate;

      return (
        ownerName?.toLowerCase()?.includes(formattedSearch) ||
        teamName?.toLowerCase()?.includes(formattedSearch) ||
        activeSeats?.toLowerCase()?.includes(formattedSearch) ||
        createdDate?.toLowerCase()?.includes(formattedSearch)
      );
    }
    return true;
  });

  return result;
};

export const getTeamsDataDashboardSelector = createSelector(
  getTeamsSelector,
  getTeamSearchSelector,
  (initTeams, search) => {
    const members = getSearchedTeams(initTeams, search);
    return members;
  },
);
