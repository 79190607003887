import { createAction } from 'redux-actions';

export const getPublishedKitsAction = createAction('REQUEST/GET_PUBLISHED_KITS');

export const createCampaignAction = createAction('REQUEST/CREATE_CAMPAIGN');

export const updateCampaignAction = createAction('REQUEST/UPDATE_CAMPAIGN');

export const getCampaignsAction = createAction('REQUEST/GET_CAMPAIGNS');

export const getCampaignByIdAction = createAction('REQUEST/GET_CAMPAIGN_BY_ID');

export const getCampaignMembersByCampaignIdAction = createAction('REQUEST/GET_CAMPAIGN_MEMBERS');

export const getCampaignMasterSwitchAction = createAction('REQUEST/GET_CAMPAIGN_MASTER_SWITCH');

export const putCampaignMasterSwitchAction = createAction('REQUEST/PUT_CAMPAIGN_MASTER_SWITCH');

export const getCampaignPublishedKitsWithStatsAction = createAction(
  'REQUEST/GET_CAMPAIGN_PUBLISHED_KITS_WITH_STATS',
);

export const setSelectedPlanIdAction = createAction('SET_SELECTED_PLAN_ID');

export const getUpcomingMemberCampaignInfoAction = createAction(
  'REQUEST/GET_UPCOMING_MEMBER_CAMPAIGN_INFO',
);

export const getPastMemberCampaignInfoAction = createAction(
  'REQUEST/GET_PAST_MEMBER_CAMPAIGN_INFO',
);

export const getTestClockAction = createAction('REQUEST/GET_TEST_CLOCK');

export const createTestClockAction = createAction('REQUEST/CREATE_TEST_CLOCK');

export const resetTestClockAction = createAction('REQUEST/RESET_TEST_CLOCK');

export const fastForwardTestClockAction = createAction('REQUEST/FAST_FORWARD_TEST_CLOCK');

export const createCampaignTaskAction = createAction('REQUEST/CREATE_CAMPAIGN_TASK');

export const updateCampaignTaskAction = createAction('REQUEST/UPDATE_CAMPAIGN_TASK');

export const getCampaignTaskAction = createAction('REQUEST/GET_CAMPAIGN_TASK');

export const deleteCampaignTaskAction = createAction('REQUEST/DELETE_CAMPAIGN_TASK');

export const uploadCampaignTaskDocumentsAction = createAction(
  'REQUEST/UPLOAD_CAMPAIGN_TASK_DOCUMENTS',
);

export const deleteCampaignTaskDocumentAction = createAction(
  'REQUEST/DELETE_CAMPAIGN_TASK_DOCUMENT',
);

export const getCampaignTaskMembersAction = createAction('REQUEST/GET_CAMPAIGN_TASK_MEMBERS');

export const resetSelectedCampaignAction = createAction('RESET_SELECTED_CAMPAIGN');
