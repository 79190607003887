import { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { chunk, flatMap } from 'lodash-es';

import { Checkbox } from 'components';
import { Row, Col } from 'components-antd';
import { pushOrRemoveStageOptionObj } from 'pages/OnBoardingWizard/helpers';
import { prefsIds, PREF_TYPE } from 'settings/constants/preferences';

import styles from './styles.module.scss';
import Keywords from '../Keywords';
import { appOpenModalEffect } from 'store/effects';
import { useDispatch } from 'react-redux';
import { Plus } from 'components/Icons';
import WishlistModal from '../Keywords/WishlistModal';
import {
  AMENITIES_TYPES,
  FLOORING_TYPES,
  INTERIOR_TYPES,
  KITCHEN_CABINET_TYPES,
  KITCHEN_FEATURES_TYPES,
  LAYOUT_TYPES,
  MECHANICAL_ENERGY,
  MECHANICAL_HEAT_SOURCE,
  MECHANICAL_WASTE,
  MECHANICAL_WATER_SOURCE,
  NEIGHBORHOOD_TYPE,
  OUTDOOR_TYPES,
  PROPERTY_CONDITION_TYPES,
  RVParking,
  VIEW_TYPES,
} from '../../constants';

const Options = (props) => {
  const dispatch = useDispatch();
  const { className, onChange, value, disabled, testid, preferences, keywords, setKeywords } =
    props;
  const [actives, setActives] = useState(value);
  let prePopulateObj = {};

  useEffect(() => {
    setActives(value);
    selectMustPreferences();
  }, []);

  const openModal = (id) => {
    dispatch(appOpenModalEffect({ id, open: true }));
  };

  const getFlatPrefs = useCallback(
    () =>
      flatMap(
        preferences,
        ({
          AmenitiesPrefs,
          NeighborhoodPrefs,
          Parking,
          HomePrefs,
          CommutePrefs,
          InteriorStylesPrefs,
          OutdoorPrefs,
          ViewPrefs,
        }) => {
          const result = [];

          if (ViewPrefs && Array.isArray(ViewPrefs)) {
            result.push(...ViewPrefs);
          }

          if (AmenitiesPrefs && Array.isArray(AmenitiesPrefs)) {
            result.push(...AmenitiesPrefs);
          }

          if (NeighborhoodPrefs && Array.isArray(NeighborhoodPrefs)) {
            result.push(...NeighborhoodPrefs);
          }

          if (Parking) {
            result.push(Parking);
          }

          if (HomePrefs) {
            result.push(...HomePrefs);
          }

          if (CommutePrefs && Array.isArray(CommutePrefs)) {
            result.push(...CommutePrefs);
          }

          if (InteriorStylesPrefs && Array.isArray(InteriorStylesPrefs)) {
            result.push(...InteriorStylesPrefs);
          }

          if (OutdoorPrefs) {
            result.push(...OutdoorPrefs);
          }

          return result;
        },
      ),
    [preferences],
  );

  const createOptions = useCallback(() => {
    const commutes = [];
    const parking = [];
    const flatPrefs = getFlatPrefs().map((obj, index) => {
      const { Preference, NumOfParkingSpaces, TransportationModePreference, Name } = obj;

      if (TransportationModePreference) {
        commutes.push({
          id: Name,
          label: Name,
          type: 'commute',
          commute: obj,
        });
        return Name;
      }
      if (NumOfParkingSpaces) {
        parking.push({
          id: PREF_TYPE.parking,
          label: `Parking for ${NumOfParkingSpaces} vehicle${NumOfParkingSpaces > 1 ? 's' : ''}`,
          type: PREF_TYPE.parking,
          commute: obj,
        });
        return Options.PARKING;
      }
      return Preference;
    });

    const schools =
      Object.keys(preferences[0]?.Schools).length > 0
        ? [
            {
              id: 'schools',
              label: preferences[0]?.Schools?.NonSpecificConfig
                ? 'Schools: ' + preferences[0]?.Schools?.NonSpecificConfig?.SchoolTypes?.join(' ,')
                : 'Schools: ' +
                  preferences[0]?.Schools?.SpecificDistricts?.map(({ Name }) => Name)?.join(' ,'),
              type: 'schools',
              schools: preferences[0]?.Schools,
            },
          ]
        : [];

    return [
      ...schools,
      ...parking,
      ...[
        ...commutes,
        ...LAYOUT_TYPES,
        ...OUTDOOR_TYPES,
        ...PROPERTY_CONDITION_TYPES,
        ...FLOORING_TYPES.map((obj) => ({ ...obj, label: obj.label + ' Flooring' })),
        ...KITCHEN_FEATURES_TYPES,
        ...KITCHEN_CABINET_TYPES.map((obj) => ({ ...obj, label: obj.label + ' Cabinet' })),
        ...VIEW_TYPES.map((obj) => ({ ...obj, label: obj.label + ' View' })),
        ...INTERIOR_TYPES,
        ...AMENITIES_TYPES,
        ...MECHANICAL_HEAT_SOURCE,
        ...MECHANICAL_WATER_SOURCE,
        ...MECHANICAL_WASTE,
        ...MECHANICAL_ENERGY,
        ...NEIGHBORHOOD_TYPE,
        { id: RVParking, label: 'RV Parking' },
      ].filter((option) => flatPrefs.includes(option.id)),
    ];
  }, [getFlatPrefs]);

  const getOptions = () => {
    return chunk(createOptions(), 1);
  };

  const onClick = useCallback(
    (option) => {
      const newActives = pushOrRemoveStageOptionObj({
        obj: actives,
        key: option.id,
        multiple: true,
      });
      setActives(newActives);
      onChange(createOptions().filter(({ id }) => id in newActives));
    },
    [createOptions, onChange, actives],
  );

  const onClickFirst = (option) => {
    if (option.id) {
      prePopulateObj[option.id] = true;
    }
    setActives((prevState) => ({ ...prevState, ...prePopulateObj }));
  };

  const selectMustPreference = (val, prefType) => {
    for (const pref of preferences) {
      if (pref?.[prefType]) {
        if (prefType === prefsIds.parking) {
          if (pref[prefType]?.ImportanceAndWeight.Importance === 'Must') {
            onClickFirst({ id: prefsIds.parking, label: prefsIds.parking, type: prefsIds.parking });
            break;
          }
        } else {
          let found = false;
          pref[prefType].map((v) => {
            if (val.id === v.Preference) {
              if (v.ImportanceAndWeight.Importance === 'Must') {
                onClickFirst(val);
                found = true;
              }
            }
          });
          if (found) break;
        }
      }
    }
  };

  const selectMustPreferences = () => {
    getOptions().forEach((e) => {
      e.forEach((val) => {
        selectMustPreference(val, PREF_TYPE.amenitiesPrefs);
        selectMustPreference(val, PREF_TYPE.homePrefs);
        selectMustPreference(val, PREF_TYPE.neighborhoodPrefs);
        selectMustPreference(val, PREF_TYPE.outdoorPrefs);
        selectMustPreference(val, prefsIds.parking);
      });
    });
  };

  const renderOptions = () =>
    getOptions().map((option, index) => (
      <Col xs={24} sm={12} key={index} className={styles.optionHolder}>
        {option.map(({ id, label, type }, index) => (
          <Checkbox
            key={id}
            name={name}
            label={label}
            direction={Checkbox.DIRECTION_RIGHT}
            className={classNames(styles.checkboxOption, {
              [styles.checked]: id in actives,
              [styles.offsetLeft]: index % 2 !== 0,
            })}
            labelClassName={styles.label}
            labelTextClassName={classNames(styles.labelText)}
            onChange={() => onClick({ id, label, type })}
            checkboxWrapperClassName={styles.checkboxWrapper}
            checkboxClassName={classNames(styles.checkbox, {
              [styles.unchecked]: !(id in actives),
            })}
            checkmarkClassName={classNames(styles.checkmark, {
              [styles.unchecked]: !(id in actives),
            })}
            checked={id in actives}
            value={label}
            testid={testid}
            hasOuterClick={true}
            checkboxColor="#fff"
            tooltip
          />
        ))}
      </Col>
    ));

  return (
    <div className={classNames(styles.wrapper, { [styles.disabled]: disabled }, className)}>
      <Row gutter={[10, 10]} className={styles.holder}>
        {renderOptions()}
        <Keywords keywords={keywords} setKeywords={setKeywords} />
      </Row>
      <div
        className={styles.button}
        onClick={() => {
          openModal(WishlistModal.id);
        }}
      >
        <div className={styles.add}>
          <Plus color="#747475" />
        </div>
        <p className={styles.label}>Add Something Else</p>
      </div>
    </div>
  );
};

Options.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.shape({}),
  disabled: PropTypes.bool,
  testid: PropTypes.string,
  preferences: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

Options.defaultProps = {
  className: '',
  onChange: () => {},
  value: {},
  disabled: false,
  testid: undefined,
};

export default Options;
