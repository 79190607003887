import React from 'react';

import styles from './styles.module.scss';
import { FilterSection } from '../FilterSection';
import { Checkbox } from 'antd';
import classNames from 'classnames';
import { useFormik } from 'formik';
import {
  NewListingIcon,
  PriceReductionIcon,
  PriceIncreaseIcon,
  BackOnTheMarketIcon,
  SoldIcon,
  AgentUpdatesIcon,
} from '../Icons';
import { Categories, FormValues } from './types';
import { icons } from './constants';

export const Filter: React.FC = () => {
  const handleSubmit = (values: FormValues) => {
    console.log(values);
  };

  const formik = useFormik({
    initialValues: {
      categories: {
        [Categories.NEW_LISTING]: false,
        [Categories.PRICE_REDUCTION]: false,
        [Categories.PRICE_INCREASE]: false,
        [Categories.BACK_ON_THE_MARKET]: false,
        [Categories.WITHDRAWN]: false,
        [Categories.UNDER_CONTRACT]: false,
        [Categories.EXPIRED]: false,
        [Categories.SOLD]: false,
        [Categories.AGENT_UPDATES]: false,
      },
      tags: {
        tag1: false,
        tag2: false,
        tag3: false,
        tag4: false,
      },
    } as FormValues,

    onSubmit: handleSubmit,
  });
  return (
    <div className={styles.filterWrapper}>
      <FilterSection title={'Categories'}>
        {Object.keys(formik.values.categories)?.map((category, index) => (
          <div className={styles.checkboxWrapper} key={`${category}-${index}`}>
            <div className={styles.flex}>
              <div className={styles.iconWrapper} style={{ background: icons[category].bgColor }}>
                {icons[category].icon}
              </div>
              <div>{category}</div>
            </div>
            <div>
              <Checkbox className={styles.checkbox} />
            </div>
          </div>
        ))}
      </FilterSection>
      <FilterSection title={'Tags'}>
        <div className={styles.tagWrapper}>
          {Object.keys(formik.values.tags)?.map((tag, index) => (
            <div
              className={classNames(styles.tag, {
                [styles.active]: formik.values.tags[tag],
              })}
              key={`${tag}-${index}`}
              onClick={() => formik.setFieldValue(`tags.${tag}`, !formik.values.tags[tag])}
            >
              <div>{tag}</div>
            </div>
          ))}
        </div>
      </FilterSection>
    </div>
  );
};
