import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { Avatar } from 'components';
import { Button, Modal } from 'components-antd';
import { Checkbox } from 'antd';
import { Icons } from 'pages/Vault/Icons';

import { getCurrentContextSelector } from 'store/selectors/context';

import { getInitials } from 'utils';
import { getUserFullName } from 'store/selectors/user';
import { ArrowRight } from 'components/Icons';

import styles from './styles.module.scss';

const Assignee = ({
  avatarUrl,
  name,
  checked,
  nameLabel = '',
  onChange = (checked: boolean) => {},
}) => {
  return (
    <div className={styles.assigneeContainer}>
      <div className={styles.left}>
        <Avatar avatarClassName={styles.avatar} src={avatarUrl} placeholder={getInitials(name)} />
        <span className={styles.name}>{nameLabel || name}</span>
      </div>
      <div className={styles.right}>
        <Checkbox
          onChange={(e) => onChange(e.target.checked)}
          className={classNames(styles.checkbox, 'mosaikCheckbox', {
            [styles.checkboxDisabled]: !!nameLabel,
          })}
          checked={checked}
          disabled={!!nameLabel}
        />
      </div>
    </div>
  );
};

export const ShareModal = ({
  open,
  onClose,
  handleShareDocument,
  handleShareDocumentInApp,
  loadingDoneBtn,
}) => {
  const userName = useSelector(getUserFullName);
  const selectedContext = useSelector(getCurrentContextSelector);
  const connectedAgents = selectedContext?.Agents || {};
  const [selectedUsersToShare, setSelectedUsersToShare] = useState<number[]>([]);

  useEffect(() => {
    setSelectedUsersToShare([]);
  }, [open]);

  return (
    <Modal
      open={open}
      width={675}
      footer={null}
      onCancel={onClose}
      className={styles.shareDocumentsModal}
      cancelButtonStyles={styles.crossButton}
      maskClosable={false}
      destroyOnClose={true}
    >
      <div className={styles.content}>
        <p className={styles.title}>Share Document</p>
        <div className={styles.shareAssigneesContainer}>
          <p className={styles.heading}>Who can access this document?</p>
          <Assignee avatarUrl={null} name={userName} nameLabel="Me" checked />
          {connectedAgents?.map((item: any, idx) => (
            <Assignee
              key={idx}
              avatarUrl={item?.AvatarUrl}
              name={`${item?.FirstName} ${item?.LastName}`}
              checked={!!selectedUsersToShare?.includes(item?.Id)}
              onChange={(checked) => {
                setSelectedUsersToShare((prev) =>
                  checked ? [...prev, item.Id] : prev.filter((userId) => userId !== item.Id),
                );
              }}
            />
          ))}
        </div>
        <div className={styles.seperator} />
        <div className={styles.shareViaContainer}>
          <p className={styles.heading}>Share via ...</p>
          <div className={styles.appMsgShare} onClick={handleShareDocumentInApp}>
            <div className={styles.left}>
              <Icons variant={Icons.SHARE_APP_MSG} />
              <p className={styles.text}>In-App Message</p>
            </div>
            <ArrowRight className={styles.arrowRight} />
          </div>
        </div>
        <div className={styles.doneBtnContainer}>
          <Button
            variant="secondary"
            className={styles.doneBtn}
            loading={loadingDoneBtn}
            onClick={() => handleShareDocument(selectedContext, selectedUsersToShare)}
          >
            Done
          </Button>
        </div>
      </div>
    </Modal>
  );
};
