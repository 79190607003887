import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { map, slice } from 'lodash-es';
import { Info } from 'components/Icons';
import { Tooltip } from 'components-antd';

import SearchBlockActions from '../SearchBlockActions';
import { SearchIcon } from 'components/Icons';

import styles from './styles.module.scss';
import classNames from 'classnames';

const SearchCard = ({
  title,
  status,
  info,
  withActions,
  onClick,
  onEdit,
  onSearch,
  onRename,
  onDelete,
  onInactive,
  onActivate,
  photos,
  hideBorder,
  fullInfo,
}) => {
  const deleteHandler = useCallback(() => {
    onDelete();
  }, [onDelete]);

  const editHandler = useCallback(() => {
    onEdit();
  }, [onEdit]);

  const searchHandler = useCallback(
    (e) => {
      e.stopPropagation();
      onSearch();
    },
    [onSearch],
  );

  const inactiveHandler = useCallback(() => {
    onInactive();
  }, [onInactive]);

  const activateHandler = useCallback(() => {
    onActivate();
  }, [onActivate]);

  return (
    <div
      className={classNames(styles.containerActiveSearch, { [styles.hideBorder]: hideBorder })}
      onClick={onClick}
    >
      <div className={styles.images}>
        {photos?.length ? (
          map(slice(photos, 0, 4), (link, index) => <img key={index} src={`${link}?width=200`} />)
        ) : (
          <SearchIcon className={styles.noImages} />
        )}
      </div>
      <div className={styles.searchName}>
        {title ? (
          <>
            <div className={styles.searchTitle}>{title}</div>
          </>
        ) : (
          <a>&nbsp;</a>
        )}
        <div className={styles.descInfoWrapper}>
          {info ? (
            <p className={classNames(styles.descInfoWrapped, styles.descInfo)}>{info}</p>
          ) : (
            <div className={styles.emptyDescInfo}></div>
          )}
          {fullInfo && (
            <Tooltip
              placement="top"
              getPopupContainer={(trigger) => trigger}
              overlayClassName={classNames(styles.compareOfferTooltip, 'mosaikTooltip')}
              title={
                <p testid="search_info" className={styles.descInfo}>
                  {fullInfo}
                </p>
              }
            >
              <div>
                <Info size={'16'} />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
      <div className={styles.menuActions}>
        {withActions && (
          <SearchBlockActions
            onDelete={onDelete && deleteHandler}
            onSearch={onSearch && searchHandler}
            onRename={onRename}
            searchName={title}
            onEdit={editHandler}
            onInactive={status !== 'Inactive' && inactiveHandler}
            onActivate={status === 'Inactive' && activateHandler}
            clientProfileDrawer={true}
          />
        )}
      </div>
    </div>
  );
};

SearchCard.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  status: PropTypes.string,
  agentName: PropTypes.string,
  info: PropTypes.string,
  footer: PropTypes.string,
  className: PropTypes.string,
  infoClassName: PropTypes.string,
  testid: PropTypes.string,
  withActions: PropTypes.bool,
  onClick: PropTypes.func,
  onEdit: PropTypes.func,
  onSearch: PropTypes.func,
  onRename: PropTypes.func,
  searchName: PropTypes.string,
  onDelete: PropTypes.func,
  onInactive: PropTypes.func,
  onActivate: PropTypes.func,
  hideBorder: PropTypes.bool,
};

SearchCard.defaultProps = {
  photos: null,
  withActions: true,
  className: '',
  infoClassName: '',
  testid: undefined,
  title: '',
  agentName: '',
  status: '',
  info: '',
  footer: '',
  onClick: null,
  searchName: '',
  onEdit: () => {},
  onSearch: () => {},
  onRename: () => {},
  onDelete: () => {},
  onActivate: () => {},
  onInactive: () => {},
  hideBorder: false,
};

export default SearchCard;
