import { useMemo, useState } from 'react';
import { ClausesTemplatesCard, TemplateListItemType } from '../ClausesTemplatesCard';
import { useHistory } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';
import { DeleteClause } from '../DeleteClause';
import { NoTemplatesFound } from 'pages/Templates/components/NoTemplates';

type ClausesListType = {
  showTeamClauses?: boolean;
  clauses: TemplateListItemType[];
  clausesList: TemplateListItemType[];
  searchTerm: string;
  activeKey: string;
};

export const ClausesList = (props: ClausesListType) => {
  const { showTeamClauses = false, clausesList = [], clauses, searchTerm, activeKey } = props;

  const [clauseTemplate, setClauseTemplate] = useState<TemplateListItemType>();

  const history = useHistory();

  const onEditClause = (clause) => {
    history.push({
      pathname: routes.templatesClauseEdit,
      state: { clause, clausesTabKey: activeKey },
    });
  };

  const onViewClause = (clause) => {
    history.push({
      pathname: routes.templatesClauseEdit,
      state: { clause, clausesTabKey: activeKey, viewMode: true },
    });
  };

  const toggleDeleteClause = (clause?) => {
    setClauseTemplate(clause || undefined);
  };

  const clausesDataSource = useMemo(() => {
    return clausesList.filter(
      (temp) => temp.Name.toLowerCase().includes(searchTerm) || temp.Name.includes(searchTerm),
    );
  }, [searchTerm, showTeamClauses, clausesList]);

  return (
    <div>
      {clausesDataSource.map((template) => (
        <ClausesTemplatesCard
          key={template?.Id}
          template={template}
          onEdit={onEditClause}
          onDelete={toggleDeleteClause}
          onView={onViewClause}
        />
      ))}

      <DeleteClause
        clauses={clauses}
        clauseTemplate={clauseTemplate}
        onClose={() => toggleDeleteClause()}
      />
    </div>
  );
};
