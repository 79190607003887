import { routes } from 'settings/navigation/routes';
import { AGENT } from 'settings/constants/roles';
import { ClientProfile } from 'pages/ClientProfile';

export default [
  {
    path: routes.clientProfile + '/:id',
    component: ClientProfile,
    exact: true,
    roles: [AGENT],
  },
];
