import PropTypes from 'prop-types';
import classNames from 'classnames';

import MatchFull from 'components/Icons/MatchFull';
import { MATCH_LEVEL } from 'settings/constants/common';
import MatchPartial from 'components/Icons/MatchPartial';
import MatchNone from 'components/Icons/MatchNone';
import MatchUnknown from 'components/Icons/MatchUnknown';
import { Insight } from './Insight';

import styles from './styles.module.scss';

const MatchItem = ({
  name,
  isMatched,
  matchLevel,
  matchType,
  iconClassName,
  titleClassName,
  insight,
  dataSource,
  criteriaInsight,
}) => {
  const itemNameClass = classNames(
    {
      [styles.itemName]: true,
      [styles.matched]: isMatched,
      [styles.partial]: matchLevel === MATCH_LEVEL.PARTIAL,
      [styles.unmatched]: !isMatched,
    },
    titleClassName,
  );
  const isNotProd = window.location.hostname !== 'app.mosaik.io';
  return (
    <div testid="match_item" className={styles.matchItemContainer}>
      {matchLevel === MATCH_LEVEL.FULL ? (
        <MatchFull className={classNames(iconClassName, styles.matchLevelIcon)} />
      ) : matchLevel === MATCH_LEVEL.PARTIAL ? (
        <MatchPartial className={classNames(iconClassName, styles.matchLevelIcon)} />
      ) : matchLevel === MATCH_LEVEL.UNKNOWN ? (
        <MatchUnknown className={classNames(iconClassName, styles.matchLevelIcon)} />
      ) : (
        <MatchNone className={classNames(iconClassName, styles.matchLevelIcon)} />
      )}
      <div className={styles.content}>
        <p className={itemNameClass}>
          {name}
          {/*** Temproray hidden ***/}
          {/* {isNotProd && matchType && <span className={styles.smallText}>({matchType})</span>} */}
        </p>
        {criteriaInsight ? (
          <p className={styles.criteriaInsight}>{criteriaInsight && criteriaInsight}</p>
        ) : insight && matchLevel !== MATCH_LEVEL.NONE ? (
          <Insight name={name} matchLevel={matchLevel} dataSource={dataSource} />
        ) : null}
      </div>
    </div>
  );
};

MatchItem.propTypes = {
  iconClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  name: PropTypes.string,
  isMatched: PropTypes.bool,
  insight: PropTypes.bool,
  dataSource: PropTypes.string,
};

MatchItem.defaultProps = {
  name: '',
  titleClassName: null,
  iconClassName: null,
  isMatched: null,
  insight: false,
};

export default MatchItem;
