export const prepareUpdatedRoles = (savedRoles) => {
  const updatedRoles = savedRoles.map((roleId, index) => ({
    roleId,
    oldRoleId: undefined,
    isDeleted: false,
    isReplaced: false,
    savedRole: roleId,
  }));

  return updatedRoles;
};

export const getNewRole = (roleId, deleted = false) => ({
  roleId,
  oldRoleId: undefined,
  isDeleted: deleted,
  isReplaced: false,
  savedRole: undefined,
});

export const prepareTemplateUpdatedData = (details, deletedIds) => {
  let roleDetails = deletedIds.map((id) => ({ Id: id, IsDeleted: true }));

  details.forEach((item) => {
    if (item.isReplaced || item.isDeleted || !item.savedRole) {
      roleDetails.push({
        Id: item.roleId,
        ...(item.isReplaced && { IsReplaced: true, OldRoleId: item.oldRoleId }),
        ...(!item.isReplaced && !item.isDeleted && { IsDeleted: false, IsReplaced: false }),
      });
    } else {
      roleDetails.push({ Id: item.roleId, IsReplaced: false, IsDeleted: false });
    }
  });

  return roleDetails;
};
