import * as Yup from 'yup';

export const rangeCheck = Yup.object()
  .shape({
    Min: Yup.number(),
    Max: Yup.number(),
  })
  .test('is-valid-range', 'Invalid range', function (value) {
    const { Min, Max } = value;
    return (Min || Min === 0) && (Max || Max === 0)
      ? (Min < Max && Max > Min) || Min === Max
      : true;
  });

export const ValidationSchema = Yup.object().shape({
  PriceRange: rangeCheck,
  NumBedroomsRange: rangeCheck,
  NumBathroomsRange: rangeCheck,
  SquareFeetRange: rangeCheck,
  ParkingSpacesRange: rangeCheck,
  StoriesRange: rangeCheck,
});
