import { routes } from 'settings/navigation/routes';
import { HeaderLink } from '../../components/PageHeader/types';

enum TabTitles {
  PROPERTY = 'Property',
  NEIGHBORHOOD = 'Neighborhood',
}

export const tabRoutes = {
  property: routes.intelProperties,
  neighborhood: routes.intelNeighborhood,
};

export const TabRoutesLinks: HeaderLink[] = [
  {
    title: TabTitles.PROPERTY,
    value: tabRoutes.property,
    // icon: null,
  },
  {
    title: TabTitles.NEIGHBORHOOD,
    value: tabRoutes.neighborhood,
    // icon: null,
  },
];
