import { useSelector } from 'react-redux';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { getEmptyMilestone } from 'settings/constants/transaction';
import { showErrorMessage } from 'helpers';
import { validateControlOperatorOffset } from 'utils/templatesHelper';
import { readExcelFile } from 'utils/readFile';
import Icon from 'pages/Workshop/Transactions/TransactionTasks/Icons';

import styles from './styles.module.scss';

const UploadTimelines = ({ updateMilestones, isViewMode }) => {
  const { isSuperUser } = useSelector(getUserRolesMapSelector);

  const formatTimeline = (list) => {
    const timelines = [];
    const ControlOperatorOffsetPattern = /^[A-Z]+[+-]\d+/;
    let error = false;

    for (let index = 0; index < list.length; index++) {
      const timeline = list[index];
      const newTimeline = getEmptyMilestone();
      newTimeline.Title = timeline['Milestone Name'] || '';
      newTimeline.IsPrivate = timeline.Private?.toUpperCase() === 'X';
      const dueDate = timeline['Due Date'];
      if (!dueDate) {
        newTimeline.Initial = null;
        newTimeline.Operator = null;
        newTimeline.DueDate = null;
        newTimeline.ControlOperatorOffset = '';
      } else if (
        validateControlOperatorOffset(null, dueDate, () => {}) &&
        ControlOperatorOffsetPattern.test(dueDate)
      ) {
        const split = dueDate.includes('+') ? dueDate.split('+') : dueDate.split('-');
        newTimeline.Initial = split[0];
        newTimeline.Operator = dueDate.includes('+') ? '+' : '-';
        newTimeline.DueDate = +split[1];
        newTimeline.ControlOperatorOffset = dueDate;
      } else {
        showErrorMessage(
          `Due Date on row ${
            index + 2
          } is incorrect! Please re-enter Due Date using the correct format.`,
        );
        error = true;
        break;
      }
      timelines.push(newTimeline);
    }
    if (!error) updateMilestones(timelines);
  };

  const downloadTemplate = (e) => {
    const url =
      'https://mosaik-assets.s3.amazonaws.com/referenceData/Mosaik_Timeline_Template_V1_0.xlsx';
    if (!isViewMode) window.open(url, '_blank');
  };

  return (
    <>
      {isSuperUser && (
        <div className={styles.uploadTimelinesContainer}>
          <button type="button" className={styles.actionBtn} onClick={(e) => downloadTemplate(e)}>
            <Icon variant={Icon.DOWNLOAD} />
          </button>
          <div>
            <label htmlFor="timeline-upload" className={styles.actionBtn}>
              <Icon variant={Icon.UPLOAD} className={styles.uploadIcon} />
              Upload
            </label>
            {!isViewMode && (
              <input
                id="timeline-upload"
                type="file"
                accept=".xls,.xlsx,.csv"
                onChange={(e) => readExcelFile(e, formatTimeline)}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default UploadTimelines;
