import { LOCAL_STORAGE_KEY } from 'features/emailVerification/constants';
import { undoInterruptedActionHandlers } from 'features/emailVerification/interruptedActions';
import { useVerifyEmailViaOtp } from 'pages/OnboardingV2Agent/ProAndGrowFlow/VerifyEmailViaOtp/useVerifyEmailViaOtp';
import { useDispatch } from 'react-redux';
import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { OnboardingContinueButton } from '../agent/components/OnboardingContinueButton';
import { OnboardingSkipButton } from '../agent/components/OnboardingSkipButton';

export const VerifyEmailOtp: React.FC<any> = (props) => {
  const dispatch = useDispatch();

  const {
    userEmail,
    enteredCode,
    inputRefs,
    errorText,
    handleChange,
    handleKeyDown,
    isVerifying,
    handlePaste,
    successfulVerification,
    handleSubmit,
    getResendText,
  } = useVerifyEmailViaOtp({
    onEmailVerified: () => {
      const afterVerificationStateJson = window.localStorage.getItem(LOCAL_STORAGE_KEY);
      if (afterVerificationStateJson) {
        const afterVerificationState = JSON.parse(afterVerificationStateJson);

        let actionHandler = undoInterruptedActionHandlers[afterVerificationState.action];
        if (actionHandler) {
          actionHandler(history, dispatch);
        }
        window.localStorage.removeItem(LOCAL_STORAGE_KEY);
      }
      props.onNext();
    },
  });
  return (
    <div className={styles.verifyEmail}>
      <div className={styles.heading}>Verify Your Email Address</div>
      <div className={styles.subHeading}>
        A verification code has been sent to <span>{userEmail}</span>. Please check your inbox and
        enter the verification code below.
      </div>
      <div className={styles.inputOtpContainer}>
        {enteredCode.map((code, index) => (
          <input
            key={index}
            ref={(el) => (inputRefs.current[index] = el)}
            type={'number'}
            value={code}
            className={classNames(styles.input, {
              [styles.invalid]: !!errorText,
            })}
            onChange={(e) => handleChange(e, index)}
            maxLength={1}
            onKeyDown={(e) => handleKeyDown(e, index)} // Handle arrow keys and backspace
            disabled={isVerifying}
            onPaste={index === 0 ? handlePaste : undefined} // Only the first input handles paste
          />
        ))}
      </div>
      <div className={styles.resendContainer}>Didn&apos;t receive it? {getResendText()}</div>
      <ButtonsContainer>
        <OnboardingSkipButton
          onClick={() => {
            window.localStorage.removeItem('afterVerificationState');
            props.onNext();
          }}
          className={styles.skipButton}
        />
      </ButtonsContainer>
    </div>
  );
};
