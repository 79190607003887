export enum TransactionMileStoneTitle {
  ListingExpire = 'Listing expires',
  ClosingDate = 'Closing date',
  ContractDate = 'Contract date',
  Kickoff = 'Kickoff',
  Completion = 'Completion',
  Cancelled = 'Cancelled',
  Closed = 'Closed',
  TargetLiveDate = 'Target Live Date',
}
