import { GET_ALL_TEAMS } from 'app-constants';
import { apiServer } from 'settings/web-services/api';

export function getTeamList(cfg) {
  return apiServer.get(`/agentTeam/members`, { params: cfg });
}

export function getTransactionTeamList(cfg) {
  return apiServer.get(`/agentTeam/members-contacts`, { params: cfg });
}

export function getTeamPermittedList(cfg) {
  return apiServer.get(`/agentTeam/permittedMembers`);
}

export function postInviteTeamMember(cfg) {
  return apiServer.post('/agentTeam/invite', cfg);
}

export function postReinviteTeamMember(cfg) {
  return apiServer.post('/agentTeam/reinvite', cfg);
}

export function getTeamTitles() {
  return apiServer.get('/agentTeam/titles');
}

export function getTeamTitlesByTeamId(id) {
  return apiServer.get(`/agentTeam/titles/${id}`);
}

export function getAgentDetail(cfg) {
  return apiServer.get(`/agentTeam/members/${cfg.id}`);
}

export function getAgentTeamDocuments({ id, ...cfg }) {
  return apiServer.post(`/agentteam/documents/client/${id}`, cfg);
}

export function getTeamClients() {
  return apiServer.get(`/agentTeam/clients`);
}

export function assignClientsToMember(cfg, { agentId }) {
  return apiServer.post(`/agentTeam/members/${agentId}/clients`, cfg);
}

export function updateMemberActiveStatus(cfg, { agentId }) {
  return apiServer.put(`/agentTeam/members/${agentId}/activeStatus`, cfg);
}

export function updateMemberProfile(cfg, { agentId }) {
  return apiServer.put(`/agentTeam/members/${agentId}/profile`, cfg);
}

export function updateMemberRole(cfg, { agentId }) {
  return apiServer.put(`/agentTeam/members/${agentId}/role`, cfg);
}

export function configureAdminAccess(cfg, { agentId }) {
  return apiServer.post(`/agentTeam/members/${agentId}/managedMembers`, cfg);
}

export function getTeamMemberClients(cfg, { agentId }) {
  return apiServer.get(`/agentTeam/members/${agentId}/clients`);
}

export function getTeamMemberTasks(cfg, { agentId }) {
  return apiServer.get(`/agentTeam/members/${agentId}/tasks`);
}

export function getTeamMemberTransactions(cfg, { agentId }) {
  return apiServer.get(`/agentTeam/members/${agentId}/transactions`);
}

export function getAllTeams() {
  return apiServer.get(GET_ALL_TEAMS);
}
