import { useSelector } from 'react-redux';

import { getGraphNameSelector } from 'store/selectors/clarity';
import { Content } from './Content';

export const Stats = () => {
  const graphName = useSelector(getGraphNameSelector);

  return (
    <div>
      <Content value={graphName} />
    </div>
  );
};
