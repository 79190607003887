import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getAdminOwnerAccessSelector, getKeyDatesSelector } from 'store/selectors/transaction';
import { getTransactionSelector, getFinancialPricingSelector } from 'store/selectors/transaction';

import { Row, Col } from 'components-antd';
import { PageWrapper, AsideNavigation, Content } from 'components/Transactions';
import { Wrapper } from 'components';
import {
  getKeyDatesEffect,
  getFinancialPricing,
  getTransactionEffect,
  getTransactionAgentsCoordinators,
  getTransactionRolesEffect,
} from 'store/effects/transactions';
import { requestGetTransactionRelatedContactsEffect } from 'store/effects/relatedContacts';

import { getTransactionLedgers } from 'api/financials';
import { transactionRoles } from 'settings/constants/roles';
import { TransactionStatus } from 'types';
import { ListPrice, LeadSource, Commission, Splits, Fees, NetCard, CardTypes } from './components';

import { LedgerTransactionSideId } from 'settings/constants/ledger';
import { formatNets, getGCIFeesSum, getLedgerId, getTransactionCommissions } from './helpers';

import styles from './styles.module.scss';

export enum SplitTabTypes {
  LISTING = 'Listing',
  PURCHASE = 'Purchase',
}

export enum SplitTypes {
  AGENT = 'Agent_Split',
  TEAM = 'Team_Split',
  BROKERAGE = 'Brokerage_Split',
  CLIENT = 'Client_Split',
}

interface NetType {
  netTitle: string;
  netValue: number;
  isRestricted: boolean;
}

export const TransactionFinancials = () => {
  const dispatch = useDispatch();
  const params: { id?: string } = useParams();
  const [splitTabType, setSplitTabType] = useState(SplitTabTypes.LISTING);
  const [priceHistory, setPriceHistory] = useState([]);
  const [ledgerData, setLedgerData] = useState<any>(null);
  const [splitList, setSplitData] = useState<any>([]);
  const [nets, setNets] = useState<NetType[] | null>([]);
  const [loading, setLoading] = useState(false);
  const { transaction, isError, status, isIdle, isPending } = useSelector(getTransactionSelector);
  const keyDatesAndPrices = useSelector(getKeyDatesSelector);

  const [transactionPrice, setTransactionPrice] = useState(0);
  const [transactionDate, setTransactionDate] = useState(null);

  let _pricing = useSelector(getFinancialPricingSelector);
  const { isAdminOrOwner } = useSelector(getAdminOwnerAccessSelector);
  const netCardTypes = [CardTypes.CONFETTI_BLUE, CardTypes.PURPLE, CardTypes.LIGHT_BLUE];

  const isBuyerTransaction = useMemo(() => {
    // Checking if the transaction is a buyer.
    return (
      transaction?.RepresentingRoles?.length < 2 &&
      transaction?.RepresentingRoles?.includes(transactionRoles.BUYER)
    );
  }, [transaction?.RepresentingRoles]);

  const isSellerTransaction = useMemo(() => {
    // Checking if the transaction is a seller.
    return (
      transaction?.RepresentingRoles?.length <= 2 &&
      transaction?.RepresentingRoles?.includes(transactionRoles.SELLER)
    );
  }, [transaction?.RepresentingRoles]);

  const isDualTransaction = useMemo(() => {
    // Checking if the transaction is a dual.
    return (
      transaction?.RepresentingRoles?.length === 2 &&
      transaction?.RepresentingRoles?.includes(transactionRoles.BUYER) &&
      transaction?.RepresentingRoles?.includes(transactionRoles.SELLER)
    );
  }, [transaction?.RepresentingRoles]);

  let _transactionCommission =
    ledgerData?.ledgers &&
    ledgerData?.ledgerLineItems &&
    getTransactionCommissions(ledgerData?.ledgers, ledgerData?.ledgerLineItems);

  const getSelectedDate = () => {
    const {
      CloseDate,
      UnderContractCloseDate,
      PurchaseEffectiveDate,
      ListingExpireDate,
      ListingEffectiveDate,
    } = keyDatesAndPrices || {};

    if (transaction?.status === TransactionStatus.Closed) {
      return CloseDate;
    } else if (transaction?.status === TransactionStatus.UnderContract) {
      return UnderContractCloseDate;
    } else {
      return (
        CloseDate ??
        UnderContractCloseDate ??
        PurchaseEffectiveDate ??
        ListingExpireDate ??
        ListingEffectiveDate ??
        new Date().toISOString()
      );
    }
  };

  useEffect(() => {
    const selectedPrice =
      keyDatesAndPrices?.ClosePrice ??
      keyDatesAndPrices?.UnderContractPrice ??
      keyDatesAndPrices?.ListingPrice ??
      0;

    setTransactionPrice(selectedPrice);
    setTransactionDate(getSelectedDate());
  }, [ledgerData?.ledgers, keyDatesAndPrices]);

  useEffect(() => {
    if (isBuyerTransaction) {
      setSplitTabType(SplitTabTypes.PURCHASE);
    }
  }, [transaction?.RepresentingRoles]);

  useEffect(() => {
    dispatch(getKeyDatesEffect({ transactionId: params?.id }, { silent: !isIdle }));
    dispatch(getFinancialPricing({ transactionId: params?.id }));
    dispatch(getTransactionEffect({ id: params?.id }, { silent: !isIdle }));
    dispatch(getTransactionAgentsCoordinators(params.id, { silent: !isIdle }));
    dispatch(getTransactionRolesEffect());
    dispatch(requestGetTransactionRelatedContactsEffect({ transactionId: params?.id }));
  }, []);

  useEffect(() => {
    if (!isPending) fetchLedger();
  }, [isPending]);

  const fetchLedger = async () => {
    try {
      setLoading(true);
      const ledger = await getTransactionLedgers({ transactionId: params?.id });
      setLedgerData(ledger);
    } catch (err) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setPriceHistory(_pricing);
  }, [_pricing]);

  const getSplitList = (data) => {
    const filterUnrestrictedSplits = data?.filter((item) => !item?.SplitType?.IsRestricted);
    setSplitData(isAdminOrOwner ? data : filterUnrestrictedSplits);
  };

  useEffect(() => {
    setNets(
      splitTabType === SplitTabTypes.LISTING
        ? formatNets(
            ledgerData?.ledgers,
            LedgerTransactionSideId.Listing,
            ledgerData?.ledgerLineItems,
          )
        : formatNets(
            ledgerData?.ledgers,
            LedgerTransactionSideId.Purchase,
            ledgerData?.ledgerLineItems,
          ),
    );
  }, [splitTabType, ledgerData]);

  const getComparePrice = (data, transactionSideLedgerId, withoutGCIDeduction = false) => {
    let _comparePrice = 0;

    const selectedPrice =
      keyDatesAndPrices?.ClosePrice ??
      keyDatesAndPrices?.UnderContractPrice ??
      keyDatesAndPrices?.ListingPrice ??
      0;
    if (data?.IsFeeTypePercentage) {
      _comparePrice = (data?.Value / 100) * selectedPrice;
    } else {
      _comparePrice = data?.Value;
    }

    const gciFees = getGCIFeesSum(ledgerData?.ledgerLineItems, transactionSideLedgerId);
    if (withoutGCIDeduction) return _comparePrice;

    return _comparePrice - gciFees;
  };

  const getRelevantGCIPrice = () => {
    const _listSideledgerId = getLedgerId(ledgerData?.ledgers, LedgerTransactionSideId.Listing);
    const _buySideledgerId = getLedgerId(ledgerData?.ledgers, LedgerTransactionSideId.Purchase);

    if (splitTabType === SplitTabTypes.LISTING) {
      return getComparePrice(_transactionCommission?.SellerCommissionFees, _listSideledgerId, true);
    } else {
      return getComparePrice(_transactionCommission?.BuyerCommissionFees, _buySideledgerId, true);
    }
  };

  return (
    <div>
      <PageWrapper notFound={isError && status === 404} unAuthorized={isError && status === 401}>
        <AsideNavigation />
        <Content>
          <Row>
            <Col xs={24} md={12} className={styles.pageDivider}>
              <div className={styles.financialsContent}>
                <h1 className={styles.pageTitle}>Financials</h1>
                <ListPrice priceHistory={priceHistory} isBuyerTransaction={isBuyerTransaction} />
                <LeadSource
                  leadSource={transaction?.LeadSource}
                  transactionId={params?.id}
                  isTransactionAdminOrOwner={isAdminOrOwner}
                  transactionCreator={transaction?.Creator}
                />
                <Wrapper isPending={loading}>
                  <Commission
                    fetchFinancials={fetchLedger}
                    isBuyerTransaction={isBuyerTransaction}
                    isDualTransaction={isDualTransaction}
                    isSellerTransaction={isSellerTransaction}
                    ledgers={ledgerData?.ledgers}
                    ledgerLineItems={ledgerData?.ledgerLineItems}
                    transactionPrice={transactionPrice}
                    transactionDate={transactionDate}
                  />
                </Wrapper>
              </div>
            </Col>
            <Col xs={24} md={12}>
              <div className={styles.financialsContent}>
                <Wrapper isPending={loading || isPending}>
                  <Splits
                    transactionId={params?.id}
                    fetchFinancials={fetchLedger}
                    getSplitList={getSplitList}
                    representingRoles={transaction?.RepresentingRoles}
                    isBuyerTransaction={isBuyerTransaction}
                    splitTabType={splitTabType}
                    setSplitTabType={(tabType) => setSplitTabType(tabType)}
                    isTransactionAdminOrOwner={isAdminOrOwner}
                    ledgers={ledgerData?.ledgers}
                    ledgerLineItems={ledgerData?.ledgerLineItems}
                    _transactionCommission={_transactionCommission}
                    getComparePrice={getComparePrice}
                  />
                </Wrapper>
                <Wrapper isPending={loading || isPending}>
                  <Fees
                    fetchFinancials={fetchLedger}
                    splitList={splitList}
                    splitTabType={splitTabType}
                    isTransactionAdminOrOwner={isAdminOrOwner}
                    ledgers={ledgerData?.ledgers}
                    ledgerLineItems={ledgerData?.ledgerLineItems}
                    transactionPrice={transactionPrice}
                    gciPrice={getRelevantGCIPrice()}
                  />
                </Wrapper>
                <Wrapper isPending={loading || isPending}>
                  {nets?.map((item, idx) => (
                    <NetCard
                      key={idx}
                      label={item?.netTitle}
                      variant={netCardTypes?.[idx % netCardTypes?.length]}
                      amount={item?.netValue ?? 0}
                      isRestricted={item?.isRestricted}
                      isAdminOrOwner={isAdminOrOwner}
                    />
                  ))}
                </Wrapper>
              </div>
            </Col>
          </Row>
        </Content>
      </PageWrapper>
    </div>
  );
};
