import { Row, Col } from 'components-antd';
import { AreaGraphTemplate } from '../../Graphs/AreaGraphTemplate';

import styles from '../styles.module.scss';
import { useSelector } from 'react-redux';
import { getTransactionFilter } from 'store/selectors/clarity';

export const Volume = ({
  listingsSummary,
  listingsData,
  purchasesSummary,
  purchasesData,
  closedSummary,
  closedData,
  expiredSummary,
  expiredData,
  canceledSummary,
  canceledData,
  isPending,
}) => {
  const transactionFilter = useSelector(getTransactionFilter);
  return (
    <div className={styles.mainContentWrapper}>
      <Row gutter={[24, 24]}>
        {transactionFilter !== 2 ? (
          <Col
            span={8}
            xs={{ span: 24, order: 1 }}
            sm={{ span: 12, order: 1 }}
            lg={{ span: 8, order: 1 }}
            className="gutter-row"
          >
            <AreaGraphTemplate
              title={'Listings'}
              color={'#8882D9'}
              summary={listingsSummary}
              data={listingsData}
              isPending={isPending}
              isExpandableChart={false}
              showRequests={listingsSummary?.NumberTransactions > 0}
            />
          </Col>
        ) : (
          <Col
            span={8}
            xs={{ span: 24, order: 1 }}
            sm={{ span: 12, order: 1 }}
            lg={{ span: 8, order: 1 }}
            className="gutter-row"
          ></Col>
        )}
        {transactionFilter !== 1 ? (
          <Col
            span={8}
            xs={{ span: 24, order: 2 }}
            sm={{ span: 12, order: 2 }}
            lg={{ span: 8, order: 2 }}
            className="gutter-row"
          >
            <AreaGraphTemplate
              title={'Purchases'}
              color={'#4673D1'}
              summary={purchasesSummary}
              data={purchasesData}
              isPending={isPending}
              isExpandableChart={false}
              showRequests={purchasesSummary?.NumberTransactions > 0}
            />
          </Col>
        ) : (
          <Col
            span={8}
            xs={{ span: 24, order: 1 }}
            sm={{ span: 12, order: 1 }}
            lg={{ span: 8, order: 1 }}
            className="gutter-row"
          ></Col>
        )}

        <Col
          span={8}
          xs={{ span: 24, order: 3 }}
          sm={{ span: 12, order: 3 }}
          lg={{ span: 8, order: 3 }}
          className="gutter-row"
        ></Col>
        <Col
          span={8}
          xs={{ span: 24, order: 4 }}
          sm={{ span: 12, order: 4 }}
          lg={{ span: 8, order: 4 }}
          className="gutter-row"
        >
          <AreaGraphTemplate
            title={'Closed'}
            color={'#04A451'}
            summary={closedSummary}
            data={closedData}
            isPending={isPending}
            isExpandableChart={false}
            showRequests={closedSummary?.NumberTransactions > 0}
          />
        </Col>

        <Col
          span={8}
          xs={{ span: 24, order: 5 }}
          sm={{ span: 12, order: 5 }}
          lg={{ span: 8, order: 5 }}
          className="gutter-row"
        >
          <AreaGraphTemplate
            title={'Expired'}
            color={'#FB913A'}
            summary={expiredSummary}
            data={expiredData}
            isPending={isPending}
            isExpandableChart={false}
            showRequests={expiredSummary?.NumberTransactions > 0}
          />
        </Col>
        <Col
          span={8}
          xs={{ span: 24, order: 6 }}
          sm={{ span: 12, order: 6 }}
          lg={{ span: 8, order: 6 }}
          className="gutter-row"
        >
          <AreaGraphTemplate
            title={'Canceled'}
            color={'#FF576D'}
            summary={canceledSummary}
            data={canceledData}
            isPending={isPending}
            isExpandableChart={false}
            showRequests={canceledSummary?.NumberTransactions > 0}
          />
        </Col>
      </Row>
    </div>
  );
};
