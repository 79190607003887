import { useMemo, useRef, useState } from 'react';

import classNames from 'classnames';
import { omit } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { useOutsideClick } from 'hooks';

import { appSetActiveClientSearchInstanceAction } from 'store/actions/app';
import { getActiveClientSearchInstance } from 'store/selectors/app';

import { Button, Popover } from 'components-antd';
import { LocationCollapsibleOptions } from 'components/ClientsDrawer/InviteClient/StageParts/InviteClientWishList/components/LocationAttributes';
import { PropertyCollapsibleOptions } from 'components/ClientsDrawer/InviteClient/StageParts/InviteClientWishList/components/PropertyAtrributes';

import Icons from '../../Icons/Icons';
import { Edit } from 'components/Icons';
import styles from './styles.module.scss';
import { PREF_TYPE, prefsIds } from 'settings/constants/preferences';

type NeedsWantsType = {
  softCriteria: {
    type: string;
    value: any;
  };
  type: 'Need' | 'Want';
};

export const NeedsWants = ({ type, softCriteria }: NeedsWantsType) => {
  const dispatch = useDispatch();
  const [editState, setEditState] = useState(false);
  const activeClientSearchInstance = useSelector(getActiveClientSearchInstance);
  const [openSelections, setOpenSelections] = useState(false);
  const selectionsRef = useRef(null);

  useOutsideClick([selectionsRef], () => {
    setOpenSelections(false);
  });

  const wishlistAttributes = [
    ...LocationCollapsibleOptions,
    ...PropertyCollapsibleOptions.flatMap((option) =>
      option.criteria.flatMap((criteria) =>
        criteria.additionalOptions ? [criteria, ...criteria.additionalOptions.options] : criteria,
      ),
    ),
  ];

  const commuteNeedOrWants = useMemo(() => {
    const result: any = [];

    softCriteria?.[prefsIds.commutes]?.forEach((feature) => {
      const importance = feature.ImportanceAndWeight.Importance;
      if (
        (type === 'Need' && importance === 'Must') ||
        (type === 'Want' && importance === 'Somewhat')
      ) {
        result.push(feature.Name);
      }
    });

    return result;
  }, [softCriteria?.[PREF_TYPE.keywordPrefs]]);

  const keywordNeedOrWants = useMemo(() => {
    const result: any = [];

    softCriteria?.[PREF_TYPE.keywordPrefs]?.forEach((feature) => {
      const importance = feature.ImportanceAndWeight.Importance;
      if (
        (type === 'Need' && importance === 'Must') ||
        (type === 'Want' && importance === 'Somewhat')
      ) {
        result.push(feature.Keyword);
      }
    });

    return result;
  }, [softCriteria?.[PREF_TYPE.keywordPrefs]]);

  const needsorWants: any = Object.entries(softCriteria)
    .filter(([_, value]) => value.value === type)
    .reduce((obj, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});

  const onRemove = (key) => {
    const updatedSoftCriteria = omit({ ...softCriteria }, key);
    dispatch(
      appSetActiveClientSearchInstanceAction({
        ...activeClientSearchInstance,
        softCriteria: updatedSoftCriteria,
      }),
    );
  };

  const onRemoveKeywordPref = (keyword) => {
    if (softCriteria?.[PREF_TYPE.keywordPrefs]) {
      const updatedKeywordPrefs = softCriteria?.[PREF_TYPE.keywordPrefs]?.filter(
        (item) => item?.Keyword !== keyword,
      );
      dispatch(
        appSetActiveClientSearchInstanceAction({
          ...activeClientSearchInstance,
          softCriteria: { ...softCriteria, [PREF_TYPE.keywordPrefs]: updatedKeywordPrefs },
        }),
      );
    }
  };

  const onClickCriteria = (criteria) => {
    const criteriaToAdd: any = { type: criteria?.type, value: type };
    if (needsorWants?.[criteria?.id]) {
      onRemove(criteria.id);
    } else {
      dispatch(
        appSetActiveClientSearchInstanceAction({
          ...activeClientSearchInstance,
          softCriteria: { ...softCriteria, [criteria?.id]: criteriaToAdd },
        }),
      );
    }
  };

  function editClick() {
    setEditState((prev) => !prev);
  }

  const Option = ({ criteria }) => (
    <div className={styles.criteria} onClick={() => onClickCriteria(criteria)}>
      <p>{criteria.showTitle}</p>
      {needsorWants?.[criteria?.id] && <Icons variant={Icons.CHECKMARK} />}
    </div>
  );

  const PopoverContent = (
    <div className={classNames(styles.selectionsContainer)}>
      {PropertyCollapsibleOptions.map(
        (item, idx) =>
          item.title !== 'Parking' &&
          !item?.criteria?.additionalOptions && (
            <div key={idx} className={styles.optionsContainer}>
              <div className={styles.category}>
                <span>{item.title}</span>
              </div>
              {item.criteria?.map((criteria, idx) => (
                <Option key={idx} criteria={criteria} />
              ))}
            </div>
          ),
      )}
      <div className={styles.optionsContainer}>
        <div className={styles.category}>
          <span>Location Attributes</span>
        </div>
        {LocationCollapsibleOptions?.map((criteria, idx) => (
          <Option key={idx} criteria={criteria} />
        ))}
      </div>
    </div>
  );

  const itemMapped = (item) => {
    const itemFound = wishlistAttributes.find((wishlistItem) => wishlistItem.id === item);
    if (!itemFound) return { showTitle: item };
    if (itemFound?.id?.includes('Flooring'))
      // For Flooring preferences options, append `Flooring` for display
      return {
        ...itemFound,
        showTitle: `${itemFound?.showTitle} Flooring`,
      };
    if (itemFound?.type === PREF_TYPE.ViewPrefs)
      // For View preferences options, append `View` for display
      return {
        ...itemFound,
        showTitle: `${itemFound?.showTitle} View`,
      };
    else if (itemFound?.id === prefsIds.kitchenFeaturesLargerThanAverage)
      return { ...itemFound, showTitle: 'Large Kitchen' };
    else if (
      itemFound?.id === prefsIds.parking &&
      softCriteria?.[prefsIds.parking]?.data?.count > 0
    ) {
      // For Parking, append `count` to display
      return {
        ...itemFound,
        showTitle: `Parking for ${softCriteria?.[prefsIds.parking]?.data?.count} Vehicles`,
      };
    } else return itemFound;
  };

  return (
    <div className={styles.needsContainer}>
      <div className={classNames(styles.edit, { [styles.active]: editState })} onClick={editClick}>
        <Edit stroke={'#262626'} strokeWidth={2} />
      </div>
      <p className={styles.title}>{type}s</p>
      <div className={styles.preferencePillsContainer}>
        {Object.keys(needsorWants).map((item, idx) => (
          <div key={idx} className={styles.preferencePill}>
            <span>{itemMapped(item)?.showTitle || itemMapped(item)?.label}</span>
            {editState && <Icons variant={Icons.CLOSE} onClick={() => onRemove(item)} />}
          </div>
        ))}
        {[...keywordNeedOrWants, ...commuteNeedOrWants]?.map((item, idx) => (
          <div key={idx} className={styles.preferencePill}>
            <span>{item}</span>
            {editState && <Icons variant={Icons.CLOSE} onClick={() => onRemoveKeywordPref(item)} />}
          </div>
        ))}
      </div>
      {editState && (
        <div className={styles.addNeedWantContainer}>
          <Popover
            content={PopoverContent}
            overlayClassName={styles.optionsPopoverContent}
            trigger="click"
            autoAdjustOverflow={false}
          >
            <Button
              variant="link"
              icon={<Icons variant={Icons.ADD} />}
              onClick={() => setOpenSelections(!openSelections)}
            >
              Add {type}
            </Button>
          </Popover>
        </div>
      )}
    </div>
  );
};
