import {
  FormHeader,
  FormSearchFilter,
} from 'pages/Workshop/Forms/components/NewFormModal/FormStages/components';
import styles from './style.module.scss';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getClauseTemplatesSelector } from 'store/selectors/templates';
import { getAllClausesEffect } from 'store/effects/templates';
import { Spinner } from 'components';
import { ICON_VARIANT_TYPE, Icons } from 'pages/FormProcess/Icons';
import { NoTemplatesFound } from 'pages/Templates/components/NoTemplates';
import { EmptyClausePlaceholder } from 'pages/Templates/Clauses/components';
import classNames from 'classnames';

export const InsertTemplateClauses = ({ onClose, onSelectClause }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const { data: clauses = [] } = useSelector(getClauseTemplatesSelector);

  useEffect(() => {
    setLoading(true);
    dispatch(
      getAllClausesEffect({}, {}, () => {
        setLoading(false);
      }),
    );
  }, []);

  const clausesDataSource = useMemo(() => {
    if (searchTerm) {
      return clauses.filter((clauseItem) => {
        const { Name, Description } = clauseItem;
        const lowerCasedSearch = searchTerm.toLowerCase();

        return (
          Name.toLowerCase().includes(lowerCasedSearch) ||
          Description.toLowerCase().includes(lowerCasedSearch)
        );
      });
    }

    return clauses || [];
  }, [searchTerm, clauses]);

  return (
    <div className={styles.insertClauseWrapper}>
      <FormHeader heading={'Insert Clause'} className={styles.clausesHeader} />

      <div className={styles.insertClauseContent}>
        <div className={styles.insertClauseContentWrapper}>
          <FormSearchFilter
            searchText={searchTerm}
            onSearch={setSearchTerm}
            hideSelection={true}
            hideFilter={true}
            className={styles.clausesSearch}
          />

          {loading ? (
            <Spinner loaderClassName={styles.loadingSpinner} />
          ) : clauses?.length ? (
            <div className={styles.clausesListContent}>
              <div className={styles.clauseTitle}>Clause</div>

              <div className={styles.clausesList}>
                {clausesDataSource.map((item) => (
                  <div
                    key={item.Name}
                    className={styles.clausesListItem}
                    onClick={() => onSelectClause(item)}
                  >
                    <div className={styles.clausesIcon}>
                      <Icons variant={ICON_VARIANT_TYPE.InsertClauseBG} />
                    </div>
                    <div>
                      <div className={styles.clauseItemTitle}>{item.Name}</div>
                      <div className={styles.clauseItemDescription}>
                        {item.Description.split('\n').map((line, index) => (
                          <Fragment key={index}>
                            {line}
                            <br />
                          </Fragment>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <NoTemplatesFound
              className={classNames(styles.noTemplates, styles.noClausesTemplates)}
              placeholderText={"You don't have any clauses"}
              placeholderIcon={<EmptyClausePlaceholder />}
            />
          )}
        </div>
      </div>
    </div>
  );
};
