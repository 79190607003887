import React from 'react';

import { Footer } from 'components-antd';

import classNames from 'classnames';
import styles from './styles.module.scss';

export const PageWrapper = ({
  children,
  className = '',
  pageWrapperContainerClassName = '',
  testId = '',
  isBgWhite = false,
  isContainer = true,
  isHideFooter = false,
}) => {
  return (
    <div className={classNames(styles.pageWrapper, className, { [styles.bgWhite]: isBgWhite })}>
      <div
        testid={testId}
        className={classNames(styles.pageWrapperContainer, pageWrapperContainerClassName, {
          container: isContainer,
        })}
      >
        {children}
      </div>
      {!isHideFooter && <Footer />}
    </div>
  );
};
