import { Modal } from 'components-antd';

import styles from './style.module.scss';
import { Icons } from '../Icons';
import { InProgressForms } from './InProgressForms';

type InProgressFormsModalProps = {
  onSelectForm?: (data) => void;
  people?: number[];
  teams?: number[];
  locations?: object[];
  open: boolean;
  onClose: () => void;
  propertyInfo?: any;
  setSelectedForm?: (data) => void;
};

export const InProgressFormsModal = (props: InProgressFormsModalProps) => {
  const { open, onClose } = props;

  return (
    <Modal
      open={open}
      width={1002}
      footer={null}
      onCancel={() => {
        onClose();
      }}
      maskClosable={false}
      destroyOnClose
      className={styles.inProgressFormsModal}
      closeIcon={<Icons variant={'modal-close'} />}
    >
      <InProgressForms />
    </Modal>
  );
};
