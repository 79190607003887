import { useState } from 'react';
import AntModal, { ModalProps as AntModalProps } from 'antd/lib/modal';
import classNames from 'classnames';

import { Close } from 'components/Icons';

import styles from './styles.module.scss';

export interface ModalProps extends Omit<AntModalProps, 'onOk'> {
  className?: string;
  closeIconStyles?: string;
  children?: any;
  onOk?: (setLoading: (loading: boolean) => void) => void;
  cancelButtonStyles?: string;
  width?: number;
}

export const Modal = ({
  className,
  children,
  onOk,
  cancelButtonStyles,
  confirmLoading,
  cancelButtonProps,
  closeIconStyles,
  width = 360,
  ...props
}: ModalProps) => {
  const [loading, setLoading] = useState(false);

  return (
    <AntModal
      confirmLoading={confirmLoading || loading}
      onOk={() => onOk && onOk(setLoading)}
      className={classNames(styles.commonModal, className)}
      cancelButtonProps={{
        className: classNames(styles.cancelButton, cancelButtonStyles),
        ...cancelButtonProps,
      }}
      closeIcon={<Close className={classNames(styles.closeButton, closeIconStyles)} />}
      centered={true}
      width={width}
      destroyOnClose
      {...props}
    >
      {children}
    </AntModal>
  );
};
