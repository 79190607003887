/* eslint-disable react/jsx-indent */
import { map } from 'lodash-es';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { Col, Row } from 'components-antd';
import { Close } from 'components/Icons';
import { PartnerProfile } from 'pages/ServicesCategoryPartner/components/PartnerProfile';
import { PartnerCard } from './components/PartnerCard/PartnerCard';

import styles from './styles.module.scss';

const PartnersList = ({ list, isAllTab = false, categoryTitle }) => {
  const [selectedPartnerId, setSelectedPartnerId] = useState(null);
  const [viewPartnerDetails, setViewPartnerDetails] = useState(false);
  const [showMessage, setShowMessage] = useState(true);

  return (
    <>
      <PartnerProfile
        partnerId={selectedPartnerId}
        open={viewPartnerDetails}
        setModalVisible={setViewPartnerDetails}
        onCancel={() => {
          setViewPartnerDetails(false);
          setSelectedPartnerId(null);
        }}
      />
      {list?.length ? (
        <div className={styles.partners}>
          <Row gutter={[0, 24]}>
            {isAllTab && showMessage && (
              <Col xs={24} sm={24} md={24}>
                <div className={styles.directoryMessage}>
                  <div className={styles.text}>
                    This directory includes vendors who were invited to Mosaik by other agents in
                    the area who recommend their services. This directory is for informational
                    purposes and does not constitute any endorsement or guarantee from Mosaik.
                  </div>

                  <div onClick={() => setShowMessage(false)}>
                    <Close color="#747475" className={styles.closeIcon} />
                  </div>
                </div>
              </Col>
            )}
            {map(
              list?.filter((partner) => partner.IsActive),
              (partner) => (
                <PartnerCard
                  partner={partner}
                  key={partner.Id}
                  setViewPartnerDetails={setViewPartnerDetails}
                  setSelectedPartnerId={setSelectedPartnerId}
                />
              ),
            )}
          </Row>
        </div>
      ) : (
        <div testid="no_partners" className={styles.noPartners}>
          <svg
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.2881 51.9894L32.0036 44.7783L45.7192 51.9894L43.0992 36.7161L54.1947 25.9005M20.9081 36.7161L9.8125 25.9005L25.1458 23.6739L32.0036 9.77832C34.6817 15.2049 36.1833 18.2473 38.8614 23.6739"
              stroke="#E8E9EA"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.95081 48.6789L55.0622 12.8036"
              stroke="#E8E9EA"
              strokeWidth="4"
              strokeLinecap="round"
            />
          </svg>
          <span className={styles.noPartnersText}>
            {isAllTab
              ? `There aren’t any vendors to choose from at this time.`
              : `You have no recommended partners.`}
            <br />
          </span>
        </div>
      )}
    </>
  );
};

PartnersList.propTypes = {
  isAllTab: PropTypes.bool,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
    }),
  ),
  categoryTitle: PropTypes.string,
};

export default PartnersList;
