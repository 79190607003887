import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Document, Page } from 'react-pdf';
import { Spinner } from 'components';
import { Icons } from 'pages/Vault/Icons';

import { previewFormDocumentEffect } from 'store/effects/formProcess';

import styles from './styles.module.scss';

export const PdfThumbnail = ({ documentVaultUUID, size }) => {
  const dispatch = useDispatch();
  const [documentLoading, setDocumentLoading] = useState(false);
  const [pdfFile, setPdfFile] = useState<any>(null);
  const isLessThan5MB = size && Number(size) <= 5242880;

  useEffect(() => {
    if (documentVaultUUID && isLessThan5MB) {
      setDocumentLoading(true);
      dispatch(
        previewFormDocumentEffect({ documentVaultUUID: documentVaultUUID }, {}, (err, response) => {
          if (!err) {
            setPdfFile(response);
          }
          setDocumentLoading(false);
        }),
      );
    }
  }, []);

  const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const isFileImageType = useMemo(
    () => pdfFile?.headers?.['content-type']?.includes('image'),
    [pdfFile],
  );

  return (
    <div className={styles.pdfThumbnail}>
      {documentLoading ? (
        <Spinner className={styles.loaderSpinner} />
      ) : isLessThan5MB ? (
        isFileImageType ? (
          <img
            src={`data:${pdfFile?.headers?.['content-type']};base64,${arrayBufferToBase64(
              pdfFile?.data,
            )}`}
            alt={pdfFile.Filename || 'Preview'}
            className={styles.imagePreview}
          />
        ) : (
          <Document
            className={styles.pdfThumbnailDocument}
            file={pdfFile}
            loading={<Spinner className={styles.loaderSpinner} />}
          >
            <Page pageNumber={1} height={180} />
          </Document>
        )
      ) : (
        <div className={styles.placeholderIcon}>
          <Icons variant={Icons.DOCUMENT_3} />
        </div>
      )}
    </div>
  );
};
