import { useState, FC, useCallback, useEffect } from 'react';
import { Avatar, DropdownCheck, OutlinedButton, SearchFieldWithDebounce } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { RadarHeader } from '../components/RadarHeader';
import { RadarPortfolioViewTypes } from '../types';
import { Table } from '../components';
import { map } from 'lodash-es';
import { getInitials } from 'utils';
import { Widget } from './components/Widget';
import { PageWrapper } from 'components-antd';
import ActionsIcon from 'pages/Properties/Search/components/Icons/ActionsIcon';
import { Popover } from 'components-antd';
import SmallDeleteIcon from 'pages/Properties/Search/components/Icons/SmallDeleteIcon';
import { push } from 'connected-react-router';
import {
  requestPropertyReferenceDataEffect,
  requestPropertyReferenceStatsEffect,
} from 'store/effects/radarPortfolio';
import {
  getPropertyReferenceDataSelector,
  getPropertyReferenceStatsSelector,
} from 'store/selectors/radarPortfolio';
import { PENDING } from 'settings/constants/apiState';
import moment from 'moment';
import { Icons } from '../Icons';
import { TEAM_ADMIN, TEAM_OWNER } from 'settings/constants/roles';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import NumberFormat from 'react-number-format';

export const RadarPortfolio: FC = () => {
  const dispatch = useDispatch();
  const [view, setView] = useState(RadarPortfolioViewTypes.ViewByClient);
  const isPropertyView = view === RadarPortfolioViewTypes.ViewByProperty;
  const { data, state } = useSelector(getPropertyReferenceDataSelector);
  const { data: stats, state: statsState } = useSelector(getPropertyReferenceStatsSelector);
  const userRole = useSelector(getAgentTeamRoleSelector);
  const isAdminOrOwner = [TEAM_ADMIN, TEAM_OWNER].includes(userRole);

  const isLoading = state === PENDING;
  const isLoadingStats = statsState === PENDING;

  const getAgentViewData = () => {
    const agentMap = new Map();

    data?.forEach((client) => {
      const { Client, AvatarUrl } = client;
      const { Agents } = Client;

      Agents?.forEach(({ Id: agentId, Bio }) => {
        // Avoid duplicate agents
        if (!agentMap.has(agentId)) {
          Client.Address.forEach((addressObj) => {
            const { address1, address2, homeValue, rentalValue } =
              addressObj?.parsedData || addressObj || {};

            const agentData = {
              name: Bio || 'Unknown',
              image: AvatarUrl || null,
              address: {
                property: { line1: address1 || '', line2: address2 || '' },
                client: {
                  name: `${client.FirstName} ${client.LastName}`,
                  image: AvatarUrl || null,
                },
                value: homeValue || 0,
                rentalValue: rentalValue || 0,
                activity: addressObj.neighbourhoodRadius || 0,
                closed: addressObj.closed ? moment(addressObj.closed).format('MM/DD/YYYY') : null,
              },
            };

            // Store the agent with relevant data
            agentMap.set(agentId, agentData);
          });
        }
      });
    });

    // Convert map values to an array of agent objects
    return Array.from(agentMap.values());
  };

  const clientViewData = data?.map(({ FirstName, LastName, Client, AvatarUrl, Id }) => {
    const name = `${FirstName} ${LastName}`;
    return {
      name,
      image: AvatarUrl,
      address: Client?.Address?.map(
        ({ closed, neighbourhoodRadius, parsedData, ...addressObj }) => {
          const { address1, address2, homeValue, rentalValue } = parsedData || addressObj || {};
          return {
            property: { line1: address1, line2: address2 },
            client: {
              name,
              image: AvatarUrl,
            },
            value: homeValue,
            rentalValue: rentalValue,
            activity: neighbourhoodRadius,
            closed: moment(closed).format('MM/DD/YYYY'),
            clientId: Id,
          };
        },
      ),
    };
  });

  const agentViewData = getAgentViewData();

  const propertiesViewData = [
    {
      name: '',
      address:
        data?.flatMap(
          ({ FirstName, LastName, Client, AvatarUrl }) =>
            Client?.Address?.map(
              ({ address, estimatedValue, estimatedRentalValue, closed, neighbourhoodRadius }) => ({
                property: { line1: address?.delivery_line_1, line2: address?.last_line },
                client: {
                  name: `${FirstName} ${LastName}`,
                  image: AvatarUrl,
                },
                value: estimatedValue,
                rentalValue: estimatedRentalValue,
                activity: neighbourhoodRadius,
                closed: moment(closed).format('MM/DD/YYYY'),
              }),
            ) || [],
        ) ?? [],
    },
  ];

  useEffect(() => {
    dispatch(requestPropertyReferenceDataEffect());
    dispatch(requestPropertyReferenceStatsEffect());
  }, []);

  const columns: any = [
    {
      title: 'Property',
      dataIndex: 'property',
      key: 'property',
      render: (property: any) => (
        <div className={styles.propertyContainer}>
          <div className={styles.iconContainer}>
            <Icons variant={Icons.CONDO} />
          </div>
          <div className={styles.textContainer}>
            <p className={styles.line1}>{property?.line1}</p>
            <p className={styles.line2}>{property?.line2}</p>
          </div>
        </div>
      ),
    },
    ...(isPropertyView || isAdminOrOwner
      ? [
          {
            title: 'Client',
            dataIndex: 'client',
            key: 'client',
            render: (client: any) => (
              <div className={styles.client}>
                <Avatar
                  avatarClassName={styles.avatarSmall}
                  src={client?.image}
                  placeholder={getInitials(client?.name)}
                />
                <p className={styles.text}>{client?.name}</p>
              </div>
            ),
          },
        ]
      : []),
    {
      title: 'Est. Value',
      dataIndex: 'value',
      key: 'value',
      render: (val: string) => (
        <NumberFormat
          thousandSeparator
          displayType="text"
          value={val}
          prefix="$"
          renderText={(val) => <p className={styles.value}>{val}</p>}
        />
      ),
    },
    {
      title: 'Est. Rental Value',
      dataIndex: 'rentalValue',
      key: 'rentalValue',
      render: (rentalValue: string) => (
        <NumberFormat
          thousandSeparator
          displayType="text"
          value={rentalValue}
          prefix="$"
          renderText={(val) => <p className={styles.text}>{val}</p>}
        />
      ),
    },
    {
      title: 'Neighborhood Activity',
      dataIndex: 'activity',
      key: 'activity',
      render: (activity: number) => <p className={styles.text}>{activity}</p>,
    },
    {
      title: 'Closed',
      dataIndex: 'closed',
      key: 'closed',
      render: (closed: string) => <p className={styles.text}>{closed}</p>,
    },
    ...(isPropertyView
      ? [
          {
            title: '',
            dataIndex: 'id',
            key: 'id',
            render: (id: any) => (
              <p className={styles.value}>
                <div className={styles.actionBtn}>
                  <Popover
                    overlayClassName={styles.popoverOverlay}
                    placement="bottomRight"
                    trigger="hover"
                    content={
                      <div className={styles.actionList}>
                        <div onClick={(e) => {}} className={styles.actionListBtn}>
                          <SmallDeleteIcon className={styles.icon} />
                          <span>Remove</span>
                        </div>
                      </div>
                    }
                  >
                    <div>
                      <ActionsIcon className={styles.actionBtnIcon} color={'#252d44'} />
                    </div>
                  </Popover>
                </div>
              </p>
            ),
          },
        ]
      : []),
  ];

  const ViewItems = [
    ...[
      isAdminOrOwner
        ? {
            label: 'View By Agent',
            value: RadarPortfolioViewTypes.ViewByClient,
          }
        : {
            label: 'View By Client',
            value: RadarPortfolioViewTypes.ViewByAgent,
          },
    ],
    {
      label: 'View By Property',
      value: RadarPortfolioViewTypes.ViewByProperty,
    },
  ];

  const handleRowClick = useCallback(
    ({ property, clientId, client }) =>
      dispatch(
        push(
          `property?address1=${encodeURIComponent(property?.line1)}&address2=${encodeURIComponent(
            property?.line2,
          )}&clientId=${clientId}&clientName=${client?.name}`,
        ),
      ),
    [dispatch],
  );

  return (
    <PageWrapper version={2} mainPageContentStyle={styles.pageWrapper}>
      <RadarHeader
        title="Portfolio"
        actions={
          <div className={styles.actions}>
            <div className={styles.row}>
              <SearchFieldWithDebounce
                className={styles.inputWrap}
                classNameInput={styles.searchInput}
                iconClassName={styles.icon}
                resetAction={() => {}}
                sendAction={() => {}}
                isLoading={isLoading}
                placeholder="Search"
              />
              <div className="seperator" />
              <DropdownCheck
                dropdownFields={ViewItems}
                defaultValue={RadarPortfolioViewTypes.ViewByClient}
                onChange={(val: string) => {
                  setView(val as RadarPortfolioViewTypes);
                }}
              />
            </div>
            <div className={styles.row}>
              <div className="seperator" />
              <OutlinedButton title="All Filters" />
            </div>
          </div>
        }
      />
      <div className={styles.pageContent}>
        <div className={styles.tableContainer}>
          <div className={styles.widgetsContainer}>
            <Widget
              isLoading={isLoadingStats}
              title="Portfolio Value"
              subCount={stats?.homeValueGrowth ?? '0'}
              count={String(stats?.homeValueMonthlyStats?.[0]?.total) ?? '0'}
              data={stats?.homeValueMonthlyStats}
            />
            {isAdminOrOwner && (
              <Widget
                isLoading={isLoading}
                title="Agents"
                count={String(agentViewData?.length ?? 0)}
                color="yellow"
              />
            )}
            <Widget
              isLoading={isLoading}
              title="Clients"
              count={String(clientViewData?.length ?? 0)}
              color="lightBlue"
            />
            <Widget
              isLoading={isLoading}
              title="Properties"
              count={String(propertiesViewData[0]?.address?.length ?? 0)}
              color="purple"
            />
          </div>
          {map(
            isPropertyView ? (isAdminOrOwner ? agentViewData : propertiesViewData) : clientViewData,
            ({ address, name = '' }) => (
              <div>
                <Table
                  title={name}
                  data={address}
                  columns={columns}
                  count={address?.length}
                  handleRowClick={handleRowClick}
                  titlePrefix={
                    <Avatar
                      avatarClassName={styles.avatar}
                      src={null}
                      placeholder={getInitials(name)}
                    />
                  }
                  disableHeader={isPropertyView}
                  isLoading={isLoading}
                />
              </div>
            ),
          )}
        </div>
      </div>
    </PageWrapper>
  );
};
