import React, { FC, memo, useMemo } from 'react';
import PropTypes from 'prop-types';

interface Props {
  className?: string;
  color?: string;
  width?: number;
  height?: number;
}

const Chain: FC<Props> = memo(
  ({ className = '', color = 'currentColor', width = 25, height = 24 }) =>
    useMemo(
      () => (
        <div className={className}>
          <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 13.25L6.5 12L8 10.75M11 10.75L12.5 12L14 13.25M17 13.25L18.5 12L20 10.75"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <circle cx="3.5" cy="14.5" r="1.5" stroke={color} strokeWidth="2" />
            <circle cx="21.5" cy="9.5" r="1.5" stroke={color} strokeWidth="2" />
            <circle cx="15.5" cy="14.5" r="1.5" stroke={color} strokeWidth="2" />
            <circle cx="9.5" cy="9.5" r="1.5" stroke={color} strokeWidth="2" />
          </svg>
        </div>
      ),
      [className, color, width, height],
    ),
);

export default Chain;
