import React from 'react';
import PropTypes from 'prop-types';

const Geotag = ({ className, color = '#ADADAD' }) => (
  <div className={className}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <circle cx="12" cy="11" r="3" stroke={color} strokeWidth="2" />
      <path
        d="M20 11C20 15.6072 15.283 19.9196 13.0931 21.6734C12.4466 22.1912 11.5533 22.1912 10.9069 21.6734C8.71695 19.9196 4 15.6072 4 11C4 6.58172 7.58172 3 12 3C16.4183 3 20 6.58172 20 11Z"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  </div>
);

Geotag.propTypes = {
  className: PropTypes.string,
};

Geotag.defaultProps = {
  className: '',
};

export default Geotag;
