import { PhoneNumber } from 'components';
import { MemberInput } from './components';
import validator from 'validator';

import styles from './styles.module.scss';
import { setOptionalEmail } from 'pages/Workshop/Transactions/TransactionCreate/helpers';

export const ExternalMember = (props) => {
  const { participant, index, updateParticipantValues, fullWidth } = props;

  const onEmailChange = (value, index) => {
    const inputValue = value.trim();
    updateParticipantValues(
      {
        customEmail: inputValue,
        error:
          inputValue && !validator.isEmail(inputValue) ? 'Please enter a valid email' : undefined,
      },
      index,
    );
  };

  const onInputNameChange = (firstName: string, lastName: string, index: number) => {
    updateParticipantValues(
      {
        customName: firstName && lastName ? `${firstName} ${lastName}` : '',
        firstName,
        lastName,
      },
      index,
    );
  };

  const onPhoneInput = (value: string, index: number) => {
    const phoneNum = value.trim();
    updateParticipantValues(
      {
        Phones: [
          {
            PhoneNumber: phoneNum,
            PhoneType: 'Mobile',
            IsPrimary: true,
            IsVerified: false,
          },
        ],
      },
      index,
    );
  };

  const onPhoneInputBlur = (value: string, index: number) => {
    const phoneNum = value.trim();
    updateParticipantValues(
      {
        phoneNoError:
          phoneNum && !validator.isMobilePhone(phoneNum, 'en-US')
            ? 'Please enter a valid phone number'
            : undefined,
      },
      index,
    );
  };

  return (
    <div className={styles.externalMemberWrapper}>
      <div className={styles.rowFlex}>
        <div>
          <div className={styles.displayInputLabelWrap}>
            <p className={styles.inputLabel}>First Name</p>
          </div>
          <MemberInput
            value={participant.firstName}
            placeholder=" "
            onUpdate={() => {}}
            onBlurInput={(value) => onInputNameChange(value.trim(), participant.lastName, index)}
            inputHolderClassName={styles.customName}
            className={styles.memberInput}
          />
        </div>

        <div>
          <div className={styles.displayInputLabelWrap}>
            <p className={styles.inputLabel}>Last Name</p>
          </div>
          <MemberInput
            value={participant.lastName}
            placeholder=" "
            onUpdate={() => {}}
            onBlurInput={(value) => onInputNameChange(participant.firstName, value.trim(), index)}
            inputHolderClassName={styles.customName}
            className={styles.memberInput}
          />
        </div>
      </div>

      <div className={!fullWidth ? styles.rowFlex : ''}>
        <div>
          <div className={styles.displayInputLabelWrap}>
            <p className={styles.inputLabel}>Email</p>
            {setOptionalEmail(participant) && (
              <p className={styles.inputLabelOptional}>(Optional)</p>
            )}
          </div>
          <MemberInput
            value={participant.customEmail}
            error={participant.error}
            placeholder=" "
            onUpdate={() => {}}
            onBlurInput={(value) => onEmailChange(value, index)}
            inputHolderClassName={styles.customName}
            className={styles.memberInput}
          />
        </div>

        <div>
          <div className={styles.displayInputLabelWrap}>
            <p className={styles.inputLabel}>Phone</p>
            <p className={styles.inputLabelOptional}>(Optional)</p>
          </div>
          <PhoneNumber
            className={styles.clientPhoneInput}
            placeholder="(---) --- -----"
            value={
              participant?.Phones && participant.Phones.length > 0
                ? participant.Phones[0].PhoneNumber
                : null
            }
            error={participant.phoneNoError}
            errorClassName={styles.errorClass}
            onUpdate={(value) => onPhoneInput(value, index)}
            onBlurInput={(value) => onPhoneInputBlur(value, index)}
          />
        </div>
      </div>
    </div>
  );
};
