import classNames from 'classnames';
import moment from 'moment';
import { FormStatus } from '../../../FormStatus';
import { ICON_VARIANT_TYPE, Icons } from 'pages/Workshop/Forms/Icons';
import styles from './styles.module.scss';

export const FormDetailHeader = (props) => {
  const {
    formDetails,
    openMegaPhoneModal,
    disableSections,
    selectedFormStatus,
    isFormCreator,
    showMegaPhone,
  } = props;

  return (
    <div className={styles.formDocumentHeader}>
      <div>
        <p className={styles.formName}>{formDetails?.formName}</p>
        <p className={styles.formSent}>
          {formDetails?.isCompleted ? 'Completed: ' : formDetails?.isVoid ? 'Voided: ' : 'Sent: '}
          {moment(formDetails?.formDocument?.date).format('MM/DD/YYYY [at] hh:mm A')}
        </p>
      </div>

      <div
        className={classNames(styles.formHeaderStatusPanel, {
          [styles.disabledSection]: disableSections,
        })}
      >
        <FormStatus selectedFormStatus={selectedFormStatus} />

        {isFormCreator && showMegaPhone && (
          <div className={styles.megaPhoneIcon}>
            <Icons variant={ICON_VARIANT_TYPE.MEGAPHONE} onClick={() => openMegaPhoneModal()} />
          </div>
        )}
      </div>
    </div>
  );
};
