import classNames from 'classnames';
import { Badge } from 'components-antd';
import { Dropdown } from 'components-antd';
import { ArrowDown, Close } from 'components/Icons';

import styles from './styles.module.scss';
import { HomeOutlined } from '@ant-design/icons';
import { map } from 'lodash-es';
import { Button } from 'components';
import Icons from './icons';
import { getComparesSelector } from 'store/selectors/feed';
import { useDispatch, useSelector } from 'react-redux';
import {
  openShareDrawerEffect,
  resetFullDataComparePropertiesEffect,
  resetPropertiesWholeTabsEffect,
  setComparesEffect,
  setMultipleModeEffect,
  setSharePropertyEffect,
} from 'store/effects';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { LocationService } from 'services';
import { openNewTab } from 'services/newTab';
import { routes } from 'settings/navigation/routes';
import { useHistory, useLocation } from 'react-router-dom';
import { openCommentsDrawerEffect } from 'store/effects/drawers/comments';
import { useState } from 'react';
import {
  requestScheduleTourEffect,
  toggleFavoriteListingDetailEffect,
} from 'store/effects/listingDetail';
import { getCurrentContextSelector } from 'store/selectors/context';
import { getSearchResultsV2Effect } from 'store/effects/searchResults/searchV2';
import { useNoMlsAccessHandler } from 'pages/Properties/Feed/Properties/hooks/useNoMlsAccessHandler';
import { showErrorMessage, showSuccessMessage, getFirstImage } from 'helpers';
import AgentSelectionModal from 'pages/Properties/ListingDetail/components/AgentSelectionModal';

const locationService = new LocationService();

export const PropertyCart = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const isFeed = pathname.includes('/properties/feed');
  const isSearch = pathname.includes('/properties/search/results');
  const [visible, setVisible] = useState(false);
  const [showAgentSelectionModal, setShowAgentSelectionModal] = useState(false);
  const compares = useSelector(getComparesSelector);
  const { isAgent } = useSelector(getUserRolesMapSelector);
  const propertyList = compares?.data ?? [];
  const query = locationService.setLocation(location).getQuery();
  const context = useSelector(getCurrentContextSelector);
  const [isLoading, setIsLoading] = useState(false);

  const { wrappedEffect: getSearchResultsEffectWrapped } =
    useNoMlsAccessHandler(getSearchResultsV2Effect);

  const handleDropdownVisibleChange = (flag) => {
    setVisible(flag); // Update dropdown visibility
  };

  const handleItemClick = (e, onClick) => {
    // Close the dropdown when an item is clicked
    setVisible(false);
    // Call the onClick function of the item
    onClick(e);
  };

  const getQueryParameter = () => {
    if (compares.data.length > 1) {
      let str = '';
      for (let i = 0; i < compares.data.length; i++) {
        str += compares.data[i].Id;
        if (i != compares.data.length - 1) {
          str += ',';
        }
      }
      if (query?.activeTab) {
        str += '&activeTab=' + query?.activeTab;
      }
      return str;
    } else {
      return '';
    }
  };

  const onCompare = (e) => {
    let path = routes.listings + '?ids=' + getQueryParameter();
    dispatch(setMultipleModeEffect(false));
    dispatch(resetFullDataComparePropertiesEffect());
    e.preventDefault();
    if (e.ctrlKey || e.metaKey) {
      openNewTab(path);
    } else {
      history.push(path);
    }
  };

  const handleRemove = (id) => {
    const newCompares = compares.data.filter((property) => property.Id !== id);
    dispatch(setComparesEffect(newCompares));
  };

  const handleRemoveAll = () => {
    dispatch(setComparesEffect([]));
  };

  const onComment = () => {
    dispatch(setSharePropertyEffect(compares?.data));
    dispatch(openCommentsDrawerEffect({ open: true }));
    dispatch(setMultipleModeEffect(false));
    dispatch(resetFullDataComparePropertiesEffect());
  };

  const onShare = () => {
    dispatch(setSharePropertyEffect(compares?.data));
    dispatch(openShareDrawerEffect({ open: true }));
    dispatch(setMultipleModeEffect(false));
    dispatch(resetFullDataComparePropertiesEffect());
  };

  const onFavorite = () => {
    dispatch(
      toggleFavoriteListingDetailEffect(
        {
          id: compares?.data?.map(({ Id }) => Id),
          isFavorite: true,
          contextKey: context.ContextKey,
        },
        {},
        (err) => {
          if (!err) {
            if (isFeed) dispatch(resetPropertiesWholeTabsEffect());
            if (isSearch) dispatch(getSearchResultsEffectWrapped());
            dispatch(setMultipleModeEffect(false));
            dispatch(resetFullDataComparePropertiesEffect());
          }
        },
      ),
    );
  };

  const onScheduleTourSubmit = () => {
    let agentId = context.Agents[0].Id;
    if (!isLoading) {
      setIsLoading(true);
      dispatch(
        requestScheduleTourEffect(
          { propertyInfo: compares?.data, agentId },
          null,
          (error, response) => {
            if (response?.status === 200) {
              showSuccessMessage('Tour Request Sent');
              dispatch(setMultipleModeEffect(false));
              dispatch(resetFullDataComparePropertiesEffect());
            } else showErrorMessage('Tour not scheduled successfully');
            setIsLoading(false);
          },
        ),
      );
    }
  };

  const onScheduleTour = () => {
    setShowAgentSelectionModal(true);
  };

  const agentActionList = [
    { label: 'Compare', icon: Icons.COMPARE, onClick: onCompare },
    // { label: 'Add To Watchlist', icon: Icons.WATCHLIST },
    { label: 'Leave Comment(s)', icon: Icons.COMMENT, onClick: onComment },
    { label: 'Share', icon: Icons.SHARE, onClick: onShare },
  ];

  const clientActionList = [
    { label: 'Compare', icon: Icons.COMPARE, onClick: onCompare },
    { label: 'Schedule Tour(s)', icon: Icons.SCHEDULE, onClick: onScheduleTour },
    { label: 'Leave Comment(s)', icon: Icons.COMMENT, onClick: onComment },
    { label: 'Add to Favorites', icon: Icons.HEART, onClick: onFavorite },
  ];

  const PropertyActions = (
    <div className={styles.container}>
      {map(isAgent ? agentActionList : clientActionList, ({ label, icon, onClick }) => (
        <div className={styles.list} onClick={(e) => handleItemClick(e, onClick)}>
          <Icons variant={icon} />
          <p>{label}</p>
        </div>
      ))}
    </div>
  );

  const PropertyList = (
    <div className={styles.container}>
      <div className={styles.header}>
        <p className={styles.title}>Selected Properties</p>
        <p className={styles.clear} onClick={handleRemoveAll}>
          Clear All
        </p>
      </div>
      {map(propertyList, ({ PhotoUrls, Media, Address, Id }) => {
        const { Line1, City, State, Zip } = Address;
        const firstImage = getFirstImage(Media);
        const ImageToSend = firstImage
          ? firstImage
          : PhotoUrls && PhotoUrls?.length
          ? PhotoUrls?.[0]
          : '';
        return (
          <div className={styles.item}>
            <div className={styles.row}>
              <img src={ImageToSend} />
              <div className={styles.infoContainer}>
                <p className={styles.line1}>{Line1 ?? ''}</p>
                <p className={styles.line2}>{`${City ?? ''}, ${State ?? ''} ${Zip ?? ''}`}</p>
              </div>
            </div>
            <Close
              className={styles.close}
              color="#747475"
              onClick={() => {
                handleRemove(Id);
              }}
            />
          </div>
        );
      })}

      <Dropdown
        overlay={PropertyActions}
        placement="bottomLeft"
        getPopupContainer={(triggerNode) => triggerNode}
        trigger={['click']}
        overlayClassName={styles.propertiesActions}
      >
        <Button
          title="Actions"
          icon={<ArrowDown color="#FFFFFF" size={14} className={styles.arrow} />}
          titleClassName={styles.button}
          className={styles.fullWidth}
        />
      </Dropdown>
    </div>
  );

  if (propertyList?.length)
    return (
      <div className={classNames(styles.filterWrap, props.className)}>
        {showAgentSelectionModal && (
          <AgentSelectionModal
            showModal={showAgentSelectionModal}
            onCloseModal={() => setShowAgentSelectionModal(false)}
            onSubmit={onScheduleTourSubmit}
          />
        )}
        <Dropdown
          overlay={PropertyList}
          placement="bottomLeft"
          getPopupContainer={(triggerNode) => triggerNode}
          trigger={['click']}
          overlayClassName={styles.selectedProperties}
          open={visible}
          onOpenChange={handleDropdownVisibleChange}
        >
          <a
            onClick={(e) => e.preventDefault()}
            className={classNames(styles.filterButton, 'mosaikDropdownButton')}
          >
            <Badge count={propertyList?.length} size="small">
              <HomeOutlined className={styles.optionIcon} />
            </Badge>
            <ArrowDown className={classNames(styles.icon, { [styles.open]: open })} />
          </a>
        </Dropdown>
      </div>
    );
  else return null;
};
