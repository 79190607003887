import { useDispatch, useSelector } from 'react-redux';

import { Close } from 'components/Icons';
import { setSelectedGraphAction, setShowSubChartsAction } from 'store/actions/clarity';
import { getExpandChart } from 'store/selectors/clarity';

import styles from './styles.module.scss';

export const ModalHeader = ({ title }) => {
  const dispatch = useDispatch();
  const expandChart = useSelector(getExpandChart);

  const onClose = () => {
    dispatch(setSelectedGraphAction({ isGraphSelected: false, graphName: '' }));
    if (expandChart) dispatch(setShowSubChartsAction({ value: true }));
  };

  return (
    <div className={styles.modalHeader}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.closeButton} onClick={onClose}>
        <Close />
      </div>
    </div>
  );
};
