import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';
import { Row, Col } from 'antd';

import { PageWrapper, LinkExpired, NoResults, PageNotFound, Spinner } from 'components';
import { Header } from './Header';
import { BookingCalendar } from './BookingCalendar';
import { LeftPanel } from './LeftPanel';
import { BookingForm } from './BookingForm';
import { Confirmation } from './Confirmation';
import { getFormattedTime, getTimeZone, showErrorMessage } from 'helpers';

import {
  requestGetShowingDetailsEffect,
  requestGetShowingAvailabilityEffect,
  requestGetShowingAppointmentDetailsEffect,
} from 'store/effects/showingAppointment';
import {
  requestFillShowingDetails,
  requestFillShowingAvailability,
} from 'store/actions/showingAppointment';
import { DateTime, AppointmentForm } from 'types';
import { getShowingAppointmentDetails } from 'store/selectors/showingAppointment';
import { useExternalVerificationRestriction } from 'features/externalVerification/useExtermalVerificationRestriction';
import { ExternalVerificationWallModal } from 'features/externalVerification/ExternalVerificationWallModal/ExternalVerificationWallModal';
import {
  getShowingAppointmentExternalVerificationData,
  sendShowingAppointmentExternalVerificationLink,
} from 'api/showingAppointment';

import styles from './styles.module.scss';
import { useResize } from 'hooks';

export const ShowingAppointment = (props) => {
  const { match } = props;
  const dispatch = useDispatch();

  const [isEditMode, setEditMode] = useState(false);
  const [isError, setIsError] = useState({
    status: false,
    resObj: null,
    message: '',
    type: '',
  });
  const [isCalendarView, setCalendarViewMode] = useState(true);
  const [isAppointmentCreated, setAppointmentCreated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDateTime, setDateTime] = useState<DateTime>({
    date: null,
    startTime: null,
    endTime: null,
    timeZone: null,
  });
  const [appointmentFormData, setAppointmentFormData] = useState<AppointmentForm>();
  const preFilledAppoitnmentData: AppointmentForm = useSelector(getShowingAppointmentDetails);

  const { token: externalToken, isTokenLoaded: isExternalTokenLoaded } =
    useExternalVerificationRestriction();

  const [isVerificationModalVisible, setVerificationModalVisible] = useState(false);

  const { screen } = useResize();
  const { mobileWidth, mobileSmallWidth, width } = screen;

  useEffect(() => {
    if (match) {
      const { Id, appointmentId } = match.params;

      // for showing appointment creation
      if (Id) {
        dispatch(
          requestGetShowingDetailsEffect({ Id }, {}, (req, res) => {
            if (res?.data?.statusCode != 200) {
              const SellerAgent = res?.data?.value?.SellerAgent;
              setIsError({
                ...isError,
                resObj: SellerAgent,
                status: true,
                message: res?.data?.value?.errorMessage,
                type: 'LinkExpired',
              });
            } else {
              dispatch(requestGetShowingAvailabilityEffect({ Id }));
              setAppointmentFormData({
                ...appointmentFormData,
                ...preFilledAppoitnmentData,
              });
            }
            setIsLoading(false);
          }),
        );
      }

      // for existing showing appointment view
      if (appointmentId) {
        if (isExternalTokenLoaded === true && externalToken) {
          dispatch(
            requestGetShowingAppointmentDetailsEffect(
              { Id: appointmentId },
              externalToken,
              (err, res) => {
                if (err || res?.data?.statusCode != 200) {
                  if (err.response.data.code === 'EXTERNAL_AUTH_REQUIRED') {
                    setIsError({
                      ...isError,
                      status: true,
                      message: '',
                      type: 'NoRecord',
                    });
                    setVerificationModalVisible(true);
                  } else {
                    setIsError({
                      ...isError,
                      status: true,
                      message: err.response.data?.errorMessage ?? err.message,
                      type: 'NoRecord',
                    });
                    showErrorMessage('Showing Not Found!');
                  }
                } else {
                  const apptData = res.data.value;
                  setAppointmentFormData({
                    ...appointmentFormData,
                    ...apptData,
                  });
                  setDateTime({
                    ...selectedDateTime,
                    date: apptData?.AppointmentDate,
                    startTime: apptData?.AppointmentStartTime,
                    endTime: apptData?.AppointmentEndTime,
                  });
                  dispatch(requestFillShowingDetails({ data: apptData?.ShowingDetails }));
                  dispatch(requestFillShowingAvailability({ data: apptData?.ShowingAvailability }));
                  setAppointmentCreated(true);
                }
                setIsLoading(false);
              },
            ),
          );
        } else if (isExternalTokenLoaded === true) {
          setVerificationModalVisible(true);
        }
      }
    }
  }, [isExternalTokenLoaded]);

  useEffect(() => {
    if (!selectedDateTime?.timeZone) {
      const timeZone = getTimeZone();
      setDateTime({ ...selectedDateTime, timeZone });
    }
  }, [selectedDateTime?.timeZone]);

  const changeEditMode = (editMode: boolean, calendarView: boolean) => {
    setEditMode((val) => editMode);
    setCalendarViewMode((val) => calendarView);
  };

  return (
    <PageWrapper className={styles.wrapper} contentContainerClassName={styles.servicesContainer}>
      <div className={styles.pageWrapper}>
        {isLoading ? (
          <Spinner loaderClassName={classNames(styles.loader)} />
        ) : (
          <>
            {isError.status ? (
              getErrorComponent(isError.type, isError?.resObj, isError?.message)
            ) : (
              <>
                {!isAppointmentCreated ? (
                  <>
                    <Header mobileWidth={mobileWidth} />

                    <div className={styles.mainAreaWrapper}>
                      <div></div>

                      <div className={styles.mainArea}>
                        {isCalendarView ? (
                          <BookingCalendar
                            changeEditMode={changeEditMode}
                            setDateTime={setDateTime}
                            selectedDateTime={selectedDateTime}
                            appointmentFormData={appointmentFormData}
                            setAppointmentFormData={setAppointmentFormData}
                            mobileSmallWidth={mobileSmallWidth}
                            screenWidth={width}
                          />
                        ) : (
                          <BookingForm
                            setIsLoading={setIsLoading}
                            setAppointmentCreated={setAppointmentCreated}
                            appointmentFormData={appointmentFormData}
                            setAppointmentFormData={setAppointmentFormData}
                            selectedDateTime={selectedDateTime}
                            setCalendarViewMode={setCalendarViewMode}
                          />
                        )}
                      </div>

                      {!mobileWidth ? (
                        <div className={styles.leftPanelArea}>
                          <LeftPanel />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                ) : (
                  <div className={styles.confirmationArea}>
                    <Confirmation
                      setIsLoading={setIsLoading}
                      selectedDateTime={selectedDateTime}
                      setAppointmentFormData={setAppointmentFormData}
                      appointmentFormData={appointmentFormData}
                      setAppointmentCreated={setAppointmentCreated}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}

        <ExternalVerificationWallModal
          isModalOpen={isVerificationModalVisible}
          sendVerificationLink={async () => {
            const response = await sendShowingAppointmentExternalVerificationLink({
              uuid: match.params.appointmentId,
            });

            return response.data.value;
          }}
          getExternalVerificationInfo={async () => {
            const response = await getShowingAppointmentExternalVerificationData({
              uuid: match.params.appointmentId,
            });
            return response.data.value;
          }}
        />
      </div>
    </PageWrapper>
  );
};

const getDateTimeView = (selectedDateTime) => {
  return (
    <h4>
      {moment(selectedDateTime?.date).format('dddd, MMMM D') +
        (selectedDateTime.startTime ? ' at ' + getFormattedTime(selectedDateTime?.startTime) : '') +
        (selectedDateTime?.timeZone ? ' ( ' + selectedDateTime?.timeZone + ' ) ' : '')}
    </h4>
  );
};

const getErrorComponent = (type, resObj, message) => {
  switch (type) {
    case 'LinkExpired':
      return <LinkExpired info={message} SellerAgent={resObj} />;
    case 'NoRecord':
      return <NoResults text={message} />;
    default:
      return <PageNotFound />;
  }
};
