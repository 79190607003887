import React, { useState } from 'react';
import { Button } from 'components-antd';
import moment from 'moment';
import { useCampaignKitTestClock } from './hooks/use-campaign-kit-test-clock.hook';
import { ClockCircleOutlined } from '@ant-design/icons';

import styles from './styles.module.scss';
import classNames from 'classnames';
import FastForwardClockModal from 'pages/Radar/RadarKits/CampaignKitTestClock/FastForwardModal';

type Props = {
  clientId: string;
  campaignId: string;
};

export const CampaignKitTestClock: React.FC<Props> = ({ clientId, campaignId }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const handleAdvanceTime = (date) => {
    handleFastForwardTestClock(date);
    handleCloseModal();
  };

  const {
    pollTestClock,
    testClock,
    isCreating,
    isResetting,
    isFastForwarding,
    isFetching,
    handleCreateTestClock,
    handleResetTestClock,
    handleFastForwardTestClock,
  } = useCampaignKitTestClock(clientId, campaignId);

  return (
    <div className={styles.testClockWrapper}>
      <FastForwardClockModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onAdvance={handleAdvanceTime}
        frozenDate={testClock?.FrozenTime}
      />
      {!testClock ? (
        <div className={styles.banner}>
          <span>No Test Clock exists for this member. Would you like to enable it?</span>
          <Button
            onClick={handleCreateTestClock}
            className={styles.enableButton}
            loading={isCreating}
          >
            Enable Test Clock
          </Button>
        </div>
      ) : (
        <div className={styles.clockContainer}>
          <div className={styles.clockInfo}>
            <div className={styles.campaignFlexRow}>
              <ClockCircleOutlined width={24} height={24} />
              {isFastForwarding ? (
                <div className={styles.campaignFlexCol}>
                  <span>The time of this test clock is advancing</span>
                  <span>
                    This can take up to a few minutes depending on the number of upcoming events
                  </span>
                </div>
              ) : (
                <div className={styles.campaignFlexCol}>
                  <span>Current simulation date:</span>
                  <span>{moment(testClock.FrozenTime).format('MMMM D, YYYY')}</span>
                </div>
              )}
            </div>
            <div className={styles.buttons}>
              <Button
                onClick={handleOpenModal}
                className={styles.fastForwardButton}
                loading={isFastForwarding}
                disabled={isResetting || isFastForwarding}
              >
                Advance time
              </Button>
              <Button
                onClick={handleResetTestClock}
                className={styles.resetButton}
                loading={isResetting}
                disabled={isResetting || isFastForwarding}
              >
                Reset Clock
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
