import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import notification from 'antd/lib/notification';

import { FORM_PROCESS_SCREEN } from 'app-constants/formProcess';
import {
  AddFormCommentType,
  EditFormCommentType,
  FormDocumentType,
  FieldInfoType,
  StrikeThrough,
} from 'types';
import { FormNotAvailableScreen } from '../../components/FormNotAvailableScreen';
import { FormStateManagerProps } from '../FormStateManager';
import { SendDocument } from '../../Ancillary/SendDocument';
import { EditFormManager, PreviewManager } from './managers';
import { SignFormManager } from './managers/SignFormManager';
import {
  acceptTermsAndConditionsAnonEffect,
  acceptTermsAndConditionsAuthEffect,
  deferQuestionAnonEffect,
  deferQuestionAuthEffect,
  undoDeferQuestionAnonEffect,
  undoDeferQuestionAuthEffect,
  unlockFormDocumentAnonEffect,
  unlockFormDocumentAuthEffect,
  updateStrikeThroughAnonEffect,
  updateStrikeThroughAuthEffect,
} from 'store/effects/formProcess';
import { link as linkTo } from 'settings/navigation/link';
import { ConfirmationModal, ConfirmationModalProps, Modal } from 'components-antd';
import { routes } from 'settings/navigation/routes';
import {
  addFormCommentEffect,
  deleteFormCommentEffect,
  editFormCommentEffect,
  getFormCommentsEffect,
  getFormCommentUsersEffect,
  resetFormCommentStateEffect,
  toggleResolveThreadEffect,
} from 'store/effects/formComment';
import { formCommentSelector } from 'store/selectors/formComment';
import { FormCommentPanel, FormCommentPopover, TermsAndConditionsModal } from '../../components';
import { LocalStorage } from 'services';
import styles from './style.module.scss';

interface FormScreenManagerProps extends FormStateManagerProps {
  formDocument: FormDocumentType;
  refresh: () => void;
}

export const FormScreenManager = ({
  formDocument,
  anonData,
  authData,
  refresh,
  webView,
}: FormScreenManagerProps) => {
  const { screen, showUnlock, isEditSign, showTermsAndConditions, allowStrikeThrough, questions } =
    formDocument;

  const noCreatorInput =
    screen === FORM_PROCESS_SCREEN.CreatorEdit && questions?.every((q) => q.disabled);

  const formComment = useSelector(formCommentSelector);

  const dispatch = useDispatch();
  const { state: formState } = useLocation<any>();
  const memoizedState = useMemo(() => formState, []);

  const [formCommentFieldId, setFormCommentFieldId] = useState<string>('');
  const [postForm, setPostForm] = useState(false);
  const [creatorPreview, setCreatorPreview] = useState(false);
  const [preview, setPreview] = useState(false);
  const [signPreview, setSignPreview] = useState(false);
  const [onlyPreview, setOnlyPreview] = useState(false);
  const [commentPanelOpen, setCommentPanelOpen] = useState(false);

  const [forceSign, setForceSign] = useState(false);

  const [showUnlockButton, setUnlockButton] = useState(false);

  const [agreeBtnLoading, setAgreeBtnLoading] = useState(false);

  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const [confirmationModalProps, setConfirmationModalProps] = useState<
    ConfirmationModalProps | undefined
  >();

  const [fieldInfo, setFieldInfo] = useState<FieldInfoType>();

  useEffect(() => {
    setUnlockButton(!!showUnlock);
  }, [showUnlock]);

  const history = useHistory();

  const handleCreatorFinish = () => {
    setPostForm(true);
    setCreatorPreview(false);
    setPreview(false);
  };

  const handleEditForm = () => {
    setPreview(false);
    setSignPreview(false);
    setCreatorPreview(false);
  };

  const openUnlockConfirmationModal = () => {
    setConfirmationModalProps({
      okText: 'Unlock',
      confirmText: 'Are you sure you want to unlock and edit this form?',
      confirmDescription: 'Each party will need to sign the form again.',
      onOk: handleUnlock,
    });
  };

  const openExitConfirmationModal = () => {
    const url = LocalStorage.getMobileRedirectionUrl() || '';
    if (webView && url) {
      window.location.replace(url);
    } else {
      handleExit();
    }
  };

  const handlePreviewExit = () => {
    if (creatorPreview) {
      handleCreatorFinish();
    } else {
      openExitConfirmationModal();
    }
  };

  const handleUnlock = () => {
    setConfirmationLoading(true);
    if (authData) {
      dispatch(
        unlockFormDocumentAuthEffect(authData, (err, response) => {
          setConfirmationLoading(false);
          if (!err) {
            const { formProcessPublicId, formDocumentPublicId } = response.data.value;
            history.push(
              linkTo.toWorkshopFormProcessDocument({
                formProcessPublicId,
                formDocumentPublicId,
              }),
            );
            location.reload();
          }
        }),
      );
    } else if (anonData) {
      dispatch(
        unlockFormDocumentAnonEffect({ Token: anonData.token, type: anonData.type }, (err) => {
          setConfirmationLoading(false);
          if (!err) {
            location.reload();
          }
        }),
      );
    }
  };

  const handleDeferQuestions = async (questionsUUID, defer, reload, handleResponse) => {
    if (authData) {
      dispatch(
        deferQuestionAuthEffect({ ...authData, questionsUUID, defer }, (err) => {
          handleResponse();
          if (!err) {
            if (reload) {
              refresh();
            } else if (screen === FORM_PROCESS_SCREEN.CreatorEdit) {
              handleCreatorFinish();
            }
          }
        }),
      );
    } else if (anonData) {
      dispatch(
        deferQuestionAnonEffect(
          { Token: anonData.token, type: anonData.type, questionsUUID, defer },
          (err) => {
            handleResponse();
            if (!err) {
              if (reload) {
                refresh();
              } else if (screen === FORM_PROCESS_SCREEN.CreatorEdit) {
                handleCreatorFinish();
              }
            }
          },
        ),
      );
    }
  };

  const handleUndoDeferQuestions = async (questionsUUID, reload, handleResponse) => {
    if (authData) {
      dispatch(
        undoDeferQuestionAuthEffect({ ...authData, questionsUUID }, (err) => {
          handleResponse();
          if (!err) {
            if (reload) {
              refresh();
            } else if (screen === FORM_PROCESS_SCREEN.CreatorEdit) {
              handleCreatorFinish();
            }
          }
        }),
      );
    } else if (anonData) {
      dispatch(
        undoDeferQuestionAnonEffect(
          { Token: anonData.token, type: anonData.type, questionsUUID },
          (err) => {
            handleResponse();
            if (!err) {
              if (reload) {
                refresh();
              } else if (screen === FORM_PROCESS_SCREEN.CreatorEdit) {
                handleCreatorFinish();
              }
            }
          },
        ),
      );
    }
  };

  const handleGetFormCommentUsers = () =>
    dispatch(getFormCommentUsersEffect({ authData, anonData }));

  const handleGetFormComments = () =>
    dispatch(
      getFormCommentsEffect({
        authData,
        anonData,
      }),
    );

  useEffect(() => {
    if (
      screen === FORM_PROCESS_SCREEN.CreatorEdit ||
      screen === FORM_PROCESS_SCREEN.CreatorEditPreview ||
      screen === FORM_PROCESS_SCREEN.CreatorSignPreview ||
      screen === FORM_PROCESS_SCREEN.Edit ||
      screen === FORM_PROCESS_SCREEN.EditPreview ||
      screen === FORM_PROCESS_SCREEN.Sign ||
      screen === FORM_PROCESS_SCREEN.SignPreview
    ) {
      handleGetFormCommentUsers();
      handleGetFormComments();
    } else {
      dispatch(resetFormCommentStateEffect());
    }
  }, []);

  const goBackToFormsPageWithQueryParam = () => {
    history.replace({
      pathname: routes.workshopForms,
      search: `?from=signature&formId=${authData?.formProcessPublicId}`,
    });
  };

  const handleAddFormComment = async (
    payload: AddFormCommentType,
    setSending: (isSending: boolean) => void,
  ) => {
    setSending(true);
    dispatch(
      addFormCommentEffect({
        authData,
        anonData,
        cfg: payload,
        cb: (error) => {
          if (!error) {
            setFieldInfo(undefined);
            handleGetFormComments();
          }
          setSending(false);
        },
      }),
    );
  };

  const handleEditFormComment = async (
    payload: EditFormCommentType,
    setEditingMode: (editingMode: boolean) => void,
    setSending: (isSending: boolean) => void,
  ) => {
    setSending(true);
    dispatch(
      editFormCommentEffect({
        authData,
        anonData,
        cfg: payload,
        cb: (error) => {
          if (!error) {
            setEditingMode(false);
            handleGetFormComments();
          }
          setSending(false);
        },
      }),
    );
  };

  const handleDeleteFormComment = async (formCommentId: number) => {
    dispatch(
      deleteFormCommentEffect({
        authData,
        anonData,
        cfg: { formCommentId },
        cb: (error) => {
          if (!error) {
            handleGetFormComments();
          }
        },
      }),
    );
  };

  const handleToggleResolveThread = async (
    threadId: number,
    setResolving: (resolving: boolean) => void,
  ) => {
    dispatch(
      toggleResolveThreadEffect({
        authData,
        anonData,
        cfg: { threadId },
        cb: (error) => {
          if (!error) {
            handleGetFormComments();
          }
          setResolving(false);
        },
      }),
    );
  };

  const handleExit = () => {
    if (memoizedState?.redirectionLink) {
      history.replace(memoizedState.redirectionLink);
    } else if (authData) history.replace(routes.workshopForms);
    else history.replace('/');
  };

  const handlePostSignDone = () => {
    if (!creatorPreview) {
      if (authData) {
        notification.success({
          message: 'Form has been signed.',
          placement: 'top',
        });
        goBackToFormsPageWithQueryParam();
      } else {
        history.push({
          pathname: routes.workshopDynamicFormSuccess,
          state: { dynamicFormData: {} },
        });

        setOnlyPreview(true);
        setUnlockButton(false);
      }
    }
  };

  const handleEditDone = (creator) => {
    if (isEditSign) {
      setForceSign(true);
    } else if (creator) {
      handleCreatorFinish();
    } else {
      setPreview(true);
    }
  };

  const handleSignFinish = () => {
    if (forceSign && screen === FORM_PROCESS_SCREEN.CreatorEdit) {
      handleCreatorFinish();
    } else if (screen !== FORM_PROCESS_SCREEN.CreatorEdit) {
      setSignPreview(true);
    }
  };

  const handleAgree = () => {
    setAgreeBtnLoading(true);

    if (authData) {
      dispatch(
        acceptTermsAndConditionsAuthEffect(authData, (err) => {
          if (!err) {
            refresh();
          }

          setAgreeBtnLoading(false);
        }),
      );
    } else if (anonData) {
      dispatch(
        acceptTermsAndConditionsAnonEffect(
          { Token: anonData.token, type: anonData.type },
          (err) => {
            if (!err) {
              refresh();
            }

            setAgreeBtnLoading(false);
          },
        ),
      );
    }
  };

  const handlePostFormDone = () => {
    if (isEditSign) {
      notification.success({
        message: 'Form has been signed.',
        placement: 'top',
      });
    }
    if (authData) {
      goBackToFormsPageWithQueryParam();
    } else {
      setPostForm(false);
      setOnlyPreview(true);
      setCreatorPreview(true);
    }
  };

  const updateStrikeThrough = (updatedStrikeThrough: StrikeThrough) => {
    const newStrikeThrough: StrikeThrough = {};
    Object.keys(updatedStrikeThrough).map((page) => {
      newStrikeThrough[page] = (updatedStrikeThrough[page] || []).filter(
        (points) => points.start && points.end,
      );
    });

    const callBack = (err) => {
      if (!err) {
        refresh();
      }
    };

    if (authData) {
      dispatch(
        updateStrikeThroughAuthEffect({ ...authData, strikeThrough: newStrikeThrough }, callBack),
      );
    } else if (anonData) {
      dispatch(
        updateStrikeThroughAnonEffect(
          { Token: anonData.token, type: anonData.type, strikeThrough: newStrikeThrough },
          callBack,
        ),
      );
    }
  };

  const handleSendDocumentPreview = () => {
    setPostForm(false);
    setCreatorPreview(true);
  };

  const renderScreen = () => {
    let manager = <FormNotAvailableScreen />;

    if (postForm && authData) {
      manager = (
        <SendDocument
          formProcessPublicId={authData.formProcessPublicId}
          formDocumentPublicId={authData.formDocumentPublicId}
          back={() => {
            refresh();
            setPostForm(false);
            setForceSign(false);
          }}
          handleExit={openExitConfirmationModal}
          handleDone={handlePostFormDone}
          handlePreview={noCreatorInput ? undefined : handleSendDocumentPreview}
        />
      );
    } else if (
      preview ||
      creatorPreview ||
      screen === FORM_PROCESS_SCREEN.SignPreview ||
      screen === FORM_PROCESS_SCREEN.EditPreview ||
      screen === FORM_PROCESS_SCREEN.CancelPreview ||
      screen === FORM_PROCESS_SCREEN.CompletePreview ||
      signPreview
    ) {
      manager = (
        <PreviewManager
          formDocument={formDocument}
          anonData={anonData}
          authData={authData}
          handleDone={creatorPreview ? handleCreatorFinish : undefined}
          postHandleDone={signPreview ? handlePostSignDone : undefined}
          onlyPreview={
            screen === FORM_PROCESS_SCREEN.SignPreview ||
            screen === FORM_PROCESS_SCREEN.EditPreview ||
            onlyPreview
          }
          completed={screen === FORM_PROCESS_SCREEN.CompletePreview}
          canceled={screen === FORM_PROCESS_SCREEN.CancelPreview}
          signed={signPreview}
          signPreview={screen === FORM_PROCESS_SCREEN.SignPreview}
          handleEdit={signPreview || preview || creatorPreview ? handleEditForm : undefined}
          handleUnlock={showUnlockButton ? openUnlockConfirmationModal : undefined}
          handleExit={handlePreviewExit}
          isCreator={creatorPreview}
          isEditSign={isEditSign}
          commentUsers={formComment.commentUsers}
          formComment={formComment}
          setFieldInfo={setFieldInfo}
          handleGetFormComments={handleGetFormComments}
          handleAddFormComment={handleAddFormComment}
          handleEditFormComment={handleEditFormComment}
          handleDeleteFormComment={handleDeleteFormComment}
          handleToggleResolveThread={handleToggleResolveThread}
          setCommentPanelOpen={setCommentPanelOpen}
          webView={webView}
          refresh={refresh}
          formCommentThreads={Object.values(formComment.fieldThreads.data).flat()}
        />
      );
    } else if (
      (screen === FORM_PROCESS_SCREEN.CreatorEdit || screen === FORM_PROCESS_SCREEN.Edit) &&
      !forceSign
    ) {
      manager = (
        <EditFormManager
          formDocument={formDocument}
          anonData={anonData}
          authData={authData}
          {...(screen === FORM_PROCESS_SCREEN.CreatorEdit
            ? { handleEditFormFinish: () => handleEditDone(true), isCreator: true }
            : { handleEditFormFinish: () => handleEditDone(false) })}
          handleUnlock={showUnlockButton ? openUnlockConfirmationModal : undefined}
          handleExit={openExitConfirmationModal}
          handleDeferQuestion={handleDeferQuestions}
          handleUndoDeferQuestions={handleUndoDeferQuestions}
          commentUsers={formComment.commentUsers}
          formComment={formComment}
          setFieldInfo={setFieldInfo}
          handleGetFormComments={handleGetFormComments}
          handleAddFormComment={handleAddFormComment}
          handleEditFormComment={handleEditFormComment}
          handleDeleteFormComment={handleDeleteFormComment}
          handleToggleResolveThread={handleToggleResolveThread}
          setCommentPanelOpen={setCommentPanelOpen}
          {...(allowStrikeThrough ? { updateStrikeThrough: updateStrikeThrough } : {})}
          formCommentFieldId={formCommentFieldId}
          formComments={Object.values(formComment.fieldThreads.data).flat()}
          selectedFieldInfo={fieldInfo}
        />
      );
    } else if (
      screen === FORM_PROCESS_SCREEN.Sign ||
      screen === FORM_PROCESS_SCREEN.DeclineSign ||
      forceSign
    ) {
      manager = (
        <SignFormManager
          formDocument={formDocument}
          anonData={anonData}
          authData={authData}
          handleSignFormFinish={handleSignFinish}
          handleEdit={isEditSign ? () => setForceSign(false) : undefined}
          declined={screen === FORM_PROCESS_SCREEN.DeclineSign}
          handleUnlock={showUnlockButton ? openUnlockConfirmationModal : undefined}
          handleExit={openExitConfirmationModal}
          isEditSign={isEditSign}
          isCreatorEdit={screen === FORM_PROCESS_SCREEN.CreatorEdit}
          refresh={refresh}
          commentUsers={formComment.commentUsers}
          formComment={formComment}
          setFieldInfo={setFieldInfo}
          handleGetFormComments={handleGetFormComments}
          handleAddFormComment={handleAddFormComment}
          handleEditFormComment={handleEditFormComment}
          handleDeleteFormComment={handleDeleteFormComment}
          handleToggleResolveThread={handleToggleResolveThread}
          setCommentPanelOpen={setCommentPanelOpen}
          formComments={Object.values(formComment.fieldThreads.data).flat()}
        />
      );
    }

    return manager;
  };

  return (
    <>
      {confirmationModalProps && (
        <ConfirmationModal
          open={true}
          title={null}
          onCancel={() => setConfirmationModalProps(undefined)}
          closable={false}
          confirmLoading={confirmationLoading}
          {...confirmationModalProps}
        />
      )}
      <FormCommentPanel
        setFormCommentFieldId={setFormCommentFieldId}
        commentPanelOpen={commentPanelOpen}
        setCommentPanelOpen={setCommentPanelOpen}
        threads={Object.values(formComment.fieldThreads.data).flat()}
        commentUsers={formComment.commentUsers}
        handleAddFormComment={handleAddFormComment}
        handleEditFormComment={handleEditFormComment}
        handleDeleteFormComment={handleDeleteFormComment}
        handleToggleResolveThread={handleToggleResolveThread}
      />

      {/* <Modal
        open={!!fieldInfo}
        closeIcon={<></>}
        footer={null}
        width={370}
        className={styles.formCommentModal}
        onCancel={() => setFieldInfo(undefined)}
      >
        <FormCommentPopover
          isNewThread={true}
          commentUsers={formComment.commentUsers}
          fieldInfo={fieldInfo}
          handleGetFormComments={handleGetFormComments}
          handleAddFormComment={handleAddFormComment}
        />
      </Modal> */}

      {showTermsAndConditions && (
        <TermsAndConditionsModal loading={agreeBtnLoading} handleAgree={handleAgree} />
      )}

      {renderScreen()}
    </>
  );
};
