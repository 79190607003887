import { Space, Dropdown, Menu } from 'components-antd';
import Icon from 'pages/Workshop/Transactions/TransactionTasks/Icons';

import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { getAgentTeamIdSelector, getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { TEAM_ADMIN, TEAM_OWNER } from 'settings/constants/roles';
import { getUserId, getUserRolesMapSelector } from 'store/selectors/user';
import { List } from 'components/Icons';
import classNames from 'classnames';
import { Fragment } from 'react';

export type TemplateListItemType = {
  Name: string;
  Description: string;
  Id: number;
  PublishToTeam?: boolean;
  TeamId: number;
  CreatedBy: number;
};

export interface TemplateCardProps {
  template: TemplateListItemType;
  onEdit: (template: TemplateListItemType) => void;
  onView: (template: TemplateListItemType) => void;
  onDelete: (template: TemplateListItemType) => void;
}

export const ClausesTemplatesCard = ({ template, onEdit, onDelete, onView }: TemplateCardProps) => {
  const agentRole = useSelector(getAgentTeamRoleSelector);

  const isAdminOrOwner = [TEAM_ADMIN, TEAM_OWNER].includes(agentRole);
  const userId = useSelector(getUserId);

  const teamId = useSelector(getAgentTeamIdSelector);
  const { isSuperUser } = useSelector(getUserRolesMapSelector);

  const menuItems = [
    {
      key: '1',
      label: (
        <div className={styles.dropdownItem} onClick={() => onEdit(template)}>
          <Icon variant={Icon.OPTIONS_EDIT} className={styles.optionIcon} />
          <span>Edit</span>
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div className={styles.dropdownItem} onClick={() => onDelete(template)}>
          <Icon variant={Icon.OPTIONS_DELETE} className={styles.optionIcon} />
          <span>Remove</span>
        </div>
      ),
    },
  ];

  const menuItemView = [
    {
      key: '1',
      label: (
        <div className={styles.dropdownItem} onClick={() => onView(template)}>
          <List className={classNames(styles.optionIcon)} />
          <span>View</span>
        </div>
      ),
    },
  ];

  const getTemplateActions = () => {
    if (!isSuperUser && template.PublishToTeam && !(template.TeamId === teamId))
      return menuItemView;
    else if (
      userId === template.CreatedBy ||
      isSuperUser ||
      ([TEAM_OWNER, TEAM_ADMIN].includes(agentRole) && template.TeamId === teamId)
    ) {
      return menuItems;
    }

    return menuItemView;
  };

  return (
    <div className={styles.templateCard}>
      <div className={styles.templateInfo}>
        <div className={styles.templateInfoHeader}>
          <h3 className={styles.title}>{template.Name}</h3>

          <div id={`dropdown-${template.Id}`} className={styles.dropdownWrap}>
            <Dropdown
              overlay={<Menu items={getTemplateActions()} />}
              trigger={['click']}
              overlayClassName={styles.moreOptions}
              getPopupContainer={() => document.getElementById(`dropdown-${template.Id}`)!}
              placement="bottomRight"
            >
              <Icon variant={Icon.DOTS} className={styles.dropdownTrigger} />
            </Dropdown>
          </div>
        </div>

        <p className={styles.description}>
          {template.Description.split('\n').map((line, index) => (
            <Fragment key={index}>
              {line}
              <br />
            </Fragment>
          ))}
        </p>

        {template.PublishToTeam && isAdminOrOwner && (
          <p className={styles.publishToTeamClause}>Published To Team</p>
        )}
      </div>
    </div>
  );
};
