import { createAction } from 'redux-actions';

export const requestGetMatchedClientsWithScoresByMls = createAction(
  'REQUEST/GET_MATCHED_BUYERS_WITH_SCORES_BY_MLS',
);

export const reqeustGetAgentMatchScoreWithCriteria = createAction(
  'REQUEST/GET_AGENT_MATCH_SCORE_WITH_CRITERIA',
);

export const requestGetPropertyInsights = createAction('REQUEST/GET_PROPERTY_INSIGHTS');

export const requestGetAdditionalFeatures = createAction('REQUEST/GET_ADDITIONAL_FEATURES');
