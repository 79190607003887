import classNames from 'classnames';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Avatar } from 'components/Icons';
import { SmartFormIconVariants, SmartFormIcons } from 'components/SmartForm';
import { Button, Col, Row, Text, TextArea, Tooltip } from 'components-antd';
import {
  getDynamicPostFormSelector,
  getFormDocumentSelector,
  getFormMetaSelector,
  getPostFormSelector,
} from 'store/selectors/requestFormProcess';
import { FormPill } from 'pages/FormProcess/components';
import { CustomDropDown } from 'pages/Workshop/Forms/components/FormDetailModal/components/CustomDropDown';
import { FormEditorRole } from 'app-constants';

import { Spinner } from 'components';
import { DraggableCombineMenuList, DraggableCombineMenuListItem } from 'components-antd';

import styles from './styles.module.scss';
import { SignFormToggleRow } from '../PreForm/SignForm/SignFormToggleRow';
import { useFetchFormMetaData } from 'pages/FormProcess/hooks/useFetchFormMetaData';

interface PostFormProps {
  pdfName?: string;
  handleDone: (message: string) => void;
  handleExit?: () => void;
  back?: () => void;
  clientName?: string;
  clientAddress?: string;
  handleRemoveEditor: (editorId) => void;
  setReplaceEntityModal: (...data) => void;
  updateSignatureSequence: (isSequence, updatedSignatories, cb) => void;
  handlePreview?: () => void;
  dynamicForm?: boolean;
  isBundle?: boolean;
}

export const PostForm = ({
  pdfName,
  clientName,
  clientAddress,
  handleExit,
  handleDone,
  handleRemoveEditor,
  back,
  setReplaceEntityModal,
  updateSignatureSequence,
  handlePreview,
  dynamicForm,
  isBundle,
}: PostFormProps) => {
  const postForm =
    (dynamicForm ? useSelector(getDynamicPostFormSelector) : useSelector(getPostFormSelector)) ||
    {};

  const { allDeferrableUsers = [] } = useSelector(getFormDocumentSelector);
  const { clients = [], agents = [] } = useSelector(getFormMetaSelector);
  const [loading, setLoading] = useState(false);

  dynamicForm && useFetchFormMetaData();

  const {
    editorParticipants = [],
    editorsPresent = false,
    isSequentialSigning = false,
    signatoriesPresent = false,
    signatoryParticipants = [],
  } = postForm;

  const [message, setMessage] = useState('');

  const handleContinue = () => {
    handleDone(message);
  };

  const editors = editorParticipants.filter((e) => !e.done);
  const signers = signatoryParticipants.filter((s) => !s.done);
  const countCorrection = signers?.length ? signers[0]?.sequence - 1 : 0;

  let editorSequential = false;
  if (editors.length > 1) {
    const firstSequence = editors?.[0]?.sequence;
    if (firstSequence) {
      editorSequential = !editors.every((e) => e.sequence === firstSequence);
    }
  }

  const NavigationInfoRow = () => {
    return (
      <Row className={styles.navigationInfoRow}>
        <Col className={styles.sideControls}>
          {handlePreview && (
            <div
              className={classNames(styles.previewForm, styles.navigationButton)}
              onClick={handlePreview}
            >
              <SmartFormIcons variant={SmartFormIconVariants.Preview} />
              Preview
            </div>
          )}
        </Col>
        <Col>
          <FormPill clientAddress={clientAddress} clientName={clientName} pdfName={pdfName} />
        </Col>

        <Col className={styles.sideControls}>
          <div className={styles.navigationIcons}>
            <div
              onClick={back}
              className={classNames(styles.backNavigation, styles.navigationButton)}
            >
              <SmartFormIcons variant="back" />
            </div>
            <div
              className={classNames(styles.nextNavigation, styles.navigationButton)}
              onClick={handleContinue}
            >
              <SmartFormIcons variant="next" />
            </div>
            <div
              className={classNames(styles.closeNavigation, styles.navigationButton)}
              onClick={handleExit}
            >
              <SmartFormIcons variant="cancel" />
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  const cleanOptions = (options, { userId, email }, ignoreSelf = true) => {
    return options.filter(
      (o) =>
        (ignoreSelf || !o.self) && ((userId && o.Id !== userId) || (email && o.Email !== email)),
    );
  };

  const signatoryToggleChange = () => {
    setLoading(true);
    const isSequence = !isSequentialSigning;
    const signatories = signers.map((s, index) => ({
      id: s.signatoryId,
      sequence: isSequence ? index + 1 : 1,
    }));

    updateSignatureSequence(isSequence, signatories, () => {
      setLoading(false);
    });
  };

  const getInitialItems = () =>
    signers.map((signer) => ({
      id: signer.signatoryId,
      sequence: signer.sequence - countCorrection,
    })) as DraggableCombineMenuListItem[];

  const onSequenceUpdate = (updatedSigners) => {
    setLoading(true);
    updateSignatureSequence(
      isSequentialSigning,
      updatedSigners.map((signer) => ({ ...signer, sequence: signer.sequence + countCorrection })),
      () => {
        setLoading(false);
      },
    );
  };

  const renderSignatory = (
    item: DraggableCombineMenuListItem,
    isDraggingOver: boolean,
    handleDecouple,
  ) => {
    const signatory = signers.find((signer) => signer.signatoryId === item.id);

    if (!signatory) {
      return <></>;
    }

    return (
      <div
        className={classNames(styles.recipientContainer, {
          [styles.isDraggingOver]: isDraggingOver,
        })}
        key={`signatory-${signatory.signatoryId}`}
      >
        <div className={styles.recipient}>
          <span className={styles.recipientPicture}>
            {isSequentialSigning ? (
              <span className={styles.numberSequence} onClick={handleDecouple}>
                {item.sequence}
              </span>
            ) : signatory.AvatarUrl ? (
              <img src={signatory.AvatarUrl} alt="avatar" />
            ) : (
              <Avatar />
            )}
          </span>
          <div className={styles.recipientInfo}>
            <span className={styles.recipientName}>{signatory.Name}</span>
            <span className={styles.recipientEmail}>{signatory.email}</span>
            <span className={styles.recipientRole}>{signatory.Role}</span>
          </div>
        </div>

        <div className={styles.recipientAction}>
          <span className={styles.toAction}>To Sign</span>
          <CustomDropDown
            allowReplace={signatory.allowReplace}
            onReplace={() => {
              setReplaceEntityModal({
                open: true,
                isEditor: false,
                entityId: signatory.signatoryId,
                name: signatory.Name,
                email: signatory.email,
                role: signatory.Role,
                options: cleanOptions([...agents, ...clients], {
                  email: signatory.email,
                  userId: signatory.userId,
                }),
              });
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className={classNames(styles.postFormContainer, {
          [styles.postDynamicFormContainer]: dynamicForm,
        })}
      >
        {!dynamicForm && <NavigationInfoRow />}

        <Row className={classNames(styles.postFormRow, { [styles.dynamicFormRow]: dynamicForm })}>
          <Col>
            <div className={styles.listContainer}>
              <div>
                {!editorsPresent && !signatoriesPresent ? (
                  <></>
                ) : (
                  <>
                    <Text className={styles.questionHeader}>
                      This {isBundle ? 'bundle' : 'document'} is going to:
                    </Text>
                    <div className={styles.content}>
                      {/* To Edit */}
                      {editorsPresent && editors.length ? (
                        <div>
                          {editors.map((editor) => (
                            <div
                              className={styles.recipientContainer}
                              key={`editor-${editor.editorId}`}
                            >
                              <div className={styles.recipient}>
                                <span className={styles.recipientPicture}>
                                  {editorSequential ? (
                                    <span className={styles.numberSequence}>{editor.sequence}</span>
                                  ) : editor.AvatarUrl ? (
                                    <img src={editor.AvatarUrl} alt="avatar" />
                                  ) : (
                                    <Avatar />
                                  )}
                                </span>
                                <div className={styles.recipientInfo}>
                                  <span className={styles.recipientName}>
                                    {editor.Name ? editor.Name : editor.email}
                                  </span>

                                  <span className={styles.recipientRole}>
                                    {editor.Role === FormEditorRole.Defer ? (
                                      <Tooltip
                                        trigger={'hover'}
                                        title={`${editor.allowedQuestions?.length || 0} ${
                                          editor.allowedQuestions?.length === 1
                                            ? 'question'
                                            : 'questions'
                                        }`}
                                      >
                                        Deferred
                                      </Tooltip>
                                    ) : (
                                      editor.Role
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className={styles.recipientAction}>
                                <span className={styles.toAction}>To Edit</span>
                                <CustomDropDown
                                  allowReplace={editor.allowReplace}
                                  allowRemove={editor.allowRemove}
                                  onReplace={() => {
                                    setReplaceEntityModal({
                                      open: true,
                                      isEditor: true,
                                      entityId: editor.editorId,
                                      name: editor.Name,
                                      email: editor.email,
                                      role: editor.Role,
                                      options: cleanOptions(
                                        editor.Role === FormEditorRole.Defer
                                          ? allDeferrableUsers
                                          : [...agents, ...clients],
                                        { email: editor.email, userId: editor.userId },
                                        editor.Role !== FormEditorRole.Defer,
                                      ),
                                    });
                                  }}
                                  onRemove={() => handleRemoveEditor(editor.editorId)}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <></>
                      )}

                      {editorsPresent && signatoriesPresent ? (
                        <div className={styles.toSign}>{`And then it’s going to`}</div>
                      ) : (
                        <></>
                      )}

                      {/* To Sign */}
                      {signers.length ? (
                        <div>
                          {loading ? (
                            <Spinner />
                          ) : (
                            <DraggableCombineMenuList
                              initialItems={getInitialItems()}
                              onItemUpdate={onSequenceUpdate}
                              renderFunction={renderSignatory}
                              isDisabled={!isSequentialSigning || signers.length === 1}
                            />
                          )}

                          {signers.length > 1 ? (
                            <SignFormToggleRow
                              handleSequentialSigning={signatoryToggleChange}
                              isSequentialSignature={isSequentialSigning}
                              disabled={loading}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <TextArea
                      placeholder="Add a message (optional)"
                      autoSize
                      className={styles.responseInput}
                      size="large"
                      bordered={false}
                      onChange={(e) => setMessage(e.target.value)}
                      maxLength={10000}
                      itemID={`send-form-message`}
                    />
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>

      {!loading && !dynamicForm && (
        <div className={styles.postFormFooter}>
          <div className={styles.actionContainer}>
            <Button
              variant="secondary"
              className={styles.postFormFooterButton}
              onClick={handleContinue}
              disabled={loading}
            >
              {!editors.length && !signatoriesPresent ? (
                <>
                  Complete <SmartFormIcons variant="continue" className={styles.svgContinue} />
                </>
              ) : (
                <>
                  Send <SmartFormIcons variant="continue" className={styles.svgContinue} />
                </>
              )}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
