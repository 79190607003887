import AutoComplete from 'antd/lib/auto-complete';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FORM_USER_ROLE } from 'app-constants';
import { DraggableCombineMenuList, DraggableCombineMenuListItem, Text } from 'components-antd';
import { caseInsensitiveFilter } from 'helpers';
import { sortOptions } from 'pages/FormProcess/helper';
import { updateFormRoleEffect, updatePreFormResponseEffect } from 'store/effects/formProcess';
import { getPreFormSelector, getSmartFormDataSelector } from 'store/selectors/requestFormProcess';
import { FormRoleType, FormTemplateWizardType, SignatoriesType, SignatoryType } from 'types';
import { Navigation } from 'pages/FormProcess/components/PreForm/components';

import classNames from 'classnames';
import { RemoveFile, Plus } from 'components/Icons';
import styles from './styles.module.scss';

import { getSmartFormMetaSelect } from 'store/selectors/formBuilder';
import { useEditSignatoryMode } from './hooks/useEditSignatoryMode';

export const TemplateSignForm = ({
  customForm = false,
  editMode = false,
  templateState = {} as FormTemplateWizardType,
  onContinue,
  loading = false,
  editSignatoryMode = false,
  signatoryModal = false,
}) => {
  const dispatch = useDispatch();
  const data = useSelector(getSmartFormMetaSelect);
  const { formRoles } = useSelector(getSmartFormDataSelector);

  const { signatories: allSignatories, isSequentialSignature } = useSelector(getPreFormSelector);

  const [templateFormRoles, setTemplateFormRoles] = useState<number[]>(
    editMode ? templateState?.formRoleIds || [] : [],
  );

  const rolesForTemplate = data?.questionRoles;
  const allRoles = (rolesForTemplate || []).map((role) => ({
    key: role.Id,
    label: role.Name,
    value: role.Name,
  }));

  const updateFormRoles = (updatedFormRoles: FormRoleType[]) => {
    dispatch(updateFormRoleEffect({ formRoles: updatedFormRoles }));
  };

  const [signatories, setSignatoriesState] = useState<SignatoryType[]>([]);
  const signatoriesDataCount = signatories.reduce((count, sig) => {
    if (sig.Email || sig.Name) return count + 1;
    return count;
  }, 0);

  const { manageSignatoryEditMode, getTemplateUpdatedData } = useEditSignatoryMode({
    templateState,
    templateFormRoles,
  });

  useEffect(() => {
    const updatedSignatories: SignatoriesType = {};

    const availableSignatories = signatories.filter(
      (signatory) => signatory.UserId || signatory.Email,
    );

    availableSignatories.forEach((signatory) => {
      if (signatory.FormRole) {
        updatedSignatories[signatory.FormRole] = {
          ...signatory,
          SignatureSequence: signatory.SignatureSequence || 1,
        };
      }
    });

    dispatch(
      updatePreFormResponseEffect({
        signatories: updatedSignatories,
      }),
    );
  }, [signatories, isSequentialSignature]);

  const clearInvalidRole = (signatoryIndex: number) => {
    const roles = [...templateFormRoles];

    delete roles[signatoryIndex];
    setTemplateFormRoles([...roles]);
  };

  const modifySignatories = () => {
    let result: SignatoryType[] = [];
    const availableSignatories = allSignatories || {};

    if (formRoles?.length) {
      formRoles.forEach((role) => {
        const roleId = role.Id;
        if (availableSignatories[roleId]) {
          result.push(availableSignatories[roleId]);
        }
      });

      formRoles.forEach((role) => {
        const roleId = role.Id;
        if (!availableSignatories[roleId]) {
          result.push({
            FormRole: roleId,
            SignatureSequence: result.length + 1,
          });
        }
      });
    }

    setSignatoriesState(
      result.sort((a, b) => (a.SignatureSequence > b.SignatureSequence ? 1 : -1)),
    );
  };

  useEffect(() => {
    modifySignatories();

    if (customForm && !formRoles?.length) {
      addEmptyRole();
    }
  }, [formRoles]);

  const getInitialItems = () =>
    signatories.map((signer) => ({
      id: signer.FormRole,
      sequence: signer.SignatureSequence,
    })) as DraggableCombineMenuListItem[];

  const handleRoleDelete = (roleId, signatoryIndex = -1) => {
    updateFormRoles(formRoles.filter((role) => role.Id !== roleId));

    if (signatoryIndex >= 0) {
      editSignatoryMode && manageSignatoryEditMode(signatoryIndex, roleId, 'delete');

      setTemplateFormRoles(templateFormRoles.filter((_, index) => index !== signatoryIndex));
    }
  };

  const handleTemplateRoleChange = (value: string | number, index) => {
    let roles: any = [...templateFormRoles];

    if (!value && roles[index]) {
      delete roles[index];
      setTemplateFormRoles([...roles]);
    } else {
      roles[index] = value;
      setTemplateFormRoles(roles);
    }
  };

  const handleTemplateRoleSelect = (index, option) => {
    let roles: any = [...templateFormRoles];

    if (editSignatoryMode) {
      manageSignatoryEditMode(index, option.key, 'select');
    }

    roles[index] = option.key;

    setTemplateFormRoles(roles);
  };

  const getRoleName = (roleId: any) => {
    if (roleId) {
      return allRoles.find((role) => role.key === roleId)?.label;
    }
  };

  const renderFormSignatory = (item: DraggableCombineMenuListItem) => {
    const signatoryIndex = signatories?.findIndex((signatory) => signatory.FormRole === item?.id);
    const signatory = signatories?.[signatoryIndex];

    const roleIndex = formRoles?.findIndex((role) => role.Id === signatory?.FormRole);
    const role = roleIndex >= 0 ? formRoles?.[roleIndex] : undefined;

    if (!signatory || !role) {
      return <></>;
    }

    const getRoleOptions = () => {
      let roles = templateFormRoles?.length
        ? allRoles.filter(({ key }) => !templateFormRoles.includes(key))
        : allRoles;

      return sortOptions(roles, 'label');
    };

    let autoCompleteValue = getRoleName(templateFormRoles[signatoryIndex]);

    return (
      <div className={classNames(styles.checkboxRow)} key={item.id}>
        <div className={classNames(styles.templateFieldWrapper)}>
          <div
            className={classNames(styles.field, {
              [styles.signFormField]: !autoCompleteValue,
            })}
          >
            {signatoryModal ? (
              <div className={styles.label}>Signer {signatoryIndex + 1}</div>
            ) : (
              <></>
            )}

            <AutoComplete
              placeholder={'Select Role...'}
              bordered={false}
              className={styles.autoCompleteInput}
              value={autoCompleteValue || templateFormRoles[signatoryIndex] || ''}
              options={getRoleOptions()}
              filterOption={(inputValue, option) =>
                caseInsensitiveFilter(inputValue, option?.label as string)
              }
              onBlur={() => (autoCompleteValue ? undefined : clearInvalidRole(signatoryIndex))}
              onSelect={(value, option) => handleTemplateRoleSelect(signatoryIndex, option)}
              onChange={(value) => handleTemplateRoleChange(value, signatoryIndex)}
              allowClear
              getPopupContainer={(node) => node}
              popupClassName={styles.autoCompleteDropdown}
            />
          </div>

          {item.sequence > 0 && templateFormRoles.length > 1 && (
            <div
              className={classNames(styles.removeButton, {
                [styles.editSignatoryModeRemove]: signatoryModal,
              })}
            >
              <RemoveFile
                onClick={() => {
                  handleRoleDelete(role.Id, signatoryIndex);
                }}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const addEmptyRole = () => {
    const max = formRoles.reduce((max, formRole) => (formRole.Id > max ? formRole.Id : max), 0);
    const newRoleId = max + 1;

    updateFormRoles([
      ...formRoles,
      {
        Id: newRoleId,
        Name: '',
        RoleId: newRoleId,
        UserType: FORM_USER_ROLE.Agent,
      },
    ]);
  };

  const isDisabled = () => {
    const filteredRoles = templateFormRoles?.filter((role) => role);

    return !filteredRoles.length;
  };

  const handleContinue = () => {
    let params = {
      ...(editSignatoryMode
        ? {
            templateRoleIds: getTemplateUpdatedData(),
          }
        : {
            templateRoleIds: templateFormRoles
              .filter((roleId) => roleId)
              .map((Id) => ({ Id, IsDeleted: false, IsReplaced: false })),
          }),
    };
    onContinue(params);
  };

  const items = getInitialItems();

  return formRoles?.length && rolesForTemplate?.length ? (
    <>
      {!signatoryModal && <Text className={styles.questionHeader}>Who needs to sign?</Text>}

      <div className={styles.pageContent}>
        <DraggableCombineMenuList
          initialItems={items}
          onItemUpdate={() => {}}
          renderFunction={renderFormSignatory}
          isDisabled={!isSequentialSignature}
        />

        <div className={styles.add} onClick={addEmptyRole}>
          <Plus color="#515151" className={styles.addIcon} />
          <div className={styles.addText}>Add Another</div>
        </div>

        <Navigation
          onContinue={handleContinue}
          disabled={isDisabled()}
          className={signatoriesDataCount < 2 ? styles.navigationButton : ''}
          loading={loading}
          buttonText={signatoryModal ? 'Save' : ''}
        />
      </div>
    </>
  ) : (
    <Text className={styles.questionHeader}>No Form Roles Available for this form</Text>
  );
};
