import PropTypes from 'prop-types';
import { Wrapper as PendingWrapper, SearchFieldWithDebounce, Select } from 'components';

import { Modal } from 'components-antd';

import styles from './styles.module.scss';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { getLoginLogsByAdminEffect } from 'store/effects';
import { useEffect, useState } from 'react';
import { TeamsTable } from '../Components/TeamsTable';
import { tableColumns } from './tableColumns';
import {
  getLoginLogsSelector,
  getTeamDetailsDashboardSelector,
} from 'store/selectors/adminPanel/teamDetails';

export const LoginLogs = (props) => {
  const { agentEmail } = props;
  const dispatch = useDispatch();
  const loginLogs = useSelector(getLoginLogsSelector);
  const [memberLoginLogs, setMemberLoginLogs] = useState([]);
  const [search, setSearch] = useState('');
  const { isPending } = useSelector(getTeamDetailsDashboardSelector);

  const resetLoginLogsSearch = () => {
    setSearch('');
  };

  const searchLoginLogs = (val) => {
    setSearch(val);
  };

  const getSearchLoginLogs = (loginDetails) => {
    let result = (loginDetails || []).filter((data) => {
      if (search) {
        const formattedSearch = search?.toLowerCase();
        let ipAddress = `${data?.IPAddress}`;
        let createdDate = data?.CreatedDate;
        return (
          ipAddress?.toLowerCase()?.includes(formattedSearch) ||
          createdDate?.toLowerCase()?.includes(formattedSearch)
        );
      }
      return true;
    });
    return result;
  };

  useEffect(() => {
    dispatch(getLoginLogsByAdminEffect({ searchString: agentEmail }));
  }, []); // eslint-disable-line

  useEffect(() => {
    if (loginLogs) {
      const tempAgents = getSearchLoginLogs(loginLogs);
      setMemberLoginLogs(tempAgents);
    }
  }, [loginLogs, search]);

  const tableProps = {
    columns: tableColumns(),
    onRow: (record) => {
      return {
        onClick: (event) => {},
      };
    },
  };

  return (
    <div>
      <PendingWrapper
        isPending={isPending}
        className={classNames({ [styles.pendingWrapper]: isPending })}
      >
        <div>
          <span className={styles.loginLogsTitle}>Login History</span>
        </div>
        <div className={styles.loginLogsBar}>
          <span className={styles.loginLogsCount}>{loginLogs?.length ?? 0} Records</span>
          <div className={styles.filterWrapper}>
            <div className={styles.searchWrapper}>
              <SearchFieldWithDebounce
                className={styles.inputWrap}
                classNameInput={styles.searchInput}
                iconClassName={styles.icon}
                resetAction={resetLoginLogsSearch}
                sendAction={searchLoginLogs}
                isLoading={isPending}
                placeholder="Search"
                testid="search"
                isTransactionAggregate={true}
                isExpandedVersion
                doNotDispatch={true}
              />
            </div>
          </div>
        </div>
        <div className={styles.loginTable}>
          <TeamsTable
            dataSource={memberLoginLogs?.length > 0 ? memberLoginLogs : []}
            {...tableProps}
          />
        </div>
      </PendingWrapper>
    </div>
  );
};

LoginLogs.propTypes = {
  agentEmail: PropTypes.string,
};

LoginLogs.defaultProps = {
  agentEmail: '',
};
