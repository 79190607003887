import {
  LedgerAmountTypeId,
  LedgerLineItemTypeId,
  LedgerNetsTitle,
  LedgerTransactionSideId,
  LedgerAccountId,
} from 'settings/constants/ledger';
import { SplitTypes } from './TransactionFinancials';

export const getLedgerId = (ledgers, transactionSide) => {
  if (!ledgers || !Array.isArray(ledgers)) return null;

  const foundLedger = ledgers.find(
    (item) => item && item.LedgerTransactionSideId === transactionSide,
  );
  return foundLedger && foundLedger.Id ? foundLedger.Id : null;
};

export const getCommissionSide = (ledgerLineItems, ledgerId) => {
  if (!ledgerLineItems || !Array.isArray(ledgerLineItems) || !ledgerId) return null;

  const foundLedgerLineItem = ledgerLineItems.find(
    (item) =>
      item &&
      item.LedgerLineItemTypeId === LedgerLineItemTypeId.TransactionCommission &&
      item.LedgerId === ledgerId,
  );

  return foundLedgerLineItem || null;
};

export const getTransactionCommissions = (ledgers, ledgerLineItems) => {
  if (!ledgers || !Array.isArray(ledgers) || !ledgerLineItems || !Array.isArray(ledgerLineItems))
    return null;

  const listingLedgerId = getLedgerId(ledgers, LedgerTransactionSideId.Listing);
  const buyerLedgerId = getLedgerId(ledgers, LedgerTransactionSideId.Purchase);

  const sellerCommissionSide = getCommissionSide(ledgerLineItems, listingLedgerId);
  const buyerCommissionSide = getCommissionSide(ledgerLineItems, buyerLedgerId);

  return {
    SellerCommissionFees: {
      Value: isFeeTypePercentage(sellerCommissionSide)
        ? sellerCommissionSide?.Percent
        : sellerCommissionSide?.Amount,
      IsFeeTypePercentage: isFeeTypePercentage(sellerCommissionSide),
    },
    BuyerCommissionFees: {
      Value: isFeeTypePercentage(buyerCommissionSide)
        ? buyerCommissionSide?.Percent
        : buyerCommissionSide?.Amount,
      IsFeeTypePercentage: isFeeTypePercentage(buyerCommissionSide),
    },
  };
};

export const getSplitItem = (ledgerItems, ledgerId, splitItemId) => {
  if (!ledgerItems || !Array.isArray(ledgerItems) || !ledgerId || !splitItemId) return null;

  const foundLedgerLineItem = ledgerItems.find(
    (item) =>
      item?.LedgerId === ledgerId &&
      item?.LedgerLineItemTypeId === LedgerLineItemTypeId.TransactionCommissionPaymentSplit &&
      item?.LedgerAccountId === splitItemId,
  );

  return foundLedgerLineItem || null;
};

export const getCustomSplitItem = (ledgerItems, ledgerId, memo) => {
  if (!ledgerItems || !Array.isArray(ledgerItems) || !ledgerId || !memo) return null;

  const foundLedgerLineItem = ledgerItems.find(
    (item) =>
      item?.LedgerId === ledgerId &&
      item?.LedgerLineItemTypeId === LedgerLineItemTypeId.TransactionCommissionPaymentSplit &&
      item?.LedgerAccountId === LedgerAccountId.CustomAccountPayment &&
      item?.Memo === memo,
  );

  return foundLedgerLineItem || null;
};

export const getFeeItems = (ledgerItems, ledgerId) => {
  if (!ledgerItems || !Array.isArray(ledgerItems) || !ledgerId) return null;

  const feeItems = ledgerItems.filter(
    (item) =>
      item?.LedgerId === ledgerId &&
      item?.LedgerLineItemTypeId === LedgerLineItemTypeId.TransactionFeeDeduction,
  );

  const feeItemsWithAddToAccountIdMapped = feeItems?.map((feeItem) => {
    const correspondingFeeAdditionItem = ledgerItems?.find(
      (item) =>
        item?.LedgerLineItemTypeId === LedgerLineItemTypeId.TransactionFeeAddition &&
        item?.RelatedLedgerLineItemId === feeItem.Id,
    );
    return {
      ...feeItem,
      AddToAccountId: correspondingFeeAdditionItem?.LedgerAccountId,
      AddToAccountIdLineItemId: correspondingFeeAdditionItem.Id,
    };
  });

  return feeItemsWithAddToAccountIdMapped;
};

export const getGCIFeesSum = (ledgerItems, ledgerId) => {
  if (!ledgerItems || !Array.isArray(ledgerItems) || !ledgerId) return null;

  const gciFeeItems = ledgerItems?.filter(
    (item) =>
      item?.LedgerId === ledgerId &&
      item?.LedgerLineItemTypeId === LedgerLineItemTypeId.TransactionFeeDeduction &&
      item?.LedgerAccountId === LedgerAccountId.CommissionIncome,
  );

  return gciFeeItems?.reduce((sum, obj) => sum + (obj?.Amount || 0), 0);
};

export const generateTransactionSplitsData = (
  ledgers,
  ledgerLineItems,
  transactionSide,
  transactionAdminOrOwner,
) => {
  if (
    !ledgers ||
    !Array.isArray(ledgers) ||
    !ledgerLineItems ||
    !Array.isArray(ledgerLineItems) ||
    !transactionSide
  )
    return null;

  const ledgerId = getLedgerId(ledgers, transactionSide);

  const predefinedSplitItems = [
    { accountId: LedgerAccountId.AgentPayment, title: 'Agent Split', type: SplitTypes.AGENT },
    { accountId: LedgerAccountId.TeamPayment, title: 'Team Split', type: SplitTypes.TEAM },
    {
      accountId: LedgerAccountId.BrokeragePayment,
      title: 'Brokerage Split',
      type: SplitTypes.BROKERAGE,
    },
  ];

  const splitSide = transactionSide === LedgerTransactionSideId.Listing ? 'Listing' : 'Purchase';

  const mainSplitItems = predefinedSplitItems.map(({ accountId, title, type }, index) => {
    const splitItem = getSplitItem(ledgerLineItems, ledgerId, accountId);

    return {
      Id: splitItem?.Id || index,
      LedgerLineItemId: splitItem?.Id || null,
      LedgerId: ledgerId || null,
      LedgerAccountId: accountId,
      IsSplitTypePercentage: splitItem ? isFeeTypePercentage(splitItem) : true,
      SplitPercentage: splitItem?.Percent || null,
      SplitSide: splitSide,
      SplitType: { SplitTitle: title, SplitType: type },
      SplitValue: splitItem?.Amount || null,
      IsRestricted: splitItem?.Id
        ? splitItem?.IsRestricted
        : type === SplitTypes.AGENT
        ? !transactionAdminOrOwner // false for admin creator or owner, otherwise true
        : true, // If it's not AGENT, always true
      LedgerAccountUser: splitItem?.LedgerAccountUser || null,
      LedgerAccountUserId: splitItem?.LedgerAccountUserId || null,
      LedgerAccountContact: splitItem?.LedgerAccountContact || null,
      LedgerAccountContactId: splitItem?.LedgerAccountContactId || null,
      alreadyExists: !!splitItem, // flag to determine whether create or update the split.
    };
  });

  const customSplitItems =
    ledgerLineItems
      ?.filter(
        (item) =>
          item?.LedgerId === ledgerId &&
          item?.LedgerLineItemTypeId === LedgerLineItemTypeId.TransactionCommissionPaymentSplit &&
          item?.LedgerAccountId === LedgerAccountId.CustomAccountPayment,
      )
      ?.map((customSplitItem) => ({
        Id: customSplitItem?.Id,
        LedgerLineItemId: customSplitItem?.Id || null,
        LedgerId: ledgerId || null,
        LedgerAccountId: LedgerAccountId.CustomAccountPayment,
        IsSplitTypePercentage: customSplitItem ? isFeeTypePercentage(customSplitItem) : true,
        SplitPercentage: customSplitItem?.Percent || null,
        SplitSide: splitSide,
        SplitType: { SplitTitle: customSplitItem?.Memo, SplitType: null },
        SplitValue: customSplitItem?.Amount || null,
        IsRestricted: customSplitItem?.IsRestricted,
        LedgerAccountUser: customSplitItem?.LedgerAccountUser || null,
        LedgerAccountUserId: customSplitItem?.LedgerAccountUserId || null,
        LedgerAccountContact: customSplitItem?.LedgerAccountContact || null,
        LedgerAccountContactId: customSplitItem?.LedgerAccountContactId || null,
      })) || [];

  return [...mainSplitItems, ...customSplitItems];
};

export const isFeeTypePercentage = (item) => {
  return item?.LedgerAmountTypeId === LedgerAmountTypeId.Percent;
};

export const formatNets = (ledgers, transactionSide: LedgerTransactionSideId, ledgerLineItems) => {
  if (
    !ledgers ||
    !Array.isArray(ledgers) ||
    !ledgerLineItems ||
    !Array.isArray(ledgerLineItems) ||
    !transactionSide
  )
    return null;

  const ledger = ledgers?.find((item) => item?.LedgerTransactionSideId === transactionSide);
  const ledgerId = getLedgerId(ledgers, transactionSide);

  const agentSplitItem = getSplitItem(ledgerLineItems, ledgerId, LedgerAccountId.AgentPayment);
  const teamSplitItem = getSplitItem(ledgerLineItems, ledgerId, LedgerAccountId.TeamPayment);
  const brokerageSplitItem = getSplitItem(
    ledgerLineItems,
    ledgerId,
    LedgerAccountId.BrokeragePayment,
  );

  return [
    {
      netTitle: LedgerNetsTitle.AGENT_NET,
      netValue: ledger?.AgentNet,
      ledgerAccountId: LedgerAccountId.AgentPayment,
      isRestricted: agentSplitItem ? agentSplitItem?.IsRestricted : false,
    },
    {
      netTitle: LedgerNetsTitle.TEAM_NET,
      netValue: ledger?.TeamNet,
      ledgerAccountId: LedgerAccountId.TeamPayment,
      isRestricted: teamSplitItem ? teamSplitItem?.IsRestricted : true,
    },
    {
      netTitle: LedgerNetsTitle.BROKERAGE_NET,
      netValue: ledger?.BrokerageNet,
      ledgerAccountId: LedgerAccountId.BrokeragePayment,
      isRestricted: brokerageSplitItem ? brokerageSplitItem?.IsRestricted : true,
    },
    ...(ledger?.CustomAccountNet?.map((item) => ({
      netTitle: `${item?.customAccount} Net`,
      netValue: item?.netTotal,
      isRestricted: getCustomSplitItem(ledgerLineItems, ledgerId, item?.customAccount)
        ?.IsRestricted,
    })) || []),
  ];
};
