import { apiServer } from 'settings/web-services/api';

export const getReminderById = (Id) => {
  return apiServer.get(`/reminder/${Id}`);
};

export const updateReminder = (cfg) => {
  const { Id, ...rest } = cfg;
  return apiServer.put(`/reminder/${Id}`, rest);
};

export const deleteReminder = (Id) => {
  return apiServer.delete(`/reminder/${Id}`);
};

export const createReminderTemplate = (templateData) => {
  return apiServer.post(`/reminder/templates`, templateData);
};

export const getReminderTemplates = (entityName?, entityId?) => {
  return apiServer.get(`/reminder/templates`, {
    params: {
      entityName,
      entityId,
    },
  });
};

export const getReminderTemplateById = (Id) => {
  return apiServer.get(`/reminder/templates/${Id}`);
};

export const getReminderTemplateByEntity = (EntityName, EntityId) => {
  return apiServer.get(`/reminder/entity/template`, {
    params: {
      EntityName,
      EntityId,
    },
  });
};

export const updateReminderTemplateReq = (cfg) => {
  const { Id, ...rest } = cfg;
  return apiServer.put(`/reminder/templates/${Id}`, rest);
};

export const deleteReminderTemplate = (Id) => {
  return apiServer.delete(`/reminder/templates/${Id}`);
};
