import React, { useMemo } from 'react';

import { Modal, TableColumnsType } from 'antd';
import { ModalClose } from 'components/Icons';
import classNames from 'classnames';
import { Table } from 'pages/Radar/components';
import { Button } from 'components';
import { PlansIcon } from '../components';

import addKitsStyles from '../AddKitsModal/styles.module.scss';
import tableStyles from '../../components/Table/styles.module.scss';
import parentStyles from '../styles.module.scss';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectedLibraryKitSelector } from 'store/selectors/radarKits';

type DataType = {
  key: string;
  name: {
    title: string;
    info: string;
  };
  addedOn: string;
  sent: number;
};

const columns: TableColumnsType<DataType> = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    className: tableStyles.tableCol,
    sorter: (a, b) => a.name.title.localeCompare(b.name.title),
    render: (group: any) => (
      <div className={classNames(parentStyles.tableColNameWrapper)}>
        <div className={parentStyles.iconWrapper}>
          <PlansIcon />
        </div>
        <div className={parentStyles.detailsWrapper}>
          <div className={parentStyles.title}>{group.title}</div>
          <div className={parentStyles.info}>{group.info}</div>
        </div>
      </div>
    ),
  },
  {
    title: 'Added',
    dataIndex: 'addedOn',
    key: 'addedOn',
    className: tableStyles.tableCol,
    sorter: (a, b) => a.addedOn.localeCompare(b.addedOn),
  },
  {
    title: 'Sent',
    dataIndex: 'sent',
    key: 'sent',
    className: tableStyles.tableCol,
    sorter: (a, b) => a.sent - b.sent,
  },
];

type Props = {
  isOpen: boolean;
  closeModal: () => void;
};

export const PlansViewModal: React.FC<Props> = ({ isOpen, closeModal }) => {
  const selectedPlan = useSelector(selectedLibraryKitSelector);
  const dataSource = useMemo<DataType[]>(() => {
    return (
      selectedPlan?.campaignStats?.map((item: any) => ({
        key: item.campaign.Id,
        name: {
          title: item.campaign.Name,
          info: item.campaign.Description,
        },
        addedOn: moment(item.campaign.CreatedDate).format('MM/DD/YYYY'),
        sent: item.sent,
      })) || []
    );
  }, [selectedPlan]);
  return (
    <Modal
      open={isOpen}
      centered
      maskStyle={{
        background: 'rgba(0, 0, 0, 0.60)',
        backdropFilter: 'blur(20px)',
      }}
      closable
      footer={null}
      destroyOnClose
      onCancel={closeModal}
      closeIcon={<ModalClose />}
      title={
        <div>
          <div>{selectedPlan?.Kits?.Name}</div>
          <div className={addKitsStyles.subtitle}>{selectedPlan?.Kits?.Category}</div>
        </div>
      }
      wrapClassName={addKitsStyles.wrapper}
      width={1002}
    >
      <div className={addKitsStyles.contentWrapper}>
        <div className={classNames(addKitsStyles.content, addKitsStyles.tableContainer)}>
          <Table columns={columns} data={dataSource} disableHeader={true} />
        </div>
        <div className={addKitsStyles.btnWrapper}>
          <Button title="Done" className={addKitsStyles.btn} onClick={closeModal} />
        </div>
      </div>
    </Modal>
  );
};
