import { SVGAttributes } from 'react';

export const SoldIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M4.5 14V9C4.5 8.44772 4.94771 8 5.5 8H14.5C15.0523 8 15.5 8.44772 15.5 9V14C15.5 14.5523 15.0523 15 14.5 15H5.5C4.94772 15 4.5 14.5523 4.5 14Z"
        stroke="#928CDA"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 8L9.24742 4.86009C9.64584 4.40476 10.3542 4.40476 10.7526 4.86009L13.5 8"
        stroke="#928CDA"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <circle cx="10" cy="4" r="2" fill="#928CDA" />
    </svg>
  );
};
