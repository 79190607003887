import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { Card, CardTypes, DisableSectionCover } from './../../..';
import { SplitTabTypes, SplitTypes } from '../../../../TransactionFinancials';
import { useParams } from 'react-router-dom';
import { EditDynamicSplit, DynamicSplitItem } from '..';
import { Icons } from 'pages/Workshop/Icons';

import styles from './styles.module.scss';
import { SplitItemWrapper } from '../SplitItemWrapper';
import { LedgerAccountId, LedgerTransactionSideId } from 'settings/constants/ledger';
import { convertToDecimalIfNotWhole } from 'helpers';

export interface SplitType {
  Id: number;
  SplitTitle: string;
  SplitType: SplitTypes.AGENT | SplitTypes.TEAM | SplitTypes.BROKERAGE | SplitTypes.CLIENT;
  IsRestricted?: boolean;
  CreatedDate: string;
  UpdatedDate: string;
}

export interface SplitData {
  Id: number;
  LedgerId: LedgerTransactionSideId;
  LedgerAccountId: LedgerAccountId;
  SplitTypeId: number;
  SplitSide: string;
  SplitValue: number;
  IsSplitTypePercentage: boolean;
  defaultSplitValueType?: boolean;
  SplitPercentage: number;
  IsRestricted?: boolean;
  SplitType: SplitType;
  LedgerAccountUser: {
    FirstName: string;
    LastName: string;
  } | null;
  LedgerAccountUserId?: number | null;
  LedgerAccountContact: {
    FirstName: string;
    LastName: string;
  } | null;
  LedgerAccountContactId?: number | null;
}

interface SplitCardProps {
  ledgerId: any;
  data: SplitData[];
  fetchFinancials;
  getSplitList;
  comparePrice;
  isTransactionAdminOrOwner: boolean;
  splitType: SplitTabTypes.LISTING | SplitTabTypes.PURCHASE;
  autocompleteSplitNames: string[];
  ledgers?: any[];
  memberContacts?: any[];
}

export const SplitCard: React.FC<SplitCardProps> = ({
  ledgerId,
  data = [],
  fetchFinancials,
  getSplitList,
  comparePrice,
  isTransactionAdminOrOwner,
  splitType,
  autocompleteSplitNames,
  ledgers,
  memberContacts,
}) => {
  const [editFieldKey, setEditFieldKey] = useState(null);
  const [splitList, setSplitList] = useState<SplitData[]>([]);
  const [dynamicSplitList, setDynamicSplitList] = useState<SplitData[]>([]);
  const [isEditInput, setEditInput] = useState(false);
  const [isValidate, setValidate] = useState(false);
  const [isUpdateButtonDisable, setIsUpdateButtonDisable] = useState(false);
  const [isAddSplitToggle, setIsAddSplitToggle] = useState(false);

  const params: { id?: string } = useParams();

  // Modify data to show % by default on the Inputs.
  const modifiedData = useMemo(
    () =>
      data?.map((item) => ({
        ...item,
        defaultSplitValueType: item?.IsSplitTypePercentage,
        IsSplitTypePercentage: true,
      })),
    [data],
  );

  useEffect(() => {
    // Setting Dynamic Split List: Splits other than Agent, Team, Brokerage type.
    const dynamicSplits = modifiedData.filter(
      (item) =>
        ![SplitTypes.AGENT, SplitTypes.TEAM, SplitTypes.BROKERAGE, SplitTypes.CLIENT].includes(
          item?.SplitType?.SplitType,
        ),
    );
    setDynamicSplitList(dynamicSplits);

    // Setting Split List: Splits Agent, Team, Brokerage type.
    const splits = modifiedData.filter((item) =>
      [SplitTypes.AGENT, SplitTypes.TEAM, SplitTypes.BROKERAGE, SplitTypes.CLIENT].includes(
        item?.SplitType?.SplitType,
      ),
    );
    setSplitList(splits);
    getSplitList(modifiedData);
  }, [isEditInput]);

  const isPriceWithinRange = (sum) => {
    // Case when there's no compare Price
    if (comparePrice === 0 && sum === 0) {
      setValidate(true);
      setIsUpdateButtonDisable(true);
      return;
    }

    // Tolerance 0.04 for comparison, backend has adjusting cents logic.
    if (Math.abs(sum - comparePrice) <= 0.04 || comparePrice >= sum) {
      setValidate(false);
      setIsUpdateButtonDisable(false);
    } else {
      setValidate(true);
      setIsUpdateButtonDisable(true);
    }
  };

  const setEditField = (value) => {
    setEditInput(value);
  };

  const onCancel = () => {
    const splits = modifiedData.filter((item) =>
      [SplitTypes.AGENT, SplitTypes.TEAM, SplitTypes.BROKERAGE, SplitTypes.CLIENT].includes(
        item?.SplitType?.SplitType,
      ),
    );

    setSplitList(splits);
    setEditInput(false);
    setValidate(false);
  };

  const calculateGCI = (rate) => {
    return (rate / 100) * comparePrice;
  };

  const calculateRate = (GCI) => {
    return convertToDecimalIfNotWhole((GCI * 100) / comparePrice);
  };

  const updateSplitValue = (id, updatedObject, togglePercentAmount) => {
    const filteredArray =
      splitList && [...splitList, ...dynamicSplitList].filter((obj) => obj.Id !== id);
    let objectToUpdate = {};

    if (togglePercentAmount) {
      objectToUpdate = {
        IsSplitTypePercentage: updatedObject?.IsSplitTypePercentage,
      };
    } else {
      let sum = 0;
      let calculatedValue = 0;
      const comparedToBeSum = filteredArray.reduce((acc, obj) => {
        if (typeof obj.SplitValue === 'number' || obj.SplitValue === null) {
          if (obj?.defaultSplitValueType) {
            return acc + (obj?.SplitPercentage / 100) * comparePrice;
          }
          return acc + obj.SplitValue;
        }
        return acc;
      }, 0);
      if (updatedObject?.IsSplitTypePercentage) {
        calculatedValue = (updatedObject?.SplitValue / 100) * comparePrice;
      } else {
        calculatedValue = updatedObject?.SplitValue;
      }
      sum = +calculatedValue + +comparedToBeSum;

      if (updatedObject?.SplitValue === null || !isNaN(updatedObject?.SplitValue)) {
        setValidate(false);
        isPriceWithinRange(parseFloat(sum?.toFixed(2)));
      }
      objectToUpdate = {
        IsSplitTypePercentage: updatedObject?.IsSplitTypePercentage,
        ...(updatedObject?.IsSplitTypePercentage
          ? {
              SplitPercentage: updatedObject?.SplitValue,
              SplitValue: calculateGCI(updatedObject?.SplitValue),
            }
          : {
              SplitValue: updatedObject?.SplitValue,
              SplitPercentage: calculateRate(updatedObject?.SplitValue),
            }),
      };
    }

    setSplitList((prevList) =>
      prevList.map((item) => (item.Id === id ? { ...item, ...objectToUpdate } : item)),
    );
  };

  const handleAddClick = () => {
    setIsAddSplitToggle(true);
    setEditField(false);
    setValidate(false);
  };

  return (
    <div style={{ marginTop: '24px' }}>
      <Card variant={CardTypes.ORANGE}>
        {!ledgerId && <DisableSectionCover />}
        <div className={classNames(styles.splitsContainer, { [styles.dullSection]: !ledgerId })}>
          <div className={styles.headerContainer}>
            <p className={styles.title} style={{ marginBottom: isEditInput ? '0' : '1rem' }}>
              Splits
            </p>
            <div className={styles.addSplitCon} onClick={handleAddClick}>
              <Icons variant={Icons.PLUS} />
              <p className={styles.addSplit}>Add Split</p>
            </div>
          </div>
          {splitList &&
            splitList.map((item, index) => (
              <SplitItemWrapper
                key={index}
                splitItem={item}
                isAddSplitToggle={isAddSplitToggle}
                editFieldKey={editFieldKey}
                isTransactionAdminOrOwner={isTransactionAdminOrOwner}
                updateSplitValue={updateSplitValue}
                isValidate={isValidate}
                isUpdateButtonDisable={isUpdateButtonDisable}
                onCancel={onCancel}
                fetchFinancials={fetchFinancials}
                setEditFieldKey={(editFieldKey) => setEditFieldKey(editFieldKey)}
                memberContacts={memberContacts}
                splitType={splitType}
              />
            ))}

          {/* DYNAMIC SPLITS */}

          {dynamicSplitList.map((item, index) => (
            <DynamicSplitItem
              key={index}
              item={item}
              autocompleteSplitNames={autocompleteSplitNames}
              transactionId={params?.id}
              updateSplitValue={updateSplitValue}
              isValidate={isValidate}
              fetchFinancials={fetchFinancials}
              isTransactionAdminOrOwner={isTransactionAdminOrOwner}
              isDisabledField={
                isAddSplitToggle ||
                (editFieldKey !== null && item?.Id !== editFieldKey) ||
                isEditInput
              }
              setEditFieldKey={(editFieldKey) => setEditFieldKey(editFieldKey)}
              memberContacts={memberContacts}
              comparePrice={comparePrice}
            />
          ))}

          {isAddSplitToggle && (
            <EditDynamicSplit
              updateSplitValue={updateSplitValue}
              isTransactionAdminOrOwner={isTransactionAdminOrOwner}
              fetchFinancials={fetchFinancials}
              onCancel={() => setIsAddSplitToggle(false)}
              isValidate={isValidate}
              splitType={splitType}
              autocompleteSplitNames={autocompleteSplitNames}
              ledgers={ledgers}
              memberContacts={memberContacts || []}
              comparePrice={comparePrice}
            />
          )}
        </div>
      </Card>
    </div>
  );
};
