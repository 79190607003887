import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Row, Col } from 'components-antd/Grid';

import { inviteClientSteps, YesNoOptions } from 'types/inviteClient';
import { PreApprovalValues, validationSchema } from './types';
import { getActiveClientPreApproval, getInvitedClientValues } from 'store/selectors/app';
import { readFiles } from 'helpers';
import { InviteHeader } from 'components/ClientsDrawer/Headers';
import { FormTitle, Input, RadioOptions, Toggle } from 'components';
import { DatePicker } from 'antd';
import DropzoneWithFileInput from 'components/DropzoneWithFileInput';
import Footer from 'components/ClientsDrawer/Footer';
import Attachment from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/Questions/UploadFiles/Attachment';

import styles from './styles.module.scss';
import { appSetActiveClientPreApproval } from 'store/actions/app';
import classNames from 'classnames';
import moment from 'moment';

const isPreApprovedOptions = [
  {
    id: YesNoOptions.Yes,
    label: YesNoOptions.Yes,
    value: YesNoOptions.Yes,
  },
  {
    id: YesNoOptions.No,
    label: YesNoOptions.No,
    value: YesNoOptions.No,
  },
];

const initialValues: PreApprovalValues = {
  isPreApproved: null,
  isManualDataInput: false,
  Amount: '',
  ApprovedBy: '',
  ApprovalDate: [],
  ExpirationDate: [],
  PreApprovalDocuments: [],
};

const InviteClientPreApproval = ({ onNext, stageIndex, onPrev }) => {
  const dispatch = useDispatch();
  const { firstName } = useSelector(getInvitedClientValues);
  const activeClientPreApproval = useSelector(getActiveClientPreApproval);

  const formik = useFormik({
    initialValues: activeClientPreApproval
      ? {
          ...activeClientPreApproval,
          ApprovalDate: [activeClientPreApproval.ApprovalDate],
          ExpirationDate: [activeClientPreApproval.ExpirationDate],
        }
      : initialValues,
    validateOnMount: false,
    validationSchema,
    onSubmit: (values) => {
      dispatch(
        appSetActiveClientPreApproval({
          ...values,
          ApprovalDate: values.ApprovalDate.length ? values.ApprovalDate[0] : '',
          ExpirationDate: values.ExpirationDate.length ? values.ExpirationDate[0] : '',
          PreApprovalDocuments: values.isManualDataInput ? [] : values.PreApprovalDocuments,
        }),
      );
      onNext(inviteClientSteps.ClientNotifications);
    },
  });

  const handleIsPreApprovedChange = useCallback(
    (value) => {
      if (formik.values.isPreApproved !== value) {
        formik.setFieldValue('isPreApproved', value);
      } else {
        formik.setFieldValue('isPreApproved', null);
      }
    },
    [formik.setFieldValue, formik.values],
  );

  const handleDropFiles = (acceptedFiles) => {
    readFiles(acceptedFiles).then((values) => {
      formik.setFieldValue('PreApprovalDocuments', [
        ...formik.values.PreApprovalDocuments,
        ...values,
      ]);
    });
  };

  const handleChangeFiles = (event) => {
    readFiles(event.target.files).then((values) => {
      formik.setFieldValue('PreApprovalDocuments', [
        ...formik.values.PreApprovalDocuments,
        ...values,
      ]);
    });
  };

  const handleRemoveAttachment = useCallback(
    (event, index) => {
      const filesCopy = [...formik.values.PreApprovalDocuments];
      filesCopy.splice(index, 1);
      formik.setFieldValue('PreApprovalDocuments', filesCopy);
    },
    [formik.values.PreApprovalDocuments],
  );

  const validateDateChange = () => {
    if (
      formik.values.ExpirationDate.length &&
      formik.values.ExpirationDate[0] < formik.values.ApprovalDate[0]
    )
      formik.setFieldValue('ExpirationDate', []);
  };

  return (
    <form onSubmit={formik.handleSubmit} className={styles.isPreApprovedForm}>
      <InviteHeader stageIndex={stageIndex} onPrev={onPrev} isValid={formik.isValid} />
      <div className={styles.formContainer}>
        <FormTitle className={styles.title}>{`Is ${firstName} pre-approved?`}</FormTitle>
        <RadioOptions
          options={isPreApprovedOptions}
          name="isPreApproved"
          onChange={handleIsPreApprovedChange}
          value={formik.values.isPreApproved}
          testid="is_pre_approved"
          className={styles.selectType}
          optionClassName={styles.selectOption}
          checkedClassName={styles.selectChecked}
          labelTextClassName={styles.labelText}
        />
        {formik.values.isPreApproved === YesNoOptions.Yes && (
          <>
            <FormTitle className={styles.subtitle}>
              {formik.values.isManualDataInput
                ? 'Do you want to enter any pre-approval information for your records?'
                : 'Do you want to upload any pre-approval documentation?'}
            </FormTitle>
            {!formik.values.isManualDataInput && (
              <>
                <DropzoneWithFileInput onDrop={handleDropFiles} onChange={handleChangeFiles} />
                <div>
                  {formik.values.PreApprovalDocuments.map((file, index) => (
                    <Attachment
                      key={file?.id || index}
                      onRemove={(event) => handleRemoveAttachment(event, index)}
                      {...file}
                    />
                  ))}
                </div>
              </>
            )}

            {formik.values.isManualDataInput && (
              <div>
                <Row gutter={24}>
                  <Col xs={24} md={12}>
                    <Input
                      variant={Input.LIGHT_ROUND}
                      label="Amount"
                      isNumberFormat
                      name="Amount"
                      prefix="$"
                      type="number"
                      onChange={formik.handleChange}
                      value={formik.values.Amount}
                      placeholder="$100,000"
                      className={styles.inputWrapper}
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <Input
                      variant={Input.LIGHT_ROUND}
                      label="Approved by"
                      name="ApprovedBy"
                      onChange={formik.handleChange}
                      value={formik.values.ApprovedBy}
                      placeholder="Mountain Mortgage"
                      className={styles.inputWrapper}
                    />
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col xs={24} md={12} style={{ paddingTop: '18px' }}>
                    <label className={styles.labelDate}>Approval Date</label>
                    <DatePicker
                      className={classNames(styles.inputHolder, styles.closingDate)}
                      format={'MMM DD, YYYY'}
                      name="ApprovalDate"
                      placeholder={'MM/DD/YYYY'}
                      size="large"
                      onChange={(e: any) => {
                        const formikDateObject = {
                          target: {
                            name: 'ApprovalDate',
                            value: [new Date(e)],
                          },
                        };
                        formik.handleChange(formikDateObject);
                        validateDateChange();
                      }}
                      inputReadOnly
                      value={
                        formik?.values?.ApprovalDate?.length &&
                        formik?.values?.ApprovalDate?.[0] !== ''
                          ? moment(formik?.values?.ApprovalDate?.[0])
                          : null
                      }
                      getPopupContainer={(node) => node}
                      popupClassName={classNames('mosaikCalendar', styles.popupCalender)}
                      allowClear={false}
                    />
                  </Col>
                  <Col xs={24} md={12} style={{ paddingTop: '18px' }}>
                    <label className={styles.labelDate}>Expiration Date</label>
                    <DatePicker
                      className={classNames(styles.inputHolder, styles.closingDate)}
                      format={'MMM DD, YYYY'}
                      name="ExpirationDate"
                      placeholder={'MM/DD/YYYY'}
                      size="large"
                      onChange={(e: any) => {
                        const formikDateObject = {
                          target: {
                            name: 'ExpirationDate',
                            value: [new Date(e)],
                          },
                        };
                        formik.handleChange(formikDateObject);
                        validateDateChange();
                      }}
                      inputReadOnly
                      value={
                        formik?.values?.ExpirationDate?.length &&
                        formik?.values?.ExpirationDate?.[0] !== ''
                          ? moment(formik?.values?.ExpirationDate?.[0])
                          : null
                      }
                      getPopupContainer={(node) => node}
                      popupClassName={classNames('mosaikCalendar', styles.popupCalender)}
                      allowClear={false}
                      disabledDate={(currentDate) =>
                        currentDate && currentDate < moment(formik?.values?.ApprovalDate?.[0])
                      }
                      disabled={!formik?.values?.ApprovalDate?.[0]}
                    />
                  </Col>
                </Row>
              </div>
            )}
            <Toggle
              name="isManualDataInput"
              checked={formik.values.isManualDataInput}
              onChange={formik.handleChange}
              label="Enter data manually"
              labelClassName={styles.toggle}
              labelTextClassName={styles.toggleText}
            />
          </>
        )}
      </div>
      <Footer
        buttonType="submit"
        buttonTitle={formik.values.isPreApproved ? 'Continue' : 'Skip'}
        isValid={formik.isValid}
        testid="invite_buyer_footer"
      />
    </form>
  );
};

export default InviteClientPreApproval;
