import { MlsAccessStatusEnum } from 'pages/Dashboard/MlsAgents/constants';
import { apiServer } from 'settings/web-services/api';

export function getAllUsers(cfg) {
  return apiServer.get('/user', { params: cfg });
}

export function getAnyUserById({ id }) {
  return apiServer.get(`/user/admin-panel/user/${id}`);
}

export function allowMlsAccessForAgent({ userId, mlsId }) {
  return updateMlsAccessStatusForAgent(userId, mlsId, MlsAccessStatusEnum.APPROVED);
}

export function inactivateMlsAccessForAgent({ userId, mlsId }) {
  return updateMlsAccessStatusForAgent(userId, mlsId, MlsAccessStatusEnum.INACTIVE);
}

export function rejectMlsAccessForAgent({ userId, mlsId }) {
  return updateMlsAccessStatusForAgent(userId, mlsId, MlsAccessStatusEnum.REJECTED);
}

function updateMlsAccessStatusForAgent(userId, mlsId, status) {
  return apiServer.patch(`/user/admin-panel/user/${userId}/mls/${mlsId}`, {
    status,
  });
}

export function requestMlsAccessForAgent(mlsId) {
  return apiServer.post(`/user/agent/mls/${mlsId}/request`);
}

export function requestGetTeamAvatarForAgent(id) {
  return apiServer.get(`user/agent/${id}/team/avatar`);
}

export function requestPutTeamAvatarForAgent(cfg, id) {
  return apiServer.put(`user/agent/${id}/team/avatar`, cfg);
}

export function removeMlsAccessForAgent(mlsId) {
  return apiServer.delete(`/user/agent/mls/${mlsId}/request`);
}

export function renameTeamName({ teamId, name }) {
  return apiServer.patch(`/agentTeam/rename/${teamId}`, { name });
}

export function updateUser({ id, Values }) {
  return apiServer.put(`/user/updateUser/${id}`, { Values });
}

export function getAllSoloAgents() {
  return apiServer.get('/user/admin/getAllSoloAgents');
}

export function getAllAgents() {
  return apiServer.get('/user/admin/getAllAgents');
}

export function verifyUserEmail({ id }) {
  return apiServer.put(`/user/verifyEmail/${id}`, {});
}
