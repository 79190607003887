import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Fragment, useCallback, useEffect, useState } from 'react';

import { DYNAMIC_QUESTION_TYPE } from 'app-constants';
import { FormDocumentQuestionType } from 'types';
import {
  getDynamicFormRequiredErrorSelector,
  getDynamicFormScaleSelector,
  getRequestFormProcessSelector,
} from 'store/selectors/requestFormProcess';
import { dynamicManager } from 'pages/FormProcess/DynamicForm/DynamicManager';

import styles from './styles.module.scss';

import moment from 'moment';
import { TextFitField } from './TextFitField';
import {
  DefaultSystemFont,
  SignatureResponseType,
  SignatureResult,
} from 'pages/FormBuilder/components';
import { extractSignatureFieldData } from 'pages/FormProcess/DynamicForm/helper';
import { InfoTooltip } from 'pages/FormProcess/DynamicForm/Containers/DynamicEditor/components/InfoTooltip';
import { getDateFontSize, hasEmoji } from './helper';
import { getFieldInitialFontSize } from './TextFitField/helper';

import { DatePicker } from 'antd';

import { ICON_VARIANT_TYPE, Icons } from 'pages/FormProcess/Icons';
import { TextBox } from './TextBox';

moment.locale('en-us', {
  week: {
    dow: 7,
  },
});

export const textFieldsType = [
  DYNAMIC_QUESTION_TYPE.TextBox,
  DYNAMIC_QUESTION_TYPE.CheckBox,
  DYNAMIC_QUESTION_TYPE.Date,
  DYNAMIC_QUESTION_TYPE.Address,
  DYNAMIC_QUESTION_TYPE.Number,
  DYNAMIC_QUESTION_TYPE.Phone,
  DYNAMIC_QUESTION_TYPE.Currency,
  DYNAMIC_QUESTION_TYPE.FullName,
];

interface TextFieldProps {
  question: FormDocumentQuestionType;
  disabled?: boolean;
  color: { border: string; background: string };
  isHighlighted?: boolean;
  answer: string;
  handleFieldClick: (e) => void;
  isCohesiveFlow?: boolean;
  documentIndex?: number;
  otherSignatoryField?: boolean;
}

export const TextField = ({
  question,
  disabled,
  answer,
  color,
  isHighlighted,
  handleFieldClick,
  documentIndex,
  otherSignatoryField,
}: TextFieldProps) => {
  const scale = useSelector(getDynamicFormScaleSelector);

  const errors = useSelector(getDynamicFormRequiredErrorSelector);

  const questionType = question.Type as DYNAMIC_QUESTION_TYPE;

  let defaultChecked = false;
  let defaultValue = answer;

  const field = question?.Fields?.[0];
  const widget = field?.widgets?.[0];
  const { x, y, width, height } = widget;
  const fieldInitialFontSize = getFieldInitialFontSize(height, scale);

  const [date, setDate] = useState(defaultValue);

  const { currentUserSignature: defaultSignature } = useSelector(getRequestFormProcessSelector);

  const isCheckbox = questionType === DYNAMIC_QUESTION_TYPE.CheckBox;
  const isTextBox = questionType === DYNAMIC_QUESTION_TYPE.TextBox;
  const isNumber = questionType === DYNAMIC_QUESTION_TYPE.Number;
  const isCurrency = questionType === DYNAMIC_QUESTION_TYPE.Currency;
  const isPhone = questionType === DYNAMIC_QUESTION_TYPE.Phone;
  const isDate = questionType === DYNAMIC_QUESTION_TYPE.Date;
  const isAddress = questionType === DYNAMIC_QUESTION_TYPE.Address;
  const isFullName = questionType === DYNAMIC_QUESTION_TYPE.FullName;

  let response: any = [];

  useEffect(() => {
    if (isFullName && !disabled && !otherSignatoryField) {
      let response: SignatureResult | undefined;

      try {
        if (answer) response = JSON.parse(answer);
      } catch (e) {
        response = undefined;
      }

      const responseData = extractSignatureFieldData(response, questionType);

      if (!responseData && defaultSignature) {
        const data = {
          fullName: defaultSignature.FullName,
          type: SignatureResponseType.Text,
          font: DefaultSystemFont,
        };
        dynamicManager.handleQuestionResponse(question.UUID, JSON.stringify(data), documentIndex);
      }
    }
  }, [defaultSignature]);

  try {
    if (answer && isCheckbox) response = JSON.parse(answer);
  } catch (e) {
    response = [];
  }

  if (questionType === DYNAMIC_QUESTION_TYPE.CheckBox) {
    if (response.includes(question.UUID)) {
      defaultChecked = true;
    } else {
      defaultChecked = false;
    }
  }

  const hasValue = isFullName ? defaultValue && JSON.parse(defaultValue).fullName : defaultValue;

  const requiredClass = { [styles.required]: question.Required && !hasValue };

  const isRequired =
    (isNumber || isTextBox || isPhone || isCurrency || isAddress || isDate || isFullName) &&
    errors.some((error) => error.questionUUID === question?.UUID) &&
    !defaultValue?.length;

  const getStyles = () => {
    return {
      style: {
        width: width * scale,
        height: height * scale,
        left: x * scale,
        top: y * scale,
        border: `2px solid ${color.border}`,
        fontFamily: field.fontFamily,
        ...(isTextBox && { fontSize: fieldInitialFontSize }),

        ...(isCheckbox ? { accentColor: '#fff', '--border-color': color.border } : {}),
        ...((isTextBox || isAddress || isFullName) && !isHighlighted
          ? { background: color.background }
          : {}),
        ...(isNumber && !isHighlighted ? { background: color.background } : {}),
        ...(isPhone && !isHighlighted ? { background: color.background } : {}),
        ...(isCurrency && !isHighlighted ? { background: color.background } : {}),
      },
    };
  };

  const getFieldProps = () => {
    return {
      ...(isCheckbox
        ? {
            type: 'checkbox',
            className: classNames(styles.PDFFieldOverlay, styles.checkboxField),
            value: field.id,
            defaultChecked,
            onChange: (e) => {
              let defaultAnswer = response;
              const { checked } = e.target;

              defaultAnswer = defaultAnswer.filter((el) => el !== e.target.value);

              if (checked) {
                defaultAnswer = [question.UUID];
              }

              dynamicManager.handleQuestionResponse(
                question.UUID,
                checked ? JSON.stringify(defaultAnswer) : '',
                documentIndex,
                fieldInitialFontSize,
                true,
              );
            },
          }
        : {}),

      ...(isTextBox || isPhone || isDate || isAddress || isFullName
        ? {
            className: classNames(
              styles.PDFFieldOverlay,
              styles.textField,
              {
                [styles.activeTextField]: isHighlighted,
                [styles.textBoxField]: isTextBox,
              },
              requiredClass,
            ),
            onBlur: (e, fontSize = fieldInitialFontSize) => {
              const value = e.target.value;

              const data = {
                fullName: value?.trim(),
                type: SignatureResponseType.Text,
                font: DefaultSystemFont,
              };

              isFullName &&
                dynamicManager.handleQuestionResponse(
                  question.UUID,
                  JSON.stringify(data),
                  documentIndex,
                  fontSize,
                );

              !isFullName &&
                dynamicManager.handleQuestionResponse(
                  question.UUID,
                  value.trim(),
                  documentIndex,
                  fontSize,
                );
            },

            ...(isFullName && defaultValue
              ? { defaultValue: JSON.parse(defaultValue).fullName }
              : { defaultValue }),
          }
        : {}),

      ...(isNumber || isCurrency
        ? {
            className: classNames(
              styles.PDFFieldOverlay,
              styles.textField,
              {
                [styles.activeTextField]: isHighlighted,
              },
              isCurrency && requiredClass,
              { [styles.prefixContainer]: isCurrency },
            ),
            onBlur: (e, fontSize = fieldInitialFontSize) => {
              dynamicManager.handleQuestionResponse(
                question.UUID,
                e.target.value,
                documentIndex,
                fontSize,
              );
            },
          }
        : {}),
    };
  };

  const onUpdateDateField = (value) => {
    setDate(value);
  };

  const getProps = useCallback(
    () => ({
      ...getStyles(),
      ...getFieldProps(),
      disabled,
    }),
    [scale, isHighlighted, disabled, isRequired, hasValue, ...(isFullName ? [defaultValue] : [])],
  );

  const getInputFieldProps = useCallback(
    () => ({
      ...getFieldProps(),
      disabled,
    }),
    [disabled, isRequired, hasValue],
  );

  return (
    <Fragment>
      <InfoTooltip
        text={isRequired ? <div className={styles.requiredPopover}>Required</div> : ''}
        tooltipOpen={isRequired}
        className={styles.textFieldTooltip}
      >
        {isPhone ? (
          <TextFitField
            onClick={handleFieldClick}
            itemID={`question-${question.UUID}`}
            textFitType="phoneNumber"
            {...getProps()}
            fieldProps={{ className: styles.phoneNumberField }}
            fieldInitialFontSize={fieldInitialFontSize}
          />
        ) : isNumber || isCurrency ? (
          <TextFitField
            textFitType="numberInput"
            itemID={`question-${question.UUID}`}
            defaultValue={defaultValue}
            onClick={handleFieldClick}
            fieldProps={{
              requiredClass,
              isCurrency,
              className: styles.numberField,
              prefixClass: styles.currencyPrefix,
            }}
            {...getProps()}
            fieldInitialFontSize={fieldInitialFontSize}
          />
        ) : isDate ? (
          <div
            {...getProps()}
            className={`${getProps().className} ${styles.dateBoxContainer}`}
            itemID={`question-${question.UUID}`}
            onBlur={(event) =>
              getProps?.().onBlur &&
              getProps?.().onBlur?.(
                event,
                getDateFontSize(getProps().style.width, fieldInitialFontSize),
              )
            }
          >
            <DatePicker
              disabled={disabled}
              format={'MM/DD/YYYY'}
              placeholder={getProps().style.width > 70 ? 'Date' : ''}
              value={date ? moment(date) : null}
              onClick={handleFieldClick}
              onChange={onUpdateDateField}
              className={styles.formDatePicker}
              getPopupContainer={(node) => node}
              style={{
                background: !isHighlighted ? color.background : '#fff',
                fontSize: `${getDateFontSize(getProps().style.width, fieldInitialFontSize)}px`,
              }}
              popupClassName={classNames('mosaikCalendar', styles.formDatePickerPopup)}
            />
          </div>
        ) : isAddress || isFullName ? (
          <TextFitField
            onClick={handleFieldClick}
            itemID={`question-${question.UUID}`}
            isFullName={isFullName}
            isAddress={isAddress}
            fieldInitialFontSize={fieldInitialFontSize}
            {...getProps()}
            isSmallField={height < 20}
          />
        ) : isCheckbox ? (
          <input onClick={handleFieldClick} {...getProps()} itemID={`question-${question.UUID}`} />
        ) : (
          <TextBox
            inputProps={getProps()}
            fieldProps={getInputFieldProps()}
            questionUUID={question.UUID}
            onClick={handleFieldClick}
            height={height}
          />
        )}
      </InfoTooltip>
    </Fragment>
  );
};
