import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import { useSelector, useDispatch } from 'react-redux';
import { getTransactionAccessSelector, getTransactionSelector } from 'store/selectors/transaction';
import {
  getTransactionEffect,
  updateTransactionParticipantsEffect,
} from 'store/effects/transactions';

import Icon from 'pages/Workshop/Transactions/TransactionOverview/Icons';
import { RemoveFile } from 'components/Icons';
import { AgentCoordinatorField } from '../AgentCoordinatorField';
import { Input } from 'components';
import { Button } from 'components-antd';

import styles from './styles.module.scss';
import transactionViewStyles from './../TransactionView/styles.module.scss';
import { AgentCoordinatorPrompt } from '../AgentCoordinatorPrompt';

export const NamesArray = ({
  label,
  keyToUpdate,
  namesArray,
  isDisableField,
  setEditId,
  memberContacts,
  stringArrayNames,
  refetchAgentsCoordinators,
  isAgentInfo = false,
  isCoordinatorInfo = false,
  isBuyerTransaction = false,
  isSellerTransaction = false,
}) => {
  const params: { id?: string } = useParams();
  const dispatch = useDispatch();
  const [isPending, setIsPending] = useState(false);
  const { transaction } = useSelector(getTransactionSelector);
  const { fullAccess } = useSelector(getTransactionAccessSelector);
  const [isEdit, setIsEdit] = useState(false);
  const [arrayInputFields, setArrayInputFields] = useState<any>([]);
  const [showRemovePrompt, setShowRemovePrompt] = useState<boolean>(false);
  const [itemRemovePrompt, setItemRemovePrompt] = useState<any>(null);

  //TEMP: Disable editing of Owner(s) field
  const disableEdit = label === 'Owner(s)';

  useEffect(() => {
    setArrayInputFields(namesArray && namesArray?.length ? namesArray : ['']);
  }, [namesArray]);

  const handleChange = (index, selectedParticipant) => {
    const newArray = [...arrayInputFields];
    newArray[index] = selectedParticipant.target.value;
    setArrayInputFields(newArray);
  };

  const handleRemoveField = (index) => {
    const newArray = [...arrayInputFields];
    newArray.splice(index, 1);
    setArrayInputFields(newArray);
  };

  const handleAddField = () => {
    if (stringArrayNames || arrayInputFields?.every((item) => item?.value)) {
      setArrayInputFields([...arrayInputFields, '']);
    }
  };

  const onEdit = () => {
    if (!fullAccess || isDisableField(keyToUpdate) || disableEdit) return null;
    setIsEdit(true);
    setEditId(keyToUpdate);
  };

  const updateTransaction = () => {
    setIsPending(true);

    dispatch(
      updateTransactionParticipantsEffect(
        {
          id: transaction?.Id,
          [keyToUpdate]: arrayInputFields.filter((item) => item.trim() !== ''),
        },
        (err) => {
          if (!err) {
            setIsEdit(false);
            dispatch(getTransactionEffect({ id: params?.id }, { silent: true }));
            setEditId(null);
          }
          setIsPending(false);
        },
      ),
    );
  };

  const onCancel = () => {
    setArrayInputFields(namesArray ?? ['']);
    setIsEdit(false);
    setEditId(null);
  };

  return (
    <>
      {!isEdit ? (
        <div
          className={classNames(
            styles.clientNames,
            { [transactionViewStyles.fullAccess]: fullAccess && !disableEdit },
            { [transactionViewStyles.disableField]: !!isDisableField(keyToUpdate) },
          )}
          onClick={onEdit}
        >
          <div>
            <div>{label}:</div>
            <div className={styles.names}>
              {stringArrayNames
                ? namesArray &&
                  namesArray.map((item, i) => (
                    <div key={i} className={styles.value}>
                      {item}
                    </div>
                  ))
                : namesArray &&
                  namesArray.map((item, i) => (
                    <div key={i} className={styles.value}>
                      {item?.label}
                    </div>
                  ))}
            </div>
          </div>
          {fullAccess && !isDisableField(keyToUpdate) && !disableEdit && (
            <Icon testid="edit_icon" className={styles.editIcon} variant={Icon.EDIT} />
          )}
        </div>
      ) : (
        <div className={transactionViewStyles.editWrapper}>
          <div className={styles.clientNameContainer}>
            <div className={styles.header}>
              <span>{label}:</span>
              <Icon variant={Icon.PLUS_BG} onClick={() => handleAddField()} />
            </div>
            {arrayInputFields &&
              arrayInputFields.map((item, index) => (
                <div key={index} className={styles.clientNameInput}>
                  {stringArrayNames ? (
                    <Input
                      placeholder="Names (e.g. John, Doe)"
                      variant="lightRound"
                      value={item}
                      onChange={(e) => handleChange(index, e)}
                      disabled={isPending}
                    />
                  ) : (
                    <AgentCoordinatorField
                      item={item}
                      memberContacts={memberContacts}
                      refetchAgentsCoordinators={() => {
                        refetchAgentsCoordinators();
                        setEditId(null);
                        setIsEdit(false);
                      }}
                      isAgentInfo={isAgentInfo}
                      isCoordinatorInfo={isCoordinatorInfo}
                      isBuyerTransaction={isBuyerTransaction}
                      isSellerTransaction={isSellerTransaction}
                    />
                  )}
                  {stringArrayNames || item?.value ? (
                    <RemoveFile
                      className={styles.removeClientName}
                      onClick={() => {
                        if (stringArrayNames) handleRemoveField(index);
                        else {
                          setItemRemovePrompt(item);
                          setShowRemovePrompt(true);
                        }
                      }}
                    />
                  ) : (
                    <div style={{ width: 22 }} />
                  )}
                </div>
              ))}
          </div>
          <div className={transactionViewStyles.buttons}>
            {stringArrayNames && (
              <Button
                variant="secondary"
                size="large"
                disabled={isPending}
                onClick={updateTransaction}
                loading={isPending}
              >
                Update
              </Button>
            )}
            <Button variant="hollow-bordered" size="large" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </div>
      )}
      {/* Remove/Change Role Modal */}
      <AgentCoordinatorPrompt
        item={itemRemovePrompt}
        open={showRemovePrompt}
        onClose={() => setShowRemovePrompt(false)}
        refetchAgentsCoordinators={() => {
          refetchAgentsCoordinators();
          setEditId(null);
          setIsEdit(false);
        }}
      />
    </>
  );
};

export default NamesArray;
