import { Modal } from 'components-antd';

import styles from './style.module.scss';

import { ICON_VARIANT_TYPE, Icons } from 'pages/FormProcess/Icons';
import { InsertTemplateClauses } from './InsertTemplateClauses';
import { TemplateListItemType } from 'pages/Templates/Clauses/components';

interface InsertClauseModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelectClause: (clause: TemplateListItemType) => void;
}

export const InsertClauseModal = ({ isOpen, ...rest }: InsertClauseModalProps) => {
  return (
    <Modal
      open={isOpen}
      width={1002}
      footer={null}
      onCancel={rest.onClose}
      maskClosable={false}
      destroyOnClose
      className={styles.insertClauseModal}
      closeIcon={<Icons variant={ICON_VARIANT_TYPE.Close} />}
    >
      <InsertTemplateClauses {...rest} />
    </Modal>
  );
};
