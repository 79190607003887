import { getDateTime } from 'helpers/formatters';

import styles from './styles.module.scss';
import { useState } from 'react';

export const Notes = (props) => {
  const { BookingPageNotes, CreatedDate } = props;
  const [showFull, setFull] = useState(false);
  const wordLimit = 75;

  const pageNotes = BookingPageNotes || '';

  const toggleShowFull = () => {
    setFull(!showFull);
  };

  const note = showFull
    ? `${pageNotes} `
    : `${pageNotes.slice(0, wordLimit)}${pageNotes.length > wordLimit ? '..' : ''}`;

  return (
    <div>
      <div className={styles.noteDetail}>
        <p className={styles.note}>
          {note}
          {pageNotes.length > wordLimit && (
            <span className={styles.readMoreLess} onClick={toggleShowFull}>
              Read {showFull ? 'Less' : 'More'}
            </span>
          )}
        </p>
        <p className={styles.noteDateTime}>{getDateTime(CreatedDate, 'at', 'MMM D')}</p>
      </div>
    </div>
  );
};
