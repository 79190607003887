import { FC } from 'react';
import styles from './styles.module.scss';
import { map } from 'lodash-es';
import { ISaleHistory } from 'store/reducers/radarPortfolio/types';
import moment from 'moment';
import { formatNumber } from 'helpers';

interface ISale {
  data: ISaleHistory[];
}

export const Sale: FC<ISale> = ({ data }) => {
  const transformSales = () => {
    return data
      .sort(
        (a, b) =>
          new Date(a.amount.salerecdate).getTime() - new Date(b.amount.salerecdate).getTime(),
      )
      .map((sale, index, array) => {
        const currentPrice = sale.amount.saleamt;
        const previousPrice = index > 0 ? array[index - 1].amount.saleamt : currentPrice;
        const priceDifference = currentPrice - previousPrice;
        const subValue = previousPrice
          ? ((priceDifference / previousPrice) * 100).toFixed(1)
          : '0.0';
        const subValueType = priceDifference > 0 ? 'increase' : 'decrease';

        return {
          date: moment(sale.amount.salerecdate).format('MM/DD/YYYY'),
          action: sale.amount.saletranstype,
          price: `$${formatNumber(currentPrice)}`,
          subValue: `${priceDifference >= 0 ? '+' : ''}${subValue}%`,
          subValueType,
          mls: '',
          mlsType: '',
        };
      });
  };

  return (
    <div className={styles.table}>
      {map(
        [
          {
            date: 'Date',
            action: 'Action',
            price: 'Price',
            mls: 'MLS',
            subValue: '',
            mlsType: '',
            subValueType: '',
          },
          ...transformSales(),
        ],
        ({ date, action, price, subValue = '', mls, mlsType, subValueType }, index) => (
          <div className={styles.tableRow}>
            <div className={styles.column}>
              <p className={index === 0 ? styles.labelHeader : styles.label}>{date}</p>
            </div>
            <div className={styles.column}>
              <p className={index === 0 ? styles.labelHeader : styles.value}>{action}</p>
            </div>
            <div className={styles.column}>
              <p className={index === 0 ? styles.labelHeader : styles.label}>
                {price}
                <span className={styles[subValueType]}>{subValue}</span>
              </p>
            </div>
            <div className={styles.column}>
              <p className={index === 0 ? styles.labelHeader : styles.label}>{mls}</p>
              <p className={index === 0 ? styles.labelHeader : styles.label}>{mlsType}</p>
            </div>
          </div>
        ),
      )}
    </div>
  );
};
