import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { SplitItem } from '..';
import { EditSplitItem } from '../EditSplitItem';
import { Row, Col, Button } from 'components-antd';
import { ErrorMessage } from '../../.././Error/ErrorMessage';
import CheckboxComponent from 'components/Form/Checkbox';

import { addSplitLineItem, removeSplitLineItem, updateSplitLineItem } from 'api/financials';
import { getUserId } from 'store/selectors/user';

import { showErrorMessage } from 'helpers';

import styles from './../SplitCard/styles.module.scss';
import {
  SplitTabTypes,
  SplitTypes,
} from 'pages/Workshop/Transactions/TransactionFinancials/TransactionFinancials';
import { getTransactionAgentsCoordinatorsSelector } from 'store/selectors/transaction';
import { TransactionUserRoleIds } from 'settings/constants/transaction';

export const SplitItemWrapper = ({
  splitItem,
  isAddSplitToggle,
  editFieldKey,
  isTransactionAdminOrOwner,
  updateSplitValue,
  isValidate,
  isUpdateButtonDisable,
  onCancel,
  fetchFinancials,
  setEditFieldKey,
  memberContacts,
  splitType,
}) => {
  const agentsCoordinators = useSelector(getTransactionAgentsCoordinatorsSelector);
  const [isEditState, setIsEditState] = useState(false);
  const [isUpdateSplitLoading, setUpdateSplitLoading] = useState(false);
  const loggedInUserId = useSelector(getUserId);
  const [isRestricted, setIsRestricted] = useState(false);
  const [ledgerAccount, setLedgerAccount] = useState<any>(null);
  const [defaultAccountItem, setDefaultAccountItem] = useState<any>({
    label: null,
    value: null,
  });

  useEffect(() => {
    if (splitItem?.SplitType?.SplitType === SplitTypes.AGENT) {
      if (splitItem?.LedgerAccountUserId || splitItem?.LedgerAccountContactId) {
        const ledgerAccountUser = splitItem?.LedgerAccountUser || splitItem?.LedgerAccountContact;
        const ledgerAccountId = splitItem?.LedgerAccountUserId || splitItem?.LedgerAccountContactId;

        setLedgerAccount(
          splitItem?.LedgerAccountUserId
            ? { LedgerAccountUserId: ledgerAccountId }
            : { LedgerAccountContactId: ledgerAccountId },
        );
        setDefaultAccountItem({
          label: ledgerAccountUser
            ? `${ledgerAccountUser?.FirstName} ${ledgerAccountUser?.LastName}`
            : null,
          value: ledgerAccountId,
        });
      } else {
        const defaultAgent = agentsCoordinators?.Agents?.find(
          (item) =>
            item?.RoleId ===
            (splitType === SplitTabTypes.LISTING
              ? TransactionUserRoleIds.ListingAgent
              : TransactionUserRoleIds.BuyersAgent),
        );
        setLedgerAccount(
          defaultAgent?.UserId
            ? { LedgerAccountUserId: defaultAgent?.UserId }
            : { LedgerAccountContactId: defaultAgent?.ContactId },
        );
        setDefaultAccountItem({
          label: defaultAgent?.Name,
          value: defaultAgent?.UserId,
        });
      }
    }
  }, [splitItem]);

  useEffect(() => {
    if (splitItem?.alreadyExists) setIsRestricted(splitItem?.IsRestricted);
    if (
      !splitItem?.alreadyExists &&
      (splitItem?.SplitType?.SplitType === SplitTypes.TEAM ||
        splitItem?.SplitType?.SplitType === SplitTypes.BROKERAGE)
    )
      setIsRestricted(true);
  }, []);

  const handleCancel = () => {
    setIsEditState(false);
    setEditFieldKey(null);
    onCancel();
  };

  const handleUpdate = async () => {
    const valueToUpdate = splitItem?.IsSplitTypePercentage
      ? splitItem?.SplitPercentage
      : splitItem?.SplitValue;

    if (valueToUpdate === null || valueToUpdate === 0) {
      // Delete the split when value is zero or null.
      try {
        setUpdateSplitLoading(true);
        await removeSplitLineItem(splitItem?.Id);
        fetchFinancials();
      } catch (err) {
        setUpdateSplitLoading(false);
        setEditFieldKey(null);
        showErrorMessage(err);
      } finally {
        setUpdateSplitLoading(false);
      }
    } else {
      const payload = {
        LedgerId: splitItem?.LedgerId,
        LedgerLineItemId: splitItem?.Id,
        LedgerAccountId: splitItem?.LedgerAccountId,
        ...(splitItem?.IsSplitTypePercentage
          ? {
              Percent: parseFloat(splitItem?.SplitPercentage),
            }
          : { Amount: parseFloat(splitItem?.SplitValue) }),
        IsRestricted: isRestricted,
        UpdatedBy: loggedInUserId,
        ...(splitItem?.SplitType?.SplitType === SplitTypes.AGENT ? ledgerAccount : {}),
      };

      try {
        setUpdateSplitLoading(true);
        await updateSplitLineItem(payload);
        fetchFinancials();
      } catch (err) {
        setUpdateSplitLoading(false);
        setEditFieldKey(null);
        showErrorMessage(err);
      } finally {
        setUpdateSplitLoading(false);
        setIsEditState(false);
        setEditFieldKey(null);
      }
    }
  };

  const handleCreate = async () => {
    const payload = {
      LedgerId: splitItem?.LedgerId,
      LedgerAccountId: splitItem?.LedgerAccountId,
      ...(splitItem?.IsSplitTypePercentage
        ? {
            Percent: splitItem?.SplitPercentage || 0,
          }
        : { Amount: splitItem?.SplitValue || 0 }),
      IsRestricted: isRestricted,
      CreatedBy: loggedInUserId,
      UpdatedBy: loggedInUserId,
      ...(splitItem?.SplitType?.SplitType === SplitTypes.AGENT ? ledgerAccount : {}),
    };

    try {
      setUpdateSplitLoading(true);
      await addSplitLineItem(payload);
      fetchFinancials();
    } catch (err) {
      setUpdateSplitLoading(false);
      setEditFieldKey(null);
      showErrorMessage(err);
    } finally {
      setUpdateSplitLoading(false);
      setIsEditState(false);
      setEditFieldKey(null);
    }
  };

  const isLedgerAccountValid = useMemo(
    () => !(!ledgerAccount?.LedgerAccountUserId && !ledgerAccount?.LedgerAccountContactId),
    [ledgerAccount],
  );

  return (
    <div>
      {!isEditState ? (
        <SplitItem
          label={splitItem?.SplitType?.SplitTitle}
          amount={splitItem?.SplitValue}
          percentage={splitItem?.SplitPercentage}
          setEditField={(Id) => {
            setIsEditState(Id);
            setEditFieldKey(Id);
          }}
          valueTypeEntered={splitItem?.defaultSplitValueType} // Property tells which type of value(% or $) user added while adding Split.
          splitType={splitItem?.SplitType?.SplitType}
          isTransactionAdminOrOwner={isTransactionAdminOrOwner}
          isRestricted={splitItem?.IsRestricted}
          isDisabledField={
            isAddSplitToggle || (editFieldKey !== null && splitItem?.Id !== editFieldKey)
          }
          ledgerAccount={splitItem?.LedgerAccountUserId || splitItem?.LedgerAccountContactId}
          accountUser={splitItem?.alreadyExists ? defaultAccountItem?.label : ''}
        />
      ) : (
        <>
          <EditSplitItem
            item={splitItem}
            updateSplitValue={updateSplitValue}
            isTransactionAdminOrOwner={isTransactionAdminOrOwner}
            memberContacts={memberContacts}
            onChangeAccountId={(account) => setLedgerAccount(account)}
            accountUserItem={defaultAccountItem}
          />
          <Row style={{ margin: '14px 0' }}>
            <Col lg={24}>
              {isValidate && (
                <ErrorMessage message="The cumulative value of the splits cannot exceed the GCI value." />
              )}
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className={styles.buttonsContainer}>
                <Button
                  variant="secondary"
                  size="large"
                  disabled={
                    isUpdateButtonDisable ||
                    (splitItem?.SplitType?.SplitType === SplitTypes.AGENT &&
                      !isLedgerAccountValid) || // Account ID is mandatory for Agent splits
                    (!splitItem?.alreadyExists &&
                      !(splitItem?.SplitPercentage > 0 || splitItem?.SplitValue > 0)) // If creating a split, Percent or Amount should be > 0
                  }
                  loading={isUpdateSplitLoading}
                  onClick={splitItem?.alreadyExists ? handleUpdate : handleCreate}
                >
                  Update
                </Button>
                <Button variant="hollow-bordered" size="large" onClick={handleCancel}>
                  Cancel
                </Button>
              </div>
            </Col>
            {isTransactionAdminOrOwner && (
              <Col lg={12} className={styles.checkboxCont}>
                <CheckboxComponent
                  id="restricted-add"
                  label="Restricted"
                  checked={isRestricted}
                  onChange={(e, v, checked) => setIsRestricted(checked)}
                  direction={CheckboxComponent.DIRECTION_RIGHT}
                />
              </Col>
            )}
          </Row>
        </>
      )}
    </div>
  );
};
