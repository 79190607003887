export default {
  templatesTasks: '/templates/tasks',
  templatesTimelines: '/templates/timelines',
  templatesTransactions: '/templates/transactions',
  templatesTransactionCreate: '/templates/transaction/create',
  templatesTransactionView: '/templates/transaction/:id',
  templatesTransactionEdit: '/templates/transaction/:id/edit',
  templatesDetails: '/templates/details',
  templatesProjects: '/templates/projects',
  templatesProjectCreate: '/templates/project/create',
  templatesProjectView: '/templates/project/:id',
  templatesProjectEdit: '/templates/project/:id/edit',
  templatesClause: '/templates/clauses',
  templatesClauseCreate: '/templates/clauses/create',
  templatesClauseEdit: '/templates/clauses/edit',
};
