import { createAction } from 'redux-actions';

export const createUpdateOfferAction = createAction('REQUEST/CREATE_UPDATE_OFFER');
export const createOfferSubmissionAction = createAction('POST/CREATE_OFFER_SUBMISSION');
export const deleteOfferAction = createAction('DELETE/DELETE_OFFER');
export const sendAnnouncementAction = createAction('POST/MEGA_PHONE_ANNOUNCEMENT');
export const offerListAction = createAction('GET/OFFER_LIST');
export const updateOfferIterationAction = createAction('UPDATE/OFFER_ITERATION');
export const sendCounterOfferAction = createAction('POST/SEND_COUNTER_OFFER');
export const selectedOffersForComparison = createAction('GET/SELECTED_OFFERS_FOR_COMPARISON');
export const uploadAIFileAction = createAction('POST/UPLOAD_AI_FILE');
export const deleteAIFileSessionAction = createAction('DELETE/AI_FILE_SESSION');
