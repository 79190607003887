import { FC, ReactElement, SVGAttributes } from 'react';

export const TagsIcon: FC<SVGAttributes<SVGElement>> = (
  props: SVGAttributes<SVGElement>,
): ReactElement => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.93934 19.9393L4.06066 14.0607C3.47487 13.4749 3.47487 12.5251 4.06066 11.9393L11.5607 4.43934C11.842 4.15804 12.2235 4 12.6213 4H18.5C19.3284 4 20 4.67157 20 5.5V11.3787C20 11.7765 19.842 12.158 19.5607 12.4393L12.0607 19.9393C11.4749 20.5251 10.5251 20.5251 9.93934 19.9393Z"
      stroke="#747475"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <circle cx="16" cy="8" r="1.5" fill="#747475" />
  </svg>
);
