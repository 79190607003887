import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { RadarHeader } from '../components/RadarHeader';
import { AntCard, PageWrapper } from 'components-antd';
import { TooltipIcon } from 'components/Icons';
import ListingDetailMap from 'pages/Properties/ListingDetail/components/ListingDetailMap';
import { map } from 'lodash-es';
import { Icons } from './Icons';
import { HistoryCard } from './components/HistoryCard';
import { NeighborhoodActivityCard } from './components/NeighborhoodActivityCard';
import {
  requestPropertyDetailsEffect,
  requestPropertyNeighborhoodEffect,
} from 'store/effects/radarPortfolio';
import { useLocation } from 'react-router-dom';
import {
  getPropertyReferenceDetailsSelector,
  getPropertyReferenceNeighborhoodSelector,
} from 'store/selectors/radarPortfolio';
import { PENDING } from 'settings/constants/apiState';
import { Wrapper } from 'components';
import { formatNumber } from 'helpers';
import moment from 'moment';
import { ValuationCard } from 'pages/Intel/components/PropertyTab/components';
import { getValuationDataSelector } from 'store/selectors/intel';
import { requestGetAddressValuationDetailsEffect } from 'store/effects/intel';

export const RadarProperty: FC = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const clientName = queryParams.get('clientName');
  const address1 = queryParams.get('address1') ?? '';
  const address2 = queryParams.get('address2') ?? '';
  const clientId = queryParams.get('clientId') ?? '';
  const params = {
    address1,
    address2,
    clientId,
  };
  const { data, state } = useSelector(getPropertyReferenceDetailsSelector);
  const { data: neighborhoodData, state: neighborhoodState } = useSelector(
    getPropertyReferenceNeighborhoodSelector,
  );
  const isPendingDetails = state === PENDING;
  const isPendingNeighborhood = neighborhoodState === PENDING;
  const parsedData = data?.parsedData?.[0];
  const neighbourhoodRadius = data?.address?.neighbourhoodRadius ?? '';
  const closingDate = data?.address?.closingDate
    ? moment(data?.address?.closingDate).format('M/D/YY')
    : '';
  const tax = data?.assessmentHistory;
  const sale = data?.saleHistory;
  const metadata = data?.address?.address?.metadata;
  const { data: valuationData, isPending: isPendingValuationData } =
    useSelector(getValuationDataSelector);

  useEffect(() => {
    dispatch(requestPropertyDetailsEffect(search));
    dispatch(requestGetAddressValuationDetailsEffect(params, { showError: false }));
  }, []);

  const mapOptions = {
    markers: {
      [`${metadata?.longitude} ${metadata?.latitude}`]: [
        {
          id: data?.address?._id,
          geometry: {
            coordinates: {
              lng: metadata?.longitude,
              lat: metadata?.latitude,
            },
          },
        },
      ],
    },
    zoom: 14,
    center: {
      lng: metadata?.longitude,
      lat: metadata?.latitude,
    },
  };

  return (
    <PageWrapper version={2} mainPageContentStyle={styles.pageWrapper}>
      <RadarHeader>
        <div className={styles.titleInputContainer}>
          <div className={styles.title}>{address1}</div>
          <div className={styles.subHeading}>
            <p>
              Client: <span>{clientName}</span>
            </p>
            <TooltipIcon size={'16px'} color={'#747475'} className={styles.tooltipIcon} />
          </div>
        </div>
      </RadarHeader>
      <div className={styles.pageContent}>
        <Wrapper isPending={isPendingDetails} className={styles.container}>
          <div className={styles.detailsRow}>
            <AntCard className={styles.detailsCard}>
              <p className={styles.heading}>Details</p>
              <ListingDetailMap options={mapOptions} onExpand={() => {}} />
              {map(
                [
                  { icon: Icons.CLOSED, label: 'Closed', value: closingDate },
                  { icon: Icons.CLOCK, label: 'Added to Mosaik', value: '' },
                  { icon: Icons.BEDS, label: 'Beds', value: parsedData?.beds ?? '' },
                  { icon: Icons.BATHS, label: 'Baths', value: parsedData?.baths ?? '' },
                  {
                    icon: Icons.SIZE,
                    label: 'Size',
                    value: parsedData?.size ? formatNumber(parsedData?.size) + 'sqft' : '',
                  },
                  { icon: Icons.TYPE, label: 'Type', value: parsedData?.type ?? '' },
                  {
                    icon: Icons.LOT_SIZE,
                    label: 'Lot Size',
                    value: parsedData?.lotSize ? formatNumber(parsedData?.lotSize) + 'sqft' : '',
                  },
                  {
                    icon: Icons.NEIGHBORHOOD,
                    label: 'Neighborhood Activity Radius',
                    value: neighbourhoodRadius,
                  },
                ],
                ({ label, value, icon }) => (
                  <div className={styles.tableRow}>
                    <div className={styles.iconRow}>
                      <Icons variant={icon} />
                      <p className={styles.label}>{label}</p>
                    </div>
                    <p className={styles.value}>{value}</p>
                  </div>
                ),
              )}
            </AntCard>
          </div>
          <div className={styles.cardsRow}>
            <ValuationCard
              valuationData={valuationData ?? []}
              isPending={isPendingValuationData}
              params={params}
              rental={false}
            />
            <ValuationCard
              valuationData={valuationData ?? []}
              isPending={isPendingValuationData}
              params={params}
              rental={true}
            />
            <NeighborhoodActivityCard isPending={isPendingNeighborhood} />
            <HistoryCard tax={tax ?? []} sale={sale ?? []} />
          </div>
        </Wrapper>
      </div>
    </PageWrapper>
  );
};
