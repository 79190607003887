import React from 'react';
import { useSelector } from 'react-redux';
import { map } from 'lodash-es';

import {
  getFormattedListingDetailData,
  getListingDetailPropertyData,
} from 'store/selectors/listingDetail';
import { getListingMarket } from 'store/selectors/listingDetail';
import { getUserRolesMapSelector } from 'store/selectors/user';

import { Accordion, TitleValueTable } from 'components';
import { Disclaimer } from 'components/Icons';

import PropertyTabAgentInformation from './AgentInformation';
import InfoBlockList from '../InfoBlockList';
import BuyerCommutes from '../BuyerCommutes';
import styles from './styles.module.scss';
import ClimateData from '../ClimateData';
import { getMlsImage, getMlsDisclaimer, getSupportEmail } from 'helpers/listingDisclaimer';

const PropertyTab = () => {
  const { description, keyDetails, listingInfo, property, interior, community, commutes } =
    useSelector(getListingDetailPropertyData);
  const { isAgent, isClient } = useSelector(getUserRolesMapSelector);
  const market = useSelector(getListingMarket);
  const mlsDisclaimer = getMlsDisclaimer(market);
  const { mlsLastChecked } = useSelector(getFormattedListingDetailData);
  const { mlsLogoURL, mlsLogoWidth } = getMlsImage(market);
  return (
    <div className={styles.propertyTabContainer}>
      {!!description?.text && (
        <Accordion open testid="description_block" className={styles.accordion} title="Description">
          <div testid="descr_content" className={styles.content}>
            {description.text}
          </div>
        </Accordion>
      )}
      {!!keyDetails?.length && (
        <Accordion open testid="key_details_block" className={styles.accordion} title="Key Details">
          <div className={styles.content}>
            <TitleValueTable items={keyDetails} />
          </div>
        </Accordion>
      )}
      {isClient && !!commutes?.length && (
        <Accordion testid="commute_times_block" className={styles.accordion} title="Commute Times">
          <BuyerCommutes data={commutes} />
        </Accordion>
      )}
      {!!interior?.length && (
        <Accordion testid="interior_features_block" className={styles.accordion} title="Interior">
          <InfoBlockList list={interior} />
        </Accordion>
      )}
      {!!property?.length && (
        <Accordion
          testid="exterior_features_block"
          className={styles.accordion}
          title="Property Details"
        >
          <InfoBlockList list={property} />
        </Accordion>
      )}
      {!!community?.length && (
        <Accordion testid="building_info_block" className={styles.accordion} title="Community">
          <InfoBlockList list={community} />
        </Accordion>
      )}
      {!!listingInfo?.length && (
        <Accordion
          testid="listing_info_block"
          className={styles.accordion}
          title="Listing Information"
        >
          <InfoBlockList list={listingInfo} />
        </Accordion>
      )}
      {/* {market !== 'himls' && !!listingInfo?.length && (
        <Accordion testid="climate_check_block" className={styles.accordion} title="Climate Data">
          <ClimateData />
        </Accordion>
      )} */}
      {isAgent && (
        <Accordion testid="agent_info_block" className={styles.accordion} title="Agent Information">
          <PropertyTabAgentInformation />
        </Accordion>
      )}

      <div className={styles.propertyDisclaimer}>
        <p className={styles.content}>
          {mlsLogoURL && (
            <div>
              <img width={mlsLogoWidth} src={mlsLogoURL}></img>
              <br></br>
            </div>
          )}
          {mlsDisclaimer}
          <br></br>
          <br></br>
          {!!mlsLastChecked && (
            <div testid="mls_last_checked" className={styles.mlsLastChecked}>
              {mlsLastChecked}
            </div>
          )}
          <i>
            Please contact{' '}
            <a href={`mailto:${getSupportEmail(getListingMarket)}`}>
              {getSupportEmail(getListingMarket)}
            </a>{' '}
            to report incorrect information.
          </i>
        </p>
      </div>
    </div>
  );
};

export default PropertyTab;
