import { FC, ReactElement, SVGAttributes } from 'react';

export const DraftsIcon: FC<SVGAttributes<SVGElement>> = (
  props: SVGAttributes<SVGElement>,
): ReactElement => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.8346 10.5V17.8333C18.8346 18.2754 18.659 18.6993 18.3465 19.0118C18.0339 19.3244 17.61 19.5 17.168 19.5H7.83464C7.39261 19.5 6.96868 19.3244 6.65612 19.0118C6.34356 18.6993 6.16797 18.2754 6.16797 17.8333V6.16667C6.16797 5.72464 6.34356 5.30072 6.65612 4.98816C6.96868 4.67559 7.39261 4.5 7.83464 4.5H14.668L16.3346 6.16667"
      stroke="#676767"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 15H14.1314L22.3515 6.77998C22.7667 6.36472 23 5.80152 23 5.21426C23 4.627 22.7667 4.0638 22.3515 3.64854C21.9362 3.23329 21.373 3 20.7857 3C20.1985 3 19.6353 3.23329 19.22 3.64854L11 11.8686V15Z"
      stroke="#676767"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
