import PropTypes from 'prop-types';

import { Modal } from 'components-antd';
import { TaskModalForm } from '../TaskModalForm';

import styles from './styles.module.scss';

export const TaskModal = (props) => {
  const {
    isOpen,
    onCloseModal,
    isNew,
    showSubheading,
    isTemplate,
    editData,
    templateId,
    isAssignTask,
    assignTask,
    isAggregatedTasks,
    isViewMode,
    isFormTask,
    isCampaignTask,
    campaignId,
    ...taskProps
  } = props;

  return (
    <Modal
      open={isOpen}
      width={675}
      footer={null}
      onCancel={onCloseModal}
      maskClosable={false}
      destroyOnClose
      className={styles.taskModal}
    >
      <TaskModalForm
        isOpen={isOpen}
        isNew={isNew}
        showSubheading={showSubheading}
        isTemplate={isTemplate}
        editData={editData}
        templateId={templateId}
        isAssignTask={isAssignTask}
        assignTask={assignTask}
        onCancelModal={onCloseModal}
        isAggregatedTasks={isAggregatedTasks}
        isViewMode={isViewMode}
        isFormTask={isFormTask}
        isCampaignTask={isCampaignTask}
        campaignId={campaignId}
        {...taskProps}
      />
    </Modal>
  );
};

TaskModal.propTypes = {
  onCloseModal: PropTypes.func,
  isOpen: PropTypes.bool,
  isNew: PropTypes.bool,
  isViewMode: PropTypes.bool,
  isTemplate: PropTypes.bool,
  templateId: PropTypes.number,
  campaignId: PropTypes.number,
  editData: PropTypes.object,
  isAggregatedTasks: PropTypes.bool,
  isTransactionTask: PropTypes.bool,
  onUpdate: PropTypes.func,
  onRemove: PropTypes.func,
  taskLength: PropTypes.number,
  isFormTask: PropTypes.bool,
  isCampaignTask: PropTypes.bool,
};

TaskModal.defaultProps = {
  onCloseModal: () => {},
  isOpen: false,
  isNew: false,
  isViewMode: false,
  isTemplate: false,
  templateId: null,
  editData: null,
  isAggregatedTasks: false,
  isFormTask: false,
  campaignId: null,
  isCampaignTask: false,
};
