import { Spinner } from 'components';
import { Modal, Text } from 'components-antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { dynamicManager } from 'pages/FormProcess/DynamicForm/DynamicManager';
import { TemplateSignForm } from 'pages/FormProcess/components/FormTemplateWizard/components/TemplateSignForm';

import { getSmartFormBuilderEffect } from 'store/effects/formBuilder';
import {
  getDynamicTemplateDocumentEffect,
  getFormMetaEffect,
  updateFormRoleEffect,
  updateTemplateSignatoriesEffect,
} from 'store/effects/formProcess';
import {
  getDynamicFormEditorConfigSelector,
  getDynamicFormSelector,
  getSmartFormDataSelector,
} from 'store/selectors/requestFormProcess';

import styles from './styles.module.scss';
import { prepareSignFormDetails } from 'pages/FormProcess/components/FormTemplateWizard/helper';
import { prepareEditorConfig, prepareTemplateData } from 'store/reducers/requestFormProcess/helper';
import { getQuestionsByRole } from '../EditSignatoryModal/helper';
import { updateDynamicFormAction } from 'store/actions/formProcess';
import { showSuccessMessage } from 'helpers';
import _ from 'lodash';

export const SignatoryModal = (props) => {
  const { onClose, open } = props;

  const dispatch = useDispatch();

  const {
    meta: { formVersionId, formRolesIds, formRoles: savedFormRoles } = {},
    dynamicFormDocuments,
    bundleDocumentsDetails,
  } = useSelector(getDynamicFormSelector);

  const dynamicForm = useSelector(getDynamicFormSelector);
  const { questions } = useSelector(getDynamicFormEditorConfigSelector);

  const { isError } = useSelector(getSmartFormDataSelector);
  const isTemplate = dynamicManager.isTemplate();
  const isBundleTemplate = dynamicManager.isBundleTemplate();

  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    if (open) {
      if (isTemplate || isBundleTemplate) {
        const { formRoles } = prepareSignFormDetails(savedFormRoles);

        dispatch(updateFormRoleEffect({ formRoles }));

        dispatch(
          getSmartFormBuilderEffect({ versionId: formVersionId }, () => {
            setLoading(false);
          }),
        );
      } else {
        dispatch(getFormMetaEffect());
      }
    }
  }, [open]);

  const reloadPage = (replacedRoles) => {
    dispatch(
      getDynamicTemplateDocumentEffect(
        {
          templateId: dynamicManager.getSingleTemplateId(),
        },
        (error, response) => {
          if (!error) {
            const data = prepareTemplateData(response.data.value);
            const newEditorConfig: any = prepareEditorConfig(data, dynamicForm) || {};

            newEditorConfig.selectedFieldIndex = -1;

            newEditorConfig.questions = getQuestionsByRole(
              questions,
              newEditorConfig.formRoles,
              replacedRoles,
            );

            if (isBundleTemplate) {
              bundleDocumentsDetails?.forEach((item, index) => {
                const preparedQuestions = getQuestionsByRole(
                  item.questions,
                  newEditorConfig.formRoles,
                  replacedRoles,
                );

                item.questions = preparedQuestions;

                if (dynamicFormDocuments) {
                  dynamicFormDocuments[index].questions = preparedQuestions;
                }
              });
            }

            setTimeout(() => {
              dispatch(
                updateDynamicFormAction({
                  editorConfig: newEditorConfig,

                  ...(isBundleTemplate ? { bundleDocumentsDetails, dynamicFormDocuments } : {}),
                }),
              );

              setUpdating(false);
              onClose();
            }, 300);
          } else {
            setUpdating(false);
          }
        },
      ),
    );
  };

  const handleContinue = (details) => {
    setUpdating(true);

    const { templateRoleIds: templateRoles } = details;
    const payload = {
      templateRoles,
      isBundleTemplate,
      ...(isTemplate && { templateId: dynamicManager.getTemplateId() }),
      ...(isBundleTemplate && { templateId: dynamicManager.getTemplateBundleId() }),
    };

    dispatch(
      updateTemplateSignatoriesEffect(payload, (error) => {
        if (!error) {
          showSuccessMessage('Signatories updated successfully');

          const replacedRoles = templateRoles.filter(({ IsReplaced }) => IsReplaced);

          reloadPage(replacedRoles);
        } else {
          setUpdating(false);
        }
      }),
    );
  };

  const renderTemplateContainer = () => {
    if (loading) {
      return <Spinner />;
    } else if (isError) {
      return <Text>There was an issue with loading, please try again.</Text>;
    } else {
      return (
        <TemplateSignForm
          customForm={true}
          loading={updating}
          editMode={true}
          onContinue={handleContinue}
          templateState={{
            formVersionId,
            formRoleIds: formRolesIds,
          }}
          editSignatoryMode={true}
          signatoryModal={true}
        />
      );
    }
  };

  return (
    <Modal
      open={open}
      width={675}
      onCancel={onClose}
      maskClosable={false}
      destroyOnClose
      footer={null}
      title={isTemplate || isBundleTemplate ? 'Edit Signatories' : ''}
      className={styles.editSignatoryModal}
    >
      {isTemplate || isBundleTemplate ? renderTemplateContainer() : <></>}
    </Modal>
  );
};
