import { Tooltip } from 'components-antd';
import styles from './styles.module.scss';

export const DisableSectionCover = () => (
  <Tooltip
    placement="topLeft"
    getPopupContainer={(trigger) => trigger}
    overlayClassName={'mosaikTooltip'}
    title={
      <span>
        To add additional financial information, please enter <br />
        the commission rate or amount.
      </span>
    }
  >
    <div className={styles.disableFeeCoverContainer} />
  </Tooltip>
);
