import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Checkbox, Col, Popover, Row } from 'components-antd';
import { ArrowDown, ArrowUp } from 'components/Icons';
import { Card } from '../../Card';
import {
  getLoadingGraphsSelector,
  getFilteredGraphsSelector,
} from 'store/selectors/agentDashboard';
import { Wrapper as PendingWrapper } from 'components';
import { AreaConfigData, expiringStats, setStats } from '../helper';

import styles from './styles.module.scss';
import { PulseAreaGraph } from '../../PulseAreaGraph';

export const ExpiringListingWidget = ({ teamStats }) => {
  const filterValues = ['Today', 'This Week', 'This Month'];
  const [filter, setFilter] = useState<string>('This Week');
  const [open, setOpen] = useState(false);
  const [totalRequests, setTotalRequests] = useState<number>(0);
  const [totalAmount, setTotalAmount] = useState<string>('0');
  const [data, setData] = useState<AreaConfigData[]>([] as AreaConfigData[]);
  const [currentStats, setCurrentStats] = useState<expiringStats>();

  const { isPending } = useSelector(getLoadingGraphsSelector);
  const stats = useSelector(getFilteredGraphsSelector('expiring', teamStats));

  useEffect(() => {
    !isPending && setCurrentStats(stats);
  }, [isPending, teamStats]);

  useEffect(() => {
    let value;

    if (filter === 'Today') {
      value = 'dueToday';
    } else if (filter === 'This Week') {
      value = 'dueThisWeek';
    } else {
      value = 'dueThisMonth';
    }

    setStats(currentStats, value, setData, setTotalAmount, setTotalRequests);
  }, [currentStats]);

  const handleChange = (checkedValues) => {
    setOpen(false);

    let filterOption = checkedValues.target.value;
    let currentValue;
    if (filterOption === 'Today') {
      currentValue = 'dueToday';
    } else if (filterOption === 'This Week') {
      currentValue = 'dueThisWeek';
    } else {
      currentValue = 'dueThisMonth';
    }

    setFilter(filterOption);
    setStats(currentStats, currentValue, setData, setTotalAmount, setTotalRequests);
  };
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const popoverContent = () => (
    <ul className={styles.filterOptions}>
      {filterValues.map((value, index) => {
        return (
          <li>
            <Checkbox
              className={styles.sortCheckbox}
              key={index}
              onChange={handleChange}
              checked={value == filter}
              value={value}
            >
              <div className={styles.checkboxHeading}>{value}</div>
            </Checkbox>
          </li>
        );
      })}
    </ul>
  );

  return (
    <Card
      cardTitle={'Expiring Listings'}
      showHeader={false}
      cardWrapperClassName={styles.cardWrapper}
      cardHeaderClassName={styles.cardHeader}
      cardBodyClassName={styles.cardBody}
    >
      <PendingWrapper
        className={styles.pendingWrapper}
        spinnerClassname={styles.centerSpinner}
        isPending={isPending}
        loaderClassName={styles.loader}
      >
        <header className={styles.widgetHeader}>
          <div>
            <h3>{'Expiring Listings'}</h3>
          </div>
          <div>
            <Popover
              content={popoverContent}
              trigger="click"
              open={open}
              onOpenChange={handleOpenChange}
              overlayClassName={styles.filterDropdown}
              placement="bottomRight"
              className={styles.dropdown}
            >
              <span className={styles.switchButton} onClick={(e) => e.preventDefault()}>
                {filter}
              </span>
              {open ? (
                <ArrowUp className={styles.icon} />
              ) : (
                <ArrowDown className={styles.icon} color={'#747475'} />
              )}
            </Popover>
          </div>
        </header>

        <Row className={styles.countContainer}>
          <span className={styles.totalAmount}>{totalAmount}</span>
          {+totalRequests !== 0 && <span className={styles.count}>{`(${totalRequests})`}</span>}
        </Row>

        <Row align="middle">
          <Col xs={24} sm={24}>
            <PulseAreaGraph
              data={data}
              xField="day"
              yField="value"
              color="#FF576D"
              areaFillColor="l(270) 0:#FF576D00 1:#FF576D"
            />
          </Col>
        </Row>
      </PendingWrapper>
    </Card>
  );
};
