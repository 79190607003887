export const fieldRquiredRule = {
  required: true,
  message: 'Required',
};

export const extractClauseData = (clauseDetails) => {
  return [
    {
      name: 'name',
      value: clauseDetails.Name,
    },
    {
      name: 'description',
      value: clauseDetails.Description,
    },
    {
      name: 'publishToTeam',
      value: clauseDetails.PublishToTeam,
    },
  ];
};
