import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { TransactionCloseComponentIds } from 'app-constants';
import { getTransactionSelector } from 'store/selectors/transaction';
import { TransactionUserRole } from 'settings/constants/transaction';
import {
  getTransactionClientAddressEffect,
  removeClientAddressEffect,
} from 'store/effects/transactions';
import { SmartFormIconVariants, SmartFormIcons } from 'components/SmartForm';
import Question from 'pages/Workshop/Transactions/TransactionCreate/components/Question';
import Answers from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/Answers';
import { Continue } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/Continue';

import styles from './styles.module.scss';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';

interface ClientProfileRemoveAddressProps {
  previousStage?: TransactionCloseComponentIds;
  setStage: (stage: TransactionCloseComponentIds) => void;
}

export const ClientProfileRemoveAddress = ({
  previousStage,
  setStage,
}: ClientProfileRemoveAddressProps) => {
  const dispatch = useDispatch();
  const params: { id?: string } = useParams();

  const { clientAddress } = useSelector(getTransactionSelector);

  useEffect(() => {
    const filteredClients = clientAddress?.data?.filter(
      (clientInfo) => clientInfo.Role === TransactionUserRole.Seller,
    );
    if (!clientAddress.isPending && !filteredClients.length) {
      if (previousStage === TransactionCloseComponentIds.ActiveSearchInstances) {
        setStage(TransactionCloseComponentIds.ClientProfileAddAddress);
      } else if (previousStage === TransactionCloseComponentIds.ClientProfileAddAddress) {
        setStage(TransactionCloseComponentIds.ActiveSearchInstances);
      }
    }
  }, [clientAddress]);

  const onDeleteAddress = (clientId: number, addressId: number) => {
    dispatch(
      removeClientAddressEffect({ id: params?.id, clientId, addressId }, (err) => {
        if (!err) {
          dispatch(getTransactionClientAddressEffect({ id: params?.id }));
        }
      }),
    );
  };

  const getAddress = (address) => {
    if (!address) return '';

    if (typeof address === 'string') {
      return address;
    }

    if (address.address && typeof address.address === 'string') {
      return address.address;
    }

    const { delivery_line_1, components } = address || {};

    if (delivery_line_1 && components) {
      const { city_name, state_abbreviation, zipcode } = components;

      return `${delivery_line_1}, ${city_name}, ${state_abbreviation} ${zipcode}`;
    }

    return '';
  };

  return (
    <div className={styles.ClientProfileRemoveAddress}>
      <Question>
        These are the addresses on each client&apos;s profile. <br />
        Do you want to remove them?
      </Question>
      <AnswersContainer>
        <>
          {clientAddress.data
            ?.filter((clientInfo) => clientInfo.Role === TransactionUserRole.Seller)
            .map((clientInfo) => (
              <div key={clientInfo.Id}>
                <div className={styles.clientName}>{clientInfo.Name}</div>
                {clientInfo?.Address?.filter((addressInfo) => addressInfo.address).map(
                  (addressInfo, i) => {
                    const address = getAddress(addressInfo.address);

                    const splitAddress = address.split(/,(.+)/);

                    return (
                      <div key={`${addressInfo.Id}-${i}`}>
                        <div className={styles.removeAddress}>
                          <div className={styles.addressBlock}>
                            <div className={styles.addressInfo}>
                              <span className={styles.addressLocation}>
                                {splitAddress[0] || ''}
                                {addressInfo.suiteUnit
                                  ? `, Unit ${addressInfo.suiteUnit}`
                                  : undefined}
                              </span>
                              <span className={styles.addressState}>{splitAddress[1] || ''}</span>
                            </div>
                          </div>
                          <div className={styles.actionIconContainer}>
                            <span
                              className={styles.actionBtn}
                              onClick={() => onDeleteAddress(clientInfo.Id, addressInfo.Id)}
                            >
                              <SmartFormIcons
                                stroke="#515151"
                                width={24}
                                height={24}
                                variant={SmartFormIconVariants.Trash}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  },
                )}
              </div>
            ))}
        </>
      </AnswersContainer>
      <ButtonsContainer>
        <Continue
          onClick={() => {
            setStage(TransactionCloseComponentIds.ClientProfileAddAddress);
          }}
          className={styles.submit}
        />
      </ButtonsContainer>
    </div>
  );
};
