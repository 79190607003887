import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { ClientRole } from 'components/Transactions';
import { Checkbox } from 'components';
import { Question } from 'pages/RequestQuote/components';
import { Continue } from '../../Continue';
import { AnswersContainer } from '../../AnswersContainer';
import { ButtonsContainer } from '../../ButtonsContainer';
import {
  TransactionUserRoleMap,
  transactionPreFormQuestionsIds,
} from 'settings/constants/transaction';
import { getTransactionPreFormSelector } from 'store/selectors/transactions';
import {
  getTransactionRolesEffect,
  setTransactionPreFormQuestionsEffect,
} from 'store/effects/transactions';
import { getUserDataSelector, getUserDefaultRoleIdForTransaction } from 'store/selectors/user';
import { getTransactionRolesSelector } from 'store/selectors/transactionRoles';

import styles from './styles.module.scss';

interface CreatorRoleProps {
  className: string;
  onNext: (event) => void;
  currentQuestionId: typeof transactionPreFormQuestionsIds[keyof typeof transactionPreFormQuestionsIds];
  heading?: string;
}

export const CreatorRole = ({
  className,
  currentQuestionId,
  onNext,
  heading = 'What is your role in the transaction?',
}: CreatorRoleProps) => {
  const dispatch = useDispatch();
  const { preForm } = useSelector(getTransactionPreFormSelector);

  const { transactionRoles } = useSelector(getTransactionRolesSelector);
  const defaultRoleId = useSelector(getUserDefaultRoleIdForTransaction);
  const user = useSelector(getUserDataSelector);

  const onAnswerHandler = (id, answer) => {
    dispatch(
      setTransactionPreFormQuestionsEffect({
        [id]: answer,
      }),
    );
  };

  useEffect(() => {
    if (defaultRoleId) {
      const defaultRole = transactionRoles?.find((item) => item?.Id === defaultRoleId);
      const defaultOption = {
        name: TransactionUserRoleMap[defaultRole?.Title] || defaultRole?.Title,
        value: defaultRole?.Title,
        roleId: defaultRole?.Id,
      };

      onAnswerHandler(transactionPreFormQuestionsIds.creatorRole, defaultOption);
      onAnswerHandler(transactionPreFormQuestionsIds.saveDefaultCreatorRole, true);
    }
  }, [
    defaultRoleId,
    transactionRoles,
    user,
    preForm?.[transactionPreFormQuestionsIds.whoAreYouRepresenting],
  ]);

  useEffect(() => {
    if (currentQuestionId === transactionPreFormQuestionsIds.projectCategory) {
      dispatch(getTransactionRolesEffect());
    }
  }, [currentQuestionId]);

  const onNextHandler = (event) => {
    if (currentQuestionId === transactionPreFormQuestionsIds.creatorRole) {
      onNext(event);
    }
  };

  const canContinue = useMemo(
    () => !!preForm?.[transactionPreFormQuestionsIds.creatorRole],
    [preForm],
  );

  return (
    <div testid="what_creator_role" className={classNames(styles.container, className)}>
      <Question>{heading}</Question>
      <AnswersContainer>
        <ClientRole
          className={styles.clientRole}
          wrapperClassName={styles.inputWrapper}
          arrowIconClassName={styles.arrowdownicon}
          onChange={(e) =>
            onAnswerHandler(transactionPreFormQuestionsIds.creatorRole, e.target.value)
          }
          value={preForm?.[transactionPreFormQuestionsIds.creatorRole]}
          showFullAccessRoles
          isSearchIcon={false}
        />
        <Checkbox
          checked={preForm?.[transactionPreFormQuestionsIds.saveDefaultCreatorRole]}
          className={styles.checkbox}
          onChange={(e) => {
            const checked = e.target.checked;
            onAnswerHandler(transactionPreFormQuestionsIds.saveDefaultCreatorRole, checked);
          }}
          direction="right"
          label="Save as default"
          labelTextClassName={styles.label}
        />
      </AnswersContainer>
      <ButtonsContainer>
        <Continue onClick={onNextHandler} disabled={!canContinue} />
      </ButtonsContainer>
    </div>
  );
};
