import { MosaikResponse } from 'api/types';
import { apiServer } from 'settings/web-services/api';

export function putUserData(cfg, { userId, urlRole }) {
  return apiServer.put(`/user/${urlRole}/${userId}`, cfg);
}

export function getUserData(cfg) {
  return apiServer.get('/accounts/user', cfg);
}

export function login(cfg, opts) {
  return apiServer.post('/accounts/password/authenticate', cfg, opts);
}

export function logout(cfg) {
  return apiServer.post('/accounts/logout', cfg);
}

export function signUpUser(cfg) {
  return apiServer.post('/accounts/password/register', cfg);
}

export function emailExists(cfg) {
  return apiServer.get('/user/accounts/emailExists', cfg);
}

export function addListingToFavorites(cfg) {
  return apiServer.post(`/user/client/profile/favorites/${cfg.contextKey}`, cfg);
}

export function removeListingFromFavorites(cfg) {
  return apiServer.delete(`/user/client/profile/favorites/${cfg.id}/${cfg.contextKey}`, cfg);
}

export function getSavedSearches() {
  return apiServer.get('/user/agent/instances');
}

export async function markProfileAsComplete(cfg) {
  return apiServer.get(`/user/agent/${cfg.id}/markComplete`);
}

export function getSearchInstances(cfg) {
  return apiServer.get(`/user/profile/save-search-instances`, { params: cfg });
}

export function getSearchInstanceById(cfg) {
  return apiServer.get(`/user/profile/search-instance/${cfg.id}`);
}

export function deactivateSearchInstance(cfg) {
  return apiServer.post(`/user/profile/search-instance/${cfg.id}/deactivate`, cfg);
}

export function activateSearchInstance(cfg) {
  return apiServer.post(`/user/profile/search-instance/${cfg.id}/activate`, cfg);
}

export function deleteSearchInstance(cfg) {
  return apiServer.delete(`/user/profile/search-instance/${cfg.id}`);
}

export function renameSearchInstance({ id, ...cfg }) {
  return apiServer.post(`/user/profile/search-instance/${id}/rename`, cfg);
}

export function updateSearchInstance(cfg) {
  const { id, ...config } = cfg;
  return apiServer.patch(`/user/profile/search-instance/${cfg.id}`, config);
}

export function getClientInstances(cfg) {
  return apiServer.get(`/user/client/instances`, { params: cfg });
}

export function getClientsSearchInstances(cfg) {
  return apiServer.get('/user/agent/clients/search-instances', { params: cfg });
}

export function verifyEmail(cfg) {
  return apiServer.post('/accounts/password/verifyEmail', cfg);
}

export function sendVerificationEmail(data) {
  return apiServer.post('/accounts/password/sendVerificationEmail', data);
}

export function sendOtpVerificationEmail() {
  return apiServer.post('/user/emailCodeVerification/requestCode');
}

export function verifyOtpCode(data: { code: string }) {
  return apiServer.post('/user/emailCodeVerification/verify', data);
}

export function updateNotificationsSettings(cfg) {
  return apiServer.post('/user/notifications-preferences', cfg);
}

export function postRefreshToken(cfg) {
  return apiServer.post('/accounts/refreshTokens', cfg);
}

export function getClientDetail(cfg) {
  return apiServer.get(`/user/client/${cfg.id}`);
}

export function updateUserData(cfg, { userId }) {
  return apiServer.put(`/user/${userId}`, cfg);
}

export function registerSalesLead(cfg: SalesLeadRequest) {
  return apiServer.post(`/accounts/sales-lead`, cfg);
}

export function fetchSalesLead() {
  return apiServer.get(`/accounts/sales-lead`);
}

export function fetchSalesLeadSignUpUsers() {
  return apiServer.get(`/selfsignup/signUpUsers`);
}

export function requestMultiFactorAuthCode(data) {
  return apiServer.post(`/user/multiFactorAuth/requestCode`, data);
}

export function requestMultiFactorAuthCodeInfo(data) {
  return apiServer.post(`/user/multiFactorAuth/requestCodeInformation`, data);
}

export function requestPhoneVerificationCode(data) {
  return apiServer.post(`/user/phoneVerification/requestCode`, data);
}

export function getPhoneVerificationCodeInfo() {
  return apiServer.get(`/user/phoneVerification/getCodeInformation`);
}

export function verifyPhoneNumberWithCode(data) {
  return apiServer.post(`/user/phoneVerification/verifyCode`, data);
}

export function getEmailVerificationInfo() {
  return apiServer.get(`/user/emailVerification/status`);
}

export function getTokenValidationStatus(cfg) {
  return apiServer.post(`/user/tokenValidation/status`, cfg);
}

export function retainClient(id, cfg) {
  return apiServer.put(`/user/client/${id}/retention`, cfg);
}

export function setSelectedMlsServicesForAgent(
  data: SetSelectedMlsServicesForAgentRequest,
): Promise<MosaikResponse<SetSelectedMlsServicesForAgentResponse>> {
  return apiServer.patch(`/user/agent/profile/mlsServices`, data);
}

export function fetchIsClientInvitedFromTransaction() {
  return apiServer.get(`/user/invited-from-transaction`);
}

export function getAgentUnreadMessagesByClients(cfg) {
  return apiServer.get(`/user/agent/unreadClientsMessages`, { params: cfg });
}

interface SetSelectedMlsServicesForAgentRequest {
  SelectedMlsServices: number[];
}

interface SetSelectedMlsServicesForAgentResponse {
  Id: number;
  SelectedMlsServices: Array<{
    MlsId: number;
  }>;
}

interface SalesLeadRequest {
  Email: string;
  FirstName: string;
  LastName: string;
  LeadSource: string;
  Status?: string;
  LeadInformation?: Record<string, any>;
}
