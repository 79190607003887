import { getDateOnly } from 'helpers';

export const getTransactionPrice = (status, keyDatesPrices, checkClose = true) => {
  if (status === 'Closed' && checkClose) {
    return keyDatesPrices?.ClosePrice;
  }

  return (
    keyDatesPrices?.ClosePrice || keyDatesPrices?.UnderContractPrice || keyDatesPrices?.ListingPrice
  );
};

export const getTransactionEndDate = (status, keyDatesPrices, isListed = false) => {
  if (status === 'Canceled') {
    return getDateOnly(keyDatesPrices?.CanceledDate);
  } else if (status === 'Closed') {
    return getDateOnly(keyDatesPrices?.CloseDate);
  }
  if (isListed) {
    return getDateOnly(keyDatesPrices?.ListingExpireDate);
  }
  const date = keyDatesPrices?.UnderContractCloseDate || keyDatesPrices?.ListingExpireDate;
  return getDateOnly(date);
};

export const getTransactionEffectiveDate = (keyDatesPrices, isProject = false) => {
  const date = isProject
    ? keyDatesPrices?.KickoffDate
    : keyDatesPrices?.PurchaseEffectiveDate || keyDatesPrices?.ListingEffectiveDate;
  return getDateOnly(date);
};

export const getTransactionClosingDate = (keyDatesPrices, isProject = false) => {
  const date = isProject ? keyDatesPrices?.ExpiredDate : keyDatesPrices?.UnderContractCloseDate;
  return getDateOnly(date);
};
