export enum RadarPortfolioViewTypes {
  ViewByClient = 'ViewByClient',
  ViewByProperty = 'ViewByProperty',
  ViewByAgent = 'ViewByAgent',
}

export enum NeighborhoodViewTypes {
  List = 'List',
  Map = 'Map',
}
