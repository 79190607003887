import { useSelector } from 'react-redux';
import { LocationDetails } from '../LeftPanel/LocationDetails';
import styles from './styles.module.scss';
import { getShowingDetails } from 'store/selectors/showingAppointment';
import { useEffect, useState } from 'react';
import { ICON_VARIANT_TYPE, Icons } from '../Icons';
import { ShowingInformationModal } from '../ShowingInformationModal';

export const Header = (props) => {
  const { hasError, mobileWidth } = props;
  const { PropertyTransaction, ...rest } = useSelector(getShowingDetails);
  const [infoOpen, setInfoOpen] = useState(false);

  // useEffect(() => {
  //   if (!mobileWidth && infoOpen) {
  //     setInfoOpen(false);
  //   }
  // }, [mobileWidth]);

  const toggleModal = () => {
    setInfoOpen((prev) => !prev);
  };

  return (
    <div className={styles.headerArea}>
      <ShowingInformationModal
        isOpen={infoOpen}
        showingDetails={{ PropertyTransaction, ...rest }}
        onClose={toggleModal}
      />

      <h1 className={styles.headTitle}>Book a Showing</h1>
      <LocationDetails PropertyTransaction={PropertyTransaction} />

      {!hasError ? (
        <Icons variant={ICON_VARIANT_TYPE.INFO} className={styles.infoIcon} onClick={toggleModal} />
      ) : null}
    </div>
  );
};
