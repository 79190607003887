import {
  LOCALITY,
  POSTAL_CODE,
  ROUTE,
  STATE,
  STREET_NUMBER,
  SUB_LOCALITY,
  SUB_LOCALITY_LEVEL_1,
  SUB_PREMISE,
} from 'settings/constants/locations';
import { getLocationContext, getPlaceComponent } from './locations';

interface Address {
  city: string;
  state: string;
  zip: string;
  line1: string;
  providerPlaceId: string;
  placeName?: string;
}

interface Location {
  place_id: string;
  result?: { placeName?: string };
}

/**
 * Creates an address object from a location object.
 *
 * @param location - The location object to generate the address from.
 * @param result - An optional result object containing additional place information.
 * @returns An object containing:
 * - `data`: The created address object or `null` if location is undefined.
 * - `success`: A boolean indicating whether the address was successfully created.
 */
const createAddress = (
  location: Location | undefined,
  result?: { placeName?: string },
): { data: Address | null; success: boolean } => {
  if (location === undefined) {
    return { data: null, success: false };
  }
  const updatedCity = getLocationContext(location, [LOCALITY, SUB_LOCALITY_LEVEL_1, SUB_LOCALITY]);
  const updatedState = getLocationContext(location, STATE);
  const updatedZip = getLocationContext(location, POSTAL_CODE);
  const street = getPlaceComponent(location, ROUTE);
  const streetNumber = getLocationContext(location, STREET_NUMBER);
  const subPremise = getLocationContext(location, SUB_PREMISE);

  return {
    data: {
      city: updatedCity,
      state: updatedState,
      zip: updatedZip,
      line1: [streetNumber, street?.shortName ?? street?.name, subPremise]
        .filter((i) => !!i)
        .join(' '),
      providerPlaceId: location.place_id,
      placeName: result?.placeName || '',
    },
    success: true,
  };
};

export { createAddress };
