import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import AgentInfo from 'pages/Dashboard/Users/View/AgentInfo';
import BuyerInfo from 'pages/Dashboard/Users/View/BuyerInfo';
import SellerInfo from 'pages/Dashboard/Users/View/SellerInfo';
import ThirdPartyInfo from 'pages/Dashboard/Users/View/ThirdPartyInfo';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { AGENT, CLIENT, THIRD_PARTY } from 'settings/constants/roles';

import { getUserDashboardEffect, updateUserEffect } from 'store/effects/adminPanel/users';
import { getUserDashboardSelector, getUserDataDashboardSelector } from 'store/selectors/adminPanel';
import { Wrapper, Button } from 'components';
import Input from 'components/Form/Input';

import styles from './styles.module.scss';
import { verifyUserEmailEffect } from '../../../../store/effects/adminPanel/users';

const validationSchema = Yup.object().shape({
  Email: Yup.string().trim().required(),
});

const ViewUser = (props) => {
  const { className, id, onClose } = props;
  const dispatch = useDispatch();
  const { isPending } = useSelector(getUserDashboardSelector);
  const user = useSelector(getUserDataDashboardSelector);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(getUserDashboardEffect({ id }));
    }
  }, [id]); //eslint-disable-line

  const getAdditionalInfoByRole = useCallback(() => {
    switch (user?.Roles[0]) {
      case CLIENT: // T3-1: BUYER replaced with CLIENT
        return <BuyerInfo user={user[user?.Roles[0]]} />;
      // case CLIENT: // T3-1: SELLER replaced with CLIENT
      //   return <SellerInfo user={user[user?.Roles[0]]} />;
      case AGENT:
        return <AgentInfo user={user[user?.Roles[0]]} userId={user.Id} />;
      case THIRD_PARTY:
        return <ThirdPartyInfo user={user[user?.Roles[0]]} userEmail={user.Email} />;
      default:
        return null;
    }
  }, [user]);

  const onSubmit = useCallback(
    (values) => {
      dispatch(
        updateUserEffect(
          {
            id: user.Id,
            Values: values,
          },
          {},
          (err) => {
            if (!err) {
              onClose();
            }
          },
        ),
      );
    },
    [user, dispatch],
  );

  const formik = useFormik({
    initialValues: {
      Email: user?.Email,
    },
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const onVerifyEmail = useCallback(() => {
    setLoading(true);
    dispatch(
      verifyUserEmailEffect(
        {
          id: user.Id,
        },
        {},
        (err) => {
          if (!err) {
            dispatch(
              getUserDashboardEffect(
                {
                  id: user.Id,
                },
                { silent: true },
              ),
            );
          }
          setLoading(false);
        },
      ),
    );
  }, [user, dispatch]);

  return (
    <Wrapper isPending={isPending} className={classNames(styles.wrapper, className)}>
      <div className={styles.title}>
        {user?.FirstName}
        &nbsp;
        {user?.LastName}
      </div>
      <div className={styles.descriptionWrapper}>
        <div className={styles.description}>{user?.Roles?.[0]}</div>
        {!user?.IsEmailVerified && (
          <Button
            title="Verify Email"
            className={styles.emailVerifyBtn}
            onClick={() => {
              onVerifyEmail();
            }}
            disabled={loading}
          />
        )}
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.inputBlock}>
          <span className={classNames(styles.label, styles.inputLabel)}>Email (Verified)</span>
          <span className={styles.infoBlockForMultipleLines}>
            <div className={styles.emailWrapper}>
              <Input
                name="Email"
                type="text"
                error={formik.touched.Email ? formik.errors.Email : ''}
                value={formik.values.Email}
                onChange={formik.handleChange}
                className={styles.emailInput}
              />
              <span>{`(${user?.IsEmailVerified ? 'Yes' : 'No'})`}</span>
            </div>
          </span>
        </div>
        {user?.Phones?.length && (
          <div className={styles.inputBlock}>
            <span className={styles.label}>Phones</span>
            <span className={styles.infoBlockForMultipleLines}>
              {user?.Phones.map(({ PhoneNumber }) => (
                <div>{PhoneNumber}</div>
              ))}
            </span>
          </div>
        )}
        <div className={styles.inputBlock}>
          <span className={styles.label}>Created Date</span>
          <span className={styles.infoBlockForMultipleLines}>
            {moment(user?.CreatedDate).format('MM-DD-YYYY')}
          </span>
        </div>
        {getAdditionalInfoByRole()}
        <Button type="submit" className={styles.saveBtn} title="Save"></Button>
      </form>
    </Wrapper>
  );
};

ViewUser.propTypes = {
  className: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onClose: PropTypes.func,
};

ViewUser.defaultProps = {
  className: '',
};

export default ViewUser;
