import classNames from 'classnames';

import { InputLabel, Input } from 'components';
import { ClientRole } from 'components/Transactions';

import styles from './styles.module.scss';

interface RoleFieldProps {
  value: string;
  error?: string;
  onChange: (value) => void;
  onBlurRoleInput: (...args) => void;
  onRoleKeyPress: (...args) => void;
  className?: string;
  isAgentInfo?: boolean;
  isCoordinatorInfo?: boolean;
  isBuyerTransaction?: boolean;
  isSellerTransaction?: boolean;
  required?: boolean;
  hideLabel?: boolean;
  placeholder?: string;
}

export const Role = ({
  value,
  error,
  onChange,
  onBlurRoleInput,
  onRoleKeyPress,
  className,
  isAgentInfo = false,
  isCoordinatorInfo = false,
  isBuyerTransaction = false,
  isSellerTransaction = false,
  required = false,
  hideLabel = false,
  placeholder = '',
}: RoleFieldProps) => {
  return (
    <div className={classNames(styles.role, className)}>
      <div className={styles.fieldWrapper}>
        <>
          {!hideLabel && (
            <div className={styles.labelContainer}>
              <InputLabel label="Role" className={styles.label} />
              {required && <span className={styles.asterik}>*</span>}
            </div>
          )}
          <ClientRole
            isSearchIcon={false}
            onChange={(e) => {
              const selectedValue = e.target.value;
              onChange(selectedValue);
            }}
            value={{ tagRole: value }}
            onBlur={onBlurRoleInput}
            onKeyPress={onRoleKeyPress}
            variant={Input.LIGHT_ROUND}
            placeholder={placeholder || ' '}
            isAgentInfo={isAgentInfo}
            isCoordinatorInfo={isCoordinatorInfo}
            isBuyerTransaction={isBuyerTransaction}
            isSellerTransaction={isSellerTransaction}
          />
        </>
      </div>
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
};
