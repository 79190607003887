import { Fragment } from 'react';
import { Modal } from 'components-antd';

import classnames from 'classnames';

import styles from './styles.module.scss';
import { Icons } from '../Icons';

import { AgentDetails } from '../LeftPanel/AgentDetails';
import { Notes } from '../LeftPanel/Notes';
import classNames from 'classnames';

export const ShowingInformationModal = ({ isOpen, onClose, showingDetails }) => {
  const {
    PropertyTransaction,
    isPending,
    SellerAgent,
    AgentBrokerage,
    DisplayBookingPageNotes,
    BookingPageNotes,
    BookingPageMessages,
    CreatedDate,
  } = showingDetails;

  const getFormatterAddress = () => {
    const Property = PropertyTransaction?.Property;
    const { Line1, City, State, Zip } = Property?.Address || {
      Line1: '',
      City: '',
      State: '',
      Zip: '',
    };

    return (
      <Fragment>
        <div className={styles.title}>{Line1}</div>
        <div className={styles.subTitle}>{`${City}, ${State} ${Zip}`}</div>
      </Fragment>
    );
  };

  return (
    <Modal
      open={isOpen}
      footer={null}
      okText={'Done'}
      width={'100%'}
      data-testid="offer-information-modal"
      className={classnames(styles.showingInformationModal)}
      centered
      closeIcon={false}
      destroyOnClose
      closable={false}
      onCancel={onClose}
      title={
        <div className={styles.infoTitle}>
          <div className={styles.addressInfo}>{getFormatterAddress()}</div>
          <Icons variant={'close'} onClick={onClose} />
        </div>
      }
    >
      <Fragment>
        <div className={classNames(styles.agentDetails)}>
          <h4>Agent</h4>
          <AgentDetails SellerAgent={SellerAgent} AgentBrokerage={AgentBrokerage} />
        </div>

        {BookingPageNotes || BookingPageMessages?.length ? (
          <div className={styles.submissionNotes}>
            <h4>Notes from Listing Agent</h4>
            <div className={styles.notes}>
              {BookingPageMessages?.map((b, index) => (
                <Notes
                  key={index}
                  BookingPageNotes={b?.Message}
                  CreatedDate={b?.CreatedDate}
                  SellerAgent={SellerAgent}
                />
              ))}

              {DisplayBookingPageNotes && (
                <Notes
                  BookingPageNotes={BookingPageNotes}
                  CreatedDate={CreatedDate}
                  SellerAgent={SellerAgent}
                />
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
      </Fragment>
    </Modal>
  );
};
