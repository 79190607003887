import React, { useState, useEffect } from 'react';
import { ProfileIcons } from 'components/ClientsDrawer/ProfileClient/Icons/ProfileIcons';
import { Plus, RemoveFile } from 'components/Icons';
import { ClientAddress, ClientAddressEntity, ClientAddressUnion } from 'app-constants';
import { useDispatch } from 'react-redux';
import { updateClientDataEffect } from 'store/effects';
import { showSuccessMessage } from 'helpers';

import styles from './addressListingStyles.module.scss';
import { normalizeAddress } from 'components/ClientsDrawer/ProfileClient/Contact/components/Address/helpers';

interface Props {
  isPopoverOpen: boolean;
  clientId: string;
  addresses: ClientAddressUnion[];
  handleAddressClick: (address: ClientAddressUnion) => void;
  handleAddAddressClick: () => void;
}

export const AddressListing = ({
  addresses,
  handleAddressClick,
  clientId,
  handleAddAddressClick,
}: Props) => {
  const AddAddressElement = (
    <div className={styles.addressWrapper} onClick={handleAddAddressClick}>
      <Plus className={styles.iconStyles} />
      <p className={styles.addressTextStyle}>Add Address</p>
    </div>
  );

  const getAddressElement = (address, isPrimary, index) => {
    const { line1, state, city } = normalizeAddress(address);

    return (
      <div className={styles.addressWrapper} onClick={() => handleAddressClick(address)}>
        <ProfileIcons name={ProfileIcons.ADDRESS} color="#aaabab" />
        <div className={styles.addressSection}>
          <p className={styles.addressTextStyle}>
            {line1}, {city}, {state}
          </p>
          <p className={styles.addressLabel}>
            {isPrimary ? 'Primary Address' : `Address ${index}`}
          </p>
        </div>
      </div>
    );
  };
  const getAddressArray = () => {
    const addressElements: React.ReactNode[] = [];
    const addressesCopy = [...addresses];
    const primaryAddressIndex = addresses.findIndex((clientAddress) => clientAddress.isPrimary);
    const primaryAddress = addressesCopy.splice(primaryAddressIndex, 1);
    addressElements.unshift(getAddressElement(primaryAddress[0], true, 0));
    addressesCopy?.forEach((address, index) => {
      addressElements.push(getAddressElement(address, false, index + 1));
    });
    return addressElements;
  };

  // const handleAddressClick = (selectedAddress: ClientAddress) => {
  //   const addressIndex = addresses.indexOf(selectedAddress);
  //   const addressesCopy = addresses.map((address) => ({ ...address, isPrimary: false }));
  //   const updatedAddress = { ...addressesCopy[addressIndex], isPrimary: true };
  //   addressesCopy.splice(addressIndex, 1, updatedAddress);
  //   setAddresses(addressesCopy);
  //   submitAddressChange(addressesCopy, false);
  // };

  return (
    <div className={styles.addressListingWrapper}>
      <p className={styles.headingWrapper}>Client Addresses</p>
      {getAddressArray()}
      {AddAddressElement}
    </div>
  );
};
