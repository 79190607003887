import { DateTimeColumn, DocumentNameColumn, AvatarColumn } from './TableColumns';
import { Options } from '../Options';
import { AgentDocumentTypes } from 'types';

const docType = AgentDocumentTypes.MessageAttachments;

export const messageAttachmentsColumns = (optionUtils) => [
  {
    key: 'title',
    title: 'Name',
    dataIndex: 'title',
    width: 475,
    render: (title) => <DocumentNameColumn name={title} docIconType="Ungrouped" />,
  },
  {
    key: 'uploadedBy',
    title: 'Shared By',
    width: 350,
    render: (row) => <AvatarColumn name={row?.messageAttachmentMeta?.uploadedBy} imgSrc={null} />,
  },
  {
    key: 'uploaded',
    title: 'Date',
    render: (row) => <DateTimeColumn date={row?.messageAttachmentMeta?.uploadedAt} hideTime />,
  },
  {
    key: 'options',
    title: '',
    render: (row) => <Options document_={row} type={docType} optionUtils={optionUtils} />,
  },
];
