import classNames from 'classnames';
import { MenuProps, Tooltip } from 'components-antd';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ConfirmationWithReasonModal, Menu, Popover } from 'components-antd';
import { Icons } from 'pages/Workshop/Offers/icons';
import { updateOfferIterationEffect } from 'store/effects/offers';
import { showSuccessMessage } from 'helpers/success';
import { showErrorMessage } from 'helpers';
import { getofferSelector } from 'store/selectors/offers';
import { SendCounterOfferModal } from 'pages/Workshop/Transactions/TransactionOffers/SendCounterOfferModal';
import { getUserEmail, getUserId } from 'store/selectors/user';
import { ArchivedOfferStatus, OfferStatus } from 'types';
import { getTransactionSelector } from 'store/selectors/transaction';
import { transactionRoles } from 'settings/constants/roles';
import { shareOfferIteration } from 'api/offers';
import { getUserRolesSelector } from 'store/selectors/user';
import { CLIENT } from 'settings/constants/roles';

import styles from './styles.module.scss';
import { getOfferAggregateByUserIdEffect } from 'store/effects/offerAggregate';

interface DetailsProps {
  data: {
    CreatedDate: Date;
    UUID: string;
  };
  offerData: any;
  onClose: any;
  setOfferCompareModalIsOpen: any;
  columnName: any;
  transactionId: any;
  fullAccess: boolean;
  setReloadOffer?: Function;
  readOnly: boolean;
}

interface MenuItem {
  label: any;
  key: string;
  disabled?: boolean;
}

export const Actions = ({
  data,
  offerData,
  onClose,
  columnName,
  setOfferCompareModalIsOpen,
  transactionId,
  fullAccess,
  setReloadOffer,
  readOnly,
}: DetailsProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const userEmail = useSelector(getUserEmail);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showRejectWarning, setShowRejectWarning] = useState(false);
  const [showOfferShareModal, setShowOfferShareModal] = useState(false);
  const dispatch = useDispatch();

  const [Reason, setConfirmationReason] = useState();
  const [counterOfferModal, setCounterOfferModal] = useState(false);
  const disabledColumnNames = [OfferStatus.Cancelled, OfferStatus.Rejected, OfferStatus.Withdrawn];
  const offerList = useSelector(getofferSelector);
  const { transaction } = useSelector(getTransactionSelector);
  const [sellerName, setSellerName] = useState('');
  const loggedinUserRoles = useSelector(getUserRolesSelector);
  const [actionItems, setActionItems] = useState<MenuItem[]>([]);
  const userId = useSelector(getUserId);
  const [loading, setLoading] = useState(false);

  const onCounterClose = () => {
    setCounterOfferModal(false);
  };

  useEffect(() => {
    transaction?.Participants?.forEach((participant) => {
      if (participant.Role.Title === transactionRoles.SELLER) {
        setSellerName(`${participant.FirstName} ${participant.LastName}`);
      }
    });

    if (loggedinUserRoles?.includes(CLIENT) || !fullAccess) {
      setActionItems([
        {
          label: (
            <div className={styles.menuIcon}>
              <Icons variant={'compare'} /> <span>Compare</span>
            </div>
          ),
          key: '1',
        },
      ]);
    } else {
      setActionItems([
        {
          label: (
            <div className={styles.menuIcon}>
              <Icons variant={'compare'} /> <span>Compare</span>
            </div>
          ),
          key: '1',
        },
        {
          label: (
            <div className={styles.menuIcon}>
              <Icons variant={'share'} /> <span>Share</span>
            </div>
          ),
          key: '2',
        },
        ...(disabledColumnNames.includes(columnName)
          ? []
          : [
              ...(offerData?.PerformedBy?.Email !== userEmail
                ? [
                    {
                      label: (
                        <div className={styles.menuIcon}>
                          <Icons variant={'accept'} /> <span>Accept </span>
                        </div>
                      ),
                      key: '3',
                    },
                    {
                      label: (
                        <div className={styles.menuIcon}>
                          <Icons variant={'counter'} /> <span>Counter </span>
                        </div>
                      ),
                      key: '4',
                    },
                  ]
                : []),
              {
                label: (
                  <div className={styles.menuIcon}>
                    <Icons variant={'reject'} /> <span>Reject</span>
                  </div>
                ),
                key: '5',
              },
            ]),
      ]);
    }
  }, [transaction]);

  const handlePreFormUpdate = (offerId, offerIterationStatus) => {
    dispatch(
      updateOfferIterationEffect(
        {
          OfferIterationId: offerId,
          OfferStatus: offerIterationStatus,
          Reason: Reason,
          TransactionId: offerList?.TransactionId,
        },
        {},
        (err, res) => {
          if (!err) {
            const toaster = `Offer updated successfully!`;
            showSuccessMessage(toaster);
            if (setReloadOffer) setReloadOffer();
          }
        },
      ),
    );
  };

  const shareOfferWithClient = async (offerId) => {
    setLoading(true);
    try {
      await shareOfferIteration({
        OfferIterationId: offerId,
        TransactionId: offerList?.TransactionId,
      });
      const toaster = `Offer shared successfully!`;
      showSuccessMessage(toaster);
      dispatch(
        getOfferAggregateByUserIdEffect(
          {
            id: userId,
            filters: {
              transactionIds: [transactionId],
            },
          },
          { silent: true },
        ),
      );
      setShowOfferShareModal(false);
      setLoading(false);
      onClose();
    } catch (error) {
      showErrorMessage(error);
      setLoading(false);
    }
  };

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === '1') {
      setOfferCompareModalIsOpen(true);
    } else if (e.key === '2') {
      setShowOfferShareModal(true);
    } else if (e.key === '3') {
      setShowAcceptModal(true);
    } else if (e.key === '5') {
      setShowRejectWarning(true);
    } else if (e.key === '4') {
      setCounterOfferModal(true);
    }
    setIsOpen(false);
  };
  const menu = <Menu onClick={handleMenuClick} items={actionItems} />;

  const actionButton = (
    <div className={classNames(styles.popmenu, { [styles.disabled]: readOnly })}>
      <p className={styles.popmenuButton}>
        <span>Actions</span>
        <div className={classNames(styles.menuIcon, { [styles.menuOpen]: isOpen })}>
          <Icons variant={'arrowdown'} className={styles.actionBtn} />
        </div>
      </p>
    </div>
  );
  return (
    <>
      <div className={styles.wrapper}>
        <SendCounterOfferModal
          isOpen={counterOfferModal}
          onClose={onCounterClose}
          offerIterationData={data}
          OfferCount={offerData?.OfferCount}
          isExternal={false}
          uuid={offerData?.UUID}
        />

        <ConfirmationWithReasonModal
          open={showAcceptModal}
          confirmText={<span>Are you sure you want to accept offer?</span>}
          placeholder={`Add a note......`}
          okText={'Accept'}
          note="Note"
          showReason={true}
          setReason={setConfirmationReason}
          onOk={() => {
            handlePreFormUpdate(data?.UUID, 'Accepted');
          }}
          onCancel={() => setShowAcceptModal(false)}
        />
        <ConfirmationWithReasonModal
          open={showRejectWarning}
          confirmText={<span>Are you sure you want to reject offer?</span>}
          placeholder={`Add a note......`}
          okText={'Reject'}
          note="Note"
          showReason={true}
          setReason={setConfirmationReason}
          onOk={() => {
            handlePreFormUpdate(data?.UUID, 'Rejected');
          }}
          onCancel={() => setShowRejectWarning(false)}
        />
        <ConfirmationWithReasonModal
          open={showOfferShareModal}
          confirmText={
            <span>
              {sellerName ? (
                `Are you sure you want to share this offer with ${sellerName}?`
              ) : (
                <>
                  <span>No Sellers Available!</span>
                  <br />
                  Please invite any to share this offer.
                </>
              )}
            </span>
          }
          showReason={false}
          onOk={() => {
            shareOfferWithClient(data?.UUID);
          }}
          okButtonProps={{ disabled: !sellerName }}
          okText="Share"
          onCancel={() => setShowOfferShareModal(false)}
          confirmLoading={loading}
        />
        {!showRejectWarning &&
          !showAcceptModal &&
          !counterOfferModal &&
          !Object.values(ArchivedOfferStatus).includes(offerData.IterationStatus) && (
            <Popover
              content={menu}
              trigger={'click'}
              open={isOpen}
              placement={'bottom'}
              onOpenChange={(newOpen: boolean) => {
                if (!readOnly) setIsOpen(newOpen);
              }}
              overlayClassName={styles.actionPopover}
              getPopupContainer={(trigger) => {
                return trigger;
              }}
            >
              {readOnly ? (
                <Tooltip
                  title="You are not authorized to perform any action."
                  getPopupContainer={(trigger) => trigger}
                  color="black"
                >
                  {actionButton}
                </Tooltip>
              ) : (
                actionButton
              )}
            </Popover>
          )}
      </div>
    </>
  );
};
