import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Input, Locations, Phone, TextareaAutosize } from 'components';
import { Add } from 'components/Icons';

import { createAddress } from 'helpers/utilsTS';

import styles from './styles.module.scss';

const Partner = (props) => {
  const { className, formik, isPending } = props;
  const [isAdditionalName, setIsAdditionalName] = useState(
    !!formik?.values?.additionalBusinessName,
  );

  const onAdd = () => {
    setIsAdditionalName(true);
  };

  const onAddressChange = useCallback(
    (result) => {
      const location = result?.result?.[0];
      const coordinates = result?.coordinates;

      const { data, success } = createAddress(location, result);

      if (success && data) {
        const { city, state, zip, line1, providerPlaceId, placeName } = data;
        const geolocation = coordinates
          ? {
              Lat: coordinates.lat,
              Long: coordinates.lng,
            }
          : null;

        formik.setFieldValue('address', {
          City: city,
          State: state,
          Zip: zip,
          Line1: line1,
          ProviderPlaceId: providerPlaceId,
          PlaceName: placeName,
          Geolocation: geolocation,
        });
      } else {
        formik.setFieldValue('address', null);
      }
    },
    [formik],
  );

  return (
    <div className={classNames(styles.partner, className)}>
      <Input
        name="businessName"
        value={formik.values.businessName}
        onChange={formik.handleChange}
        placeholder="Company Name"
        error={formik.touched.businessName ? formik.errors.businessName : ''}
        disabled={isPending}
        testid="company_name"
        variant={Input.LIGHT}
        className={styles.input}
        replacePlaceholderWithLabelWhenHasValue
      />
      {isAdditionalName && (
        <Input
          name="additionalBusinessName"
          value={formik.values.additionalBusinessName}
          onChange={formik.handleChange}
          placeholder="Additional Name (Optional)"
          label="Additional Name"
          error={formik.touched.additionalBusinessName ? formik.errors.additionalBusinessName : ''}
          disabled={isPending}
          className={styles.input}
          testid="additional_name"
          variant={Input.LIGHT}
          replacePlaceholderWithLabelWhenHasValue
        />
      )}
      {!isAdditionalName && (
        <div onClick={onAdd} className={styles.addName}>
          <Add testid="add_icon" className={styles.addIcon} />
          <span>Add additional name</span>
        </div>
      )}
      <Input
        name="partnerEmail"
        value={formik.values.partnerEmail}
        onChange={formik.handleChange}
        placeholder="Email Address"
        error={formik.touched.partnerEmail ? formik.errors.partnerEmail : ''}
        disabled={isPending}
        testid="email"
        variant={Input.LIGHT}
        className={styles.input}
        replacePlaceholderWithLabelWhenHasValue
      />
      <Phone
        name="partnerPhone"
        placeholder="Phone number"
        onChange={(event, value) => {
          formik.setFieldValue('partnerPhone', value);
        }}
        error={formik.touched.partnerPhone ? formik.errors.partnerPhone : ''}
        disabled={isPending}
        testid="phone"
        value={formik.values.partnerPhone}
        variant={Phone.LIGHT}
        className={styles.input}
        replacePlaceholderWithLabelWhenHasValue
      />
      <Locations
        multiple={false}
        name="address"
        types={['address']}
        onResult={onAddressChange}
        placeholder="Office Address"
        error={formik.touched.address ? formik.errors.address : ''}
        disabled={isPending}
        value={
          formik.values.address
            ? [
                {
                  ...formik.values.address,
                  ProviderPlaceId: 1,
                  PlaceName: formik?.values?.address?.Line1,
                },
              ]
            : []
        }
        testid="address"
        variant={Locations.LIGHT}
        className={styles.input}
        searchIcon={false}
        valuesWrapperClassName={styles.locations}
        replacePlaceholderWithLabelWhenHasValue
      />
      <Input
        name="suiteUnit"
        value={formik.values.suiteUnit}
        onChange={formik.handleChange}
        placeholder="Suite / Unit"
        error={formik.touched.suiteUnit ? formik.errors.suiteUnit : ''}
        disabled={isPending}
        testid="suite_unit"
        variant={Input.LIGHT}
        className={styles.input}
        replacePlaceholderWithLabelWhenHasValue
      />
      <Input
        name="description"
        value={formik.values.description}
        placeholder="Description"
        onChange={formik.handleChange}
        error={formik.touched.description ? formik.errors.description : ''}
        disabled={isPending}
        testid="description"
        variant={TextareaAutosize.LIGHT}
        className={styles.input}
        replacePlaceholderWithLabelWhenHasValue
      />
      <Input
        name="website"
        value={formik.values.website}
        onChange={formik.handleChange}
        placeholder="Website"
        error={formik.touched.website ? formik.errors.website : ''}
        disabled={isPending}
        testid="website"
        variant={Input.LIGHT}
        className={styles.input}
        replacePlaceholderWithLabelWhenHasValue
      />
    </div>
  );
};

Partner.propTypes = {
  className: PropTypes.string,
  formik: PropTypes.shape({
    handleChange: PropTypes.func,
    setFieldValue: PropTypes.func,
    values: PropTypes.shape({
      businessName: PropTypes.string,
      additionalBusinessName: PropTypes.string,
      partnerEmail: PropTypes.string,
      partnerPhone: PropTypes.string,
      address: PropTypes.shape({
        Line1: PropTypes.string,
      }),
      suiteUnit: PropTypes.string,
      description: PropTypes.string,
      website: PropTypes.string,
    }),
    errors: PropTypes.shape({
      businessName: PropTypes.string,
      additionalBusinessName: PropTypes.string,
      partnerEmail: PropTypes.string,
      partnerPhone: PropTypes.string,
      address: PropTypes.string,
      suiteUnit: PropTypes.string,
      description: PropTypes.string,
      website: PropTypes.string,
    }),
    touched: PropTypes.shape({
      businessName: PropTypes.bool,
      additionalBusinessName: PropTypes.bool,
      partnerEmail: PropTypes.bool,
      partnerPhone: PropTypes.bool,
      address: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
      suiteUnit: PropTypes.bool,
      description: PropTypes.bool,
      website: PropTypes.bool,
    }),
  }).isRequired,
  isPending: PropTypes.bool,
};

Partner.defaultProps = {
  className: '',
  isPending: false,
};

export default Partner;
