import { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { Menu, MenuItem, Dropdown, Radio } from 'components-antd';
import { ArrowDown, Check } from 'components/Icons';
import { offerFilters } from 'settings/constants/offer';
import { setOffersFiltersEffect } from 'store/effects/offerAggregate';
import styles from './styles.module.scss';

import { getOfferFiltersSelector } from 'store/selectors/offerAggregate';
import { TEAM_OWNER, TEAM_ADMIN } from 'settings/constants/roles';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { LocationService } from 'services';
import { useLocation } from 'react-router-dom';

export const OffersFilter = (props) => {
  const { offersFilter } = useSelector(getOfferFiltersSelector);

  const { categorizedFilter = {} } = offersFilter;

  const [value, setValue] = useState(offerFilters.allOffers || categorizedFilter);
  const dispatch = useDispatch();

  const [isFiltersApplied, setIsFilterApplied] = useState(
    false || !!Object.keys(categorizedFilter).length,
  );

  const agentRole = useSelector(getAgentTeamRoleSelector);
  const locationSrv = new LocationService();
  const location = useLocation();
  locationSrv.setLocation(location);
  const query = locationSrv.getQuery();

  useEffect(() => {
    // Handle dashboard redirect
    let filterStatus = offerFilters.allOffers;
    if ('teamStats' in query) {
      filterStatus = query.teamStats ? offerFilters.allOffers : offerFilters.myOffers;
      setIsFilterApplied(!query.teamStats);
    }

    setValue(filterStatus);
  }, []);

  const onChange = (e) => {
    const val = e.target.value;
    const filters = {
      [offerFilters.myOffers]: { isMyOffers: 'true' },
      [offerFilters.teamOffers]: { isTeamOffers: 'true' },
      [offerFilters.allOffers]: {},
    };

    setIsFilterApplied(val !== offerFilters.allOffers);
    dispatch(setOffersFiltersEffect({ ...offersFilter, categorizedFilter: filters[val] }));
    setValue(val);
  };

  // Hide Team Offers option for Team members.
  const teamAdminAndOwnerOption = [TEAM_OWNER, TEAM_ADMIN].includes(agentRole)
    ? [offerFilters.teamOffers]
    : [];

  const dropdownFields = [
    offerFilters.myOffers,
    ...teamAdminAndOwnerOption,
    offerFilters.allOffers,
  ];

  const menu = (
    <Radio.Group
      onChange={onChange}
      value={value}
      className={styles.optionsItems}
      defaultValue={value}
    >
      <Menu>
        {dropdownFields.map((item, idx) => (
          <MenuItem key={idx}>
            <Radio value={item} key={idx}>
              <div className={styles.option}>
                <span className={styles.optionValue}>{item}</span>
              </div>
              {value === item && <Check className={styles.checkIcon} />}
            </Radio>
          </MenuItem>
        ))}
      </Menu>
    </Radio.Group>
  );

  return (
    <div
      className={classNames(
        styles.filterWrap,
        {
          [styles.filtersAppliedWrapper]: isFiltersApplied,
        },
        props.className,
      )}
    >
      <Dropdown
        overlay={menu}
        placement="bottomLeft"
        getPopupContainer={(triggerNode) => triggerNode}
        trigger={['click']}
        overlayClassName={styles.offerFilterDropdown}
      >
        <a
          onClick={(e) => e.preventDefault()}
          className={classNames(styles.filterButton, 'mosaikDropdownButton')}
        >
          <span className={styles.selectedValue}>{value}</span>
          <ArrowDown className={classNames(styles.icon, { [styles.open]: open })} />
        </a>
      </Dropdown>
    </div>
  );
};
