import { FC } from 'react';

import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { IntelAgent } from './IntelAgent';
import { IntelClient } from './IntelClient';
import { AGENT, CLIENT } from 'settings/constants/roles';
import { getUserDataSelector } from 'store/selectors/user';

export const Intel: FC = () => {
  const user = useSelector(getUserDataSelector);

  const renderRoleBasedIntel = () => {
    const roleType = user?.Roles;
    if (roleType.includes(AGENT)) {
      return <IntelAgent />;
    } else if (roleType.includes(CLIENT)) {
      return <IntelClient />;
    } else {
      return <></>;
    }
  };

  return <div className={styles.pageWrapper}>{renderRoleBasedIntel()}</div>;
};
