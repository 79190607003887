import { useState, useRef, FC, ReactElement, MouseEvent } from 'react';
import classNames from 'classnames';
import { useOutsideClick } from 'hooks';

import { Radio } from 'components-antd';
import { ArrowDown, Check } from 'components/Icons';

import styles from './styles.module.scss';
import { RadioChangeEvent } from 'antd';
import { FeedVisibility } from '../../../../../types/intel';

export interface FilterProps {
  onChange: (event: RadioChangeEvent) => void;
  activeValue: string;
}

export const Filter: FC<FilterProps> = (props: FilterProps): ReactElement => {
  const { onChange, activeValue } = props;
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<HTMLInputElement>(null);
  const optionsRef = useRef<HTMLInputElement>(null);

  useOutsideClick([buttonRef, optionsRef], () => {
    setOpen(false);
  });

  const onClickOptions = (event: MouseEvent): void => {
    event.stopPropagation();
    setOpen(!open);
  };

  const onOptionChange = (event: RadioChangeEvent): void => {
    onChange(event);
    setOpen(false);
  };

  const dropdownFields = [
    { itemValue: FeedVisibility.User, iconColor: '#04A451' },
    { itemValue: FeedVisibility.Team, iconColor: '#04A451' },
    { itemValue: FeedVisibility.All, iconColor: '#04A451' },
  ];

  return (
    <div className={styles.filterWrap}>
      <span className={styles.filterButton} ref={buttonRef} onClick={onClickOptions}>
        {activeValue} <ArrowDown className={classNames(styles.icon, { [styles.open]: open })} />
      </span>
      {open && (
        <div ref={optionsRef} className={styles.options}>
          <Radio.Group
            onChange={onOptionChange}
            value={activeValue}
            className={styles.optionsItems}
            defaultValue={FeedVisibility.User}
          >
            {dropdownFields.map((item, idx) => {
              return (
                <Radio value={item.itemValue} key={idx}>
                  {item.itemValue} <Check className={styles.icon} color={item.iconColor} />
                </Radio>
              );
            })}
          </Radio.Group>
        </div>
      )}
    </div>
  );
};
