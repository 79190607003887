import { ALL_FORM_TEMPLATE_WIZARD_SCREENS, FormTemplateWizardScreens } from 'app-constants';
import { useEffect, useMemo, useState } from 'react';

import styles from './styles.module.scss';
import { Progress, Text } from 'components-antd';
import { NavigationRow, TemplateDetails, TemplateSuccess } from './components';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormTemplateWizardParamType, FormTemplateWizardType } from 'types';
import { routes } from 'settings/navigation/routes';
import { Spinner } from 'components';
import {
  getRequestFormProcessSelector,
  getSmartFormDataSelector,
} from 'store/selectors/requestFormProcess';

import {
  getSmartFormBuilderEffect,
  updateBundleTemplateEffect,
  updateFormTemplateEffect,
} from 'store/effects/formBuilder';
import { link } from 'settings/navigation/link';
import {
  createBundleFormTemplateEffect,
  getBundleTemplateEffect,
  getDynamicTemplateDocumentEffect,
  getFormMetaEffect,
  updateFormRoleEffect,
} from 'store/effects/formProcess';
import { getTemplateForm } from 'store/selectors/formBuilder';
import { TemplateSignForm } from './components/TemplateSignForm';
import { prepareSignFormDetails } from './helper';

export const FormTemplateWizard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams<FormTemplateWizardParamType>();
  const data = useSelector(getTemplateForm);

  const { state: formState } = useLocation<FormTemplateWizardType>();
  const state = useMemo(() => formState, []);
  const isBundleEdit = state?.templateBundle && state?.editMode;

  const [allScreens, setAllScreens] = useState([...ALL_FORM_TEMPLATE_WIZARD_SCREENS]);

  const [index, setIndex] = useState(state?.templateSuccess ? 2 : state?.editMode ? 0 : 1);
  const [wizardDetails, setWizardDetails] = useState<any>({ categories: [] });
  const [loading, setLoading] = useState(false);

  const { isPending, isError } = useSelector(getSmartFormDataSelector);

  const { preForm } = useSelector(getRequestFormProcessSelector);
  const { isProject } = preForm;

  useEffect(() => {
    if (!state?.templateSuccess) {
      if (params?.templateId) {
        dispatch(
          getDynamicTemplateDocumentEffect(
            { templateId: params?.templateId },
            (error, response) => {
              if (!error && state?.editMode) {
                let data = response?.data?.value;

                const { FormVersion, FormVersionId } = data.formTemplate;
                const categories = FormVersion.FormCategoryTag.map((cat) => cat.Id);

                setWizardDetails({ categories });

                dispatch(getSmartFormBuilderEffect({ versionId: FormVersionId }));
              }
            },
          ),
        );
      } else if (isBundleEdit) {
        dispatch(
          getBundleTemplateEffect(state?.bundleId, (error, response) => {
            if (!error) {
              const { FormBundleTemplate } = response.data.value.response;

              const { Name, PublishToTeam } = FormBundleTemplate[0].FormBundleTemplate;

              const screens = allScreens.filter(
                (screen) => screen !== FormTemplateWizardScreens.SignForm,
              );

              setWizardDetails({ templateName: Name, publishToTeam: PublishToTeam });

              setAllScreens(screens);
            }
          }),
        );
      }
    }
  }, []);

  useEffect(() => {
    if (!state?.editMode && state?.formVersionId) {
      dispatch(getSmartFormBuilderEffect({ versionId: state?.formVersionId }));
    }

    dispatch(getFormMetaEffect({ isProject }));
  }, []);

  const modifyRolesAndSignatories = (savedFormRoles) => {
    const { formRoles } = prepareSignFormDetails(savedFormRoles);

    dispatch(updateFormRoleEffect({ formRoles }));
  };

  const handleNextQuestion = (details) => {
    setWizardDetails(details);

    modifyRolesAndSignatories(data?.dynamicForm?.meta?.formRoles);

    setIndex(index + 1);
  };

  const handlePreviousQuestion = () => {
    if (allScreens[index - 1] !== undefined) {
      setIndex(index - 1);
    }
  };

  const handleExit = () => {
    history.push(routes.workshopForms);
  };

  const navigateToBundle = (details, editMode = false) => {
    const { templates, Name, PublishToTeam, Id } = details;

    const navigationState = {
      templateBundleId: Id,
      bundleName: Name,
      publishToTeam: PublishToTeam,
      allTemplates: templates,
      templateBundle: true,
      editMode,
      templateForm: true,
      ...(editMode && { updateTemplate: true }),
    };

    setLoading(false);

    history.push({
      pathname: link.toWorkshopDynamicTemplateBundle,
      state: {
        ...navigationState,
      },
    });
  };

  const createBundleTemplate = ({ templateRoleIds }) => {
    const roleIds = templateRoleIds.map(({ Id }) => Id);

    const templateBundleData = {
      documentLinks: state?.documentLinks,
      categories: state?.categories,
      publishToTeam: state?.publishToTeam,
      bundleName: state?.templateName?.trim(),
      roleIds,
    };

    dispatch(
      createBundleFormTemplateEffect(templateBundleData, (err, resp) => {
        if (!err) {
          const { response } = resp.data.value;

          navigateToBundle(response);
        } else {
          setLoading(false);
        }
      }),
    );
  };

  const updateBundleTemplate = (details) => {
    setWizardDetails(details);

    const requestPayload = {
      name: details.templateName,
      publishToTeam: details.publishToTeam,
      templateBundleId: state?.bundleId,
    };

    setLoading(true);
    dispatch(
      updateBundleTemplateEffect(requestPayload, (error, response) => {
        if (!error) {
          setLoading(false);

          const value = response.data.value;
          navigateToBundle(value, true);
        }
      }),
    );
  };

  const updateTemplate = (details) => {
    const { categories, ...restDetails } = wizardDetails;
    dispatch(
      updateFormTemplateEffect(
        {
          templateId: state?.templateId || params?.templateId,
          ...details,
          ...restDetails,
          formCategoryTag: categories,
        },
        (err, res) => {
          if (!err) {
            setLoading(false);
            const { TemplateId } = res.data.value.formTemplate;
            history.push({
              pathname: link.toWorkshopDynamicTemplateForm({
                templateId: TemplateId,
              }),
              state: {
                editMode: state?.editMode,
                templateForm: true,
                ...(state?.editMode && { updateTemplate: true }),
              },
            });
          }
        },
      ),
    );
  };

  const handleContinue = (details?: any) => {
    if (state?.templateBundle && state?.editMode) {
      updateBundleTemplate(details);
    } else if (state?.documentLinks) {
      setLoading(true);
      createBundleTemplate(details);
    } else {
      setLoading(true);
      updateTemplate(details);
    }
  };

  const getActiveQuestion = () => {
    switch (allScreens[index]) {
      case FormTemplateWizardScreens.TemplateDetails:
        return (
          <TemplateDetails
            templateFields={{
              templateName: data?.dynamicForm?.meta?.documentName,
              publishToTeam: data?.dynamicForm?.meta?.publishToTeam,
              formVersionId: data?.dynamicForm?.meta?.formVersionId,
              formId: data?.dynamicForm?.meta?.formId,
              ...wizardDetails,
            }}
            onContinue={isBundleEdit ? handleContinue : handleNextQuestion}
            isBundle={isBundleEdit}
            loading={isBundleEdit && loading}
          />
        );

      case FormTemplateWizardScreens.SignForm:
        return (
          <TemplateSignForm
            customForm={true}
            loading={loading}
            editMode={state?.editMode}
            onContinue={handleContinue}
            templateState={{
              ...(state && state),
              ...(state?.editMode && {
                formVersionId: data?.dynamicForm?.meta?.formVersionId,
                formRoleIds: data?.dynamicForm?.meta?.formRolesIds,
              }),
            }}
            editSignatoryMode={state?.editMode}
          />
        );

      case FormTemplateWizardScreens.Success:
        return (
          <TemplateSuccess
            isBundle={state?.templateBundle}
            editMode={state?.editMode}
            onContinue={handleExit}
          />
        );

      default:
        return <></>;
    }
  };

  const getContainerContent = () => {
    if (isPending) {
      return <Spinner />;
    } else if (isError) {
      return (
        <Text className={styles.errorMessage}>
          There was an issue with loading, please try again.
        </Text>
      );
    } else {
      return <div className={styles.listContainer}>{getActiveQuestion()}</div>;
    }
  };

  return (
    <div className={styles.formTemplateWizardContainer}>
      <Progress percent={((index + 1) / allScreens.length) * 100} className={styles.progressBar} />
      <NavigationRow
        onPrevious={handlePreviousQuestion}
        onCancel={handleExit}
        hasPrevious={state?.editMode && index > 0 && !state?.templateSuccess}
        pdfName={state?.templateName}
      />
      {getContainerContent()}
    </div>
  );
};
