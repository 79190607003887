import { FC, useState } from 'react';
import styles from './styles.module.scss';
import { AntCard } from 'components-antd';
import { DropdownCheck, Wrapper } from 'components';
import { Icons } from '../../Icons';
import { NeighborhoodViewTypes } from 'pages/Radar/types';
import { map } from 'lodash-es';
import { Map } from 'components';

interface INeighborhoodActivityCard {
  isPending: boolean;
}

export const NeighborhoodActivityCard: FC<INeighborhoodActivityCard> = ({ isPending }) => {
  const [view, setView] = useState<NeighborhoodViewTypes>(NeighborhoodViewTypes.List);

  const tableData = [
    {
      image:
        'https://cdn.prod.website-files.com/62e8df21cffc5e12a4b7541b/65bb388c44675506902b8018_Arable%20Land.jpg',
      status: 'Back on the Market',
      price: '$670,000',
      addressLine1: '350 N Canal St',
      addressLine2: 'Chicago, IL 60654',
      time: '6h ago',
      distance: '0.9 mi',
    },
    {
      image:
        'https://cdn.prod.website-files.com/62e8df21cffc5e12a4b7541b/65bb388c44675506902b8018_Arable%20Land.jpg',
      status: 'Back on the Market',
      price: '$670,000',
      addressLine1: '350 N Canal St',
      addressLine2: 'Chicago, IL 60654',
      time: '6h ago',
      distance: '0.9 mi',
    },
    {
      image:
        'https://cdn.prod.website-files.com/62e8df21cffc5e12a4b7541b/65bb388c44675506902b8018_Arable%20Land.jpg',
      status: 'Back on the Market',
      price: '$670,000',
      addressLine1: '350 N Canal St',
      addressLine2: 'Chicago, IL 60654',
      time: '6h ago',
      distance: '0.9 mi',
    },
    {
      image:
        'https://cdn.prod.website-files.com/62e8df21cffc5e12a4b7541b/65bb388c44675506902b8018_Arable%20Land.jpg',
      status: 'Back on the Market',
      price: '$670,000',
      addressLine1: '350 N Canal St',
      addressLine2: 'Chicago, IL 60654',
      time: '6h ago',
      distance: '0.9 mi',
    },
  ];

  const toggleView = () => {
    setView((prev) =>
      prev === NeighborhoodViewTypes.List ? NeighborhoodViewTypes.Map : NeighborhoodViewTypes.List,
    );
  };

  const renderView = (view: NeighborhoodViewTypes) => {
    switch (view) {
      case NeighborhoodViewTypes.List:
        return (
          <div className={styles.table}>
            {map(
              tableData,
              ({ image, status, price, addressLine1, addressLine2, time, distance }) => (
                <div className={styles.tableItem}>
                  <div className={styles.row}>
                    <img src={image} />
                    <div className={styles.details}>
                      <div className={styles.statusContainer}>
                        <Icons variant={Icons.STATUS_ARROWS} />
                        <p className={styles.status}>{status}</p>
                      </div>
                      <p className={styles.price}>{price}</p>
                      <p className={styles.address}>{addressLine1}</p>
                      <p className={styles.address}>
                        <span>{addressLine2}</span>
                      </p>
                    </div>
                  </div>
                  <div className={styles.subDetails}>
                    <p className={styles.text}>{time}</p>
                    <div className={styles.distanceContainer}>
                      <Icons variant={Icons.MARKER} />
                      <p className={styles.text}>{distance}</p>
                    </div>
                  </div>
                </div>
              ),
            )}
          </div>
        );
      case NeighborhoodViewTypes.Map:
        return (
          <div className={styles.map}>
            <Map
              popupClassName={styles.popupClassName}
              // markersMap={markers}
              // bounds={bounds}
              center={{
                lng: -155.87594096,
                lat: 19.31424466,
              }}
              zoom={14}
              shouldFitBound={false}
            />
          </div>
        );

      default:
        break;
    }
  };

  return (
    <AntCard className={styles.card}>
      <Wrapper isPending={isPending}>
        <p className={styles.title}>Neighborhood Activity</p>
        <div className={styles.header}>
          <div className={styles.row}>
            <DropdownCheck
              className={styles.dropdown}
              dropdownFields={[
                { label: 'All', value: 'All' },
                { label: 'New Listing', value: 'NewListing' },
                { label: 'Status Change', value: 'StatusChange' },
                { label: 'Price Reduction', value: 'PriceReduction' },
                { label: 'Price Increase', value: 'PriceIncrease' },
                { label: 'Sold', value: 'Sold' },
              ]}
              placement="bottomLeft"
              defaultValue="All"
              onChange={() => {}}
              disableCheck={true}
            />
            <DropdownCheck
              className={styles.dropdown}
              dropdownFields={[{ label: 'This Month', value: 'ThisMonth' }]}
              placement="bottomLeft"
              defaultValue="ThisMonth"
              onChange={() => {}}
              disableCheck={true}
            />
          </div>
          <div className={styles.toggle} onClick={toggleView}>
            <Icons variant={view === NeighborhoodViewTypes.Map ? Icons.LIST : Icons.MAP} />
          </div>
        </div>
        {renderView(view)}
      </Wrapper>
    </AntCard>
  );
};
