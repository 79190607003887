import styles from './styles.module.scss';
import moment from 'moment';
import React, { FC, ReactElement, useMemo } from 'react';
import { ImageGrid } from '../ImageGrid';
import { UsersIcon } from '../Icons';

export type Activity = {
  id: string;
  user: {
    name: string;
    role: string;
    avatar: string;
  };
  createdAt: string;
  title: string;
  description: string;
  images: string[];
  publishedTo:
    | 'ALL'
    | {
        type: 'CLIENT' | 'AREA';
        data: string[];
      };
};

export interface ActivityCardProps {
  activity: Activity;
}

export const ActivityCard: FC<ActivityCardProps> = (props: ActivityCardProps): ReactElement => {
  const { activity } = props;

  const publishedToText = useMemo(() => {
    if (activity.publishedTo === 'ALL') {
      return 'Published to all clients';
    }

    if (activity.publishedTo.type === 'CLIENT') {
      const clients = activity.publishedTo.data.slice(0, 3).join(', ');
      return activity.publishedTo.data.length > 3
        ? `${clients} +${activity.publishedTo.data.length - 3}`
        : clients;
    }

    const areas = activity.publishedTo.data.slice(0, 3).join(', ');
    return activity.publishedTo.data.length > 3
      ? `Published to clients in: ${areas} +${activity.publishedTo.data.length - 3}`
      : `Published to clients in: ${areas}`;
  }, [activity.publishedTo]);

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <div className={styles.profile}>
          <img className={styles.profileAvatar} src={activity.user.avatar} alt="avatar" />
          <div className={styles.profileName}>{activity.user.name}</div>
          <div className={styles.profileRole}>{activity.user.role}</div>
        </div>
        <div className={styles.timeFromNow}>{moment(activity.createdAt).fromNow()}</div>
      </div>
      <div className={styles.info}>
        <div className={styles.title}>{activity.title}</div>
        <div className={styles.description}>{activity.description}</div>
      </div>

      {activity.images.length > 0 && <ImageGrid images={activity.images} />}

      <div className={styles.publishedTo}>
        <UsersIcon />
        <div className={styles.text}>{publishedToText}</div>
      </div>
    </div>
  );
};
