import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

type Props = {
  text?: string | React.ReactNode;
  isPending?: boolean;
  setIsSubmitted?: (a: boolean) => void;
  isDisabled?: boolean;
  onSubmitClick?: () => void;
  preButton?: React.ReactNode;
  showContinue?: boolean;
};

export const FormFooter: React.FC<Props> = ({
  text,
  isPending = false,
  setIsSubmitted,
  isDisabled = false,
  onSubmitClick,
  preButton,
  showContinue = true,
}) => {
  return (
    <>
      {text && <div className={styles.info}>{text}</div>}
      <div
        className={styles.btnContainer}
        onClick={() => {
          if (setIsSubmitted) {
            setIsSubmitted(true);
          }
          if (onSubmitClick) {
            onSubmitClick();
          }
        }}
      >
        {preButton}
        {showContinue && (
          <button
            type="submit"
            className={classNames(styles.btn, { [styles.disabled]: isPending || isDisabled })}
            disabled={isPending}
          >
            {isPending ? (
              <Spin
                className={styles.loader}
                indicator={
                  <LoadingOutlined
                    color="antiquewhite"
                    width={24}
                    height={24}
                    className={styles.spinner}
                    spin
                  />
                }
              />
            ) : (
              'Continue'
            )}
          </button>
        )}
      </div>
    </>
  );
};
