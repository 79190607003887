import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Modal } from 'components-antd';
import { Button, Locations } from 'components';

import styles from './styles.module.scss';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { map } from 'lodash-es';
import { Checkbox } from 'antd';
import classNames from 'classnames';
import { Add } from 'components/Icons';
import { Icons } from '../../../icons';
import { getClientDetailsEffect, updateClientDataEffect } from 'store/effects';
import { showSuccessMessage } from 'helpers';
import { schema } from './validation';

export const AddressModal = ({ isOpen, onClose, addresses, clientId }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const initialAddressValues = { address: { delivery_line_1: undefined }, isPrimary: false };

  const formik = useFormik({
    initialValues: {
      addresses: addresses?.length ? addresses : [initialAddressValues],
    },
    validationSchema: schema,
    onSubmit: async ({ addresses }) => {
      setLoading(true);
      const Client = { Address: addresses };
      dispatch(
        updateClientDataEffect({ clientId, Client }, (err) => {
          if (!err) {
            dispatch(getClientDetailsEffect({ id: clientId }, { silent: true }, () => {}));
            showSuccessMessage('Addresses updated successfully');
            setLoading(false);
            onClose();
          }
        }),
      );
    },
  });

  const onClearAddress = (index) => {
    formik.setFieldValue(`addresses[${index}].address`, {});
  };

  const disabled =
    !formik?.values?.addresses?.[formik.values.addresses.length - 1]?.address?.delivery_line_1;

  return (
    <Modal
      open={isOpen}
      width={521}
      footer={null}
      onCancel={() => {
        onClose();
      }}
      maskClosable={false}
      destroyOnClose
    >
      <form onSubmit={formik.handleSubmit}>
        <p className={styles.heading}>Primary Address</p>
        <div className={styles.content}>
          <FormikProvider value={formik}>
            <FieldArray
              name={'addresses'}
              render={(arrayHelpers) =>
                map(formik.values.addresses, (_, index: number) => (
                  <>
                    <div className={styles.form}>
                      {index !== 0 && (
                        <span
                          className={styles.remove}
                          onClick={() => {
                            arrayHelpers.remove(index);
                          }}
                        >
                          <Icons variant={Icons.REMOVE} />
                        </span>
                      )}
                      <Locations
                        name="address"
                        onResult={(r, preparedData) => {
                          r.deleteItemId
                            ? onClearAddress(index)
                            : formik.setFieldValue(`addresses[${index}].address`, preparedData[0]);
                        }}
                        label={'Address' + (index + 1 > 1 ? ` ${index + 1}` : '')}
                        placeholder="Enter Address"
                        value={
                          formik.values.addresses[index]?.address?.delivery_line_1
                            ? [formik.values.addresses[index]?.address]
                            : []
                        }
                        multiple={false}
                        showAsterisk={index === 0}
                        variant={Locations.ROUND}
                        placeholderClassName={styles.border}
                        searchWrapperClassName={styles.border}
                        valuesWrapperClassName={styles.border}
                        rounded={true}
                        shouldFilterCityLevelResults={true}
                        allowedSmartyAddress={true}
                        showOnlyCassAddresses={true}
                        setFullCassAddress={true}
                      />
                      <Checkbox
                        type="checkbox"
                        name="isPrimary"
                        className={classNames(styles.checkbox, 'mosaikCheckbox')}
                        value={formik.values?.addresses?.[index]?.isPrimary}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          const updatedAddresses = formik.values.addresses.map((address, i) => ({
                            ...address,
                            isPrimary: i === index ? isChecked : false,
                          }));
                          formik.setFieldValue('addresses', updatedAddresses);
                        }}
                        checked={formik.values?.addresses?.[index]?.isPrimary}
                      >
                        Primary Address
                      </Checkbox>
                    </div>
                    {formik.values.addresses.length - 1 === index && (
                      <div className={styles.footer}>
                        <div
                          className={classNames(
                            {
                              [styles.disabled]: disabled,
                            },
                            styles.addContainer,
                          )}
                          onClick={() => {
                            arrayHelpers.push(initialAddressValues);
                          }}
                        >
                          <Add
                            className={classNames(styles.addIcon, { [styles.disabled]: disabled })}
                            color={Add.COLLAPSE}
                          />
                          <p className={styles.addText} onClick={() => {}}>
                            Add Another
                          </p>
                        </div>
                        <Button
                          title={'Update'}
                          className={styles.submit}
                          type="submit"
                          isPending={loading}
                        />
                      </div>
                    )}
                  </>
                ))
              }
            />
          </FormikProvider>
        </div>
      </form>
    </Modal>
  );
};
