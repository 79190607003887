import { useSelector } from 'react-redux';

import { postClarityVolumeData } from 'api/clarity';
import { getAgentTeamIdSelector } from 'store/selectors/agentTeamDetail';
import {
  getAgentTeamStatsSelector,
  getTimePeriodSelector,
  getTransactionFilter,
} from 'store/selectors/clarity';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { TEAM_BASIC } from 'settings/constants/roles';
import { getUserId } from 'store/selectors/user';

const formatData = (data) =>
  data.map((agent) => {
    return { ...agent, Agent: agent.AgentUser || agent.AgentContact };
  });

export const useFetchVolumeStats = () => {
  const teamId = useSelector(getAgentTeamIdSelector);
  const teamStats = useSelector(getAgentTeamStatsSelector);
  const timePeriod = useSelector(getTimePeriodSelector);
  const transactionFilter = useSelector(getTransactionFilter);
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const userId = useSelector(getUserId);

  const fetchVolumeStats = async ({
    setSummary,
    setData,
    setIsPending,
    aggregateDataFormat,
    summaryType,
    year,
    quarter,
    month,
    isoWeek,
    date,
    expand = false,
    agentSummary = false,
    transactionAgentUserId = false,
    transactionAgentContactId = false,
    isTeam = false,
  }) => {
    setIsPending(true);
    const excludeTimePeriod = !!(year || quarter || month || isoWeek);
    const sendValues = {
      teamId: teamId,
      ...(!excludeTimePeriod && { timePeriod: timePeriod }),
      aggregateDataFormat,
      summaryType,
      ...(agentRole === TEAM_BASIC && { transactionAgentUserId: userId }),
      ...(teamStats.itemValue !== 'Team' && { transactionAgentUserId: teamStats.userId }),
      ...(expand && { expand: expand }),
      ...(year && { year: year }),
      ...(quarter && { quarter }),
      ...(month && { month }),
      ...(isoWeek && { isoWeek }),
      ...(date && { date }),
      ...(agentSummary && { agentSummary: agentSummary }),
      ...(transactionFilter && {
        representingRole: transactionFilter,
      }),
      ...(!isTeam && transactionAgentUserId && { transactionAgentUserId: transactionAgentUserId }),
      ...(!isTeam &&
        !transactionAgentUserId &&
        transactionAgentContactId && { transactionAgentContactId: transactionAgentContactId }),
    };
    const { data, status } = await postClarityVolumeData(sendValues);
    if (status === 200) {
      if (agentSummary) {
        setSummary(data.Summary || []);
      } else {
        setSummary(data.Summary || {});
        setData(formatData(data.Data) || []);
      }
    }
    setIsPending(false);
  };
  return fetchVolumeStats;
};
