import { useOutsideClick } from 'hooks';
import React, { useRef, useState } from 'react';

import styles from './styles.module.scss';
import { HamburgerIcon } from '../icons';
import { Popover } from 'components-antd';

export type DropdownMenuOptions = {
  label: string;
  icon?: React.ReactNode;
  id?: string;
  onSelect?: (id: string) => void;
};

type Props = {
  options: DropdownMenuOptions[];
  btnIcon?: React.ReactNode;
  id: string;
  currentValue?: string;
  showValue?: boolean;
  onSelect?: (id: string, frequenceName: string, prevFrequenceName: string) => void;
};

export const DropdownMenu: React.FC<Props> = ({
  options,
  btnIcon,
  id,
  currentValue,
  showValue = false,
  onSelect,
}) => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<string | null>(currentValue || null);

  useOutsideClick([buttonRef, optionsRef], () => setOpen(false));

  const onClickOptions = (e) => {
    e?.stopPropagation();
    setOpen(!open);
  };

  const onModalOpen = (e, type) => {};

  return (
    <div className={styles.dropdownWrapper}>
      {showValue && (
        <div className={styles.selectedLabel}>
          {options.find((option) => option?.id === selected)?.label}
        </div>
      )}
      <div className={styles.optionBtn} ref={buttonRef} onClick={onClickOptions}>
        {btnIcon ? btnIcon : <HamburgerIcon />}
      </div>

      <Popover
        open={open}
        overlayClassName={styles.assignedToOptionsPopover}
        content={
          <div ref={optionsRef} className={styles.options}>
            <ul className={styles.dropdown}>
              {options?.map((option: any, index: number) => (
                <li
                  key={`${option.label}-${index}-${id}`}
                  className={styles.item}
                  onClick={(e) => {
                    e.stopPropagation();
                    option.id &&
                      onSelect &&
                      onSelect(option.id, option.label, option.prevFrequenceName);
                    option.onSelect && option.onSelect(id);
                    setSelected(option.id || null);
                    setOpen(false);
                  }}
                >
                  {option?.icon ? <div className={styles.icon}>{option.icon}</div> : null}
                  <span>{option.label}</span>
                </li>
              ))}
            </ul>
          </div>
        }
      />
    </div>
  );
};
