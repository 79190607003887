export const getQuestionsByRole = (questions, formRoles, replacedRoles = []) => {
  const modifiedQuestions = {};

  formRoles.forEach(({ roleId }) => {
    Object.keys(questions).forEach((pageNumber) => {
      const replacedRoleItem: any =
        replacedRoles.length > 0 ? replacedRoles.find(({ Id }) => Id === roleId) : '';

      const savedRoleId = replacedRoleItem?.OldRoleId || roleId;

      if (questions[pageNumber]?.[savedRoleId]) {
        let newRoleId = replacedRoleItem ? replacedRoleItem.Id : roleId;

        modifiedQuestions[pageNumber] = {
          ...modifiedQuestions[pageNumber],
          [newRoleId]: questions[pageNumber][savedRoleId],
        };
      }
    });
  });

  return modifiedQuestions;
};
