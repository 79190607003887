import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styles from './styles.module.scss';
import Icons from 'pages/Properties/SearchResults/Icons';
import { goBack } from 'connected-react-router';
import classNames from 'classnames';

interface RadarHeaderProps {
  title?: string;
  actions?: any;
  children?: React.ReactNode;
  rightChildren?: React.ReactNode;
  headerClassName?: string;
}

export const RadarHeader: FC<RadarHeaderProps> = ({
  title,
  actions,
  children,
  rightChildren,
  headerClassName = '',
}) => {
  const dispatch = useDispatch();

  const handleBack = useCallback(() => dispatch(goBack()), [dispatch]);

  return (
    <div className={styles.headerWrapper}>
      <div className={classNames(styles.header, headerClassName)}>
        <div>
          <div className={styles.backWrapper} onClick={handleBack}>
            <Icons className={styles.icon} variant={Icons.BACK} />
          </div>
        </div>
        {title ? <div className={styles.title}>{title}</div> : null}
        {children ? <div className={styles.title}>{children}</div> : null}

        <div className={styles.filterBtnWrapper}>{rightChildren}</div>
      </div>
      {actions ? <div className={styles.actions}>{actions}</div> : null}
    </div>
  );
};
