import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { NameAutocomplete } from 'pages/Workshop/Transactions/TransactionFinancials/components';
import { getRelatedTransactionContactsFormattedList } from 'store/selectors/relatedContacts';
import { AddParticipantModal } from './AddParticipantModal';
import { splitName } from 'helpers';

import styles from './styles.module.scss';

type AgentCoordinatorFieldType = {
  item: any;
  memberContacts: any[];
  onChange?: Function;
  isFinancials?: boolean;
  refetchAgentsCoordinators?: Function;
  isAgentInfo?: boolean;
  isCoordinatorInfo?: boolean;
  isBuyerTransaction?: boolean;
  isSellerTransaction?: boolean;
};

export const AgentCoordinatorField = ({
  item,
  memberContacts,
  onChange,
  isFinancials = false,
  refetchAgentsCoordinators,
  isAgentInfo = false,
  isCoordinatorInfo = false,
  isBuyerTransaction = false,
  isSellerTransaction = false,
}: AgentCoordinatorFieldType) => {
  const transactionContacts = useSelector(getRelatedTransactionContactsFormattedList);

  const [selectedItem, setSelectedItem] = useState({ label: '', value: null });
  const [itemToPrefillModal, setItemToPrefillModal] = useState<any>({});
  const [namePreFillModal, setNamePrefillModal] = useState({
    FirstName: '',
    LastName: '',
  });
  const [openAddModal, setOpenAddModal] = useState(false);
  const [modalFieldsDisable, setModalFieldsDisable] = useState(false);

  // Determine mode of modal i.e. if the selected user already exists
  const [isUpdateModalMode, setUpdateModalMode] = useState(false);

  useEffect(() => {
    if (item) setSelectedItem(item);
  }, []);

  const isUserAlreadyExistsInTransaction = (itemToPrefill) => {
    const idSuffix = itemToPrefill.Id.split('-')[1]; // Extract the suffix
    const idToCompare = idSuffix === 'user' ? itemToPrefill.UserId : itemToPrefill.ContactId;

    return transactionContacts.some((item) => {
      if (idSuffix === 'user') {
        return item.UserId === idToCompare;
      } else if (idSuffix === 'contact') {
        return item.ContactId === idToCompare;
      }
      return false;
    });
  };

  const getFoundExistingTransactionContact = (itemToPrefill) => {
    const idSuffix = itemToPrefill.Id.split('-')[1]; // Extract the suffix
    const idToCompare = idSuffix === 'user' ? itemToPrefill.UserId : itemToPrefill.ContactId;

    const foundItem = transactionContacts.find((item) => {
      if (idSuffix === 'user') {
        return item.UserId === idToCompare;
      } else if (idSuffix === 'contact') {
        return item.ContactId === idToCompare;
      }
      return false;
    });

    return {
      ...foundItem,
      invitedOrUninvitedParticipantId:
        foundItem?.UninvitedParticipantId || foundItem?.ParticipantId,
      isParticipant: foundItem?.UninvitedParticipantId ? false : true,
    };
  };

  return (
    <div className={styles.agentCoordinatorWrapper}>
      <NameAutocomplete
        allNames={memberContacts?.map((item) => ({
          label: item?.Name,
          value: item?.Id,
        }))}
        value={selectedItem}
        onChange={(selectedParticipant) => {
          setSelectedItem(selectedParticipant);
          onChange && onChange(selectedParticipant);
        }}
        onSelect={(selectedParticipant) => {
          // For Financials Splits
          setSelectedItem(selectedParticipant);
          onChange && onChange(selectedParticipant);

          // For Transaction Overview Page
          if (!isFinancials) {
            const itemToPrefill = memberContacts?.find(
              (item) => item?.Id === selectedParticipant?.value,
            );

            if (itemToPrefill) {
              setUpdateModalMode(isUserAlreadyExistsInTransaction(itemToPrefill));
              const exisiting = getFoundExistingTransactionContact(itemToPrefill);
              setItemToPrefillModal({
                ...itemToPrefill,
                ...splitName(itemToPrefill?.Name),
                Role: exisiting?.Role || '',
                invitedOrUninvitedParticipantId: exisiting?.invitedOrUninvitedParticipantId || '',
                isParticipant: exisiting?.isParticipant || false,
              });
              setOpenAddModal(true);
            }
            if (selectedParticipant) setModalFieldsDisable(true);
          }
        }}
        isNoOptionFoundEnabled
        cbNoMemberOption={(name) => {
          setItemToPrefillModal({});
          setNamePrefillModal(splitName(name));
          setOpenAddModal(true);
        }}
        className={styles.noMarginBottom}
      />

      {/* Add Participant Modal */}

      <AddParticipantModal
        itemToPrefill={{ ...namePreFillModal, ...itemToPrefillModal }}
        isOpen={openAddModal}
        onCloseModal={() => {
          if (!isFinancials) {
            setSelectedItem({ label: '', value: null });
            setItemToPrefillModal(null);
          }
          setOpenAddModal(false);
          setModalFieldsDisable(false);
          setItemToPrefillModal({});
          setNamePrefillModal({ FirstName: '', LastName: '' });
          setUpdateModalMode(false);
        }}
        callback={(data) => {
          if (!isFinancials) {
            refetchAgentsCoordinators && refetchAgentsCoordinators();
          } else {
            const ledgerAccountKey = data?.UserId
              ? 'LedgerAccountUserId'
              : 'LedgerAccountContactId';
            const item = {
              label: `${data?.FirstName} ${data?.LastName}`,
              value: data?.UserId || data?.ContactId || data?.Id,
            };
            setSelectedItem(item);
            onChange && onChange({ ...item, key: ledgerAccountKey });
          }
        }}
        fieldsDisable={modalFieldsDisable}
        isAgentInfo={isAgentInfo}
        isCoordinatorInfo={isCoordinatorInfo}
        isBuyerTransaction={isBuyerTransaction}
        isSellerTransaction={isSellerTransaction}
        isUpdateModalMode={isUpdateModalMode}
      />
    </div>
  );
};
