import Api from 'store/effects/core/api';
import {
  deactivateTeamSubscriptionPlan,
  getLoginLogs,
  getTeamDetailsById,
  getTeamMembersByTeamId,
  getTeams,
  getTeamSeatsLogByTeamId,
  getTeamSubscriptionDetails,
  postInviteTeamMemberByAdmin,
  reactivateTeamSubscriptionPlan,
  updateMemberActiveStatusByAdmin,
  updateMemberProfileByAdmin,
  updateMemberRoleByAdmin,
  updateTeamSubscriptionPlan,
} from 'api/admin';
import {
  fetchTeamSubscriptionDetailsAction,
  requestDeactivateTeamSubscriptionAction,
  requestGetLoginLogsAction,
  requestGetTeamDetailsByIdAction,
  requestGetTeamMembersAction,
  requestGetTeamsDashboardAction,
  requestGetTeamSeatsLogByTeamIdAction,
  requestPostSendMemberInviteByAdminAction,
  requestReactivateTeamSubscriptionAction,
  requestUpdateTeamSubscriptionAction,
  setMembersSearchAction,
  setSelectedTeamName,
  setTeamSearchAction,
  updateAgentActiveStatusByAdminAction,
  updateAgentProfileByAdminAction,
  updateAgentRoleByAdminAction,
} from 'store/actions/adminPanel';

export const getTeamsDashboardEffect = (cfg, options, cb?) => {
  const requestParams = {
    action: requestGetTeamsDashboardAction,
    method: getTeams,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const setSelectedTeamNameEffect = (name) => (dispatch) => {
  dispatch(setSelectedTeamName(name));
};

export const getTeamDetailsByIdEffect = (cfg, options, cb) => {
  const { teamId } = cfg;
  const requestParams = {
    action: requestGetTeamDetailsByIdAction,
    method: getTeamDetailsById,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(teamId, options, cb);
};

export const getTeamSubscriptionDetailsEffect = (cfg, cb?) => {
  const sendRequest = Api.execBase({
    action: fetchTeamSubscriptionDetailsAction,
    method: getTeamSubscriptionDetails,
  });
  return sendRequest(cfg, {}, cb);
};

export const updateTeamSubscriptionPlanEffect = (cfg, options, cb?) => {
  const sendRequest = Api.execBase({
    method: updateTeamSubscriptionPlan,
    action: requestUpdateTeamSubscriptionAction,
  });
  return sendRequest(cfg, options, cb);
};

export const getTeamMembersByTeamIdEffect = (cfg, options, cb) => {
  const requestParams = {
    action: requestGetTeamMembersAction,
    method: getTeamMembersByTeamId,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const cancelTeamSubscriptionEffect = (cfg, cb) => (dispatch) => {
  const sendRequest = Api.execBase({
    action: requestDeactivateTeamSubscriptionAction,
    method: deactivateTeamSubscriptionPlan,
  });

  return dispatch(sendRequest(cfg, {}, cb));
};

export const reactivateTeamSubscriptionEffect = (cfg, cb) => (dispatch) => {
  const sendRequest = Api.execBase({
    action: requestReactivateTeamSubscriptionAction,
    method: reactivateTeamSubscriptionPlan,
  });

  return dispatch(sendRequest(cfg, {}, cb));
};

export const getTeamSeatsLogEffect = (cfg, options, cb?) => {
  const { teamId } = cfg;
  const requestParams = {
    action: requestGetTeamSeatsLogByTeamIdAction,
    method: getTeamSeatsLogByTeamId,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(teamId, options, cb);
};

export const setMembersSearchEffect = (cfg) => (dispatch) => {
  dispatch(setMembersSearchAction(cfg));
};

export const searchMembersEffect =
  ({ search }) =>
  (dispatch) => {
    dispatch(setMembersSearchEffect(search));
  };

export const resetMembersSearchEffect = () => (dispatch) => {
  dispatch(searchMembersEffect({ search: '' }));
};

export const updateMemberProfileByAdminEffect = (cfg, options, cb) => {
  const requestParams = {
    action: updateAgentProfileByAdminAction,
    method: updateMemberProfileByAdmin,
  };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  return sendRequest(cfg, options, cb);
};

export const updateMemberActiveStatusByAdminEffect = (cfg, options, cb) => {
  const requestParams = {
    action: updateAgentActiveStatusByAdminAction,
    method: updateMemberActiveStatusByAdmin,
  };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  return sendRequest(cfg, options, cb);
};

export const updateMemberRoleByAdminEffect = (cfg, options, cb) => {
  const requestParams = { action: updateAgentRoleByAdminAction, method: updateMemberRoleByAdmin };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  return sendRequest(cfg, options, cb);
};

export const getLoginLogsByAdminEffect = (cfg, options, cb) => {
  const requestParams = { action: requestGetLoginLogsAction, method: getLoginLogs };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  return sendRequest(cfg, options, cb);
};

export const handleSendTeamMemberInviteFormByAdminEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestPostSendMemberInviteByAdminAction,
    method: postInviteTeamMemberByAdmin,
  });

  return sendRequest(cfg, options, cb);
};

export const setTeamSearchEffect = (cfg) => (dispatch) => {
  dispatch(setTeamSearchAction(cfg));
};

export const searchTeamEffect =
  ({ search }) =>
  (dispatch) => {
    dispatch(setTeamSearchEffect(search));
  };

export const resetTeamSearchEffect = () => (dispatch) => {
  dispatch(searchTeamEffect({ search: '' }));
};
