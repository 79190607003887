import PropTypes from 'prop-types';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';
import { intersection } from 'lodash-es';
import { SubscriptionPlanLevel } from 'api/subscription';
import { AGENT, CLIENT } from 'settings/constants/roles';
import { checkRouteRequiresPremiumPlan } from 'settings/navigation/config';

const AppRoute = ({
  userRoles,
  userSubscriptionPlanLevel,
  clientContexts,
  routeRoles,
  path,
  component,
  exact,
}) => {
  const { search } = useLocation();
  if (routeRoles && (!userRoles || !intersection(userRoles, routeRoles).length)) {
    const redirectUrl = `/login?redirectUrl=${window.location.pathname}${search}`;
    return <Redirect to={redirectUrl} />;
  }

  // @NOTE: commenting out this logic for now as it duplicates with appInitEffect Action

  // if (
  //   userRoles?.includes(AGENT) &&
  //   checkRouteRequiresPremiumPlan(path) &&
  //   userSubscriptionPlanLevel &&
  //   userSubscriptionPlanLevel !== SubscriptionPlanLevel.Premium
  // ) {
  //   if (!userSubscriptionPlanLevel) {
  //     return null;
  //   }

  //   if (userSubscriptionPlanLevel !== SubscriptionPlanLevel.Premium) {
  //     return <Redirect to={routes.paywall} />;
  //   }
  // }

  // if (
  //   userRoles?.includes(CLIENT) &&
  //   checkRouteRequiresPremiumPlan(path) &&
  //   clientContexts &&
  //   !clientContexts?.length
  // ) {
  //   return <Redirect to={routes.paywall} />;
  // }

  return <Route key={path} path={path} component={component} exact={exact} />;
};

AppRoute.propTypes = {
  userRoles: PropTypes.arrayOf(PropTypes.string),
  userSubscriptionPlanLevel: PropTypes.oneOf([
    SubscriptionPlanLevel.Basic,
    SubscriptionPlanLevel.Premium,
  ]),
  clientContexts: PropTypes.arrayOf(PropTypes.object),
  routeRoles: PropTypes.arrayOf(PropTypes.string),
  path: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
  exact: PropTypes.bool,
};

AppRoute.defaultProps = {
  routeRoles: undefined,
  userRoles: undefined,
  exact: false,
};

export default AppRoute;
