import { createSelector } from 'reselect';
import { IDLE, PENDING, ERROR } from 'settings/constants/apiState';
import { tasksStatusesOptions } from 'settings/constants/transactionTasks';
import { RadarKitsInitialState } from 'store/reducers/radarKits/types';

const localState = ({ radarKits }: { radarKits: RadarKitsInitialState }) => radarKits;

export const getPublishedKitsSelector = createSelector(
  localState,
  ({ publishedKits }) => publishedKits,
);

export const getCampaignsSelector = createSelector(localState, ({ campaigns }) => campaigns);

export const getFilteredCampaigns = (campaigns: any, clientId) => {
  const addedKits: any = [];
  const nonAddedKits: any = [];
  (campaigns ?? []).forEach((campaign) => {
    if (campaign.CampaignMembers.some(({ ClientId }) => ClientId === clientId)) {
      addedKits.push(campaign);
    } else {
      nonAddedKits.push(campaign);
    }
  });
  return { addedKits, nonAddedKits };
};

export const getLibraryKitsSelector = createSelector(localState, ({ library }) => library);

export const selectedLibraryKitSelector = createSelector(
  localState,
  ({ library, selectedLibraryKitId }) =>
    library.data?.find(({ KitId }) => KitId === selectedLibraryKitId),
);

export const selectedSequenceSelector = createSelector(
  localState,
  ({ selectedCampaign }) => selectedCampaign.data,
);

export const selectedSequenceStateSelector = createSelector(
  localState,
  ({ selectedCampaign }) => selectedCampaign.state,
);

export const selectedSequenceCampaignMembers = createSelector(
  localState,
  ({ selectedCampaign }) => selectedCampaign.data?.CampaignMembers || [],
);

export const selectedPreviewKitSelector = (kitId: number) =>
  createSelector(localState, ({ publishedKits }) =>
    publishedKits.data?.find(({ KitId }) => KitId === kitId),
  );

export const getCampaignTaskSelector = createSelector(localState, ({ campaignTask }) => {
  const task = campaignTask?.data;
  const taskStatusOption = tasksStatusesOptions.find((option) => option?.value === task?.Status);
  return {
    isIdle: campaignTask.state === IDLE,
    isPending: campaignTask.state === PENDING,
    isError: campaignTask.state === ERROR,
    isData: !!campaignTask?.data,
    status: campaignTask?.meta?.status,
    task,
    taskStatusOption,
  };
});

export const getCampaignTaskMembersSelector = createSelector(
  localState,
  ({ campaignTaskMembers }) => {
    const memberData = campaignTaskMembers?.data;
    const teamMembers = memberData?.teamMembers || [];

    return {
      isIdle: campaignTaskMembers.state === IDLE,
      isPending: campaignTaskMembers.state === PENDING,
      isError: campaignTaskMembers.state === ERROR,
      isData: !!campaignTaskMembers?.data,
      data: [...teamMembers],
    };
  },
);
