import { SEARCH_INSTANCE_STATUS } from 'app-constants';
import _ from 'lodash';
import classNames from 'classnames';

import { Button, Input, SimpleModal } from 'components';
import { useFormik } from 'formik';
import { Icons } from 'pages/Properties/common/Icons';
import { useSearchInstanceV3Effect } from 'pages/Properties/ClientFeed/hooks/useSearchInstanceV3Effect';
import Icon from 'pages/Properties/SearchResults/Icons';
import styles from 'pages/Properties/SearchResults/LocalHeader/SaveSearchButton/styles.module.scss';
import { ValidationSchema } from 'pages/Properties/SearchResults/LocalHeader/SaveSearchButton/validation';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';
import { selectedSearchAction } from 'store/actions/feedv3';
import { getSearchInstancesEffect, saveSearchInstanceEffect } from 'store/effects';
import { cleanSearchQueryObj } from 'store/effects/search/helpers';
import { getCurrentContextSelector } from 'store/selectors/context';
import { getFeedCriteriaSelectorV3, getFeedMetaSelectorV3 } from 'store/selectors/feedv3';
import { getSearchCriteriaSelector } from 'store/selectors/search';
import { TabButton } from '../../ClientHeader';

type Props = {
  className?: string;
  disabled?: boolean;
};

export const SaveSearchBtn: React.FC<Props> = ({ className, disabled = false }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const [isPending, setIsPending] = useState(false);
  const searchContext = useSelector(getCurrentContextSelector);
  const cfgSearch = { contextKey: searchContext?.ContextKey };
  const { criteria } = useSelector(getFeedCriteriaSelectorV3);

  const formik = useFormik({
    initialValues: { name: '' },
    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      setIsPending(true);

      const DefaultPropertySearchPreferences = cleanSearchQueryObj(criteria);

      if (DefaultPropertySearchPreferences?.HomeType?.length < 1) {
        DefaultPropertySearchPreferences.HomeType = ['Single', 'Townhouse', 'Condo'];
      }

      const cfg = {
        name: values.name,
        status: SEARCH_INSTANCE_STATUS.ACTIVE,
        DefaultPropertySearchPreferences: {
          ...DefaultPropertySearchPreferences,
        },
        ...cfgSearch,
      };

      dispatch(
        saveSearchInstanceEffect(cfg, {}, (err, resp) => {
          if (!err) {
            // Get the recently added saved search and apply that on the Feed page.
            const latestIdObject: any = _.maxBy(resp?.data?.result, 'Id');
            if (latestIdObject) {
              const item = {
                id: latestIdObject?.Id,
                name: latestIdObject?.Name,
                criterias: latestIdObject?.DefaultPropertySearchPreferences,
              };
              dispatch(selectedSearchAction({ selectedSearch: item }));
            }
            dispatch(
              getSearchInstancesEffect(cfgSearch, {}, () => {
                history.push(routes.feed);
              }),
            );
          } else setIsPending(false);
        }),
      );
    },
  });

  const onClose = () => {
    setPopupOpen(false);
    formik.resetForm();
  };

  const onClickHandler = () => {
    if (!disabled) setPopupOpen(!popupOpen);
  };
  return (
    <div className={classNames(styles.saveSearchButton, className)}>
      <TabButton text="Save Search" icon={Icons.BOOKMARK} onClick={onClickHandler} />

      <SimpleModal isOpen={popupOpen} onClose={onClose} contentClassName={styles.popup}>
        <div className={styles.popupInner}>
          <form testid="save_search_form" onSubmit={formik.handleSubmit}>
            <div className={styles.header}>
              <Icon className={styles.icon} variant={Icon.BOOKMARK} />
              <span className={styles.title}>Save Search</span>
            </div>
            <Input
              label="Name"
              name="name"
              variant={Input.LIGHT_FULL}
              placeholder="e.g. Chicago - 3 BR / 2 BA"
              maxLength={30}
              onChange={formik.handleChange}
              value={formik.values.name}
              error={formik.touched.name ? formik.errors.name : ''}
              testid="search_name"
              className={styles.saveSearchInput}
            />
            <div className={styles.actions}>
              <Button
                onClick={onClose}
                className={classNames(styles.action, styles.cancel)}
                title="Cancel"
                testid="cancel"
                titleClassName={styles.buttonTitle}
              />
              <Button
                type="submit"
                title="Save"
                isPending={isPending}
                className={classNames(styles.action, styles.save)}
                loaderClassName={styles.loader}
                testid="save"
                titleClassName={styles.buttonTitle}
              />
            </div>
          </form>
        </div>
      </SimpleModal>
    </div>
  );
};
