import classNames from 'classnames';
import Button from 'components/Button';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getOnBoardingData } from 'pages/OnBoarding/selectors';
import { PENDING } from 'settings/constants/apiState';
import { THIRD_PARTY } from 'settings/constants/roles';
import { routes } from 'settings/navigation/routes';
import {
  getUserDataSelector,
  getUserNotificationSettingsSelector,
  getUserRoleSelector,
  isPartnerSelector,
} from 'store/selectors/user';

import { Question } from 'pages/RequestQuote/components';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';

import { updateNotificationsSettings } from 'api/user';
import { OnBoardingContainer } from 'pages/OnBoarding/components/OnBoardingContainer';
import { OnBoardingWrapper } from 'pages/OnBoarding/components/OnBoardingWrapper';
import { OnboardingContinueButton } from '../../agent/components/OnboardingContinueButton';
import styles from './styles.module.scss';

type Props = {
  className: string;
  onNext: () => {};
  stageIndex?: number;
  Controls: React.ElementType;
};

const NotificationForm: React.FC<Props> = (props) => {
  const { className, onNext, stageIndex, Controls } = props;
  const dispatch = useDispatch();
  const user = useSelector(getUserDataSelector);
  const userRole = useSelector(getUserRoleSelector);
  const { onBoarding } = useSelector(getOnBoardingData);
  const isPartner = useSelector(isPartnerSelector);
  const userNotificationsSettings = useSelector(getUserNotificationSettingsSelector);
  const [notificationGroups, setNotificationGroups] = useState(userNotificationsSettings);

  const history = useHistory();

  const isPending = onBoarding.state === PENDING;
  const onSkip = () => (userRole === THIRD_PARTY ? history.push(routes.index) : onNext());
  const [checked, setChecked] = useState<string>('');

  const isChecked = (value) => value === checked;

  const variantChangeHandler = ({ checkedValues }: { checkedValues: string[] }) => {
    const groups = JSON.parse(JSON.stringify(notificationGroups));
    Object.keys(groups[1].NotificationItems[0].Variants).forEach((key) => {
      groups[1].NotificationItems[0].Variants[key] = !!checkedValues.includes(key);
    });
    updateNotificationsSettings([
      {
        ...groups[1],
        NotificationItems: [groups[1].NotificationItems[0]],
      },
    ]);
    return;
  };

  return (
    <OnBoardingWrapper isPending={isPending}>
      <Controls onNext={() => onNext()} className={styles.controls} variant="lightFull" />

      <OnBoardingContainer className={styles.container}>
        <Question className={styles.title}>
          How do you want to receive notifications for new quote requests and inquiries?
        </Question>
        <AnswersContainer className={styles.answersContainer}>
          <Button
            className={classNames(styles.button, {
              [styles.active]: isChecked('Email'),
            })}
            title="Email"
            onClick={() => {
              variantChangeHandler({ checkedValues: ['Email'] });
              setChecked('Email');
            }}
          ></Button>
          <Button
            className={classNames(styles.button, {
              [styles.active]: isChecked('Sms'),
            })}
            title="Text Message"
            onClick={() => {
              variantChangeHandler({ checkedValues: ['Sms'] });
              setChecked('Sms');
            }}
          ></Button>
          <Button
            className={classNames(styles.button, {
              [styles.active]: isChecked('Both'),
            })}
            title="Both"
            onClick={() => {
              variantChangeHandler({ checkedValues: ['Email', 'Sms'] });
              setChecked('Both');
            }}
          ></Button>
        </AnswersContainer>
        <ButtonsContainer>
          <OnboardingContinueButton
            isPending={isPending}
            disabled={false}
            testid="next"
            htmlType="submit"
            className={classNames(styles.submitBtn, {
              [styles.active]: false,
            })}
            onClick={() => onNext()}
          />
        </ButtonsContainer>
      </OnBoardingContainer>
    </OnBoardingWrapper>
  );
};

export { NotificationForm };
