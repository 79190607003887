import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Locations, FormTitle } from 'components';
import { handleSendClientInviteForm } from 'store/effects/clientsList';
import { appSetActiveClientTransactionInstanceAction } from 'store/actions/app';
import {
  getClientDrawerSendingInvite,
  getInvitedClientRole,
  getActiveClientTransactionInstance,
} from 'store/selectors/app';
import { PENDING } from 'settings/constants/apiState';
import Footer from '../../../Footer';
import { InviteHeader } from '../../../Headers';
import { schema } from './validation';
import { ClientCategory } from 'types';
import { routes } from 'settings/navigation/routes';

import styles from './styles.module.scss';
import { inviteClientSteps } from 'types/inviteClient';

const InviteClientTransactionInstance = ({ onNext, stageIndex, onPrev }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const role = useSelector(getInvitedClientRole);
  const { state } = useSelector(getClientDrawerSendingInvite);
  const activeClientTransactionInstance = useSelector(getActiveClientTransactionInstance);

  const buyerRole = role.includes(ClientCategory.Buyer);
  const retentionRole = role.includes(ClientCategory.Retention);

  const formik = useFormik({
    initialValues:
      Object.keys(activeClientTransactionInstance).length > 0
        ? activeClientTransactionInstance
        : InviteClientTransactionInstance.initialValues,
    validateOnMount: true,
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(appSetActiveClientTransactionInstanceAction(values));
      if (retentionRole) {
        onNext(inviteClientSteps.ClientRetentionMode);
      } else if (buyerRole) {
        onNext(inviteClientSteps.ClientSearchInstance);
      } else {
        dispatch(
          handleSendClientInviteForm({ values }, () => {
            history.push(routes.transactionCreate);
          }),
        );
      }
    },
  });

  const isPending = state === PENDING;

  return (
    <form testid="invite_seller_form" className={styles.fullWidth} onSubmit={formik.handleSubmit}>
      <InviteHeader
        disabled={isPending}
        stageIndex={stageIndex}
        onPrev={onPrev}
        isValid={formik.isValid}
      />
      <div className={styles.formContainer}>
        <FormTitle className={styles.titleHeading}>Seller Profile</FormTitle>
        <div className={styles.contactInformation}>
          <Locations
            name="listingAddress"
            onResult={(_, preparedData) => {
              formik.setFieldValue('address', preparedData?.length ? preparedData[0] : []);
            }}
            label="Listing Address"
            placeholder="Enter Address"
            testid="listing_address"
            value={Object.keys(formik.values.address).length > 0 ? [formik.values.address] : []}
            multiple={false}
            variant={Locations.ROUND}
            placeholderClassName={styles.border}
            searchWrapperClassName={styles.border}
            valuesWrapperClassName={styles.border}
            dropdownClassName={styles.dropdown}
            rounded={true}
            shouldFilterCityLevelResults={true}
            allowedSmartyAddress={true}
            showOnlyCassAddresses={true}
            setFullCassAddress={true}
            shouldFilterStreetLevelResults={true}
          />
          {/* <Input
            disabled={isPending}
            className={styles.inputContainer}
            name="aptUnit"
            variant={Input.LIGHT_ROUND}
            onChange={formik.handleChange}
            value={formik.values.aptUnit}
            label="Apt/Unit"
            placeholder="Unit Number"
            error={formik.touched.aptUnit ? (formik.errors.aptUnit as string) : ''}
            testid="apt_unit"
          /> */}
        </div>
      </div>
      <Footer
        disabled={isPending}
        buttonTitle={buyerRole || retentionRole ? 'Continue' : 'Send Invitation'}
        isValid={formik.isValid}
        testid="invite_seller_footer"
      />
    </form>
  );
};

InviteClientTransactionInstance.initialValues = {
  address: {},
  aptUnit: '',
};

InviteClientTransactionInstance.propTypes = {
  onNext: PropTypes.func,
};

InviteClientTransactionInstance.defaultProps = {
  onNext: () => {},
  stageIndex: 1,
};

export default InviteClientTransactionInstance;
