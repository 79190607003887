import { Button } from 'components-antd';
import React from 'react';
import styles from '../DowngradeModal/styles.module.scss';

type StepProps = {
  handleNextStep: () => void;
  closeModal: () => void;
};

export const StepOne: React.FC<StepProps> = ({ handleNextStep, closeModal }) => {
  return (
    <div className={styles.content}>
      <div className={styles.header}>Are you sure you want to cancel?</div>
      <div className={styles.subHeader}>
        You will lose access to all data in your account and your clients will lose access to their
        Mosaik accounts and files.
      </div>
      <div className={styles.btnContainer}>
        <Button variant="secondary" className={styles.cancelBtn} onClick={closeModal}>
          Never Mind
        </Button>
        <Button variant="primary" className={styles.confirmBtn} onClick={handleNextStep}>
          Yes
        </Button>
      </div>
    </div>
  );
};
