import React, { useCallback } from 'react';

import { AgentDocumentTypes } from 'types';
import styles from '../styles.module.scss';

interface DocumentIconsProps extends React.SVGProps<SVGSVGElement> {
  type: AgentDocumentTypes;
}

const Container = ({ children, color }) => (
  <div
    style={{
      background: color,
    }}
    className={styles.container}
  >
    {children}
  </div>
);

export const DocumentIcons = ({ type }) => {
  const getIcon = useCallback(() => {
    switch (type) {
      case AgentDocumentTypes.Drafts:
        return (
          <Container color="#EEF9FC">
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.0371 2V7.03704C13.0371 7.37101 13.1698 7.69131 13.4059 7.92747C13.6421 8.16362 13.9624 8.2963 14.2964 8.2963H19.3334"
                stroke="#51BFE1"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.6661 24.6667H4.22164C3.55369 24.6667 2.9131 24.4013 2.44078 23.929C1.96847 23.4567 1.70313 22.8161 1.70312 22.1481V4.51852C1.70312 3.85057 1.96847 3.20997 2.44078 2.73766C2.9131 2.26534 3.55369 2 4.22164 2H13.0365L19.3328 8.2963V10"
                stroke="#51BFE1"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.74023 9.55603L8.66618 9.55603"
                stroke="#51BFE1"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.74023 14.5927H12.6662"
                stroke="#51BFE1"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.74023 19.6302L8.66618 19.6302"
                stroke="#51BFE1"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11.334 27.3334H15.5092L26.4693 16.3733C27.0229 15.8197 27.334 15.0687 27.334 14.2857C27.334 13.5027 27.0229 12.7518 26.4693 12.1981C25.9156 11.6444 25.1646 11.3334 24.3816 11.3334C23.5986 11.3334 22.8477 11.6444 22.294 12.1981L11.334 23.1581V27.3334Z"
                fill="#EEF9FC"
                stroke="#51BFE1"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21.25 13.2426L25.4252 17.4178"
                stroke="#51BFE1"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Container>
        );
      case AgentDocumentTypes.PendingForms:
        return (
          <Container color="#F4F4FB">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M12.8 16C12.8 12.8 8 12.8 8 4.80005H24C24 12.8 19.2 12.8 19.2 16C19.2 19.2001 24 19.2001 24 27.2001H8C8 19.2001 12.8 19.2001 12.8 16Z"
                stroke="#928CDA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.40039 4.80005H25.6004"
                stroke="#928CDA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.40039 27.2H25.6004"
                stroke="#928CDA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.0008 19.2C15.2008 19.2 12.8008 21.6 12.8008 24H19.2008C19.2008 21.6 16.8008 19.2 16.0008 19.2Z"
                fill="#928CDA"
              />
              <path
                d="M16.0004 12.8C15.6004 12.8 14.4004 12 14.4004 11.2H17.6004C17.6004 12 16.4004 12.8 16.0004 12.8Z"
                fill="#928CDA"
              />
            </svg>
          </Container>
        );
      case AgentDocumentTypes.PreApprovals:
        return (
          <Container color="#FFF4EB">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M18.666 4V9.33333C18.666 9.68696 18.8065 10.0261 19.0565 10.2761C19.3066 10.5262 19.6457 10.6667 19.9993 10.6667H25.3327"
                stroke="#FB913A"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M22.666 28H9.33268C8.62544 28 7.94716 27.719 7.44706 27.219C6.94697 26.7189 6.66602 26.0406 6.66602 25.3333V6.66667C6.66602 5.95942 6.94697 5.28115 7.44706 4.78105C7.94716 4.28095 8.62544 4 9.33268 4H18.666L25.3327 10.6667V25.3333C25.3327 26.0406 25.0517 26.7189 24.5516 27.219C24.0515 27.719 23.3733 28 22.666 28Z"
                stroke="#FB913A"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 12H13.3333"
                stroke="#FB913A"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 17.3334H20"
                stroke="#FB913A"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 22.6666H20"
                stroke="#FB913A"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Container>
        );
      case AgentDocumentTypes.MessageAttachments:
        return (
          <Container color="#EEFBF4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M19.9977 9.33459L11.331 18.0013C10.8006 18.5317 10.5026 19.2511 10.5026 20.0013C10.5026 20.7514 10.8006 21.4708 11.331 22.0013C11.8614 22.5317 12.5808 22.8297 13.331 22.8297C14.0811 22.8297 14.8006 22.5317 15.331 22.0013L23.9977 13.3346C25.0585 12.2737 25.6545 10.8349 25.6545 9.33459C25.6545 7.8343 25.0585 6.39545 23.9977 5.33459C22.9368 4.27372 21.4979 3.67773 19.9977 3.67773C18.4974 3.67773 17.0585 4.27372 15.9977 5.33459L7.33098 14.0013C5.73969 15.5926 4.8457 17.7508 4.8457 20.0013C4.8457 22.2517 5.73969 24.41 7.33098 26.0013C8.92228 27.5926 11.0805 28.4865 13.331 28.4865C15.5814 28.4865 17.7397 27.5926 19.331 26.0013L27.9976 17.3346"
                stroke="#04A451"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Container>
        );

      case AgentDocumentTypes.Transactions:
        return (
          <Container color="#F4F5F6">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 7.00037H19M19 7.00037L15.5 3.50037M19 7.00037L15.5 10.5004"
                stroke="#747475"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19 17H5M5 17L8.5 13.5M5 17L8.5 20.5"
                stroke="#747475"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Container>
        );

      case AgentDocumentTypes.Ungrouped:
        return (
          <Container color="#ff576d1a">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M26.666 8H5.33268C3.85992 8 2.66602 9.19391 2.66602 10.6667V21.3333C2.66602 22.8061 3.85992 24 5.33268 24H26.666C28.1388 24 29.3327 22.8061 29.3327 21.3333V10.6667C29.3327 9.19391 28.1388 8 26.666 8Z"
                stroke="#FF576D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16 16H16.0125"
                stroke="#FF576D"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M22.666 16H22.6785"
                stroke="#FF576D"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.33398 16H9.34648"
                stroke="#FF576D"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Container>
        );
      case AgentDocumentTypes.Misc:
        return (
          <Container color="#ff576d1A">
            <svg
              width="32"
              height="32"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 3V7C14 7.26522 14.1054 7.51957 14.2929 7.70711C14.4804 7.89464 14.7348 8 15 8H19"
                stroke="#FF576D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H14L19 8V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21Z"
                stroke="#FF576D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <circle cx="8.75" cy="13.25" r="1.25" fill="#FF576D" />
              <circle cx="12" cy="13.25" r="1.25" fill="#FF576D" />
              <circle cx="15.25" cy="13.25" r="1.25" fill="#FF576D" />
            </svg>
          </Container>
        );
      case DocumentIcons.MODAL_CLOSE:
        return (
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.75"
              y="0.75"
              width="38.5"
              height="38.5"
              rx="19.25"
              fill="white"
              stroke="#E8E9EA"
              strokeWidth="1.5"
            />
            <path d="M25 25L15 15" stroke="#262626" strokeWidth="2" strokeLinecap="round" />
            <path d="M15 25L25 15" stroke="#262626" strokeWidth="2" strokeLinecap="round" />
          </svg>
        );

      default: {
        return <></>;
      }
    }
  }, [type]);

  return getIcon();
};

DocumentIcons.MODAL_CLOSE = 'modal-close';
