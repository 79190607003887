/* eslint-disable jsx-a11y/label-has-associated-control */
import classNames from 'classnames';
import { AddPhoto, Button, Locations, Phone, Spinner, Wrapper } from 'components';
import { Select as AntdSelect, Option } from 'components-antd';
import Input from 'components/Form/Input';
import Textarea from 'components/Form/Textarea';
import { CheckBox } from 'components/Icons';
import { TitleWithButtons } from 'components/SettingsGroup';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updatePartnerProfileEffect } from 'store/effects/user';
import { getPartnerSettingsViewData, isUserPending } from 'store/selectors/user';
import { getLogoPlaceholder, prepareFormValues } from '../helpers';
import styles from '../styles.module.scss';
import { ValidationSchema } from './validation';

import { AreasOfOperationFieldInput } from 'pages/OnBoarding/components/Forms/PartnerOnboardingForms/AreasServedForm/AreasOfOperationFieldInput';
import locationStyles from 'pages/OnBoarding/components/Forms/PartnerOnboardingForms/AreasServedForm/styles.module.scss';
import {
  onAddressChange,
  onAreasOfOperationChange,
  onLogoChange,
  onPartnerTagDeselect,
  onPartnerTagSelect,
} from 'utils';
import { DirectoryPreference } from '../components';

const SettingsPartnerEditMode = ({
  changeEditMode,
  tagsError,
  tagsData,
  tagsLoading,
  serviceTags,
}) => {
  let tagIds = serviceTags.map((item) => item.Id);
  const dispatch = useDispatch();
  const partnerData = useSelector(getPartnerSettingsViewData);
  const isPending = useSelector(isUserPending);

  const onSubmit = useCallback(
    (values) => {
      dispatch(
        updatePartnerProfileEffect(values, (err) => {
          if (!err) {
            changeEditMode();
          }
        }),
      );
    },
    [dispatch, changeEditMode],
  );

  const [phoneCountry, setPhoneCountry] = useState();

  const validationSchema = useMemo(() => ValidationSchema(phoneCountry), [phoneCountry]);

  const getInitialValues = useCallback(() => {
    return prepareFormValues(partnerData, serviceTags);
  }, []);

  return (
    <Wrapper isPending={isPending} className={styles.pageWrapper}>
      <Formik
        initialValues={getInitialValues()}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, handleSubmit, dirty, handleChange, touched, errors, setFieldValue }) => (
          <form testid="edit_partner_profile" onSubmit={handleSubmit}>
            <TitleWithButtons className={styles.title} title="Account Settings">
              {dirty ? (
                <Button
                  testid="profile_save"
                  type="submit"
                  className={styles.saveBtn}
                  title="Save"
                  isPending={isPending}
                />
              ) : (
                <Button
                  testid="profile_save"
                  type="button"
                  className={styles.saveBtn}
                  title="Save"
                  onClick={changeEditMode}
                />
              )}
            </TitleWithButtons>
            {tagsLoading ? (
              <Spinner loaderClassName={classNames(styles.loader)} />
            ) : (
              <div className={styles.profileWrapper}>
                <div className={styles.leftBlock}>
                  <AddPhoto
                    className={styles.updateAvatar}
                    avatarClassName={styles.avatar}
                    onChange={(image, file) => onLogoChange(image, file, setFieldValue)}
                    value={partnerData.LogoUrl}
                    binary
                    placeholder={getLogoPlaceholder(partnerData.BusinessName)}
                  >
                    <span>Update</span>
                  </AddPhoto>
                </div>
                <div className={styles.rightBlock}>
                  <h3 className={styles.subTitle}>Directory Profile</h3>
                  <div className={styles.inputBlock}>
                    <label htmlFor="BusinessName" className={styles.editLabel}>
                      Company Name
                    </label>
                    <div className={styles.rightBlock}>
                      <Input
                        id="BusinessName"
                        className={styles.input}
                        type="text"
                        name="BusinessName"
                        error={touched.BusinessName ? errors.BusinessName : ''}
                        value={values.BusinessName}
                        onChange={handleChange}
                        disabled={isPending}
                        testid="business_name"
                        inputClassName={styles.inputField}
                      />
                    </div>
                  </div>
                  <div className={styles.inputBlock}>
                    <label htmlFor="AdditionalBusinessName" className={styles.editLabel}>
                      Alias
                    </label>
                    <div className={styles.rightBlock}>
                      <Input
                        id="AdditionalBusinessName"
                        className={styles.input}
                        type="text"
                        name="AdditionalBusinessName"
                        error={touched.AdditionalBusinessName ? errors.AdditionalBusinessName : ''}
                        value={values.AdditionalBusinessName}
                        placeholder="Enter name (Optional)"
                        onChange={handleChange}
                        disabled={isPending}
                        testid="additional_name"
                        inputClassName={styles.inputField}
                      />
                    </div>
                  </div>
                  <div className={styles.inputBlock}>
                    <label htmlFor="Email" className={styles.editLabel}>
                      Email
                    </label>
                    <div className={styles.rightBlock}>
                      <Input
                        id="Email"
                        className={styles.input}
                        type="text"
                        name="Email"
                        error={touched.Email ? errors.Email : ''}
                        value={values.Email}
                        onChange={handleChange}
                        disabled={isPending}
                        testid="email"
                        inputClassName={styles.inputField}
                      />
                    </div>
                  </div>
                  <div className={styles.inputBlock}>
                    <label htmlFor="PhoneNumber" className={styles.editLabel}>
                      Phone
                    </label>
                    <div className={styles.rightBlock}>
                      <Phone
                        name="PhoneNumber"
                        value={partnerData.PhoneNumber}
                        onChange={(event, value, country) => {
                          setPhoneCountry(country);
                          setFieldValue('PhoneNumber', value);
                        }}
                        error={touched.PhoneNumber ? errors.PhoneNumber : ''}
                        disabled={isPending}
                        className={classNames(styles.rightBlock, styles.input, styles.phone)}
                        options={{ inputProps: { id: 'PhoneNumber' } }}
                        testid="phone"
                        inputClassName={styles.inputField}
                      />
                    </div>
                  </div>
                  <div className={styles.inputBlock}>
                    <label htmlFor="OfficeAddress" className={styles.editLabel}>
                      Office Address
                    </label>
                    <div className={styles.rightBlock}>
                      <Locations
                        multiple={false}
                        types={['address']}
                        variant="full"
                        onResult={(result) => onAddressChange(result, setFieldValue)}
                        value={values.Address ? [values.Address] : []}
                        error={touched.Address ? errors.Address : ''}
                        className={classNames(
                          styles.rightBlock,
                          styles.input,
                          styles.officeAddress,
                        )}
                        testid="office_address"
                        valuesWrapperClassName={styles.inputFieldLocations}
                      />
                      {typeof errors?.Address === 'string' && (
                        <div className={styles.errorContainer}>{errors.Address}</div>
                      )}
                    </div>
                  </div>
                  <div className={styles.inputBlock}>
                    <label htmlFor="SuiteUnit" className={styles.editLabel}>
                      Unit / Suite
                    </label>
                    <div className={styles.rightBlock}>
                      <Input
                        id="SuiteUnit"
                        className={styles.input}
                        type="text"
                        placeholder="eg. 1053"
                        name="SuiteUnit"
                        error={touched.SuiteUnit ? errors.SuiteUnit : ''}
                        value={values.SuiteUnit}
                        onChange={handleChange}
                        disabled={isPending}
                        testid="suite_unit"
                        inputClassName={styles.inputField}
                      />
                    </div>
                  </div>
                  <div className={styles.inputBlock}>
                    <label htmlFor="Description" className={styles.editLabel}>
                      Description
                    </label>
                    <div className={styles.rightBlock}>
                      <Textarea
                        id="Description"
                        className={styles.input}
                        type="text"
                        name="Description"
                        error={touched.Description ? errors.Description : ''}
                        value={values.Description}
                        onChange={handleChange}
                        disabled={isPending}
                        testid="description"
                        inputFieldClassName={styles.inputTextarea}
                      />
                    </div>
                  </div>
                  <div className={styles.inputBlock}>
                    <label htmlFor="Website" className={styles.editLabel}>
                      Website
                    </label>
                    <div className={styles.rightBlock}>
                      <Input
                        id="Website"
                        className={styles.input}
                        type="text"
                        name="Website"
                        error={touched.Website ? errors.Website : ''}
                        value={values.Website}
                        onChange={handleChange}
                        disabled={isPending}
                        testid="website"
                        inputClassName={styles.inputField}
                      />
                    </div>
                  </div>
                  {tagsError ? (
                    <></>
                  ) : (
                    <div className={styles.inputBlock}>
                      <label htmlFor="PartnerServices" className={styles.editLabel}>
                        Services
                      </label>
                      <div className={styles.rightBlock}>
                        <AntdSelect
                          large
                          showArrow={true}
                          mode="tags"
                          getPopupContainer={(triggerNode) => triggerNode}
                          menuItemSelectedIcon={<CheckBox className={styles.checkBoxIcon} />}
                          placeholder="Select Services"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                          }
                          onSelect={(value) =>
                            onPartnerTagSelect(value, values, setFieldValue, tagsData)
                          }
                          onDeselect={(value) => onPartnerTagDeselect(value, values, setFieldValue)}
                          value={values.PartnerServices}
                          id="PartnerServices"
                          name="PartnerServices"
                          className={styles.antdSelect}
                          disabled={!tagsData}
                        >
                          {tagsData ? (
                            tagsData.map((el, i) => (
                              <Option value={el.value} key={`tags-${el.value}`}>
                                {el.name}
                              </Option>
                            ))
                          ) : (
                            <Option value={''} key={`tags-${0}`}>
                              Loading...
                            </Option>
                          )}
                        </AntdSelect>
                      </div>
                    </div>
                  )}
                  <div className={styles.inputBlock}>
                    <label htmlFor="AreasOfOperation" className={styles.editLabel}>
                      Geographic Areas Served
                    </label>
                    <div className={classNames(styles.rightBlock, styles.geoBlocks)}>
                      <div className={locationStyles.stageWrap}>
                        <AreasOfOperationFieldInput
                          name="locations"
                          values={values}
                          touched={touched}
                          errors={errors}
                          setFieldValue={setFieldValue}
                          onAreasOfOperationChange={onAreasOfOperationChange}
                          isPending={isPending}
                          showLabels={false}
                        />
                        {typeof errors?.locations === 'string' && (
                          <div className={styles.errorContainer}>{errors.locations}</div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={styles.inputBlock}>
                    <DirectoryPreference
                      isChecked={partnerData.DirectoryPreference}
                      onChange={(value) => {
                        const event = { target: { value, name: 'DirectoryPreference' } };
                        handleChange(event);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </form>
        )}
      </Formik>
    </Wrapper>
  );
};

SettingsPartnerEditMode.propTypes = {
  changeEditMode: PropTypes.func.isRequired,
  tagsLoading: PropTypes.bool,
  tagsError: PropTypes.bool,
  tagsData: PropTypes.array,
  serviceTags: PropTypes.array,
};

export default SettingsPartnerEditMode;
