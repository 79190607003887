import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ConfirmationDialog } from 'components';
import { Modal } from 'components-antd';
import { Icons } from '../../Icons';
import { Icons as FormIcons } from './../NewFormModal/FormStages/components';
import { FormSearchFilter } from '../NewFormModal/FormStages/components';
import { LinkFormsTable } from './LinkFormsTable';
import {
  getAllUnlinkedFormProcessEffect,
  linkToFormProcessEffect,
} from 'store/effects/formProcess';
import { getTransactionDocumentsFilterEffect } from 'store/effects/transactions';
import {
  getAllUnlinkFormProcessesSelector,
  getRequestFormProcessSelector,
} from 'store/selectors/requestFormProcess';
import { renderLastActivity, renderStatus } from '../FormsTable';
import { showSuccessMessage } from 'helpers';

import styles from './style.module.scss';
import { debounce } from 'lodash-es';

const FE_PAGE_SIZE = 10;

type TransactionType = {
  Id?: string;
  IsProject?: boolean;
};

type LinkFormModalProps = {
  open: boolean;
  onClose: () => void;
  onUpdate: Function;
  transaction?: TransactionType;
  taskId?: number;
  cb?: () => void;
  isLinkClient?: boolean;
  clientId?: number;
};

const columns = [
  {
    key: 'formName',
    title: 'Upload',
    dataIndex: 'formName',
    width: 300,
    render: (formName) => {
      return (
        <div className={styles.formName}>
          <FormIcons variant={FormIcons.FILE} className={styles.fileIcon} />
          <span className={styles.name}>{formName}</span>
        </div>
      );
    },
  },
  {
    key: 'status',
    title: 'Status',
    dataIndex: 'status',
    width: 150,
    render: (status) => renderStatus(status),
  },
  {
    key: 'created',
    title: 'Created',
    render: (row) => (
      <span>
        {row?.creator?.FirstName} {row?.creator?.LastName}
      </span>
    ),
  },
  {
    key: 'lastActivity',
    title: 'Last Activity',
    render: (row) => renderLastActivity(row, false),
  },
];

export const LinkFormModal = ({
  open,
  onClose,
  transaction,
  taskId,
  cb,
  isLinkClient = false,
  clientId,
}: LinkFormModalProps) => {
  const dispatch = useDispatch();
  const allUnlinkFormProcesses = useSelector(getAllUnlinkFormProcessesSelector);
  const [localUnlinkFormProcesses, setLocalUnlinkFormProcesses] = useState<any[]>([]);
  const { isPending } = useSelector(getRequestFormProcessSelector);

  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [searchTerm, setSearchTerm] = useState('');
  const [showSelection, setShowSelection] = useState(false);
  const [rowKeys, setRowKeys] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const [formToLink, setFormToLink] = useState<any>(null);

  useEffect(() => {
    if (open) {
      setSearchTerm('');
      dispatch(
        getAllUnlinkedFormProcessEffect({
          page: 1,
          pageSize: FE_PAGE_SIZE,
          search: '',
        }),
      );
    }
  }, [open]);

  useEffect(() => {
    if (allUnlinkFormProcesses) {
      // Map status 'Created' to 'Draft'
      setLocalUnlinkFormProcesses(
        allUnlinkFormProcesses?.forms?.map((item) => ({
          ...item,
          status: item?.status === 'Created' ? 'Draft' : item?.status,
        })),
      );

      setPageNumber(allUnlinkFormProcesses?.page);
      setTotalPages(allUnlinkFormProcesses?.total);
    }
  }, [allUnlinkFormProcesses]);

  const onUpdateLinkForm = async () => {
    setConfirmationLoading(true);
    dispatch(
      linkToFormProcessEffect(
        {
          formProcessIds: [formToLink?.formProcessId],
          ...(isLinkClient ? { clientIds: [clientId] } : { transactionId: transaction?.Id }),
          ...(taskId && { taskIds: [taskId] }),
        },
        (err) => {
          if (!err) {
            if (!isLinkClient) dispatch(getTransactionDocumentsFilterEffect());
            showSuccessMessage('Form linked successfully.');
            if (cb) cb();
            setFormToLink(null);
            onClose();
            setShowConfirmationModal(false);
          }
          setConfirmationLoading(false);
        },
      ),
    );
  };

  const onSearch = (search: string) => {
    setSearchTerm(search);
    debouncedSearch(search);
  };

  const handleSearch = (search) => {
    dispatch(
      getAllUnlinkedFormProcessEffect({
        page: 1,
        pageSize: FE_PAGE_SIZE,
        search,
      }),
    );
  };

  const debouncedSearch = useCallback(debounce(handleSearch, 500), []);

  const handleOnChange = (data) => {
    dispatch(
      getAllUnlinkedFormProcessEffect({
        page: data?.current,
        pageSize: FE_PAGE_SIZE,
        search: searchTerm,
      }),
    );
  };

  return (
    <Modal
      open={open}
      width={875}
      footer={null}
      onCancel={() => {
        onClose();
      }}
      maskClosable={false}
      destroyOnClose
      className={styles.linkFormModal}
      closeIcon={<Icons variant={'modal-close'} />}
    >
      <div className={styles.header}>
        <h1 className={styles.heading}>Link Form</h1>
      </div>
      <FormSearchFilter
        searchText={searchTerm}
        onSearch={onSearch}
        showSelection={showSelection}
        onShowSelection={(selection) => {
          !selection && rowKeys.length > 0 && setRowKeys([]);
          setShowSelection(selection);
        }}
        customFilter={<div />}
        hideSelection
      />
      <LinkFormsTable
        columns={columns}
        dataSource={localUnlinkFormProcesses}
        loading={isPending}
        onRowClick={(row) => {
          setFormToLink(row);
          setShowConfirmationModal(true);
        }}
        pagination={{
          pageSize: FE_PAGE_SIZE,
          total: totalPages,
        }}
        onChange={handleOnChange}
      />

      <ConfirmationDialog
        onReject={() => setShowConfirmationModal(false)}
        onConfirm={onUpdateLinkForm}
        isOpen={showConfirmationModal}
        confirmText="Link Form"
        isPending={confirmationLoading}
        className={styles.confirmFormLinkDialog}
      >
        <div className={styles.content}>
          Are you sure you want to link <strong>{formToLink?.formName}</strong> with this
          {isLinkClient ? ' Client' : ' Transaction'}?
        </div>
      </ConfirmationDialog>
    </Modal>
  );
};
