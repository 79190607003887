import { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { getOnBoardingData } from 'pages/OnBoarding/selectors';
import { PENDING } from 'settings/constants/apiState';

import { OnBoardingWrapper } from 'pages/OnBoarding/components/OnBoardingWrapper';
import { OnBoardingContainer } from 'pages/OnBoarding/components/OnBoardingContainer';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { OnboardingContinueButton } from 'pages/OnBoarding/components/Forms/agent/components/OnboardingContinueButton';
import { dropConfetti } from 'utils';
import {
  getAgentTeamDetailSelector,
  getAgentTeamRoleSelector,
} from 'store/selectors/agentTeamDetail';
import { getUserCompletedProfile } from 'store/selectors/user';
import { Question } from 'pages/RequestQuote/components';

import styles from './styles.module.scss';
import { getAgentTypeSelector } from 'store/selectors/user';
import { TEAM_OWNER } from 'settings/constants/roles';
import { AGENT_TYPE } from 'settings/constants/drawers';
import { markProfileAsCompleteEffect } from 'store/effects';

const IntroScreen = (props) => {
  const { onNext } = props;
  const { onBoarding } = useSelector(getOnBoardingData);
  const { data } = useSelector(getAgentTeamDetailSelector);
  const agentType = useSelector(getAgentTypeSelector);
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const isProfileComplete = useSelector(getUserCompletedProfile);

  const isPending = onBoarding.state === PENDING || !data?.TeamName;

  useEffect(() => {
    dropConfetti();
  }, []);

  const dispatch = useDispatch();
  useEffect(async () => {
    if (
      data?.AgentId &&
      agentRole !== TEAM_OWNER &&
      agentType === AGENT_TYPE.Team &&
      !isProfileComplete
    ) {
      dispatch(markProfileAsCompleteEffect({ id: data.AgentId }));
    }
  }, [data?.AgentId, agentRole, agentType, isProfileComplete]);

  return (
    <OnBoardingWrapper isPending={isPending} className={styles.wrapper}>
      <OnBoardingContainer>
        <Question>
          You have been invited to join the {data?.TeamName}. Let&apos;s set up your Mosaik account.
        </Question>
        <ButtonsContainer>
          <OnboardingContinueButton
            className={styles.submit}
            isPending={isPending}
            onClick={onNext}
          />
        </ButtonsContainer>
      </OnBoardingContainer>
    </OnBoardingWrapper>
  );
};

IntroScreen.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  stageIndex: PropTypes.number,
  Controls: PropTypes.elementType,
};

IntroScreen.defaultProps = {
  className: '',
  onNext: () => {},
  stageIndex: undefined,
  Controls: () => null,
};

export default IntroScreen;
