import { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { cloneDeep } from 'lodash-es';
import { useSelector, useDispatch } from 'react-redux';

import { AnswersContainer } from '../../AnswersContainer';
import { ButtonsContainer } from '../../ButtonsContainer';
import { Continue } from '../../Continue';
import Participant from './Participant';
import CheckboxComponent from 'components/Form/Checkbox';
import Icon from 'pages/Workshop/Transactions/TransactionCreate/Icons';
import { FullAccessInfo } from 'components/Modals/TransactionInviteModal/FullAccessInfo';
import { Question } from 'pages/Workshop/Transactions/TransactionCreate/components';
import { validateFullAccess } from 'pages/Workshop/Transactions/TransactionCreate/helpers';
import { validateParticipant } from 'pages/Workshop/Transactions/TransactionCreate/helpers';

import { splitFirstOccurrence } from 'utils';

import {
  transactionPreFormQuestionsIds as questionId,
  participantEmpty,
  TransactionUserRoleMap,
  TransactionUserRole,
  transactionPreFormQuestionsIds,
} from 'settings/constants/transaction';

import { getTransactionPreFormSelector } from 'store/selectors/transactions';
import { getAgentTeamIsActiveSelector } from 'store/selectors/agentTeamDetail';
import { getTransactionRolesSelector } from 'store/selectors/transactionRoles';
import {
  getTransactionRolesEffect,
  setTransactionPreFormQuestionsEffect,
} from 'store/effects/transactions';

import styles from './styles.module.scss';

import { InviteToTransaction } from './InviteToTransaction';
import { TransactionMemberRole } from './TransactionMemberRole';
import { getTeamListDataArray } from 'store/selectors/teamList';
import { preFormQuestionsIds } from 'settings/constants/preForm';

interface MembersProps {
  className?: string;
  onNext?: (...args) => void;
  currentQuestionId?: string;
  previousQuestionId?: string;
  setPreviousQuestionId?: (Id: string) => void;
  setCurrentQuestionId?: (Id: string) => void;
  heading?: string;
}

const TransactionMembers = ({
  className,
  onNext,
  currentQuestionId,
  heading = 'Who is the agent you are representing?',
}: MembersProps) => {
  const dispatch = useDispatch();

  const { preForm, isPending } = useSelector(getTransactionPreFormSelector);
  const { transactionRoles } = useSelector(getTransactionRolesSelector);
  const isTeamAgentActive = useSelector(getAgentTeamIsActiveSelector);

  const teamList = useSelector(getTeamListDataArray);
  const teamMembers = useMemo(() => teamList, []);

  const inviteAgentQuestion = currentQuestionId === questionId.agents;
  const inviteCoordinatorQuestion = currentQuestionId === questionId.coordinators;
  const whoisReprentingBuyerQuestion = currentQuestionId === questionId.whoIsRepresentingTheBuyer;

  const isBuyerFlow =
    preForm?.whoAreYouRepresenting?.Buyer &&
    !preForm?.whoAreYouRepresenting?.Seller &&
    inviteAgentQuestion;

  const isSellerFlow =
    preForm?.whoAreYouRepresenting?.Seller &&
    !preForm?.whoAreYouRepresenting?.Buyer &&
    inviteAgentQuestion;

  const isDualFlow =
    preForm?.whoAreYouRepresenting?.Seller &&
    preForm?.whoAreYouRepresenting?.Buyer &&
    inviteAgentQuestion;

  let preFormQuestionId = questionId.clients;
  if (inviteAgentQuestion) {
    preFormQuestionId = questionId.agents;
  } else if (inviteCoordinatorQuestion) {
    preFormQuestionId = questionId.coordinators;
  } else if (whoisReprentingBuyerQuestion) {
    preFormQuestionId = questionId.whoIsRepresentingTheBuyer;
  }

  const participantsValues = cloneDeep(preForm?.[preFormQuestionId]) || [];

  const formattedData = (obj) => {
    let type: string | undefined;

    if (isSellerFlow) type = TransactionUserRole.ListingAgent;

    if (whoisReprentingBuyerQuestion || isBuyerFlow) type = TransactionUserRole.BuyerAgent;

    if (!obj?.roleId && type) {
      const role = transactionRoles?.find((t) => t.Title === type);

      obj.roleId = role?.Id;
      obj.tagRole = whoisReprentingBuyerQuestion
        ? TransactionUserRoleMap[type]
        : TransactionUserRoleMap[role?.Title];
    }

    if (obj?.phone) {
      obj.Phones = [
        {
          PhoneNumber: obj.phone,
          PhoneType: 'Mobile',
          IsPrimary: true,
          IsVerified: false,
        },
      ];
    }
    return obj;
  };

  const updateStoredMembers = (values) => {
    dispatch(
      setTransactionPreFormQuestionsEffect({
        [preFormQuestionId]: [...values],
      }),
    );
  };

  const createParticipant = () => ({
    name: '',
    value: '',
    role: '',
    avatarUrl: '',
    firstName: '',
    lastName: '',
    invite: true,
    Phones: [{ PhoneNumber: '', PhoneType: '' }],
    transactionAccess: false,
  });

  useEffect(() => {
    if (whoisReprentingBuyerQuestion && !transactionRoles) {
      dispatch(getTransactionRolesEffect());
    }
  }, []);

  useEffect(() => {
    if (
      (!preForm?.agents?.length && inviteAgentQuestion) ||
      (!preForm?.coordinators?.length && inviteCoordinatorQuestion) ||
      (!preForm?.whoIsRepresentingTheBuyer?.length &&
        whoisReprentingBuyerQuestion &&
        transactionRoles)
    ) {
      const newParticipant = createParticipant();
      updateStoredMembers([
        {
          ...(whoisReprentingBuyerQuestion || isBuyerFlow || isSellerFlow
            ? formattedData(newParticipant)
            : participantEmpty),

          ...(inviteAgentQuestion || inviteCoordinatorQuestion || whoisReprentingBuyerQuestion
            ? { invite: false }
            : undefined),
        },
      ]);
    }
  }, [currentQuestionId, transactionRoles]);

  const onAdd = () => {
    const newParticipant = createParticipant();
    participantsValues.push(
      formattedData({
        ...newParticipant,
        invite: false,
      }),
    );
    updateStoredMembers(participantsValues);
  };

  const onDeleteParticipant = (index) => {
    participantsValues.splice(index, 1);
    updateStoredMembers(participantsValues);
  };

  const onRemoveData = (index) => {
    const newParticipant = createParticipant();
    participantsValues[index] = formattedData({
      ...newParticipant,
      invite: false,
      Phones: [{ PhoneNumber: '', PhoneType: '' }],
      tagRole: participantsValues[index]?.tagRole,
      roleId: participantsValues[index]?.roleId,
    });
    updateStoredMembers(participantsValues);
  };

  const insertName = (value, index: number) => {
    const inputValue = value?.trim();
    const { first, remainder } = splitFirstOccurrence(inputValue, ' ');

    updateParticipantValues(
      formattedData({
        customName: inputValue,
        customEmail: inputValue ? participantsValues[index].customEmail : '',
        email: '',
        id: '',
        value: '',
        name: '',
        firstName: first,
        lastName: remainder,
        role: '',
        tagRole: participantsValues[index]?.tagRole,
        roleId: participantsValues[index]?.roleId,
        error: '',
        transactionAccess: inputValue !== '' ? participantsValues[index].transactionAccess : false,
        ContactId: '',
        UserId: '',
        isContact: false,
      }),
      index,
    );
  };

  const insertRole = (event, index) => {
    const inputValue = event.target.value.trim();

    if (inputValue !== '') {
      updateParticipantValues(
        {
          tagRole: inputValue,
        },
        index,
      );
    }
  };

  const onBlurClientInput = (value, index) => {
    insertName(value, index);
  };

  const updateParticipantValues = (obj, index) => {
    participantsValues[index] = {
      ...(participantsValues?.[index] || {}),
      ...obj,
    };

    updateStoredMembers(participantsValues);
  };

  const onChangeClient = (value, index) => {
    updateParticipantValues(
      {
        ...formattedData(value),
        customEmail: value.email,
        customName: undefined,
        error: undefined,
        roleId: participantsValues[index]?.roleId,
        tagRole: participantsValues[index]?.tagRole,
        isContact: value.isContact,
        ...(value.isContact && { transactionAccess: false }),
      },
      index,
    );
  };

  const onRoleKeyPress = (event, index) => {
    if (event.key === 'Enter') {
      insertRole(event, index);
    }
  };

  const onChangeRole = (value, index) => {
    updateParticipantValues(
      {
        tagRole: value?.name || value,
        roleId: value?.roleId,
      },
      index,
    );
  };

  const onInvite = (checked, index) => {
    updateParticipantValues(
      {
        invite: checked,
        transactionAccess: false,
      },
      index,
    );
  };

  const canContinue = () => validateParticipant(participantsValues, inviteAgentQuestion);

  const getQuestionTitle = () => {
    if (currentQuestionId === questionId.agents) return 'Who is the agent in this transaction?';
    else if (currentQuestionId === questionId.coordinators)
      return 'Is there a coordinator in this transaction?';
    else return heading;
  };

  const fullAccess = () =>
    (currentQuestionId === questionId.agents || questionId.coordinators) &&
    validateFullAccess(participantsValues);

  const handleContinue = () => onNext?.();

  const onChangeAccess = (value, index) => {
    updateParticipantValues(
      {
        transactionAccess: value,
      },
      index,
    );
  };

  const showFullAccess = (id: number, tagRole: string) => {
    const isSelectedUserTeamMember = !!teamMembers?.find((el: { Id: number }) => el.Id === id);
    return isTeamAgentActive && isSelectedUserTeamMember;
  };

  const roleInfoProp = useMemo(() => {
    if (isDualFlow) return { isAgent: true };

    return isBuyerFlow ? { isBuyerTransaction: true } : { isSellerTransaction: true };
  }, [preForm?.whoAreYouRepresenting, isBuyerFlow, isSellerFlow, isDualFlow]);

  return (
    <div
      testid="invite_participants"
      className={classNames(styles.participants, styles.container, className)}
    >
      <Question className={classNames(styles.question)}>{getQuestionTitle()}</Question>

      <AnswersContainer className={styles.answersContainer}>
        <div>
          {participantsValues.map((participant, index) =>
            inviteAgentQuestion ||
            inviteCoordinatorQuestion ||
            whoisReprentingBuyerQuestion ||
            participant?.invite ? (
              <div className={styles.boxWrapper} key={index}>
                <Participant
                  key={participant?.id || index}
                  onBlurClientInput={(value) => onBlurClientInput(value, index)}
                  onChangeClient={(value) => onChangeClient(value, index)}
                  onDelete={() => onDeleteParticipant(index)}
                  onRemoveData={() => onRemoveData(index)}
                  value={participant}
                  participants={participantsValues}
                  currentQuestionId={currentQuestionId}
                  participantIndex={index}
                  updateParticipantValues={(value) => updateParticipantValues(value, index)}
                />

                <TransactionMemberRole
                  onChange={(value) => onChangeRole(value, index)}
                  onKeyPress={(event) => onRoleKeyPress(event, index)}
                  value={participant}
                  {...roleInfoProp}
                  isCoordinator={inviteCoordinatorQuestion}
                  whoisReprentingBuyerQuestion={whoisReprentingBuyerQuestion}
                />

                <InviteToTransaction
                  invite={participant.invite}
                  onInvite={(checked) => onInvite(checked, index)}
                />

                {!participant.externalMember &&
                !participant.isContact &&
                participant.invite &&
                showFullAccess(participant.id, participant.tagRole) ? (
                  <div className={styles.fullAccessContainer}>
                    <CheckboxComponent
                      id="FullAccess"
                      label="Full Access"
                      checked={participant.transactionAccess}
                      onChange={(e, v, checked) => onChangeAccess(checked, index)}
                      direction={CheckboxComponent.DIRECTION_RIGHT}
                      name="FullAccess"
                      labelTextClassName={styles.checkboxLabel}
                      className={styles.checkbox}
                    />
                    <FullAccessInfo />
                  </div>
                ) : null}
              </div>
            ) : (
              <></>
            ),
          )}
        </div>
        <div onClick={onAdd} className={styles.add}>
          <Icon className={styles.addIcon} variant={Icon.ADDPLAIN} />
          <div testid="invite_another" className={styles.addText}>
            {participantsValues.length < 1 ? 'Add' : 'Add another'}
          </div>
        </div>
      </AnswersContainer>
      {whoisReprentingBuyerQuestion && (
        <AnswersContainer className={styles.answersContainer}>
          <div className={classNames(styles.boxWrapper, styles.marginTop)}>
            <CheckboxComponent
              label="Dual Transaction"
              checked={preForm?.[transactionPreFormQuestionsIds.isTransactionDual]}
              onChange={(e, v, checked) =>
                dispatch(
                  setTransactionPreFormQuestionsEffect({
                    [transactionPreFormQuestionsIds.isTransactionDual]: checked,
                  }),
                )
              }
              direction={CheckboxComponent.DIRECTION_RIGHT}
              name="DualTransaction"
              labelTextClassName={styles.checkboxLabel}
              labelClassName={styles.noMarginB}
              className={styles.checkbox}
            />
          </div>
        </AnswersContainer>
      )}
      <ButtonsContainer buttonsContainerClassName={styles.buttonCon}>
        <Continue
          className={classNames(styles.continue)}
          onClick={handleContinue}
          isPending={isPending}
          disabled={!canContinue() || !fullAccess()}
        />
      </ButtonsContainer>
    </div>
  );
};

export default TransactionMembers;
