import { FormContainer, FormFooter, FormHeader } from 'pages/OnboardingV2Agent/components';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { AutoComplete, AutoCompleteProps } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { getListOfAvailableMlsServices } from 'api/mls';
import classNames from 'classnames';
import { Input } from 'components';
import { FieldArray, Form, Formik, FormikErrors, FormikTouched } from 'formik';
import { showErrorMessage } from 'helpers';
import parentStyles from 'pages/SignupV2/components/PersonalDetails/styles.module.scss';
import { CloseIcon, EditIcon } from './icons';
import styles from './styles.module.scss';
import { onBoardingSignUpEffect, userGetDataOnBackgroundEffect } from 'store/effects';
import { useDispatch, useSelector } from 'react-redux';
import { updateNewAgentEffect } from 'store/effects/subscription';
import { getUserDataSelector } from 'store/selectors/user';
import { BackIcon } from 'pages/SignupV2/components/icons';
import { AddRounded2 } from 'components/Icons';

type SetFormValues = (field: string, value: any, shouldValidate?: boolean) => void;

enum FormFields {
  MLS = 'mls',
}

type FormValues = {
  [key in FormFields]: any[];
};

const schema = Yup.object().shape({
  [FormFields.MLS]: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().required('MLS ID is required'),
      name: Yup.string().required('MLS Name is required'),
      brokerageName: Yup.string().required('Brokerage Name is required'),
      brokerageEmail: Yup.string().email('Invalid email').required('Broker Email is required'),
      managingBroker: Yup.string().required('Managing Broker is required'),
    }),
  ),
});

const initialValues = {
  [FormFields.MLS]: [
    {
      mlsId: '',
      id: '',
      name: '',
      brokerageName: '',
      brokerageEmail: '',
      managingBroker: '',
    },
  ],
} as FormValues;

type Props = {
  onNext: () => void;
  onPrev: () => void;
};

type InputGroupProps = {
  options: DefaultOptionType[] | undefined;
  onChange: (key: any, value: any, option: any, setFieldValue: SetFormValues) => void;
  onSelect: (key: any, data: any, setFieldValue: SetFormValues, prevValue: any) => void;
  onBlur: (key: any, setFieldTouched: any) => void;
  onSearch: (searchText: string) => void;
  getValue: (index: any, values: any) => any;
  values: FormValues;
  index: number;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  handleChange: any;
  touched: FormikTouched<FormValues>;
  errors: FormikErrors<FormValues>;
  isActive: boolean;
  onRemove: () => void;
  setIsEditIndex: (index: number) => void;
  setFieldTouched: any;
};
const InputGroup: React.FC<InputGroupProps> = ({
  options,
  onChange,
  onSelect,
  onSearch,
  onBlur,
  getValue,
  values,
  index,
  setFieldValue,
  handleChange,
  touched,
  errors,
  isActive = false,
  onRemove,
  setIsEditIndex,
  setFieldTouched,
}) => {
  const showExtraFields = values?.[FormFields.MLS]?.[index]?.name !== '';
  return (
    <div>
      <div
        className={classNames(parentStyles.inputContainer, styles.selectorWrapper, {
          [styles.inActive]: !isActive,
        })}
      >
        <AutoComplete
          options={options && options?.length > 0 && getValue(index, values) ? options : []}
          onChange={(values, options) =>
            onChange(`${FormFields.MLS}.[${index}].name`, values, options, setFieldValue)
          }
          onSelect={(data) => {
            const selectedOption = options?.filter((option) => option.value === data)[0];
            onSelect(
              `${FormFields.MLS}.[${index}]`,
              selectedOption,
              setFieldValue,
              values[FormFields.MLS][index],
            );
          }}
          onBlur={() => onBlur(`${FormFields.MLS}.[${index}].name`, setFieldTouched)}
          onSearch={(text) => onSearch(text)}
          value={getValue(index, values)}
          placeholder="Enter MLS"
          popupClassName={styles.dropdownWrapper}
        />
        {touched?.[FormFields.MLS]?.[index] && (errors?.[FormFields.MLS]?.[index] as any)?.name && (
          <div className={styles.error}>{(errors?.[FormFields.MLS]?.[index] as any)?.name}</div>
        )}
        {!isActive && (
          <div className={styles.actionBtns}>
            <div className={styles.iconBtn} onClick={() => setIsEditIndex(index)}>
              <EditIcon />
            </div>
            {values[FormFields.MLS].length > 1 && (
              <div className={styles.iconBtn} onClick={onRemove}>
                <CloseIcon />
              </div>
            )}
          </div>
        )}
      </div>
      {isActive && showExtraFields && (
        <div className={styles.inputGroups}>
          <div className={styles.inputContainer}>
            <Input
              type="text"
              name={`${FormFields.MLS}.[${index}].id`}
              value={values[FormFields.MLS][index].id}
              onChange={handleChange}
              className={styles.input}
              onBlur={() => onBlur(`${FormFields.MLS}.[${index}].id`, setFieldTouched)}
              error={
                touched?.[FormFields.MLS]?.[index]?.id
                  ? (errors?.[FormFields.MLS]?.[index] as any)?.id
                  : ''
              }
              errorClassName={styles.error}
              disabled={false}
              variant={Input.LIGHT_ROUND}
              label={'Your MLS ID'}
            />
            <Input
              type="text"
              name={`${FormFields.MLS}.[${index}].brokerageName`}
              value={values[FormFields.MLS][index].brokerageName}
              onChange={handleChange}
              className={styles.input}
              onBlur={() => onBlur(`${FormFields.MLS}.[${index}].brokerageName`, setFieldTouched)}
              error={
                touched?.[FormFields.MLS]?.[index]?.brokerageName
                  ? (errors?.[FormFields.MLS]?.[index] as any)?.brokerageName
                  : ''
              }
              errorClassName={styles.error}
              disabled={false}
              variant={Input.LIGHT_ROUND}
              label={'Brokerage Name'}
            />
          </div>
          <div className={styles.inputContainer}>
            <Input
              type="text"
              name={`${FormFields.MLS}.[${index}].managingBroker`}
              value={values[FormFields.MLS][index]?.managingBroker}
              onChange={handleChange}
              onBlur={() => onBlur(`${FormFields.MLS}.[${index}].managingBroker`, setFieldTouched)}
              className={styles.input}
              error={
                touched?.[FormFields.MLS]?.[index]?.managingBroker
                  ? (errors?.[FormFields.MLS]?.[index] as any)?.managingBroker
                  : ''
              }
              errorClassName={styles.error}
              disabled={false}
              variant={Input.LIGHT_ROUND}
              label={'Managing Broker Name'}
            />
            <Input
              type="text"
              name={`${FormFields.MLS}.[${index}].brokerageEmail`}
              value={values[FormFields.MLS][index]?.brokerageEmail}
              onChange={handleChange}
              className={styles.input}
              onBlur={() => onBlur(`${FormFields.MLS}.[${index}].brokerageEmail`, setFieldTouched)}
              error={
                touched?.[FormFields.MLS]?.[index]?.brokerageEmail
                  ? (errors?.[FormFields.MLS]?.[index] as any)?.brokerageEmail
                  : ''
              }
              errorClassName={styles.error}
              disabled={false}
              variant={Input.LIGHT_ROUND}
              label={'Managing Broker Email'}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export const MLS: React.FC<Props> = ({ onNext, onPrev }) => {
  const dispatch = useDispatch();

  const userId = useSelector(getUserDataSelector)?.Id;
  const [mlsServices, setMlsServices] = useState<AutoCompleteProps['options']>([]);
  const [options, setOptions] = useState<AutoCompleteProps['options']>([]);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [isPending, setIsPending] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  useEffect(() => {
    function handleError() {
      showErrorMessage("Cannot load list of available MLS'. Try to reload the page");
    }

    const runEffect = async () => {
      try {
        const response = await getListOfAvailableMlsServices();
        if (response.data?.success) {
          setMlsServices(
            response.data.result
              ?.filter((mls) => mls.IsVisible)
              .map((mls) => ({
                label: mls.Name,
                value: mls.Id,
              })),
          );
          setOptions(
            response.data.result
              ?.filter((mls) => mls.IsVisible)
              .map((mls) => ({
                label: mls.Name,
                value: mls.Id,
              })),
          );
        } else {
          handleError();
        }
      } catch (err) {
        handleError();
      }
    };

    runEffect();
  }, []);

  const onSubmit = (values: FormValues) => {
    setIsPending(true);
    dispatch(
      onBoardingSignUpEffect({ ...values }, {}, (err) => {
        setIsPending(false);
        if (!err) {
          dispatch(
            updateNewAgentEffect({ WizardFinished: true, Id: userId }, (err) => {
              dispatch(userGetDataOnBackgroundEffect({}, { showError: false }, (err) => onNext()));
            }),
          );
        }
      }),
    );
  };

  const onChange = (key, value, option, setFieldValue: SetFormValues) => {
    setFieldValue(key, value);
  };

  const onBlur = (key, setFieldTouched) => {
    setFieldTouched(key, true);
  };
  const onSelect = (
    key,
    data: { label: string; value: string },
    setFieldValue: SetFormValues,
    prevValue,
  ) => {
    setFieldValue(key, {
      ...prevValue,
      mlsId: data.value,
      name: data.label,
    });
  };

  const onSearch = (searchText: string) => {
    setOptions(
      mlsServices?.filter((mls) =>
        (mls.label as string)?.toLowerCase().startsWith(searchText.toLowerCase()),
      ),
    );
  };
  const getValue = (index, values) => {
    if (typeof values[FormFields.MLS]?.[index].name === 'number') {
      return options?.filter((mls) => mls.value === Number(values[FormFields.MLS]?.[index].name))[0]
        ?.label;
    } else return values[FormFields.MLS]?.[index].name;
  };

  return (
    <>
      <div className={styles.backBtn} onClick={onPrev}>
        <BackIcon />
      </div>
      <FormContainer>
        <div className={classNames(parentStyles.formContainer)}>
          <FormHeader title="Which MLS are you a member of?" />
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={onSubmit}
            validateOnBlur={true}
            validateOnChange={true}
            validateOnMount={false}
          >
            {(props) => {
              const removeLastItem = (arrayHelpers, isSubmit = false) => {
                const lastIndex = props.values[FormFields.MLS].length - 1;
                const lastEntry = props.values[FormFields.MLS][lastIndex];
                if (isSubmit && lastIndex === 0) {
                  return;
                }
                if (!lastEntry.name) {
                  arrayHelpers.remove(lastIndex);
                }
              };

              const onEditClick = (index, arrayHelpers) => {
                setActiveIndex(index);
                removeLastItem(arrayHelpers);
              };
              const showAddBtn =
                props.values?.[FormFields.MLS]?.[props.values[FormFields.MLS].length - 1]?.id !==
                '';

              return (
                <Form className={parentStyles.form}>
                  <FieldArray
                    name={FormFields.MLS}
                    render={(arrayHelpers) => (
                      <>
                        <InputGroup
                          key={`mls-input-0`}
                          getValue={getValue}
                          index={0}
                          onChange={onChange}
                          onSearch={onSearch}
                          onSelect={onSelect}
                          onBlur={onBlur}
                          options={options}
                          setFieldTouched={props.setFieldTouched}
                          setFieldValue={props.setFieldValue}
                          values={props.values}
                          handleChange={props.handleChange}
                          touched={props.touched}
                          errors={props.errors}
                          isActive={activeIndex === 0}
                          onRemove={() => {
                            if (props.values[FormFields.MLS].length === 1) return;
                            arrayHelpers.remove(0);
                          }}
                          setIsEditIndex={(index: number) => onEditClick(index, arrayHelpers)}
                        />
                        {props.values[FormFields.MLS] &&
                          props.values[FormFields.MLS].length > 1 &&
                          props.values[FormFields.MLS]
                            .slice(1)
                            .map((mls, index) => (
                              <InputGroup
                                key={`mls-input-${index + 1}`}
                                getValue={getValue}
                                index={index + 1}
                                onChange={onChange}
                                onSearch={onSearch}
                                onSelect={onSelect}
                                onBlur={onBlur}
                                options={options}
                                setFieldValue={props.setFieldValue}
                                setFieldTouched={props.setFieldTouched}
                                values={props.values}
                                handleChange={props.handleChange}
                                touched={props.touched}
                                errors={props.errors}
                                isActive={activeIndex === index + 1}
                                onRemove={() => arrayHelpers.remove(index + 1)}
                                setIsEditIndex={(index: number) => onEditClick(index, arrayHelpers)}
                              />
                            ))}
                        <div
                          className={classNames(styles.footContainer, {
                            [styles.justifyBetween]: showAddBtn,
                          })}
                        >
                          {showAddBtn && (
                            <div
                              className={styles.addAnother}
                              onClick={async () => {
                                setIsSubmitted(false);
                                const validationResult = await props.validateForm();
                                const lastInputGroup = props.values[FormFields.MLS].length - 1;
                                if (
                                  Object.keys(validationResult).length &&
                                  props.values[FormFields.MLS][lastInputGroup].id === ''
                                ) {
                                  //touch all fields of last input group
                                  props.setFieldTouched(
                                    `${FormFields.MLS}[${lastInputGroup}].id`,
                                    true,
                                  );
                                  props.setFieldTouched(
                                    `${FormFields.MLS}[${lastInputGroup}].name`,
                                    true,
                                  );
                                  props.setFieldTouched(
                                    `${FormFields.MLS}[${lastInputGroup}].brokerageName`,
                                    true,
                                  );
                                  props.setFieldTouched(
                                    `${FormFields.MLS}[${lastInputGroup}].managingBroker`,
                                    true,
                                  );
                                  props.setFieldTouched(
                                    `${FormFields.MLS}[${lastInputGroup}].brokerageEmail`,
                                    true,
                                  );
                                  return;
                                }

                                setActiveIndex(props.values[FormFields.MLS].length);
                                arrayHelpers.push({
                                  id: '',
                                  name: '',
                                  brokerageName: '',
                                  brokerageEmail: '',
                                  managingBroker: '',
                                });
                              }}
                            >
                              <AddRounded2 />
                              Add Another
                            </div>
                          )}
                          <FormFooter
                            isPending={isPending}
                            setIsSubmitted={setIsSubmitted}
                            onSubmitClick={() => removeLastItem(arrayHelpers, true)}
                          />
                        </div>
                      </>
                    )}
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
      </FormContainer>
    </>
  );
};
