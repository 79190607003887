import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { cloneDeep } from 'lodash-es';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { routes } from 'settings/navigation/routes';

import { getRequestQuoteSelector } from 'store/selectors/requestQuote';
import {
  setRequestQuotePartnersEffect,
  submitDraftQuoteEffect,
  updateQuoteDraftEffect,
} from 'store/effects/quotes';
import { getUserRolesMapSelector } from 'store/selectors/user';

import { Close } from 'components/Icons';
import Partner from './Partner';
import AddPartner from './AddPartner';
import Controls from '../PreForm/Controls';
import { ContentWrapper, Question } from 'pages/RequestQuote/components';
import { PartnerProfile } from 'pages/ServicesCategoryPartner/components/PartnerProfile';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { Continue } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/Continue';

import styles from './styles.module.scss';

const Partners = (props) => {
  const { className, list } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const { isClient } = useSelector(getUserRolesMapSelector);
  const { quoteData } = useSelector(getRequestQuoteSelector);
  const [isPending, setIsPending] = useState(false);
  const [selectedPartnerId, setSelectedPartnerId] = useState(null);
  const [viewPartnerDetails, setViewPartnerDetails] = useState(false);

  if (!list) return null;

  const onRemovePartner = (partner) => {
    const copyQuotePartners = cloneDeep(quoteData?.Partners);
    const partnerIndex = copyQuotePartners.findIndex(
      (quotePartner) => quotePartner?.Id === partner?.Id,
    );
    copyQuotePartners.splice(partnerIndex, 1);
    dispatch(setRequestQuotePartnersEffect({ partners: copyQuotePartners }));
  };

  const onSend = () => {
    if (isPending) return;
    setIsPending(true);
    dispatch(
      updateQuoteDraftEffect({}, { silent: true }, (err) => {
        if (!err) {
          return dispatch(
            submitDraftQuoteEffect({}, { silent: true }, () => {
              setIsPending(false);
              history.push(routes.servicesQuotes);
            }),
          );
        }
        setIsPending(false);
      }),
    );
  };

  const onClose = () => {
    history.replace(routes.services);
  };

  return (
    <>
      <div className={styles.head}>
        <Controls className={styles.controls} isClient={isClient} onPrev={() => history.goBack()} />
        <Close onClick={onClose} className={styles.closeIcon} />
      </div>
      <ContentWrapper className={classNames(styles.partners, className)}>
        <div testid="partners_list">
          <div className={styles.inner}>
            <Question>We are sending your quote request to:</Question>
            <AnswersContainer className={styles.partnersList}>
              {list.map((partner) => (
                <Partner
                  onClick={() => {
                    setSelectedPartnerId(partner?.Id);
                    setViewPartnerDetails(true);
                  }}
                  key={partner?.Id}
                  businessName={partner?.BusinessName}
                  logoUrl={partner?.LogoUrl}
                  action={
                    <Close onClick={() => onRemovePartner(partner)} className={styles.remove} />
                  }
                />
              ))}
              <AddPartner />
            </AnswersContainer>
          </div>
        </div>
        <ButtonsContainer>
          <Continue
            loading={isPending}
            className={classNames({ [styles.loadingBtn]: isPending }, styles.continueBtn)}
            disabled={!quoteData?.Partners?.length || isPending}
            onClick={onSend}
          />
        </ButtonsContainer>
      </ContentWrapper>
      <PartnerProfile
        partnerId={selectedPartnerId}
        open={viewPartnerDetails}
        setModalVisible={setViewPartnerDetails}
        onCancel={() => {
          setViewPartnerDetails(false);
          setSelectedPartnerId(null);
        }}
      />
    </>
  );
};

Partners.propTypes = {
  className: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

Partners.defaultProps = {
  className: '',
};

export default Partners;
