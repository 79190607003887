import PropTypes from 'prop-types';
import classNames from 'classnames';

import MatchItem from '../MatchItem';

import styles from './styles.module.scss';

const MatchItemsList = ({ items, insight, ...rest }) => {
  const matched = items.filter((item) => item.isMatched);
  const unmatched = items.filter((item) => !item.isMatched);
  const isFullLength = !matched?.length || !unmatched?.length;

  return (
    <div className={styles.matchItemsListContainer}>
      {!!matched?.length && (
        <div
          testid="matched_container"
          className={classNames(styles.matchContainer, {
            [styles.fullLengthContainer]: isFullLength,
          })}
        >
          {matched.map(
            ({ name, isMatched, matchLevel, dataSource, matchType, criteriaInsight }) => (
              <MatchItem
                name={name}
                isMatched={isMatched}
                matchLevel={matchLevel}
                matchType={matchType}
                dataSource={dataSource}
                key={name}
                insight={insight}
                criteriaInsight={criteriaInsight}
                {...rest}
              />
            ),
          )}
        </div>
      )}
      {!!unmatched?.length && (
        <div
          testid="unmatched_container"
          className={classNames(styles.matchContainer, {
            [styles.fullLengthContainer]: isFullLength,
          })}
        >
          {unmatched.map(({ name, isMatched, matchLevel, dataSource, criteriaInsight }) => (
            <MatchItem
              name={name}
              isMatched={isMatched}
              matchLevel={matchLevel}
              dataSource={dataSource}
              key={name}
              insight={insight}
              criteriaInsight={criteriaInsight}
              {...rest}
            />
          ))}
        </div>
      )}
    </div>
  );
};

MatchItemsList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  insight: PropTypes.bool,
};

MatchItemsList.defaultProps = {
  items: null,
  insight: false,
};

export default MatchItemsList;
