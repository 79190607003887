export enum LedgerLineItemTypeId {
  BalancingLineItem = 1,
  TransactionCommission = 2,
  TransactionCommissionPaymentSplit = 3,
  TransactionFeeDeduction = 4,
  TransactionFeeAddition = 5,
  GeneralExpense = 6,
  GeneralExpenseLiability = 7,
}

export enum LedgerAccountTypeId {
  Income = 1,
  Liability = 2,
  Asset = 3,
  Expense = 4,
}

export enum LedgerAmountTypeId {
  Number = 1,
  Percent = 2,
}

export enum LedgerTransactionSideId {
  Listing = 1,
  Purchase = 2,
}

export enum LedgerAccountId {
  CommissionIncome = 1,
  ClientFeeIncome = 2,
  UnallocatedSplits = 3,
  AgentPayment = 4,
  TeamPayment = 5,
  BrokeragePayment = 6,
  CustomAccountPayment = 7,
  OutboundPayment = 8,
  GeneralExpense = 9,
  GeneralExpensePaymentLiability = 10,
}

export enum LedgerNetsTitle {
  AGENT_NET = 'Agent Net',
  TEAM_NET = 'Team Net',
  BROKERAGE_NET = 'Brokerage Net',
}
