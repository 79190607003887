import { get } from 'lodash-es';
import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import {
  createCampaignAction,
  getCampaignByIdAction,
  getCampaignPublishedKitsWithStatsAction,
  getCampaignTaskAction,
  getCampaignTaskMembersAction,
  getCampaignsAction,
  getPublishedKitsAction,
  resetSelectedCampaignAction,
  setSelectedPlanIdAction,
  updateCampaignAction,
  updateCampaignTaskAction,
} from 'store/actions/radarKits';
import { RadarKitsInitialState } from './types';

const initialState: RadarKitsInitialState = {
  publishedKits: {
    state: IDLE,
    data: null,
    meta: {},
  },
  campaigns: {
    state: IDLE,
    data: null,
    meta: {},
  },
  library: {
    state: IDLE,
    data: null,
    meta: {},
  },
  selectedCampaign: {
    state: IDLE,
    data: null,
    meta: {},
  },
  selectedLibraryKitId: null,
  campaignTask: {
    state: IDLE,
    data: null,
    meta: {},
  },
  campaignTaskMembers: {
    state: IDLE,
    data: null,
    meta: {},
  },
};

export default handleActions(
  {
    [resetSelectedCampaignAction.toString()]: (state, { payload }) => {
      return {
        ...state,
        selectedCampaign: initialState.selectedCampaign,
        selectedLibraryKitId: initialState.selectedLibraryKitId,
        campaignTask: initialState.campaignTask,
        campaignTaskMembers: initialState.campaignTaskMembers,
      };
    },
    [getPublishedKitsAction.toString()]: (state, { payload }) => ({
      ...state,
      publishedKits: {
        ...state.publishedKits,
        state: get(payload, 'state'),
        data: get(payload, 'data', null),
        meta: get(payload, 'meta'),
      },
    }),
    [createCampaignAction.toString()]: (state, { payload }) => {
      return {
        ...state,
        //   publishedKits: {
        //     ...state.publishedKits,
        //     state: get(payload, 'state'),
        //     data: get(payload, 'data', null),
        //     meta: get(payload, 'meta'),
        //   },
      };
    },
    [getCampaignsAction.toString()]: (state, { payload }) => ({
      ...state,
      campaigns: {
        ...state.campaigns,
        state: get(payload, 'state'),
        data: get(payload, 'data.[result]', null),
        meta: get(payload, 'meta'),
      },
    }),
    [getCampaignPublishedKitsWithStatsAction.toString()]: (state, { payload }) => ({
      ...state,
      library: {
        ...state.library,
        state: get(payload, 'state'),
        data: get(payload, 'data', null),
        meta: get(payload, 'meta'),
      },
    }),
    [setSelectedPlanIdAction.toString()]: (state, { payload }) => {
      return {
        ...state,
        selectedLibraryKitId: payload as unknown as number,
      };
    },
    [getCampaignByIdAction.toString()]: (state, { payload }) => {
      return {
        ...state,
        selectedCampaign: {
          ...state.selectedCampaign,
          state: get(payload, 'state'),
          data: get(payload, 'data.result', null),
          meta: get(payload, 'meta'),
        },
      };
    },
    [updateCampaignAction.toString()]: (state, { payload }) => {
      return {
        ...state,
        selectedCampaign: {
          ...state.selectedCampaign,
          state: get(payload, 'state'),
          data: get(payload, 'data.result', null),
          meta: get(payload, 'meta'),
        },
      };
    },
    [getCampaignTaskAction.toString()]: (state, { payload }) => {
      return {
        ...state,
        campaignTask: {
          ...state.campaignTask,
          state: get(payload, 'state'),
          data: get(payload, 'data', null),
          meta: get(payload, 'meta'),
        },
      };
    },
    [updateCampaignTaskAction.toString()]: (state, { payload }) => {
      return {
        ...state,
        campaignTask: {
          ...state.campaignTask,
          data: get(payload, 'data', state?.campaignTask?.data),
        },
      };
    },
    [getCampaignTaskMembersAction.toString()]: (state, { payload }) => {
      return {
        ...state,
        campaignTaskMembers: {
          ...state.campaignTaskMembers,
          state: get(payload, 'state'),
          data: get(payload, 'data', null),
          meta: get(payload, 'meta'),
        },
      };
    },
  },
  initialState,
);
