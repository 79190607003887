import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';

interface ICopyToClipboardProps {
  icon?: any;
  textToCopy: string;
  className?: string;
}

const CopyToClipboard: React.FC<ICopyToClipboardProps> = ({ textToCopy, icon, className }) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const timeoutId = useRef<ReturnType<typeof setTimeout>>();

  const timeoutHandler = useCallback(() => {
    setIsCopied(false);
    if (timeoutId.current) clearTimeout(timeoutId.current);
  }, []);

  const handleClick = useCallback(() => {
    navigator.clipboard.writeText(textToCopy);
    setIsCopied(true);
    timeoutId.current = setTimeout(timeoutHandler, 3000);
  }, [textToCopy]);

  useEffect(() => {
    return () => {
      // cleanup function for setTimeout to avoid memory leakage
      if (timeoutId.current) clearTimeout(timeoutId.current);
    };
  }, []);

  if (isCopied) {
    return (
      <div className={classNames(styles.iconWrapper, className)}>
        <div className={styles.successMsg}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_61141_398717)">
              <path
                d="M3.33203 7.99935L6.66536 11.3327L13.332 4.66602"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_61141_398717">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span>Text Copied</span>
        </div>
      </div>
    );
  }

  return (
    <div onClick={handleClick} className={classNames(styles.iconWrapper, className)}>
      {icon ? (
        icon
      ) : (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_60956_403016)">
            <path
              d="M10.6367 4L17.364 4C17.8944 4 18.4031 4.21071 18.7782 4.58579C19.1533 4.96086 19.364 5.46957 19.364 6V15C19.364 15.5304 19.1533 16.0391 18.7782 16.4142C18.4031 16.7893 17.8944 17 17.364 17H10.6367C10.1063 17 9.59758 16.7893 9.22251 16.4142C8.84743 16.0391 8.63672 15.5304 8.63672 15V6C8.63672 5.46957 8.84743 4.96086 9.22251 4.58579C9.59758 4.21071 10.1063 4 10.6367 4Z"
              stroke="#676767"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.364 17V19C15.364 19.5304 15.1533 20.0391 14.7782 20.4142C14.4031 20.7893 13.8944 21 13.364 21H6.63672C6.10629 21 5.59758 20.7893 5.22251 20.4142C4.84743 20.0391 4.63672 19.5304 4.63672 19V10C4.63672 9.46957 4.84743 8.96086 5.22251 8.58579C5.59758 8.21071 6.10629 8 6.63672 8H8.63672"
              stroke="#676767"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_60956_403016">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
    </div>
  );
};

export default CopyToClipboard;
