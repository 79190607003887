import { FC, ReactElement, SVGAttributes } from 'react';

export const FrameIcon: FC<SVGAttributes<SVGElement>> = (
  props: SVGAttributes<SVGElement>,
): ReactElement => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="4" y="4" width="16" height="16" rx="2.50001" stroke="#676767" strokeWidth="2" />
    <path
      d="M9.5 11.5C10.6046 11.5 11.5 10.6046 11.5 9.5C11.5 8.39543 10.6046 7.5 9.5 7.5C8.39543 7.5 7.5 8.39543 7.5 9.5C7.5 10.6046 8.39543 11.5 9.5 11.5Z"
      stroke="#676767"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 20L14.2929 12.7071C14.6834 12.3166 15.3166 12.3166 15.7071 12.7071L20 17"
      stroke="#676767"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
