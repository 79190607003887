import { useState, useEffect, useMemo, Fragment } from 'react';
import classNames from 'classnames';

import prettyBytes from 'pretty-bytes';
import { useSelector } from 'react-redux';
import { Input } from 'components';
import { Select, Option, OptionType } from 'components-antd';
import { Checkbox } from 'antd';
import Icon from 'pages/Workshop/Transactions/TransactionCreate/Icons';
import { DocumentIcons } from 'pages/ClientProfile/Documents/Icons/index';
import { AgentDocumentTypes } from 'types';

import { getAggregatePageTransactionsSelector } from 'store/selectors/transactions';
import { PermissionSelector, selectJustMeOption, selectAllOption } from '../../PermissionSelector';

import styles from './styles.module.scss';
import { getUserId } from 'store/selectors/user';

export enum CategoryOptions {
  MISC = 'MISC',
  PREAPPROVALS = 'PREAPPROVALS',
  TRANSACTION = 'TRANSACTION',
}

const ItemClientProfile = ({
  size,
  filename,
  TransactionOrFolder = '',
  onDelete,
  documentName,
  onChangeDocumentName,
  type,
  shareWithClient = false,
  onChangeType,
  onChangeTransactionOrFolder,
  onChangeShareWithClient,
  onChangePermittedUsers,
}) => {
  const loggedInUserId = useSelector(getUserId);
  const { transactions: transactions_ } = useSelector(getAggregatePageTransactionsSelector);
  const [permissionOptions, setPermissionOptions] = useState<OptionType[]>([]);
  const [permittedUsers, setPermittedUsers] = useState<string[]>([]);
  const [selectedTransaction, setSelectedTransaction] = useState<any>(null);

  const updatePermittedUsers = (users) => {
    onChangePermittedUsers(
      null,
      users.map((item) => (item === '!' ? loggedInUserId : item)).filter((item) => item !== '*'),
    );
  };

  useEffect(() => {
    const participantIds = permissionOptions.map((user) => user.key);
    setPermittedUsers([...participantIds, selectJustMeOption.value, selectAllOption.value]);
    updatePermittedUsers(participantIds);
  }, [permissionOptions]);

  const transactions = useMemo(
    () =>
      transactions_?.map((item) => ({
        label: item?.address,
        value: item?.Id,
      })),
    [],
  );

  const getParticipantsWithUniqueEmails = () => {
    const uniqueEmails = new Set();
    const participantsWithUniqueEmails = selectedTransaction?.Participants?.filter((obj) => {
      if (!uniqueEmails.has(obj.Email)) {
        uniqueEmails.add(obj.Email);
        return true;
      }
      return false;
    });

    return participantsWithUniqueEmails;
  };

  useEffect(() => {
    if (selectedTransaction?.Participants?.length) {
      setPermissionOptions(
        getParticipantsWithUniqueEmails()?.map((participant) => ({
          key: participant.Id || participant.Email,
          value: participant.Id || participant.Email,
          label:
            participant.FirstName === '' && participant.name !== ''
              ? `${participant.name}`
              : `${participant.FirstName} ${participant.LastName}`,
        })),
      );
    }
  }, [selectedTransaction]);

  return (
    <div className={styles.itemVaultContainer}>
      <div className={styles.contentWrapper}>
        <div className={styles.uploadedFile}>
          <div className={styles.fileName}>
            <Icon className={styles.icon} variant={Icon.FILE_SIMPLE} />
            <div className={styles.filename}>
              <span testid="file_name" className={styles.fileTitle}>
                {filename}
              </span>
              <span testid="size" className={styles.fileSize}>
                ({prettyBytes(size)})
              </span>
            </div>
          </div>
          <Icon
            testid="delete_icon"
            onClick={onDelete}
            className={styles.iconDelete}
            variant={Icon.DELETE}
          />
        </div>
      </div>
      <div>
        <p className={styles.label}>Name</p>
        <Input
          variant={Input.LIGHT_ROUNDED}
          placeholder="Document name"
          onChange={onChangeDocumentName}
          value={documentName}
          className={styles.input}
          testid="document_name"
          maxLength={50}
          inputClassName={styles.marginBottom}
        />
      </div>
      <div>
        <p className={styles.label}>Category</p>
        <Select
          placeholder="Select Category"
          className={styles.typeSelectWrapper}
          dropdownStyle={{ zIndex: 10000 }}
          value={type}
          onChange={(value) => onChangeType(null, value)}
          popupClassName={styles.categoryPopover}
        >
          <Option value={CategoryOptions.MISC} className={styles.option}>
            <DocumentIcons type={AgentDocumentTypes.Misc} />
            Misc.
          </Option>
          <Option value={CategoryOptions.PREAPPROVALS} className={styles.option}>
            <DocumentIcons type={AgentDocumentTypes.PreApprovals} />
            Pre-Approvals
          </Option>
          <Option value={CategoryOptions.TRANSACTION} className={styles.option}>
            <DocumentIcons type={AgentDocumentTypes.Transactions} />
            Transaction
          </Option>
        </Select>
      </div>
      {type === CategoryOptions.TRANSACTION ? (
        <Fragment>
          <div>
            <p className={styles.label}>Address</p>
            <Select
              placeholder="Select Address"
              options={transactions}
              className={classNames(styles.typeSelectWrapper)}
              dropdownStyle={{ zIndex: 10000 }}
              value={TransactionOrFolder}
              onChange={(value) => {
                onChangeTransactionOrFolder(null, value);
                const selectedTransaction = transactions_?.find((item) => item?.Id === value);
                setSelectedTransaction(selectedTransaction);
                setPermissionOptions([]);
                setPermittedUsers([]);
              }}
              popupClassName={styles.categoryPopover}
            />
          </div>
          <div>
            <p className={styles.label}>Permission</p>
            <PermissionSelector
              permissionOptions={permissionOptions}
              permittedUsers={permittedUsers}
              setPermittedUsers={(users) => {
                setPermittedUsers(users);
                updatePermittedUsers(users);
              }}
              permissionSelectClassName={styles.permissionSelect}
            />
          </div>
        </Fragment>
      ) : (
        <></>
        // <Checkbox
        //   onChange={(e) => {
        //     onChangeShareWithClient(null, e.target.checked);
        //   }}
        //   className={classNames(styles.checkbox, 'mosaikCheckbox')}
        //   value={shareWithClient}
        //   checked={shareWithClient}
        // >
        //   Share with Client
        // </Checkbox>
      )}
    </div>
  );
};

export default ItemClientProfile;
