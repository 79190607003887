import { useEffect, useState } from 'react';
import { LocalHeader } from './components/LocalHeader';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { requestScheduleToursEffect } from 'store/effects/tour';
import { UpcomingAndRequested } from './components/UpcomingAndRequested';
import { Archive } from './components/Archive';
import { getScheduledTours } from 'store/selectors/tour';
import { Wrapper } from 'components';

const Tours = () => {
  const [isArchive, setIsArchive] = useState(false);
  const dispatch = useDispatch();
  const { isPending } = useSelector(getScheduledTours);

  useEffect(() => {
    dispatch(requestScheduleToursEffect());
  }, []);
  const render = () => {
    if (isArchive) {
      return <Archive isArchive={isArchive} setIsArchive={setIsArchive} />;
    }
    return <UpcomingAndRequested isArchive={isArchive} setIsArchive={setIsArchive} />;
  };
  return (
    <div className={styles.clientTours}>
      <LocalHeader isArchive={isArchive} setIsArchive={setIsArchive} />
      <Wrapper className={styles.content} isPending={isPending}>
        <div className={styles.main}>{render()}</div>
      </Wrapper>
    </div>
  );
};
export default Tours;
