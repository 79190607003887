import moment from 'moment';
import classNames from 'classnames';

import { FormCreatedByType, HistoryLogsType, HistoryLogType } from 'types';
import { Avatar } from 'components/Icons';
import { Avatar as AntAvatar } from 'components-antd';
import { historyActionSentences } from 'app-constants';
import { Row, Col } from 'components-antd';
import { Icons, IconVariant } from 'pages/Workshop/Forms/Icons';

import styles from './styles.module.scss';

interface HistoryProps {
  history: HistoryLogsType;
  createdBy: FormCreatedByType;
  isFormOwner?: boolean;
}

export const History = ({ history, createdBy, isFormOwner }: HistoryProps) => {
  const renderSimpleRow = (val: HistoryLogType) => {
    const logAction = historyActionSentences[val.Action];
    const ActionItem = (
      <span
        className={classNames({
          [styles.marginLeft]: logAction?.hideUser,
        })}
      >
        {`${logAction?.text}`}
        {logAction?.showUserInfo ? (
          <span>
            to <b>{val.name}</b> ({val.email})
          </span>
        ) : null}
      </span>
    );

    return (
      <Row className={styles.contentRowSimple} key={`${val.Id}`}>
        <Col xs={17} sm={17} md={17} lg={17} xl={17} className={styles.user}>
          <div className={styles.userAvatar}>
            {logAction?.hideUser ? (
              <></>
            ) : logAction?.iconVariant ? (
              <Icons variant={logAction?.iconVariant as IconVariant} />
            ) : val.avatar ? (
              <AntAvatar src={val.avatar} size={32} />
            ) : (
              <Avatar visibility={val.name ? 'visible' : 'hidden'} width={32} height={32} />
            )}
          </div>

          <div className={styles.simpleHeading}>
            {logAction?.hideUser ? (
              <></>
            ) : (
              <b>
                {val.isYou || (logAction?.checkOwner && isFormOwner)
                  ? 'You '
                  : logAction?.checkOwner && !isFormOwner && createdBy
                  ? `${createdBy.FirstName} ${createdBy.LastName} `
                  : val.name
                  ? `${val.name} `
                  : 'Mosaik '}
              </b>
            )}

            {historyActionSentences[val.Action]?.textBold ? <b>{ActionItem}</b> : ActionItem}
          </div>
        </Col>

        <Col xs={7} sm={7} md={7} lg={7} xl={7} className={styles.time}>
          {moment(val.CreatedDate).format('MM/DD [at] hh:mm A')}
        </Col>
      </Row>
    );
  };

  return <div className={styles.historyContent}>{history.map((val) => renderSimpleRow(val))}</div>;
};
