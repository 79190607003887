import { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { getAgentTeamIsActiveSelector } from 'store/selectors/agentTeamDetail';
import { Select } from 'components-antd';
import { AccessInfo } from './AccessInfo';
import { updateTransactionParticipantAccessEffect } from 'store/effects/transactions';
import { showSuccessMessage } from 'helpers';
import { TransactionAccessType, TransactionUserRole } from 'settings/constants/transaction';
import { getTransactionParticipant } from 'store/selectors/drawers/transactionParticipant';
import { getTransactionAccessSelector } from 'store/selectors/transaction';
import { getTeamMemberExistance } from 'store/selectors/teamList';
import { requestGetTeamListEffect } from 'store/effects';
import { getUserId } from 'store/selectors/user';

import styles from './styles.module.scss';

export const TransactionAccess = (props) => {
  const { className } = props;
  const userId = useSelector(getUserId);
  const { participant } = useSelector(getTransactionParticipant);
  const { fullAccess } = useSelector(getTransactionAccessSelector);
  const isTeamAgentActive = useSelector(getAgentTeamIsActiveSelector);

  const {
    Id,
    TransactionAccess,
    TransactionRole: { Title },
  } = participant;
  const { isParticipantTeamMember } = useSelector(getTeamMemberExistance(Id));

  const dispatch = useDispatch();

  const onChangeAccess = (val) => {
    dispatch(
      updateTransactionParticipantAccessEffect(
        {
          participantId: Id,
          TransactionAccess: val === TransactionAccessType.Full,
        },
        null,
        (err) => {
          if (!err) showSuccessMessage(`${val} access granted!`);
        },
      ),
    );
  };

  const getTeamMembers = () => dispatch(requestGetTeamListEffect({ Status: 'Accepted' }));

  useEffect(() => {
    getTeamMembers();
  }, []);

  return (
    <>
      {fullAccess && isTeamAgentActive && isParticipantTeamMember && userId !== participant?.Id ? (
        <div className={classNames(styles.transactionAccess, className)}>
          <div>
            <p className={styles.label}>Transaction Access</p>
            <AccessInfo />
          </div>
          <Select
            className={styles.accessSelect}
            style={{ width: 176 }}
            defaultValue={
              TransactionAccess ? TransactionAccessType.Full : TransactionAccessType.Limited
            }
            bordered={false}
            onChange={onChangeAccess}
            options={[
              {
                value: TransactionAccessType.Full,
                label: TransactionAccessType.Full,
              },
              {
                value: TransactionAccessType.Limited,
                label: TransactionAccessType.Limited,
              },
            ]}
          />
        </div>
      ) : null}
    </>
  );
};

TransactionAccess.propTypes = {
  className: PropTypes.string,
  access: PropTypes.bool.isRequired,
  participantId: PropTypes.number.isRequired,
};

TransactionAccess.defaultProps = {
  className: '',
};
