import classNames from 'classnames';
import { useOutsideClick } from 'hooks';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SEARCH_INSTANCE_STATUS } from 'app-constants';
import { getSearchInstancesEffect } from 'store/effects';
import { getCurrentContextSelector } from 'store/selectors/context';
import { getSearchInstancesFormattedSelector } from 'store/selectors/mySearches';
import { getUserRolesMapSelector } from 'store/selectors/user';

import { Popover } from 'components-antd';
import { Icons } from '../../../../../common/Icons';
import { TabButton } from '../../ClientHeader';

import { sortFeedResetAction } from 'store/actions/feed';
import { selectedSearchAction } from 'store/actions/feedv3';
import { getFeedMetaSelectorV3 } from 'store/selectors/feedv3';
import { subFilterTypes } from 'settings/constants/properties';

import styles from './styles.module.scss';
import { setSubFilterTypeAction } from 'store/actions/feed';

type Props = {
  isSavedSearchResult?: boolean;
  setShowBlurBg: Function;
  handleOpenSearch: (showSavedSearches: boolean) => void;
};

export const SavedSearches: React.FC<Props> = ({ setShowBlurBg, handleOpenSearch }) => {
  const dispatch = useDispatch();
  const { isClient } = useSelector(getUserRolesMapSelector);
  const { data } = useSelector(getSearchInstancesFormattedSelector);
  const { selectedSearch } = useSelector(getFeedMetaSelectorV3);
  const ActiveSavedSearches = data?.filter(
    ({ status }) => status === SEARCH_INSTANCE_STATUS.ACTIVE,
  );
  const searchContext = useSelector(getCurrentContextSelector);
  const cfg = { contextKey: searchContext?.ContextKey };

  const [openPopover, setOpenPopover] = useState(false);
  const ref = useRef(null);

  const closePopover = () => {
    setOpenPopover(false);
    setShowBlurBg(false);
  };

  useOutsideClick([ref], () => closePopover());

  useEffect(() => {
    if (selectedSearch) {
      dispatch(
        getSearchInstancesEffect(cfg, {}, (err, result) => {
          if (!err) {
            const data = result?.data?.result?.filter(
              (item) => item?.Status === SEARCH_INSTANCE_STATUS.ACTIVE,
            )?.[0];
            const item = {
              id: data?.Id,
              name: data?.Name,
              criterias: data?.DefaultPropertySearchPreferences,
            };
            dispatch(selectedSearchAction({ selectedSearch: item }));
          }
        }),
      );
    }
  }, [searchContext?.ContextKey]);

  useEffect(() => {
    if (isClient && searchContext && !selectedSearch) {
      dispatch(
        getSearchInstancesEffect(cfg, {}, (err, result) => {
          if (!err && !selectedSearch) {
            const data = result?.data?.result?.filter(
              (item) => item?.Status === SEARCH_INSTANCE_STATUS.ACTIVE,
            )?.[0];
            const item = {
              id: data?.Id,
              name: data?.Name,
              criterias: data?.DefaultPropertySearchPreferences,
            };
            dispatch(selectedSearchAction({ selectedSearch: item }));
          }
        }),
      );
    }
  }, [isClient, searchContext, selectedSearch]);

  const onSelectSearch = (item) => {
    closePopover();
    dispatch(setSubFilterTypeAction(subFilterTypes.ALL));
    dispatch(sortFeedResetAction());
    dispatch(selectedSearchAction({ selectedSearch: item }));
  };

  const viewAllClick = () => {
    closePopover();
    handleOpenSearch(true);
  };

  const PopoverContent = (
    <div className={styles.searchesContainer} ref={ref}>
      <div className={styles.header}>
        <span className={styles.title}>Saved Searches</span>
        <span className={styles.viewAll} onClick={viewAllClick}>
          View all
        </span>
      </div>
      {ActiveSavedSearches?.map((item, idx) => (
        <div
          key={idx}
          className={styles.searchOptionContainer}
          onClick={() => onSelectSearch(item)}
        >
          <div className={styles.searchOption}>
            <Icons variant={Icons.BOOKMARK} />
            <span>{item?.name}</span>
          </div>
          {item?.id === selectedSearch?.id && <Icons variant={Icons.CHECKMARK} />}
        </div>
      ))}
      <div
        className={styles.searchOptionContainer}
        onClick={() => {
          closePopover();
          handleOpenSearch(false);
        }}
      >
        <div className={styles.searchOption}>
          <Icons variant={Icons.PLUS} />
          <span>Start a New Search</span>
        </div>
      </div>
    </div>
  );

  const TabButtonText = () => (
    <div className={classNames(styles.tabBtnText, { [styles.rotateIcon]: openPopover })}>
      {selectedSearch?.name ? (
        <>
          <span className={styles.text}>{selectedSearch?.name}</span>
          <Icons variant={Icons.CARET_DOWN} className={styles.icon} />
        </>
      ) : (
        'None'
      )}
    </div>
  );

  return (
    <Popover
      content={PopoverContent}
      overlayClassName={styles.optionsPopoverContent}
      trigger="click"
      autoAdjustOverflow={false}
      placement="bottomLeft"
      open={openPopover}
    >
      <TabButton
        text={<TabButtonText />}
        icon={Icons.BOOKMARK_DARK}
        onClick={() => {
          setOpenPopover(true);
          setShowBlurBg(true);
        }}
        className={styles.savedSearchBtn}
      />
    </Popover>
  );
};
