import { PageWrapper } from 'components-antd';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { SideBar } from './SideBar';
import { useCallback, useEffect, useState } from 'react';
import { getAgentDetailsEffect, getClientDetailsEffect } from 'store/effects';
import { getAgentDetailsSelector } from 'store/selectors/agentDetail';
import { getClientDetailsSelector } from 'store/selectors/clientDetail';
import { Wrapper } from 'components';
import { Tabs } from 'components-antd';
import { Icons } from './icons';
import classNames from 'classnames';
import { Activity } from './Activity';
import { Portfolio } from './Portfolio';
import { Kits } from './Kits';
import { ActiveSearches } from './ActiveSearches';
import { Transactions } from './Transactions';
import { useParams } from 'react-router-dom';
import { Role } from 'settings/constants/roles';
import { Documents } from './Documents';
import { get } from 'lodash-es';

export const ClientProfile = () => {
  const { id } = useParams() as any;
  const [isAgent, setIsAgent] = useState(false);
  const [selectedTab, setSelectedTab] = useState('1');
  const [isLoading, setIsLoading] = useState(true);
  const details = useSelector(isAgent ? getAgentDetailsSelector : getClientDetailsSelector);

  const contact = details?.data || {};
  const addresses = get(contact, ['Client', 'Address']) || [];
  const dispatch = useDispatch();

  const getConnectionEffect = () => (isAgent ? getAgentDetailsEffect : getClientDetailsEffect);

  const getConnectionDetails = useCallback(
    (id) => {
      setIsLoading(true);
      dispatch(
        getConnectionEffect()({ id }, {}, (err, res) => {
          if (!err) {
            setIsAgent(res?.data?.result?.Roles?.includes(Role.Agent));
          }
          setIsLoading(false);
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    getConnectionDetails(id);
  }, [id]);

  const tabs = [
    {
      key: '1',
      id: 'activity',
      label: (
        <span className={classNames(styles.tabLabel, { [styles.active]: selectedTab === '1' })}>
          <Icons variant={Icons.ACTIVITY} /> Activity
        </span>
      ),
      children: <Activity isAgent={isAgent} />,
    },
    // {
    //   key: '2',
    //   id: 'portfolio',
    //   label: (
    //     <span className={classNames(styles.tabLabel, { [styles.active]: selectedTab === '2' })}>
    //       <Icons variant={Icons.PORTFOLIO} /> Portfolio
    //     </span>
    //   ),
    //   children: <Portfolio clientId={Number(id)} />,
    // },
    {
      key: '3',
      id: 'kits',
      label: (
        <span className={classNames(styles.tabLabel, { [styles.active]: selectedTab === '3' })}>
          <Icons variant={Icons.KITS} /> KITs
        </span>
      ),
      children: <Kits clientId={Number(id)} />,
    },
    {
      key: '4',
      id: 'searches',
      label: (
        <span className={classNames(styles.tabLabel, { [styles.active]: selectedTab === '4' })}>
          <Icons variant={Icons.SEARCHES} /> Searches
        </span>
      ),
      children: (
        <ActiveSearches activeSearches={contact?.Client?.SearchInstances} clientId={Number(id)} />
      ),
    },
    {
      key: '5',
      id: 'transactions',
      label: (
        <span className={classNames(styles.tabLabel, { [styles.active]: selectedTab === '5' })}>
          <Icons variant={Icons.TRANSACTIONS} /> Transactions
        </span>
      ),
      children: <Transactions userId={id} />,
    },
    {
      key: '6',
      id: 'files',
      label: (
        <span className={classNames(styles.tabLabel, { [styles.active]: selectedTab === '6' })}>
          <Icons variant={Icons.FILES} /> Files
        </span>
      ),
      children: (
        <Documents userId={id} contactName={`${contact?.FirstName} ${contact?.LastName}`} />
      ),
    },
  ];

  return (
    <PageWrapper
      version={2}
      mainPageContentStyle={styles.mainPageContent}
      pageContentClassName={styles.pageContent}
      showFooter={false}
    >
      <SideBar contact={contact} isPending={isLoading} isAgent={isAgent} addresses={addresses} />
      <Wrapper isPending={isLoading} className={styles.content}>
        <Tabs
          className={styles.tabs}
          items={tabs as any}
          defaultActiveKey={selectedTab}
          onTabClick={(id) => {
            setSelectedTab(id);
          }}
        />
      </Wrapper>
    </PageWrapper>
  );
};
