import React from 'react';

import styles from './styles.module.scss';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';

type Props = {
  historyAttom: {
    change: string;
    changeType: string;
    month: number;
    year: number;
    value: number;
  }[];
};

export const Table: React.FC<Props> = ({ historyAttom }) => {
  const formatMonthYear = (month: number, year: number): string => {
    const date = new Date(year, month - 1); // Create a date object
    return date.toLocaleString('en-US', { month: 'short', year: 'numeric' }); // Format it as "Jan 2024"
  };

  return (
    <div className={styles.valuationTable}>
      <div className={styles.header}>
        <div className={styles.col}>Month</div>
        <div className={styles.subCols}>{historyAttom && <div>Attom</div>}</div>
      </div>
      <div className={styles.data}>
        {historyAttom.map((item) => (
          <div className={styles.row}>
            <div className={styles.col}>{formatMonthYear(item.month, item.year)}</div>
            <div className={styles.subCols}>
              <NumberFormat thousandSeparator displayType="text" value={item.value} prefix="$" />
              <span className={classNames(styles.change, styles[item.changeType])}>
                {item.change}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
