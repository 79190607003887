import * as yup from 'yup';

export const schema = yup.object().shape({
  address: yup
    .object()
    .test(
      'has-key',
      'Location is a required field',
      (value) => value && Object.keys(value).length > 0,
    )
    .required('Location is a required field'),
});
