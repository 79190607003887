import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { cloneDeep } from 'lodash-es';

import { priceConverter } from 'helpers';
import { DateRangeFilter } from 'pages/ClarityRevamped/Components/Filters/DateRangeFilter';
import { TableViewFilter } from 'pages/ClarityRevamped/Components/Filters/TableViewFilter';
import { StatusFilter } from '../../Components/StatusFilter';
import { Wrapper as PendingWrapper } from 'components';
import { useEffect, useState } from 'react';
import { ModalHeader } from '../../Components/ModalHeader/ModalHeader';
import { AggregateResultHeader } from '../../Components/ModalHeader';
import { BreakdownMenu } from '../../../../BreakdownMenu';
import TableView from '../../Components/TableView/TableView';
import CompareModal from '../../Components/CompareModals/CompareModal';
import { getAgentTeamStatsSelector, getTimePeriodSelector } from 'store/selectors/clarity';
import { useFetchVolumeStats } from 'pages/ClarityRevamped/hooks';
import { ClarityLineChart } from 'components/ClarityLineChart';
import { ClarityBarChart } from 'components/ClarityBarChart';
import { LineChart, BarChart } from 'components/Icons';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { TEAM_BASIC } from 'settings/constants/roles';
import styles from '../../styles.module.scss';

export const Expired = () => {
  const [isGraphView, setIsGraphView] = useState<boolean>(true);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [openCompareModal, setOpenCompareModal] = useState<boolean>(false);
  const [isPending, setIsPending] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [toggleGraph, setToggleGraph] = useState(false);
  const [summary, setSummary] = useState<any>({});
  const [data, setData] = useState([]);
  const [breakdownSummary, setBreakdownSummary] = useState<any>([]);
  const [breakdownList, setBreakdownList] = useState<any>([]);
  const [selectedAgent, setSelectedAgent] = useState<any>(null);
  const [teamSummary, setTeamSummary] = useState<any>([]);
  const timePeriod = useSelector(getTimePeriodSelector);
  const teamStats = useSelector(getAgentTeamStatsSelector);
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const color = '#FB913A';

  const getStats = useFetchVolumeStats();

  useEffect(() => {
    getStats({
      setSummary,
      setData,
      setIsPending,
      summaryType: 'Expired',
      aggregateDataFormat: isGraphView ? 'Series' : 'Tabular',
      year: null,
      quarter: null,
      month: null,
      isoWeek: null,
      date: null,
      transactionAgentUserId: selectedAgent?.TransactionAgentUserId,
      transactionAgentContactId: selectedAgent?.TransactionAgentContactId,
    });
  }, [timePeriod, isGraphView, selectedAgent]);

  useEffect(() => {
    if ('TotalVolume' in summary && firstRender && teamStats.itemValue === 'Team')
      setTeamSummary(summary);
  }, [summary]);

  useEffect(() => {
    if (firstRender && breakdownList?.length) {
      setOpenMenu(true);
      setFirstRender(false);
    } else if (!breakdownList?.length) setOpenMenu(false);
  }, [breakdownList]);

  useEffect(() => {
    getStats({
      setSummary: setBreakdownSummary,
      setData: () => {},
      setIsPending: () => {},
      summaryType: 'Expired',
      aggregateDataFormat: 'Series',
      year: null,
      quarter: null,
      month: null,
      isoWeek: null,
      date: null,
      agentSummary: true,
    });
  }, [timePeriod]);

  useEffect(() => {
    if (breakdownSummary.length && 'TotalVolume' in teamSummary) {
      if (agentRole === TEAM_BASIC || 'userId' in teamStats) {
        setBreakdownList([{ ...breakdownSummary[0], selected: true }]);
      } else if (!selectedAgent)
        setBreakdownList([
          {
            TransactionAgent: 'Team',
            Total: teamSummary?.TotalVolume || 0,
            NumberTransactions: teamSummary?.NumberTransactions || 0,
            Percentage: 100,
            selected: true,
          },
          ...breakdownSummary,
        ]);
      else {
        const newList = cloneDeep([
          {
            TransactionAgent: 'Team',
            Total: teamSummary?.TotalVolume || 0,
            NumberTransactions: teamSummary?.NumberTransactions || 0,
            Percentage: 100,
          },
          ...breakdownSummary,
        ]);
        newList[selectedAgent[1]] = { ...selectedAgent[0], selected: true };
        setBreakdownList(newList);
      }
    } else {
      setBreakdownList([]);
    }
  }, [breakdownSummary, teamSummary]);

  const handleClick = (agent, index) => {
    let newList;
    if (agentRole === TEAM_BASIC || 'userId' in teamStats) return;
    if (agent?.TransactionAgent === 'Team') {
      setSelectedAgent(null);
      newList = cloneDeep([
        {
          TransactionAgent: 'Team',
          Total: teamSummary?.TotalVolume || 0,
          NumberTransactions: teamSummary?.NumberTransactions || 0,
          Percentage: 100,
          selected: true,
        },
        ...breakdownSummary,
      ]);
    } else {
      setSelectedAgent(agent);
      newList = cloneDeep([
        {
          TransactionAgent: 'Team',
          Total: teamSummary?.TotalVolume || 0,
          NumberTransactions: teamSummary?.NumberTransactions || 0,
          Percentage: 100,
        },
        ...breakdownSummary,
      ]);
      newList[index] = { ...agent, selected: true };
    }
    setBreakdownList(newList);
  };

  const getGraph = () => {
    return toggleGraph || timePeriod === 'MTD' || timePeriod === 'WEEK' ? (
      <ClarityBarChart
        data={data}
        timePeriod={timePeriod}
        colors={[color]}
        title={'Expired'}
        selectedAgent={selectedAgent}
        clarityType={'Volume'}
        summaryType={'Expired'}
      />
    ) : (
      <ClarityLineChart
        data={data}
        timePeriod={timePeriod}
        colors={[color]}
        title={'Expired'}
        selectedAgent={selectedAgent}
        clarityType={'Volume'}
        summaryType={'Expired'}
      />
    );
  };

  return (
    <PendingWrapper isPending={false} className={styles.clarityContentWrapper}>
      <div className={styles.headerRows}>
        <ModalHeader title={'Expired'} />
        <div className={styles.infoRow}>
          <AggregateResultHeader
            totalAmount={priceConverter(summary?.TotalVolume || 0)}
            totalRequests={summary?.NumberTransactions}
            textColor={{ color: color }}
          />
          <div className={styles.filtersContainer}>
            <DateRangeFilter defaultOption={timePeriod} />
            <div
              className={classnames(styles.breakdownBtn, { [styles.openMenu]: openMenu })}
              onClick={() => setOpenMenu(!openMenu)}
            >
              Breakdown
            </div>
            {/* <StatusFilter options={['All', ...dropdownList]} cb={() => {}} /> */}
            <TableViewFilter
              setIsGraphView={setIsGraphView}
              openCompareModal={() => setOpenCompareModal(true)}
              setIsPending={setIsPending}
            />
          </div>
        </div>
      </div>
      <div className={styles.bodyRow}>
        <PendingWrapper
          isPending={isPending}
          className={classnames(
            styles.graphContainer,
            { [styles.openMenu]: isGraphView && openMenu },
            { [styles.tableContainer]: !isGraphView && openMenu },
          )}
        >
          {isGraphView ? (
            <div className={styles.graphContent}>
              {(timePeriod === 'YTD' || timePeriod === 'QTD') && (
                <div className={styles.graphToggleContainer}>
                  <div
                    className={styles.grpahToggleBtn}
                    onClick={() => setToggleGraph(!toggleGraph)}
                  >
                    {toggleGraph ? <LineChart /> : <BarChart />}
                  </div>
                </div>
              )}
              {getGraph()}
            </div>
          ) : (
            <TableView
              open={openMenu}
              data={data}
              title={'Expired'}
              selectedAgent={selectedAgent}
              clarityType={'Volume'}
              summaryType={'Expired'}
            />
          )}
        </PendingWrapper>
        <div className={classnames(styles.breakdownMenu, { [styles.openMenu]: openMenu })}>
          <BreakdownMenu
            open={openMenu}
            list={breakdownList}
            handleClick={handleClick}
            color={color}
            onClose={() => setOpenMenu(false)}
            title={'Expired'}
            selectedAgent={selectedAgent}
            clarityType={'Volume'}
            summaryType={'Expired'}
          />
        </div>
      </div>
      {/* <CompareModal open={openCompareModal} onClose={() => setOpenCompareModal(false)} /> */}
    </PendingWrapper>
  );
};
