import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Tabs } from 'components-antd';
import { Volume } from './Volume';
import { Clients } from './Clients';
import { Financials } from './Financials';
import { getAgentTeamIdSelector, getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import {
  getAgentTeamStatsSelector,
  getTimePeriodSelector,
  getTransactionFilter,
} from 'store/selectors/clarity';
import { postClarityFinancialData, postClarityVolumeData } from 'api/clarity';
import { useFetchVolumeStats } from 'pages/ClarityRevamped/hooks';
import { getUserId } from 'store/selectors/user';
import { TEAM_BASIC } from 'settings/constants/roles';

import styles from './styles.module.scss';

export const HeaderTabs = () => {
  const teamStats = useSelector(getAgentTeamStatsSelector);
  const timePeriod = useSelector(getTimePeriodSelector);
  const teamId = useSelector(getAgentTeamIdSelector);
  const transactionFilter = useSelector(getTransactionFilter);
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const userId = useSelector(getUserId);

  const [grossSummary, setGrossSummary] = useState([]);
  const [grossData, setGrossData] = useState([]);
  const [netSummary, setNetSummary] = useState([]);
  const [netData, setNetData] = useState([]);
  const [feesSummary, setFeesSummary] = useState([]);
  const [feesData, setFeesData] = useState([]);
  const [listingsSummary, setListingsSummary] = useState([]);
  const [listingsData, setListingsData] = useState([]);
  const [purchasesSummary, setPurchasesSummary] = useState([]);
  const [purchasesData, setPurchasesData] = useState([]);
  const [closedSummary, setClosedSummary] = useState([]);
  const [closedData, setClosedData] = useState([]);
  const [expiredSummary, setExpiredSummary] = useState([]);
  const [expiredData, setExpiredData] = useState([]);
  const [canceledSummary, setCanceledSummary] = useState([]);
  const [canceledData, setCanceledData] = useState([]);
  const [isPending, setIsPending] = useState(false);

  const fetchFinancialChartStats = async ({
    aggregationType,
    summaryType,
    getSummary,
    getData,
  }) => {
    const { data, status } = await postClarityFinancialData({
      aggregationType: aggregationType,
      summaryType: summaryType,
      teamId,
      timePeriod,
      aggregateDataFormat: 'Series',
      ...(agentRole === TEAM_BASIC && { transactionAgentUserId: userId }),
      ...(teamStats.itemValue !== 'Team' && { transactionAgentUserId: teamStats.userId }),
      financialOptions: ['ActualOnly'],
      optionalColumns: [],
      ...(transactionFilter && {
        filterByLedgerRepresentingSideId: transactionFilter,
      }),
    });
    if (status === 200) {
      getSummary(data.Summary);
      getData(data.Data);
    }
  };

  const getVolumeStats = useFetchVolumeStats();

  const getAllStats = async () => {
    setIsPending(true);

    await Promise.all([
      getVolumeStats({
        setSummary: setListingsSummary,
        setData: setListingsData,
        setIsPending: () => {},
        aggregateDataFormat: 'Series',
        summaryType: 'Listings',
        year: null,
        quarter: null,
        month: null,
        isoWeek: null,
        date: null,
      }),

      getVolumeStats({
        setSummary: setPurchasesSummary,
        setData: setPurchasesData,
        setIsPending: () => {},
        aggregateDataFormat: 'Series',
        summaryType: 'Purchases',
        year: null,
        quarter: null,
        month: null,
        isoWeek: null,
        date: null,
      }),

      getVolumeStats({
        setSummary: setClosedSummary,
        setData: setClosedData,
        setIsPending: () => {},
        aggregateDataFormat: 'Series',
        summaryType: 'Closed',
        year: null,
        quarter: null,
        month: null,
        isoWeek: null,
        date: null,
      }),

      getVolumeStats({
        setSummary: setExpiredSummary,
        setData: setExpiredData,
        setIsPending: () => {},
        aggregateDataFormat: 'Series',
        summaryType: 'Expired',
        year: null,
        quarter: null,
        month: null,
        isoWeek: null,
        date: null,
      }),

      getVolumeStats({
        setSummary: setCanceledSummary,
        setData: setCanceledData,
        setIsPending: () => {},
        aggregateDataFormat: 'Series',
        summaryType: 'Canceled',
        year: null,
        quarter: null,
        month: null,
        isoWeek: null,
        date: null,
      }),

      fetchFinancialChartStats({
        aggregationType: 'Gross',
        summaryType: 'CommissionSplits',
        getSummary: setGrossSummary,
        getData: setGrossData,
      }),

      fetchFinancialChartStats({
        aggregationType: 'Net',
        summaryType: 'CommissionSplits',
        getSummary: setNetSummary,
        getData: setNetData,
      }),

      fetchFinancialChartStats({
        aggregationType: 'Net',
        summaryType: 'FeeSummary',
        getSummary: setFeesSummary,
        getData: setFeesData,
      }),
    ]).then(() => setIsPending(false));
  };

  useEffect(() => {
    if (teamId) {
      getAllStats();
    }
  }, [teamStats, timePeriod, teamId, transactionFilter]);

  const tabItems = [
    // {
    //   key: '1',
    //   label: 'Volume',
    //   children: (
    //     <Volume
    //       listingsSummary={listingsSummary}
    //       listingsData={listingsData}
    //       underContractSummary={underContractSummary}
    //       underContractData={underContractData}
    //       closedSummary={closedSummary}
    //       closedData={closedData}
    //       expiredSummary={expiredSummary}
    //       expiredData={expiredData}
    //       canceledSummary={canceledSummary}
    //       canceledData={canceledData}
    //       isPending={isPending}
    //     />
    //   ),
    // },
    // {
    //   key: '2',
    //   label: 'Clients',
    //   children: <Clients />,
    //   disabled: true,
    // },
    {
      key: '1',
      label: 'Volume',
      children: (
        <Volume
          listingsSummary={listingsSummary}
          listingsData={listingsData}
          purchasesSummary={purchasesSummary}
          purchasesData={purchasesData}
          closedSummary={closedSummary}
          closedData={closedData}
          expiredSummary={expiredSummary}
          expiredData={expiredData}
          canceledSummary={canceledSummary}
          canceledData={canceledData}
          isPending={isPending}
        />
      ),
    },
    // {
    //   key: '2',
    //   label: 'Clients',
    //   children: <Clients />,
    //   disabled: true,
    // },
    {
      key: '2',
      label: 'Financials',
      children: (
        <Financials
          grossSummary={grossSummary}
          grossData={grossData}
          netSummary={netSummary}
          netData={netData}
          feesSummary={feesSummary}
          feesData={feesData}
          isPending={isPending}
        />
      ),
    },
  ];

  return <Tabs className={styles.headerTabs} defaultActiveKey="1" items={tabItems} />;
};
