import { Input, InputLabel } from 'components';

import styles from './styles.module.scss';

interface ContactFieldProps {
  placeholder?: string;
  value: string;
  label: string;
  error?: string;
  onChange: (value: string) => void;
  required?: boolean;
  disabled?: boolean;
}

export const ContactField = ({
  placeholder,
  value,
  label,
  error,
  onChange,
  required = false,
  disabled = false,
}: ContactFieldProps) => {
  const handleInputChange = (e) => {
    const newValue = e.target.value;
    onChange(newValue);
  };

  return (
    <div className={styles.contactField}>
      <div className={styles.fieldWrapper}>
        <div className={styles.labelContainer}>
          <InputLabel label={label} />
          {required && <span className={styles.asterik}>*</span>}
        </div>
        <Input
          className={styles.field}
          variant={Input.LIGHT_ROUND}
          placeholder={placeholder}
          value={value}
          onChange={handleInputChange}
          maxLength={50}
          disabled={!!disabled}
        />
        {error && <div className={styles.error}>{error}</div>}
      </div>
    </div>
  );
};
