import { FC } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import Filter from 'components/Icons/Filter';

interface Props {
  title?: string;
}
const OutlinedButton: FC<Props> = ({ title }) => {
  return (
    <div
      className={classNames(styles.buttonWrapper, styles.aggregateWrapper, {
        [styles.buttonsAppliedWrapper]: false,
      })}
      onClick={() => {}}
    >
      <div className={styles.icon}>
        <Filter color={'#515151'} />
      </div>
      <span>{title}</span>
    </div>
  );
};

export default OutlinedButton;
