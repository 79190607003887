import Api from '../core/api';
import {
  requestGetOfferAggregateByUserId,
  resetOfferAggregateAction,
  setOffersArchiveAction,
  setOffersArchiveIdAction,
  setOffersFiltersAppliedAction,
  setOffersFiltersAction,
} from 'store/actions/offerAggregate';
import { getOffersAggregateByUserId } from 'api/offerAggregate';

export const getOfferAggregateByUserIdEffect = (cfg, options = {}, cb?) => {
  const sendRequest = Api.execBase({
    action: requestGetOfferAggregateByUserId,
    method: getOffersAggregateByUserId,
  });
  return sendRequest(cfg, options, cb);
};

export const resetOfferAggregateEffect = () => (dispatch) => {
  dispatch(resetOfferAggregateAction());
};

export const setOffersIsArchiveEffect = (cfg) => (dispatch) => {
  dispatch(setOffersArchiveAction(cfg));
};

export const setOffersArchiveIdEffect = (cfg) => (dispatch) => {
  dispatch(setOffersArchiveIdAction(cfg));
};

export const setOffersFiltersAppliedEffect = (cfg) => (dispatch) => {
  dispatch(setOffersFiltersAppliedAction(cfg));
};

export const setOffersFiltersEffect = (cfg) => (dispatch) => {
  dispatch(setOffersFiltersAction(cfg));
};

export const searchOfferAggregateByUserIdEffect =
  ({ search, userId }, filters?) =>
  (dispatch) => {
    dispatch(
      getOfferAggregateByUserIdEffect({
        id: userId,
        filters: {
          searchString: search,
          ...filters,
        },
      }),
    );
  };

export const resetOfferAggregateByUserIdSearchEffect =
  (userId, filters = {}) =>
  (dispatch) => {
    dispatch(searchOfferAggregateByUserIdEffect({ search: '', userId }, filters));
  };
