import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDataSelector } from 'store/selectors/user';
import { Popover } from 'components-antd';
import {
  getNeighborhoodActivityDataSelector,
  getValuationDataSelector,
} from 'store/selectors/intel';
import {
  requestGetAddressNeighborhoodActivityEffect,
  requestGetAddressValuationDetailsEffect,
} from 'store/effects/intel';
import { Categories, FormValues } from './components/NeighborhoodTab/components/Filter/types';
import { tabRoutes, TabRoutesLinks } from './constants';
import { useOutsideClick } from 'hooks';
import { NeighborhoodTab, PropertyTab } from './components';
import { PageHeader } from '../../components';

export const getAddressFormattedCassAddress = (
  cassAddress: any,
): { address1: string; address2: string } => {
  // Extract values from the components of the Cass address
  const streetNumber = cassAddress.components.primary_number;
  const streetName = cassAddress.components.street_name;
  const streetPostDirection = cassAddress.components.street_postdirection
    ? ` ${cassAddress.components.street_postdirection}`
    : '';
  const streetSuffix = cassAddress.components.street_suffix
    ? ` ${cassAddress.components.street_suffix}`
    : '';
  const city = cassAddress.components.city_name;
  const state = cassAddress.components.state_abbreviation;
  const { zipcode } = cassAddress.components;

  // Construct address1 (StreetNumber + StreetName + optional StreetPostDirection + optional StreetSuffix)
  const address1 = `${streetNumber} ${streetName}${streetPostDirection}${streetSuffix}`.trim();

  // Construct address2 (City, State Zipcode)
  const address2 = `${city}, ${state} ${zipcode}`;

  // Return the formatted addresses
  return { address1, address2 };
};

const AddressDropdown: FC = () => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  useOutsideClick([buttonRef, optionsRef], () => setOpen(false));

  const user = useSelector(getUserDataSelector);
  const addresses = user?.Client?.Address;
  const { address1 } = getAddressFormattedCassAddress(addresses?.[0]?.address);
  return (
    <div className={styles.dropdownWrapper}>
      <span>{address1}</span>
      <Popover open={open} overlayClassName={styles.assignedToOptionsPopover} content={<></>} />
    </div>
  );
};

export const IntelClient: FC = () => {
  const dispatch = useDispatch();

  const valuationData = useSelector(getValuationDataSelector);

  const neighborhoodActivityData = useSelector(getNeighborhoodActivityDataSelector);

  const user = useSelector(getUserDataSelector);
  const addresses = user?.Client?.Address;
  const { address1, address2 } = getAddressFormattedCassAddress(addresses?.[0]?.address);

  useEffect(() => {
    dispatch(
      requestGetAddressValuationDetailsEffect(
        {
          address1,
          address2,
          clientId: user?.Client?.Id,
        },
        {
          silent: valuationData !== null,
        },
      ),
    );

    dispatch(
      requestGetAddressNeighborhoodActivityEffect({
        address: {
          long: user?.Client?.Address?.[0]?.address?.metadata?.longitude,
          lat: user?.Client?.Address?.[0]?.address?.metadata?.latitude,
          radius: user?.Client?.Address?.[0]?.neighbourhoodRadius ?? 10,
        },
      }),
    );
  }, []);

  const handleFiltersChange = useCallback(
    (values: FormValues) => {
      const { categories } = values;

      let category: string[] | undefined = undefined;
      let activityStatus: string[] | undefined;

      if (categories[Categories.NEW_LISTING]) {
        category = [...(category ?? []), 'New'];
      } else if (categories[Categories.PRICE_REDUCTION]) {
        category = [...(category ?? []), 'Price Reductions'];
      } else if (categories[Categories.PRICE_INCREASE]) {
        category = [...(category ?? []), 'Price Increase'];
      } else if (categories[Categories.BACK_ON_THE_MARKET]) {
        category = [...(category ?? []), 'Back on Market'];
      } else if (
        categories[Categories.WITHDRAWN] ||
        categories[Categories.UNDER_CONTRACT] ||
        categories[Categories.EXPIRED] ||
        categories[Categories.SOLD]
      ) {
        category = [...(category ?? []), 'Status Changes'];
        activityStatus = [
          categories[Categories.WITHDRAWN] && 'Withdrawn',
          categories[Categories.UNDER_CONTRACT] && 'ActiveUnderContract',
          categories[Categories.EXPIRED] && 'Expired',
          categories[Categories.SOLD] && 'Sold',
        ].filter(Boolean) as string[];
      } else if (categories[Categories.AGENT_UPDATES]) {
        category = [...(category ?? []), 'Agent Updates'];
      }

      dispatch(
        requestGetAddressNeighborhoodActivityEffect(
          {
            category,
            activityStatus,
            address: {
              long: user?.Client?.Address?.[0]?.address?.metadata?.longitude,
              lat: user?.Client?.Address?.[0]?.address?.metadata?.latitude,
              radius: user?.Client?.Address?.[0]?.neighbourhoodRadius ?? 10,
            },
          },
          {
            silent: neighborhoodActivityData !== null,
          },
        ),
      );
    },
    [user, dispatch, neighborhoodActivityData],
  );

  const [activeTab, setActiveTab] = useState<string>(TabRoutesLinks[1].value);

  const propertyTabParams = useMemo(
    () => ({
      address1,
      address2,
      clientId: user?.Client?.Id,
    }),
    [address1, address2, user],
  );

  const renderActiveTab = useMemo(() => {
    switch (activeTab) {
      case tabRoutes.property:
        return <PropertyTab params={propertyTabParams} />;
      default:
      case tabRoutes.neighborhood:
        return <NeighborhoodTab onFilterChange={handleFiltersChange} />;
    }
  }, [activeTab]);

  return (
    <div className={styles.pageWrapper}>
      <PageHeader
        // title={`Some Address`}
        links={TabRoutesLinks}
        activeKey={activeTab}
        setActiveTab={setActiveTab}
      >
        <AddressDropdown />
      </PageHeader>
      {renderActiveTab}
    </div>
  );
};
