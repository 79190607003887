import { Row, Col } from 'components-antd';
import { CommissionField } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/Questions/FinancingDetails/components';
import { SplitTypes } from '../../../../TransactionFinancials';

import { AgentCoordinatorField } from 'pages/Workshop/Transactions/TransactionOverview/components/AgentCoordinatorField';

import styles from './styles.module.scss';

export const EditSplitItem = ({
  item,
  updateSplitValue,
  isTransactionAdminOrOwner,
  memberContacts,
  onChangeAccountId = (arg: any) => {},
  accountUserItem,
}) => {
  const splitType = item?.SplitType?.SplitType;
  const isAgentSplit = splitType === SplitTypes.AGENT;

  const updateAccountId = (account) => {
    if (typeof account?.value === 'number') {
      onChangeAccountId({ [account?.key]: account?.value });
    } else {
      const Id = account?.value?.replace('-contact', '')?.replace('-user', '');
      const key = account?.value?.includes('-contact')
        ? 'LedgerAccountContactId'
        : 'LedgerAccountUserId';
      onChangeAccountId({ [key]: parseInt(Id) });
    }
  };
  // Edit Capability of Restricted splits only to admin/owner & Hide Client Split Item
  if ((!isTransactionAdminOrOwner && item?.IsRestricted) || splitType === SplitTypes?.CLIENT)
    return null;

  return (
    <Row gutter={12}>
      <Col lg={isAgentSplit ? 12 : 24}>
        <p className={styles.labelSplit}>{item?.SplitType?.SplitTitle}</p>
        <CommissionField
          IsFeeTypePercentage={item?.IsSplitTypePercentage}
          update={(object) => {
            const isToggled = object?.IsFeeTypePercentage !== item?.IsSplitTypePercentage;
            const value = object?.Value === '' ? null : parseFloat(object?.Value);
            if (
              isToggled ||
              (object?.IsFeeTypePercentage && value == item?.SplitPercentage) ||
              (!object?.IsFeeTypePercentage && value == item?.SplitValue)
            ) {
              // Handle toggling between $ / % button
              updateSplitValue(
                item?.Id,
                {
                  IsSplitTypePercentage: object?.IsFeeTypePercentage,
                },
                true,
              );
            } else {
              const updatedObject = {
                SplitValue: object?.Value === '' ? null : parseFloat(object?.Value),
                IsSplitTypePercentage: object?.IsFeeTypePercentage,
              };
              updateSplitValue(item?.Id, updatedObject);
            }
          }}
          label=""
          value={item?.IsSplitTypePercentage ? item?.SplitPercentage || '' : item?.SplitValue || ''}
        />
      </Col>
      {isAgentSplit && (
        <Col lg={12}>
          <p className={styles.labelSplit}>Agent</p>
          <AgentCoordinatorField
            item={accountUserItem}
            memberContacts={memberContacts}
            onChange={updateAccountId}
            isFinancials
            isAgentInfo
          />
        </Col>
      )}
    </Row>
  );
};
