import common from './common';
import loginRoutes from './login';
import signUpRoutes from './signUp';
import userRoutes from './user';
import vaultRoutes from './vault';
import onBoardingRoutes from './onBoarding';
import properties from './properties';
import services from './services';
import adminPanel from './adminPanel';
import workshop from './workshop';
import showing from './showing';
import offer from './offer';
import symphony from './symphony';
import clientDashboard from './clientDashboard';
import listing from './listings';
import clarity from './clarity';
import paywall from './paywall';
import shortUrl from './shortUrl';
import radar from './radar';
import clientProfile from './clientProfile';
import intel from './intel';

export const routes = {
  index: '/',
  ...common,
  ...loginRoutes,
  ...signUpRoutes,
  ...userRoutes,
  ...vaultRoutes,
  ...onBoardingRoutes,
  ...properties,
  ...services,
  ...adminPanel,
  ...radar,
  ...workshop,
  ...showing,
  ...offer,
  ...symphony,
  ...clientDashboard,
  ...listing,
  ...clarity,
  ...paywall,
  ...shortUrl,
  ...clientProfile,
  ...intel,
};
