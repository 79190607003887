import React, { useState } from 'react';
import { Modal } from 'antd';
import styles from './styles.module.scss';
import { ModalClose } from 'components/Icons';
import { Button } from 'components-antd';
import { useDispatch } from 'react-redux';
import { requestUpdateCampaignEffect } from 'store/effects/radarKits';
import { boolean } from 'yup';

type Props = {
  payload: {
    newFrequencyName: string;
    prevFrequenceName: string;
    campaignId: string;
    frequencyId: string;
    kitId: number;
  };
  handleClose: () => void;
  selectedKits: any;
};

export const ChangePlanFrequencyModal: React.FC<Props> = ({
  payload,
  handleClose,
  selectedKits,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleConfirm = () => {
    setIsLoading(true);

    dispatch(
      requestUpdateCampaignEffect(
        {
          Id: Number(payload.campaignId),
          kits: selectedKits.map((item) => {
            if (item.KitId === payload.kitId) {
              return {
                kitId: Number(item.KitId),
                frequencyId: Number(payload.frequencyId),
              };
            }
            return {
              kitId: Number(item.KitId),
              frequencyId: Number(item.FrequencyId),
            };
          }),
        },
        {},
        (err, res) => {
          setIsLoading(false);
          if (!err) {
            handleClose();
          }
        },
      ),
    );
  };
  return (
    <Modal
      open={true}
      centered
      maskStyle={{
        background: 'rgba(255, 255, 255, 0.60)',
        backdropFilter: ' blur(12.5px)',
      }}
      bodyStyle={{
        borderRadius: '24px',
        background: 'var(--REBRAND-Secondary-White, #FFF)',
        boxShadow: '0px 24px 80px 0px rgba(0, 0, 0, 0.20)',
      }}
      wrapClassName={styles.wrapper}
      closable
      footer={null}
      className={styles.modalWrapper}
      onCancel={handleClose}
      destroyOnClose
      closeIcon={<ModalClose />}
    >
      <div className={styles.content}>
        <div className={styles.header}>
          This will change the frequency from {payload.prevFrequenceName} to{' '}
          {payload.newFrequencyName} for all clients in the plan going forward.
        </div>

        <div className={styles.btnContainer}>
          <Button variant="secondary" className={styles.cancelBtn} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            className={styles.confirmBtn}
            onClick={handleConfirm}
            // loading={isLoading}
            disabled={isLoading}
          >
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  );
};
