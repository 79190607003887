import { useEffect, useCallback, useState, useMemo } from 'react';

import { Button, Modal, TabPane, Tabs } from 'components-antd';
import { Spinner } from 'components';
import { Header } from './components/Header';
import { Details } from './components/Details';

import { useDispatch, useSelector } from 'react-redux';
import { SOCKET_THREAD_TYPES } from 'settings/constants/sockets';

import { getUserId, getUserRolesMapSelector } from 'store/selectors/user';
import { Icons } from 'pages/Workshop/Icons';
import { MessageIcon } from 'pages/ServicesCategory/components/AgentPartnersList/icons';
import { useEmailVerificationWall } from 'features/emailVerification/useEmailVerificationWall';
import { ActionToInterrupt } from 'features/emailVerification/constants';
import {
  getHrefLink,
  getIfExistThread,
  getPropertyAddress,
  getPropertyTwoLinesAddress,
} from 'helpers';
import { getThreadsListSelector } from 'store/selectors/sockets/threads';
import { useHistory, useParams } from 'react-router-dom';
import { reduce, trim } from 'lodash-es';
import axios from 'axios';
import classNames from 'classnames';
import fileDownload from 'js-file-download';

import { ModalProps, Paragraph, Title } from 'components-antd';
import { FormattedPhone } from 'components';
import { Phone, Email, Geotag, World } from 'components/Icons';
import {
  getLoadingPartnerData,
  getPartnerObject,
  getPartnerBusinessNameAndId,
} from 'store/selectors/singlePartner';
import {
  requestChangeRecommendPartnerStatus,
  requestGetPartnersListByCategoryIdEffect,
  requestGetRecommendedPartnersByAgentListEffect,
} from 'store/effects/partners';
import { requestGetPartnerByIdEffect } from 'store/effects/singlePartner';
import { getClientAgentId, getUserRoleSelector } from 'store/selectors/user';
import {
  changeMessagesDrawerTypeAction,
  openMessagesDrawerAction,
} from 'store/actions/drawers/messages';
import { DRAWER_MESSAGES_TYPES } from 'settings/constants/drawers';
import { THIRD_PARTY, AGENT, CLIENT } from 'settings/constants/roles';
import { link } from 'settings/navigation/link';
import { sortGroupedQuoteDocuments } from 'pages/Quotes/components/QuoteDetails/helpers';
import { requestGetQuoteDetailsEffect, respondAndUploadDocumentEffect } from 'store/effects/quotes';
import { getQuoteDetailsSelector } from 'store/selectors/quoteDetails';
import { getAgentTypeSelector } from 'store/selectors/user';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { AGENT_TYPE } from 'settings/constants/drawers';
import { TEAM_BASIC } from 'settings/constants/roles';
import { getAgentTeamIdSelector } from 'store/selectors/agentTeamDetail';
import { getConciergeSearchSelector } from 'store/selectors/concierge';
import { RecommendationModal } from 'pages/ServicesCategory/components/AgentPartnersList/components/PartnersList/components/RecommendationModal';
import { QuoteFiles } from './components/QuoteFiles';
import { YourRequest } from './components/YourRequest';
import moment from 'moment';
import { quoteRequestStatus } from 'settings/constants/quotes';
import { Upload } from '../components/QuoteDetails/components';
import { Highlights } from './components/Highlights';
import PdfView from './components/PdfView';

import styles from './styles.module.scss';
interface PartnerProfileProps extends ModalProps {
  requestQuoteId: number;
  partnerId: number;
  setModalVisible: (open: boolean) => void;
}

export const QuoteDetailModal = ({
  partnerId,
  setModalVisible,
  requestQuoteId,
  open,
  onCancel,
  ...props
}: PartnerProfileProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [tabKey, setTabKey] = useState('1');
  const userId = useSelector(getUserId);
  const [expand, setExpand] = useState(false);
  const [fullProfile, setFullProfile] = useState(false);
  const [requestMode, setRequestMode] = useState(false);
  const [confirmChangeRecommendation, setConfirmChangeRecommendation] = useState(false);
  const [updateChangeRecommendation, setUpdateChangeRecommendation] = useState(false);
  const [uploadPending, setUploadPending] = useState(false);

  useEffect(() => {
    if (requestQuoteId) {
      setFullProfile(false);
      setRequestMode(true);
    }
  }, []);

  const history = useHistory();
  const dispatch = useDispatch();

  const { isPending, isIdle, metaId } = useSelector(getLoadingPartnerData);
  const { id } = useSelector(getPartnerBusinessNameAndId);
  const partnerData = useSelector(getPartnerObject);
  const threads = useSelector(getThreadsListSelector);
  const { quote, groupedByDateQuoteDocuments, requestorRole, requestorName } =
    useSelector(getQuoteDetailsSelector);
  const agentType = useSelector(getAgentTypeSelector);
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const teamId = useSelector(getAgentTeamIdSelector);
  const { isThirdParty } = useSelector(getUserRolesMapSelector);
  const { areasOfOperation, category: categoryId } = useSelector(getConciergeSearchSelector);

  const getSortedDocuments = () => {
    return Object.entries(groupedByDateQuoteDocuments).sort(sortGroupedQuoteDocuments);
  };
  const getLatestQuoteFile = () => {
    const sortedDoc = getSortedDocuments()?.[0];
    return sortedDoc?.[1]?.[0];
  };
  const agentId = useSelector(getClientAgentId);
  const userRole = useSelector(getUserRoleSelector);

  useEffect(() => {
    if (open) {
      if (requestQuoteId) {
        dispatch(
          requestGetQuoteDetailsEffect({ id: requestQuoteId }, {}, (err, res) => {
            if (!err) setIsLoading(false);
          }),
        );
      }
      if (partnerId) {
        dispatch(requestGetPartnerByIdEffect({ partnerId }));
      }
    }
  }, [open]);

  useEffect(() => {
    if (partnerId && metaId !== partnerId && !isPending) {
      dispatch(requestGetPartnerByIdEffect({ partnerId }));
    }
  }, [partnerId, dispatch, isPending, metaId]);

  useEffect(() => {
    if (updateChangeRecommendation) {
      dispatch(requestGetPartnerByIdEffect({ partnerId }));
      setUpdateChangeRecommendation(false);
    }
  }, [updateChangeRecommendation]);

  const { address, categories, recommendedBy } = useMemo(
    () => ({
      address: getPropertyTwoLinesAddress(partnerData?.Address),
      categories: reduce(
        partnerData?.PartnerCategories,
        (acc, { Title }) => {
          if (acc) {
            return `${acc}, ${Title}`;
          }
          return Title;
        },
        '',
      ),
      recommendedBy: reduce(
        partnerData?.RecommendingAgents,
        (acc, { Id, FirstName, LastName }) => {
          if (Id !== agentId) {
            return trim(`${FirstName} ${LastName}`);
          }
          return acc;
        },
        '',
      ),
    }),
    [
      partnerData?.Address,
      partnerData?.PartnerCategories,
      partnerData?.RecommendingAgents,
      agentId,
    ],
  );

  const initiateSendingMessageToPartner = useCallback(() => {
    setModalVisible(false);

    const thread = getIfExistThread(
      threads,
      [id, userId],
      ({ Type }) => Type === SOCKET_THREAD_TYPES.CHAT,
    );

    dispatch(openMessagesDrawerAction(true));

    if (!thread) {
      const firstName = partnerData?.ThirdParty?.User?.FirstName;
      const lastName = partnerData?.ThirdParty?.User?.LastName;

      dispatch(
        changeMessagesDrawerTypeAction({
          type: DRAWER_MESSAGES_TYPES.NEW_MESSAGE,
          params: {
            threadId: thread?.Id || null,
            participants: [
              {
                id: partnerData?.Id,
                name: `${firstName} ${lastName || ''} (${partnerData?.BusinessName || ''})`,
                value: partnerData?.Id,
                role: THIRD_PARTY,
                avatarUrl: partnerData?.LogoUrl,
                firstName: partnerData?.FirstName,
                lastName: partnerData?.LastName,
              },
            ],
          },
        }),
      );
    } else {
      dispatch(
        changeMessagesDrawerTypeAction({
          type: DRAWER_MESSAGES_TYPES.CHAT,
          params: {
            threadId: thread.Id,
          },
        }),
      );
    }
  }, [dispatch, threads, id, userId, partnerData]);

  const initiateSendingMessageToRequestor = useCallback(() => {
    setModalVisible(false);

    const thread = getIfExistThread(
      threads,
      [quote?.Requestor?.Id, userId],
      ({ Type }) => Type === SOCKET_THREAD_TYPES.CHAT,
    );

    dispatch(openMessagesDrawerAction(true));

    if (!thread) {
      const firstName = quote?.Requestor?.FirstName;
      const lastName = quote?.Requestor?.LastName;

      dispatch(
        changeMessagesDrawerTypeAction({
          type: DRAWER_MESSAGES_TYPES.NEW_MESSAGE,
          params: {
            threadId: thread?.Id || null,
            participants: [
              {
                id: quote?.Requestor?.Id,
                name: `${firstName} ${lastName || ''}`,
                value: quote?.Requestor?.Id,
                role: THIRD_PARTY,
                avatarUrl: quote?.Requestor?.AvatarUrl,
                firstName: quote?.Requestor?.FirstName,
                lastName: quote?.Requestor?.LastName,
              },
            ],
          },
        }),
      );
    } else {
      dispatch(
        changeMessagesDrawerTypeAction({
          type: DRAWER_MESSAGES_TYPES.CHAT,
          params: {
            threadId: thread.Id,
          },
        }),
      );
    }
  }, [dispatch, threads, quote, userId]);

  const handleThirdPartyMessageButtonClick = useEmailVerificationWall(
    ActionToInterrupt.MESSAGE_TO_PARTNER,
    initiateSendingMessageToRequestor,
    {
      categoryId,
    },
  );

  const handleMessageButtonClick = useEmailVerificationWall(
    ActionToInterrupt.MESSAGE_TO_PARTNER,
    initiateSendingMessageToPartner,
    {
      categoryId,
    },
  );

  const handleRequestQuoteClick = useEmailVerificationWall(
    ActionToInterrupt.REQUEST_QUOTE,
    () => {
      history.push(link.toServicesCategoryPartnerQuote({ partnerId, categoryId }));
    },
    {
      categoryId,
    },
  );

  const recommendedAgent =
    userRole === AGENT &&
    partnerData?.RecommendingAgents.find(
      ({ Id, TeamId }) => Id === agentId || (TeamId && TeamId === teamId),
    );

  const validateAgentType = () =>
    agentType === AGENT_TYPE.Individual ||
    (agentType === AGENT_TYPE.Team && agentRole !== TEAM_BASIC);

  const showRecommendedBy = () => recommendedBy && userRole === CLIENT;

  const showRecommended = () => recommendedAgent && validateAgentType();

  const showRecommend = () => userRole === AGENT && validateAgentType();

  const onChangeRecommendation = useCallback(() => {
    dispatch(
      requestChangeRecommendPartnerStatus(
        {
          recommend: !!recommendedAgent,
          partnerId: partnerData?.Id,
        },
        (err) => {
          if (!err) {
            setConfirmChangeRecommendation(false);
            setUpdateChangeRecommendation(true);
            dispatch(requestGetRecommendedPartnersByAgentListEffect());
            setModalVisible(false);
          }
        },
      ),
    );
  }, [
    partnerData,
    dispatch,
    requestGetPartnersListByCategoryIdEffect,
    setConfirmChangeRecommendation,
    setUpdateChangeRecommendation,
    categoryId,
    areasOfOperation,
  ]);

  const onDownload = () => {
    axios.get(quote.RequestQuoteFile?.Url, { responseType: 'blob' }).then((response) => {
      fileDownload(response.data, `${getPropertyAddress(quote?.Property?.Address)}.pdf`);
    });
  };

  const onExpand = () => {
    setExpand(true);
  };

  const getDate = (UploadDate) => {
    const momentDate = moment(UploadDate);
    const formattedDate = momentDate.format('M/D/YY');
    const formattedTime = momentDate.format('h:mm A');

    return `${isThirdParty ? 'Requested' : 'Received'} ${formattedDate} at ${formattedTime}`;
  };
  const getClientName = () =>
    quote?.Client ? `${(quote.Client.FirstName ?? '') + (' ' + quote.Client.LastName)}` : undefined;

  const TABS_ITEMS = useMemo(
    () => [
      {
        key: '1',
        label: 'Files',
        icon: (
          <Icons
            className={styles.tabIcon}
            color={tabKey === '1' ? '#262626' : '#AAABAB'}
            variant={Icons.FILES}
          />
        ),
        children: (
          <QuoteFiles
            sortedDocuments={getSortedDocuments() || []}
            multi={quote?.QuoteDocuments?.length > 1}
          />
        ),
      },

      {
        key: '2',
        label: 'Your Request',
        icon: (
          <Icons
            color={tabKey === '2' ? '#262626' : '#AAABAB'}
            className={styles.tabIcon}
            variant={Icons.HISTORY}
          />
        ),
        children: <YourRequest />,
      },
    ],
    [quote, tabKey],
  );

  const onUpload = (event) => {
    setUploadPending(true);
    dispatch(
      respondAndUploadDocumentEffect(
        { id: quote?.Id, files: event.target.files },
        { silent: true },
        () => {
          setUploadPending(false);
        },
      ),
    );
  };
  return (
    <Modal
      open={open}
      width={1000}
      onCancel={onCancel}
      closeIcon={false}
      closable={false}
      className={styles.appointmentDetailsModal}
      footer={null}
      title={
        isLoading ? null : (
          <div className={styles.header}>
            <div className={styles.headerTitle}>
              <Header
                onModalClose={onCancel}
                status={quote?.Status}
                isProfileView={fullProfile}
                partnerData={partnerData}
                categories={categories}
                userRole={userRole}
                showRecommendedBy={showRecommendedBy}
                recommendedBy={recommendedBy}
                showRecommended={showRecommended}
                showRecommend={showRecommend}
                setFullProfile={setFullProfile}
                setConfirmChangeRecommendation={setConfirmChangeRecommendation}
                handleMessageButtonClick={handleMessageButtonClick}
              />
            </div>
          </div>
        )
      }
      {...props}
    >
      {!isLoading ? (
        <>
          {fullProfile ? (
            <>
              <div className={styles.partnerBody}>
                <div className={styles.contactContainer}>
                  {partnerData?.PhoneNumber && (
                    <Paragraph className={styles.contactParagraph}>
                      <div className={styles.contactInner}>
                        <div className={styles.contactIcon}>
                          <Phone color="#aaabab" />
                        </div>
                        <span>Phone</span>
                      </div>
                      <FormattedPhone
                        testid="phone"
                        className={classNames(styles.contactPhoneText, styles.cpDetail)}
                      >
                        {partnerData?.PhoneNumber}
                      </FormattedPhone>
                    </Paragraph>
                  )}
                  {partnerData?.Email && (
                    <Paragraph className={styles.contactParagraph}>
                      <div className={styles.contactInner}>
                        <div className={styles.contactIcon}>
                          <Email color="#aaabab" />
                        </div>
                        <span>Email</span>
                      </div>
                      <div className={styles.cpDetail}>{partnerData?.Email}</div>
                    </Paragraph>
                  )}
                  {partnerData?.Address && (
                    <Paragraph className={styles.contactParagraph}>
                      <div className={styles.contactInner}>
                        <div className={styles.contactIcon}>
                          <Geotag color="#aaabab" />
                        </div>
                        <span>Address</span>
                      </div>
                      <div className={classNames(styles.contactAddressText, styles.cpDetail)}>
                        {address.length && (
                          <>
                            <div>{address[0] || address[1] || '-'}</div>
                            {address[0] && address[1] && <div>{address[1]}</div>}
                          </>
                        )}
                      </div>
                    </Paragraph>
                  )}
                  {partnerData?.Website && (
                    <Paragraph className={styles.contactParagraph}>
                      <div className={styles.contactInner}>
                        <div className={styles.contactIcon}>
                          <World color="#aaabab" />
                        </div>
                        <span>Website</span>
                      </div>
                      <div className={styles.cpDetail}>
                        {' '}
                        <a
                          href={getHrefLink(partnerData?.Website)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {partnerData?.Website}
                        </a>
                      </div>
                    </Paragraph>
                  )}
                </div>

                {partnerData?.AreasOfOperation?.length > 0 && (
                  <div className={styles.areasServerContainer}>
                    <Title level={5} className={styles.bodyHeader}>
                      Areas Served
                    </Title>
                    <div className={styles.areasTags}>
                      {partnerData.AreasOfOperation.map((area, index) => (
                        <span key={`${index}-areaServed`}>
                          {area?.City || ''}, {area?.State || ''};
                        </span>
                      ))}
                    </div>
                  </div>
                )}
                {partnerData?.Description && (
                  <div className={styles.descriptionContainer}>
                    <Title level={5} className={styles.bodyHeader}>
                      Description
                    </Title>
                    <Paragraph
                      className={classNames(styles.descriptionParagraph, {
                        [styles.descriptionEmpty]: !partnerData?.Description,
                      })}
                    >
                      {partnerData?.Description || 'No description'}
                    </Paragraph>
                  </div>
                )}
                {partnerData?.PartnerTags?.length > 0 && (
                  <div className={styles.servicesContainer}>
                    <Title level={5} className={styles.bodyHeader}>
                      Services
                    </Title>
                    <div className={styles.servicesTags}>
                      {partnerData.PartnerTags.map((pTag) => (
                        <span key={pTag.Id}>{pTag.Name}</span>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <RecommendationModal
                isOpen={confirmChangeRecommendation}
                selectedPartner={partnerData}
                onCloseModal={() => setConfirmChangeRecommendation(false)}
                isLoadingRecommendation={isPending}
                onChangeRecommendation={onChangeRecommendation}
              />
            </>
          ) : (
            <div className={styles.content}>
              <div className={styles.detailsContainer}>
                {quote && (
                  <>
                    <Details
                      name={isThirdParty ? requestorName : quote?.Partner?.BusinessName}
                      type={isThirdParty ? requestorRole : 'Vendor'}
                      logoUrl={quote?.Partner?.LogoUrl}
                      property={quote?.Property}
                      setFullProfile={setFullProfile}
                      client={
                        isThirdParty && quote?.Client
                          ? `${quote?.Client?.FirstName} ${quote?.Client?.LastName}`
                          : ''
                      }
                      onCancel={onCancel}
                      handleThirdPartyMessageButtonClick={handleThirdPartyMessageButtonClick}
                    />
                    {isThirdParty ? (
                      <div className={styles.quoteDetailsUploadButton}>
                        <Upload
                          onChange={onUpload}
                          id="uploadQuote"
                          isPending={uploadPending}
                          className={styles.quoteDetailsUpload}
                          iconClassName={styles.quoteDetailsUploadIcon}
                          isPDFOnly={true}
                        />
                      </div>
                    ) : (
                      <div className={styles.messageBtn} onClick={handleMessageButtonClick}>
                        <div onClick={onCancel}>
                          <MessageIcon />
                        </div>
                        Message
                      </div>
                    )}
                  </>
                )}
                {isThirdParty && quote?.Status !== quoteRequestStatus.Responded ? (
                  <>
                    <div className={styles.quotesBlock}>
                      <span className={styles.title}>Quotes</span>
                      <QuoteFiles
                        sortedDocuments={getSortedDocuments() || []}
                        multi={quote?.QuoteDocuments?.length > 1}
                      />
                    </div>
                  </>
                ) : (
                  <div className={styles.tabs}>
                    <Tabs
                      activeKey={tabKey}
                      onChange={setTabKey}
                      centered
                      defaultActiveKey={'1'}
                      popupClassName={styles.popup}
                    >
                      {TABS_ITEMS.map(({ key, icon, children, label }) => (
                        <TabPane
                          tab={
                            <div className={styles.tabRow}>
                              <span>{icon}</span>
                              <span>{label} </span>
                            </div>
                          }
                          key={key}
                        >
                          {children}
                        </TabPane>
                      ))}
                    </Tabs>
                  </div>
                )}
              </div>

              <div className={styles.contentRight}>
                <>
                  {isThirdParty && quote?.Status !== quoteRequestStatus.Responded ? (
                    <>
                      <Highlights
                        quote={quote}
                        UploadDate={getDate(quote?.RequestQuoteFile?.UploadDate)}
                      />
                    </>
                  ) : (
                    <>
                      {getSortedDocuments()?.length > 0 ? (
                        <>
                          <PdfView
                            file={getLatestQuoteFile()}
                            UploadDate={getDate(getSortedDocuments()?.[0]?.[1]?.[0]?.UploadDate)}
                          />
                        </>
                      ) : (
                        <>
                          <div>
                            <span className={styles.recentQuoteTitle}>Most Recent Quote</span>
                            <div className={styles.disclaimerContainer}>
                              <p className={styles.disclaimer}>No Quotes Uploaded</p>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              </div>
            </div>
          )}
        </>
      ) : (
        <Spinner />
      )}
    </Modal>
  );
};
