import {
  renderSortableTitle,
  sorterWithCompletedOnTop,
} from 'pages/Workshop/Forms/components/FormsTable/sortingHelpers';
import { CompareFn } from 'antd/lib/table/interface';
import { FormProcessType } from 'types';
import { FormColumn, LastActivityColumn, OwnerColumn, StatusColumn } from './components';

export const inProgressColumns = ({ isOwnerOrAdmin }) => [
  {
    title: renderSortableTitle('formName', 'Form'),
    dataIndex: 'formName',
    key: 'formName',
    width: '25%',
    render: (text, row) => <FormColumn row={row} />,
    sorter: sorterWithCompletedOnTop('formName') as CompareFn<FormProcessType>,
  },
  ...(isOwnerOrAdmin
    ? [
        {
          title: 'Owner',
          dataIndex: 'creator',
          key: 'creator',
          width: '16%',
          render: (text, row) => <OwnerColumn row={row} />,
        },
      ]
    : []),
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '14%',
    render: (text, row) => <StatusColumn row={row} />,
  },

  {
    title: 'Last Activity',
    dataIndex: 'status',
    key: 'lastActivity',
    width: '25%',
    render: (text, row) => <LastActivityColumn row={row} />,
  },
];
