import { Modal, Button, Switch, Select } from 'components-antd';
import styles from './styles.module.scss';
import { Icons } from 'pages/Clarity/Icons';
import { useState } from 'react';
import { Input, Locations } from 'components';
import { updateKitById } from 'api/kits';
import { CheckBox } from 'components/Icons';

type EditModalPropsType = {
  showModal: Function;
  open: boolean;
  targetKit;
  propertyTypes;
  updateKitHandler: Function;
};

function EditModal(props: EditModalPropsType) {
  const [savingKit, setSavingKit] = useState<boolean>(false);
  const [savingKitError, setSavingKitError] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>(props?.targetKit?.Notes || '');
  const [inAppReminder, setInAppReminder] = useState<boolean>(
    props?.targetKit?.InAppReminder || false,
  );
  const [eligibleLocations, setEligibleLocations] = useState<any>(
    props?.targetKit?.EligibleLocations || [],
  );
  const [restrictedLocations, setRestrictedLocations] = useState<any>(
    props?.targetKit?.RestrictedLocations || [],
  );
  const [eligiblePropertyTypes, setEligiblePropertyTypes] = useState<string[]>(
    props?.targetKit?.EligiblePropertyTypes?.replace(/[{}"]/g, '')?.split(',') || [],
  );

  const saveKit = async () => {
    try {
      setSavingKit(true);
      const kitsObject = {
        Notes: notes,
        InAppReminder: inAppReminder,
        EligibleLocations: eligibleLocations,
        RestrictedLocations: restrictedLocations,
        EligiblePropertyTypes: `{${eligiblePropertyTypes.join(',')}}`,
      };
      await updateKitById(props.targetKit.Id, kitsObject);
      props.updateKitHandler(props.targetKit.Id, kitsObject);
      setSavingKit(false);
      setSavingKitError(false);
    } catch (e) {
      setSavingKit(false);
      setSavingKitError(true);
    }
  };

  const updateLocation = (result, prepareData, isEligible) => {
    if (isEligible) {
      setEligibleLocations(prepareData);
    } else {
      setRestrictedLocations(prepareData);
    }
  };

  return (
    <Modal
      footer={null}
      width={675}
      open={props.open}
      onCancel={() => {
        props.showModal(false);
      }}
      title={
        <span className={styles.headerTitle}>
          <Icons variant="kits" />
          <span className={styles.headerText}>{`${props.targetKit?.Name} KIT`}</span>
        </span>
      }
      className={styles.kitsEditModal}
    >
      <div className={styles.propertyTypes}>
        <p className={styles.sectionLabel}>Property Types:</p>
        <Select
          large
          disabled={false}
          showArrow={true}
          placeholder="Select Property Types"
          mode="multiple"
          getPopupContainer={(triggerNode) => triggerNode}
          menuItemSelectedIcon={<CheckBox className={styles.checkBoxIcon} />}
          className={styles.selector}
          value={eligiblePropertyTypes}
          options={props.propertyTypes.map((el) => ({
            label: el,
            value: el,
            key: `type-${el}`,
          }))}
          filterOption={(input, option) => {
            return String((option?.label as unknown as string) || '')
              .toLowerCase()
              .includes(input.toLowerCase());
          }}
          onChange={(val) => {
            if (val?.length) setEligiblePropertyTypes(val);
          }}
        />
      </div>
      <div className={styles.inAppReminder}>
        <p className={styles.sectionLabel}>In-App Reminder:</p>
        <Switch checked={inAppReminder} onChange={(checked) => setInAppReminder(checked)} />
      </div>
      <div className={styles.eligibleLocations}>
        <p className={styles.sectionLabel}>Eligible Locations</p>
        <Locations
          multiple
          allowStates={true}
          getStates={true}
          onResult={(result, prepareData) => updateLocation(result, prepareData, true)}
          value={eligibleLocations?.length ? eligibleLocations : undefined}
          placeholder={'Enter City, State, or County'}
          activeInputClassName={styles.activeInputClassName}
          valuesWrapperClassName={styles.valuesWrapperClassName}
          placeholderClassName={styles.placeholderClass}
          valuesContainerClassName={styles.valuesContainer}
          searchIcon={!!eligibleLocations?.length}
          variant={Locations.SQUARE}
          customPlaceName={({ address }) => address?.split?.(',')?.slice(0, 2)?.join(',')}
          showOnlyCityAndState={true}
        />
      </div>
      <div className={styles.restrictedLocations}>
        <p className={styles.sectionLabel}>Restricted Locations</p>
        <Locations
          multiple
          allowStates={true}
          getStates={true}
          onResult={(result, prepareData) => updateLocation(result, prepareData, false)}
          value={restrictedLocations?.length ? restrictedLocations : undefined}
          placeholder={'Enter City, State, or County'}
          activeInputClassName={styles.activeInputClassName}
          valuesWrapperClassName={styles.valuesWrapperClassName}
          placeholderClassName={styles.placeholderClass}
          valuesContainerClassName={styles.valuesContainer}
          searchIcon={!!restrictedLocations?.length}
          variant={Locations.SQUARE}
          customPlaceName={({ address }) => address?.split?.(',')?.slice(0, 2)?.join(',')}
          showOnlyCityAndState={true}
        />
      </div>
      <div className={styles.kitsNotes}>
        <Input
          className={styles.field}
          variant="light"
          placeholder="Enter notes"
          value={props?.targetKit?.Notes || ''}
          onChange={(e, val) => setNotes(val)}
          testid="name_input"
          maxLength={10000}
        />
        <Button
          className={styles.notesButton}
          loading={savingKit}
          disabled={savingKit}
          onClick={() => {
            saveKit();
          }}
        >
          {savingKitError ? 'Try Again' : 'Save Kit'}
        </Button>
      </div>
    </Modal>
  );
}

export default EditModal;
