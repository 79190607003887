import moment from 'moment';
import { ActivityGroupingTypes } from 'types/activityLogs';
import { ActivityLogsActionsTypes } from 'utils/activityLogsActionsTypes';

export const getFormattedFilterObject = (filterValues, currentPageNum, searchTerm, pgSize) => {
  const now = moment().utcOffset() / 60;
  return {
    FromTimestamp: filterValues?.startDate?.startOf('day'),
    ToTimestamp: filterValues?.endDate?.endOf('day'),
    ...(filterValues?.users?.length > 0 && { UserIds: filterValues?.users }),
    ...(filterValues?.properties?.length > 0 && {
      PropertyIds: filterValues?.properties,
    }),
    ActionTypes: ActivityLogsActionsTypes,
    SearchTerm: searchTerm,
    timeZoneOffset: now,
    queryParams: `?pageNum=${currentPageNum}&pageSize=${pgSize}`,
  };
};

export const getDayAndDateTitleStr = (dateTimestamp) => {
  let dateStr = moment(new Date(dateTimestamp)).format('dddd, MMM D, YYYY');
  if (moment().add(-1, 'days').format('dddd, MMM D, YYYY') === dateStr) {
    return 'Yesterday';
  }
  if (moment().format('dddd, MMM D, YYYY') === dateStr) {
    return 'Today';
  }
  return dateStr;
};

function isTenMinutesWindow(timestamp1, timestamp2) {
  const time1 = moment(timestamp1);
  const time2 = moment(timestamp2);
  if (time1 === time2) return true;
  const differenceInMinutes = Math.abs(time1.diff(time2, 'minutes'));

  return differenceInMinutes <= 10;
}
function getCategoryName(categoryId, categoriesArray: any[]) {
  return categoriesArray?.filter((x) => x.Id === categoryId)?.[0]?.Name || null;
}
const getHighLights = (logsArray: any, filters: any = {}) => {
  const highLights: any = [];
  let totalTransactions = {};
  logsArray.forEach((log) => {
    if (!totalTransactions[log.Payload.Transaction.Id]) {
      totalTransactions[log.Payload.Transaction.Id] = 1;
    }
    if (filters.MileStone) {
      highLights.push({
        key: log.Payload.MilestoneName,
        oldValue: '',
        newValue: '',
        taskGroup: true,
        taskId: null,
        transactionId: log.Payload.Transaction.Id,
        milestoneId: log.Payload.MilestoneId,
        isTemplate: true,
        transactionDetails: log.Payload.Transaction,
        actionTimestamp: log.ActionTimestamp,
      });
    } else {
      highLights.push({
        key: log.Payload.Title,
        oldValue: '',
        newValue: '',
        taskGroup: true,
        taskId: log.Payload.TaskId,
        transactionId: log.Payload.Transaction.Id,
        transactionDetails: log.Payload.Transaction,
        CategoryId: log?.Payload?.CategoryId,
        actionTimestamp: log.ActionTimestamp,
        assignees: log?.Payload?.AssigneeList,
        categoryName: getCategoryName(log?.Payload?.CategoryId, filters.categories),
      });
    }
  });

  return { highLights, totalTransactions: Object.keys(totalTransactions).length };
};
const makeTaskGroup = (items: any, userIdsHash = {}, categories = []) => {
  if (!items.length) return [];
  const groupedAbleTask = {
    TaskUpcoming: ActivityGroupingTypes.TaskUpcoming,
    NewTask: ActivityGroupingTypes.NewTask,
    TaskAssigned: ActivityGroupingTypes.TaskAssigned,
    TaskOverdue: ActivityGroupingTypes.TaskOverdue,
    TaskCompleted: ActivityGroupingTypes.TaskCompleted,
    MilestoneAdded: ActivityGroupingTypes.MilestoneAdded,
    MilestoneMissed: ActivityGroupingTypes.MilestoneMissed,
    TaskStuck: ActivityGroupingTypes.TaskStuck,
    MilestoneOverdue: ActivityGroupingTypes.MilestoneOverdue,
    MilestoneDeadlineUpcoming: ActivityGroupingTypes.MilestoneDeadlineUpcoming,
    TaskDeleted: ActivityGroupingTypes.TaskDeleted,
  };
  let usersHash = {};
  let groupedHash = {};
  let taskGroup: any = [];
  const logsArray: any = [];
  items.forEach((singleLog) => {
    if (
      singleLog.Payload.Assignee &&
      Array.isArray(singleLog.Payload.Assignee) &&
      singleLog.Payload.Assignee.length > 1
    ) {
      singleLog.Payload.Assignee.forEach((assignee) => {
        const deepLogCopy = JSON.parse(JSON.stringify(singleLog));
        deepLogCopy.Payload.Assignee = [assignee];
        if (deepLogCopy.TagLine) {
          deepLogCopy.TagLine.Assignee = [assignee];
        }
        logsArray.push(deepLogCopy);
      });
    } else {
      logsArray.push(singleLog);
    }
  });
  for (let i = logsArray.length - 1; i >= 0; i--) {
    const log = { ...logsArray[i] };
    let ActionType = log.ActionType;
    if (groupedAbleTask[ActionType]) {
      let assigneeID;
      if (ActionType === ActivityGroupingTypes.TaskAssigned) {
        assigneeID = `${log.Payload.Assignee.FirstName}${log.Payload.Assignee.LastName}`;
        log.Payload.Assignee.Id = userIdsHash[assigneeID];
      }
      if (ActionType === ActivityGroupingTypes.NewTask) {
        assigneeID = log.Payload.Assignor.Id;
      }
      if (
        ActionType === ActivityGroupingTypes.TaskUpcoming ||
        ActionType === ActivityGroupingTypes.TaskOverdue
      ) {
        assigneeID = log.Payload.Assignee[0].Assignee.Id;
      }
      if (ActionType === ActivityGroupingTypes.TaskCompleted) {
        assigneeID = log.Payload.CompletedBy.Id;
      }
      if (
        ActionType === ActivityGroupingTypes.MilestoneAdded ||
        ActionType === ActivityGroupingTypes.MilestoneMissed
      ) {
        assigneeID = log.ActionType;
      }
      if (ActionType === ActivityGroupingTypes.TaskStuck) {
        assigneeID = log.Payload.ChangedBy.Id;
      }

      if (!usersHash[assigneeID]) {
        usersHash[`${assigneeID}`] = log.ActionTimestamp;
        const obj = {};
        obj[ActionType] = [log];
        groupedHash[`${log.ActionTimestamp}${assigneeID}`] = obj;
      } else {
        const timeStamp1 = usersHash[assigneeID];
        const timestamp2 = log.ActionTimestamp;
        if (isTenMinutesWindow(timeStamp1, timestamp2)) {
          if (groupedHash[`${timeStamp1}${assigneeID}`][ActionType]) {
            groupedHash[`${timeStamp1}${assigneeID}`][ActionType].push(log);
          } else {
            const obj = {};
            obj[ActionType] = [log];
            groupedHash[`${timeStamp1}${assigneeID}`][ActionType] = [log];
          }
        } else {
          usersHash[`${assigneeID}`] = timestamp2;
          const obj = {};
          obj[ActionType] = [log];
          groupedHash[`${log.ActionTimestamp}${assigneeID}`] = obj;
        }
      }
    } else {
      taskGroup.push(log);
    }
  }
  const userGroupHashKeys = Object.entries(groupedHash).map(([key, value]) => key);
  userGroupHashKeys.forEach((key) => {
    const group = groupedHash[key];
    const groupKeys = Object.entries(group).map(([key, value]) => key);
    groupKeys.forEach((gKey) => {
      const logsArray = group[gKey];
      if (logsArray.length > 1) {
        const KeyHighlights = logsArray[0];
        const { highLights, totalTransactions } = getHighLights(logsArray, { categories });
        KeyHighlights.ActionType = `${KeyHighlights.ActionType}Summary`;
        KeyHighlights.TagLine.Highlights = highLights;
        KeyHighlights.transactionCount = totalTransactions;
        KeyHighlights.Payload.transactionCount = totalTransactions;
        KeyHighlights.Payload.isGroupedSummary = true;
        taskGroup.push(KeyHighlights);
      } else {
        taskGroup.push(logsArray[0]);
      }
    });
  });

  return taskGroup;
};
const getFirstAndLastNameFromLogItem = (assignee: any) => {
  return `${assignee.FirstName}${assignee.LastName}`;
};
const getAssignedUserIdFromPayload = (
  log: any,
  hashMapObject = {},
  getUserIdFromHash: boolean = false,
) => {
  let payload = log.Payload;
  let assignee;
  if (!payload.AssigneeList || payload.Assignee) return;
  payload?.AssigneeList
    ? (assignee = payload?.AssigneeList[0]?.Assignee)
    : payload?.Assignee && payload?.Assignee.length
    ? (assignee = payload?.Assignee)
    : (assignee = payload?.Assignee);

  if (getUserIdFromHash) return hashMapObject[getFirstAndLastNameFromLogItem(assignee)];
  if (assignee?.Id) {
    hashMapObject[getFirstAndLastNameFromLogItem(assignee)] = assignee?.Id;
  }
  return hashMapObject;
};

export const makeTaskGroupForDashboard = (logItems: any[], filters: any = {}) => {
  let userIdsHash = filters.userIds ? filters.userIds : {};
  logItems.forEach((log) => {
    getAssignedUserIdFromPayload(log, userIdsHash);
  });
  const data = makeTaskGroup(logItems, userIdsHash, filters.categories);
  let sortedData = data.sort((a, b) => {
    if (a.ActionTimestamp < b.ActionTimestamp) return 1;
    if (a.ActionTimestamp > b.ActionTimestamp) return -1;
    return 0;
  });
  return { groupedData: sortedData, userIdsHash };
};
export const formatLogsData = (logItems: any[], filters = { taskGroup: false, categories: [] }) => {
  let userIdsHash = {};
  const formattedItems: any = [];
  logItems?.length > 0 &&
    logItems?.forEach((document) => {
      if (document.ActionTimestamp) {
        const date = getDayAndDateTitleStr(document.ActionTimestamp);
        getAssignedUserIdFromPayload(document, userIdsHash);
        const index = formattedItems.findIndex((f) => f[date] !== undefined);

        if (index === -1) {
          formattedItems.push({ [date]: [document] });
        } else {
          formattedItems[index][date].push(document);
        }
      }
    });

  let taskGrouped: any = [];

  if (filters?.taskGroup) {
    formattedItems.forEach((item: any) => {
      for (let key in item) {
        const data = makeTaskGroup([...item[key]], userIdsHash, filters.categories);
        let obj = {};
        obj[key] = data.sort((a, b) => {
          if (a.ActionTimestamp < b.ActionTimestamp) return 1;
          if (a.ActionTimestamp > b.ActionTimestamp) return -1;
          return 0;
        });
        taskGrouped.push(obj);
      }
    });
  }
  return !filters.taskGroup ? formattedItems : taskGrouped;
};
