/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from 'pages/Properties/Feed/Icons';
import MatchFull from 'components/Icons/MatchFull';
import { MATCH_LEVEL } from 'settings/constants/common';
import MatchPartial from 'components/Icons/MatchPartial';
import { CircularProgressBar } from 'components';

import styles from './styles.module.scss';
import MatchNone from 'components/Icons/MatchNone';
import MatchUnknown from 'components/Icons/MatchUnknown';

const Hint = (props) => {
  const { className, matchedCriterias, matchScore } = props;

  const getMatchLevelicon = (matchLevel) => {
    if (matchLevel === MATCH_LEVEL.FULL) {
      return <MatchFull className={classNames(styles.matchIcon, styles.matchFullIcon)} />;
    } else if (matchLevel === MATCH_LEVEL.PARTIAL) {
      return <MatchPartial className={styles.matchIcon} />;
    } else if (matchLevel === MATCH_LEVEL.UNKNOWN) {
      return <MatchUnknown className={styles.matchIcon} />;
    } else {
      return <MatchNone className={styles.matchIcon} />;
    }
  };

  return (
    <div className={classNames(className)}>
      <div className={classNames(styles.hintTitle)}>
        <CircularProgressBar size={24} percentage={matchScore} />
        <span className={styles.text} testid="hint_match_score">{`${matchScore || 0}% match`}</span>
      </div>
      <div className={styles.preferences}>
        {(matchedCriterias || []).map((preference, index) => (
          <div className={styles.prefItems} key={index}>
            {preference.map((item) => (
              <div
                testid="score_hint_item"
                key={typeof item === 'object' ? item?.Feature : item}
                className={styles.item}
              >
                <div className={styles.innerItemHolder}>
                  {typeof item === 'object' ? (
                    getMatchLevelicon(item?.MatchLevel)
                  ) : (
                    <Icon variant={Icon.GREEN_CHECKMARK} className={styles.icon} />
                  )}
                  <span>{typeof item === 'object' ? item?.label : item}</span>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

Hint.propTypes = {
  className: PropTypes.string,
  matchedCriterias: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  matchScore: PropTypes.number,
};

Hint.defaultProps = {
  className: '',
  matchedCriterias: null,
  matchScore: null,
};

export default Hint;
