import { addSpaceBeforeCapitalLetters } from 'helpers/strings';
import { prefsIds } from 'settings/constants/preferences';

export const constructMatchScoreLabels = (matchScoreKey: string) => {
  switch (matchScoreKey) {
    case prefsIds.flooringHardwood:
      return 'Hardwood Flooring';
    case prefsIds.flooringCarpet:
      return 'Carpet Flooring';
    case prefsIds.flooringParquet:
      return 'Parquet Flooring';
    case prefsIds.flooringTile:
      return 'Tile Flooring';
    case prefsIds.flooringStone:
      return 'Stone Flooring';
    case prefsIds.flooringConcrete:
      return 'Concrete Flooring';
    case prefsIds.flooringOther:
      return 'Other Flooring';
    case prefsIds.kitchenFeaturesLargerThanAverage:
      return 'Kitchen Features: Larger Than Average';
    case prefsIds.kitchenFeaturesEatIn:
      return 'Kitchen Features: Eat-In';
    case prefsIds.kitchenFeaturesIsland:
      return 'Kitchen Features: Island';
    case prefsIds.kitchenFeaturesStainlessSteelAppliances:
      return 'Kitchen Features: Stainless Steel Appliances';
    default:
      return addSpaceBeforeCapitalLetters(matchScoreKey);
  }
};
