import validator from 'validator';
import AutoComplete from 'antd/lib/auto-complete';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FORM_USER_ROLE, incorrectEmail } from 'app-constants';
import { DraggableCombineMenuList, DraggableCombineMenuListItem, Text } from 'components-antd';
import { caseInsensitiveFilter } from 'helpers';
import { sortDetails, sortOptions, validationToast } from 'pages/FormProcess/helper';
import { updateFormRoleEffect, updatePreFormResponseEffect } from 'store/effects/formProcess';
import {
  getFormMetaSelector,
  getPreFormSelector,
  getSmartFormDataSelector,
} from 'store/selectors/requestFormProcess';
import { FormRoleType, SignatoriesType, SignatoryType } from 'types';

import { Input } from 'antd';
import classNames from 'classnames';
import { RemoveFile, Plus } from 'components/Icons';
import styles from './styles.module.scss';
import { Navigation } from '../components';

import { SignFormToggleRow } from './SignFormToggleRow';

export const SignForm = ({
  customForm = false,

  onContinue,
  isTemplate = false,
  saveAsTemplate = false,
  isBundle = false,
  isVoidForm = false,
  isLibraryTemplate = false,
  makeFormCopy = false,
}) => {
  const dispatch = useDispatch();

  const {
    formRoles: smartFormRoles,
    signatoriesPresent,
    clientFieldsPresent,
    showClientEditorScreen,
  } = useSelector(getSmartFormDataSelector);

  let formRoles = useMemo(() => {
    if (isLibraryTemplate) {
      return sortOptions(smartFormRoles, 'Name');
    }

    return smartFormRoles;
  }, [smartFormRoles]);

  const { clients, agents } = useSelector(getFormMetaSelector);
  const {
    signatories: allSignatories,
    isSequentialSignature,
    saveAsTemplateFormRolesIds,
    agentFormRoles,
  } = useSelector(getPreFormSelector);

  const signatoriesList = [...(agents || []), ...(clients || [])];

  const [hideClientEditRole, setHideClientEditRole] = useState(false);

  const [templateFormRoles, setTemplateFormRoles] = useState<number[]>(
    saveAsTemplate ? saveAsTemplateFormRolesIds || [] : [],
  );

  const [signatoryOptions, setSignatoryOptions] = useState(
    signatoriesList.map((signatory) => ({
      key: signatory.Id,
      label: `${signatory.FirstName} ${signatory.LastName}`,
      value: `${signatory.FirstName} ${signatory.LastName}`,
    })),
  );

  const rolesForTemplate = agentFormRoles;
  const allRoles = (rolesForTemplate || []).map((role) => ({
    key: role.Id,
    label: role.Name,
    value: role.Name,
  }));

  const updateFormRoles = (updatedFormRoles: FormRoleType[]) => {
    dispatch(updateFormRoleEffect({ formRoles: updatedFormRoles }));
  };

  const [signatories, setSignatoriesState] = useState<SignatoryType[]>([]);
  const signatoriesDataCount = signatories.reduce((count, sig) => {
    if (sig.Email || sig.Name) return count + 1;
    return count;
  }, 0);

  useEffect(() => {
    const selectedOptions = signatories.map((value) => value.UserId).filter((item) => item);
    const filteredOptions = signatoryOptions.filter((item) => !selectedOptions.includes(item.key));

    setSignatoryOptions(filteredOptions);

    if (signatories.length === 1 && isSequentialSignature) {
      handleSequentialSigning();
    }
  }, [signatories]);

  useEffect(() => {
    const updatedSignatories: SignatoriesType = {};

    const availableSignatories = signatories.filter(
      (signatory) =>
        signatory.UserId || signatory.Email || (makeFormCopy && signatory.OldSignatoryEmail),
    );

    availableSignatories.forEach((signatory) => {
      if (signatory.FormRole) {
        updatedSignatories[signatory.FormRole] = {
          ...signatory,
          SignatureSequence: signatory.SignatureSequence || 1,
        };
      }
    });

    dispatch(
      updatePreFormResponseEffect({
        signatories: updatedSignatories,
      }),
    );
  }, [signatories, isSequentialSignature]);

  const handleOnBlur = (signatoryIndex: number) => {
    const signatory = signatories?.[signatoryIndex];

    if (signatory) {
      let updatedSignatories = [...signatories];
      updatedSignatories[signatoryIndex].customName =
        !!signatory.Name?.trim().length && !signatory.UserId;

      if (signatory.Email && !validator.isEmail(signatory.Email.trim())) {
        updatedSignatories[signatoryIndex].Email = '';

        validationToast(incorrectEmail);
      }

      if (makeFormCopy) {
        const { customName, Email } = updatedSignatories[signatoryIndex];
        updatedSignatories[signatoryIndex].IsReplaced = customName || !Email;
      }

      setSignatoriesState(updatedSignatories);
    }
  };

  const onBlurEmail = (signatoryIndex, email?: string) => {
    if (signatories && email && !validator.isEmail(email.trim())) {
      let updatedSignatories = [...signatories];
      updatedSignatories[signatoryIndex].Email = '';
      validationToast(incorrectEmail);
      setSignatoriesState(updatedSignatories);
    }
  };

  useEffect(() => {
    modifySignatories();
  }, [isSequentialSignature]);

  const handleSequentialSigning = () => {
    dispatch(
      updatePreFormResponseEffect({
        isSequentialSignature: !isSequentialSignature,
      }),
    );
  };

  const updateClientEditRole = (roleId) => {
    dispatch(
      updatePreFormResponseEffect({
        clientEditorRole: roleId,
      }),
    );
  };

  const modifySignatories = () => {
    let result: SignatoryType[] = [];
    const availableSignatories = allSignatories || {};

    if (formRoles?.length) {
      formRoles.forEach((role) => {
        const roleId = role.Id;
        if (availableSignatories[roleId]) {
          result.push(availableSignatories[roleId]);
        }
      });

      formRoles.forEach((role) => {
        const roleId = role.Id;
        if (!availableSignatories[roleId]) {
          result.push({
            FormRole: roleId,
            SignatureSequence: result.length + 1,
          });
        }
      });
      if (isSequentialSignature) {
        result = result.map((signatory, index) => ({
          ...signatory,
          SignatureSequence: index + 1,
        }));
      }
    }

    setSignatoriesState(
      result.sort((a, b) => (a.SignatureSequence > b.SignatureSequence ? 1 : -1)),
    );
  };

  useEffect(() => {
    modifySignatories();

    if (!customForm && formRoles?.length && !showClientEditorScreen) {
      if (!clientFieldsPresent) {
        setHideClientEditRole(true);
      } else {
        const clientRoles = formRoles.filter((role) => role.UserType === FORM_USER_ROLE.Client);
        if (clientRoles.length === 1) {
          setHideClientEditRole(true);
          updateClientEditRole(clientRoles[0].Id);
        } else if (clientRoles.length === 0) {
          setHideClientEditRole(true);
        } else {
          updateClientEditRole(clientRoles[0].Id);
        }
      }
    } else if (customForm && !formRoles?.length) {
      addEmptyRole();
    }
  }, [formRoles]);

  const getInitialItems = () =>
    signatories.map((signer) => ({
      id: signer.FormRole,
      sequence: signer.SignatureSequence,
    })) as DraggableCombineMenuListItem[];

  const onSequenceUpdate = (updatedSigners: DraggableCombineMenuListItem[]) => {
    if (signatories?.length) {
      setSignatoriesState(
        signatories.map((signer) => {
          const updated = { ...signer };
          const item = updatedSigners.find((item) => item.id === signer.FormRole);
          if (item?.id) {
            updated.SignatureSequence = item.sequence;
          }

          return updated;
        }),
      );
    }
  };

  const handleRoleDelete = (roleId, signatoryIndex = -1, userId = -1) => {
    updateFormRoles(formRoles.filter((role) => role.Id !== roleId));

    if (customForm && userId >= 0) {
      // Setting item back which got filtered to not show in list, so it will be shown again
      const listItem = signatoriesList.find((item) => item.Id === userId);

      if (listItem) {
        const { Id, FirstName, LastName } = listItem;

        const optionItem = {
          key: Id,
          label: `${FirstName} ${LastName}`,
          value: `${FirstName} ${LastName}`,
        };

        setSignatoryOptions((prev) => [...prev, optionItem]);
      }
    }

    if (saveAsTemplate) {
      const sortedSignatories = sortDetails(signatories, 'SignatureSequence');

      const index = sortedSignatories.findIndex(({ FormRole }) => FormRole === roleId);

      setTemplateFormRoles(templateFormRoles.filter((_, roleIndex) => roleIndex !== index));
    }
  };

  const handleSignatoriesSelect = (value: string, index, option) => {
    const userId = option.key;
    const signatory = signatories?.[index];
    const details = signatoriesList.find((sig) => sig.Id === userId);

    if (signatory) {
      const updatedSignatories = [...signatories];
      const signatoryData: SignatoryType = {
        ...signatory,
        UserId: userId,
        Name: value,
        Email: details?.Email,
        ...(makeFormCopy && {
          IsReplaced: signatory.OldSignatoryEmail !== details?.Email,
        }),
      };

      signatoryData.customName && delete signatoryData.customName;

      updatedSignatories[index] = { ...signatoryData };
      setSignatoriesState(updatedSignatories);
    }
  };

  const handleSignatoriesChange = (
    key: string,
    value: string,
    index: number,
    smartForm = false,
  ) => {
    const signatory = signatories?.[index];

    if (signatory) {
      if (signatory.UserId) {
        const isExist = signatoryOptions.find(({ key }) => key === signatory.UserId);
        if (!isExist) {
          signatoryOptions.push({
            key: signatory.UserId,
            label: signatory.Name as string,
            value: signatory.Name as string,
          });
          setSignatoryOptions(signatoryOptions);
        }
      }

      const updatedSignatories = [...signatories];
      const signatoryData = {
        ...signatory,
        [key]: value,

        ...(makeFormCopy &&
          key === 'Email' && {
            IsReplaced: signatory.OldSignatoryEmail !== value,
          }),
      };

      (signatoryData.UserId || (key === 'Name' && !value?.trim().length)) &&
        delete signatoryData.Email;
      delete signatoryData.UserId;

      updatedSignatories[index] = { ...signatoryData };
      setSignatoriesState(updatedSignatories);
    }
  };

  const modifyInSignatory = (value, index) => {
    const updatedSignatories = [...signatories];

    updatedSignatories[index].FormRoleId = value || '';
    setSignatoriesState(updatedSignatories);
  };

  const handleTemplateRoleChange = (value: string, index, option) => {
    let roles = [...templateFormRoles];

    if (!value && roles[index]) {
      delete roles[index];
      setTemplateFormRoles([...roles]);
    } else {
      roles[index] = option.key;
      setTemplateFormRoles(roles);
    }

    saveAsTemplate && modifyInSignatory(option.key, index);
  };

  const renderFormSignatory = (item: DraggableCombineMenuListItem) => {
    const signatoryIndex = signatories?.findIndex((signatory) => signatory.FormRole === item?.id);
    const signatory = signatories?.[signatoryIndex];

    const roleIndex = formRoles?.findIndex((role) => role.Id === signatory?.FormRole);
    const role = roleIndex >= 0 ? formRoles?.[roleIndex] : undefined;

    if (!signatory || !role) {
      return <></>;
    }

    const getNumberTag = (num: number) => {
      if (!isSequentialSignature) return;
      return <div className={styles.numberTag}>{num}</div>;
    };

    const getRoleName = (roleId: number | undefined) => {
      if (roleId && saveAsTemplate) {
        return allRoles.find((role) => role.key === roleId)?.label;
      }
    };

    const getRoleOptions = () => {
      let roles = templateFormRoles?.length
        ? allRoles.filter(({ key }) => !templateFormRoles.includes(key))
        : allRoles;

      return sortOptions(roles, 'label');
    };

    let autoCompleteValue = !customForm
      ? signatory?.Name || signatory?.Email || getRoleName(templateFormRoles[signatoryIndex])
      : '';

    return (
      <div className={classNames(styles.checkboxRow)} key={item.id}>
        {getNumberTag(item.sequence)}

        {customForm || isVoidForm ? (
          <div className={styles.fieldWrapper}>
            <div className={styles.fieldsContainer}>
              {saveAsTemplate ? (
                <div className={classNames(styles.field)}>
                  <div className={styles.label}>Role</div>

                  <AutoComplete
                    value={getRoleName(signatories[signatoryIndex].FormRoleId)}
                    onSelect={(value, option) =>
                      handleTemplateRoleChange(value, signatoryIndex, option)
                    }
                    onChange={(email) => handleTemplateRoleChange(email, signatoryIndex, {})}
                    placeholder="Select Role..."
                    bordered={false}
                    className={styles.autoCompleteInput}
                    options={getRoleOptions()}
                    filterOption={(inputValue, option) =>
                      caseInsensitiveFilter(inputValue, option?.label as string)
                    }
                    allowClear
                    getPopupContainer={(node) => node}
                    popupClassName={styles.autoCompleteDropdown}
                  />
                </div>
              ) : (
                <></>
              )}

              <div className={styles.field}>
                <div className={styles.label}>Name</div>
                <AutoComplete
                  placeholder="Enter name"
                  bordered={false}
                  className={styles.autoCompleteInput}
                  value={signatory?.Name}
                  options={[...sortOptions(signatoryOptions, 'label')]}
                  filterOption={(inputValue, option) =>
                    caseInsensitiveFilter(inputValue, option?.label as string)
                  }
                  onBlur={() => (signatory?.UserId ? undefined : handleOnBlur(signatoryIndex))}
                  onSelect={(value, option) =>
                    handleSignatoriesSelect(value, signatoryIndex, option)
                  }
                  onChange={(name) => handleSignatoriesChange('Name', name, signatoryIndex)}
                  allowClear
                  getPopupContainer={(node) => node}
                  popupClassName={styles.autoCompleteDropdown}
                />
              </div>

              <div className={styles.field}>
                <div className={styles.label}>Email</div>
                <Input
                  className={styles.email}
                  placeholder={'Enter email address'}
                  value={signatory.Email}
                  disabled={!!signatory.UserId}
                  onChange={({ target }) =>
                    !signatory?.UserId
                      ? handleSignatoriesChange('Email', target.value, signatoryIndex)
                      : null
                  }
                  onBlur={() => onBlurEmail(signatoryIndex, signatory.Email)}
                />
              </div>
            </div>

            {item.sequence > 1 && (
              <div className={styles.removeButton}>
                <RemoveFile
                  onClick={() => {
                    handleRoleDelete(role.Id, signatoryIndex, signatory?.UserId);
                  }}
                />
              </div>
            )}
          </div>
        ) : (
          <div className={classNames(styles.fieldWrapper)}>
            <div
              className={classNames(styles.field, {
                [styles.signFormField]: !autoCompleteValue,
              })}
            >
              {role.Name ? <div className={styles.label}>{role.Name}</div> : <></>}

              <div className={styles.fieldsContainer}>
                <div className={styles.field}>
                  <AutoComplete
                    placeholder={'Enter name...'}
                    bordered={false}
                    className={styles.autoCompleteInput}
                    value={signatory?.Name || getRoleName(templateFormRoles[signatoryIndex])}
                    options={sortOptions(signatoryOptions, 'label')}
                    filterOption={(inputValue, option) =>
                      caseInsensitiveFilter(inputValue, option?.label as string)
                    }
                    onBlur={() => handleOnBlur(signatoryIndex)}
                    onSelect={(value, option) =>
                      handleSignatoriesSelect(value, signatoryIndex, option)
                    }
                    onChange={(value) =>
                      handleSignatoriesChange('Name', value, signatoryIndex, true)
                    }
                    onKeyDown={(e) => {
                      if (e.code === 'Tab') {
                        const element = document.getElementById(`Email-${item.id}`);
                        if (element) {
                          setTimeout(() => element.focus(), 100);
                        }
                      }
                    }}
                    allowClear
                    getPopupContainer={(node) => node}
                    popupClassName={styles.autoCompleteDropdown}
                  />
                </div>

                <div className={styles.field}>
                  <Input
                    className={styles.email}
                    placeholder={'Enter email'}
                    value={signatory.Email}
                    disabled={!!signatory.UserId || !signatory.customName}
                    onBlur={() => handleOnBlur(signatoryIndex)}
                    onChange={({ target }) =>
                      !signatory?.UserId
                        ? handleSignatoriesChange('Email', target.value, signatoryIndex)
                        : null
                    }
                    id={`Email-${item.id}`}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const addEmptyRole = () => {
    const max = formRoles.reduce((max, formRole) => (formRole.Id > max ? formRole.Id : max), 0);
    const newRoleId = max + 1;

    updateFormRoles([
      ...formRoles,
      {
        Id: newRoleId,
        Name: '',
        RoleId: newRoleId,
        UserType: FORM_USER_ROLE.Agent,
      },
    ]);
  };

  const isDisabled = () => {
    if (isSequentialSignature || makeFormCopy) {
      let hasEmptySignatory;
      if (!customForm && !isVoidForm) {
        hasEmptySignatory = signatories.find(
          (item) => (item.Name && !item.Email) || (item.Email && !item.Name),
        );

        hasEmptySignatory = hasEmptySignatory || !signatoriesDataCount;
      } else {
        hasEmptySignatory = signatories.find((item) => !item.Name || !item.Email);
      }

      return hasEmptySignatory;
    } else if (saveAsTemplate) {
      const allRolesFilled = templateFormRoles?.every((item) => item);

      const hasEmptySignatory = signatories.find((item) => !item.Name || !item.Email);
      return hasEmptySignatory || !allRolesFilled;
    } else if (isTemplate) {
      const hasSignatory = signatories.find(
        (item) => !item.customName && (item.Name || item.Email),
      );

      const hasCustomName = signatories.find((item) => item.customName);

      const hasFilledCustomSignatory = signatories.find(
        (item) => item.customName && item.Name && item.Email,
      );

      return (
        !(hasSignatory || (hasCustomName && hasFilledCustomSignatory)) ||
        (hasSignatory && hasCustomName && !hasFilledCustomSignatory)
      );
    } else if (isBundle) {
      return !signatories.find((item) => item.Name && item.Email);
    }

    return false;
  };

  const handleContinue = () => {
    if (saveAsTemplate) {
      const sequencedRoleIds = sortDetails(signatories, 'SignatureSequence').map(
        ({ FormRoleId }) => FormRoleId,
      );

      dispatch(
        updatePreFormResponseEffect({
          saveAsTemplateFormRolesIds: sequencedRoleIds,
        }),
      );
    }
    onContinue();
  };

  const items = getInitialItems();

  const showSequence = signatoriesDataCount > 1;
  return signatoriesPresent || formRoles?.length || isBundle ? (
    <>
      <Text className={styles.questionHeader}>Who needs to sign?</Text>

      <div
        className={classNames(styles.pageContent, {
          [styles.saveAsTemplatePageContent]: (customForm || isVoidForm) && saveAsTemplate,
        })}
      >
        <DraggableCombineMenuList
          initialItems={items}
          onItemUpdate={onSequenceUpdate}
          renderFunction={renderFormSignatory}
          isDisabled={!isSequentialSignature}
          isCombinedEnabled={false}
          droppableClassName={`${styles.fieldsDroppable}`}
        />

        {customForm || isVoidForm ? (
          <div className={styles.add} onClick={addEmptyRole}>
            <Plus color="#515151" className={styles.addIcon} />
            <div className={styles.addText}>Add Another</div>
          </div>
        ) : (
          ''
        )}

        {showSequence && (
          <SignFormToggleRow
            isSequentialSignature={isSequentialSignature}
            handleSequentialSigning={handleSequentialSigning}
            className={styles.listSignSequence}
          />
        )}

        <Navigation
          onContinue={handleContinue}
          disabled={isDisabled()}
          className={classNames(styles.horizontalListButton, {
            [styles.navigationButton]: signatoriesDataCount < 2,
            [styles.buttonWithoutSequence]: !showSequence,
          })}
        />
      </div>
    </>
  ) : (
    <Text className={styles.questionHeader}>No Signatories Available for this form</Text>
  );
};
