import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LocalHeader } from './LocalHeader';
import { OffersTable } from './components/OffersTable';
import {
  resetOfferAggregateEffect,
  setOffersFiltersEffect,
  setOffersIsArchiveEffect,
} from 'store/effects/offerAggregate';
import { getUserId } from 'store/selectors/user';
import {
  getOfferAggregateDataSelector,
  getOfferFiltersSelector,
} from 'store/selectors/offerAggregate';
import { getOfferAggregateByUserIdEffect } from 'store/effects/offerAggregate';

import styles from './styles.module.scss';
import { PageWrapper } from 'pages/Workshop/PageWrapper';
import { LocationService } from 'services';
import moment from 'moment';
import _ from 'lodash';

export const Offers = () => {
  const dispatch = useDispatch();

  const { offersFilter } = useSelector(getOfferFiltersSelector);

  const userId = useSelector(getUserId);
  const offerAggregate = useSelector(getOfferAggregateDataSelector);
  const locationSrv = new LocationService();
  locationSrv.setLocation(location);
  const query = locationSrv.getQuery();

  useEffect(() => {
    if (Object.keys(query).length) {
      if (query.isArchive) dispatch(setOffersIsArchiveEffect(true));

      const categorizedFilter = query.teamStats ? {} : { isMyOffers: true };
      const allFilters = {
        ...(query.status && { status: query.status.split(',') }),
        ...(query.statuses && { statuses: query.statuses.split(',') }),
        ...(query.dueToday && { expiresFromDate: moment(), expiresToDate: moment() }),
      };

      if (!_.isEmpty(allFilters) || !_.isEmpty(categorizedFilter)) {
        dispatch(setOffersFiltersEffect({ allFilters, categorizedFilter }));
      } else {
        getOffers();
      }
    }

    return () => {
      dispatch(resetOfferAggregateEffect());
      dispatch(setOffersFiltersEffect({ searchString: '', allFilters: {}, categorizedFilter: {} }));
    };
  }, []);

  useEffect(() => {
    const { allFilters = {}, categorizedFilter = {}, searchString } = offersFilter;

    getOffers({ ...(searchString && { searchString }), ...allFilters, ...categorizedFilter });
  }, [offersFilter]);

  const getOffers = (filters = {}) => {
    dispatch(
      getOfferAggregateByUserIdEffect({
        id: userId,
        filters,
      }),
    );
  };

  return (
    <PageWrapper isContainer={false}>
      <div className={styles.offersPage}>
        <LocalHeader />
        <div className={styles.offersWrapper}>
          <OffersTable offerAggregate={offerAggregate} />
        </div>
      </div>
    </PageWrapper>
  );
};
