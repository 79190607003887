import { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory, useParams } from 'react-router-dom';

import { Controls } from './Controls';
import { link } from 'settings/navigation/link';
import { Spinner } from 'components';
import { routes } from 'settings/navigation/routes';
import {
  transactionPreFormQuestionsIds,
  transactionStatuses,
} from 'settings/constants/transaction';
import { getTransactionSelector } from 'store/selectors/transaction';
import { resetTransactionCreateAction } from 'store/actions/transactions';
import { getAllTransactionFormProcessEffect } from 'store/effects/formProcess';
import {
  getTransactionEffect,
  getTransactionClientAddressEffect,
  updateTransactionUnderContractEffect_,
  getTransactionRolesEffect,
  setTransactionPreFormQuestionsEffect,
} from 'store/effects/transactions';
import { getTransactionLedgers } from 'api/financials';

import {
  Participants,
  PurchasePrice,
  Timeline,
  TransactionTemplate,
  ClosingDate,
  FinancingType,
  UploadFiles,
  EffectiveDate,
  FinancingDetails,
} from '../TransactionCreate/components/PreForm/Questions';
import { ContentWrapper } from '../TransactionCreate/components';
import {
  getAgentTeamDetailSelector,
  getAgentTeamIsActiveSelector,
} from 'store/selectors/agentTeamDetail';
import { requestGetTeamListEffect } from 'store/effects';

import styles from './styles.module.scss';
import { requestGetUserParticipantsListEffect } from 'store/effects/drawers/addParticipants';

import TransactionMembers from '../TransactionCreate/components/PreForm/Questions/TransactionMembers';
import { LedgerTransactionSideId } from 'settings/constants/ledger';
import {
  getLedgerId,
  getCommissionSide,
  isFeeTypePercentage,
} from '../TransactionFinancials/helpers';
import { requestGetAgentCoordinatorRelatedContactsEffect } from 'store/effects/relatedContacts';
import { getUserRolesMapSelector } from 'store/selectors/user';
import {
  calculateGCI,
  calculateRate,
} from '../TransactionCreate/components/PreForm/Questions/FinancingDetails/validationHelpers';

export const TransactionUnderContract = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const params: { id?: string } = useParams();

  const [loading, setLoading] = useState(false);
  const [stage, setStage] = useState<string>(
    transactionPreFormQuestionsIds.whoIsRepresentingTheBuyer,
  );
  const [previousStage, setPreviousStage] = useState<string>();

  const handleNextStage = (nextStage: string) => {
    setPreviousStage(stage);
    setStage(nextStage);
  };

  const { transaction } = useSelector(getTransactionSelector);

  const isTeamAgentActive = useSelector(getAgentTeamIsActiveSelector);

  useEffect(() => {
    setLoading(true);
    if (isTeamAgentActive) {
      dispatch(requestGetTeamListEffect({}, {}, () => setLoading(false)));
      dispatch(requestGetAgentCoordinatorRelatedContactsEffect({ includeAgents: true }));
    } else {
      setLoading(false);
    }
  }, [isTeamAgentActive]);

  useEffect(() => {
    setupFinancialFields();
    dispatch(getTransactionRolesEffect());
    dispatch(requestGetUserParticipantsListEffect({}, { silent: true }));
  }, []);

  useEffect(
    () => () => {
      dispatch(resetTransactionCreateAction());
    },
    [],
  );

  useEffect(() => {
    if (!transaction.Id) {
      dispatch(getTransactionEffect({ id: params?.id }));
    }
    if (params?.id) {
      dispatch(getAllTransactionFormProcessEffect({ transactionId: params?.id }));
      dispatch(getTransactionClientAddressEffect({ id: params?.id }));
    }
  }, [params?.id]);

  const formatCommissionContribution = (object, comparePrice) => {
    if (!object) return null;
    return {
      IsFeeTypePercentage: object?.IsFeeTypePercentage,
      ...(object?.IsFeeTypePercentage
        ? {
            Percent: object?.Value,
            Value: calculateGCI(object?.Value, comparePrice),
          }
        : {
            Value: object?.Value,
            Percent: calculateRate(object?.Value, comparePrice),
          }),
    };
  };

  const setupFinancialFields = async () => {
    const _data = await getTransactionLedgers({ transactionId: params?.id });
    const { ledgers, ledgerLineItems } = _data || {};

    const buySideLedgerId = getLedgerId(ledgers, LedgerTransactionSideId.Purchase);
    const listSideLedgerId = getLedgerId(ledgers, LedgerTransactionSideId.Listing);

    let _buyerSideComission = getCommissionSide(ledgerLineItems, buySideLedgerId);
    let _sellerSideComission = getCommissionSide(ledgerLineItems, listSideLedgerId);

    const {
      BuySideCommissionResponsibility,
      BuyerCommissionContribution,
      SellerCommissionContribution,
    } = transaction?.TransactionCommission || {};

    // Formatting commission
    const buyerSideCommission = {
      Percent: _buyerSideComission?.Percent,
      Value: _buyerSideComission?.Amount,
      IsFeeTypePercentage: _buyerSideComission ? isFeeTypePercentage(_buyerSideComission) : true,
    };
    const sellerSideCommission = {
      Percent: _sellerSideComission?.Percent,
      Value: _sellerSideComission?.Amount,
      IsFeeTypePercentage: _sellerSideComission ? isFeeTypePercentage(_sellerSideComission) : true,
    };

    const fieldMappings = {
      buyerCommission: _buyerSideComission ? buyerSideCommission : undefined,
      sellerCommission: _sellerSideComission ? sellerSideCommission : undefined,
      buySideCommissionResponsibility: BuySideCommissionResponsibility,
      buyerCommissionContribution: formatCommissionContribution(
        BuyerCommissionContribution,
        buyerSideCommission?.Value,
      ),
      sellerCommissionContribution: formatCommissionContribution(
        SellerCommissionContribution,
        buyerSideCommission?.Value,
      ),
      whoAreYouRepresenting: {
        Buyer: true,
        Seller: true,
      },
    };

    Object.entries(fieldMappings).forEach(([key, value]) => {
      if (value !== undefined) {
        dispatch(
          setTransactionPreFormQuestionsEffect({
            [transactionPreFormQuestionsIds[key]]: value,
          }),
        );
      }
    });
  };

  const onSubmit = () => {
    setLoading(true);
    dispatch(
      updateTransactionUnderContractEffect_(
        { Id: params?.id, Status: transactionStatuses.UnderContract },
        {},
        (err) => {
          if (!err) {
            history.push(link.toTransactionOverview(transaction?.Id));
          }
          setLoading(false);
        },
      ),
    );
  };

  const onClose = () => {
    history.push(link.toTransactionOverview(String(params?.id)));
  };

  const renderStage = () => {
    switch (stage) {
      case transactionPreFormQuestionsIds.whoIsRepresentingTheBuyer:
        return (
          <TransactionMembers
            currentQuestionId={transactionPreFormQuestionsIds.whoIsRepresentingTheBuyer}
            heading="Who is representing the buyer?"
            onNext={() => handleNextStage(transactionPreFormQuestionsIds.purchasePrice)}
          />
        );
      case transactionPreFormQuestionsIds.purchasePrice:
        return <PurchasePrice setStage={handleNextStage} />;
      case transactionPreFormQuestionsIds.effectiveDate:
        return <EffectiveDate setStage={handleNextStage} />;
      case transactionPreFormQuestionsIds.closingDate:
        return <ClosingDate validateWithEffective setStage={handleNextStage} />;
      case transactionPreFormQuestionsIds.financingType:
        return <FinancingType setStage={handleNextStage} />;
      case transactionPreFormQuestionsIds.financingDetails:
        return <FinancingDetails setStage={handleNextStage} />;
      case transactionPreFormQuestionsIds.purchaseAgreement:
        return (
          <UploadFiles
            currentQuestionId={transactionPreFormQuestionsIds.purchaseAgreement}
            question="Please upload a copy of the executed purchase agreement"
            onPreFormSubmit={() =>
              handleNextStage(transactionPreFormQuestionsIds.transactionTemplate)
            }
          />
        );
      case transactionPreFormQuestionsIds.transactionTemplate:
        return <TransactionTemplate showSkipButton={false} setStage={handleNextStage} />;
      case transactionPreFormQuestionsIds.timeline:
        return <Timeline setStage={handleNextStage} />;
      case transactionPreFormQuestionsIds.assignParticipantsToRoles:
        return (
          <Participants
            heading="Do you want to invite any other collaborators?"
            currentQuestionId={stage}
            previousQuestionId={previousStage}
            setCurrentQuestionId={setStage}
            setPreviousQuestionId={setPreviousStage}
            onNext={() => {
              handleNextStage(transactionPreFormQuestionsIds.participants);
            }}
          />
        );
      case transactionPreFormQuestionsIds.participants:
        return (
          <Participants
            currentQuestionId={stage}
            onNext={() => {
              handleNextStage(transactionPreFormQuestionsIds.files);
            }}
          />
        );
      case transactionPreFormQuestionsIds.files:
        return <UploadFiles onPreFormSubmit={onSubmit} />;
      default:
        return <PurchasePrice setStage={handleNextStage} />;
    }
  };

  return (
    <div className={styles.transactionUnderContract}>
      <div className={styles.head}>
        <Controls onClose={onClose} stage={stage} setStage={handleNextStage} />
      </div>
      <ContentWrapper>{loading ? <Spinner /> : renderStage()}</ContentWrapper>
    </div>
  );
};
