import { createSelector } from 'reselect';
import {
  get,
  join,
  values,
  trim,
  flow,
  map,
  keys,
  lowerCase,
  isArray,
  isNil,
  isString,
  isEmpty,
  isNumber,
} from 'lodash-es';
import moment from 'moment';
import { PENDING } from 'settings/constants/apiState';
import {
  getDisplayMlsNameFromMarket,
  getMlsIdToDisplay,
  getAgentPhoneNumber,
  getHomeTypeLabel,
} from 'helpers/listingDisclaimer';
import {
  formatUsd,
  formatNameToAvatarLetters,
  formatNumber,
  formatMinutes,
  formatPhoneNumber,
  formatAreaUnits,
  addressToSentenceCase,
  toDecimalPlaces,
  sizeToDecimalPlaces,
  appendTypetoValue,
  formatStringWithNumber,
  formatUnwantedString,
} from 'helpers/formatters';
import {
  getArrayItemByPath,
  getArrayStringItemsByPaths,
  getFormattedArrayStringByPath,
  wrapInArray,
  wrapInArrayWithFormat,
  getArrayItemStringByPath,
  formatStringList,
  formatEachArrayValue,
  formatEachObjectValue,
  convertTime,
  getUtilitiesArrayStringItemsByPaths,
  getSimpleDate,
  daysFromToday,
  formatArrayToString,
  isValidNumericValue,
  formatRoomInfoString,
  formatDecimal,
  getGenericRoomsAsItems,
  convertToEmbededYoutubeUrl,
  filterImagesOnly,
  getArrayByPath,
  extractOrderedPhotos,
  getBathsTotalNum,
  getTaxMapKey,
  extractOrderedPhotosForFiltering,
  filterImagesOnlyFromPhotoUrls,
} from 'helpers/utils';

import {
  getPreparedAdditionalFeaturesParsed,
  getPreparedMustAndWantsMlsCriterias,
} from 'store/selectors/mlsScores';
import { FORMATES } from 'settings/constants/common';
import { openHouseSorter, preparePriceForMarkup, formatOpenHouses } from './feed';
import { PropertyType } from 'components';
const boolToYesNo = (val) =>
  val && val !== 0 && val !== '0' && val !== 'N' && val !== 'No' && val !== false ? 'Yes' : 'No';

const formattedHoaFee = (fee) =>
  formatUsd(fee, {
    ...(!Number.isInteger(fee) && { minimumFractionDigits: 0 }),
    maximumFractionDigits: 0,
  });

const stringForHoaFeeKeyDetails = (fee, feeFrequency) =>
  `${formattedHoaFee(fee)}${feeFrequency ? `/${feeFrequency}` : ''}`;

const stringForHoaFee = (fee, feeFrequency) =>
  `${formatUsd(fee)}${feeFrequency ? `/${feeFrequency}` : ''}`;

const hoaFee = (fee, feeFrequency, fee2, feeFrequency2, fee3 = null, feeFrequency3 = null) => {
  if (fee && fee2 && fee3 && feeFrequency === feeFrequency2 && feeFrequency3 === feeFrequency) {
    return [stringForHoaFee(+fee + +fee2 + +fee3, feeFrequency)];
  } else if (fee && fee2 && feeFrequency === feeFrequency2) {
    return [stringForHoaFee(+fee + +fee2, feeFrequency)];
  } else if (fee && fee3 && feeFrequency === feeFrequency3) {
    return [stringForHoaFee(+fee + +fee3, feeFrequency)];
  } else if (fee2 && fee3 && feeFrequency2 === feeFrequency3) {
    return [stringForHoaFee(+fee2 + +fee3, feeFrequency)];
  }
  return [
    ...(fee ? [stringForHoaFee(fee, feeFrequency)] : []),
    ...(fee2 ? [stringForHoaFee(fee2, feeFrequency2)] : []),
    ...(fee3 ? [stringForHoaFee(fee3, feeFrequency3)] : []),
  ];
};
const hoaFeeForKeyDetails = (
  fee,
  feeFrequency,
  fee2,
  feeFrequency2,
  fee3 = null,
  feeFrequency3 = null,
) => {
  if (fee && fee2 && fee3 && feeFrequency === feeFrequency2 && feeFrequency3 === feeFrequency) {
    return [stringForHoaFeeKeyDetails(+fee + +fee2 + +fee3, feeFrequency)];
  } else if (fee && fee2 && feeFrequency === feeFrequency2) {
    return [stringForHoaFeeKeyDetails(+fee + +fee2, feeFrequency)];
  } else if (fee && fee3 && feeFrequency === feeFrequency3) {
    return [stringForHoaFeeKeyDetails(+fee + +fee3, feeFrequency)];
  } else if (fee2 && fee3 && feeFrequency2 === feeFrequency3) {
    return [stringForHoaFeeKeyDetails(+fee2 + +fee3, feeFrequency)];
  }
  return [
    ...(fee ? [stringForHoaFeeKeyDetails(fee, feeFrequency)] : []),
    ...(fee2 ? [stringForHoaFeeKeyDetails(fee2, feeFrequency2)] : []),
    ...(fee3 ? [stringForHoaFeeKeyDetails(fee3, feeFrequency3)] : []),
  ];
};

const unCategoizedRoomInfoFormat = (data) => [
  ...getArrayItemStringByPath({
    data,
    path: 'Category',
    format: (val) => `Category: ${val}`,
  }),
  ...roomInfoFormat(data),
];

const roomInfoFormat = (data) => {
  // Validate if the "Size" is a string and has a length greater than 0
  const isAValidSize = data.Size && `${sizeToDecimalPlaces(data.Size)}`?.length > 0;

  let detailsArr = [
    ...getArrayItemStringByPath({
      data,
      path: 'Size',
      format: (val) => val && `Size: ${val && sizeToDecimalPlaces(val, 2)}`,
    }),
    ...getArrayItemStringByPath({
      data,
      path: 'Level',
      format: (val) => val && `Level: ${val && val}`,
    }),
    ...getArrayItemStringByPath({
      data,
      path: 'Flooring',
      format: (val) => (val && val.length ? `Flooring: ${formatStringList(val)}` : null),
    }),
    ...getArrayItemStringByPath({
      data,
      path: 'Features',
      format: (val) =>
        val && formatStringList(val).length ? `Features: ${formatStringList(val)}` : null,
    }),
    ...getArrayItemStringByPath({
      data,
      path: 'Description',
      format: (val) => `Description: ${formatStringList(val)}`,
    }),
    ...getArrayItemStringByPath({
      data,
      path: 'BathroomFeatures',
      format: (val) => `Bathroom Features: ${formatStringList(val)}`,
    }),
    ...getArrayItemStringByPath({
      data,
      path: 'Accessibility',
      format: (val) => `Accessibility Features: ${formatStringList(val)}`,
    }),
    ...getArrayItemStringByPath({
      data,
      path: 'Area',
      format: (val) =>
        `Area: ${isValidNumericValue ? formatNumber(val, { maximumFractionDigits: 2 }) : val}`,
    }),

    // If the size is available, then hide length and width fields since these fields are redundant
    ...(isAValidSize
      ? []
      : [
          ...getArrayItemStringByPath({
            data,
            path: 'Length',
            format: (val) => `Length: ${toDecimalPlaces(val, 2)}`, //toDecimalPlaces(val, 2)
          }),
          ...getArrayItemStringByPath({
            data,
            path: 'Width',
            format: (val) => `Width: ${toDecimalPlaces(val, 2)}`, //toDecimalPlaces(val, 2)
          }),
        ]),
    ...getArrayItemStringByPath({
      data,
      path: 'LengthWidthSource',
      format: (val) => `Length/Width Source: ${val}`,
    }),
    ...getArrayItemStringByPath({
      data,
      path: 'LengthWidthUnits',
      format: (val) => `Length/Width Units: ${val}`,
    }),
    ...getArrayItemStringByPath({
      data,
      path: 'AreaSource',
      format: (val) => `Area Source: ${val}`,
    }),
    ...getArrayItemStringByPath({
      data,
      path: 'AreaUnits',
      format: (val) => `Area Units: ${val}`,
    }),
    ...getArrayItemStringByPath({
      data,
      path: 'BathroomType',
      format: (val) => `Bathroom Type: ${val}`,
    }),
    ...getArrayItemStringByPath({
      data,
      path: 'BathroomSize',
      format: (val) => `Bathroom Size: ${val && toDecimalPlaces(val, 2)}`,
    }),
    ...getArrayItemStringByPath({
      data,
      path: 'NumberFullBaths',
      format: (val) => `Full Baths: ${val}`,
    }),
    ...getArrayItemStringByPath({
      data,
      path: 'NumberHalfBaths',
      format: (val) => `Half Baths: ${val}`,
    }),
    ...getArrayItemStringByPath({
      data,
      path: 'NumberThreeQtrBaths',
      format: (val) => `Three Qtr Baths : ${val}`,
    }),
    ...getArrayItemStringByPath({
      data,
      path: 'EgressWindow',
      format: (val) => `Egress Window : ${val}`,
    }),
    ...getArrayItemStringByPath({
      data,
      path: 'RoomType',
      format: (val) => `Room Type : ${val}`,
    }),
  ];
  if (!detailsArr.length) {
    detailsArr = ['Details Not Available'];
  }
  return detailsArr;
};

const agentInfoFormat = (data) => [
  ...getArrayItemStringByPath({ data, path: 'FullName', format: (val) => `Name: ${val}` }),
  ...getArrayItemStringByPath({ data, path: 'Name', format: (val) => `Name: ${val}` }),
  ...getArrayItemStringByPath({
    data,
    path: 'Email',
    format: (val) => `Email: ${formatStringList(val)}`,
  }),
  ...getArrayItemStringByPath({
    data,
    path: 'PhoneOffice',
    format: (val) => `Phone Office: ${val}`,
  }),
  ...getArrayItemStringByPath({
    data,
    path: 'PhoneDirect',
    format: (val) => `Phone Direct: ${val}`,
  }),
  ...getArrayItemStringByPath({ data, path: 'Fax', format: (val) => `Fax: ${val}` }),
  ...getArrayItemStringByPath({
    data,
    path: 'Designation',
    format: (val) => `Designation: ${val}`,
  }),

  ...getArrayItemStringByPath({ data, path: 'Type', format: (val) => `Type: ${val}` }),
  ...(formatPhoneNumber(get(data, 'Phone'))
    ? getArrayItemStringByPath({
        data,
        path: 'Phone',
        format: (val) => `Phone: ${formatPhoneNumber(val)}`,
      })
    : []),
  ...getArrayItemStringByPath({
    data,
    path: 'PhoneMobile',
    format: (val) => `Phone: ${formatPhoneNumber(val)}`,
  }),
  ...getArrayItemStringByPath({ data, path: 'PhoneExt', format: (val) => `Ext: ${val}` }),
  ...getArrayItemStringByPath({
    data,
    path: 'Instructions',
    format: (val) => `Instructions: ${formatStringList(val)}`,
  }),
  ...getArrayItemStringByPath({
    data,
    path: 'Method',
    format: (val) => `Method: ${val}`,
  }),
  ...getArrayItemStringByPath({
    data,
    path: 'Requirements',
    format: (val) => `Requirements: ${formatStringList(val)}`,
  }),
  ...getArrayItemStringByPath({
    data,
    path: 'LockBoxDescription',
    format: (val) => `Lock Box Description: ${formatStringList(val)}`,
  }),
  ...getArrayItemStringByPath({
    data,
    path: 'ContactType',
    format: (val) => `Contact Type: ${val}`,
  }),
  ...getArrayItemStringByPath({ data, path: 'AccessCode', format: (val) => `AccessCode: ${val}` }),
  ...getArrayItemStringByPath({
    data,
    path: 'Financing',
    format: (val) => `Financing: ${formatStringList(val)}`,
  }),
  //...getArrayItemStringByPath({ data, path: 'Incentive', format: (val) => `Incentive: ${val}` }),
  ...getArrayItemStringByPath({ data, path: 'OfficeName', format: (val) => `OfficeName: ${val}` }),
  ...getArrayItemStringByPath({
    data,
    path: 'OfficePhone',
    format: (val) => `Office Phone: ${val}`,
  }),
  ...getArrayItemStringByPath({
    data,
    path: 'OfficePhoneExt',
    format: (val) => `Office Phone Ext: ${val}`,
  }),
  ...getArrayItemStringByPath({
    data,
    path: 'ClsgCostPdbySell',
    format: (val) => `Clsg Cost Pdby Sell: ${val}`,
  }),

  ...getArrayItemStringByPath({
    data,
    path: 'ClosePriceLotSizeSquareFeet',
    format: (val) => `Close Price Lot Size Square Feet: ${val} (${convertToPercentage(val)})`,
  }),
  ...getArrayItemStringByPath({
    data,
    path: 'CurrentPriceByLivingArea',
    format: (val) => `Current Price by Living Area: ${val} (${convertToPercentage(val)})`,
  }),
  ...getArrayItemStringByPath({
    data,
    path: 'CurrentPriceByOriginalListPrice',
    format: (val) => `Current Price by Original List Price: ${val} (${convertToPercentage(val)})`,
  }),
  ...getArrayItemStringByPath({
    data,
    path: 'CurrentPriceLotSizeSquareFeet',
    format: (val) => `Current Price Lot Size Square Feet: ${val} (${convertToPercentage(val)})`,
  }),
  ...getArrayItemStringByPath({
    data,
    path: 'ListPriceByBuildingAreaTotal',
    format: (val) => `List Price by Building Area Total: ${val} (${convertToPercentage(val)})`,
  }),
  ...getArrayItemStringByPath({
    data,
    path: 'ListPriceByLivingArea',
    format: (val) => `List Price by Living Area: ${val} (${convertToPercentage(val)})`,
  }),
  ...getArrayItemStringByPath({
    data,
    path: 'ListPriceByOriginalListPrice',
    format: (val) => `List Price by Original List Price: ${val} (${convertToPercentage(val)})`,
  }),
  ...getArrayItemStringByPath({
    data,
    path: 'ListPriceLotSizeSquareFeet',
    format: (val) => `List Price Lot Size Square Feet: ${val} (${convertToPercentage(val)})`,
  }),

  ...getArrayItemStringByPath({ data, path: 'AOR', format: (val) => `AOR: ${val}` }),

  ...getArrayItemStringByPath({
    data,
    path: 'ModificationTimestamp',
    format: (val) => `Modification Timestamp: ${moment(val).format('MM-DD-YYYY HH:mm')}`,
  }),
  ...getArrayItemStringByPath({
    data,
    path: 'NumMlsDocuments',
    format: (val) => `Num Mls Documents: ${val}`,
  }),
  ...getArrayItemStringByPath({
    data,
    path: 'NumPrivateDocuments',
    format: (val) => `Num Private Documents: ${val}`,
  }),
  ...getArrayItemStringByPath({
    data,
    path: 'NumPublicDocuments',
    format: (val) => `Num Public Documents: ${val}`,
  }),
  ...getArrayItemStringByPath({
    data,
    path: 'HasDocBoxYN',
    format: (val) => `Has Doc Box: ${val}`,
  }),

  ...getArrayItemStringByPath({ data, path: 'LoanAmount', format: (val) => `Loan Amount: ${val}` }),
  ...getArrayItemStringByPath({
    data,
    path: 'NewLoanAmount',
    format: (val) => `New Loan Amount: ${val}`,
  }),
  ...getArrayItemStringByPath({
    data,
    path: 'BuyerPoints',
    format: (val) => `Buyer Points: ${val}`,
  }),
  ...getArrayItemStringByPath({
    data,
    path: 'SellerPoints',
    format: (val) => `Seller Points: ${val}`,
  }),
  ...getArrayItemStringByPath({
    data,
    path: 'TotalPoints',
    format: (val) => `Total Points: ${val}`,
  }),

  ...getArrayItemStringByPath({
    data,
    path: 'MlsId',
    format: (val) => `MLS ID: ${val}`,
  }),
  ...getArrayItemStringByPath({
    data,
    path: 'StateLicenseNumber',
    format: (val) => `State License Number: ${val}`,
  }),
  ...getArrayItemStringByPath({
    data,
    path: 'NationalAssociationId',
    format: (val) => `National Association ID: ${val}`,
  }),
  ...getArrayItemStringByPath({
    data,
    path: 'TeamName',
    format: (val) => `Team Name: ${val}`,
  }),
  ...getArrayItemStringByPath({
    data,
    path: 'TeamLeadAgentName',
    format: (val) => `Team Lead: ${val}`,
  }),

  ...getArrayItemStringByPath({
    data,
    path: 'SourceListTeamName',
    format: (val) => `Source Team Name: ${val}`,
  }),
];

const convertToPercentage = (value) => {
  return (value * 100).toFixed(2) + '%';
};

const localState = ({ listingDetail }) => listingDetail;

const filterEmptyValues = (value) => {
  if (isNil(value)) return false;
  if (typeof value === 'string') {
    const formatted = value.toLowerCase().trim();
    return !['', 'not applicable', 'null', ' null', null].includes(formatted);
  }
  if (isArray(value)) return !!value.filter(filterEmptyValues).length;
  return true;
};

export const getListingDetailData = createSelector(localState, ({ data }) => data);

export const getListingDetailFavoriteState = createSelector(
  localState,
  ({ favoriteState }) => favoriteState,
);

export const getListingMarket = createSelector(getListingDetailData, (data) => {
  return get(data, 'Market');
});

export const getFormattedListingDetailData = createSelector(getListingDetailData, (data) => {
  let line1 = get(data, 'Address.Line1') || 'Private Address';
  const unitNumber = get(data, 'Address.Line2') ? `Unit ${get(data, 'Address.Line2')}` : '';
  let city = get(data, 'Address.City');
  const state = get(data, 'Address.State');
  const zip = get(data, 'Address.Zip') || '';
  let square = get(data, 'SquareFeet');
  const propertyHistory = get(data, 'PropertyHistory');
  const propertyType = get(data, 'HomeType');
  if (!square && propertyType === 'Land') {
    const lotSizeAcres = get(data, 'LotSizeAcres');
    const lotSizeSqFeet = get(data, 'LotSizeSquareFeet');
    if (lotSizeAcres && +lotSizeAcres < 0.25 && lotSizeSqFeet) {
      square = `${formatNumber(lotSizeSqFeet)} sqft`;
    } else if (lotSizeAcres) {
      square = `${formatNumber(lotSizeAcres)} acre`;
    } else {
      const lotSizeInfo = get(data, 'ExteriorAndLotDetails.LotSizeInfo');
      if (lotSizeInfo) square = lotSizeInfo;
      else square = '';
    }
  }

  if (line1.includes('Undefined')) {
    line1 = undefined;
  }
  if (data.Market === 'bright') {
    line1 = line1.replace('#', 'Unit ');

    line1 = addressToSentenceCase(line1);
    city = addressToSentenceCase(city);
  }

  const addressLine1 = join([...(line1 ? [line1, unitNumber] : [])], ' ');
  const addressLine2 = `${join([...(city ? [city] : []), ...(state ? [state] : [])], ', ')} ${zip}`;
  const activityStatus = get(data, 'ActivityStatus');
  const phoneNumber = get(data, 'AgentInfo.ListingAgent.PhoneMobile');

  const mlsName = getDisplayMlsNameFromMarket(get(data, 'Market'));
  const mlsId = get(data, 'ListingId');
  const photoCount = get(data, 'PhotoCount');
  const Media = get(data, 'Media');
  let mlsLastUpd = get(data, 'LastUpdatedInMLS');
  if (!mlsLastUpd) mlsLastUpd = get(data, 'ModifiedDate');
  const updated = get(data, 'Updated');
  const revisedMlsId = getMlsIdToDisplay(get(data, 'Market'), mlsId ? mlsId : get(data, 'Id'));
  const listingAgentName = get(data, 'ListingAgentName');
  const listingOfficeName = get(data, 'ListingOfficeName');
  const attributionContact = get(data, 'AgentInfo.AttributionContact');
  let courtesy = trim(
    `${listingAgentName || ''}, ${listingOfficeName || ''}${
      (listingAgentName || listingOfficeName) && phoneNumber && mlsName === 'CRMLS'
        ? ' (' + phoneNumber + ')'
        : ''
    }`,
    ', ',
  );
  if (attributionContact && mlsName === 'PRIMEMLS') {
    courtesy = `${courtesy} (${attributionContact})`;
  }

  const price = get(data, 'SellingPrice');
  const closingPrice = get(data, 'ClosePrice');
  const sourceSection = [
    ...(courtesy ? [`Listed by: ${courtesy}`] : []),
    ...(mlsName ? [`Source: ${mlsName}`] : []),
    ...(revisedMlsId ? [`MLS #${revisedMlsId}`] : []),
  ];

  const photos = !isEmpty(Media)
    ? filterImagesOnly(extractOrderedPhotosForFiltering(Media, Media.length), mlsName)
    : filterImagesOnlyFromPhotoUrls(get(data, 'PhotoUrls'), mlsName);

  const mlsLastUpdate = mlsLastUpd
    ? [`Listing Updated: ${moment(mlsLastUpd).format('MMMM D, YYYY')}`]
    : [];

  const mlsLastChecked = updated
    ? [`Mosaik Last Checked: ${moment(updated).format('MMMM D, YYYY')}`]
    : [];
  return {
    recommendedTo: get(data, 'RecommendedTo'),
    virtualTour: convertToEmbededYoutubeUrl(get(data, 'VirtualTourUrl')),
    price: price
      ? formatUsd(price, {
          minimumFractionDigits: 0,
        })
      : 'Price Unavailable',
    closingPrice: closingPrice
      ? formatUsd(closingPrice, {
          minimumFractionDigits: 0,
        })
      : '',
    photos,
    baths: getBathsTotalNum(get(data, 'NumBathsTotal')),
    beds: get(data, 'NumBeds'),
    square: square && propertyType !== 'Land' ? `${formatNumber(square)} sqft` : square,
    match: get(data, 'MatchScore'),
    address: `${addressLine1}, ${addressLine2}`,
    addressLine1,
    addressLine2,
    propertyHistory,
    sourceSection,
    mlsLastUpdate,
    mlsLastChecked,
    virtualTourBranded: convertToEmbededYoutubeUrl(get(data, 'VirtualTourURLBranded')),
    virtualTourUnBranded: convertToEmbededYoutubeUrl(get(data, 'VirtualTourURLUnbranded')),
    activityStatus,
  };
});

export const getFormattedListingDetailBuyerMatch = ({ propertyInsights, additionalFeatures }) =>
  createSelector(getListingDetailData, (data) => ({
    match: get(data, 'MatchScore'),
    ...getPreparedMustAndWantsMlsCriterias(
      get(data, 'MatchedCriterias'),
      propertyInsights,
      additionalFeatures,
    ),
    matchedCriterias: get(data, 'MatchedCriterias'),
    additionalFeatures: additionalFeatures
      ? getPreparedAdditionalFeaturesParsed(additionalFeatures)
      : null,
  }));

export const getListingDetailNeighborhoodTab = createSelector(
  getListingDetailData,
  getFormattedListingDetailData,
  (data, { address }) => ({
    id: get(data, 'Id'),
    community: get(data, 'Community') || 'Nearby',
    schools: get(data, 'Schools'),
    walkScore: get(data, 'TransportationScore.WalkScore'),
    bikeScore: get(data, 'TransportationScore.BikeScore'),
    transitScore: get(data, 'TransportationScore.TransitScore'),
    geoLat: get(data, 'Geolocation.Lat'),
    geoLong: get(data, 'Geolocation.Long'),
    address,
    priceToDisplay: preparePriceForMarkup(get(data, 'SellingPrice')),
  }),
);

export const getListingDetailKeyDetailsTab = createSelector(getListingDetailData, (data) => {
  const price = get(data, 'SellingPrice');
  const square = get(data, 'SquareFeet');
  const taxAmount = get(data, 'TaxAmount');
  const taxYear = get(data, 'TaxYear');
  const fee = get(data, 'AssociationInfo.Fee');
  const feeFrequency = get(data, 'AssociationInfo.FeeFrequency');
  const fee2 = get(data, 'AgentInfo.AssociationFee2');
  const feeFrequency2 = get(data, 'AgentInfo.AssociationFee2Frequency');
  const fee3 = get(data, 'AgentInfo.AssociationFee3');
  const feeFrequency3 = get(data, 'AgentInfo.AssociationFee3Frequency');

  const lotRent = get(data, 'ExteriorAndLotDetails.LotRent');
  const lotRentFrequency = get(data, 'ExteriorAndLotDetails.LotRentFrequency');
  const propertyType = get(data, 'PropertyType');
  const numOfOpenParkingSpaces = get(data, 'Parking.NumOfOpenParkingSpaces');
  const openParkingYN = get(data, 'ExteriorAndLotDetails.OpenParkingYN');

  const parkingTotal = get(data, 'ParkingTotal');

  const parking = [
    `${formatStringList(get(data, 'Parking.GarageType')) || ''} ${
      formatStringList(get(data, 'Parking.ParkingType')) || ''
    }`,
    ...(getFormattedArrayStringByPath({
      data,
      path: 'Parking.NumOfGarageSpaces',
      format: (val) => (val && val !== 0 ? `Number of Spaces (parking garage): ${val}` : null),
    }) || []),
    ...(data.Market !== 'smartmls'
      ? getFormattedArrayStringByPath({
          data,
          path: 'Parking.GarageSpaces',
          format: (val) => `# of Garage Spaces: ${val}`,
        })
      : []),

    ...(!parkingTotal
      ? getFormattedArrayStringByPath({
          data,
          path: 'Parking.NumOfParkingSpaces',
          format: (val) => `Total # of Parking Spaces: ${val}`,
        })
      : []),
    ...(parkingTotal ? [`Total Parking: ${parkingTotal}`] : []),
    ...getFormattedArrayStringByPath({
      data,
      path: 'Parking.OtherParking',
      format: (val) => `Other Parking: ${val}`,
    }),
    ...getFormattedArrayStringByPath({
      data,
      path: 'Parking.NumOfOpenParkingSpaces',
      format: (val) => `# of Open Parking Spaces: ${val}`,
    }),
    ...getArrayItemByPath({
      format: (data) => [
        ...(openParkingYN ? [`Open Parking: ${openParkingYN}`] : []),
        ...(numOfOpenParkingSpaces ? [`Spaces: ${numOfOpenParkingSpaces}`] : []),
      ],
    }),
    ...getFormattedArrayStringByPath({
      data,
      path: 'Parking.NumOfParkingSpaces',
      format: (val) => `Total # of Parking Spaces: ${val}`,
    }),
    ...getFormattedArrayStringByPath({
      data,
      path: 'Parking.DeedGarageCost',
      format: (val) => `Deeded Garage Cost: ${formatUsd(val)}`,
    }),
    ...getFormattedArrayStringByPath({
      data,
      path: 'Parking.DeedReference',
      format: (val) => `Deed Reference: ${formatUsd(val)}`,
    }),
    ...getFormattedArrayStringByPath({
      data,
      path: 'Parking.DeedParkingCost',
      format: (val) => `Deeded Parking Cost: ${formatUsd(val)}`,
    }),
    ...getFormattedArrayStringByPath({
      data,
      path: 'Parking.MainLevelGarageYN',
      format: (val) => `Main Level Garage YN: ${val}`,
    }),
    ...getFormattedArrayStringByPath({
      data,
      path: 'Parking.GarageOwnership',
      format: (val) => `Ownership: ${val}`,
    }),
    ...getFormattedArrayStringByPath({
      data,
      path: 'Parking.GarageOnSite',
      format: (val) => `Garage on-site: ${val}`,
    }),
    ...getFormattedArrayStringByPath({
      data,
      path: 'Parking.GarageFeatures',
      format: (val) => `Garage Features: ${formatStringList(val)}`,
    }),
    ...getFormattedArrayStringByPath({
      data,
      path: 'Parking.ParkingFeatures',
      format: (val) => `Parking Features: ${formatStringList(val)}`,
    }),
    ...getFormattedArrayStringByPath({
      data,
      path: 'Parking.GarageDimensions',
      format: (val) => `Garage Dimensions: ${formatStringList(val)}`,
    }),
    ...getFormattedArrayStringByPath({
      data,
      path: 'Parking.CoveredSpaces',
      format: (val) => `Covered Spaces: ${val}`,
    }),
  ];

  const hoa = [
    ...getFormattedArrayStringByPath({
      data,
      path: 'AssociationInfo.IsAssociation',
      format: boolToYesNo,
    }),

    ...hoaFeeForKeyDetails(fee, feeFrequency, fee2, feeFrequency2, fee3, feeFrequency3),
  ];

  let agentInformation = [
    get(data, 'AgentInfo.ListingAgent.FullName'),
    get(data, 'ListingOfficeName'),
    get(data, 'ListAgentURL'),
  ];
  const email = get(data, 'AgentInfo.ListingAgent.Email');
  const officePhone = get(data, 'AgentInfo.ListingAgent.PhoneOffice');
  const mobilePhone = get(data, 'AgentInfo.ListingAgent.PhoneMobile');
  const directPhone = get(data, 'AgentInfo.ListingAgent.PhoneDirect');
  if (email) agentInformation.push(email);
  if (officePhone) agentInformation.push(officePhone + ' (office)');
  if (mobilePhone) agentInformation.push(mobilePhone + ' (mobile)');
  if (directPhone) agentInformation.push(directPhone + ' (direct)');

  const annualLeasePricePerSquareFoot = get(data, 'AnnualLeasePricePerSquareFoot');
  const leaseAmount = get(data, 'LeaseAmount');
  const leaseAmountFrequency = get(data, 'LeaseAmountFrequency');
  const pricePerSquareFoot = get(data, 'PricePerSquareFoot');
  const grossIncome = get(data, 'GrossIncome');
  const totalActualRent = get(data, 'AgentInfo.TotalActualRent');

  const schoolDistricts = [
    ...getFormattedArrayStringByPath({
      data,
      path: 'CommunityInformation.ElementarySchoolDistrict',
      format: (val) => `Elementary School District: ${val}`,
    }),
    ...getFormattedArrayStringByPath({
      data,
      path: 'CommunityInformation.MiddleOrJuniorSchoolDistrict',
      format: (val) => `Middle School District: ${val}`,
    }),
    ...getFormattedArrayStringByPath({
      data,
      path: 'CommunityInformation.HighSchoolDistrict',
      format: (val) => `High School District: ${val}`,
    }),
    ...getFormattedArrayStringByPath({
      data,
      path: 'CommunityInformation.SchoolDistrictName',
      format: (val) => `School District: ${val}`,
    }),
  ];

  data = {
    ...data,
    HomeType: getHomeTypeLabel(data?.HomeType),
  };

  return [
    ...getArrayItemByPath({ title: 'Status', data, path: 'ActivityStatus' }),
    ...getArrayItemByPath({ title: 'Home Type', data, path: 'HomeType' }),
    ...getArrayItemByPath({ title: 'Property Type', data, path: 'PropertyType' }),
    //...getArrayItemByPath({ title: 'Property Subtype', data, path: 'PropertySubType' }),
    ...getArrayItemByPath({
      title: 'Days On Market',
      data,
      path: 'ListingDate',
      format: daysFromToday,
    }),

    ...(price ? [{ title: 'Price', value: formatUsd(price) }] : []),
    ...(price &&
    square &&
    (!pricePerSquareFoot || data.Market === 'bright' || data.Market === 'mfrmls')
      ? [{ title: 'Price / Sq Ft', value: formatUsd(price / square, { maximumFractionDigits: 0 }) }]
      : []),
    ...(pricePerSquareFoot && data.Market !== 'bright' && data.Market !== 'mfrmls'
      ? [
          {
            title: 'Price per Square Foot',
            value: `${formatUsd(pricePerSquareFoot, { maximumFractionDigits: 0 })}`,
          },
        ]
      : []),

    ...(hoa?.length ? [{ title: 'HOA', value: hoa }] : []),

    ...(lotRent && lotRent != 0
      ? [
          {
            title: 'Lot Rent',
            value: `${formatUsd(lotRent)}${lotRentFrequency ? `/${lotRentFrequency}` : ''}`,
          },
        ]
      : []),

    ...getArrayItemByPath({ title: 'Beds', data, path: 'NumBeds' }),
    ...(propertyType != 'Land'
      ? getArrayItemByPath({
          title: 'Bathrooms',
          data,
          path: 'NumBathsTotal',

          format: (val) => ` ${getBathsTotalNum(val)}`,
        })
      : []),
    ...(square ? [{ title: 'Sq Ft', value: formatNumber(square) }] : []),

    ...(!data.LotSizeCalculatedFromRange
      ? getArrayItemByPath({
          title: 'Lot Size',
          data,
          path: 'LotSizeAcres',
          format: (val) =>
            val ? `${formatNumber(val, { maximumFractionDigits: 2 })} Acres` : null,
        })
      : []),

    ...getArrayItemByPath({ title: 'Year Built', data, path: 'YearBuilt' }),
    ...(parking?.length ? [{ title: 'Parking Information', value: parking }] : []),

    ...(taxAmount
      ? [
          {
            title: 'Property Tax',
            value: `${formatUsd(taxAmount)}${taxYear ? ` (${taxYear})` : ''}`,
          },
        ]
      : []),

    ...getArrayItemByPath({ title: 'Community', data, path: 'Community' }),
    ...getArrayItemByPath({ title: 'View', data, path: 'View' }),

    ...(annualLeasePricePerSquareFoot
      ? [
          {
            title: 'Annual Lease (price per square foot)',
            value: `${formatUsd(annualLeasePricePerSquareFoot)}`,
          },
        ]
      : []),
    ...(leaseAmount
      ? [
          {
            title: 'Lease Amount',
            value: `${formatUsd(leaseAmount)}${
              leaseAmountFrequency ? `/${leaseAmountFrequency}` : ''
            }`,
          },
        ]
      : []),
    ...(grossIncome
      ? [
          {
            title: 'Gross Income',
            value: `${formatUsd(grossIncome)}`,
          },
        ]
      : []),
    ...(totalActualRent
      ? [
          {
            title: 'Total Rent',
            value: `${formatUsd(totalActualRent)}`,
          },
        ]
      : []),

    ...getArrayItemByPath({
      title: 'Income includes',
      data,
      path: 'IncomeIncludes',
      format: formatStringList,
    }),
    ...getArrayItemByPath({
      title: 'Business Name',
      data,
      path: 'BusinessName',
      format: formatStringList,
    }),
    ...getArrayItemByPath({
      title: 'Business Type',
      data,
      path: 'BusinessType',
      format: formatStringList,
    }),
    ...getArrayItemByPath({ title: 'Year Established', data, path: 'YearEstablished' }),
    ...getArrayItemByPath({
      title: 'Restrictions',
      data,
      path: 'Restrictions',
      format: formatStringList,
    }),
    ...getArrayItemByPath({
      title: 'Closing Price',
      data,
      path: 'AgentInfo.ClosePrice',
      format: formatUsd,
    }),
    ...(schoolDistricts?.length ? [{ title: 'School District(s)', value: schoolDistricts }] : []),
  ].filter(({ value }) => filterEmptyValues(value));
});

export const getListingDetailAgentInformationData = createSelector(getListingDetailData, (data) => {
  const name = get(data, 'AgentInfo.ListingAgent.FullName');
  const market = get(data, 'Market');
  const listOfficePhoneExt = get(data, 'AgentInfo.ListOffice.PhoneExt');
  const buyerAgenyCompensationType = get(data, 'ListingInfo.BuyerAgencyCompensationType');
  const subAgencyCompensation = get(data, ' AgentInfo.SubAgency.Compensation');
  const agentPhoneNumber = get(data, 'AgentInfo.ListingAgent.PhoneMobile');
  const PhoneMobile = getAgentPhoneNumber(market, agentPhoneNumber);
  const listOfficeAddress = get(data, 'AgentInfo.ListOffice.OfficeAddress');
  const listOfficeCity = get(data, 'AgentInfo.ListOffice.OfficeCity');
  const listOfficeState = get(data, 'AgentInfo.ListOffice.OfficeState');
  const listOfficeZipCode = get(data, 'AgentInfo.ListOffice.OfficeZipCode');
  const officeAddress = `${join([
    ...(listOfficeAddress ? [listOfficeAddress] : []),
    ...(listOfficeCity ? [listOfficeCity] : []),
    ...(listOfficeState ? [listOfficeState] : []),
    ...(listOfficeZipCode ? [listOfficeZipCode] : []),
  ])}`;
  if (data.AgentInfo && !data.AgentInfo.AttributionContact) {
    data.AgentInfo.AttributionContact = 'None';
  }

  return {
    market,
    name,
    showingsData: data?.AgentInfo?.Showing,
    office: get(data, 'ListingOfficeName'),
    avatarName: formatNameToAvatarLetters(name),
    coListAvatarName: formatNameToAvatarLetters(get(data, 'AgentInfo.CoListAgent.Name')),
    email: get(data, 'AgentInfo.ListingAgent.Email'),
    fax: get(data, 'AgentInfo.ListingAgent.Fax'),
    url: get(data, 'ListAgentURL'),
    officePhone: get(data, 'AgentInfo.ListingAgent.PhoneOffice'),
    mobilePhone: PhoneMobile && formatPhoneNumber(get(data, 'AgentInfo.ListingAgent.PhoneMobile')),
    directPhone: formatPhoneNumber(get(data, 'AgentInfo.ListingAgent.PhoneDirect')),
    designation: get(data, 'AgentInfo.ListingAgent.Designation'),
    aOR: get(data, 'AgentInfo.ListingAgent.AOR'),
    stateLicenseNumber: get(data, 'AgentInfo.ListingAgent.StateLicenseNumber'),
    nationalAssociationId: get(data, 'AgentInfo.ListingAgent.NationalAssociationId'),
    coListingAgent: get(data, 'AgentInfo.CoListAgent'),
    blocks: [
      ...getArrayItemByPath({ title: 'Private Remarks', data, path: 'AgentInfo.PrivateRemarks' }),

      ...getArrayItemByPath({
        title: 'Showings',
        data,
        path: 'AgentInfo.Showing',
        format: agentInfoFormat,
      }),

      ...getArrayItemByPath({
        title: 'Showing Contact',
        data,
        path: 'AgentInfo.Showing.ContactName',
      }),

      ...getArrayItemByPath({
        title: 'Lockbox Type',
        data,
        path: 'AgentInfo.LockBoxType',
      }),

      ...getArrayItemByPath({
        title: 'Documents Available',
        data,
        path: 'AgentInfo.DocumentsAvailable',
        format: (data) =>
          typeof data === 'string' && data !== null
            ? data
                .split(',')
                .map((val) => formatUnwantedString(val, 'amp;'))
                .join(', ')
            : '',
      }),

      ...getArrayItemByPath({
        title: 'Documents Count',
        data,
        path: 'AgentInfo.DocumentsCount',
      }),

      ...getArrayItemByPath({
        title: 'Disclosures',
        data,
        path: 'AgentInfo.Disclosures',
        format: formatStringList,
      }),

      ...getArrayItemByPath({
        title: 'Listing Agreement',
        data,
        path: 'AgentInfo.ListingAgreement',
      }),

      ...getArrayItemByPath({
        title: 'Listing Terms',
        data,
        path: 'AgentInfo.ListingTerms',
        format: formatStringList,
      }),

      ...getArrayItemByPath({
        title: 'Occupant',
        data,
        path: 'AgentInfo.Occupant',
        format: agentInfoFormat,
      }),

      ...getArrayItemByPath({ title: 'Exclusions', data, path: 'AgentInfo.Exclusions' }),
      ...getArrayItemByPath({
        title: 'Possession',
        data,
        path: 'AgentInfo.Possesion',
        format: formatStringList,
      }),

      ...getArrayItemByPath({
        title: 'Office Board',
        data,
        path: 'AgentInfo.OfficeBoard',
        format: formatStringList,
      }),

      /*
      ...getArrayItemByPath({
        title: 'Net Sale Price',
        data,
        path: 'AgentInfo.CompensationPaidOn',
      }),
      */
      ...getArrayItemByPath({
        title: 'Special Conditions',
        data,
        path: 'AgentInfo.SpecialConditions',
      }),
      ...getArrayItemByPath({
        title: 'Sales Price Includes Parking',
        data,
        path: 'AgentInfo.SalePriceIncludesParking',
        format: boolToYesNo,
      }),
      ...getArrayItemByPath({
        title: 'Holds Earnest Money',
        data,
        path: 'AgentInfo.HoldsEarnestMoney',
        format: boolToYesNo,
      }),
      ...getArrayItemByPath({
        title: 'Some property photos virtually staged',
        data,
        path: 'AgentInfo.HasVirtualPhotos',
        format: boolToYesNo,
      }),

      ...getArrayItemByPath({
        title: 'Lender Owned:',
        data,
        path: 'AgentInfo.LenderOwned',
        format: boolToYesNo,
      }),

      ...getArrayItemByPath({
        title: 'Potential Short Sale:',
        data,
        path: 'AgentInfo.PotentialShortSale',
        format: boolToYesNo,
      }),

      ...getArrayItemByPath({
        title: `Buyer`,
        data,
        path: 'AgentInfo.Buyers',
        format: agentInfoFormat,
      }),
      ...getArrayItemByPath({
        title: 'Owner Name',
        data,
        path: 'AgentInfo.OwnerName',
      }),

      ...getArrayItemByPath({
        title: 'Mortgage Balance',
        data,
        path: 'AgentInfo.MortgageBalance',
      }),

      ...getArrayItemByPath({
        title: 'Mortgage',
        data,
        path: 'AgentInfo',
        format: (data) => [
          ...getArrayItemStringByPath({
            data,
            path: 'AssumableMortgage',
            format: (val) => `Assumable: ${val}`,
          }),
          ...getArrayItemStringByPath({
            data,
            path: 'MortgageType',
            format: (val) => `Type: ${val}`,
          }),
        ],
      }),

      ...getArrayItemByPath({
        title: 'Acceptable Financing',
        data,
        path: 'AgentInfo.AcceptableFinancing',
      }),

      ...getArrayItemByPath({
        title: 'Expiration Date',
        data,
        path: 'AgentInfo.ExpirationDate',
        format: (val) =>
          val && moment(val).year() != 1900 ? moment(val).format('MM-DD-YYYY') : null,
      }),
      ...getArrayItemByPath({
        title: 'Back OnMarket Date',
        data,
        path: 'AgentInfo.BackOnMarketDate',
        format: (val) => (val ? moment(val).format('MM-DD-YYYY') : null),
      }),

      ...getArrayItemByPath({
        title: 'Close Date',
        data,
        path: 'AgentInfo.CloseDate',
        format: (val) => (val ? moment(val).format('MM-DD-YYYY') : null),
      }),
      ...getArrayItemByPath({
        title: 'Close Price',
        data,
        path: 'AgentInfo.ClosePrice',
        format: formatUsd,
      }),

      ...getArrayItemByPath({
        title: 'Option Date',
        data,
        path: 'AgentInfo.OptionDate',
        format: (val) => (val ? moment(val).format('MM-DD-YYYY') : null),
      }),

      ...getArrayItemByPath({
        title: 'Pending Timestamp',
        data,
        path: 'AgentInfo.PendingTimestamp',
        format: (val) => moment(val).format('MM-DD-YYYY HH:mm'),
      }),
      ...getArrayItemByPath({
        title: 'Price Change Timestamp',
        data,
        path: 'AgentInfo.PriceChangeTimestamp',
        format: (val) => moment(val).format('MM-DD-YYYY HH:mm'),
      }),

      ...(data.Market !== 'actris'
        ? getArrayItemByPath({
            title: 'Property Key',
            data,
            path: 'AgentInfo.PropertyKey',
          })
        : []),

      ...getArrayItemByPath({
        title: 'Property Match',
        data,
        path: 'AgentInfo.PropertyMatch',
      }),

      ...getArrayItemByPath({
        title: 'Purchase Contract Date',
        data,
        path: 'AgentInfo.PurchaseContractDate',
        format: (val) => (val ? moment(val).format('MM-DD-YYYY') : null),
      }),

      ...(data.Market !== 'actris'
        ? getArrayItemByPath({
            title: 'Ratios',
            data,
            path: 'AgentInfo.Ratios',
            format: agentInfoFormat,
          })
        : []),

      // ...getArrayItemByPath({
      //   title: 'Colisting Agent',
      //   data,
      //   path: 'AgentInfo.CoListAgent',
      //   format: agentInfoFormat,
      // }),

      ...getArrayItemByPath({
        title: 'Co Buyer Agent',
        data,
        path: 'AgentInfo.CoBuyerAgent',
        format: agentInfoFormat,
      }),

      ...(data.Market !== 'actris'
        ? getArrayItemByPath({
            title: 'Doc Box Detail',
            data,
            path: 'AgentInfo.DocBox',
            format: agentInfoFormat,
          })
        : []),

      ...getArrayItemByPath({
        title: 'Loan Details',
        data,
        path: 'AgentInfo.LoanDetails',
        format: agentInfoFormat,
      }),
      ...getArrayItemByPath({
        title: 'Intermediary',
        data,
        path: 'AgentInfo.IntermediaryYN',
      }),

      ...getArrayItemByPath({
        title: 'Repairs Amount',
        data,
        path: 'AgentInfo.RepairsAmount',
      }),
      ...getArrayItemByPath({
        title: 'Tax Rate',
        data,
        path: 'AgentInfo.TaxRate',
      }),

      ...getArrayItemByPath({
        title: 'Tentative Close Date',
        data,
        path: 'AgentInfo.TentativeCloseDate',
        format: (val) => (val ? moment(val).format('MM-DD-YYYY') : null),
      }),
      ...getArrayItemByPath({
        title: 'Title Company',
        data,
        path: 'AgentInfo.TitleCompany',
      }),

      ...getArrayItemByPath({
        title: 'Total Actual Rent',
        data,
        path: 'AgentInfo.TotalActualRent',
      }),

      ...getArrayItemByPath({
        title: 'Tax Exemptions',
        data,
        path: 'AgentInfo.TaxExemptions',
        format: formatStringList,
      }),
      ...getArrayItemByPath({
        title: 'Association Management Company',
        data,
        path: 'AgentInfo.AssociationManagementCompany',
      }),
      /*
      ...getArrayItemByPath({
        title: 'Dual Variable Compensation',
        data,
        path: 'AgentInfo.DualVariableCompensationYN',
        format: boolToYesNo,
      }),
      */
      ...getArrayItemByPath({
        title: 'Assessment Pending',
        data,
        path: 'AgentInfo.AssessmentPending',
        format: boolToYesNo,
      }),
      ...getArrayItemByPath({
        title: 'Sold Leased Comments',
        data,
        path: 'AgentInfo.SoldLeasedComments',
      }),
      ...getArrayItemByPath({
        title: 'Title',
        data,
        path: 'AgentInfo.Title',
      }),
      ...getArrayItemByPath({
        title: 'Withdrawn Date',
        data,
        path: 'AgentInfo.WithdrawnDate',
        format: (val) => (val ? moment(val).format('MM-DD-YYYY') : null),
      }),

      ...getArrayItemByPath({
        title: 'Lockbox',
        data,
        path: 'AgentInfo.LockBoxSource',
        format: (val) => (val ? `Source: ${formatStringList(val)}` : null),
      }),
      ...getArrayItemByPath({
        title: 'Groundwater Conservation District',
        data,
        path: 'AgentInfo.GroundwaterConservationDistrictYN',
      }),
      ...getArrayItemByPath({
        title: 'Special Assessment',
        data,
        path: 'AgentInfo.SpecialAssessment',
        format: boolToYesNo,
      }),

      ...getArrayItemByPath({
        title: 'Contingent Date',
        data,
        path: 'AgentInfo.ContingentDate',
        format: (val) => (val ? moment(val).format('MM-DD-YYYY') : null),
      }),
      ...getArrayItemByPath({
        title: 'Appraisal Amount',
        data,
        path: 'AgentInfo.AppraisalAmount',
      }),
      ...getArrayItemByPath({
        title: 'Association Requirement',
        data,
        path: 'AgentInfo.AssociationRequirement',
      }),
      ...getArrayItemByPath({
        title: 'Co Buyer Office Name',
        data,
        path: 'AgentInfo.CoBuyerOfficeName',
      }),
      ...getArrayItemByPath({
        title: 'Co Buyer Office Phone',
        data,
        path: 'AgentInfo.CoBuyerOfficePhone',
      }),
      ...getArrayItemByPath({
        title: 'Auction Date',
        data,
        path: 'AgentInfo.AuctionDate',
        format: (val) => (val ? moment(val).format('MM-DD-YYYY') : null),
      }),
      ...getArrayItemByPath({
        title: 'Ownership Type',
        data,
        path: 'AgentInfo.OwnershipType',
      }),
      /*
      ...getArrayItemByPath({
        title: 'Concessions',
        data,
        path: 'AgentInfo.Concessions',
      }),
      */
      ...getArrayItemByPath({
        title: 'Additional Pet Fee',
        data,
        path: 'BuildingInfo.PetsInformation.AdditionalPetFee',
      }),
      ...getArrayItemByPath({
        title: 'App Delivery To',
        data,
        path: 'AppDeliveryTo',
      }),
      ...getArrayItemByPath({
        title: 'App Deposit Payable To',
        data,
        path: 'AppDepositPayableTo',
      }),
      ...getArrayItemByPath({
        title: 'App Fee Payable To',
        data,
        path: 'AppFeePayableTo',
      }),
      ...getArrayItemByPath({
        title: 'Appliances Maintained',
        data,
        path: 'AppliancesMaintained',
        format: formatStringList,
      }),
      ...getArrayItemByPath({
        title: 'Application Policy',
        data,
        path: 'ApplicationPolicy',
      }),
      ...getArrayItemByPath({
        title: 'Application Required',
        data,
        path: 'ApplicationRequired',
        format: formatStringList,
      }),
      ...getArrayItemByPath({
        title: 'Availability Date',
        data,
        path: 'AvailabilityDate',
        format: (val) => (val ? moment(val).format('MM-DD-YYYY') : null),
      }),
      ...getArrayItemByPath({
        title: 'First Month Rent Payable To:',
        data,
        path: 'FirstMonthRentPayableTo',
      }),
      ...getArrayItemByPath({
        title: 'Housing Vouchers:',
        data,
        path: 'AgentInfo.HousingVouchersYN',
      }),
      ...getArrayItemByPath({
        title: 'Lease Guarantor? (Y/N)',
        data,
        path: 'AgentInfo.LeaseGuarantorYN',
        format: boolToYesNo,
      }),

      ...getArrayItemByPath({
        title: 'Lock Box Serial Number',
        data,
        path: 'AgentInfo.LockBoxSerialNumber',
      }),
      ...getArrayItemByPath({
        title: 'Managed By',
        data,
        path: 'ManagedBy',
        format: (data) => [
          ...getArrayItemStringByPath({
            data,
            path: 'ManagedBy',
            format: (val) => `Managed By: ${val}`,
          }),
          ...getArrayItemStringByPath({
            data,
            path: 'Phone',
            format: (val) => `Phone: ${formatPhoneNumber(val)}`,
          }),
          ...getArrayItemStringByPath({
            data,
            path: 'Rep',
            format: (val) => `Rep: ${val}`,
          }),
          ...getArrayItemStringByPath({
            data,
            path: 'ManagementCompany',
            format: (val) => `Management Company: ${val}`,
          }),
        ],
      }),

      ...getArrayItemByPath({
        title: 'Monthly Pet Rent Per Pet? (Y/N)',
        data,
        path: 'BuildingInfo.PetsInformation.MonthlyPetRent',
        format: (data) => [
          ...getArrayItemStringByPath({
            data,
            path: 'MonthlyPetRentPerPetYN',
            format: boolToYesNo,
          }),
          ...getArrayItemStringByPath({
            data,
            path: 'MonthlyPetRent',
            format: (val) => `Pet Rent: ${val}`,
          }),
        ],
      }),
      ...getArrayItemByPath({
        title: 'Move In Special? (Y/N)',
        data,
        path: 'MoveInSpecialYN',
        format: boolToYesNo,
      }),
      ...getArrayItemByPath({
        title: 'On-site Compliance? (Y/N)',
        data,
        path: 'AgentInfo.OnSiteComplianceYN',
        format: boolToYesNo,
      }),
      ...getArrayItemByPath({
        title: 'One Time Expenses',
        data,
        path: 'AgentInfo.OneTimeExpenses',
        format: formatStringList,
      }),
      ...getArrayItemByPath({
        title: 'Online Application Instructions',
        data,
        path: 'AgentInfo.OnlineApplicationInstructions',
      }),
      ...getArrayItemByPath({
        title: 'Per Person (Y/N)',
        data,
        path: 'AgentInfo.PerPersonYN',
        format: boolToYesNo,
      }),
      ...getArrayItemByPath({
        title: 'Per Pet (Y/N)',
        data,
        path: 'AgentInfo.PerPetYN',
        format: boolToYesNo,
      }),

      ...getArrayItemByPath({
        title: 'Recurring Expenses',
        data,
        path: 'AgentInfo.RecurringExpenses',
      }),
      ...getArrayItemByPath({
        title: 'Renters Insurance Required (Y/N)',
        data,
        path: 'AgentInfo.RentersInsuranceRequiredYN',
        format: boolToYesNo,
      }),

      /*
      ...(subAgencyCompensation
        ? getArrayItemByPath({
            title: 'Sub Agency Compensation',
            data,
            path: 'AgentInfo.SubAgency',
            format: (data) => [
              ...getArrayItemStringByPath({
                data,
                path: 'Compensation',
                format: (val) => `Compensation: ${val}`,
              }),
              ...getArrayItemStringByPath({
                data,
                path: 'CompensationType',
                format: (val) => `Type: ${val}`,
              }),
            ],
          })
        : ''),
        */

      ...getArrayItemByPath({
        title: 'Final Lease Type',
        data,
        path: 'AgentInfo.FinalLeaseType',
      }),

      ...getArrayItemByPath({
        title: 'Other Remarks',
        data,
        path: 'AgentInfo',
        format: (data) => [
          ...getArrayItemStringByPath({
            data,
            path: 'SyndicationRemarks',
            format: (val) => `Syndication: ${val}`,
          }),
          /*
          ...getArrayItemStringByPath({
            data,
            path: 'SubAgency.CompensationRemarks',
            format: (val) => `Sub Agency Compensation: ${val}`,
          }),

          ...getArrayItemStringByPath({
            data,
            path: 'ListingInfo.BuyerAgencyCompensation',
            format: (val) => `Buyer Agency Compensation: ${val}`,
          }),

          ...getArrayItemStringByPath({
            data,
            path: 'ListingInfo.TransactionBrokerCompensation',
            format: (val) => `Transaction Broker Compensation: ${val}`,
          }),
          */
        ],
      }),

      ...getArrayItemByPath({
        title: 'Owner 1',
        data,
        path: 'AgentInfo',
        format: (data) => [
          ...getArrayItemStringByPath({
            data,
            path: 'OwnerNameByTax',
            format: (val) => `Name: ${val} (tax records)`,
          }),
          ...getArrayItemStringByPath({
            data,
            path: 'Owner1.Name',
            format: (val) => `Name: ${val}`,
          }),
          ...getArrayItemStringByPath({
            data,
            path: 'Owner1.Email',
            format: (val) => `Email: ${val}`,
          }),
          ...getArrayItemStringByPath({
            data,
            path: 'Owner1.MobilePhone',
            format: (val) => `Phone: ${formatPhoneNumber(val)}`,
          }),
        ],
      }),

      ...getArrayItemByPath({
        title: 'Owner 2',
        data,
        path: 'AgentInfo.Owner2',
        format: (data) => [
          ...getArrayItemStringByPath({
            data,
            path: 'Name',
            format: (val) => `Name: ${val}`,
          }),
          ...getArrayItemStringByPath({
            data,
            path: 'Email',
            format: (val) => `Email: ${val}`,
          }),
          ...getArrayItemStringByPath({
            data,
            path: 'MobilePhone',
            format: (val) => `Phone: ${formatPhoneNumber(val)}`,
          }),
        ],
      }),

      ...getArrayItemByPath({
        title: 'Sale Includes',
        data,
        path: 'AgentInfo.SaleIncludes',
      }),

      ...getArrayItemByPath({
        title: 'Ownership Interest',
        data,
        path: 'AgentInfo.OwnershipInterest',
      }),
      ...getArrayItemByPath({
        title: 'Brokerage Interest',
        data,
        path: 'AgentInfo.BrokerageInterest',
      }),

      ...getArrayItemByPath({
        title: 'Short Sale:',
        data,
        path: 'AgentInfo.ShortSale',
      }),

      ...getArrayItemByPath({
        title: 'REO:',
        data,
        path: 'AgentInfo.RealEstateOwnedYN',
        format: boolToYesNo,
      }),

      ...getArrayItemByPath({
        title: 'Sale Type',
        data,
        path: 'AgentInfo.SaleType',
      }),

      ...getArrayItemByPath({
        title: 'Closed Sale Terms',
        data,
        path: 'AgentInfo.CloseSaleTerms',
      }),

      ...getArrayItemByPath({
        title: 'Sale Options',
        data,
        path: 'AgentInfo.SaleOptions',
        format: formatEachArrayValue(formatStringList),
      }),

      ...getArrayItemByPath({
        title: 'Closed Lease Terms (months)',
        data,
        path: 'AgentInfo.ClosedLeaseTermsMonths',
      }),

      ...getArrayItemByPath({
        title: 'Listing Office',
        data,
        path: 'AgentInfo.ListOffice',
        format: (data) => [
          ...getArrayItemStringByPath({
            data,
            path: 'Name',
            format: (val) => `Name: ${val}`,
          }),
          ...getArrayItemStringByPath({
            data,
            path: 'Email',
            format: (val) => `Email: ${val}`,
          }),
          ...getArrayItemStringByPath({
            data,
            path: 'Fax',
            format: (val) => `Fax: ${val}`,
          }),
          ...(officeAddress ? [`Office Address: ${officeAddress}`] : []),
          ...getArrayItemStringByPath({
            data,
            path: 'Phone',
            format: (val) =>
              formatPhoneNumber(val)
                ? `Phone: ${formatPhoneNumber(val)}${
                    listOfficePhoneExt ? ' ext. ' + listOfficePhoneExt : ''
                  }`
                : '',
          }),
          ...getArrayItemStringByPath({
            data,
            path: 'MlsId',
            format: (val) => `MLS ID: ${val}`,
          }),
          ...(market === 'crmls'
            ? getArrayItemStringByPath({
                data,
                path: 'StateLicenseNumber',
                format: (val) => `State License Number: ${val}`,
              })
            : []),
        ],
      }),

      ...getArrayItemByPath({
        title: 'Colisting Office',
        data,
        path: 'AgentInfo.CoListOffice',
        format: (data) => [
          ...getArrayItemStringByPath({
            data,
            path: 'Name',
            format: (val) => `Name: ${val}`,
          }),
          ...getArrayItemStringByPath({
            data,
            path: 'Email',
            format: (val) => `Email: ${val}`,
          }),
          ...getArrayItemStringByPath({
            data,
            path: 'MlsId',
            format: (val) => `MLS ID: ${val}`,
          }),
          ...getArrayItemStringByPath({
            data,
            path: 'Fax',
            format: (val) => `Fax: ${val}`,
          }),
          ...getArrayItemStringByPath({
            data,
            path: 'Phone',
            format: (val) => `Phone: ${val}`,
          }),
          ...getArrayItemStringByPath({
            data,
            path: 'PhoneExt',
            format: (val) => `Phone Ext: ${val}`,
          }),
          ...getArrayItemStringByPath({
            data,
            path: 'URL',
            format: (val) => `URL: ${val}`,
          }),
        ],
      }),

      ...getArrayItemByPath({
        title: 'Buyer Agent',
        data,
        path: 'AgentInfo.BuyerAgent',
        format: (data) => [
          ...getArrayItemStringByPath({
            data,
            path: 'FullName',
            format: (val) => `Name: ${val}`,
          }),
          ...getArrayItemStringByPath({
            data,
            path: 'Email',
            format: (val) => `Email: ${val}`,
          }),
          ...getArrayItemStringByPath({
            data,
            path: 'Phone',
            format: (val) => `Phone: ${formatPhoneNumber(val)}`,
          }),
          ...getArrayItemStringByPath({
            data,
            path: 'MlsId',
            format: (val) => `MLS ID: ${val}`,
          }),
          ...getArrayItemStringByPath({
            data,
            path: 'StateLicenseNumber',
            format: (val) => `State License Number: ${val}`,
          }),
          ...getArrayItemStringByPath({
            data,
            path: 'TeamName',
            format: (val) => `Team Name: ${val}`,
          }),
          ...getArrayItemStringByPath({
            data,
            path: 'TeamLeadAgentName',
            format: (val) => `Team Lead: ${val}`,
          }),
          /*
          ...getArrayItemStringByPath({
            data,
            path: 'BuyerAgentCompensationAmount',
            format: (val) =>
              `Agent's Fee: ${
                buyerAgenyCompensationType
                  ? val?.split('')?.includes(buyerAgenyCompensationType)
                    ? formatUsd(val)
                    : appendTypetoValue(buyerAgenyCompensationType, String(val))
                  : formatUsd(val)
              }`,
          }),
          */
        ],
      }),

      ...getArrayItemByPath({
        title: 'Office Exclusive',
        data,
        path: 'AgentInfo',
        format: (data) => [
          ...getArrayItemStringByPath({
            data,
            path: 'OfficeExclusiveYN',
            format: boolToYesNo,
          }),
          ...getArrayItemStringByPath({
            data,
            path: 'OfficeExclusiveTermsOfUse',
            format: (val) => `Terms: ${val}`,
          }),
        ],
      }),

      ...getArrayItemByPath({
        title: 'Listing Agreement Type',
        data,
        path: 'AgentInfo.ListingAgreementType',
      }),

      ...getArrayItemByPath({
        title: 'Developer Price',
        data,
        path: 'AgentInfo.Developer',
        format: (data) => [
          ...getArrayItemStringByPath({
            data,
            path: 'PriceMax',
            format: (val) => `Max: ${formatUsd(val)}`,
          }),
          ...getArrayItemStringByPath({
            data,
            path: 'PriceMin',
            format: (val) => `Min: ${formatUsd(val)}`,
          }),
        ],
      }),

      ...getArrayItemByPath({
        title: 'Escrow Agent',
        data,
        path: 'AgentInfo.EscrowAgent',
        format: (data) => [
          ...getArrayItemStringByPath({
            data,
            path: 'Company',
            format: (val) => `Company: ${val}`,
          }),
          ...getArrayItemStringByPath({
            data,
            path: 'Name',
            format: (val) => `Name: ${val}`,
          }),
          ...getArrayItemStringByPath({
            data,
            path: 'Email',
            format: (val) => `Email: ${val}`,
          }),
          ...getArrayItemStringByPath({
            data,
            path: 'Phone',
            format: (val) => `Phone: ${val}`,
          }),
          ...getArrayItemStringByPath({
            data,
            path: 'Address',
            format: (val) => `Address: ${val}`,
          }),
        ],
      }),
      ...getArrayItemByPath({
        title: 'Attribution Contact',
        data,
        path: 'AgentInfo.AttributionContact',
      }),
    ].filter(({ value }) => filterEmptyValues(value)),
  };
});

export const getListingDetailListingInfoTab = createSelector(getListingDetailData, (data) => {
  const leaseInEffect = get(data, 'ListingInfo.LeaseInEffect');

  const monthlyPetRent = get(data, 'BuildingInfo.PetsInformation.MonthlyPetRent');
  const numOfUnitsTotal = get(data, 'BuildingInfo.NumOfUnits');

  const propertyType = get(data, 'PropertyType');
  const buyerAgencyCompensationType = get(data, 'ListingInfo.BuyerAgencyCompensationType');

  let buyerBrokerageCompensationType = get(data, 'ListingInfo.BuyerBrokerageCompensationType');
  const transactionBrokerCompensationType = get(
    data,
    'ListingInfo.TransactionBrokerCompensationType',
  );
  if (!buyerBrokerageCompensationType && transactionBrokerCompensationType) {
    buyerBrokerageCompensationType = transactionBrokerCompensationType;
  }
  const zoning = get(data, 'ListingInfo.Zoning');
  const petDeposit = get(data, 'AgentInfo.PetDeposit');
  const parcelNumber = data.ListingInfo?.ParcelNumber;
  const taxMapKey = parcelNumber ? getTaxMapKey(parcelNumber) : null;
  const market = get(data, 'Market');
  const agentPhoneNumber = get(data, 'AgentInfo.ListingAgent.PhoneMobile');
  const PhoneMobile = getAgentPhoneNumber(market, agentPhoneNumber);
  return [
    ...getArrayItemByPath({
      title: 'MLS List Date',
      data,
      path: 'ListingDate',
      format: (val) => (val ? moment(val).format('MM-DD-YYYY') : null),
    }),

    ...getArrayItemByPath({
      title: 'Directions',
      data,
      path: 'ListingInfo.Directions',
      format: wrapInArray,
    }),

    ...getArrayItemByPath({
      title: 'Additional Information',
      data,
      path: 'ListingInfo.AdditionalInformation',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Days on Market',
      data,
      path: 'ListingDate',
      format: daysFromToday,
    }),

    ...getArrayItemByPath({
      title: 'Location Information',
      data,
      path: 'Address.Geolocation',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'Lat',
          format: (val) => `Latitude: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'Long',
          format: (val) => `Longitude: ${val}`,
        }),
      ],
    }),

    ...getArrayItemStringByPath({
      data,
      path: 'ListingInfo.Homestead',
      format: boolToYesNo,
    }),

    ...getArrayItemStringByPath({
      data,
      path: 'ListingInfo.ResortYN',
      format: boolToYesNo,
    }),

    ...getArrayItemStringByPath({
      data,
      path: 'ListingInfo.SeasonalYN',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Disclosure',
      data,
      path: 'ListingInfo.Disclosure',
      format: wrapInArray,
    }),

    ...getArrayItemByPath({
      title: 'Disclosures',
      data,
      path: 'ListingInfo.Disclosures',
      format: wrapInArray,
    }),
    ...getArrayItemByPath({
      title: 'Exception Yn',
      data,
      path: 'ListingInfo.ExceptionYn',
      format: boolToYesNo,
    }),
    /*
    ...getArrayItemByPath({
      title: 'Buyer Agency Compensation',
      data,
      path: 'ListingInfo.BuyerAgencyCompensation',
      format: (val) =>
        val?.length && val?.split('')?.includes(buyerAgencyCompensationType)
          ? val
          : appendTypetoValue(buyerAgencyCompensationType, String(val)),
    }),

    ...getArrayItemByPath({
      title: 'Buyer Brokerage Compensation',
      data,
      path: 'ListingInfo.BuyerBrokerageCompensation',
      format: (val) =>
        val?.split('')?.includes(buyerAgencyCompensationType)
          ? val
          : appendTypetoValue(buyerAgencyCompensationType, String(val)),
    }),

    ...getArrayItemByPath({
      title: 'Transaction Broker Compensation',
      data,
      path: 'ListingInfo.TransactionBrokerCompensation',
      format: (val) =>
        val?.split('')?.includes(transactionBrokerCompensationType)
          ? val
          : appendTypetoValue(transactionBrokerCompensationType, String(val)),
    }),

    ...getArrayItemByPath({
      title: 'Non Representative Broker Compensation',
      data,
      path: 'AgentInfo.NonRepresentativeBrokerCompensation',
    }),
    */
    ...getArrayItemByPath({
      title: 'Additional Parcels',
      data,
      path: 'ListingInfo',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'AdditionalParcelsYN',
          format: boolToYesNo,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'AdditionalParcelsDescription',
          format: (val) => `Description: ${formatStringList(val)}`,
        }),
      ],
    }),
    ...getArrayItemByPath({
      title: 'Zoning',
      data,
      path: 'ExteriorAndLotDetails',
      format: (data) => [
        ...(zoning ? [`${zoning}`] : []),
        ...getArrayItemStringByPath({
          data,
          path: 'ZoningDescription',
          format: (val) => `Description: ${val}`,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Lease Considered:',
      data,
      path: 'ListingInfo.LeaseConsideredYN',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Lease Restrictions:',
      data,
      path: 'ListingInfo.LeaseRestrictionsYN',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Vacation Rental:',
      data,
      path: 'ListingInfo.VacationRentalYN',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Rental License:',
      data,
      path: 'AgentInfo.RentalLicenseYN',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Pets',
      data,
      path: 'BuildingInfo',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'PetsInformation.PetsAllowedYN',
          format: (val) => `Pets Allowed: ${boolToYesNo(val)}`,
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'PetsInformation.AllowedPets',
          format: (val) => `Allowed Pets: ${formatStringList(val)}`,
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'PetsInformation.MaxNumofPets',
          format: (val) => `Max Num of Pets: ${val}`,
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'PetsInformation.PetRestrictions',
          format: (val) => `Pet Restriction: ${val}`,
        }),

        ...(monthlyPetRent && typeof monthlyPetRent === 'object'
          ? getArrayItemStringByPath({
              data,
              path: 'PetsInformation.MonthlyPetRent.MonthlyPetRent',
              format: (val) => `Monthly Pet Rent: ${val}`,
            })
          : []),

        ...(monthlyPetRent && typeof monthlyPetRent === 'string'
          ? [`Monthly Pet Rent: ${formatUsd(monthlyPetRent)}`]
          : []),

        ...(petDeposit ? [`Pet Deposit: ${formatUsd(petDeposit)}`] : []),
      ],
    }),

    ...(monthlyPetRent && typeof monthlyPetRent === 'string'
      ? [{ title: 'Monthly Pet Rent', value: monthlyPetRent }]
      : []),

    ...(data.Market !== 'bright'
      ? getArrayItemByPath({
          title: 'Listing Agent',
          data,
          path: 'AgentInfo.ListingAgent',
          format: (data) => [
            ...getArrayItemStringByPath({
              data,
              path: 'FullName',
              format: (val) => `Name: ${val}`,
            }),
            ...(PhoneMobile
              ? getArrayItemStringByPath({
                  data,
                  path: 'PhoneMobile',
                  format: (val) => `Phone: ${formatPhoneNumber(val)}`,
                })
              : []),
            ...getArrayItemStringByPath({
              data,
              path: 'PhoneExt',
              format: (val) => `Ext: ${val}`,
            }),
          ],
        })
      : []),

    ...getArrayItemByPath({
      title: 'Township',
      data,
      path: 'ListingInfo.Township',
      format: wrapInArray,
    }),

    ...getArrayItemByPath({
      title: 'Expected Market Date',
      data,
      path: 'ListingInfo.ExpectedMarketDate',
      format: (val) => (val ? getSimpleDate(val, FORMATES.ONLY_DATE) : null),
    }),

    ...getArrayItemByPath({
      title: 'Completion Date',
      data,
      path: 'ListingInfo.CompletionDate',
      format: (val) => (val ? getSimpleDate(val, FORMATES.ONLY_DATE) : null),
    }),

    ...getArrayItemByPath({
      title: 'Parcel Identification Number',
      data,
      path: 'ListingInfo.ParcelNumber',
      format: wrapInArray,
    }),
    ...(market === 'himls' && taxMapKey ? [{ title: 'Tax Map Key', value: taxMapKey }] : []),

    ...getArrayItemByPath({
      title: 'Listing Office Contact Preferred',
      data,
      path: 'ListingOfficeContactPreferred',
    }),

    ...getArrayItemByPath({
      title: 'Listing Office Head Office Key Numeric',
      data,
      path: 'ListingOfficeHeadOfficeKeyNumeric',
    }),

    ...getArrayItemByPath({
      title: 'Smoking Allowed:',
      data,
      path: 'ListingInfo.SmokingInsideYN',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Smoking Allowed:',
      data,
      path: 'ListingInfo.SmokingPermitted',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Foreclosure:',
      data,
      path: 'ListingInfo.ForeclosureStatus',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Fractional Ownership:',
      data,
      path: 'ListingInfo.FractionalOwnershipYN',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Down Payment Resource:',
      data,
      path: 'ListingInfo.DownPaymentResource',
      format: boolToYesNo,
    }),

    ...(propertyType === 'Land'
      ? getArrayItemByPath({
          title: 'Includes House:',
          data,
          path: 'ListingInfo.FractionalOwnershipYN',
          format: boolToYesNo,
        })
      : []),

    ...getArrayItemByPath({
      title: 'Tax Details',
      data,
      path: 'ListingInfo.TaxDetails',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'TaxFilledSqftTotal',
          format: (val) => `Filled sqft Total: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'TaxLegalDescription',
          format: (val) => `Legal Description: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'TaxMapNumber',
          format: (val) => `Map Number: ${val}`,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Rent Includes',
      data,
      path: 'ListingInfo.RentIncludes',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Existing Lease Type',
      data,
      path: 'ListingInfo.ExistingLeaseType',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Available Lease Type',
      data,
      path: 'ListingInfo.AvailableLeaseType',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Number of Units',
      data,
      path: 'ListingInfo',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'NumberOfUnitsMoMo',
          format: (val) => `Month to Month: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'NumberOfUnitsVacant',
          format: (val) => `Vacant: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'UnitsFurnished',
          format: (val) => `Furnished: ${val}`,
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'NumberOfUnitsLeased',
          format: (val) => `Leased: ${val}`,
        }),
        ...(numOfUnitsTotal ? [`Total: ${numOfUnitsTotal}`] : []),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Owner Pays',
      data,
      path: 'AgentInfo.OwnerPays',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Lease',
      data,
      path: 'AgentInfo',
      format: (data) => [
        ...(leaseInEffect ? [`In Effect: ${formatStringList(leaseInEffect)}`] : []),
        ...getArrayItemStringByPath({
          data,
          path: 'LeaseTerm',
          format: (val) => `Term: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'MinLeaseMonths',
          format: (val) => `Min: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'MaxLeaseMonths',
          format: (val) => `Max: ${val}`,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Background Check Required:',
      data,
      path: 'ListingInfo.BackgroundCheckReq',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Prepaid Rent',
      data,
      path: 'ListingInfo.PrePaidRent',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Land Lease:',
      data,
      path: 'ListingInfo.LandLeaseYN',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Additional Lease Restrictions',
      data,
      path: 'ListingInfo.AdditionalLeaseRestrictions',
    }),

    ...getArrayItemByPath({
      title: 'Amenities Additional Fees',
      data,
      path: 'ListingInfo.AmenitiesAdditionalFees',
    }),

    ...getArrayItemByPath({
      title: 'Approval Process',
      data,
      path: 'ListingInfo.ApprovalProcess',
    }),

    ...getArrayItemByPath({
      title: 'Folio Number',
      data,
      path: 'ListingInfo.FolioNumber',
    }),

    ...getArrayItemByPath({
      title: 'Rent Control:',
      data,
      path: 'ListingInfo.RentControlYN',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Tenant Pays',
      data,
      path: 'AgentInfo.TenantPays',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Vacancy Allowance Rate',
      data,
      path: 'ListingInfo.VacancyAllowanceRate',
    }),

    ...getArrayItemByPath({
      title: 'Purchase Option:',
      data,
      path: 'ListingInfo.PurchaseOptionalYN',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Building Permits',
      data,
      path: 'ListingInfo.BuildingPermits',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Move-in Fee',
      data,
      path: 'ListingInfo.MoveInFee',
      format: formatUsd,
    }),

    ...getArrayItemByPath({
      title: 'Application Fee',
      data,
      path: 'ApplicationFee',
      format: formatUsd,
    }),

    ...getArrayItemByPath({
      title: 'Section 8 Approved:',
      data,
      path: 'ListingInfo.Section8Approved',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Months Rent Upfront',
      data,
      path: 'ListingInfo.MonthsRentUpfront',
      format: formatUsd,
    }),

    ...getArrayItemByPath({
      title: 'Tenancy SVO',
      data,
      path: 'ListingInfo.TenancySVO',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Online Application',
      data,
      path: 'ListingInfo',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'AllowOnlineApplicationYN',
          format: boolToYesNo,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'OnlineApplicationInstructions',
          format: formatStringList,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Agent Owned',
      data,
      path: 'ListingInfo.AgentOwned',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Agent Related to Owner',
      data,
      path: 'ListingInfo.AgentRelatedToOwner',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Agent Is Designated Managing Broker',
      data,
      path: 'ListingInfo.AgentIsDesignatedManagingBroker',
      format: boolToYesNo,
    }),

    ...(data.Market !== 'bright'
      ? getArrayItemByPath({
          title: 'Colisting Agent',
          data,
          path: 'AgentInfo.CoListAgent',
          format: agentInfoFormat,
        })
      : []),

    ...getArrayItemByPath({
      title: 'Habitable Residence:',
      data,
      path: 'ListingInfo.HabitableResidenceYN',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Buildout Allowance:',
      data,
      path: 'ExteriorAndLotDetails.BuildoutAllowanceYN',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Home Warranty:',
      data,
      path: 'ExteriorAndLotDetails.HomeWarrantyYN',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Contiguous Square Feet Available:',
      data,
      path: 'ListingInfo.ContiguousSquareFeetAvailableYN',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Other Units',
      data,
      path: 'ListingInfo.OtherUnits',
    }),

    ...getArrayItemByPath({
      title: 'Acceptable Financing',
      data,
      path: 'ListingInfo.AcceptableFinancing',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'College Rental',
      data,
      path: 'ListingInfo.CollegeRental',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Credit Check',
      data,
      path: 'ListingInfo.CreditCheckYN',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Credit Check Amount',
      data,
      path: 'ListingInfo.CreditCheckAmount',
      format: formatUsd,
    }),

    ...getArrayItemByPath({
      title: 'Units',
      data,
      path: 'ListingInfo',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'OneBedroomUnits',
          format: (val) => `One Bedroom: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'SingleRoomUnits',
          format: (val) => `Single Room: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'TwoBedroomUnits',
          format: (val) => `Two Bedrooms: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'ThreeBedroomUnits',
          format: (val) => `Three Bedrooms: ${val}`,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Model',
      data,
      path: 'ListingInfo.Model',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'Hours',
          format: (val) => `Hours: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'Location',
          format: (val) => `Location: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'Phone',
          format: (val) => `Phone: ${formatPhoneNumber(val)}`,
        }),
      ],
    }),
  ].filter(({ value }) => filterEmptyValues(value));
});

export const getListingDetailPropertyTab = createSelector(getListingDetailData, (data) => {
  const propertyType = get(data, 'HomeType');
  const yearBuild = get(data, 'YearBuilt');
  const ageOfProperty = get(data, 'BuildingInfo.Age');
  const market = get(data, 'Market');
  const age = ageOfProperty
    ? ageOfProperty
    : yearBuild
    ? moment().diff(moment(yearBuild, 'YYYY'), 'years')
    : yearBuild;
  const utilities = getUtilitiesArrayStringItemsByPaths({
    data,
    paths: ['ExteriorAndLotDetails.Utilities'],
    format: formatStringList,
  });
  const parkingSpaceNumber = get(data, 'ExteriorAndLotDetails.ParkingSpaceNumber');
  const garageYN = get(data, 'ExteriorAndLotDetails.GarageYN');
  const attachedGarageYN = get(data, 'ExteriorAndLotDetails.AttachedGarageYN');

  const buildingAreaTotal = get(data, 'BuildingInfo.BuildingAreaTotal');
  const fencing = get(data, 'Fencing');
  const fencingYN = get(data, 'FencingYN');

  const numOfStories = get(data, 'NumOfStories');

  const otherStructures = getArrayStringItemsByPaths({
    data,
    paths: ['ExteriorAndLotDetails.OtherStructures', 'ExteriorAndLotDetails.OtherStructuresList'],
    format: formatStringList,
  });

  const heatingFuel = get(data, 'ExteriorAndLotDetails.HeatingFuel');
  const heatingOilAverageMonthly = get(data, 'ExteriorAndLotDetails.HeatingOilAverageMonthly');
  const heatingOilLast12Months = get(data, 'ExteriorAndLotDetails.HeatingOilLast12Months');

  const coolingFuel = get(data, 'ExteriorAndLotDetails.CoolingFuel');
  const lotSize = get(data, 'ExteriorAndLotDetails.LotSize');
  const lotSizeUnits = get(data, 'ExteriorAndLotDetails.LotSizeUnits');

  const parkingTotal = get(data, 'ParkingTotal');

  const LotSizeSquareFeet = get(data, 'LotSizeSquareFeet');
  const LotSizeAcres = get(data, 'LotSizeAcres');
  const LotSizeCalculatedFromRange = get(data, 'LotSizeCalculatedFromRange');
  const lotSizeDimensions = get(data, 'ExteriorAndLotDetails.LotSizeDimensions');
  const numberOfUnitsTotal = Number(get(data, 'Interior.NumberOfUnitsTotal'));
  const carportSp = get(data, 'ExteriorAndLotDetails.CarportSpaces');
  const poolData = market === 'himls' ? get(data, 'ExteriorAndLotDetails.Pool') : true;
  return [
    ...getArrayItemByPath({
      title: 'Ownership',
      data,
      path: 'BuildingInfo.Ownership',
      format: wrapInArray,
    }),

    ...getArrayItemByPath({
      title: 'Additional Property Description',
      data,
      path: 'AdditionalPropertyDescription',
    }),

    ...getArrayItemByPath({
      title: 'Projected Completion Date',
      data,
      path: 'ProjectedCompletionDate',
      format: (val) => (val ? getSimpleDate(val, FORMATES.ONLY_DATE) : null),
    }),

    ...getArrayItemByPath({
      title: 'Year Built',
      data,
      path: 'ExteriorAndLotDetails',
      format: (data) => [
        ...(yearBuild ? [`${yearBuild}`] : []),

        ...(age !== undefined
          ? [
              age <= 0
                ? 'Age: New construction'
                : `Age: ${age} ` +
                  (`${age}`?.toLowerCase()?.includes('year') ? '' : `year${age > 1 ? 's' : ''}`),
            ]
          : []),

        ...getArrayItemStringByPath({
          data,
          path: 'YearBuiltSource',
          format: (val) => `Source: ${val}`,
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'YearBuiltEffective',
          format: (val) => `Effective: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'YearBuiltDetails',
          format: (val) => `Details: ${val}`,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Exterior Features',
      data,
      path: 'ExteriorAndLotDetails.ExteriorFeatures',
      format: formatEachArrayValue(formatStringList),
    }),
    ...getArrayItemByPath({ title: 'Inclusions', data, path: 'AgentInfo.Inclusions' }),
    ...getArrayItemByPath({
      title: 'Above Finished Square Feet',
      data,
      path: 'ExteriorAndLotDetails.AboveGradeFinishedArea',
      format: (val) => (val && Number(val) !== 0 ? formatNumber(val) : null),
    }),
    ...getArrayItemByPath({
      title: 'Below Finished Square Feet',
      data,
      path: 'ExteriorAndLotDetails.BelowGradeFinishedArea',
      format: (val) => (val && Number(val) !== 0 ? formatNumber(val) : null),
    }),
    ...(poolData
      ? getArrayItemByPath({
          title: 'Pool',
          data,
          path: 'ExteriorAndLotDetails',
          format: (data) => [
            ...getArrayItemStringByPath({
              data,
              path: 'Pool',
            }),

            ...getArrayItemStringByPath({
              data,
              path: 'PoolFeatures',
              format: (val) => `Features: ${formatStringList(val)}`,
            }),
            ...getArrayItemStringByPath({
              data,
              path: 'PoolPrivateYN',
              format: (val) => `Private: ${boolToYesNo(val)}`,
            }),
            ...getArrayItemStringByPath({
              data,
              path: 'AccessToPoolYN',
              format: (val) => `Access to Pool: ${boolToYesNo(val)}`,
            }),
            ...getArrayItemStringByPath({
              data,
              path: 'PoolDimensions',
              format: (val) => `Pool Dimensions: ${val}`,
            }),
            ...getArrayItemStringByPath({
              data,
              path: 'CommunityPoolFeatures',
              format: (val) => `Community Pool Features: ${formatStringList(val)}`,
            }),
          ],
        })
      : []),

    ...getArrayItemByPath({
      title: 'Fencing',
      data,
      path: 'ExteriorAndLotDetails',
      format: () => [
        ...(fencingYN || fencingYN === false ? [`${boolToYesNo(fencingYN)}`] : []),
        ...(fencing ? [`${formatStringList(fencing)}`] : []),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Parking',
      data,
      path: 'Parking',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'AssignedSpaces',
          format: (val) => `Assigned Parking Spaces: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'AssignedSpaceNumber',
          format: (val) => `Space #: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'NumDrivewaySpaces',
          format: (val) => `Driveway Spaces: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'NumParkingLotSpaces',
          format: (val) => `Parking Lot Spaces: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'NumOffSiteSpaces',
          format: (val) => `Offsite Spaces: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'NumOffStreetSpaces',
          format: (val) => `Offstreet Spaces: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'ParkingFeatures',
          format: (val) => `Features: ${formatStringList(val)}`,
        }),
        ...(parkingSpaceNumber ? [`Space Number: ${parkingSpaceNumber}`] : []),
        ...getArrayItemStringByPath({
          data,
          path: 'ParkingType',
          format: (val) => `Types: ${formatStringList(val)}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'NumOfParkingSpaces',
          format: (val) => `Number of Spaces: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'NumDetachedGarageSpaces',
          format: (val) => `Number of Spaces (detached garage): ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'NumAttachedGarageSpaces',
          format: (val) => `Number of Spaces (attached garage): ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'NumAttachedCarportSpaces',
          format: (val) => `Number of Spaces (attached carport): ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'NumDetachedCarportSpaces',
          format: (val) => `Number of Spaces (detached carport): ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'NumOfOpenParkingSpaces',
          format: (val) => `Number of Open Parkings ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'NumOfGarageSpaces',
          format: (val) => (val && val !== 0 ? `Number of Spaces (parking garage): ${val}` : null),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'NumTruckTrailerParkingSpaces',
          format: (val) => `Number of Spaces (truck/trailer): ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'NumCarParkingSpaces',
          format: (val) => `Number of Spaces (car): ${val}`,
        }),
        ...(!parkingTotal
          ? getArrayItemStringByPath({
              data,
              path: 'TotalGarageAndParkingSpaces',
              format: (val) => `Total Spaces: ${val}`,
            })
          : []),

        ...getFormattedArrayStringByPath({
          data,
          path: 'DeedParkingCost',
          format: (val) => `Deeded Parking Cost: ${formatUsd(val)}`,
        }),

        ...getFormattedArrayStringByPath({
          data,
          path: 'CoveredSpaces',
          format: (val) => `Covered Spaces: ${formatStringList(val)}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'ExteriorAndLotDetails.CarportSpaces',
          format: (val) => `Carport: ${val}`,
        }),
        ...(parkingTotal ? [`Total Parking: ${parkingTotal}`] : []),
      ],
    }),
    ...getArrayItemByPath({
      title: 'Carport',
      data,
      path: 'ExteriorAndLotDetails',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'CarportYN',
          format: boolToYesNo,
        }),
        ...(carportSp
          ? getArrayItemStringByPath({
              data,
              path: 'CarportSpaces',
              format: (val) => `Spaces: ${val}`,
            })
          : ''),
      ],
    }),
    ...(!data.Parking && garageYN
      ? [
          {
            title: 'Garage',
            value: `${boolToYesNo(garageYN)}`,
          },
        ]
      : []),
    ...(!data.Parking && attachedGarageYN
      ? [
          {
            title: 'Attached Garage',
            value: `${boolToYesNo(attachedGarageYN)}`,
          },
        ]
      : []),

    ...getArrayItemByPath({
      title: 'Garage',
      data,
      path: 'Parking',
      format: (data) => [
        ...(garageYN || garageYN === false ? [`${boolToYesNo(garageYN)}`] : []),
        ...getArrayItemStringByPath({
          data,
          path: 'GarageType',
          format: (val) => `Type: ${formatStringList(val)}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'GarageSpaces',
          format: (val) => `Spaces: ${val}`,
        }),
        ...(attachedGarageYN || (market !== 'tmls' && attachedGarageYN === false)
          ? [`Attached Garage: ${boolToYesNo(attachedGarageYN)}`]
          : []),
        ...getArrayItemStringByPath({
          data,
          path: 'GarageSQFT',
          format: (val) =>
            val && val !== 0 && val !== '0' ? `Size (sq. ft.): ${formatNumber(val)} ` : null,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'GarageDoorHeight',
          format: (val) => `Door Height: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'GarageDoorWidth',
          format: (val) => `Door Width: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'StallNum',
          format: (val) => `Stall Number: ${val}`,
        }),

        ...getFormattedArrayStringByPath({
          data,
          path: 'GarageFeatures',
          format: (val) => `Features: ${formatStringList(val)}`,
        }),

        ...getFormattedArrayStringByPath({
          data,
          path: 'GarageOnSite',
          format: (val) => `on-site: ${val}`,
        }),

        ...getFormattedArrayStringByPath({
          data,
          path: 'GarageOwnership',
          format: (val) => `Ownership: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'GarageRemotes',
          format: (val) => `Garage Remotes: ${val}`,
        }),

        ...getFormattedArrayStringByPath({
          data,
          path: 'DeedGarageCost',
          format: (val) => `Deeded Garage Cost: ${formatUsd(val)}`,
        }),

        ...getFormattedArrayStringByPath({
          data,
          path: 'GarageDimensions',
          format: (val) => `Garage Dimensions: ${formatStringList(val)}`,
        }),

        ...getFormattedArrayStringByPath({
          data,
          path: 'GarageFloor',
          format: (val) => `Garage Floor: ${formatStringList(val)}`,
        }),
        ...getFormattedArrayStringByPath({
          data,
          path: 'RVParkingDimensions',
          format: (val) => `RV Parking Dimensions : ${formatStringList(val)}`,
        }),
      ],
    }),
    ...(lotSizeDimensions !== '0.00 x 0.00'
      ? getArrayItemByPath({
          title: 'Lot Information',
          data,
          path: 'ExteriorAndLotDetails',
          format: (data) => [
            ...(!LotSizeAcres &&
            !LotSizeSquareFeet &&
            data?.LotSizeInfo &&
            isString(data?.LotSizeInfo) &&
            data?.LotSizeInfo !== '0'
              ? [`Lot Size: ${formatStringWithNumber(data?.LotSizeInfo, ' ', false, 2)}`]
              : []),

            ...(lotSize && !LotSizeCalculatedFromRange
              ? [`Size (area): ${formatNumber(Math.round(lotSize))} Square Feet`]
              : []),

            ...getArrayItemStringByPath({
              data,
              path: 'LotSizeSource',
              format: (val) => (val ? `Size (source): ${val}` : null),
            }),

            ...getArrayItemStringByPath({
              data,
              path: 'LotSizeDescription',
              format: (val) => (val ? `Size Description: ${val}` : null),
            }),

            ...getArrayItemStringByPath({
              data,
              path: 'LotDescription',
              format: (val) => (val ? `Description: ${formatStringList(val)}` : null),
            }),

            ...getArrayItemStringByPath({
              data,
              path: 'LotSizeDimensions',
              format: (val) =>
                val && val !== 0 && val !== '0'
                  ? `Dimensions: ${isNumber(val) ? formatNumber(val) : val}`
                  : null,
            }),

            ...getArrayItemStringByPath({
              data,
              path: 'LotDimensionsSource',
              format: (val) => (val ? `Dimensions Source: ${val}` : null),
            }),

            ...(LotSizeSquareFeet && LotSizeSquareFeet != 0 && !LotSizeCalculatedFromRange
              ? [`Size (square feet): ${formatNumber(Math.round(LotSizeSquareFeet))}`]
              : []),
            ...(LotSizeAcres && LotSizeAcres != 0 && !LotSizeCalculatedFromRange
              ? [`Size (acres): ${formatNumber(LotSizeAcres, { maximumFractionDigits: 2 })}`]
              : []),

            ...((LotSizeSquareFeet && LotSizeSquareFeet != 0) || (LotSizeAcres && LotSizeAcres != 0)
              ? [
                  ...getArrayItemStringByPath({
                    data,
                    path: 'LotSizeUnits',
                    format: (val) => (val ? `Size (units): ${val}` : null),
                  }),
                ]
              : []),

            ...getArrayItemStringByPath({
              data,
              path: 'LotFeatures',
              format: (val) =>
                val && isString(data?.LotFeatures)
                  ? `Features: ${formatStringWithNumber(formatStringList(val), ' ')}`
                  : null,
            }),

            ...getArrayItemStringByPath({
              data,
              path: 'FrontageType',
              format: (val) => (val ? `Frontage Type: ${val}` : null),
            }),
            ...getArrayItemStringByPath({
              data,
              path: 'FrontageLength',
              format: (val) => (val ? `Frontage Length: ${val}` : null),
            }),

            ...getArrayItemStringByPath({
              data,
              path: 'DistanceToSewerComments',
              format: (val) => (val ? `Distance To Sewer Comments: ${val}` : null),
            }),
            ...getArrayItemStringByPath({
              data,
              path: 'DistanceToSchoolsComments',
              format: (val) => (val ? `Distance To Schools Comments: ${val}` : null),
            }),
            ...getArrayItemStringByPath({
              data,
              path: 'DistanceToBusComments',
              format: (val) => (val ? `Distance To Bus Comments: ${val}` : null),
            }),
            ...getArrayItemStringByPath({
              data,
              path: 'DistanceToFreewayComments',
              format: (val) => (val ? `Distance To Freeway Comments: ${val}` : null),
            }),
            ...getArrayItemStringByPath({
              data,
              path: 'DistanceToPlaceofWorshipComments',
              format: (val) => (val ? `Distance To Place of Worship Comments: ${val}` : null),
            }),

            ...getArrayItemStringByPath({
              data,
              path: 'DistanceToStreetComments',
              format: (val) => (val ? `Distance To Street Comments: ${val}` : null),
            }),
            ...getArrayItemStringByPath({
              data,
              path: 'DistanceToWaterComments',
              format: (val) => (val ? `Distance To Water Comments: ${val}` : null),
            }),
            ...getArrayItemStringByPath({
              data,
              path: 'DistanceToShoppingComments',
              format: (val) => (val ? `Distance To Shopping Comments: ${val}` : null),
            }),

            ...getArrayItemStringByPath({
              data,
              path: 'DistanceToElectricComments',
              format: (val) => (val ? `Distance To Electric Comments: ${val}` : null),
            }),
            ...getArrayItemStringByPath({
              data,
              path: 'DistanceToGasComments',
              format: (val) => (val ? `Distance To Gas Comments: ${val}` : null),
            }),
            ...getArrayItemStringByPath({
              data,
              path: 'DistanceToPhoneServiceComments',
              format: (val) => (val ? `Distance To Phone Service Comments: ${val}` : null),
            }),
          ],
        })
      : []),

    ...getArrayItemByPath({
      title: 'View',
      data,
      path: 'ExteriorAndLotDetails',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'ViewYN',
          format: boolToYesNo,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'View',
          format: (val) => `Type: ${formatStringList(val)}`,
        }),
      ],
    }),
    ...getArrayItemByPath({
      title: 'Suppliers',
      data,
      path: 'Supplier',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'Electricity',
          format: (val) => `Electricity: ${formatStringList(val)}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'Gas',
          format: (val) => `Gas: ${formatStringList(val)}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'Garbage',
          format: (val) => `Garbage: ${formatStringList(val)}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'Water',
          format: (val) => `Water: ${formatStringList(val)}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'Sewer',
          format: (val) => `Sewer: ${formatStringList(val)}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'Other',
          format: (val) => `Other: ${formatStringList(val)}`,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Direction Faces',
      data,
      path: 'ExteriorAndLotDetails.DirectionFaces',
      format: wrapInArray,
    }),

    ...(utilities?.length ? [{ title: 'Utilities', value: utilities }] : []),

    ...getArrayItemByPath({
      title: 'Heating',
      data,
      path: 'Interior',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'HeatingYN',
          format: boolToYesNo,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'Heating',
          format: (val) => `${formatStringList(val)}`,
        }),

        ...(heatingFuel ? [`Fuel: ${formatStringList(heatingFuel)}`] : []),
        ...(heatingOilAverageMonthly ? [`Oil Average Monthly: ${heatingOilAverageMonthly}`] : []),
        ...(heatingOilLast12Months ? [`Oil Last 12 Months: ${heatingOilLast12Months}`] : []),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Cooling',
      data,
      path: 'Interior',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'CoolingYN',
          format: boolToYesNo,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'Cooling',
          format: (val) => `${formatStringList(val)}`,
        }),
        ...(coolingFuel ? [`Fuel: ${formatStringList(coolingFuel)}`] : []),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Virtual Tour',
      data,
      path: 'Interior',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'PreVisitVTourURL',
          format: (val) => `Previsit VTour URL:${convertToEmbededYoutubeUrl(val)}`,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Electric',
      data,
      path: 'ExteriorAndLotDetails',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'ElectricOnPropertyYN',
          format: (val) => `Electric on Property : ${boolToYesNo(val)}`,
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'Survey Information',
          format: (val) => `Surveyed : ${val}`,
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'Electricity',
          format: formatStringList,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'PowerCompanyName',
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'ElectricAverageMonthly',
          format: (val) => `Monthly Average: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'ElectricLast12Months',
          format: (val) => `Last 12 Months: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'NumberOfSeparateElectricMeters',
          format: (val) => `Separate Electric Meters: ${val}`,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: market === 'maris' ? 'Utilities' : 'Water',
      data,
      path: 'ExteriorAndLotDetails',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'Water',
          format: (val) => `Water source: ${formatStringList(val)}`,
        }),
        ...(market === 'maris'
          ? getArrayItemStringByPath({
              data,
              path: 'Sewage',
              format: (val) => `Sewer: ${formatStringList(val)}`,
            })
          : []),
        ...getArrayItemStringByPath({
          data,
          path: 'WaterAverageMonthly',
          format: (val) => `Water Monthly Average: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'WaterLast12Months',
          format: (val) => `Water Last 12 Months: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'NumberOfSeparateWaterMeters',
          format: (val) => `Separate Water Meters: ${val}`,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Central Air:',
      data,
      path: 'ExteriorAndLotDetails.CentralAirYN',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Hot Water',
      data,
      path: 'ExteriorAndLotDetails.HotWater',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Construction Materials',
      data,
      path: 'ExteriorAndLotDetails',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'ConstructionDetails',
          format: formatStringList,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'ConstructionMaterialsDesc',
          format: formatStringList,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Foundation',
      data,
      path: 'ExteriorAndLotDetails',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'FoundationDetails',
          format: wrapInArrayWithFormat(formatStringList),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'FoundationDimensions',
          format: (val) => `Dimensions: ${val.join(', ')}`,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Roof',
      data,
      path: 'ExteriorAndLotDetails.RoofDetails',
      format: wrapInArrayWithFormat(formatStringList),
    }),
    ...getArrayItemByPath({
      title: 'Roof Design',
      data,
      path: 'ExteriorAndLotDetails.RoofDesign',
      format: wrapInArrayWithFormat(formatStringList),
    }),
    ...getArrayItemByPath({
      title: 'Window Features',
      data,
      path: 'ExteriorAndLotDetails.WindowFeatures',
      format: formatEachArrayValue(formatStringList),
    }),
    ...getArrayItemByPath({
      title: 'Green Attributes',
      data,
      path: 'BuildingInfo.GreenFeatures',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'VerificationYN',
          format: (val) => `Green Verification: ${boolToYesNo(val)}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'EnergyEfficient',
          format: (val) =>
            val && val.length ? `Green Energy Efficient: ${formatStringList(val)}` : null,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'EnergyGeneration',
          format: (val) =>
            val && val.length ? `Green Energy Generation: ${formatStringList(val)}` : null,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'InDoorAirQuality',
          format: (val) =>
            val && val.length ? `Green Indoor Air Quality: ${formatStringList(val)}` : null,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'Remarks',
          format: (val) => (val && val.length ? `Remarks: ${formatStringList(val)}` : null),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'Sustainability',
          format: (val) =>
            val && val.length ? `Green Sustainability: ${formatStringList(val)}` : null,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'WaterConversion',
          format: (val) =>
            val && val.length ? `Water Conservation: ${formatStringList(val)}` : null,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'GreenCertifiedStatus',
          format: (val) =>
            val && val.length ? `Certified Status: ${formatStringList(val)}` : null,
        }),
      ],
    }),
    ...getArrayItemByPath({
      title: 'Builder Name',
      data,
      path: 'CommunityInformation.BuilderName',
      format: formatStringList,
    }),
    ...getArrayItemByPath({
      title: 'Builder Model',
      data,
      path: 'CommunityInformation.BuilderModel',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Development Status',
      data,
      path: 'ExteriorAndLotDetails.DevelopmentStatus',
      format: formatStringList,
    }),
    ...getArrayItemByPath({
      title: 'Door Features',
      data,
      path: 'ExteriorAndLotDetails.DoorFeatures',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Structure Design Type',
      data,
      path: 'ExteriorAndLotDetails.StructureDesignType',
    }),

    ...getArrayItemByPath({
      title: 'Architectural Style',
      data,
      path: 'ExteriorAndLotDetails.ArchitecturalStyle',
      format: formatStringList,
    }),
    ...getArrayItemByPath({
      title: 'Driveway',
      data,
      path: 'ExteriorAndLotDetails.Driveway',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Property Condition',
      data,
      path: 'ExteriorAndLotDetails.PropertyCondition',
      format: formatStringList,
    }),
    ...getArrayItemByPath({
      title: 'Easements',
      data,
      path: 'ExteriorAndLotDetails.Easements',
    }),
    ...getArrayItemByPath({
      title: 'Style',
      data,
      path: 'ExteriorAndLotDetails.Style',
    }),

    ...getArrayItemByPath({
      title: 'Elevator',
      data,
      path: 'ExteriorAndLotDetails.Elevators',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      data,
      path: 'ExteriorAndLotDetails.LavaZone',
      format: (val) => (
        <>
          <b>Lava Zone:</b> {val}
        </>
      ),
    }),
    ...getArrayItemByPath({
      title: 'Land Tenure',
      data,
      path: 'ExteriorAndLotDetails.LandTenure',
    }),

    ...getArrayItemByPath({
      title: 'Exterior',
      data,
      path: 'ExteriorAndLotDetails.Exterior',
      format: wrapInArrayWithFormat(formatStringList),
    }),

    ...getArrayItemByPath({
      title: 'Sewer Access',
      data,
      path: 'ExteriorAndLotDetails.SewageInfo.SewerAccess',
      format: formatStringList,
    }),
    ...(market !== 'maris'
      ? getArrayItemByPath({
          title: 'Sewer',
          data,
          path: 'ExteriorAndLotDetails.Sewage',
          format: formatStringList,
        })
      : []),
    ...getArrayItemByPath({
      title: 'Restrictions',
      data,
      path: 'ExteriorAndLotDetails',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'BuilderRestrictions',
          format: (val) => `Builder: ${val}`,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Improvements',
      data,
      path: 'ExteriorAndLotDetails.Improvements',
      format: (val) => formatStringList(val),
    }),

    ...getArrayItemByPath({
      title: 'Crop Type',
      data,
      path: 'ExteriorAndLotDetails.CropType',
      format: (val) => formatStringList(val),
    }),

    ...getArrayItemByPath({
      title: 'Farm Type',
      data,
      path: 'ExteriorAndLotDetails.FarmType',
      format: (val) => formatStringList(val),
    }),

    ...(propertyType !== 'Land'
      ? getArrayItemByPath({
          title: 'For investors, can owner rent unit:',
          data,
          path: 'ExteriorAndLotDetails.CanOwnerRent',
          format: wrapInArrayWithFormat(boolToYesNo),
        })
      : []),

    ...getArrayItemByPath({
      title: 'Deck',
      data,
      path: 'ExteriorAndLotDetails.Deck',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Enclosed Balcony',
      data,
      path: 'ExteriorAndLotDetails.EnclosedBalcony',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Screened Porch',
      data,
      path: 'ExteriorAndLotDetails.ScreenedPorch',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Terrace',
      data,
      path: 'ExteriorAndLotDetails.Terrace',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Grain Bin',
      data,
      path: 'ExteriorAndLotDetails.GrainBin',
      format: roomInfoFormat,
    }),

    ...getArrayItemByPath({
      title: 'Patio',
      data,
      path: 'ExteriorAndLotDetails.Patio',
      format: roomInfoFormat,
    }),

    ...getArrayItemByPath({
      title: 'Porch',
      data,
      path: 'ExteriorAndLotDetails.Porch',
      format: roomInfoFormat,
    }),

    ...getArrayItemByPath({
      title: 'Distance to UT Shuttle',
      data,
      path: 'ExteriorAndLotDetails.DistanceToUTShuttle',
      format: wrapInArray,
    }),
    ...getArrayItemByPath({
      title: 'Accessibility Features',
      data,
      path: 'AccessibilityFeatures',
      format: formatStringList,
    }),
    ...getArrayItemByPath({
      title: 'FEMA Flood Plain',
      data,
      path: 'ExteriorAndLotDetails.FEMAFloodPlain',
      format: (val) => `In Flood Plain: ${val}`,
    }),

    ...getArrayItemStringByPath({
      data,
      path: 'ExteriorAndLotDetails.HorseYN',
      format: (val) => `Horses Allowed: ${boolToYesNo(val)}`,
    }),
    ...getArrayItemStringByPath({
      data,
      path: 'ExteriorAndLotDetails.HorseAmenities',
      format: (val) => `Horse Amenities: ${val}`,
    }),
    ...(market !== 'scwmls'
      ? getArrayItemByPath({
          title: 'Levels',
          data,
          path: 'Levels',
        })
      : []),

    ...getArrayItemByPath({
      title: 'Security Features',
      data,
      path: 'ExteriorAndLotDetails.SecurityFeatures',
      format: formatStringList,
    }),
    ...getArrayItemByPath({
      title: 'Unit Style',
      data,
      path: 'ExteriorAndLotDetails.UnitStyle',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Total Finished Sq. Ft.(appx.)',
      data,
      path: 'BuildingInfo.TotalFinishedSqFtAppx',
      format: (val) => (val && Number(val) !== 0 ? formatNumber(val) : null),
    }),
    ...getArrayItemByPath({
      title: 'Total Sq. Ft.',
      data,
      path: 'BuildingInfo.TotalSqFt',
      format: (val) => (val && Number(val) !== 0 ? formatNumber(val) : null),
    }),

    ...getArrayItemByPath({
      title: 'Upper Square Feet',
      data,
      path: 'BuildingInfo.UpperSqFt',
      format: (val) => (val && Number(val) !== 0 ? formatNumber(val) : null),
    }),
    ...getArrayItemByPath({
      title: 'Main Square Feet',
      data,
      path: 'BuildingInfo.MainSqft',
      format: (val) => (val && Number(val) !== 0 ? formatNumber(val) : null),
    }),
    ...getArrayItemByPath({
      title: 'Lower Square Feet',
      data,
      path: 'BuildingInfo.LowerSqft',
      format: (val) => (val && Number(val) !== 0 ? formatNumber(val) : null),
    }),
    ...getArrayItemByPath({
      title: 'Number of Units',
      data,
      path: 'BuildingInfo',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'NumOfUnits',
          format: (val) => `Available: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'NumOfUnitsSold',
          format: (val) => `Sold: ${val}`,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Townhouse Characteristics',
      data,
      path: 'ExteriorAndLotDetails.TownhouseCharacteristics',
      format: (val) => `${formatStringList(val)}`,
    }),

    ...getArrayItemByPath({
      title: 'Disability Access',
      data,
      path: 'DisabilityAccess',
      format: formatStringList,
    }),
    ...getArrayItemByPath({
      title: 'Unit Floor Level',
      data,
      path: 'UnitFloorLevel',
      format: formatStringList,
    }),
    ...getArrayItemByPath({
      title: 'Metering',
      data,
      path: 'ExteriorAndLotDetails.Metering',
      format: formatStringList,
    }),
    ...getArrayItemByPath({
      title: 'Internet',
      data,
      path: 'ExteriorAndLotDetails.InternetServices',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Cable',
      data,
      path: 'ExteriorAndLotDetails.CableCompany',
      format: (val) => `Company: ${val}`,
    }),

    ...getArrayItemByPath({
      title: 'Crops Included:',
      data,
      path: 'ExteriorAndLotDetails.CropsIncludedYN',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Cultivated Area',
      data,
      path: 'ExteriorAndLotDetails.CultivatedArea',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Current Use',
      data,
      path: 'ListingInfo.CurrentUse',
      format: wrapInArrayWithFormat(formatStringList),
    }),

    ...getArrayItemByPath({
      title: 'Distance to Body of Water',
      data,
      path: 'ExteriorAndLotDetails',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'DistanceToWaterAccess',
          format: (val) => `Distance: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'DistanceToBodyOfWaterUnits',
          format: (val) => `Units Distance: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'DistanceToBodyOfWaterRemarks',
          format: (val) => `Remarks: ${formatStringList(val)}`,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Water Body',
      data,
      path: 'ExteriorAndLotDetails.WaterBody',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'Name',
          format: (val) => `Name: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'Type',
          format: (val) => `Type: ${formatStringList(val)}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'LakeAcres',
          format: (val) => `Lake Acres: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'LakeBottom',
          format: (val) => `Lake Bottom: ${formatStringList(val)}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'LakeChain',
          format: (val) => `Lake Chain: ${boolToYesNo(val)}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'LakeClass',
          format: (val) => `Lake Class: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'LakeDepth',
          format: (val) => `Lake Depth: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'LakeChainAcreage',
          format: (val) => `Lake Chain Acerage: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'LakeChainName',
          format: (val) => `Lake Chain Name: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'source',
          format: (val) => `Source: ${val}`,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Dock Slip Conveyance',
      data,
      path: 'ExteriorAndLotDetails.DockSlipConveyance',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Farm Credit Service Included:',
      data,
      path: 'ExteriorAndLotDetails.FarmCreditServiceInclYN',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Farm Land Area Source',
      data,
      path: 'ExteriorAndLotDetails.FarmLandAreaSource',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Farm Land Area Units',
      data,
      path: 'ExteriorAndLotDetails.FarmLandAreaUnits',
    }),

    ...getArrayItemByPath({
      title: 'Federal Flood Insurance',
      data,
      path: 'ExteriorAndLotDetails.FederalFloodInsurance',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Federal Flood Zone',
      data,
      path: 'ExteriorAndLotDetails',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'FederalFloodZoneYN',
          format: boolToYesNo,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'FederalFloodZone',
          format: formatStringList,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Irrigation',
      data,
      path: 'ExteriorAndLotDetails',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'IrrigationSource',
          format: (val) => `Source: ${formatStringList(val)}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'IrrigationWaterRightsAcres',
          format: (val) => `Water Rights Acres: ${formatStringList(val)}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'IrrigationWaterRightsYN',
          format: (val) => `Water Rights: ${boolToYesNo(val)}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'IrrigationFeatures',
          format: (val) => `Features: ${val}`,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Number of Buildings',
      data,
      path: 'ExteriorAndLotDetails.NumberOfBuildings',
    }),

    ...getArrayItemByPath({
      title: 'Number of Lots',
      data,
      path: 'ExteriorAndLotDetails.NumberOfLots',
    }),

    ...getArrayItemByPath({
      title: 'Slips',
      data,
      path: 'ExteriorAndLotDetails.Slips',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Area',
      data,
      path: 'ExteriorAndLotDetails.Area',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'Tillable',
          format: (val) => `Tillable: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'Pasture',
          format: (val) => `Pasture: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'Fenced',
          format: (val) => `Fenced: ${boolToYesNo(val)}`,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Road',
      data,
      path: 'ExteriorAndLotDetails',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'RoadResponsibility',
          format: (val) => `Responsibility: ${formatStringList(val)}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'RoadFrontageYN',
          format: boolToYesNo,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'RoadFrontageLength',
          format: (val) => `Frontage Length: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'RoadSurfaceType',
          format: (val) => `Surface Type: ${formatStringList(val)}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'RoadFrontageType',
          format: (val) => `Frontage Type: ${formatStringList(val)}`,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Soil Types',
      data,
      path: 'ExteriorAndLotDetails.SoilTypes',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Topography',
      data,
      path: 'ExteriorAndLotDetails.Topography',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Topography List',
      data,
      path: 'ExteriorAndLotDetails.TopographyList',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Water Access',
      data,
      path: 'ExteriorAndLotDetails',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'WaterAccessYN',
          format: boolToYesNo,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'WaterAccessFeatures',
          format: (val) => `Features: ${formatStringList(val)}`,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Current Adjacent Use',
      data,
      path: 'CurrentAdjacentUse',
    }),

    ...getArrayItemByPath({
      title: 'Vegetation',
      data,
      path: 'Vegetation',
    }),

    ...getArrayItemByPath({
      title: 'Waterfront',
      data,
      path: 'ExteriorAndLotDetails',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'WaterfrontYN',
          format: boolToYesNo,
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'WaterOrientedYN',
          format: (val) => `Oriented: ${boolToYesNo(val)}`,
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'WaterfrontFeatures',
          format: (val) => `Features: ${formatStringList(val)}`,
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'WaterFrontageInFeet',
          format: (val) => `Frontage (feet): ${val}`,
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'RoadBetweenWaterfrontAndHomeYN',
          format: (val) => `Is there a road between waterfront and home ${boolToYesNo(val)}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'WaterfrontElevation',
          format: (val) => `Elevation: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'WaterfrontFeet',
          format: (val) => `Feet: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'WaterfrontNum',
          format: (val) => `Number: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'WaterfrontSlope',
          format: (val) => `Slope: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'WaterFrontRights',
          format: (val) => `Rights: ${val}`,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Wooded Area',
      data,
      path: 'ExteriorAndLotDetails.WoodedArea',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Farm Features',
      data,
      path: 'ExteriorAndLotDetails.FarmFeatures',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Storage',
      data,
      path: 'ExteriorAndLotDetails.Storage',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Farm Operation',
      data,
      path: 'ExteriorAndLotDetails.FarmOperation',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Security Deposit',
      data,
      path: 'AgentInfo.SecurityDeposit',
      format: (val) => formatUsd(val),
    }),

    ...getArrayItemByPath({
      title: 'Farm Remarks',
      data,
      path: 'ExteriorAndLotDetails.FarmRemarks',
    }),

    ...getArrayItemByPath({
      title: 'Building Sites',
      data,
      path: 'ExteriorAndLotDetails.BuildingSites',
    }),

    ...getArrayItemByPath({
      title: 'Exterior Amenities',
      data,
      path: 'ExteriorAndLotDetails.OtherFeatures',
      format: wrapInArrayWithFormat(formatStringList),
    }),

    ...getArrayItemByPath({
      title: 'Well Depth',
      data,
      path: 'ExteriorAndLotDetails.WellDepth',
    }),

    ...getArrayItemByPath({
      title: 'Poultry Farm Features',
      data,
      path: 'ExteriorAndLotDetails.PoultryFarmFeatures',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Dock(s)',
      data,
      path: 'ExteriorAndLotDetails',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'DockTypeAndFeatures',
          format: (val) => `Type and Features: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'DockSlipConveyanceMulti',
          format: (val) => `Dock Slip Conveyance Multi: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'DockType',
          format: (val) => `Type: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'Docks',
          format: (val) => `Docks: ${val}`,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Tidal Water:',
      data,
      path: 'ExteriorAndLotDetails.TidalWaterYN',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Water View:',
      data,
      path: 'ExteriorAndLotDetails.WaterViewYN',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Farm Land Preservation:',
      data,
      path: 'ExteriorAndLotDetails.FarmLandPreservationYN',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Property Use',
      data,
      path: 'ExteriorAndLotDetails.PropertyUse',
    }),
    ...getArrayItemByPath({
      title: 'Possible Use',
      data,
      path: 'ExteriorAndLotDetails.PossibleUse',
    }),

    ...getArrayItemByPath({
      title: 'Other Equipment',
      data,
      path: 'ExteriorAndLotDetails.OtherEquipment',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Riparian Rights:',
      data,
      path: 'ExteriorAndLotDetails.RiparianRightsYN',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Manufactured Home:',
      data,
      path: 'ExteriorAndLotDetails.ManufacturedHome',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Spa',
      data,
      path: 'ExteriorAndLotDetails',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'SpaYN',
          format: boolToYesNo,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'SpaFeatures',
          format: (val) => `Features: ${formatStringList(val)}`,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'In City Limits:',
      data,
      path: 'ExteriorAndLotDetails.InCityLimitsYN',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Historic:',
      data,
      path: 'ExteriorAndLotDetails.HistoricYN',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Land Use Code',
      data,
      path: 'ExteriorAndLotDetails.LandUseCode',
    }),

    ...getArrayItemByPath({
      title: 'Main Level Finished Area',
      data,
      path: 'ExteriorAndLotDetails',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'MainLevelFinishedArea',
          format: (val) => `Area: ${formatNumber(val)}`,
        }),
      ],
    }),

    /*
    ...getArrayItemByPath({
      title: 'Building Area Units',
      data,
      path: 'ExteriorAndLotDetails.BuildingAreaUnits',
    }),
    */

    ...getArrayItemByPath({
      title: 'Entry',
      data,
      path: 'ExteriorAndLotDetails',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'EntryLevel',
          format: (val) => `Level: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'EntryLocation',
          format: (val) => `Location: ${val}`,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Gas',
      data,
      path: 'ExteriorAndLotDetails',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'NumberOfSeparateGasMeters',
          format: (val) => `Separate Gas Meters: ${val}`,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Building Area',
      data,
      path: 'ExteriorAndLotDetails',
      format: (data) => [
        ...(buildingAreaTotal
          ? [
              `Building Area (total): ${formatNumber(buildingAreaTotal)} ${
                data.ExteriorAndLotDetails?.BuildingAreaUnits || ''
              }`,
            ]
          : []),
        ...getArrayItemStringByPath({
          data,
          path: 'BuildingAreaSource',
          format: (val) => `Source: ${val}`,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'New Construction',
      data,
      path: 'NewConstruction',
      format: boolToYesNo,
    }),
    ...getArrayItemByPath({
      title: 'Construction Stage',
      data,
      path: 'ConstructionStage',
    }),

    ...getArrayItemByPath({
      title: 'Construction Type',
      data,
      path: 'ConstructionType',
    }),

    ...getArrayItemByPath({
      title: 'Construction Completed:',
      data,
      path: 'ExteriorAndLotDetails.ConstructionCompletedYN',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Property Attached:',
      data,
      path: 'ExteriorAndLotDetails.PropertyAttachedYN',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Common Walls',
      data,
      path: 'ExteriorAndLotDetails.CommonWalls',
    }),

    ...getArrayItemByPath({
      title: 'Stories',
      data,
      path: 'ExteriorAndLotDetails',
      format: (data) => {
        const stories = getArrayItemStringByPath({
          data,
          path: 'Stories',
          format: (val) => `${val}`,
        });

        const numOfStoriesIncluded = stories.includes(`${numOfStories}`);

        return [
          ...stories,
          ...(numOfStories && !numOfStoriesIncluded ? [`Total: ${numOfStories}`] : []),
        ];
      },
    }),

    ...(otherStructures?.length ? [{ title: 'Other Structures', value: otherStructures }] : []),

    ...getArrayItemByPath({
      title: 'Walls & Ceilings',
      data,
      path: 'ExteriorAndLotDetails.WallsCeilings',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Mobile Dimensions',
      data,
      path: 'ExteriorAndLotDetails',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'MobileLength',
          format: (val) => `Length: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'MobileWidth',
          format: (val) => `Width: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'MobileDimUnits',
          format: (val) => `Units: ${val}`,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Number of Pads',
      data,
      path: 'ExteriorAndLotDetails.NumberOfPads',
    }),

    ...getArrayItemByPath({
      title: 'Leasable Area',
      data,
      path: 'ExteriorAndLotDetails',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'LeasableArea',
          format: (val) => `Area: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'LeasableAreaUnits',
          format: (val) => `Units: ${val}`,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Building Winterized:',
      data,
      path: 'ExteriorAndLotDetails.BuildingWinterizedYN',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Loading Docks',
      data,
      path: 'ExteriorAndLotDetails.TotalLoadingDocks',
    }),

    ...getArrayItemByPath({
      title: 'Levelers',
      data,
      path: 'ExteriorAndLotDetails.TotalLevelers',
    }),

    ...getArrayItemByPath({
      title: 'Drive In Doors',
      data,
      path: 'ExteriorAndLotDetails.TotalDriveInDoors',
    }),

    ...getArrayItemByPath({
      title: 'Unit Floors',
      data,
      path: 'ExteriorAndLotDetails.UnitFloors',
    }),

    ...getArrayItemByPath({
      title: 'Story List',
      data,
      path: 'ExteriorAndLotDetails.StoryList',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Office Sq. Ft. (appx.)',
      data,
      path: 'ExteriorAndLotDetails.ApproximateOfficeSquareFeet',
    }),

    ...getArrayItemByPath({
      title: 'Retail Sq. Ft. (appx.)',
      data,
      path: 'ExteriorAndLotDetails.ApproximateRetailSquareFeet',
    }),

    ...getArrayItemByPath({
      title: 'Warehouse Sq. Ft. (appx.)',
      data,
      path: 'ExteriorAndLotDetails.ApproximateWarehouseSquareFeet',
    }),

    ...getArrayItemByPath({
      title: 'Warehouse Sq. Ft. (appx.)',
      data,
      path: 'ExteriorAndLotDetails.ClearSpanCeilingHeight',
    }),

    ...getArrayItemByPath({
      title: 'Drive In Door Height',
      data,
      path: 'ExteriorAndLotDetails.DriveInDoorHeight',
    }),

    ...getArrayItemByPath({
      title: 'Net Square Feet',
      data,
      format: formatNumber,
      path: 'ExteriorAndLotDetails.NetSquareFeet',
    }),
    ...getArrayItemByPath({
      title: 'Energy Features',
      data,
      path: 'BuildingInfo.EnergyFeatures',
      format: wrapInArray,
    }),

    ...getArrayItemByPath({
      title: 'Overhead Doors',
      data,
      path: 'ExteriorAndLotDetails.NumberOfOverheadDoors',
    }),

    ...getArrayItemByPath({
      title: 'Restrooms',
      data,
      path: 'ExteriorAndLotDetails.TotalRestrooms',
    }),

    ...getArrayItemByPath({
      title: 'Barn Features',
      data,
      path: 'ExteriorAndLotDetails.BarnFeatures',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Seating Capacity',
      data,
      path: 'ExteriorAndLotDetails.SeatingCapacity',
    }),

    ...getArrayItemByPath({
      title: 'Unit Building Type',
      data,
      path: 'ExteriorAndLotDetails.UnitBuildingType',
    }),

    ...getArrayItemByPath({
      title: 'Condo:',
      data,
      path: 'ExteriorAndLotDetails.CondoYN',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Building Classification',
      data,
      path: 'ExteriorAndLotDetails.BuildingClassification',
    }),
    ...getArrayItemByPath({
      title: 'Encumbrances Restrictions',
      data,
      path: 'ExteriorAndLotDetails.EncumbrancesRestrictions',
    }),

    ...getArrayItemByPath({
      title: 'Universal Property Id',
      data,
      path: 'UniversalPropertyId',
    }),

    ...getArrayItemByPath({
      title: 'Additional Unit',
      data,
      path: 'ExteriorAndLotDetails.AdditionalUnit',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Structure Type',
      data,
      path: 'StructureType',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Entry Location',
      data,
      path: 'ExteriorAndLotDetails.EntryLocation',
      format: formatStringList,
    }),

    ...(numberOfUnitsTotal !== 0
      ? getArrayItemByPath({
          title: 'Number of Units (total)',
          data,
          path: 'Interior.NumberOfUnitsTotal',
        })
      : []),

    ...(numberOfUnitsTotal !== 0
      ? getArrayItemByPath({
          title: 'Is Auction',
          data,
          path: 'IsAuction',
          format: boolToYesNo,
        })
      : []),
    ...(numberOfUnitsTotal !== 0
      ? getArrayItemByPath({
          title: 'Second Living Quarters',
          data,
          path: 'ExteriorAndLotDetails.SecondLivingQuarters',
        })
      : []),
    ...getArrayItemByPath({
      title: 'Color',
      data,
      path: 'ExteriorAndLotDetails.Color',
    }),
    ...getArrayItemByPath({
      title: 'Project Features',
      data,
      path: 'ExteriorAndLotDetails.ProjectFeatures',
    }),
  ].filter(({ value }) => filterEmptyValues(value));
});

export const getListingDetailInteriorTab = createSelector(getListingDetailData, (data) => {
  const bathFeatures = get(data, 'Interior.Rooms.Bathroom.Features');
  const bathfull = get(data, 'NumBathsFull');
  const bathhalf = get(data, 'NumBathsHalf');
  const bathThreeQuarter = get(data, 'NumBathThreeQuarter');
  const bathOneQuarter = get(data, 'NumBathOneQuarter');
  const baths = getBathsTotalNum(get(data, 'NumBathsTotal'));
  const BathsAboveGrade = get(data, 'Interior.BathsAboveGrade');
  const bathInfo = [
    ...(bathfull ? [`Bathrooms (Full): ${bathfull}`] : []),
    ...(bathhalf ? [`Bathrooms (1/2): ${bathhalf}`] : []),
    ...(bathOneQuarter ? [`Bathrooms (1/4): ${bathOneQuarter}`] : []),
    ...(bathThreeQuarter ? [`Bathrooms (3/4): ${bathThreeQuarter}`] : []),
    ...(bathFeatures ? [formatStringList(bathFeatures)] : []),
    ...(BathsAboveGrade ? [`Bathrooms Above Grade: ${BathsAboveGrade}`] : []),
  ];

  const numOfFireplaces = get(data, 'Interior.Fireplaces.NumOfFireplaces');
  const otherFeatures = data.Interior?.OtherFeatures || [];
  let filteredFeatures = otherFeatures;
  if (data.Market === 'realtrac') {
    filteredFeatures = otherFeatures.filter((feature) => feature !== 'Primary Bedroom Main Floor');
  }
  const mainLevelBedrooms = data.Interior?.MainLevelBedrooms;
  // Process 'Bedrooms on Main Level'
  let processedMainLevelBedrooms = mainLevelBedrooms;
  if (data.Market === 'realtrac' && processedMainLevelBedrooms) {
    if (otherFeatures.includes('Primary Bedroom Main Floor')) {
      if (!mainLevelBedrooms || mainLevelBedrooms < 1) {
        processedMainLevelBedrooms = '1 (Primary)';
      } else {
        processedMainLevelBedrooms = `${mainLevelBedrooms} (Primary)`;
      }
    }
  }
  const laundryLocation = get(data, 'Interior.LaundryLocation');
  const square = get(data, 'SquareFeet');
  const squareFeetSource = get(data, 'BuildingInfo.SqftSource');

  const roomsTotal = get(data, 'NumRooms');
  const beds = get(data, 'NumBeds');
  const genericRooms = [
    ...getGenericRoomsAsItems({
      title: 'More Rooms',
      data,
      path: 'Interior.GenericRooms',
      format: roomInfoFormat,
    }),
  ];
  const bedrooms = [
    ...getArrayItemByPath({
      title: 'Master Bedroom',
      data,
      path: 'Interior.Rooms.MasterBedroom',
      format: roomInfoFormat,
    }),

    ...getArrayItemByPath({
      title: 'Primary Bedroom',
      data,
      path: 'Interior.Rooms.PrimaryBedroom',
      format: roomInfoFormat,
    }),

    ...getArrayItemByPath({
      title: 'Bedroom',
      data,
      path: 'Interior.Rooms.Bedroom',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Bedroom #1',
      data,
      path: 'Interior.Rooms.Bedroom1',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Bedroom #2',
      data,
      path: 'Interior.Rooms.Bedroom2',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Bedroom #3',
      data,
      path: 'Interior.Rooms.Bedroom3',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Bedroom #4',
      data,
      path: 'Interior.Rooms.Bedroom4',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Bedroom #5',
      data,
      path: 'Interior.Rooms.Bedroom5',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Room Office',
      data,
      path: 'Interior.Rooms.RoomOffice',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Additional Bedroom',
      data,
      path: 'Interior.Rooms.AdditionalBedroom',
      format: roomInfoFormat,
    }),
  ];

  const bathrooms = [
    ...getArrayItemByPath({
      title: 'Primary Bathroom',
      data,
      path: 'Interior.Rooms.PrimaryBathroom',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Master Bathroom',
      data,
      path: 'Interior.Rooms.MasterBathroom',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Bathroom',
      data,
      path: 'Interior.Rooms.Bathroom',
      format: roomInfoFormat,
    }),

    ...getArrayItemByPath({
      title: 'Bathroom #1',
      data,
      path: 'Interior.Rooms.Bathroom1',
      format: roomInfoFormat,
    }),

    ...getArrayItemByPath({
      title: 'Bathroom #2',
      data,
      path: 'Interior.Rooms.Bathroom2',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Bathroom #3',
      data,
      path: 'Interior.Rooms.Bathroom3',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Bathroom #4',
      data,
      path: 'Interior.Rooms.Bathroom4',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Bathroom #5',
      data,
      path: 'Interior.Rooms.Bathroom5',
      format: roomInfoFormat,
    }),
  ];
  return [
    ...getArrayByPath({
      data,
      path: 'UnitDetails',
      keyMapArray: [
        { title: 'Number of Bedrooms', format: '', key: 'NumberOfBedrooms' },
        { title: 'Number of Bathrooms', format: '', key: 'Baths' },
        {
          title: 'Number of Full Bathrooms',
          format: '',
          key: 'NumberOfFullBathrooms',
        },
        {
          title: 'Number of Half Bathrooms',
          format: '',
          key: 'NumberOfHalfBathrooms',
        },
        {
          title: 'Number of Three Quarter Bathrooms',
          format: '',
          key: 'NumberOfThreeQuarterBathrooms',
        },
        {
          title: 'Number of Quarter Bathrooms',
          format: '',
          key: 'NumberOfQuarterBathrooms',
        },
        { title: 'Number of Rooms', format: '', key: 'NumberOfRooms' },
        { title: 'Number of Parkings', format: '', key: 'NumberOfParkings' },
        { title: 'Garage Spaces', format: '', key: 'GarageSpaces' },
        { title: 'Garage Attatched', format: '', key: 'GarageAttatched' },
        { title: 'Number of Units', format: '', key: 'NumberOfUnits' },
        { title: 'Deposit', format: formatUsd, key: 'Deposit' },
        { title: 'Total Rent', format: formatUsd, key: 'TotalRent' },
        { title: 'Actual Rent', format: formatUsd, key: 'ActualRent' },
        { title: 'Actual Rent Range', format: '', key: 'ActualRentRange' },
        { title: 'Leased', format: '', key: 'Leased' },
        {
          title: 'Lease Expiration Date',
          format: '',
          key: 'LeaseExpirationDate',
        },
        { title: 'Lease Expiration', format: '', key: 'LeaseExpiration' },
        { title: 'Month to Month', format: '', key: 'MonthToMonth' },
        { title: 'Pet Deposit', format: formatUsd, key: 'PetDeposit' },
        { title: 'Pet Deposit Per Pet', format: '', key: 'PetDepositPerPet' },
        { title: 'Description', format: '', key: 'Description' },
        { title: 'Furnish Status', format: '', key: 'FurnishStatus' },
        { title: 'Unit sqft', format: '', key: 'UnitSqFt' },
        { title: 'Area', format: '', key: 'Area' },
        { title: 'Area Units', format: '', key: 'AreaUnits' },
        { title: 'Area Source', format: '', key: 'AreaSource' },
        { title: 'Fireplace Exists', format: '', key: 'Fireplace' },
        { title: 'Occupant Type', format: '', key: 'OccupantType' },
        { title: 'Unit Number', format: '', key: 'UnitNumber' },
        { title: 'Unit Type', format: '', key: 'UnitType' },
        { title: 'ProForma', format: '', key: 'ProForma' },
      ],
      initialTitle: 'Unit Details',
    }),
    ...getArrayItemByPath({
      title: 'Square Footage',
      data,
      path: 'ExteriorAndLotDetails',
      format: (data) => [
        ...(square
          ? [
              `${formatNumber(square)} sq. ft.${
                squareFeetSource ? ' (' + squareFeetSource + ')' : ''
              }`,
            ]
          : []),

        ...(data.Area?.Total &&
        Number(data.Area?.Total) !== 0 &&
        Number(data.Area?.Total) !== square
          ? [
              `Area Total: ${formatNumber(data.Area?.Total)}${
                data.Area?.TotalSource ? ' (' + data.Area?.TotalSource + ')' : ''
              } `,
            ]
          : []),

        ...(data.AboveGradeFinishedArea && Number(data.AboveGradeFinishedArea) !== 0
          ? [
              `Above Grade Finished Area: ${formatNumber(data.AboveGradeFinishedArea)}${
                data.AboveGradeFinishedAreaUnits
                  ? ' ' + formatAreaUnits(data.AboveGradeFinishedAreaUnits)
                  : ''
              }${
                data.AboveGradeFinishedAreaSource
                  ? ' (' + data.AboveGradeFinishedAreaSource + ')'
                  : ''
              } `,
            ]
          : []),
        ...(data.ApproximateTotalAreaSquareFeet && Number(data.ApproximateTotalAreaSquareFeet) !== 0
          ? [
              `Approximate Total Area: ${formatNumber(data.ApproximateTotalAreaSquareFeet)}${
                data.ApproximateTotalAreaSquareFeet
                  ? ' ' + formatAreaUnits(data.ApproximateTotalAreaSquareFeet)
                  : ''
              }${
                data.ApproximateTotalAreaSquareFeet
                  ? ' (' + data.ApproximateTotalAreaSquareFeet + ')'
                  : ''
              } `,
            ]
          : []),
        ...(data.ApproximateTotalFinishedAreaSquareFeet &&
        Number(data.ApproximateTotalFinishedAreaSquareFeet) !== 0
          ? [
              `Approximate Sq. Ft. Total Finished: ${formatNumber(
                data.ApproximateTotalFinishedAreaSquareFeet,
              )}`,
            ]
          : []),
        ...(data.BelowGradeAreaTotal &&
        Number(data.BelowGradeAreaTotal) !== 0 &&
        data.BelowGradeAreaTotal !== data.BelowGradeFinishedArea
          ? [
              `Below Grade Area: ${formatNumber(data.BelowGradeAreaTotal)}${
                data.BelowGradeAreaTotalSource ? ' (' + data.BelowGradeAreaTotalSource + ')' : ''
              } `,
            ]
          : []),

        ...(data.BelowGradeFinishedArea && Number(data.BelowGradeFinishedArea) !== 0
          ? [
              `Below Grade Finished Area: ${formatNumber(data.BelowGradeFinishedArea)}${
                data.BelowGradeFinishedAreaUnits
                  ? ' ' + formatAreaUnits(data.BelowGradeFinishedAreaUnits)
                  : ''
              }${
                data.BelowGradeFinishedAreaSource
                  ? ' (' + data.BelowGradeFinishedAreaSource + ')'
                  : ''
              } `,
            ]
          : []),

        ...(data.BelowGradeUnfinishedArea && Number(data.BelowGradeUnfinishedArea) !== 0
          ? [
              `Below Grade Unfinished Area: ${formatNumber(data.BelowGradeUnfinishedArea)}${
                data.BelowGradeUnfinishedAreaUnits ? ' ' + data.BelowGradeUnfinishedAreaUnits : ''
              }${
                data.BelowGradeUnfinishedAreaSource
                  ? ' (' + data.BelowGradeUnfinishedAreaSource + ')'
                  : ''
              } `,
            ]
          : []),

        ...getArrayItemStringByPath({
          data,
          path: 'OtherAreaAboveGrade',
          format: (val) => `Other Area Above Grade: ${val}`,
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'TotalOtherAreaSqFt',
          format: (val) => `Total Other Area SqFt: ${val}`,
        }),

        ...(data.Area?.LowerLevel !== 0 && data.Area?.LowerLevel !== 0.0
          ? getArrayItemStringByPath({
              data,
              path: 'Area.LowerLevel',
              format: (val) => `Lower Level Area: ${val}`,
            })
          : []),

        ...getArrayItemStringByPath({
          data,
          path: 'Area.UpperLevel',
          format: (val) =>
            isValidNumericValue(val) ? `Upper Level Area: ${formatDecimal(val)}` : null,
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'Area.MainLevel',
          format: (val) =>
            isValidNumericValue(val) ? `Main Level Area: ${formatDecimal(val)}` : null,
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'OptionalLevel.Level',
          format: (val) => `Optional Level: ${val}`,
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'OptionalLevel.Area',
          format: (val) =>
            isValidNumericValue(val) ? `Optional Level Area: ${formatDecimal(val)}` : null,
        }),
      ],
    }),

    ...(bathInfo?.length ? [{ title: 'Bathrooms', value: bathInfo }] : []),
    ...bathrooms,
    ...getArrayItemByPath({
      title: 'Bathroom Features',
      data,
      path: 'ExteriorAndLotDetails.BathroomFeatures',
    }),
    ...getArrayItemByPath({
      title: 'Main Level',
      data,
      path: 'Interior.MainLevel',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'TotalBaths',
          format: (val) =>
            isValidNumericValue(val) ? `Bathrooms (total): ${formatDecimal(val)}` : null,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'TotalFullBaths',
          format: (val) =>
            isValidNumericValue(val) ? `Bathrooms (full): ${formatDecimal(val)}` : null,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'TotalHalfBaths',
          format: (val) =>
            isValidNumericValue(val) ? `Bathrooms (half): ${formatDecimal(val)}` : null,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'TotalBedrooms',
          format: (val) => (isValidNumericValue(val) ? `Bedrooms: ${formatDecimal(val)}` : null),
        }),
      ],
    }),
    ...getArrayItemByPath({
      title: 'Third Level',
      data,
      path: 'Interior.ThirdLevel',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'TotalBaths',
          format: (val) =>
            isValidNumericValue(val) ? `Bathrooms (total): ${formatDecimal(val)}` : null,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'TotalFullBaths',
          format: (val) =>
            isValidNumericValue(val) ? `Bathrooms (full): ${formatDecimal(val)}` : null,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'TotalHalfBaths',
          format: (val) =>
            isValidNumericValue(val) ? `Bathrooms (half): ${formatDecimal(val)}` : null,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'TotalBedrooms',
          format: (val) => (isValidNumericValue(val) ? `Bedrooms: ${formatDecimal(val)}` : null),
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Upper Levels',
      data,
      path: 'Interior.UpperLevel',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'TotalBaths',
          format: (val) =>
            isValidNumericValue(val) ? `Bathrooms (total): ${formatDecimal(val)}` : null,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'TotalFullBaths',
          format: (val) =>
            isValidNumericValue(val) ? `Bathrooms (full): ${formatDecimal(val)}` : null,
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'TotalHalfBaths',
          format: (val) =>
            isValidNumericValue(val) ? `Bathrooms (half): ${formatDecimal(val)}` : null,
        }),
      ],
      ...getArrayItemStringByPath({
        data,
        path: 'TotalBedrooms',
        format: (val) => (isValidNumericValue(val) ? `Bedrooms: ${formatDecimal(val)}` : null),
      }),
    }),
    ...getArrayItemByPath({
      title: 'Upper Levels 2',
      data,
      path: 'Interior.UpperLevel2',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'TotalBaths',
          format: (val) =>
            isValidNumericValue(val) ? `Bathrooms (total): ${formatDecimal(val)}` : null,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'TotalFullBaths',
          format: (val) =>
            isValidNumericValue(val) ? `Bathrooms (full): ${formatDecimal(val)}` : null,
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'TotalHalfBaths',
          format: (val) =>
            isValidNumericValue(val) ? `Bathrooms (half): ${formatDecimal(val)}` : null,
        }),
      ],
      ...getArrayItemStringByPath({
        data,
        path: 'TotalBedrooms',
        format: (val) => (isValidNumericValue(val) ? `Bedrooms: ${formatDecimal(val)}` : null),
      }),
    }),
    ...getArrayItemByPath({
      title: 'Lower Levels',
      data,
      path: 'Interior.LowerLevel',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'TotalBaths',
          format: (val) =>
            isValidNumericValue(val) ? `Bathrooms (total): ${formatDecimal(val)}` : null,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'TotalFullBaths',
          format: (val) =>
            isValidNumericValue(val) ? `Bathrooms (full): ${formatDecimal(val)}` : null,
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'TotalHalfBaths',
          format: (val) =>
            isValidNumericValue(val) ? `Bathrooms (half): ${formatDecimal(val)}` : null,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'TotalBedrooms',
          format: (val) => (isValidNumericValue(val) ? `Bedrooms: ${formatDecimal(val)}` : null),
        }),
      ],
    }),
    ...getArrayItemByPath({
      title: 'Lower Levels 2',
      data,
      path: 'Interior.LowerLevel2',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'TotalBaths',
          format: (val) =>
            isValidNumericValue(val) ? `Bathrooms (total): ${formatDecimal(val)}` : null,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'TotalFullBaths',
          format: (val) =>
            isValidNumericValue(val) ? `Bathrooms (full): ${formatDecimal(val)}` : null,
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'TotalHalfBaths',
          format: (val) =>
            isValidNumericValue(val) ? `Bathrooms (half): ${formatDecimal(val)}` : null,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'TotalBedrooms',
          format: (val) => (isValidNumericValue(val) ? `Bedrooms: ${formatDecimal(val)}` : null),
        }),
      ],
    }),
    ...(baths
      ? getArrayItemByPath({
          title: 'Total Bathrooms',
          data,
          path: 'NumBathsTotal',

          format: (val) => ` ${getBathsTotalNum(val)}`,
        })
      : []),
    ...getArrayItemByPath({
      title: 'Rooms',
      data,
      path: 'Interior.Rooms',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'Total',
          format: (val) => `Total: ${val}`,
        }),

        ...(roomsTotal && !data.Total ? [`Total: ${roomsTotal}`] : []),

        ...getArrayItemStringByPath({
          data,
          path: 'RoomInformation',
          format: (val) =>
            val && val.length
              ? `Information: ${formatRoomInfoString(formatArrayToString(val))}`
              : null,
        }),
      ],
    }),
    ...getArrayItemByPath({
      title: 'Bedroom Information',
      path: 'Interior.Rooms',
      data,
      format: (data) => [
        ...(beds ? [`Total Bedrooms: ${beds}`] : []),

        ...(processedMainLevelBedrooms
          ? [`Bedrooms on Main Level: ${processedMainLevelBedrooms}`]
          : []),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Living Room',
      data,
      path: 'Interior.Rooms.LivingRoom',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Dining Room',
      data,
      path: 'Interior.Rooms.DiningRoom',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Kitchen',
      data,
      path: 'Interior.Rooms.Kitchen',
      format: roomInfoFormat,
    }),

    ...getArrayItemByPath({
      title: 'Basement',
      data,
      path: 'Interior.Rooms',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'BasementYN',
          format: (val) => (boolToYesNo(val) === 'Yes' ? 'Has Basement' : ''),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'Basement',
          format: (val) => (val === 'Y' ? `Yes` : val === 'N' ? `No` : `${formatStringList(val)}`),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'BasementFeatures',
          format: (val) => `Features: ${formatStringList(val)}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'BasementFinishedPercent',
          format: (val) => `Finished: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'BasementAccess',
          format: (val) => `Access: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'BasementFootprintPercent',
          format: (val) => `Footprint: ${val}`,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Family Room',
      data,
      path: 'Interior.Rooms.FamilyRoom',
      format: roomInfoFormat,
    }),

    ...getArrayItemByPath({
      title: 'Loft',
      data,
      path: 'Interior.Rooms.Loft',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Workshop',
      data,
      path: 'Interior.Rooms.Workshop',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Den',
      data,
      path: 'Interior.Rooms.Den',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Office',
      data,
      path: 'Interior.Rooms.Office',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Exercise Room',
      data,
      path: 'Interior.Rooms.ExerciseRoom',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Gym',
      data,
      path: 'Interior.Rooms.Gym',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Storage',
      data,
      path: 'Interior.Rooms.Storage',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Game Room',
      data,
      path: 'Interior.Rooms.GameRoom',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Sun Room',
      data,
      path: 'Interior.Rooms.SunRoom',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Foyer',
      data,
      path: 'Interior.Rooms.Foyer',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Great Room',
      data,
      path: 'Interior.Rooms.GreatRoom',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Recreation Room',
      data,
      path: 'Interior.Rooms.RecreationRoom',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Other Room',
      data,
      path: 'Interior.Rooms.OtherRoom',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Other',
      data,
      path: 'Interior.Rooms.Other',
      format: unCategoizedRoomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Breakfast Room',
      data,
      path: 'Interior.Rooms.BreakfastRoom',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Play Room',
      data,
      path: 'Interior.Rooms.PlayRoom',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Enclosed Porch',
      data,
      path: 'Interior.Rooms.EnclosedPorch',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Mud Room',
      data,
      path: 'Interior.Rooms.MudRoom',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Library',
      data,
      path: 'Interior.Rooms.Library',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Pantry',
      data,
      path: 'Interior.Rooms.Pantry',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Walk In Closet',
      data,
      path: 'Interior.Rooms.WalkInCloset',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Bonus Room',
      data,
      path: 'Interior.Rooms.BonusRoom',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Sitting Room',
      data,
      path: 'Interior.Rooms.SittingRoom',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Theatre Room',
      data,
      path: 'Interior.Rooms.TheatreRoom',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Eating Area',
      data,
      path: 'Interior.Rooms.EatingArea',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Attic',
      data,
      path: 'Interior.Rooms.Attic',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Study',
      data,
      path: 'Interior.Rooms.Study',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Gallery',
      data,
      path: 'Interior.Rooms.Gallery',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Heated Sun Room',
      data,
      path: 'Interior.Rooms.HeatedSunRoom',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Suite',
      data,
      path: 'Interior.Rooms.Suite',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Tandem Room',
      data,
      path: 'Interior.Rooms.TandemRoom',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Enclosed Porch Heated',
      data,
      path: 'Interior.Rooms.EnclosedPorchHeated',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Media Room',
      data,
      path: 'Interior.Rooms.MediaRoom',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Nursery',
      data,
      path: 'Interior.Rooms.Nursery',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Utility Room',
      data,
      path: 'Interior.Rooms.UtilityRoom',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Utility Room Lower Level',
      data,
      path: 'Interior.Rooms.UtilityRoomLowerLevel',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Sauna',
      data,
      path: 'Interior.Rooms.Sauna',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Work Room',
      data,
      path: 'Interior.Rooms.WorkRoom',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Family Room Down',
      data,
      path: 'Interior.Rooms.FamilyRoomDown',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Balcony/Porch/Lanai',
      data,
      path: 'Interior.Rooms.Balcony_Porch_Lanai',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Bar/Entertainment',
      data,
      path: 'Interior.Rooms.Bar_Entertainment',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Kitchen 2nd',
      data,
      path: 'Interior.Rooms.Kitchen2nd',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Utility Room 2nd Floor',
      data,
      path: 'Interior.Rooms.UtilityRoom2ndFloor',
      format: roomInfoFormat,
    }),
    ...getArrayItemByPath({
      title: 'Utility Room 1st Floor',
      data,
      path: 'Interior.Rooms.UtilityRoom1stFloor',
      format: roomInfoFormat,
    }),

    ...getArrayItemByPath({
      title: 'Office/Study',
      data,
      path: 'Interior.Rooms.Office_Study',
      format: roomInfoFormat,
    }),

    ...getArrayItemByPath({
      title: 'Living Area/Other Room 1',
      data,
      path: 'Interior.Rooms.LivingArea_OtherRoom1',
      format: roomInfoFormat,
    }),

    ...getArrayItemByPath({
      title: 'Living Area/Other Room 3',
      data,
      path: 'Interior.Rooms.LivingArea_OtherRoom3',
      format: roomInfoFormat,
    }),

    ...getArrayItemByPath({
      title: 'Other Area/Room 2',
      data,
      path: 'Interior.Rooms.OtherArea_Room2',
      format: roomInfoFormat,
    }),

    ...getArrayItemByPath({
      title: 'Laundry',
      data,
      path: 'Interior.Rooms',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'UnitWasherDryerHookupYN',
          format: (val) => `Is there a washer dryer hookup: ${boolToYesNo(val)}`,
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'Laundry.Size',
          format: (val) => `Size: ${val && val}`,
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'Laundry.Level',
          format: (val) => `Level: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'Laundry.Flooring',
          format: (val) => `Flooring: ${formatStringList(val)} `,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'Laundry.Features',
          format: (val) => `Features: ${formatStringList(val)}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'Laundry.Description',
          format: (val) => `Description: ${formatStringList(val)}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'Laundry.BathroomFeatures',
          format: (val) => `Features: ${formatStringList(val)}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'Laundry.Accessibility',
          format: (val) => `Accessibility Features: ${formatStringList(val)}`,
        }),

        ...(laundryLocation ? [`Location: ${formatStringList(laundryLocation)}`] : []),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Entry',
      data,
      path: 'Interior.Rooms.Entry',
      format: roomInfoFormat,
    }),

    ...getArrayItemByPath({
      title: 'Guest Quarters',
      data,
      path: 'Interior.Rooms.GuestQuarters',
      format: roomInfoFormat,
    }),

    ...getArrayItemByPath({
      title: 'Wine Cellar',
      data,
      path: 'Interior.Rooms.WineCellar',
      format: roomInfoFormat,
    }),

    ...getArrayItemByPath({
      title: 'Sun Porch',
      data,
      path: 'Interior.Rooms.SunPorch',
      format: roomInfoFormat,
    }),

    ...getArrayItemByPath({
      title: 'Hot Tub Room',
      data,
      path: 'Interior.Rooms.HotTubRoom',
      format: roomInfoFormat,
    }),

    ...getArrayItemByPath({
      title: 'Nook',
      data,
      path: 'Interior.Rooms.Nook',
      format: roomInfoFormat,
    }),
    ...genericRooms,
    ...bedrooms,
    ...getArrayItemByPath({
      title: 'Master Bed and Bath Features',
      data,
      path: 'Interior.MasterBedandBathFeatures',
    }),

    ...getArrayItemByPath({
      title: 'Additional Rooms',
      data,
      path: 'Interior.AdditionalRooms',
      format: wrapInArrayWithFormat(formatStringList),
    }),

    ...getArrayItemByPath({
      title: 'Appliances',
      data,
      path: 'Interior.Equipment',
      format: formatEachArrayValue(formatStringList),
    }),
    ...getArrayItemByPath({
      title: 'Attic Description',
      data,
      path: 'Interior.AtticDescription',
    }),
    ...getArrayItemByPath({
      title: 'InLawApartmentYN',
      data,
      path: 'Interior.InLawApartmentYN',
    }),
    ...getArrayItemByPath({
      title: 'InLaw Access',
      data,
      path: 'Interior.InLawAccess',
    }),
    ...getArrayItemByPath({
      title: 'InLaw Apartment',
      data,
      path: 'Interior.InLawApartment',
    }),
    ...getArrayItemByPath({
      title: 'AtticYN',
      data,
      path: 'Interior.AtticYN',
      format: boolToYesNo,
    }),
    ...getArrayItemByPath({
      title: 'Home Appliances',
      data,
      path: 'Interior.Appliances',
    }),

    ...(data.Market === 'realtrac' && filteredFeatures
      ? [
          {
            title: 'Features',
            value: `${formatStringList(filteredFeatures)}`,
          },
        ]
      : getArrayItemByPath({
          title: 'Features',
          data,
          path: 'Interior.OtherFeatures',
          format: formatEachArrayValue(formatStringList),
        })),

    ...getArrayItemByPath({
      title: 'Furnished:',
      data,
      path: 'Furnished',
    }),

    ...getArrayItemByPath({
      title: 'Flooring',
      data,
      path: 'Interior.Flooring',
      format: formatEachArrayValue(formatStringList),
    }),

    ...getArrayItemByPath({
      title: 'Fireplace(s)',
      data,
      path: 'Interior.Fireplaces',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'FireplaceYN',
          format: boolToYesNo,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'NumOfFireplaces',
          format: (val) => (val && val !== 0 ? `Total: ${val}` : null),
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'Location',
          format: (val) => `Location: ${formatStringList(val)}`,
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'Features',
          format: (val) => `Features: ${formatStringList(val)}`,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Dining Spaces',
      data,
      path: 'Interior.DiningSpaces',
    }),
    ...getArrayItemByPath({
      title: 'Living Spaces',
      data,
      path: 'Interior.LivingSpaces',
    }),
    ...getArrayItemByPath({
      title: 'Bedrooms on Other Levels',
      data,
      path: 'Interior.BedroomsOnOtherLevels',
    }),

    // ...getArrayItemByPath({
    //   title: 'Living Area',
    //   data,
    //   path: 'Interior',
    //   format: (data) => [
    //     ...getArrayItemStringByPath({
    //       data,
    //       path: 'LivingArea',
    //       format: (val) => `Area: ${val}`,
    //     }),
    //     ...getArrayItemStringByPath({
    //       data,
    //       path: 'LivingAreaUnits',
    //       format: (val) => `Units: ${val}`,
    //     }),
    //     ...getArrayItemStringByPath({
    //       data,
    //       path: 'LivingAreaSource',
    //       format: (val) => `Source: ${val}`,
    //     }),
    //   ],
    // }),

    ...getArrayItemByPath({
      title: 'Number of Rooms on Basement Level',
      data,
      path: 'Interior.RoomsPerLevel.Basement',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'Beds',
          format: (val) => (isValidNumericValue(val) ? `Bedrooms: ${formatDecimal(val)}` : null),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'Baths',
          format: (val) =>
            isValidNumericValue(val) ? `Bathrooms (total): ${formatDecimal(val)}` : null,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'FullBaths',
          format: (val) =>
            isValidNumericValue(val) ? `Bathrooms (full): ${formatDecimal(val)}` : null,
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'HalfBaths',
          format: (val) =>
            isValidNumericValue(val) ? `Bathrooms (half): ${formatDecimal(val)}` : null,
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'Kitchen',
          format: (val) => (isValidNumericValue(val) ? `Kitchen: ${formatDecimal(val)}` : null),
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'DiningRoom',
          format: (val) => (isValidNumericValue(val) ? `Dining Room: ${formatDecimal(val)}` : null),
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'LivingRoom',
          format: (val) => (isValidNumericValue(val) ? `Living Room: ${formatDecimal(val)}` : null),
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'Laundry',
          format: (val) => (isValidNumericValue(val) ? `Laundry: ${formatDecimal(val)}` : null),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'GreatRoom',
          format: (val) => (isValidNumericValue(val) ? `Great Room: ${formatDecimal(val)}` : null),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'Den',
          format: (val) => (isValidNumericValue(val) ? `Den Room: ${formatDecimal(val)}` : null),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'FamilyRoom',
          format: (val) => (isValidNumericValue(val) ? `Family Room: ${formatDecimal(val)}` : null),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'UtilityRoom',
          format: (val) =>
            isValidNumericValue(val) ? `Utility Room: ${formatDecimal(val)}` : null,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'RecRoom',
          format: (val) => (isValidNumericValue(val) ? `Rec Room: ${formatDecimal(val)}` : null),
        }),
      ],
    }),
    ...getArrayItemByPath({
      title: 'Number of Rooms on Basement Level2',
      data,
      path: 'Interior.RoomsPerLevel.Basement2',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'Beds',
          format: (val) => (isValidNumericValue(val) ? `Bedrooms: ${formatDecimal(val)}` : null),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'Baths',
          format: (val) =>
            isValidNumericValue(val) ? `Bathrooms (total): ${formatDecimal(val)}` : null,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'FullBaths',
          format: (val) =>
            isValidNumericValue(val) ? `Bathrooms (full): ${formatDecimal(val)}` : null,
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'HalfBaths',
          format: (val) =>
            isValidNumericValue(val) ? `Bathrooms (half): ${formatDecimal(val)}` : null,
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'Kitchen',
          format: (val) => (isValidNumericValue(val) ? `Kitchen: ${formatDecimal(val)}` : null),
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'DiningRoom',
          format: (val) => (isValidNumericValue(val) ? `Dining Room: ${formatDecimal(val)}` : null),
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'LivingRoom',
          format: (val) => (isValidNumericValue(val) ? `Living Room: ${formatDecimal(val)}` : null),
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'Laundry',
          format: (val) => (isValidNumericValue(val) ? `Laundry: ${formatDecimal(val)}` : null),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'GreatRoom',
          format: (val) => (isValidNumericValue(val) ? `Great Room: ${formatDecimal(val)}` : null),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'Den',
          format: (val) => (isValidNumericValue(val) ? `Den Room: ${formatDecimal(val)}` : null),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'FamilyRoom',
          format: (val) => (isValidNumericValue(val) ? `Family Room: ${formatDecimal(val)}` : null),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'UtilityRoom',
          format: (val) =>
            isValidNumericValue(val) ? `Utility Room: ${formatDecimal(val)}` : null,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'RecRoom',
          format: (val) => (isValidNumericValue(val) ? `Rec Room: ${formatDecimal(val)}` : null),
        }),
      ],
    }),
    ...getArrayItemByPath({
      title: 'Number of Rooms on First Floor',
      data,
      path: 'Interior.RoomsPerLevel.FirstFloor',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'Beds',
          format: (val) => (isValidNumericValue(val) ? `Bedrooms: ${formatDecimal(val)}` : null),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'Baths',
          format: (val) =>
            isValidNumericValue(val) ? `Bathrooms (total): ${formatDecimal(val)}` : null,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'FullBaths',
          format: (val) =>
            isValidNumericValue(val) ? `Bathrooms (full): ${formatDecimal(val)}` : null,
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'HalfBaths',
          format: (val) =>
            isValidNumericValue(val) ? `Bathrooms (half): ${formatDecimal(val)}` : null,
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'Kitchen',
          format: (val) => (isValidNumericValue(val) ? `Kitchen: ${formatDecimal(val)}` : null),
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'DiningRoom',
          format: (val) => (isValidNumericValue(val) ? `Dining Room: ${formatDecimal(val)}` : null),
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'LivingRoom',
          format: (val) => (isValidNumericValue(val) ? `Living Room: ${formatDecimal(val)}` : null),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'Laundry',
          format: (val) => (isValidNumericValue(val) ? `Laundry: ${formatDecimal(val)}` : null),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'MasterBedRoomSuite',
          format: (val) => (isValidNumericValue(val) ? `Master Beds: ${formatDecimal(val)}` : null),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'FamilyGameRoom',
          format: (val) =>
            isValidNumericValue(val) ? `Family Game Room: ${formatDecimal(val)}` : null,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'GreatRoom',
          format: (val) => (isValidNumericValue(val) ? `Great Room: ${formatDecimal(val)}` : null),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'Den',
          format: (val) => (isValidNumericValue(val) ? `Den Room: ${formatDecimal(val)}` : null),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'FamilyRoom',
          format: (val) => (isValidNumericValue(val) ? `Family Room: ${formatDecimal(val)}` : null),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'UtilityRoom',
          format: (val) =>
            isValidNumericValue(val) ? `Utility Room: ${formatDecimal(val)}` : null,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'RecRoom',
          format: (val) => (isValidNumericValue(val) ? `Rec Room: ${formatDecimal(val)}` : null),
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Number of Rooms on Second Floor',
      data,
      path: 'Interior.RoomsPerLevel.SecondFloor',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'Beds',
          format: (val) => (isValidNumericValue(val) ? `Bedrooms: ${formatDecimal(val)}` : null),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'Baths',
          format: (val) =>
            isValidNumericValue(val) ? `Bathrooms (total): ${formatDecimal(val)}` : null,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'FullBaths',
          format: (val) =>
            isValidNumericValue(val) ? `Bathrooms (full): ${formatDecimal(val)}` : null,
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'HalfBaths',
          format: (val) =>
            isValidNumericValue(val) ? `Bathrooms (half): ${formatDecimal(val)}` : null,
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'Kitchen',
          format: (val) => (isValidNumericValue(val) ? `Kitchen: ${formatDecimal(val)}` : null),
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'DiningRoom',
          format: (val) => (isValidNumericValue(val) ? `Dining Room: ${formatDecimal(val)}` : null),
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'LivingRoom',
          format: (val) => (isValidNumericValue(val) ? `Living Room: ${formatDecimal(val)}` : null),
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'Laundry',
          format: (val) => (isValidNumericValue(val) ? `Laundry: ${formatDecimal(val)}` : null),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'MasterBedRoomSuite',
          format: (val) => (isValidNumericValue(val) ? `Master Beds: ${formatDecimal(val)}` : null),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'FamilyGameRoom',
          format: (val) =>
            isValidNumericValue(val) ? `Family Game Room: ${formatDecimal(val)}` : null,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'GreatRoom',
          format: (val) => (isValidNumericValue(val) ? `Great Room: ${formatDecimal(val)}` : null),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'Den',
          format: (val) => (isValidNumericValue(val) ? `Den Room: ${formatDecimal(val)}` : null),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'FamilyRoom',
          format: (val) => (isValidNumericValue(val) ? `Family Room: ${formatDecimal(val)}` : null),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'UtilityRoom',
          format: (val) =>
            isValidNumericValue(val) ? `Utility Room: ${formatDecimal(val)}` : null,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'RecRoom',
          format: (val) => (isValidNumericValue(val) ? `Rec Room: ${formatDecimal(val)}` : null),
        }),
      ],
    }),
    ...getArrayItemByPath({
      title: 'Number of Rooms on Second Floor2',
      data,
      path: 'Interior.RoomsPerLevel.SecondFloor2',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'Beds',
          format: (val) => (isValidNumericValue(val) ? `Bedrooms: ${formatDecimal(val)}` : null),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'Baths',
          format: (val) =>
            isValidNumericValue(val) ? `Bathrooms (total): ${formatDecimal(val)}` : null,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'FullBaths',
          format: (val) =>
            isValidNumericValue(val) ? `Bathrooms (full): ${formatDecimal(val)}` : null,
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'HalfBaths',
          format: (val) =>
            isValidNumericValue(val) ? `Bathrooms (half): ${formatDecimal(val)}` : null,
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'Kitchen',
          format: (val) => (isValidNumericValue(val) ? `Kitchen: ${formatDecimal(val)}` : null),
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'DiningRoom',
          format: (val) => (isValidNumericValue(val) ? `Dining Room: ${formatDecimal(val)}` : null),
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'LivingRoom',
          format: (val) => (isValidNumericValue(val) ? `Living Room: ${formatDecimal(val)}` : null),
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'Laundry',
          format: (val) => (isValidNumericValue(val) ? `Laundry: ${formatDecimal(val)}` : null),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'MasterBedRoomSuite',
          format: (val) => (isValidNumericValue(val) ? `Master Beds: ${formatDecimal(val)}` : null),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'FamilyGameRoom',
          format: (val) =>
            isValidNumericValue(val) ? `Family Game Room: ${formatDecimal(val)}` : null,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'GreatRoom',
          format: (val) => (isValidNumericValue(val) ? `Great Room: ${formatDecimal(val)}` : null),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'Den',
          format: (val) => (isValidNumericValue(val) ? `Den Room: ${formatDecimal(val)}` : null),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'FamilyRoom',
          format: (val) => (isValidNumericValue(val) ? `Family Room: ${formatDecimal(val)}` : null),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'UtilityRoom',
          format: (val) =>
            isValidNumericValue(val) ? `Utility Room: ${formatDecimal(val)}` : null,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'RecRoom',
          format: (val) => (isValidNumericValue(val) ? `Rec Room: ${formatDecimal(val)}` : null),
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Number of Rooms on Third Floor',
      data,
      path: 'Interior.RoomsPerLevel.ThirdFloor',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'Beds',
          format: (val) => (isValidNumericValue(val) ? `Bedrooms: ${formatDecimal(val)}` : null),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'Baths',
          format: (val) =>
            isValidNumericValue(val) ? `Bathrooms (total): ${formatDecimal(val)}` : null,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'FullBaths',
          format: (val) =>
            isValidNumericValue(val) ? `Bathrooms (full): ${formatDecimal(val)}` : null,
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'HalfBaths',
          format: (val) =>
            isValidNumericValue(val) ? `Bathrooms (half): ${formatDecimal(val)}` : null,
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'Kitchen',
          format: (val) => (isValidNumericValue(val) ? `Kitchen: ${formatDecimal(val)}` : null),
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'DiningRoom',
          format: (val) => (isValidNumericValue(val) ? `Dining Room: ${formatDecimal(val)}` : null),
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'LivingRoom',
          format: (val) => (isValidNumericValue(val) ? `Living Room: ${formatDecimal(val)}` : null),
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'Laundry',
          format: (val) => (isValidNumericValue(val) ? `Laundry: ${formatDecimal(val)}` : null),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'MasterBedRoomSuite',
          format: (val) => (isValidNumericValue(val) ? `Master Beds: ${formatDecimal(val)}` : null),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'FamilyGameRoom',
          format: (val) =>
            isValidNumericValue(val) ? `Family Game Room: ${formatDecimal(val)}` : null,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'GreatRoom',
          format: (val) => (isValidNumericValue(val) ? `Great Room: ${formatDecimal(val)}` : null),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'Den',
          format: (val) => (isValidNumericValue(val) ? `Den Room: ${formatDecimal(val)}` : null),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'FamilyRoom',
          format: (val) => (isValidNumericValue(val) ? `Family Room: ${formatDecimal(val)}` : null),
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'UtilityRoom',
          format: (val) =>
            isValidNumericValue(val) ? `Utility Room: ${formatDecimal(val)}` : null,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'RecRoom',
          format: (val) => (isValidNumericValue(val) ? `Rec Room: ${formatDecimal(val)}` : null),
        }),
      ],
    }),
    ...getArrayItemByPath({
      title: 'Number of Closets',
      data,
      path: 'Interior.TotalClosets',
    }),
  ].filter(({ value }) => filterEmptyValues(value));
});

export const getListingDetailCommunityTab = createSelector(getListingDetailData, (data) => {
  const propertyType = get(data, 'HomeType');
  const fee = get(data, 'AssociationInfo.Fee');
  const feeFrequency = get(data, 'AssociationInfo.FeeFrequency');

  const fee2 = get(data, 'AgentInfo.AssociationFee2');
  const feeFrequency2 = get(data, 'AgentInfo.AssociationFee2Frequency');
  const fee3 = get(data, 'AgentInfo.AssociationFee3');
  const feeFrequency3 = get(data, 'AgentInfo.AssociationFee3Frequency');

  const otherFee = get(data, 'CommunityInformation.OtherFees');
  const otherFeeFrequency = get(data, 'CommunityInformation.OtherFeesFrequency');

  const isSeniorCommunity = get(data, 'IsSeniorCommunity');
  const hOASubjectTo = get(data, 'AssociationInfo.HOASubjectTo');
  return [
    ...getArrayItemByPath({
      title: 'Building Name',
      data,
      path: 'BuildingInfo.BuildingName',
      format: wrapInArray,
    }),
    ...getArrayItemByPath({
      title: '# of Units',
      data,
      path: 'BuildingInfo.NumOfUnits',
      format: wrapInArray,
    }),

    ...(propertyType !== 'Land'
      ? getArrayItemByPath({
          title: 'Recent Rehab',
          data,
          path: 'BuildingInfo.IsRecentRehab',
          format: wrapInArrayWithFormat(boolToYesNo),
        })
      : []),
    // [TODO] - Check with Duane if we need to display this new information only for NTREIS market
    // since this was requested by a customer
    // ...(data.Market === 'ntreis'
    //   ? ...getArrayItemByPath({
    // title: 'Pool',
    // data,
    // path: 'ExteriorAndLotDetails.CommunityPoolFeatures',
    // format: (val) => `${val?.length > 0 ? 'Yes' : 'No'}`,
    // }) : []),
    ...getArrayItemByPath({
      title: 'Pool',
      data,
      path: 'ExteriorAndLotDetails.CommunityPoolFeatures',
      format: (val) => `${val?.length > 0 ? 'Yes' : 'No'}`,
    }),

    ...getArrayItemByPath({
      title: 'Rehab Year',
      data,
      path: 'BuildingInfo.RehabYear',
      format: wrapInArray,
    }),
    ...getArrayItemByPath({
      title: '% Owner Occupied',
      data,
      path: 'BuildingInfo.OwnerOccupiedPercent',
      format: wrapInArrayWithFormat((val) => `${val}%`),
    }),
    ...getArrayItemByPath({
      title: 'Association Features',
      data,
      path: 'BuildingInfo.AssociationFeatures',
      format: formatEachArrayValue(formatStringList),
    }),

    ...getArrayItemByPath({
      title: 'Pets',
      data,
      path: 'BuildingInfo.PetsInformation',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'AllowedPets',
          format: (val) => `Allowed Pets: ${formatStringList(val)}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'MaxPetWeight',
          format: (val) => `Max Pet Weight: ${val}`,
        }),
      ],
    }),

    ...(propertyType !== 'Land'
      ? getArrayItemByPath({
          title: 'Rebuilt',
          data,
          path: 'BuildingInfo.IsRebuilt',
          format: wrapInArrayWithFormat(boolToYesNo),
        })
      : []),

    ...getArrayItemByPath({
      title: 'HOA',
      data,
      path: 'AssociationInfo.IsAssociation',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'HOA Subject To Dues:',
      data,
      path: 'AssociationInfo.HOASubjectToDues',
    }),
    ...getArrayItemByPath({
      title: 'HOA Inclusions',
      data,
      path: 'AssociationInfo.HOAInclusions',
    }),

    ...(hOASubjectTo && hOASubjectTo !== 'None'
      ? getArrayItemByPath({
          title: 'HOA Subject To:',
          data,
          path: 'AssociationInfo.HOASubjectTo',
        })
      : []),

    ...getArrayItemByPath({
      title: 'HOA Fees',
      data,
      path: 'AssociationInfo',
      format: (data) => [
        ...hoaFee(fee, feeFrequency, fee2, feeFrequency2, fee3, feeFrequency3),
        ...getArrayItemStringByPath({
          data,
          path: 'FeaturesIncludedInFee',
          format: (val) => `Includes: ${formatStringList(val)}`,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'HOA Annual Amount:',
      data,
      path: 'AssociationInfo.AnnualFee',
      format: formatUsd,
    }),

    ...(otherFee
      ? [
          {
            title: 'Other Fees',
            value: `${formatUsd(otherFee)}${otherFeeFrequency ? `/${otherFeeFrequency}` : ''}`,
          },
        ]
      : []),

    ...getArrayItemByPath({
      title: 'CDD Fee Amount:',
      data,
      path: 'AssociationInfo.CDDFeeAmount',
      format: formatUsd,
    }),

    ...getArrayItemByPath({
      title: 'CDD Fee YN:',
      data,
      path: 'AssociationInfo.CDDFeeYN',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Association Email',
      data,
      path: 'AssociationInfo.AssociationEmail',
    }),

    ...getArrayItemByPath({
      title: 'Association URL',
      data,
      path: 'AssociationInfo.AssociationURL',
    }),

    ...getArrayItemByPath({
      title: 'Community Development District',
      data,
      path: 'AssociationInfo.CommunityDevelopmentDistrict',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Community/Subdivision',
      data,
      path: 'CommunityInformation.SubdivisionName',
      format: formatStringList,
    }),
    ...getArrayItemByPath({
      title: 'General Information',
      data,
      path: 'CommunityInformation.GeneralInformation',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Building Features',
      data,
      path: 'BuildingInfo.BuildingFeatures',
      format: formatStringList,
    }),
    ...getArrayItemByPath({
      title: 'Building Style',
      data,
      path: 'BuildingInfo.BuildingStyle',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Park Name',
      data,
      path: 'CommunityInformation.ParkName',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Association Expense',
      data,
      path: 'CommunityInformation.AssociationExpense',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Location Type',
      data,
      path: 'CommunityInformation.LocationType',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Neighborhood',
      data,
      path: 'CommunityInformation.NeighborhoodInformation',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Agricultural District',
      data,
      path: 'CommunityInformation.AgriculturalDistrict',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Elementary School',
      data,
      path: 'CommunityInformation',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'ElementarySchool',
          format: (val) => `${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'ElementarySchoolDistrict',
          format: (val) => `District: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'ElementarySchoolSource',
          format: (val) => `Source: ${val}`,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'High School',
      data,
      path: 'CommunityInformation',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'HighSchool',
          format: (val) => `${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'HighSchoolDistrict',
          format: (val) => `District: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'HighSchoolSource',
          format: (val) => `Source: ${val}`,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Middle or Junior School',
      data,
      path: 'CommunityInformation',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'MiddleOrJuniorSchool',
          format: (val) => `${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'MiddleOrJuniorSchoolDistrict',
          format: (val) => `District: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'MiddleSchoolSource',
          format: (val) => `Source: ${val}`,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'School District',
      data,
      path: 'CommunityInformation',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'SchoolDistrictName',
          format: (val) => `${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'SchoolDistrictSource',
          format: (val) => `Source: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'SchoolDistrictNumber',
          format: (val) => `${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'SchoolDistrictPhone',
          format: (val) => `Phone Number: ${val}`,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Cross Street',
      data,
      path: 'CommunityInformation.CrossStreet',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Area',
      data,
      path: 'CommunityInformation.MLSAreaMajor',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Amenities',
      data,
      path: 'CommunityInformation.AssociationAmenities',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Association Name',
      data,
      path: 'CommunityInformation',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'AssociationName',
          format: (val) => `${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'AssociationName2',
          format: (val) => `${val}`,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Association Phone',
      data,
      path: 'CommunityInformation',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'AssociationPhoneNumber',
          format: (val) => `${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'AssociationPhoneNumber2',
          format: (val) => `${val}`,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Senior Community',
      data,
      path: 'CommunityInformation',
      format: (data) => [
        ...(isSeniorCommunity || isSeniorCommunity === false
          ? [`${boolToYesNo(isSeniorCommunity)}`]
          : []),

        ...getArrayItemStringByPath({
          data,
          path: 'SeniorCommunityAgeRequirement',
          format: (val) => `Age Requirement: ${val}`,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Community Rules',
      data,
      path: 'CommunityInformation.CommunityRules',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Elevator Use Fee',
      data,
      path: 'CommunityInformation.ElevatorUseFee',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Property Manager:',
      data,
      path: 'CommunityInformation.PropertyManagerYN',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Transportation',
      data,
      path: 'CommunityInformation.Transportation',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Boating & Docks',
      data,
      path: 'CommunityInformation',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'NonPowerBoatsPermitted',
          format: (val) => `Non-power boats permitted: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'NumberOfCommunitySlips',
          format: (val) => `Community Slips: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'NumberOfCommunityDocks',
          format: (val) => `Community Docks: ${val}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'PhysicalDockSlipConveysYN',
          format: (val) => `Physicial Dock Slip Conveys: ${boolToYesNo(val)}`,
        }),
        ...getArrayItemStringByPath({
          data,
          path: 'PowerBoatsPermitted',
          format: (val) => `Power Boats Permitted: ${val}`,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Capital Contribution Fee',
      data,
      path: 'CommunityInformation.CapitalContributionFee',
      format: formatUsd,
    }),

    ...getArrayItemByPath({
      title: 'Additional HOA Fees',
      data,
      path: 'CommunityInformation.AdditionalAssociationFees',
      format: formatUsd,
    }),

    ...getArrayItemByPath({
      title: 'Recreation Fee',
      data,
      path: 'CommunityInformation',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'AssociationRecreationFeeYN',
          format: boolToYesNo,
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'AssociationRecreationFee',
          format: formatUsd,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Units in Community',
      data,
      path: 'CommunityInformation.NumberOfUnits',
      format: (val) => formatNumber(val),
    }),

    ...getArrayItemByPath({
      title: 'County',
      data,
      path: 'County',
    }),
  ].filter(({ value }) => filterEmptyValues(value));
});

export const getListingDetailDescriptionTab = createSelector(getListingDetailData, (data) => {
  const courtesy = trim(
    `${get(data, 'ListingAgentName') || ''}, ${get(data, 'ListingOfficeName') || ''}`,
    ', ',
  );
  const source = getDisplayMlsNameFromMarket(get(data, 'Market'));
  const mlsId = get(data, 'ListingId');
  const mlsLastUpd = get(data, 'LastUpdatedInMLS');

  return {
    text: get(data, 'Description'),
    info: [
      ...(courtesy ? [`Listed by: ${courtesy}`] : []),
      ...(source ? [`Source: ${source}`] : []),
      ...(mlsId ? [`MLS #${mlsId}`] : []),
      ...(mlsLastUpd ? [`MLS Last Updated: ${moment(mlsLastUpd).format('MMMM D, YYYY')}`] : []),
    ],
  };
});

export const getListingDetailBuyerCommutes = createSelector(getListingDetailData, (data) =>
  flow(
    (listing) => get(listing, 'Commutes') || [],
    (commutes) =>
      map(commutes, (item) => {
        const times = get(item, 'Times');
        return {
          title: get(item, 'Name'),
          address: get(item, 'DestinationAddress.PlaceName'),
          times: map(keys(times), (key) => ({
            value: formatMinutes(times[key]),
            type: lowerCase(key),
            variant: key,
          })),
        };
      }),
  )(data),
);

export const getListingDetailPropertyData = createSelector(
  getListingDetailDescriptionTab,
  getListingDetailKeyDetailsTab,
  getListingDetailListingInfoTab,
  getListingDetailPropertyTab,
  getListingDetailInteriorTab,
  getListingDetailCommunityTab,
  getListingDetailBuyerCommutes,
  (description, keyDetails, listingInfo, property, interior, community, commutes) => ({
    description,
    keyDetails,
    listingInfo,
    property,
    interior,
    community,
    commutes,
  }),
);

export const getListingDetailFinancesCostData = createSelector(getListingDetailData, (data) => {
  const fee = get(data, 'AssociationInfo.Fee');
  const feeFrequency = get(data, 'AssociationInfo.FeeFrequency');

  const fee2 = get(data, 'AgentInfo.AssociationFee2');
  const feeFrequency2 = get(data, 'AgentInfo.AssociationFee2Frequency');
  const fee3 = get(data, 'AgentInfo.AssociationFee3');
  const feeFrequency3 = get(data, 'AgentInfo.AssociationFee3Frequency');

  const otherFee = get(data, 'CommunityInformation.OtherFees');
  const otherFeeFrequency = get(data, 'CommunityInformation.OtherFeesFrequency');
  let feeArr = [];

  if (fee && fee2 && fee3 && feeFrequency === feeFrequency2 && feeFrequency === feeFrequency3) {
    feeArr = [{ title: 'HOA Fees', value: stringForHoaFee(+fee + +fee2 + +fee3, feeFrequency) }];
  } else if (fee && fee2 && feeFrequency === feeFrequency2) {
    feeArr = [{ title: 'HOA Fees', value: stringForHoaFee(+fee + +fee2, feeFrequency) }];
  } else if (fee && fee3 && feeFrequency === feeFrequency3) {
    feeArr = [{ title: 'HOA Fees', value: stringForHoaFee(+fee + +fee3, feeFrequency) }];
  } else if (fee2 && fee3 && feeFrequency2 === feeFrequency3) {
    feeArr = [{ title: 'HOA Fees', value: stringForHoaFee(+fee2 + +fee3, feeFrequency) }];
  } else {
    feeArr = [
      ...(fee
        ? [
            {
              title: 'HOA Fees',
              value: stringForHoaFee(fee, feeFrequency),
            },
          ]
        : []),

      ...(fee2
        ? [
            {
              title: 'HOA Fees',
              value: stringForHoaFee(fee2, feeFrequency2),
            },
          ]
        : []),

      ...(fee3
        ? [
            {
              title: 'HOA Fees',
              value: stringForHoaFee(fee3, feeFrequency3),
            },
          ]
        : []),
    ];
  }

  return [
    ...feeArr,

    ...getArrayItemByPath({
      title: 'HOA Fees Includes',
      data,
      path: 'AssociationInfo.FeaturesIncludedInFee',
      format: wrapInArrayWithFormat(formatStringList),
    }),

    ...(otherFee
      ? [
          {
            title: 'Other Fees',
            value: `${formatUsd(otherFee)}${otherFeeFrequency ? `/${otherFeeFrequency}` : ''}`,
          },
        ]
      : []),

    ...getArrayItemByPath({
      title: 'Tax Exemptions',
      data,
      path: 'TaxExemptions',
    }),
    ...getArrayItemByPath({
      title: 'Special Service Area:',
      data,
      path: 'ListingInfo.SpecialServiceArea',
      format: (val) => (val && val === 'N' ? wrapInArray('No') : wrapInArray),
    }),

    ...getArrayItemByPath({
      title: 'Water Sewer Hookup Fee',
      data,
      path: 'Costs.WaterSewerHookupFee',
      format: formatUsd,
    }),

    ...getArrayItemByPath({
      title: 'Gross Expense (annual)',
      data,
      path: 'Costs.AnnualGrossExpense',
      format: formatUsd,
    }),

    ...getArrayItemByPath({
      title: 'Heating Expense (annual)',
      data,
      path: 'Costs.AnnualHeatingExpense',
      format: formatUsd,
    }),

    ...getArrayItemByPath({
      title: 'Common Area Maintenance',
      data,
      path: 'Costs.CommonAreaMaintenance',
      format: formatUsd,
    }),

    ...getArrayItemByPath({
      title: 'Fuel Expense',
      data,
      path: 'Costs.FuelExpense',
      format: formatUsd,
    }),

    ...getArrayItemByPath({
      title: 'Operating Expense Includes',
      data,
      path: 'Costs.OperatingExpenseIncludes',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Cable TV Expense',
      data,
      path: 'Costs.CableTvExpense',
      format: formatUsd,
    }),

    ...getArrayItemByPath({
      title: 'Electric Expense',
      data,
      path: 'Costs.ElectricExpense',
      format: formatUsd,
    }),

    ...getArrayItemByPath({
      title: 'Furniture Replacement Expense',
      data,
      path: 'Costs.FurnitureReplacementExpense',
      format: formatUsd,
    }),

    ...getArrayItemByPath({
      title: 'Maintenance Expense',
      data,
      path: 'Costs.MaintenanceExpense',
      format: formatUsd,
    }),

    ...getArrayItemByPath({
      title: 'Operating Expense',
      data,
      path: 'Costs.OperatingExpense',
      format: formatUsd,
    }),

    ...getArrayItemByPath({
      title: 'Other Expense',
      data,
      path: 'Costs.OtherExpense',
      format: formatUsd,
    }),

    ...getArrayItemByPath({
      title: 'Pest Control Expense',
      data,
      path: 'Costs.PestControlExpense',
      format: formatUsd,
    }),

    ...getArrayItemByPath({
      title: 'Pool Expense',
      data,
      path: 'Costs.PoolExpense',
      format: formatUsd,
    }),

    ...getArrayItemByPath({
      title: 'Professional Management Expense',
      data,
      path: 'Costs.ProfessionalManagementExpense',
      format: formatUsd,
    }),

    ...getArrayItemByPath({
      title: 'Trash Expense',
      data,
      path: 'Costs.TrashExpense',
      format: formatUsd,
    }),

    ...getArrayItemByPath({
      title: 'Water Sewer Expense',
      data,
      path: 'Costs.WaterSewerExpense',
      format: formatUsd,
    }),

    ...getArrayItemByPath({
      title: 'Dock Annual Fee',
      data,
      path: 'Costs.DockAnnualFee',
      format: formatUsd,
    }),

    ...getArrayItemByPath({
      title: 'Repair Deductible',
      data,
      path: 'Costs.RepairDeductible',
      format: formatUsd,
    }),

    ...getArrayItemByPath({
      title: 'Municipal Trash:',
      data,
      path: 'Costs.MunicipalTrashYN',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Refuse Fee',
      data,
      path: 'Costs.RefuseFee',
      format: formatUsd,
    }),

    ...getArrayItemByPath({
      title: 'Capital Contribution Fee',
      data,
      path: 'CommunityInformation.CapitalContributionFee',
      format: formatUsd,
    }),

    ...getArrayItemByPath({
      title: 'Additional HOA Fees',
      data,
      path: 'CommunityInformation.AdditionalAssociationFees',
      format: formatUsd,
    }),

    ...getArrayItemByPath({
      title: 'Recreation Fee:',
      data,
      path: 'CommunityInformation.AssociationRecreationFeeYN',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Recreation Fee',
      data,
      path: 'CommunityInformation.AssociationRecreationFee',
      format: formatUsd,
    }),

    ...getArrayItemByPath({
      title: 'Total Expenses',
      data,
      path: 'Costs.TotalExpenses',
      format: formatUsd,
    }),
  ].filter(({ value }) => filterEmptyValues(value));
});

// TODO:
export const getListingDetailFinancesPriceHistoryData = createSelector(getListingDetailData, () => [
  // { date: 'Feb 10, 2021', event: 'SearchItem change', price: '$1,025,000' },
  // { date: 'Nov 9, 2020', event: 'SearchItem change', price: '$1,025,000' },
]);

// TODO:
export const getListingDetailFinancesSalesHistoryData = createSelector(
  getListingDetailData,
  () => [],
);

// TODO:
export const getListingDetailFinancesTaxHistoryData = createSelector(
  getListingDetailData,
  () => [],
);

export const getListingDetailFinancialTab = createSelector(getListingDetailData, (data) => {
  const groundRentFrequency = get(data, 'Financial.GroundRent.Frequency');
  const frontFoot = get(data, 'Financial.FrontFoot.Fee');
  const frontFootFrequency = get(data, 'Financial.FrontFoot.Frequency');
  const landLeaseAmount = get(data, 'Financial.LandLeaseAmount');
  const landLeaseAmountFrequency = get(data, 'Financial.LandLeaseAmountFrequency');
  const insuranceFeeFrequency = get(data, 'InsuranceFeeFrequency');

  return [
    ...getArrayItemByPath({
      title: 'Ground Rent',
      data,
      path: 'Financial.GroundRent',
      format: (data) => [
        ...getArrayItemStringByPath({
          data,
          path: 'Exists',
          format: boolToYesNo,
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'Amount',
          format: (val) =>
            `${formatUsd(val)}${groundRentFrequency ? `/${groundRentFrequency}` : ''}`,
        }),

        ...getArrayItemStringByPath({
          data,
          path: 'YearsLeft',
          format: (val) => `${val} years left`,
        }),
      ],
    }),

    ...getArrayItemByPath({
      title: 'Cap Value Ground Rent',
      data,
      path: 'Financial.GroundRent.CapValueGroundRent',
    }),

    ...(frontFoot
      ? [
          {
            title: 'Front Foot Fee',
            value: [`${formatUsd(frontFoot)}${frontFootFrequency ? `/${frontFootFrequency}` : ''}`],
          },
        ]
      : []),

    ...getArrayItemByPath({
      title: 'Income Expense List',
      data,
      path: 'Financial.IncomeExpenseList',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Investor Ratio',
      data,
      path: 'Financial.InvestorRatio',
      format: formatStringList,
    }),

    ...getArrayItemByPath({
      title: 'Land Lease Years Remaining',
      data,
      path: 'Financial.LandLeaseYearsRemaining',
    }),

    ...getArrayItemByPath({
      title: 'Price per Square Foot (CAM)',
      data,
      path: 'Financial.CAMPricePerSquareFoot',
    }),

    ...getArrayItemByPath({
      title: 'Cap Rate',
      data,
      path: 'Financial.CapRate',
    }),

    ...getArrayItemByPath({
      title: 'Gross Scheduled Income',
      data,
      path: 'Financial.GrossScheduledIncome',
      format: (val) => (val && val !== 0 ? formatUsd(val) : null),
    }),

    ...getArrayItemByPath({
      title: 'Insurance Expense',
      data,
      path: 'InsuranceCost',
      format: (val) =>
        val && val !== 0
          ? `${formatUsd(val)}${insuranceFeeFrequency ? ` ${insuranceFeeFrequency}` : ''}`
          : null,
    }),

    ...(landLeaseAmount
      ? [
          {
            title: 'Land Lease',
            value: [
              `${formatUsd(landLeaseAmount)}${
                landLeaseAmountFrequency ? `/${landLeaseAmountFrequency}` : ''
              }`,
            ],
          },
        ]
      : []),

    ...getArrayItemByPath({
      title: 'Gross Rent Multiplier',
      data,
      path: 'Financial.GrossMultiplier',
      format: formatUsd,
    }),
    ...getArrayItemByPath({
      title: 'Net Operating Income',
      data,
      path: 'Financial.NetOperatingIncome',
      format: formatUsd,
    }),

    ...getArrayItemByPath({
      title: 'Gross Operating Income',
      data,
      path: 'Financial.GrossOperatingIncome',
    }),
  ].filter(({ value }) => filterEmptyValues(value));
});

export const getListingDetailTaxesTab = createSelector(getListingDetailData, (data) => {
  const cityTownTax = get(data, 'Taxes.CityTownTax');
  const cityTownTaxFrequency = get(data, 'Taxes.CityTownTaxPaymentFrequency');

  const countyTax = get(data, 'Taxes.CountyTax');
  const countyTaxFrequency = get(data, 'Taxes.CountyTaxPaymentFrequency');

  const taxAmount = get(data, 'TaxAmount');
  const taxYear = get(data, 'TaxYear');

  const schoolTax = get(data, 'Taxes.SchoolTax');
  const schoolTaxFrequency = get(data, 'Taxes.SchoolTaxPaymentFrequency');

  return [
    ...getArrayItemByPath({
      title: 'Assessment Year',
      data,
      path: 'Taxes.AssessmentYear',
    }),

    ...getArrayItemByPath({
      title: 'SPAN Number',
      data,
      path: 'Taxes.SpanNumber',
    }),

    ...getArrayItemByPath({
      title: 'Tax Gross Amount',
      data,
      path: 'Taxes.TaxGrossAmount',
      format: (val) => (val && val !== 0 ? formatUsd(val) : null),
    }),

    ...getArrayItemByPath({
      title: 'Page Deed',
      data,
      path: 'Taxes.PageDeed',
    }),

    ...(cityTownTax
      ? [
          {
            title: 'City/Town Tax',
            value: [
              `${formatUsd(cityTownTax)}${cityTownTaxFrequency ? `/${cityTownTaxFrequency}` : ''}`,
            ],
          },
        ]
      : []),

    ...(countyTax
      ? [
          {
            title: 'County Tax',
            value: [`${formatUsd(countyTax)}${countyTaxFrequency ? `/${countyTaxFrequency}` : ''}`],
          },
        ]
      : []),

    ...getArrayItemByPath({
      title: 'Section',
      data,
      path: 'Taxes.Section',
    }),

    ...getArrayItemByPath({
      title: 'Tax Assessment Amount',
      data,
      path: 'TaxAssessedValue',
      format: (val) => (val && val !== 0 ? formatUsd(val) : null),
    }),

    ...getArrayItemByPath({
      title: 'Other Annual Assessment Amount',
      data,
      path: 'Taxes.TaxOtherAnnualAssessmentAmount',
      format: (val) => (val && val !== 0 ? formatUsd(val) : null),
    }),

    ...getArrayItemByPath({
      title: 'Tax Subdivision',
      data,
      path: 'Taxes.TaxSubdivision',
    }),

    ...getArrayItemByPath({
      title: 'Tax Lot',
      data,
      path: 'Taxes.TaxLot',
    }),

    ...getArrayItemByPath({
      title: 'Tax Total Living Area ',
      data,
      path: 'Taxes.TaxTotalLivingArea',
    }),

    ...getArrayItemByPath({
      title: 'Tax Total Finish Sq. Ft.',
      data,
      format: (val) => formatNumber(val),
      path: 'Taxes.TaxTotalFinishedSqFt',
    }),

    ...(taxAmount
      ? [
          {
            title: 'Tax Amount (annual)',
            value: `${formatUsd(taxAmount)}${taxYear ? ` (${taxYear})` : ''}`,
          },
        ]
      : []),

    ...getArrayItemByPath({
      title: 'Listing Tax ID',
      data,
      path: 'Taxes.ListingTaxID',
    }),

    ...getArrayItemByPath({
      title: 'Auto Update Tax Data:',
      data,
      path: 'Taxes.AutoUpdateTaxDataYN',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Tax Data Updated:',
      data,
      path: 'Taxes.TaxDataUpdatedYN',
      format: boolToYesNo,
    }),

    ...(schoolTax
      ? [
          {
            title: 'School Tax',
            value: [`${formatUsd(schoolTax)}${schoolTaxFrequency ? `/${schoolTaxFrequency}` : ''}`],
          },
        ]
      : []),

    ...getArrayItemByPath({
      title: 'Annual Tax (leasable area)',
      data,
      path: 'Taxes.LeasableAreaAnnualTax',
    }),
    ...getArrayItemByPath({
      title: 'Taxes per Square Feet',
      data,
      path: 'Taxes.TaxesPerSquareFeet',
    }),

    ...getArrayItemByPath({
      title: 'Tax Opportunity Zone:',
      data,
      path: 'Taxes.TaxOpportunityZoneYN',
      format: boolToYesNo,
    }),

    ...getArrayItemByPath({
      title: 'Tax Payment Frequency',
      data,
      path: 'Taxes.TotalTaxesPaymentFrequency',
    }),

    ...getArrayItemByPath({
      title: 'Taxable Acres',
      data,
      path: 'Taxes.TaxableAcres',
    }),
  ].filter(({ value }) => filterEmptyValues(value));
});

export const getListingDetailFinancesData = createSelector(
  getListingDetailFinancesCostData,
  getListingDetailFinancesPriceHistoryData,
  getListingDetailFinancesSalesHistoryData,
  getListingDetailFinancesTaxHistoryData,
  getListingDetailFinancialTab,
  getListingDetailTaxesTab,
  (cost, priceHistory, salesHistory, taxHistory, financials, taxes) => ({
    cost,
    priceHistory,
    salesHistory,
    taxHistory,
    financials,
    taxes,
  }),
);

export const getListingDetailFavoriteData = createSelector(
  getListingDetailData,
  getListingDetailFavoriteState,
  (data, favoriteState) => ({
    id: get(data, 'Id'),
    isFavorite: !!get(data, 'IsFavorite'),
    isLoading: favoriteState === PENDING,
  }),
);

export const getListingDetailMapData = createSelector(getListingDetailData, (data) => {
  const latitude = get(data, 'Geolocation.Lat');
  const longitude = get(data, 'Geolocation.Long');

  return {
    markers: {
      [`${longitude} ${latitude}`]: [
        {
          id: get(data, 'Id'),
          geometry: {
            coordinates: { lng: longitude, lat: latitude },
          },
        },
      ],
    },
    zoom: 14,
    center: { lng: longitude, lat: latitude },
  };
});

export const getListingLocationData = createSelector(getListingDetailData, (data) => {
  const latitude = get(data, 'Geolocation.Lat');
  const longitude = get(data, 'Geolocation.Long');

  return {
    latitude,
    longitude,
  };
});

export const getLoadingListingData = createSelector(localState, ({ state, data, meta }) => ({
  state,
  dataId: get(data, 'Id'),
  metaId: get(meta, 'id'),
}));

export const getListingDetailOpenHouseInfo = createSelector(getListingDetailData, (data) => {
  const openHouses = map(get(data, 'OpenHouses'), (val) => {
    return formatOpenHouses(val, data?.Market);
  });

  const filteredOpenHouses = [...new Set(openHouses.map(JSON.stringify))].map(JSON.parse);

  const sorted = filteredOpenHouses.sort(function (a, b) {
    return openHouseSorter(a, b);
  });

  return sorted;
});

export const formatYelpDataToShowIt = flow(
  (data) => get(data, 'businessList') || [],
  (list) =>
    map(list, (item) => {
      const distance = get(item, 'Distance');
      const title = get(item, 'Name') || 'No name';
      const address = get(item, 'Address') || 'No address';
      const image = get(item, 'Image');
      const voteCount = get(item, 'UserRatingsTotal') || 0;
      return {
        id: title + address,
        title,
        address,
        rating: get(item, 'Rating') || 0,
        distance: distance ? `${distance} mi` : '-',
        category: getMosaikLocationCategory(get(item, 'Categories') || []),
        info: get(item, 'Categories.0.title') || 'No category', // TODO: for list maybe
        image: image ? `${image}?width=200` : null,
        yelpIcon: true,
        maxWidth: 176,
        voteCount,
        geometry: {
          coordinates: {
            lng: get(item, 'Geolocation.Long'),
            lat: get(item, 'Geolocation.Lat'),
          },
        },
      };
    }),
);

function convertJSON(jsonObj) {
  if (typeof jsonObj !== 'object' || jsonObj === null) {
    return jsonObj;
  }

  if (Array.isArray(jsonObj)) {
    if (jsonObj.length === 1) {
      return convertJSON(jsonObj[0]);
    }

    return jsonObj.map(convertJSON);
  }

  const result = {};
  for (const key in jsonObj) {
    if (Object.prototype.hasOwnProperty.call(jsonObj, key)) {
      result[key] = convertJSON(jsonObj[key]);
    }
  }

  return result;
}

function getMosaikLocationCategory(categories) {
  if (!categories || !categories.length) return 'General';

  const mosaikCategoriesForReference = [
    'Coffee Shop',
    'Night Life',
    'Fitness',
    'Beauty & Spas',
    'Restaurants',
    'Bars',
    'Groceries',
    'Parks & Nature',
    'Health & Medical',
    'Education',
    'Recreation & Amusement',
    'General',
  ];

  const googleCategoriesForReference = [
    'accounting',
    'airport',
    'amusement_park',
    'aquarium',
    'art_gallery',
    'atm',
    'bakery',
    'bank',
    'bar',
    'beauty_salon',
    'bicycle_store',
    'book_store',
    'bowling_alley',
    'bus_station',
    'cafe',
    'campground',
    'car_dealer',
    'car_rental',
    'car_repair',
    'car_wash',
    'casino',
    'cemetery',
    'church',
    'city_hall',
    'clothing_store',
    'convenience_store',
    'courthouse',
    'dentist',
    'department_store',
    'doctor',
    'drugstore',
    'electrician',
    'electronics_store',
    'embassy',
    'fire_station',
    'florist',
    'funeral_home',
    'furniture_store',
    'gas_station',
    'gym',
    'hair_care',
    'hardware_store',
    'hindu_temple',
    'home_goods_store',
    'hospital',
    'insurance_agency',
    'jewelry_store',
    'laundry',
    'lawyer',
    'library',
    'light_rail_station',
    'liquor_store',
    'local_government_office',
    'locksmith',
    'lodging',
    'meal_delivery',
    'meal_takeaway',
    'mosque',
    'movie_rental',
    'movie_theater',
    'moving_company',
    'museum',
    'night_club',
    'painter',
    'park',
    'parking',
    'pet_store',
    'pharmacy',
    'physiotherapist',
    'plumber',
    'police',
    'post_office',
    'primary_school',
    'real_estate_agency',
    'restaurant',
    'roofing_contractor',
    'rv_park',
    'school',
    'secondary_school',
    'shoe_store',
    'shopping_mall',
    'spa',
    'stadium',
    'storage',
    'store',
    'subway_station',
    'supermarket',
    'synagogue',
    'taxi_stand',
    'tourist_attraction',
    'train_station',
    'transit_station',
    'travel_agency',
    'university',
    'veterinary_care',
    'zoo',
  ];

  const coffeeShop = ['cafe'];
  const nightLife = ['night_club'];
  const fitness = ['gym'];
  const beautyAndSpa = ['beauty_salon', 'hair_care', 'spa'];
  const restaurants = ['restaurant'];
  const bars = ['bar'];
  const groceries = [
    'bakery',
    'clothing_store',
    'convenience_store',
    'department_store',
    'electronics_store',
    'florist',
    'furniture_store',
    'hardware_store',
    'home_goods_store',
    'shoe_store',
    'shopping_mall',
    'store',
    'supermarket',
  ];
  const parksAndNature = ['campground', 'park', 'rv_park', 'zoo'];
  const healthAndMedical = [
    'dentist',
    'doctor',
    'drugstore',
    'hospital',
    'physiotherapist',
    'veterinary_care',
  ];
  const education = ['primary_school', 'school', 'secondary_school', 'university'];
  const recreationAndAmusement = [
    'amusement_park',
    'aquarium',
    'art_gallery',
    'bowling_alley',
    'casino',
    'movie_rental',
    'movie_theater',
    'moving_company',
    'museum',
    'stadium',
    'tourist_attraction',
  ];

  let hasMatch = categories.some((obj) => {
    return (
      (obj.Alias && coffeeShop.includes(obj.Alias)) || (obj.Title && coffeeShop.includes(obj.Title))
    );
  });
  if (hasMatch) return 'Coffee Shop';
  hasMatch = categories.some((obj) => {
    return (
      (obj.Alias && nightLife.includes(obj.Alias)) || (obj.Title && nightLife.includes(obj.Title))
    );
  });
  if (hasMatch) return 'Night Life';
  hasMatch = categories.some((obj) => {
    return (obj.Alias && fitness.includes(obj.Alias)) || (obj.Title && fitness.includes(obj.Title));
  });
  if (hasMatch) return 'Fitness';
  hasMatch = categories.some((obj) => {
    return (
      (obj.Alias && beautyAndSpa.includes(obj.Alias)) ||
      (obj.Title && beautyAndSpa.includes(obj.Title))
    );
  });
  if (hasMatch) return 'Beauty & Spas';
  hasMatch = categories.some((obj) => {
    return (
      (obj.Alias && restaurants.includes(obj.Alias)) ||
      (obj.Title && restaurants.includes(obj.Title))
    );
  });
  if (hasMatch) return 'Restaurants';
  hasMatch = categories.some((obj) => {
    return (obj.Alias && bars.includes(obj.Alias)) || (obj.Title && bars.includes(obj.Title));
  });
  if (hasMatch) return 'Bars';
  hasMatch = categories.some((obj) => {
    return (
      (obj.Alias && groceries.includes(obj.Alias)) || (obj.Title && groceries.includes(obj.Title))
    );
  });
  if (hasMatch) return 'Groceries';
  hasMatch = categories.some((obj) => {
    return (
      (obj.Alias && parksAndNature.includes(obj.Alias)) ||
      (obj.Title && parksAndNature.includes(obj.Title))
    );
  });
  if (hasMatch) return 'Parks & Nature';
  hasMatch = categories.some((obj) => {
    return (
      (obj.Alias && healthAndMedical.includes(obj.Alias)) ||
      (obj.Title && healthAndMedical.includes(obj.Title))
    );
  });
  if (hasMatch) return 'Health & Medical';
  hasMatch = categories.some((obj) => {
    return (
      (obj.Alias && education.includes(obj.Alias)) || (obj.Title && education.includes(obj.Title))
    );
  });
  if (hasMatch) return 'Education';
  hasMatch = categories.some((obj) => {
    return (
      (obj.Alias && recreationAndAmusement.includes(obj.Alias)) ||
      (obj.Title && recreationAndAmusement.includes(obj.Title))
    );
  });
  if (hasMatch) return 'Recreation & Amusement';
  return 'General';
}
