import { Table, TableProps } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { Modal } from 'components-antd';
import { getTimePeriodSelector } from 'store/selectors/clarity';
import { Wrapper as PendingWrapper } from 'components';
import { useFetchVolumeStats } from 'pages/ClarityRevamped/hooks';

import styles from '../styles.module.scss';

interface DataType {
  Date: string;
  Transaction: string;
  TotalVolume: number;
  PropertyTransactionId: number;
  RepresentingSide: string;
}

export const VolumeTableModal = ({
  title,
  summaryType,
  year,
  month,
  isoWeek,
  date,
  open,
  onCancel,
  selectedAgent,
}) => {
  const timePeriod = useSelector(getTimePeriodSelector);
  const [data, setData] = useState([]);
  const [isPending, setIsPending] = useState(false);

  const getStats = useFetchVolumeStats();

  const getDateTitle = (type) => {
    if (type === 'Listings' || type === 'Purchases') return 'Date';
    else if (type === 'Canceled') return 'Canceled Date';
    return 'Closing Date';
  };
  const volumeColumns: TableProps<DataType>['columns'] = [
    {
      title: getDateTitle(summaryType),
      dataIndex: 'Date',
      key: 'Date',
      sorter: {
        compare: (record1, record2) => {
          const a = moment(record1.Date);
          const b = moment(record2.Date);
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render(value) {
        return <span className={styles.dateColumn}>{value}</span>;
      },
    },
    {
      title: 'Transaction',
      dataIndex: 'Transaction',
      key: 'Transaction',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.Transaction;
          const b = record2.Transaction;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render(value, record) {
        return (
          <a
            target="_blank"
            className={styles.linkToTransaction}
            href={`/workshop/transactions/${record.PropertyTransactionId}/overview`}
            rel="noreferrer"
          >
            {value}
          </a>
        );
      },
    },
    {
      title: 'Amount',
      dataIndex: 'TotalVolume',
      key: 'TotalVolume',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.TotalVolume;
          const b = record2.TotalVolume;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render: (TotalVolume) => {
        return TotalVolume > 0
          ? `$${TotalVolume.toLocaleString()}`
          : `-$${Math.abs(TotalVolume).toLocaleString()}`;
      },
    },
    {
      title: 'Representing',
      dataIndex: 'RepresentingRole',
      key: 'RepresentingRole',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.RepresentingSide;
          const b = record2.RepresentingSide;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
  ];

  useEffect(() => {
    getStats({
      setSummary: () => {},
      setData,
      setIsPending,
      summaryType: summaryType,
      aggregateDataFormat: 'Tabular',
      year: year,
      quarter: null,
      month: timePeriod === 'YTD' ? month : null,
      isoWeek: timePeriod === 'QTD' ? isoWeek : null,
      date: timePeriod === 'MTD' || timePeriod === 'WEEK' ? date : null,
      transactionAgentUserId: selectedAgent?.TransactionAgentUserId,
      transactionAgentContactId: selectedAgent?.TransactionAgentContactId,
      expand: true,
    });
  }, [timePeriod, year, month, isoWeek]);

  return (
    <Modal width={1000} className={styles.tableModal} open={open} footer={null} onCancel={onCancel}>
      <div className={styles.modalContent}>
        <h2 className={styles.modalHeader}>{title}</h2>
        <PendingWrapper isPending={isPending} className={styles.tableContainer}>
          <Table
            className={styles.statsTable}
            columns={volumeColumns}
            dataSource={data}
            pagination={false}
          />
        </PendingWrapper>
      </div>
    </Modal>
  );
};
