// Deprecated logic
// export const validateMembership = (record, userId) => {
//   if (
//     !record.IsManagedMemberTransaction &&
//     record.IsTeamTransaction &&
//     record.TransactionAccess === undefined
//   ) {
//     showErrorMessage('Inaccessible: You are not a member');
//     return false;
//   }
//   return true;
// };

export const getParticipantsData = (document) => {
  const reviewers = document?.reviewers || [];

  const ids = reviewers?.filter((item) => typeof item === 'number');
  const emails = reviewers?.filter((item) => typeof item === 'string');
  const access =
    reviewers?.length === 0
      ? [{ accessToEveryone: true }]
      : reviewers?.filter((item) => typeof item === 'object');

  return {
    UsersWithAccess: ids || [],
    UsersEmailWithAccess: emails || [],
    AccessToEveryone: access[0].accessToEveryone,
  };
};
