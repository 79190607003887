import { useEffect, useMemo, useState } from 'react';

import { AntCard, Divider, Row, Col, Select, Input, Button } from 'components-antd';
import {
  ProjectStatus,
  RuleType,
  TemplateAction,
  TemplateActionType,
  TemplateTrigger,
  TemplateTriggerType,
  TransactionStatus,
  TriggerComparisonType,
  TriggerOperatorAfterType,
} from 'types/rules';
import { FinancingType, TransactionTemplate } from 'types';
import useIsProjectRoute from 'hooks/use-is-project-route';

import styles from './styles.module.scss';
import { PropertyType } from 'types/property';

interface RuleProps {
  isNew?: boolean;
  onAdd?: (Rule: RuleType) => void;
  goBack?: () => void;
  transactionTemplate: TransactionTemplate;
  defaultTriggers?: TemplateTrigger[];
  defaultActions?: TemplateAction[];
}

const triggerOptions = Object.keys(TemplateTriggerType).map((e) => ({
  key: e,
  value: e,
  label: e,
}));

const actionOptions = Object.keys(TemplateActionType).map((e) => ({
  key: e,
  value: e,
  label: e,
}));

const comparisonOptions = Object.keys(TriggerComparisonType).map((e) => ({
  key: e,
  value: TriggerComparisonType[e],
  label: e,
}));

const DEFAULT_TRIGGER = {
  Type: TemplateTriggerType.Days_Contract_Date,
  OperatorAfter: TriggerOperatorAfterType.OR,
  Comparison: TriggerComparisonType.EQUAL,
} as const;

const DEFAULT_ACTION = { Type: TemplateActionType.Form_Remove_Forms } as const;

const Trigger = ({
  index,
  trigger,
  triggers,
  disabled,
  entityIdOptions,
  handleTriggerChange,
  handleTriggerDelete,
}) => {
  const isProject = useIsProjectRoute();
  // NOTE: This Special trigger type performs three operations
  // 1. Comparison Field is switched to 'Equal' & becomes disabled
  // 2. Entity Field is disabled
  // 3. Value Field becomes select dropdown with specific options.
  const isSpecialTriggerType = useMemo(
    () =>
      trigger?.Type === TemplateTriggerType.Transaction_Attribute_Status_Changes ||
      trigger?.Type === TemplateTriggerType.Property_Attribute_Property_Type ||
      trigger?.Type === TemplateTriggerType.Transaction_Attribute_Financing_Type,
    [trigger?.Type],
  );

  useEffect(() => {
    if (isSpecialTriggerType) {
      handleTriggerChange(index, 'Comparison', TriggerComparisonType.EQUAL);
    }
  }, [isSpecialTriggerType]);

  useEffect(() => {
    handleTriggerChange(index, 'Value', []);
  }, [trigger?.Type]);

  const valueOptions = useMemo(() => {
    const mapping = {
      [TemplateTriggerType.Transaction_Attribute_Status_Changes]: isProject
        ? ProjectStatus
        : TransactionStatus,
      [TemplateTriggerType.Property_Attribute_Property_Type]: PropertyType,
      [TemplateTriggerType.Transaction_Attribute_Financing_Type]: FinancingType,
    };

    const selectedType = mapping[trigger?.Type];

    return selectedType
      ? Object.keys(selectedType).map((key) => ({
          key,
          value: selectedType[key],
          label: key,
        }))
      : [];
  }, [trigger?.Type]);

  return (
    <Row key={`trigger-${trigger.Id}-${index}`}>
      <Col span={12}>
        <Select
          placeholder="Select Type"
          className={styles.w100}
          disabled={disabled}
          options={triggerOptions}
          value={trigger.Type}
          onChange={(e) => handleTriggerChange(index, 'Type', e)}
        />
      </Col>
      <Col span={12}>
        <Select
          placeholder="Select Entity"
          className={styles.w100}
          disabled={disabled || isSpecialTriggerType}
          options={entityIdOptions}
          value={trigger.EntityId}
          onChange={(e) => handleTriggerChange(index, 'EntityId', e)}
        />
      </Col>
      <Col span={12}>
        <Select
          placeholder="Select Comparison"
          className={styles.w100}
          disabled={disabled || isSpecialTriggerType}
          options={comparisonOptions}
          value={trigger.Comparison}
          onChange={(e) => handleTriggerChange(index, 'Comparison', e)}
        />
      </Col>
      <Col span={12}>
        {isSpecialTriggerType ? (
          <Select
            placeholder="Select Value"
            className={styles.w100}
            disabled={disabled}
            options={valueOptions}
            value={trigger.Value}
            onChange={(e) => handleTriggerChange(index, 'Value', e?.split(', '))}
          />
        ) : (
          <Input
            placeholder="Enter Value"
            className={styles.w100}
            disabled={disabled}
            value={trigger.Value?.join(', ')}
            onChange={(e) => handleTriggerChange(index, 'Value', e.target.value?.split(', '))}
          />
        )}
      </Col>
      <Col span={12}>
        {trigger.OperatorAfter && triggers.length - 1 !== index ? trigger.OperatorAfter : ''}
        {index !== 0 && (
          <Button disabled={disabled} onClick={() => handleTriggerDelete(index)}>
            DELETE
          </Button>
        )}
      </Col>
    </Row>
  );
};

export const Rule = ({
  isNew,
  onAdd,
  transactionTemplate,
  goBack,
  defaultActions,
  defaultTriggers,
}: RuleProps) => {
  const [triggers, setTriggers] = useState<TemplateTrigger[]>(
    defaultTriggers ?? [{ ...DEFAULT_TRIGGER }],
  );
  const [actions, setActions] = useState<TemplateAction[]>(
    defaultActions ?? [{ ...DEFAULT_ACTION }],
  );

  const entityIdOptions = [
    ...(transactionTemplate?.TaskTemplates?.length
      ? transactionTemplate.TaskTemplates.map((task) => ({
          label: `Task Template: ` + task.Name,
          value: String(task.Id),
        }))
      : []),
    ...(transactionTemplate?.TransactionFormTemplate?.length
      ? transactionTemplate.TransactionFormTemplate.map((form) => ({
          label: `Form: ` + form?.Name,
          value: String(form.Id),
        }))
      : []),

    ...(transactionTemplate?.TimelineTemplate?.Id &&
    transactionTemplate?.TimelineTemplate?.Milestones?.length
      ? transactionTemplate.TimelineTemplate.Milestones.filter(
          (milestone) => milestone.PublicId,
        ).map((milestone) => ({
          label: 'Milestone: ' + milestone.Title,
          value: `${milestone.PublicId}`,
        }))
      : []),
  ];

  const handleActionChange = (index, key, value) => {
    if (isNew) {
      const updatedActions = [...actions];
      if (updatedActions[index]) {
        updatedActions[index][key] = value;
        setActions(updatedActions);
      }
    }
  };

  const handleTriggerChange = (index, key, value) => {
    if (isNew) {
      const updatedTriggers = [...triggers];
      if (updatedTriggers[index]) {
        updatedTriggers[index][key] = value;
        setTriggers(updatedTriggers);
      }
    }
  };

  const handleNewAction = () => {
    const updatedActions: TemplateAction[] = [...actions];
    updatedActions.push({ ...DEFAULT_ACTION });
    setActions(updatedActions);
  };

  const handleNewTrigger = (operator: TriggerOperatorAfterType) => {
    const updatedTriggers: TemplateTrigger[] = [...triggers];
    if (updatedTriggers.length) {
      updatedTriggers[updatedTriggers.length - 1].OperatorAfter = operator;
    }
    updatedTriggers.push({ ...DEFAULT_TRIGGER });
    setTriggers(updatedTriggers);
  };

  const handleTriggerDelete = (index) => {
    const updatedTriggers: TemplateTrigger[] = [...triggers];
    updatedTriggers.splice(index, 1);
    setTriggers(updatedTriggers);
  };

  const handleActionDelete = (index) => {
    const updatedActions: TemplateAction[] = [...actions];
    updatedActions.splice(index, 1);
    setActions(updatedActions);
  };

  const disabled = !isNew;

  return (
    <>
      {onAdd && (
        <Button
          onClick={() => {
            onAdd?.({
              actions: actions.map((action, index) => ({ ...action, Order: index })),
              triggers: triggers.map((trigger, index) => ({ ...trigger, Order: index })),
            });
          }}
          disabled={disabled}
        >
          Save
        </Button>
      )}
      {goBack && <Button onClick={goBack}>Back</Button>}
      <AntCard title="Triggers">
        {triggers.map((trigger, index) => (
          <Trigger
            index={index}
            trigger={trigger}
            triggers={triggers}
            disabled={disabled}
            entityIdOptions={entityIdOptions}
            handleTriggerChange={handleTriggerChange}
            handleTriggerDelete={handleTriggerDelete}
          />
        ))}
        <Row>
          {!disabled && (
            <>
              <Col span={12}>
                <Button onClick={(e) => handleNewTrigger(TriggerOperatorAfterType.OR)}>OR</Button>
                <Button onClick={(e) => handleNewTrigger(TriggerOperatorAfterType.AND)}>AND</Button>
              </Col>
            </>
          )}
        </Row>
      </AntCard>
      <Divider />
      <AntCard title="Actions">
        {actions.map((action, index) => (
          <Row key={`action-${action.Id}-${index}`}>
            <Col span={12}>
              <Select
                placeholder="Select Type"
                className={styles.w100}
                disabled={disabled}
                options={actionOptions}
                value={action.Type}
                onChange={(e) => handleActionChange(index, 'Type', e)}
              />
            </Col>
            <Col span={12}>
              <Select
                mode="multiple"
                placeholder="Select Entities"
                className={styles.w100}
                disabled={disabled}
                options={entityIdOptions}
                value={action.EntityIds}
                onChange={(e) => handleActionChange(index, 'EntityIds', e)}
              />
            </Col>
            <Col span={12}>
              {index !== 0 && (
                <>
                  AND
                  <Button disabled={disabled} onClick={() => handleActionDelete(index)}>
                    DELETE
                  </Button>
                </>
              )}
            </Col>
          </Row>
        ))}

        <Row>
          {!disabled && (
            <>
              <Col span={12}>
                <Button onClick={(e) => handleNewAction()}>AND</Button>
              </Col>
            </>
          )}
        </Row>
      </AntCard>
    </>
  );
};
