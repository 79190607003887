import { useState } from 'react';
import Icons from '../Icons';
import { formatUsd } from 'helpers/formatters';
import styles from './styles.module.scss';
import classNames from 'classnames';

type TableRowPropsType = {
  indexRow: number;
  rowHeading: string;
  dataObjectKeys: string[];
  columnsData: any[];
  arrayBasedData: boolean;
  hideFields: boolean;
  countStarredFields: number;
  starredFieldsCountHandler: Function;
  childRow?: {
    index: number;
    heading: string;
    key: string;
  };
};

function TableRow(props: TableRowPropsType) {
  const [starred, setStarred] = useState<boolean>(false);

  let getProcessedCurrency = (value) => {
    const match = value && value.match(/\$\d+/);

    if (match) {
      const currencyStr = match[0];

      const currencyValue = currencyStr.replace('$', '');

      const processedValue = formatUsd(currencyValue);

      const processedCurrency = `$${processedValue}`;

      const result = value.replace(currencyStr, processedCurrency);

      return result;
    }

    return value;
  };

  const starClickHandler = () => {
    if (!starred) {
      setStarred(true);
      props.starredFieldsCountHandler('inc', props.rowHeading);
    } else if (starred && props.hideFields && props.countStarredFields === 1) {
      setStarred(true);
    } else {
      setStarred(false);
      props.starredFieldsCountHandler('dec', props.rowHeading);
    }
  };

  if (!starred && props.hideFields) return <></>;

  return (
    <tr
      className={
        styles.propsCompareRow + ' ' + (props.indexRow % 2 === 0 ? styles.propsCompareRowEven : '')
      }
    >
      <td
        className={classNames(
          styles.column,
          { [styles.Two]: props.columnsData.length === 2 },
          styles.columnHeading,
        )}
      >
        <div>
          <div onClick={() => starClickHandler()}>
            <Icons iconName={starred ? 'yellowStar' : 'star'} />
          </div>
          <div className={styles.divBlock}>
            <div>{props.rowHeading}</div>
            {props.childRow && props.childRow.index === props.indexRow && (
              <div className={styles.childHeading + ' ' + styles.childCell}>
                {props.childRow.heading}
              </div>
            )}
          </div>
        </div>
      </td>
      {props.columnsData.map((itemCol) => (
        <td className={classNames(styles.column, { [styles.Two]: props.columnsData.length === 2 })}>
          {props.arrayBasedData ? (
            <div className={styles.divBlock}>
              {itemCol[props.dataObjectKeys[props.indexRow]].map((tItem) => (
                <div className={styles.multiItem + ' ' + styles.text}>{tItem}</div>
              ))}
            </div>
          ) : (
            <div className={styles.divBlock}>
              <div className={styles.text}>
                {props.rowHeading === 'Property Tax:'
                  ? getProcessedCurrency(itemCol[props.dataObjectKeys[props.indexRow]])
                  : itemCol[props.dataObjectKeys[props.indexRow]]}
              </div>
              {props.childRow && props.childRow.index === props.indexRow && (
                <div className={styles.childCell}>
                  {itemCol[props.childRow.key].map((item) => (
                    <div className={styles.multiItem + ' ' + styles.text}>{item}</div>
                  ))}
                </div>
              )}
            </div>
          )}
        </td>
      ))}
    </tr>
  );
}

export default TableRow;
