import { MouseEventHandler, ReactChild, ReactChildren, ReactNode } from 'react';
import classNames from 'classnames';

import { Plus } from 'components/Icons';

import styles from './styles.module.scss';

interface CardProps {
  cardTitle?: ReactNode;
  cardBodyClassName?: String;
  children?: ReactChild | ReactChildren;
  onClickHandler?: MouseEventHandler<HTMLButtonElement>;
  isClickable?: boolean;
  showFooter?: boolean;
  showHeader?: boolean;
  cardWrapperClassName?: String;
  cardHeaderClassName?: String;
  onCardClick?: () => void;
}

export const Card = (props: CardProps) => {
  const {
    cardTitle,
    cardBodyClassName,
    children,
    onClickHandler,
    isClickable,
    cardWrapperClassName,
    cardHeaderClassName,
    onCardClick,
  } = props;
  return (
    <div className={classNames(styles.cardWrap, cardWrapperClassName)} onClick={onCardClick}>
      {props.showHeader && (
        <header className={classNames(styles.cardheader, cardHeaderClassName)}>
          <h3>{cardTitle}</h3>
        </header>
      )}
      <div className={classNames(styles.cardBody, cardBodyClassName)}>{children}</div>
      {props.showFooter && (
        <footer className={styles.cardFooter}>
          <button
            className={classNames(styles.viewAll, { [styles.disabled]: !isClickable })}
            disabled={!isClickable}
            onClick={onClickHandler}
          >
            <Plus className={styles.viewAllIcon} />
            View All
          </button>
        </footer>
      )}
    </div>
  );
};
