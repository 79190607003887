import { useDispatch, useSelector } from 'react-redux';

import { Tabs } from 'components-antd';
import { Avatar, Modal } from 'components';
import { LocationService } from 'services';
import { appOpenModalEffect, getSavedSearchesEffect } from 'store/effects';
import { getModalSelector } from 'store/selectors/app';
import { getFeedMetaSelectorV3 } from 'store/selectors/feedv3';
import {
  selectedSearchAction,
  setCriteriaModeAction,
  setSavedSearchTabAction,
  setSelectedClientSavedSearchAction,
} from 'store/actions/feedv3';
import { ClientFavorites } from './ClientsFavorites';
import { ClientSearches } from './ClientSearches';

import styles from './styles.module.scss';
import { useEffect } from 'react';
import { getSavedSearchesFormattedSelector } from 'store/selectors/mySearches';
import { SavedSearches } from './SavedSearches';
import { getInitials } from 'utils';
import { ArrowLeftFull } from 'components/Icons';
import { getCurrentContextSelector } from 'store/selectors/context';
import { EDIT } from 'settings/constants/mode';
import { useAgentSearchInstance } from 'pages/Properties/AgentFeed/hooks/useAgentSearchInstance';
import { getClientsSearchInstancesSelector } from 'store/selectors/clientSearches';

const SearchesModal = ({ setIsOpenSearchModal }) => {
  const locationSrv = new LocationService();
  locationSrv.setLocation(location);
  const dispatch = useDispatch();
  const { selectedClientSavedSearch } = useSelector(getFeedMetaSelectorV3);
  const modal = useSelector(getModalSelector);
  const { savedSearchTab, selectedSearch } = useSelector(getFeedMetaSelectorV3);
  const { data } = useSelector(getClientsSearchInstancesSelector);

  const clientSearchQuery = data.find(
    (obj) => obj?.Id === selectedClientSavedSearch?.Id,
  )?.SearchQueries;

  const { agentSavedSearches, isLoading: isSavedSearchLoading } = useSelector(
    getSavedSearchesFormattedSelector,
  );

  const getAgentSearchInstance = useAgentSearchInstance();

  useEffect(() => {
    dispatch(
      getSavedSearchesEffect(undefined, undefined, (err, res) => {
        if (!err) {
          const mySearch = res?.data?.result?.find(
            (search) => search?.Name === 'My Primary Search' && !search?.ClientId,
          );
          if (mySearch && !selectedSearch?.name) {
            dispatch(setCriteriaModeAction({ mode: EDIT }));
            dispatch(
              selectedSearchAction({
                selectedSearch: { id: mySearch.Id, name: mySearch?.Name },
              }),
            );
            getAgentSearchInstance({
              id: mySearch.Id,
              criterias: mySearch?.DefaultPropertySearchPreferences,
            });
          }
        }
      }),
    );
  }, []);

  const onClose = () => {
    dispatch(appOpenModalEffect({ id: null, open: false }));
  };

  const tabs = [
    {
      key: 1,
      id: 'savedSearches',
      label: 'Saved Searches',
      children: (
        <SavedSearches
          data={agentSavedSearches}
          isPending={isSavedSearchLoading}
          setIsOpenSearchModal={setIsOpenSearchModal}
        />
      ),
    },
    {
      key: 2,
      id: 'clientSearches',
      label: 'Client Searches',
      children: <ClientSearches />,
    },
    {
      key: 3,
      id: 'clientFavorites',
      label: 'Client Favorites',
      children: <ClientFavorites />,
    },
  ];

  if (modal.id !== SearchesModal.id) return null;

  const ClientView = () => {
    const { FirstName, LastName, AvatarUrl } = selectedClientSavedSearch;
    return (
      <>
        <ArrowLeftFull
          color="#262626"
          className={styles.back}
          width={32}
          height={32}
          onClick={() => {
            dispatch(setSelectedClientSavedSearchAction({ selectedClientSavedSearch: null }));
          }}
        />
        <div className={styles.container}>
          <Avatar
            avatarClassName={styles.avatar}
            placeholder={getInitials(FirstName + LastName)}
            src={AvatarUrl}
          />
          <span className={styles.heading}>{`${FirstName} ${LastName}`}</span>
          <span className={styles.text}>Client</span>
        </div>
        <SavedSearches
          data={clientSearchQuery}
          setIsOpenSearchModal={setIsOpenSearchModal}
          client={selectedClientSavedSearch}
        />
      </>
    );
  };

  return (
    <Modal
      isOpen={modal.open}
      onClose={onClose}
      opacityLayerClassName={styles.opacityLayer}
      contentClassName={styles.modal}
      innerHolderClassName={styles.innerHolder}
      headerClassName={styles.header}
      label={selectedClientSavedSearch ? '' : 'Searches'}
      className={styles.searchModalWrapper}
    >
      <div className={styles.drawerInner}>
        {selectedClientSavedSearch ? (
          <ClientView />
        ) : (
          <Tabs
            className={styles.tabs}
            items={tabs}
            defaultActiveKey={savedSearchTab}
            onTabClick={(id) => {
              dispatch(setSavedSearchTabAction({ savedSearchTab: id }));
            }}
          />
        )}
      </div>
    </Modal>
  );
};

export default SearchesModal;

SearchesModal.id = 'searchesModal';
