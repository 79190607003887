import { Button, Input } from 'components';
import styles from './styles.module.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { updateClientDataEffect } from 'store/effects';
import { showErrorMessage, showSuccessMessage } from 'helpers';

export const EmailForm = ({ value, handleClose, clientId }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const validateEmail = (email) => {
    const emailValidator =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailValidator.test(email.toLowerCase());
  };

  const validationSchema = Yup.object({
    email: Yup.string(),
  });

  const { handleSubmit, handleChange, values, handleBlur } = useFormik({
    initialValues: value,
    validationSchema,
    onSubmit: async ({ email }) => {
      if (value.email !== email) {
        if (validateEmail(email)) {
          setLoading(true);
          dispatch(
            updateClientDataEffect({ clientId, email: email?.toLowerCase() }, (err) => {
              if (!err) {
                showSuccessMessage('Client email updated');
              }
              setLoading(false);
              handleClose();
            }),
          );
        } else {
          showErrorMessage('Invalid email');
        }
      }
    },
  });

  return (
    <form className={styles.main} onSubmit={handleSubmit}>
      <Input
        name="email"
        onChange={handleChange}
        value={values.email}
        onBlur={handleBlur}
        variant={Input.LIGHT_ROUND}
      />
      <div className={styles.actionButtons}>
        <Button type="submit" title={'Update'} className={styles.update} isPending={loading} />
        <Button title={'Cancel'} className={styles.cancel} onClick={handleClose} />
      </div>
    </form>
  );
};
