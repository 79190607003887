import React, { useMemo } from 'react';
import styles from './styles.module.scss';
import { Input } from 'components';
import CopyToClipboard from 'components/CopyToClipboard';

const Header: React.FC = () => {
  return (
    <div className={styles.header}>
      <div>Email Template</div>
    </div>
  );
};

export const EmailTemplate: React.FC<{}> = () => {
  const templateBody = useMemo(
    () => `We’re implementing a new platform at ______ called Mosaik. We’ll be using it to manage property search, transactions, and client experience.

In Mosaik, there is a module called Concierge that allows us to showcase our preferred vendors to our clients, including our past clients and our entire database (think of it like our personal Angie’s List that’s accessible to clients 24/7).

We included you in our list of preferred vendors, so you will be receiving an email from Mosaik to activate your account. Please keep an eye out for it.

There is no cost to you whatsoever and you only need to log in to the system when responding to clients (you can set up email and text alerts when a client contacts you).

We think this will be a great way to grow our businesses together and appreciate your support!

If you have any questions or run into any issues with your account, you can email support@mosaik.io directly and they will assist you.`,
    [],
  );
  const templateSubject = useMemo(() => `Adding you to my preferred vendor list`, []);
  return (
    <div className={styles.contentWrapper}>
      <Header />
      {/* <PendingWrapper isPending={isLoading}> */}
      <div className={styles.contentBody}>
        <Input
          className={styles.templateInputContainer}
          name="subject"
          label="Subject"
          maxLength={30}
          disabled
          inputClassName={styles.emailTemplateInput}
          inputHolderClassName={styles.inputHolder}
          icon={<CopyToClipboard textToCopy={templateSubject} />}
          value={templateSubject}
          iconClassName={styles.copyIcon}
        />
        <div className={styles.templateInputContainer}>
          <label>
            <span>Body</span>
          </label>
          <div className={styles.emailTemplateTextArea}>
            <CopyToClipboard textToCopy={templateBody} className={styles.copyIcon} />
            <br />
            <p>
              We&apos;re implementing a new platform at ______ called Mosaik. We&apos;ll be using it
              to manage property search, transactions, and client experience.
            </p>
            <p>
              In Mosaik, there is a module called Concierge that allows us to showcase our preferred
              vendors to our clients, including our past clients and our entire database (think of
              it like our personal Angie&apos;s List that&apos;s accessible to clients 24/7).
            </p>
            <p>
              We included you in our list of preferred vendors, so you will be receiving an email
              from Mosaik to activate your account. Please keep an eye out for it.
            </p>
            <p>
              There is no cost to you whatsoever and you only need to log in to the system when
              responding to clients (you can set up email and text alerts when a client contacts
              you).
            </p>
            <p>
              We think this will be a great way to grow our businesses together and appreciate your
              support!
            </p>
            <p>
              If you have any questions or run into any issues with your account, you can email
              support@mosaik.io directly and they will assist you.
            </p>
          </div>
        </div>
      </div>
      {/* </PendingWrapper> */}
    </div>
  );
};
