import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { Button, Locations } from 'components';
import { getUserDataSelector } from 'store/selectors/user';
import { getOnBoardingData } from 'pages/OnBoarding/selectors';
import { onBoardingSignUpEffect } from 'store/effects';
import { PENDING } from 'settings/constants/apiState';
import { ValidationSchema } from './validation';
import { Role } from 'app-constants';

import styles from './styles.module.scss';
import { OnBoardingContainer } from '../../OnBoardingContainer';
import { Question } from 'pages/RequestQuote/components';
import { AnswersContainer } from '../../AnswersContainer';
import ArrowRightFull from 'components/Icons/ArrowRightFull';

const MailingAddressForm = (props) => {
  const { stageIndex, onNext, Controls } = props;
  const dispatch = useDispatch();
  const user = useSelector(getUserDataSelector);
  const { onBoarding, signUpRole } = useSelector(getOnBoardingData);
  const isPending = onBoarding.state === PENDING;

  const sellerAddress = user?.[signUpRole]?.SellerAddress;
  const addresses = user?.[signUpRole]?.Address;
  const primaryAddress = addresses.find(({ isPrimary }) => isPrimary);
  const clientAddress = useMemo(() => {
    if (sellerAddress && !addresses?.length) {
      return {
        address: sellerAddress,
        neighbourhoodRadius: 2,
        notes: '',
      };
    }

    return primaryAddress || addresses?.[0];
  }, [sellerAddress, addresses]);
  const oldAddresses = primaryAddress
    ? addresses?.filter(({ isPrimary }) => !isPrimary)
    : addresses?.slice(1) || [];

  const onSubmit = useCallback(
    (values) => {
      dispatch(
        onBoardingSignUpEffect(
          { address: [...oldAddresses, values], stageIndex },
          { isCommonInfo: true },
          (err) => {
            if (!err) {
              onNext();
            }
          },
        ),
      );
    },
    [onNext, stageIndex, dispatch],
  );

  const formik = useFormik({
    initialValues: {
      ...(signUpRole === Role.Client
        ? clientAddress
        : {
            address: user?.[signUpRole]?.Address || '',
            suiteUnit: user?.[signUpRole]?.SuiteUnit || '',
          }),
    },
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    validateOnChange: true,
    onSubmit,
  });

  return (
    <>
      <Controls
        onNext={() => formik.submitForm()}
        className={styles.controls}
        variant="lightFull"
      />
      <OnBoardingContainer>
        <Question>What is your mailing address?</Question>
        <AnswersContainer>
          <form onSubmit={formik.handleSubmit} className={styles.form}>
            <Locations
              multiple={false}
              label="Mailing Address"
              name="address"
              types={['address']}
              valuesWrapperClassName={styles.border}
              placeholderClassName={styles.border}
              searchWrapperClassName={styles.border}
              dropdownClassName={styles.dropdown}
              onResult={(result, preparedData) => {
                formik.setFieldValue('address', preparedData[0]);
              }}
              placeholder=""
              error={formik.touched.address ? formik.errors.address : ''}
              disabled={isPending}
              value={formik.values?.address ? [formik.values?.address] : []}
              testid="address"
              variant={Locations.ROUND}
              rounded={true}
              allowedSmartyAddress={true}
              showOnlyCassAddresses={true}
              setFullCassAddress={true}
            />
            {/* <Input
              label="Suite/Unit"
              name="suiteUnit"
              className={styles.suiteUnit}
              value={signUpRole === Role.Client ? clientAddress.suiteUnit : formik.values.suiteUnit}
              onChange={formik.handleChange}
              error={formik.touched.suiteUnit ? formik.errors.suiteUnit : ''}
              disabled={isPending}
              testid="suite_unit"
              variant={Input.LIGHT_ROUND}
            /> */}
            <p className={styles.note}>
              This information is only shared with your agent and any collaborators you invite to
              your transaction (your lender, attorney, etc.).
            </p>
            <div className={styles.buttonContainer}>
              <Button
                titleClassName={styles.titleClassName}
                className={styles.button}
                title="Continue"
                icon={<ArrowRightFull color={'#fff'} />}
                isPending={isPending}
                disabled={!formik.values?.address}
                onClick={() => formik.submitForm()}
              />
            </div>
          </form>
        </AnswersContainer>
      </OnBoardingContainer>
    </>
  );
};

MailingAddressForm.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  stageIndex: PropTypes.number,
  Controls: PropTypes.elementType,
};

MailingAddressForm.defaultProps = {
  className: '',
  onNext: () => {},
  stageIndex: undefined,
  Controls: () => null,
};

export default MailingAddressForm;
