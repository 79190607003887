import React from 'react';

import moment from 'moment';
import classNames from 'classnames';

import { Avatar } from 'components';
import { Icons } from 'pages/Workshop/Icons';
import { getDateOnly, showErrorMessage, subtractTimeZone } from 'helpers';
import { getInitials } from 'utils';
import { transactionRoles } from 'settings/constants/roles';
import { Checkbox } from 'components-antd';
import TaskStatus from '../../../Task/View/components/Actions/Status';

import { isBeforeToday, isToday, isYesterday } from 'helpers/date';

import styles from './styles.module.scss';
import { NamesContainer } from 'pages/Workshop/Transactions/components/NamesContainer';
import { displayListedStatus, displayTransactionStatuses } from 'settings/constants/transaction';

export function tableColumns({
  isAggregatedTasks,
  showSelection,
  handleSelectTask,
  openTransactionDrawer,
  handleSelectAllTask,
  selectedTasks,
  showSelectAll,
  tableDataSource,
  userId,
  hasFullAccess,
  hasAccess,
}) {
  const me = 'Me';

  const categoryColumn = {
    title: 'Category',
    key: 'category',
    dataIndex: 'Category',
    render: (_, { Transaction }) => {
      const transactionType = (roles = []) => {
        if (!roles.includes(transactionRoles.BUYER) && roles.includes(transactionRoles.SELLER))
          return 'Listing';
        else if (roles.includes(transactionRoles.BUYER) && !roles.includes(transactionRoles.SELLER))
          return 'Purchase';
        else return 'Dual';
      };

      const getCategory = () => {
        const { IsProject, Status, RepresentingRoles } = Transaction ?? {};
        return (
          <div className={styles.category}>
            <span>{IsProject ? 'Project' : transactionType(RepresentingRoles)}</span>
            <br />
            <span className={styles.statusText}>
              {displayListedStatus[Status] ?? displayTransactionStatuses[Status] ?? Status}
            </span>
          </div>
        );
      };

      return <div className={styles.tagContainer}>{getCategory()}</div>;
    },
  };

  const columns = [
    {
      title: 'Task',
      key: 'task',
      dataIndex: 'Title',
      width: 500,
      sorter: (a, b) => {
        if (a.Title < b.Title) return -1;
        if (a.Title > b.Title) return 1;
        return 0;
      },
      render: (text, row) => {
        let unreadCount = 0;
        row?.Comments?.forEach((comment) => {
          if (comment.SenderUserId !== userId && comment.AccessType !== me) {
            let isUnRead = false;
            comment.MessageReceipts.forEach((receipt) => {
              if (receipt.UserId === userId && !receipt.IsRead) {
                isUnRead = true;
              }
            });
            if (isUnRead) {
              unreadCount++;
            }
          }
        });
        const showIcon = unreadCount > 0 || row.Comments?.length > 0 || row.showCommentIcon;

        const getSecondLineText = () => {
          const { IsProject, Name, Property } = row?.Transaction ?? {};
          return IsProject ? Name : Property?.Address?.Line1;
        };

        return (
          <div className={styles.taskWrap}>
            <div className={styles.transactionTask}>
              {row?.TransactionId && (
                <Icons
                  variant={Icons.OFFER}
                  className={styles.icon}
                  onClick={(e) => {
                    if (isAggregatedTasks) {
                      e.stopPropagation();
                      openTransactionDrawer(row?.TransactionId, row?.Transaction?.IsProject);
                    }
                  }}
                />
              )}
              <div className={styles.info}>
                <span className={styles.line1}>{text}</span>
                {isAggregatedTasks && <span className={styles.line2}>{getSecondLineText()}</span>}
              </div>
            </div>
            {showIcon && (
              <div
                className={classNames(styles.commentWrapper, unreadCount > 0 && styles.isUnread)}
              >
                <Icons className={styles.icon} variant={Icons.TASK_COMMENT} />
                {unreadCount > 0 && (
                  <span className={unreadCount > 9 ? styles.ninePlus : styles.count}>
                    {unreadCount > 9 ? '9+' : unreadCount}
                  </span>
                )}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'Status',
      width: 170,
      sorter: (a, b) => {
        if (a.Status < b.Status) return -1;
        if (a.Status > b.Status) return 1;
        return 0;
      },
      render: (_, { Id, TransactionId, CampaignId, Status, Category, DueDate, CcList }) => (
        <TaskStatus
          taskId={Id}
          transactionId={TransactionId}
          campaignId={CampaignId}
          status={Status}
          category={Category?.Name}
          fullAccess={hasFullAccess(TransactionId)}
          dueDate={DueDate}
          ccList={CcList}
        />
      ),
    },
    {
      title: 'Due Date',
      key: 'dueDate',
      dataIndex: 'DueDate',
      width: 148,
      defaultSortOrder: 'ascend',
      sorter: (a, b) => moment(a.DueDate).unix() - moment(b.DueDate).unix(),
      render: (_, { DueDate }) => {
        let dueDate = DueDate ? getDateOnly(DueDate, 'M/D/YYYY') : 'N/A';
        let isPrimaryDate = false;

        if (isToday(dueDate)) {
          dueDate = 'Today';
          isPrimaryDate = true;
        }
        if (isYesterday(dueDate)) {
          dueDate = 'Yesterday';
          isPrimaryDate = true;
        }
        if (isBeforeToday(dueDate)) {
          isPrimaryDate = true;
        }

        return <p className={classNames({ [styles.datePrimary]: isPrimaryDate })}>{dueDate}</p>;
      },
    },
    {
      title: 'Assigned To',
      key: 'assignedTo',
      width: isAggregatedTasks ? 250 : 150,
      render: (_, { Id, AssigneeList }) => {
        const assignee = AssigneeList?.find((obj) => obj?.Id === userId) || AssigneeList?.[0] || {};
        const name =
          assignee?.Id === userId
            ? me
            : assignee?.FirstName
            ? `${assignee?.FirstName} ${assignee?.LastName}`
            : assignee?.Email;
        if (!AssigneeList || !AssigneeList?.length) return <span>-</span>;
        const namesArray = AssigneeList?.map((item) =>
          item.Id === userId ? me : `${item?.FirstName} ${item?.LastName}`,
        );
        const isMeExists = namesArray?.includes(me);
        const sortedNamesArray = isMeExists
          ? [me, ...namesArray.filter((item) => item !== me)]
          : namesArray;
        return (
          <div className={styles.tagContainer}>
            <div className={styles.avatarRow}>
              <Avatar
                avatarClassName={styles.avatar}
                src={assignee.AvatarUrl}
                placeholder={
                  name !== me ? (
                    <div className={styles.avatarPlaceholder}>
                      {getInitials(`${assignee.FirstName} ${assignee.LastName}`)}
                    </div>
                  ) : undefined
                }
              />
              <div>
                <NamesContainer names={sortedNamesArray} maxDisplayCount={1} />
              </div>
            </div>

            {/* Multi select button on Tasks (Transaction Room) */}
            {!isAggregatedTasks && !showSelectAll && (
              <div className={styles.actionSelectContainer} onClick={(e) => e.stopPropagation()}>
                <div className={styles.multiSelect}>
                  {showSelection && (
                    <Checkbox
                      className={styles.checkbox}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelectTask(Id, e.target.checked);
                      }}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        );
      },
    },
    ...(isAggregatedTasks ? [categoryColumn] : []),

    ...(showSelectAll && showSelection
      ? [
          {
            title: () => {
              const checkedIds = tableDataSource.filter((item) => selectedTasks.includes(item.Id));

              const hasDisabledStatusTask = tableDataSource.find(
                (item) => !hasAccess(item.Id) && !hasFullAccess(item.TransactionId),
              );

              const taskIds = !hasDisabledStatusTask ? tableDataSource.map(({ Id }) => Id) : [];
              const allowedTaskIds = !hasDisabledStatusTask
                ? taskIds
                : tableDataSource
                    .filter(({ Id, TransactionId }) => {
                      const disabled = !hasAccess(Id) && !hasFullAccess(TransactionId);
                      return !disabled;
                    })
                    .map(({ Id }) => Id);

              const disabled = !allowedTaskIds.length;

              return (
                <Checkbox
                  checked={checkedIds.length > 0 && checkedIds.length === allowedTaskIds.length}
                  className={styles.checkbox}
                  disabled={disabled}
                  onClick={(e) => {
                    e.stopPropagation();

                    !disabled && handleSelectAllTask(allowedTaskIds, e.target.checked);
                  }}
                />
              );
            },
            key: 'checkbox',
            width: 1,
            render: (_, { Id, TransactionId }) => {
              const disabled = !hasAccess(Id) && !hasFullAccess(TransactionId);

              return (
                <div className={styles.multiSelectionCheckbox}>
                  <Checkbox
                    className={styles.checkbox}
                    checked={selectedTasks.includes(Id)}
                    disabled={disabled}
                    onClick={(e) => {
                      e.stopPropagation();
                      !disabled && handleSelectTask(Id, e.target.checked);
                    }}
                  />
                </div>
              );
            },
          },
        ]
      : []),
  ];

  return columns;
}
