import {
  getPropertyReferenceData,
  getPropertyReferenceDetails,
  getPropertyReferenceNeighborhood,
  getPropertyReferenceStats,
} from 'api/radarPortfolio';
import {
  getPropertyReferenceDataAction,
  getPropertyReferenceDetailsAction,
  getPropertyReferenceNeighborhoodAction,
  getPropertyReferenceStatsAction,
} from 'store/actions/radarPortfolio';
import Api from 'store/effects/core/api';

export const requestPropertyReferenceDataEffect = (cfg?, options?, cb?) => {
  const requestParams = {
    action: getPropertyReferenceDataAction,
    method: getPropertyReferenceData,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const requestPropertyReferenceStatsEffect = (cfg?, options?, cb?) => {
  const requestParams = {
    action: getPropertyReferenceStatsAction,
    method: getPropertyReferenceStats,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const requestPropertyDetailsEffect = (cfg?, options?, cb?) => {
  const requestParams = {
    action: getPropertyReferenceDetailsAction,
    method: getPropertyReferenceDetails,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const requestPropertyNeighborhoodEffect = (cfg?, options?, cb?) => {
  const requestParams = {
    action: getPropertyReferenceNeighborhoodAction,
    method: getPropertyReferenceNeighborhood,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};
