import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { routes } from 'settings/navigation/routes';
import { DropdownCheck, Spinner } from 'components';
import {
  activateSearchInstanceEffect,
  deactivateSearchInstanceEffect,
  renameSearchInstanceEffect,
} from 'store/effects/mySearches';
import SearchCard from './SearchCard';
import {
  setRenameSearchInstanceDrawerAction,
  setStatusSearchInstanceDrawerAction,
} from 'store/actions/drawerActiveSearches';
import { appManageClientDrawerAction } from 'store/actions/app';

import { showSuccessMessage } from 'helpers/success';
import { getClientInstancesEffect } from 'store/effects/clientInstances';

import styles from './styles.module.scss';
import {
  selectedSearchAction,
  setCriteriaAction,
  setSelectedClientSavedSearchAction,
} from 'store/actions/feedv3';
import { useAgentSearchInstance } from 'pages/Properties/AgentFeed/hooks/useAgentSearchInstance';
import { getClientsListDataArray } from 'store/selectors/clientsList';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { getSearchCriteriaFormattedString } from 'helpers';
import { appOpenModalEffect } from 'store/effects';
import CriteriaModal from 'pages/Properties/CriteriaModal';

enum filterType {
  Active = 'Active',
  Inactive = 'Inactive',
}

export const ActiveSearches = ({
  activeSearches,
  isLoading = false,
  clientId = null,
}: {
  activeSearches: any;
  isLoading?: boolean;
  clientId?: number | null;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isClient } = useSelector(getUserRolesMapSelector);
  const clientsList = useSelector(getClientsListDataArray);
  const [filter, setFilter] = useState<filterType>(filterType.Active);
  const getAgentSearchInstance = useAgentSearchInstance();

  const getSearchHandler = useCallback(
    (criterias, openCriteriaModal) => () => {
      if (openCriteriaModal) {
        dispatch(appOpenModalEffect({ id: CriteriaModal.id, open: true }));
      } else {
        dispatch(appOpenModalEffect({ id: null, open: false }));
      }
      dispatch(appManageClientDrawerAction(false));
      const item = {
        id: criterias?.Id,
        name: criterias?.Name,
        criterias: criterias?.DefaultPropertySearchPreferences,
      };
      const client = clientsList?.find(({ Id }) => Id === clientId);
      dispatch(
        selectedSearchAction({
          selectedSearch: item,
        }),
      );
      getAgentSearchInstance(
        {
          id: criterias?.Id,
          criterias: criterias.DefaultPropertySearchPreferences,
        },
        undefined,
        client,
      );
      dispatch(
        setCriteriaAction({
          criteria: criterias.DefaultPropertySearchPreferences,
        }),
      );
      dispatch(setSelectedClientSavedSearchAction({ selectedClientSavedSearch: client }));
      history.push(routes.feed);
    },
    [dispatch],
  );

  const getRenameHandler = (id) => (name, onCloseRenameModal) => {
    dispatch(
      renameSearchInstanceEffect({ id, name }, {}, (err) => {
        if (!err) {
          dispatch(setRenameSearchInstanceDrawerAction({ id, name }));
          showSuccessMessage('Search Instance Renamed Successfully');
        }
        onCloseRenameModal();
      }),
    );
  };

  const getInactiveHandler = useCallback(
    (id) => () => {
      showSuccessMessage('Success');
      dispatch(
        deactivateSearchInstanceEffect({ id }, {}, (err) => {
          if (!err) {
            isClient && dispatch(getClientInstancesEffect());
            dispatch(setStatusSearchInstanceDrawerAction({ id, status: 'Inactive' }));
            showSuccessMessage('Search Instance Deactivated');
          }
        }),
      );
    },
    [dispatch],
  );

  const getActiveHandler = useCallback(
    (id) => () => {
      showSuccessMessage('Success');
      dispatch(
        activateSearchInstanceEffect({ id }, {}, (err) => {
          if (!err) {
            isClient && dispatch(getClientInstancesEffect());
            dispatch(setStatusSearchInstanceDrawerAction({ id, status: 'Active' }));
            showSuccessMessage('Search Instance Activated');
          }
        }),
      );
    },
    [dispatch],
  );

  const getFilteredSearches = (status) => {
    return (
      activeSearches &&
      activeSearches.filter(function (item) {
        return item.Status === status;
      })
    );
  };

  // const getActiveSearches = () => {
  //   var filtered =
  //     activeSearches &&
  //     activeSearches.filter((item) => {
  //       return item.Status === 'Active';
  //     });
  //   return filtered.length || 0;
  // };

  const handleStatusChange = (status) => {
    setFilter(status);
  };

  return (
    <div className={styles.main}>
      <div className={styles.containerActiveSearch}>
        {/* {view === 'expanded' ? ( */}
        <div className={styles.expandedTransaction}>
          <div className={styles.expandedTransactionHeading}>
            <h3>Searches</h3>
          </div>
          <DropdownCheck
            className={styles.button}
            dropdownFields={ActiveSearches.options}
            onChange={handleStatusChange}
            defaultValue={filter}
          />
        </div>
        {/* ) : activeSearches && getActiveSearches() ? (
        <h3>Active Searches</h3>
      ) : (
        ''
      )} */}

        <div className={styles.CardsContainer}>
          {isLoading ? (
            <Spinner loaderClassName={styles.loader} />
          ) : activeSearches?.length ? (
            activeSearches.map(
              (search, index) =>
                search.Status === filter && (
                  <SearchCard
                    testid="active_search_block"
                    photos={search.PreviewPhotoUrls}
                    key={search.Id}
                    title={search.Name}
                    agentName={search.AgentName}
                    status={search.Status}
                    info={
                      getSearchCriteriaFormattedString(search.DefaultPropertySearchPreferences)
                        ?.info
                    }
                    fullInfo={
                      getSearchCriteriaFormattedString(search.DefaultPropertySearchPreferences)
                        ?.fullInfo
                    }
                    footer={`${search.NumOfResults || 'no'} results`}
                    onDelete={null}
                    onSearch={null}
                    onEdit={getSearchHandler(search, true)}
                    onClick={getSearchHandler(search, false)}
                    onRename={getRenameHandler(search.Id)}
                    onInactive={getInactiveHandler(search.Id)}
                    onActivate={getActiveHandler(search.Id)}
                    hideBorder={index === activeSearches.length - 1}
                  />
                ),
            )
          ) : null}
          <div>
            {!getFilteredSearches(filter)?.length && (
              <div className={styles.noData}>No Results</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
ActiveSearches.options = [
  { id: 1, label: 'Active', value: 'Active' },
  { id: 1, label: 'Inactive', value: 'Inactive' },
];
