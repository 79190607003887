export const LOCALITY = 'locality';
export const NEIGHBORHOOD = 'neighborhood';
export const POSTAL_CODE = 'postal_code';
export const STATE = 'administrative_area_level_1';
export const LINE2 = 'administrative_area_level_2';
export const STREET_ADDRESS = 'street_address';
export const STREET_NUMBER = 'street_number';
export const ROUTE = 'route';
export const PREMISE = 'premise';
export const SUB_PREMISE = 'subpremise';
export const CASS_ADDRESS = 'CassAddress';
export const COUNTY = 'county';
export const SUB_LOCALITY = 'sublocality';
export const SUB_LOCALITY_LEVEL_1 = 'sublocality_level_1';

export const locationsMap = {
  [LOCALITY]: 'City',
  [NEIGHBORHOOD]: 'Neighborhood',
  [POSTAL_CODE]: 'Zipcode',
  [STREET_ADDRESS]: 'Box',
  [PREMISE]: 'Box',
  [STREET_NUMBER]: 'Street',
  [ROUTE]: 'Street',
  [STATE]: 'State',
  [COUNTY]: 'County',
};
