import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';
import InfoItem from '../Content/InfoItem';
import { normalizeAddress } from 'components/ClientsDrawer/ProfileClient/Contact/components/Address/helpers';

const AddressInfo = (props) => {
  const { className, value } = props;

  if (!value || !value?.length) return null;

  const getAddress = (address) => {
    if (typeof address === 'string') {
      return <div className={styles.address}>{address}</div>;
    }

    if (address.address && typeof address.address === 'string') {
      return <div className={styles.address}>{address.address}</div>;
    }

    const { line1, city, state, zip } = normalizeAddress(address || {});

    const statsString = state ? `, ${state}` : '';

    return (
      <div className={styles.address}>
        <div>{`${line1 || ''}`}</div>
        <div>{`${city}${statsString} ${zip || ''}`}</div>
      </div>
    );
  };

  const renderAddress = () => {
    if (Array.isArray(value)) {
      return value.map((val, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className={styles.addressItem} key={index}>
          {getAddress(val)}
        </div>
      ));
    }

    if (value?.City) {
      return getAddress(value);
    }

    return value;
  };

  return (
    <InfoItem
      className={classNames(styles.addressInfo, className)}
      label="Mailing Address"
      value={renderAddress()}
    />
  );
};

AddressInfo.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
};

AddressInfo.defaultProps = {
  className: '',
  value: null,
};

export default AddressInfo;
