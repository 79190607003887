import { FC, ReactElement, SVGAttributes } from 'react';

export const CheckmarkIcon: FC<SVGAttributes<SVGElement>> = (
  props: SVGAttributes<SVGElement>,
): ReactElement => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_62909_5062)">
      <path
        d="M3.33203 7.9999L6.66536 11.3332L13.332 4.66656"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_62909_5062">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
