const Display = ({ className, onClick }) => {
  return (
    <div className={className} onClick={onClick} style={{ position: 'relative', top: '2px' }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M20 12C20 17.6129 14.7022 14.2026 13 16C11.5419 17.5396 15.5 19.5 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
          stroke="#262626"
          strokeWidth="2"
        />
        <circle cx="7.80835" cy="12.9297" r="1.5" fill="#262626" />
        <circle cx="9.30835" cy="8.95312" r="1.5" fill="#262626" />
        <circle cx="13.5" cy="8.09375" r="1.5" fill="#262626" />
        <circle cx="15.927" cy="11.4297" r="1.5" fill="#262626" />
      </svg>
    </div>
  );
};

export default Display;
