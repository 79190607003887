import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  requestGetProjectsEffect,
  requestGetTransactionsEffect,
} from 'store/effects/transactions/all';
import {
  getFormNamesSelector,
  getFormMetaSelector,
  getFormClientNamesSelector,
} from 'store/selectors/requestFormProcess';

import { getProjectsSelector } from 'store/selectors/projects';
import { getAggregatePageTypeSelector } from 'store/selectors/workshop';
import { FilterSections } from './FilterSections/FilterSections';
import { updateFormsFiltersAction } from 'store/actions/formProcess';
import { addProOrTransFilterParam } from 'utils/aggregatedPageTypeHelper';

export const Filter = (props) => {
  const { isArchive } = props;

  const { transactions } = useSelector(getFormMetaSelector);
  const formNames = useSelector(getFormNamesSelector);
  const clientNames = useSelector(getFormClientNamesSelector);
  const projects = useSelector(getProjectsSelector);
  const aggregatedPageType = useSelector(getAggregatePageTypeSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestGetTransactionsEffect({}));
    dispatch(requestGetProjectsEffect({ filters: { isProject: true } }));
  }, []);

  const doneHandler = (p) => {
    const filters = addProOrTransFilterParam(aggregatedPageType, p);

    dispatch(updateFormsFiltersAction({ search: undefined, ...filters }));
  };

  const resetHandler = () => {
    dispatch(updateFormsFiltersAction({ search: undefined }));
  };

  return (
    <FilterSections
      onDone={doneHandler}
      onReset={resetHandler}
      isArchive={isArchive}
      formNames={formNames}
      taskTransactions={transactions}
      projects={projects}
      clientNames={clientNames}
    />
  );
};
