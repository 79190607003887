import { useSelector, useDispatch } from 'react-redux';
import { useState, useMemo, useEffect } from 'react';
import { cloneDeep } from 'lodash-es';
import PropTypes from 'prop-types';

import { getUserRoleSelector, getUserRolesMapSelector } from 'store/selectors/user';
import { preFormQuestions, preFormQuestionsIds } from 'settings/constants/preForm';
import { getRequestQuoteSelector } from 'store/selectors/requestQuote';
import { getPreFormSelector } from 'store/selectors/requestQuote';
import { setPreFormQuestionsEffect } from 'store/effects/quotes';
import { ContentWrapper } from 'pages/RequestQuote/components';
import { updateQuoteDraftEffect } from 'store/effects/quotes';
import ChangeQuestion from './ChangeQuestion';
import { Wrapper } from 'components';
import Controls from './Controls';
import { Close as CloseIcon } from 'components/Icons';
import { useHistory } from 'react-router-dom';
import { link } from 'settings/navigation/link';

import styles from './styles.module.scss';

const PreForm = (props) => {
  const { onSubmit, setQuestionIndex } = props;
  const [currentQuestionId, setCurrentQuestionId] = useState(preFormQuestionsIds.isProperty);
  const [prevIds, setPrevIds] = useState([]);
  const dispatch = useDispatch();
  const userRole = useSelector(getUserRoleSelector);
  const { isAgent, isClient } = useSelector(getUserRolesMapSelector);
  const { isDraft, isPending } = useSelector(getRequestQuoteSelector);
  const preForm = useSelector(getPreFormSelector);

  const questions = useMemo(() => preFormQuestions(userRole), [userRole]);

  useEffect(() => {
    setQuestionIndex(questions.findIndex((question) => question.id === currentQuestionId));
  }, [currentQuestionId]);

  const setNext = (id) => {
    const moveNext = () => {
      if (id) {
        setPrevIds((prevState) => [...prevState, currentQuestionId]);
        return setCurrentQuestionId(id);
      }

      const currentQuestionIndex = questions.findIndex(
        (question) => question.id === currentQuestionId,
      );

      if (questions[currentQuestionIndex + 1]) {
        const nextQuestionId = questions[currentQuestionIndex + 1].id;
        setPrevIds((prevState) => [...prevState, currentQuestionId]);
        setCurrentQuestionId(nextQuestionId);
      }

      if (currentQuestionIndex === 3 && (preForm?.client?.id || preForm?.client?.Id)) {
        dispatch(
          setPreFormQuestionsEffect({
            client: { id: preForm.client.id || preForm.client.Id, ...preForm?.client },
          }),
        );
        return onSubmit();
      }
    };

    if (
      isDraft &&
      (currentQuestionId === preFormQuestionsIds.property ||
        currentQuestionId === preFormQuestionsIds.client)
    ) {
      dispatch(
        updateQuoteDraftEffect({}, { silent: true }, (err) => {
          if (!err) {
            moveNext();
          }
        }),
      );
    } else {
      moveNext();
    }
  };

  const setPrevious = () => {
    if (prevIds.length) {
      const copyPrevIds = cloneDeep(prevIds);
      const prevId = copyPrevIds.pop();
      setCurrentQuestionId(prevId);
      setPrevIds(copyPrevIds);
    } else {
      history.goBack();
    }
  };

  const onAnswer = (isAnswer) => {
    if (currentQuestionId === preFormQuestionsIds.isProperty) {
      if (isAnswer) {
        setNext(preFormQuestionsIds.property);
      } else if (isAgent) {
        setNext(preFormQuestionsIds.isClient);
      } else {
        return onSubmit();
      }
    }

    if (currentQuestionId === preFormQuestionsIds.property) {
      if (isAgent) {
        setNext(preFormQuestionsIds.isClient);
      } else {
        return onSubmit();
      }
    }

    if (currentQuestionId === preFormQuestionsIds.isClient) {
      if (isAnswer) {
        setNext(preFormQuestionsIds.client);
      } else {
        return onSubmit();
      }
    }
  };

  const onContinue = () => {
    if (
      currentQuestionId === preFormQuestionsIds.isProperty &&
      preForm?.[preFormQuestionsIds.isProperty] === false
    ) {
      return setNext(preFormQuestionsIds.isClient);
    } else if (
      currentQuestionId === preFormQuestionsIds.isProperty &&
      (preForm?.[preFormQuestionsIds.isProperty] === true ||
        preForm?.[preFormQuestionsIds.isProperty] === undefined)
    ) {
      return setNext(preFormQuestionsIds.property);
    } else if (
      currentQuestionId === preFormQuestionsIds.isClient &&
      (preForm?.[preFormQuestionsIds.isClient] === true ||
        preForm?.[preFormQuestionsIds.isClient] === undefined)
    ) {
      return setNext(preFormQuestionsIds.client);
    } else if (
      currentQuestionId === preFormQuestionsIds.isClient &&
      preForm?.[preFormQuestionsIds.isClient] === false
    ) {
      return onSubmit();
    }
  };
  const history = useHistory();
  const onClose = () => {
    history.replace(link.toServices());
  };

  return (
    <Wrapper className={styles.preFormContainer} isPending={isPending}>
      <div className={styles.head}>
        <Controls
          className={styles.controls}
          isClient={isClient}
          onPrev={setPrevious}
          onNext={setNext}
          onSubmit={onSubmit}
          currentQuestionId={currentQuestionId}
        />
        <CloseIcon onClick={onClose} className={styles.closeIcon} />
      </div>
      <ContentWrapper>
        {questions.map(({ id, Component }) => (
          <ChangeQuestion show={currentQuestionId === id} key={id}>
            <Component
              isClient={isClient}
              onAnswer={onAnswer}
              onContinue={onContinue}
              onPrev={setPrevious}
              onNext={setNext}
              onPreFormSubmit={onSubmit}
            />
          </ChangeQuestion>
        ))}
      </ContentWrapper>
    </Wrapper>
  );
};

PreForm.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  setQuestionIndex: PropTypes.func,
};

PreForm.defaultProps = {
  className: '',
  onSubmit: () => {},
};

export default PreForm;
