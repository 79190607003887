import moment from 'moment';
import Spin from 'antd/lib/spin';
import classnames from 'classnames';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getIfExistThread } from 'helpers';

import { AgentsType, ClientsType } from 'types';

import { getUserId } from 'store/selectors/user';
import { Avatar as AntAvatar, Button } from 'components-antd';
import { SOCKET_THREAD_TYPES } from 'settings/constants/sockets';
import { resetFormDetailsEffect } from 'store/effects/formProcess';
import { DRAWER_MESSAGES_TYPES } from 'settings/constants/drawers';
import { Icons, ICON_VARIANT_TYPE } from 'pages/Workshop/Forms/Icons';
import { getThreadsListSelector } from 'store/selectors/sockets/threads';
import { FormsTableStatus, ResentEmailEnum, SignatoryStatus } from 'app-constants';
import { Modal, ModalProps, Divider, Row, Col } from 'components-antd';
import { getFormDetailsSelector, getFormMetaSelector } from 'store/selectors/requestFormProcess';

import {
  changeMessagesDrawerTypeAction,
  openMessagesDrawerAction,
} from 'store/actions/drawers/messages';
import { onHandleViewFormType } from '../../Forms';

import styles from './styles.module.scss';

import { Signatory } from 'components/Icons/Signatory';
import { CCtag } from 'components/Icons/CCtag';
import classNames from 'classnames';
import { FormStatus } from '../FormStatus';
import { useHistory } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';
import {
  DeleteDraftForm,
  Documents,
  DownloadFilesModal,
  FormDetailCollapsePanel,
  FormDetailEditor,
  FormDetailHeader,
  FormLink,
  History,
  MegaPhoneModal,
  ReplaceEntityModal,
  VoidFormModal,
} from './components';
import { link } from 'settings/navigation/link';
import { isExternalMember } from '../FormsTable/helper';
import { CustomDropDown, MessageDataProps } from './components/CustomDropDown';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { TEAM_ADMIN, TEAM_OWNER } from 'settings/constants/roles';

interface FormDetailModalProps extends ModalProps {
  handleSendMessage: (
    message: string,
    users: string[],
    setSendBtnLoading: (loading: boolean) => void,
    setShowMegaPhoneModal: (open: boolean) => void,
  ) => void;
  onCloseModal: () => void;

  handleViewForm: onHandleViewFormType;
  handleResendForm: ({
    url,
    userId,
    email,
    type,
  }: {
    url: string;
    userId?: number;
    email: string;
    type: ResentEmailEnum;
  }) => void;
  handleRemoveEditor: ({ url, editorId }: { url: string; editorId: number }) => void;
  handleReplaceEntity: ({
    url,
    isEditor,
    oldId,
    userId,
    email,
    setLoading,
  }: {
    url: string;
    isEditor?: boolean;
    oldId?: number;
    userId?: number;
    email?: string;
    setLoading: (loading: boolean) => void;
    onCloseModal: () => void;
  }) => void;
  viewOnly?: boolean;
  selectedFormProcess: number;
  selectedFormStatus?: string;
  fetchFormDetails: Function;
  onDeleteFormSuccess?: Function;
  refetchForms?: Function;
  inProgressForm?: boolean;
  clientProfileId?: number;
}

export const FormDetailModal = ({
  handleSendMessage,
  onCloseModal,
  handleViewForm,
  handleResendForm,
  handleRemoveEditor,
  handleReplaceEntity,
  viewOnly: formViewOnly,
  selectedFormProcess,
  selectedFormStatus,
  fetchFormDetails,
  onDeleteFormSuccess,
  inProgressForm,
  refetchForms,
  clientProfileId,
}: FormDetailModalProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const currentUserId = useSelector(getUserId);
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const isAdminOrOwner = [TEAM_ADMIN, TEAM_OWNER].includes(agentRole);
  const threads = useSelector(getThreadsListSelector);
  const { isPending, data: formDetails } = useSelector(getFormDetailsSelector);
  const { clients, agents } = useSelector(getFormMetaSelector);
  const [historyKey, setHistoryKey] = useState<string | string[]>();
  const [fileKey, setFileKey] = useState<string | string[]>();
  const [formVoidModal, setFormVoidModal] = useState<{
    modalType: string;
    formName?: string;
    formId?: string;
  }>({
    modalType: '',
  });

  const [showMegaPhoneModal, setShowMegaPhoneModal] = useState(false);

  const [replaceEntityModal, setReplaceEntityModal] = useState<{
    open: boolean;
    isEditor?: boolean;
    entityId?: number;
    name?: string;
    email?: string;
    role?: string;
    options?: ClientsType & AgentsType;
  }>({ open: false });
  const [disableSections, setDisableSections] = useState(false);
  const [showDownloadFilesModal, setShowDownloadFilesModal] = useState(false);

  const allRecipients = [
    ...(formDetails?.copyRecipients || []),
    ...(formDetails?.updateRecipients || []),
  ];

  const isFormCreator = formDetails?.isOwner;
  const isExternalParticipant = isExternalMember(formDetails);
  let viewOnly = formViewOnly || isExternalParticipant;

  const editorsAvailable =
    !!formDetails?.editors?.Agent?.users?.length || !!formDetails?.editors?.Client?.users?.length;

  const isDraftForm = selectedFormStatus === FormsTableStatus.Draft;
  const isWaitingOnYouForm = selectedFormStatus === FormsTableStatus.WaitingOnYou;
  const isWaitingOnOtherForm = selectedFormStatus === FormsTableStatus.WaitingOnOthers;

  const showLeftPanel =
    isFormCreator ||
    (isExternalParticipant && (formDetails?.transactionId || formDetails?.client)) ||
    (isDraftForm && isAdminOrOwner);

  const nonVoidDocuments = useMemo(
    () => formDetails?.formDocuments?.filter(({ isVoid }) => !isVoid),
    [formDetails],
  );

  const hasMultipleDocuments = nonVoidDocuments && nonVoidDocuments.length > 1;

  useEffect(
    () => () => {
      resetFormDetailsEffect(dispatch);
    },
    [],
  );

  const openMegaPhoneModal = () => {
    if (!viewOnly && !disableSections) {
      setShowMegaPhoneModal(true);
    }
  };

  const closeMegaPhoneModal = () => {
    setShowMegaPhoneModal(false);
  };

  const closeReplaceEntityModal = () => {
    setReplaceEntityModal({ open: false });
  };

  const handleMessage = ({ id, name, avatar }: MessageDataProps) => {
    const thread = getIfExistThread(
      threads,
      [id, currentUserId],
      ({ Type }) => Type === SOCKET_THREAD_TYPES.CHAT,
    );

    onCloseModal();
    dispatch(openMessagesDrawerAction(true));

    if (!thread) {
      dispatch(
        changeMessagesDrawerTypeAction({
          type: DRAWER_MESSAGES_TYPES.NEW_MESSAGE,
          params: {
            threadId: thread?.Id || null,
            participants: [
              {
                id,
                name,
                value: id,
                avatarUrl: avatar,
              },
            ],
          },
        }),
      );
    } else {
      dispatch(
        changeMessagesDrawerTypeAction({
          type: DRAWER_MESSAGES_TYPES.CHAT,
          params: {
            threadId: thread.Id,
          },
        }),
      );
    }
  };

  const changeModalDetails = (details) => {
    setFormVoidModal({ ...formVoidModal, ...details });
  };

  const renderDeclineReason = (declineMessage) => {
    if (!declineMessage) return;

    return <div className={styles.declinedReason}>{declineMessage}</div>;
  };

  const onViewForm = () => {
    if (formDetails && formDetails.formDocument?.url) {
      if (hasMultipleDocuments) {
        const [formProcessPublicId] = formDetails.formDocument.url.split('/');
        const allDocuments = nonVoidDocuments.map((item) => ({
          ...item,
          Order: item.order,
          PublicId: item.url.split('/')[1],
        }));

        history.push({
          pathname: link.toWorkshopDynamicFormBundle({
            formProcessPublicId,
          }),

          state: {
            formBundle: true,
            allDocuments,
            bundleName: formDetails?.formName,
            redirectionLink: location.pathname,
          },
        });
      } else {
        handleViewForm(formDetails?.formDocument?.url, formDetails?.formDocument?.type);
      }
    }
  };

  const onVoidClick = () => {
    changeModalDetails({
      modalType: 'confirmation',
      formName: formDetails?.formName,
    });
  };

  const proceedToEditForm = () => {
    history.push({
      pathname: routes.workshopFormProcessRequest,
      state: {
        voidedForm: true,
        createFormCopy: true,
        formDetails,
        name: formDetails?.formName,
        formProcessId: selectedFormProcess,
      },
    });
  };

  const renderEditButton = (onClick) => {
    return (
      <Button variant="default" className={styles.editButton} onClick={onClick}>
        <div className={styles.editButtonContainer}>
          <Icons variant={ICON_VARIANT_TYPE.EDIT_PENCIL} className={styles.editIcon} />
          <div>Edit</div>
        </div>
      </Button>
    );
  };

  const renderViewButton = () => {
    return (
      <Button
        variant="secondary"
        className={classNames(showLeftPanel ? styles.viewFormBtn : styles.viewFormFixBtn, {
          [styles.disabledSection]: disableSections,
        })}
        onClick={onViewForm}
        disabled={disableSections}
      >
        {isWaitingOnYouForm ? 'Sign' : 'View'}
      </Button>
    );
  };

  const renderVoidButton = () => {
    if (!(formDetails?.isCompleted || formDetails?.isCancelled || isDraftForm)) {
      return (
        <Button
          variant="default"
          disabled={!formDetails || disableSections}
          className={classNames(styles.voidButton, { [styles.disabledSection]: disableSections })}
          onClick={onVoidClick}
        >
          Void
        </Button>
      );
    }
  };

  const renderDeleteButton = () => {
    if (!isDraftForm || !isFormCreator) return;

    return (
      <DeleteDraftForm
        formName={formDetails?.formName}
        formProcessId={selectedFormProcess}
        onDeleteFormSuccess={onDeleteFormSuccess}
        refetchForms={refetchForms}
      />
    );
  };

  const renderCopyButton = () => {
    return (
      <Button variant="default" className={styles.editButton} onClick={proceedToEditForm}>
        <div className={styles.editButtonContainer}>Copy</div>
      </Button>
    );
  };

  const renderFormDetailButtons = (flexed = false) => {
    if (formDetails?.isVoid) return renderEditButton(proceedToEditForm);

    return (
      <div className={flexed ? styles.modalFlexButtons : ''}>
        {flexed && renderVoidButton()}
        {renderViewButton()}
        {!flexed && renderVoidButton()}

        {renderDeleteButton()}
        {formDetails?.isCompleted && renderCopyButton()}
      </div>
    );
  };

  return (
    <>
      <Modal
        open={true}
        footer={null}
        width={showLeftPanel ? 1000 : 640}
        title={
          !isPending ? (
            <FormDetailHeader
              formDetails={formDetails}
              openMegaPhoneModal={openMegaPhoneModal}
              disableSections={disableSections}
              selectedFormStatus={selectedFormStatus}
              isFormCreator={isFormCreator}
              showMegaPhone={!viewOnly && !disableSections}
              inProgressForm={inProgressForm}
            />
          ) : (
            <></>
          )
        }
        onCancel={onCloseModal}
        className={classNames(styles.formDetailModal, {
          [styles.smallFormDetailModal]: !showLeftPanel && !isPending,
          [styles.modalLoading]: isPending,
        })}
        maskClosable={false}
        destroyOnClose={true}
      >
        {isPending ? (
          <Spin className={styles.spinner} />
        ) : (
          <div
            className={classNames(styles.formDetailModalBody, {
              [styles.smallFormDetailModalBody]: !showLeftPanel,
            })}
          >
            <div
              className={classNames(styles.modalContentContainer, {
                [styles.smallFormDetailContentContainer]: !showLeftPanel,
              })}
            >
              <Row gutter={16}>
                {showLeftPanel ? (
                  <Col span={12} className={styles.formDetailLeftPanel}>
                    <div className={styles.formDetailFlexPanel}>
                      <div>
                        <h1 className={classnames(styles.formDetailHeader, styles.transHeading)}>
                          This form is linked to:
                        </h1>
                        <FormLink
                          formProcessId={selectedFormProcess}
                          formDetails={formDetails}
                          refetchFormDetails={fetchFormDetails}
                          setDisableSections={setDisableSections}
                          viewOnly={viewOnly}
                          clientProfileId={clientProfileId}
                          onCloseModal={onCloseModal}
                        />
                      </div>

                      {isFormCreator || (isDraftForm && isAdminOrOwner) ? (
                        <div className={styles.formDetailButtons}>{renderFormDetailButtons()}</div>
                      ) : (
                        ''
                      )}
                    </div>
                  </Col>
                ) : (
                  <></>
                )}

                <Col
                  span={showLeftPanel ? 12 : 24}
                  className={classNames(styles.formDetailRightPanel, {
                    [styles.righPanelPadding]: showLeftPanel,
                    [styles.disabledSection]: disableSections,
                  })}
                >
                  <FormDetailCollapsePanel
                    panelKey={'people'}
                    panelHeading={'Tracking'}
                    disabledSection={disableSections}
                    defaultActiveKey={['people']}
                  >
                    {editorsAvailable ? (
                      <>
                        {!formDetails?.isCompleted ? (
                          <div className={styles.formEditorHeading}>Editors</div>
                        ) : (
                          ''
                        )}

                        {formDetails?.editors?.Client?.users?.length ? (
                          <div className={styles.subHeadingItems}>
                            {formDetails.editors.Client.users.map((val, index) => (
                              <div key={index}>
                                <FormDetailEditor
                                  item={val}
                                  role="Client"
                                  options={clients}
                                  viewOnly={viewOnly}
                                  handleResendForm={handleResendForm}
                                  setReplaceEntityModal={setReplaceEntityModal}
                                  handleRemoveEditor={handleRemoveEditor}
                                  handleMessage={handleMessage}
                                />
                              </div>
                            ))}
                          </div>
                        ) : (
                          <></>
                        )}

                        {formDetails?.editors?.Agent?.users?.length ? (
                          <div className={styles.subHeadingItems}>
                            {formDetails.editors.Agent.users.map((val, index) => (
                              <div key={index}>
                                <FormDetailEditor
                                  item={val}
                                  role="Agent"
                                  options={agents}
                                  viewOnly={viewOnly}
                                  handleResendForm={handleResendForm}
                                  setReplaceEntityModal={setReplaceEntityModal}
                                  handleRemoveEditor={handleRemoveEditor}
                                  handleMessage={handleMessage}
                                />
                              </div>
                            ))}
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}

                    {formDetails?.signatories?.length ? (
                      <>
                        <br></br>
                        {!formDetails?.isCompleted ? (
                          <div className={styles.formEditorHeading}>Signatories</div>
                        ) : (
                          ''
                        )}

                        <div className={styles.subHeadingItems}>
                          {formDetails?.signatories?.map((val, index) => {
                            const isDeclined = val.status === SignatoryStatus.Declined;

                            return (
                              <div key={index}>
                                <Row
                                  className={classnames(styles.fillersRow, styles.avatarWrapper)}
                                  key={index}
                                >
                                  <div className={styles.fillerInfo}>
                                    <Col className={styles.signatureCountRow}>
                                      {formDetails?.isSequential ? (
                                        <div
                                          className={classnames({
                                            [styles.pendingSignature]:
                                              val.status === SignatoryStatus.Pending,
                                          })}
                                        >
                                          {val.status === SignatoryStatus.Signed ? (
                                            <>
                                              <div>
                                                <Icons
                                                  className={styles.avatarWrapper}
                                                  variant={ICON_VARIANT_TYPE.SIGNED}
                                                />
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              <div>
                                                <Signatory />
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      ) : val.status === SignatoryStatus.Signed ? (
                                        <Icons variant={ICON_VARIANT_TYPE.SIGNED} />
                                      ) : (
                                        <Signatory />
                                      )}
                                    </Col>
                                    <Col>
                                      <p className={styles.fillersName}>
                                        {val.isYou ? 'You' : val.name}
                                      </p>
                                      <p className={styles.fillerEmail}>{val.email}</p>
                                    </Col>
                                  </div>

                                  <div className={styles.fillerActions}>
                                    <Col className={styles.fillersStatus}>
                                      <p
                                        className={
                                          val?.status === SignatoryStatus.Pending ||
                                          val?.status === SignatoryStatus.Requested ||
                                          formDetails?.isVoid
                                            ? styles.colorClass
                                            : ''
                                        }
                                      >
                                        {val.status === SignatoryStatus.Requested
                                          ? formDetails?.isVoid
                                            ? 'Voided'
                                            : 'Requested'
                                          : formDetails?.isVoid
                                          ? 'Voided'
                                          : val.status}
                                      </p>

                                      {!formDetails?.isVoid ? (
                                        <p>
                                          {val.status[0] !== 'W' && val.date
                                            ? moment(val.date).fromNow()
                                            : ''}
                                        </p>
                                      ) : (
                                        <></>
                                      )}
                                    </Col>

                                    <Col>
                                      {!viewOnly && !formDetails?.isVoid && (
                                        <CustomDropDown
                                          messageData={{
                                            id: val.userId,
                                            name: val.name,
                                            avatar: val.avatar,
                                          }}
                                          allowResend={val.allowResend}
                                          allowReplace={val.allowReplace}
                                          allowMessage={Boolean(val.userId) && !val.isYou}
                                          onResend={() =>
                                            handleResendForm({
                                              url: formDetails?.formDocument?.url,
                                              userId: val.userId,
                                              email: val.email,
                                              type: ResentEmailEnum.signature,
                                            })
                                          }
                                          onReplace={() =>
                                            setReplaceEntityModal({
                                              open: true,
                                              isEditor: false,
                                              entityId: val.signatoryId,
                                              name: val.name,
                                              email: val.email,
                                              role: val.role,
                                              options: [...(agents || []), ...(clients || [])],
                                            })
                                          }
                                          onMessage={handleMessage}
                                        />
                                      )}
                                    </Col>
                                  </div>
                                </Row>

                                {formDetails.isSequential ? (
                                  <div
                                    className={classNames(styles.trackingDivider, {
                                      [styles.declineTrackingdDivider]: isDeclined,
                                    })}
                                  >
                                    {isDeclined ? renderDeclineReason(val.declineMessage) : <></>}
                                  </div>
                                ) : (
                                  <div className={styles.emptyDivider}>
                                    {isDeclined ? renderDeclineReason(val.declineMessage) : <></>}
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {allRecipients?.length ? (
                      <div className={styles.subHeadingItems}>
                        {allRecipients?.map((val, index) => (
                          <Row
                            className={classnames(
                              styles.fillersRow,
                              styles.avatarWrapper,
                              styles.ccRow,
                            )}
                            key={index}
                          >
                            <div className={styles.fillerInfo}>
                              <Col className={styles.signatureCountRow}>
                                <CCtag />
                              </Col>
                              <Col>
                                <p className={styles.fillersName}>
                                  {val.FirstName + ' ' + val.LastName}
                                </p>
                              </Col>
                            </div>
                            <div className={styles.fillerActions}>
                              <Col className={styles.fillersStatus}>
                                {formDetails?.isCompleted ? (
                                  <span>Updates Sent</span>
                                ) : (
                                  <span>Copy not sent</span>
                                )}
                              </Col>
                            </div>
                          </Row>
                        ))}
                      </div>
                    ) : (
                      <></>
                    )}
                  </FormDetailCollapsePanel>

                  <Divider />

                  <FormDetailCollapsePanel
                    activeKey={fileKey}
                    onChange={setFileKey}
                    panelKey={'Files'}
                    panelHeading={'Files'}
                    disabledSection={disableSections}
                    filesPanel={true}
                    extra={
                      formDetails?.isCompleted && (
                        <div
                          className={styles.downloadIcon}
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowDownloadFilesModal(true);
                          }}
                        >
                          <Icons variant={ICON_VARIANT_TYPE.DOWNLOAD_FORM_BUNDLE} />
                        </div>
                      )
                    }
                  >
                    {formDetails?.formDocuments?.length ? (
                      <Documents
                        documentData={formDetails?.formDocuments}
                        formProcessDocuments={formDetails?.formProcessDocuments}
                        handleViewForm={handleViewForm}
                        selectedFormProcess={selectedFormProcess}
                        disableViewForm={!!isExternalMember}
                        showFormDate={!isDraftForm}
                      />
                    ) : (
                      <></>
                    )}
                  </FormDetailCollapsePanel>

                  {formDetails?.historyLogs?.length ? (
                    <>
                      <Divider />

                      <FormDetailCollapsePanel
                        activeKey={historyKey}
                        onChange={setHistoryKey}
                        panelKey={'History'}
                        panelHeading={'History'}
                        disabledSection={disableSections}
                      >
                        <History
                          history={formDetails?.historyLogs}
                          createdBy={formDetails?.createdBy}
                          isFormOwner={isFormCreator}
                        />
                      </FormDetailCollapsePanel>
                    </>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            </div>

            <div
              className={classNames(styles.formDetailButtons, {
                [styles.hideFooterButtons]: showLeftPanel || (!isFormCreator && !isDraftForm),
                [styles.formDetailFooterButtons]: isFormCreator || isDraftForm,
              })}
            >
              {renderFormDetailButtons(true)}
            </div>
          </div>
        )}
      </Modal>

      {showMegaPhoneModal && (
        <MegaPhoneModal
          signatories={formDetails?.signatories}
          editors={formDetails?.editors}
          handleSendMessage={handleSendMessage}
          setShowMegaPhoneModal={setShowMegaPhoneModal}
          onCloseModal={closeMegaPhoneModal}
        />
      )}

      <ReplaceEntityModal
        url={formDetails?.formDocument?.url}
        replaceEntityData={replaceEntityModal}
        onCloseModal={closeReplaceEntityModal}
        handleReplaceEntity={handleReplaceEntity}
      />

      <VoidFormModal
        {...formVoidModal}
        onChangeModalDetails={changeModalDetails}
        formProcessId={selectedFormProcess}
        onEditForm={proceedToEditForm}
        isWaitingOnYouForm={isWaitingOnYouForm}
        isWaitingOnOtherForm={isWaitingOnOtherForm}
        inProgressForm={inProgressForm}
        refetchForms={refetchForms}
      />

      <DownloadFilesModal
        isOpen={showDownloadFilesModal}
        onClose={() => setShowDownloadFilesModal(false)}
        documents={nonVoidDocuments || []}
        auditTrailDocuments={formDetails?.formProcessDocuments || []}
        formProcessPublicId={nonVoidDocuments?.[0]?.url.split('/')[0]}
        downloadedFileName={formDetails?.formName}
      />
    </>
  );
};
