import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from 'pages/Workshop/Transactions/TransactionTasks/Icons';
import { Button } from 'components';
import { useOutsideClick } from 'hooks';
import { TaskModal } from '../../../../TaskModal';
import { TaskTemplateModal } from '../../TaskTemplateModal';

import styles from './styles.module.scss';
import { useDispatch } from 'react-redux';
import { getAllTaskTemplatesEffect } from 'store/effects/templates';

const NewTask = (props) => {
  const { className } = props;
  const [open, setOpen] = useState(false);
  const [taskModal, setTaskModal] = useState(false);
  const [templatesModal, setTemplatesModal] = useState(false);
  const [showTemplateOption, setShowTemplateOption] = useState(false);

  const buttonRef = useRef();
  const optionsRef = useRef();
  const dispatch = useDispatch();

  useOutsideClick([buttonRef, optionsRef], () => setOpen(false));

  useEffect(() => {
    dispatch(
      getAllTaskTemplatesEffect({}, { silent: true }, (err, response) => {
        if (!err) {
          setShowTemplateOption(!!response.data.result.length);
        }
      }),
    );

    return () => {
      setShowTemplateOption(false);
    };
  }, []); // eslint-disable-line

  const onClickOptions = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  const onNewTask = (e) => {
    e.stopPropagation();
    setTaskModal(true);
    setOpen(false);
  };

  const onTemplates = (e) => {
    e.stopPropagation();
    setTemplatesModal(true);
    setOpen(false);
  };

  return (
    <div className={classNames(styles.newTask, className)}>
      <Button
        title="New"
        className={styles.btn}
        iconLeft={<Icon className={styles.icon} variant={Icon.PLUS} />}
        titleClassName={styles.btnTitle}
        onClick={onClickOptions}
        testid="new_task_button"
        ref={buttonRef}
      />
      {open && (
        <div ref={optionsRef} className={styles.options}>
          <ul>
            <li testid="edit_action" className={styles.item} onClick={onNewTask}>
              <div className={styles.icon}>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M3 8H13" stroke="#FF576D" strokeWidth="2" strokeLinecap="round" />
                  <path d="M8 13L8 3" stroke="#FF576D" strokeWidth="2" strokeLinecap="round" />
                </svg>
              </div>
              <span>New Task</span>
            </li>

            {showTemplateOption ? (
              <li testid="delete_action" className={styles.item} onClick={onTemplates}>
                <div className={styles.icon}>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_11056_179282)">
                      <path
                        d="M1.52344 3.04804L2.66629 4.1909L4.57106 2.28613"
                        stroke="#FF576D"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M1.52344 7.61933L2.66629 8.76218L4.57106 6.85742"
                        stroke="#FF576D"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M1.52344 12.1906L2.66629 13.3335L4.57106 11.4287"
                        stroke="#FF576D"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.23779 3.42871H14.0949"
                        stroke="#FF576D"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.23779 8H14.0949"
                        stroke="#FF576D"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.23779 12.5713H14.0949"
                        stroke="#FF576D"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_11056_179282">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <span>Templates</span>
              </li>
            ) : (
              <></>
            )}
          </ul>
        </div>
      )}

      <TaskModal
        isOpen={taskModal}
        onCloseModal={() => setTaskModal(false)}
        isNew={true}
        onSaveTaskAsTemplate={() => !showTemplateOption && setShowTemplateOption(true)}
        isTranactionRoom={true}
      />

      <TaskTemplateModal open={templatesModal} onCancel={() => setTemplatesModal(false)} />
    </div>
  );
};

NewTask.propTypes = {
  className: PropTypes.string,
};

NewTask.defaultProps = {
  className: '',
};

export default NewTask;
