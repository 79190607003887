import React from 'react';
import styles from '../styles.module.scss';
import { Button, Input } from 'components';
import { useDispatch } from 'react-redux';
import { updateClientDataEffect } from 'store/effects';
import { showSuccessMessage } from 'helpers';
import { useFormik } from 'formik';
import { requestGetClientsListEffect } from 'store/effects/clientsList';

type Props = {
  setIsEditingHeader: (isEditing: boolean) => void;
  firstName: string;
  lastName: string;
  id: string;
};

export const EditModalContent: React.FC<Props> = ({
  setIsEditingHeader,
  firstName,
  lastName,
  id,
}) => {
  const dispatch = useDispatch();
  const onSubmit = (values) => {
    dispatch(
      updateClientDataEffect({ clientId: id, ...values }, (err) => {
        if (!err) {
          showSuccessMessage('Client primary phone updated');
          dispatch(requestGetClientsListEffect({}, { silent: true }));
        }
        setIsEditingHeader(false);
      }),
    );
  };

  const formik = useFormik({
    initialValues: {
      firstName: firstName || '',
      lastName: lastName || '',
    },
    onSubmit,
  });
  return (
    <>
      <div className={styles.editHeader}>Edit Client</div>
      <form className={styles.editInputWrapper} onSubmit={formik.handleSubmit}>
        <div>
          <Input
            id={'firstName'}
            name="firstName"
            variant="lightRound"
            placeholder="First Name"
            value={formik.values.firstName}
            label={'First Name'}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
        <div>
          <Input
            id={'lastName'}
            name="lastName"
            variant="lightRound"
            placeholder="Last Name"
            label={'Last Name'}
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
        <div className={styles.editBtnWrapper}>
          <Button type="submit" className={styles.saveButton} title="Save" />
        </div>
      </form>
    </>
  );
};
