import validator from 'validator';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import Field from '../Field';
import { Spinner, Input } from 'components';
import { FullAccessInfo } from '../FullAccessInfo';
import CheckboxComponent from 'components/Form/Checkbox';
import { ClientInput, ClientRole } from 'components/Transactions';
import { getTransactionAccessSelector } from 'store/selectors/transaction';
import { getAgentTeamIsActiveSelector } from 'store/selectors/agentTeamDetail';
import { ValueObject } from 'components/Transactions/ClientInput/ClientInputView';
import { ParticipantEmail } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/Questions/Participants/ParticipantEmail';
import { splitFirstOccurrence } from 'utils';
import { getTransactionRolesEffect, requestGetTeamListEffect } from 'store/effects';

import styles from './styles.module.scss';

interface ContentProps {
  className: string;
  participant: ValueObject;
  setParticipant: (...args) => void;
}

const Content = ({ className, participant, setParticipant }: ContentProps) => {
  const { fullAccess } = useSelector(getTransactionAccessSelector);
  const isTeamAgentActive = useSelector(getAgentTeamIsActiveSelector);
  const dispatch = useDispatch();

  const [teamMembersLoading, setTeamMembersLoading] = useState(true);
  const [teamMembers, setTeamMembers] = useState([]);

  const fetchTeamList = async () => {
    dispatch(
      requestGetTeamListEffect({ Status: 'Accepted' }, {}, (err, resp) => {
        if (!err) setTeamMembers(resp.data);
        setTeamMembersLoading(false);
      }),
    );
  };

  const isSelectedUserTeamMember = (id) => {
    return !!teamMembers.find((el: { Id: number }) => el.Id === id);
  };

  useEffect(() => {
    if (teamMembersLoading) fetchTeamList();
  }, [teamMembersLoading]);

  const updateFields = (values) => setParticipant({ ...participant, ...values });

  const isInvalidName = (name) => {
    const regex = new RegExp('^[0-9a-zA-Z ]+$');

    if (!regex.test(name)) {
      return 'Please enter a valid invitee name';
    }

    return '';
  };

  const insertName = (val) => {
    const inputValue = val.trim();

    const { first, remainder } = splitFirstOccurrence(inputValue, ' ');

    const participantValue: {
      id?: number;
      customName?: string;
      firstName?: string;
      lastName?: string;
      tagRole?: string;
      error?: string;
      nameError?: string;
    } = {
      id: participant?.id,
      tagRole: participant?.tagRole,
    };

    if (inputValue !== '') {
      participantValue.customName = inputValue;
      participantValue.firstName = first;
      participantValue.lastName = remainder;
      participantValue.error = undefined;
      participantValue.id = undefined;

      participantValue.nameError = isInvalidName(inputValue);
    }
    updateFields(participantValue);
  };

  const insertRole = (val, roleId?) => {
    const inputValue = (val || '').trim();

    if (inputValue !== '') {
      updateFields({
        id: participant?.id,
        email: participant?.email,
        error: participant?.error,
        nameError: '',
        tagRole: val,
        roleId,
      });
    }
  };

  const onChangeClient = (value) => {
    updateFields({
      ...participant,
      ...value,
      customEmail: undefined,
      customName: undefined,
      error: undefined,
      nameError: undefined,
    });
  };

  const onBlurClientInput = (value) => {
    insertName(value);
  };

  const onChangeRole = (event, val) => {
    insertRole(val?.name, val?.roleId);
  };

  const onBlurRoleInput = (event) => {
    insertRole(event.target.value);
  };

  const onRoleKeyPress = (event) => {
    if (event.key === 'Enter') {
      insertRole(event.target.value);
    }
  };

  const onEmailInput = (val) => {
    const email = val.trim()?.toLowerCase();
    let errorField: any = undefined;

    if (validator.isEmail(email)) errorField = { error: undefined };

    updateFields({
      customEmail: email,
      ...errorField,
    });
  };

  useEffect(() => {
    dispatch(getTransactionRolesEffect());
  }, []);

  const onEmailBlur = (val) => {
    const email = val.trim();
    updateFields({
      error: email && !validator.isEmail(email) ? 'Please enter a valid email' : undefined,
    });
  };

  const onChangeAccess = (e, t, val) => updateFields({ transactionAccess: val });

  if (teamMembersLoading) {
    return (
      <div className={classNames(styles.content, className)}>
        <Spinner loaderClassName={classNames(styles.loader)} />
      </div>
    );
  } else {
    return (
      <div className={classNames(styles.content, className)}>
        <Field label="Invitee">
          <ClientInput
            onBlur={onBlurClientInput}
            onChange={onChangeClient}
            value={participant}
            excludeUser
            className={styles.inviteAutoComplete}
            maxLength={32}
          />
          {participant.nameError && <div className={styles.nameError}>{participant.nameError}</div>}
        </Field>
        {participant?.customName ? (
          <Field label="Email">
            <ParticipantEmail
              customEmail={participant.customEmail}
              error={participant.error}
              onUpdate={(val) => onEmailInput(val)}
              onBlurInput={(val) => onEmailBlur(val)}
              className={styles.participantEmail}
              variant={Input.LIGHT_ROUND}
            />
          </Field>
        ) : (
          <></>
        )}

        <Field label="Role">
          <ClientRole
            wrapperClassName={styles.inputWrapper}
            onChange={onChangeRole}
            onBlur={onBlurRoleInput}
            onKeyPress={onRoleKeyPress}
            value={participant}
            variant={Input.LIGHT_ROUND}
            isSearchIcon={false}
          />
        </Field>
        {fullAccess && isTeamAgentActive && isSelectedUserTeamMember(participant.id) ? (
          <>
            <CheckboxComponent
              id="FullAccess"
              label="Full Access"
              checked={participant.transactionAccess}
              onChange={onChangeAccess}
              direction={CheckboxComponent.DIRECTION_RIGHT}
              name="FullAccess"
              labelTextClassName={styles.checkboxLabel}
              className={styles.checkbox}
            />
            <FullAccessInfo />
          </>
        ) : null}
      </div>
    );
  }
};

export default Content;
