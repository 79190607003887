import React, { useState, useEffect, useMemo } from 'react';
import { Checkbox, Locations } from 'components';
import { ClientAddressUnion, ClientAddressEntity, ClientAddress } from 'app-constants';
import { useDispatch } from 'react-redux';
import { updateClientDataEffect } from 'store/effects';
import { showSuccessMessage } from 'helpers';
import { showErrorMessage } from 'helpers/errors';

import styles from './addAddressStyles.module.scss';
import { TrashIcon } from 'components/Icons';
import { Button } from 'components-antd';
import { isCASSAddress } from 'components/ClientsDrawer/ProfileClient/Contact/components/Address/helpers';
import { createAddress } from 'helpers/utilsTS';

interface Props {
  clientId: string;
  addresses: ClientAddressUnion[];
  setAddresses: React.Dispatch<React.SetStateAction<ClientAddressUnion[]>>;
  editAddress: ClientAddressUnion | null;
  handleCancelClick: () => void;
  setEditAddress: React.Dispatch<React.SetStateAction<ClientAddressUnion | null>>;
  editAddressIndex: number;
  setPoperOverOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

type LocationResultType = {
  result: { place_id: string }[];
  placeName: string;
};

const emptyLocation = {
  address: '',
  placeId: '',
  isPrimary: false,
  entityType: ClientAddressEntity.User,
};

export const AddEditAddress = ({
  addresses,
  setAddresses,
  clientId,
  editAddress,
  handleCancelClick,
  setEditAddress,
  setPoperOverOpen,
  editAddressIndex,
}: Props) => {
  const [isPrimary, setIsPrimary] = useState(false);
  const [newAddress, setNewAddress] = useState<ClientAddressUnion | null>(null);
  // supporting all new updates with cass only
  const isCASS = true;

  const dispatch = useDispatch();

  useEffect(() => {
    if (editAddress) {
      setNewAddress(editAddress);
      setIsPrimary(editAddress.isPrimary);
    } else {
      setIsPrimary(false);
    }
  }, [editAddress]);

  const updateLocation = (isPrimary: boolean, result: LocationResultType, preparedData) => {
    const currentLocation = Array.isArray(result?.result) ? result?.result?.[0] : result?.result;
    let updatedLocation: any = { ...emptyLocation, isPrimary };
    if (
      currentLocation &&
      !addresses.find(
        (location) => (location as ClientAddress).placeId === currentLocation.place_id,
      ) &&
      !isCASS
    ) {
      const { data, success } = createAddress(currentLocation, result);

      if (success) {
        const { city, state, zip, line1, providerPlaceId, placeName } = data!;

        updatedLocation = {
          address: result?.placeName,
          placeId: currentLocation.place_id,
          isPrimary,
          entityType: ClientAddressEntity.User,
          addressParsed: {
            City: city,
            State: state,
            Zip: zip,
            Line1: line1,
            ProviderPlaceId: providerPlaceId,
            PlaceName: placeName,
            Street: preparedData.length && preparedData[0].Street ? preparedData[0].Street : '',
          },
        };
        setNewAddress(updatedLocation);
      }
    } else {
      updatedLocation = {
        address: currentLocation,
        isPrimary,
        neighbourhoodRadius: 2,
        notes: '',
      };
      setNewAddress(updatedLocation);
    }
  };

  const handleSubmit = () => {
    let addressesCopy = [...addresses];
    if (!newAddress) return;
    //Set All other addresses as false to set this one as primary
    if (isPrimary) {
      addressesCopy = addressesCopy.map((address) => ({ ...address, isPrimary: false }));
    }
    if (editAddress) {
      addressesCopy[editAddressIndex] = {
        ...addressesCopy[editAddressIndex],
        ...newAddress,
        isPrimary,
      };
      setAddresses(addressesCopy);
      submitAddressChange(addressesCopy, false);
    } else if (!editAddress) {
      const newAddresses = [...addressesCopy, { ...newAddress, isPrimary }];
      setAddresses(newAddresses);
      submitAddressChange(newAddresses, true);
    }
    setPoperOverOpen(false);
  };

  const handleRemoveAddress = () => {
    const addressCopy = addresses;
    if (addressCopy?.length <= 1) {
      showErrorMessage('At least one address is required');
      return;
    }
    const removedAddress = addressCopy.splice(editAddressIndex, 1)?.[0];
    //If the removed address was primary then set the first address as primary
    if (removedAddress?.isPrimary) {
      addressCopy[0].isPrimary = true;
    }
    setAddresses(addressCopy);
    submitAddressChange(addressCopy, false);
    setPoperOverOpen(false);
  };

  const submitAddressChange = (newAddresses: ClientAddressUnion[], isNew: boolean) => {
    const Client = { Address: newAddresses };
    dispatch(
      updateClientDataEffect({ clientId, Client }, (err) => {
        if (!err) {
          showSuccessMessage(isNew ? 'Address added successfully' : 'Address updated successfully');
        }
      }),
    );
  };

  const selectedValue: any = useMemo(() => {
    if (newAddress && !isCASSAddress(newAddress) && (newAddress as ClientAddress)?.placeId) {
      const addr = newAddress as ClientAddress;
      return [
        {
          PlaceName: addr?.addressParsed?.PlaceName,
          ProviderPlaceId: addr.placeId,
        },
      ];
    }
    if (newAddress?.address) {
      return [newAddress?.address];
    }
    return undefined;
  }, [newAddress]);

  return (
    <div className={styles.addAddressWrapper}>
      <p className={styles.headingWrapper}>{editAddress !== null ? 'Edit' : 'Add'} Address</p>
      <div className={styles.locationWrapper}>
        <Locations
          multiple={false}
          types={['address']}
          variant="full"
          placeholder="Enter address"
          blockClassName={styles.rightBlock}
          onResult={(result, preparedData) => {
            updateLocation(false, result, preparedData);
          }}
          value={selectedValue}
          className={styles.inputFieldLocations}
          testid="address"
          placeholderClassName={styles.locationPlaceholder}
          showPrefixIcon={true}
          searchIconClassName={styles.searchIcon}
          shouldFilterCityLevelResults={isCASS}
          shouldFilterStreetLevelResults={true}
          allowedSmartyAddress={isCASS}
          showOnlyCassAddresses={isCASS}
          setFullCassAddress={isCASS}
        />
      </div>
      <div className={styles.setAsPrimary}>
        <Checkbox
          className={styles.checkbox}
          checkmarkClassName={editAddress?.isPrimary ? styles.checkmark : ''}
          checked={isPrimary}
          onChange={(e) => setIsPrimary((val) => !val)}
          disabled={!!editAddress?.isPrimary}
        />
        <p>Set as primary address</p>
      </div>
      <div className={styles.footer}>
        <div>
          {editAddress ? (
            <div onClick={handleRemoveAddress} className={styles.removeWrapper}>
              <TrashIcon stroke="#FF576D" className={styles.trashIcon} /> Remove
            </div>
          ) : null}
        </div>
        <div className={styles.buttonWrapper}>
          <Button variant="tertiary" className={styles.cancelButton} onClick={handleCancelClick}>
            Cancel
          </Button>
          <Button variant="secondary" className={styles.doneButton} onClick={handleSubmit}>
            Done
          </Button>
        </div>
      </div>
    </div>
  );
};
