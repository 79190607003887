import React from 'react';
import NumberFormat from 'react-number-format';
import { Card } from '..';
import { Icons } from 'pages/Workshop/Icons';

import styles from './styles.module.scss';
import classNames from 'classnames';
import { convertToDecimalIfNotWhole } from 'helpers';

export const NetCard = ({ label, variant, amount, isRestricted, isAdminOrOwner }) => {
  // Hiding the net percentages
  // const validPercentage = typeof percentage === 'number' && !isNaN(percentage) ? percentage : 0;

  const isZeroNetValue = amount === 0;
  const isHideCard = isZeroNetValue || (!isAdminOrOwner && isRestricted);

  if (isHideCard) return null;

  return (
    <Card variant={variant} hideBoxShadow>
      <div className={styles.netCardContainer}>
        <div className={styles.labelContainer}>
          <span className={styles.labelText}>{label}</span>
          {isRestricted && <Icons variant={Icons.RESTRICTED} className={styles.icon} />}
        </div>
        <div className={classNames(styles.amountContainer, styles[variant])}>
          <NumberFormat
            thousandSeparator
            displayType="text"
            value={convertToDecimalIfNotWhole(amount)}
            prefix="$"
            renderText={(val) => <span className={styles.amount}>{val}</span>}
          />
          {/* Hiding the net percentages */}
          {/* <span className={styles.percentage}>({validPercentage}%)</span> */}
        </div>
      </div>
    </Card>
  );
};
