import { useSelector } from 'react-redux';
import {
  Listings,
  Purchases,
  Closed,
  Expired,
  Canceled,
  LeadSources,
  ActiveClients,
  PortfolioClients,
  PortfolioValue,
  PortfolioVolume,
  GCI,
  Fees,
  Expenses,
  AgentSplit,
  TeamSplit,
  BrokerageSplit,
  AgentNetIncome,
  TeamNetIncome,
  BrokerageNetIncome,
} from './ContentTypes';
import { Other } from './ContentTypes/Financials/Other';
import { getExpandChart } from 'store/selectors/clarity';
import { GeneralLedgerKeys } from './ContentTypes/helper';

export const Content = ({ value }) => {
  const expandChart = useSelector(getExpandChart);

  const renderContent = () => {
    switch (value) {
      case 'Listings':
        return <Listings />;
      case 'Purchases':
        return <Purchases />;
      case 'Closed':
        return <Closed />;
      case 'Expired':
        return <Expired />;
      case 'Canceled':
        return <Canceled />;
      case 'Lead Sources':
        return <LeadSources />;
      case 'Active Clients':
        return <ActiveClients />;
      case 'Portfolio Clients':
        return <PortfolioClients />;
      case 'Portfolio Value':
        return <PortfolioValue />;
      case 'Portfolio Volume':
        return <PortfolioVolume />;
      case 'GCI':
        return <GCI />;
      case 'Expenses':
        return <Expenses />;
      case 'Agent Split':
        return <AgentSplit />;
      case 'Team Split':
        return <TeamSplit />;
      case 'Brokerage Split':
        return <BrokerageSplit />;
      case 'Agent Net Income':
        return <AgentNetIncome />;
      case 'Team Net Income':
        return <TeamNetIncome />;
      case 'Brokerage Net Income':
        return <BrokerageNetIncome />;
      default:
        if (expandChart === GeneralLedgerKeys.Fees) return <Fees />;
        else if (
          expandChart === GeneralLedgerKeys.OtherSplits ||
          expandChart === GeneralLedgerKeys.OtherNetIncomes
        )
          return <Other />;
        else return <></>;
    }
  };
  return renderContent();
};
