import moment, { Moment } from 'moment';
import { Title } from 'components-antd';
import { useState, useEffect, ReactNode, useMemo } from 'react';

import classNames from 'classnames';
import { CalendarSimple } from 'components/Icons';
import { Col, DatePicker, Row } from 'components-antd';

import styles from './styles.module.scss';

interface DateRangePickerProps {
  onDatesChange: (from?: Moment, to?: Moment) => void;
  dateRangePickerWrapperClass?: string;
  resetDates?: boolean;
  title?: string;
  futureDisabled?: boolean;
  fromDateValue?: Moment;
  toDateValue?: Moment;
  isDashboardRedirected?: boolean;
  isDueToday?: boolean;
  isDueTomorrow?: boolean;
  isOverDue?: boolean;
  isTotalTasks?: boolean;
  dateIcon?: ReactNode;
  span?: number;
  containerClass?: string;
}

export const DateRangePicker = ({
  onDatesChange,
  dateRangePickerWrapperClass,
  resetDates,
  title = 'Date Range',
  futureDisabled = true,
  fromDateValue,
  toDateValue,
  isDashboardRedirected,
  isDueToday,
  isDueTomorrow,
  isOverDue,
  isTotalTasks,
  dateIcon = <CalendarSimple className={styles.icon} />,
  span = 11,
  containerClass,
}: DateRangePickerProps) => {
  const [fromDate, setFromDate] = useState(fromDateValue);
  const [toDate, setToDate] = useState(toDateValue);

  useEffect(() => {
    setFromDate(fromDateValue);
    setToDate(toDateValue);
  }, [fromDateValue, toDateValue]);

  useMemo(() => {
    onDatesChange(fromDate, toDate);
  }, [fromDate, toDate]);

  useEffect(() => {
    resetDates && resetDatesHandler();
  }, [resetDates]);

  const resetDatesHandler = () => {
    setFromDate(undefined);
    setToDate(undefined);
  };

  useEffect(() => {
    if (isDashboardRedirected) {
      const today = moment();
      const tomorrow = moment().add(1, 'day');
      const yesterday = moment().add(-1, 'day');
      if (isDueToday) {
        setFromDate(today);
        setToDate(today);
      } else if (isDueTomorrow) {
        setFromDate(tomorrow);
        setToDate(tomorrow);
      } else if (isOverDue) {
        setFromDate(undefined);
        setToDate(yesterday);
      } else if (isTotalTasks) {
        setFromDate(undefined);
        setToDate(tomorrow);
      }
    }
  }, []);

  return (
    <div
      className={classNames(
        styles.dateRangePickerWrapper,
        dateRangePickerWrapperClass ? dateRangePickerWrapperClass : '',
      )}
    >
      <Title level={4}>{title}</Title>
      <div className={classNames(styles.colContainer, containerClass)}>
        <Col span={span}>
          <DatePicker
            className={classNames(styles.datepicker)}
            format={'MM/DD/YYYY'}
            disabledDate={(current) =>
              toDate
                ? current.isAfter(toDate)
                : futureDisabled && moment(current).isAfter(moment(new Date()))
            }
            value={fromDate}
            onChange={(_from: any) => {
              setFromDate(_from);
              if (toDate && _from?.isAfter(toDate)) {
                setToDate(_from);
              }
            }}
            suffixIcon={dateIcon}
          />
        </Col>
        <Col span={2} className={classNames(styles.dateRangeToText)}>
          To
        </Col>
        <Col span={span}>
          <DatePicker
            className={classNames(styles.datepicker)}
            format={'MM/DD/YYYY'}
            disabledDate={(current) =>
              (fromDate && current.isBefore(fromDate)) ||
              (futureDisabled && moment(current).isAfter(moment(new Date())))
            }
            value={toDate}
            onChange={(_to: any) => {
              setToDate(_to);
            }}
            suffixIcon={dateIcon}
          />
        </Col>
      </div>
    </div>
  );
};
