import moment from 'moment';

const formatPrice = (price) => {
  return parseFloat(price.replace(/[$,]/g, ''));
};

const isNone = (value) => value === 'None';

const getBuyerName = (buyerName) => {
  if (isNone(buyerName)) return '';

  if (buyerName.includes(' and ')) {
    return buyerName.trim().split(' and ');
  }
  return buyerName.trim().split(', ');
};

export const prepareOfferValues = (fileDetails, offerFormData) => {
  const {
    'Buyer Agent Name': buyingAgentName,
    'Buyer Brokerage': buyerBrokerage,
    'Buyer Name': buyerName,
    'Buyer Email': email,
    'Buyer Phone': phone,
    'Purchase Price': purchasePrice,
    'Closing Date': closingDate,
    'Financing Type': financingType,
    'Initial Deposit': downPayment,
    'Earnest Amount': earnestMoney,
    Inspections: inspection,
    'Termite Inspection': termiteInspection,
    'Home Warranty': homeWarranty,
    Appliances: appliances,
    'Post-closing occupancy': occupancy,
  } = fileDetails;

  const [buyer1, buyer2] = getBuyerName(buyerName);
  const { OfferIteration, ...restData } = offerFormData;
  const isValidDate = moment(closingDate).isValid();

  offerFormData = {
    ...restData,
    ...(buyer1 && { Buyer: [buyer1] }),
    ...(buyer2 && { Buyer2: [buyer2] }),

    BuyingAgentEmail: !isNone(email) ? email : '',
    BuyingAgentPhone: phone?.includes('-') ? phone.replaceAll('-', '') : '',
    BuyingAgentName: !isNone(buyingAgentName) ? buyingAgentName : '',
    BuyingAgentBrokerage: !isNone(buyerBrokerage) ? buyerBrokerage : '',

    OfferIteration: {
      ...OfferIteration,
      PurchasePrice: !isNone(purchasePrice) ? formatPrice(purchasePrice) : null,
      DownPayment: !isNone(downPayment) ? formatPrice(downPayment) : null,
      EarnestMoney: !isNone(earnestMoney) ? formatPrice(earnestMoney) : null,

      FinancingType: !isNone(financingType) ? financingType : null,
      ClosingDate: isValidDate ? moment(closingDate) : null,

      Contingencies: {
        ...OfferIteration.Contingencies,
        ...(!isNone(inspection) && {
          Inspection: {
            selected: true,
            days: isNaN(inspection) ? null : +inspection,
          },
        }),
      },

      TermiteInspection: termiteInspection === 'Yes',
      HomeWarranty: homeWarranty === 'Yes',
      Appliances: !isNone(appliances) ? appliances : null,
      Occupancy: occupancy === 'Yes',
    },
  };

  return offerFormData;
};
