import { useRef, useEffect, useCallback, useState, useMemo } from 'react';
import Space from 'antd/lib/space';
import moment from 'moment';
import classnames from 'classnames';
import { Col, Menu, Popover, Row } from 'components-antd';
import { EyeOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import { useReactToPrint } from 'react-to-print';
import { MenuProps } from 'antd';

import { Icons } from 'pages/Workshop/Icons';
import { Icons as IconOffers } from 'pages/Workshop/Offers/icons';
import { CompareOfferTooltip } from './CompareOfferTooltip';
import { useSelector, useDispatch } from 'react-redux';
import { getSelectedOffersForComparison } from 'store/selectors/offers';

import { OfferDetailsModal } from '../OfferDetailsModal';
import { setSelectedOffersForComparison } from 'store/effects/offers';
import { getCompareOffers } from 'api/offers';

import { getTransaction } from 'api/transactions';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';

import fileDownload from 'js-file-download';
import { CompareOffersModal } from 'pages/Workshop/Transactions/TransactionOffers/CompareOffersModal';

import styles from './styles.module.scss';
import GoBack from 'components/Back';
import { ArrowRight } from 'components/Icons';
import { getDateTime } from 'helpers';
import { LocalStorage } from 'services';
import { previewDocumentEffect } from 'store/effects/transactions';
import { TokenTypes } from 'app-constants';
import classNames from 'classnames';
import { getTransactionPrice } from 'utils';

export const CompareOffer = (props) => {
  const { transactionId } = props.match.params;
  const csvRef = useRef<any>();
  const pdfRef = useRef<any>();
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedOffers = useSelector(getSelectedOffersForComparison);
  const [Offers, setOffers]: any = useState([]);
  const [csvData, setCsvData]: any = useState([]);
  const [offerComparisonData, setOffersForComparison]: any = useState({});
  const [showBuyer, setShowBuyer] = useState(true);

  const [offerData, setOfferData]: any = useState();

  const [transaction, setTransaction]: any = useState({});
  const [offerCompareModalIsOpen, setOfferCompareModalIsOpen] = useState(false);
  const [responsiveColumns, setResponsiveColumns] = useState(3);
  const [numColumns, setNumColumns] = useState(3);
  const [mainColumnWidth, setMainColumnWidth] = useState(3);
  const [openMenuIndex, setOpenMenuIndex] = useState(-1);

  const [offerDetailsModal, setOfferDetailsModal] = useState(false);

  const onDetailsClose = () => {
    setOfferDetailsModal(false);
  };

  const remainingSpace =
    Offers.length < 4 ? 24 - Math.ceil(24 / responsiveColumns) * Offers.length : 0;
  const offset = Offers.length === 2 ? 6 : Math.floor(remainingSpace / 2);

  useEffect(() => {
    if (Object.entries(selectedOffers.selectedOffers).length === 0) {
      history.push(`/workshop/transactions/${transactionId}/offers`);
    }
    setOffers(selectedOffers);
    let sOffers: any = [];
    Object.entries(selectedOffers.selectedOffers).forEach((entry) => {
      const [key, value] = entry;
      sOffers.push(value);
    });
    setOffers(sOffers);
    fetchTransactionById(transactionId, sOffers);
    fetchOffersForComparison(transactionId, sOffers);
  }, [transactionId, selectedOffers, showBuyer]);

  const fetchTransactionById = async (transactionId, sOffers) => {
    let { data } = await getTransaction({ id: transactionId });
    setTransaction(data.result);
    getCsvData(sOffers, data.result);
  };

  const fetchOffersForComparison = useCallback(async (TransactionId, sOffers) => {
    const { data } = await getCompareOffers({ transactionId: TransactionId });
    if (data?.value) {
      const { Pending, Rejected, Withdrawn } = data.value;
      alreadySelectedOffers(Pending, Rejected, Withdrawn, sOffers);
      setOffersForComparison({ Pending, Rejected, Withdrawn });
    }
  }, []);

  const price = useMemo(() => {
    const keyDatesPrices = transaction?.PropertyTransactionKeyDatesPrices;
    return getTransactionPrice(transaction?.Status, keyDatesPrices);
  }, [transaction]);

  const alreadySelectedOffers = (Pending, Rejected, Withdrawn, sOffers) => {
    sOffers.forEach((element) => {
      Pending.forEach((pOffer) => {
        if (pOffer.UUID === element.UUID) {
          pOffer.selected = true;
        } else if (pOffer.OfferIteration.length > 1) {
          pOffer.OfferIteration.forEach((pIteration) => {
            if (pIteration.UUID === element.UUID) {
              pIteration.selected = true;
            }
          });
        }
      });
      Rejected.forEach((rOffer) => {
        if (rOffer.UUID === element.UUID) {
          rOffer.selected = true;
        } else if (rOffer.OfferIteration.length > 1) {
          rOffer.OfferIteration.forEach((rIteration) => {
            if (rIteration.UUID === element.UUID) {
              rIteration.selected = true;
            }
          });
        }
      });
      Withdrawn.forEach((wOffer) => {
        if (wOffer.UUID === element.UUID) {
          wOffer.selected = true;
        } else if (wOffer.OfferIteration.length > 1) {
          wOffer.OfferIteration.forEach((wIteration) => {
            if (wIteration.UUID === element.UUID) {
              wIteration.selected = true;
            }
          });
        }
      });
    });
  };

  const handleOfferClick = (e, index: number) => {
    setOpenMenuIndex(-1);

    if (e.key === '1') {
      setOfferData({
        ...Offers?.[index]?.OfferIteration[0],
        OfferId: +Offers?.[index]?.Id,
        OfferUUID: Offers?.[index]?.UUID,
        count: Offers?.[index]?.OfferCount,
        OfferCount: Offers?.[index]?.OfferCount,
      });
      setOfferDetailsModal(true);
    } else if (e.key === '2') {
      let sOffers = Offers;
      sOffers.splice(index, 1);
      setOffers([...sOffers]);
      getCsvData([...sOffers], transaction);
      dispatch(setSelectedOffersForComparison(sOffers));
    }
  };

  const handleMenuClick: MenuProps['onClick'] = (e) => {};

  const pageStyle = `
  @page {
    size: A4 landscape;
    margin: 0;
  }

  @media all {
    .offers {
      .header .pageTitle{
        font-size: 20px !important;
      }
      .compareTable {
        font-size: 12px !important;
      }
    }
  }

  @media print {
    .offers {
      .header .pageTitle{
        font-size: 20px !important;
      }
      .compareTable {
        font-size: 12px !important;
      }
    }
    .arrowRight{
      display:none !important;
    }
  }
`;
  const handlePrint = useReactToPrint({
    content: () => pdfRef.current,
    pageStyle: pageStyle,
    copyStyles: true,
    documentTitle: 'Offers_Comparison.pdf',
  });

  const renderAgentNames = (agents) => {
    if (agents?.length > 0) {
      return agents.map((item, idx) => {
        return (
          <span key={idx} className={styles.seperator}>
            {item}
          </span>
        );
      });
    } else {
      return '-';
    }
  };

  const onDownload = (document) => {
    const token = LocalStorage.getExternalToken();

    dispatch(
      previewDocumentEffect(
        { DocumentVaultUUID: document.DocumentVaultUUID, token, type: TokenTypes.Offer_Iteration },
        {},
        (err, response) => {
          if (err) {
            return;
          }
          fileDownload(response.data, response.headers['file-name']);
        },
      ),
    );
  };

  const getCsvData = (offers, transaction) => {
    let csvData: any = [];
    const kdp = transaction?.PropertyTransactionKeyDatesPrices;
    const transactionPrice = getTransactionPrice(transaction?.Status, kdp);
    offers?.forEach((offer) => {
      csvData.push({
        'Offer #':
          'Offer #' +
          (offer?.OfferIteration
            ? offer?.OfferIteration[0].IterationCount > 0
              ? offer?.OfferCount + '-' + offer?.OfferIteration[0].IterationCount
              : offer?.OfferCount
            : offer?.IterationCount > 0
            ? offer?.OfferCount + '-' + offer?.IterationCount
            : offer?.OfferCount),
        Agent: offer.BuyingAgentName,
        ...(showBuyer && offer?.Buyer && { Buyer: offer?.Buyer[0] }),
        Status:
          offer.OfferIteration && offer.OfferIteration.length
            ? offer.OfferIteration[0].IterationStatus
            : offer.IterationStatus,
        'Offer Price':
          offer.OfferIteration && offer.OfferIteration.length
            ? offer.OfferIteration[0].PurchasePrice
            : offer.PurchasePrice,

        Variance:
          offer.OfferIteration && offer.OfferIteration.length
            ? Number(offer.OfferIteration[0]?.PurchasePrice) - Number(transactionPrice)
            : Number(offer?.PurchasePrice) - Number(transactionPrice),
        'Down Payment':
          offer.OfferIteration && offer.OfferIteration.length
            ? Number(offer.OfferIteration[0]?.DownPayment)
            : Number(offer?.DownPayment),
        'Earnest Money Deposit':
          offer.OfferIteration && offer.OfferIteration.length
            ? Number(offer.OfferIteration[0]?.EarnestMoneyDeposit)
            : Number(offer?.EarnestMoneyDeposit),
        'Financing Type':
          offer.OfferIteration && offer.OfferIteration.length
            ? offer.OfferIteration[0]?.FinancingType
            : offer?.FinancingType,
        'Date Submitted': moment(
          offer.OfferIteration && offer.OfferIteration.length
            ? offer.OfferIteration[0].CreatedDate
            : offer.CreatedDate,
        ).format('L'),
        'Expiration Date':
          offer.OfferIteration && offer.OfferIteration.length
            ? !offer.OfferIteration[0].OfferExpiryIndefinitely
              ? getDateTime(offer.OfferIteration[0].OfferExpiryEndDate)
              : 'none'
            : !offer.OfferExpiryIndefinitely
            ? getDateTime(offer.OfferExpiryEndDate)
            : 'none',
        Contingencies: (offer.OfferIteration && offer.OfferIteration.length
          ? renderContingencies(offer.OfferIteration[0].Contingencies)
          : renderContingencies(offer.Contingencies)
        ).trim(),
        'Termite Inspection':
          offer?.OfferIteration &&
          offer?.OfferIteration[0]?.TermiteInspection &&
          offer?.OfferIteration[0]?.TermiteInspectionResponsibility
            ? 'Yes (Responsible Party: ' +
              offer?.OfferIteration[0]?.TermiteInspectionResponsibility +
              ')'
            : 'No',
        'Home Warranty':
          offer?.OfferIteration &&
          offer.OfferIteration[0].HomeWarranty &&
          offer.OfferIteration[0].HomeWarrantyCost !== null
            ? 'Yes ($' +
              offer.OfferIteration[0].HomeWarrantyCost +
              ' / Responsible Party: ' +
              offer.OfferIteration[0].HomeWarrantyResponsibility +
              ')'
            : 'No',
        'Appliances ':
          offer?.OfferIteration && offer.OfferIteration[0].Appliances
            ? offer.OfferIteration[0].Appliances
            : '-',
        'Post-Closing Occupancy/SIP':
          offer?.OfferIteration &&
          offer.OfferIteration[0].Occupancy &&
          offer.OfferIteration[0].OccupancyDays !== null
            ? 'Yes (' + offer.OfferIteration[0].OccupancyDays + ' Days)'
            : 'No',
        Documents: renderDocsName(
          offer.OfferIteration && offer.OfferIteration.length > 0
            ? offer.OfferIteration[0].OfferIterationDocument
            : offer.OfferIterationDocument,
        ).trim(),
      });
    });
    setCsvData(csvData);
  };

  const renderDocsName = (documents) => {
    let docs = '';
    if (documents?.length > 0) {
      documents.forEach((element, idx) => {
        docs += `${element.DocumentName}`;
        docs += idx + 1 < documents?.length ? ', ' : '';
      });
      return docs;
    } else {
      return '-';
    }
  };

  const renderDocuments = (documents) => {
    if (documents?.length > 0) {
      return documents.map((item, idx) => {
        return (
          <span key={idx} className={styles.document}>
            <a
              onClick={() => {
                onDownload(item);
              }}
            >
              {item.DocumentName}
            </a>
          </span>
        );
      });
    } else {
      return '-';
    }
  };

  const menu = (
    <Menu
      onClick={handleMenuClick}
      className={styles.offerMenu}
      items={[
        {
          label: (
            <div className={styles.menuIcon}>
              <IconOffers variant={'user'} /> <span>Share With Seller</span>
            </div>
          ),
          key: '1',
        },
        {
          label: (
            <div className={styles.menuIcon}>
              <IconOffers variant={'document'} /> <span onClick={handlePrint}>Download PDF</span>
            </div>
          ),
          key: '2',
        },
        {
          label: (
            <div className={styles.menuIcon}>
              <IconOffers variant={'document'} />
              <CSVLink filename={'Offers_Comparison.csv'} data={csvData}>
                Download CSV
              </CSVLink>
            </div>
          ),
          key: '3',
        },
      ]}
    />
  );

  const offerOptions = (index) => {
    return (
      <Menu
        onClick={(e) => handleOfferClick(e, index)}
        className={styles.offerMenu}
        items={[
          {
            label: (
              <div className={styles.menuIcon}>
                <IconOffers variant={'book'} /> <span>View Details</span>
              </div>
            ),
            key: '1',
          },
          ...(Offers.length > 2
            ? [
                {
                  label: (
                    <div className={styles.menuIcon}>
                      <IconOffers variant={'delete'} /> <span>Remove from Comparison</span>
                    </div>
                  ),
                  key: '2',
                },
              ]
            : []),
        ]}
      />
    );
  };

  const renderContingencies = (contingencies) => {
    if (contingencies) {
      return (
        <>
          {`Inspection:${
            contingencies.Inspection.days ? `(${contingencies.Inspection.days}d)` : 'WAIVED'
          }`}

          <br />

          {`Appraisal: ${
            contingencies.Appraisal.days ? `(${contingencies.Appraisal.days}d)` : 'WAIVED'
          }`}

          <br />

          {`Financing: ${
            contingencies.Financing.days ? `(${contingencies.Financing.days}d)` : 'WAIVED'
          }`}
        </>
      );
    }
    return '-';
  };

  const renderActions = () => {
    return (
      <div className={styles.actions}>
        <div className={styles.iconTxtBtn} onClick={() => setOfferCompareModalIsOpen(true)}>
          <IconOffers variant={'add'} />
          Add Offer
        </div>

        <div
          className={`${styles.iconBtn} ${showBuyer ? '' : 'no-border'}`}
          onClick={() => {
            setShowBuyer(!showBuyer);
          }}
        >
          {showBuyer ? (
            <EyeOutlined className={styles.eyeOut} />
          ) : (
            <span className={styles.eyeIconInvisible}>
              <IconOffers variant={'eyeInvisibleCircleOutlined'} />
            </span>
          )}{' '}
        </div>

        <Popover
          content={menu}
          trigger="click"
          placement="bottomLeft"
          overlayClassName={classnames(styles.compareOfferPopover)}
        >
          <div className={styles.iconBtn}>
            <IconOffers className={styles.uploadIcon} variant={'upload'} />
          </div>
        </Popover>
      </div>
    );
  };

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const length = Object.entries(selectedOffers.selectedOffers).length;
      let newNumColumns = screenWidth >= 1024 ? 7 : screenWidth >= 768 ? 3 : 2;
      setResponsiveColumns(newNumColumns);

      if (newNumColumns === 7 && length < 7 && screenWidth > 768) {
        newNumColumns = 5;
      }
      setResponsiveColumns(newNumColumns);
      setNumColumns(newNumColumns);
      setMainColumnWidth(newNumColumns === 2 ? 5 : 4);
      setNumColumns(Math.ceil(20 / newNumColumns));
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div
        ref={pdfRef}
        onClick={(e) => {
          setOpenMenuIndex(-1);
        }}
      >
        <div className={styles.offers}>
          <div className={styles.header}>
            <Row className={styles.headerTopRow}>
              <Col span={6}>
                <GoBack
                  showText={false}
                  className={styles.backBtn}
                  iconClassName={styles.icon}
                  onBack={() => history.goBack()}
                />
              </Col>
              <Col span={12}>
                <div className={styles.pageTitleAlign}>
                  <h2 className={styles.pageTitle}>{transaction?.Property?.Address?.Line1}</h2>
                </div>
              </Col>
              <Col span={6} className={styles.mobileCenter}>
                <span className={styles.mobile}>
                  <Popover
                    placement="bottomLeft"
                    content={renderActions()}
                    trigger="click"
                    key={'actions'}
                    getPopupContainer={(trigger) => trigger}
                    overlayClassName={classnames(
                      styles.compareOfferPopover,
                      styles.compareOfferOptionPopover,
                    )}
                  >
                    <Space>
                      <Icons variant={Icons.DOTSWHITE} svgClass={styles.mainActions} />
                    </Space>
                  </Popover>
                </span>
                <span className={styles.desktop}>{renderActions()}</span>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <p className={styles.pageSubTitle}>
                  Offer Comparison
                  <CompareOfferTooltip price={price} client={transaction?.Creator?.FirstName} />
                </p>
              </Col>
            </Row>
          </div>

          <div className={styles.compareTableParent}>
            <div
              className={classNames(styles.compareTable, {
                [styles.overflowCompareTable]: Offers.length > 4,
              })}
              ref={csvRef}
            >
              <Row className={styles.cTRow} gutter={[16, 16]}>
                <Col offset={offset}></Col>
                <Col className={styles.cTCol} span={mainColumnWidth}>
                  Offer #
                </Col>
                {Offers.map((offer, index) => (
                  <Col className={styles.cTCol} span={numColumns} key={`offer-title-${index}`}>
                    <Row>
                      <Col span={20}>
                        <p>
                          Offer #
                          {offer.OfferIteration
                            ? offer.OfferIteration[0].IterationCount > 0
                              ? offer.OfferCount + '-' + offer?.OfferIteration[0].IterationCount
                              : offer?.OfferCount
                            : offer?.IterationCount > 0
                            ? offer?.OfferCount + '-' + offer?.IterationCount
                            : offer?.OfferCount}
                        </p>
                      </Col>
                      <Col span={4}>
                        <Popover
                          content={offerOptions(index)}
                          trigger="click"
                          placement="bottomLeft"
                          key={'offer1'}
                          getPopupContainer={(trigger) => trigger}
                          overlayClassName={classnames(
                            styles.compareOfferPopover,
                            styles.compareOfferOptionPopover,
                          )}
                          open={openMenuIndex === index}
                          onOpenChange={(visible) => !visible && setOpenMenuIndex(-1)}
                        >
                          <Space
                            onClick={(e) => {
                              e.stopPropagation();
                              setOpenMenuIndex(index);
                            }}
                          >
                            <Icons variant={Icons.DOTS} className={styles.actionBtn} />
                          </Space>
                        </Popover>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>

              <Row className={styles.cTRow} gutter={[16, 16]}>
                <Col offset={offset}></Col>
                <Col className={styles.cTCol} span={mainColumnWidth}>
                  <span className={styles.contentBold}> Agent</span>
                </Col>
                {Offers.map((offer, index) => (
                  <Col className={styles.cTCol} span={numColumns} key={`offer-agent-${index}`}>
                    <span className={styles.contentBold}>
                      {offer?.BuyingAgentName ? offer?.BuyingAgentName : offer?.BuyingAgent?.Name}
                    </span>
                  </Col>
                ))}
              </Row>
              {showBuyer && (
                <Row className={styles.cTRow} gutter={[16, 16]}>
                  <Col offset={offset}></Col>
                  <Col className={styles.cTCol} span={mainColumnWidth}>
                    <span className={styles.contentBold}> Buyer</span>
                  </Col>
                  {Offers.map((offer, index) => (
                    <Col className={styles.cTCol} span={numColumns} key={`offer-buyer-${index}`}>
                      <span className={styles.contentBold}> {renderAgentNames(offer?.Buyer)}</span>
                    </Col>
                  ))}
                </Row>
              )}
              <Row className={styles.cTRow} gutter={[16, 16]}>
                <Col offset={offset}></Col>
                <Col className={styles.cTCol} span={mainColumnWidth}>
                  <span className={styles.contentBold}> Status</span>
                </Col>
                {Offers.map((offer, index) => (
                  <Col
                    className={classnames(styles.cTCol, styles.status)}
                    span={numColumns}
                    key={`offer-status-${index}`}
                  >
                    {offer?.OfferIteration
                      ? offer?.OfferIteration[0]?.IterationStatus
                      : offer?.IterationStatus}
                  </Col>
                ))}
              </Row>
              <Row className={styles.cTRow} gutter={[16, 16]}>
                <Col offset={offset}></Col>
                <Col className={styles.cTCol} span={mainColumnWidth}>
                  <span className={styles.contentBold}> Offer Price</span>
                </Col>
                {Offers?.map((offer, index) => (
                  <Col className={styles.cTCol} span={numColumns} key={`offer-price-${index}`}>
                    <span className={styles.contentBold}>
                      <NumberFormat
                        thousandSeparator
                        displayType="text"
                        value={
                          offer?.OfferIteration
                            ? offer?.OfferIteration[0]?.PurchasePrice
                            : offer?.PurchasePrice
                        }
                        prefix="$"
                        renderText={(val) => <span testid="property_price">{val}</span>}
                      />
                    </span>
                  </Col>
                ))}
              </Row>
              <Row className={styles.cTRow} gutter={[16, 16]}>
                <Col offset={offset}></Col>
                <Col className={classnames(styles.cTCol, {})} span={mainColumnWidth}>
                  <span className={styles.contentBold}>Variance</span>
                </Col>
                {Offers?.map((offer, index) => (
                  <Col
                    className={classnames(styles.cTCol, {})}
                    span={numColumns}
                    key={`offer-variance-${index}`}
                  >
                    <span className={styles.content}>
                      <NumberFormat
                        thousandSeparator
                        displayType="text"
                        value={
                          offer?.OfferIteration
                            ? Number(offer?.OfferIteration[0]?.PurchasePrice) - Number(price)
                            : Number(offer?.PurchasePrice) - Number(price)
                        }
                        prefix="$"
                        renderText={(val) => (
                          <span
                            className={
                              +val?.replace(/[$,]/g, '') > 0 ? styles.positive : styles.negative
                            }
                            testid="property_price"
                          >
                            {val}
                          </span>
                        )}
                      />
                    </span>
                  </Col>
                ))}
              </Row>
              <Row className={styles.cTRow} gutter={[16, 16]}>
                <Col offset={offset}></Col>
                <Col className={classnames(styles.cTCol, {})} span={mainColumnWidth}>
                  <span className={styles.contentBold}>Down Payment</span>
                </Col>
                {Offers?.map((offer, index) => (
                  <Col
                    className={classnames(styles.cTCol, {})}
                    span={numColumns}
                    key={`offer-down-payment-${index}`}
                  >
                    <span className={styles.content}>
                      <NumberFormat
                        thousandSeparator
                        displayType="text"
                        value={
                          offer?.OfferIteration
                            ? Number(offer?.OfferIteration[0]?.DownPayment)
                            : Number(offer?.DownPayment)
                        }
                        prefix="$"
                        renderText={(val) => (
                          <span
                            // className={
                            //   +val?.replace(/[$,]/g, '') > 0 ? styles.positive : styles.negative
                            // }
                            testid="property_price"
                          >
                            {val}
                          </span>
                        )}
                      />
                    </span>
                  </Col>
                ))}
              </Row>
              {/* bottom is done */}
              <Row className={styles.cTRow} gutter={[16, 16]}>
                <Col offset={offset}></Col>
                <Col className={classnames(styles.cTCol, {})} span={mainColumnWidth}>
                  <span className={styles.contentBold}>Earnest Money Deposit</span>
                </Col>
                {Offers?.map((offer, index) => (
                  <Col
                    className={classnames(styles.cTCol, {})}
                    span={numColumns}
                    key={`offer-money-deposit-${index}`}
                  >
                    <span className={styles.content}>
                      <NumberFormat
                        thousandSeparator
                        displayType="text"
                        value={
                          offer?.OfferIteration
                            ? Number(offer?.OfferIteration[0]?.EarnestMoneyDeposit)
                            : Number(offer?.EarnestMoneyDeposit)
                        }
                        prefix="$"
                        renderText={(val) => (
                          <span
                            // className={
                            //   +val?.replace(/[$,]/g, '') > 0 ? styles.positive : styles.negative
                            // }
                            testid="property_price"
                          >
                            {val}
                          </span>
                        )}
                      />
                    </span>
                  </Col>
                ))}
              </Row>
              <Row className={styles.cTRow} gutter={[16, 16]}>
                <Col offset={offset}></Col>
                <Col className={classnames(styles.cTCol, {})} span={mainColumnWidth}>
                  <span className={styles.contentBold}>Financing Type</span>
                </Col>
                {Offers?.map((offer, index) => (
                  <Col
                    className={classnames(styles.cTCol, {})}
                    span={numColumns}
                    key={`offer-financing-type-${index}`}
                  >
                    <span className={styles.content}>
                      {(offer?.OfferIteration
                        ? offer?.OfferIteration[0]?.FinancingType
                        : offer?.FinancingType) || '-'}
                    </span>
                  </Col>
                ))}
              </Row>
              <Row className={styles.cTRow} gutter={[16, 16]}>
                <Col offset={offset}></Col>
                <Col className={styles.cTCol} span={mainColumnWidth}>
                  <span className={styles.contentBold}> Date Submitted</span>
                </Col>
                {Offers?.map((offer, index) => (
                  <Col
                    className={styles.cTCol}
                    span={numColumns}
                    key={`offer-date-submitted-${index}`}
                  >
                    <span className={styles.content}>
                      {moment(
                        offer?.OfferIteration
                          ? offer?.OfferIteration[0].CreatedDate
                          : offer.CreatedDate,
                      ).format('L')}
                    </span>
                  </Col>
                ))}
              </Row>
              <Row className={styles.cTRow} gutter={[16, 16]}>
                <Col offset={offset}></Col>
                <Col className={styles.cTCol} span={mainColumnWidth}>
                  <span className={styles.contentBold}> Closing Date</span>
                </Col>
                {Offers?.map((offer, index) => (
                  <Col
                    className={styles.cTCol}
                    span={numColumns}
                    key={`offer-date-submitted-${index}`}
                  >
                    <span className={styles.content}>
                      {offer?.OfferIteration && offer?.OfferIteration[0].ClosingDate
                        ? moment(offer?.OfferIteration[0].ClosingDate).format('L')
                        : 'None'}
                    </span>
                  </Col>
                ))}
              </Row>

              <Row className={styles.cTRow} gutter={[16, 16]}>
                <Col offset={offset}></Col>
                <Col className={styles.cTCol} span={mainColumnWidth}>
                  <span className={styles.contentBold}> Expiration Date</span>
                </Col>
                {Offers &&
                  Offers?.map((offer, index) => (
                    <Col
                      className={styles.cTCol}
                      span={numColumns}
                      key={`offer-expiration-date-${index}`}
                    >
                      <span className={styles.content}>
                        {offer && offer?.OfferIteration && Offers?.[0]?.OfferIteration?.length
                          ? offer?.OfferIteration[0]?.OfferExpiryIndefinitely
                            ? 'None'
                            : getDateTime(offer?.OfferIteration[0]?.OfferExpiryEndDate)
                          : offer?.OfferExpiryIndefinitely
                          ? 'None'
                          : getDateTime(offer.OfferExpiryEndDate)}
                      </span>
                    </Col>
                  ))}
              </Row>
              <Row className={styles.cTRow} gutter={[16, 16]}>
                <Col offset={offset}></Col>
                <Col className={styles.cTCol} span={mainColumnWidth}>
                  <span className={styles.contentBold}> Contingencies</span>
                </Col>
                {Offers?.map((offer, index) => (
                  <Col
                    className={styles.cTCol}
                    span={numColumns}
                    key={`offer-contingencies-${index}`}
                  >
                    <span className={styles.content}>
                      {renderContingencies(
                        offer?.OfferIteration && offer.OfferIteration.length
                          ? offer?.OfferIteration[0].Contingencies
                          : offer.Contingencies,
                      )}
                    </span>
                  </Col>
                ))}
              </Row>
              <Row className={styles.cTRow} gutter={[16, 16]}>
                <Col offset={offset}></Col>
                <Col className={classnames(styles.cTCol, {})} span={mainColumnWidth}>
                  <span className={styles.contentBold}>Termite Inspection</span>
                </Col>
                {Offers?.map((offer, index) => (
                  <Col
                    className={classnames(styles.cTCol, {})}
                    span={numColumns}
                    key={`offer-termite-inspection-${index}`}
                  >
                    <span className={styles.content}>
                      {offer?.OfferIteration
                        ? offer?.OfferIteration[0].TermiteInspection &&
                          offer?.OfferIteration[0].TermiteInspectionResponsibility
                          ? 'Yes (Responsible Party: ' +
                            offer?.OfferIteration[0].TermiteInspectionResponsibility +
                            ')'
                          : 'No'
                        : offer?.TermiteInspection && offer?.TermiteInspectionResponsibility
                        ? 'Yes (Responsible Party: ' + offer?.TermiteInspectionResponsibility + ')'
                        : 'No'}
                    </span>
                  </Col>
                ))}
              </Row>
              <Row className={styles.cTRow} gutter={[16, 16]}>
                <Col offset={offset}></Col>
                <Col className={classnames(styles.cTCol, {})} span={mainColumnWidth}>
                  <span className={styles.contentBold}>Home Warranty</span>
                </Col>
                {Offers?.map((offer, index) => (
                  <Col
                    className={classnames(styles.cTCol, {})}
                    span={numColumns}
                    key={`offer-home-warranty-${index}`}
                  >
                    <span className={styles.content}>
                      {offer?.OfferIteration
                        ? offer?.OfferIteration[0].HomeWarranty &&
                          offer?.OfferIteration[0].HomeWarrantyCost &&
                          offer?.OfferIteration[0].HomeWarrantyResponsibility
                          ? 'Yes ($' +
                            offer?.OfferIteration[0].HomeWarrantyCost +
                            ' / Responsible Party: ' +
                            offer?.OfferIteration[0].HomeWarrantyResponsibility +
                            ')'
                          : 'No'
                        : offer?.HomeWarranty &&
                          offer?.HomeWarrantyCost &&
                          offer?.HomeWarrantyResponsibility
                        ? 'Yes ($' +
                          offer?.HomeWarrantyCost +
                          ' / Responsible Party: ' +
                          offer?.HomeWarrantyResponsibility +
                          ')'
                        : 'No'}
                    </span>
                  </Col>
                ))}
              </Row>
              <Row className={styles.cTRow} gutter={[16, 16]}>
                <Col offset={offset}></Col>
                <Col className={classnames(styles.cTCol, {})} span={mainColumnWidth}>
                  <span className={styles.contentBold}>Appliances</span>
                </Col>
                {Offers?.map((offer, index) => (
                  <Col
                    className={classnames(styles.cTCol, {})}
                    span={numColumns}
                    key={`offer-appliances-${index}`}
                  >
                    <span className={styles.content}>
                      {offer?.OfferIteration
                        ? offer?.OfferIteration[0].Appliances
                          ? offer?.OfferIteration[0].Appliances
                          : '-'
                        : offer?.Appliances
                        ? offer?.Appliances
                        : '-'}
                    </span>
                  </Col>
                ))}
              </Row>
              <Row className={styles.cTRow} gutter={[16, 16]}>
                <Col offset={offset}></Col>
                <Col className={classnames(styles.cTCol, {})} span={mainColumnWidth}>
                  <span className={styles.contentBold}>Post-Closing Occupancy/SIP</span>
                </Col>
                {Offers?.map((offer, index) => (
                  <Col
                    className={classnames(styles.cTCol, {})}
                    span={numColumns}
                    key={`offer-sip-${index}`}
                  >
                    <span className={styles.content}>
                      {offer?.OfferIteration
                        ? offer?.OfferIteration[0].Occupancy &&
                          offer?.OfferIteration[0].OccupancyDays
                          ? 'Yes (' + offer?.OfferIteration[0].OccupancyDays + ' Days)'
                          : 'No'
                        : offer?.Occupancy && offer?.OccupancyDays
                        ? 'Yes (' + offer?.OccupancyDays + ' Days)'
                        : 'No'}
                    </span>
                  </Col>
                ))}
              </Row>
              <Row className={styles.cTRow} gutter={[16, 16]}>
                <Col offset={offset}></Col>
                <Col className={styles.cTCol} span={mainColumnWidth}>
                  <span className={styles.contentBold}> Documents</span>
                </Col>
                {Offers?.map((offer, index) => (
                  <Col className={styles.cTCol} span={numColumns} key={`offer-documents-${index}`}>
                    {renderDocuments(
                      offer?.OfferIteration
                        ? offer?.OfferIteration[0]?.OfferIterationDocument
                        : offer?.OfferIterationDocument,
                    )}
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </div>
      </div>
      {offerDetailsModal && (
        <OfferDetailsModal
          isOpen={offerDetailsModal}
          onClose={onDetailsClose}
          transactionId={transactionId}
          offerData={offerData}
          setOfferCompareModalIsOpen={setOfferDetailsModal}
        />
      )}
      <CompareOffersModal
        data={offerComparisonData}
        open={offerCompareModalIsOpen}
        transactionId={transactionId}
        onCloseModal={() => setOfferCompareModalIsOpen(false)}
      />
    </>
  );
};
