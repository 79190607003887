import PropTypes from 'prop-types';

export const Icons = ({ variant, color }) => {
  const renderIcon = () => {
    switch (variant) {
      case Icons.HIGHLIGHTS:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
          >
            <g clipPath="url(#clip0_59563_407243)">
              <path
                d="M8 12.5652L4.29161 14.5013L5 10.4005L2 7.49665L6.1458 6.89881L8 3.16797L9.8542 6.89881L14 7.49665L11 10.4005L11.7084 14.5013L8 12.5652Z"
                stroke="white"
                strokeOpacity="0.9"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_59563_407243">
                <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
              </clipPath>
            </defs>
          </svg>
        );

      case Icons.REMOVE:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <rect width="24" height="24" rx="12" fill="#FF576D" />
            <path d="M7 12H17" stroke="white" strokeWidth="2" strokeLinecap="round" />
          </svg>
        );

      case Icons.MENU:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clipPath="url(#clip0_59549_393689)">
              <path
                d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
                fill={color || '#AAABAB'}
                stroke={color || '#AAABAB'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                fill={color || '#AAABAB'}
                stroke={color || '#AAABAB'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
                fill={color || '#AAABAB'}
                stroke={color || '#AAABAB'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_59549_393689">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );

      case Icons.EDIT:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M2 14H5.13144L13.3515 5.77998C13.7667 5.36472 14 4.80152 14 4.21426C14 3.627 13.7667 3.0638 13.3515 2.64854C12.9362 2.23329 12.373 2 11.7857 2C11.1985 2 10.6353 2.23329 10.22 2.64854L2 10.8686V14Z"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.4375 3.43188L12.5689 6.56332"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );

      case Icons.BACK:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.194 18.6943C15.602 18.2867 15.602 17.6259 15.194 17.2184L9.96989 12L15.194 6.78165C15.602 6.37408 15.602 5.71326 15.194 5.30568C14.7859 4.89811 14.1244 4.8981 13.7164 5.30568L7.80602 11.2096C7.58861 11.4267 7.48704 11.7158 7.50132 12.0002C7.48714 12.2844 7.58871 12.5733 7.80604 12.7904L13.7164 18.6943C14.1244 19.1019 14.786 19.1019 15.194 18.6943Z"
              fill={color || '#D6D6D7'}
            />
          </svg>
        );

      case Icons.ACTIVITY:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
          >
            <g clipPath="url(#clip0_59549_392469)">
              <path
                d="M21.5 12H17.5L14.5 20L10.5 4L7.5 12H3.5"
                stroke="#747475"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_59549_392469">
                <rect width="24" height="24" fill="white" transform="translate(0.5)" />
              </clipPath>
            </defs>
          </svg>
        );

      case Icons.PORTFOLIO:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
          >
            <path
              d="M5.75 11V20.75H19.25V11"
              stroke="#aaabab"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.25 20.75V15.5H14.75V20.75"
              stroke="#aaabab"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.25 11.75L12.5 5L16.2482 8.06668L19.2482 10.5212L20.75 11.75"
              stroke="#aaabab"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icons.KITS:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
          >
            <path
              d="M22 21V10.4375C22 9.79103 21.7629 9.17105 21.341 8.71393C20.919 8.25681 20.3467 8 19.75 8H12.25C11.6533 8 11.081 8.25681 10.659 8.71393C10.2371 9.17105 10 9.79103 10 10.4375V15.3125C10 15.959 10.2371 16.579 10.659 17.0361C11.081 17.4932 11.6533 17.75 12.25 17.75H19L22 21Z"
              fill="white"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2 16V5.4375C2 4.79103 2.27656 4.17105 2.76884 3.71393C3.26113 3.25681 3.92881 3 4.625 3H13.375C14.0712 3 14.7389 3.25681 15.2312 3.71393C15.7234 4.17105 16 4.79103 16 5.4375V10.3125C16 10.959 15.7234 11.579 15.2312 12.0361C14.7389 12.4932 14.0712 12.75 13.375 12.75H5.5L2 16Z"
              fill="white"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icons.SEARCHES:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M10.4444 15.8889C13.4513 15.8889 15.8889 13.4513 15.8889 10.4444C15.8889 7.43756 13.4513 5 10.4444 5C7.43756 5 5 7.43756 5 10.4444C5 13.4513 7.43756 15.8889 10.4444 15.8889Z"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.9987 19L14.332 14.3333"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icons.TRANSACTIONS:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
          >
            <path
              d="M6.66406 7.83464H18.3307M18.3307 7.83464L15.4141 4.91797M18.3307 7.83464L15.4141 10.7513"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.332 16.1667H6.66536M6.66536 16.1667L9.58203 13.25M6.66536 16.1667L9.58203 19.0833"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icons.FILES:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M13.2857 18H4.71429C4.25963 18 3.82359 17.8127 3.5021 17.4793C3.18061 17.1459 3 16.6937 3 16.2222V3.77778C3 3.30628 3.18061 2.8541 3.5021 2.5207C3.82359 2.1873 4.25963 2 4.71429 2H10L15 7V16.2222C15 16.6937 14.8194 17.1459 14.4979 17.4793C14.1764 17.8127 13.7404 18 13.2857 18Z"
              fill="white"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.2857 21H9.71429C9.25963 21 8.82359 20.8127 8.5021 20.4793C8.18061 20.1459 8 19.6937 8 19.2222V6.77778C8 6.30628 8.18061 5.8541 8.5021 5.5207C8.82359 5.1873 9.25963 5 9.71429 5H15L20 10V19.2222C20 19.6937 19.8194 20.1459 19.4979 20.4793C19.1764 20.8127 18.7404 21 18.2857 21Z"
              fill="white"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15 5V9C15 9.26522 15.1054 9.51957 15.2929 9.70711C15.4804 9.89464 15.7348 10 16 10H20"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );

      case Icons.NOTES:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
          >
            <g clipPath="url(#clip0_59549_393620)">
              <path
                d="M7.9987 5.83313H5.4987C5.05667 5.83313 4.63275 6.00872 4.32019 6.32129C4.00763 6.63385 3.83203 7.05777 3.83203 7.4998V14.9998C3.83203 15.4418 4.00763 15.8657 4.32019 16.1783C4.63275 16.4909 5.05667 16.6665 5.4987 16.6665H12.9987C13.4407 16.6665 13.8646 16.4909 14.1772 16.1783C14.4898 15.8657 14.6654 15.4418 14.6654 14.9998V12.4998"
                stroke="#D0D1D4"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 12.5003H10.5L17.5833 5.41694C17.9149 5.08542 18.1011 4.63578 18.1011 4.16694C18.1011 3.6981 17.9149 3.24846 17.5833 2.91694C17.2518 2.58542 16.8022 2.39917 16.3333 2.39917C15.8645 2.39917 15.4149 2.58542 15.0833 2.91694L8 10.0003V12.5003Z"
                stroke="#D0D1D4"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13.832 4.16687L16.332 6.66687"
                stroke="#D0D1D4"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_59549_393620">
                <rect width="20" height="20" fill="white" transform="translate(0.5)" />
              </clipPath>
            </defs>
          </svg>
        );

      case Icons.MESSAGE:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
          >
            <path
              d="M4.81988 13.3818L5.73995 13.7735L5.93781 13.3088L5.68124 12.8738L4.81988 13.3818ZM3.52963 16.4121L4.4497 16.8038H4.4497L3.52963 16.4121ZM4.16963 17.0744L3.8097 16.1415H3.8097L4.16963 17.0744ZM7.35708 15.8448L7.83917 14.9687L7.4314 14.7443L6.99715 14.9118L7.35708 15.8448ZM16.2331 10C16.2331 13.1297 13.696 15.6667 10.5664 15.6667V17.6667C14.8006 17.6667 18.2331 14.2342 18.2331 10H16.2331ZM10.5664 4.33337C13.696 4.33337 16.2331 6.87043 16.2331 10H18.2331C18.2331 5.76586 14.8006 2.33337 10.5664 2.33337V4.33337ZM4.89973 10C4.89973 6.87043 7.43678 4.33337 10.5664 4.33337V2.33337C6.33221 2.33337 2.89973 5.76586 2.89973 10H4.89973ZM5.68124 12.8738C5.18481 12.032 4.89973 11.0508 4.89973 10H2.89973C2.89973 11.4179 3.28554 12.7486 3.95851 13.8898L5.68124 12.8738ZM4.4497 16.8038L5.73995 13.7735L3.8998 12.99L2.60956 16.0203L4.4497 16.8038ZM3.8097 16.1415C4.22201 15.9824 4.62282 16.3972 4.4497 16.8038L2.60956 16.0203C2.09019 17.2401 3.29263 18.4846 4.52955 18.0074L3.8097 16.1415ZM6.99715 14.9118L3.8097 16.1415L4.52955 18.0074L7.717 16.7778L6.99715 14.9118ZM10.5664 15.6667C9.57575 15.6667 8.64727 15.4134 7.83917 14.9687L6.87498 16.7209C7.97097 17.324 9.23016 17.6667 10.5664 17.6667V15.6667Z"
              fill="white"
              fillOpacity="0.8"
            />
          </svg>
        );

      case Icons.RECENT_ACTIVITY:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M6.16602 7.83464H17.8327M17.8327 7.83464L14.916 4.91797M17.8327 7.83464L14.916 10.7513"
              stroke="#aaabab"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.832 16.1667H6.16536M6.16536 16.1667L9.08203 13.25M6.16536 16.1667L9.08203 19.0833"
              stroke="#aaabab"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );

      case Icons.DOCUMENT_TO_SIGN:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M13.2857 18H4.71429C4.25963 18 3.82359 17.8127 3.5021 17.4793C3.18061 17.1459 3 16.6937 3 16.2222V3.77778C3 3.30628 3.18061 2.8541 3.5021 2.5207C3.82359 2.1873 4.25963 2 4.71429 2H10L15 7V16.2222C15 16.6937 14.8194 17.1459 14.4979 17.4793C14.1764 17.8127 13.7404 18 13.2857 18Z"
              fill="white"
              stroke="#aaabab"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.2857 21H9.71429C9.25963 21 8.82359 20.8127 8.5021 20.4793C8.18061 20.1459 8 19.6937 8 19.2222V6.77778C8 6.30628 8.18061 5.8541 8.5021 5.5207C8.82359 5.1873 9.25963 5 9.71429 5H15L20 10V19.2222C20 19.6937 19.8194 20.1459 19.4979 20.4793C19.1764 20.8127 18.7404 21 18.2857 21Z"
              fill="white"
              stroke="#aaabab"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15 5V9C15 9.26522 15.1054 9.51957 15.2929 9.70711C15.4804 9.89464 15.7348 10 16 10H20"
              stroke="#aaabab"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );

      case Icons.ACTIVE_SEARCHES:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M10.4444 15.8889C13.4513 15.8889 15.8889 13.4513 15.8889 10.4444C15.8889 7.43756 13.4513 5 10.4444 5C7.43756 5 5 7.43756 5 10.4444C5 13.4513 7.43756 15.8889 10.4444 15.8889Z"
              stroke="#aaabab"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.0007 19L14.334 14.3334"
              stroke="#aaabab"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );

      case Icons.OVERDUE_TASK:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M3.5 5.5L5 7L7.5 4.5"
              stroke="#aaabab"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.5 11.5L5 13L7.5 10.5"
              stroke="#aaabab"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.5 17.5L5 19L7.5 16.5"
              stroke="#aaabab"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11 6H20"
              stroke="#aaabab"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11 12H20"
              stroke="#aaabab"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11 18H20"
              stroke="#aaabab"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );

      case Icons.UPCOMMING_MILESTONES:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clipPath="url(#clip0_59563_406464)">
              <path
                d="M11 6H20"
                stroke="#aaabab"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11 12H20"
                stroke="#aaabab"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11 18H20"
                stroke="#aaabab"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <circle cx="5.5" cy="6" r="1.5" stroke="#aaabab" strokeWidth="2" />
              <circle cx="5.5" cy="12" r="1.5" stroke="#aaabab" strokeWidth="2" />
              <circle cx="5.5" cy="18" r="1.5" stroke="#aaabab" strokeWidth="2" />
            </g>
            <defs>
              <clipPath id="clip0_59563_406464">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );

      case Icons.NEW_COMMENTS:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M5.18229 16.0581L6.10236 16.4498L6.30022 15.9851L6.04365 15.5501L5.18229 16.0581ZM3.54135 19.912L2.62128 19.5203H2.62128L3.54135 19.912ZM4.18134 20.5744L4.54126 21.5074H4.54126L4.18134 20.5744ZM8.22693 19.0137L8.70902 18.1376L8.30125 17.9132L7.86701 18.0807L8.22693 19.0137ZM19.0781 12C19.0781 15.866 15.9441 19 12.0781 19V21C17.0487 21 21.0781 16.9706 21.0781 12H19.0781ZM12.0781 5C15.9441 5 19.0781 8.13401 19.0781 12H21.0781C21.0781 7.02944 17.0487 3 12.0781 3V5ZM5.07811 12C5.07811 8.13401 8.21212 5 12.0781 5V3C7.10755 3 3.07811 7.02944 3.07811 12H5.07811ZM6.04365 15.5501C5.43029 14.51 5.07811 13.2977 5.07811 12H3.07811C3.07811 13.6647 3.53101 15.2267 4.32093 16.5661L6.04365 15.5501ZM4.46142 20.3038L6.10236 16.4498L4.26222 15.6663L2.62128 19.5203L4.46142 20.3038ZM3.82142 19.6414C4.23373 19.4824 4.63454 19.8972 4.46142 20.3038L2.62128 19.5203C2.10191 20.7401 3.30435 21.9846 4.54126 21.5074L3.82142 19.6414ZM7.86701 18.0807L3.82142 19.6414L4.54126 21.5074L8.58685 19.9467L7.86701 18.0807ZM12.0781 19C10.8548 19 9.70754 18.687 8.70902 18.1376L7.74483 19.8898C9.03123 20.5977 10.5092 21 12.0781 21V19Z"
              fill="#aaabab"
            />
          </svg>
        );

      case Icons.NEW_FAVOURITES:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M4.37884 13.2743C3.52487 12.3958 3 11.2029 3 9.88889C3 7.18883 5.21619 5 7.95 5C9.62414 5 11.1042 5.82084 12 7.07726C12.8958 5.82084 14.3759 5 16.05 5C18.7838 5 21 7.18883 21 9.88889C21 11.2674 20.4224 12.5126 19.4931 13.4013L13.4238 19.5561C12.6407 20.3503 11.3592 20.3504 10.5759 19.5564L4.37884 13.2743Z"
              stroke="#aaabab"
              strokeWidth="2"
            />
          </svg>
        );

      case Icons.TOUR_REQUESTS:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M17.4008 4.80005H6.60078C5.60667 4.80005 4.80078 5.60594 4.80078 6.60005V17.4001C4.80078 18.3942 5.60667 19.2001 6.60078 19.2001H17.4008C18.3949 19.2001 19.2008 18.3942 19.2008 17.4001V6.60005C19.2008 5.60594 18.3949 4.80005 17.4008 4.80005Z"
              stroke="#aaabab"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.5996 3.59998V5.99998"
              stroke="#aaabab"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.40039 3.59998V5.99998"
              stroke="#aaabab"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.80078 9.59998H19.2008"
              stroke="#aaabab"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.1992 14.4L13.7992 14.4"
              stroke="#aaabab"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );

      case Icons.ADDRESS:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
          >
            <path
              d="M22.4 10.4C22.4 9.07452 23.4745 8 24.8 8H52C53.3255 8 54.4 9.07452 54.4 10.4V57.3037C54.4 58.351 53.551 59.2 52.5037 59.2H24.8C23.4745 59.2 22.4 58.1255 22.4 56.8V10.4Z"
              fill="#4673D1"
            />
            <path
              d="M8 26.4C8 25.0745 9.07452 24 10.4 24H31.2C32.5255 24 33.6 25.0745 33.6 26.4V59.2H10.4C9.07452 59.2 8 58.1255 8 56.8V26.4Z"
              fill="#51BFE1"
            />
            <path
              d="M22.4 24H31.2C32.5255 24 33.6 25.0745 33.6 26.4V59.2H24.8C23.4745 59.2 22.4 58.1255 22.4 56.8V24Z"
              fill="#34569D"
            />
          </svg>
        );

      default:
        return null;
    }
  };
  return <>{renderIcon()}</>;
};

Icons.BACK = 'Back';
Icons.NOTES = 'Notes';
Icons.MESSAGE = 'Message';
Icons.ACTIVITY = 'Activity';
Icons.PORTFOLIO = 'Portfolio';
Icons.KITS = 'Kits';
Icons.SEARCHES = 'Searches';
Icons.TRANSACTIONS = 'Transactions';
Icons.FILES = 'Files';
Icons.EDIT = 'Edit';
Icons.MENU = 'Menu';
Icons.REMOVE = 'Remove';
Icons.HIGHLIGHTS = 'Highlights';
Icons.RECENT_ACTIVITY = 'recentActivity';
Icons.DOCUMENT_TO_SIGN = 'documentToSign';
Icons.ACTIVE_SEARCHES = 'activeSearches';
Icons.OVERDUE_TASK = 'overdueTask';
Icons.UPCOMMING_MILESTONES = 'upcommingMilestones';
Icons.NEW_COMMENTS = 'newComments';
Icons.NEW_FAVOURITES = 'newFavourites';
Icons.TOUR_REQUESTS = 'tourRequest';
Icons.ADDRESS = 'address';

Icons.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
};

Icons.defaultProps = {
  variant: '',
  color: '',
};
