import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';

import { CollapsibleTable, WorkshopTableWrapper } from 'pages/Workshop/common';
import { tableColumns } from '../tableColumns';

import { TEAM_ADMIN, TEAM_OWNER, transactionRoles } from 'settings/constants/roles';
import { LISTED_STATUS, transactionStatuses } from 'settings/constants/transaction';
import { transactionsFilters } from 'settings/constants/roles';
import { link } from 'settings/navigation/link';
import { getUserId } from 'store/selectors/user';
import { useSelector } from 'react-redux';

import styles from './../styles.module.scss';
import { getDateOnly } from 'helpers';
import { getTransactionsSearchSelector } from 'store/selectors/transactions';
import { get } from 'lodash-es';
import { openNewTab } from 'services/newTab';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';

export const TransactionsTables = ({
  isFiltersApplied,
  isArchive,
  transactions,
  transactionFilter,
  userRole,
}) => {
  const history = useHistory();
  const userId = useSelector(getUserId);
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const isSearchApplied = useSelector(getTransactionsSearchSelector);

  const transactionsData: any = {
    purchasesTableData: [],
    listingTableData: [],
    dualTableData: [],
    closedTransactionsTableData: [],
    cancelledTransactionsTableData: [],
    expiredTransactionsTableData: [],
    allData: [],
  };

  transactions = transactions.map((t) => {
    let isAlertVisible = false;
    if (
      ([TEAM_OWNER, TEAM_ADMIN].includes(agentRole) && t.IsTeamTransaction) ||
      t.TransactionAccess
    ) {
      isAlertVisible = true;
    }

    return {
      ...t,
      isAlertVisible,
    };
  });

  for (const transaction of transactions) {
    let keepItem = true;

    if (isArchive) {
      keepItem = [
        transactionStatuses.Closed,
        transactionStatuses.Canceled,
        LISTED_STATUS.Expired,
      ].includes(transaction.Status);
    } else {
      keepItem = ![
        transactionStatuses.Closed,
        transactionStatuses.Canceled,
        LISTED_STATUS.Expired,
      ].includes(transaction.Status);
    }

    if (!keepItem) continue;

    switch (transactionFilter) {
      case transactionsFilters.myTransactions:
        keepItem = transaction.IsMyTransaction;
        break;

      case transactionsFilters.teamTransactions:
        keepItem = transaction.IsTeamTransaction && !transaction.IsMyTransaction;
        break;

      case transactionsFilters.externalTransactions:
        keepItem = !transaction.IsTeamTransaction && !transaction.IsMyTransaction;
        break;
    }

    if (!keepItem) continue;

    if (isFiltersApplied) {
      transactionsData.allData.push(transaction);
      continue;
    }

    const roles = transaction.RepresentingRoles || [];

    const addPurchaseData =
      roles.includes(transactionRoles.BUYER) && !roles.includes(transactionRoles.SELLER);

    if (addPurchaseData) {
      transactionsData.purchasesTableData.push(transaction);
    }

    const addListingData =
      !roles.includes(transactionRoles.BUYER) && roles.includes(transactionRoles.SELLER);

    if (addListingData) {
      transactionsData.listingTableData.push(transaction);
    }

    const addDualData =
      roles.includes(transactionRoles.BUYER) && roles.includes(transactionRoles.SELLER);

    if (addDualData) {
      transactionsData.dualTableData.push(transaction);
    }

    const addClosedData = transaction.Status === transactionStatuses.Closed;

    if (addClosedData) {
      transactionsData.closedTransactionsTableData.push(transaction);
    }

    const addCanceledData = transaction.Status === transactionStatuses.Canceled;

    if (addCanceledData) {
      transactionsData.cancelledTransactionsTableData.push(transaction);
    }

    const addExpiredData = transaction.Status === LISTED_STATUS.Expired;

    if (addExpiredData) {
      transactionsData.expiredTransactionsTableData.push(transaction);
    }
  }

  const tableProps = {
    columns: tableColumns(userRole),
    onRow: (record) => {
      return {
        onClick: (e) => {
          const path = link.toTransactionOverview(record.Id);
          if (e.ctrlKey || e.metaKey) {
            openNewTab(path);
          } else {
            history.push(path);
          }
        },
      };
    },
    tableClassName: styles.transactionsTable,
  };

  function getFilterAppliedTransactionsTitle() {
    const count = transactionsData.allData.length;
    return `${count} Transaction${count > 1 ? 's' : ''}`;
  }

  const filteredListingTableData = isSearchApplied
    ? transactionsData.listingTableData.filter(
        (transaction) => transaction.Status !== transactionStatuses.Canceled,
      )
    : transactionsData.listingTableData;

  const filteredPurchasesTableData = isSearchApplied
    ? transactionsData.purchasesTableData.filter(
        (transaction) => transaction.Status !== transactionStatuses.Canceled,
      )
    : transactionsData.purchasesTableData;

  const filteredDualTableData = isSearchApplied
    ? transactionsData.dualTableData.filter(
        (transaction) => transaction.Status !== transactionStatuses.Canceled,
      )
    : transactionsData.dualTableData;

  const filteredCanceledTableData = isSearchApplied
    ? transactions.filter((transaction) => transaction.Status === transactionStatuses.Canceled)
    : transactionsData.cancelledTransactionsTableData;

  const filteredClosedTableData = isSearchApplied
    ? transactions.filter((transaction) => transaction.Status === transactionStatuses.Closed)
    : transactionsData.cancelledTransactionsTableData;

  const filteredExpiredTableData = isSearchApplied
    ? transactions.filter((transaction) => transaction.Status === LISTED_STATUS.Expired)
    : transactionsData.expiredTransactionsTableData;

  return (
    <Fragment>
      {isFiltersApplied ? (
        <WorkshopTableWrapper data={[transactionsData.allData]} emptyText="No Transactions.">
          <CollapsibleTable
            title={getFilterAppliedTransactionsTitle()}
            dataSource={transactionsData.allData}
            disableCollapse
            {...tableProps}
          />
        </WorkshopTableWrapper>
      ) : isArchive ? (
        <WorkshopTableWrapper
          data={[
            transactionsData.closedTransactionsTableData,
            transactionsData.cancelledTransactionsTableData,
            transactionsData.expiredTransactionsTableData,
          ]}
          emptyText="No Transactions."
        >
          <CollapsibleTable
            title="Closed"
            dataSource={transactionsData.closedTransactionsTableData}
            defaultCollapse
            {...tableProps}
            columns={tableColumns(userRole, 'Closed', true)}
          />
          <CollapsibleTable
            title="Cancelled"
            dataSource={transactionsData.cancelledTransactionsTableData}
            defaultCollapse
            {...tableProps}
            columns={tableColumns(userRole, 'Cancelled', true)}
          />
          <CollapsibleTable
            title="Expired"
            dataSource={transactionsData.expiredTransactionsTableData}
            defaultCollapse
            {...tableProps}
            columns={tableColumns(userRole)}
          />
        </WorkshopTableWrapper>
      ) : (
        <WorkshopTableWrapper
          data={[
            filteredListingTableData,
            filteredPurchasesTableData,
            filteredDualTableData,
            filteredClosedTableData,
            filteredCanceledTableData,
          ]}
          emptyText="No Transactions."
        >
          <CollapsibleTable
            title="Listings"
            dataSource={filteredListingTableData}
            {...tableProps}
          />
          <CollapsibleTable
            title="Purchases"
            dataSource={filteredPurchasesTableData}
            {...tableProps}
          />
          <CollapsibleTable title="Dual" dataSource={filteredDualTableData} {...tableProps} />
          {isSearchApplied && (
            <>
              <CollapsibleTable
                title="Closed"
                dataSource={filteredClosedTableData}
                {...tableProps}
                columns={tableColumns(userRole, 'Closed', true)}
              />
              <CollapsibleTable
                title="Cancelled"
                dataSource={filteredCanceledTableData}
                {...tableProps}
                columns={tableColumns(userRole, 'Cancelled', true)}
              />
            </>
          )}
        </WorkshopTableWrapper>
      )}
    </Fragment>
  );
};
