import React from 'react';

export const HouseIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M3.87109 8.32275V15.6772C3.87109 15.8912 4.04458 16.0647 4.25859 16.0647H15.7417C15.9557 16.0647 16.1292 15.8912 16.1292 15.6772V8.32275"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.06445 16.0643V11.6131C8.06445 11.3991 8.23794 11.2256 8.45195 11.2256H11.5479C11.7619 11.2256 11.9354 11.3991 11.9354 11.6131V16.0643"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.58008 9.29016L9.75264 3.365C9.89589 3.24667 10.103 3.24667 10.2462 3.365L17.4188 9.29016"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
