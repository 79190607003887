import Api from 'store/effects/core/api';
import {
  onBoardingSignUpAction,
  onBoardingWizardAction,
  markWizardFinishedAction,
  sendPreApprovalQuoteAction,
  sentPreApprovalDocumentAction,
} from 'store/actions/onBoarding';
import {
  onBoardingUser,
  requestPreApprovalQuote,
  sendPreApprovalDocument,
  uploadAvatar,
} from 'api/onBoarding';
import { getState } from 'store';
import { CLIENT, mapRoles } from 'settings/constants/roles';
import { get } from 'lodash-es';
import { getInfoByRole } from './helpers';

export const onBoardingSignUpEffect =
  (cfg, options = {}, cb) =>
  (dispatch) => {
    const sendRequest = Api.execBase({ action: onBoardingSignUpAction, method: onBoardingUser });
    const sendAvatar = Api.execBase({ action: onBoardingSignUpAction, method: uploadAvatar });
    const { user, loginGroup } = getState();
    const config = {
      id: user?.data?.Id,
      role: mapRoles[loginGroup.signUpRole],
      stageIndex: cfg.stageIndex,
      Values: {
        FirstName: cfg.firstName || undefined,
        LastName: cfg.lastName || undefined,
        Email: cfg?.email ? cfg?.email?.toLowerCase() : user?.data?.Email?.toLowerCase(),
        Phones: cfg.phone
          ? [{ PhoneNumber: cfg.phone, PhoneType: 'Mobile', IsPrimary: true }]
          : undefined,
        ...getInfoByRole({
          signUpRole: loginGroup.signUpRole,
          cfg,
          user: user?.data,
          ...options,
        }),
        PartnerServices: cfg?.PartnerServices ?? undefined,
      },
    };
    if (cfg.file instanceof File) {
      const formData = new FormData();
      formData.append('file', cfg.file);

      return dispatch(
        sendAvatar(formData, {}, () => {
          dispatch(sendRequest(config, options, cb));
        }),
      );
    }

    return dispatch(sendRequest(config, options, cb));
  };

export const onBoardingSignUpSearchEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({ action: onBoardingSignUpAction, method: onBoardingUser });
  const { user, clientInstances, loginGroup } = getState();

  const { stageIndex, ...conf } = cfg;

  const config = {
    id: user?.data?.Id,
    role: mapRoles[loginGroup.signUpRole],
    stageIndex,
    Values: {
      Email: user?.data?.Email?.toLowerCase(),
      // Currently this onboarding worked specifically for Buyer/SearchInstance, so it will always be here
      // Should be updated for other use cases
      ...(loginGroup.signUpRole === CLIENT && clientInstances.data.searchInstances?.[0]
        ? {
            [loginGroup.signUpRole]: {
              SearchInstance: {
                Id: clientInstances.data.searchInstances[0].Id,
                ...conf,
              },
            },
          }
        : {
            [loginGroup.signUpRole]: {
              ...conf,
            },
          }),
    },
  };

  return sendRequest(config, options, cb);
};

export const onBoardingWizardEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({ action: onBoardingWizardAction, method: onBoardingUser });
  const { user, clientInstances, onBoarding, loginGroup } = getState();
  const signUpDefaultSearch =
    get(
      onBoarding,
      'signUp.[8].meta.Values.Client.SearchInstances[0].DefaultPropertySearchPreferences',
    ) || {};
  const userDefaultSearchInstance = get(clientInstances, 'data.searchInstances[0]');

  const { stageIndex, PreApproval, WizardFinished, ...conf } = cfg;

  const config = {
    id: user?.data?.Id,
    role: mapRoles[loginGroup.signUpRole],
    stageIndex,
    Values: {
      Email: user?.data?.Email?.toLowerCase(),
      [loginGroup.signUpRole]: {
        ...(WizardFinished ? { WizardFinished } : {}),
        SearchInstance: {
          // Taking default search instance for now
          Id: userDefaultSearchInstance?.Id,
          ...conf,
          DefaultPropertySearchPreferences: {
            ...{
              ...(userDefaultSearchInstance?.DefaultPropertySearchPreferences || {}),
              ...signUpDefaultSearch,
            },
            ...conf?.DefaultPropertySearchPreferences,
          },
        },
        // Quick hack to support preapproval. Ideally would be to have separate effect for preapproval flow
        ...(PreApproval
          ? {
              Context: { PreApproval },
            }
          : {}),
      },
    },
  };

  return sendRequest(config, options, cb);
};

export const markWizardFinishedEffect = (cfg, options, cb) => {
  const sendRequest = Api.execResult({ action: markWizardFinishedAction, method: onBoardingUser });

  const { user, clientInstances, loginGroup } = getState();
  const userDefaultSearchInstance = get(clientInstances, 'data.searchInstances[0]');

  const config = {
    id: user?.data?.Id,
    role: mapRoles[loginGroup.signUpRole],
    Values: {
      [loginGroup.signUpRole]: {
        WizardFinished: !cfg?.isWizardSkipped ? true : undefined,
        WizardSkipped: cfg?.isWizardSkipped ? true : undefined,
        SearchInstance: {
          Id: userDefaultSearchInstance?.Id,
          DefaultPropertySearchPreferences: {
            ...(userDefaultSearchInstance?.DefaultPropertySearchPreferences || {}),
          },
        },
      },
    },
  };

  return sendRequest(config, options, cb);
};

export const sendPreApprovalQuoteEffect = (cfg, options, cb) => {
  const requestParams = { action: sendPreApprovalQuoteAction, method: requestPreApprovalQuote };
  let sendRequest = Api.execBase(requestParams);

  if (options.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const uploadPreApprovalDocumentEffect = (cfg, options, cb) => {
  const requestParams = { action: sentPreApprovalDocumentAction, method: sendPreApprovalDocument };
  let sendRequest = Api.execBase(requestParams);
  const { user, contexts } = getState();

  const formData = new FormData();

  if (cfg?.files) {
    const arrayFiles = Array.from(cfg?.files);
    arrayFiles.forEach((file) => {
      formData.append('files', file);
    });
  }

  const config = {
    clientId: user?.data?.Id,
    contextKey: (contexts.data || [])[0]?.ContextKey,
    formData,
  };

  return sendRequest(config, options, cb);
};
