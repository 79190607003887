import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';

import { prepareData } from './helpers';
import { ValidationSchema } from './validation';

import { getClientDefaultSearchSelector } from 'store/selectors/user';
import { onBoardingSignUpSearchEffect } from 'store/effects';
import { addSearchInstance } from 'store/effects/clientInstances';
import { PENDING } from 'settings/constants/apiState';

import { Input, MinMax } from 'components';
import { getOnBoardingData } from 'pages/OnBoarding/selectors';

import styles from './styles.module.scss';
import { useDebounce } from 'hooks/use-debounce';
import { useEffect } from 'react';

const PriceRangeForm = (props) => {
  const { stageIndex } = props;
  const dispatch = useDispatch();
  const defaultClientSearch = useSelector(getClientDefaultSearchSelector);
  const { onBoarding } = useSelector(getOnBoardingData);

  const formik = useFormik({
    initialValues: {
      priceMin: defaultClientSearch?.PriceRange?.Min || '',
      priceMax: defaultClientSearch?.PriceRange?.Max || '',
    },
    validationSchema: ValidationSchema,
    onSubmit(values) {
      const cfg = prepareData(values, stageIndex, defaultClientSearch);
      dispatch(
        onBoardingSignUpSearchEffect(cfg, {}, (err) => {
          if (!err) {
            dispatch(addSearchInstance({ data: cfg.DefaultPropertySearchPreferences }));
          }
        }),
      );
    },
  });

  const debouncedMin = useDebounce(formik.values?.priceMin);
  const debouncedMax = useDebounce(formik.values?.priceMax);

  useEffect(() => {
    if (formik.dirty) {
      formik.handleSubmit();
    }
  }, [debouncedMin, debouncedMax]);

  console.log('CHM', formik.errors);

  return (
    <form onSubmit={formik.handleSubmit}>
      <MinMax
        placeholderMin="No min"
        placeholderMax="No max"
        separatorClassName={styles.to}
        prefix="$"
        nameMin="priceMin"
        nameMax="priceMax"
        valueMin={formik.values.priceMin}
        valueMax={formik.values.priceMax}
        onChangeMin={(e, val) => formik.setFieldValue('priceMin', val)}
        onChangeMax={(e, val) => formik.setFieldValue('priceMax', val)}
        testidMin="price_min"
        testidMax="price_max"
        variant={Input.LIGHT_ROUND}
        error={formik.errors.priceMax}
      />
    </form>
  );
};

export default PriceRangeForm;
