import { createAction } from 'redux-actions';

export const requestGetClientRelatedContactsAction = createAction(
  'REQUEST/GET_CLIENT_RELATED_CONTACTS',
);

export const requestGetAgentCoordinatorRelatedContactsAction = createAction(
  'REQUEST/GET_AGENT_COORDINATOR_RELATED_CONTACTS',
);

export const requestGetTransactionRelatedContactsAction = createAction(
  'REQUEST/GET_TRANSACTION_RELATED_CONTACTS',
);

export const requestGetAllRelatedContactsAction = createAction('REQUEST/GET_ALL_RELATED_CONTACTS');
