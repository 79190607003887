import classNames from 'classnames';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Avatar, ConfirmationDialog } from 'components';
import { Button } from 'components-antd';
import { PreApproveVerified } from 'components/Icons';
import { convertNameToAvatarPlaceholder } from 'helpers/formatters';
import { showSuccessMessage } from 'helpers/success';
import PropTypes from 'prop-types';
import { DRAWER_MESSAGES_TYPES } from 'settings/constants/drawers';
import { TEAM_OWNER } from 'settings/constants/roles';
import {
  changeMessagesDrawerTypeAction,
  openMessagesDrawerAction,
} from 'store/actions/drawers/messages';
import { requestGetSymphonyListEffect, updateMemberProfileEffect } from 'store/effects';
import { setProfileExpandedSection } from 'store/effects/app';
import {
  getAgentTeamIsActiveSelector,
  getAgentTeamRoleSelector,
} from 'store/selectors/agentTeamDetail';
import { getUserId } from 'store/selectors/user';
import { TitleInput } from '../../../InviteTeamMember/TitleInput';
import { ProfileIcons } from '../../Icons';
import Name from '../../Name';
import AgentRole from '../AgentRole';

import { PencilIcon } from '../../Icons';
import styles from './styles.module.scss';

const ExpandedHeader = ({ contact, isAgent, isPreApproved, onClose, setIsEditingHeader }) => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [titleValue, setTitleValue] = useState({});
  const isTeamAgentActive = useSelector(getAgentTeamIsActiveSelector);
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const userId = useSelector(getUserId);
  const dispatch = useDispatch();

  const renderAgentRole = () =>
    isAgent && isTeamAgentActive && agentRole === TEAM_OWNER && contact.Id !== userId ? (
      <AgentRole contact={contact} />
    ) : null;

  const renderAgentTitle = () => (
    <div className={styles.agentTitle}>
      <span>{contact?.Title?.Title}</span>
      {isTeamAgentActive && (contact.Id === userId || contact.IsManagedByCurrentUser) ? (
        <div className={styles.editIcon} onClick={() => setOpenConfirmModal(true)}>
          <ProfileIcons name={ProfileIcons.EDIT} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );

  const onSubmitConfirmModal = () => {
    dispatch(
      updateMemberProfileEffect(
        { titleFreeText: titleValue?.titleFreeText || titleValue?.title?.name },
        { agentId: contact.Id, silent: true },
        (err) => {
          if (!err) {
            dispatch(requestGetSymphonyListEffect({}, { silent: true }));
            setOpenConfirmModal(false);
            showSuccessMessage(`Agent title updated.`);
          }
        },
      ),
    );
  };

  const changeAgentTitleContent = () => (
    <div className={styles.agentTitleContent}>
      <h4>Edit Title</h4>
      <TitleInput values={titleValue} setFieldValue={(key, val) => setTitleValue({ [key]: val })} />
    </div>
  );

  const openMessageDrawer = () => {
    dispatch(openMessagesDrawerAction(true));
    dispatch(
      changeMessagesDrawerTypeAction({
        type: DRAWER_MESSAGES_TYPES.NEW_MESSAGE,
        params: {
          threadId: null,
          participants: [
            {
              id: contact.Id,
              name: `${contact.FirstName} ${contact.LastName || ''}`,
              value: contact.Id,
              role: contact.Role,
              avatarUrl: contact.AvatarUrl,
              firstName: contact.FirstName,
              lastName: contact.LastName,
            },
          ],
        },
      }),
    );
    dispatch(setProfileExpandedSection(false));
    onClose();
  };

  const getSubtitle = () => 'Client';
  return (
    <div testid="profile_client" className={classNames(styles.profile)}>
      <Avatar
        className={styles.profileAvatar}
        image={contact.AvatarUrl}
        placeholder={convertNameToAvatarPlaceholder(`${contact.FirstName} ${contact.LastName}`)}
      />
      <Name
        testid="client_name"
        className={styles.name}
        name={`${contact.FirstName} ${contact.LastName}`}
        editBtn={
          !isAgent ? (
            <span className={styles.hoverEdit} onClick={() => setIsEditingHeader(true)}>
              <PencilIcon />
            </span>
          ) : null
        }
      />
      <div className={styles.isPreApprovedWrapper}>
        {isAgent ? <>{renderAgentTitle()}</> : <p className={styles.joinedIn}>{getSubtitle()}</p>}
        {isPreApproved && !isAgent ? <PreApproveVerified /> : null}
        {isPreApproved && !isAgent ? <p className={styles.joinedIn}>Pre-Approved</p> : null}
      </div>
      <div className={styles.actions}>
        <Button variant={'default'} className={styles.messageButton} onClick={openMessageDrawer}>
          <ProfileIcons name={ProfileIcons.MESSAGE} color="#262626" />
          <span>Message</span>
        </Button>
        {renderAgentRole()}
      </div>
      <ConfirmationDialog
        onReject={() => setOpenConfirmModal(false)}
        onConfirm={onSubmitConfirmModal}
        isOpen={openConfirmModal}
        confirmText="Done"
        unsetOverflow
      >
        {changeAgentTitleContent()}
      </ConfirmationDialog>
    </div>
  );
};
ExpandedHeader.propTypes = {
  isAgent: PropTypes.bool,
  contact: PropTypes.object,
  onClose: PropTypes.func,
  showClientJoining: PropTypes.bool,
  setIsEditingHeader: PropTypes.func,
};

export default ExpandedHeader;
