import { FormsTableStatus } from 'app-constants';
import styles from './styles.module.scss';
import { ArrowDownFull } from 'components/Icons';
import classNames from 'classnames';
import { SortOrder } from 'antd/lib/table/interface';
import { get } from 'lodash-es';

export const sorterWithCompletedOnTop =
  (fieldName: string) => (a: any, b: any, sortOrder: SortOrder) => {
    const valueA = get(a, fieldName, '');
    const valueB = get(b, fieldName, '');

    const completeOnTopMultiplier = sortOrder === 'ascend' ? 1 : -1;
    if (a.status === FormsTableStatus.Complete && b.status !== FormsTableStatus.Complete) {
      return -1 * completeOnTopMultiplier; // "Complete" items come first
    } else if (a.status !== FormsTableStatus.Complete && b.status === FormsTableStatus.Complete) {
      return 1 * completeOnTopMultiplier; // "Complete" items come first
    }

    return valueA?.localeCompare(valueB);
  };

export const defaultFormsTableSorter = (fieldName: string) => (a: any, b: any) => {
  const valueA = a?.[fieldName] || '';
  const valueB = b?.[fieldName] || '';

  return valueA.localeCompare(valueB);
};

export const renderSortableTitle =
  (fieldName: string, title: string) =>
  ({ sortColumns }) => {
    const sortedColumn = sortColumns?.find(({ column }) => column.key === fieldName);
    return (
      <div className={styles.sortedColumnTitle}>
        {title}
        {sortedColumn?.order && (
          <ArrowDownFull
            className={classNames(styles.sorterIcon, {
              [styles.sorterUp]: sortedColumn?.order === 'ascend',
            })}
          />
        )}
      </div>
    );
  };
