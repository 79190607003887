import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { FormDetailModal } from '../FormDetailModal';
import {
  getFormDetailsEffect,
  getTeamAgentFormDetailsEffect,
  removeEditorEffect,
  replaceEditorEffect,
  replaceSignatoryEffect,
  resendEmailEffect,
  sendMessageEffect,
} from 'store/effects/formProcess';
import { ResentEmailEnum } from 'app-constants';
import { successNotification } from 'components-antd';
import { onHandleViewFormType } from '../../Forms';

interface FormDetailProps {
  selectedFormProcess: number;
  agentId?: number;
  onClose: () => void;
  onHandleViewForm: onHandleViewFormType;
  viewOnly?: boolean;
  selectedFormStatus?: string;
  refetchForms: Function;
  onDeleteFormSuccess?: Function;
  inProgressForm?: boolean;
  clientProfileId?: number;
}

export const FormDetails = ({
  selectedFormProcess,
  onClose,
  onHandleViewForm,
  agentId,
  viewOnly,
  selectedFormStatus,
  refetchForms,
  onDeleteFormSuccess,
  inProgressForm,
  clientProfileId,
}: FormDetailProps) => {
  const dispatch = useDispatch();

  const fetchFormDetails = () => {
    if (agentId) {
      dispatch(getTeamAgentFormDetailsEffect({ formProcessId: selectedFormProcess, agentId }));
    } else {
      dispatch(getFormDetailsEffect(selectedFormProcess));
    }
  };

  useEffect(() => {
    if (selectedFormProcess) fetchFormDetails();
  }, [selectedFormProcess]);

  const handleSendMessage = (
    message: string,
    users: string[],
    setSendBtnLoading: (loading: boolean) => void,
    setShowMegaPhoneModal: (open: boolean) => void,
  ) => {
    if (!viewOnly) {
      setSendBtnLoading(true);
      dispatch(
        sendMessageEffect({ message, formProcessId: selectedFormProcess, users }, (err) => {
          if (!err) {
            setShowMegaPhoneModal(false);
            successNotification({
              message: 'Message sent',
              placement: 'top',
            });
          }
          setSendBtnLoading(false);
        }),
      );
    }
  };

  const handleResendForm = ({
    url,
    userId,
    email,
    type,
  }: {
    url: string;
    userId?: number;
    email: string;
    type: ResentEmailEnum;
  }) => {
    if (!viewOnly) {
      const Ids = url.split('/');
      dispatch(
        resendEmailEffect(
          {
            formProcessPublicId: Ids[0],
            formDocumentPublicId: Ids[1],
            user: {
              ...(userId ? { userId: userId } : { email: email }),
            },
            type,
          },
          () => {
            dispatch(
              getFormDetailsEffect(selectedFormProcess, (err) => {
                if (!err) {
                  successNotification({
                    message: 'Resent form',
                    placement: 'top',
                  });
                }
              }),
            );
          },
        ),
      );
    }
  };

  const handleRemoveEditor = ({ url, editorId }: { url: string; editorId: number }) => {
    if (!viewOnly) {
      const Ids = url.split('/');
      dispatch(
        removeEditorEffect(
          {
            formProcessPublicId: Ids[0],
            formDocumentPublicId: Ids[1],
            oldEditorId: editorId,
          },
          () => {
            dispatch(
              getFormDetailsEffect(selectedFormProcess, (err) => {
                if (!err) {
                  successNotification({
                    message: 'Removed editor',
                    placement: 'top',
                  });
                }
              }),
            );
          },
        ),
      );
    }
  };

  const handleReplaceEntity = ({
    url,
    isEditor,
    oldId,
    userId,
    email,
    setLoading,
    onCloseModal,
  }: {
    url: string;
    isEditor?: boolean;
    oldId?: number;
    userId?: number;
    email?: string;
    setLoading: (loading: boolean) => void;
    onCloseModal: () => void;
  }) => {
    if (!viewOnly) {
      setLoading(true);
      const Ids = url.split('/');

      const payload = {
        formProcessPublicId: Ids[0],
        formDocumentPublicId: Ids[1],
        ...(isEditor ? { oldEditorId: oldId } : { oldSignatoryId: oldId }),
        newUser: {
          ...(userId ? { userId: userId } : { email: email }),
        },
      };

      const handleResponse = (err) => {
        setLoading(false);
        if (!err) {
          onCloseModal();
          dispatch(
            getFormDetailsEffect(selectedFormProcess, (err) => {
              if (!err) {
                successNotification({
                  message: `Removed ${isEditor ? 'editor' : 'signatory'}`,
                  placement: 'top',
                });
              }
            }),
          );
        }
      };

      if (isEditor) {
        dispatch(replaceEditorEffect(payload, handleResponse));
      } else {
        dispatch(replaceSignatoryEffect(payload, handleResponse));
      }
    }
  };

  return (
    <FormDetailModal
      onCloseModal={onClose}
      handleSendMessage={handleSendMessage}
      handleViewForm={onHandleViewForm}
      handleResendForm={handleResendForm}
      handleRemoveEditor={handleRemoveEditor}
      handleReplaceEntity={handleReplaceEntity}
      viewOnly={viewOnly}
      selectedFormProcess={selectedFormProcess}
      selectedFormStatus={selectedFormStatus}
      fetchFormDetails={() => {
        fetchFormDetails();
        refetchForms(); // Refetch the table data
      }}
      refetchForms={refetchForms}
      onDeleteFormSuccess={onDeleteFormSuccess}
      inProgressForm={inProgressForm}
      clientProfileId={clientProfileId}
    />
  );
};
