import { SVGAttributes } from 'react';

export const PriceIncreaseIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M4 13L8 9L11 12L17 6M17 6H13M17 6V10"
        stroke="#EC455E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
