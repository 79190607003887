import React from 'react';

export const DashIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g opacity="0.8">
        <path
          d="M5 9L13 9"
          stroke="#AAABAB"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
