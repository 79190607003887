import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';

import { prepareData } from './helpers';
import { ValidationSchema } from './validation';

import { getClientDefaultSearchSelector } from 'store/selectors/user';
import { onBoardingSignUpSearchEffect } from 'store/effects';
import { addSearchInstance } from 'store/effects/clientInstances';

import { Locations } from 'components';

import styles from './styles.module.scss';
import { featureFlags } from 'utils/featureFlags';

const LocationsForm = (props) => {
  const { stageIndex, setIsValid } = props;
  const dispatch = useDispatch();
  const defaultClientSearch = useSelector(getClientDefaultSearchSelector);
  const formik = useFormik({
    initialValues: {
      locations: defaultClientSearch?.Locations || [],
    },
    validationSchema: ValidationSchema,
    onSubmit(values) {
      const cfg = prepareData(values, stageIndex, defaultClientSearch);
      dispatch(
        onBoardingSignUpSearchEffect(cfg, {}, (err) => {
          if (!err) {
            dispatch(addSearchInstance({ data: cfg.DefaultPropertySearchPreferences }));
          }
        }),
      );
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Locations
        variant={Locations.ROUND}
        name="locations"
        onResult={(_, preparedData) => {
          formik.setFieldValue('locations', preparedData);
          if (preparedData?.length) {
            setIsValid(true);
            formik.handleSubmit();
          } else {
            setIsValid(false);
          }
        }}
        placeholder="Enter city, neighborhood or zip"
        placeholderClassName={styles.border}
        searchWrapperClassName={styles.border}
        valuesWrapperClassName={styles.border}
        testid="desired_locations"
        value={formik?.values?.locations || []}
        activeInputClassName={styles.locationInput}
        showMultipleText={true}
        searchIcon={true}
        rounded={true}
        enableDraw={featureFlags.customSearchPolygonDrawing}
      />
    </form>
  );
};

export default LocationsForm;
