import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Row } from 'components-antd';
import { Header } from './Components/Header';
import { HeaderTabs } from './Components/HeaderTabs';
import { getExpandChart, getIsGraphSelected, getShowSubCharts } from 'store/selectors/clarity';
import { Stats } from './Components/Stats';
import {
  setTeamStatsAction,
  setTimePeriodAction,
  setSelectedGraphAction,
  setExpandChartAction,
  setShowSubChartsAction,
  setTransactionFilterAction,
} from 'store/actions/clarity';
import { ExpandedChartsView } from './Components/Stats/Content/Components/ExpandedChartsView';

export const ClarityRevamped = () => {
  const dispatch = useDispatch();
  const isGraphSelected = useSelector(getIsGraphSelected);
  const expandChart = useSelector(getExpandChart);
  const showSubCharts = useSelector(getShowSubCharts);

  useEffect(() => {
    return () => {
      dispatch(setTeamStatsAction(null));
      dispatch(setTimePeriodAction(null));
      dispatch(setSelectedGraphAction(false));
      dispatch(setExpandChartAction(null));
      dispatch(setShowSubChartsAction(false));
      dispatch(setTransactionFilterAction({ value: 0 }));
    };
  }, []);

  const getContent = () => {
    if (showSubCharts)
      return (
        <>
          <Header title={expandChart} showFilters={false} />
          <ExpandedChartsView />
        </>
      );
    else if (isGraphSelected) return <Stats />;
    return (
      <>
        <Header />
        <HeaderTabs />
      </>
    );
  };
  return getContent();
};
