import React from 'react';

export const LibraryIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M15.6363 8.5H18.8125C19.3927 8.5 19.9491 8.73047 20.3593 9.1407C20.7695 9.55094 21 10.1073 21 10.6875V20.3334L18.0833 17.5H11.6875C11.1073 17.5 10.5509 17.2695 10.1407 16.8593C9.73047 16.4491 9.5 15.8927 9.5 15.3125V13.447"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 16.1667L3 5.84375C3 5.22215 3.24693 4.62601 3.68647 4.18647C4.12601 3.74693 4.72215 3.5 5.34375 3.5H13.1562C13.7779 3.5 14.374 3.74693 14.8135 4.18647C15.2531 4.62601 15.5 5.22215 15.5 5.84375V10.698C15.5 11.3196 15.2531 11.9157 14.8135 12.3553C14.374 12.7948 13.7779 13.0417 13.1562 13.0417H6.125L3 16.1667Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
