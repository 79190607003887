import { Button } from 'components';
import styles from './styles.module.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { updateClientDataEffect } from 'store/effects';
import { showErrorMessage, showSuccessMessage } from 'helpers';
import { Phone as PhoneInput } from 'components';

export const PhoneForm = ({ value, handleClose, clientId }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    phone: Yup.string(),
  });

  const { handleSubmit, setFieldValue, values, handleBlur } = useFormik({
    initialValues: value,
    validationSchema,
    onSubmit: async ({ phone }) => {
      if (value.phone !== phone) {
        const phoneSchema = Yup.string()
          .phone('US', false, 'Must be a valid phone number for region US')
          .required();
        if (await phoneSchema.isValid(phone)) {
          setLoading(true);
          dispatch(
            updateClientDataEffect({ clientId, phone }, (err) => {
              if (!err) {
                showSuccessMessage('Client primary phone updated');
              }
              setLoading(false);
              handleClose();
            }),
          );
        } else {
          showErrorMessage('Must be a valid phone number for region US');
        }
      }
    },
  });

  return (
    <form className={styles.main} onSubmit={handleSubmit}>
      <PhoneInput
        name="phone"
        value={values.phone}
        className={styles.phone}
        onChange={(e, val) => setFieldValue('phone', val)}
        onBlur={handleBlur}
        disabled={loading}
      />
      <div className={styles.actionButtons}>
        <Button type="submit" title={'Update'} className={styles.update} isPending={loading} />
        <Button title={'Cancel'} className={styles.cancel} onClick={handleClose} />
      </div>
    </form>
  );
};
