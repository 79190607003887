import { apiServer } from 'settings/web-services/api';

export function getAllClauseTemplates(cfg) {
  return apiServer.get('/fillDocument/dynamicForm/getPublishedFormClauses', { params: cfg });
}

export function getClauseTemplateById({ id }) {
  return apiServer.get(`/transaction/templates/clause-templates/${id}`);
}

export function deleteClauseTemplate({ id }) {
  return apiServer.delete(`/fillDocument/dynamicForm/formClause/${id}`);
}

export function createClauseTemplate(cfg) {
  return apiServer.post('/fillDocument/dynamicForm/formClause', cfg);
}

export function updateClauseTemplate(cfg, { id }) {
  return apiServer.patch(`/fillDocument/dynamicForm/formClause/${id}`, cfg);
}
