import React, { FC, ReactElement, useCallback, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { Filters } from './Filters';
import moment from 'moment/moment';
import { ActivityCard, Activity } from '../ActivityCard';
import { ShareIntelModal } from '../ShareIntelModal';

export const ActivityFeed: FC = (): ReactElement => {
  const [openShareModal, setOpenShareModal] = useState<boolean>(false);
  const data = useMemo((): Activity[] => {
    return [
      {
        id: '1',
        user: {
          name: 'Sonya Huang',
          role: 'Agent',
          avatar:
            'https://s3-alpha-sig.figma.com/img/9926/3fdc/037976a953eb501ddb20e0be8af1e6e8?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=DnxXzQEpkq4ZZ-9EmU4vRESu7v0JcPoEENcewaJzrVxuWMdLx6WkhZJAE95zCtPQFMAt5tKJEE0QfNkXlnJ0~XF1E5BWMprZpGHQjaE~b5rnVeqmg3fp15YfgUCgRrDvtC6Sn7CWV9nfyHgjGg5DTa9XdMYaYhJf~GP~SSZcdbD~vVHfe-SPQtiGrjk5O0WkGKXQ1K1HvLwwJk99dmmGWv4JPKwcbgdwpJYw-2xuvx8i1-0VB1cRg4KE0x97aKi5xe-tRnhwhX0VyuSD4xqyOEibg28-q3r3L1rPlbsh8HLxTluaw2vL~IuW-0DGcCJxYn4CpKiSdh6hWW9jB48Ygw__',
        },
        createdAt: moment().subtract(15, 'seconds').toISOString(),
        title: 'My favorite listing this week',
        description:
          'Classic old school West Loop loft space with privacy views - neither bedroom is fully enclosed',
        images: [
          'https://s3-alpha-sig.figma.com/img/6545/9971/58a8b60acf6b2c0808c4217d7b3c7bd6?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=NMg03xFQks6eGKNAExcwF9oEfXL2xH3Y2RKM0EDH2eWePMJpzywxrUjAytTLNSidJmeDb1Jhyb2hrQfKXtH4mmE1D~ng8xj8iESZwW3n~kb7sZl-emE5SRE-9C5amn9DcO7iFChIQsCbahV27bC4Tx7oGgMgEYBHA4miE1QmUh1JbbNdNFY2dRGdxwuh7sLs1p1Kb23T6fFx0Xh9tzf5FK4VON~U4sXKoqYjDYu9Ik6u9W~R5M~Zr8-JDMnOufhyJQj1ymnbzMgXqWnUJ1~LqcCA4vJaIMbjk2dftQSil1eXs4q9vNgofE3KlnzisgXwXumkPY-E5CrdPqrSFUQJhg__',
          'https://s3-alpha-sig.figma.com/img/5eb6/74a6/eaf4db1355b6d4ccb83e0a74a2b2eda3?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Q7wLsCOb0SSFAD4Yc7rsXrvIAmerjy~ITWafFk~6JR1c1fQ6ORIfSOjV~HF18sqIFXYT2AZAs8OQpmd9yRiYGJGo-bnZ4WiDxxiKlUxss7rL29zNy40kCQe1Ym0Ppt0O9pzbN9xl9Yc4SZ2nnDNH~H72BGhRQeadkrYsum5puiJcFfG3ncwdHAHbU45dKpOc6s3W1JqmU1YUsfXz9da8B9Pgy0Tf7emzCG3iWIK0gUSHySPdI0QbuXPFjJ3N9GGAnXX5yRRwZCnD6rwUSu~FOQxyi5VeHybVxPjMZqVYldmnPvrQ4IttgRn2h1lLfCSP9jsSr0jwcF3yhtU4UzZIKw__',
          'https://s3-alpha-sig.figma.com/img/35ff/7685/ddfbba612ef287af4c8b05512547c1f9?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=OgV-BNU6Y1mUMtjCS7TRozRvbjGVUnby7MjyEw-qjGgnfkFOh-xZK3qE2~D95dwsVdH7Erxit~Im9x~rvWqbnNb~RQl5BJEtrTRQ2oKw0JYvkVffxlNKDezPR~xRmnVV4C0xNiv2sBnPsZsQ7TznmDjKtuFy~fEdzXzwgprhzRtgVinvAatwK98gtdpooDnN3yDcvTHYhdDFQucPaCmrMXgq-equJeJ70znDktavk8R4sdbbIz7MT9FQrf8mbK6IJ0lf6Eg87B0ZJpsdQBqcgb01TXMhZIsZQ84lLVJ~U0X44uz2DmMgDbqnUCQ-m0oRQNRLw8jntUZhhfRZPOG~Vg__',
        ],
        publishedTo: { type: 'AREA', data: ['Chicago, IL', 'Naperville, IL'] },
      },
      {
        id: '2',
        user: {
          name: 'Sonya Huang',
          role: 'Agent',
          avatar:
            'https://s3-alpha-sig.figma.com/img/9926/3fdc/037976a953eb501ddb20e0be8af1e6e8?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=DnxXzQEpkq4ZZ-9EmU4vRESu7v0JcPoEENcewaJzrVxuWMdLx6WkhZJAE95zCtPQFMAt5tKJEE0QfNkXlnJ0~XF1E5BWMprZpGHQjaE~b5rnVeqmg3fp15YfgUCgRrDvtC6Sn7CWV9nfyHgjGg5DTa9XdMYaYhJf~GP~SSZcdbD~vVHfe-SPQtiGrjk5O0WkGKXQ1K1HvLwwJk99dmmGWv4JPKwcbgdwpJYw-2xuvx8i1-0VB1cRg4KE0x97aKi5xe-tRnhwhX0VyuSD4xqyOEibg28-q3r3L1rPlbsh8HLxTluaw2vL~IuW-0DGcCJxYn4CpKiSdh6hWW9jB48Ygw__',
        },
        createdAt: moment().subtract(30, 'minutes').toISOString(),
        title: 'Discover Your Dream Home in Chicago!',
        description:
          "Check out this stunning 3-bedroom condo in the heart of Lincoln Park. With a modern kitchen, spacious living area, and breathtaking views of Lake Michigan, this gem won't last long!\n📅 Open House: This Saturday, 1-4 PM",
        images: [
          'https://s3-alpha-sig.figma.com/img/efde/0bbd/e539ba5f6b52f0d6856626c24ca65a59?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=EOIaqT4rewHNlZA5DPpyYH0jWkqMCytp5nD--1PJfxHbUWnecWE5XMEHpOJF9IzuwyvKkI0Qm1wr1UCLYRiyw29vESX-ikGODk3Pf8fBABTLwmKdyutHcaXQ4aRQHzCUB4rDFX0VBq1Ehmj6DOmuu7AVyYbTkkupL3tvv9eLKoKlMJBLJv5yZwg5w~LqGUjoAT8wrwV3rCT6HjqSK95dSQX-BGamG1XmVLDDVjgRWleZEnmCEN00EhCEjujgDySyNaMmNg77d3I2Xm-6gC1OjJAn6JqMmUnSVTAJG3ac~KF8yliMMgGAQmbmdjyXxCzDxRdPaYbhJD2dhDbsKdEU2A__',
          'https://s3-alpha-sig.figma.com/img/4ec3/0d76/b848c75ff6e577b1223a980de1d6bac2?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=kMJveESbwr5Sa980AP7YujurK46RE4qLMAStQTCKukrsHIBjhudsP4BriVoOV3rT9l8s5PAJ8L1k4HO1HvGXqcup-Nr9XbVW0sF5G16j3yBcNeZGP1AN695bM~aXpuUeYOJTF2qgZKib7s5Bw86qEOsb3iwyiNR9Jp~2cTT55NBkfZ-~3zGH0mBDkoWNJWG4T8JCFbqgN1izAki5BTiebakPunk6RFOS2wdPY7nXdofXjboBb~TiJl~HxO9Myq2h5tR9FqCuSWaha04YzX~D7NCi40oEGNUKQESfgLLCr95kRXVYrjc03anIh5Vt0fJ3PES7wIeZY0ODgftbODkPmA__',
          'https://s3-alpha-sig.figma.com/img/1419/d4ee/344acffbf7b9228985dbdfd66e659043?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=LM34FixnDJW1PZYI1lE8SPWDJzvkNwqQ3uWbSzUZvbw4L4P6FX4UBEo8LyT9rHrnV-SrrKZl3wNd-8qgfjUsiK-OLGrrYhRHQAimLElzwDVUiTyUrI8L-Iu0MS6TCyRl0Xyw3JNTZgEFqKIT1wb50zEKpuKlCkaNW72Rg0lmAUktN4AEfDmx~J00BCjmaUygkmh7l5YDkfaAnmzjRxn8ZQUjiDKD2EKkdLnSNQUZlzcSIarNsuGyWBaIKS0b9nKSg1E0~Ly94LoOHqp~NdDlp87T1kkwOCvYBAd8kODm~pIgN1fzhhWFsu~AyZpItbOgEz-Fx2hZ9q5ui32JDur0pw__',
          'https://s3-alpha-sig.figma.com/img/1a25/27f5/a29fe49f335d4512cd260953798457b2?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bXczateCLoUfY3siHW33bQ12UB4fTrpnng-ehm2Dps9cO54Jcy-CsgUEKgqNBkhqSDnwIx9PqHCuQv9CNnRt7m~hnlOziMSRBrhctWAqW9Nr5tvP6QFlOmGfLdhARGq~yZGOORMHsliCImsccPZSYxVOWyJFgyZMiRisY~VSwei-dY9zTfYD9f1jeaRJ2KM587r0F8oCiGib9FJr6ISyIYw9SrZuLHCH6eKGc5AjQzF-LreGT6RWcLNPE5vAQiSOVJj2BN1S1eJI6sjDoSQEztdBKz3N3A6teCOClxePNoNVYUEquZulaiM~IgSB66A2~bAYhaj6TAfqSUMIoDq~OQ__',
          'https://s3-alpha-sig.figma.com/img/efde/0bbd/e539ba5f6b52f0d6856626c24ca65a59?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=EOIaqT4rewHNlZA5DPpyYH0jWkqMCytp5nD--1PJfxHbUWnecWE5XMEHpOJF9IzuwyvKkI0Qm1wr1UCLYRiyw29vESX-ikGODk3Pf8fBABTLwmKdyutHcaXQ4aRQHzCUB4rDFX0VBq1Ehmj6DOmuu7AVyYbTkkupL3tvv9eLKoKlMJBLJv5yZwg5w~LqGUjoAT8wrwV3rCT6HjqSK95dSQX-BGamG1XmVLDDVjgRWleZEnmCEN00EhCEjujgDySyNaMmNg77d3I2Xm-6gC1OjJAn6JqMmUnSVTAJG3ac~KF8yliMMgGAQmbmdjyXxCzDxRdPaYbhJD2dhDbsKdEU2A__',
          'https://s3-alpha-sig.figma.com/img/4ec3/0d76/b848c75ff6e577b1223a980de1d6bac2?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=kMJveESbwr5Sa980AP7YujurK46RE4qLMAStQTCKukrsHIBjhudsP4BriVoOV3rT9l8s5PAJ8L1k4HO1HvGXqcup-Nr9XbVW0sF5G16j3yBcNeZGP1AN695bM~aXpuUeYOJTF2qgZKib7s5Bw86qEOsb3iwyiNR9Jp~2cTT55NBkfZ-~3zGH0mBDkoWNJWG4T8JCFbqgN1izAki5BTiebakPunk6RFOS2wdPY7nXdofXjboBb~TiJl~HxO9Myq2h5tR9FqCuSWaha04YzX~D7NCi40oEGNUKQESfgLLCr95kRXVYrjc03anIh5Vt0fJ3PES7wIeZY0ODgftbODkPmA__',
          'https://s3-alpha-sig.figma.com/img/1419/d4ee/344acffbf7b9228985dbdfd66e659043?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=LM34FixnDJW1PZYI1lE8SPWDJzvkNwqQ3uWbSzUZvbw4L4P6FX4UBEo8LyT9rHrnV-SrrKZl3wNd-8qgfjUsiK-OLGrrYhRHQAimLElzwDVUiTyUrI8L-Iu0MS6TCyRl0Xyw3JNTZgEFqKIT1wb50zEKpuKlCkaNW72Rg0lmAUktN4AEfDmx~J00BCjmaUygkmh7l5YDkfaAnmzjRxn8ZQUjiDKD2EKkdLnSNQUZlzcSIarNsuGyWBaIKS0b9nKSg1E0~Ly94LoOHqp~NdDlp87T1kkwOCvYBAd8kODm~pIgN1fzhhWFsu~AyZpItbOgEz-Fx2hZ9q5ui32JDur0pw__',
          'https://s3-alpha-sig.figma.com/img/1a25/27f5/a29fe49f335d4512cd260953798457b2?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bXczateCLoUfY3siHW33bQ12UB4fTrpnng-ehm2Dps9cO54Jcy-CsgUEKgqNBkhqSDnwIx9PqHCuQv9CNnRt7m~hnlOziMSRBrhctWAqW9Nr5tvP6QFlOmGfLdhARGq~yZGOORMHsliCImsccPZSYxVOWyJFgyZMiRisY~VSwei-dY9zTfYD9f1jeaRJ2KM587r0F8oCiGib9FJr6ISyIYw9SrZuLHCH6eKGc5AjQzF-LreGT6RWcLNPE5vAQiSOVJj2BN1S1eJI6sjDoSQEztdBKz3N3A6teCOClxePNoNVYUEquZulaiM~IgSB66A2~bAYhaj6TAfqSUMIoDq~OQ__',
          'https://s3-alpha-sig.figma.com/img/efde/0bbd/e539ba5f6b52f0d6856626c24ca65a59?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=EOIaqT4rewHNlZA5DPpyYH0jWkqMCytp5nD--1PJfxHbUWnecWE5XMEHpOJF9IzuwyvKkI0Qm1wr1UCLYRiyw29vESX-ikGODk3Pf8fBABTLwmKdyutHcaXQ4aRQHzCUB4rDFX0VBq1Ehmj6DOmuu7AVyYbTkkupL3tvv9eLKoKlMJBLJv5yZwg5w~LqGUjoAT8wrwV3rCT6HjqSK95dSQX-BGamG1XmVLDDVjgRWleZEnmCEN00EhCEjujgDySyNaMmNg77d3I2Xm-6gC1OjJAn6JqMmUnSVTAJG3ac~KF8yliMMgGAQmbmdjyXxCzDxRdPaYbhJD2dhDbsKdEU2A__',
          'https://s3-alpha-sig.figma.com/img/4ec3/0d76/b848c75ff6e577b1223a980de1d6bac2?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=kMJveESbwr5Sa980AP7YujurK46RE4qLMAStQTCKukrsHIBjhudsP4BriVoOV3rT9l8s5PAJ8L1k4HO1HvGXqcup-Nr9XbVW0sF5G16j3yBcNeZGP1AN695bM~aXpuUeYOJTF2qgZKib7s5Bw86qEOsb3iwyiNR9Jp~2cTT55NBkfZ-~3zGH0mBDkoWNJWG4T8JCFbqgN1izAki5BTiebakPunk6RFOS2wdPY7nXdofXjboBb~TiJl~HxO9Myq2h5tR9FqCuSWaha04YzX~D7NCi40oEGNUKQESfgLLCr95kRXVYrjc03anIh5Vt0fJ3PES7wIeZY0ODgftbODkPmA__',
          'https://s3-alpha-sig.figma.com/img/1419/d4ee/344acffbf7b9228985dbdfd66e659043?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=LM34FixnDJW1PZYI1lE8SPWDJzvkNwqQ3uWbSzUZvbw4L4P6FX4UBEo8LyT9rHrnV-SrrKZl3wNd-8qgfjUsiK-OLGrrYhRHQAimLElzwDVUiTyUrI8L-Iu0MS6TCyRl0Xyw3JNTZgEFqKIT1wb50zEKpuKlCkaNW72Rg0lmAUktN4AEfDmx~J00BCjmaUygkmh7l5YDkfaAnmzjRxn8ZQUjiDKD2EKkdLnSNQUZlzcSIarNsuGyWBaIKS0b9nKSg1E0~Ly94LoOHqp~NdDlp87T1kkwOCvYBAd8kODm~pIgN1fzhhWFsu~AyZpItbOgEz-Fx2hZ9q5ui32JDur0pw__',
          'https://s3-alpha-sig.figma.com/img/1a25/27f5/a29fe49f335d4512cd260953798457b2?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bXczateCLoUfY3siHW33bQ12UB4fTrpnng-ehm2Dps9cO54Jcy-CsgUEKgqNBkhqSDnwIx9PqHCuQv9CNnRt7m~hnlOziMSRBrhctWAqW9Nr5tvP6QFlOmGfLdhARGq~yZGOORMHsliCImsccPZSYxVOWyJFgyZMiRisY~VSwei-dY9zTfYD9f1jeaRJ2KM587r0F8oCiGib9FJr6ISyIYw9SrZuLHCH6eKGc5AjQzF-LreGT6RWcLNPE5vAQiSOVJj2BN1S1eJI6sjDoSQEztdBKz3N3A6teCOClxePNoNVYUEquZulaiM~IgSB66A2~bAYhaj6TAfqSUMIoDq~OQ__',
          'https://s3-alpha-sig.figma.com/img/efde/0bbd/e539ba5f6b52f0d6856626c24ca65a59?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=EOIaqT4rewHNlZA5DPpyYH0jWkqMCytp5nD--1PJfxHbUWnecWE5XMEHpOJF9IzuwyvKkI0Qm1wr1UCLYRiyw29vESX-ikGODk3Pf8fBABTLwmKdyutHcaXQ4aRQHzCUB4rDFX0VBq1Ehmj6DOmuu7AVyYbTkkupL3tvv9eLKoKlMJBLJv5yZwg5w~LqGUjoAT8wrwV3rCT6HjqSK95dSQX-BGamG1XmVLDDVjgRWleZEnmCEN00EhCEjujgDySyNaMmNg77d3I2Xm-6gC1OjJAn6JqMmUnSVTAJG3ac~KF8yliMMgGAQmbmdjyXxCzDxRdPaYbhJD2dhDbsKdEU2A__',
          'https://s3-alpha-sig.figma.com/img/4ec3/0d76/b848c75ff6e577b1223a980de1d6bac2?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=kMJveESbwr5Sa980AP7YujurK46RE4qLMAStQTCKukrsHIBjhudsP4BriVoOV3rT9l8s5PAJ8L1k4HO1HvGXqcup-Nr9XbVW0sF5G16j3yBcNeZGP1AN695bM~aXpuUeYOJTF2qgZKib7s5Bw86qEOsb3iwyiNR9Jp~2cTT55NBkfZ-~3zGH0mBDkoWNJWG4T8JCFbqgN1izAki5BTiebakPunk6RFOS2wdPY7nXdofXjboBb~TiJl~HxO9Myq2h5tR9FqCuSWaha04YzX~D7NCi40oEGNUKQESfgLLCr95kRXVYrjc03anIh5Vt0fJ3PES7wIeZY0ODgftbODkPmA__',
          'https://s3-alpha-sig.figma.com/img/1419/d4ee/344acffbf7b9228985dbdfd66e659043?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=LM34FixnDJW1PZYI1lE8SPWDJzvkNwqQ3uWbSzUZvbw4L4P6FX4UBEo8LyT9rHrnV-SrrKZl3wNd-8qgfjUsiK-OLGrrYhRHQAimLElzwDVUiTyUrI8L-Iu0MS6TCyRl0Xyw3JNTZgEFqKIT1wb50zEKpuKlCkaNW72Rg0lmAUktN4AEfDmx~J00BCjmaUygkmh7l5YDkfaAnmzjRxn8ZQUjiDKD2EKkdLnSNQUZlzcSIarNsuGyWBaIKS0b9nKSg1E0~Ly94LoOHqp~NdDlp87T1kkwOCvYBAd8kODm~pIgN1fzhhWFsu~AyZpItbOgEz-Fx2hZ9q5ui32JDur0pw__',
          'https://s3-alpha-sig.figma.com/img/1a25/27f5/a29fe49f335d4512cd260953798457b2?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bXczateCLoUfY3siHW33bQ12UB4fTrpnng-ehm2Dps9cO54Jcy-CsgUEKgqNBkhqSDnwIx9PqHCuQv9CNnRt7m~hnlOziMSRBrhctWAqW9Nr5tvP6QFlOmGfLdhARGq~yZGOORMHsliCImsccPZSYxVOWyJFgyZMiRisY~VSwei-dY9zTfYD9f1jeaRJ2KM587r0F8oCiGib9FJr6ISyIYw9SrZuLHCH6eKGc5AjQzF-LreGT6RWcLNPE5vAQiSOVJj2BN1S1eJI6sjDoSQEztdBKz3N3A6teCOClxePNoNVYUEquZulaiM~IgSB66A2~bAYhaj6TAfqSUMIoDq~OQ__',
          'https://s3-alpha-sig.figma.com/img/efde/0bbd/e539ba5f6b52f0d6856626c24ca65a59?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=EOIaqT4rewHNlZA5DPpyYH0jWkqMCytp5nD--1PJfxHbUWnecWE5XMEHpOJF9IzuwyvKkI0Qm1wr1UCLYRiyw29vESX-ikGODk3Pf8fBABTLwmKdyutHcaXQ4aRQHzCUB4rDFX0VBq1Ehmj6DOmuu7AVyYbTkkupL3tvv9eLKoKlMJBLJv5yZwg5w~LqGUjoAT8wrwV3rCT6HjqSK95dSQX-BGamG1XmVLDDVjgRWleZEnmCEN00EhCEjujgDySyNaMmNg77d3I2Xm-6gC1OjJAn6JqMmUnSVTAJG3ac~KF8yliMMgGAQmbmdjyXxCzDxRdPaYbhJD2dhDbsKdEU2A__',
          'https://s3-alpha-sig.figma.com/img/4ec3/0d76/b848c75ff6e577b1223a980de1d6bac2?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=kMJveESbwr5Sa980AP7YujurK46RE4qLMAStQTCKukrsHIBjhudsP4BriVoOV3rT9l8s5PAJ8L1k4HO1HvGXqcup-Nr9XbVW0sF5G16j3yBcNeZGP1AN695bM~aXpuUeYOJTF2qgZKib7s5Bw86qEOsb3iwyiNR9Jp~2cTT55NBkfZ-~3zGH0mBDkoWNJWG4T8JCFbqgN1izAki5BTiebakPunk6RFOS2wdPY7nXdofXjboBb~TiJl~HxO9Myq2h5tR9FqCuSWaha04YzX~D7NCi40oEGNUKQESfgLLCr95kRXVYrjc03anIh5Vt0fJ3PES7wIeZY0ODgftbODkPmA__',
        ],
        publishedTo: 'ALL',
      },
      {
        id: '3',
        user: {
          name: 'Sonya Huang',
          role: 'Agent',
          avatar:
            'https://s3-alpha-sig.figma.com/img/9926/3fdc/037976a953eb501ddb20e0be8af1e6e8?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=DnxXzQEpkq4ZZ-9EmU4vRESu7v0JcPoEENcewaJzrVxuWMdLx6WkhZJAE95zCtPQFMAt5tKJEE0QfNkXlnJ0~XF1E5BWMprZpGHQjaE~b5rnVeqmg3fp15YfgUCgRrDvtC6Sn7CWV9nfyHgjGg5DTa9XdMYaYhJf~GP~SSZcdbD~vVHfe-SPQtiGrjk5O0WkGKXQ1K1HvLwwJk99dmmGWv4JPKwcbgdwpJYw-2xuvx8i1-0VB1cRg4KE0x97aKi5xe-tRnhwhX0VyuSD4xqyOEibg28-q3r3L1rPlbsh8HLxTluaw2vL~IuW-0DGcCJxYn4CpKiSdh6hWW9jB48Ygw__',
        },
        createdAt: moment().subtract(2, 'hour').toISOString(),
        title: 'Market is slowly recovering 🎉📈',
        description: 'It may be a good time to list 995 Canal st property back on the market.',
        images: [],
        publishedTo: { type: 'CLIENT', data: ['Rebecca Torres', 'John Doe'] },
      },
      {
        id: '4',
        user: {
          name: 'Sonya Huang',
          role: 'Agent',
          avatar:
            'https://s3-alpha-sig.figma.com/img/9926/3fdc/037976a953eb501ddb20e0be8af1e6e8?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=DnxXzQEpkq4ZZ-9EmU4vRESu7v0JcPoEENcewaJzrVxuWMdLx6WkhZJAE95zCtPQFMAt5tKJEE0QfNkXlnJ0~XF1E5BWMprZpGHQjaE~b5rnVeqmg3fp15YfgUCgRrDvtC6Sn7CWV9nfyHgjGg5DTa9XdMYaYhJf~GP~SSZcdbD~vVHfe-SPQtiGrjk5O0WkGKXQ1K1HvLwwJk99dmmGWv4JPKwcbgdwpJYw-2xuvx8i1-0VB1cRg4KE0x97aKi5xe-tRnhwhX0VyuSD4xqyOEibg28-q3r3L1rPlbsh8HLxTluaw2vL~IuW-0DGcCJxYn4CpKiSdh6hWW9jB48Ygw__',
        },
        createdAt: moment().subtract(7, 'hour').toISOString(),
        title: '✨ Just Listed! ✨',
        description:
          'Step into luxury with this elegant 4-bedroom home in the Gold Coast. Featuring high ceilings, hardwood floors, and a private backyard oasis.',
        images: [
          'https://s3-alpha-sig.figma.com/img/dd00/7726/c99d08c0d2d186972b1e75ed79e238ae?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=d2Jd3g6NC100-CF1yRF2JrgRglm-6QoSS9U1nVzt1g6qBH7TWgW4TD9j~945xepYwOdPoh3VE48w7TJDg7y34GlLDaQb2TiXIPs0b-6pH4OlGZvMMPPB3iPNPaRvl8TEWTezYEPSgN3bHTyanqhwvX6OJHg8~sjNrSYJ5lDHAVItYn4IDlFcSNQbgjdoOJW33ss23-hvEpSpR8ZHcplG~oJYRblSceOTyMDhfmCKUoAmZvWZIIE~w60IwKbEWSKOJk4mZz3BOBV3NS5w46kcsPKCl4fU8nhPqXtSATujHoxmHrUaNx8s-2A17toJ7gm3JVPSoD~GWisS5hXcbTyjLw__',
        ],
        publishedTo: { type: 'AREA', data: ['Chicago, IL'] },
      },
      {
        id: '5',
        user: {
          name: 'Sonya Huang',
          role: 'Agent',
          avatar:
            'https://s3-alpha-sig.figma.com/img/9926/3fdc/037976a953eb501ddb20e0be8af1e6e8?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=DnxXzQEpkq4ZZ-9EmU4vRESu7v0JcPoEENcewaJzrVxuWMdLx6WkhZJAE95zCtPQFMAt5tKJEE0QfNkXlnJ0~XF1E5BWMprZpGHQjaE~b5rnVeqmg3fp15YfgUCgRrDvtC6Sn7CWV9nfyHgjGg5DTa9XdMYaYhJf~GP~SSZcdbD~vVHfe-SPQtiGrjk5O0WkGKXQ1K1HvLwwJk99dmmGWv4JPKwcbgdwpJYw-2xuvx8i1-0VB1cRg4KE0x97aKi5xe-tRnhwhX0VyuSD4xqyOEibg28-q3r3L1rPlbsh8HLxTluaw2vL~IuW-0DGcCJxYn4CpKiSdh6hWW9jB48Ygw__',
        },
        createdAt: moment().subtract(2, 'day').toISOString(),
        title: 'Chicago Real Estate Opportunity',
        description:
          "Prime commercial property in the Loop. With over 10,000 sq. ft. of office space and a central location, it's perfect for your next business venture.",
        images: [],
        publishedTo: { type: 'CLIENT', data: ['John Doe'] },
      },
      {
        id: '6',
        user: {
          name: 'Sonya Huang',
          role: 'Agent',
          avatar:
            'https://s3-alpha-sig.figma.com/img/9926/3fdc/037976a953eb501ddb20e0be8af1e6e8?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=DnxXzQEpkq4ZZ-9EmU4vRESu7v0JcPoEENcewaJzrVxuWMdLx6WkhZJAE95zCtPQFMAt5tKJEE0QfNkXlnJ0~XF1E5BWMprZpGHQjaE~b5rnVeqmg3fp15YfgUCgRrDvtC6Sn7CWV9nfyHgjGg5DTa9XdMYaYhJf~GP~SSZcdbD~vVHfe-SPQtiGrjk5O0WkGKXQ1K1HvLwwJk99dmmGWv4JPKwcbgdwpJYw-2xuvx8i1-0VB1cRg4KE0x97aKi5xe-tRnhwhX0VyuSD4xqyOEibg28-q3r3L1rPlbsh8HLxTluaw2vL~IuW-0DGcCJxYn4CpKiSdh6hWW9jB48Ygw__',
        },
        createdAt: moment().subtract(3, 'day').toISOString(),
        title: 'Modern Living in Chicago',
        description:
          "Explore this gorgeous 3-bedroom home in Wicker Park. From the chef's kitchen to the cozy fireplace, every detail is designed for comfort and style.",
        images: [],
        publishedTo: {
          type: 'CLIENT',
          data: [
            'Rebecca Torres',
            'John Doe',
            'Alissa Zimmerman',
            'Sarah Smith',
            'David Johnson',
            'Emily Davis',
          ],
        },
      },
      {
        id: '7',
        user: {
          name: 'Sonya Huang',
          role: 'Agent',
          avatar:
            'https://s3-alpha-sig.figma.com/img/9926/3fdc/037976a953eb501ddb20e0be8af1e6e8?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=DnxXzQEpkq4ZZ-9EmU4vRESu7v0JcPoEENcewaJzrVxuWMdLx6WkhZJAE95zCtPQFMAt5tKJEE0QfNkXlnJ0~XF1E5BWMprZpGHQjaE~b5rnVeqmg3fp15YfgUCgRrDvtC6Sn7CWV9nfyHgjGg5DTa9XdMYaYhJf~GP~SSZcdbD~vVHfe-SPQtiGrjk5O0WkGKXQ1K1HvLwwJk99dmmGWv4JPKwcbgdwpJYw-2xuvx8i1-0VB1cRg4KE0x97aKi5xe-tRnhwhX0VyuSD4xqyOEibg28-q3r3L1rPlbsh8HLxTluaw2vL~IuW-0DGcCJxYn4CpKiSdh6hWW9jB48Ygw__',
        },
        createdAt: moment().subtract(1, 'week').toISOString(),
        title: 'New Listing Alert! 🏡',
        description:
          'Beautiful 2-bedroom loft in River North with exposed brick, large windows, and a rooftop deck. Perfect for city living! 🌇',
        images: [
          'https://s3-alpha-sig.figma.com/img/c332/2292/0b97f11423b766d8a35bdad559386e08?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=GqmqpHXSejmuHiv~JystEL0mIq5K3sW9OW~0EN4yab-xU546ZKTSOMtEGroVFb7adeGVAD~1iFHuYtnHU4GXpj38D0rxq7ocsYMryX9J7A2rTlAzRdxfCe0j0vLv9R31trDzITFzN7Zdc~2hsX3Kw~O6ALOK6nAoAYXX-oG755Wr~bYTgf4rTltS2NhkWf~oeHBoEunF2Hb0jkz-7Sl6tt7wmnq~aqeEOhkx7WWTaJeZFTVNQwyTYMlD7WiMpPXnojQdgYfDWJFY4c7L~PgJuz7TH-SA7AHfVVnOVuYTGlmmhnWE8Z6blZdRcGtKGPUXH2AhtkMCPBQO48OMcLTq~w__',
          'https://s3-alpha-sig.figma.com/img/44a6/8a46/34c1b9d9b2324405855e18c905cb0681?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=XW14SlESdM218MP~LZYozsrlB0R06JinvcSJQTgIBLW3VmkZ9Ary0l9TrjfRSGJiAo6VDkaq0zuB74RUg~lcxj79zfz28rL8Z-EfvfnAe~76obzRdx~Q3o10gdAoLAnGcxI6GSFpY02idxetilt-GEnO8rxrQdUMPA-TByZE71Qd-AaluWqCV4pb4BvSGO3V~k7ISpeH2qHA0qW9zzdevcJGyUDeP93EuwoWJC8g2hPltQfkgJoNhTnQmVh90oY65nCtNMbHWkqmNkpSSm8F9O40VEaIxX6UDBNzbW~7rGY5M0HXWSqzNYqw4fxmzb2OVJ8tdaw-MRN2WlP71e3Tkg__',
        ],
        publishedTo: 'ALL',
      },
    ];
  }, []);

  const renderFeed = useCallback(() => {
    if (data.length) {
      return null;
    }

    return (
      <div className={styles.noDataFound}>
        <p className={styles.noDataTitle}>You’re all caught up!</p>
        <p className={styles.noDataDescription}>You have no new posts in the Intel page.</p>
      </div>
    );
  }, [data]);

  const handleShareIntel = () => {
    setOpenShareModal(true);
  };

  const handleCloseShareModal = () => {
    setOpenShareModal(false);
  };

  return (
    <div className={styles.pageContent}>
      <div className={styles.activityWrapper}>
        {renderFeed()}

        {data.map((activity) => (
          <ActivityCard key={activity.id} activity={activity} />
        ))}
      </div>
      <div className={styles.filterWrapper}>
        <button className={styles.shareIntelButton} onClick={handleShareIntel}>
          Share Intel
        </button>
        <ShareIntelModal open={openShareModal} onClose={handleCloseShareModal} />
        <Filters />
      </div>
    </div>
  );
};
