import { combineReducers } from 'redux';
import propertyReferenceDetails from './propertyReferenceDetails';
import propertyReferenceData from './propertyReferenceData';
import propertyReferenceStats from './propertyReferenceStats';
import propertyReferenceNeighborhood from './propertyReferenceNeighborhood';

export default combineReducers({
  propertyReferenceData,
  propertyReferenceDetails,
  propertyReferenceStats,
  propertyReferenceNeighborhood,
});
