import { get } from 'lodash-es';
import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import {
  requestGetOfferAggregateByUserId,
  resetOfferAggregateAction,
  setOffersArchiveAction,
  setOffersArchiveIdAction,
  setOffersFiltersAction,
  setOffersFiltersAppliedAction,
} from 'store/actions/offerAggregate';
import { appLogoutAction } from 'store/actions/app';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
  isArchive: false,
  offerId: null,
  isFiltersApplied: false,
  offersFilter: {},
};

export default handleActions(
  {
    [requestGetOfferAggregateByUserId as any]: (state, { payload }) => {
      return {
        ...state,
        state: payload.state,
        data: get(payload, 'data.value', initialData.data),
        meta: get(payload, 'meta', initialData.meta),
      };
    },
    [setOffersArchiveAction as any]: (state, { payload }) => ({
      ...state,
      isArchive: !!payload,
    }),
    [setOffersFiltersAppliedAction as any]: (state, { payload }) => ({
      ...state,
      isFiltersApplied: !!payload,
    }),

    [setOffersFiltersAction as any]: (state, { payload }) => ({
      ...state,
      offersFilter: payload,
    }),

    [setOffersArchiveIdAction as any]: (state, { payload }) => ({
      ...state,
      offerId: payload?.offerId || null,
    }),
    [resetOfferAggregateAction as any]: () => ({ ...initialData }),
    [appLogoutAction as any]: () => initialData,
  },
  initialData,
);
