export const criteriaTypes = {
  ForSale: 'For Sale',
  ForRent: 'For Rent',
  Sold: 'Sold',
};

export const criteriaTypesWithoutRent = {
  ForSale: 'For Sale',
  Sold: 'Sold',
};

export const rentOptions = [
  { id: 1, name: 'Single Family', value: 'RL_Single' },
  { id: 2, name: 'Apartment', value: 'RL_Apartment' },
  { id: 3, name: 'Condo', value: 'RL_Condo' },
  { id: 4, name: 'Townhouse', value: 'RL_Townhouse' },
  { id: 5, name: 'Multi Family', value: 'RL_MultiFamily' },
  { id: 7, name: 'Other', value: 'RL_Other' },
  { id: 9, name: 'Commercial Lease', value: 'CommercialLease' },
];

export const defaultPropertyTypeSelectionForRent = [
  'RL_Apartment',
  'RL_Condo',
  'RL_Single',
  'RL_Townhouse',
];
