import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { UploadDocuments as CommonUploadDocuments } from 'components/Transactions';
import { uploadTransactionTaskDocumentsEffect } from 'store/effects/transactions';
import { uploadCampaignTaskDocumentsEffect } from 'store/effects/radarKits';

import { getTransactionTaskSelector } from 'store/selectors/transactionTask';

import styles from './styles.module.scss';

const UploadDocument = (props) => {
  const { className, uploadButton, afterUploadCallback } = props;
  const dispatch = useDispatch();
  const { taskId, task } = useSelector(getTransactionTaskSelector);

  const onSaveHandler = (newValues, cb) => {
    const config = {
      taskId,
      documents: newValues,
      category: task?.Category,
      assigneeList: task?.AssigneeList,
      ...(task?.CampaignId && { campaignId: task.CampaignId }),
    };
    const effect = task?.CampaignId
      ? uploadCampaignTaskDocumentsEffect
      : uploadTransactionTaskDocumentsEffect;
    dispatch(
      effect(config, {}, (err) => {
        cb(err);
        if (!err) afterUploadCallback();
      }),
    );
  };

  return (
    <div testid="upload_document" className={classNames(styles.uploadDocument, className)}>
      <CommonUploadDocuments
        onSave={onSaveHandler}
        uploadIconClassName={styles.uploadIcon}
        uploadButton={uploadButton}
        withCategory
        withPermissions
        isCategoryOptional
        fillDocumentName={true}
      />
    </div>
  );
};

UploadDocument.propTypes = {
  className: PropTypes.string,
  afterUploadCallback: PropTypes.func,
};

UploadDocument.defaultProps = {
  className: '',
  uploadButton: undefined,
  afterUploadCallback: () => {},
};

export default UploadDocument;
