import styles from './styles.module.scss';
import { Avatar, Button } from 'components';
import { convertNameToAvatarPlaceholder, getIfExistThread, subtractTimeZone } from 'helpers';
import { Icons } from '../icons';
import {
  changeMessagesDrawerTypeAction,
  openMessagesDrawerAction,
} from 'store/actions/drawers/messages';
import { AGENT_TYPE, DRAWER_MESSAGES_TYPES } from 'settings/constants/drawers';
import { useDispatch, useSelector } from 'react-redux';
import { appManageClientDrawerAction } from 'store/actions/app';
import FormattedPhone from 'components/FormattedPhone';
import classNames from 'classnames';
import moment from 'moment';
import { get } from 'lodash-es';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { EmailForm } from './components/EmailForm';
import { PhoneForm } from './components/PhoneForm';
import { BirthdayForm } from './components/BirthdayForm';
import { Status } from './components/Status';
import { NameForm } from './components/NameForm';
import { getAgentTypeSelector, getUserId } from 'store/selectors/user';
import { AssignedTo } from './components/AssignedTo';
import { AddressModal } from './components/AddressModal';
import { Tooltip } from 'components-antd';
import { getThreadsListSelector, getThreadsSelector } from 'store/selectors/sockets/threads';
import { SOCKET_THREAD_TYPES } from 'settings/constants/sockets';

export const SideBar = ({ contact, isPending, isAgent, addresses }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [editEmail, setEditEmail] = useState(false);
  const [editPhone, setEditPhone] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [editName, setEditName] = useState(false);
  const [editBirthday, setEditBirthday] = useState(false);
  const [openHighlights, setOpenHighlights] = useState(false);
  const threads = useSelector(getThreadsListSelector);
  const userId = useSelector(getUserId);

  const agentType = useSelector(getAgentTypeSelector);

  const openMessageDrawer = () => {
    const existThread = getIfExistThread(
      threads,
      [contact.Id, userId],
      (thread) => thread?.Type === SOCKET_THREAD_TYPES.CHAT,
    );

    dispatch(openMessagesDrawerAction(true));
    dispatch(
      changeMessagesDrawerTypeAction({
        type: existThread ? DRAWER_MESSAGES_TYPES.CHAT : DRAWER_MESSAGES_TYPES.NEW_MESSAGE,
        params: {
          threadId: existThread?.Id || null,
          participants: [
            {
              id: contact.Id,
              name: `${contact.FirstName} ${contact.LastName || ''}`,
              value: contact.Id,
              role: contact.Role,
              avatarUrl: contact.AvatarUrl,
              firstName: contact.FirstName,
              lastName: contact.LastName,
            },
          ],
        },
      }),
    );
    dispatch(appManageClientDrawerAction(false));
  };

  const primaryAddress = addresses.find((clientAddress) => clientAddress.isPrimary);
  const primaryPhone = contact.Phones?.find((phone) => phone.IsPrimary)?.PhoneNumber || '';
  const joinedAt = isAgent ? contact.JoinedDate : contact.CreatedDate;
  const getLastActive = () => {
    const lastActive = get(contact, isAgent ? 'LastActive' : 'LastLogin');
    return lastActive ? moment(lastActive).fromNow() : '-';
  };

  const handleBack = () => {
    history.goBack();
  };

  const handleEmailClose = () => {
    setEditEmail(false);
  };

  const handlePhoneClose = () => {
    setEditPhone(false);
  };

  const handleNameClose = () => {
    setEditName(false);
  };

  const handleBirthdayClose = () => {
    setEditBirthday(false);
  };

  return (
    <div className={styles.main}>
      {isPending ? null : (
        <>
          <div className={styles.back} onClick={handleBack}>
            <Icons variant={Icons.BACK} />
          </div>
          <Avatar
            className={styles.profileAvatar}
            src={contact.AvatarUrl ?? ''}
            placeholder={convertNameToAvatarPlaceholder(
              `${contact.FirstName?.trim()} ${contact.LastName?.trim()}`,
            )}
            avatarClassName={styles.backgroundClassName}
          />
          {editName ? (
            <NameForm
              value={{ firstName: contact.FirstName, lastName: contact.LastName }}
              clientId={contact.Id}
              handleClose={handleNameClose}
            />
          ) : (
            <p className={styles.name}>
              {`${contact.FirstName} ${contact.LastName}`}{' '}
              <span className={styles.icon} onClick={() => setEditName(true)}>
                <Icons variant={Icons.EDIT} />
              </span>
            </p>
          )}
          {/* <div className={styles.actionButtons}>
            <HighlightsModal isOpen={openHighlights} onClose={() => setOpenHighlights(false)} />
            <Button
              title={'Highlights'}
              className={styles.highlights}
              icon={<Icons variant={Icons.HIGHLIGHTS} />}
              onClick={() => setOpenHighlights(true)}
            />
          </div> */}
          <div className={styles.actionButtons}>
            {/* <Button
              title={'Notes'}
              className={styles.notes}
              icon={<Icons variant={Icons.NOTES} />}
            /> */}
            <Button
              onClick={openMessageDrawer}
              title={'Message'}
              className={styles.message}
              icon={<Icons variant={Icons.MESSAGE} />}
            />
          </div>
          <div className={styles.rowsContainer}>
            <div className={styles.row}>
              <p className={styles.label}>Status</p>
              <Status contact={contact} />
            </div>
            <div className={styles.row}>
              <p className={styles.label}>
                Phone{' '}
                {!editPhone && (
                  <span className={styles.icon} onClick={() => setEditPhone(true)}>
                    <Icons variant={Icons.EDIT} />
                  </span>
                )}
              </p>
              {!editPhone && (
                <p className={styles.value}>
                  <FormattedPhone>{primaryPhone}</FormattedPhone>
                </p>
              )}
            </div>
            {editPhone && (
              <PhoneForm
                clientId={contact.Id}
                value={{ phone: primaryPhone }}
                handleClose={handlePhoneClose}
              />
            )}
            <div className={styles.row}>
              <p className={styles.label}>
                Email{' '}
                {!editEmail && (
                  <span className={styles.icon} onClick={() => setEditEmail(true)}>
                    <Icons variant={Icons.EDIT} />
                  </span>
                )}
              </p>
              {!editEmail && (
                <Tooltip
                  placement="top"
                  overlayClassName={classNames(styles.compareOfferTooltip, 'mosaikTooltip')}
                  title={<p className={styles.descInfo}>{contact.Email}</p>}
                >
                  <p className={classNames(styles.value, styles.email)}>{contact.Email}</p>
                </Tooltip>
              )}
            </div>
            {editEmail && (
              <EmailForm
                clientId={contact.Id}
                value={{ email: contact.Email }}
                handleClose={handleEmailClose}
              />
            )}
            <div className={styles.row}>
              <p className={styles.label}>
                Birthday
                {!editBirthday && (
                  <span className={styles.icon} onClick={() => setEditBirthday(true)}>
                    <Icons variant={Icons.EDIT} />
                  </span>
                )}
              </p>
              {!editBirthday && (
                <p className={styles.value}>
                  {contact?.DOB ? moment(contact?.DOB).format('MMMM D') : ''}
                </p>
              )}
            </div>
            {editBirthday && (
              <BirthdayForm
                clientId={contact.Id}
                value={{ dob: contact?.DOB ? moment(contact?.DOB) : '' }}
                handleClose={handleBirthdayClose}
              />
            )}
            <div className={styles.row}>
              <p className={styles.label}>Joined Mosaik</p>
              <p className={styles.value}>{moment(joinedAt).format('M/D/YY')}</p>
            </div>
            <div className={styles.row}>
              <p className={styles.label}>Last Active</p>
              <p className={styles.value}>{getLastActive()}</p>
            </div>
            <div className={styles.card}>
              <div className={styles.header}>
                <p className={styles.title}>Primary Address</p>
                <span onClick={() => setEditAddress(true)} className={styles.editIcon}>
                  <Icons variant={Icons.EDIT} />
                </span>
              </div>
              <AddressModal
                clientId={contact.Id}
                isOpen={editAddress}
                onClose={() => setEditAddress(false)}
                addresses={addresses}
              />
              <p className={styles.address1}>{primaryAddress?.address?.delivery_line_1 || '-'}</p>
              <p className={styles.address2}>
                {primaryAddress?.address?.last_line?.split('-')[0] || '-'}
              </p>
            </div>
            {agentType === AGENT_TYPE.Team && !isAgent ? <AssignedTo clientInfo={contact} /> : null}
          </div>
        </>
      )}
    </div>
  );
};
