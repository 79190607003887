import { ClientCASSAddress } from 'app-constants';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { AddFile } from 'components/Icons';

import classNames from 'classnames';
import { Spinner } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { getClientDashboardAddressesValuationEffect } from 'store/effects/clientDashboard';
import { getClientDashboardAddressesValuationSelector } from 'store/selectors/clientDashboard';
import { ClientDashboardEligibility } from '../../ClientDashboard';
import { AddressDetails } from './components/AddressDetails';
import { AddAddress } from './components/AddAddress';
import styles from './styles.module.scss';

type Props = {
  addresses: ClientCASSAddress[];
  setDashboardEligibility: Dispatch<SetStateAction<ClientDashboardEligibility>>;
};

export const ClientMyPortfolioDashboard: React.FC<Props> = ({
  addresses,
  setDashboardEligibility,
}) => {
  const [showAddAddressFlow, setShowAddAddressFlow] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { data: valuationData, state } = useSelector(getClientDashboardAddressesValuationSelector);
  const isLoading = state === 'pending' || state === 'idle';
  useEffect(() => {
    if (addresses?.length) {
      const addressId = addresses.map(({ Id }) => Id);
      dispatch(getClientDashboardAddressesValuationEffect({ addressId }, { silent: true }));
    }
  }, [addresses]);

  if (isLoading) {
    <div className={styles.wrapper}>
      <Spinner />
    </div>;
  }
  return (
    <div className={styles.wrapper}>
      {!showAddAddressFlow && (
        <>
          {addresses?.map((address, index) => (
            <AddressDetails
              key={`address-${index}`}
              address={address}
              valuationData={valuationData?.find(
                ({ addressId }) => Number(addressId) === Number(address.Id),
              )}
              wrapperClassname={styles.card}
            />
          ))}
          <div
            className={classNames(styles.card, styles.addAddressWrapper)}
            onClick={() => setShowAddAddressFlow(true)}
          >
            <div className={styles.content}>
              <div className={styles.iconWrapper}>
                <AddFile />
              </div>
              <div className={styles.title}>Add Address</div>
            </div>
          </div>
        </>
      )}
      {showAddAddressFlow && (
        <AddAddress
          wrapperClassname={styles.card}
          addresses={addresses}
          setShowAddAddressFlow={setShowAddAddressFlow}
          setDashboardEligibility={setDashboardEligibility}
        />
      )}
    </div>
  );
};
