import { FC, SVGAttributes } from 'react';

export const SearchIcon: FC<SVGAttributes<SVGElement>> = (props: SVGAttributes<SVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.4444 15.8889C13.4513 15.8889 15.8889 13.4513 15.8889 10.4444C15.8889 7.43756 13.4513 5 10.4444 5C7.43756 5 5 7.43756 5 10.4444C5 13.4513 7.43756 15.8889 10.4444 15.8889Z"
      stroke="#747475"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.0007 19L14.334 14.3333"
      stroke="#747475"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
