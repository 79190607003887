import PropTypes from 'prop-types';
import styles from './styles.module.scss';

import { ClientActivityLogSection } from './components/ClientActivityLogSection';

export const Activity = ({ isAgent }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <ClientActivityLogSection isAgent={isAgent} />
      </div>
    </div>
  );
};

Activity.propTypes = {
  isAgent: PropTypes.bool,
};

Activity.defaultProps = {
  isAgent: false,
};
