import { useCallback, useMemo, useState, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appOpenModalEffect } from 'store/effects';

import styles from './styles.module.scss';
import SearchCard from 'pages/Properties/Search/components/SearchCard';
import { Row } from 'components-antd';
import { Spinner, DropdownCheck, Button } from 'components';
import { useAgentSearchInstance } from 'pages/Properties/AgentFeed/hooks/useAgentSearchInstance';
import {
  selectedSearchAction,
  setCriteriaModeAction,
  setSelectedClientSavedSearchAction,
} from 'store/actions/feedv3';
import { EDIT } from 'settings/constants/mode';
import { getFeedMetaSelectorV3 } from 'store/selectors/feedv3';
import { Add } from 'components/Icons';
import { useSavedSearches } from 'pages/Properties/ClientSearch/savedSearches.hook';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import CriteriaModal from 'pages/Properties/CriteriaModal';

const SearchStatus = {
  active: 'Active',
  inactive: 'Inactive',
};

const SearchStatusItems = [
  {
    label: SearchStatus.active,
    value: SearchStatus.active,
  },
  {
    label: SearchStatus.inactive,
    value: SearchStatus.inactive,
  },
];

interface ISavedSearches {
  data: any;
  isPending?: boolean;
  setIsOpenSearchModal: (flag: boolean) => void;
  client?: any;
}

export const SavedSearches: FC<ISavedSearches> = ({
  data,
  isPending,
  setIsOpenSearchModal,
  client,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [status, setStatus] = useState(SearchStatus.active);
  const getAgentSearchInstance = useAgentSearchInstance();
  const { selectedClientSavedSearch } = useSelector(getFeedMetaSelectorV3);

  const { getRenameHandler, getInactiveHandler, getActivateHandler } = useSavedSearches({
    isClientSearch: Boolean(client),
    clientId: client?.Id,
  });

  const filterByStatus = (data, status) => {
    const res = data?.filter((item) => item.status === status);
    const mySearchIndex = res.findIndex((item) => item.name === 'My Search');
    if (mySearchIndex !== -1) {
      const [selectedItem] = res.splice(mySearchIndex, 1);
      res.unshift(selectedItem);
    }
    return res;
  };

  const searchesToShow = useMemo(() => filterByStatus(data, status), [status, data]);

  const getSearchHandler = useCallback(
    ({ openCriteriaModal, ...criterias }) =>
      () => {
        setIsOpenSearchModal(false);
        dispatch(
          selectedSearchAction({
            selectedSearch: criterias,
          }),
        );
        getAgentSearchInstance(
          { criterias: criterias?.criterias, id: criterias?.Id },
          undefined,
          selectedClientSavedSearch,
        );
        if (openCriteriaModal) {
          dispatch(appOpenModalEffect({ id: CriteriaModal.id, open: true }));
        } else {
          dispatch(appOpenModalEffect({ id: null, open: false }));
        }
        dispatch(setCriteriaModeAction({ mode: EDIT }));
      },
    [dispatch],
  );

  return (
    <div className={styles.savedSearches}>
      {isPending ? (
        <Row gutter={[30, 30]} className={styles.loaderContainer}>
          <Spinner loaderClassName={styles.loader} />
        </Row>
      ) : (
        <>
          <div className={classNames(styles.itemsContainer, { [styles.client]: Boolean(client) })}>
            <div className={styles.header}>
              <p className={styles.count}>
                {searchesToShow?.length ?? 0} {searchesToShow?.length === 1 ? 'Search' : 'Searches'}
              </p>
              <DropdownCheck
                dropdownFields={SearchStatusItems}
                defaultValue={SearchStatus.active}
                className={styles.button}
                dropdownClassName={styles.dropdown}
                onChange={(val) => {
                  setStatus(val);
                }}
              />
            </div>
            {searchesToShow?.length < 1 && <p className={styles.notFound}>No searches found</p>}
            {searchesToShow.map((search, idx) => (
              <Row key={idx} className={styles.cardsContainer}>
                <SearchCard
                  photos={search.photos}
                  key={search.id}
                  title={search.name}
                  agentName={search.agentName}
                  status={search.status}
                  info={search?.info}
                  fullInfo={search?.fullInfo}
                  footer={`${search.total || 'no'} results`}
                  updatedDate={search?.updatedDate}
                  infoClassName={styles.infoDesc}
                  onRename={getRenameHandler(search.id)}
                  onDelete={null} //getDeleteHandler(search.id)
                  onInactive={getInactiveHandler(search.id)}
                  onActivate={getActivateHandler(search.id)}
                  onEdit={getSearchHandler({
                    ...search,
                    ...{ saveSearchId: search.id },
                    ...{ openCriteriaModal: true },
                  })}
                  onClick={getSearchHandler({
                    ...search,
                    ...{ saveSearchId: search.id },
                    ...{ openCriteriaModal: false },
                  })}
                />
              </Row>
            ))}
          </div>
          <Button
            className={styles.searchButton}
            titleClassName={styles.buttonTitle}
            title="New Search"
            onClick={() => {
              if (selectedClientSavedSearch?.Id) {
                const searchParams = new URLSearchParams(location.search);
                searchParams.set('userId', selectedClientSavedSearch?.Id);
                dispatch(setSelectedClientSavedSearchAction({ selectedClientSavedSearch: null }));
                history.push({
                  ...location,
                  search: `?${searchParams.toString()}`,
                });
              }
              dispatch(appOpenModalEffect({ id: null, open: false }));
              setIsOpenSearchModal(true);
            }}
            icon={<Add />}
          />
        </>
      )}
    </div>
  );
};
