import classNames from 'classnames';

import { Row, Col } from 'components-antd';
import { Edit, Delete } from 'components/Icons';
import { whiteColor } from 'app-constants';
import { formatPhoneNumber } from 'helpers/formatters';

import styles from './styles.module.scss';

const PartnerCard = ({
  partner,
  setSelectedPartner,
  deletePartner,
  categoryOptions,
  existingEmails,
  agentOptions,
}) => {
  const validateCategory = (categories) =>
    categories.every((category) => categoryOptions.some(({ name }) => name === category));

  const getPartnerName = (partner) => {
    const { FirstName, LastName } = partner.ContactUser;
    if (FirstName && LastName) return `${FirstName} ${LastName}`;
    return FirstName || null;
  };

  const isExistingEmail = (email) => existingEmails.includes(email);

  const getAgentsName = (agentIds) => {
    const names = agentIds.map((agentId) => {
      const recommendedAgent = agentOptions.find(({ value, name }) => value === agentId);
      return recommendedAgent.name;
    });
    return names?.join(', ');
  };
  const isEmpty = (value) => {
    return value === undefined || value === '';
  };

  return (
    <div className={styles.partnerCard}>
      <div className={styles.headerContainer}>
        <div className={styles.title}>
          <p
            className={classNames({
              [styles.error]: !partner.Category?.length || !validateCategory(partner.Category),
            })}
          >
            Category*: <span>{partner.Category?.length ? partner.Category.join(', ') : '---'}</span>
          </p>
        </div>

        <div className={styles.actionContainer}>
          <button className={styles.actionBtn} onClick={(e) => setSelectedPartner()}>
            <Edit className={styles.icon} />
          </button>
          <button className={styles.actionBtn} onClick={(e) => deletePartner()}>
            <Delete className={styles.icon} color={whiteColor} />
          </button>
        </div>
      </div>
      <div className={styles.cardBody}>
        <Row gutter={[8, 24]} className={styles.topRow}>
          <Col span={8}>
            <div className={classNames({ [styles.error]: !partner.BusinessName })}>
              <div className={styles.columnName}>Company*</div>
              <div className={styles.columnValue}>
                {isEmpty(partner.BusinessName) ? '---' : partner.BusinessName}
              </div>
            </div>
          </Col>
          <Col span={8}>
            <div className={classNames({ [styles.error]: !partner.Services?.length })}>
              <div className={styles.columnName}>Specialities</div>
              <div className={styles.columnValue}>
                {partner.Services?.length ? partner.Services.join(', ') : '---'}
              </div>
            </div>
          </Col>
          <Col span={8}>
            <div className={classNames({ [styles.error]: !getPartnerName(partner) })}>
              <div className={styles.columnName}>Point of Contact/ Name</div>
              <div className={styles.columnValue}>{getPartnerName(partner) ?? '---'}</div>
            </div>
          </Col>
        </Row>
        <Row gutter={[8, 16]}>
          <Col span={8}>
            <div className={classNames({ [styles.error]: !partner.PhoneNumber })}>
              <div className={styles.columnName}>Phone Number</div>
              <div className={styles.columnValue}>
                {partner.PhoneNumber ? formatPhoneNumber(partner.PhoneNumber) : '---'}
              </div>
            </div>
          </Col>
          <Col span={8}>
            <div
              className={classNames({
                [styles.error]: !partner.Email || isExistingEmail(partner.Email) || !partner.Email,
              })}
            >
              <div className={styles.columnName}>Email*</div>
              <div className={styles.columnValue}>{partner.Email ?? '---'}</div>
            </div>
          </Col>
          <Col span={8}>
            <div
              className={classNames({
                [styles.error]: !partner.Website,
              })}
            >
              <div className={styles.columnName}>Website</div>
              <div className={styles.columnValue}>{partner.Website ?? '---'}</div>
            </div>
          </Col>
        </Row>
        {partner.RecommendedByAgentsId?.length > 0 && (
          <Row gutter={[8, 24]} className={styles.bottomRow}>
            <Col span={8}>
              <div>
                <div className={styles.columnName}>Recommended By Agents</div>
                <div className={styles.columnValue}>
                  {getAgentsName(partner.RecommendedByAgentsId)}
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default PartnerCard;
