import { useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Select } from 'antd';
import { isEqual, upperCase } from 'lodash-es';
import { useHistory, useLocation } from 'react-router-dom';
import { LocalStorage } from 'services';
import { formatPhoneNumber } from 'helpers';

import classNames from 'classnames';
import { Avatar } from 'components-antd';
import { Check, ArrowDown2, Message } from 'components/Icons';
import { Button } from 'components-antd';

import { getCurrentContextSelector, getSelectedContextIdSelector } from 'store/selectors/context';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { setCurrentContextAction, setSelectedContextIdAction } from 'store/actions/context';
import { getClientContextsEffect } from 'store/effects/context';
import { READY, IDLE } from 'settings/constants/apiState';
import { getClientContextsStateSelector } from 'store/selectors/contexts';
import { openMessagesDrawerEffect } from 'store/effects/drawers/messages';
import { DRAWER_MESSAGES_TYPES } from 'settings/constants/drawers';

import styles from './styles.module.scss';

const { Option } = Select;

export const ContextSelector = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { isClient } = useSelector(getUserRolesMapSelector);
  const contextsState = useSelector(getClientContextsStateSelector);
  const agentId = useSelector(getSelectedContextIdSelector);
  const selectedContext = useSelector(getCurrentContextSelector);

  const handleChange = (contextKey: string) => {
    const ctx = contextsState.data.find((c) => c.ContextKey === contextKey);
    dispatch(setCurrentContextAction(ctx));
    LocalStorage.setContextPropertyFeed(ctx);

    // TODO: we won't depend on specific agent
    const firstAgent = ctx.Agents[0];
    dispatch(setSelectedContextIdAction(firstAgent.Id));
  };

  const fetchContexts = useCallback(() => dispatch(getClientContextsEffect()), [dispatch]);

  useEffect(() => {
    if (contextsState.state === READY) {
      const contexts = contextsState.data || [];
      let selectedContext = contexts[0];
      const savedContext = LocalStorage.getContextPropertyFeed();
      const parsedSavedContext = savedContext ? JSON.parse(savedContext) : {};
      if (
        savedContext &&
        contexts.some(({ Agents }) => isEqual(parsedSavedContext?.Agents, Agents))
      )
        selectedContext = parsedSavedContext;

      LocalStorage.setContextPropertyFeed(selectedContext);

      // TODO: we won't depend on specific agent
      const firstAgent = selectedContext?.Agents[0];
      if (!agentId && firstAgent) {
        dispatch(setSelectedContextIdAction(firstAgent.Id));
        dispatch(setCurrentContextAction(selectedContext));
      }
    }
  }, [contextsState.state]);

  useEffect(() => {
    if (isClient && contextsState.state === IDLE) fetchContexts();
  }, []);

  if (contextsState.state !== READY) {
    return null;
  }

  const contexts = contextsState.data;

  const getTitle = () => {
    if (selectedContext?.Agents?.length === 1) {
      return 'My Agent:';
    }

    return 'My Agents:';
  };

  const handleMessagesOpen = (agent) => {
    dispatch(
      openMessagesDrawerEffect({
        open: true,
        type: DRAWER_MESSAGES_TYPES.NEW_MESSAGE,
        params: {
          participants: [
            {
              id: agent?.Id,
              value: agent?.Id,
              avatarUrl: agent?.AvatarUrl,
              email: agent?.Email,
              firstName: agent?.FirstName,
              lastName: agent?.LastName,
              name: `${agent.FirstName} ${agent?.LastName} / ${agent?.Email}`,
            },
          ],
        },
      }),
    );
  };

  if (contextsState.state !== READY) return null;

  const isHomePage = false;
  const isSingleContext = contexts?.length === 1 && contexts?.[0]?.Agents?.length === 1;

  return (
    <>
      {selectedContext ? (
        <div
          className={classNames(styles.contextSelector, {
            [styles.contextSelectorClient]: isHomePage && isClient,
          })}
        >
          <span className={styles.staticText}>{getTitle()}</span>
          <Select
            defaultValue={selectedContext.ContextKey}
            popupClassName={classNames(styles.contextSelectorDropdown, {
              [styles.singleContextSelectorDropdown]: isSingleContext,
            })}
            onChange={handleChange}
            bordered={false}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            placement="bottomRight"
            suffixIcon={<ArrowDown2 />}
          >
            {contexts.map((ctx) => {
              const agent = ctx.Agents[0];
              const totalAgents = ctx.Agents.length;
              const firstName = agent.FirstName;
              const lastName = agent.LastName;
              const email = agent?.Email;
              const brokerage = agent?.Brokerage;
              const phone = agent?.Phones?.[0]?.PhoneNumber;
              const avatar = agent.AvatarUrl;
              const placeholder = upperCase(`${firstName?.[0] || ''}${lastName?.[0] || ''}`);

              if (isSingleContext) {
                return (
                  <Option key={ctx.ContextKey} value={ctx.ContextKey}>
                    <Avatar
                      className={classNames(styles.avatar, { [styles.avatarName]: !avatar })}
                      src={avatar}
                    >
                      {placeholder}
                    </Avatar>
                    <div className={styles.contentArea}>
                      <div className={styles.info}>
                        <p className={styles.singleAgentName}>
                          {firstName} {lastName}
                        </p>
                        <p className={styles.brokerage}>{brokerage?.Name}</p>
                        <p className={styles.contactNumber}>{formatPhoneNumber(phone)}</p>
                        <p className={styles.email}>{email}</p>
                        <div className={styles.buttonContainer}>
                          <Button
                            icon={<Message />}
                            variant="hollow-bordered"
                            className={styles.messageBtn}
                            onClick={() => handleMessagesOpen(agent)}
                          >
                            Message
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Option>
                );
              }

              return (
                <Option key={ctx.ContextKey} value={ctx.ContextKey}>
                  {totalAgents > 1 ? (
                    <div className={styles.multiAvatar}>
                      {ctx.Agents.slice(0, 2).map((agents, idx, agentsArray) => {
                        return (
                          <div className={styles.avatarWrap}>
                            {idx + 1 === agentsArray.length && (
                              <span className={styles.overlay}>+{totalAgents - 1}</span>
                            )}
                            <Avatar
                              key={idx}
                              className={classNames(styles.avatar, {
                                [styles.avatarName]: !avatar,
                              })}
                              src={agents.AvatarUrl && agents?.AvatarUrl}
                            >
                              {upperCase(
                                `${agents.FirstName?.[0] || ''}${agents.LastName?.[0] || ''}`,
                              )}
                            </Avatar>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <Avatar
                      className={classNames(styles.avatar, { [styles.avatarName]: !avatar })}
                      src={avatar}
                    >
                      {placeholder}
                    </Avatar>
                  )}

                  <div className={styles.contentArea}>
                    <span className={classNames(styles.name)}>{` ${firstName} ${lastName} ${
                      ctx.Agents.length > 1 ? ` +${ctx.Agents.length - 1}` : ''
                    }`}</span>
                    {ctx.Agents.length > 1 && (
                      <span className={styles.teamWrap}>
                        {ctx.Agents.slice(1).map((agent, idx) => {
                          return (
                            <span className={styles.teamName} key={idx}>{`${agent.FirstName} ${
                              agent.LastName
                            }${ctx.Agents.slice(1).length > 1 ? ', ' : ''}`}</span>
                          );
                        })}
                      </span>
                    )}
                  </div>
                  <Check className={styles.icon} color="#04a451" />
                </Option>
              );
            })}
          </Select>
        </div>
      ) : null}
    </>
  );
};
