import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ActionsIcon from '../Icons/ActionsIcon';
import ActionsIconGray from '../Icons/ActionsIconGray';

import SmallSearchIcon from '../Icons/SmallSearchIcon';
import SmallDeleteIcon from '../Icons/SmallDeleteIcon';
import SmallEditIcon from '../Icons/SmallEditIcon';
import { Popover } from 'components-antd';

import styles from './styles.module.scss';
import { ConfirmationModal } from './ConfirmationModal';
import { RenameSearchInstanceModal } from '../RenameSearchInstanceModal';

const SearchBlockActions = ({
  onSearch,
  onEdit,
  onRename,
  onDelete,
  onInactive,
  onActivate,
  searchName,
  popupPosition,
  clientProfileDrawer = false,
}) => {
  const [confirmationModal, setConfirmationModal] = useState({
    open: false,
  });

  const [renameModal, setRenameModal] = useState({
    showRenameModal: false,
  });

  const onConfirm = (callback) => {
    setConfirmationModal({ open: false });
    callback();
  };

  const showRenameModal = (e) => {
    e.stopPropagation();
    setRenameModal({
      showRenameModal: true,
      onOk: () => onConfirm(onRename),
    });
  };

  const showConfirmationModal = (e, type) => {
    e.stopPropagation();
    if (type === 'Delete') {
      setConfirmationModal({
        open: true,
        variant: 'Delete',
        onOk: () => onConfirm(onDelete),
      });
    } else if (type === 'Deactivate') {
      setConfirmationModal({
        open: true,
        variant: 'Deactivate',
        onOk: () => onConfirm(onInactive),
      });
    } else if (type === 'Activate') {
      setConfirmationModal({
        open: true,
        variant: 'Activate',
        onOk: () => onConfirm(onActivate),
      });
    }
  };

  const onRenameHandler = (name, setLoading) => {
    const onCloseHandler = () => {
      setLoading(false);
      setRenameModal({ showRenameModal: false });
    };
    onRename(name, onCloseHandler);
  };

  return (
    <div>
      <RenameSearchInstanceModal
        open={renameModal.showRenameModal}
        searchName={searchName}
        submit={onRenameHandler}
        onOk={renameModal.onOk}
        onCancel={() => setRenameModal({ showRenameModal: false })}
      />

      <ConfirmationModal
        variant={confirmationModal.variant}
        open={confirmationModal.open}
        onOk={confirmationModal.onOk}
        onCancel={() => setConfirmationModal({ open: false })}
      />

      <div testid="search_actions_menu" className={styles.actionBtn}>
        <Popover
          overlayClassName={styles.popoverOverlay}
          placement="bottomRight"
          trigger="hover"
          content={
            <div testid="search_actions" className={styles.actionList}>
              {onEdit && (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    onEdit();
                  }}
                  className={styles.actionListBtn}
                >
                  <span>Edit Criteria</span>
                </div>
              )}

              {onRename && (
                <div
                  testid="inactive_search"
                  onClick={(e) => showRenameModal(e)}
                  className={styles.actionListBtn}
                >
                  <span>Rename</span>
                </div>
              )}

              {onDelete && (
                <div
                  testid="delete_search"
                  onClick={(e) => showConfirmationModal(e, 'Delete')}
                  className={styles.actionListBtn}
                >
                  {!(onActivate || onInactive) && <SmallDeleteIcon className={styles.icon} />}
                  <span>Delete</span>
                </div>
              )}
              {(onActivate || onInactive) && (
                <div
                  testid={onActivate ? 'activate_search' : 'inactive_search'}
                  onClick={(e) => showConfirmationModal(e, onActivate ? 'Activate' : 'Deactivate')}
                  className={styles.actionListBtn}
                >
                  <span>{onActivate ? 'Activate' : 'Deactivate'}</span>
                </div>
              )}
            </div>
          }
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <ActionsIcon
              className={classNames(styles.actionBtnIcon, {
                [styles.inactiveBtnIcon]: !!onActivate,
              })}
              color={'#252d44'}
            />
          </div>
        </Popover>
      </div>

      {/* <div
        testid="search_actions_menu"
        className={styles.actionBtn}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {clientProfileDrawer ? (
          <ActionsIconGray />
        ) : (
          <ActionsIcon className={styles.actionBtnIcon} />
        )}

        <div
          testid="search_actions"
          className={classNames(styles.actionList, styles[popupPosition])}
        >
          {onSearch && (
            <>
              <button testid="start_search" onClick={onSearch} className={styles.actionListBtn}>
                <span>Run Search</span>
              </button>
            </>
          )}
          {onEdit && (
            <>
              <button testid="edit_search" onClick={onEdit} className={styles.actionListBtn}>
                <span>Edit Criteria</span>
              </button>
            </>
          )}
          {onRename && (
            <>
              <button
                testid="inactive_search"
                onClick={(e) => showRenameModal(e)}
                className={styles.actionListBtn}
              >
                <span>Rename</span>
              </button>
            </>
          )}
          {onDelete && (
            <>
              <button
                testid="delete_search"
                onClick={(e) => showConfirmationModal(e, 'Delete')}
                className={styles.actionListBtn}
              >
                {!(onActivate || onInactive) && (
                  <>
                    <SmallDeleteIcon className={styles.icon} />
                  </>
                )}
                <span>Delete</span>
              </button>
            </>
          )}
          {onInactive && (
            <>
              <button
                testid="inactive_search"
                onClick={(e) => showConfirmationModal(e, 'Deactivate')}
                className={styles.actionListBtn}
              >
                <span>Deactivate</span>
              </button>
            </>
          )}
          {onActivate && (
            <>
              <button
                testid="activate_search"
                onClick={(e) => showConfirmationModal(e, 'Activate')}
                className={styles.actionListBtn}
              >
                <span>Activate</span>
              </button>
            </>
          )}
        </div>
      </div> */}
    </div>
  );
};

SearchBlockActions.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onActivate: PropTypes.func,
  onInactive: PropTypes.func,
  onOk: PropTypes.func,
  popupPosition: PropTypes.string,
};

SearchBlockActions.defaultProps = {
  popupPosition: 'top',
  onOk: () => {},
};

export default SearchBlockActions;
