import { useSelector } from 'react-redux';

import { Stages } from 'components';
import { Modal } from 'components-antd';
import { useDispatch } from 'react-redux';
import { isInviteClientModalOpened } from 'store/selectors/app';
import {
  ClientDetails,
  InviteClientSearchInstance,
  InviteClientWishList,
  InviteClientTransactionInstance,
  InviteClientPreApproval,
} from './StageParts';
import { appSetInviteClientValuesAction, appSetShowInviteClientAction } from 'store/actions/app';

import styles from './styles.module.scss';
import InviteClientRetentionMode from './StageParts/InviteClientRetentionMode';
import { inviteClientSteps } from 'types/inviteClient';
import { InviteClientSearchSummary } from './StageParts/InviteClientSearchSummary';
import { InviteClientNotifications } from './StageParts/InviteClientNotifications';

const InviteClient = () => {
  const isInviteOpened = useSelector(isInviteClientModalOpened);

  const dispatch = useDispatch();
  return (
    <div className={styles.inviteClientContainer}>
      <Modal
        open={isInviteOpened}
        width={700}
        footer={null}
        onCancel={() => {
          dispatch(appSetShowInviteClientAction(false));
          dispatch(appSetInviteClientValuesAction({}));
        }}
        className={styles.inviteModal}
        cancelButtonStyles={styles.crossButton}
        maskClosable={false}
        destroyOnClose={true}
      >
        <div className={styles.wrapper}>
          <Stages
            stages={[
              { id: inviteClientSteps.ClientDetails, Component: ClientDetails },
              {
                id: inviteClientSteps.ClientTransactionInstance,
                Component: InviteClientTransactionInstance,
              },
              { id: inviteClientSteps.ClientSearchInstance, Component: InviteClientSearchInstance },
              { id: inviteClientSteps.ClientWishList, Component: InviteClientWishList },
              {
                id: inviteClientSteps.ClientSearchInstanceSummary,
                Component: InviteClientSearchSummary,
              },
              { id: inviteClientSteps.ClientRetentionMode, Component: InviteClientRetentionMode },
              { id: inviteClientSteps.ClientPreApproval, Component: InviteClientPreApproval },
              { id: inviteClientSteps.ClientNotifications, Component: InviteClientNotifications },
            ]}
            classNameProgressBackground={styles.progressBackground}
            classNameProgress={styles.progressBar}
            classNameWrapper={styles.stagesWrapper}
            showProgressBar={false}
          />
        </div>
      </Modal>
    </div>
  );
};

export default InviteClient;
