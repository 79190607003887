import { Table } from 'pages/Radar/components';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCampaignPublishedKitsWithStatsEffect } from 'store/effects/radarKits';
import { PlansViewModal } from '../PlansViewModal';
import styles from '../styles.module.scss';
import { libraryColumns, LibraryDataType } from './helpers';
import { getLibraryKitsSelector } from 'store/selectors/radarKits';
import { setSelectedPlanIdAction } from 'store/actions/radarKits';

export const Library: React.FC = () => {
  const dispatch = useDispatch();

  const { data } = useSelector(getLibraryKitsSelector);

  const [isPlansViewModalOpen, setIsPlansViewModalOpen] = useState<boolean>(false);
  //   const [selectedPlanId, setSelectedPlanId] = useState<number | null>(null);

  useEffect(() => {
    dispatch(getCampaignPublishedKitsWithStatsEffect({}, { silent: !!data }));
  }, []);

  const dataSource = useMemo<LibraryDataType[]>(() => {
    return (
      data?.map((item: any) => ({
        key: item.KitId,
        name: {
          title: item.Kits.Name,
          info: item.Kits.Category,
        },
        status: item.Kits.Published,
        numberOfPlans: item.stats.numberOfPlans,
        numberOfClients: item.stats.numberOfClients,
        sent: item.stats.numberOfKitsSent,
        opened: item.stats.numberOfKitsOpened,
      })) || []
    );
  }, [data]);

  //   const selectedPlan = useMemo(() => {
  //     return data?.find((item: any) => item.KitId === selectedPlanId);
  //   }, [data, selectedPlanId]);

  return (
    <>
      <div className={styles.tableContainer}>
        <div>
          <Table
            title="KITs"
            count={2}
            data={dataSource}
            columns={libraryColumns}
            handleRowClick={(record: LibraryDataType) => {
              // history.push(link.toRadarKitSequence(record.key));
              dispatch(setSelectedPlanIdAction(record.key));
              setIsPlansViewModalOpen(true);
            }}
          />
        </div>
      </div>
      {isPlansViewModalOpen && (
        <PlansViewModal
          isOpen={isPlansViewModalOpen}
          closeModal={() => setIsPlansViewModalOpen(false)}
        />
      )}
    </>
  );
};
