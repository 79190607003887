import { combineReducers } from 'redux';

import mlsScores from './mlsScores';
import propertyInsight from './propertyInsight';
import additionalFeatures from './additionalFeatures';

export default combineReducers({
  mlsScores,
  propertyInsight,
  additionalFeatures,
});
