import { FC, useState } from 'react';
import classNames from 'classnames';

import { Menu, MenuItem, Dropdown, Radio } from 'components-antd';
import { ArrowDown, Check } from 'components/Icons';

import styles from './styles.module.scss';

interface IDropdownCheck {
  dropdownFields: { label: string; value: string }[];
  iconFields?: { icon: any; value: string }[];
  defaultValue: string;
  onChange: (val: string) => void;
  className?: string;
  dropdownClassName?: string;
  prefixLabel?: string;
  disableCheck?: boolean;
  disableArrow?: boolean;
  placement?:
    | 'bottomRight'
    | 'topLeft'
    | 'topCenter'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomCenter'
    | 'top'
    | 'bottom'
    | undefined;
}

const DropdownCheck: FC<IDropdownCheck> = ({
  dropdownFields,
  iconFields,
  defaultValue,
  onChange,
  className,
  dropdownClassName,
  prefixLabel = '',
  placement = 'bottomRight',
  disableCheck = false,
  disableArrow = false,
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const handleChange = (e) => {
    const val = e.target.value;
    setSelectedValue(val);
    onChange(val);
  };

  const menu = (
    <Radio.Group onChange={handleChange} value={selectedValue} className={styles.optionsItems}>
      <Menu>
        {dropdownFields.map(({ label, value }) => (
          <MenuItem key={value}>
            <Radio value={value}>
              <div className={styles.option}>
                <span className={styles.optionValue}>{label}</span>
              </div>
              {selectedValue === value && !disableCheck ? (
                <Check className={styles.checkIcon} />
              ) : null}
            </Radio>
          </MenuItem>
        ))}
      </Menu>
    </Radio.Group>
  );

  return (
    <div className={classNames(styles.wrap)}>
      <Dropdown
        overlay={menu}
        placement={placement}
        getPopupContainer={(triggerNode) => triggerNode}
        trigger={['click']}
        overlayClassName={classNames(styles.dropdown, dropdownClassName)}
      >
        <a
          onClick={(e) => e.preventDefault()}
          className={classNames(styles.button, 'mosaikDropdownButton', className)}
        >
          <span
            className={classNames(styles.selectedValue, { [styles.disableArrow]: disableArrow })}
          >
            {prefixLabel}
            {iconFields ? (
              <>{iconFields?.find(({ value }) => value === selectedValue)?.icon}</>
            ) : (
              <>{dropdownFields?.find(({ value }) => value === selectedValue)?.label}</>
            )}
          </span>
          {!disableArrow && (
            <ArrowDown className={classNames(styles.icon, { [styles.open]: open })} />
          )}
        </a>
      </Dropdown>
    </div>
  );
};

export default DropdownCheck;
