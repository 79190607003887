import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import classNames from 'classnames';

import { Input, RadioOptions, Button } from 'components';
import { Row, Col } from 'components-antd/Grid';
import { Modal } from 'components-antd';
import { useDispatch } from 'react-redux';
import { schema } from './validation';
import { TitleInput } from './TitleInput';
import { handleSendTeamMemberInviteFormByAdminEffect } from 'store/effects';

import styles from './styles.module.scss';
import { showSuccessMessage } from 'helpers';

const InviteTeamMember = ({ isOpen, onCloseModal, teamId, getTeamMembers }) => {
  const [options, setOptions] = useState([
    { id: 'Basic', label: 'Member', value: 'Basic' },
    { id: 'Admin', label: 'Admin', value: 'Admin' },
  ]);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: { firstName: '', lastName: '', email: '', role: '' },
    validateOnMount: true,
    validationSchema: schema,
    onSubmit: (values) => {
      setIsLoading(true);
      const body = { ...values, titleId: values?.title?.titleId || undefined };
      delete body.title;
      dispatch(
        handleSendTeamMemberInviteFormByAdminEffect(body, { teamId }, (err) => {
          if (!err) {
            showSuccessMessage('Member invited');
            getTeamMembers(false);
          }
        }),
      );
      setIsLoading(false);
      onCloseModal();
    },
  });
  const {
    values,
    touched,
    errors,
    isValid,
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
  } = formik;

  const handleRadioOptionChange = (value) => {
    setFieldValue('role', value);
  };

  const isDisabled = () => (!values?.titleFreeText && !values?.title?.name) || isLoading;

  return (
    <Modal
      open={isOpen}
      width={675}
      footer={null}
      onCancel={onCloseModal}
      className={styles.inviteTeamMemberModal}
      cancelButtonStyles={styles.crossButton}
      maskClosable={false}
      destroyOnClose={true}
    >
      <form testid="invite_buyer_form" onSubmit={handleSubmit}>
        <h1 className={styles.heading}>Invite</h1>
        <div className={styles.formContainer}>
          <div className={styles.contactInformation}>
            <Row className={styles.inputRow} gutter={24}>
              <Col xs={24} md={12}>
                <Input
                  className={styles.inputContainer}
                  name="firstName"
                  variant="lightFull"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  label="First name"
                  error={touched.firstName ? errors.firstName : ''}
                  testid="first_name"
                  placeholder={'Enter first name'}
                  maxLength={'30'}
                />
              </Col>
              <Col xs={24} md={12}>
                <Input
                  className={styles.inputContainer}
                  name="lastName"
                  variant="lightFull"
                  onChange={handleChange}
                  value={values.lastName}
                  onBlur={handleBlur}
                  label="Last name"
                  error={touched.lastName ? errors.lastName : ''}
                  testid="last_name"
                  placeholder={'Enter last name'}
                  maxLength={'30'}
                />
              </Col>
            </Row>

            <Row className={styles.inputRow}>
              <Col span={24}>
                <Input
                  className={styles.inputContainerEmail}
                  name="email"
                  variant="lightFull"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  label="Email"
                  error={touched.email ? errors.email : ''}
                  testid="email"
                  placeholder={'Enter email address...'}
                  maxLength={'50'}
                />
              </Col>
            </Row>
          </div>
          <Row className={classNames(styles.inputRow, styles.memberRole)}>
            <Col span={24}>
              <p className={styles.subHeading}>Role</p>
              <RadioOptions
                options={options}
                name="role"
                onChange={handleRadioOptionChange}
                onBlur={handleBlur}
                value={values.role}
                error={touched.role ? errors.role : ''}
                testid="client_type"
                className={styles.selectType}
                optionClassName={styles.selectOption}
                checkedClassName={styles.selectChecked}
              />
            </Col>
          </Row>
          <Row className={styles.inputRow}>
            <Col span={24}>
              <TitleInput values={values} setFieldValue={setFieldValue} teamId={teamId} />
            </Col>
          </Row>
          <div className={styles.footer}>
            <Button
              disabled={isLoading || isDisabled() || !isValid}
              type="submit"
              className={classNames({
                [styles.button]: true,
                [styles.active]: isValid && !isDisabled() && !isLoading,
              })}
              title="Send Invitation"
              loading={isLoading}
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default InviteTeamMember;
