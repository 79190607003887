/* eslint-disable jsx-a11y/label-has-associated-control */
import { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Select } from 'components';
import { pushOrRemove } from 'helpers';
import Icon from './Icons';
import Option from './Option';
import { criteriaTypes } from 'pages/Properties/CriteriaModal/constants';

import styles from './styles.module.scss';

const PropertyType = (props) => {
  const {
    className,
    onChange,
    value,
    multiple,
    disabled,
    error,
    viewMode,
    label,
    testid,
    inline,
    type,
    propertyTypeWrapperClassName,
    propertySelectClassName,
    itemClassName,
    isTransactionCreation,
    isFeedFilter,
    variant,
    selectWrapperClassname,
    criteriaType,
  } = props;
  const [actives, setActives] = useState([]);
  const [options, setOptions] = useState(saleOptions);

  useEffect(() => {
    if (criteriaType)
      setOptions(criteriaType === criteriaTypes.ForRent ? rentOptions : saleOptions);
  }, [criteriaType]);

  useEffect(() => {
    if (Array.isArray(value)) {
      setActives(value);
    } else {
      setActives([]);
    }
  }, [value]);

  const onClick = useCallback(
    (type) => {
      if (viewMode) {
        return;
      }
      const newActives = pushOrRemove({ arr: actives, id: type, multiple });
      setActives(newActives);
      onChange(newActives);
    },
    [actives, multiple, viewMode],
  );

  const optionsData = [
    {
      title: isFeedFilter ? 'House' : 'Single Family Home',
      className: styles.option,
      onClick: () => onClick(PropertyType.HOUSE),
      iconVariant: Icon.HOUSE,
      active: actives.includes(PropertyType.HOUSE),
    },
    {
      title: 'Condo',
      className: styles.option,
      onClick: () => onClick(PropertyType.CONDO),
      iconVariant: Icon.CONDO,
      active: actives.includes(PropertyType.CONDO),
    },
    {
      title: 'Townhouse',
      className: styles.option,
      onClick: () => onClick(PropertyType.TOWN_HOME),
      iconVariant: Icon.TOWN_HOME,
      active: actives.includes(PropertyType.TOWN_HOME),
    },
    {
      title: 'Multi-Family',
      className: styles.option,
      onClick: () => onClick(PropertyType.MULTI_FAMILY),
      iconVariant: Icon.MULTI_FAMILY,
      active: actives.includes(PropertyType.MULTI_FAMILY),
    },
    {
      title: 'Land',
      className: styles.option,
      onClick: () => onClick(PropertyType.LAND),
      iconVariant: Icon.LAND,
      active: actives.includes(PropertyType.LAND),
    },
    {
      title: 'Other',
      className: styles.option,
      onClick: () => onClick(PropertyType.OTHER),
      iconVariant: '',
      active: actives.includes(PropertyType.OTHER),
    },
    {
      title: 'Commercial',
      className: styles.option,
      onClick: () => onClick(PropertyType.COMMERCIAL),
      iconVariant: '',
      active: actives.includes(PropertyType.COMMERCIAL),
    },
    {
      title: 'Commercial Lease',
      className: styles.option,
      onClick: () => onClick(PropertyType.COMMERCIAL_LEASE),
      iconVariant: '',
      active: actives.includes(PropertyType.COMMERCIAL_LEASE),
    },
  ];

  const handleDropdownSelect = (options) => {
    if (multiple) {
      onChange(options.map((option) => option.value));
    } else {
      onChange(options.value);
    }
  };

  const getDropdownValue = (value) => {
    if (multiple) {
      return options?.filter((option) => value.includes(option.value));
    } else {
      return options?.find((option) => value === option.value);
    }
  };

  useEffect(() => {
    getDropdownValue(value);
  }, []);

  return (
    <div
      testid="wrapper"
      className={classNames(
        styles.wrapper,
        {
          [styles.disabled]: disabled,
          [styles.inline]: inline,
        },
        className,
      )}
    >
      {!!label && <label className={styles.label}>{label}</label>}
      {type === 'dropdown' ? (
        <Select
          name="PropertyType"
          multiple={multiple}
          closeOnSelect={!multiple}
          className={{
            wrapper: classNames(
              { [styles.customDropdownFPK]: variant ? variant !== Select.LIGHT_ROUND : false },
              propertySelectClassName,
            ),
          }}
          variant={variant || Select.LIGHT_ROUND}
          options={options}
          value={getDropdownValue(value)}
          onSelect={(event, options) => handleDropdownSelect(options)}
          placeholder="Choose property type"
          checkboxPostion="end"
          isArrowIcon={true}
          selectWrapperClassname={selectWrapperClassname}
        />
      ) : (
        <div className={classNames(styles.propertyType, propertyTypeWrapperClassName)}>
          {optionsData.map((item, idx) => {
            return (
              <Option
                title={item.title}
                className={classNames(item.className, itemClassName)}
                onClick={item.onClick}
                iconVariant={item.iconVariant}
                active={item.active}
                testid={testid}
                key={idx}
                isTransactionCreation={isTransactionCreation}
              />
            );
          })}
        </div>
      )}

      {error && (
        <div testid="validation" className={styles.error}>
          {error}
        </div>
      )}
    </div>
  );
};

PropertyType.HOUSE = 'Single';
PropertyType.CONDO = 'Condo';
PropertyType.TOWN_HOME = 'Townhouse';
PropertyType.MULTI_FAMILY = 'MultiFamily';
PropertyType.LAND = 'Land';
PropertyType.OTHER = 'Other';
PropertyType.COMMERCIAL = 'Commercial';
PropertyType.COMMERCIAL_LEASE = 'CommercialLease';
PropertyType.RESIDENTIAL_LEASE = 'ResidentialLease';
PropertyType.RL_OTHER = 'RL_Other';
PropertyType.RL_SINGLE = 'RL_Single';
PropertyType.RL_APARTMENT = 'RL_Apartment';
PropertyType.RL_TOWNHOUSE = 'RL_Townhouse';
PropertyType.RL_CONDO = 'RL_Condo';
PropertyType.RL_MULTI_FAMILY = 'RL_MultiFamily';
PropertyType.COMMERCIAL_LAND = 'Commercial_Land';
PropertyType.COMMERCIAL_LEASE_LAND = 'CommercialLease_Land';

const rentOptions = [
  { id: 1, name: 'Single Family', value: PropertyType.RL_SINGLE },
  { id: 2, name: 'Apartment', value: PropertyType.RL_APARTMENT },
  { id: 3, name: 'Condo', value: PropertyType.RL_CONDO },
  { id: 4, name: 'Townhouse', value: PropertyType.RL_TOWNHOUSE },
  { id: 5, name: 'Multi Family', value: PropertyType.RL_MULTI_FAMILY },
  { id: 7, name: 'Other', value: PropertyType.RL_OTHER },
  { id: 7, name: 'Commercial', value: PropertyType.COMMERCIAL },
  { id: 8, name: 'Commercial Lease', value: PropertyType.COMMERCIAL_LEASE },
  { id: 9, name: 'Residential Lease', value: PropertyType.RESIDENTIAL_LEASE },
];

const saleOptions = [
  { id: 1, name: 'Single Family', value: PropertyType.HOUSE },
  { id: 2, name: 'Condo', value: PropertyType.CONDO },
  { id: 3, name: 'Townhouse', value: PropertyType.TOWN_HOME },
  { id: 4, name: 'Multi Family', value: PropertyType.MULTI_FAMILY },
  { id: 5, name: 'Land', value: PropertyType.LAND },
  { id: 6, name: 'Other', value: PropertyType.OTHER },
  { id: 7, name: 'Commercial', value: PropertyType.COMMERCIAL },
  { id: 8, name: 'Commercial Lease', value: PropertyType.COMMERCIAL_LEASE },
  { id: 9, name: 'Residential Lease', value: PropertyType.RESIDENTIAL_LEASE },
];

PropertyType.propTypes = {
  className: PropTypes.string,
  itemClassName: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  viewMode: PropTypes.bool,
  isTransactionCreation: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  error: PropTypes.string,
  testid: PropTypes.string,
  dropdownClassName: PropTypes.string,
  propertySelectClassName: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string,
  selectWrapperClassname: PropTypes.string,
};

PropertyType.defaultProps = {
  selectWrapperClassname: '',
  className: '',
  label: '',
  onChange: () => {},
  multiple: true,
  value: [],
  viewMode: false,
  disabled: false,
  inline: false,
  error: undefined,
  testid: 'property_type',
  propertySelectClassName: '',
  variant: Select.LIGHT_ROUND,
};

export default PropertyType;
