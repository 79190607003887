import { FC, ReactElement, SVGAttributes } from 'react';

export const PlusIcon: FC<SVGAttributes<SVGElement>> = (
  props: SVGAttributes<SVGElement>,
): ReactElement => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.17188 5.17157L10.8287 10.8284"
      stroke="#747475"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M5.17188 10.8284L10.8287 5.17158"
      stroke="#747475"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
