import { renderStatus } from 'pages/Workshop/Forms/components/FormsTable';
import { DateTimeColumn, DocumentNameColumn } from './TableColumns';
import { Options } from '../Options';
import { AgentDocumentTypes } from 'types';

const docType = AgentDocumentTypes.Drafts;

export const draftColumns = (optionUtils) => [
  {
    key: 'title',
    title: 'Name',
    dataIndex: 'title',
    width: 275,
    render: (title) => <DocumentNameColumn name={title} docIconType={docType} />,
  },
  {
    key: 'status',
    title: 'Status',
    width: 200,
    render: () => renderStatus('Draft'),
  },
  {
    key: 'address',
    title: 'Transaction',
    width: 350,
    render: (row) => <span style={{ fontSize: 16 }}>{row?.draftMeta?.address || '-'}</span>,
  },
  {
    key: 'lastEdited',
    title: 'Last Edited',
    render: (row) => <DateTimeColumn date={row?.draftMeta?.lastEdit} />,
  },
  {
    key: 'options',
    title: '',
    render: (row) => <Options document_={row} type={docType} optionUtils={optionUtils} />,
  },
];
