import { TableColumnsType } from 'antd';
import React, { useEffect, useState } from 'react';
import { Table as AntdTable } from 'components-antd';
import { Button, Wrapper as PendingWrapper } from 'components';
import styles from './styles.module.scss';
import { Plus } from 'components/Icons';
import classNames from 'classnames';

type Props = {
  data: any[];
  columns: TableColumnsType<any>;
  title?: string;
  count?: number;
  titlePrefix?: any;
  defaultCollapse?: boolean;
  disableCollapse?: boolean;
  disableHeader?: boolean;
  handleRowClick?: any;
  handleNew?: () => void;
  isLoading?: boolean;
  newTitle?: string;
  emptyText?: string;
};

export const Table: React.FC<Props> = ({
  data,
  columns,
  title,
  count,
  titlePrefix,
  defaultCollapse = false,
  disableCollapse = false,
  disableHeader = false,
  isLoading = false,
  handleRowClick,
  handleNew,
  newTitle,
  emptyText = 'No Date',
}) => {
  const [collapsed, setIsCollapsed] = useState(defaultCollapse);

  useEffect(() => {
    setIsCollapsed(defaultCollapse);
  }, [defaultCollapse]);

  function onClick() {
    if (disableCollapse) return;
    setIsCollapsed(!collapsed);
  }

  return (
    <PendingWrapper spinnerClassname={styles.centerSpinner} isPending={isLoading}>
      <div className={styles.tableWrapper}>
        {!disableHeader && (
          <div className={styles.tableWrapperHeader}>
            <div
              className={classNames(styles.heading, { [styles.cursor]: !disableCollapse })}
              onClick={onClick}
            >
              {titlePrefix}
              {title}{' '}
              {typeof count === 'number' && (disableCollapse ? true : collapsed) ? (
                <span>{`(${count})`}</span>
              ) : null}
            </div>
            {handleNew && (
              <Button
                title={newTitle || 'New'}
                icon={<Plus color="#fff" />}
                className={styles.add}
                onClick={handleNew}
              />
            )}
          </div>
        )}
        {!collapsed && (
          <div>
            <AntdTable
              dataSource={data}
              columns={columns}
              showSorterTooltip={false}
              pagination={false}
              className={styles.table}
              locale={{ emptyText }}
              onRow={(record) => ({
                onClick: () => handleRowClick && handleRowClick(record),
              })}
            />
          </div>
        )}
      </div>
    </PendingWrapper>
  );
};
