import { apiServer } from 'settings/web-services/api';

export function getMatchedClientsWithScoresByMls(cfg) {
  const { ids } = cfg;
  return apiServer.get('/listings/feed/matchedClients', {
    params: {
      PropertyMLSIds: ids,
    },
  });
}

export function getAgentMatchScore(cfg) {
  const { id, searchQuery } = cfg;
  return apiServer.post(`/listings/feed/agentScore?PropertyMLSId=${id}`, {
    searchQuery,
  });
}

export function getPropertyInsight(cfg) {
  const { id, MatchScore, MatchedCriteria } = cfg;
  return apiServer.post(`/ai/property-insights/${id}`, {
    MatchScore,
    MatchedCriteria,
  });
}

export function getAdditionalFeatures(cfg) {
  const { id, matchCriteria } = cfg;
  return apiServer.post(`/ai/additonal-feature/${id}`, {
    matchCriteria,
  });
}
