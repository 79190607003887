import { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Close } from 'components/Icons';
import { extractClientAddress } from 'utils';
import { getSearchDrawnPolygonSelector } from 'store/selectors/search';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { CASS_ADDRESS } from 'settings/constants/locations';
import { cleanSearchDrawnPolygonAction } from 'store/actions/searchResults';
import { LocationType } from 'types';

const Values = (props) => {
  const {
    values,
    className,
    valueClassName,
    onDelete,
    multiple,
    showMultipleText,
    displayedValuesLimit,
    groupSize,
    locationSelector,
    customPlaceName,
  } = props;

  const dispatch = useDispatch();
  const displayedValues = useMemo(
    () =>
      (values || [])
        .sort((a, b) => {
          const isAPolygon = a.Type === LocationType.Polygon;
          const isBPolygon = b.Type === LocationType.Polygon;
          if (isAPolygon && !isBPolygon) {
            return -1;
          }
          if (!isAPolygon && isBPolygon) {
            return 1;
          }
          return 0;
        })
        .slice(0, displayedValuesLimit),
    [values, displayedValuesLimit],
  );
  const moreValues = useMemo(
    () => (values || []).slice(displayedValuesLimit),
    [values, displayedValuesLimit],
  );
  const groups = useMemo(() => {
    if (groupSize) {
      let count = 0;
      const grouped = [];
      while (count < displayedValues.length) {
        const group = displayedValues.slice(count, count + groupSize);
        grouped.push(group);
        count += group.length;
      }
      return grouped;
    }
    return null;
  }, [displayedValues, groupSize]);

  if (!values || !values?.length) return null;

  const getMoreValuesHint = () => {
    const names = moreValues.map(({ PlaceName }) => PlaceName);
    return names.join(', ');
  };

  const getValue = (val) => {
    if (!val) return null;
    const { ProviderPlaceId, PlaceName, delivery_line_1, Type } = val || {};
    const parsedAddress = extractClientAddress(PlaceName);

    const placeName = customPlaceName
      ? customPlaceName?.(parsedAddress)
      : parsedAddress?.address?.split(',')[0];
    const isPolygon = Type === LocationType.Polygon;

    return (
      <div
        data-rh={PlaceName}
        key={ProviderPlaceId}
        className={classNames(
          styles.value,
          { [styles.multiple]: multiple },
          { [styles.custom]: isPolygon },
          valueClassName,
        )}
        testid="location_value"
      >
        {isPolygon ? 'Custom' : delivery_line_1 ?? placeName}
        <Close onClick={() => onDelete(ProviderPlaceId, isPolygon)} className={styles.icon} />
      </div>
    );
  };

  return (
    <div className={classNames(styles.values, className, { [styles.grouped]: !!groups?.length })}>
      {groups?.length
        ? groups.map((group, index) => (
            <div className={styles.valuesGroup} key={group[0]?.ProviderPlaceId || index}>
              {group.map((val) => getValue(val))}
            </div>
          ))
        : displayedValues.map((val) => getValue(val))}
      {!!moreValues?.length && (
        <div data-rh={getMoreValuesHint()} className={styles.more}>{`+${moreValues?.length}`}</div>
      )}
      {showMultipleText && !moreValues?.length && !locationSelector ? (
        <p className={styles.anotherText}>Add another</p>
      ) : null}
    </div>
  );
};

Values.propTypes = {
  className: PropTypes.string,
  valueClassName: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      placeName: PropTypes.string,
    }),
  ).isRequired,
  onDelete: PropTypes.func,
  multiple: PropTypes.bool,
  showMultipleText: PropTypes.bool,
  displayedValuesLimit: PropTypes.number,
  groupSize: PropTypes.number,
};

Values.defaultProps = {
  className: '',
  valueClassName: '',
  onDelete: () => {},
  multiple: true,
  showMultipleText: false,
  displayedValuesLimit: 2,
  groupSize: null,
};

export default Values;
