import * as Yup from 'yup';

// eslint-disable-next-line
const websiteRegex =
  /((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%-]+&?)?$/;

const Email = Yup.string().trim().email('Invalid email').required('Required');
const Address = Yup.object().nullable(true);
const SuiteUnit = Yup.string().trim().nullable(true);
const Website = Yup.string().trim().matches(websiteRegex, 'Enter correct url!').nullable(true);
export const getPhoneValidator = (country) => {
  const countryCode = country?.countryCode?.toUpperCase() || 'US';
  return Yup.string()
    .test({
      test: function (value) {
        // Check if the value is empty or a valid phone number
        return !value || Yup.string().phone(countryCode).isValidSync(value);
      },
      message: `Must be a valid phone number for region ${countryCode}`,
    })
    .nullable(true);
};
const getContactUserValidator = (country) =>
  Yup.object()
    .shape({
      FirstName: Yup.string(),
      LastName: Yup.string(),
      Email,
      Phones: Yup.array().of(getPhoneValidator(country)),
    })
    .required('Required');

export const ValidationSchema = (country, isUpdate) =>
  Yup.object().shape({
    BusinessName: Yup.string().trim().required('Required'),
    AdditionalBusinessName: Yup.string().nullable(true),
    Email,
    PhoneNumber: getPhoneValidator(country),
    Address,
    SuiteUnit,
    Description: Yup.string().trim().nullable(true),
    Website,
    IsRecommendedByMosaik: Yup.boolean(),
    IsActive: Yup.boolean(),
    PartnerCategoryIds: Yup.array().min(1, 'Required').of(Yup.object().shape({})),
    PartnerServices: Yup.array().optional(),
    RecommendedByAgentsId: Yup.array().optional(),
    ...(isUpdate
      ? {
          ContactUser: Yup.object().shape({
            Email,
          }),
        }
      : { ContactUser: getContactUserValidator(country) }),
  });
