import { showErrorMessage } from 'helpers/errors';
import { cloneDeep, get } from 'lodash-es';
import { socketsChangeStatusAction, socketsResetAction } from 'store/actions/sockets';
import {
  socketsStoreInitNotificationsAction,
  socketsStoreNewNotificationAction,
} from 'store/actions/sockets/notifications';
import {
  socketsStoreNewMessageAction,
  socketsStoreEditedMessageAction,
  socketsRemoveDeletedMessageAction,
  socketsStoreDeletedMessageAction,
  socketsStoreMessagesByThreadIdAction,
  socketsResetMessagesAction,
} from 'store/actions/sockets/messages';
import {
  socketsStoreNewTransactionMessageAction,
  socketsStoreEditedTransactionMessageAction,
  socketsRemoveDeletedTransactionMessageAction,
} from 'store/actions/sockets/transactionMessages';
import {
  socketsStoreNewTransactionTaskMessageAction,
  socketsStoreEditedTransactionTaskMessageAction,
  socketsRemoveDeletedTransactionTaskMessageAction,
} from 'store/actions/sockets/transactionTaskMessages';
import {
  socketsStoreNewTransactionNoteAction,
  socketsStoreEditedTransactionNoteAction,
  socketsRemoveDeletedTransactionNoteAction,
} from 'store/actions/sockets/transactionNotes';
import {
  socketsStoreNewOfferMessageAction,
  socketsStoreEditedOfferMessageAction,
  socketsRemoveDeletedOfferMessageAction,
} from 'store/actions/sockets/offerMessages';
import {
  socketsStoreInitThreadsAction,
  socketsStoreNewThreadAction,
  socketsStoreParticipantOnlineStatusAction,
  socketsStoreRemovedFromThreadAction,
  socketsStoreUpdatedThreadAction,
} from 'store/actions/sockets/threads';

import {
  connectSocketToBackEnd,
  setOnDisconnectHandler,
  setOnConnectErrorHandler,
  setOnRecieveNewMessagesHandler,
  setOnRecieveEditedMessagesHandler,
  setOnRecieveDeletedMessagesHandler,
  setOnRecieveRemovedMessagesHandler,
  setOnRecieveInitThreadsHandler,
  setOnRecieveNewThreadHandler,
  setOnRecieveRemovedFromThreadHandler,
  setOnRecieveUpdatedThreadHandler,
  closeSocket,
  setOnReceiveInitNotificationsHandler,
  setOnReceiveNewNotificationHandler,
  setOnReceiveMessageReadReceiptHandler,
  setOnReceiveThreadParticipantOnlineStatusHandler,
  onUnauthorized,
} from 'services/sockets';
import { getState } from 'store';
import { getCurrentMessagesThreadId } from 'store/selectors/sockets/messages';
import { getCurrentTransactionMessagesThreadIdSelector } from 'store/selectors/sockets/transactionMessages';
import { getCurrentTransactionTaskMessagesThreadIdSelector } from 'store/selectors/sockets/transactionTaskMessages';
import { getCurrentTransactionNotesThreadIdSelector } from 'store/selectors/sockets/transactionNotes';
import { getCurrentOfferMessagesThreadIdSelector } from 'store/selectors/sockets/offerMessages';

import { SOCKET_STATE, SOCKET_THREAD_TYPES } from 'settings/constants/sockets';
import { openNotification } from 'components-antd';
import notificationStyles from '../../../components-antd/NotificationPopup/styles.module.scss';
import { convertNameToAvatarPlaceholder } from 'helpers';
import AvatarComponent from 'components/Messages/MessagesView/Message/Avatar';
import {
  changeMessagesDrawerTypeAction,
  openMessagesDrawerAction,
} from 'store/actions/drawers/messages';
import { DRAWER_MESSAGES_TYPES } from 'settings/constants/drawers';
import { socketsGetOfferMessagesByThreadIdEffect } from './offerMessages';
import { socketsGetTransactionTaskMessagesByThreadIdEffect } from './transactionTaskMessages';
import { socketsGetTransactionNotesByThreadIdEffect } from './transactionNotes';

// import { getSocket } from 'settings/web-services/sockets';

export function socketsInitEffect() {
  return async (dispatch) => {
    // connection
    connectSocketToBackEnd(() =>
      dispatch(socketsChangeStatusAction({ status: SOCKET_STATE.CONNECTED })),
    );

    onUnauthorized(() => {});

    setOnDisconnectHandler(() => {
      console.warn('Chat api was disconnected');
      dispatch(socketsChangeStatusAction({ status: SOCKET_STATE.DISCONNECTED }));
    });
    setOnConnectErrorHandler((error) => {
      // TODO: check error and send to the login page
      console.error(`Chat socket connection error: ${error?.message}`);
      dispatch(socketsChangeStatusAction({ status: SOCKET_STATE.ERROR, error }));
    });

    // recieve messages
    setOnRecieveNewMessagesHandler((data) => {
      const state = getState();
      if (data?.MessageThread?.Type === SOCKET_THREAD_TYPES.TRANSACTION) {
        const currentThreadId = getCurrentTransactionMessagesThreadIdSelector(getState());
        return dispatch(
          socketsStoreNewTransactionMessageAction({
            data,
            currentThread: currentThreadId === data?.MessageThreadId,
          }),
        );
      }

      if (data?.MessageThread?.Type === SOCKET_THREAD_TYPES.TRANSACTION_TASK) {
        const currentThreadId = getCurrentTransactionTaskMessagesThreadIdSelector(getState());
        return dispatch(
          socketsStoreNewTransactionTaskMessageAction({
            data,
            currentThread: currentThreadId === data?.MessageThreadId,
          }),
        );
      }

      if (data?.MessageThread?.Type === SOCKET_THREAD_TYPES.TRANSACTION) {
        const currentThreadId = getCurrentTransactionNotesThreadIdSelector(getState());
        return dispatch(
          socketsStoreNewTransactionNoteAction({
            data,
            currentThread: currentThreadId === data?.MessageThreadId,
          }),
        );
      }

      if (data?.MessageThread?.Type === SOCKET_THREAD_TYPES.OFFER) {
        const currentThreadId = getCurrentOfferMessagesThreadIdSelector(getState());
        return dispatch(
          socketsStoreNewOfferMessageAction({
            data,
            currentThread: currentThreadId === data?.MessageThreadId,
          }),
        );
      }

      const currentThreadId = getCurrentMessagesThreadId(state);
      const currentThread = currentThreadId === data?.MessageThreadId;

      const messagesThread = cloneDeep(get(state, 'sockets.threads')[data?.MessageThreadId]);
      const messageSender = messagesThread?.Participants?.find((p) => p?.Id === data.SenderUserId);

      if (!currentThread) {
        openNotification({
          message: <span className={notificationStyles.notificationTitle}>New Message:</span>,
          description: (
            <span className={notificationStyles.notificationDescription}>
              <strong>{`${messageSender.FirstName} ${messageSender.LastName}: `}</strong>
              {data?.Text || 'Attachment'}
            </span>
          ),
          key: data?.MessageType + data?.Id,
          placement: 'bottomRight',
          closeIcon: (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M17 17L7 7" stroke="#F4F5F6" strokeWidth="2" strokeLinecap="round" />
              <path d="M7 17L17 7" stroke="#F4F5F6" strokeWidth="2" strokeLinecap="round" />
            </svg>
          ),
          icon: (
            <AvatarComponent
              placeholder={convertNameToAvatarPlaceholder(
                `${messageSender.FirstName} ${messageSender.LastName}`,
              )}
              className={notificationStyles.avatarWrapper}
              avatarClassName={notificationStyles.avatar}
              src={messageSender?.AvatarUrl}
            />
          ),
          onClick: () => {
            dispatch(openMessagesDrawerAction(true));
            dispatch(
              changeMessagesDrawerTypeAction({
                type: DRAWER_MESSAGES_TYPES.CHAT,
                params: { threadId: data?.MessageThreadId, isPartnerGroup: false },
              }),
            );
          },
        });
      }

      return dispatch(
        socketsStoreNewMessageAction({
          data,
          currentThread,
        }),
      );
    });
    setOnRecieveEditedMessagesHandler((data) => {
      const state = getState();

      if (data?.MessageThread?.Type === SOCKET_THREAD_TYPES.TRANSACTION_TASK) {
        return dispatch(
          socketsGetTransactionTaskMessagesByThreadIdEffect({ Id: data?.MessageThread?.Id }),
        );
      }

      if (data?.MessageThread?.Type === SOCKET_THREAD_TYPES.TRANSACTION) {
        return dispatch(
          socketsGetTransactionNotesByThreadIdEffect({ Id: data?.MessageThread?.Id }),
        );
      }

      if (data?.MessageThread?.Type === SOCKET_THREAD_TYPES.OFFER) {
        return dispatch(socketsGetOfferMessagesByThreadIdEffect({ Id: data?.MessageThread?.Id }));
      }

      const currentMessages = cloneDeep(get(state, 'sockets.messages.messages', []));
      const lastMessage = currentMessages?.pop();
      const isLastMessage =
        data?.Id === lastMessage?.Id ||
        get(state, `sockets.threads[${data.MessageThreadId}].LastMessageId`, null) === data.Id;
      return dispatch(socketsStoreEditedMessageAction({ data, isLastMessage }));
    });
    setOnRecieveDeletedMessagesHandler((data) => {
      const state = getState();

      if (data?.MessageThread?.Type === SOCKET_THREAD_TYPES.TRANSACTION_TASK) {
        return dispatch(socketsStoreEditedTransactionTaskMessageAction({ data }));
      }

      if (data?.MessageThread?.Type === SOCKET_THREAD_TYPES.TRANSACTION) {
        return dispatch(socketsStoreEditedTransactionNoteAction({ data }));
      }

      if (data?.MessageThread?.Type === SOCKET_THREAD_TYPES.OFFER) {
        return dispatch(socketsStoreEditedOfferMessageAction({ data }));
      }

      const currentMessages = cloneDeep(get(state, 'sockets.messages.messages', []));
      let lastMessage = null;
      if (!currentMessages) {
        lastMessage = data;
      } else {
        lastMessage = currentMessages.pop();
      }
      const isLastMessage = data?.Id === lastMessage?.Id;
      lastMessage =
        isLastMessage && currentMessages
          ? currentMessages[currentMessages.length - 2]
          : lastMessage;
      return dispatch(socketsStoreDeletedMessageAction({ data, lastMessage }));
    });
    setOnRecieveRemovedMessagesHandler((data) => {
      if (data?.MessageThread?.Type === SOCKET_THREAD_TYPES.TRANSACTION) {
        return dispatch(socketsRemoveDeletedTransactionMessageAction({ data }));
      }

      if (data?.MessageThread?.Type === SOCKET_THREAD_TYPES.TRANSACTION_TASK) {
        return dispatch(socketsRemoveDeletedTransactionTaskMessageAction({ data }));
      }

      if (data?.MessageThread?.Type === SOCKET_THREAD_TYPES.TRANSACTION) {
        return dispatch(socketsRemoveDeletedTransactionNoteAction({ data }));
      }

      if (data?.MessageThread?.Type === SOCKET_THREAD_TYPES.OFFER) {
        return dispatch(socketsRemoveDeletedOfferMessageAction({ data }));
      }

      dispatch(socketsRemoveDeletedMessageAction({ data }));
    }); // TODO:

    // recieve threads
    setOnRecieveInitThreadsHandler((data) => dispatch(socketsStoreInitThreadsAction({ data })));
    setOnRecieveNewThreadHandler((data) => dispatch(socketsStoreNewThreadAction({ data }))); // TODO:
    setOnRecieveRemovedFromThreadHandler((data) => {
      dispatch(socketsStoreRemovedFromThreadAction({ data }));
      dispatch(socketsResetMessagesAction());
    });
    setOnRecieveUpdatedThreadHandler((data) => dispatch(socketsStoreUpdatedThreadAction({ data }))); // TODO:

    // receive notifications
    setOnReceiveInitNotificationsHandler((data) =>
      dispatch(socketsStoreInitNotificationsAction({ data })),
    );
    setOnReceiveNewNotificationHandler((data) =>
      dispatch(socketsStoreNewNotificationAction({ data })),
    );

    setOnReceiveMessageReadReceiptHandler((payload) => {
      const currentThreadId = getCurrentMessagesThreadId(getState());
      if (currentThreadId === payload?.threadId) {
        dispatch(
          socketsStoreMessagesByThreadIdAction({
            data: payload.messages,
            threadId: payload.threadId,
          }),
        );
      }
    });

    setOnReceiveThreadParticipantOnlineStatusHandler((payload) => {
      dispatch(
        socketsStoreParticipantOnlineStatusAction({
          threadId: payload.threadId,
          lastOnline: payload.lastOnline,
          participantId: payload.userId,
        }),
      );
    });
  };
}

export function socketsCloseEffect() {
  return (dispatch) => {
    dispatch(socketsResetAction());
    closeSocket();
  };
}

export function retrySocketConnectionEffect() {
  return (dispatch) => {
    dispatch(socketsCloseEffect());
    dispatch(socketsInitEffect());
  };
}
