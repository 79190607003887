import AntAvatar, { AvatarProps as AntdAvatarProps, Group } from 'antd/lib/avatar';
import classNames from 'classnames';

import styles from './styles.module.scss';

export interface AvatarProps extends AntdAvatarProps {
  noBackground?: boolean;
  backgroundClassName?: string;
}

export const Avatar = ({
  children,
  className,
  noBackground = false,
  backgroundClassName = '',
  ...props
}: AvatarProps) => (
  <AntAvatar
    className={classNames(
      styles.avatar,
      { [styles.defaultBackgroundColor]: !noBackground },
      backgroundClassName,
      className,
    )}
    {...props}
  >
    {children}
  </AntAvatar>
);

export const AvatarGroup = Group;
