import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { transactionPreFormQuestionsIds } from 'settings/constants/transaction';
import { Question } from 'pages/RequestQuote/components';
import { Input, Button } from 'components';
import { updateTransactionEffect } from 'store/effects/transactions';
import Answers from '../../Answers';
import { Continue } from '../../Continue';
import styles from './styles.module.scss';
import { AnswersContainer } from '../../AnswersContainer';
import { ButtonsContainer } from '../../ButtonsContainer';

const AdditionalComments = (props) => {
  const { className, onNext, currentQuestionId, transactionId } = props;
  const dispatch = useDispatch();
  const [additionalComments, setAdditionalComments] = useState(null);

  const onNextHandler = (event) => {
    if (currentQuestionId === transactionPreFormQuestionsIds.additionalComments) {
      const data = {
        id: transactionId,
        AdditionalCancellationComments: additionalComments,
      };

      dispatch(
        updateTransactionEffect(data, {}, (err) => {
          if (!err) {
            onNext(event);
          }
        }),
      );
    }
  };

  return (
    <div
      testid="what_purchase_prise"
      className={classNames(styles.presenting, styles.container, className)}
    >
      <Question>
        Do you want to add any additional comments <br /> (for your records)?
      </Question>
      <AnswersContainer>
        <Input
          variant={Input.LIGHT}
          placeholder="Add a comment..."
          onChange={(e, val) => setAdditionalComments(val)}
          value={additionalComments}
          testid="comment_input"
        />
      </AnswersContainer>
      <ButtonsContainer>
        <Continue onClick={onNextHandler} className={styles.continue} />
      </ButtonsContainer>
    </div>
  );
};

AdditionalComments.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  currentQuestionId: PropTypes.string,
  transactionId: PropTypes.string,
};

AdditionalComments.defaultProps = {
  className: '',
  onNext: () => {},
  currentQuestionId: null,
  transactionId: null,
};

export default AdditionalComments;
