import React from 'react';

import styles from '../DowngradeModal/styles.module.scss';
import { Button } from 'components-antd';
import { useSelector } from 'react-redux';
import {
  getInvoiceDataOverviewSelector,
  getLegacyPlanInterval,
} from 'store/selectors/subscription';
import moment from 'moment';
import { NewTabIcon } from '../../icons';
import classNames from 'classnames';

type Props = {
  handleNextStep: () => void;
  isLoading: boolean;
};

export const StepFive: React.FC<Props> = ({ handleNextStep, isLoading }) => {
  const invoiceDataOverview = useSelector(getInvoiceDataOverviewSelector);
  const currentPlanLegacyInterval = useSelector(getLegacyPlanInterval);
  const lastInvoiceDate = invoiceDataOverview?.LastInvoice?.Date;
  const nextBillingDate = moment(lastInvoiceDate)
    .add(currentPlanLegacyInterval === 'year' ? 12 : 1, 'M')
    .format('MMMM DD, YYYY');

  return (
    <div className={styles.content}>
      <div className={styles.header}>We&apos;re sorry to see you go!</div>
      <div className={classNames(styles.info, styles.textLeft)}>
        You will have access to your account until <strong>{nextBillingDate}</strong>.
      </div>
      <div className={classNames(styles.info, styles.textLeft)}>
        After {nextBillingDate}, your account will be permanently deleted. Your team (if applicable)
        and clients will also lose access to their accounts.
      </div>
      <div className={classNames(styles.info, styles.textLeft)}>
        Please ensure you download any files you want to retain before {nextBillingDate}. They will
        not be recoverable after {nextBillingDate}.
      </div>
      <div className={styles.secondaryInfo}>
        If you would like to reactivate your plan, you can do so from the Billing page or by
        contacting{' '}
        <a href="mailto:support@mosaik.io" target="_blank" rel="noreferrer">
          support@mosaik.io
        </a>
      </div>
      <div className={styles.btnContainer}>
        <a
          className={classNames(styles.anchorBtn, styles.cancelBtn)}
          href="https://www.mosaik.io/pricing"
          target="_blank"
          rel="noreferrer"
        >
          <NewTabIcon /> View Plans
        </a>
        <Button
          variant="primary"
          className={styles.confirmBtn}
          onClick={handleNextStep}
          loading={isLoading}
        >
          Reactivate
        </Button>
      </div>
    </div>
  );
};
