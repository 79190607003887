import PropTypes from 'prop-types';

import SummaryList from './SummaryList';
import { OnBoardingContainer } from 'pages/OnBoarding/components/OnBoardingContainer';
import { Question } from 'pages/RequestQuote/components';

import styles from './styles.module.scss';
import { AnswersContainer } from 'pages/OnBoarding/components/AnswersContainer';
import { sellerSignupSteps } from 'pages/OnBoarding/components/Middle';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getClientDefaultSearchSelector } from 'store/selectors/user';

const Summary = (props) => {
  const { onNext, Controls } = props;
  const defaultClientSearch = useSelector(getClientDefaultSearchSelector);
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    setIsValid(defaultClientSearch?.Locations?.length > 0);
  }, [defaultClientSearch?.Locations?.length]);

  return (
    <>
      <Controls className={styles.controls} variant="lightFull" />
      <OnBoardingContainer>
        <Question>Please enter your search preferences.</Question>
        <AnswersContainer onNext={() => onNext(sellerSignupSteps.HOME_PREFS)} disabled={!isValid}>
          <SummaryList onNext={onNext} setIsValid={setIsValid} />
        </AnswersContainer>
      </OnBoardingContainer>
    </>
  );
};

Summary.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  Controls: PropTypes.elementType,
};

Summary.defaultProps = {
  className: '',
  onNext: () => {},
  Controls: () => null,
};

export default Summary;
