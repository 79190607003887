import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import { Row, Col, Button } from 'components-antd';
import { ConfirmationDialog } from 'components';
import { NameAutocomplete, PriceHighlighter } from '../../..';
import { useParams } from 'react-router-dom';
import { removeFeeLineItem, updateFeeLineItem } from 'api/financials';
import { Select } from 'components';
import CheckboxComponent from 'components/Form/Checkbox';
import { Options } from './../../components';
import { CommissionField } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/Questions/FinancingDetails/components';
import { Icons } from 'pages/Workshop/Icons';

import { convertToDecimalIfNotWhole } from 'helpers';
import { getUserId } from 'store/selectors/user';

import styles from './styles.module.scss';

type FeeItemType = {
  feeId?: number;
  label: string;
  labelClassName?: string;
  containerClassName?: string;
  amount: number;
  isRestricted?: boolean;
  percentage: number | null;
  notEditable?: boolean;
  fieldKey: string;
  setEditField?: Function;
  isDisableField: Function;
  addToAccount?: number;
  addToAccountIdLineItemId?: number;
  deductFrom?: number;
  splitInfo?: any[];
  fetchFinancials?: Function;
  isPercentage?: boolean;
  feeArray?: any[];
  isTransactionAdminOrOwner?: boolean;
  autocompleteFeeNames?: any[];
  hidePriceHighlighter?: boolean;
  addToOptions?: any[];
  ledgerId?: number | null;
};

export const FeeItem = ({
  feeId,
  label,
  labelClassName = '',
  containerClassName = '',
  amount,
  isRestricted = false,
  percentage,
  notEditable = false,
  fieldKey,
  setEditField,
  isDisableField,
  addToAccount = 0,
  addToAccountIdLineItemId,
  deductFrom = 0,
  splitInfo = [],
  fetchFinancials,
  isPercentage,
  feeArray = [],
  isTransactionAdminOrOwner,
  autocompleteFeeNames = [],
  hidePriceHighlighter = false,
  addToOptions = [],
  ledgerId,
}: FeeItemType) => {
  const [isEdit, setIsEdit] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationProps, setConfirmationProps] = useState({
    title: '',
    message: <></>,
    action: () => {},
  });
  const [splitDropdownOption, setSplitDropdownOption] = useState<any>(null);
  const [localAddToAccount, setLocalAddToAccount] = useState<any>(null);
  const [updateTitle, setUpdateTitle] = useState('');
  const [isValidateUpdate, setValidateUpdate] = useState(false);
  const [isRestrictedUpdate, setIsRestrictedUpdate] = useState(false);
  const [updateAmountObj, setUpdateAmountObj] = useState<any>({
    isUpdatePercentage: false,
    feeAmount: null,
    feePercent: null,
  });
  const [isUpdateFeeLoading, setUpdateFeeLoading] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const loggedInUserId = useSelector(getUserId);

  const params: { id?: string } = useParams();

  useEffect(() => {
    setSplitDropdownOption(splitInfo?.find((item) => item?.accountId === deductFrom));
  }, [deductFrom, splitInfo]);

  useEffect(() => {
    setLocalAddToAccount(addToOptions?.find((item) => item?.accountId === addToAccount));
  }, [addToAccount, addToOptions]);

  useEffect(() => {
    setUpdateTitle(label);
    setUpdateAmountObj({
      isUpdatePercentage: isPercentage,
      feeAmount: amount,
      feePercent: percentage,
    });
    setIsRestrictedUpdate(isRestricted);
  }, []);

  const onEdit = () => {
    setEditField && setEditField(fieldKey);
    setIsEdit(true);
  };

  const onCancel = () => {
    setEditField && setEditField(null);
    setSplitDropdownOption(splitInfo?.find((item) => item?.accountId === deductFrom));
    setUpdateTitle(label);
    setUpdateAmountObj({
      isUpdatePercentage: isPercentage,
      feeAmount: amount,
      feePercent: percentage,
    });
    setIsRestrictedUpdate(isRestricted);
    setIsEdit(false);
    setValidateUpdate(false);
  };

  const handleSelect = (e, selectedOption) => {
    // Update percent/amount of the item based on deduct to specific compare price.
    setUpdateAmountObj({
      ...updateAmountObj,
      ...(updateAmountObj?.isUpdatePercentage
        ? {
            feeAmount: calculateGCI(updateAmountObj?.feePercent, selectedOption?.comparePrice),
          }
        : { feePercent: calculateRate(updateAmountObj?.feeAmount, selectedOption?.comparePrice) }),
    });

    setSplitDropdownOption(selectedOption);
  };

  const handleAddToAccount = (e, selectedOption) => {
    setLocalAddToAccount(selectedOption);
  };

  const getUpdateFeeDisable = () => {
    if (
      updateAmountObj?.feeAmount === null ||
      updateAmountObj?.feeAmount === 0 ||
      updateTitle === '' ||
      splitDropdownOption?.value === null
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isPriceWithinRangeUpdate = (compareprice, sum, _updatedObject) => {
    let calculatedValue = 0;
    let _sum = 0;
    if (_updatedObject?.isUpdatePercentage) {
      let _data = _updatedObject?.feeAmount / 100;

      calculatedValue = (_updatedObject?.feeAmount / 100) * compareprice;
    } else {
      calculatedValue = _updatedObject?.feeAmount;
    }

    _sum = +calculatedValue + sum;

    if (_sum === compareprice || _sum < compareprice) {
      setValidateUpdate(false);
    } else {
      setValidateUpdate(true);
    }
  };

  const onUpdateFee = async () => {
    try {
      setUpdateFeeLoading(true);
      const payload = {
        LedgerId: ledgerId,
        ...(splitDropdownOption?.ledgerLineItemId
          ? { DeductFromLedgerLineItemId: splitDropdownOption?.ledgerLineItemId } // For Splits, passing the LedgerLineItemId
          : { DeductFromLedgerAccountId: splitDropdownOption?.accountId }), // For Others, passing the LedgerAccountId
        ...(localAddToAccount?.ledgerLineItemId
          ? { AddToLedgerLineItemId: localAddToAccount?.ledgerLineItemId } // For Splits, passing the LedgerLineItemId
          : { AddToLedgerAccountId: localAddToAccount?.accountId }), // For Others, passing the LedgerAccountId
        Memo: updateTitle,
        ...(updateAmountObj?.isUpdatePercentage
          ? {
              Percent: updateAmountObj?.feePercent,
            }
          : { Amount: updateAmountObj?.feeAmount }),
        IsRestricted: isRestrictedUpdate,
        UpdatedBy: loggedInUserId,
        EditDeductFeeLineItemId: feeId,
        EditAddFeeLineItemId: addToAccountIdLineItemId,
      };
      await updateFeeLineItem(payload);
      fetchFinancials && fetchFinancials();
    } catch (err) {
      setUpdateFeeLoading(false);
      setIsEdit(false);
    } finally {
      setUpdateFeeLoading(false);
      setIsEdit(false);
    }
  };

  const onDeleteFee = async () => {
    try {
      setConfirmationLoading(true);
      await removeFeeLineItem(feeId);
      fetchFinancials && fetchFinancials();
    } catch (err) {
      setConfirmationLoading(false);
    } finally {
      setConfirmationLoading(false);
      setShowConfirmationModal(false);
    }
  };

  const addToAccountLabel = () => {
    if (!addToAccount) return null;
    return `-> ${
      addToOptions?.find((item) => item.accountId === addToAccount)?.name || 'Outbound'
    } `;
  };

  const splitTypeLabel = () => {
    if (!deductFrom) return null;
    return `${splitInfo?.find((item) => item.accountId === deductFrom)?.name || 'GCI'} `;
  };

  const calculateGCI = (rate, comparePrice = null) => {
    const priceToCompare = comparePrice || splitDropdownOption?.comparePrice;

    return (rate / 100) * priceToCompare;
  };

  const calculateRate = (GCI, comparePrice = null) => {
    const priceToCompare = comparePrice || splitDropdownOption?.comparePrice;

    return convertToDecimalIfNotWhole((GCI * 100) / priceToCompare);
  };

  return (
    <div
      className={classNames(
        styles.feeItem,
        { [styles.feeItemEdit]: !isEdit && !notEditable && !isDisableField(fieldKey) },
        { [styles.disabled]: isDisableField(fieldKey) },
        containerClassName,
      )}
    >
      {!isEdit ? (
        <Fragment>
          <div>
            <div className={styles.labelContainer}>
              <p className={classNames(styles.label, labelClassName)}>{label}</p>
              {!notEditable && isRestrictedUpdate && <Icons variant={Icons.RESTRICTED} />}
            </div>
            <p className={styles.secondaryLabel}>
              {splitTypeLabel()} {addToAccountLabel()}
            </p>
          </div>
          <div className={styles.amounts}>
            {amount || percentage ? (
              <>
                <NumberFormat
                  thousandSeparator
                  displayType="text"
                  value={convertToDecimalIfNotWhole(amount)}
                  prefix="$"
                  renderText={(val) => (
                    <span className={styles.amount}>
                      {val}
                      <PriceHighlighter isShow={!isPercentage && !hidePriceHighlighter} />
                    </span>
                  )}
                />
                {percentage !== null && (
                  <span className={styles.percentage}>
                    ({convertToDecimalIfNotWhole(percentage)}%)
                    <PriceHighlighter isShow={isPercentage && !hidePriceHighlighter} />
                  </span>
                )}
              </>
            ) : (
              <span>-</span>
            )}
            <div className={styles.editIcon}>
              <Options
                onEdit={onEdit}
                onDelete={() => {
                  setConfirmationProps({
                    title: 'Delete',
                    message: (
                      <>
                        Are you sure you want to delete <b>{label}</b>?
                      </>
                    ),
                    action: onDeleteFee,
                  });
                  setShowConfirmationModal(true);
                }}
              />
            </div>
          </div>
        </Fragment>
      ) : (
        <Row gutter={12}>
          <Col lg={12}>
            <NameAutocomplete
              label="Fee"
              allNames={autocompleteFeeNames?.map((item, idx) => ({ label: item, value: idx + 1 }))}
              value={updateTitle}
              onChange={(value) => setUpdateTitle(value?.label)}
              onSelect={(value) => setUpdateTitle(value?.label)}
            />
          </Col>
          <Col lg={12}>
            <CommissionField
              label="Amount"
              value={
                updateAmountObj?.isUpdatePercentage
                  ? updateAmountObj?.feePercent
                  : updateAmountObj?.feeAmount
              }
              update={(object) => {
                const isToggled =
                  object?.IsFeeTypePercentage !== updateAmountObj?.isUpdatePercentage;
                const value = object?.Value === '' || null ? null : parseFloat(object?.Value);
                // Handle toggling between $ / % button
                if (
                  isToggled ||
                  (object?.IsFeeTypePercentage && value == updateAmountObj?.feePercent) ||
                  (!object?.IsFeeTypePercentage && value == updateAmountObj?.feeAmount)
                ) {
                  const updatedObject = {
                    ...updateAmountObj,
                    isUpdatePercentage: object?.IsFeeTypePercentage,
                  };
                  setUpdateAmountObj(updatedObject);
                } else {
                  const updatedObject = {
                    isUpdatePercentage: object?.IsFeeTypePercentage,
                    ...(object?.IsFeeTypePercentage
                      ? {
                          feePercent: value,
                          feeAmount: calculateGCI(value),
                        }
                      : {
                          feeAmount: value,
                          feePercent: calculateRate(value),
                        }),
                  };
                  let _selectedSplitData =
                    splitInfo &&
                    splitInfo.filter((data) => {
                      return data?.value === splitDropdownOption?.value;
                    });
                  let _selectedFeeListingData =
                    feeArray &&
                    feeArray.filter((_data) => {
                      return _data?.SplitId === splitDropdownOption?.value && _data?.Id !== feeId;
                    });

                  let sum = _selectedFeeListingData.reduce((acc, obj) => acc + obj?.FeeValue, 0);

                  if (splitDropdownOption?.value !== 0) {
                    isPriceWithinRangeUpdate(
                      _selectedSplitData && _selectedSplitData[0]?.amount,
                      sum,
                      updatedObject,
                    );
                  }
                  setUpdateAmountObj(updatedObject);
                }
              }}
              IsFeeTypePercentage={updateAmountObj?.isUpdatePercentage}
            />
            {/* {isValidateUpdate && (
              <ErrorMessage
                message={'Fee amount can never be greater than its relevant split price'}
              />
            )} */}
          </Col>
          <Col lg={12} className={styles.marginTop}>
            <p className={styles.applyLabel}>{'Deduct From'}</p>
            <Select
              options={splitInfo}
              onSelect={handleSelect}
              isArrowIcon={true}
              variant={Select.LIGHT_ROUND}
              className={{
                input: styles.inputHeight,
              }}
              value={splitDropdownOption?.value}
            />
          </Col>
          <Col lg={12} className={styles.marginTop}>
            <p className={styles.applyLabel}>{'Add To'}</p>
            <Select
              options={addToOptions}
              onSelect={handleAddToAccount}
              isArrowIcon={true}
              variant={Select.LIGHT_ROUND}
              className={{
                input: styles.inputHeight,
              }}
              value={localAddToAccount}
            />
          </Col>
          <Col lg={12}>
            <div className={styles.buttonsContainer}>
              <Button
                variant="secondary"
                size="large"
                onClick={onUpdateFee}
                loading={isUpdateFeeLoading}
                disabled={getUpdateFeeDisable()}
              >
                Update
              </Button>
              <Button variant="hollow-bordered" size="large" onClick={onCancel}>
                Cancel
              </Button>
            </div>
          </Col>
          {isTransactionAdminOrOwner && (
            <Col lg={12} className={styles.checkboxCont}>
              <CheckboxComponent
                id="restricted-update"
                label="Restricted"
                checked={isRestrictedUpdate}
                onChange={(e, v, checked) => setIsRestrictedUpdate(checked)}
                direction={CheckboxComponent.DIRECTION_RIGHT}
              />
            </Col>
          )}
        </Row>
      )}
      <ConfirmationDialog
        onReject={() => setShowConfirmationModal(false)}
        onConfirm={confirmationProps?.action}
        isOpen={showConfirmationModal}
        confirmText={confirmationProps?.title}
        isPending={confirmationLoading}
        className={styles.deleteFeeDialog}
      >
        <div className={styles.content}>{confirmationProps?.message}</div>
      </ConfirmationDialog>
    </div>
  );
};
