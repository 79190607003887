import { Role } from 'app-constants';
import ParticipantInputView, { ValueObject } from './ParticipantInputView';
import { useSelector } from 'react-redux';
import {
  getRelatedClientContactsFormattedList,
  getTransactionRelatedAgentCoordinatorContactsFormattedList,
} from 'store/selectors/relatedContacts/relatedContacts';
import { transactionPreFormQuestionsIds as questionId } from 'settings/constants/transaction';

interface ParticipantInputProps {
  className?: string;
  dropdownContainer?: string;
  userInfoContainer?: string;
  userNameContainer?: string;
  userEmailContainer?: string;
  value: ValueObject;
  onChange?: (...args) => void;
  onBlur?: (...args) => void;
  disabled?: boolean;
  placeholder?: string;
  excludedMemberIds?: number[];
  currentQuestionId?: string;
  allowExternalMember?: boolean;
  onAddExternalParticipant?: (...args) => void;
}

const ParticipantInput = ({
  excludedMemberIds,
  currentQuestionId,
  ...props
}: ParticipantInputProps) => {
  const isClient = currentQuestionId === 'inviteClient';
  let clientList = useSelector(getRelatedClientContactsFormattedList || []);
  let agentCoordinatorList = useSelector(
    getTransactionRelatedAgentCoordinatorContactsFormattedList || [],
  );

  let participants;

  if (currentQuestionId === 'inviteClient') {
    participants = clientList.filter((member) => !excludedMemberIds?.includes(member.id));
  }

  const inviteAgentQuestion = currentQuestionId === questionId.agents;
  const inviteCoordinatorQuestion = currentQuestionId === questionId.coordinators;
  const whoisReprentingBuyerQuestion = currentQuestionId === questionId.whoIsRepresentingTheBuyer;

  if (inviteAgentQuestion || inviteCoordinatorQuestion || whoisReprentingBuyerQuestion) {
    participants = agentCoordinatorList.filter((member) => !excludedMemberIds?.includes(member.id));
  }

  return <ParticipantInputView transactionUsers={participants} isClient={isClient} {...props} />;
};

export default ParticipantInput;
