import {
  NewListingIcon,
  PriceReductionIcon,
  PriceIncreaseIcon,
  BackOnTheMarketIcon,
  SoldIcon,
  AgentUpdatesIcon,
} from '../Icons';
import { Categories } from './types';

export const icons = {
  [Categories.NEW_LISTING]: { icon: <NewListingIcon />, bgColor: '#4673D114' },
  [Categories.PRICE_REDUCTION]: { icon: <PriceReductionIcon />, bgColor: '#04A45114' },
  [Categories.PRICE_INCREASE]: { icon: <PriceIncreaseIcon />, bgColor: '#EC455E14' },
  [Categories.BACK_ON_THE_MARKET]: { icon: <BackOnTheMarketIcon />, bgColor: '#FB913A14' },
  [Categories.WITHDRAWN]: { icon: <BackOnTheMarketIcon />, bgColor: '#FB913A14' },
  [Categories.UNDER_CONTRACT]: { icon: <BackOnTheMarketIcon />, bgColor: '#FB913A14' },
  [Categories.EXPIRED]: { icon: <BackOnTheMarketIcon />, bgColor: '#FB913A14' },
  [Categories.SOLD]: { icon: <SoldIcon />, bgColor: '#928CDA14' },
  [Categories.AGENT_UPDATES]: { icon: <AgentUpdatesIcon />, bgColor: '#4673D114' },
};
