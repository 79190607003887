import moment from 'moment-timezone';
import { get, flow, map, filter, uniqWith } from 'lodash-es';

import {
  setTransactionPreFormQuestionsAction,
  createTransactionAction,
  createTransactionAction_,
  resetTransactionCreateAction,
  createTransactionContactAction,
  resetTransactionCreateTemplateAction,
} from 'store/actions/transactions';
import Api from 'store/effects/core/api';
import { getState } from 'store';
import { transactionRoles } from 'settings/constants/roles';
import { getParticipantsData } from 'utils/workshopHelper';
import {
  createTransaction,
  createTransaction_,
  createProject_,
  createTransactionContact,
} from 'api/transactions';

export const setTransactionPreFormQuestionsEffect = (cfg) => (dispatch) => {
  dispatch(setTransactionPreFormQuestionsAction(cfg));
};

export const convertMilestones = (transactionData) =>
  (transactionData?.timeline || [])
    .filter((timeline) => timeline.Title && timeline.DueDate && !timeline.isDateControlMilestone)
    .map((milestone) => ({
      Title: milestone?.Title || milestone?.name,
      DueDate: milestone?.DueDate || milestone?.deadline,
      IsPrivate: !!milestone?.IsPrivate,
      milestoneTemplateId: milestone?.milestoneTemplateId,
      PublicId: milestone?.PublicId,
      ControlOperatorOffset: milestone?.ControlOperatorOffset,
    }));

export const convertDocuments = (transactionData) =>
  (transactionData?.files || []).map((document) => {
    const participants = getParticipantsData(document);
    return {
      Title: document?.documentName,
      Category: document?.customTag || document?.category?.name || document?.category,
      File: {
        ContentType: document?.contentType,
        Filename: document?.filename,
        Size: document?.size,
        Data: btoa(document?.data),
      },
      UsersWithAccess: participants.UsersWithAccess,
      UsersEmailWithAccess: participants.UsersEmailWithAccess,
      AccessToEveryone: participants.AccessToEveryone,
    };
  });

export const convertPurchaseDocuments = (transactionData) =>
  (transactionData?.purchaseAgreement || []).map((document) => {
    const participants = getParticipantsData(document);
    return {
      Title: document?.documentName,
      Category: document?.customTag || document?.category?.name || document?.category,
      File: {
        ContentType: document?.contentType,
        Filename: document?.filename,
        Size: document?.size,
        Data: btoa(document?.data),
      },
      UsersWithAccess: participants.UsersWithAccess,
      UsersEmailWithAccess: participants.UsersEmailWithAccess,
      AccessToEveryone: participants.AccessToEveryone,
      IsPurchaseAgreement: true,
    };
  });

export const convertTransactionEarnestMoney = (transactionData) => {
  const filteredData = (transactionData?.transactionEarnestMoney || []).filter((form) => {
    return form.FieldAnswer !== '' && form.DueDate !== '';
  });

  return filteredData.map((form) => ({
    ...form,
    FieldAnswer: Number(form.FieldAnswer),
    DueDate: moment(form.DueDate),
  }));
};

export const getAdditionalRolesForTasks = (transactionData = {}) => {
  const { assignParticipantsToRoles } = transactionData;

  const additionalRolesForTasks = assignParticipantsToRoles.map((item) => ({
    ParticipantEmail: (item?.customEmail || item?.email)?.toLowerCase(),
    Role: item?.tagRole || undefined,
    TransactionAccess: item?.transactionAccess,
  }));

  return additionalRolesForTasks;
};

export const filterAssignToRolesParticipants = (transactionData = {}, allParticipants) => {
  // **Note**: Removing duplicates that already exists in
  // the participants array so API doesn't throw error.

  let filterAssignToRolesArr = [];
  const { assignParticipantsToRoles } = transactionData;
  const participantsIds = allParticipants.map((item) => item?.id);

  const uniqueEmails = new Set();
  const assignParticipantsToRolesWithUniqueEmails = assignParticipantsToRoles.filter((obj) => {
    if (!uniqueEmails.has(obj.customEmail)) {
      uniqueEmails.add(obj.customEmail);
      return true;
    }
    return false;
  });

  if (assignParticipantsToRolesWithUniqueEmails?.length) {
    assignParticipantsToRolesWithUniqueEmails.forEach((item) => {
      if (item?.id === '' || !participantsIds.includes(item?.id)) filterAssignToRolesArr.push(item);
    });
  }

  return filterAssignToRolesArr;
};

const getAgentsCoordinators = (creatorRole, transactionData) => {
  if (['BuyerAgent', 'ListingAgent'].includes(creatorRole)) {
    return transactionData?.coordinators || [];
  } else if (
    ['AdministrativeAssistant', 'Brokerage', 'Finance', 'Operations'].includes(creatorRole)
  ) {
    return [...(transactionData?.agents || []), ...(transactionData?.coordinators || [])];
  }

  return transactionData?.agents || [];
};

const getAllParticipants = (transactionData) => {
  const { clients, coordinators, agents, participants } = transactionData ?? {};
  return [...(clients || []), ...(agents || []), ...(coordinators || []), ...(participants || [])];
};

const convertParticipants = (transactionData) => {
  const combinedParticipants = [
    ...(transactionData?.clients || []),
    ...getAgentsCoordinators(transactionData?.creatorRole?.value, transactionData),
    ...(filterAssignToRolesParticipants(transactionData, getAllParticipants(transactionData)) ||
      []),
    ...(transactionData?.participants || []),
  ];
  const participants = combinedParticipants
    .filter((participant) => participant.name || participant.firstName)
    .map((participant) => ({
      Phones: participant?.Phones,
      Email: (participant?.customEmail || participant?.email || undefined)?.toLowerCase(),
      Role: participant?.tagRole || undefined,
      RoleId: participant?.roleId,
      FirstName: participant?.firstName,
      LastName: participant?.lastName,
      Name: participant?.name,
      Invite: participant?.invite,
      TransactionAccess: participant?.transactionAccess,
      ...(participant?.UserId && { UserId: participant.UserId }),
      ...(participant?.ContactId && { ContactId: participant.ContactId }),
    }));
  return participants;
};

const formatDateControls = (dateControls, isProject) => {
  if (isProject) {
    const transformedData = {
      ...dateControls,
      KO: dateControls.CD,
      DL: dateControls.CL,
    };

    delete transformedData.CD;
    delete transformedData.CL;

    return transformedData;
  } else return dateControls;
};

export const createTransactionEffect_ = (cfg, options, cb) => {
  const state = getState();
  const transactionData = get(state, 'transactionCreate.data', {});

  const sendRequest = Api.execBase({
    action: createTransactionAction_,
    method: transactionData?.projectName ? createProject_ : createTransaction_,
  });

  const config = {
    Price: transactionData?.purchasePrice || transactionData?.listingPrice,
    RepresentingRoles: Object.entries(transactionData?.whoAreYouRepresenting || {})
      .filter(([, value]) => !!value)
      .map(([role]) => role),
    IsPropertyUnderContract:
      !transactionData?.whoAreYouRepresenting?.[transactionRoles.BUYER] &&
      !!transactionData?.whoAreYouRepresenting?.[transactionRoles.SELLER] &&
      !!transactionData?.isPropertyUnderContract,

    ...(transactionData?.effectiveDate && {
      EffectiveDate: moment(transactionData?.effectiveDate).format('YYYY-MM-DD'),
    }),
    ...(transactionData?.listingExpireDate && {
      ListingExpireDate: moment(transactionData?.listingExpireDate).format('YYYY-MM-DD'),
    }),

    ...(transactionData?.closingDate && {
      ClosingDate: moment(transactionData?.closingDate).format('YYYY-MM-DD'),
    }),
    Participants: convertParticipants(transactionData),
    ...(transactionData?.assignParticipantsToRoles
      ? { AdditionalRolesForTasks: getAdditionalRolesForTasks(transactionData) }
      : {}),
    Milestones: convertMilestones(transactionData),
    Documents: convertDocuments(transactionData),
    DateControls: formatDateControls(
      transactionData?.dateControls,
      Boolean(transactionData?.projectName),
    ),
    Property: {
      Address: transactionData?.address,
    },
    PropertyType: transactionData?.propertyType,
    TargetLiveDate: transactionData?.liveDate
      ? moment(transactionData?.liveDate).format('YYYY-MM-DD')
      : undefined,
    templateId: transactionData?.templateId,
    YearBuilt:
      transactionData?.yearBuilt && !Number.isNaN(Number(transactionData?.yearBuilt))
        ? Number(transactionData?.yearBuilt)
        : undefined,
    FinancingType: transactionData?.financingType,
    timeZone: moment.tz.guess(),
    Status: transactionData?.listedStatus,
    CreatorRole: transactionData?.creatorRole?.value,
    Name: transactionData?.projectName,
    IsProject: Boolean(transactionData?.projectName),
    TransactionCategoryId: transactionData?.projectCategory,
    TransactionEarnestMoney: convertTransactionEarnestMoney(transactionData),
    ...(transactionData?.totalCommission?.Value > 0
      ? {
          TotalCommission: {
            IsFeeTypePercentage: transactionData?.totalCommission?.IsFeeTypePercentage,
            Value: transactionData?.totalCommission?.IsFeeTypePercentage
              ? parseFloat(transactionData?.totalCommission?.Percent)
              : parseFloat(transactionData?.totalCommission?.Value),
          },
        }
      : {}),
    LeadSource: transactionData?.leadSource,
    ...(transactionData?.referralFee ? { ReferralFee: transactionData?.referralFee } : {}),
    ...(transactionData?.otherFees ? { OtherFees: transactionData?.otherFees } : {}),
    ...(transactionData?.buyerCommission?.Value > 0
      ? {
          BuyerCommission: {
            IsFeeTypePercentage: transactionData?.buyerCommission?.IsFeeTypePercentage,
            Value: transactionData?.buyerCommission?.IsFeeTypePercentage
              ? parseFloat(transactionData?.buyerCommission?.Percent)
              : parseFloat(transactionData?.buyerCommission?.Value),
          },
        }
      : {}),
    ...(transactionData?.sellerCommission?.Value > 0
      ? {
          SellerCommission: {
            IsFeeTypePercentage: transactionData?.sellerCommission?.IsFeeTypePercentage,
            Value: transactionData?.sellerCommission?.IsFeeTypePercentage
              ? parseFloat(transactionData?.sellerCommission?.Percent)
              : parseFloat(transactionData?.sellerCommission?.Value),
          },
        }
      : {}),
    ...((transactionData?.buyerCommission?.Value > 0 ||
      transactionData?.totalCommission?.Value > 0) &&
    transactionData?.buyerCommissionContribution?.Value > 0
      ? {
          BuyerCommissionContribution: {
            IsFeeTypePercentage: transactionData?.buyerCommissionContribution?.IsFeeTypePercentage,
            Value: transactionData?.buyerCommissionContribution?.IsFeeTypePercentage
              ? parseFloat(transactionData?.buyerCommissionContribution?.Percent)
              : parseFloat(transactionData?.buyerCommissionContribution?.Value),
          },
        }
      : {}),
    ...((transactionData?.buyerCommission?.Value > 0 ||
      transactionData?.totalCommission?.Value > 0) &&
    transactionData?.sellerCommissionContribution?.Value > 0
      ? {
          SellerCommissionContribution: {
            IsFeeTypePercentage: transactionData?.sellerCommissionContribution?.IsFeeTypePercentage,
            Value: transactionData?.sellerCommissionContribution?.IsFeeTypePercentage
              ? parseFloat(transactionData?.sellerCommissionContribution?.Percent)
              : parseFloat(transactionData?.sellerCommissionContribution?.Value),
          },
        }
      : {}),
    ...(transactionData?.buyerCommission?.Value > 0 || transactionData?.totalCommission?.Value > 0
      ? {
          BuySideCommissionResponsibility:
            transactionData?.buySideCommissionResponsibility ?? 'Buyer',
        }
      : {}),
    DefaultRoleId: transactionData?.saveDefaultCreatorRole
      ? transactionData?.creatorRole?.roleId
      : null,
  };
  return sendRequest(config, options, cb);
};

export const createTransactionEffect = (cfg, options, cb) => {
  const state = getState();
  const sendRequest = Api.execBase({ action: createTransactionAction, method: createTransaction });

  const transactionData = get(state, 'transactionCreate.data', {});

  const config = {
    Price: transactionData?.purchasePrice || transactionData?.listingPrice,
    RepresentingRoles: Object.entries(transactionData?.whoAreYouRepresenting || {})
      .filter(([, value]) => !!value)
      .map(([role]) => role),
    IsPropertyUnderContract:
      !transactionData?.whoAreYouRepresenting?.[transactionRoles.BUYER] &&
      !!transactionData?.whoAreYouRepresenting?.[transactionRoles.SELLER] &&
      !!transactionData?.isPropertyUnderContract,

    ...(transactionData?.effectiveDate && {
      EffectiveDate: moment(transactionData?.effectiveDate).format('YYYY-MM-DD'),
    }),
    ...(transactionData?.listingExpireDate && {
      ListingExpireDate: moment(transactionData?.listingExpireDate).format('YYYY-MM-DD'),
    }),

    ...(transactionData?.closingDate && {
      ClosingDate: moment(transactionData?.closingDate).format('YYYY-MM-DD'),
    }),
    Participants: convertParticipants(transactionData),
    ...(transactionData?.assignParticipantsToRoles
      ? { AdditionalRolesForTasks: getAdditionalRolesForTasks(transactionData) }
      : {}),
    Milestones: convertMilestones(transactionData),
    Documents: convertDocuments(transactionData),
    DateControls: formatDateControls(
      transactionData?.dateControls,
      Boolean(transactionData?.projectName),
    ),
    Property: {
      Address: transactionData?.address,
    },
    PropertyType: transactionData?.propertyType,
    TargetLiveDate: transactionData?.liveDate
      ? moment(transactionData?.liveDate).format('YYYY-MM-DD')
      : undefined,
    templateId: transactionData?.templateId,
    YearBuilt:
      transactionData?.yearBuilt && !Number.isNaN(Number(transactionData?.yearBuilt))
        ? Number(transactionData?.yearBuilt)
        : undefined,
    FinancingType: transactionData?.financingType,
    timeZone: moment.tz.guess(),
    Status: transactionData?.listedStatus,
    CreatorRole: transactionData?.creatorRole?.value,
    Name: transactionData?.projectName,
    IsProject: Boolean(transactionData?.projectName),
    TransactionCategoryId: transactionData?.projectCategory,
    TransactionEarnestMoney: convertTransactionEarnestMoney(transactionData),
    ...(transactionData?.totalCommission?.Value > 0
      ? {
          TotalCommission: {
            IsFeeTypePercentage: transactionData?.totalCommission?.IsFeeTypePercentage,
            Value: transactionData?.totalCommission?.IsFeeTypePercentage
              ? parseFloat(transactionData?.totalCommission?.Percent)
              : parseFloat(transactionData?.totalCommission?.Value),
          },
        }
      : {}),
    LeadSource: transactionData?.leadSource,
    ...(transactionData?.referralFee ? { ReferralFee: transactionData?.referralFee } : {}),
    ...(transactionData?.otherFees ? { OtherFees: transactionData?.otherFees } : {}),
    ...(transactionData?.buyerCommission?.Value > 0
      ? {
          BuyerCommission: {
            IsFeeTypePercentage: transactionData?.buyerCommission?.IsFeeTypePercentage,
            Value: transactionData?.buyerCommission?.IsFeeTypePercentage
              ? parseFloat(transactionData?.buyerCommission?.Percent)
              : parseFloat(transactionData?.buyerCommission?.Value),
          },
        }
      : {}),
    ...(transactionData?.sellerCommission?.Value > 0
      ? {
          SellerCommission: {
            IsFeeTypePercentage: transactionData?.sellerCommission?.IsFeeTypePercentage,
            Value: transactionData?.sellerCommission?.IsFeeTypePercentage
              ? parseFloat(transactionData?.sellerCommission?.Percent)
              : parseFloat(transactionData?.sellerCommission?.Value),
          },
        }
      : {}),
    ...((transactionData?.buyerCommission?.Value > 0 ||
      transactionData?.totalCommission?.Value > 0) &&
    transactionData?.buyerCommissionContribution?.Value > 0
      ? { BuyerCommissionContribution: transactionData?.buyerCommissionContribution }
      : {}),
    ...((transactionData?.buyerCommission?.Value > 0 ||
      transactionData?.totalCommission?.Value > 0) &&
    transactionData?.sellerCommissionContribution?.Value > 0
      ? { SellerCommissionContribution: transactionData?.sellerCommissionContribution }
      : {}),
    ...(transactionData?.buyerCommission?.Value > 0 || transactionData?.totalCommission?.Value > 0
      ? {
          BuySideCommissionResponsibility:
            transactionData?.buySideCommissionResponsibility ?? 'Buyer',
        }
      : {}),

    DefaultRoleId: transactionData?.saveDefaultCreatorRole
      ? transactionData?.creatorRole?.roleId
      : null,
  };
  return sendRequest(config, options, cb);
};

export const resetTransactionCreateEffect = () => (dispatch) => {
  dispatch(resetTransactionCreateAction());
};

export const resetTransactionCreateTemplateEffect = () => (dispatch) => {
  dispatch(resetTransactionCreateTemplateAction());
};

export const createTransactionContactEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: createTransactionContactAction,
    method: createTransactionContact,
  });
  return sendRequest(cfg, options, cb);
};
