import classNames from 'classnames';
import styles from './styles.module.scss';
import { Transaction, ArrowRight2 } from 'components/Icons';
import { useHistory } from 'react-router-dom';

interface MyTransactionCardProps {
  Id: string;
  address1: string;
  address2?: string;
  close?: string;
  remaining?: string;
  route?: string;
  createdDate?: string;
  backgroundColor?: string;
  transactionIconColor?: string;
}

const bgColors = ['#EEF9FC', '#F4F3FB', '#FFF4EB'];
const transactionIconColors = ['#51BFE1', '#928CDA', '#FB913A'];

const MyTransactionCard = ({
  address1,
  address2,
  close,
  remaining,
  route = '',
  backgroundColor,
  transactionIconColor,
}: MyTransactionCardProps) => {
  const history = useHistory();

  const onClick = () => {
    if (route) {
      history.push(route);
    }
  };
  return (
    <div
      className={classNames(styles.myTransactionCardWrapper)}
      style={{
        backgroundColor: backgroundColor,
      }}
    >
      <div className={classNames(styles.detailsWrapper)}>
        <div className={classNames(styles.details)}>
          <div>
            <Transaction color={transactionIconColor} />
          </div>
          <div className={classNames(styles.detailsInner)}>
            <div className={classNames(styles.main)} onClick={onClick}>
              {address1}
            </div>
            <div className={classNames(styles.address)} onClick={onClick}>
              {address2}
            </div>
            <div className={classNames(styles.closeWrapper)}>
              <div className={classNames(styles.close)}>Closing {close}</div>
              <div className={classNames(styles.close, styles.daysBadge)}>{remaining}</div>
            </div>
          </div>
        </div>
      </div>
      <div onClick={onClick}>
        <ArrowRight2 className={classNames(styles.rightIcon)} />
      </div>
    </div>
  );
};

export const MyTransactions = ({
  title = 'My Transactions',
  transactions = [],
}: {
  title?: string;
  transactions: MyTransactionCardProps[];
}) => {
  return (
    <div className={classNames(styles.mainWrapper)}>
      <div className={classNames(styles.list)}>
        {/*  */}
        {transactions.map((transaction, index) => (
          <MyTransactionCard
            {...transaction}
            key={transaction.Id}
            backgroundColor={bgColors[index % 3]}
            transactionIconColor={transactionIconColors[index % 3]}
          />
        ))}
        {/*  */}
      </div>
    </div>
  );
};

export default MyTransactions;
