import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { transactionPreFormQuestionsIds } from 'settings/constants/transaction';
import { setTransactionPreFormQuestionsEffect } from 'store/effects/transactions';

import { Input } from 'components';
import { Continue } from '../../Continue';
import { AnswersContainer } from '../../AnswersContainer';
import { ButtonsContainer } from '../../ButtonsContainer';
import { calculateGCI, calculateRate } from '../FinancingDetails/validationHelpers';
import { Question } from 'pages/RequestQuote/components';
import { getTransactionSelector } from 'store/selectors/transaction';
import { getTransactionPreFormSelector } from 'store/selectors/transactions';

import styles from './styles.module.scss';

const PurchasePrice = (props) => {
  const { className, onNext, currentQuestionId, setStage } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { preForm } = useSelector(getTransactionPreFormSelector);
  const { transaction } = useSelector(getTransactionSelector);

  const isTransactionUnderContractRoute = useMemo(
    () => history.location?.pathname.includes('undercontract'),
    [],
  );

  useEffect(() => {
    dispatch(
      setTransactionPreFormQuestionsEffect({
        [transactionPreFormQuestionsIds.purchasePrice]: transaction.Price
          ? transaction.Price
          : undefined,
      }),
    );
  }, [transaction]);

  const onAnswerHandler = (answer) => {
    dispatch(
      setTransactionPreFormQuestionsEffect({
        [transactionPreFormQuestionsIds.purchasePrice]: answer ? Number(answer) : undefined,
        [transactionPreFormQuestionsIds.listingPrice]: undefined,
      }),
    );

    // Modify commissions accordingly for Financing step.
    if (isTransactionUnderContractRoute) {
      const { buyerCommission, sellerCommission } = preForm || {};

      if (buyerCommission) {
        dispatch(
          setTransactionPreFormQuestionsEffect({
            [transactionPreFormQuestionsIds.buyerCommission]: {
              ...buyerCommission,
              ...(buyerCommission?.IsFeeTypePercentage
                ? { Value: calculateGCI(buyerCommission?.Percent, Number(answer)) }
                : {
                    Percent: calculateRate(buyerCommission?.Value, Number(answer)),
                  }),
            },
          }),
        );
      }

      if (sellerCommission) {
        dispatch(
          setTransactionPreFormQuestionsEffect({
            [transactionPreFormQuestionsIds.sellerCommission]: {
              ...sellerCommission,
              ...(sellerCommission?.IsFeeTypePercentage
                ? { Value: calculateGCI(sellerCommission?.Percent, Number(answer)) }
                : {
                    Percent: calculateRate(sellerCommission?.Value, Number(answer)),
                  }),
            },
          }),
        );
      }
    }
  };

  const onNextHandler = (event) => {
    if (setStage) {
      setStage(transactionPreFormQuestionsIds.effectiveDate);
    }
    if (currentQuestionId === transactionPreFormQuestionsIds.purchasePrice) {
      onNext(event);
    }
  };

  return (
    <div
      testid="what_purchase_prise"
      className={classNames(styles.presenting, styles.container, className)}
    >
      <Question>What is the purchase price?</Question>
      <AnswersContainer>
        <Input
          isNumberFormat
          variant={Input.LIGHT_ROUNDED}
          placeholder="$ Price"
          onChange={(e, val) => onAnswerHandler(val)}
          value={preForm[transactionPreFormQuestionsIds.purchasePrice]}
          prefix="$"
          testid="price_input"
          maxNumber={1000000000}
        />
      </AnswersContainer>
      <ButtonsContainer>
        <Continue
          onClick={onNextHandler}
          disabled={!preForm?.[transactionPreFormQuestionsIds.purchasePrice]}
          className={styles.continue}
        />
      </ButtonsContainer>
    </div>
  );
};

PurchasePrice.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  setStage: PropTypes.func,
  currentQuestionId: PropTypes.string,
};

PurchasePrice.defaultProps = {
  className: '',
  onNext: () => {},
  currentQuestionId: null,
};

export default PurchasePrice;
