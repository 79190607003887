export enum ClausesTabsPanes {
  MyClauses = 'My Clauses',
  TeamClauses = 'Team Clauses',
}

export const getDefaultTab = (clauses, previousClauseTab = '') => {
  const myClauses = getFilteredClauses(clauses);
  const teamClauses = getFilteredClauses(clauses, true);

  if (!previousClauseTab) {
    return myClauses.length ? ClausesTabsPanes.MyClauses : ClausesTabsPanes.TeamClauses;
  } else {
    const isMyTab = previousClauseTab === ClausesTabsPanes.MyClauses;
    const isTeamTab = previousClauseTab === ClausesTabsPanes.TeamClauses;

    if (myClauses.length || teamClauses.length) {
      if (isMyTab && !myClauses.length) return ClausesTabsPanes.TeamClauses;
      if (isTeamTab && !teamClauses.length) return ClausesTabsPanes.MyClauses;

      return previousClauseTab;
    }

    return '';
  }
};

export const getFilteredClauses = (clauses, teamClauses = false) => {
  return clauses?.filter(({ PublishToTeam }) => PublishToTeam === teamClauses);
};
