import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { TEAM_OWNER } from 'settings/constants/roles';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { isEmailVerificationPopupOpen } from 'store/selectors/app';

const EmailVerificationCodeModalLazy = React.lazy(() =>
  import('../EmailVerificationCodeModal').then((module) => ({
    default: module.EmailVerificationCodeModal,
  })),
);

const EmailVerificationWallModalLazy = React.lazy(() =>
  import('../EmailVerificationWallModal/EmailVerificationWallModal').then((module) => ({
    default: module.EmailVerificationWallModal,
  })),
);

export const EmailVerificationModal: React.FC = () => {
  //if user is team_agent or owner, show email code verification modal
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const isModalOpen = useSelector(isEmailVerificationPopupOpen);

  if (!isModalOpen) return null;
  if (agentRole === TEAM_OWNER) {
    return (
      <>
        {isModalOpen && (
          <Suspense fallback={<div>Loading...</div>}>
            <EmailVerificationCodeModalLazy />
          </Suspense>
        )}
      </>
    );
  }

  return (
    <>
      {isModalOpen && (
        <Suspense fallback={<div>Loading...</div>}>
          <EmailVerificationWallModalLazy />
        </Suspense>
      )}
    </>
  );
};
