import { useEffect, useMemo, useState } from 'react';
import { TabPane, Tabs } from 'components-antd';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';
import { SplitTabTypes } from '../../TransactionFinancials';
import { SplitCard } from './components';
import { getSplitNames } from 'api/financials';
import { generateTransactionSplitsData, getLedgerId } from '../../helpers';
import { LedgerTransactionSideId } from 'settings/constants/ledger';

import { getRelatedTransactionContactsFormattedList } from 'store/selectors/relatedContacts';

export const Splits = (props) => {
  const [tabKey, setTabKey] = useState(props?.splitTabType === SplitTabTypes?.LISTING ? '1' : '2');
  const [buySideLedgerId, setBuySideLedgerId] = useState(null);
  const [listSideLedgerId, setListSideLedgerId] = useState(null);
  const [autocompleteSplitNames, setAutocompleteSplitNames] = useState<any>(null);
  const transactionContacts = useSelector(getRelatedTransactionContactsFormattedList);

  const formattedAgentCoordinatorsMembers = useMemo(
    () =>
      transactionContacts?.map((item) => ({
        ...item,
        Id: item?.UserId ? `${item?.UserId}-user` : `${item?.ContactId}-contact`,
      })),
    [transactionContacts],
  );

  const fetchAutocompleteSplitNames = async () => {
    let autocompleteSplitNames = await getSplitNames();
    setAutocompleteSplitNames(autocompleteSplitNames?.SplitTitles);
  };

  useEffect(() => {
    fetchAutocompleteSplitNames();
  }, []);

  useEffect(() => {
    if (props?.ledgers) {
      const _listSideledgerId = getLedgerId(props?.ledgers, LedgerTransactionSideId.Listing);
      const _buySideledgerId = getLedgerId(props?.ledgers, LedgerTransactionSideId.Purchase);
      setListSideLedgerId(_listSideledgerId);
      setBuySideLedgerId(_buySideledgerId);
    }
  }, [props?.ledgers]);

  const transactionSplitsData: any[] = useMemo(
    () => [
      ...(generateTransactionSplitsData(
        props?.ledgers,
        props?.ledgerLineItems,
        LedgerTransactionSideId.Listing,
        props?.isTransactionAdminOrOwner,
      ) || []),
      ...(generateTransactionSplitsData(
        props?.ledgers,
        props?.ledgerLineItems,
        LedgerTransactionSideId.Purchase,
        props?.isTransactionAdminOrOwner,
      ) || []),
    ],
    [props?.ledgers, props?.ledgerLineItems],
  );

  let listingData =
    transactionSplitsData &&
    transactionSplitsData.filter((data) => {
      return data.SplitSide === 'Listing';
    });

  let buyerData =
    transactionSplitsData &&
    transactionSplitsData.filter((data) => {
      return data.SplitSide === 'Purchase';
    });

  const isNotDualTransaction = useMemo(() => {
    // Checking if the transaction is a buyer or seller and *NOT* dual.
    return props?.representingRoles?.length < 2;
  }, [props?.representingRoles]);

  const TABS_ITEMS = [
    {
      key: '1',
      label: 'List-Side',
      children: (
        <SplitCard
          ledgerId={listSideLedgerId}
          data={listingData}
          fetchFinancials={props?.fetchFinancials}
          getSplitList={props?.getSplitList}
          comparePrice={props?.getComparePrice(
            props?._transactionCommission?.SellerCommissionFees,
            listSideLedgerId,
          )}
          isTransactionAdminOrOwner={props?.isTransactionAdminOrOwner}
          splitType={SplitTabTypes.LISTING}
          autocompleteSplitNames={autocompleteSplitNames}
          ledgers={props?.ledgers}
          memberContacts={formattedAgentCoordinatorsMembers}
        />
      ),
    },
    {
      key: '2',
      label: 'Buy-Side',
      children: (
        <SplitCard
          ledgerId={buySideLedgerId}
          data={buyerData}
          fetchFinancials={props?.fetchFinancials}
          getSplitList={props?.getSplitList}
          comparePrice={props?.getComparePrice(
            props?._transactionCommission?.BuyerCommissionFees,
            buySideLedgerId,
          )}
          isTransactionAdminOrOwner={props?.isTransactionAdminOrOwner}
          splitType={SplitTabTypes.PURCHASE}
          autocompleteSplitNames={autocompleteSplitNames}
          ledgers={props?.ledgers}
          memberContacts={formattedAgentCoordinatorsMembers}
        />
      ),
    },
  ];

  const handleTabChange = (key) => {
    setTabKey(key);
    if (key === '1') {
      props?.getSplitList(listingData);
      props?.setSplitTabType(SplitTabTypes.LISTING);
    } else {
      props?.getSplitList(buyerData);
      props?.setSplitTabType(SplitTabTypes.PURCHASE);
    }
  };

  return (
    <>
      {isNotDualTransaction ? (
        <>
          <p className={styles.notDualTransTitle}>
            {props?.isBuyerTransaction ? 'Buy' : 'List'}-Side
          </p>
          <SplitCard
            ledgerId={props?.isBuyerTransaction ? buySideLedgerId : listSideLedgerId}
            data={props?.isBuyerTransaction ? buyerData : listingData}
            fetchFinancials={props?.fetchFinancials}
            getSplitList={props?.getSplitList}
            comparePrice={props?.getComparePrice(
              props?.isBuyerTransaction
                ? props?._transactionCommission?.BuyerCommissionFees
                : props?._transactionCommission?.SellerCommissionFees,
              props?.isBuyerTransaction ? buySideLedgerId : listSideLedgerId,
            )}
            isTransactionAdminOrOwner={props?.isTransactionAdminOrOwner}
            splitType={props?.isBuyerTransaction ? SplitTabTypes.PURCHASE : SplitTabTypes.LISTING}
            autocompleteSplitNames={autocompleteSplitNames}
            ledgers={props?.ledgers}
            memberContacts={formattedAgentCoordinatorsMembers}
          />
        </>
      ) : (
        <Tabs activeKey={tabKey} onChange={handleTabChange} defaultActiveKey={'1'}>
          {TABS_ITEMS.map(({ key, children, label }) => (
            <TabPane
              tab={
                <div className={styles.tabRow}>
                  <span>{label}</span>
                </div>
              }
              key={key}
            >
              {children}
            </TabPane>
          ))}
        </Tabs>
      )}
    </>
  );
};
