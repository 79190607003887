import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { Badge, Button, Dropdown, Modal, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getComparesSelector } from 'store/selectors/feed';
import styles from './styles.module.scss';
import { HomeOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import { map } from 'lodash-es';
import { ArrowDown, Close } from 'components/Icons';
import { routes } from 'settings/navigation/routes';
import { openNewTab } from 'services/newTab';
import {
  resetFullDataComparePropertiesEffect,
  openShareDrawerEffect,
  resetPropertiesWholeTabsEffect,
  setComparesEffect,
  setMultipleModeEffect,
  setSharePropertyEffect,
} from 'store/effects';
import Icons from '../components/PropertyCart/icons';
import { CommentsDrawer, Button as CustomButton } from 'components';
import { openCommentsDrawerEffect } from 'store/effects/drawers/comments';
import { LocationService } from 'services/location';
import { useHistory, useLocation } from 'react-router-dom';
import { getFirstImage } from 'helpers';
import {
  requestScheduleTourEffect,
  toggleFavoriteListingDetailEffect,
} from 'store/effects/listingDetail';
import { showErrorMessage, showSuccessMessage } from 'helpers';
import { getCurrentContextSelector } from 'store/selectors/context';
import { getSearchResultsV2Effect } from 'store/effects/searchResults/searchV2';
import { useNoMlsAccessHandler } from '../Feed/Properties/hooks/useNoMlsAccessHandler';
import classNames from 'classnames';
import { getUserRolesMapSelector } from 'store/selectors/user';

const locationService = new LocationService();

interface IWithSelectedPropertiesProps extends PropsWithChildren<{}> {}

const WithSelectedProperties: React.FC<IWithSelectedPropertiesProps> = ({ children }) => {
  const compares = useSelector(getComparesSelector);
  const { isAgent } = useSelector(getUserRolesMapSelector);
  const dispatch = useDispatch();
  const [isSelectedListModalOpen, setIsSelectedListModalOpen] = useState<boolean>(false);
  const [hoveredId, setHoveredId] = useState(null);
  const query = locationService.setLocation(location).getQuery();
  const history = useHistory();
  const context = useSelector(getCurrentContextSelector);
  const { pathname } = useLocation();
  const isFeed = pathname.includes('/properties/feed');
  const isSearch = pathname.includes('/properties/search/results');
  const { wrappedEffect: getSearchResultsEffectWrapped } =
    useNoMlsAccessHandler(getSearchResultsV2Effect);
  const [isLoading, setIsLoading] = useState(false);

  const participantObject = useMemo(() => {
    if (context) {
      return {
        value: context.Agents[0].Id,
        name: `${context.Agents[0].FirstName} ${context.Agents[0].LastName}`,
        firstName: context?.Agents[0].FirstName,
        lastName: context?.Agents[0].LastName,
        role: context?.Agents[0].Roles[0],
        avatarUrl: context.Agents[0].AvatarUrl,
        id: context.Agents[0].Id,
      };
    }
    return null;
  }, [context]);

  const handleOpenModal = useCallback(() => setIsSelectedListModalOpen(true), []);
  const handleCloseModal = useCallback(() => setIsSelectedListModalOpen(false), []);

  const handleRemove = (id) => {
    const newCompares = compares.data.filter((property) => property.Id !== id);
    if (newCompares?.length < 1) {
      dispatch(setMultipleModeEffect(false));
    }
    dispatch(setComparesEffect(newCompares));
    if (!newCompares.length) {
      handleCloseModal();
    }
  };

  const getQueryParameter = () => {
    if (compares.data.length > 1) {
      let str = '';
      for (let i = 0; i < compares.data.length; i++) {
        str += compares.data[i].Id;
        if (i != compares.data.length - 1) {
          str += ',';
        }
      }
      if (query?.activeTab) {
        str += '&activeTab=' + query?.activeTab;
      }
      return str;
    } else {
      return '';
    }
  };

  const onScheduleTourSubmit = () => {
    let agentId = context.Agents.map((agent) => agent.Id);
    if (!isLoading) {
      setIsLoading(true);
      dispatch(
        requestScheduleTourEffect(
          { propertyInfo: compares?.data, agentId },
          null,
          (error, response) => {
            if (response?.status === 200) {
              showSuccessMessage('Tour Request Sent');
              dispatch(setMultipleModeEffect(false));
              dispatch(resetFullDataComparePropertiesEffect());
            } else showErrorMessage('Tour not scheduled successfully');
            setIsLoading(false);
          },
        ),
      );
    }
  };

  const onFavorite = () => {
    dispatch(
      toggleFavoriteListingDetailEffect(
        {
          id: compares?.data?.map(({ Id }) => Id),
          isFavorite: true,
          contextKey: context.ContextKey,
        },
        { IsFavorite: true },
        (err) => {
          if (!err) {
            dispatch(setMultipleModeEffect(false));
            dispatch(resetFullDataComparePropertiesEffect());
          }
        },
      ),
    );
  };

  const handleRemoveAll = () => {
    dispatch(setMultipleModeEffect(false));
    dispatch(setComparesEffect([]));
  };

  const onCompare = (e) => {
    let path = routes.listings + '?ids=' + getQueryParameter();
    dispatch(setMultipleModeEffect(false));
    dispatch(resetFullDataComparePropertiesEffect());
    e.preventDefault();
    if (e.ctrlKey || e.metaKey) {
      openNewTab(path);
    } else {
      history.push(path);
    }
  };

  const handleMouseEnter = (id) => {
    setHoveredId(id);
  };

  const handleMouseLeave = () => {
    setHoveredId(null);
  };

  const onComment = () => {
    dispatch(setSharePropertyEffect(compares?.data));
    dispatch(
      openCommentsDrawerEffect({
        open: true,
      }),
    );
    dispatch(setMultipleModeEffect(false));
    dispatch(resetFullDataComparePropertiesEffect());
  };

  const onShare = () => {
    dispatch(setSharePropertyEffect(compares?.data));
    dispatch(openShareDrawerEffect({ open: true }));
    dispatch(setMultipleModeEffect(false));
    dispatch(resetFullDataComparePropertiesEffect());
  };

  const agentActionList = [
    {
      label: 'Compare',
      icon: Icons.COMPARE,
      onClick: onCompare,
      disabled: compares?.data?.length < 2,
    },
    // { label: 'Add To Watchlist', icon: Icons.WATCHLIST },
    { label: 'Highlight for Client(s)', icon: Icons.STAR, onClick: onShare },
    { label: 'Leave Comment(s)', icon: Icons.COMMENT, onClick: onComment },
    { label: 'Clear All', icon: Icons.CLEAR, onClick: handleRemoveAll },
  ];

  const clientActionList = [
    {
      label: 'Compare',
      icon: Icons.COMPARE,
      onClick: onCompare,
      disabled: compares.data.length < 2,
    },
    { label: 'Schedule Tour(s)', icon: Icons.SCHEDULE, onClick: onScheduleTourSubmit },
    { label: 'Leave Comment(s)', icon: Icons.COMMENT, onClick: onComment },
    { label: 'Add to Favorites', icon: Icons.HEART, onClick: onFavorite },
    { label: 'Clear All', icon: Icons.CLEAR, onClick: handleRemoveAll },
  ];

  const PropertyActions = (
    <div className={styles.actionsContainer}>
      {map(
        isAgent ? agentActionList : clientActionList,
        ({ label, icon, onClick, disabled }: any, idx) => (
          <div
            key={idx}
            className={classNames(styles.list, { [styles.disabled]: disabled })}
            onClick={(e) => !disabled && handleItemClick(e, onClick)}
          >
            <Icons variant={icon} />
            <p>{label}</p>
          </div>
        ),
      )}
    </div>
  );

  const handleItemClick = (e, onClick) => {
    handleCloseModal();
    onClick(e);
  };

  const PropertyList = (
    <div className={styles.container}>
      <div className={styles.header}>
        <p className={styles.title}>Selected Properties</p>
        <div className={styles.closeWrapper}>
          <Close onClick={handleCloseModal} className={styles.close} size={32} />
        </div>
      </div>
      <div className={styles.itemsWrapper}>
        {map(compares?.data, ({ PhotoUrls, Media, Address, Id }) => {
          const firstImage = getFirstImage(Media);
          const ImageToSend = firstImage
            ? firstImage
            : PhotoUrls && PhotoUrls?.length
            ? PhotoUrls?.[0]
            : '';
          const { Line1, City, State, Zip } = Address;
          const isHovered = hoveredId === Id;
          return (
            <div className={styles.item}>
              <div className={styles.row}>
                <img src={ImageToSend} />
                <div className={styles.infoContainer}>
                  <p className={styles.line1}>{Line1 ?? ''}</p>
                  <p className={styles.line2}>{`${City ?? ''}, ${State ?? ''} ${Zip ?? ''}`}</p>
                </div>
              </div>
              <CustomButton
                icon={<Icons variant={Icons.DELETE} color={isHovered ? 'red' : '#747475'} />}
                onClick={() => handleRemove(Id)}
                className={styles.deleteIcon}
                onMouseEnter={() => handleMouseEnter(Id)}
                onMouseLeave={handleMouseLeave}
              />
            </div>
          );
        })}
      </div>
      <div>
        <Dropdown
          overlay={PropertyActions}
          placement="bottomLeft"
          getPopupContainer={(triggerNode) => triggerNode}
          trigger={['click']}
          overlayClassName={styles.propertiesActions}
        >
          <CustomButton
            title="Actions"
            icon={<ArrowDown color="#FFFFFF" size={14} className={styles.arrow} />}
            titleClassName={styles.button}
            className={styles.fullWidth}
          />
        </Dropdown>
      </div>
    </div>
  );

  return (
    <div className={styles.wrapper}>
      {children}
      {!!compares?.data.length && (
        <div className={styles.buttonContainer}>
          <Button
            className={classnames(styles.button, { [styles.hidden]: isSelectedListModalOpen })}
            size="large"
            onClick={handleOpenModal}
          >
            <Badge count={compares?.data?.length} className={styles.badge}>
              <HomeOutlined className={styles.homeIcon} />
            </Badge>
            <p> Selected Properties</p>
          </Button>
        </div>
      )}
      <div className={classnames(styles.modal, { [styles.visible]: isSelectedListModalOpen })}>
        {PropertyList}
      </div>
      <CommentsDrawer initialParticipants={participantObject && [participantObject]} />
    </div>
  );
};

export default WithSelectedProperties;
