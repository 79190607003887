import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Modal } from 'components-antd';
import { Avatar, Button, ConfirmationDialog, Select } from 'components';
import { convertNameToAvatarPlaceholder } from 'helpers';
import { getTeamListDataArray } from 'store/selectors/teamList';

import styles from './styles.module.scss';

export const ReassignShareModal = ({
  isOpen,
  modalType,
  onReassignShare,
  onClose,
  clients,
  loading,
}) => {
  const [assignedTo, setAssignedTo]: any = useState([]);
  const [reassignShareTo, setReassignShareTo]: any = useState([]);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const teamList = useSelector(getTeamListDataArray);

  useEffect(() => {
    // Preselect the options
    if (assignedTo) {
      setReassignShareTo(
        assignedTo?.map((item) => ({
          name: `${item?.FirstName} ${item?.LastName}`,
          value: item?.Id,
        })),
      );
    }
  }, [assignedTo]);

  const getAssignedToAgents = () => {
    const assigned = {};
    clients.map((c) => c?.AssignedAgentIds.forEach((agentId) => (assigned[agentId] = agentId)));

    setAssignedTo(
      Object.values(assigned).map((Id) => {
        const agent = teamList.find((t) => t.Id === Id);

        return {
          Id,
          FirstName: agent?.FirstName,
          LastName: agent?.LastName,
          AvatarUrl: agent?.AvatarUrl,
        };
      }),
    );
  };

  useEffect(() => {
    getAssignedToAgents();
  }, [clients]);

  const renderUser = (user) => {
    const name = `${user?.FirstName} ${user?.LastName}`;

    return (
      <div className={styles.user} key={user.Id}>
        <Avatar
          src={user?.AvatarUrl}
          placeholder={
            <div className={styles.avatarPlaceholder}>{convertNameToAvatarPlaceholder(name)}</div>
          }
          avatarClassName={styles.avatar}
        />
        <div className={styles.label}>{name}</div>
      </div>
    );
  };

  const sortByName = (a, b) => {
    const nameA = a.FirstName.toUpperCase();
    const nameB = b.FirstName.toUpperCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  };

  const getTeamsList = () => {
    const list: any = [];
    teamList?.sort(sortByName)?.forEach((member) => {
      list.push({
        name: member.FirstName + ' ' + member.LastName,
        value: member.Id,
      });
    });
    return list;
  };
  const teamsList = getTeamsList();

  const onSubmitConfirmModal = () => {
    onReassignShare(reassignShareTo.map(({ value }) => value));
    setOpenConfirmModal(false);
  };

  const joinAgentNames = (agents) => agents.map((a) => `${a.FirstName} ${a.LastName}`).join(', ');

  const getSelectedNames = (reassignShareTo) => {
    const agents = teamList.filter((a) => reassignShareTo.map((a) => a.value).includes(a.Id));
    return joinAgentNames(agents);
  };

  const reassignShareClientsContent = useMemo(
    () => (
      <div className={styles.reassignShareConfirmText}>
        <h4>Are you sure that you want to {modalType.toLowerCase()} the client(s)?</h4>
        <p>
          The client will be {modalType === 'Reassign' ? 'reassigned to' : 'shared with'}{' '}
          {getSelectedNames(reassignShareTo).trim()}.
        </p>
      </div>
    ),
    [modalType, assignedTo, reassignShareTo],
  );

  return (
    <Modal
      open={isOpen}
      width={675}
      footer={null}
      onCancel={() => {
        setReassignShareTo([]);
        onClose();
      }}
      maskClosable={false}
      destroyOnClose
      className={styles.reassignShareModal}
    >
      <h1 className={styles.heading}>{modalType}</h1>
      <div className={styles.content}>
        <p className={styles.clients}>
          Client: <span>{clients.map((c) => `${c?.FirstName} ${c?.LastName}`).join()}</span>
        </p>
        <div className={styles.card}>
          <p className={styles.heading}>Currently Assigned To:</p>
          {assignedTo.map((c) => renderUser(c))}
          {/* <h5 className={styles.subHeading}>
            {modalType} {modalType === 'Reassign' ? 'To' : 'With'}:
          </h5> */}
        </div>
        <Select
          value={reassignShareTo}
          onSelect={(_, values) => {
            setReassignShareTo(values);
          }}
          options={teamsList}
          multiple
          placeholder={'Select Agent(s)'}
          isArrowIcon={true}
          variant={Select.LIGHT_ROUND}
          label={modalType + (modalType === 'Reassign' ? ' To' : ' With') + ':'}
        />
        {/* <MultiSelect
          size="large"
          placeholderText="Select agent(s)"
          variant={'none'}
          onHandleChange={(values) => setReassignShareTo(values)}
          propClass={styles.multiSelect}
          optionFilterProp="children"
          wrapperClass={styles.multiSelectWrapper}
          values={reassignShareTo}
        >
          {renderTeamsList()}
        </MultiSelect> */}
        <Button
          title={modalType}
          className={styles.submitBtn}
          disabled={loading || !reassignShareTo.length}
          onClick={() => setOpenConfirmModal(true)}
        />
        <ConfirmationDialog
          onReject={() => setOpenConfirmModal(false)}
          onConfirm={onSubmitConfirmModal}
          isOpen={openConfirmModal}
          confirmText={modalType}
        >
          {reassignShareClientsContent}
        </ConfirmationDialog>
      </div>
    </Modal>
  );
};

ReassignShareModal.propTypes = {
  isOpen: PropTypes.bool,
  modalType: PropTypes.string,
  onReassignShare: PropTypes.func,
  onClose: PropTypes.func,
  clients: PropTypes.array,
  loading: PropTypes.bool,
};

ReassignShareModal.defaultProps = {
  isOpen: false,
  modalType: 'Reassign',
  onReassignShare: () => {},
  onClose: () => {},
  clients: [],
  loading: false,
};
