import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Locations } from 'components';
import { Checkbox, Col, Row } from 'components-antd';
import { AddFile, RemoveFile } from 'components/Icons';
import { ClientCASSAddress } from 'app-constants';
import styles from './styles.module.scss';
import containerStyles from '../styles.module.scss';

interface EditClientAddressesProps {
  clientAddresses: ClientCASSAddress[];
  error: string;
  disabled: boolean;
  onChangeAddress: (address: ClientCASSAddress[]) => void;
}

export type LocationResultType = {
  result: { place_id: string }[];
  placeName: string;
};

export const EditClientAddresses = ({
  clientAddresses,
  error,
  disabled,
  onChangeAddress,
}: EditClientAddressesProps) => {
  const emptyLocation = {
    address: null,
    isPrimary: false,
    neighbourhoodRadius: 2,
  };

  const [locations, setLocations] = useState<ClientCASSAddress[]>(
    clientAddresses.length ? clientAddresses : [emptyLocation],
  );
  const [addAddressDisabled, setAddAddressDisabled] = useState(false);

  useEffect(() => {
    setAddAddressDisabled(!!locations.find((location) => !location.address?.delivery_line_1));
    const updatedAddresses = locations.filter((location) => location.address?.delivery_line_1);

    if (updatedAddresses.length && !locations.filter((location) => location.isPrimary).length) {
      updatedAddresses[0].isPrimary = true;
    }

    onChangeAddress(updatedAddresses);
  }, [locations]);

  const addLocation = () => {
    setLocations((prevLocations) => [...prevLocations, emptyLocation]);
  };

  const updatePrimaryStatus = (index: number) => {
    const updatedLocations = locations.map((location, i) => ({
      ...location,
      isPrimary: i === index,
    }));
    setLocations(updatedLocations);
  };

  const removeLocation = (index: number) => {
    setLocations((prevLocations) => [...prevLocations.filter((el, i) => i !== index)]);
  };

  const addressRow = ({ address, isPrimary }, index) => {
    const addressDisabled = disabled;
    return (
      <Col>
        <Row
          className={classNames(styles.locationAndRemoveIcon, {
            [styles.addressDisabled]: addressDisabled,
          })}
        >
          <Col className={styles.locationCol}>
            <Locations
              multiple={false}
              types={['address']}
              variant="full"
              placeholder="Enter address"
              onResult={(result, preparedData) =>
                setLocations((prevLocations) =>
                  prevLocations.map((prevLocation, i) =>
                    index === i ? { ...prevLocation, address: preparedData[0] } : prevLocation,
                  ),
                )
              }
              value={address ? [address] : []}
              error={error}
              activeInputClassName={styles.inputFieldLocations}
              disabled={addressDisabled}
              testid="address"
              placeholderClassName={styles.locationPlaceholder}
              allowedSmartyAddress={true}
              showOnlyCassAddresses={true}
              setFullCassAddress={true}
            />
          </Col>
          {locations?.length > 1 && (
            <div className={styles.removeIconPosition}>
              <RemoveFile
                className={classNames(styles.deleteIcon, {
                  [styles.addressDisabled]: addressDisabled,
                })}
                onClick={() => !addressDisabled && removeLocation(index)}
              />
            </div>
          )}
        </Row>
        <Row className={styles.primaryContainer}>
          <Checkbox
            className={styles.primaryCheckbox}
            checked={isPrimary}
            onChange={() => updatePrimaryStatus(index)}
          />
          <span className={styles.primaryText}>Set as Primary</span>
        </Row>
      </Col>
    );
  };

  return (
    <>
      {locations.map((location, index) => (
        <div
          key={`${location.address?.delivery_line_1}-${index}`}
          className={containerStyles.inputBlock}
        >
          <label htmlFor="address" className={containerStyles.editLabel}>
            Address {index ? index + 1 : undefined}
          </label>
          <div className={containerStyles.rightBlock}>{addressRow(location, index)}</div>
        </div>
      ))}
      <Row>
        <Col onClick={() => !addAddressDisabled && addLocation()}>
          <span
            className={classNames(styles.addAddressContainer, {
              [styles.addressDisabled]: addAddressDisabled,
            })}
          >
            <AddFile /> <span className={styles.addAddress}>Add address</span>
          </span>
        </Col>
      </Row>
    </>
  );
};
