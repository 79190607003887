import { useDispatch } from 'react-redux';

import {
  AddFormCommentType,
  CommentUsersType,
  EditFormCommentType,
  FormCommentType,
  FormDocumentType,
  FieldInfoType,
  FormCommentThread,
} from 'types';
import { PreviewForm } from 'components/SmartForm/PreviewForm';
import { FormStateManagerProps } from 'pages/FormProcess/FormBuilder/FormStateManager';
import {
  progressSmartFormAnonEffect,
  progressSmartFormAuthEffect,
} from 'store/effects/formProcess/previewForm';
import { getAnonFormDocument, getAuthFormDocument } from 'pages/FormProcess/helper';
import { FORM_QUESTION_TYPE } from 'app-constants';

interface PreviewFormManagerProps extends FormStateManagerProps {
  formDocument: FormDocumentType;
  handleDone?: () => void;
  postHandleDone?: () => void;
  completed?: boolean;
  canceled?: boolean;
  onlyPreview?: boolean;
  signed?: boolean;
  signPreview?: boolean;
  handleEdit?: () => void;
  handleUnlock?: () => void;
  handleExit?: () => void;
  isCreator?: boolean;
  isEditSign?: boolean;
  commentUsers?: CommentUsersType;
  formComment?: FormCommentType;
  setFieldInfo: (fieldInfo: FieldInfoType) => void;
  handleGetFormComments: () => void;
  handleAddFormComment: (
    payload: AddFormCommentType,
    setSending: (isSending: boolean) => void,
  ) => void;
  handleEditFormComment: (
    payload: EditFormCommentType,
    setEditingMode: (editingMode: boolean) => void,
    setSending: (isSending: boolean) => void,
  ) => void;
  handleDeleteFormComment: (formCommentId: number) => void;
  handleToggleResolveThread?: (
    threadId: number,
    setResolving: (resolving: boolean) => void,
  ) => void;
  setCommentPanelOpen: (commentPanelOpen: boolean) => void;
  refresh?: () => void;
  formCommentThreads?: FormCommentThread[];
}

export const PreviewManager = ({
  formDocument,
  authData,
  anonData,
  handleDone,
  canceled,
  completed,
  handleExit,
  onlyPreview,
  signed,
  postHandleDone,
  signPreview,
  handleEdit,
  handleUnlock,
  isCreator = false,
  isEditSign = false,
  commentUsers,
  formComment,
  setFieldInfo,
  handleGetFormComments,
  handleAddFormComment,
  handleEditFormComment,
  handleDeleteFormComment,
  handleToggleResolveThread,
  setCommentPanelOpen,
  webView,
  refresh,
  formCommentThreads,
}: PreviewFormManagerProps) => {
  const dispatch = useDispatch();

  const {
    questions = [],
    link,
    documentName,
    clientName,
    answers,
    address,
    strikeThrough = {},
    otherDocuments = [],
  } = formDocument;

  if (questions.every((q) => q.disabled) && isCreator && handleDone) {
    handleDone();
  }

  const refreshForm = (err) => {
    if (!err && !signed) {
      refresh?.();
    }
  };

  const _handleDone = () => {
    if (handleDone) {
      handleDone();
    } else {
      if (authData) {
        dispatch(
          progressSmartFormAuthEffect(
            {
              formProcessPublicId: authData.formProcessPublicId,
              formDocumentPublicId: authData.formDocumentPublicId,
            },
            refreshForm,
          ),
        );
      } else if (anonData) {
        dispatch(
          progressSmartFormAnonEffect(
            {
              Token: anonData.token,
              type: anonData.type,
            },
            refreshForm,
          ),
        );
      }
    }
    if (postHandleDone) {
      postHandleDone();
    }
  };

  const getResponses = () => {
    if (isEditSign) {
      let signedAnswers = {};
      questions.forEach((ques) => {
        if (answers?.[ques.UUID]) signedAnswers[ques.UUID] = answers[ques.UUID];
      });
      return signedAnswers;
    } else if (signPreview) {
      return {};
    } else if (signed) {
      let signedAnswers = {};
      questions
        .filter((ques) => ques.Type === FORM_QUESTION_TYPE.Signature && !ques.disabled)
        .forEach((ques) => {
          if (answers?.[ques.UUID]) signedAnswers[ques.UUID] = answers[ques.UUID];
        });
      return signedAnswers;
    } else {
      return answers || {};
    }
  };

  return (
    <PreviewForm
      questions={questions}
      responses={getResponses()}
      handleDone={_handleDone}
      link={link}
      address={address}
      clientName={clientName}
      pdfName={documentName}
      completed={completed}
      canceled={canceled}
      onlyPreview={onlyPreview}
      handleEdit={handleEdit}
      handleUnlock={handleUnlock}
      handleExit={handleExit}
      commentUsers={commentUsers}
      formComment={formComment}
      setFieldInfo={setFieldInfo}
      handleGetFormComments={handleGetFormComments}
      handleAddFormComment={handleAddFormComment}
      handleEditFormComment={handleEditFormComment}
      handleDeleteFormComment={handleDeleteFormComment}
      handleToggleResolveThread={handleToggleResolveThread}
      setCommentPanelOpen={setCommentPanelOpen}
      strikeThrough={strikeThrough}
      webView={webView}
      isCreator={isCreator}
      formCommentThreads={formCommentThreads}
      otherDocuments={otherDocuments}
      anonData={anonData}
      authData={authData}
    />
  );
};
