import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { ClientAddress, ClientAddressUnion } from 'app-constants';
import { Popover } from 'components-antd';
import {
  isCASSAddress,
  normalizeAddress,
} from 'components/ClientsDrawer/ProfileClient/Contact/components/Address/helpers';
import { ProfileIcons } from 'components/ClientsDrawer/ProfileClient/Icons/ProfileIcons';
import { ArrowDown, ArrowUp } from 'components/Icons';
import { useOutsideClick } from 'hooks';
import { AddEditAddress } from './AddEditAddress';
import { AddressListing } from './AddressListing';
import styles from './styles.module.scss';

export const AddressesMenu = ({ testid, label, clientAddresses, className, clientId }) => {
  const addressRef = useRef(null);
  const [addresses, setAddresses] = useState<Array<ClientAddressUnion>>(clientAddresses ?? []);
  const [editAddress, setEditAddress] = useState<ClientAddressUnion | null>(null);
  const [editAddressIndex, setEditAddressIndex] = useState<number>(-1);
  const [open, setOpen] = useState(false);
  const [showAddAddressMenu, setShowAddAddressMenu] = useState(false);

  useOutsideClick([addressRef], () => {
    setOpen(false);
  });

  const getPrimaryAddress = () => {
    const primaryAddress = addresses.find((clientAddress) => clientAddress.isPrimary);
    const { line1, state, city } = normalizeAddress(primaryAddress);

    return (
      <div testid={testid} className={classNames(styles.value, styles.address)}>
        {primaryAddress ? (
          <>
            <p>{line1 || ''}</p>
            <p>{`${city || '-'}, ${state || '-'}`}</p>
          </>
        ) : (
          <p>{'-'}</p>
        )}
      </div>
    );
  };
  const handleAddressClick = (address: ClientAddressUnion) => {
    setEditAddress(address);
    setShowAddAddressMenu(true);
    const normalizeAddresses = addresses.map((address) => normalizeAddress(address));
    const selectedNormalizeAddress = normalizeAddress(address);

    setEditAddressIndex(
      normalizeAddresses.map((address) => address.line1).indexOf(selectedNormalizeAddress.line1),
    );
  };
  const handleAddAddressClick = () => {
    setEditAddress(null);
    setShowAddAddressMenu(true);
  };

  useEffect(() => {
    if (!open) {
      setEditAddress(null);
      setEditAddressIndex(-1);
    }
  }, [open]);

  const handleCancelClick = () => {
    setOpen(false);
  };

  return (
    <div
      testid="item_wrapper"
      ref={addressRef}
      className={classNames(styles.addressListing, className)}
    >
      <Popover
        content={
          showAddAddressMenu ? (
            <AddEditAddress
              addresses={addresses}
              clientId={clientId}
              setAddresses={setAddresses}
              setPoperOverOpen={setOpen}
              editAddressIndex={editAddressIndex}
              editAddress={editAddress}
              setEditAddress={setEditAddress}
              handleCancelClick={handleCancelClick}
            />
          ) : (
            <AddressListing
              addresses={addresses}
              handleAddressClick={handleAddressClick}
              isPopoverOpen={open}
              clientId={clientId}
              handleAddAddressClick={handleAddAddressClick}
            />
          )
        }
        placement="bottom"
        overlayClassName={styles.popoverOverlay}
        getPopupContainer={(triggerNode) => triggerNode}
        open={open}
      >
        <div className={styles.iconLabel}>
          <ProfileIcons name={ProfileIcons.ADDRESS} />
          <div className={classNames(styles.label)}>{label}</div>
          <div
            className={styles.iconContainer}
            onClick={() => {
              setShowAddAddressMenu(false);
              setOpen((val) => !val);
            }}
          >
            {open ? (
              <ArrowUp className={styles.icon} color="#515151" />
            ) : (
              <ArrowDown className={styles.icon} color="#515151" />
            )}
          </div>
        </div>
      </Popover>

      {getPrimaryAddress()}
    </div>
  );
};
