export const isExternalMember = (form) => {
  return !(form?.isOwner || form?.isSignatory || form?.isEditor);
};

export const getFilteredDataSourceByCategory = (formCategoryFilter, dataSource) => {
  let formData = [...dataSource];
  if (formCategoryFilter !== 'All Forms') {
    switch (formCategoryFilter) {
      case 'My Forms':
        formData = formData.filter((data) => data.isOwner);
        break;
      case 'Team Forms':
        formData = formData.filter((data) => !data.isOwner && data.isTeamForm);
        break;
      case 'External Forms':
        formData = formData.filter((data) => !data.isTeamForm && !data.isOwner);
        break;
    }
  }

  return formData;
};
