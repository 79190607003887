import React from 'react';

export const PencilIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_55015_45270)">
        <path
          d="M4.00049 19.9993H8.00049L18.5005 9.49932C19.0309 8.96889 19.3289 8.24947 19.3289 7.49933C19.3289 6.74918 19.0309 6.02976 18.5005 5.49933C17.9701 4.96889 17.2506 4.6709 16.5005 4.6709C15.7503 4.6709 15.0309 4.96889 14.5005 5.49933L4.00049 15.9993V19.9993Z"
          stroke="#747475"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.5 6.50146L17.5 10.5015"
          stroke="#747475"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_55015_45270">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const PencilSmallIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_55058_39196)">
        <path
          d="M2.66797 13.3283H5.33464L12.3346 6.32833C12.6883 5.9747 12.8869 5.49509 12.8869 4.99499C12.8869 4.4949 12.6883 4.01528 12.3346 3.66166C11.981 3.30804 11.5014 3.10938 11.0013 3.10938C10.5012 3.10937 10.0216 3.30804 9.66797 3.66166L2.66797 10.6617V13.3283Z"
          stroke="#FF576D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 4.33594L11.6667 7.0026"
          stroke="#FF576D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_55058_39196">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
