import React from 'react';
import { link } from 'settings/navigation/link';
import { useHistory } from 'react-router-dom';

import { DropdownMenu } from 'pages/Radar/RadarKits/components/DropdownMenu';

import styles from './styles.module.scss';
import classNames from 'classnames';
import moment from 'moment';

type Props = {
  variant?: 'large';
};

export const ReminderCard: React.FC<Props> = ({ variant }) => {
  const history = useHistory();

  const navigateToRemindersPage = () => {
    history.push(link.toIntelReminders());
  };
  return (
    <div
      className={classNames(styles.remindersCard, {
        [styles.large]: variant === 'large',
      })}
    >
      <div className={styles.header}>
        <div className={styles.heading}>Reminders</div>
        {variant !== 'large' && (
          <div className={styles.viewAll} onClick={navigateToRemindersPage}>
            View All
          </div>
        )}
      </div>
      {Array(6)
        .fill(0)
        .map((item) => (
          <div className={styles.reminderItem}>
            <div className={styles.days}>45</div>
            <div
              className={classNames(styles.description, {
                [styles.large]: variant === 'large',
              })}
            >
              <div>
                <span>Home insurance</span> policy is renewing in <span>4 days.</span>{' '}
              </div>
              <div className={styles.date}>
                {variant === 'large' && <>{moment().format('MM/DD/YYYY')}</>}
              </div>
            </div>
            <div className={styles.menu}>
              <DropdownMenu id="test" options={[{ label: 'Edit' }]} />
            </div>
          </div>
        ))}
    </div>
  );
};
