import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { DrawerHeaderTitle, DrawerCancel } from 'components/index';
import { DrawerFilter } from '../DrawerFilter';
import { DrawerSort } from 'components/Drawers/components/DrawerSort';

import styles from './styles.module.scss';

const Header = ({ className, onClose }) => (
  <div className={classNames(styles.header, className)}>
    <DrawerHeaderTitle>Notifications</DrawerHeaderTitle>
    <ul className={styles.actions}>
      {/* <li className={styles.actionItem}>
        <DrawerSort />
      </li> */}
      <li className={styles.actionItem}>
        <DrawerCancel onClick={onClose} />
      </li>
    </ul>
  </div>
);

Header.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
};

Header.defaultProps = {
  className: '',
  onClose: () => {},
};

export default Header;
