import { apiServer } from 'settings/web-services/api';

export function getVaultDocumentsData(cfg) {
  return apiServer.get(`/userDocument/ClientUserDocuments/${cfg.id}`);
}

export function getClientTransactionDocumentsData(cfg) {
  const { id, ...config } = cfg;
  return apiServer.get(`/userDocument/ClientUserDocuments/transaction/${id}`, { params: config });
}

export function getPreapprovalDocumentsData(cfg) {
  return apiServer.get(`/userDocument/ClientUserDocuments/preapproval/${cfg.id}`);
}

export function getArchiveDocumentsData(cfg) {
  return apiServer.get(`/userDocument/ClientUserDocuments/archive/${cfg.id}`);
}

export function getVaultDocumentsMetaData(cfg) {
  const clientId = cfg?.clientId;
  return apiServer.get(
    `/userDocument/ClientUserDocumentsMeta/${cfg.id}${clientId ? `?clientId=${clientId}` : ''}`,
  );
}

export function postVaultDocumentData(cfg) {
  const { id, clientId, shareWithClient, ...config } = cfg;
  return apiServer.post(
    `/userDocument/UploadDocument/${cfg.id}${clientId ? `?clientId=${clientId}` : ''}${
      shareWithClient !== undefined ? `&shareWithClient=${shareWithClient}` : ''
    }`,
    { ...config },
  );
}

export function updateVaultDocumentData(cfg) {
  const { id, ...config } = cfg;
  return apiServer.put(`/userDocument/UpdateDocument/${cfg.id}`, { ...config });
}

export function shareVaultDocument(cfg) {
  return apiServer.post('/userDocument/UserDocuments/share', { ...cfg });
}

export function deleteVaultDocument(cfg) {
  return apiServer.delete(`/userDocument/DeleteDocument/${cfg.id}`);
}
