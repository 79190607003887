import classNames from 'classnames';
import { Tooltip } from 'components-antd';
import moment from 'moment';
import { TableColumnsType } from 'pages/Dashboard/components/Table';
import { RadarHeader, Table } from 'pages/Radar/components';
import { Icons } from 'pages/Workshop/Icons';
import React, { useEffect, useMemo, useState } from 'react';
import tableStyles from '../../components/Table/styles.module.scss';
import headerStyles from '../AssignedClients/styles.module.scss';
import { DashIcon, PlansIcon, PlusIcon } from '../components';
import parentStyles from '../styles.module.scss';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPastMemberCampaignInfoEffect,
  getUpcomingMemberCampaignInfoEffect,
  requestGetCampaignByIdEffect,
  requestGetPublishedKitsEffect,
} from 'store/effects/radarKits';
import { useParams } from 'react-router-dom';
import { getClientDetailsEffect } from 'store/effects/clientDetail';
import { featureFlags } from 'utils/featureFlags';
import { CampaignKitTestClock } from 'pages/Radar/RadarKits/CampaignKitTestClock/CampaignKitTestClock';
import { KitIcon } from 'components/Icons/KitIcon';
import { TaskIcon } from 'components/Icons/TaskIcon';
import { AddKitsModal } from '../AddKitsModal';
import { SelectedKit } from '../ViewSequence';
import { getPublishedKitsSelector, selectedSequenceSelector } from 'store/selectors/radarKits';

export type PastDataType = {
  key: string;
  name: {
    title: string;
    info: string;
  };
  completed: {
    on: string;
    by: string;
  };
  isOpened: {
    date: string;
    time: string;
  } | null;
  isClicked: {
    date: string;
    time: string;
  } | null;
};

export const pastColumns: TableColumnsType<PastDataType> = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    className: tableStyles.tableCol,
    sorter: (a, b) => a.name.title.localeCompare(b.name.title),
    render: (group: any) => (
      <div className={parentStyles.tableColNameWrapper}>
        <div className={parentStyles.iconWrapper}>
          {group.info === 'KIT' ? <KitIcon /> : <TaskIcon />}
        </div>
        <div className={parentStyles.detailsWrapper}>
          <div className={parentStyles.title}>{group.title}</div>
          <div className={parentStyles.info}>{group.info}</div>
        </div>
      </div>
    ),
  },
  {
    title: 'Completed',
    dataIndex: 'completed',
    key: 'completed',
    className: tableStyles.tableCol,
    sorter: (a, b) => moment(a.completed.on).diff(moment(b.completed.on)),
    render: (completed: any) => (
      <div className={parentStyles.tableColNameWrapper}>
        <div className={parentStyles.detailsWrapper}>
          <div className={parentStyles.title}>{completed.on}</div>
          <div className={parentStyles.info}>{completed.by}</div>
        </div>
      </div>
    ),
  },
  {
    title: 'Opened',
    dataIndex: 'isOpened',
    key: 'isOpened',
    className: tableStyles.tableCol,
    sorter: (a, b) => (a.isOpened ? 1 : -1),
    render: (isOpened: { date: string; time: string } | null) => (
      <div className={tableStyles.tableNormalCell}>
        {isOpened ? (
          <Tooltip
            placement="top"
            getPopupContainer={(trigger) => trigger}
            overlayClassName={classNames(styles.compareOfferTooltip, 'mosaikTooltip')}
            title={
              <p className={styles.descInfo}>
                <div className={styles.heading1}>Opened:</div>
                <div className={styles.heading2}>{isOpened.date}</div>
                <div className={styles.heading3}>{isOpened.time}</div>
              </p>
            }
          >
            <div className={styles.checkmark}>
              <Icons variant={Icons.GREEN_LIGHT_CHECKMARK} />
            </div>
          </Tooltip>
        ) : (
          <div className={styles.icon}>
            <DashIcon />
          </div>
        )}
      </div>
    ),
  },
  {
    title: 'Clicked',
    dataIndex: 'isClicked',
    key: 'isClicked',
    className: tableStyles.tableCol,
    sorter: (a, b) => (a.isClicked ? 1 : -1),
    render: (isClicked: { date: string; time: string } | null) => (
      <div className={tableStyles.tableNormalCell}>
        {isClicked ? (
          <Tooltip
            placement="top"
            getPopupContainer={(trigger) => trigger}
            overlayClassName={classNames(styles.compareOfferTooltip, 'mosaikTooltip')}
            title={
              <p className={styles.descInfo}>
                <div className={styles.heading1}>Clicked:</div>
                <div className={styles.heading2}>{isClicked.date}</div>
                <div className={styles.heading3}>{isClicked.time}</div>
              </p>
            }
          >
            <div className={styles.checkmark}>
              <Icons variant={Icons.GREEN_LIGHT_CHECKMARK} />
            </div>
          </Tooltip>
        ) : (
          <div className={styles.icon}>
            <DashIcon />
          </div>
        )}
      </div>
    ),
  },
];

export type UpcomingDataType = {
  key: string;
  name: {
    title: string;
    info: string;
  };
  assignee: string;
  scheduled: string;
};

export const upcomingColumns: TableColumnsType<UpcomingDataType> = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    className: tableStyles.tableCol,
    sorter: (a, b) => a.name.title.localeCompare(b.name.title),
    render: (group: any) => (
      <div className={parentStyles.tableColNameWrapper}>
        <div className={parentStyles.iconWrapper}>
          {group.info === 'KIT' ? <KitIcon /> : <TaskIcon />}
        </div>
        <div className={parentStyles.detailsWrapper}>
          <div className={parentStyles.title}>{group.title}</div>
          <div className={parentStyles.info}>{group.info}</div>
        </div>
      </div>
    ),
  },
  {
    title: 'Assignee',
    dataIndex: 'assignee',
    key: 'assignee',
    className: tableStyles.tableCol,
    sorter: (a, b) => a.assignee.localeCompare(b.assignee),
  },
  {
    title: 'Scheduled',
    dataIndex: 'scheduled',
    key: 'scheduled',
    className: tableStyles.tableCol,
    sorter: (a, b) => moment(a.scheduled).diff(moment(b.scheduled)),
  },
];

export const AssignedClientsView: React.FC = () => {
  const { id, clientId } = useParams<{ id: string; clientId: string }>();
  const dispatch = useDispatch();
  const [upcomingData, setUpcomingData] = useState<any[]>([]);
  const [pastData, setPastData] = useState<any[]>([]);
  const [campaign, setCampaign] = useState<any>(null);
  const [client, setClient] = useState<any>(null);
  const [isAddKitsModalOpen, setIsAddKitsModalOpen] = useState<boolean>(false);
  const handleOpenAddKitsModal = () => setIsAddKitsModalOpen(true);
  const handleCloseAddKitsModal = () => setIsAddKitsModalOpen(false);
  useEffect(() => {
    dispatch(
      getUpcomingMemberCampaignInfoEffect(
        {
          campaignId: id,
          memberId: clientId,
        },
        { silent: !!upcomingData },
        (err, res) => {
          if (!err) setUpcomingData(res.data);
        },
      ),
    );
    dispatch(
      getPastMemberCampaignInfoEffect(
        {
          campaignId: id,
          memberId: clientId,
        },
        { silent: !!pastData },
        (err, res) => {
          if (!err) setPastData(res.data);
        },
      ),
    );
    dispatch(
      requestGetCampaignByIdEffect(id, { silent: true }, (err, res) => {
        if (!err) setCampaign(res.data.result);
      }),
    );

    dispatch(
      getClientDetailsEffect({ id: clientId }, { silent: true }, (err, res) => {
        if (!err) setClient(res.data.result);
      }),
    );
  }, []);

  const upcomingDataSource = useMemo<UpcomingDataType[]>(() => {
    return upcomingData.map((item) => {
      if (item.campaignKit) {
        return {
          key: item.campaignKit.Id,
          name: {
            title: item.campaignKit.Kit.Name,
            info: 'KIT',
          },
          assignee: item.assignee,
          scheduled: moment(item.execDate).format('MM/DD/YYYY'),
        };
      } else {
        return {
          key: item.campaignTask.Id,
          name: {
            title: item.campaignTask.Title,
            info: 'Task',
          },
          assignee: item?.campaignTask?.AssigneeList?.[0]?.FirstName,
          scheduled: moment(item.execDate).format('MM/DD/YYYY'),
        };
      }
    });
  }, [upcomingData]);

  const pastDataSource = useMemo<PastDataType[]>(() => {
    return pastData.map((item) => {
      if (item.campaignKit) {
        return {
          key: item.campaignKit.Id,
          name: {
            title: item.campaignKit.Kit.Name,
            info: 'KIT',
          },
          completed: {
            on: moment(item.execDate).format('MM/DD/YYYY'),
            by: item.assignee,
          },
          isOpened:
            item.opened && item.openedDate
              ? {
                  date: moment(item.openedDate).format('MM/DD/YYYY'),
                  time: moment(item.openedDate).format('h:mm A'),
                }
              : null,
          isClicked:
            item.clicked && item.clickedDate
              ? {
                  date: moment(item.clickedDate).format('MM/DD/YYYY'),
                  time: moment(item.clickedDate).format('h:mm A'),
                }
              : null,
        };
      } else {
        return {
          key: item.campaignTask.Id,
          name: {
            title: item.campaignTask.Title,
            info: 'Task',
          },
          completed: {
            on: moment(item.execDate).format('MM/DD/YYYY'),
            by: item?.campaignTask?.CompletedBy,
          },
          isOpened:
            item.opened && item.openedDate
              ? {
                  date: moment(item.openedDate).format('MM/DD/YYYY'),
                  time: moment(item.openedDate).format('h:mm A'),
                }
              : null,
          isClicked:
            item.clicked && item.clickedDate
              ? {
                  date: moment(item.clickedDate).format('MM/DD/YYYY'),
                  time: moment(item.clickedDate).format('h:mm A'),
                }
              : null,
        };
      }
    });
  }, [pastData]);

  const showSimulator = useMemo(
    () => featureFlags.testClock && client?.Email?.endsWith('@mosaik.io'),
    [client],
  );

  const { data: publishedKits } = useSelector(getPublishedKitsSelector);
  // Fetch Published Kits
  useEffect(() => {
    dispatch(requestGetPublishedKitsEffect({}, { silent: !!publishedKits }));
  }, []);

  const sequenceData = useSelector(selectedSequenceSelector);

  const selectedKits = useMemo<SelectedKit[]>(() => {
    if (!publishedKits || !sequenceData?.CampaignKits) return [];
    return sequenceData.CampaignKits.map((campaignKit) => {
      const kit = publishedKits.find((item) => item.KitId === campaignKit.KitId);
      return {
        ...campaignKit,
        Kits: kit?.Kits,
      };
    });
  }, [publishedKits, sequenceData]);

  return (
    <>
      <div className={parentStyles.pageWrapper}>
        <RadarHeader
          rightChildren={
            <div className={styles.btnWrapper}>
              <button
                className={styles.btn}
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenAddKitsModal();
                }}
              >
                <div>
                  <PlusIcon />
                </div>
                New
              </button>
            </div>
          }
        >
          <div className={parentStyles.titleInputContainer}>
            {client && (
              <div className={parentStyles.title}>{`${client.FirstName} ${client.LastName}`}</div>
            )}
            {campaign?.Name && <div className={parentStyles.description}>{campaign?.Name}</div>}
          </div>
        </RadarHeader>
        {showSimulator && <CampaignKitTestClock clientId={clientId} campaignId={id} />}
        <div className={parentStyles.pageContent}>
          <div className={parentStyles.tableContainer}>
            <div>
              <Table
                title="Upcoming"
                count={upcomingDataSource.length}
                data={upcomingDataSource}
                columns={upcomingColumns}
                disableCollapse={true}
              />
            </div>
            <div>
              <Table
                title="Past"
                count={pastDataSource.length}
                data={pastDataSource}
                columns={pastColumns}
                disableCollapse={true}
              />
            </div>
          </div>
        </div>
      </div>
      {isAddKitsModalOpen && (
        <AddKitsModal
          isOpen={isAddKitsModalOpen}
          closeModal={handleCloseAddKitsModal}
          title="Add KITs"
          selectedKits={selectedKits}
          sequenceId={id}
        />
      )}
    </>
  );
};
