import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { Button } from 'components';
import ArrowRightFull from 'components/Icons/ArrowRightFull';

export interface AnswersContainerProps {
  className?: any;
  children: React.ReactNode;
  isPending?: boolean;
  onNext?: any;
  disabled?: boolean;
}

export const AnswersContainer = ({
  className,
  children,
  isPending,
  onNext,
  disabled = false,
}: AnswersContainerProps) => {
  return (
    <div className={classNames(styles.answersContainer, className)}>
      {children}
      {onNext && (
        <div className={styles.buttonContainer}>
          <Button
            titleClassName={styles.titleClassName}
            className={styles.button}
            title="Continue"
            isPending={isPending}
            onClick={onNext}
            icon={<ArrowRightFull color={'#fff'} />}
            disabled={disabled}
          />
        </div>
      )}
    </div>
  );
};
