import React from 'react';
import { FinancialsTableModal } from './Financial';
import { VolumeTableModal } from './Volume';

type TableModalProps = {
  title: string;
  summaryType: string;
  open: boolean;
  onCancel: any;
  clarityType: string;
  selectedAgent?;
  aggregationType?: string;
  summaryKey?: string;
  columnType?: string;
  ledgerAccountId?: number | null;
  memo?: string | null;
  year?: number | null;
  month?: number | null;
  isoWeek?: number | null;
  date?: string | null;
};
export const TableModal: React.FC<TableModalProps> = ({
  title,
  aggregationType,
  summaryType,
  summaryKey,
  columnType,
  ledgerAccountId,
  memo,
  year = null,
  month = null,
  isoWeek = null,
  date = null,
  open,
  onCancel,
  selectedAgent,
  clarityType,
}) => {
  const getClarityTableModal = () => {
    if (clarityType === 'Financials') {
      return (
        <FinancialsTableModal
          title={title}
          aggregationType={aggregationType}
          summaryType={summaryType}
          summaryKey={summaryKey}
          columnType={columnType}
          ledgerAccountId={ledgerAccountId}
          memo={memo}
          year={year}
          month={month}
          isoWeek={isoWeek}
          date={date}
          open={open}
          onCancel={onCancel}
          selectedAgent={selectedAgent}
        />
      );
    } else {
      return (
        <VolumeTableModal
          title={title}
          summaryType={summaryType}
          year={year}
          month={month}
          isoWeek={isoWeek}
          date={date}
          open={open}
          onCancel={onCancel}
          selectedAgent={selectedAgent}
        />
      );
    }
  };

  return <>{getClarityTableModal()}</>;
};
