import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Icon from 'pages/Workshop/Transactions/TransactionDocuments/Icons';
import { Button } from 'components';
import { useOutsideClick } from 'hooks';
import { setFormLibraryModalVisibleAction } from 'store/actions/formProcess';
import { isFormLibraryModalVisibleSelector } from 'store/selectors/requestFormProcess';

import { useEmailVerificationWall } from 'features/emailVerification/useEmailVerificationWall';
import { ActionToInterrupt } from 'features/emailVerification/constants';
import { getTransactionSelector } from 'store/selectors/transaction';

import { getTransactionFolderEffect } from 'store/effects/transactions/folders/read';
import { uploadTransactionDocumentClientProfileEffect } from 'store/effects/transactions';
import { uploadDocumentVaultEffect } from 'store/effects';

import { MyLibraryModal } from 'pages/Workshop/Forms/components/MyLibraryModal';
import { LinkFormModal } from 'pages/Workshop/Forms/components/LinkFormModal';
import DocumentsModal from 'components/Transactions/UploadDocuments/DocumentsModal';
import { getUserId, getUserRolesMapSelector } from 'store/selectors/user';
import { showErrorMessage, showSuccessMessage } from 'helpers';
import { CategoryOptions } from 'components/Transactions/UploadDocuments/Uploaded/ItemClientProfile';
import styles from './styles.module.scss';

const CategoryIds = {
  MISC: 8,
  PREAPPROVALS: 6,
};

export const New = ({ clientId, clientName, fetchClientDocuments }) => {
  const [open, setOpen] = useState(false);
  const [openLinkFormsModal, setOpenLinkFormsModal] = useState(false);
  const [modalUpload, setModalUpload] = useState({ open: false });
  const buttonRef = useRef();
  const optionsRef = useRef();
  const isFormLibraryModalVisible = useSelector(isFormLibraryModalVisibleSelector);
  const { transaction } = useSelector(getTransactionSelector);
  const dispatch = useDispatch();
  const { isAgent } = useSelector(getUserRolesMapSelector);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadLoading, setUploadLoading] = useState(false);
  const loggedInUserId = useSelector(getUserId);

  useOutsideClick([buttonRef, optionsRef], () => setOpen(false));

  const onUploadFiles = async (files) => {
    const { TransactionOrFolder, permittedUsers, shareWithClient } = files?.[0] || {};
    const isTransactionCategory = files?.[0]?.type === CategoryOptions.TRANSACTION;

    const documentsPayload = files?.map((document) => ({
      Title: document?.documentName,
      ...(!isTransactionCategory
        ? {
            CategoryId:
              document?.type === CategoryOptions.MISC
                ? CategoryIds.MISC
                : CategoryIds?.PREAPPROVALS,
          }
        : {}),
      ...(isTransactionCategory
        ? {
            AccessToEveryone: false,
            UsersWithAccess: permittedUsers,
          }
        : {}),
      File: {
        ContentType: document?.contentType,
        Filename: document?.filename,
        Size: document?.size,
        Data: btoa(document?.data),
      },
    }));

    setUploadLoading(true);
    if (!isTransactionCategory) {
      dispatch(
        uploadDocumentVaultEffect(
          {
            id: loggedInUserId,
            clientId: clientId,
            Documents: documentsPayload,
            shareWithClient: !!shareWithClient,
          },
          {},
          (err) => {
            if (!err) {
              setModalUpload({
                open: false,
              });
              showSuccessMessage('Document uploaded successfully');
              fetchClientDocuments();
            } else showErrorMessage(err);

            setUploadLoading(false);
          },
        ),
      );
    } else {
      dispatch(
        uploadTransactionDocumentClientProfileEffect(
          { documents: documentsPayload, transactionId: TransactionOrFolder },
          {},
          (err) => {
            if (!err) {
              setModalUpload({
                open: false,
              });
              showSuccessMessage('Document uploaded successfully');
              fetchClientDocuments();
            } else showErrorMessage(err);

            setUploadLoading(false);
          },
        ),
      );
    }
  };

  const onLinkForm = (e) => {
    e.stopPropagation();
    setOpenLinkFormsModal(true);
    setOpen(false);
  };

  const onUpload = (e) => {
    e.stopPropagation();
    setModalUpload({ open: true });
    setOpen(false);
  };

  const onNewForm = (e) => {
    e.stopPropagation();
    openFormLibraryModalOrRequireEmailVerification(true);
    setOpen(false);
  };

  useEffect(() => {
    if (transaction?.Id) {
      dispatch(getTransactionFolderEffect(transaction, {}));
    }
  }, [transaction]);

  const openFormLibraryModalOrRequireEmailVerification = useEmailVerificationWall(
    ActionToInterrupt.CREATE_FORM,
    (value) => dispatch(setFormLibraryModalVisibleAction(value)),
  );

  const onCloseFormLibraryModal = () => dispatch(setFormLibraryModalVisibleAction(false));
  return (
    <div className={styles.new}>
      <Button
        title="New"
        className={styles.btn}
        iconLeft={<Icon className={styles.icon} variant={Icon.PLUS} />}
        titleClassName={styles.btnTitle}
        onClick={() => setOpen((prev) => !prev)}
        testid="new_task_button"
        ref={buttonRef}
      />
      {open && (
        <div ref={optionsRef} className={styles.options}>
          <ul>
            <div>
              <li testid="upload_file_action" className={styles.item} onClick={onUpload}>
                <div className={styles.icon}>
                  <Icon variant={Icon.UPLOAD_2} color="#FF576D" />
                </div>
                <span>Upload</span>
              </li>
              <>
                <li testid="link_form_action" className={styles.item} onClick={onLinkForm}>
                  <div className={styles.icon}>
                    <Icon variant={Icon.LINK_FORM} color="#FF576D" />
                  </div>
                  <span>Link Form</span>
                </li>
                <li testid="new_form_action" className={styles.item} onClick={onNewForm}>
                  <div className={styles.icon}>
                    <Icon variant={Icon.PLUS} color="#FF576D" />
                  </div>
                  <span>New Form</span>
                </li>
              </>
            </div>
          </ul>
        </div>
      )}

      {isAgent ? (
        <MyLibraryModal
          open={isFormLibraryModalVisible}
          transaction={transaction}
          clientId={parseInt(clientId)}
          onClose={onCloseFormLibraryModal}
        />
      ) : (
        <></>
      )}

      <LinkFormModal
        open={openLinkFormsModal}
        transaction={transaction}
        onClose={() => setOpenLinkFormsModal(false)}
        isLinkClient
        clientId={parseInt(clientId)}
        cb={fetchClientDocuments}
      />

      <DocumentsModal
        modal={modalUpload}
        multiple={false}
        files={uploadedFiles}
        isPending={uploadLoading}
        onSave={(files) => onUploadFiles(files)}
        onCloseModal={() => setModalUpload({ open: false })}
        dropzone
        filesRequired
        fileNameRequired
        addMore={false}
        isClientProfile
        clientName={clientName}
      />
    </div>
  );
};
