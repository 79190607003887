import { FormDetails } from 'pages/Workshop/Forms/components/FormDetails';

export const InProgressFormDetails = ({
  selectedForm,
  onCloseDetail,
  onHandleViewForm,
  refetchForms,
}) => {
  return (
    <FormDetails
      selectedFormProcess={selectedForm.formProcessId}
      onClose={() => onCloseDetail(true)}
      onHandleViewForm={onHandleViewForm}
      selectedFormStatus={selectedForm.status}
      refetchForms={refetchForms}
      onDeleteFormSuccess={onCloseDetail}
      inProgressForm={true}
    />
  );
};
