import React, { useState } from 'react';

import { FormHeader, FormSearchFilter } from '../components';
import { FormLibraryContent, myLibraryColumns } from './tableColumns';
import styles from './style.module.scss';
import { FormFilesTable } from '../components/FormFilesTable';
import { FormFilePreview } from '../components/FormFilePreview';
import Navigation from '../components/Footer';
import { useHistory } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';
import { FormFieldsModal } from '../components/FormFieldsModal';

import { AGENT } from 'settings/constants/roles';
import { useFetchTeamList } from '../components/hooks/useFetchTeamList';
import { isEditDynamicForm, smartFormPublishedByOption } from '../../helper';
import { excludeSmartForms } from './helper';
import { PublishedFormsType } from 'types';

import { DeleteTemplateModal } from './components/DeleteTemplateModal';
import { debounce } from 'lodash-es';
import { useFetchPublishedForms } from './hooks/useFetchPublishedForms';

import { FilterPayloadType, MyLibraryProps } from './types';
import { useAddFormsInBundle } from '../hooks/useAddFormsInBundle';
import { useHandleLibraryActions } from './hooks/useHandleLibraryActions';

export const MyLibrary = (props: MyLibraryProps) => {
  const {
    stagesStep,
    onUpdate,
    setCurrentStageIndex,
    transactionId,
    clientId,
    isProject,
    onSelectForm,
    onCloseModal,
    people,
    teams,
    locations,
    propertyInfo,
    setSelectedForm,
    onClose,
  } = props;

  const history = useHistory();

  const [rowKeys, setRowKeys] = useState<React.Key[]>([]);
  const [showSelection, setShowSelection] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [previewFile, setPreviewFile] = useState<FormLibraryContent | string>('');
  const [showNameModal, setNameModal] = useState(false);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<any>({});

  const [filterPayload, setFilterPayload] = useState<FilterPayloadType>({
    page: 1,
    pageSize: 5,
    publishByIds: [],
    categories: [],
    orderBy: '',
    order: '',
  });

  const [loading, setLoading] = useState(false);

  const { teamMembers, loading: teamLoading } = useFetchTeamList();

  const [rowKeyRecords, setRowKeyRecords] = useState<PublishedFormsType>([]);

  /************* Custom Hooks ****************/

  const { getPublishedForms, myLibraryFiles, isPending, dataSource } = useFetchPublishedForms({
    filterPayload,
    people,
    teams,
    locations,
  });

  const { mangeLibraryFormsForDynamicView, adding } = useAddFormsInBundle({
    onClose,
    rowKeyRecords,
  });

  const { handleFormRequest, createBundleForm } = useHandleLibraryActions({
    forms: myLibraryFiles?.forms || [],
    rowKeys,
    rowKeyRecords,
    onSelectForm,
    transactionId,
    clientId,
    isProject,
    propertyInfo,
    setLoading,
    setSelectedForm,
  });

  const dynamicForm = stagesStep && isEditDynamicForm(stagesStep);

  const handlePrevious = () => {
    onUpdate && onUpdate(stagesStep.FormCategories);
    setCurrentStageIndex && setCurrentStageIndex(stagesStep.FormCategories);
  };

  const applyFilter = (filterValues) => {
    setFilterPayload((prev) => ({ ...prev, ...filterValues }));
  };

  const managePreview = (record) => {
    setPreviewFile(record || '');
  };

  const debounced = React.useCallback(
    debounce((search) => applyFilter({ search, page: 1 }), 300),
    [],
  );

  /** Show Bundle Name Modal, Handle & Create Bundle if multiple library files */

  const toggleNameModal = () => {
    setNameModal((prev) => !prev);
  };

  const onFieldsModalSubmit = async (setLoader, { templateName }) => {
    setLoader(true);
    if (dynamicForm) {
      mangeLibraryFormsForDynamicView(templateName);
    } else {
      const bundleFormDetails: any = await createBundleForm(templateName);

      if (bundleFormDetails) {
        if (setSelectedForm) {
          toggleNameModal();
          setSelectedForm({ bundleId: bundleFormDetails.bundleId });
          setRowKeys([]);
          setRowKeyRecords([]);
          setShowSelection(false);
          getPublishedForms();
        } else {
          history.push({
            pathname: routes.workshopFormProcessRequest,
            state: {
              ...bundleFormDetails,
              formBundle: true,
              type: AGENT,
              name: templateName,
              isLibraryTemplate: true,
            },
          });
          onCloseModal && onCloseModal();
        }
      }
    }

    setLoader(false);
  };

  // Next of My Library
  const onNext = (libraryForm?: any) => {
    if (dynamicForm) {
      mangeLibraryFormsForDynamicView(libraryForm);
    } else if (rowKeys.length > 1) {
      toggleNameModal();
    } else {
      handleFormRequest(libraryForm?.versionId || libraryForm?.bundleId || (rowKeys[0] as string));
    }
  };

  // Delete Record in My Library
  const onDeleteRecordClick = (record) => {
    setSelectedDocument(record);
    setConfirmationOpen(true);
  };

  const closeDeleteModal = () => {
    setSelectedDocument({});
    setConfirmationOpen(false);
  };

  const onDeleteSuccess = ({ keys, records }) => {
    keys.length && setRowKeys(keys);
    records.length && setRowKeyRecords(records);

    closeDeleteModal();

    const { page: currentPage } = filterPayload;
    const isLastItem = currentPage > 1 && myLibraryFiles.forms.length === 1;

    if (isLastItem) {
      applyFilter({ page: currentPage - 1 });
    } else {
      getPublishedForms();
    }
  };

  const handleRowKeys = (selectedId, checked) => {
    let keys = [...rowKeys];
    if (checked) {
      if (onSelectForm) {
        setRowKeys([selectedId]);
      } else {
        keys.push(selectedId);
        setRowKeys(keys);

        const form = myLibraryFiles.forms.find(
          (item) => item.bundleId === selectedId || item.versionId === selectedId,
        );

        form && setRowKeyRecords((prev) => [...prev, form]);
      }
    } else {
      if (rowKeyRecords.length) {
        const records = rowKeyRecords.filter(
          (item) => item.bundleId !== selectedId && item.versionId !== selectedId,
        );

        setRowKeyRecords(records);
      }

      if (onSelectForm) {
        setRowKeys([]);
      } else {
        keys = keys.filter((item) => item !== selectedId);
        setRowKeys(keys);
      }
    }
  };

  const onTableChange = (pagination, filter, sorter) => {
    const orderKey: any = {
      ascend: 'asc',
      descend: 'desc',
    };

    const sortDetails = {
      order: Array.isArray(sorter) ? undefined : orderKey[sorter.order] || '',
      orderBy: Array.isArray(sorter) ? undefined : sorter.order ? sorter.field : '',
    };

    applyFilter(sortDetails);
  };

  return (
    <div className={styles.myLibraryWrapper}>
      <FormHeader heading={'My Library'} handlePrevious={stagesStep ? handlePrevious : undefined} />

      {previewFile && (
        <FormFilePreview record={previewFile} onClosePreview={() => managePreview('')} />
      )}

      <div className={styles.myLibraryContent}>
        <div className={styles.uploadsContentWrapper}>
          <FormSearchFilter
            searchText={searchTerm}
            onSearch={(value) => {
              setSearchTerm(value);
              debounced(value);
            }}
            showSelection={showSelection}
            onShowSelection={(selection) => {
              !selection && rowKeys.length > 0 && setRowKeys([]);
              setShowSelection(selection);
            }}
            hideSelection={false}
            filterProps={{
              publishedByOptions: [smartFormPublishedByOption, ...teamMembers],
              applyFilter,
            }}
          />

          <FormFilesTable
            className={styles.myLibraryTable}
            loading={isPending || (adding && !rowKeys.length)}
            columns={myLibraryColumns({
              rowKeys,
              dynamicForm,
              showSelection,
              handleRowKeys,
              onPreview: managePreview,
              onDelete: onDeleteRecordClick,
              onUpdateNameSuccess: getPublishedForms,
            })}
            dataSource={
              !isPending
                ? showSelection || dynamicForm
                  ? excludeSmartForms(dataSource)
                  : dataSource
                : []
            }
            total={myLibraryFiles?.total}
            rowKeyId={'versionId'}
            showSelection={showSelection}
            onRowClick={(record) => onNext(record)}
            onPageChange={(page) => applyFilter({ page })}
            currentPage={filterPayload.page}
            onTableChange={onTableChange}
            pageSize={filterPayload.pageSize}
          />
        </div>

        <FormFieldsModal
          open={showNameModal}
          title={setSelectedForm ? 'New Bundle' : dynamicForm ? 'Add New Form' : 'New Form Package'}
          okText={dynamicForm ? 'Add' : 'Create'}
          submit={onFieldsModalSubmit}
          onCancel={toggleNameModal}
          destroyOnClose={true}
          maskClosable={false}
          headerIcon="formBundle"
          libraryFields={true}
        />
      </div>

      {rowKeys.length ? (
        <Navigation
          size="large"
          onNext={() => onNext()}
          buttonText={onSelectForm || dynamicForm ? 'Add Form(s)' : 'Next'}
          loading={(rowKeys.length === 1 && (loading || adding)) || teamLoading || adding}
          disabled={loading || adding || teamLoading}
        />
      ) : (
        <></>
      )}

      <DeleteTemplateModal
        rowKeys={rowKeys}
        rowKeyRecords={rowKeyRecords}
        open={confirmationOpen}
        document={selectedDocument}
        onClose={closeDeleteModal}
        onDeleteSuccess={onDeleteSuccess}
      />
    </div>
  );
};
