import { Wrapper } from 'components';
import { TemplateHeader } from 'pages/Templates/components/TemplateHeader';
import { ClauseForm } from '../ClauseForm';

import styles from './style.module.scss';

export const CreateClause = () => {
  return (
    <Wrapper isPending={false} className={styles.clauseWrapper}>
      <TemplateHeader label="Clauses" className={styles.clauseHeader} />
      <ClauseForm />
    </Wrapper>
  );
};
