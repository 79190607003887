import classNames from 'classnames';
import { ConfirmationDialog, Wrapper as PendingWrapper, SearchFieldWithDebounce } from 'components';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { link } from 'settings/navigation/link';
import {
  getTeamMembersByTeamIdEffect,
  getTeamsDashboardEffect,
  resetMembersSearchEffect,
  searchMembersEffect,
  setSelectedTeamNameEffect,
  updateMemberActiveStatusByAdminEffect,
  updateMemberActiveStatusEffect,
} from 'store/effects';
import {
  getTeamsDashboardSelector,
  getTeamsDataDashboardSelector,
} from 'store/selectors/adminPanel';
import { TeamsTable } from './Components/TeamsTable';
import styles from './styles.module.scss';
import { tableColumns } from './tableColumns';
import {
  getMembersSelector,
  getTeamDetailsDashboardSelector,
  getTeamMembersSelector,
} from 'store/selectors/adminPanel/teamDetails';
import GroupBy from './Components/GroupBy';
import { showSuccessMessage } from 'helpers';
import { MlsAgentsModal } from './MlsAgentsModal';
import { MemberModal } from './MemberModal';
import { LoginLogsModal } from './LoginHistoryModal';
import InviteTeamMember from './InviteTeamMember';

export const Members = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { isPending, isIdle } = useSelector(getTeamDetailsDashboardSelector);
  const teamMembers = useSelector(getMembersSelector);
  const [membersGroupBy, setMembersGroupBy] = useState('active');
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [currentData, setCurrentData] = useState();
  const [openMlsModal, setOpenMlsModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openLoginLogsModal, setOpenLoginLogsModal] = useState(false);
  const [openInviteTeamMemberModal, setOpenInviteTeamMemberModal] = useState(false);
  const hasBeenRendered = useRef(false);
  const teamId = params?.id;

  const getTeamMembers = (idle) => {
    if (idle) {
      dispatch(getTeamMembersByTeamIdEffect({ teamId, isActive: membersGroupBy === 'active' }, {}));
    } else {
      dispatch(
        getTeamMembersByTeamIdEffect(
          { teamId, isActive: membersGroupBy === 'active' },
          { silent: true },
        ),
      );
    }
  };

  useEffect(() => {
    if (hasBeenRendered.current) {
      getTeamMembers(true);
    } else {
      getTeamMembers(isIdle);
    }
    hasBeenRendered.current = true;
  }, [membersGroupBy]); // eslint-disable-line

  const onReactivateClick = (data) => {
    console.log('Reactivate Click', data);
    setCurrentData({
      ...data,
      IsActive: false,
    });
    setOpenConfirmModal(true);
  };

  const onDeactivateClick = (data) => {
    console.log('Deactivate Click', data);
    setCurrentData({
      ...data,
      IsActive: true,
    });
    setOpenConfirmModal(true);
  };

  const changeUserStatusContent = useMemo(
    () =>
      currentData ? (
        <div className={styles.userStatusConfirmText}>
          <h4>
            Are you sure that you want to {currentData.IsActive ? 'deactivate' : 'activate'}{' '}
            {currentData?.MemberFirstName} {currentData?.MemberLastName}&apos;s account?
          </h4>
          <p>
            {currentData?.MemberFirstName} will {currentData.IsActive ? 'no longer' : 'regain'} have
            access to this account.
          </p>
        </div>
      ) : null,
    [currentData],
  );

  const onSubmitConfirmModal = () => {
    dispatch(
      updateMemberActiveStatusByAdminEffect(
        { isActive: !currentData.IsActive, silent: true },
        { agentId: currentData.UserId },
        (err) => {
          if (!err) {
            getTeamMembers(false);
            setOpenConfirmModal(false);
            showSuccessMessage(`Agent ${currentData.IsActive ? 'deactivated' : 'activated'}.`);
          }
        },
      ),
    );
  };

  const onMlsClick = (data) => {
    setCurrentData(data);
    setOpenMlsModal(true);
  };

  const onEditClick = (data) => {
    setCurrentData(data);
    setOpenEditModal(true);
  };

  const onLoginClick = (data) => {
    setCurrentData(data);
    setOpenLoginLogsModal(true);
  };

  const tableProps = {
    columns: tableColumns(
      membersGroupBy === 'active',
      onReactivateClick,
      onDeactivateClick,
      onMlsClick,
      onEditClick,
      onLoginClick,
    ),
    onRow: (record) => {
      return {
        onClick: (event) => {
          console.log('Records is ', record);
        },
      };
    },
  };

  return (
    <div>
      <PendingWrapper
        isPending={isPending}
        className={classNames({ [styles.pendingWrapper]: isPending })}
      >
        <div className={styles.titleBar}>
          <div>
            <span className={styles.membersTitle}>
              Members <span className={styles.membersCount}>{`(${teamMembers.length})`}</span>
            </span>
          </div>
          <div className={styles.actions}>
            <div className={styles.searchWrapper}>
              <SearchFieldWithDebounce
                className={styles.inputWrap}
                classNameInput={styles.searchInput}
                iconClassName={styles.icon}
                resetAction={resetMembersSearchEffect}
                sendAction={searchMembersEffect}
                isLoading={isPending}
                placeholder="Search"
                testid="search"
                isTransactionAggregate={true}
                isExpandedVersion
              />
            </div>
            <GroupBy membersGroupBy={membersGroupBy} setMembersGroupBy={setMembersGroupBy} />
            <div
              className={classNames(styles.inviteBtn, 'show-cursor')}
              onClick={() => setOpenInviteTeamMemberModal(true)}
            >
              <div>+ Invite</div>
            </div>
          </div>
        </div>
        <TeamsTable dataSource={teamMembers} {...tableProps} />
      </PendingWrapper>
      <ConfirmationDialog
        onReject={() => setOpenConfirmModal(false)}
        onConfirm={onSubmitConfirmModal}
        isOpen={openConfirmModal}
        confirmText="Continue"
      >
        {changeUserStatusContent}
      </ConfirmationDialog>
      {openMlsModal && (
        <MlsAgentsModal
          isOpen={openMlsModal}
          onCloseModal={() => setOpenMlsModal(false)}
          agentId={currentData.UserId}
          agentName={`${currentData?.MemberFirstName} ${currentData?.MemberLastName}`}
        />
      )}
      {openEditModal && (
        <MemberModal
          isOpen={openEditModal}
          onCloseModal={() => setOpenEditModal(false)}
          agentId={currentData?.UserId}
          teamId={Number(teamId)}
          firstName={currentData?.MemberFirstName}
          lastName={currentData?.MemberLastName}
          email={currentData?.MemberEmail}
          phone={currentData?.MemberPhones?.find((phone) => phone?.IsPrimary)?.PhoneNumber}
          title={currentData?.Title}
          role={currentData?.MemberRole}
          getTeamMembers={getTeamMembers}
        />
      )}
      {openLoginLogsModal && (
        <LoginLogsModal
          isOpen={openLoginLogsModal}
          onCloseModal={() => setOpenLoginLogsModal(false)}
          agentEmail={`${currentData?.MemberEmail}`}
        />
      )}
      {openInviteTeamMemberModal && (
        <InviteTeamMember
          isOpen={openInviteTeamMemberModal}
          onCloseModal={() => setOpenInviteTeamMemberModal(false)}
          teamId={Number(teamId)}
          getTeamMembers={getTeamMembers}
        />
      )}
    </div>
  );
};

export default Members;
