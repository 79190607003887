import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { showErrorMessage } from 'helpers/errors';
import { CLIENT, TEAM_ADMIN, TEAM_OWNER } from 'settings/constants/roles';
import { postReinviteClientById } from 'api/clientsList';
import {
  appDrawerSetClientIdAndInstancesAction,
  appManageClientDrawerAction,
  appSetClientDrawerActiveTab,
  appSetClientsSearchValueAction,
} from 'store/actions/app';
import { Resend, Approve } from 'components/Icons';
import { getConnectionTypeSelector } from 'store/selectors/app';
import { AGENT_CONNECTIONS } from 'settings/constants/drawers';
import { appSetConnectionTypeEffect, setDrawerAgentProfileId } from 'store/effects';

import styles from './styles.module.scss';
import { postReinviteTeamMember } from 'api/teamList';
import { useHistory } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';

const ListItem = ({
  testid,
  connectionName,
  connectionRole,
  teamRole,
  activeTabName,
  sendDate,
  connectionId,
  searchInstances,
  transactionInstances,
  listType,
}) => {
  const connectionType = useSelector(getConnectionTypeSelector);
  const [sendStatus, setSendStatus] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const additionalInfoClassName = classNames(styles.additionalInfoContainer, {
    [styles.pendingAdditionalInfoContainer]: listType === 'Pending',
  });

  const resendInvite = useCallback(async () => {
    try {
      setSendStatus(false);
      if (connectionRole === CLIENT) {
        await postReinviteClientById({ id: connectionId });
        setSendStatus(true);
      }
      if (connectionType === AGENT_CONNECTIONS.Team) {
        await postReinviteTeamMember({ id: connectionId });
        setSendStatus(true);
      }
    } catch (err) {
      showErrorMessage(err);
      setSendStatus(null);
    }
  }, [setSendStatus, connectionRole, connectionId]);

  const getIcon = useCallback(() => {
    if (sendStatus) {
      return (
        <div className={styles.sentIconContainer}>
          <Approve color="green" />
          <span className={styles.text}>Sent</span>
        </div>
      );
    }
    if (sendStatus === null) {
      return (
        <button
          testid="resend"
          className={styles.button}
          onClick={(e) => {
            e.stopPropagation();
            resendInvite();
          }}
        >
          <Resend />
          <span className={styles.text}>Resend</span>
        </button>
      );
    }
    return <span className={styles.loading}>Loading...</span>;
  }, [sendStatus, resendInvite]);

  const handleDrawerClose = useCallback(() => {
    dispatch(appManageClientDrawerAction(false));
    dispatch(appSetConnectionTypeEffect({ type: AGENT_CONNECTIONS.Clients }));
    dispatch(appSetClientsSearchValueAction(''));
    dispatch(appSetClientDrawerActiveTab(0));
  }, [dispatch]);

  const handleItemClick = () => {
    if (connectionType === AGENT_CONNECTIONS.Collaborators) return;
    if (connectionType === AGENT_CONNECTIONS.Clients) {
      handleDrawerClose();
      history.push(`${routes.clientProfile}/${connectionId}`);
    } else {
      dispatch(
        connectionType === AGENT_CONNECTIONS.Team
          ? setDrawerAgentProfileId({ id: connectionId, open: false, showExpandedSection: true })
          : appDrawerSetClientIdAndInstancesAction({
              id: connectionId,
              searchInstances,
              transactionInstances,
              open: false,
              showExpandedSection: true,
            }),
      );
    }
  };

  return (
    <div testid={testid} className={styles.itemContainer} onClick={handleItemClick}>
      <div className={styles.connectionNameContainer}>
        <p testid="name" className={styles.connectionName}>
          {connectionName}
        </p>
        {connectionType === AGENT_CONNECTIONS.Team &&
        (teamRole === TEAM_ADMIN || teamRole === TEAM_OWNER) ? (
          <div className={styles.tagContainer}>
            <div className={styles.tag}>{teamRole}</div>
          </div>
        ) : null}
      </div>
      <div testid="additional_info" className={additionalInfoClassName}>
        {listType === 'Active' && (
          <>
            <p className={styles.additionalInfoText}>{connectionRole}</p>
            <div className={styles.roleSeparator}></div>
            <p className={styles.additionalInfoText}>{listType}</p>
          </>
        )}
        {listType === 'Retained' && (
          <>
            <p className={styles.additionalInfoText}>{connectionRole}</p>
            <div className={styles.roleSeparator}></div>
            <p className={styles.additionalInfoText}>{listType}</p>
          </>
        )}
        {listType === 'Pending' && (
          <p className={styles.additionalInfoText}>{`Sent ${moment(
            sendStatus ? undefined : sendDate,
          ).format('M/D/YY')}`}</p>
        )}
        {listType === 'Pending' && <div testid="send_status">{getIcon()}</div>}
      </div>
    </div>
  );
};

ListItem.propTypes = {
  connectionName: PropTypes.string,
  connectionRole: PropTypes.string,
  teamRole: PropTypes.string,
  activeTabName: PropTypes.string,
  sendDate: PropTypes.string,
  connectionId: PropTypes.number,
  testid: PropTypes.string,
};

ListItem.defaultProps = {
  connectionName: '',
  connectionRole: '',
  teamRole: '',
  activeTabName: '',
  sendDate: '',
  connectionId: null,
  testid: undefined,
};

export default ListItem;
