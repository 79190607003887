import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.scss';
import SelectMilestone from './SelectMilestone';
import { TimelineMilestonesInterface } from './types/TransactionMilestone';
import { formatDate, getDateOnly } from 'helpers';
import { TaskEditableFormValuesType } from 'types';

interface MilestoneInputProps {
  onChange: (value: string) => void;
  closeOnSelect: boolean;
  values: TimelineMilestonesInterface[];
  isViewMode: boolean | null;
  placeholder?: string;
  isTemplate?: boolean;
  tasks?: TaskEditableFormValuesType;
}

const MilestoneInput = ({
  onChange,
  closeOnSelect,
  values,
  isViewMode,
  placeholder,
  isTemplate,
  tasks,
}: MilestoneInputProps) => {
  const [selectedMilestone, setSelectedMilestone] = useState<TimelineMilestonesInterface | null>(
    null,
  );

  useEffect(() => {
    if (tasks?.MilestonePublicId) {
      const matchingMilestone = values.find(
        (milestone) => milestone.PublicId === tasks.MilestonePublicId,
      );
      if (matchingMilestone) {
        setSelectedMilestone({
          name: isTemplate ? (
            matchingMilestone.Title
          ) : (
            <div className={styles.milestoneOption}>
              <span>{matchingMilestone.Title}</span>
              <span className={styles.date}>
                {getDateOnly(matchingMilestone.DueDate, 'MMM D, YYYY')}
              </span>
            </div>
          ),
          value: matchingMilestone.PublicId || undefined,
        });
      }
    }
  }, [tasks?.MilestonePublicId, values]);

  const getOptions = () => {
    return values.map((milestone) => ({
      name: isTemplate ? (
        milestone.Title
      ) : (
        <div className={styles.milestoneOption}>
          <span>{milestone?.Title}</span>
          <span className={styles.date}>{getDateOnly(milestone?.DueDate, 'MMM D, YYYY')}</span>
        </div>
      ),
      value: isTemplate ? milestone?.PublicId : milestone?.Id,
    }));
  };

  const handleSelect = (event: any) => {
    const selectedOption = event.target.value;
    setSelectedMilestone(selectedOption);
    onChange(selectedOption.value);
  };

  return (
    <div className={classNames(styles.clientRole)}>
      <SelectMilestone
        multiple={false}
        className={{
          wrapper: classNames(styles.search),
          input: styles.input,
          value: styles.value,
        }}
        options={getOptions()}
        placeholder={placeholder || 'Select a milestone'}
        onSelect={handleSelect}
        value={selectedMilestone}
        isArrowIcon
        disabled={isViewMode}
        testid="role_input_select"
        search={false}
        searchIconClassName={styles.searchIcon}
        closeOnSelect={closeOnSelect}
        emptyMessage={() => null}
      />
    </div>
  );
};

MilestoneInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  values: PropTypes.array,
  isViewMode: PropTypes.bool,
  placeholder: PropTypes.string,
  isTemplate: PropTypes.bool,
  tasks: PropTypes.object,
};

MilestoneInput.defaultProps = {
  className: '',
  onChange: () => {},
  values: [],
  isViewMode: false,
  placeholder: '',
  isTemplate: false,
  tasks: {},
};

export default MilestoneInput;
