import classNames from 'classnames';
import { Icons } from '../Icons';
import styles from './style.module.scss';

type FormHeaderProps = {
  handlePrevious?: (e: any) => void;
  heading: string;
  className?: string;
};
export const FormHeader = ({ handlePrevious, heading, className }: FormHeaderProps) => {
  return (
    <div className={classNames(styles.header, className)}>
      {handlePrevious ? (
        <div className={styles.previousIcon} onClick={handlePrevious}>
          <Icons variant={'previous'} />
        </div>
      ) : (
        <></>
      )}
      <h1 className={styles.heading}>{heading}</h1>
    </div>
  );
};
