import { UploadDocumentField } from '../../Document/UploadDocumentField';

import { useUploadFileWithAI } from 'pages/ExternalOffer/OfferSubmission/hooks/useUploadFileWithAI';
import styles from './style.module.scss';

export const UploadOfferFile = (props) => {
  const { saveFileWithAI } = useUploadFileWithAI();

  const onSaveCustomDocument = async (documents, fileCode, index, modalCloseCB) => {
    const { documentName, file } = documents[0];
    saveFileWithAI(documentName, file, (result) => {
      props.onFillOfferDetails(result);
      modalCloseCB();
    });
  };

  return (
    <UploadDocumentField
      docKey={'custom-document'}
      onSave={onSaveCustomDocument}
      uploadText={'Upload Offer'}
      index={0}
      uploadDocumentClass={styles.uploadOfferDocument}
    />
  );
};
