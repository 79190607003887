import { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Input, SingleLocation } from 'components';
import { preFormQuestionsIds } from 'settings/constants/preForm';
import { useDispatch, useSelector } from 'react-redux';
import { getPreFormSelector } from 'store/selectors/requestQuote';
import { setPreFormQuestionsEffect } from 'store/effects/quotes';

import styles from './styles.module.scss';

import { isPropertyAddress, showErrorMessage } from 'helpers';
import { createAddress } from 'helpers/utilsTS';

const PropertyAddressInput = (props) => {
  const {
    className,
    onChange,
    unitClassName,
    valuesWrapperClassName,
    searchWrapperClassName,
    wrapperClassName,
    popupClassName,
  } = props;
  const dispatch = useDispatch();
  const preForm = useSelector(getPreFormSelector);

  const onClearAddress = () => {
    dispatch(
      setPreFormQuestionsEffect({
        [preFormQuestionsIds.property]: { address: null },
        [preFormQuestionsIds.isProperty]: false,
      }),
    );
  };

  const onAddressChange = useCallback(
    (result) => {
      const location = result?.result?.[0];
      const { data, success } = createAddress(location, result);

      if (success && data) {
        const { city, state, zip, line1, providerPlaceId, placeName } = data;

        const address = {
          City: city,
          State: state,
          Zip: zip,
          Line1: line1,
          ProviderPlaceId: providerPlaceId,
          PlaceName: placeName,
        };

        if (!isPropertyAddress(address)) {
          showErrorMessage('Enter a valid property address');
          return onClearAddress();
        }

        dispatch(
          setPreFormQuestionsEffect({
            [preFormQuestionsIds.property]: {
              address: {
                ...(preForm?.[preFormQuestionsIds.property]?.address || {}),
                ...address,
              },
            },
            [preFormQuestionsIds.isProperty]: true,
          }),
        );
      }
    },
    [dispatch, preForm],
  );
  const onUnitAptChange = (event, val) => {
    if (!isPropertyAddress(preForm?.[preFormQuestionsIds.property]?.address)) return;
    dispatch(
      setPreFormQuestionsEffect({
        [preFormQuestionsIds.property]: {
          ...(preForm?.[preFormQuestionsIds.property] || {}),
          address: {
            ...(preForm?.[preFormQuestionsIds.property]?.address || {}),
            Line2: val || undefined,
          },
        },
      }),
    );
    onChange({
      ...(preForm?.[preFormQuestionsIds.property]?.address || {}),
      Line2: val || undefined,
    });
  };

  return (
    <div className={classNames(styles.propertyAddressInput, className)}>
      <SingleLocation
        placeholder="Enter property address"
        types={['address']}
        variant="light"
        onResult={onAddressChange}
        testid="address"
        value={
          preForm?.[preFormQuestionsIds.property]?.address
            ? preForm?.[preFormQuestionsIds.property]?.address
            : {}
        }
        className={wrapperClassName}
        valuesWrapperClassName={classNames(styles.locationValues, valuesWrapperClassName)}
        searchWrapperClassName={classNames(styles.locationsSearch, searchWrapperClassName)}
        popupClassName={popupClassName}
        dropdownClassName={styles.locationsDropdown}
        onClear={onClearAddress}
      />
      <Input
        className={classNames(styles.unit, unitClassName)}
        placeholder="Suite / Unit"
        variant={Input.LIGHT_FULL}
        onChange={onUnitAptChange}
        value={preForm[preFormQuestionsIds.property]?.address?.Line2 || ''}
        testid="unit_apt"
      />
    </div>
  );
};

PropertyAddressInput.propTypes = {
  className: PropTypes.string,
  unitClassName: PropTypes.string,
  valuesWrapperClassName: PropTypes.string,
  searchWrapperClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  onChange: PropTypes.func,
  popupClassName: PropTypes.string,
};

PropertyAddressInput.defaultProps = {
  className: '',
  unitClassName: '',
  valuesWrapperClassName: '',
  searchWrapperClassName: '',
  wrapperClassName: '',
  onChange: () => {},
  popupClassName: '',
};

export default PropertyAddressInput;
