import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { Close, MultiSelect } from 'components/Icons';
import { getTransactionAccessSelector } from 'store/selectors/transaction';
import { Filter } from './Filter';
import NewTask from './NewTask';

import { useLocation } from 'react-router-dom';
import { AGENT, Role, THIRD_PARTY } from 'settings/constants/roles';
import { getUserRoleSelector, getUserRolesMapSelector } from 'store/selectors/user';
import styles from './styles.module.scss';

interface ActionsProps {
  className?: string;
  showSelection: boolean;
  setShowSelection: (showSelection: boolean) => void;
}

interface LocationState {
  transactionId: string;
  taskIds?: number[];
  assignor?: number;
}

const Actions = ({ className, showSelection, setShowSelection }: ActionsProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const userRole = useSelector(getUserRoleSelector);
  const canCreateNewTask = userRole === AGENT || userRole === THIRD_PARTY;

  return (
    <div className={classNames(styles.tasksActions, className)}>
      {showSelection ? (
        <Close className={styles.multiSelectBtn} onClick={() => setShowSelection(false)} />
      ) : (
        <>
          {canCreateNewTask && <NewTask />}
          <Filter />
          {userRole !== Role.Client && (
            <div className={styles.multiSelectBtn} onClick={() => setShowSelection(true)}>
              <MultiSelect />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Actions;
