import React, { FC, ReactElement, useState } from 'react';
import { Button, Input, Modal, Switch } from '../../../../../components-antd';
import styles from './styles.module.scss';
import { CheckmarkIcon, DraftsIcon, FrameIcon, TagsIcon, UsersIcon } from '../Icons';
import { useFormik } from 'formik';
import { SearchIcon } from '../Icons/SearchIcon';
import classNames from 'classnames';
import { PlusIcon } from '../Icons/PlusIcon';
import { Autocomplete } from '../Autocomplete';

export interface ShareIntelModalProps {
  open: boolean;
  onClose: () => void;
}

enum Editor {
  Text = 'text',
  Photo = 'photo',
  Tags = 'tags',
  PublishTo = 'publishTo',
}

export const ShareIntelModal: FC<ShareIntelModalProps> = (
  props: ShareIntelModalProps,
): ReactElement => {
  const { open, onClose } = props;

  const [editor, setEditor] = useState<Editor | undefined>(Editor.Tags);

  const formik = useFormik({
    initialValues: {
      subject: 'Back on the market',
      description: 'This is a body text example',
      images: [
        'https://s3-alpha-sig.figma.com/img/4036/41ed/bed7de195e34980fffcaeec1e38f9095?Expires=1730678400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=S-uJc7HnNcd4Y4Nqnc8B6KWTEE3TajG~i76boKnRrzfptSdgEROko~RSBW8F34H0ShoUrCHdgelWdHphCRg75UB3oV-xhlfxpjr1yzYsTIjN4V89TiLKAFitRxKGUS4V~xscYk2c64hV~0KORenVUyjMHVbyWAxd2rLJM5YBCPWDDqkAFwjey2-ey7RayfgLoAvpI3bD4XWjtS6Y1Rr8QL~EzxzdWl9~j46CUgrGP5GnYxHyjKfalrVWYHnVojNVwwyTPnt4t5xB77WvrdNaSFqbW~qABFXLJvgGARSZmh~HdUMHj~qExfIUSqo1BSs9dXgzZ-ls0bHEBG22kCt2Fg__',
        'https://s3-alpha-sig.figma.com/img/c765/cea3/1e903e26d0ad1901c821f29cb18ce2cf?Expires=1730678400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=cnkfRNQ2T7SO0g5~iCg-sgCVS-fhAdwXHOglNIIVPRQyRz2v9hhlQBC5px4JrflZp9Yr98npu02r54RAwU7k3f86cl6lvQY5dOXT5NJkYX2Yxb7Enbs~BtEODybJ9awohT1TngSf9pIU3O1Etv7n1yqDErtEP7Yudp4HjIHYYtHk5lLH6omz7uttRpw4-yKsOaChNw3jGrpI0PoM3NtIALPM5QZKWrL5LQABFx7j1qUR1ciHRWYhH8L4OpT3yOXE03vEwnBhxgBdiD6GCEq71mBU2vRWmL9dPZGuWgmy4nX~ELrdecTJqToVShEqAf~atUkGL-rdqc82CzW3tr2Nzw__',
      ],
      tags: [
        { name: 'Deal of the Week', active: true },
        { name: 'Highlight of the Day', active: true },
        { name: 'Tag 3', active: false },
        { name: 'Lorem Ipsum', active: false },
        { name: 'Lorem Ipsum 2', active: false },
        { name: 'Price Reduction', active: false },
        { name: 'Custom Tag', active: false, added: true },
      ],
      publishTo: 'ALL',
      publishTarget: ['Rebecca Torres'],
      sendEmailAlert: true,
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const handleEditorClick = (nextEditor: Editor) => () => {
    if (nextEditor === editor) {
      return;
    }

    setEditor(nextEditor);
  };

  return (
    <Modal
      title={<div className={styles.modalTitle}>New Intel</div>}
      open={open}
      width={675}
      onCancel={onClose}
      onOk={onClose}
      cancelButtonProps={{ style: { display: 'none' } }}
      footer={null}
      className={styles.modal}
    >
      <div className={styles.modalContent}>
        <div className={styles.section} onClick={handleEditorClick(Editor.Text)}>
          {editor !== Editor.Text ? (
            <>
              <div className={styles.icon}>
                <DraftsIcon />
              </div>
              {formik.values.subject || formik.values.text ? (
                <div className={styles.textContainer}>
                  <div className={styles.subject}>{formik.values.subject}</div>
                  <div className={styles.description}>{formik.values.description}</div>
                </div>
              ) : (
                <div className={styles.text}>Add text</div>
              )}
            </>
          ) : (
            <div className={styles.sectionEditor}>
              <Input
                className={styles.subjectInput}
                hideOutline
                placeholder="Subject..."
                value={formik.values.subject}
                onChange={(e) => formik.setFieldValue('subject', e.target.value)}
              />
              <div className={styles.divider} />
              <textarea
                className={styles.descriptionInput}
                placeholder="Add text..."
                rows={2}
                value={formik.values.description}
                onChange={(e) => formik.setFieldValue('description', e.target.value)}
              />
            </div>
          )}
        </div>

        <div className={styles.section} onClick={handleEditorClick(Editor.Photo)}>
          {editor !== Editor.Photo && (
            <>
              <div className={styles.icon}>
                <FrameIcon />
              </div>
              <div className={styles.text}>Add photos</div>
            </>
          )}
        </div>

        <div className={styles.section} onClick={handleEditorClick(Editor.Tags)}>
          {editor !== Editor.Tags ? (
            <>
              <div className={styles.icon}>
                <TagsIcon />
              </div>
              <div className={styles.text}>
                {formik.values.tags.filter((tag) => tag.active).length > 0
                  ? formik.values.tags
                      .filter((tag) => tag.active)
                      .map((tag) => tag.name)
                      .join(', ')
                  : 'Add tags'}
              </div>
            </>
          ) : (
            <div className={styles.sectionEditor}>
              <div className={styles.sectionEditorTitle}>Tags</div>
              <Autocomplete
                name={formik.values.tags.map((tag) => tag.name)}
                setName={() => {}}
                placeholder="Search by tags ..."
              />
              <div className={styles.tagWrapper}>
                {formik.values.tags.map((tag, index) => (
                  <div
                    className={classNames(styles.tag, {
                      [styles.active]: tag.active,
                      [styles.deletable]: tag.added,
                    })}
                    key={`${tag.name}-${index}`}
                    onClick={() => {
                      const nextTags = formik.values.tags.map((t) =>
                        t.name === tag.name ? { ...t, active: !t.active } : t,
                      );

                      return formik.setFieldValue('tags', nextTags);
                    }}
                  >
                    <div>{tag.name}</div>
                    {tag.added && (
                      <PlusIcon
                        onClick={(event) => {
                          event.stopPropagation();
                          return formik.setFieldValue(
                            'tags',
                            formik.values.tags.filter((t) => t.name !== tag.name),
                          );
                        }}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className={styles.section} onClick={handleEditorClick(Editor.PublishTo)}>
          {editor !== Editor.PublishTo ? (
            <>
              <div className={styles.icon}>
                <UsersIcon />
              </div>
              <div className={styles.text}>Publish to all clients</div>
            </>
          ) : (
            <div className={styles.sectionEditor}>
              <div className={styles.sectionEditorTitle}>Publish</div>
              <div>
                <div
                  className={classNames(styles.toggleOption, {
                    [styles.selected]: formik.values.publishTo === 'ALL',
                  })}
                  onClick={() => formik.setFieldValue('publishTo', 'ALL')}
                >
                  <div className={styles.circle}>
                    {formik.values.publishTo === 'ALL' && <CheckmarkIcon />}
                  </div>
                  All Clients
                </div>
              </div>

              <div>
                <div
                  className={classNames(styles.toggleOption, {
                    [styles.selected]: formik.values.publishTo === 'CLIENTS',
                  })}
                  onClick={() => formik.setFieldValue('publishTo', 'CLIENTS')}
                >
                  <div className={styles.circle}>
                    {formik.values.publishTo === 'CLIENTS' && <CheckmarkIcon />}
                  </div>
                  Specific Clients
                </div>

                {formik.values.publishTo === 'CLIENTS' && (
                  <div className={styles.searchWrapper}>
                    <Autocomplete
                      name={formik.values.publishTarget.map((target) => target)}
                      setName={() => {}}
                      placeholder="Search clients..."
                    />
                  </div>
                )}
              </div>

              <div>
                <div
                  className={classNames(styles.toggleOption, {
                    [styles.selected]: formik.values.publishTo === 'LOCATION',
                  })}
                  onClick={() => formik.setFieldValue('publishTo', 'LOCATION')}
                >
                  <div className={styles.circle}>
                    {formik.values.publishTo === 'LOCATION' && <CheckmarkIcon />}
                  </div>
                  By Location
                </div>

                {formik.values.publishTo === 'LOCATION' && (
                  <div className={styles.searchWrapper}>
                    {/* Replace with Locations (extract to component) */}
                    <Autocomplete
                      name={formik.values.publishTarget.map((target) => target)}
                      setName={() => {}}
                      placeholder="Search location..."
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className={styles.confirmEmail}>
          <Switch
            className={styles.switch}
            id="sendEmailAlert"
            checked={formik.values.sendEmailAlert}
            onChange={(checked) => formik.setFieldValue('sendEmailAlert', checked)}
          />
          <label className={styles.label} htmlFor="sendEmailAlert">
            Send Email Alert
          </label>
        </div>
      </div>
      <div className={styles.modalFooter}>
        <Button variant="secondary" onClick={onClose} className={styles.modalPostButton}>
          Post
        </Button>
      </div>
    </Modal>
  );
};
