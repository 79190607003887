import { createSelector } from 'reselect';
import { get } from 'lodash-es';
import { IDLE, PENDING } from 'settings/constants/apiState';

const localState = ({ templates: { clause } }) => clause;

export const getClauseTemplatesSelector = createSelector(
  localState,
  ({ state, meta, ...rest }) => ({
    isIdle: state === IDLE,
    isPending: state === PENDING,
    isData: !!rest?.data,
    ...rest,
  }),
);

export const getClauseTemplatesDataSelector = createSelector(localState, (state) =>
  get(state, 'data'),
);
