import { MemberInput } from '../ExternalMember/components';
import validator from 'validator';

import styles from './styles.module.scss';
import { setOptionalEmail } from 'pages/Workshop/Transactions/TransactionCreate/helpers';

export const MemberEmail = (props) => {
  const { participant, index, updateParticipantValues, fullWidth } = props;

  const onEmailChange = (value, index) => {
    const inputValue = value.trim();
    updateParticipantValues(
      {
        customEmail: inputValue,
        error:
          inputValue && !validator.isEmail(inputValue) ? 'Please enter a valid email' : undefined,
      },
      index,
    );
  };

  return (
    <div className={styles.memberEmailWrapper}>
      <div className={styles.displayInputLabelWrap}>
        <p className={styles.inputLabel}>Email</p>
        {!participant.Email
          ? setOptionalEmail(participant) && <p className={styles.inputLabelOptional}>(Optional)</p>
          : ''}
      </div>
      <MemberInput
        value={participant.customEmail}
        error={participant.error}
        placeholder=" "
        onUpdate={() => {}}
        onBlurInput={(value) => onEmailChange(value, index)}
        inputHolderClassName={styles.customName}
        className={styles.memberInput}
        disabled={!participant.name || participant.Email}
      />
    </div>
  );
};
