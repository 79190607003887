import classNames from 'classnames';
import { ReactNode, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';

import { Check, CheckBox } from 'components/Icons';
import { Divider, Input, Link, Select, SelectProps } from 'components-antd';
import { validationToast } from 'pages/FormProcess/helper';

import styles from './styles.module.scss';

export interface SelectorTagType {
  TagId: number | string;
  Name: string;
}

interface TagSelectorProps extends SelectProps {
  selectedTags: any;
  allTags: SelectorTagType[];
  handleChangeTag: (Ids) => void;
  disabled?: boolean;
  setDisabled?: (disabled: boolean) => void;
  addTag?: (tag, cb) => void;
  placeholder?: string;
  multiple?: boolean;
  suffixIcon?: ReactNode;
}

export const TagSelector = ({
  selectedTags,
  allTags,
  disabled,
  handleChangeTag,
  setDisabled,
  addTag,
  className,
  placeholder = 'Select Tags',
  multiple = true,
  suffixIcon,
  ...props
}: TagSelectorProps) => {
  const [name, setName] = useState('');

  const filteredTags: SelectorTagType[] = [];

  allTags?.forEach((tag) => {
    if (!filteredTags.some((fTag) => fTag.Name === tag.Name)) {
      filteredTags.push(tag);
    }
  });

  const handleAddTag = async () => {
    const trimName = name.trim();
    if (trimName) {
      if (filteredTags.some((tag) => tag.Name === trimName)) {
        validationToast('Tag already exists');
      } else {
        if (setDisabled) setDisabled(true);
        if (addTag) {
          addTag({ tagName: trimName }, () => {
            if (setDisabled) setDisabled(false);
            setName('');
          });
        }
      }
    }
  };

  return (
    <Select
      allowClear={true}
      suffixIcon={suffixIcon}
      onChange={handleChangeTag}
      className={classNames(styles.tagSelector, className)}
      placeholder={placeholder}
      options={filteredTags?.map((el) => ({
        label: el.Name,
        value: el.TagId,
        key: `tags-${el.TagId}`,
      }))}
      filterOption={(input, option) =>
        String((option?.label as unknown as string) || '')
          .toLowerCase()
          .includes(input.toLowerCase())
      }
      value={selectedTags}
      mode={multiple ? 'multiple' : undefined}
      menuItemSelectedIcon={
        multiple ? <CheckBox className={styles.checkBoxIcon} /> : <Check color="#04A451" />
      }
      showArrow={true}
      large={true}
      disabled={disabled}
      popupClassName={styles.popupClassname}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider className={styles.divider} />
          <div className={styles.addListOptions}>
            <div className={styles.addOptionInput}>
              <Input
                disabled={disabled}
                placeholder="Please enter tag"
                value={name}
                onChange={(e) => setName(e.target.value)}
                onPressEnter={(e) => {
                  e.stopPropagation(); // avoid closing the dropdown
                  handleAddTag();
                  // @ts-ignore
                  menu?.ref?.current?.scrollTo(filteredTags.length); // scroll last into view
                }}
              />
            </div>
            <div className={styles.addOptionBtn}>
              <Link
                disabled={disabled}
                className={styles.link}
                onClick={() => {
                  handleAddTag();
                  // @ts-ignore
                  menu?.ref?.current?.scrollTo(filteredTags.length); // scroll last into view
                }}
              >
                <PlusOutlined /> Add tag
              </Link>
            </div>
          </div>
        </>
      )}
      {...props}
    />
  );
};
