const ResetIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M4 19V15H8"
        stroke="#262626"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 5V9H16"
        stroke="#262626"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.80965 15.507C5.56908 17.064 6.81344 18.3325 8.35564 19.1217C9.89785 19.9109 11.6546 20.1781 13.3617 19.8833C15.0688 19.5884 16.6341 18.7473 17.8222 17.4865C19.0103 16.2258 19.757 14.6134 19.9501 12.8918"
        stroke="#262626"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.1904 8.49303C18.4309 6.93596 17.1866 5.66748 15.6444 4.8783C14.1022 4.08911 12.3454 3.82187 10.6383 4.11674C8.9312 4.41161 7.36593 5.25266 6.17782 6.51345C4.98971 7.77425 4.24297 9.38665 4.04986 11.1082"
        stroke="#262626"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ResetIcon;
