import PropTypes from 'prop-types';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import { Locations, Input, FormTitle, PropertyType } from 'components';
import { handleSendClientInviteForm } from 'store/effects/clientsList';
import {
  appSetInviteClientAddressesAction,
  appResetInviteClientAddressesAction,
} from 'store/actions/app';
import {
  getClientDrawerSendingInvite,
  getActiveClientTransactionInstance,
  getInvitedClientAddresses,
  getInvitedClientRole,
} from 'store/selectors/app';
import { getInvitedClientValues } from 'store/selectors/app';
import { PENDING } from 'settings/constants/apiState';
import Footer from '../../../Footer';
import { InviteHeader } from '../../../Headers';
import { schema } from './validation';
import { DatePicker } from 'components';
import { Radio } from 'components-antd';
import { Wrapper } from 'pages/OnBoardingWizard/components';
import { DashboardIcon, MessagesIcon } from './Icons';
import { Tooltip } from 'components-antd';

import styles from './styles.module.scss';
import { Add, Cross, Edit, TooltipIcon } from 'components/Icons';
import classNames from 'classnames';
import { map } from 'lodash-es';
import { Checkbox } from 'antd';
import { ClientCategory } from 'types';
import { inviteClientSteps } from 'types/inviteClient';

enum Steps {
  EnterDetails = 0,
  QueryForMoreAddresses = 1,
  ShowInvitation = 2,
}

const InviteClientRetentionMode = ({ onNext, stageIndex, onPrev }) => {
  const dispatch = useDispatch();
  const { state } = useSelector(getClientDrawerSendingInvite);
  const role = useSelector(getInvitedClientRole);
  const activeClientTransactionInstance = useSelector(getActiveClientTransactionInstance);
  const { firstName } = useSelector(getInvitedClientValues);
  const addresses = useSelector(getInvitedClientAddresses);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [internalStep, setInternalStep] = useState(Steps.EnterDetails);
  const [defaultAnswer, setDefaultAnswer] = useState('No');

  const buyerRole = role.includes(ClientCategory.Buyer);

  useEffect(() => {
    if (internalStep === Steps.EnterDetails) {
      formik.resetForm();
    }
  }, [internalStep]);

  const formik = useFormik({
    initialValues:
      Object.keys(activeClientTransactionInstance).length > 0
        ? {
            FromRetentionMode: true,
            addresses: [
              {
                address: activeClientTransactionInstance.address,
                notes: '',
                radius: 2,
                closingDate: '',
                propertyType: [],
                isPrimary: false,
              },
            ],
          }
        : InviteClientRetentionMode.initialValues,
    validateOnMount: true,
    validationSchema: schema,
    onSubmit: (values) => {
      if (internalStep === Steps.QueryForMoreAddresses) {
        if (defaultAnswer === 'Yes') {
          setInternalStep(Steps.EnterDetails);
        }
        if (defaultAnswer === 'No') {
          setInternalStep(Steps.ShowInvitation);
        }
        return;
      }
      if (internalStep === Steps.ShowInvitation) {
        if (buyerRole) {
          onNext(inviteClientSteps.ClientSearchInstance);
        } else {
          dispatch(
            handleSendClientInviteForm(
              {
                values: {
                  FromRetentionMode: InviteClientRetentionMode.initialValues.FromRetentionMode,
                },
              },
              () => {},
            ),
          );
          return;
        }
      }
      dispatch(
        appSetInviteClientAddressesAction(
          values.addresses.map(
            ({ address, closingDate, notes, radius, propertyType, isPrimary }) => ({
              address, //...formatAddress(address),
              closingDate: closingDate[0],
              neighbourhoodRadius: Number(radius),
              ...(propertyType?.length ? { propertyType } : {}),
              notes,
              isPrimary,
            }),
          ),
        ),
      );
      setInternalStep(Steps.ShowInvitation);
    },
  });

  const isPending = state === PENDING;

  // Yes NO logic
  const handleYesNo = (value) => {
    setDefaultAnswer(value);
  };

  const disabled = !formik?.values?.addresses?.[selectedIndex]?.address?.delivery_line_1;

  return (
    <form
      testid="invite_retention_form"
      className={styles.fullWidth}
      onSubmit={formik.handleSubmit}
    >
      <InviteHeader
        disabled={isPending}
        stageIndex={stageIndex}
        onPrev={() => {
          dispatch(appResetInviteClientAddressesAction());
          onPrev();
        }}
        isValid={formik.isValid}
      />
      {internalStep === Steps.EnterDetails && (
        <div className={styles.formContainer}>
          <FormTitle className={styles.titleHeading}>
            {('What is ' + firstName + "'s" + ' address?') as string}
          </FormTitle>

          <FormikProvider value={formik}>
            <FieldArray
              name={'addresses'}
              render={(arrayHelpers) => (
                <>
                  {formik?.values?.addresses?.length > 1 && (
                    <>
                      {map(formik.values.addresses, (elm, index: number) => {
                        const delivery_line_1 = elm?.address?.delivery_line_1 ?? '';
                        return (
                          <>
                            {index === selectedIndex ? null : (
                              <div className={styles.pill}>
                                <div>
                                  {formik.values.addresses[index]?.isPrimary && (
                                    <p className={styles.primary}>Primary Address</p>
                                  )}
                                  <p className={styles.address}>{delivery_line_1}</p>
                                </div>
                                <div className={styles.pillIcons}>
                                  <div
                                    className={styles.pillIcon}
                                    onClick={() => {
                                      let newIndex;
                                      formik.values.addresses.forEach((elm2, i) => {
                                        const delivery_line_1Check =
                                          elm2?.address?.delivery_line_1 ?? '';
                                        if (!delivery_line_1Check) arrayHelpers.remove(i);
                                        else if (delivery_line_1 === delivery_line_1Check)
                                          newIndex = i;
                                      });
                                      setSelectedIndex(newIndex);
                                    }}
                                  >
                                    <Edit stroke={'#747475'} size={'16'} strokeWidth={'2'} />
                                  </div>
                                  <div
                                    className={styles.pillIcon}
                                    onClick={() => {
                                      arrayHelpers.remove(index);
                                      const newIndex = formik.values.addresses
                                        .filter((_, index) => index !== selectedIndex)
                                        .findIndex((elm3) => {
                                          const delivery_line_1Check =
                                            elm3?.address?.delivery_line_1 ?? '';
                                          return delivery_line_1 === delivery_line_1Check;
                                        });
                                      setSelectedIndex(newIndex);
                                    }}
                                  >
                                    <Cross color={'#747475'} size={'16'} />
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        );
                      })}
                    </>
                  )}

                  <div
                    className={classNames(
                      {
                        [styles.disabled]: !formik.isValid,
                      },
                      styles.addContainer,
                    )}
                    onClick={() => {
                      arrayHelpers.push(InviteClientRetentionMode.initialValues.addresses[0]);
                      setSelectedIndex(formik.values.addresses.length);
                    }}
                  >
                    <Add
                      className={styles.addIcon}
                      color={!formik.isValid ? Add.GREY_CIRCLE : Add.ORANGE_CIRCLE}
                    />
                    <p className={styles.addText}>Add Another</p>
                  </div>
                  <div className={styles.propertyInformation}>
                    <Locations
                      name="listingAddress"
                      onResult={(r, preparedData) => {
                        formik.setFieldValue(
                          `addresses[${selectedIndex}].address`,
                          preparedData?.length ? preparedData[0] : [],
                        );
                      }}
                      label="Address"
                      placeholder="Enter Address"
                      testid="listing_address"
                      value={
                        formik?.values?.addresses?.[selectedIndex]?.address
                          ? [formik.values.addresses[selectedIndex]?.address]
                          : []
                      }
                      multiple={false}
                      activeInputIconClassName={styles.locationsIcon}
                      showAsterisk={true}
                      variant={Locations.ROUND}
                      placeholderClassName={styles.border}
                      searchWrapperClassName={styles.border}
                      valuesWrapperClassName={styles.border}
                      dropdownClassName={styles.dropdown}
                      rounded={true}
                      shouldFilterCityLevelResults={true}
                      shouldFilterStreetLevelResults={true}
                      allowedSmartyAddress={true}
                      showOnlyCassAddresses={true}
                      setFullCassAddress={true}
                    />
                    {!disabled && (
                      <>
                        <Checkbox
                          type="checkbox"
                          name="isPrimary"
                          className={classNames(styles.checkbox, 'mosaikCheckbox')}
                          value={formik.values?.addresses?.[selectedIndex]?.isPrimary}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            const updatedAddresses = formik.values?.addresses?.map(
                              (address, index) => ({
                                ...address,
                                isPrimary: index === selectedIndex ? isChecked : false,
                              }),
                            );
                            formik.setFieldValue('addresses', updatedAddresses);
                          }}
                          checked={formik.values?.addresses?.[selectedIndex]?.isPrimary}
                        >
                          Primary Address
                        </Checkbox>
                        <div className={styles.row}>
                          <DatePicker
                            value={formik.values?.addresses?.[selectedIndex]?.closingDate}
                            className={styles.inputContainer}
                            label="Date of Closing"
                            variant={DatePicker.ROUNDED}
                            options={{ enableTime: false }}
                            onChange={(e) => {
                              formik.setFieldValue(
                                `addresses[${selectedIndex}].closingDate`,
                                e.target.value,
                              );
                            }}
                            allowTypedInput={true}
                            allowPlugin={false}
                            format={'m/d/Y'}
                          />
                          <Input
                            disabled={isPending}
                            className={styles.inputContainer}
                            name={`addresses[${selectedIndex}].radius`}
                            isNumberFormat={true}
                            minNumber={0.1}
                            maxNumber={10}
                            variant={Input.LIGHT_ROUND}
                            onChange={formik.handleChange}
                            value={formik.values?.addresses?.[selectedIndex]?.radius}
                            label="Neighborhood Activity Radius"
                            placeholder="0"
                            icon={<p className={styles.iconRight}>Miles</p>}
                            labelRightIcon={
                              <Tooltip
                                placement="top"
                                overlayClassName={classNames(styles.radiusTooltip, 'mosaikTooltip')}
                                title={
                                  'This is the radius that will be used to provide neighborhood activity updates to the client (for example: nearby sales, price reductions, new listings, etc.).'
                                }
                              >
                                <span>
                                  <TooltipIcon
                                    className={styles.tooltipIcon}
                                    size={'17'}
                                    color={'#AAABAB'}
                                  />
                                </span>
                              </Tooltip>
                            }
                          />
                        </div>
                        <PropertyType
                          onChange={(value) => {
                            formik.setFieldValue(`addresses[${selectedIndex}].propertyType`, value);
                          }}
                          value={formik.values.addresses?.[selectedIndex]?.propertyType}
                          type="dropdown"
                          label={'Property Type (optional)'}
                        />
                        <Input
                          disabled={isPending}
                          className={styles.inputContainer}
                          name={`addresses[${selectedIndex}].notes`}
                          variant={Input.LIGHT_ROUND}
                          onChange={formik.handleChange}
                          value={formik.values?.addresses?.[selectedIndex]?.notes}
                          label="Notes"
                          placeholder=""
                          textArea={true}
                        />
                      </>
                    )}
                  </div>
                </>
              )}
            />
          </FormikProvider>
        </div>
      )}
      {internalStep === Steps.QueryForMoreAddresses && (
        <div className={styles.formContainer}>
          <FormTitle className={styles.titleHeading}>Do you want to add another address?</FormTitle>
          <div className={styles.contactInformation}>
            <Radio.Group
              className={styles.yesNoInput}
              disabled={false}
              value={defaultAnswer}
              onChange={(e) => {
                handleYesNo(e.target.value);
              }}
              buttonStyle="solid"
            >
              <Radio.Button className={styles.yesRadioBtn} value="Yes">
                Yes
              </Radio.Button>
              <Radio.Button className={styles.noRadioBtn} value="No">
                No
              </Radio.Button>
            </Radio.Group>
          </div>
        </div>
      )}
      {internalStep === Steps.ShowInvitation && (
        <div className={styles.formContainer}>
          <FormTitle className={styles.titleHeading}>
            {'Once ' + firstName + "'s" + ' invitation is accepted:'}
          </FormTitle>
          <div className={styles.invitationInformation}>
            <Wrapper className={styles.addresses}>
              <DashboardIcon className={styles.icon} />
              <span className={styles.title}>
                The following properties will be added to the retention portfolio and homeowner
                dashboards will be available to {firstName}:
              </span>
              <ul>
                {addresses.map(({ address }) => {
                  const { delivery_line_1, components } = address;

                  return (
                    <li>
                      <b>{delivery_line_1}</b>
                      {', '}
                      {components?.city_name && components?.city_name + ', '}
                      {components?.state_abbreviation ?? ''} {components?.zipcode ?? ''}
                    </li>
                  );
                })}
              </ul>
            </Wrapper>
            <Wrapper className={styles.infoMessage}>
              <MessagesIcon className={styles.icon} />
              <span className={styles.title}>You can enable KITs for {firstName} from Radar.</span>
            </Wrapper>
          </div>
        </div>
      )}
      <Footer
        disabled={isPending}
        buttonTitle={
          buyerRole
            ? 'Continue'
            : internalStep === Steps.ShowInvitation
            ? 'Send Invitation'
            : 'Continue'
        }
        isValid={formik.isValid || internalStep > Steps.EnterDetails}
        testid="invite_seller_footer"
        className={styles.footer}
      />
    </form>
  );
};

InviteClientRetentionMode.initialValues = {
  FromRetentionMode: true,
  addresses: [
    {
      address: null,
      notes: '',
      radius: 2,
      closingDate: '',
      propertyType: [],
      isPrimary: false,
    },
  ],
};

InviteClientRetentionMode.propTypes = {
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  stageIndex: PropTypes.number,
};

InviteClientRetentionMode.defaultProps = {
  onNext: () => {},
  onPrev: () => {},
  stageIndex: 1,
};

export default InviteClientRetentionMode;
