import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import Status from './Status';
import Options from './Options';
import { getTransactionAccessByTaskSelector } from 'store/selectors/transaction';

import styles from './styles.module.scss';

const Actions = (props) => {
  const { className, transactionId, dueDate, fullAccess } = props;
  const { hasFullAccess } = useSelector(getTransactionAccessByTaskSelector);

  return (
    <div className={classNames(styles.taskActions, className)}>
      <Status fullAccess={fullAccess || hasFullAccess(transactionId)} dueDate={dueDate} />
      <Options />
    </div>
  );
};

Actions.propTypes = {
  className: PropTypes.string,
};

Actions.defaultProps = {
  className: '',
};

export default Actions;
