import classnames from 'classnames';

import { Button } from 'components-antd';

import styles from './styles.module.scss';

interface FormSelectionFooterProps {
  className?: string;
  selectedForms: number;
  handleContinueFormUpdates: () => void;
}

export const FormSelectionFooter = ({
  className,
  selectedForms,
  handleContinueFormUpdates,
}: FormSelectionFooterProps) => (
  <div className={classnames(styles.formSelectionFooterContainer, className)}>
    <div className={classnames(styles.content)}>
      <div>
        {selectedForms} Form{selectedForms === 1 ? '' : 's'} Selected
      </div>
      <Button
        disabled={!selectedForms}
        className={classnames(styles.continueBtn, { [styles.disabledBtn]: !selectedForms })}
        variant="secondary"
        onClick={handleContinueFormUpdates}
      >
        Continue
      </Button>
    </div>
  </div>
);
