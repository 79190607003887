export const getLogoPlaceholder = (businessName) => businessName?.substring(0, 1).toUpperCase();

export const prepareFormValues = (partnerData, serviceTags) => {
  const values = {
    ...partnerData,
  };

  const { Radius, AreasOfOperation, ...rest } = values;

  return {
    ...rest,
    locations: [{ AreasOfOperation: '', Radius: '' }],
    ...(AreasOfOperation?.length > 0 && {
      locations: AreasOfOperation.map((area) => {
        const { Radius: areasRadius, ...rest } = area;
        return {
          AreasOfOperation: rest,
          Radius: areasRadius || Radius || '',
        };
      }),
    }),
    PartnerServices: serviceTags.map(({ TagId }) => TagId),
  };
};

export const getServiceTags = (user) => {
  return user &&
    user.ThirdParty &&
    user.ThirdParty.Partner &&
    user.ThirdParty.Partner.PartnerTag &&
    user.ThirdParty.Partner.PartnerTag
    ? user.ThirdParty.Partner.PartnerTag
    : [];
};
