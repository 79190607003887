import React from 'react';
import PropTypes from 'prop-types';

const Archive = ({ className, onClick, color, size, strokeWidth }) => (
  <div testid="archive_icon" onClick={onClick} className={className}>
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.9775 12.9435L19.0125 5.076C19.0125 5.07526 19.0125 5.07526 19.0125 5.07449C18.7598 4.07324 17.8628 3.375 16.8307 3.375H16.83H7.17076H7.17002C6.13801 3.375 5.24104 4.07324 4.98826 5.076L3.02324 12.9435C3.00749 13.0028 3 13.0635 3 13.125V18.375C3 19.6155 4.00951 20.625 5.25 20.625H18.75C19.9905 20.625 21 19.6155 21 18.375V13.125C21 13.0635 20.9925 13.0028 20.9775 12.9435ZM6.4425 5.4405C6.52649 5.1075 6.82574 4.87501 7.16999 4.87501H16.83C17.1735 4.87501 17.472 5.1075 17.5567 5.43976L18.6315 9.7425H15C14.586 9.7425 14.25 10.0785 14.25 10.4925C14.25 11.733 13.2405 12.7425 12 12.7425C10.7595 12.7425 9.75 11.733 9.75 10.4925C9.75 10.0785 9.41401 9.7425 9.00001 9.7425H5.36774L6.4425 5.4405ZM19.5 18.375C19.5 18.7882 19.1632 19.125 18.75 19.125H5.25C4.83674 19.125 4.50001 18.7882 4.50001 18.375V13.2172L4.9935 11.2425H8.32575C8.67376 12.9517 10.1895 14.2425 12 14.2425C13.8105 14.2425 15.3262 12.9517 15.6742 11.2425H19.0065L19.5 13.2172V18.375Z"
        fill={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  </div>
);

Archive.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.string,
  size: PropTypes.string,
  strokeWidth: PropTypes.string,
};

Archive.defaultProps = {
  className: '',
  onClick: () => {},
  color: '#303030',
  size: '24',
  strokeWidth: '1',
};

export default Archive;
