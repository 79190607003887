import React from 'react';

export const LocationIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <ellipse cx="10" cy="9.1665" rx="2.5" ry="2.5" stroke="currentColor" strokeWidth="2" />
      <path
        d="M16.6673 9.16667C16.6673 12.8846 12.9812 16.3721 11.0908 17.9162C10.4494 18.4401 9.55192 18.4401 8.91046 17.9162C7.02011 16.3721 3.33398 12.8846 3.33398 9.16667C3.33398 5.48477 6.31875 2.5 10.0007 2.5C13.6825 2.5 16.6673 5.48477 16.6673 9.16667Z"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
};
