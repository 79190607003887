import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { Badge, Popover } from 'components-antd';
import { openFeedFilterDrawerEffect } from 'store/effects';
import Icon from 'pages/Properties/Feed/Icons';
import { getFeedPrefsFilterSelector } from 'store/selectors/feed';

import styles from './styles.module.scss';

const Filter = ({ className, onClick, count }) => {
  const search = useSelector(getFeedPrefsFilterSelector);
  const dispatch = useDispatch();

  const onClickHandler = () => {
    dispatch(openFeedFilterDrawerEffect({ open: true }));
  };

  const isFiltered = !!Object.keys(search || {}).filter((key) => !!search[key]).length;

  const popoverContent = () => <span>Filter</span>;

  return (
    <Popover
      content={popoverContent}
      placement="top"
      overlayClassName={classNames('mosaikTooltip')}
      getPopupContainer={(triggerNode) => triggerNode}
      arrow={true}
    >
      <div
        onClick={onClick ? onClick : onClickHandler}
        className={classNames({ [styles.active]: isFiltered }, styles.filter, className)}
      >
        <Badge count={count}>
          <Icon testid="filter_icon" variant={Icon.FILTER} />
        </Badge>
      </div>
    </Popover>
  );
};

Filter.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

Filter.defaultProps = {
  className: '',
};

export default Filter;
