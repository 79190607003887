import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import fileDownload from 'js-file-download';
import axios from 'axios';

import { PdfViewModal } from 'components';
import { Dropdown, Menu } from 'antd';
import Icon from 'pages/Quotes/components/QuoteDetails/Icons';
import Actions from './Actions';
import ActionIcons from '../Content/ThirdPartyQuote/Details/Actions';
import { Icons } from 'pages/Workshop/Icons';

import styles from './styles.module.scss';
import { dispatch } from 'store';
import { previewDocumentEffect } from 'store/effects';

const Attachment = (props) => {
  const {
    file,
    className,
    variant,
    truncateLength,
    fullDate,
    isActions,
    isActionIcons,
    deletable,
    quoteId,
    noText,
    fileNameClassName,
    dateClassName,
    isShowMenu,
    isDownloadUsingUUID,
  } = props;
  const { Filename, UploadDate } = file || {};
  const [expand, setExpand] = useState(false);

  const getDate = () => {
    const momentDate = moment(UploadDate);
    const formattedDate = momentDate.format('M/D/YY');
    const formattedTime = momentDate.format('h:mm A');

    if (!fullDate) {
      return `${formattedDate} at ${formattedTime}`;
    }

    if (variant === Attachment.RECEIVED) {
      return `${noText ? '' : 'Received on '}${formattedDate} at ${formattedTime}`;
    }

    if (variant === Attachment.SUBMITTED) {
      return `Submitted on ${formattedDate} at ${formattedTime}`;
    }
  };

  const getFilename = () => {
    if (truncateLength) {
      if (typeof Filename === 'string') {
        const truncatedFileName = Filename.split('').slice(-truncateLength).join('');
        if (Filename?.length > truncateLength) {
          return `...${truncatedFileName}`;
        }
        return truncatedFileName;
      }
      return '';
    }

    return Filename;
  };

  const onDownload = () => {
    if (isDownloadUsingUUID) {
      onDownloadUsingUUID();
    } else {
      downloadFile();
    }
  };
  const downloadFile = () => {
    axios.get(file?.Url, { responseType: 'blob' }).then((response) => {
      fileDownload(response.data, getFilename());
    });
  };

  const onDownloadUsingUUID = () => {
    if (file.DocumentVaultUUID) {
      dispatch(
        previewDocumentEffect({ DocumentVaultUUID: file.DocumentVaultUUID }, {}, (err, resp) => {
          if (!err) fileDownload(resp.data, resp.headers['file-name']);
        }),
      );
    }
  };

  const onExpand = () => {
    setExpand(true);
  };

  const menu = (
    <Menu className={styles.preApprovalMenu}>
      <Menu.Item className={styles.action} onClick={onExpand}>
        Preview
      </Menu.Item>
      <Menu.Item className={styles.action} onClick={onDownload}>
        Download
      </Menu.Item>
    </Menu>
  );

  return (
    <div
      className={classNames(
        styles.attachment,
        { [styles.submitted]: variant === Attachment.SUBMITTED },
        className,
      )}
    >
      <Icon className={styles.icon} variant={Icon.FILE} />
      <div className={styles.info}>
        <div className={classNames(styles.filename, fileNameClassName)}>
          <span>{getFilename()}</span>
        </div>
        <div className={classNames(styles.date, dateClassName)}>
          <span>{getDate()}</span>
        </div>
      </div>
      {isActions && <Actions file={file} deletable={deletable} quoteId={quoteId} />}
      {isActionIcons && <ActionIcons onDownload={onDownload} onExpand={onExpand} />}
      {isShowMenu && (
        <Dropdown overlay={menu} className={styles.dropdown} placement={'bottomRight'}>
          <a onClick={(e) => e.preventDefault()}>
            <Icons className={styles.moreIcon} variant="more" />
          </a>
        </Dropdown>
      )}
      <PdfViewModal isOpen={expand} onClose={() => setExpand(false)} file={file} />
    </div>
  );
};

Attachment.RECEIVED = 'received';
Attachment.SUBMITTED = 'submitted';

Attachment.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  file: PropTypes.shape({
    ContentType: PropTypes.string,
    Url: PropTypes.string,
    UploadDate: PropTypes.string,
    Filename: PropTypes.string,
  }).isRequired,
  truncateLength: PropTypes.number,
  fullDate: PropTypes.bool,
  isActions: PropTypes.bool,
  isActionIcons: PropTypes.bool,
  isShowMenu: PropTypes.bool,
  deletable: PropTypes.bool,
  noText: PropTypes.bool,
  fileNameClassName: PropTypes.string,
  dateClassName: PropTypes.string,
  quoteId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isDownloadUsingUUID: PropTypes.bool,
};

Attachment.defaultProps = {
  className: '',
  variant: Attachment.RECEIVED,
  truncateLength: undefined,
  fullDate: true,
  isActions: true,
  isActionIcons: false,
  isShowMenu: false,
  deletable: false,
  quoteId: undefined,
  noText: false,
  fileNameClassName: undefined,
  dateClassName: undefined,
  isDownloadUsingUUID: false,
};

export default Attachment;
