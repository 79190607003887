import fileDownload from 'js-file-download';
import JSZip from 'jszip';
import { getFilesName } from 'pages/FormProcess/DynamicForm/DynamicHeader/helper';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { previewDocumentEffect } from 'store/effects';
import {
  previewCombinedFileEffect,
  previewExternalCombinedFileEffect,
} from 'store/effects/formProcess';

export const useDownloadPDF = (props) => {
  const {
    anonymousUserDetails = {},
    isCompleted,
    downloadedFileName = '',
    formProcessPublicId,
  } = props;

  const [downloading, setDownloading] = useState(false);

  const dispatch = useDispatch();

  const getDocumentDetails = (documents) => {
    const { link, DocumentLink, formName, FormName, DocumentName } = documents[0];
    const documentLink = link || DocumentLink;
    const documentName = formName || FormName || DocumentName || '';

    return { documentLink, documentName };
  };

  const prepareDownloadedFileName = (documentName, documents) => {
    if (downloadedFileName) return downloadedFileName;

    let fileName = `${`Completed_with_Mosaik_${documentName}`}`;
    fileName = isCompleted ? fileName : `${getFilesName(documents)}`;

    return fileName;
  };

  const downloadCombinedFile = (formProcessId, documents) => {
    if (documents.length > 1) {
      setDownloading(true);

      const callback = (err, resp) => {
        if (!err) {
          const { documentName } = getDocumentDetails(documents);

          let fileName = `${prepareDownloadedFileName(documentName, documents)}.pdf`;

          fileDownload(resp.data, fileName);

          setDownloading(false);
        } else {
          setDownloading(false);
        }
      };

      if (anonymousUserDetails.token) {
        dispatch(
          previewExternalCombinedFileEffect({ token: anonymousUserDetails.token }, callback),
        );
      } else {
        dispatch(previewCombinedFileEffect({ formProcessId, isCompleted }, callback));
      }
    } else {
      const { documentLink, documentName } = getDocumentDetails(documents);

      downloadSingleFile(documentLink, documentName);
    }
  };

  const downloadAllAsZip = async (documentsList) => {
    setDownloading(true);
    const zip = new JSZip();
    const files = {};

    try {
      const promiseResults = await Promise.all(
        documentsList?.map(async (doc) => {
          const result = await dispatch(
            previewDocumentEffect(
              {
                DocumentVaultUUID: doc.DocumentLink || doc.link,
                ...anonymousUserDetails,
                formProcessPublicId: !isCompleted ? formProcessPublicId : '',
                isDownloading: !isCompleted ? true : false,
              },
              {},
            ),
          );

          const { formName, FormName, DocumentName } = doc;
          result.documentName = formName || FormName || DocumentName || '';

          return result;
        }),
      );

      promiseResults.forEach((result) => {
        let name = (result.documentName || result.headers['file-name']).split('.pdf')[0];
        const isExist = files[name];

        let documentName = files[name] ? `${name} (${files[name].length})` : name;

        if (isExist) {
          files[name].push(name);
        } else {
          files[name] = [name];
        }

        zip.file(`${documentName}.pdf`, result.data, { base64: true });
      });

      const { documentName } = getDocumentDetails(documentsList);

      const zipBlob = await zip.generateAsync({ type: 'blob' });

      let fileName = `${prepareDownloadedFileName(documentName, documentsList)}.zip`;
      fileDownload(zipBlob, fileName);
      setDownloading(false);
    } catch (err) {
      setDownloading(false);
    }
  };

  const downloadSingleFile = (documentLink, formName = '') => {
    setDownloading(true);
    dispatch(
      previewDocumentEffect(
        {
          DocumentVaultUUID: documentLink,
          ...anonymousUserDetails,
          formProcessPublicId: !isCompleted ? formProcessPublicId : '',
          isDownloading: !isCompleted ? true : false,
        },
        {},
        (err, resp) => {
          if (!err) {
            let fileName = formName || resp.headers['file-name'];
            fileName = fileName.includes('.pdf') ? fileName : `${fileName}.pdf`;
            fileDownload(resp.data, fileName);
          }

          setDownloading(false);
        },
      ),
    );
  };

  const downloadFiles = (documents) => {
    if (documents.length > 1) {
      downloadAllAsZip(documents);
    } else {
      const { documentLink, documentName } = getDocumentDetails(documents);
      downloadSingleFile(documentLink, documentName);
    }
  };

  return { downloading, downloadCombinedFile, downloadFiles, downloadSingleFile };
};
