import Expand from '../../../../../components/Icons/Expand';
import { Map } from 'components';
import styles from './styles.module.scss';

const ListingDetailMap = ({ onExpand, options }) => {
  const { zoom, center, markers } = options;

  return (
    <div className={styles.mapContainer}>
      <Map
        markersMap={markers}
        className={styles.map}
        controlsClassName={styles.mapControls}
        zoom={zoom}
        center={center}
        controls={{
          zoom: false,
        }}
      />
      <Expand className={styles.mapContainerExpand} onClick={() => onExpand && onExpand()} />
    </div>
  );
};

export default ListingDetailMap;
