import { sortDetails } from 'pages/FormProcess/helper';
import { routes } from 'settings/navigation/routes';

export const prepareVoidFormData = (formDetails) => {
  const {
    clientDetails,
    client,
    signatories,
    transactionId,
    address,
    copyRecipients = [],
    updateRecipients = [],
    propertyAddress,
  } = formDetails;

  const sortedSignatories = sortDetails(signatories, 'order');
  const modifiedFormRoles = sortedSignatories.map((item, index) => ({
    RoleId: index + 1,
    Id: index + 1,
    UserType: 'Agent',
    Name: '',
  }));

  let modifiedSignatories = {};
  modifiedFormRoles.forEach((item, index) => {
    const signatoryItem = signatories[index];
    modifiedSignatories[item.RoleId] = {
      Email: signatoryItem.email,
      FormRole: item.RoleId,
      Name: signatoryItem.name,
      UserId: signatoryItem.userId,
      SignatureSequence: index + 1,
      OldSignatoryEmail: signatoryItem.email,
      IsReplaced: false,
    };
  });

  let modifiedRecipients = copyRecipients.map((recipient) => ({
    type: 0,
    id: recipient.Id ? recipient.Id.toString() : recipient.Email,
  }));

  let modifiedUpdatedRecipients = updateRecipients.map((recipient) => ({
    type: 1,
    id: recipient.Id ? recipient.Id.toString() : recipient.Email,
  }));

  let clientIds = clientDetails.map(({ userId }) => userId);

  return {
    formRoles: modifiedFormRoles,
    signatories: modifiedSignatories,
    copyRecipients: [...modifiedRecipients, ...modifiedUpdatedRecipients],
    clientIds,
    clientName: client,

    ...(transactionId && { transactionId, clientAddress: address }),
    ...(!transactionId && propertyAddress && { address: propertyAddress }),
  };
};

export const getRedirectionLink = (state, isProject) => {
  if (state?.clientId) return `${routes.clientProfile}/${state.clientId}`;

  if (state?.transactionId || state?.propertyTransactionId) {
    let documentLink = isProject ? routes.projectDocuments : routes.transactionDocuments;
    documentLink = state?.propertyTransactionId ? routes.transactionTasks : documentLink;
    const transactionId = state?.propertyTransactionId || state?.transactionId;

    return documentLink.replace(':id', String(transactionId));
  } else {
    return routes.workshopForms;
  }
};
