import React from 'react';

import { ReminderCard, ValuationCard } from './components';

import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { getValuationDataSelector } from 'store/selectors/intel';

export const PropertyTab: React.FC<any> = ({ params }) => {
  const { data: valuationData, isPending: isPendingValuationData } =
    useSelector(getValuationDataSelector);

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.leftWrapper}>
        <ValuationCard
          valuationData={valuationData ?? []}
          isPending={isPendingValuationData}
          params={params}
        />
      </div>
      <div className={styles.rightWrapper}>
        <ReminderCard />
      </div>
    </div>
  );
};
