export const transactionTaskCategories = {
  Financing: 'Financing',
  HomeInspection: 'Home Inspection',
  Title: 'Title',
};

export const tasksStatusesIds = {
  new: 'New',
  inProgress: 'InProgress',
  stuck: 'Stuck',
  done: 'Done',
  overdue: 'Overdue',
  na: 'N/A',
};

export const taskStatusesExcludingDone = (includeDoneTasks = false) => {
  if (includeDoneTasks) {
    const { na, ...rest } = tasksStatusesIds;
    return Object.values(rest);
  }
  const { done, na, ...rest } = tasksStatusesIds;
  return Object.values(rest);
};

export const mapTasksStatuses = {
  [tasksStatusesIds.new]: 'New',
  [tasksStatusesIds.inProgress]: 'In Progress',
  [tasksStatusesIds.stuck]: 'Stuck',
  [tasksStatusesIds.done]: 'Done',
  [tasksStatusesIds.overdue]: 'Overdue',
  [tasksStatusesIds.na]: 'N/A',
};

export const tasksStatusesOptions = [
  { label: mapTasksStatuses[tasksStatusesIds.new], value: tasksStatusesIds.new },
  { label: mapTasksStatuses[tasksStatusesIds.inProgress], value: tasksStatusesIds.inProgress },
  { label: mapTasksStatuses[tasksStatusesIds.stuck], value: tasksStatusesIds.stuck },
  { label: mapTasksStatuses[tasksStatusesIds.done], value: tasksStatusesIds.done },
  { label: mapTasksStatuses[tasksStatusesIds.overdue], value: tasksStatusesIds.overdue },
  { label: mapTasksStatuses[tasksStatusesIds.na], value: tasksStatusesIds.na },
];

export const taskFilters = {
  myTasks: 'My Tasks',
  tasksIAssigned: 'Tasks I Assigned',
  teamTasks: 'Team Tasks',
  clientTasks: 'Client Tasks',
  collaboratorTasks: 'Collaborator Tasks',
  campaignOnly: 'Campaign Tasks',
  allTasks: 'All Tasks',
};
