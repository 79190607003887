import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import styles from './styles.module.scss';
import { SearchFieldWithDebounce } from 'components';
import { resetTeamSearchEffect, searchTeamEffect } from 'store/effects';

const Header = ({ title, isPending }) => (
  <div testid="header" className={styles.header}>
    <div>{title}</div>
    <div className={styles.headerButtons}>
      <div className={styles.searchWrapper}>
        <SearchFieldWithDebounce
          className={styles.inputWrap}
          classNameInput={styles.searchInput}
          iconClassName={styles.icon}
          resetAction={resetTeamSearchEffect}
          sendAction={searchTeamEffect}
          isLoading={isPending}
          placeholder="Search"
          testid="search"
          isTransactionAggregate={true}
          isExpandedVersion
        />
      </div>
    </div>
  </div>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
  isPending: PropTypes.bool,
};

Header.defaultProps = {
  title: '',
  isPending: false,
};

export default Header;
