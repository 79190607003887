import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Avatar } from 'components';
import { AutoComplete, Option } from 'components-antd';
import { caseInsensitiveFilter, convertNameToAvatarPlaceholder } from 'helpers';

import styles from './styles.module.scss';

export type ValueObject = {
  Id?: number;
  id?: number;
  name?: string;
  role?: string;
  avatarUrl?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  customName?: string;
  customEmail?: string;
  error?: string;
  invite?: boolean;
  roleId?: number;
  tagRole?: string;
  dropdownContainer?: string;
  transactionAccess?: boolean;
  isContact?: boolean;
  contactId?: number;
  userId?: number;
};

export type TransactionUserObject = {
  Id: number;
  id: number;
  email: string;
  avatarUrl: string;
  name: string;
  role: string;
  phone: string | null;
  FirstName: string;
  LastName: string;
  Email: string;
  isContact: boolean;
  contactId?: number;
  userId?: number;
};

interface ParticipantInputViewProps {
  transactionUsers: TransactionUserObject[];
  className?: string;
  value: ValueObject;
  onChange?: (...args) => void;
  onBlur?: (...args) => void;
  disabled?: boolean;
  dropdownContainer?: string;
  placeholder?: string;
  allowExternalMember?: boolean;
  onAddExternalParticipant?: (...args) => void;
  isClient?: boolean;
}

const ParticipantInputView = ({
  transactionUsers = [],
  className,
  dropdownContainer,
  value,
  onChange,
  onBlur,
  disabled = false,
  placeholder = 'Enter name',
  allowExternalMember = false,
  onAddExternalParticipant,
  isClient,
}: ParticipantInputViewProps) => {
  const [isSearch, setIsSearch] = useState(false);
  const [isExternalMember, setIsExternalMember] = useState(false);
  const [name, setName] = useState('');

  const getSelectedParticipant = (participantId?: number) =>
    transactionUsers.find((participant) => participant.id === participantId);

  useEffect(() => {
    if (value.id) {
      setName(value?.name || '');
    } else {
      setName(value?.customName || '');
    }
  }, [value]);

  const handleSelect = (value) => {
    const selectedParticipant: ValueObject = getSelectedParticipant(value) || {};
    const { userId, contactId, ...restSelectedParticipant } = selectedParticipant;
    onChange &&
      onChange({
        value,
        ...restSelectedParticipant,
        ...(userId
          ? { UserId: userId, ContactId: '', isContact: false }
          : contactId
          ? { ContactId: contactId, UserId: '', isContact: true }
          : {}),
      });
    setIsSearch(false);
  };

  const handleBlur = () => {
    const selectedParticipant = getSelectedParticipant(value?.id);

    if (!name && value?.id) {
      onBlur && onBlur('');
    } else if (name !== selectedParticipant?.name) {
      isClient && onBlur && onBlur(name);

      !isClient && setName(value?.name || '');
    }

    setIsSearch(false);
  };

  const hasPariticpant = (searchTerm) => {
    if (searchTerm) {
      return Boolean(
        transactionUsers?.filter((item) =>
          item.name?.toLowerCase().includes(searchTerm.toLowerCase()),
        ).length,
      );
    }

    return false;
  };

  return (
    <div className={className}>
      <AutoComplete
        disabled={disabled}
        value={name}
        onChange={(val) => {
          setName(val);
          if (typeof val === 'string' && !val.trim().length && isExternalMember) {
            setIsExternalMember(false);
          }
        }}
        open={isSearch || isExternalMember}
        popupClassName={classNames(styles.autoCompleteDropdown, dropdownContainer, {
          [styles.noMemberAutoComplete]: isExternalMember,
        })}
        placeholder={placeholder}
        filterOption={(inputValue, option) => {
          const optionFound = caseInsensitiveFilter(inputValue, option?.label as string);

          return optionFound || isExternalMember;
        }}
        onSearch={(val) => {
          setIsSearch(!!val.trim());
          if (val.trim().length && allowExternalMember && !hasPariticpant(val)) {
            !isExternalMember && setIsExternalMember(true);
          } else if (isExternalMember) {
            setIsExternalMember(false);
          }
        }}
        onSelect={handleSelect}
        onBlur={handleBlur}
      >
        {isExternalMember ? (
          <Option label={'No Member Found'} value={''} className={styles.noMemberOption}>
            <div className={classNames(styles.autoCompleteOption)}>
              <div className={styles.noResultsText}>No results found.</div>
              <div
                className={styles.addMemberText}
                onClick={(e) => {
                  e.stopPropagation();
                  onAddExternalParticipant?.(name);
                }}
              >
                Add &quot;{name}&quot;{' '}
              </div>
            </div>
          </Option>
        ) : (
          transactionUsers?.map((member, index) => {
            const id = member.id;
            return (
              <Option key={index} label={member.name} value={id}>
                <div className={classNames(styles.autoCompleteOption)}>
                  <Avatar
                    avatarClassName={styles.avatar}
                    src={member.avatarUrl}
                    placeholder={
                      <div className={styles.avatarPlaceholder}>
                        {convertNameToAvatarPlaceholder(`${member.name}`)}
                      </div>
                    }
                  />
                  <div className={classNames(styles.userContainer)}>
                    <div className={classNames(styles.name)}>{member.name}</div>
                    <div className={classNames(styles.email)}>{member.email}</div>
                  </div>
                </div>
              </Option>
            );
          })
        )}
      </AutoComplete>
    </div>
  );
};

export default ParticipantInputView;
