import PropTypes from 'prop-types';

export const Icons = ({ testid, className, variant, onClick, color, svgClass }) => {
  const renderIcon = () => {
    switch (variant) {
      case Icons.SIGNALS: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
          >
            <path
              d="M47 28.0002H37.7808C37.3219 28.0002 36.9219 28.3125 36.8106 28.7576L33.0786 43.6857C32.8198 44.7207 31.3371 44.6854 31.1279 43.6393L24.8722 12.3608C24.6629 11.3147 23.1802 11.2794 22.9214 12.3143L19.1894 27.2427C19.0781 27.6879 18.6781 28.0002 18.2192 28.0002H9"
              stroke="#51BFE1"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }

      case Icons.CONDO: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M15 20.75V4.25H3.75V20.75H15ZM15 20.75H20.9982V9.5L14.9982 8"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.5 20.75V17H11.25V20.75"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.5 7.45C6.5 7.20147 6.69188 7 6.92857 7H7.78571C8.02241 7 8.21429 7.20147 8.21429 7.45V8.35C8.21429 8.59853 8.02241 8.8 7.78571 8.8H6.92857C6.69188 8.8 6.5 8.59853 6.5 8.35V7.45Z"
              fill="#515151"
            />
            <path
              d="M6.5 10.45C6.5 10.2015 6.69188 10 6.92857 10H7.78571C8.02241 10 8.21429 10.2015 8.21429 10.45V11.35C8.21429 11.5985 8.02241 11.8 7.78571 11.8H6.92857C6.69188 11.8 6.5 11.5985 6.5 11.35V10.45Z"
              fill="#515151"
            />
            <path
              d="M6.5 13.45C6.5 13.2015 6.69188 13 6.92857 13H7.78571C8.02241 13 8.21429 13.2015 8.21429 13.45V14.35C8.21429 14.5985 8.02241 14.8 7.78571 14.8H6.92857C6.69188 14.8 6.5 14.5985 6.5 14.35V13.45Z"
              fill="#515151"
            />
            <path
              d="M10.5 7.45C10.5 7.20147 10.6919 7 10.9286 7H11.7857C12.0224 7 12.2143 7.20147 12.2143 7.45V8.35C12.2143 8.59853 12.0224 8.8 11.7857 8.8H10.9286C10.6919 8.8 10.5 8.59853 10.5 8.35V7.45Z"
              fill="#515151"
            />
            <path
              d="M10.5 10.45C10.5 10.2015 10.6919 10 10.9286 10H11.7857C12.0224 10 12.2143 10.2015 12.2143 10.45V11.35C12.2143 11.5985 12.0224 11.8 11.7857 11.8H10.9286C10.6919 11.8 10.5 11.5985 10.5 11.35V10.45Z"
              fill="#515151"
            />
            <path
              d="M10.5 13.45C10.5 13.2015 10.6919 13 10.9286 13H11.7857C12.0224 13 12.2143 13.2015 12.2143 13.45V14.35C12.2143 14.5985 12.0224 14.8 11.7857 14.8H10.9286C10.6919 14.8 10.5 14.5985 10.5 14.35V13.45Z"
              fill="#515151"
            />
            <path
              d="M17 11.45C17 11.2015 17.1919 11 17.4286 11H18.2857C18.5224 11 18.7143 11.2015 18.7143 11.45V12.35C18.7143 12.5985 18.5224 12.8 18.2857 12.8H17.4286C17.1919 12.8 17 12.5985 17 12.35V11.45Z"
              fill="#515151"
            />
            <path
              d="M17 14.45C17 14.2015 17.1919 14 17.4286 14H18.2857C18.5224 14 18.7143 14.2015 18.7143 14.45V15.35C18.7143 15.5985 18.5224 15.8 18.2857 15.8H17.4286C17.1919 15.8 17 15.5985 17 15.35V14.45Z"
              fill="#515151"
            />
            <path
              d="M17 17.45C17 17.2015 17.1919 17 17.4286 17H18.2857C18.5224 17 18.7143 17.2015 18.7143 17.45V18.35C18.7143 18.5985 18.5224 18.8 18.2857 18.8H17.4286C17.1919 18.8 17 18.5985 17 18.35V17.45Z"
              fill="#515151"
            />
          </svg>
        );
      }

      case Icons.UP_ARROW: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M8 13.5L8 3"
              stroke="#04A451"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.5 7.5L8 3L12.5 7.5"
              stroke="#04A451"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }

      case Icons.INTEL: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="57"
            height="56"
            viewBox="0 0 57 56"
            fill="none"
          >
            <path
              d="M7.66675 28H10.0001M28.6667 7V9.33333M47.3334 28H49.6667M13.7334 13.0667L15.3667 14.7M43.6001 13.0667L41.9667 14.7M23.3001 39.6667H34.0334M21.6667 37.3333C19.7078 35.8642 18.2609 33.8159 17.5308 31.4786C16.8007 29.1414 16.8245 26.6336 17.5988 24.3107C18.3731 21.9877 19.8587 19.9672 21.8451 18.5355C23.8316 17.1038 26.2181 16.3333 28.6667 16.3333C31.1154 16.3333 33.5019 17.1038 35.4884 18.5355C37.4748 19.9672 38.9604 21.9877 39.7347 24.3107C40.509 26.6336 40.5328 29.1414 39.8027 31.4786C39.0726 33.8159 37.6257 35.8642 35.6667 37.3333C34.7557 38.2351 34.0698 39.3387 33.6644 40.5548C33.2591 41.7709 33.1457 43.0653 33.3334 44.3333C33.3334 45.571 32.8417 46.758 31.9666 47.6332C31.0914 48.5083 29.9044 49 28.6667 49C27.4291 49 26.2421 48.5083 25.3669 47.6332C24.4917 46.758 24.0001 45.571 24.0001 44.3333C24.1878 43.0653 24.0744 41.7709 23.669 40.5548C23.2637 39.3387 22.5778 38.2351 21.6667 37.3333Z"
              stroke="#928CDA"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }

      case Icons.KITS: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="57"
            height="56"
            viewBox="0 0 57 56"
            fill="none"
          >
            <path
              d="M36.1311 20.666H44.021C45.2974 20.666 46.5214 21.173 47.4239 22.0756C48.3265 22.9781 48.8335 24.2022 48.8335 25.4785V46.3327L42.4168 39.916H27.9793C26.703 39.916 25.4789 39.409 24.5764 38.5065C23.6739 37.6039 23.1668 36.3799 23.1668 35.1035V30.3566"
              stroke="#04A451"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.50049 37.166V14.8223C8.50049 13.4547 9.04373 12.1432 10.0107 11.1762C10.9777 10.2093 12.2892 9.66602 13.6567 9.66602H30.8442C32.2118 9.66602 33.5233 10.2093 34.4903 11.1762C35.4572 12.1432 36.0005 13.4547 36.0005 14.8223V25.1348C36.0005 26.5023 35.4572 27.8138 34.4903 28.7808C33.5233 29.7478 32.2118 30.291 30.8442 30.291H15.3755L8.50049 37.166Z"
              stroke="#04A451"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }

      case Icons.PORTFOLIO: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
          >
            <path
              d="M11 23.5V42.5C11 44.433 12.567 46 14.5 46H41.5C43.433 46 45 44.433 45 42.5V28.5C45 26.567 43.433 25 41.5 25H30.5207C30.1943 25 29.8886 24.8408 29.7014 24.5735L27.545 21.4929C26.8901 20.5572 25.8198 20 24.6777 20H14.5C12.567 20 11 21.567 11 23.5Z"
              stroke="#4673D1"
              strokeWidth="3"
              strokeLinejoin="round"
            />
            <path
              d="M13 21V13.5C13 11.567 14.567 10 16.5 10H34.5C36.433 10 38 11.567 38 13.5V26"
              stroke="#4673D1"
              strokeWidth="3"
              strokeLinejoin="round"
            />
            <path
              d="M38 14H39.5C41.433 14 43 15.567 43 17.5V26"
              stroke="#4673D1"
              strokeWidth="3"
              strokeLinejoin="round"
            />
          </svg>
        );
      }

      case Icons.CLIENTS: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="57"
            height="56"
            viewBox="0 0 57 56"
            fill="none"
          >
            <path
              d="M29.3335 20C29.3335 24.4183 25.7518 28 21.3335 28C16.9152 28 13.3335 24.4183 13.3335 20C13.3335 15.5817 16.9152 12 21.3335 12C25.7518 12 29.3335 15.5817 29.3335 20Z"
              stroke="#FB913A"
              strokeWidth="3"
            />
            <path
              d="M35.3335 28C39.7518 28 43.3335 24.4183 43.3335 20C43.3335 15.5817 39.7518 12 35.3335 12"
              stroke="#FB913A"
              strokeWidth="3"
              strokeLinecap="round"
            />
            <path
              d="M9.3335 42.999C10.0814 39.2596 13.5198 35.999 17.3333 35.999H25.3335C29.1469 35.999 32.5856 39.2596 33.3335 42.999"
              stroke="#FB913A"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M39.3335 35.999C43.1469 35.999 46.5856 39.2596 47.3335 42.999"
              stroke="#FB913A"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }

      case Icons.LOGS: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="57"
            height="56"
            viewBox="0 0 57 56"
            fill="none"
          >
            <path
              d="M26.8892 10V17.5556C26.8892 18.0565 27.0882 18.537 27.4424 18.8912C27.7966 19.2454 28.2771 19.4444 28.778 19.4444H36.3336"
              stroke="#51BFE1"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.3335 44H14.1113C13.1093 44 12.1485 43.602 11.44 42.8935C10.7315 42.185 10.3335 41.2241 10.3335 40.2222V13.7778C10.3335 12.7758 10.7315 11.815 11.44 11.1065C12.1485 10.398 13.1093 10 14.1113 10H26.8891L36.3335 19.4444V26"
              stroke="#51BFE1"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.3335 22H20.3337"
              stroke="#51BFE1"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.3335 29H26.3335"
              stroke="#51BFE1"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.3335 36H21.3335"
              stroke="#51BFE1"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <circle cx="35.3335" cy="40" r="8" stroke="#51BFE1" strokeWidth="3" />
            <path
              d="M35.3335 37V40L37.8335 41.5"
              stroke="#51BFE1"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }

      default:
        return null;
    }
  };
  return (
    <div testid={testid} onClick={onClick} className={className}>
      {renderIcon()}
    </div>
  );
};

Icons.SIGNALS = 'signals';
Icons.INTEL = 'intel';
Icons.KITS = 'kits';
Icons.PORTFOLIO = 'portfolio';
Icons.CLIENTS = 'clients';
Icons.LOGS = 'logs';
Icons.UP_ARROW = 'upArrow';
Icons.CONDO = 'condo';

Icons.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  testid: PropTypes.string,
  svgClass: PropTypes.string,
};

Icons.defaultProps = {
  svgClass: '',
  className: '',
  variant: '',
  color: '',
  testid: undefined,
  onClick: () => {},
};
