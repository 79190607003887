import { SVGProps } from 'react';

export const KitIcon = ({
  color = '#928CDA',
  withCircle = true,
  ...props
}: SVGProps<SVGSVGElement> & { withCircle?: boolean }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {withCircle && <circle opacity="0.12" cx="16" cy="16" r="16" fill={color} />}
    <path
      d="M18.6656 13.3809H21.4834C21.9392 13.3809 22.3764 13.5619 22.6987 13.8843C23.0211 14.2066 23.2021 14.6438 23.2021 15.0996V22.5475L20.9105 20.2559H15.7542C15.2984 20.2559 14.8612 20.0748 14.5389 19.7524C14.2166 19.4301 14.0355 18.9929 14.0355 18.5371V16.8418"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.79785 19.2736V11.2937C8.79785 10.8053 8.99187 10.3369 9.33722 9.99152C9.68257 9.64616 10.151 9.45215 10.6394 9.45215H16.7778C17.2662 9.45215 17.7346 9.64616 18.0799 9.99152C18.4253 10.3369 18.6193 10.8053 18.6193 11.2937V14.9767C18.6193 15.4651 18.4253 15.9335 18.0799 16.2789C17.7346 16.6242 17.2662 16.8182 16.7778 16.8182H11.2532L8.79785 19.2736Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
