import { filter, find, get, groupBy, includes, map, upperCase } from 'lodash-es';
import { createSelector } from 'reselect';
import { IDLE, PENDING } from 'settings/constants/apiState';
import { AGENT_CONNECTION_STATUSES } from 'settings/constants/common';

export const localState = ({ clientsList }) => clientsList;

export const connectionsSearchFilter = (list, search) =>
  filter(list, ({ name }) => includes(upperCase(name), upperCase(search)));

export const connectionsGroupByName = (list) => groupBy(list, ({ name }) => upperCase(name[0]));

export const getStateAndDataFlag = createSelector(localState, ({ state, data }) => ({
  state,
  isIdle: state === IDLE,
  isPending: state === PENDING,
  isData: !!data,
}));

export const getClientsListDataArray = createSelector(localState, ({ data }) => data || []);

export const getFormattedClientsList = createSelector(getClientsListDataArray, (data) =>
  map(data, (item = {}) => {
    const role = get(item, 'Roles.0');
    return {
      id: item.Id,
      name: `${item.FirstName} ${item.LastName}`,
      firstName: item.FirstName,
      lastName: item.LastName,
      role,
      clientStatus: get(item, [role, 'InvitationStatus']),
      contextStatus: item.InvitationStatus,
      sendDate: get(item, 'InviteSentAt'),
      avatarUrl: get(item, 'AvatarUrl'),
      deactivated: get(item, 'Deactivated'),
      retention: item.FromRetentionMode,
      addresses: get(item, 'Client.Address'),
    };
  }),
);
export const getActiveClientsList = createSelector(getFormattedClientsList, (list) =>
  filter(
    list,
    ({ contextStatus, retention, deactivated }) =>
      contextStatus === AGENT_CONNECTION_STATUSES.ACTIVE && !retention && !deactivated,
  ).map((item) => ({ ...item, listType: 'Active' })),
);

export const getRetainedClientsList = createSelector(getFormattedClientsList, (list) =>
  filter(
    list,
    ({ retention, contextStatus }) =>
      retention && contextStatus !== AGENT_CONNECTION_STATUSES.PENDING,
  ).map((item) => ({ ...item, listType: 'Retained' })),
);

export const getInactiveClientsList = createSelector(getFormattedClientsList, (list) =>
  filter(
    list,
    ({ deactivated, contextStatus }) =>
      contextStatus === AGENT_CONNECTION_STATUSES.INACTIVE || deactivated,
  ).map((item) => ({ ...item, listType: 'Inactive' })),
);

export const getPendingClientsList = createSelector(getFormattedClientsList, (list) =>
  filter(list, ({ contextStatus }) => contextStatus === AGENT_CONNECTION_STATUSES.PENDING).map(
    (item) => ({ ...item, listType: 'Pending' }),
  ),
);

export const getDeclinedClientsList = createSelector(getFormattedClientsList, (list) =>
  filter(list, ({ contextStatus }) => contextStatus === AGENT_CONNECTION_STATUSES.DECLINED).map(
    (item) => ({ ...item, listType: 'Declined' }),
  ),
);

export const getClientById = (id) =>
  createSelector(getClientsListDataArray, (list) => find(list, ({ Id }) => Id === id));

export const getClientDataByOpenedUserId = (id) =>
  createSelector(getClientById(id), (item) => ({
    ...item,
  }));
