import {
  createClauseTemplate,
  deleteClauseTemplate,
  getAllClauseTemplates,
  updateClauseTemplate,
} from 'api/templates/clause';
import {
  requestCreateClauseTemplateAction,
  requestDeleteClauseTemplateAction,
  requestGetClauseTemplatesAction,
  requestUpdateClauseTemplateAction,
  setClauseTemplatesAction,
} from 'store/actions/templates/clause';
import Api from 'store/effects/core/api';

export const getAllClausesEffect = (cfg, options, cb) => {
  const requestParams = {
    action: requestGetClauseTemplatesAction,
    method: getAllClauseTemplates,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const createClauseTemplateEffect = (sendData, options, cb) => {
  const requestParams = { action: requestCreateClauseTemplateAction, method: createClauseTemplate };
  const sendRequest = Api.execBase(requestParams);

  return sendRequest(sendData, options, (err, response, dispatch) => {
    if (!err) {
      dispatch(getAllClausesEffect({}, { silent: true }));
    }
    if (cb) cb(err, response, dispatch);
  });
};

export const updateClauseTemplateEffect = (sendData, options, cb) => {
  const requestParams = { action: requestUpdateClauseTemplateAction, method: updateClauseTemplate };
  const sendRequest = Api.execBase(requestParams);

  return sendRequest(sendData, options, (err, response, dispatch) => {
    if (!err) {
      dispatch(getAllClausesEffect({}, { silent: true }));
    }
    if (cb) cb(err, response, dispatch);
  });
};

export const deleteClauseTemplateEffect = ({ id }, options, cb) => {
  const requestParams = {
    action: requestDeleteClauseTemplateAction,
    method: deleteClauseTemplate,
  };
  const sendRequest = Api.execBase(requestParams);

  return sendRequest({ id }, options, (err, response, dispatch) => {
    if (cb) cb(err, response, dispatch);
  });
};

export const setClauseTemplateEffect = (cfg) => (dispatch) => {
  dispatch(setClauseTemplatesAction(cfg));
};
