import { convertToDecimalIfNotWhole } from 'helpers';

export const calculateGCI = (rate, comparePrice) => {
  return (rate / 100) * comparePrice;
};

export const calculateRate = (GCI, comparePrice) => {
  if (isNaN(GCI)) return 0;
  return convertToDecimalIfNotWhole(((GCI || 0) * 100) / comparePrice);
};

export const isFeesWithinRangeOfPrice = (commission, referralFee, otherFees) => {
  const commission_ = commission?.Value || 0;
  const referralFeeGCI = calculateGCI(referralFee || 0, commission_);

  const totalFees = otherFees.reduce((total, fee) => {
    return total + (parseFloat(fee?.Value) || 0);
  }, referralFeeGCI);

  // Check if total fees exceed the purchase price/listing price
  return totalFees <= commission_;
};

export const isCommissionWithinRangeOfPrice = (
  commission,
  transactionPrice,
  otherCommission = 0,
) => {
  let _otherCommission = otherCommission ?? 0;
  let compareToBeAmount = (+commission || 0) + +_otherCommission;

  return compareToBeAmount === transactionPrice || compareToBeAmount < transactionPrice;
};
