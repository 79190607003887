import { useSelector } from 'react-redux';

import { postClarityFinancialData } from 'api/clarity';
import { getAgentTeamIdSelector, getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import {
  getAgentTeamStatsSelector,
  getTimePeriodSelector,
  getTransactionFilter,
} from 'store/selectors/clarity';
import { FeesDataType } from '../Components/Stats/Content/Components/TableView/TableModal/Financial/ModalTableColumns';
import { TEAM_BASIC } from 'settings/constants/roles';
import { getUserId } from 'store/selectors/user';

const formatDescrition = (desc, type) => {
  if (type === 'DeductFrom') {
    if (desc.includes('Custom Account Payment'))
      return desc.replace('Account Payment', 'Split').replace('Deduct from ', '');
    else if (desc.includes('GCI')) return 'GCI';
    else if (desc.includes('Client Fee Balance')) return 'Client';
    else if (desc.includes('Agent Payment')) return 'Agent Split';
    else if (desc.includes('Team Payment')) return 'Team Split';
    else if (desc.includes('Brokerage Payment')) return 'Brokerage Split';
    else return desc;
  } else {
    if (desc.includes('Custom Account Payment'))
      return desc.replace('Account Payment', 'Split').replace('Add to ', '');
    else if (desc.includes('Outbound Payment')) return 'Outbound';
    else if (desc.includes('Client Fee Balance')) return 'Client';
    else if (desc.includes('Agent Payment')) return 'Agent Net';
    else if (desc.includes('Team Payment')) return 'Team Net';
    else if (desc.includes('Brokerage Payment')) return 'Brokerage Net';
    else return desc;
  }
};

const formatData = (data) =>
  data.map((agent) => {
    return { ...agent, Agent: agent.AgentUser || agent.AgentContact };
  });

const isValidAgentName = (agent) => agent === ' ' || agent === '' || agent === null;

const formatFees = (data) => {
  const result = <FeesDataType[]>[];
  for (let i = 0; i < data.length; i += 2) {
    if (!data[i + 1]) break;
    result.push({
      Date: data[i].Date,
      Transaction: data[i].Transaction,
      DeductFrom: formatDescrition(data[i].Description, 'DeductFrom'),
      ApplyTo: formatDescrition(data[i + 1].Description, 'ApplyTo'),
      TotalValue: data[i + 1].TotalValue,
      Agent: isValidAgentName(data[i].AgentUser) ? data[i].AgentUser : data[i].AgentContact,
      PropertyTransactionId: data[i].PropertyTransactionId,
    });
  }
  return result;
};

export const useFetchFinancialStats = () => {
  const teamId = useSelector(getAgentTeamIdSelector);
  const teamStats = useSelector(getAgentTeamStatsSelector);
  const timePeriod = useSelector(getTimePeriodSelector);
  const transactionFilter = useSelector(getTransactionFilter);
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const userId = useSelector(getUserId);

  const fetchFinancialStats = async ({
    setSummary,
    setData,
    setIsPending,
    key,
    aggregationType,
    summaryType,
    aggregateDataFormat,
    memo,
    ledgerAccountId,
    year,
    quarter,
    month,
    isoWeek,
    date,
    agentSummary = false,
    transactionAgentUserId = false,
    transactionAgentContactId = false,
    isTeam = false,
  }) => {
    setIsPending(true);
    const excludeTimePeriod = !!(year || quarter || month || isoWeek);
    const sendValues = {
      teamId: teamId,
      ...(!excludeTimePeriod && { timePeriod: timePeriod }),
      aggregationType,
      summaryType,
      financialOptions: ['ActualOnly'],
      optionalColumns: [],
      ...(agentRole === TEAM_BASIC && { transactionAgentUserId: userId }),
      ...(teamStats.itemValue !== 'Team' && { transactionAgentUserId: teamStats.userId }),
      aggregateDataFormat,
      ...(year && { year: year }),
      ...(quarter && { quarter }),
      ...(month && { month }),
      ...(isoWeek && { isoWeek }),
      ...(date && { date }),
      ...(memo && { filterByMemo: memo }),
      ...(ledgerAccountId && { filterByLedgerAccountId: ledgerAccountId }),
      ...(agentSummary && { agentSummary: agentSummary }),
      ...(transactionFilter && {
        filterByLedgerRepresentingSideId: transactionFilter,
      }),
      ...(!isTeam && transactionAgentUserId && { transactionAgentUserId: transactionAgentUserId }),
      ...(!isTeam &&
        !transactionAgentUserId &&
        transactionAgentContactId && { transactionAgentContactId: transactionAgentContactId }),
    };
    const { data, status } = await postClarityFinancialData(sendValues);
    if (status === 200) {
      if (agentSummary) {
        setSummary(data.Summary?.[key] || []);
      } else if (summaryType.includes('Expand')) {
        setSummary(data.Summary.filter((s) => s.SummaryColumn === key)?.[0] || {});
        summaryType === 'FeesExpanded'
          ? setData(formatFees(data.Data[key]) || [])
          : setData(formatData(data.Data[key]) || []);
      } else {
        setSummary(data.Summary.filter((s) => s.SummaryColumn === key)?.[0] || {});
        setData(data.Data[key] || []);
      }
    }
    setIsPending(false);
  };
  return fetchFinancialStats;
};
