import { renderStatus } from 'pages/Workshop/Forms/components/FormsTable';
import { Options } from '../Options';
import { DocumentNameColumn, AvatarColumn, DateTimeColumn } from './TableColumns';
import { AgentDocumentTypes } from 'types';
import { FormsTableStatus } from 'app-constants';
import { formatDate } from 'helpers';

const docTypeMisc = AgentDocumentTypes.Misc;
const docTypeUploads = AgentDocumentTypes.Uploads;

export const miscElectronicSignColumns = (optionUtils) => [
  {
    key: 'title',
    title: 'Name',
    dataIndex: 'title',
    width: 375,
    render: (title) => <DocumentNameColumn name={title} docIconType="Ungrouped" />,
  },
  {
    key: 'status',
    title: 'Status',
    width: 200,
    render: () => renderStatus(FormsTableStatus.Complete),
  },
  {
    key: 'client',
    title: 'Client',
    width: 250,
    render: (row) => <AvatarColumn name={`${row?.clientMeta?.[0]?.UserName}`} imgSrc={null} />,
  },
  {
    title: '',
    render: (row) =>
      row?.miscellaneousMeta?.sealedDate ? (
        <span>Completed: {formatDate(row?.miscellaneousMeta?.sealedDate, 'M/D/YYYY')}</span>
      ) : (
        ''
      ),
  },
  {
    key: 'options',
    title: '',
    render: (row) => <Options document_={row} type={docTypeMisc} optionUtils={optionUtils} />,
  },
];

export const miscUploadsColumns = (optionUtils) => [
  {
    key: 'title',
    title: 'Name',
    dataIndex: 'title',
    width: 475,
    render: (title) => <DocumentNameColumn name={title} docIconType="Ungrouped" />,
  },
  {
    key: 'uploadedBy',
    title: 'Uploaded By',
    width: 320,
    render: (row) => (
      <AvatarColumn
        name={row?.uploadDocumentMeta?.uploadedBy}
        imgSrc={row?.uploadDocumentMeta?.AvatarUrl}
      />
    ),
  },
  {
    key: 'uploadedAt',
    title: 'Created',
    render: (row) => <DateTimeColumn date={row?.uploadDocumentMeta?.uploadedAt} />,
  },
  {
    key: 'options',
    title: '',
    render: (row) => <Options document_={row} type={docTypeUploads} optionUtils={optionUtils} />,
  },
];
