import { createSelector } from 'reselect';
import { get, flow, map, filter } from 'lodash-es';
import { formatPhoneNumber } from 'helpers/formatters';

const localState = ({ relatedContacts }) => relatedContacts;

export const getRelatedClientContactsFormattedList = createSelector(
  localState,
  ({ clientContacts }) =>
    flow(
      (list) => filter(list, (item) => item?.Id),
      (list) =>
        map(list || [], (item = {}) => {
          const role = get(item, 'Role.0');
          return {
            id: item?.Id,
            userId: item?.UserId,
            contactId: item?.ContactId,
            name: item?.Name,
            role,
            email: item?.Email,
            avatarUrl: item?.AvatarUrl,
            phone: formatPhoneNumber(item?.Phone),
            ...(item?.ContactId && !item.UserId && { isContact: true }),
          };
        }),
    )(clientContacts),
);

export const getRelatedAgentCoordinatorContactsFormattedList = createSelector(
  localState,
  ({ agentsCoordinatorContacts }) =>
    flow(
      (list) => filter(list, (item) => item?.Id),
      (list) =>
        map(list || [], (item = {}) => {
          return {
            Id: item?.Id,
            UserId: item?.UserId,
            ContactId: item?.ContactId,
            Name: item?.Name,
            Role: item?.Role,
            Email: item?.Email,
            AvatarUrl: item?.AvatarUrl,
            Phone: formatPhoneNumber(item?.Phone),
          };
        }),
    )(agentsCoordinatorContacts),
);

export const getTransactionRelatedAgentCoordinatorContactsFormattedList = createSelector(
  localState,
  ({ agentsCoordinatorContacts }) =>
    flow(
      (list) => filter(list, (item) => item?.Id),
      (list) =>
        map(list || [], (item = {}) => {
          const role = get(item, 'Role.0');
          return {
            id: item?.Id,
            userId: item?.UserId,
            contactId: item?.ContactId,
            name: item?.Name,
            role,
            email: item?.Email,
            avatarUrl: item?.AvatarUrl,
            phone: formatPhoneNumber(item?.Phone),
          };
        }),
    )(agentsCoordinatorContacts),
);

export const getRelatedTransactionContactsFormattedList = createSelector(
  localState,
  ({ transactionContacts }) =>
    flow(
      (list) => filter(list, (item) => item?.Id),
      (list) =>
        map(list || [], (item = {}) => {
          return {
            Id: item?.Id,
            UserId: item?.UserId,
            ContactId: item?.ContactId,
            Name: item?.Name,
            Role: item?.Role,
            Email: item?.Email,
            AvatarUrl: item?.AvatarUrl,
            Phone: formatPhoneNumber(item?.Phone),
            UninvitedParticipantId: item?.UninvitedParticipantId,
            ParticipantId: item?.ParticipantId,
          };
        }),
    )(transactionContacts),
);

export const getAllRelatedContactsFormattedList = createSelector(localState, ({ allContacts }) =>
  flow(
    (list) => filter(list, (item) => item?.Id),
    (list) =>
      map(list || [], (item = {}) => {
        return {
          Id: item?.Id,
          UserId: item?.UserId,
          ContactId: item?.ContactId,
          Name: item?.Name,
          Email: item?.Email,
          AvatarUrl: item?.AvatarUrl,
          CompanyName: item?.CompanyName,
          Role: item?.Role?.Title,
          Phone: formatPhoneNumber(item?.Phone),
        };
      }),
  )(allContacts),
);
