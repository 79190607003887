import React, { useCallback } from 'react';

export enum MenuIconTypes {
  View = 'View',
  Download = 'Download',
  Edit = 'Edit',
  Share = 'Share',
  Delete = 'Delete',
  Comment = 'Comment',
}

interface MenuIconProps extends React.SVGProps<SVGSVGElement> {
  type: MenuIconTypes;
}

export const MenuIcons = ({ type, ...props }: MenuIconProps) => {
  const getIcon = useCallback(() => {
    switch (type) {
      case MenuIconTypes.View:
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <g clipPath="url(#clip0_17122_164453)">
              <path
                d="M2 12.6668C2.91212 12.1402 3.94678 11.8629 5 11.8629C6.05322 11.8629 7.08788 12.1402 8 12.6668C8.91212 12.1402 9.94678 11.8629 11 11.8629C12.0532 11.8629 13.0879 12.1402 14 12.6668"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2 3.99989C2.91212 3.47328 3.94678 3.19604 5 3.19604C6.05322 3.19604 7.08788 3.47328 8 3.99989C8.91212 3.47328 9.94678 3.19604 11 3.19604C12.0532 3.19604 13.0879 3.47328 14 3.99989"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2 4V12.6667"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 4V12.6667"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14 4V12.6667"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_17122_164453">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );

      case MenuIconTypes.Download:
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <g clipPath="url(#clip0_17122_164445)">
              <path
                d="M2.66797 11.3334V12.6667C2.66797 13.0203 2.80844 13.3595 3.05849 13.6095C3.30854 13.8596 3.64768 14 4.0013 14H12.0013C12.3549 14 12.6941 13.8596 12.9441 13.6095C13.1942 13.3595 13.3346 13.0203 13.3346 12.6667V11.3334"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4.66797 7.33337L8.0013 10.6667L11.3346 7.33337"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 2.66663V10.6666"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_17122_164445">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      case MenuIconTypes.Share:
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <g clipPath="url(#clip0_17122_164463)">
              <path
                d="M2.66797 11.3334V12.6667C2.66797 13.0203 2.80844 13.3595 3.05849 13.6095C3.30854 13.8596 3.64768 14 4.0013 14H12.0013C12.3549 14 12.6941 13.8596 12.9441 13.6095C13.1942 13.3595 13.3346 13.0203 13.3346 12.6667V11.3334"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4.66797 5.99988L8.0013 2.66655L11.3346 5.99988"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 10.6671V2.66711"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_17122_164463">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      case MenuIconTypes.Edit:
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <g clipPath="url(#clip0_17122_164494)">
              <path
                d="M2.66797 13.3335H5.33464L12.3346 6.33345C12.6883 5.97983 12.8869 5.50022 12.8869 5.00012C12.8869 4.50002 12.6883 4.02041 12.3346 3.66679C11.981 3.31316 11.5014 3.1145 11.0013 3.1145C10.5012 3.1145 10.0216 3.31316 9.66797 3.66679L2.66797 10.6668V13.3335Z"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 4.3335L11.6667 7.00016"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_17122_164494">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      case MenuIconTypes.Delete:
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <g clipPath="url(#clip0_17122_164484)">
              <path
                d="M2.66797 4.6665H13.3346"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.66797 7.3335V11.3335"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.33203 7.3335V11.3335"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3.33203 4.6665L3.9987 12.6665C3.9987 13.0201 4.13917 13.3593 4.38922 13.6093C4.63927 13.8594 4.97841 13.9998 5.33203 13.9998H10.6654C11.019 13.9998 11.3581 13.8594 11.6082 13.6093C11.8582 13.3593 11.9987 13.0201 11.9987 12.6665L12.6654 4.6665"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 4.66667V2.66667C6 2.48986 6.07024 2.32029 6.19526 2.19526C6.32029 2.07024 6.48986 2 6.66667 2H9.33333C9.51014 2 9.67971 2.07024 9.80474 2.19526C9.92976 2.32029 10 2.48986 10 2.66667V4.66667"
                stroke="#FF576D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_17122_164484">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      case MenuIconTypes.Comment:
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M3.45355 10.7053L4.37363 11.0971L4.57149 10.6324L4.31492 10.1974L3.45355 10.7053ZM2.514 12.912L3.43408 13.3037H3.43408L2.514 12.912ZM3.154 13.5744L3.51392 14.5073H3.51392L3.154 13.5744ZM5.48331 12.6758L5.96541 11.7997L5.55763 11.5753L5.12339 11.7428L5.48331 12.6758ZM12.3841 7.99996C12.3841 10.3932 10.444 12.3333 8.05077 12.3333V14.3333C11.5486 14.3333 14.3841 11.4978 14.3841 7.99996H12.3841ZM8.05077 3.66663C10.444 3.66663 12.3841 5.60673 12.3841 7.99996H14.3841C14.3841 4.50216 11.5486 1.66663 8.05077 1.66663V3.66663ZM3.71744 7.99996C3.71744 5.60673 5.65753 3.66663 8.05077 3.66663V1.66663C4.55297 1.66663 1.71744 4.50216 1.71744 7.99996H3.71744ZM4.31492 10.1974C3.93543 9.55388 3.71744 8.8039 3.71744 7.99996H1.71744C1.71744 9.17093 2.03615 10.2705 2.59219 11.2133L4.31492 10.1974ZM3.43408 13.3037L4.37363 11.0971L2.53348 10.3136L1.59393 12.5202L3.43408 13.3037ZM2.79408 12.6414C3.20638 12.4823 3.6072 12.8971 3.43408 13.3037L1.59393 12.5202C1.07456 13.74 2.27701 14.9845 3.51392 14.5073L2.79408 12.6414ZM5.12339 11.7428L2.79408 12.6414L3.51392 14.5073L5.84323 13.6088L5.12339 11.7428ZM8.05077 12.3333C7.29281 12.3333 6.5831 12.1395 5.96541 11.7997L5.00122 13.5519C5.9068 14.0502 6.94722 14.3333 8.05077 14.3333V12.3333Z"
              fill="#FF576D"
            />
          </svg>
        );
      default: {
        return <></>;
      }
    }
  }, [type]);

  return getIcon();
};
