import React, { useCallback, useState } from 'react';
import { Modal, ModalProps } from 'components-antd';
import { useDispatch, useSelector } from 'react-redux';
import { createPartnerByAgentEffect } from 'store/effects';
import { getConciergeSearchSelector } from 'store/selectors/concierge';
import { getLoadingRecommendedPartnersByAgent } from 'store/selectors/partners';
import {
  requestGetPartnersListByCategoryIdEffect,
  requestGetRecommendedPartnersByAgentListEffect,
} from 'store/effects/partners';
import { AddVendorForm, OnError, OnSuccess, EmailTemplate } from './components';

import styles from './styles.module.scss';
import { FormikContextType, useFormik } from 'formik';
import { addVendorValidationSchema } from './validation';

interface Props extends ModalProps {
  handleClose: () => void;
}

export const AddVendorModal: React.FC<Props> = ({ open, handleClose, ...rest }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { areasOfOperation, category: categoryId } = useSelector(getConciergeSearchSelector);
  const { isPending } = useSelector(getLoadingRecommendedPartnersByAgent);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [businessName, setBusinessName] = useState<string>('');
  const [step, setStep] = useState<number>(1);

  const submitVendor = useCallback((values: any) => {
    setIsLoading(true);
    dispatch(
      createPartnerByAgentEffect(
        {
          ...values,
          Email: values.Email?.toLowerCase(),
          AreasOfOperation: areasOfOperation,
          SuiteUnit: '',
        },
        (err) => {
          if (!err) {
            if (!isPending) dispatch(requestGetRecommendedPartnersByAgentListEffect());
            dispatch(requestGetPartnersListByCategoryIdEffect());
            setIsLoading(false);
            setBusinessName(values.BusinessName);
            setIsSuccess(true);
            return;
          }
          setBusinessName(values.BusinessName);
          setIsLoading(false);
          setIsError(true);
        },
      ),
    );
  }, []);

  const form: FormikContextType<{
    BusinessName: string;
    Email: string;
    PartnerCategoryIds: { name: string; value: string }[];
  }> = useFormik<{
    BusinessName: string;
    Email: string;
    PartnerCategoryIds: { name: string; value: string }[];
  }>({
    initialValues: {
      BusinessName: '',
      Email: '',
      PartnerCategoryIds: [],
    },
    validationSchema: addVendorValidationSchema,
    onSubmit: submitVendor,
  });

  const handleClickCancel = (e: React.MouseEvent<HTMLElement>) => {
    if (step > 1) {
      setStep((prevState) => prevState - 1);
    } else {
      handleClose();
    }
  };
  const handleEmailClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (step < 2) {
      setStep((prevState) => prevState + 1);
    }
  };

  return (
    <Modal
      className={styles.common}
      footer={null}
      width={618}
      open={open}
      onCancel={handleClickCancel}
      destroyOnClose
      closable={!(isSuccess || isError)}
      {...rest}
    >
      {!(isSuccess || isError || step > 1) && (
        <AddVendorForm isLoading={isLoading} onClickEmail={handleEmailClick} form={form} />
      )}
      {step > 1 && <EmailTemplate />}
      {isSuccess && <OnSuccess handleClose={handleClose} name={businessName} />}
      {isError && <OnError handleClose={handleClose} name={businessName} />}
    </Modal>
  );
};
