import moment from 'moment';

import { Row, Col } from 'components-antd';
import { FormProcessDocumentsType, FormDetailsDocumentType } from 'types';
import { Icons, ICON_VARIANT_TYPE } from 'pages/Workshop/Forms/Icons';
import { CustomDropDown } from 'pages/Workshop/Forms/components/FormDetailModal/components/CustomDropDown';
import { onHandleViewFormType } from 'pages/Workshop/Forms';

import styles from './styles.module.scss';
import classNames from 'classnames';

interface DocumentProps {
  documentData: FormDetailsDocumentType[];
  formProcessDocuments?: FormProcessDocumentsType;
  handleViewForm: onHandleViewFormType;
  selectedFormProcess: number;
  disableViewForm?: boolean;
  showFormDate?: boolean;
}

export const Documents = ({
  documentData,
  formProcessDocuments,
  handleViewForm,
  selectedFormProcess,
  disableViewForm = false,
  showFormDate = true,
}: DocumentProps) => {
  return (
    <>
      <div className={styles.documentContent}>
        {documentData.map((val, index) => (
          <div key={`documentData-${index}`} className={styles.documentDiv}>
            <div
              className={classNames(styles.fileNameDiv, { [styles.alignFileName]: !showFormDate })}
            >
              <div className={styles.svgContainer}>
                {val.isCompleted ? (
                  <Icons variant={ICON_VARIANT_TYPE.COMPLETE_DOCUMENT} />
                ) : (
                  <Icons variant={ICON_VARIANT_TYPE.INCOMPLETE_DOCUMENT} />
                )}
              </div>

              <div>
                <p className={styles.documentName}>{val.formName}</p>
                {showFormDate && (
                  <p className={styles.documentStatus}>
                    <span>{val.isCompleted ? 'Completed' : 'Sent'}: </span>
                    <span>{moment(val.date).format('MMM DD [at] hh:mm A')}</span>
                  </p>
                )}
              </div>
            </div>

            <div className={val.isVoid ? styles.actions : ''}>
              {val.isVoid ? <span className={styles.voidPill}>Void</span> : <></>}
              <CustomDropDown
                isDocument
                pdfUrl={val.link}
                onViewForm={!disableViewForm ? () => handleViewForm(val.url, val.type) : undefined}
                selectedFormProcess={selectedFormProcess}
                formProcessDocumentId={val.Id}
                isVoid={val.isVoid}
              />
            </div>
          </div>
        ))}

        {formProcessDocuments?.map((val, index) => (
          <div key={`form-process-documents-${index}`} className={styles.documentDiv}>
            <div className={classNames(styles.fileNameDiv, styles.formDocumentNameDiv)}>
              <div>
                <Icons variant={ICON_VARIANT_TYPE.DOCUMENT} />
              </div>

              <p className={styles.documentName}>{val.DocumentName}</p>
            </div>

            <div>
              <CustomDropDown isDocument pdfUrl={val.DocumentLink} />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
