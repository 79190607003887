import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { cloneDeep, get } from 'lodash-es';

import { requestGetPropertyInsights } from 'store/actions/mlsScores';
import { appLogoutAction } from 'store/actions/app';

const initialData = {
  state: IDLE,
  data: {},
};

export default handleActions(
  {
    [requestGetPropertyInsights]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, ['data', 'response']),
    }),
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
