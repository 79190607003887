import { FC, ReactElement, useState } from 'react';
import classNames from 'classnames';
import { caseInsensitiveFilter } from 'helpers';
import { SearchIcon } from '../Icons';
import { Input, AutoComplete, AutoCompleteProps } from 'antd';
import styles from './styles.module.scss';

export interface TagAutocompleteProps {
  disabled?: boolean;
  name: string[];
  setName: (name: string) => void;
  handleSelect?: (value: string) => void;
  handleBlur?: () => void;
  autoCompleteProps?: AutoCompleteProps;
  dropdownContainer?: string;
  placeholder: string;
}

export const Autocomplete: FC<TagAutocompleteProps> = (
  props: TagAutocompleteProps,
): ReactElement => {
  const {
    disabled,
    name,
    setName,
    handleSelect,
    handleBlur,
    autoCompleteProps,
    dropdownContainer,
    placeholder,
  } = props;

  const renderOption = (text: string) => (
    <AutoComplete.Option key={text} value={text}>
      <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
        <SearchIcon style={{ marginRight: '8px', color: '#1890ff' }} />
        <span>{text}</span>
      </div>
    </AutoComplete.Option>
  );

  const [isSearch, setIsSearch] = useState<boolean>(false);

  const handleChange = (val: string) => {
    setName(val);
  };

  const [result, setResult] = useState([]);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [isAutoSuggestionTyping, setIsAutoSuggestionTyping] = useState(false);

  const onAutoCompleteClick = (entityObject) => {};

  const onOpenAutoComplete = () => {
    setOpen(!open);
    setOpenMenu(!openMenu);
  };

  const getOptions = () => {
    return [
      {
        label: 'Search result',
        options: ['Hello World 1', 'Hello World 2', 'Hello World 3', 'Hello World4'].map(
          (entity) => ({
            value: entity,
            label: (
              <span
                className="propertyTransactionIcon"
                style={{
                  padding: 10,
                }}
              >
                <SearchIcon />
                <span className="tagTypePill">{entity}</span>
              </span>
            ),
          }),
        ),
      },
    ];
  };

  const handleSearch = (val) => {
    setOpenMenu(true);
  };

  const handleSelection = (val, options) => {
    setOpen(!open);
  };

  return (
    <div testid="autocomplete_dropdown" className={styles.autocompleteWrapper}>
      <AutoComplete
        open={openMenu}
        onSearch={handleSearch}
        onSelect={handleSelection}
        bordered
        className={classNames(styles.autocompleteContainer, {
          [styles.autoCompleteTyping]: isAutoSuggestionTyping,
        })}
        popupClassName={styles.suggestionPopupContainer}
        notFoundContent={<p>You have no matched result</p>}
        options={getOptions()}
        onClick={() => setOpenMenu(true)}
        onBlur={() => {
          setOpenMenu(false);
          setOpen(false);
        }}
      >
        <Input
          className={styles.autocompleteInput}
          prefix={<SearchIcon />}
          placeholder={placeholder}
        />
      </AutoComplete>
    </div>
  );
};
