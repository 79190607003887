import Api from '../core/api';

import {
  getVaultDocumentsData,
  getPreapprovalDocumentsData,
  getArchiveDocumentsData,
  getClientTransactionDocumentsData,
  getVaultDocumentsMetaData,
  postVaultDocumentData,
  deleteVaultDocument,
  updateVaultDocumentData,
  shareVaultDocument,
} from 'api/vault';
import {
  requestGetVaultDocumentsAction,
  requestGetClientTransactionDocumentsAction,
  requestGetPreapprovalDocumentsAction,
  requestGetArchiveDocumentsAction,
  requestGetVaultDocumentsMetaAction,
  requestUploadVaultDocumentAction,
  deleteVaultDocumentAction,
  requestUpdateVaultDocumentAction,
  shareUpdateVaultDocumentAction,
} from 'store/actions/vault';

export const getVaultDocumentsEffect = (cfg, options = {}, cb = (err: any, resp: any) => {}) => {
  const sendRequest = Api.execBase({
    action: requestGetVaultDocumentsAction,
    method: getVaultDocumentsData,
  });
  return sendRequest(cfg, options, cb);
};

export const getClientTransactionDocumentsEffect = (
  cfg,
  options = {},
  cb = (err: any, resp: any) => {},
) => {
  const sendRequest = Api.execBase({
    action: requestGetClientTransactionDocumentsAction,
    method: getClientTransactionDocumentsData,
  });
  return sendRequest(cfg, options, cb);
};

export const getPreapprovalDocumentsEffect = (
  cfg,
  options = {},
  cb = (err: any, resp: any) => {},
) => {
  const sendRequest = Api.execBase({
    action: requestGetPreapprovalDocumentsAction,
    method: getPreapprovalDocumentsData,
  });
  return sendRequest(cfg, options, cb);
};

export const getArchiveDocumentsEffect = (cfg, options = {}, cb = (err: any, resp: any) => {}) => {
  const sendRequest = Api.execBase({
    action: requestGetArchiveDocumentsAction,
    method: getArchiveDocumentsData,
  });
  return sendRequest(cfg, options, cb);
};

export const getVaultDocumentsMetaEffect = (
  cfg,
  options = {},
  cb = (err: any, resp: any) => {},
) => {
  const sendRequest = Api.execBase({
    action: requestGetVaultDocumentsMetaAction,
    method: getVaultDocumentsMetaData,
  });
  return sendRequest(cfg, options, cb);
};

export const uploadDocumentVaultEffect = (cfg, options = {}, cb = (err: any, resp: any) => {}) => {
  const sendRequest = Api.execBase({
    action: requestUploadVaultDocumentAction,
    method: postVaultDocumentData,
  });
  return sendRequest(cfg, options, cb);
};

export const deleteVaultDocumentEffect = (cfg, options = {}, cb = (err: any, resp: any) => {}) => {
  const sendRequest = Api.execBase({
    action: deleteVaultDocumentAction,
    method: deleteVaultDocument,
  });
  return sendRequest(cfg, options, cb);
};

export const updateDocumentVaultEffect = (cfg, options = {}, cb = (err: any, resp: any) => {}) => {
  const sendRequest = Api.execBase({
    action: requestUpdateVaultDocumentAction,
    method: updateVaultDocumentData,
  });
  return sendRequest(cfg, options, cb);
};

export const shareVaultDocumentEffect = (cfg, options = {}, cb = (err: any, resp: any) => {}) => {
  const sendRequest = Api.execBase({
    action: shareUpdateVaultDocumentAction,
    method: shareVaultDocument,
  });
  return sendRequest(cfg, options, cb);
};
