import { activityLogsPageSize } from 'app-constants/activityLogs';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTaskCategoriesEffect } from 'store/effects';
import { requestGetActivityLogsEffect } from 'store/effects/activityLogsWorkshop';
import { getActivityLogsWorkshopSelector } from 'store/selectors/activityLogsWorkshop';
import { getActivityFiltersDrawerFieldsSelector } from 'store/selectors/drawers/activityFilters';
import { getTransactionTaskCategoriesSelector } from 'store/selectors/transactionTaskCategories';
import { getFormattedFilterObject } from 'utils/activityLogsHelper';

export const useWorkshopActivityLogSection = () => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const fieldsPayload = useSelector(getActivityFiltersDrawerFieldsSelector);
  const { isPending, activities } = useSelector(getActivityLogsWorkshopSelector);
  const { categories } = useSelector(getTransactionTaskCategoriesSelector);
  const dispatch = useDispatch();

  const [prevY, setPrevY] = useState(0);
  const logsRef: any = useRef([]);
  const loadingRef: any = useRef(null);
  const prevYRef = useRef({});
  const pageRef: any = useRef({});
  const totalItemsRef: any = useRef({});
  const fieldsRef = useRef({});
  logsRef.current = [];
  pageRef.current = pageNumber;
  prevYRef.current = prevY;
  totalItemsRef.current = activities?.total;

  const handleObserver = (entities) => {
    const y = entities[0].boundingClientRect.y;
    if (prevYRef.current > y) {
      if (pageRef.current <= Math.ceil(totalItemsRef.current / activityLogsPageSize)) {
        dispatch(
          requestGetActivityLogsEffect(
            getFormattedFilterObject(fieldsRef?.current, pageRef.current, activityLogsPageSize),
          ),
        );
        setPageNumber(pageRef.current + 1);
      }
    }
    setPrevY(y);
  };

  useEffect(() => {
    fieldsRef.current = fieldsPayload;
  }, [fieldsPayload]);

  useEffect(() => {
    setPageNumber(pageRef.current + 1);

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(handleObserver, options);
    observer.observe(loadingRef.current);
    dispatch(getTaskCategoriesEffect());
    dispatch(
      requestGetActivityLogsEffect(
        getFormattedFilterObject(fieldsPayload, pageRef.current, activityLogsPageSize),
      ),
    );
  }, []);

  const getDayAndDateTitleStr = (dateTimestamp) => {
    const dateStr = moment(new Date(dateTimestamp)).format('dddd, MMM D, YYYY');
    if (moment().add(-1, 'days').format('dddd, MMM D, YYYY') === dateStr) {
      return 'Yesterday';
    }
    if (moment().format('dddd, MMM D, YYYY') === dateStr) {
      return 'Today';
    }
    return dateStr;
  };

  return {
    activities,
    getDayAndDateTitleStr,
    categories,
    isPending,
    loadingRef,
    fieldsPayload,
  };
};
