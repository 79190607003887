import { apiServer } from 'settings/web-services/api';

export function postInviteUser(cfg) {
  return apiServer.post('/user/invite', cfg);
}

export function postReinviteClientById({ id }) {
  return apiServer.post(`/user/client/${id}/reinvite`);
}

export function postCancelInviteClientById({ id }) {
  return apiServer.post(`/user/client/${id}/cancelInvite`);
}

export function getAgentClientsList() {
  return apiServer.get('/user/agent/invites');
}

export function deactivateClient(cfg) {
  return apiServer.post(`/user/${cfg.id}/deactivate`);
}

export function activateClient(cfg) {
  return apiServer.post(`/user/${cfg.id}/reactivate`);
}

export function getUsersParticipantsList(cfg) {
  return apiServer.get('/messages/participants', { params: cfg });
}

export function changeClientStatus(cfg: { status: boolean; id: number }) {
  const { id, ...rest } = cfg;
  return apiServer.post<{ success: boolean; result: { id: string; deactivate: boolean } | null }>(
    `/user/${id}/client-status`,
    rest,
  );
}
