import { combineReducers } from 'redux';

import task from './task';
import timeline from './timeline';
import transaction from './transaction';
import form from './form';
import clause from './clause';

export default combineReducers({
  task,
  timeline,
  transaction,
  form,
  clause,
});
