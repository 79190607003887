import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

import { ValidationSchema } from './validation';

import { PENDING } from 'settings/constants/apiState';

import { onBoardingSignUpEffect } from 'store/effects/onBoarding';
import { getOnBoardingSelector } from 'store/selectors/onBoarding';
import { getUserDataSelector } from 'store/selectors/user';

import { Input, Locations } from 'components';
import { OnboardingContinueButton } from '../components/OnboardingContinueButton';

import { Question } from 'pages/RequestQuote/components';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';

import { createAddress } from 'helpers/utilsTS';

import styles from './styles.module.scss';

const BrokerDetailsForm = ({ stageIndex, onNext, Controls }) => {
  const dispatch = useDispatch();
  const onBoarding = useSelector(getOnBoardingSelector);
  const user = useSelector(getUserDataSelector);

  const formik = useFormik({
    initialValues: {
      ...BrokerDetailsForm.initialValues,
      brokerageName: user?.Agent?.Brokerage?.Name || BrokerDetailsForm.initialValues?.brokerageName,
      address: user?.Agent?.Brokerage?.Address?.Line1
        ? {
            line1: user.Agent.Brokerage.Address.Line1,
            city: user.Agent.Brokerage.Address.City,
            state: user.Agent.Brokerage.Address.State,
            zip: user.Agent.Brokerage.Address.Zip,
            ProviderPlaceId: user.Agent.Brokerage.Address.Line1,
            PlaceName: user.Agent.Brokerage.Address.Line1,
          }
        : BrokerDetailsForm.initialValues?.brokerageName,
      suiteUnit:
        user?.Agent?.Brokerage?.Address?.Line2 || BrokerDetailsForm.initialValues?.suiteUnit,
    },
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit(values) {
      dispatch(
        onBoardingSignUpEffect({ ...values, stageIndex }, {}, (err) => {
          if (!err) {
            onNext();
          }
        }),
      );
    },
  });

  const onOfficeAddressChange = (result) => {
    const location = result?.result?.[0];
    const { data, success } = createAddress(location, result);

    if (success && data) {
      const { city, state, zip, line1, providerPlaceId, placeName } = data;

      formik.setFieldValue('brokerageAddress', {
        city,
        state,
        zip,
        line1,
        ProviderPlaceId: providerPlaceId,
        PlaceName: placeName,
      });
    } else {
      formik.setFieldValue('brokerageAddress', null);
    }
  };

  const isPending = onBoarding.state === PENDING;

  return (
    <div className={styles.stageWrap}>
      <Controls
        onNext={() => formik.submitForm()}
        className={styles.controls}
        variant="lightFull"
      />
      <Question>What brokerage are you with?</Question>
      <AnswersContainer>
        <form onSubmit={formik.handleSubmit}>
          <AnswersContainer>
            <Input
              name="brokerageName"
              value={formik.values.brokerageName}
              onChange={formik.handleChange}
              placeholder="Brokerage Name"
              error={formik.touched.brokerageName ? formik.errors.brokerageName : ''}
              disabled={isPending}
              testid="brokerage_name"
              variant={Input.LIGHT}
              className={styles.formInput}
            />
            <Locations
              searchIcon={false}
              multiple={false}
              placeholder="Office Address"
              types={['address']}
              variant={Locations.LIGHT}
              onResult={onOfficeAddressChange}
              value={formik.values.brokerageAddress ? [formik.values.brokerageAddress] : []}
              error={formik.touched.brokerageAddress ? formik.errors.brokerageAddress : ''}
              testid="office_address"
              className={classNames(styles.formInput, styles.locations)}
              valuesWrapperClassName={styles.valuesWrapper}
              searchWrapperClassName={styles.locationsSearchWrapper}
              placeholderClassName={styles.locationsPlaceholder}
              activeInputClassName={styles.locationInput}
            />
            <Input
              name="brokerageSuiteUnit"
              value={formik.values.brokerageSuiteUnit}
              onChange={formik.handleChange}
              placeholder="Suite / Unit"
              error={formik.touched.brokerageSuiteUnit ? formik.errors.brokerageSuiteUnit : ''}
              disabled={isPending}
              testid="suite_unit"
              variant={Input.LIGHT}
              className={styles.formInput}
            />
          </AnswersContainer>
          <ButtonsContainer>
            <OnboardingContinueButton
              isPending={isPending}
              disabled={!formik.isValid}
              htmlType="submit"
              testid="done"
              className={styles.submit}
            />
          </ButtonsContainer>
        </form>
      </AnswersContainer>
    </div>
  );
};

BrokerDetailsForm.initialValues = {
  brokerageName: '',
  address: null,
  suiteUnit: '',
};

BrokerDetailsForm.propTypes = {
  onNext: PropTypes.func,
  stageIndex: PropTypes.number,
  Controls: PropTypes.elementType,
};

BrokerDetailsForm.defaultProps = {
  onNext: () => {},
  Controls: () => null,
  stageIndex: undefined,
};

export default BrokerDetailsForm;
