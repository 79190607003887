import { routes } from 'settings/navigation/routes';
import { Radar, RadarLogs, RadarPortfolio } from 'pages';
import { AGENT } from 'settings/constants/roles';
import {
  RadarProperty,
  AssignedClients,
  NewSequence,
  RadarKits,
  ViewSequence,
  AssignedClientsView,
} from 'pages/Radar';

export default [
  {
    path: routes.radar,
    component: Radar,
    exact: true,
    roles: [AGENT],
  },
  {
    path: routes.radarLogs,
    component: RadarLogs,
    exact: true,
    roles: [AGENT],
  },
  {
    path: routes.radarPortfolio,
    component: RadarPortfolio,
    exact: true,
    roles: [AGENT],
  },
  {
    path: routes.radarProperty,
    component: RadarProperty,
    exact: true,
    roles: [AGENT],
  },
  {
    path: routes.radarKits,
    component: RadarKits,
    exact: true,
    roles: [AGENT],
  },
  {
    path: routes.radarNewSequence,
    component: NewSequence,
    exact: true,
    roles: [AGENT],
  },
  {
    path: routes.radarViewSequence,
    component: ViewSequence,
    exact: true,
    roles: [AGENT],
  },
  {
    path: routes.radarSequenceAssignedClients,
    component: AssignedClients,
    exact: true,
    roles: [AGENT],
  },
  {
    path: routes.radarSequenceAssignedClientView,
    component: AssignedClientsView,
    exact: true,
    roles: [AGENT],
  },
];
