import classNames from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Badge, Button, Col, Row } from 'components-antd';
import { ArrowLeft } from 'components/Icons';
import Slider from 'react-slick';
import { Icons, FavourtiesHeader } from '../../../common';
import Close from 'components/Close';

import { routes } from 'settings/navigation/routes';
import { setSubFilterTypeAction } from 'store/actions/feed';
import { appOpenModalEffect, resetClientFavoritesPropsEffect } from 'store/effects';
import { getIsFeedFilterDrawerOpenSelector, getSubFilterTypeSelector } from 'store/selectors/feed';

import CriteriaModal from 'pages/Properties/CriteriaModal';
import { getFeedCriteriaSelectorV3 } from 'store/selectors/feedv3';
import styles from './styles.module.scss';
import { getSavedSearchesFormattedSelector } from 'store/selectors/mySearches';
import { cleanSearchQueryObj } from 'store/effects/search/helpers';
import {
  selectedSearchAction,
  setCriteriaAction,
  setCriteriaModeAction,
  setIsClientMatchesAction,
} from 'store/actions/feedv3';
import { Dropdown } from './components/Dropdown';
import SearchesModal from './components/SearchesModal';
import { SaveSearchBtn } from './../SaveSearchBtn';
import { LocationSearch } from 'pages/Properties/ClientSearch/LocationSearch';
import { EDIT } from 'settings/constants/mode';
import { useAgentSearchInstance } from '../../hooks/useAgentSearchInstance';

// NOTE: This is a temp fix, will look at it later!
const subFilterTypes = {
  ALL: 'All',
  MATCHES: 'Matches',
  NEW: 'New',
  OPEN_HOUSES: 'OpenHouses',
  PRICE_REDUCTIONS: 'PriceReductions',
  BACK_ON_THE_MARKET: 'BackOnTheMarket',
  STATUS_CHANGES: 'StatusChanges',
  SUGGESTIONS: 'Suggestions',
  COMMENTS: 'Comments',
};

const TabBtnType = {
  subFilter: 'subFilter',
  tabFilter: 'tabFilter',
};

const CenterTabSectionsNewSearch = [
  {
    id: subFilterTypes.ALL,
    label: 'All',
    icon: Icons.HOUSE,
    type: TabBtnType.subFilter,
  },
  {
    id: subFilterTypes.NEW,
    label: 'New',
    icon: Icons.STAR,
    type: TabBtnType.subFilter,
  },
  {
    id: subFilterTypes.OPEN_HOUSES,
    label: 'Open Houses',
    icon: Icons.OPEN_HOUSES,
    type: TabBtnType.subFilter,
  },
  {
    id: subFilterTypes.PRICE_REDUCTIONS,
    label: 'Price Reductions',
    icon: Icons.PRICE_REDUCTIONS,
    type: TabBtnType.subFilter,
  },
  {
    id: subFilterTypes.BACK_ON_THE_MARKET,
    label: 'Back on Market',
    icon: Icons.BACK_ON_MARKET,
    type: TabBtnType.subFilter,
  },
  {
    id: subFilterTypes.STATUS_CHANGES,
    label: 'Status Changes',
    icon: Icons.STATUS_CHANGES,
    type: TabBtnType.subFilter,
  },
];

const CenterTabSections = [
  {
    id: subFilterTypes.ALL,
    label: 'All',
    icon: Icons.HOUSE,
    type: TabBtnType.subFilter,
  },
  {
    id: subFilterTypes.NEW,
    label: 'New',
    icon: Icons.STAR,
    type: TabBtnType.subFilter,
  },
  {
    id: subFilterTypes.COMMENTS,
    label: 'Comments',
    icon: Icons.COMMENTS,
    type: TabBtnType.subFilter,
  },
  {
    id: subFilterTypes.OPEN_HOUSES,
    label: 'Open Houses',
    icon: Icons.OPEN_HOUSES,
    type: TabBtnType.subFilter,
  },
  {
    id: subFilterTypes.PRICE_REDUCTIONS,
    label: 'Price Reductions',
    icon: Icons.PRICE_REDUCTIONS,
    type: TabBtnType.subFilter,
  },
  {
    id: subFilterTypes.BACK_ON_THE_MARKET,
    label: 'Back on Market',
    icon: Icons.BACK_ON_MARKET,
    type: TabBtnType.subFilter,
  },
  {
    id: subFilterTypes.STATUS_CHANGES,
    label: 'Status Changes',
    icon: Icons.STATUS_CHANGES,
    type: TabBtnType.subFilter,
  },
];

const RightTabSections = [
  {
    label: 'Tours',
    icon: Icons.TOURS,
    route: routes.clientTours,
  },
  {
    label: 'New Search',
    icon: Icons.NEW_SEARCH,
    route: routes.search,
  },
];

export const TabButton = ({
  text,
  icon = '',
  onClick = () => {},
  isActive = false,
  className = '',
  count = 0,
  disabled = false,
}) => (
  <Button
    variant="text-only"
    className={classNames(styles.tabButton, { [styles.active]: isActive }, className)}
    onClick={onClick}
    disabled={disabled}
  >
    <Badge count={count}>
      <Icons variant={icon} />
    </Badge>
    <span className={styles.btnText}>{text}</span>
  </Button>
);

type Props = {
  isSavedSearchResult?: boolean;
  isSimpleHeaderTitleVisible: any;
  setIsSimpleHeaderTitleVisible: Function;
  setIsOpenSearchModal?: Function;
  setShowSavedSearches?: Function;
  setShowBlurBg: Function;
  isPending?: boolean;
};

export const AgentHeader: React.FC<Props> = ({
  isSavedSearchResult = false,
  isSimpleHeaderTitleVisible,
  setIsSimpleHeaderTitleVisible,
  setIsOpenSearchModal = (flag: boolean) => {},
  setShowSavedSearches = (flag: boolean) => {},
  setShowBlurBg,
  isPending = false,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isCriteriaOpen = useSelector(getIsFeedFilterDrawerOpenSelector);
  const { criteria } = useSelector(getFeedCriteriaSelectorV3);
  const subFilterType = useSelector(getSubFilterTypeSelector);
  const { agentSavedSearches } = useSelector(getSavedSearchesFormattedSelector);
  const getAgentSearchInstance = useAgentSearchInstance();

  const onChangeSubFilter = (value) => {
    dispatch(setSubFilterTypeAction(value));
  };

  const CarouselNext = (props) => {
    const { onClick, className } = props;
    return (
      <div className={className} onClick={onClick}>
        <ArrowLeft className={styles.icon} />
      </div>
    );
  };
  const CarouselPrev = (props) => {
    const { onClick, className } = props;
    return (
      <div className={className} onClick={onClick}>
        <ArrowLeft className={styles.icon} />
      </div>
    );
  };

  const settings = {
    draggable: true,
    accessibility: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: isSavedSearchResult ? 4 : 6,
    slidesToScroll: 2,
    nextArrow: <CarouselNext className={styles.arrows} />,
    prevArrow: <CarouselPrev className={styles.arrows} />,
    responsive: [
      {
        breakpoint: 1880,
        settings: {
          slidesToShow: isSavedSearchResult ? 4 : 6,
        },
      },
      {
        breakpoint: 1525,
        settings: {
          slidesToShow: isSavedSearchResult ? 4 : 6,
        },
      },
      {
        breakpoint: 1165,
        settings: {
          slidesToShow: isSavedSearchResult ? 4 : 6,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: isSavedSearchResult ? 4 : 6,
        },
      },
      {
        breakpoint: 790,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  if (isSimpleHeaderTitleVisible?.value) {
    return (
      <FavourtiesHeader
        text={isSimpleHeaderTitleVisible?.text}
        onClose={() => {
          const mySearch = agentSavedSearches?.find(
            (search) => search?.name === 'My Primary Search',
          );
          if (mySearch) {
            dispatch(setCriteriaModeAction({ mode: EDIT }));
            dispatch(
              selectedSearchAction({
                selectedSearch: { id: mySearch.id, name: 'My Primary Search' },
              }),
            );
            dispatch(setIsClientMatchesAction({ isClientMatches: false }));
            getAgentSearchInstance({
              criterias: mySearch?.criterias,
              id: mySearch.id,
            });
          }
          setIsSimpleHeaderTitleVisible({ value: false, text: '' });
          dispatch(resetClientFavoritesPropsEffect());
        }}
      />
    );
  }

  const openModal = (id) => {
    dispatch(appOpenModalEffect({ id, open: true }));
  };

  const onOpenSearch = (showSavedSearches: boolean = true) => {
    setIsOpenSearchModal(true);
    showSavedSearches && setShowSavedSearches(true);
  };

  return (
    <div className={classNames(styles.clientHeader)}>
      <CriteriaModal />
      {!isSavedSearchResult && <SearchesModal setIsOpenSearchModal={setIsOpenSearchModal} />}
      {isSavedSearchResult ? (
        <>
          <Col
            lg={7}
            className={classNames(styles.tabSection, styles.searchInputSection, styles.noBorder)}
          >
            <div className={styles.searchContainer}>
              <LocationSearch
                formClassname={classNames({ [styles.formWrapper]: isSavedSearchResult })}
                valuesWrapperClassName={styles.valuesWrapper}
                valueClassName={styles.locationValue}
                isSearchPage={true}
                isAgent={true}
                disabled={isPending}
              />
            </div>
          </Col>
          <Col lg={4} className={classNames(styles.tabSection)}>
            <SaveSearchBtn disabled={false} isAgent={true} />
            <TabButton
              text="Criteria"
              icon={Icons.SETTINGS}
              onClick={() => openModal(CriteriaModal.id)}
              isActive={isCriteriaOpen}
            />
          </Col>
        </>
      ) : (
        <Col lg={5} className={classNames(styles.tabSection, styles.flexEnd)}>
          <Dropdown
            setShowBlurBg={setShowBlurBg}
            setIsSimpleHeaderTitleVisible={setIsSimpleHeaderTitleVisible}
          />
          <TabButton
            text="Criteria"
            icon={Icons.SETTINGS}
            onClick={() => {
              openModal(CriteriaModal.id);
            }}
            isActive={isCriteriaOpen}
            count={
              criteria
                ? Object.values(cleanSearchQueryObj(criteria))?.filter((item) => Boolean(item))
                    ?.length
                : 0
            }
            disabled={isPending}
          />
        </Col>
      )}
      <Col
        lg={isSavedSearchResult ? 9 : 14}
        className={classNames(styles.tabSection, styles.scrollable)}
      >
        <Slider {...settings} className={classNames(styles.subfeedSlider)}>
          {(isSavedSearchResult ? CenterTabSectionsNewSearch : CenterTabSections).map(
            (item, idx) => (
              <TabButton
                key={idx}
                text={item.label}
                icon={item.icon}
                onClick={() => onChangeSubFilter(item.id)}
                isActive={subFilterType === item?.id}
              />
            ),
          )}
        </Slider>
      </Col>
      {isSavedSearchResult ? (
        <Col lg={2} className={classNames(styles.tabSection, styles.noBorder)}>
          <div className={styles.closeBtn}>
            <Close
              onClick={() => {
                const mySearch = agentSavedSearches?.find(
                  (search) => search?.name === 'My Primary Search',
                );
                if (mySearch) {
                  dispatch(setCriteriaModeAction({ mode: EDIT }));
                  dispatch(
                    selectedSearchAction({
                      selectedSearch: { id: mySearch.id, name: 'My Primary Search' },
                    }),
                  );
                  dispatch(setIsClientMatchesAction({ isClientMatches: false }));
                  getAgentSearchInstance({
                    criterias: mySearch?.criterias,
                    id: mySearch.id,
                  });
                }
                history.push(routes.feed);
              }}
            />
          </div>
        </Col>
      ) : (
        <Col
          lg={isSavedSearchResult ? 4 : 5}
          className={classNames(styles.tabSection, styles.noBorder)}
        >
          {RightTabSections.map((item, idx) => (
            <TabButton
              key={idx}
              text={item.label}
              icon={item.icon}
              className={styles.rightTabSectionBtn}
              onClick={() => {
                if (item.label === 'New Search') {
                  return setIsOpenSearchModal(true);
                }
                if (item.route) history.push(item?.route);
              }}
            />
          ))}
        </Col>
      )}
    </div>
  );
};
