import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Name = ({ testid, name, className, children, editBtn = null }) => (
  <p testid={testid} className={classNames(styles.name, className)}>
    <span>
      {name} {editBtn}
    </span>
    {children}
  </p>
);

Name.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  testid: PropTypes.string,
  children: PropTypes.node,
  editBtn: PropTypes.node,
};

Name.defaultProps = {
  className: '',
  testid: undefined,
  children: null,
  editBtn: null,
};

export default Name;
