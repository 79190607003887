import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Moment } from 'moment';

import { MultiSelect } from 'components-antd/MultiSelect';
import { Drawer, Spinner } from 'components';
import { Button, Col, Option, Row } from 'components-antd';
import { DateRangePicker } from 'pages/Workshop/Activity/components/DateRangePicker';
import { Header } from './Header';
import { Footer } from '../Footer';
import { Icons } from '../../Icons';

import {
  getIsActivityFiltersDrawerOpenSelector,
  getActivityFiltersDrawerFieldsSelector,
} from 'store/selectors/drawers/activityFilters';
import {
  openActivityFiltersDrawerEffect,
  resetActivityFiltersDrawerFieldsEffect,
  setActivityFiltersDrawerEndDateFieldEffect,
  setActivityFiltersDrawerPropertiesFieldEffect,
  setActivityFiltersDrawerStartDateFieldEffect,
  setActivityFiltersDrawerUsersFieldEffect,
} from 'store/effects/drawers/activityFilters';
import {
  requestGetActivityLogsEffect,
  resetActivityLogsItemsListEffect,
} from 'store/effects/activityLogsWorkshop';
import { relatedEntitiesCompleteListGetDataEffect } from 'store/effects/relatedEntities';
import {
  getRelatedEntitiesCompleteListState,
  getRelatedEntitiesHttpState,
} from 'store/selectors/relatedEntities';
import { getFormattedFilterObject } from 'utils/activityLogsHelper';
import { activityLogsPageSize } from 'app-constants/activityLogs';
import { PENDING } from 'settings/constants/apiState';

import styles from './styles.module.scss';

export const constructPropertyAddress = (address) => {
  const components = [
    `${address.Line1 ?? ''} ${address.Line2 ?? ''}`.trim(),
    address.City,
    address.State,
    address.Zip,
  ];
  return components.filter((c) => !!c).join(', ');
};
export const ActivityFiltersDrawer: React.FC = () => {
  const dispatch = useDispatch();
  const open = useSelector(getIsActivityFiltersDrawerOpenSelector);
  const relatedEntities = useSelector(getRelatedEntitiesCompleteListState);
  const relatedEntitiesHttpStatus = useSelector(getRelatedEntitiesHttpState);
  const fieldsPayload = useSelector(getActivityFiltersDrawerFieldsSelector);

  useEffect(() => {
    if (open) dispatch(relatedEntitiesCompleteListGetDataEffect());
  }, [open]);

  const getHeader = () => (
    <Header
      title="Activity Filters"
      onClose={() => dispatch(openActivityFiltersDrawerEffect({ open: false }))}
    />
  );

  const onDateChangeHandler = (_from: Moment, _to: Moment) => {
    dispatch(
      setActivityFiltersDrawerStartDateFieldEffect({
        data: { startDate: _from },
      }),
    );
    dispatch(
      setActivityFiltersDrawerEndDateFieldEffect({
        data: { endDate: _to },
      }),
    );
  };

  const renderUsersList = () => {
    const list: any = [];
    relatedEntities?.length &&
      relatedEntities
        ?.filter((entity) => entity.Type === 'Contact')
        ?.forEach((p) => {
          const name = `${p.FirstName} ${p.LastName}`;
          list.push(<Option value={p.ContactUserId}>{name}</Option>);
        });

    return list;
  };

  const renderPropertyList = () => {
    const list: any = [];
    relatedEntities?.length &&
      relatedEntities
        ?.filter((entity) => entity.Type === 'Property')
        ?.forEach((p) => {
          p.ExternalId &&
            list.push(
              <Option key={p.Id} value={p.ExternalId}>
                {constructPropertyAddress(p.Address)}
              </Option>,
            );
        });

    return list;
  };

  const onUserSelectHandleChange = (value) => {
    dispatch(
      setActivityFiltersDrawerUsersFieldEffect({
        data: { users: value },
      }),
    );
  };

  const onPropertiesSelectHandleChange = (value) => {
    dispatch(
      setActivityFiltersDrawerPropertiesFieldEffect({
        data: { properties: value },
      }),
    );
  };

  const onDone = (payload) => {
    dispatch(resetActivityLogsItemsListEffect());
    dispatch(
      requestGetActivityLogsEffect(getFormattedFilterObject(payload, 1, activityLogsPageSize)),
    );
    dispatch(openActivityFiltersDrawerEffect({ open: false }));
  };

  const onResetAll = () => {
    dispatch(resetActivityFiltersDrawerFieldsEffect({}));
    onDone({});
  };

  return (
    <Drawer header={getHeader()} isOpen={open} className={styles.activityFilterDrawer}>
      <Row className={styles.filterContainer} justify="start" align="stretch">
        {relatedEntitiesHttpStatus !== PENDING ? (
          <Col span={24}>
            <MultiSelect
              size="large"
              title="Users"
              placeholderText="All Users"
              variant="user"
              onHandleChange={onUserSelectHandleChange}
              propClass={styles.activityDrawerMultiselect}
              optionFilterProp="children"
              values={fieldsPayload?.users}
            >
              {renderUsersList()}
            </MultiSelect>

            <MultiSelect
              size="large"
              title="Properties"
              placeholderText="All Properties"
              onHandleChange={onPropertiesSelectHandleChange}
              propClass={styles.activityDrawerMultiselect}
              optionFilterProp="children"
              values={fieldsPayload?.properties}
            >
              {renderPropertyList()}
            </MultiSelect>
            <DateRangePicker onDatesChange={onDateChangeHandler} />
          </Col>
        ) : (
          <Spinner loaderClassName={styles.loader} />
        )}
      </Row>
      <Footer>
        <Row justify="space-between" align="middle">
          <Col span={12}>
            <span className={classNames(styles.resetSpan, 'show-cursor')} onClick={onResetAll}>
              <Icons className={styles.resetIcon} variant={'reset'} />
              <span className={styles.resetAllText}>Reset All</span>
            </span>
          </Col>
          <Col span={12}>
            <Button
              variant="secondary"
              className={styles.submitButton}
              title="Done"
              onClick={() => onDone(fieldsPayload)}
            >
              <span className={styles.btnText}>Done</span>
            </Button>
          </Col>
        </Row>
      </Footer>
    </Drawer>
  );
};
