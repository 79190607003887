import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Close } from 'components/Icons';
import Icon from 'pages/Workshop/Transactions/TransactionTasks/Icons';
import { AssignToInput } from 'components/Transactions';
import { useSelector, useDispatch } from 'react-redux';
import { getTransactionTaskSelector } from 'store/selectors/transactionTask';
import {
  getTransactionEffect,
  updateTransactionTaskEffect,
  getTransactionTaskEffect,
} from 'store/effects/transactions';
import { getUserId } from 'store/selectors/user';
import { AssignItem, AssignTitle } from '..';

import styles from './styles.module.scss';
import { getTransactionSelector } from 'store/selectors/transaction';
import { useLocation } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';
import { showSuccessMessage } from 'helpers';
import { getTaskAggregateEffect } from 'store/effects/taskAggregate';
import { requestGetCampaignByIdEffect, updateCampaignTaskEffect } from 'store/effects/radarKits';
import { pathToRegex } from 'settings/navigation/helpers';

const AssignTo = (props) => {
  const { className } = props;
  const dispatch = useDispatch();
  const { task } = useSelector(getTransactionTaskSelector);
  const { transaction } = useSelector(getTransactionSelector);
  const userId = useSelector(getUserId);
  const [edit, setEdit] = useState(false);
  const [selectedAssigneeList, setSelectedAssigneeList] = useState([]);
  const { pathname } = useLocation();

  const onEdit = () => {
    setSelectedAssigneeList([]);
    setEdit(true);
  };

  const onBlur = () => {
    setEdit(false);
    const config = {
      Id: task?.Id,
      AssigneeList: selectedAssigneeList || task?.AssigneeList,
      ...(task?.CampaignId && { CampaignId: task?.CampaignId }),
    };
    const effect = task?.CampaignId ? updateCampaignTaskEffect : updateTransactionTaskEffect;
    dispatch(
      effect(config, {}, (err) => {
        /// Refetch list silently in case of rules task addition
        if (pathname !== routes.tasks && transaction.Id) {
          dispatch(getTransactionEffect({ id: transaction?.Id }, { silent: true }));
          dispatch(
            getTransactionTaskEffect(
              { transactionId: transaction.Id, taskId: task?.Id },
              { silent: true },
            ),
          );
        } else if (routes.tasks === pathname) {
          dispatch(getTaskAggregateEffect({}, { silent: true }));
        } else if (pathname.match(pathToRegex(routes.radarViewSequence)) && task?.CampaignId) {
          dispatch(requestGetCampaignByIdEffect(task?.CampaignId, { silent: true }));
        }
      }),
    );
  };

  const onClose = () => {
    setEdit(false);
  };

  const getName = () => {
    if (!task) return '';
    const { AssigneeList } = task || {};
    const assignee = AssigneeList?.[0] || {};
    const names = AssigneeList?.map((item) => `${item?.FirstName} ${item?.LastName}`);
    let name = `${assignee.FirstName} ${assignee.LastName} ${
      AssigneeList?.length > 1 ? ` +${AssigneeList?.length - 1}` : ''
    }`;

    const layout = (
      <div className={classNames(styles.nameHolder, { [styles.alignStart]: names?.length > 1 })}>
        <div testid="assignee">
          {names?.map((name, idx) => (
            <div key={idx} className={styles.name}>
              {name}
            </div>
          ))}
        </div>
        {task?.Assignor?.Id === userId ? (
          <Icon
            testid="edit_icon"
            onClick={onEdit}
            className={styles.editIcon}
            variant={Icon.EDIT_DARK}
          />
        ) : null}
      </div>
    );

    return { layout, text: name };
  };

  return (
    <div testid="assigned_to" className={classNames(styles.assignTo, className)}>
      <AssignTitle className={styles.to}>Assigned to:</AssignTitle>
      {edit ? (
        <div className={styles.inputHolder}>
          <AssignToInput
            className={styles.input}
            wrapperClassName={styles.inputWrapper}
            onBlur={onBlur}
            onChange={(e, val) => setSelectedAssigneeList(val)}
            values={selectedAssigneeList}
            isCampaignTask={task?.CampaignId}
          />
          <Close onClick={onClose} className={styles.closeIcon} />
        </div>
      ) : (
        <AssignItem name={getName()} listItems={task?.AssigneeList} />
      )}
    </div>
  );
};

AssignTo.propTypes = {
  className: PropTypes.string,
};

AssignTo.defaultProps = {
  className: '',
};

export default AssignTo;
