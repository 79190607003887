import { Row, Col } from 'antd';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { TeamFilter } from '../Filters/TeamFilter';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { TEAM_BASIC } from 'settings/constants/roles';
import { DateRangeFilter } from '../Filters/DateRangeFilter';
import { getTimePeriodSelector } from 'store/selectors/clarity';
import { Close } from 'components/Icons';
import { setExpandChartAction, setShowSubChartsAction } from 'store/actions/clarity';
import { TransactionFilter } from '../Filters/TransactionFilter';

import styles from './styles.module.scss';

export const Header = ({ title = 'Metrics', showFilters = true }) => {
  const dispatch = useDispatch();
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const timePeriod = useSelector(getTimePeriodSelector);

  const onClose = () => {
    dispatch(setShowSubChartsAction({ value: false }));
    dispatch(setExpandChartAction(null));
  };
  return (
    <div className={classNames(styles.clarityHeader)}>
      <Row justify="center">
        <Col xs={24} lg={8} className={styles.switcherWrapper}>
          <span className={styles.title}>{title}</span>
        </Col>
        {showFilters ? (
          <Col xs={24} lg={8} className={styles.filterWrapper}>
            <DateRangeFilter defaultOption={timePeriod} />
            {agentRole !== TEAM_BASIC && <TeamFilter />}
            <TransactionFilter />
          </Col>
        ) : (
          <Col xs={24} lg={8} className={styles.filterWrapper}>
            <div className={styles.closeButton} onClick={onClose}>
              <Close />
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};
