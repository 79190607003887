import Api from 'store/effects/core/api';
import { get } from 'lodash-es';
import { createTransactionTaskAction, createTaskCategoryAction } from 'store/actions/transactions';
import { getState } from 'store';
import {
  createTransactionManyTask,
  createTransactionTask,
  createTaskCategory,
} from 'api/transactions';
import { handlePostData, handleData } from '../helpers';
import { omit } from 'lodash';

export const createTransactionTaskEffect = (cfg, options, cb) => {
  const state = getState();
  const transactionId = get(state, 'transaction.data.Id');
  const requestParams = { action: createTransactionTaskAction, method: createTransactionTask };
  const sendRequest = Api.execResult(requestParams);
  const config = handlePostData(transactionId, cfg);

  return sendRequest(config, options, cb);
};

export const createTransactionManyTaskEffect = (cfg, options, cb) => {
  if (cfg?.length) {
    const state = getState();
    const transactionId = get(state, 'transaction.data.Id');
    const requestParams = {
      action: createTransactionTaskAction,
      method: createTransactionManyTask,
    };
    const sendRequest = Api.execResult(requestParams);
    const config = cfg.map((task) =>
      omit(handleData(transactionId, task), ['transactionId', 'SaveAsTemplate']),
    );

    return sendRequest({ tasks: config, transactionId }, options, cb);
  }
};

export const createTransactionTaskCategoryEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: createTaskCategoryAction,
    method: createTaskCategory,
  });

  return sendRequest(cfg, {}, cb);
};
