import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { AgentDocumentType, AgentDocumentTypes, DocumentMetaKey, DocumentOptionUtils } from 'types';
import { Dots } from 'components/Icons';
import { MenuIconTypes, MenuIcons } from 'components/ClientsDrawer/ProfileClient/Icons';
import { Menu, MenuItemType, MenuItem, Dropdown } from 'components-antd';
import { getUserId } from 'store/selectors/user';
import styles from './styles.module.scss';

interface OptionProps extends DocumentOptionUtils {
  document_: AgentDocumentType;
  type: AgentDocumentTypes;
}

export const Options = ({
  document_,
  type,
  optionUtils: {
    downloadDocument,
    previewDocument,
    openFormDetailsModal,
    handleOpenDeleteDocumentModal,
    shareDocument,
    openMessageThread,
  },
}: OptionProps) => {
  const userId = useSelector(getUserId);

  const getOptionList = useCallback(() => {
    const items: MenuItemType[] = [];

    const metaKey = DocumentMetaKey?.[type] || '';

    const { isDeleteAble = false, isTransactionDocument = false } =
      metaKey && document_?.[metaKey]
        ? document_[metaKey]
        : { isDeleteAble: false, isTransactionDocument: false };

    const isDraftORPending =
      type === AgentDocumentTypes.Drafts || type === AgentDocumentTypes.PendingForms;
    const isMessageAttachments = type === AgentDocumentTypes.MessageAttachments;

    const deleteObject = {
      key: 'Option-Delete',
      title: 'Delete',
      icon: <MenuIcons type={MenuIconTypes.Delete} />,
      onClick: () => handleOpenDeleteDocumentModal(document_.id, type, document_),
    };

    if (isMessageAttachments) {
      const documentVaultUUID = document_.documentVaultUUID;
      const messageThreadId = document_?.[metaKey]?.messageThreadId;

      items.push(
        {
          key: 'Option-Open-Thread',
          title: 'Open Thread',
          icon: <MenuIcons type={MenuIconTypes.Comment} />,
          onClick: () => documentVaultUUID && openMessageThread(documentVaultUUID, messageThreadId),
        },
        {
          key: 'Option-Download',
          title: 'Download',
          icon: <MenuIcons type={MenuIconTypes.Download} />,
          onClick: () => documentVaultUUID && downloadDocument(documentVaultUUID),
        },
      );
    } else if (isDraftORPending && document_?.[metaKey]?.formProcessId) {
      const formProcessId = document_?.[metaKey]?.formProcessId;

      items.push({
        key: 'Option-View',
        title: 'View',
        icon: <MenuIcons type={MenuIconTypes.View} />,
        onClick: () => (formProcessId ? openFormDetailsModal(formProcessId, type) : undefined),
      });
    } else if (document_.documentVaultUUID) {
      const documentVaultUUID = document_.documentVaultUUID;
      items.push(
        {
          key: 'Option-View',
          title: 'View',
          icon: <MenuIcons type={MenuIconTypes.View} />,
          onClick: () => previewDocument(documentVaultUUID, document_?.title),
        },
        {
          key: 'Option-Download',
          title: 'Download',
          icon: <MenuIcons type={MenuIconTypes.Download} />,
          onClick: () => downloadDocument(documentVaultUUID),
        },
        {
          key: 'Option-Share',
          title: 'Share',
          icon: <MenuIcons type={MenuIconTypes.Share} />,
          onClick: () => shareDocument(documentVaultUUID),
        },
      );
    }

    if (userId == document_.uploaderId && isDeleteAble && !isDraftORPending) {
      items.push(deleteObject);
    }
    return items;
  }, [document]);

  const optionsList = getOptionList();

  if (!optionsList.length) return <></>;

  const menu = (
    <Menu>
      {optionsList.map(({ key, title, ...props }, index) => (
        <MenuItem
          key={`${key}-${document_.title}-${index}`}
          title={title}
          {...props}
          className={styles.menuItem}
        >
          {title}
        </MenuItem>
      ))}
    </Menu>
  );

  const getPopupContainer = (triggerNode) => {
    const parentElement = triggerNode.parentElement;
    // Ensure it returns an HTMLElement, otherwise fallback to document.body or a specific element
    return parentElement instanceof HTMLElement ? parentElement : document.body;
  };

  return (
    <Dropdown
      overlay={menu}
      overlayClassName={styles.menuOverlay}
      placement="bottomRight"
      getPopupContainer={getPopupContainer}
    >
      <a onClick={(e) => e.preventDefault()}>
        <Dots />
      </a>
    </Dropdown>
  );
};
