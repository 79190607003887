import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { getTransactionTaskParamsSelector } from 'store/selectors/transactionTask';
import { TagInput } from 'components/Transactions';
import { TaskModalHeader } from './TaskModalHeader';
import { TaskName } from './TaskName';
import { AssignedTo } from './AssignedTo';
import { CcTo } from './CcTo';
import { DueDate } from './DueDate';
import { Description } from './Description';
import { Links } from './Links';
import { Checklists } from './Checklists';
import { Files } from './Files';
import { Modal, ModalProps } from 'components-antd';
import { Button } from 'components-antd';
import { Button as OptionButton } from 'antd';
import { useOutsideClick } from 'hooks';
import Icon from 'pages/Workshop/Transactions/TransactionTasks/Icons';

import { TaskTemplate, TimelineMilestones } from 'types';

import styles from './styles.module.scss';
import { MoreOptionItem } from './MoreOptionItem/MoreOptionItem';
import { getTimelineTemplateEffect, getTransactionRolesEffect } from 'store/effects';
import { AssignedToMilestone } from './AssignedToMilestone';
import { orderBy } from 'lodash-es';
import { TimelineMilestonesInterface } from './AssignedToMilestone/types/TransactionMilestone';
import { convertToInitialOffset } from 'utils/templatesHelper';

interface TransactionTemplateTaskModalProps {
  task?: TaskTemplate;
  onSave: (task) => void;
  modelProps: ModalProps;
  isViewMode: boolean;
  data: TimelineMilestonesInterface[];
}

export const TransactionTemplateTaskModal = ({
  task,
  onSave,
  modelProps: { className, ...modelProps },
  isViewMode = false,
  data = [],
}: TransactionTemplateTaskModalProps) => {
  const { formValues } = useSelector(getTransactionTaskParamsSelector);
  const [values, setNewFormValues] = useState<TaskTemplate>(task ?? formValues);

  const [showCC, setShowCC] = useState(!!task?.CcRoles?.length);
  const [open, setOpen] = useState(false);
  const [addChecklists, setAddChecklists] = useState(!!task?.Checklists?.length);
  const [addLink, setAddLinks] = useState(!!task?.Links?.length);
  const [addFiles, setAddFiles] = useState(!!task?.Documents?.length);
  const [addTag, setAddTag] = useState(!!task?.Category);
  const [addDescription, setAddDescription] = useState(!!task?.Description);
  const [showMdDropdown, setShowMdDropdown] = useState(false);

  const buttonRef = useRef<any>();
  const optionsRef = useRef<any>();
  useOutsideClick([buttonRef, optionsRef], () => setOpen(false));
  const dispatch = useDispatch();
  const onFormValueChange = (values) => {
    setNewFormValues(values);
  };

  useEffect(() => {
    if (values?.Initial === 'MD') {
      setShowMdDropdown(true);
    } else {
      setShowMdDropdown(false);
    }
    dispatch(getTransactionRolesEffect());
  }, []);

  const onChangeField = (val, fieldName) => {
    onFormValueChange({ ...(values || {}), [fieldName]: val });
  };

  const onChangeManyFields = (data) => {
    onFormValueChange({ ...(values || {}), ...data });
    if (data?.Initial === 'MD') {
      setShowMdDropdown(true);
    } else {
      setShowMdDropdown(false);
    }
  };
  const setCustomTag = (val) => {
    if (val?.trim()) {
      onChangeField(val, 'customTag');
    }
  };

  const handleSave = () => {
    onSave({ ...values });
  };

  const checkDisabled = () => {
    if (values) {
      if (showMdDropdown && !values.MilestonePublicId) {
        return true;
      }

      const hasDueDate = values.DueDate || values.DueDate === 0;

      let isDisabled = !(values?.Name && values.AssigneeRoles?.length && hasDueDate) || isViewMode;

      if (isDisabled && !isViewMode && values.ControlOperatorOffset) {
        const { DueDate }: any = convertToInitialOffset(
          values.ControlOperatorOffset,
          () => {},
          undefined,
          true,
        );

        return isDisabled && !(DueDate || DueDate === 0);
      } else {
        return isDisabled;
      }
    }

    return true;
  };

  const getTitle = (text: string) => (isViewMode ? `View ` : `Add `) + text;

  return (
    <div className={classNames(styles.taskModalForm)}>
      <Modal
        width={675}
        footer={null}
        maskClosable={false}
        destroyOnClose
        className={classNames(className, styles.taskModal)}
        {...modelProps}
      >
        <TaskModalHeader
          isTransactionTask={true}
          isTemplate={true}
          isNew={!!task}
          showSubheading={false}
        />
        <div className={classNames(styles.content, styles.taskModalBody)}>
          <TaskName
            isTemplate={true}
            values={values}
            onChangeField={onChangeField}
            hideAsterisk={true}
            isViewMode={isViewMode}
          />
          <div>
            <AssignedTo
              isTemplate={true}
              values={values}
              onChangeField={onChangeField}
              className={''}
              isAssignTask={false}
              hideAsterisk={true}
              label="Assign To"
              isViewMode={isViewMode}
              setShowCC={setShowCC}
              showCC={showCC}
            />
            {showCC ? (
              <CcTo
                isTemplate={true}
                values={values}
                onChangeField={onChangeField}
                className={''}
                isAssignTask={false}
                showOptional={true}
                isViewMode={isViewMode}
              />
            ) : (
              <></>
            )}
          </div>
          <DueDate
            isTemplate={true}
            values={values}
            onChangeManyFields={onChangeManyFields}
            hideAsterisk={true}
            isViewMode={isViewMode}
          />
          {showMdDropdown && (
            <div className={styles.mdDropdownContainer}>
              <AssignedToMilestone
                isTemplate={true}
                values={values}
                onChangeField={onChangeField}
                className={''}
                isAssignTask={false}
                hideAsterisk={true}
                label="Milestone"
                isViewMode={isViewMode}
                data={data}
              />
            </div>
          )}
          <TagInput
            onSelect={(val) =>
              onFormValueChange({ ...(values || {}), Category: val, customTag: '' })
            }
            onSelectCustomTag={setCustomTag}
            value={values}
            showOptional={true}
            disabled={isViewMode}
            className={styles.tagSelectorContainer}
            addTag={addTag}
          />
          <Description
            values={values}
            onChangeField={onChangeField}
            showOptional={true}
            isViewMode={isViewMode}
            addDescription={addDescription}
          />
          <Checklists
            values={values}
            editData={task}
            onChangeField={onChangeField}
            showOptional={true}
            isViewMode={isViewMode}
            addChecklists={addChecklists}
          />
          <Links
            values={values}
            editData={task}
            onChangeField={onChangeField}
            showOptional={true}
            isViewMode={isViewMode}
            addLink={addLink}
          />
          <Files
            isAssignTask={false}
            isTemplate={true}
            values={values}
            onChangeField={onChangeField}
            showOptional={true}
            isViewMode={isViewMode}
            addFiles={addFiles}
          />

          <div className={styles.moreOptionsContainer}>
            {!(addDescription && addLink && addTag && addFiles) && (
              <OptionButton
                onClick={() => setOpen(!open)}
                className={styles.moreOptionsBtn}
                icon={<Icon variant={Icon.ADD} />}
                type="text"
                ref={buttonRef}
              >
                More Options
              </OptionButton>
            )}

            {open && (
              <div ref={optionsRef} className={styles.options}>
                <ul>
                  {!addDescription && (
                    <MoreOptionItem
                      onClick={() => {
                        setAddDescription(true);
                        setOpen(false);
                      }}
                      title={getTitle('Description')}
                    />
                  )}
                  {!addTag && (
                    <MoreOptionItem
                      onClick={() => {
                        setAddTag(true);
                        setOpen(false);
                      }}
                      title={getTitle('Tags')}
                    />
                  )}
                  {!addChecklists && (
                    <MoreOptionItem
                      onClick={() => {
                        setAddChecklists(true);
                        setOpen(false);
                      }}
                      title={getTitle('Checklists')}
                    />
                  )}
                  {!addLink && (
                    <MoreOptionItem
                      onClick={() => {
                        setAddLinks(true);
                        setOpen(false);
                      }}
                      title={getTitle('Links')}
                    />
                  )}
                  {!addFiles && (
                    <MoreOptionItem
                      onClick={() => {
                        setAddFiles(true);
                        setOpen(false);
                      }}
                      title={getTitle('Files')}
                    />
                  )}
                </ul>
              </div>
            )}
          </div>

          {!isViewMode ? (
            <div className={styles.taskModalFooter}>
              <Button
                variant="secondary"
                onClick={handleSave}
                disabled={!!checkDisabled()}
                className={classNames(styles.footerButton, {
                  [styles.disabledButton]: checkDisabled(),
                })}
              >
                {task ? 'Save' : 'Create'}
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </Modal>
    </div>
  );
};
