import { useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';

import { ConfirmationDialog } from 'components';
import { TEAM_ADMIN, TEAM_BASIC } from 'settings/constants/roles';
import { AgentStatus } from 'types';
import { showSuccessMessage } from 'helpers/success';
import { requestGetSymphonyListEffect, updateClientStatusEffect } from 'store/effects';
import { AGENT_CONNECTION_STATUSES } from 'settings/constants/common';
import { Select } from 'components';

import { AGENT_TYPE } from 'settings/constants/drawers';
import { getAgentTypeSelector, getUserId } from 'store/selectors/user';
import { useOutsideClick } from 'hooks';
import { postCancelInviteClientById, postReinviteClientById } from 'api/clientsList';

import styles from './styles.module.scss';
import { requestActiveSubscriptionTeamDataEffect } from 'store/effects/subscription';

export const Status = ({ contact }) => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const agentType = useSelector(getAgentTypeSelector);
  const dispatch = useDispatch();
  const buttonRef = useRef(null);
  const optionsRef = useRef(null);
  const userId = useSelector(getUserId);
  const isClient = contact.Client?.Id;

  useOutsideClick([buttonRef, optionsRef], () => setOpen(false));

  const onStatusChange = (obj) => {
    if (
      (obj.name === AgentStatus.Inactive && !contact?.Deactivated) ||
      (obj.name === AgentStatus.Active && contact?.Deactivated)
    )
      setOpenConfirmModal(true);
  };

  const onSubmitConfirmModal = () => {
    setOpenConfirmModal(false);
    dispatch(
      updateClientStatusEffect(
        {
          status: !contact?.Deactivated,
          id: contact.Id,
        },
        undefined,
        (err, resp) => {
          if (!err) {
            dispatch(requestGetSymphonyListEffect({}, { silent: true }));
            dispatch(requestActiveSubscriptionTeamDataEffect({}, { showError: false }));
            showSuccessMessage(
              `${isClient ? 'Client' : 'Agent'} ${
                !contact?.Deactivated ? 'Deactivated' : 'Activated'
              }.`,
            );
          }
        },
      ),
    );
  };

  const changeUserStatusContent = useMemo(() => {
    const isActive = isClient ? !contact?.Deactivated : contact?.IsActive;

    return contact ? (
      <div className={styles.userStatusConfirmText}>
        <h1>{isActive ? 'Deactivate' : 'Activate'} Account</h1>
        <p>
          {contact?.FirstName} will {isActive ? 'no longer have' : 'regain'} access to this account.
        </p>
      </div>
    ) : null;
  }, [contact]);

  const resendInvite = async () => {
    if (loading) return;
    setLoading(true);
    const result = await postReinviteClientById({ id: contact.Id });
    if (result?.data?.success) showSuccessMessage('Resent invite');
    setLoading(false);
  };

  const cancelInvite = async () => {
    if (loading) return;
    setLoading(true);
    const result = await postCancelInviteClientById({ id: contact.Id });
    if (result?.data?.success) showSuccessMessage('Invite Canceled');
    setLoading(false);
  };

  const renderPendingStatus = (inviteStatus) => (
    <div
      className={styles.pendingContainer}
      ref={buttonRef}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <div>
        <span className={styles.pendingStatus}>{inviteStatus}</span>
        <span className={styles.lastInvite}>
          Last Invite: {moment(contact.InviteSentAt).format('MM/DD/YYYY [at] hh:mm A')}
        </span>
      </div>
      <div className={styles.arrowIcon}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M3.50012 6L8.00012 10.5L12.5001 6"
            stroke="#747475"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      {open && (
        <div ref={optionsRef} className={styles.options}>
          <ul>
            <li testid="resend_action" className={styles.item} onClick={resendInvite}>
              <div className={styles.icon}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M3.0201 9.03009C2.92223 10.1718 3.18343 11.3156 3.7672 12.3017C4.35097 13.2878 5.22816 14.0669 6.27624 14.5302C7.32432 14.9935 8.49094 15.1179 9.61314 14.886C10.7353 14.6541 11.7571 14.0774 12.5356 13.2366C13.3142 12.3958 13.8107 11.3328 13.9558 10.1961C14.1008 9.05936 13.8872 7.90573 13.3448 6.89633C12.8024 5.88692 11.9582 5.07215 10.9302 4.56582C9 3.61512 6 4 6 4M6 4L9 1M6 4L9 6.89633"
                    stroke="#FF576D"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <span>Resend</span>
            </li>
            <li testid="cancel_action" className={styles.item} onClick={cancelInvite}>
              <div className={styles.icon}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M2.99927 13L13.0003 2.99893"
                    stroke="#FF576D"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M13.0007 13L2.99966 2.99893"
                    stroke="#FF576D"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <span>Cancel Invite</span>
            </li>
          </ul>
        </div>
      )}
    </div>
  );

  const getContactStatus = () => {
    if (isClient) {
      const inviteStatus = contact.Client?.InvitationStatus;
      if (inviteStatus === AGENT_CONNECTION_STATUSES.ACTIVE) {
        if (!contact?.Deactivated) return 'Active';
        else return 'Inactive';
      } else if (inviteStatus === AGENT_CONNECTION_STATUSES.PENDING) {
        return renderPendingStatus(inviteStatus);
      }

      return inviteStatus;
    }

    return contact?.IsActive ? 'Active' : 'Inactive';
  };

  const editable = !isClient
    ? agentType === AGENT_TYPE.Team &&
      [TEAM_ADMIN, TEAM_BASIC].includes(contact?.TeamRole) &&
      (contact.Id === userId || contact.IsManagedByCurrentUser)
    : true;

  const value = getContactStatus();

  return (
    <>
      {editable ? (
        <div className={classNames(styles.item, styles.statusItem, styles.expanded)}>
          <Select
            value={{ value, name: value }}
            onSelect={(e, value) => onStatusChange(value)}
            options={[
              { value: AgentStatus.Active, name: AgentStatus.Active },
              { value: AgentStatus.Inactive, name: AgentStatus.Inactive },
            ]}
            className={{
              wrapper: styles.statusWrapper,
            }}
            arrowIconClassName={styles.arrowIcon}
          />
        </div>
      ) : (
        <div className={styles.value}>{value || '-'}</div>
      )}
      <ConfirmationDialog
        onReject={() => setOpenConfirmModal(false)}
        onConfirm={onSubmitConfirmModal}
        isOpen={openConfirmModal}
        confirmText="Continue"
      >
        {changeUserStatusContent}
      </ConfirmationDialog>
    </>
  );
};
