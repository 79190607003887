import { createSelector } from 'reselect';
import { filter } from 'lodash-es';
import { PartnerCategory } from 'types';

import { PENDING, IDLE } from 'settings/constants/apiState';

const localState = ({ servicesCategories }) => servicesCategories;

export const getLoadingServicesCategoriesData = createSelector(localState, ({ state, data }) => ({
  state,
  isInit: state === IDLE,
  isLoading: state === PENDING,
  isData: !!data,
}));

export const getAllServicesCategoriesList = createSelector(
  localState,
  (state) => state?.data || [],
);

export const getFilteredServicesCategoriesList = (type: PartnerCategory) =>
  createSelector(getAllServicesCategoriesList, (data) => filter(data, ({ Type }) => Type === type));
