import { get } from 'lodash-es';
import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { getDataFor } from 'store/helpers';

import {
  optimisticActiveSubscriptionTeamDataAction,
  requestActiveSubscriptionTeamDataAction,
} from 'store/actions/subscription';

const initialData: any = {
  state: IDLE,
  data: [],
  meta: {},
};

export default handleActions(
  {
    [requestActiveSubscriptionTeamDataAction.toString()]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, ['data', 'result']),
      meta: getDataFor('meta', payload, initialData),
    }),
    [optimisticActiveSubscriptionTeamDataAction.toString()]: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        TotalSeatsCount: state.data?.TotalSeatsCount + 1,
        AdditionallyPaidSeatsCount: state.data?.AdditionallyPaidSeatsCount + 1,
        BilledSeatsCount: state.data?.BilledSeatsCount + 1,
      },
    }),
  },
  initialData,
);
