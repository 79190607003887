import { useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { Menu, MenuItem, Dropdown, Radio } from 'components-antd';
import { ArrowDown, Check } from 'components/Icons';
import { setTransactionFilterAction } from 'store/actions/clarity';
import { getTransactionFilter } from 'store/selectors/clarity';

import styles from './styles.module.scss';

export const TransactionFilter = () => {
  const dispatch = useDispatch();
  const transactionFilter = useSelector(getTransactionFilter);
  const dropdownFields = ['All Transactions', 'Listings', 'Purchases'];

  const [value, setValue] = useState(dropdownFields[transactionFilter]);

  const onChange = (e) => {
    const value = e.target.value;
    if (value === dropdownFields[0]) {
      dispatch(setTransactionFilterAction({ value: 0 }));
    } else if (value === dropdownFields[1]) {
      dispatch(setTransactionFilterAction({ value: 1 }));
    } else if (value === dropdownFields[2]) {
      dispatch(setTransactionFilterAction({ value: 2 }));
    }
    setValue(value);
  };

  const menu = (
    <Radio.Group
      onChange={onChange}
      value={value}
      className={styles.optionsItems}
      defaultValue={value}
    >
      <Menu>
        {dropdownFields.map((item, idx) => {
          return (
            <MenuItem key={idx}>
              <Radio value={item} key={idx}>
                <div className={styles.option}>
                  <span className={styles.optionValue}>{item}</span>
                </div>
                {value === item && <Check className={styles.checkIcon} />}
              </Radio>
            </MenuItem>
          );
        })}
      </Menu>
    </Radio.Group>
  );

  return (
    <div className={classNames(styles.filterWrap)}>
      <Dropdown
        overlay={menu}
        placement="bottomRight"
        getPopupContainer={(triggerNode) => triggerNode}
        trigger={['click']}
        overlayClassName={styles.teamFilterDropdown}
      >
        <a
          onClick={(e) => e.preventDefault()}
          className={classNames(styles.filterButton, 'mosaikDropdownButton')}
        >
          <span className={styles.optionValue}>{value}</span>
          <ArrowDown className={classNames(styles.icon, { [styles.open]: open })} size={16} />
        </a>
      </Dropdown>
    </div>
  );
};
