import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import prettyBytes from 'pretty-bytes';
import { useSelector } from 'react-redux';
import { Input } from 'components';
import { Select } from 'components-antd';
import Icon from 'pages/Workshop/Transactions/TransactionCreate/Icons';
import { NameAutocomplete } from 'pages/Workshop/Transactions/TransactionFinancials/components';

import { getMetaVaultDocuments } from 'store/selectors/vault';

import styles from './styles.module.scss';

const ItemVault = ({
  size,
  filename,
  TransactionOrFolder = '',
  onDelete,
  documentName,
  onChangeDocumentName,
  type,
  onChangeType,
  onChangeTransactionOrFolder,
  isPreapprovals = false,
  isClientProfile = false,
  clientName = '',
  data: fileData,
}) => {
  const params: any = useParams();
  const { data } = useSelector(getMetaVaultDocuments);
  const { categories, clientActiveTransactions } = data || {};

  useEffect(() => {
    if (isPreapprovals) {
      onChangeType(null, 6); // Change the type/category to Preapproval.
    }
  }, [isPreapprovals, type]);

  const transactions = useMemo(
    () =>
      clientActiveTransactions?.map((item) => ({
        label: item?.Property?.Address?.PlaceName,
        value: item?.TransactionId,
      })),
    [clientActiveTransactions],
  );

  useEffect(() => {
    // Pre-populate the address bar with folder name OR transaction.
    if (!isPreapprovals && !TransactionOrFolder) {
      if (params?.id?.includes('folder-')) {
        onChangeTransactionOrFolder(null, params?.id?.replace('folder-', ''));
      } else {
        const transactionItem = transactions?.find((item) => item?.value == params?.id);
        const transactionLabel = transactionItem?.label || '';
        onChangeTransactionOrFolder(null, transactionLabel);
      }
    }
  }, [fileData]);

  const formattedCategories = useMemo(() => {
    return categories
      ?.filter(
        (item) => !(!isClientProfile && isPreapprovals === false && item?.Title === 'Pre-Approval'),
      )
      .map((item) => ({ label: item?.Title, value: item?.Id }));
  }, [categories, isPreapprovals]);

  const handleAddressChange = (value) => {
    onChangeTransactionOrFolder(null, value?.label);
  };

  const isPreApprovalCategorySelected = useMemo(() => type === 6, [type]);

  return (
    <div className={styles.itemVaultContainer}>
      <div className={styles.contentWrapper}>
        <div className={styles.uploadedFile}>
          <div className={styles.fileName}>
            <Icon className={styles.icon} variant={Icon.FILE_SIMPLE} />
            <div className={styles.filename}>
              <span testid="file_name" className={styles.fileTitle}>
                {filename}
              </span>
              <span testid="size" className={styles.fileSize}>
                ({prettyBytes(size)})
              </span>
            </div>
          </div>
          <Icon
            testid="delete_icon"
            onClick={onDelete}
            className={styles.iconDelete}
            variant={Icon.DELETE}
          />
        </div>
      </div>
      <div>
        <p className={styles.label}>File Name</p>
        <Input
          variant={Input.LIGHT_ROUNDED}
          placeholder="Document name"
          onChange={onChangeDocumentName}
          value={documentName}
          className={styles.input}
          testid="document_name"
          maxLength={50}
          inputClassName={styles.marginBottom}
        />
      </div>
      <div>
        <p className={styles.label}>Type</p>
        <Select
          placeholder="Select Type"
          options={formattedCategories}
          className={styles.typeSelectWrapper}
          dropdownStyle={{ zIndex: 10000 }}
          value={type}
          onChange={(value) => onChangeType(null, value)}
          disabled={isPreapprovals}
        />
      </div>
      <div>
        {!isPreapprovals && !isPreApprovalCategorySelected && (
          <>
            <p className={styles.label}>Address</p>
            {isClientProfile ? (
              <Select
                placeholder="Select Address"
                options={transactions}
                className={classNames(styles.typeSelectWrapper, styles.marginBottom)}
                dropdownStyle={{ zIndex: 10000 }}
                value={TransactionOrFolder}
                onChange={(value) => {
                  const transactionSelected = transactions?.find((item) => item?.value === value);
                  onChangeTransactionOrFolder(null, transactionSelected?.label);
                }}
              />
            ) : (
              <NameAutocomplete
                allNames={transactions}
                value={TransactionOrFolder || ''}
                onChange={handleAddressChange}
                onSelect={handleAddressChange}
                disabled={isPreapprovals}
              />
            )}
          </>
        )}
        <div className={styles.infoContainer}>
          <Icon variant={Icon.INFO} />
          <p className={styles.infoText}>
            <strong>
              No one (other than you{isClientProfile ? ` and ${clientName}` : ''}) will be able to
              access or view this file
            </strong>{' '}
            unless you choose to share it with {!isClientProfile ? 'your agent or' : ''} others.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ItemVault;
