import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { cloneDeep } from 'lodash-es';

import { priceConverter } from 'helpers';
import { DateRangeFilter } from 'pages/ClarityRevamped/Components/Filters/DateRangeFilter';
import { TableViewFilter } from 'pages/ClarityRevamped/Components/Filters/TableViewFilter';
import { StatusFilter } from '../../Components/StatusFilter';
import { Wrapper as PendingWrapper } from 'components';
import { useEffect, useState } from 'react';
import { ClarityLineChart } from 'components/ClarityLineChart';
import { ModalHeader } from '../../Components/ModalHeader/ModalHeader';
import { AggregateResultHeader } from '../../Components/ModalHeader';
import { BreakdownMenu } from '../../../../BreakdownMenu';
import TableView from '../../Components/TableView/TableView';
import CompareModal from '../../Components/CompareModals/CompareModal';
import { getAgentTeamStatsSelector, getTimePeriodSelector } from 'store/selectors/clarity';
import { GeneralLedgerKeys } from '../helper';
import { LedgerAccountId } from 'settings/constants/ledger';
import { modalTableColumns } from '../../Components/TableView/TableModal/Financial';
import { useFetchFinancialStats } from 'pages/ClarityRevamped/hooks';
import { ClarityBarChart } from 'components/ClarityBarChart';
import { LineChart, BarChart } from 'components/Icons';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { TEAM_BASIC } from 'settings/constants/roles';

import styles from '../../styles.module.scss';

export const TeamNetIncome = () => {
  const [isGraphView, setIsGraphView] = useState<boolean>(true);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [openCompareModal, setOpenCompareModal] = useState<boolean>(false);
  const [isPending, setIsPending] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [toggleGraph, setToggleGraph] = useState(false);
  const [summary, setSummary] = useState<any>({});
  const [data, setData] = useState([]);
  const [breakdownSummary, setBreakdownSummary] = useState<any>([]);
  const [breakdownList, setBreakdownList] = useState<any>([]);
  const [selectedAgent, setSelectedAgent] = useState<any>(null);
  const [teamSummary, setTeamSummary] = useState<any>([]);
  const timePeriod = useSelector(getTimePeriodSelector);
  const teamStats = useSelector(getAgentTeamStatsSelector);
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const color = '#FF576D';

  const getStats = useFetchFinancialStats();

  useEffect(() => {
    getStats({
      setSummary,
      setData,
      setIsPending,
      key: GeneralLedgerKeys.TeamPayment,
      aggregationType: 'Net',
      summaryType: 'Splits',
      aggregateDataFormat: isGraphView ? 'Series' : 'Tabular',
      memo: null,
      ledgerAccountId: LedgerAccountId.TeamPayment,
      year: null,
      quarter: null,
      month: null,
      isoWeek: null,
      date: null,
      transactionAgentUserId: selectedAgent?.TransactionAgentUserId,
      transactionAgentContactId: selectedAgent?.TransactionAgentContactId,
    });
  }, [timePeriod, isGraphView, selectedAgent]);

  useEffect(() => {
    if ('Total' in summary && firstRender && teamStats.itemValue === 'Team')
      setTeamSummary(summary);
  }, [summary]);

  useEffect(() => {
    getStats({
      setSummary: setBreakdownSummary,
      setData: () => {},
      setIsPending: () => {},
      key: GeneralLedgerKeys.TeamPayment,
      aggregationType: 'Net',
      summaryType: 'Splits',
      aggregateDataFormat: 'Series',
      memo: null,
      ledgerAccountId: LedgerAccountId.TeamPayment,
      year: null,
      quarter: null,
      month: null,
      isoWeek: null,
      date: null,
      agentSummary: true,
    });
  }, [timePeriod]);

  useEffect(() => {
    if (breakdownSummary.length && 'Total' in teamSummary) {
      if (agentRole === TEAM_BASIC || 'userId' in teamStats) {
        setBreakdownList([{ ...breakdownSummary[0], selected: true }]);
      } else if (!selectedAgent)
        setBreakdownList([
          {
            TransactionAgent: 'Team',
            Total: teamSummary?.Total || 0,
            NumberTransactions: teamSummary?.NumberTransactions || 0,
            Percentage: 100,
            selected: true,
          },
          ...breakdownSummary,
        ]);
      else {
        const newList = cloneDeep([
          {
            TransactionAgent: 'Team',
            Total: teamSummary?.Total || 0,
            NumberTransactions: teamSummary?.NumberTransactions || 0,
            Percentage: 100,
          },
          ...breakdownSummary,
        ]);
        newList[selectedAgent[1]] = { ...selectedAgent[0], selected: true };
        setBreakdownList(newList);
      }
    } else {
      setBreakdownList([]);
    }
  }, [breakdownSummary, teamSummary]);

  useEffect(() => {
    if (firstRender && breakdownList?.length) {
      setOpenMenu(true);
      setFirstRender(false);
    } else if (!breakdownList?.length) setOpenMenu(false);
  }, [breakdownList]);

  const handleClick = (agent, index) => {
    let newList;
    if (agentRole === TEAM_BASIC || 'userId' in teamStats) return;
    if (agent?.TransactionAgent === 'Team') {
      setSelectedAgent(null);
      newList = cloneDeep([
        {
          TransactionAgent: 'Team',
          Total: teamSummary?.Total || 0,
          NumberTransactions: teamSummary?.NumberTransactions || 0,
          Percentage: 100,
          selected: true,
        },
        ...breakdownSummary,
      ]);
    } else {
      setSelectedAgent(agent);
      newList = cloneDeep([
        {
          TransactionAgent: 'Team',
          Total: teamSummary?.Total || 0,
          NumberTransactions: teamSummary?.NumberTransactions || 0,
          Percentage: 100,
        },
        ...breakdownSummary,
      ]);
      newList[index] = { ...agent, selected: true };
    }
    setBreakdownList(newList);
  };

  const getGraph = () => {
    return toggleGraph || timePeriod === 'MTD' || timePeriod === 'WEEK' ? (
      <ClarityBarChart
        data={data}
        timePeriod={timePeriod}
        colors={[color]}
        title={'Team Net Income'}
        aggregationType={'Net'}
        summaryType={'SplitsExpanded'}
        summaryKey={GeneralLedgerKeys.TeamPayment}
        columnType={modalTableColumns.TeamGrossNet}
        ledgerAccountId={LedgerAccountId.TeamPayment}
        selectedAgent={selectedAgent}
        clarityType={'Financials'}
      />
    ) : (
      <ClarityLineChart
        data={data}
        timePeriod={timePeriod}
        colors={[color]}
        title={'Team Net Income'}
        aggregationType={'Net'}
        summaryType={'SplitsExpanded'}
        summaryKey={GeneralLedgerKeys.TeamPayment}
        columnType={modalTableColumns.TeamGrossNet}
        ledgerAccountId={LedgerAccountId.TeamPayment}
        selectedAgent={selectedAgent}
        clarityType={'Financials'}
      />
    );
  };

  return (
    <PendingWrapper isPending={false} className={styles.clarityContentWrapper}>
      <div className={styles.headerRows}>
        <ModalHeader title={'Team Net Income'} />
        <div className={styles.infoRow}>
          <AggregateResultHeader
            totalAmount={priceConverter(summary?.Total || 0)}
            totalRequests={summary?.NumberTransactions}
            textColor={{ color: color }}
          />
          <div className={styles.filtersContainer}>
            <DateRangeFilter defaultOption={timePeriod} />
            <div
              className={classnames(styles.breakdownBtn, { [styles.openMenu]: openMenu })}
              onClick={() => setOpenMenu(!openMenu)}
            >
              Breakdown
            </div>
            {/* <StatusFilter options={['All', ...dropdownList]} cb={() => {}} /> */}
            <TableViewFilter
              setIsGraphView={setIsGraphView}
              openCompareModal={() => setOpenCompareModal(true)}
              setIsPending={setIsPending}
            />
          </div>
        </div>
      </div>
      <div className={styles.bodyRow}>
        <PendingWrapper
          isPending={isPending}
          className={classnames(
            styles.graphContainer,
            { [styles.openMenu]: isGraphView && openMenu },
            { [styles.tableContainer]: !isGraphView && openMenu },
          )}
        >
          {isGraphView ? (
            <div className={styles.graphContent}>
              {(timePeriod === 'YTD' || timePeriod === 'QTD') && (
                <div className={styles.graphToggleContainer}>
                  <div
                    className={styles.grpahToggleBtn}
                    onClick={() => setToggleGraph(!toggleGraph)}
                  >
                    {toggleGraph ? <LineChart /> : <BarChart />}
                  </div>
                </div>
              )}
              {getGraph()}
            </div>
          ) : (
            <TableView
              open={openMenu}
              data={data}
              title={'Team Net Income'}
              aggregationType={'Net'}
              summaryType={'SplitsExpanded'}
              summaryKey={GeneralLedgerKeys.TeamPayment}
              columnType={modalTableColumns.TeamGrossNet}
              ledgerAccountId={LedgerAccountId.TeamPayment}
              selectedAgent={selectedAgent}
              clarityType={'Financials'}
            />
          )}
        </PendingWrapper>
        <div className={classnames(styles.breakdownMenu, { [styles.openMenu]: openMenu })}>
          <BreakdownMenu
            open={openMenu}
            list={breakdownList}
            handleClick={handleClick}
            color={color}
            onClose={() => setOpenMenu(false)}
            title={'Team Net Income'}
            aggregationType={'Net'}
            summaryType={'SplitsExpanded'}
            summaryKey={GeneralLedgerKeys.TeamPayment}
            columnType={modalTableColumns.TeamGrossNet}
            ledgerAccountId={LedgerAccountId.TeamPayment}
            selectedAgent={selectedAgent}
            clarityType={'Financials'}
          />
        </div>
      </div>
      {/* <CompareModal open={openCompareModal} onClose={() => setOpenCompareModal(false)} /> */}
    </PendingWrapper>
  );
};
