import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Table, TableProps } from 'antd';
import classnames from 'classnames';

import { getTimePeriodSelector } from 'store/selectors/clarity';
import { ClarityTimePeriod } from '../../../../../helper';
import { TableModal } from './TableModal/TableModal';
import { DataType } from './helper';

import styles from './styles.module.scss';

enum ClarityTimePeriodIndex {
  YTD = 'MonthNumber',
  QTD = 'ISOWeek',
  MTD = 'Date',
}

enum timePeriodCompare {
  YTD = 'M',
  QTD = 'weeks',
  MTD = 'DD-MM-YY',
}

type TableViewProps = {
  open: boolean;
  data: any[];
  title: string;
  aggregationType?: string;
  summaryType: string;
  summaryKey?: string;
  columnType?: string;
  ledgerAccountId?: number | null;
  memo?: string | null;
  selectedAgent?: any | null;
  clarityType: string;
};

const TableView: React.FC<TableViewProps> = ({
  open,
  data,
  title,
  aggregationType,
  summaryType,
  summaryKey,
  columnType,
  ledgerAccountId = null,
  memo = null,
  selectedAgent = null,
  clarityType,
}: TableViewProps) => {
  const timePeriod = useSelector(getTimePeriodSelector);
  const [rowValue, setRowValue] = useState<DataType>();
  const [openModal, setOpenModal] = useState(false);

  const formatTableData = (data): DataType[] =>
    data?.map((row, index) => {
      return {
        key: index,
        [ClarityTimePeriod[timePeriod]]: row[ClarityTimePeriod[timePeriod]],
        value:
          clarityType === 'Financials'
            ? `$${row.TotalValue.toLocaleString()}`
            : `$${row.TotalVolume.toLocaleString()}`,
        index: row[ClarityTimePeriodIndex[timePeriod]],
        trend: row?.Trend.toLocaleString(),
        numberTransactions: row.NumberTransactions,
        year: row.Year,
        month: row?.MonthNumber,
        isoWeek: row?.ISOWeek,
        date: row?.Date,
      };
    }) || [];

  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.year;
          const b = record2.year;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: ClarityTimePeriod[timePeriod],
      dataIndex: ClarityTimePeriod[timePeriod],
      key: ClarityTimePeriod[timePeriod],
      sorter: {
        compare: (record1, record2) => {
          const a = moment(record1.index, timePeriodCompare[timePeriod]).format('DD MMM YYYY');
          const b = moment(record2.index, timePeriodCompare[timePeriod]).format('DD MMM YYYY');
          return moment(a).isAfter(b) ? -1 : moment(b).isAfter(a) ? 1 : 0;
        },
      },
    },
    {
      title: 'Transactions',
      dataIndex: 'numberTransactions',
      key: 'numberTransactions',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.numberTransactions;
          const b = record2.numberTransactions;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: 'Amount',
      dataIndex: 'value',
      key: 'value',
      sorter: {
        compare: (record1, record2) => {
          const a = parseInt(record1.value.replace(/[^0-9.]/g, ''));
          const b = parseInt(record2.value.replace(/[^0-9.]/g, ''));
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: 'Trend',
      dataIndex: 'trend',
      key: 'trend',
      render: (trend) => {
        return trend.includes('-') ? (
          <span className={styles.negative}>{`${trend}%`}</span>
        ) : (
          <span className={styles.positive}>{trend === '0' ? trend : `+${trend}%`}</span>
        );
      },
    },
  ];

  return (
    <div className={classnames(styles.tableContainer, { [styles.openMenu]: open })}>
      <Table
        className={styles.statsTable}
        onRow={(record, rowIndex) => {
          return {
            onClick: (e) => {
              setRowValue(record);
              setOpenModal(true);
            },
          };
        }}
        columns={columns}
        dataSource={formatTableData(data)}
        pagination={false}
        sticky={true}
      />
      {openModal && (
        <TableModal
          title={title}
          aggregationType={aggregationType}
          summaryType={summaryType}
          summaryKey={summaryKey}
          columnType={columnType}
          ledgerAccountId={ledgerAccountId}
          memo={memo}
          year={rowValue?.year}
          month={rowValue?.month}
          isoWeek={rowValue?.isoWeek}
          open={openModal}
          onCancel={() => setOpenModal(false)}
          selectedAgent={selectedAgent}
          clarityType={clarityType}
        />
      )}
    </div>
  );
};

export default TableView;
