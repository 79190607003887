import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'components-antd';
import { DatePicker } from 'components';
import styles from './styles.module.scss';
import moment from 'moment';
import { getDateOnly } from 'helpers';

const FastForwardClockModal = ({ open, onClose, onAdvance, frozenDate = new Date() }) => {
  const [selectedDate, setSelectedDate] = useState(
    frozenDate ? moment(getDateOnly(frozenDate)).toDate() : null,
  );

  const onAdvanceClick = () => {
    if (selectedDate) {
      onAdvance(selectedDate);
    }
  };

  return (
    <Modal width={400} open={open} footer={null} onCancel={onClose} className={styles.clockModal}>
      <div className={styles.modalContent}>
        <h2 className={styles.modalHeader}>Advance the clock</h2>
        <p className={styles.modalDescription}>
          Advance the clock to see the events and changes that occur. Select a date to advance the
          clock to that date in the future.
        </p>
        <div className={styles.datePickerWrapper}>
          <DatePicker
            name={`frozenDate`}
            className={styles.inputContainer}
            variant={DatePicker.ROUNDED}
            options={{ enableTime: false, minDate: frozenDate }}
            value={getDateOnly(selectedDate)}
            onChange={(e, val) => setSelectedDate(val?.[0])}
            allowTypedInput={true}
            allowPlugin={false}
            format={'m/d/Y'}
          />
        </div>

        <div className={styles.modalFooter}>
          <Button onClick={onClose} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button
            type="primary"
            disabled={!selectedDate}
            onClick={onAdvanceClick}
            className={styles.advanceButton}
          >
            Advance
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default FastForwardClockModal;
