import styles from './styles.module.scss';

export const EmptyChartPlaceholder = () => (
  <div className={styles.emptyPlaceholderChartsContainer}>
    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M38.5 36H16.5C14.2909 36 12.5 34.2091 12.5 32V10"
        stroke="#AAABAB"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5 26L25.1164 19.8448C25.8466 18.8711 27.27 18.77 28.1306 19.6306L30.8694 22.3694C31.73 23.23 33.1534 23.1289 33.8836 22.1552L38.5 16"
        stroke="#AAABAB"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>

    <span>No Data!</span>
  </div>
);
