import React, { useMemo, useState } from 'react';
import { Table } from '../Table';
import styles from './styles.module.scss';
import ReactApexChart from 'react-apexcharts';
import { DropdownCheck, Wrapper } from 'components';
import moment from 'moment';
import { AddressValuationDetails } from 'api/intel/types';
import NumberFormat from 'react-number-format';
import { appOpenModalEffect } from 'store/effects';
import ValuesModal from './ValuesModal';
import { useDispatch, useSelector } from 'react-redux';
import { getUserRolesMapSelector } from 'store/selectors/user';
import classNames from 'classnames';

type Props = {
  valuationData: AddressValuationDetails[];
  isPending?: boolean;
  params: any;
  rental?: boolean;
};

export const ValuationCard: React.FC<Props> = ({
  valuationData,
  isPending = false,
  params,
  rental = false,
}) => {
  const { isAgent } = useSelector(getUserRolesMapSelector);
  const dispatch = useDispatch();
  const [dataType, setDataType] = useState('attom');
  const [dateFrom, setDateFrom] = useState(
    new Date(new Date().setMonth(new Date().getMonth() - 6)),
  );

  const handleDateChange = (val) => {
    const today = new Date();
    let fromDate;

    switch (val) {
      case 'YTD':
        fromDate = new Date(today.getFullYear(), 0, 1);
        break;
      case '6M':
        fromDate = new Date(today.setMonth(today.getMonth() - 6));
        break;
      case '1Y':
        fromDate = new Date(today.setFullYear(today.getFullYear() - 1));
        break;
      case '2Y':
        fromDate = new Date(today.setFullYear(today.getFullYear() - 2));
        break;
      case 'MAX':
        fromDate = new Date(1970, 0, 1); // Or use another earliest date as needed
        break;
      default:
        fromDate = null;
    }

    setDateFrom(fromDate);
  };

  const openModal = (id) => {
    dispatch(appOpenModalEffect({ id, open: true }));
  };

  const homeValueData = useMemo(() => {
    if (!valuationData) return null;
    const valueToGet = rental ? 'rentalValue' : 'homeValue';

    const filteredValuationData = valuationData
      ?.filter((obj) => obj?.source === dataType && obj?.[valueToGet])
      ?.sort((a, b) => {
        const dateA = new Date(a.year, a.month - 1);
        const dateB = new Date(b.year, b.month - 1);

        return (dateB as any) - (dateA as any);
      });

    const estimatedValue = filteredValuationData[0]?.[valueToGet] ?? 0;

    // Calculate percentage change
    const prevValue = filteredValuationData[1]?.[valueToGet] ?? 0;
    const percChange: number = prevValue
      ? Number(
          (((Number(estimatedValue) - Number(prevValue)) / Number(prevValue)) * 100).toFixed(1),
        )
      : 0;

    const formattedPercChange = percChange > 0 ? `+${percChange}%` : `${percChange}%`;

    const changeType = percChange > 0 ? 'increase' : 'decrease';

    return {
      estimatedValue,
      percChange: formattedPercChange,
      changeType,
      historyAttom: filteredValuationData.map((item, index, arr) => {
        const nextItem = arr[index + 1]; // Get the next item instead of the previous one
        const currentValue = parseFloat(item?.[valueToGet] ?? '');
        const nextValue = nextItem ? parseFloat(nextItem?.[valueToGet] ?? '') : null;

        let change: string = '';
        let changeType: string = '';

        if (nextValue && currentValue) {
          const diff = currentValue - nextValue;
          change = ((diff / nextValue) * 100).toFixed(2);

          if (diff > 0) {
            changeType = 'increase';
            change = `+${change}%`;
          } else if (diff < 0) {
            changeType = 'decrease';
            change = `${change}%`;
          }
        }

        return {
          month: item.month,
          year: item.year,
          value: currentValue,
          change,
          changeType,
        };
      }),
    };
  }, [valuationData, dataType, dateFrom, rental]);

  return (
    <div className={styles.valuationCard}>
      {isAgent && <ValuesModal rental={rental} params={params} key={`${rental ? '1' : '2'}`} />}
      <Wrapper isPending={isPending}>
        <div className={styles.valuationCardHeader}>
          <div className={styles.headingWrapper}>
            <div className={styles.title}>Estimated {rental ? 'Rental' : ''} Value</div>
            <div className={styles.value}>
              <NumberFormat
                thousandSeparator
                displayType="text"
                value={homeValueData?.estimatedValue}
                prefix="$"
              />
            </div>
            <div className={styles.percChange}>
              <span className={styles[homeValueData?.changeType ?? '']}>
                {homeValueData?.percChange}
              </span>{' '}
              since last month
            </div>
          </div>
          <div className={styles.optionsWrapper}>
            <DropdownCheck
              className={styles.dropdown}
              defaultValue={'attom'}
              dropdownFields={[
                { value: 'attom', label: 'ATTOM' },
                { value: 'manual', label: 'Manual' },
              ]}
              onChange={(val) => setDataType(val)}
              prefixLabel={'Source: '}
            />
            <DropdownCheck
              className={styles.dropdown}
              defaultValue={'6M'}
              dropdownFields={[
                { value: 'YTD', label: 'YTD' },
                { value: '6M', label: '6M' },
                { value: '1Y', label: '1Y' },
                { value: '2Y', label: '2Y' },
                { value: 'MAX', label: 'MAX' },
              ]}
              onChange={handleDateChange}
            />
            {isAgent && (
              <div
                className={styles.addButton}
                onClick={() => {
                  openModal(rental ? 'valuesModalRental' : 'valuesModalHome');
                }}
              >
                <span>+</span> Add
              </div>
            )}
          </div>
        </div>
        <div id="chart">
          <ReactApexChart
            options={{
              chart: {
                type: 'line',
                width: '100%',
                toolbar: {
                  show: false,
                },
                zoom: {
                  enabled: false,
                },
              },
              stroke: {
                width: 1,
              },
              xaxis: {
                categories: homeValueData?.historyAttom.map(({ month }) =>
                  moment().month(month).format('MMM'),
                ),
              },
              legend: {
                floating: true,
                position: 'top',
                horizontalAlign: 'right',
              },
            }}
            series={[
              {
                name: 'Attom',
                data: homeValueData?.historyAttom.map(({ value }) => value) ?? [],
              },
            ]}
            type="line"
            height={160}
          />
        </div>
        <Table historyAttom={homeValueData?.historyAttom ?? []} />
      </Wrapper>
    </div>
  );
};
