import { Skeleton, Tabs } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { getPreApprovalDocumentsWithManualEntries } from 'api/mySearches';
import { Modal } from 'components-antd';
import { LocationService } from 'services';
import { PENDING } from 'settings/constants/apiState';
import { AGENT, CLIENT } from 'settings/constants/roles';
import { routes } from 'settings/navigation/routes';
import { getAgentDetailsEffect, getClientDetailsEffect } from 'store/effects';
import { setProfileExpandedSection } from 'store/effects/app';
import { getAgentDetailsSelector } from 'store/selectors/agentDetail';
import { isProfileExpandedSectionOPened } from 'store/selectors/app';
import { getClientDetailsSelector } from 'store/selectors/clientDetail';
import { getUserId } from 'store/selectors/user';
import { ActiveSearches } from '../ActiveSearches';
import { ActiveTransactions } from '../ActiveTransactions';
import { ClientPreApprovals } from '../ClientPreApprovals';
import Contact from '../Contact';
import { ProfileIcons } from '../Icons';
import { ConnectionActivity } from './Activity';
import { Clients } from './Clients';
import { Documents } from './Documents';
import ExpandedHeader from './ExpandedHeader';
import { Tasks } from './Tasks';
import { Transactions } from './Transactions';

import { EditModalContent } from './EditModalContent';
import styles from './styles.module.scss';

const locationSrv = new LocationService();

const ProfileExpanded = (props) => {
  const { id, isAgent, activeTab, isModalOpened, setModalClose } = props;
  const isExpandedOpened = useSelector(isProfileExpandedSectionOPened) || isModalOpened;
  const details = useSelector(isAgent ? getAgentDetailsSelector : getClientDetailsSelector);
  const contact = details?.data || {};
  const [tabKey, setTabKey] = useState('0');
  const [isLoading, setIsLoading] = useState(true);
  const [isPreApproved, setIsPreApproved] = useState(true);
  const [showApprovalsModal, setShowApprovalsModal] = useState(false);
  const [documents, setDocuments] = useState([]);

  const dispatch = useDispatch();
  const userId = useSelector(getUserId);

  useEffect(() => {
    if (activeTab) {
      setTabKey(activeTab.toString());
      const query = locationSrv.getQuery();
      history.replace(
        locationSrv.setQuery({
          ...query,
          activeTab: undefined,
          profileClientId: undefined,
          profileAgentId: undefined,
        }),
      );
    }
  }, [activeTab]);

  const history = useHistory();
  const location = useLocation();
  locationSrv.setLocation(location);

  useEffect(() => {
    const query = locationSrv.getQuery();
    if (query.tab) {
      setTabKey('' + query.tab);
      if (!query.type) history.replace(routes.symphony);
    }
  }, [location, isExpandedOpened]);

  const getConnectionEffect = () => (isAgent ? getAgentDetailsEffect : getClientDetailsEffect);

  const getConnectionDetails = useCallback(() => {
    dispatch(getConnectionEffect()({ id }, {}, (err) => setIsLoading(false)));
  }, [dispatch]);

  useEffect(() => {
    getConnectionDetails();
  }, []);
  const getPreApprovalDocuments = async () => {
    const documents = await getPreApprovalDocumentsWithManualEntries({
      clientId: id,
      agentId: userId,
    });
    if (documents?.status === 200 && documents?.data?.success) {
      const documentsData = documents?.data?.result ?? [];
      setDocuments(documentsData);
      setIsPreApproved(!!documentsData?.length);
    }
  };

  useEffect(() => {
    if (!isAgent) {
      getPreApprovalDocuments();
    }
  }, []);

  const getTabs = (type) => {
    switch (type) {
      case CLIENT:
        return [
          {
            key: '0',
            name: 'Profile',
            icon: ProfileIcons.PROFILE,
            label: (
              <span
                className={classNames(styles.tabsIconProfile, {
                  [styles.clientTabIconProfile]: !isAgent,
                })}
              >
                <ProfileIcons
                  name={ProfileIcons.PROFILE}
                  color={'0' == tabKey ? '#262626' : '#AAABAB'}
                />
                <div
                  className={classNames(styles.tabName, {
                    [styles.inactive]: '0' != tabKey,
                  })}
                >
                  Profile
                </div>
              </span>
            ),
            children: [<Contact />],
          },
          {
            key: '1',
            name: 'Instances',
            icon: ProfileIcons.SEARCH,
            label: (
              <span
                className={classNames(styles.tabsIconProfile, {
                  [styles.clientTabIconProfile]: !isAgent,
                })}
              >
                <ProfileIcons
                  name={ProfileIcons.SEARCH}
                  color={'1' == tabKey ? '#262626' : '#AAABAB'}
                />
                <div
                  className={classNames(styles.tabName, {
                    [styles.inactive]: '1' != tabKey,
                  })}
                >
                  Instances
                </div>
              </span>
            ),
            children: [
              <div className={styles.InstancesWrapper}>
                <ActiveTransactions
                  transactions={contact?.Client?.TransactionInstances}
                  view={'expanded'}
                />
                <ActiveSearches
                  activeSearches={contact?.Client?.SearchInstances}
                  clientId={contact?.Client?.Id}
                  view={'expanded'}
                />
                <ClientPreApprovals
                  clientId={id}
                  showApprovalsModal={showApprovalsModal}
                  setShowApprovalsModal={setShowApprovalsModal}
                  documents={documents}
                  setDocuments={setDocuments}
                />
              </div>,
            ],
          },
          {
            key: '2',
            name: 'Files',
            icon: ProfileIcons.DOCUMENTS,
            children: [<Documents closeAgentDetails={handleCloseModal} />],
            label: (
              <span
                className={classNames(styles.tabsIconProfile, {
                  [styles.clientTabIconProfile]: !isAgent,
                })}
              >
                <ProfileIcons
                  name={ProfileIcons.DOCUMENTS}
                  color={'2' == tabKey ? '#262626' : '#AAABAB'}
                />
                <div
                  className={classNames(styles.tabName, {
                    [styles.inactive]: '2' != tabKey,
                  })}
                >
                  Files
                </div>
              </span>
            ),
          },
          {
            key: '3',
            name: 'Activity',
            icon: ProfileIcons.ACTIVITY,
            children: [<ConnectionActivity isAgent={isAgent} />],
            label: (
              <span
                className={classNames(styles.tabsIconProfile, {
                  [styles.clientTabIconProfile]: !isAgent,
                })}
              >
                <ProfileIcons
                  name={ProfileIcons.ACTIVITY}
                  color={'3' == tabKey ? '#262626' : '#AAABAB'}
                />
                <div
                  className={classNames(styles.tabName, {
                    [styles.inactive]: '3' != tabKey,
                  })}
                >
                  Activity
                </div>
              </span>
            ),
          },
        ];
      case AGENT:
        return [
          {
            key: '0',
            name: 'Profile',
            icon: ProfileIcons.PROFILE,
            children: [<Contact isAgent={isAgent} />],
            label: (
              <span
                className={classNames(styles.tabsIconProfile, {
                  [styles.clientTabIconProfile]: !isAgent,
                })}
              >
                <ProfileIcons
                  name={ProfileIcons.PROFILE}
                  color={'0' == tabKey ? '#262626' : '#AAABAB'}
                />
                <div
                  className={classNames(styles.tabName, {
                    [styles.inactive]: '0' != tabKey,
                  })}
                >
                  Profile
                </div>
              </span>
            ),
          },
          {
            key: '1',
            name: 'Clients',
            icon: ProfileIcons.CLIENTS,
            children: [<Clients />],
            label: (
              <span
                className={classNames(styles.tabsIconProfile, {
                  [styles.clientTabIconProfile]: !isAgent,
                })}
              >
                <ProfileIcons
                  name={ProfileIcons.CLIENTS}
                  color={'1' == tabKey ? '#262626' : '#AAABAB'}
                />
                <div
                  className={classNames(styles.tabName, {
                    [styles.inactive]: '1' != tabKey,
                  })}
                >
                  Clients
                </div>
              </span>
            ),
          },
          {
            key: '2',
            name: 'Tasks',
            icon: ProfileIcons.TASKS,
            children: [<Tasks />],
            label: (
              <span
                className={classNames(styles.tabsIconProfile, {
                  [styles.clientTabIconProfile]: !isAgent,
                })}
              >
                <ProfileIcons
                  name={ProfileIcons.TASKS}
                  color={'2' == tabKey ? '#262626' : '#AAABAB'}
                />
                <div
                  className={classNames(styles.tabName, {
                    [styles.inactive]: '2' != tabKey,
                  })}
                >
                  Tasks
                </div>
              </span>
            ),
          },
          {
            key: '3',
            name: 'Transactions',
            icon: ProfileIcons.TRANSACTIONS,
            children: [<Transactions />],
            label: (
              <span
                className={classNames(styles.tabsIconProfile, {
                  [styles.clientTabIconProfile]: !isAgent,
                })}
              >
                <ProfileIcons
                  name={ProfileIcons.TRANSACTIONS}
                  color={'3' == tabKey ? '#262626' : '#AAABAB'}
                />
                <div
                  className={classNames(styles.tabName, {
                    [styles.inactive]: '3' != tabKey,
                  })}
                >
                  Transactions
                </div>
              </span>
            ),
          },
          {
            key: '4',
            name: 'Documents',
            icon: ProfileIcons.DOCUMENTS,
            children: [<Documents closeAgentDetails={handleCloseModal} />],
            label: (
              <span
                className={classNames(styles.tabsIconProfile, {
                  [styles.clientTabIconProfile]: !isAgent,
                })}
              >
                <ProfileIcons
                  name={ProfileIcons.DOCUMENTS}
                  color={'4' == tabKey ? '#262626' : '#AAABAB'}
                />
                <div
                  className={classNames(styles.tabName, {
                    [styles.inactive]: '4' != tabKey,
                  })}
                >
                  Documents
                </div>
              </span>
            ),
          },
          {
            key: '5',
            name: 'Activity',
            icon: ProfileIcons.ACTIVITY,
            children: [<ConnectionActivity isAgent={isAgent} />],
            label: (
              <span
                className={classNames(styles.tabsIconProfile, {
                  [styles.clientTabIconProfile]: !isAgent,
                })}
              >
                <ProfileIcons
                  name={ProfileIcons.ACTIVITY}
                  color={'5' == tabKey ? '#262626' : '#AAABAB'}
                />
                <div
                  className={classNames(styles.tabName, {
                    [styles.inactive]: '5' != tabKey,
                  })}
                >
                  Activity
                </div>
              </span>
            ),
          },
        ];
      default:
        return [];
    }
  };
  const [isEditingHeader, setIsEditingHeader] = useState(false);

  const handleCloseModal = () => {
    if (isEditingHeader) {
      setIsEditingHeader(false);
      return;
    }
    dispatch(setProfileExpandedSection(false));
    setModalClose();
  };

  return (
    <Modal
      open={isExpandedOpened}
      width={675}
      footer={null}
      onCancel={handleCloseModal}
      className={classNames(styles.connectionExpandedModal, {
        [styles.hideModal]: showApprovalsModal,
      })}
      maskClosable={false}
      destroyOnClose={true}
      cancelButtonStyles={styles.crossButton}
      closeIconStyles={styles.crossButton}
    >
      <div className={styles.contentWrapper}>
        {isLoading || details.state === PENDING ? (
          <Skeleton active />
        ) : (
          <>
            {/* <Collapse contactId={contact?.Id} isAgent={isAgent} /> */}
            {!isEditingHeader && (
              <>
                <ExpandedHeader
                  contact={contact}
                  isAgent={isAgent}
                  isPreApproved={isPreApproved}
                  setIsEditingHeader={setIsEditingHeader}
                />
                <div className={styles.tabWrap}>
                  {!isAgent || contact.Id === userId || contact.IsManagedByCurrentUser ? (
                    <Tabs
                      onChange={setTabKey}
                      className={classNames(styles.expandedProfile, {
                        [styles.agentProfile]: isAgent,
                      })}
                      activeKey={tabKey}
                      items={getTabs(isAgent ? AGENT : CLIENT)}
                    />
                  ) : (
                    <Contact isAgent={isAgent} />
                  )}
                </div>
              </>
            )}
            {isEditingHeader && (
              <EditModalContent
                setIsEditingHeader={setIsEditingHeader}
                firstName={contact.FirstName}
                lastName={contact.LastName}
                id={contact.Id}
              />
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

ProfileExpanded.propTypes = {
  id: PropTypes.number,
  isAgent: PropTypes.bool,
  activeTab: PropTypes.number,
  isModalOpened: PropTypes.bool,
  setModalClose: PropTypes.func,
};

ProfileExpanded.defaultProps = {
  id: 0,
  isAgent: false,
  activeTab: 0,
  isModalOpened: false,
  setModalClose: () => {},
};

export default ProfileExpanded;
