import classNames from 'classnames';

import { ClientRole } from 'components/Transactions';
import Icon from 'pages/Workshop/Transactions/TransactionCreate/Icons';
import { ValueObject } from 'components/Transactions/ClientInput/ClientInputView';
import { Role } from 'app-constants';

import styles from './styles.module.scss';
import { Cross } from 'components/Icons';
import ParticipantInput from 'components/Transactions/ParticipantInput';

interface ParticipantProps {
  value: ValueObject;
  participants?: ValueObject[];
  isClientInfo: boolean;
  isAgentInfo: boolean;
  isCoordinatorInfo: boolean;
  isWhoIsRepresentingBuyerInfo: boolean;
  onDelete: (...args) => void;
  onChangeClient: (...args) => void;
  onChangeRole: (...args) => void;
  onBlurClientInput: (...args) => void;
  onRoleKeyPress: (...args) => void;
  onRemoveData: (...args) => void;
  currentQuestionId?: string;
}

const Participant = ({
  value,
  participants,
  isClientInfo,
  isAgentInfo,
  isCoordinatorInfo,
  isWhoIsRepresentingBuyerInfo,
  onDelete,
  onChangeClient,
  onChangeRole,
  onBlurClientInput,
  onRoleKeyPress,
  onRemoveData,
  currentQuestionId,
}: ParticipantProps) => {
  const deleteIcon = (
    <Icon
      onClick={onDelete}
      className={styles.iconDelete}
      variant={Icon.MINUS}
      testid="close_icon"
    />
  );

  return (
    <div className={styles.participantWrap}>
      <div className={styles.participant}>
        <div className={styles.clientInputWrap}>
          <p className={styles.inputLabel}>{isClientInfo ? 'Client' : 'Name'}</p>
          <ParticipantInput
            onBlur={onBlurClientInput}
            onChange={onChangeClient}
            value={value}
            dropdownContainer={styles.dropdownWrap}
            currentQuestionId={currentQuestionId}
            excludedMemberIds={participants?.map((p) => p.id as number).filter((value) => !!value)}
            className={classNames(styles.client, { [styles.clientHalf]: value.invite })}
            {...(isClientInfo
              ? {
                  placeholder: ' ',
                  allowedRoles: [Role.Client],
                }
              : { placeholder: ' ' })}
          />
          {value?.firstName ? (
            <Cross color="#262626" className={styles.removeButton} onClick={onRemoveData} />
          ) : null}
        </div>{' '}
        <div className={styles.roleWrapper}>
          <p className={styles.inputLabel}>Role</p>
          <ClientRole
            className={styles.clientRole}
            wrapperClassName={styles.inputWrapper}
            arrowIconClassName={styles.arrowdownicon}
            onChange={onChangeRole}
            onKeyPress={onRoleKeyPress}
            value={value}
            isCoordinatorInfo={isCoordinatorInfo}
            isAgentInfo={isAgentInfo}
            isWhoIsRepresentingBuyerInfo={isWhoIsRepresentingBuyerInfo}
            isClientInfo={isClientInfo}
            isSearchIcon={false}
            isViewMode={isWhoIsRepresentingBuyerInfo}
          />
        </div>
      </div>
      {isClientInfo ? (participants && participants.length > 1 ? deleteIcon : null) : deleteIcon}
    </div>
  );
};

export default Participant;
