import PropTypes from 'prop-types';

import styles from './styles.module.scss';
import CheckboxComponent from 'components/Form/Checkbox';

export const InviteToTransaction = ({ invite, onInvite }) => {
  return (
    <CheckboxComponent
      id="invite-to-transaction"
      label="Invite to transaction"
      checked={invite}
      onChange={(e, v, checked) => onInvite(checked)}
      direction={CheckboxComponent.DIRECTION_RIGHT}
      labelTextClassName={styles.inviteCheckboxLabel}
      className={styles.inviteCheckbox}
    />
  );
};

InviteToTransaction.propTypes = {
  className: PropTypes.string,
  invite: PropTypes.bool,
  onUpdate: PropTypes.func,
};

InviteToTransaction.defaultProps = {
  className: '',
  invite: false,
  onUpdate: () => {},
};
