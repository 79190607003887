import { ClientRole } from 'components/Transactions';
import styles from './styles.module.scss';
import classNames from 'classnames';

export const TransactionMemberRole = (props) => {
  const {
    onChange,
    onKeyPress,
    value,
    isAgent,
    isCoordinator,
    isBuyerTransaction,
    isSellerTransaction,
    whoisReprentingBuyerQuestion,
  } = props;

  return (
    <div className={styles.roleInputWrapper}>
      <div className={styles.roleInputContainer}>
        <p className={styles.inputLabel}>Role</p>

        <ClientRole
          className={classNames(styles.clientRole, {
            [styles.viewModeRole]: whoisReprentingBuyerQuestion,
          })}
          wrapperClassName={styles.inputWrapper}
          arrowIconClassName={styles.arrowdownicon}
          onChange={(e, value) => onChange(value)}
          onKeyPress={onKeyPress}
          value={value}
          isAgentInfo={isAgent}
          isCoordinatorInfo={isCoordinator}
          isBuyerTransaction={isBuyerTransaction}
          isSellerTransaction={isSellerTransaction}
          isSearchIcon={false}
          isViewMode={whoisReprentingBuyerQuestion}
          isWhoIsRepresentingBuyerInfo={whoisReprentingBuyerQuestion}
        />
      </div>
    </div>
  );
};
