import React from 'react';

export const ICON_VARIANT_TYPE = {
  MODAL_CLOSE: 'modal-close',
  FILTER: 'filter',
  FILTER_RED: 'filterRed',
  DATE_PICKER: 'datePicker',
} as const;

type IconVariant = typeof ICON_VARIANT_TYPE[keyof typeof ICON_VARIANT_TYPE];

interface IconsProps extends React.SVGProps<SVGSVGElement> {
  variant?: IconVariant;
  size?: number;
}

export const Icons = ({ variant, size = 16, ...props }: IconsProps) => {
  const getIcons = () => {
    switch (variant) {
      case ICON_VARIANT_TYPE.MODAL_CLOSE: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            {...props}
          >
            <g clipPath="url(#clip0_42719_6422)">
              <path
                d="M24 8L8 24"
                stroke={props.stroke || '#515151'}
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 8L24 24"
                stroke={props.stroke || '#515151'}
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_42719_6422">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case ICON_VARIANT_TYPE.FILTER: {
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.2256 4.996C2.94272 4.67271 3.1723 4.16675 3.60188 4.16675H16.3981C16.8277 4.16675 17.0573 4.67271 16.7744 4.996L11.6667 10.8334V15.5244C11.6667 15.7138 11.5597 15.8869 11.3903 15.9716L9.05694 17.1383C8.72449 17.3045 8.33333 17.0628 8.33333 16.6911V10.8334L3.2256 4.996Z"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case ICON_VARIANT_TYPE.FILTER_RED: {
        return (
          <svg
            width="26"
            height="25"
            viewBox="0 0 26 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M1.96746 3.10567C1.59029 2.67462 1.89641 2 2.46918 2H23.5308C24.1036 2 24.4097 2.67461 24.0325 3.10567L15.6667 12.6667V20.2546C15.6667 20.5072 15.524 20.738 15.2981 20.8509L11.2981 22.8509C10.8549 23.0726 10.3333 22.7502 10.3333 22.2546V12.6667L1.96746 3.10567Z"
              stroke="#FF576D"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case ICON_VARIANT_TYPE.DATE_PICKER: {
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.5 4H5.5C4.67157 4 4 4.67157 4 5.5V14.5C4 15.3284 4.67157 16 5.5 16H14.5C15.3284 16 16 15.3284 16 14.5V5.5C16 4.67157 15.3284 4 14.5 4Z"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13 3V5"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7 3V5"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4 8H16"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      default: {
        return <></>;
      }
    }
  };

  return getIcons();
};
