import {
  DYNAMIC_QUESTION_TYPE,
  FORM_PROCESS_SCREEN,
  FORM_USER_ROLE,
  getSignatoryColor,
} from 'app-constants';
import { DEFAULT_PDF_SCALE } from 'components/PrintPDF';
import { get, isEmpty } from 'lodash-es';
import { sortDocuments } from 'pages/FormProcess/DynamicForm';
import { PENDING } from 'settings/constants/apiState';
import {
  DynamicFormType,
  DynamicSignatureConfig,
  EditorsType,
  FormUserRoleType,
  PDFEditorConfig,
  PreFormType,
} from 'types';

export const setPreFormData = (response: any): PreFormType => {
  const agent: EditorsType = [];
  const client: EditorsType = [];

  response?.remainingEditor?.forEach((editor) => {
    if (editor.Role === FORM_USER_ROLE.Agent) {
      agent.push({
        ...(editor.userId ? { UserId: editor.userId.toString() } : undefined),
        ...(!editor.userId ? { Email: editor.email } : undefined),
        FormRole: editor.Role,
        Name: editor.Name,
        Avatar: editor.AvatarUrl,
      });
    } else if (editor.Role === FORM_USER_ROLE.Client) {
      client.push({
        ...(editor.userId ? { UserId: editor.userId.toString() } : undefined),
        ...(!editor.userId ? { Email: editor.email } : undefined),
        FormRole: editor.Role,
        Name: editor.Name,
        Avatar: editor.AvatarUrl,
      });
    }
  });

  const signatoriesList = response?.remainingSignatories?.map((signatory) => ({
    ...(typeof signatory.Id === 'number' ? { UserId: signatory.Id } : undefined),
    ...(typeof signatory.Id === 'string' ? { Email: signatory.email } : undefined),
    ...(typeof signatory.Id === 'number' ? { Name: signatory.Name } : undefined),
    FormRole: signatory.RoleId,
    SignatureSequence: signatory.sequence,
    Avatar: signatory.AvatarUrl,
    Role: signatory.Role,
  }));

  const signatories = signatoriesList?.reduce(
    (acc, signatory) => ({
      ...acc,
      [signatory.FormRole]: signatory,
    }),
    {},
  );

  const copyRecipients = response?.remainingRecipients?.map((recipient) =>
    recipient.Id === 'string' ? recipient.email : recipient.Id.toString(),
  );

  const allowedEditors: FormUserRoleType[] = [];

  if (agent?.length) {
    allowedEditors.push(FORM_USER_ROLE.Agent);
  }
  if (client?.length) {
    allowedEditors.push(FORM_USER_ROLE.Client);
  }

  return {
    isSequentialSignature: response?.isSequentialSigning,
    editors: {
      ...(agent?.length ? { agent } : undefined),
      ...(client?.length ? { client } : undefined),
    },
    signatories,
    copyRecipients,
    allowedEditors,
  };
};

export const handleDynamicFormResponse = (response, oldDynamicForm) => {
  if (response.state === PENDING && oldDynamicForm) {
    return {
      ...oldDynamicForm,
    };
  }

  let data = response?.data?.value;

  if (!data) return;

  const isTemplate = !!data.formTemplate;
  if (isTemplate) {
    data = prepareTemplateData(data);
  }

  const signatureConfig: DynamicSignatureConfig | undefined =
    data.screen === FORM_PROCESS_SCREEN.Sign
      ? {
          activeQuestionIndex: 0,
          activeQuestions: get(data, 'activeQuestions', []),
          otherSignatoriesQuestions: get(data, 'otherSignatoriesQuestions', []),
          disabledQuestions: get(data, 'disabledQuestions', {}),
          responses: get(data, 'answers', {}),
          isCreator: get(data, 'isCreator', false),
          showSendDocumentScreen: get(data, 'showSendDocumentScreen', false),
          allFormRoles: get(data, 'allFormRoles', []),
          closeOnProgress: get(data, 'closeOnProgress', false),
          showTermsAndConditions: get(data, 'showTermsAndConditions', false),
          showWizard: get(data, 'showWizard', false),
          showUserRoles: get(data, 'showUserRoles', false),
        }
      : undefined;

  const isDeclinedScreen = data.screen === FORM_PROCESS_SCREEN.DeclineSign;
  const bundleDocumentsDetails = getBundleDocumentsDetails(data, oldDynamicForm);

  return {
    screen: data.screen,
    meta: data.meta,
    scale: DEFAULT_PDF_SCALE,
    editorConfig: prepareEditorConfig(data, oldDynamicForm),
    signatureConfig,
    isCanceled: get(data, 'isCanceled', false),
    isCompleted: get(data, 'isCompleted', false),
    isVoided: get(data, 'isCompleted', false),
    isPreview: get(data, 'isPreview', false),
    hideFooter: get(data, 'hideFooter', false) || isDeclinedScreen,
    showUnlock: get(data, 'showUnlock', false),
    allowRegress: get(data, 'allowRegress', false),
    dynamicFormDocuments: isTemplate
      ? sortDocuments(get(data, 'bundleTemplates', []), 'Order')
      : sortDocuments(get(data, 'documents', []), 'Order'),
    strikeThrough: get(data, 'strikeThrough', {}),

    ...(bundleDocumentsDetails && {
      bundleDocumentsDetails,
    }),
  } as DynamicFormType;
};

const getBundleDocumentsDetails = (data, oldDynamicForm) => {
  const bundleDetails = data.bundleDocumentsDetails;
  const oldBundleDetails = oldDynamicForm?.bundleDocumentsDetails;

  let bundleDocumentsDetails;

  if (bundleDetails) {
    bundleDocumentsDetails = bundleDetails;
  }

  if (oldBundleDetails) {
    bundleDocumentsDetails =
      bundleDetails && bundleDetails.length !== oldBundleDetails.length
        ? bundleDetails
        : oldBundleDetails;
  }

  return bundleDocumentsDetails;
};

export const prepareQuestionsWithStrikeThrough = (data) => {
  const questions = get(data, 'questions', {});
  let strikeThrough = get(data, 'strikeThrough', {});

  const selectedRole = get(data, 'formRoles.0', '');

  strikeThrough = data.StrikeThrough || strikeThrough;

  Object.keys(strikeThrough).forEach((pageIndex) => {
    const strikeThroughFields = strikeThrough[pageIndex];

    strikeThroughFields.forEach((item, index) => {
      const {
        id,
        start: { x: startX, y },
        end: { x: endX },
        strokeWidth,
        color,
      } = item;

      const width = endX - startX;
      const { r, g, b, a } = color || { r: 0, g: 0, b: 0, a: 1 };

      const roleId = selectedRole.roleId;

      const strikeThroughQuestionItem = {
        roleName: roleId,
        pageNumber: pageIndex,
        documentIndex: -1,
        UUID: id,
        id,
        type: DYNAMIC_QUESTION_TYPE.StrikeThrough,
        width,
        height: 0,
        x: startX,
        y,
        required: false,

        strokeWidth,
        strokeColor: { r, g, b, a },
      };

      if (!questions[pageIndex]) {
        questions[pageIndex] = { [roleId]: [strikeThroughQuestionItem] };
      } else if (!questions[pageIndex][roleId]) {
        questions[pageIndex][roleId] = [strikeThroughQuestionItem];
      } else {
        questions[pageIndex][roleId].push(strikeThroughQuestionItem);
      }
    });
  });

  return questions;
};

export const prepareEditorConfig = (data, oldDynamicForm) => {
  const editorConfig: PDFEditorConfig | undefined =
    data.screen === FORM_PROCESS_SCREEN.PDFEditor
      ? {
          formRoles: get(data, 'formRoles', []).map((signatory, index) => ({
            ...signatory,
            color: getSignatoryColor(index),
          })),
          selectedRole: get(data, 'formRoles.0', ''),
          questions: prepareQuestionsWithStrikeThrough(data),
        }
      : undefined;

  if (editorConfig?.formRoles?.length) {
    editorConfig.selectedRole = editorConfig.formRoles[0];

    if (oldDynamicForm?.editorConfig?.selectedRole) {
      editorConfig.selectedRole =
        editorConfig.formRoles.find(
          (role) => role.roleId === oldDynamicForm.editorConfig.selectedRole.roleId,
        ) ?? editorConfig.formRoles[0];
    }
  }

  return editorConfig;
};

export const prepareTemplateData = (details) => {
  const data = JSON.parse(JSON.stringify(details.formTemplate));

  data.screen = FORM_PROCESS_SCREEN.PDFEditor;

  const formRoles = data.formRoles.map((role) => ({
    userName: role.Name,
    roleId: role.Id,
    roleName: role.Name,
    avatar: null,
  }));

  data.meta = {
    ...data.meta,
    formId: data.FormId,
    formVersionId: data.FormVersionId,
    formCategories: data?.FormVersion?.FormCategoryTag ?? [],
    publishToTeam: data?.PublishToTeam,
    formRolesIds: data?.FormRolesIds,
    formRoles,
  };

  data.formRoles = formRoles;

  return data;
};
