import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { formatUsd } from 'helpers';

import styles from './styles.module.scss';

export const AreaChart = ({ seriesName, data, colors, className }) => {
  const chartOptions: ApexOptions = {
    chart: {
      sparkline: {
        enabled: true,
      },
      type: 'area',
      width: '100%',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: colors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 3,
      colors: colors,
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    fill: {
      opacity: 0.7,
      type: 'gradient',
      colors: colors,
      gradient: {
        type: 'vertical',
        shadeIntensity: 0.7,
        stops: [0, 80],
      },
    },
  };

  const chartSeries = [
    {
      name: seriesName,
      data: data,
    },
  ];

  return (
    <div className={className}>
      <Chart options={chartOptions} series={chartSeries} type="area" height={70} />
    </div>
  );
};
