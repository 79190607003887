import React, { useEffect, useMemo, useState } from 'react';

import { login } from 'api/user';
import classNames from 'classnames';
import { Button, Input, Modal, Select, Spinner } from 'components';
import { Button as AntButton } from 'components-antd';
import moment from 'moment';
import { Table } from 'pages/Dashboard/components/Table';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { READY } from 'settings/constants/apiState';
import {
  cancelTeamSubscriptionEffect,
  getTeamSubscriptionDetailsEffect,
  reactivateTeamSubscriptionEffect,
  updateTeamSubscriptionPlanEffect,
} from 'store/effects/adminPanel/teams';
import {
  getTeamsDataDashboardSelector,
  teamSubscriptionDetailsSelector,
} from 'store/selectors/adminPanel';
import { getUserEmail } from 'store/selectors/user';
import { AdminFriendlySubscriptionPlan } from 'types';
import styles from './styles.module.scss';
import { columns } from './tableConfig';
import { getTeamDetailsDataDashboardSelector } from 'store/selectors/adminPanel/teamDetails';
import { subscriptionPlans } from 'settings/constants/subscription';
import { showErrorMessage } from 'helpers';

type ConfirmationModalProps = {
  handleCancel: () => void;
  payload: any;
  teamId: string;
  setNewPlanName: (planName: string) => void;
  setPlan: (plan: string) => void;
  setCanceled: (canceled: boolean) => void;
  setSilentMode: (silentMode: boolean) => void;
};

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  handleCancel,
  teamId,
  setNewPlanName,
  setPlan,
  setCanceled,
  setSilentMode,
  payload = {},
}) => {
  const teamData = useSelector(getTeamDetailsDataDashboardSelector);
  const ownerId = teamData?.Owner?.Id;
  const { newPlan, userId, deactivate } = payload || {};

  const [password, setPassword] = useState<string>('');
  const userEmail = useSelector(getUserEmail);
  const [loading, setLoading] = useState<boolean>(false);
  const [authMessage, setAuthMessage] = useState('');

  const silentPollSubscriptionDetails = async (deactivate: boolean, attempts = 5) => {
    for (let i = 0; i < attempts; i++) {
      await new Promise((resolve) => setTimeout(resolve, 3000));
      const subscriptionDetails: any = await dispatch(getTeamSubscriptionDetailsEffect({ teamId }));
      const willBeCanceledTimestamp =
        subscriptionDetails?.data?.teamSubscriptionData?.WillBeCanceledTimestamp;

      if ((deactivate && willBeCanceledTimestamp) || (!deactivate && !willBeCanceledTimestamp)) {
        return subscriptionDetails;
      }
    }
    return null;
  };

  const dispatch = useDispatch();
  const handleVerifyPassword = async () => {
    setAuthMessage('');
    setLoading(true);
    try {
      const res = await login({ user: userEmail, password }, {});
      if (res.status === 200) {
        if (deactivate === true) {
          setLoading(true);
          setSilentMode(true);
          dispatch(
            cancelTeamSubscriptionEffect(
              { userId, CancellationReason: 'Cancelled by admin' },
              async () => {
                setCanceled?.(true);
                const updatedDetails = await silentPollSubscriptionDetails(deactivate);
                setLoading(false);
                if (updatedDetails) {
                  handleCancel();
                }
              },
            ),
          );
        } else if (deactivate === false) {
          setLoading(true);
          setSilentMode(true);
          dispatch(
            reactivateTeamSubscriptionEffect({ userId }, async () => {
              setCanceled?.(false);
              const updatedDetails = await silentPollSubscriptionDetails(deactivate);
              setLoading(false);
              if (updatedDetails) {
                handleCancel();
              }
            }),
          );
        } else {
          dispatch(
            updateTeamSubscriptionPlanEffect(
              { userId: userId || ownerId, newPlan },
              {},
              (err, data) => {
                //Optimistic update
                setNewPlanName(subscriptionPlans[payload.newPlan]);
                setPlan(payload.newPlan);
                dispatch(getTeamSubscriptionDetailsEffect({ teamId: teamId }));
                handleCancel();
              },
            ),
          );
        }
      }
    } catch (err) {
      setAuthMessage('Incorrect Password');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={true} onClose={handleCancel} innerHolderClassName={styles.innerHolder}>
      <div>
        <div className={styles.heading}>Confirmation</div>
        <div className={styles.prompt}>
          {deactivate === true
            ? 'Are you sure you want to deactivate the subscription? Enter your password below to continue.'
            : deactivate === false
            ? 'Are you sure you want to reactivate the subscription? Enter your password below to continue.'
            : `Are you sure you want to change the plan to ${
                subscriptionPlans[payload.newPlan]
              }? Enter your password below to
          continue.`}
        </div>
        <div>
          <Input
            placeholder="Enter your password"
            type="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            error={authMessage}
          />
        </div>
        <div className={styles.btnContainer}>
          <AntButton
            variant="primary"
            className={styles.confirmBtn}
            onClick={handleVerifyPassword}
            disabled={loading}
          >
            {loading ? <Spinner loaderClassName={styles.loader} /> : 'Confirm'}
          </AntButton>
        </div>
      </div>
    </Modal>
  );
};

export const TeamSubscriptionDetails: React.FC = () => {
  const dispatch = useDispatch();
  const { data, state } = useSelector(teamSubscriptionDetailsSelector);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [modalPayload, setModalPayload] = useState<any>(null);
  const [newPlanName, setNewPlanName] = useState<string>('');
  const [plan, setPlan] = useState('');
  const [canceled, setCanceled] = useState<boolean>(false);
  const params = useParams<{ id: string }>();
  const [silentMode, setSilentMode] = useState(false);
  const teamId = params.id;

  useEffect(() => {
    dispatch(getTeamSubscriptionDetailsEffect({ teamId: teamId }));
  }, [teamId]);

  useEffect(() => {
    const planData = data?.teamSubscriptionData;
    if (!planData || planData?.IsComplimentaryPlan || planData?.InternalPlanName === null) {
      setPlan(AdminFriendlySubscriptionPlan.Complimentary);
      return;
    }
    setPlan(planData.InternalPlanName);
    if (planData) {
      setCanceled(planData.WillBeCanceledTimestamp ? true : false);
    }
  }, [data]);

  const planName = useMemo(() => {
    if (newPlanName) return newPlanName;
    const planData = data?.teamSubscriptionData;
    if (!planData) return 'Complimentary';
    if (planData.IsComplimentaryPlan) {
      return 'Complimentary';
    }
    if (planData.InternalPlanName === 'standard') {
      if (planData.RequestedSeatsCount === 2) {
        return 'Solo';
      }
      if (planData.RequestedSeatsCount === 3) {
        return 'Team';
      }
      return 'Standard';
    }
    if (planData.InternalPlanName === 'paid') {
      return 'Legacy';
    }
    return 'Complimentary';
  }, [data, newPlanName]);

  const lastUpdatedAt = useMemo(() => {
    const lastInvoiceData = data?.billingData?.LastInvoice;
    if (!lastInvoiceData) return;
    return moment(lastInvoiceData.Date).format('MMM DD, YYYY');
  }, [data]);

  const cancelationDate = useMemo(() => {
    const cancelationDateTime = data?.teamSubscriptionData?.WillBeCanceledTimestamp;
    if (!cancelationDateTime) return;
    return moment(cancelationDateTime).format('MMM DD, YYYY');
  }, [data]);

  const nextInvoiceAt = useMemo(() => {
    const nextInvoiceData = data?.billingData?.NextInvoice;
    if (!nextInvoiceData) return;
    return moment(nextInvoiceData.Date).format('MMM DD, YYYY');
  }, [data?.billingData?.NextInvoice]);

  const handlePlanChange = (event: any) => {
    if (event.target.value.value === plan) return;
    // prevent changing from standard to legacy plan
    if (
      plan === AdminFriendlySubscriptionPlan.Standard &&
      event.target.value.value === AdminFriendlySubscriptionPlan.Paid
    ) {
      showErrorMessage('You cannot change from Standard to Legacy plan');
      return;
    }
    setModalPayload({
      deactivate: null,
      newPlan: event.target.value.value,
      userId: data?.teamSubscriptionData?.UserId,
    });
    setShowConfirmationModal(true);
  };

  const handleDeactivate = (deactivate = true) => {
    setModalPayload({
      deactivate,
      userId: data?.teamSubscriptionData?.UserId,
    });
    setShowConfirmationModal(true);
  };

  const isComplimentary = planName === 'Complimentary';

  const planSelectOptions = useMemo(() => {
    if (plan === AdminFriendlySubscriptionPlan.Standard) {
      return [
        {
          name: 'Standard',
          value: AdminFriendlySubscriptionPlan.Standard,
        },
        {
          name: 'Complimentary',
          value: AdminFriendlySubscriptionPlan.Complimentary,
        },
      ];
    }

    return [
      {
        name: 'Standard',
        value: AdminFriendlySubscriptionPlan.Standard,
      },
      {
        name: 'Legacy',
        value: AdminFriendlySubscriptionPlan.Paid,
      },
      {
        name: 'Complimentary',
        value: AdminFriendlySubscriptionPlan.Complimentary,
      },
    ];
  }, [plan]);

  if (state !== READY && !silentMode) {
    return <Spinner />;
  }
  return (
    <>
      {showConfirmationModal && (
        <ConfirmationModal
          payload={modalPayload}
          teamId={teamId}
          handleCancel={() => {
            setShowConfirmationModal(false);
            setSilentMode(false);
            setModalPayload(null);
          }}
          setNewPlanName={setNewPlanName}
          setPlan={setPlan}
          setCanceled={setCanceled}
          setSilentMode={setSilentMode}
        />
      )}
      <div className={styles.container}>
        <div className={styles.leftContainer}>
          <div className={styles.card}>
            <div className={styles.heading}>Current Plan</div>
            <div className={styles.headingBold}>{planName}</div>
            <div>
              <Select
                options={planSelectOptions}
                placeholder={'Change Plan'}
                onSelect={handlePlanChange}
                value={plan}
                selectWrapperClassname={styles.select}
              />
            </div>
            <div>
              <span className={styles.headingSemiBold}>Last Updated:</span>{' '}
              <span className={styles.textLight}>{lastUpdatedAt}</span>
            </div>
            {canceled && cancelationDate && (
              <div>
                <span className={styles.headingSemiBold}>Cancelation Date :</span>{' '}
                <span className={styles.textLight}>{cancelationDate}</span>
              </div>
            )}
            <div>
              {!canceled && !isComplimentary && (
                <Button
                  title="Deactivate Subscription"
                  className={styles.btn}
                  onClick={() => handleDeactivate(true)}
                />
              )}
              {canceled && !isComplimentary && (
                <Button
                  title="Reactivate Subscription"
                  className={styles.btn}
                  onClick={() => handleDeactivate(false)}
                />
              )}
            </div>
            <div>
              <span className={styles.headingSemiBold}>Last Renewal:</span>{' '}
              <span className={styles.textLight}>{lastUpdatedAt}</span>
            </div>
            <div>
              <span className={styles.headingSemiBold}>Next Renewal:</span>{' '}
              <span className={styles.textLight}>{nextInvoiceAt}</span>
            </div>
          </div>
          <div className={classNames(styles.card, styles.seats)}>
            <div>
              <div className={styles.seatsHeading}>Available Seats</div>
              <div className={styles.seatsCount}>{data?.seats?.available}</div>
            </div>
            <div>
              <div className={styles.seatsHeading}>Filled Seats</div>
              <div className={styles.seatsCount}>{data?.seats?.filled}</div>
            </div>
          </div>
        </div>
        <div className={classNames(styles.rightContainer, styles.card)}>
          <div className={styles.header}>
            <div className={styles.heading}>Billing History</div>
            <div></div>
          </div>
          <div className={styles.tableWrapper}>
            <Table columns={columns} dataSource={data?.billingData?.InvoiceHistory} />
          </div>
        </div>
      </div>
    </>
  );
};
