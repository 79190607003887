import React from 'react';

export const TimerIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M10 6.6665V9.99984L11.6667 11.6665"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.54297 9.16673C2.72971 7.33344 3.58486 5.63307 4.94525 4.39003C6.30564 3.14699 8.07605 2.44829 9.9187 2.42725C11.7614 2.4062 13.5473 3.06428 14.9357 4.27592C16.3241 5.48756 17.2179 7.16796 17.4464 8.9965C17.675 10.825 17.2224 12.6737 16.1749 14.1899C15.1275 15.706 13.5585 16.7834 11.7674 17.2166C9.97623 17.6498 8.08831 17.4084 6.4638 16.5384C4.83929 15.6685 3.5919 14.231 2.95964 12.5001M2.54297 16.6667V12.5001H6.70964"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
