export const EmptyClausePlaceholder = () => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.6092 51.969C35.3216 63.8161 54.8654 46.3998 32.5002 40.0281C11.0543 33.9183 18.3793 24.4262 27.9993 22.6667"
        stroke="#D6D6D7"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M42.3908 12.0317C28.6784 0.183092 9.13455 17.6002 31.4998 23.9727C52.9457 30.0833 45.6207 39.5736 36.0007 41.3333"
        stroke="#D6D6D7"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
};
