import { apiServer } from 'settings/web-services/api';

export function createTransactionTask(cfg) {
  const { transactionId, ...config } = cfg;
  return apiServer.post(`/transaction/${transactionId}/tasks`, config);
}

export function createTransactionManyTask(cfg) {
  const { transactionId, ...config } = cfg;
  return apiServer.post(`/transaction/${transactionId}/tasks/many`, config);
}

export function createTaskCategory(cfg) {
  return apiServer.post('/transaction/templates/task-categories', cfg);
}
