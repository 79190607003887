import PropTypes from 'prop-types';

import { Col, Row } from 'components-antd';
import { Input, InputLabel, Select, Locations, Checkbox } from 'components';

import styles from './styles.module.scss';
import { getLocationContext } from 'helpers/locations';
import { POSTAL_CODE } from 'settings/constants/locations';

const CommuteForm = (props) => {
  const { onChange, value, isPending } = props;

  const onChangeLocation = (result) => {
    const { deleteItemId, coordinates, placeName, result: locations } = result;
    const zip = getLocationContext(locations?.[0], POSTAL_CODE);

    const cfg = !deleteItemId
      ? {
          PlaceName: placeName,
          Geolocation: {
            Long: coordinates.lng,
            Lat: coordinates.lat,
          },
          Zip: zip,
        }
      : undefined;

    onChange({ DestinationAddress: cfg });
  };

  return (
    <div className={styles.commutes}>
      <Locations
        placeholder="Enter address or location name"
        value={
          value?.DestinationAddress?.PlaceName
            ? [{ ProviderPlaceId: 1, PlaceName: value?.DestinationAddress?.PlaceName }]
            : []
        }
        valuesWrapperClassName={styles.border}
        placeholderClassName={styles.border}
        searchWrapperClassName={styles.border}
        multiple={false}
        onResult={onChangeLocation}
        disabled={isPending}
        variant={Locations.ROUND}
        rounded={true}
        label={'Address'}
      />
      <p className={styles.preference}>Preference</p>
      <Row gutter={[16, 16]} className={styles.outerWrapper}>
        <Col xs={24} md={12} className={styles.innerWrapper}>
          <InputLabel label="Within" className={styles.inputLabel} />
          <div className={styles.inputWrapper}>
            <Input
              name="minutesInput"
              value={value.MaxCommuteTimeInMinutes?.toString() || ''}
              onChange={(e) =>
                onChange({
                  MaxCommuteTimeInMinutes: e.target.value.length ? parseInt(e.target.value) : null,
                })
              }
              placeholder="0"
              inputClassName={styles.inputMaxBorderBottom}
              className={styles.inputfieldMinutes}
              maxLength={'9'}
              type={'number'}
              disabled={isPending}
              variant={Input.LIGHT_ROUND}
            />
            <Select
              options={CommuteForm.unitTypes}
              value={'minutes'}
              // onSelect={(target, val) => setType(val)} // TODO: Make this functional
              placeholder="minutes"
              variant={Select.LIGHT_ROUND}
              className={{ wrapper: styles.timeSelectWrapper }}
              disabled={isPending}
            />
          </div>
        </Col>
        <Col xs={24} md={12} className={styles.innerWrapper}>
          <InputLabel label="Transportation Method" className={styles.inputLabel} />
          <Select
            options={CommuteForm.transportationMethods}
            value={
              value.TransportationModePreference?.length > 0
                ? value.TransportationModePreference[0]
                : null
            }
            defaultValue={
              value.TransportationModePreference?.length > 0
                ? value.TransportationModePreference[0]
                : CommuteForm.transportationMethods[0]
            }
            onSelect={(target, val) => onChange({ TransportationModePreference: [val.value] })}
            placeholder="Transport"
            variant={Select.LIGHT_ROUND}
            className={{ wrapper: styles.transportationSelectWrapper }}
            style={{ width: '100%' }}
            disabled={isPending}
          />
        </Col>
      </Row>

      <Checkbox
        defaultChecked={false}
        checked={value.IsRushHour || false}
        onChange={(_, __, value) => {
          onChange({ IsRushHour: value });
        }}
        disabled={isPending}
        label="Account for rush hour?"
        direction="right"
      />

      <div className={styles.divider} />

      <div className={styles.outerWrapper}>
        <div className={styles.inputWrapper}>
          <Input
            name="addressLabel"
            value={value.Name || ''}
            onChange={(e) => onChange({ Name: e.target.value })}
            label="Label"
            variant={Input.LIGHT_ROUND}
            placeholder="e.g. Work, school, daycare, etc"
            inputClassName={styles.inputMaxBorderBottom}
            maxLength={'40'}
            disabled={isPending}
          />
        </div>
      </div>
    </div>
  );
};

CommuteForm.unitTypes = [
  { id: 1, name: 'Minutes', value: 'minutes' },
  { id: 2, name: 'Miles', value: 'miles' },
];
CommuteForm.transportationMethods = [
  { id: 1, name: 'Car', value: 'Car' },
  { id: 2, name: 'Public Transit', value: 'PublicTransit' },
  { id: 3, name: 'Bike', value: 'Bicycle' },
  { id: 4, name: 'Walk', value: 'Walking' },
];

CommuteForm.propTypes = {
  className: PropTypes.string,
  transportModeClassName: PropTypes.string,
  transportOptionClassName: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.shape({
    Name: PropTypes.string,
    DestinationAddress: PropTypes.shape({
      PlaceName: PropTypes.string,
    }),
    TransportationModePreference: PropTypes.arrayOf(PropTypes.string),
    MaxCommuteTimeInMinutes: PropTypes.number,
    IsRushHour: PropTypes.bool,
  }),
  isPending: PropTypes.bool,
};

CommuteForm.defaultProps = {
  className: '',
  transportModeClassName: '',
  transportOptionClassName: '',
  onChange: () => {},
  value: {
    TransportationModePreference: [''],
    MaxCommuteTimeInMinutes: 0,
    DestinationAddress: {
      PlaceName: '',
    },
    Name: '',
  },
  isPending: false,
};

export default CommuteForm;
