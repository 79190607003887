import Api from 'store/effects/core/api';

import {
  createNewFormAction,
  requestFormMetaAction,
  requestSmartFormDataAction,
  requestPublishedFormsAction,
  resetPreFormAction,
  updatePreFormResponseAction,
  requestFormProcessDocumentAction,
  resetFormProcessAction,
  updateFormProcessDocumentResponseAction,
  requestSendFormProcessAction,
  requestPostFormDataAction,
  updateFormAction,
  resetSmartFormDataAction,
  deferQuestionAuthAction,
  requestDeferredQuestionsAction,
  deleteDeferredQuestionAction,
  resetDeferredQuestionsAction,
  resetPostFormEffectAction,
  updateSignatureSequenceAction,
  deferQuestionAnonAction,
  requestPublishedFormAction,
  requestCreateBundleFormAction,
  undoDeferQuestionAuthAction,
  undoDeferQuestionAnonAction,
  requestFormTemplatesAction,
  updateFormRoleAction,
  requestDynamicFormDocumentAction,
  resetDynamicFormAction,
  updateDynamicFormScaleAction,
  selectDynamicFormRoleAction,
  updateDynamicFormQuestionAction,
  selectDynamicFormFieldAction,
  deleteDynamicFormQuestionAction,
  updateDynamicFormDocumentQuestionAction,
  progressDynamicFormAction,
  updateDynamicFormActiveQuestionAction,
  updateDynamicFormResponseAction,
  updateDynamicFormAction,
  requestDynamicPostForm,
  updateDynamicFormEditorConfigAction,
  regressFormDocumentAction,
  requestFormRecentUploadsAction,
  progressSaveAsTemplateFormAction,
  requestAgentFormRolesAction,
  selectDynamicFormAction,
  saveBundleAsTemplateAction,
  deleteDocumentAction,
  requestSignatories,
  updateRequestSignatories,
  requestDynamicFormBundleDocumentAction,
  reorderBundleDocumentAction,
  updateDynamicFormSignatureConfigAction,
  addNewDocumentInDynamicFormAction,
  requestBundleTemplateAction,
  updateTemplateSignatoriesAction,
} from 'store/actions/formProcess';
import {
  createNewForm,
  getFormMeta,
  getSmartFormData,
  getPublishedForms,
  getFormProcessDocument,
  getAnonFormProcessDocument,
  sendFormProcessDocument,
  getPostFormData,
  updateForm,
  deferQuestionAuth,
  getDeferredQuestions,
  deleteDeferredQuestion,
  updateSignatureSequence,
  deferQuestionAnon,
  getPublishedForm,
  createBundleForm,
  undoDeferQuestionAuth,
  undoDeferQuestionAnon,
  getFormTemplates,
  createDynamicForm,
  getDynamicFormDocumentAuth,
  updateDynamicFormDocumentQuestion,
  updateDynamicFormProgress,
  getDynamicPostFormData,
  updateDynamicFormRegress,
  getAnonDynamicFormProcessDocument,
  getTemplateDynamicFormProcessDocument,
  updateTemplateFormDocumentQuestion,
  getFormRecentUploads,
  getAgentFormData,
  updatSaveAsTemplateFormProgress,
  getAgentFormRoles,
  createDynamicBundleForm,
  createBundleFormTemplate,
  saveBundleAsTemplate,
  getBundleFormRoles,
  createFormCopy,
  deleteFormDocument,
  deleteTemplateFromBundle,
  getSignatories,
  updateSignatories,
  getBundleTemplate,
  reorderDynamicBundleDocument,
  deleteFormTemplate,
  addNewFormInDynamicView,
  updateTemplateSignatories,
} from 'api/formProcess';
import { DynamicFormEditorQuestionUpdate, DynamicFormType, FormDocumentAnswersType } from 'types';

export const getPublishedFormsEffect = (cfg, cb?) => {
  const sendRequest = Api.execBase({
    action: requestPublishedFormsAction,
    method: getPublishedForms,
  });

  return sendRequest(cfg, {}, cb);
};

export const resetPublishedFormEffect = () => (dispatch) => {
  dispatch(requestPublishedFormsAction());
};

export const getFormTemplatesEffect = (cfg) => {
  const sendRequest = Api.execBase({
    action: requestFormTemplatesAction,
    method: getFormTemplates,
  });

  return sendRequest(cfg);
};

export const getFormRecentUploadsEffect = (cfg) => {
  const sendRequest = Api.execBase({
    action: requestFormRecentUploadsAction,
    method: getFormRecentUploads,
  });

  return sendRequest(cfg);
};

export const getFormMetaEffect = (cfg?: any) => {
  const sendRequest = Api.execBase({
    action: requestFormMetaAction,
    method: getFormMeta,
  });

  return sendRequest(cfg);
};

export const getSmartFormDataEffect = (cfg) => {
  const sendRequest = Api.execBase({
    action: requestSmartFormDataAction,
    method: getSmartFormData,
  });

  return sendRequest(cfg);
};

export const getAgentFormDataEffect = (cfg) => {
  const sendRequest = Api.execBase({
    action: requestSmartFormDataAction,
    method: getAgentFormData,
  });

  return sendRequest(cfg);
};

export const getAgentFormRolesEffect = () => {
  const sendRequest = Api.execBase({
    action: requestAgentFormRolesAction,
    method: getAgentFormRoles,
  });

  return sendRequest();
};

export const getBundleFormRolesEffect = (cfg) => {
  const sendRequest = Api.execBase({
    action: requestSmartFormDataAction,
    method: getBundleFormRoles,
  });

  return sendRequest(cfg);
};

export const getBundleTemplateEffect = (cfg, cb?) => {
  const sendRequest = Api.execBase({
    action: requestBundleTemplateAction,
    method: getBundleTemplate,
  });

  return sendRequest(cfg, {}, cb);
};

export const resetSmartFormDataEffect = () => (dispatch) => {
  dispatch(resetSmartFormDataAction());
};

export const updatePreFormResponseEffect = (cfg) => (dispatch) => {
  dispatch(updatePreFormResponseAction(cfg));
};

export const resetPreFormEffect = () => (dispatch) => {
  dispatch(resetPreFormAction());
};

export const createNewFormEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: createNewFormAction,
    method: createNewForm,
  });

  return sendRequest(cfg, {}, cb);
};

export const addNewFormInDynamicViewEffect = (cfg, cb?) => {
  const sendRequest = Api.execBase({
    action: addNewDocumentInDynamicFormAction,
    method: addNewFormInDynamicView,
  });

  return sendRequest(cfg, {}, cb);
};

export const updateFormEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: updateFormAction,
    method: updateForm,
  });

  return sendRequest(cfg, {}, cb);
};

export const getFormProcessDocumentEffect = (cfg, cb?) => {
  const sendRequest = Api.execBase({
    action: requestFormProcessDocumentAction,
    method: getFormProcessDocument,
  });

  return sendRequest(cfg, {}, cb);
};

export const getAnonFormProcessDocumentEffect = (cfg, cb?) => {
  const sendRequest = Api.execBase({
    action: requestFormProcessDocumentAction,
    method: getAnonFormProcessDocument,
  });

  return sendRequest(cfg, {}, cb);
};

export const resetFormProcessEffect = () => (dispatch) => {
  dispatch(resetFormProcessAction());
};

export const updateFormProcessDocumentResponseEffect = (cfg) => (dispatch) => {
  dispatch(updateFormProcessDocumentResponseAction(cfg));
};

export const sendFormProcessDocumentEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: requestSendFormProcessAction,
    method: sendFormProcessDocument,
  });

  return sendRequest(cfg, {}, cb);
};

export const getPostFormDataEffect = (cfg, cb?) => {
  const sendRequest = Api.execBase({
    action: requestPostFormDataAction,
    method: getPostFormData,
  });

  return sendRequest(cfg, {}, cb);
};

export const deferQuestionAuthEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: deferQuestionAuthAction,
    method: deferQuestionAuth,
  });

  return sendRequest(cfg, {}, cb);
};

export const undoDeferQuestionAuthEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: undoDeferQuestionAuthAction,
    method: undoDeferQuestionAuth,
  });

  return sendRequest(cfg, {}, cb);
};

export const deferQuestionAnonEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: deferQuestionAnonAction,
    method: deferQuestionAnon,
  });

  return sendRequest(cfg, {}, cb);
};

export const undoDeferQuestionAnonEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: undoDeferQuestionAnonAction,
    method: undoDeferQuestionAnon,
  });

  return sendRequest(cfg, {}, cb);
};

export const getDeferredQuestionsEffect = (cfg) => {
  const sendRequest = Api.execBase({
    action: requestDeferredQuestionsAction,
    method: getDeferredQuestions,
  });

  return sendRequest(cfg);
};

export const deleteDeferredQuestionEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: deleteDeferredQuestionAction,
    method: deleteDeferredQuestion,
  });

  return sendRequest(cfg, {}, cb);
};

export const resetDeferredQuestionsEffect = () => (dispatch) => {
  dispatch(resetDeferredQuestionsAction());
};

export const resetPostFormEffect = () => (dispatch) => {
  dispatch(resetPostFormEffectAction());
};

export const updateSignatureSequenceEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: updateSignatureSequenceAction,
    method: updateSignatureSequence,
  });

  return sendRequest(cfg, {}, cb);
};

export const getPublishedFormEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: requestPublishedFormAction,
    method: getPublishedForm,
  });

  return sendRequest(cfg, {}, cb);
};

export const createBundleFormEffect = (cfg, cb?) => {
  const sendRequest = Api.execBase({
    action: requestCreateBundleFormAction,
    method: createBundleForm,
  });

  return sendRequest(cfg, {}, cb);
};

export const createBundleFormTemplateEffect = (cfg, cb?) => {
  const sendRequest = Api.execBase({
    action: requestCreateBundleFormAction,
    method: createBundleFormTemplate,
  });

  return sendRequest(cfg, {}, cb);
};

export const updateFormRoleEffect = (cfg) => (dispatch) => {
  dispatch(updateFormRoleAction(cfg));
};

export const getDynamicFormDocumentAuthEffect = (cfg, cb?) => {
  const sendRequest = Api.execBase({
    action: requestDynamicFormDocumentAction,
    method: getDynamicFormDocumentAuth,
  });

  return sendRequest(cfg, {}, cb);
};

export const getDynamicFormDocumentBundleEffect = (cfg, cb?) => {
  const sendRequest = Api.execBase({
    action: requestDynamicFormBundleDocumentAction,
    method: getDynamicFormDocumentAuth,
  });

  return sendRequest(cfg, {}, cb);
};

export const getDynamicFormDocumentAnonEffect = (cfg, cb?) => {
  const sendRequest = Api.execBase({
    action: requestDynamicFormDocumentAction,
    method: getAnonDynamicFormProcessDocument,
  });

  return sendRequest(cfg, {}, cb);
};

export const getDynamicTemplateDocumentEffect = (cfg, cb?) => {
  const sendRequest = Api.execBase({
    action: requestDynamicFormDocumentAction,
    method: getTemplateDynamicFormProcessDocument,
  });

  return sendRequest(cfg, {}, cb);
};

export const createDynamicFormEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: createNewFormAction,
    method: createDynamicForm,
  });

  return sendRequest(cfg, {}, cb);
};

export const createDynamicBundleFormEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: createNewFormAction,
    method: createDynamicBundleForm,
  });

  return sendRequest(cfg, {}, cb);
};

export const reorderDynamicBundleDocumentEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: reorderBundleDocumentAction,
    method: reorderDynamicBundleDocument,
  });

  return sendRequest(cfg, {}, cb);
};

export const createFormCopyEffect = (cfg, cb) => {
  const sendRequest = Api.execBase({
    action: createNewFormAction,
    method: createFormCopy,
  });

  return sendRequest(cfg, {}, cb);
};

export const resetDynamicFormEffect = () => (dispatch) => {
  dispatch(resetDynamicFormAction());
};

export const dynamicFormScaleChangeEffect = (cfg) => (dispatch) => {
  dispatch(updateDynamicFormScaleAction(cfg));
};

export const selectDynamicFormRoleEffect = (cfg) => (dispatch) => {
  dispatch(selectDynamicFormRoleAction(cfg));
};

export const updateDynamicFormQuestionEffect =
  (cfg: DynamicFormEditorQuestionUpdate) => (dispatch) => {
    dispatch(updateDynamicFormQuestionAction(cfg));
  };

export const selectDynamicFormFieldEffect = (cfg) => (dispatch) => {
  dispatch(selectDynamicFormFieldAction(cfg));
};

export const selectDynamicFormEffect = (cfg) => (dispatch) => {
  dispatch(selectDynamicFormAction(cfg));
};

export const deleteDynamicFormQuestionEffect =
  (cfg: DynamicFormEditorQuestionUpdate) => (dispatch) => {
    dispatch(deleteDynamicFormQuestionAction(cfg));
  };

export const updateDynamicFormDocumentQuestionEffect = (cfg, cb?) => {
  const sendRequest = Api.execBase({
    action: updateDynamicFormDocumentQuestionAction,
    method: updateDynamicFormDocumentQuestion,
  });

  return sendRequest(cfg, {}, cb);
};

export const updateTemplateFormDocumentQuestionEffect = (cfg, cb?) => {
  const sendRequest = Api.execBase({
    action: updateDynamicFormDocumentQuestionAction,
    method: updateTemplateFormDocumentQuestion,
  });

  return sendRequest(cfg, {}, cb);
};

export const progressDynamicFormEffect = (cfg, cb?) => {
  const sendRequest = Api.execBase({
    action: progressDynamicFormAction,
    method: updateDynamicFormProgress,
  });

  return sendRequest(cfg, {}, cb);
};

export const progressSaveAsTemplateFormEffect = (cfg, cb?) => {
  const sendRequest = Api.execBase({
    action: progressSaveAsTemplateFormAction,
    method: updatSaveAsTemplateFormProgress,
  });

  return sendRequest(cfg, {}, cb);
};

export const saveBundleAsTemplateEffect = (cfg, cb?) => {
  const sendRequest = Api.execBase({
    action: saveBundleAsTemplateAction,
    method: saveBundleAsTemplate,
  });

  return sendRequest(cfg, {}, cb);
};

export const regressFormDocumentEffect = (cfg, cb?) => {
  const sendRequest = Api.execBase({
    action: regressFormDocumentAction,
    method: updateDynamicFormRegress,
  });

  return sendRequest(cfg, {}, cb);
};

export const updateDynamicFormActiveQuestionEffect = (cfg) => (dispatch) => {
  dispatch(updateDynamicFormActiveQuestionAction(cfg));
};

export const updateDynamicFormResponseEffect = (cfg) => (dispatch) => {
  dispatch(updateDynamicFormResponseAction(cfg));
};

export const updateDynamicFormRequestEffect = (cfg: Partial<DynamicFormType>) => (dispatch) => {
  dispatch(requestDynamicFormDocumentAction(cfg));
};

export const updateDynamicFormEffect = (cfg: Partial<DynamicFormType>) => (dispatch) => {
  dispatch(updateDynamicFormAction(cfg));
};

export const updateDynamicFormSignatureConfigEffect =
  (cfg: { responses?: FormDocumentAnswersType }) => (dispatch) => {
    dispatch(updateDynamicFormSignatureConfigAction(cfg));
  };

export const getDynamicPostFormEffect = (cfg, cb?) => {
  const sendRequest = Api.execBase({
    action: requestDynamicPostForm,
    method: getDynamicPostFormData,
  });

  return sendRequest(cfg, {}, cb);
};

export const getSignatoriesEffect = (cfg, cb?) => {
  const sendRequest = Api.execBase({
    action: requestSignatories,
    method: getSignatories,
  });

  return sendRequest(cfg, {}, cb);
};

export const updateSignatoriesEffect = (cfg, cb?) => {
  const sendRequest = Api.execBase({
    action: updateRequestSignatories,
    method: updateSignatories,
  });

  return sendRequest(cfg, {}, cb);
};

export const updateTemplateSignatoriesEffect = (cfg, cb?) => {
  const sendRequest = Api.execBase({
    action: updateTemplateSignatoriesAction,
    method: updateTemplateSignatories,
  });

  return sendRequest(cfg, {}, cb);
};

export const updateDynamicFormEditorConfigEffect = (cfg) => (dispatch) => {
  dispatch(updateDynamicFormEditorConfigAction(cfg));
};

export const deleteDocumentEffect = (cfg, cb?) => {
  const sendRequest = Api.execBase({
    action: deleteDocumentAction,
    method: deleteFormDocument,
  });

  return sendRequest(cfg, {}, cb);
};

export const deleteTemplateFromBundleEffect = (cfg, cb?) => {
  const sendRequest = Api.execBase({
    action: deleteDocumentAction,
    method: deleteTemplateFromBundle,
  });

  return sendRequest(cfg, {}, cb);
};

export const deleteTemplateEffect = (cfg, cb?) => {
  const sendRequest = Api.execBase({
    action: deleteDocumentAction,
    method: deleteFormTemplate,
  });

  return sendRequest(cfg, {}, cb);
};
