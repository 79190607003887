import moment from 'moment';
import styles from './styles.module.scss';
import { formatCurrency } from 'pages/Workshop/Transactions/components/TransactionsAndProjectsTable/helper';

export const AreaGraphTooltip = (props) => {
  const { title, detail, valueAsCurrency = true } = props;
  const { data, value } = detail[0] || {
    data: '',
  };

  if (!data) return <></>;

  return (
    <div>
      <div className={styles.tooltipTitle}>{moment(title).format('LL')}</div>
      <div className={styles.tooltipValue}>
        {valueAsCurrency ? formatCurrency(value, '$') : value}
      </div>
      {data.count > 0 ? <div className={styles.listCount}>{`${data.count} Listings`}</div> : <></>}
    </div>
  );
};
