import { Table } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Modal } from 'components-antd';
import { getTimePeriodSelector } from 'store/selectors/clarity';
import { Wrapper as PendingWrapper } from 'components';
import { getModalTableColumns } from './ModalTableColumns';
import { useFetchFinancialStats } from 'pages/ClarityRevamped/hooks';

import styles from '../styles.module.scss';

export const FinancialsTableModal = ({
  title,
  aggregationType,
  summaryType,
  summaryKey,
  columnType,
  ledgerAccountId,
  memo,
  year,
  month,
  isoWeek,
  date,
  open,
  onCancel,
  selectedAgent,
}) => {
  const timePeriod = useSelector(getTimePeriodSelector);
  const [data, setData] = useState([]);
  const [isPending, setIsPending] = useState(false);

  const getStats = useFetchFinancialStats();

  useEffect(() => {
    getStats({
      setSummary: () => {},
      setData,
      setIsPending,
      key: summaryKey,
      aggregationType: aggregationType,
      summaryType: summaryType,
      aggregateDataFormat: 'Tabular',
      memo: memo,
      ledgerAccountId: ledgerAccountId,
      year: year,
      quarter: null,
      month: timePeriod === 'YTD' ? month : null,
      isoWeek: timePeriod === 'QTD' ? isoWeek : null,
      date: timePeriod === 'MTD' || timePeriod === 'WEEK' ? date : null,
      transactionAgentUserId: selectedAgent?.TransactionAgentUserId,
      transactionAgentContactId: selectedAgent?.TransactionAgentContactId,
    });
  }, [timePeriod, year, month, isoWeek]);

  return (
    <Modal width={1000} className={styles.tableModal} open={open} footer={null} onCancel={onCancel}>
      <div className={styles.modalContent}>
        <h2 className={styles.modalHeader}>{title}</h2>
        <PendingWrapper isPending={isPending} className={styles.tableContainer}>
          <Table
            className={styles.statsTable}
            columns={getModalTableColumns(columnType) as any}
            dataSource={data}
            pagination={false}
          />
        </PendingWrapper>
      </div>
    </Modal>
  );
};
