import React from 'react';
import { Avatar } from 'antd';
import { Avatar as GenericAvatar } from 'components/Icons';
import styles from './styles.module.scss';

interface IAuditLogAvatar {
  firstLastName?: string;
  imgUrl?: string;
}

export const AuditLogAvatar: React.FC<IAuditLogAvatar> = ({ firstLastName, imgUrl, children }) => {
  if (firstLastName === undefined && imgUrl === undefined) {
    return (
      <div className={styles.genericAvatar}>
        <GenericAvatar />
      </div>
    );
  } else if (imgUrl) {
    return (
      <Avatar style={{ verticalAlign: 'middle' }} size={24} src={imgUrl && imgUrl}>
        {children}
      </Avatar>
    );
  } else {
    return (
      <Avatar
        style={{ backgroundColor: '#252D44', verticalAlign: 'middle', textTransform: 'capitalize' }}
        size={24}
      >
        {firstLastName && firstLastName}
        {children}
      </Avatar>
    );
  }
};
