import { createAction } from 'redux-actions';

export const getPropertyReferenceDataAction = createAction('REQUEST/GET_PROPERTY_REFERENCE_DATA');
export const getPropertyReferenceStatsAction = createAction('REQUEST/GET_PROPERTY_REFERENCE_STATS');
export const getPropertyReferenceDetailsAction = createAction(
  'REQUEST/GET_PROPERTY_REFERENCE_DETAILS',
);
export const getPropertyReferenceNeighborhoodAction = createAction(
  'REQUEST/GET_PROPERTY_REFERENCE_NEIGHBORHOOD',
);
