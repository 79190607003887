import { get } from 'lodash-es';
import { useEffect, useState } from 'react';
import validator from 'validator';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { Modal } from 'components-antd';
import { Button } from 'components';
import {
  getTransactionEffect,
  inviteTransactionParticipantEffect,
} from 'store/effects/transactions';
import { updateTransactionTaskAndOfferThreadsEffect } from 'store/effects/sockets/threads';
import { requestGetUserParticipantsListEffect } from 'store/effects/drawers/addParticipants';
import Content from './Content/index.tsx';

import styles from './styles.module.scss';

const TransactionInviteModal = (props) => {
  const { className, open, transactionId, onClose, data, callbackContactDelete } = props;
  const dispatch = useDispatch();
  const [participant, setParticipant] = useState({});
  const [isPending, setIsPending] = useState(false);

  useEffect(() => {
    if (data) {
      setParticipant({
        firstName: data?.FirstName,
        lastName: data?.LastName,
        roleId: data?.RoleId,
        customName: data?.customName,
        tagRole: data?.tagRole,
        email: '',
      });
    }
  }, [data]);

  const onSave = () => {
    setIsPending(true);
    const config = { transactionId, ...participant };
    dispatch(
      inviteTransactionParticipantEffect(config, { silent: true }, (err, response) => {
        if (!err) {
          const participants = get(response, 'data.Participants');
          dispatch(
            getTransactionEffect({ id: transactionId }, { silent: true }, (err) => {
              if (!err) {
                dispatch(updateTransactionTaskAndOfferThreadsEffect({ participants }));
                onCancel();
              }
            }),
          );
          dispatch(
            requestGetUserParticipantsListEffect(
              {
                includeCollaborators: true,
              },
              { silent: true },
            ),
          );
          if (callbackContactDelete) callbackContactDelete();
        }
        setIsPending(false);
      }),
    );
  };

  const isDisabled = () =>
    (!participant?.email && !(participant?.customName && participant.customEmail)) ||
    (participant?.customName && (!participant?.customEmail || !participant?.lastName)) ||
    (participant?.customEmail && !validator.isEmail(participant.customEmail)) ||
    !participant?.roleId ||
    !!participant.error ||
    !!participant.nameError;

  const onCancel = () => {
    setParticipant({});
    onClose();
  };

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      className={classNames(styles.inviteTransactionModal, className)}
      footer={null}
    >
      <h2 className={styles.heading}>Invite Party</h2>
      <Content participant={participant} setParticipant={setParticipant} />
      <div className={styles.modalFooter}>
        <Button
          className={styles.submitButton}
          loaderClassName={styles.submitButtonLoader}
          title="Invite"
          onClick={onSave}
          testid="save_button"
          isPending={isPending}
          disabled={isDisabled()}
        />
      </div>
    </Modal>
  );
};

TransactionInviteModal.propTypes = {
  className: PropTypes.string,
  transactionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func,
  open: PropTypes.bool,
  data: PropTypes.any,
  callbackContactDelete: PropTypes.func,
};

TransactionInviteModal.defaultProps = {
  className: '',
  transactionId: null,
  onClose: () => {},
  open: false,
  data: {},
  callbackContactDelete: () => {},
};

export default TransactionInviteModal;
