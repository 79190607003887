import React from 'react';

export const PlusIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M5.5 10H14.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path d="M10 14.5L10 5.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};
