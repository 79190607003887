import { ClientPlans } from 'pages/Radar/RadarKits/Plans/ClientPlan';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { requestGetCampaignsEffect } from 'store/effects/radarKits';
import { Wrapper } from 'components';
import { getCampaignsSelector, getFilteredCampaigns } from 'store/selectors/radarKits';
import { PENDING } from 'settings/constants/apiState';
import { SelectedKit } from './components/SelectedKit';
import { AddKitsModal } from './components/AddKitsModal';

export const Kits = ({ clientId }) => {
  const dispatch = useDispatch();
  const { state, data } = useSelector(getCampaignsSelector);
  const { nonAddedKits } = getFilteredCampaigns(data, clientId);
  const isPending = state === PENDING;
  const [selectedKit, setSelectedKit] = useState(null);
  const [addModalOpen, setAddModalOpen] = useState(false);

  // Fetch Campaigns
  useEffect(() => {
    dispatch(requestGetCampaignsEffect({}));
  }, []);

  const handleRowClick = (kit) => {
    setSelectedKit(kit);
  };

  const handleBack = () => {
    setSelectedKit(null);
  };

  return (
    <Wrapper isPending={isPending} className={styles.main}>
      {selectedKit ? (
        <SelectedKit kit={selectedKit} handleBack={handleBack} clientId={clientId} />
      ) : (
        <>
          <ClientPlans clientId={clientId} handleRowClick={handleRowClick} tableHeading={'KITs'} />
        </>
      )}
    </Wrapper>
  );
};
