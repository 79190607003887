import React from 'react';

import styles from './styles.module.scss';

export const CancelledDetails = ({ ReasonForCancellation, AdditionalCancellationComments }) => {
  return (
    <>
      <div className={styles.cancellationDetailsContainer}>
        <p className={styles.heading}>Cancellation Details</p>
        <div className={styles.infoWrapper}>
          <span className={styles.subHeading}>Reason:</span>
          <span className={styles.details}>
            {ReasonForCancellation ? ReasonForCancellation : '-'}
          </span>
        </div>
        <div className={styles.infoWrapper}>
          <span className={styles.subHeading}>Note:</span>
          <span className={styles.details}>
            {AdditionalCancellationComments ? AdditionalCancellationComments : '-'}
          </span>
        </div>
      </div>
    </>
  );
};
