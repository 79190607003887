import classNames from 'classnames';
import { Checkbox, InputNumber } from 'antd';
import styles from './styles.module.scss';

type YesNoPropsType = {
  questionHandler: Function;
  yes: boolean | null;
  objectKey: string;
  hasNumberInput?: boolean;
  inputLabel?: string;
  inputHandler?: Function;
  inputValue?: number;
  className?: string;
  optionClass?: string;
  inputClass?: string;
  yesClass?: string;
  inputPlaceholder?: string;
};

function YesNo(props: YesNoPropsType) {
  const numberInput = (
    <>
      <InputNumber
        className={classNames(styles.daysInputHolder, props.inputClass)}
        min={1}
        placeholder={props.inputPlaceholder || '0'}
        onChange={(e) => {
          if (props.inputHandler) props.inputHandler(e);
        }}
        value={props.inputValue ? props.inputValue : ''}
        step={1}
        precision={0}
      />
      <span className={classNames(styles.static)}>{props.inputLabel ? props.inputLabel : ''}</span>
    </>
  );

  return (
    <div className={classNames(styles.yesNo, props.className, props.yes ? props.yesClass : '')}>
      <div
        className={classNames(styles.option, props.optionClass, {
          [styles.hasInput]: props.hasNumberInput,
        })}
      >
        <Checkbox
          className={classNames(styles.checkbox, 'mosaikCheckbox')}
          checked={props.yes === null ? false : props.yes ? true : false}
          onChange={(e) => {
            props.questionHandler(true, props.objectKey);
          }}
        >
          <span className={styles.static}>Yes</span>
          {props.hasNumberInput && props.yes ? numberInput : <></>}
        </Checkbox>
      </div>
      <div className={classNames(styles.option, props.optionClass)}>
        <Checkbox
          className={classNames(styles.checkbox, 'mosaikCheckbox')}
          checked={props.yes === null ? false : props.yes ? false : true}
          onChange={(e) => {
            props.questionHandler(false, props.objectKey);
          }}
        >
          <span className={styles.static}>No</span>
        </Checkbox>
      </div>
    </div>
  );
}

export default YesNo;
