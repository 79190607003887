import { apiServer } from 'settings/web-services/api';

export const getPropertyReferenceData = () => {
  return apiServer.get('/propertyreferencedata/portfolio');
};

export const getPropertyReferenceStats = () => {
  return apiServer.get('/propertyreferencedata/portfolio-stats');
};

export const getPropertyReferenceDetails = (queryParams) => {
  return apiServer.get(`/propertyreferencedata/portfolio/details${queryParams}`);
};

export const getPropertyReferenceNeighborhood = (queryParams) => {
  return apiServer.get(`/propertyreferencedata/portfolio/neighborhood-activity${queryParams}`);
};
