import { createAction } from 'redux-actions';

export const requestGetUsersDashboardAction = createAction('REQUEST/GET_USERS_DASHBOARD');
export const requestGetUserDashboardAction = createAction('REQUEST/GET_USER_DASHBOARD');
export const requestPostDeactivateUserAction = createAction(
  'REQUEST/POST_DEACTIVATE_USER_DASHBOARD',
);
export const requestPostActivateUserAction = createAction('REQUEST/POST_ACTIVATE_USER_DASHBOARD');
export const searchUsersDashboardAction = createAction('DASHBOARD/SEARCH_USERS');
export const sortUsersDashboardAction = createAction('DASHBOARD/SORT_USERS');
export const requestPutUserDashboardAction = createAction('REQUEST/PUT_USER');
export const requestPutUserVerifyEmailAction = createAction('REQUEST/PUT_USER_VERIFY_EMAIL');
