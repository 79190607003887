import classNames from 'classnames';
import { Fragment, useEffect } from 'react';

import styles from './style.module.scss';
import { UploadDragger } from 'components-antd';
import { Icons } from '../Icons';
import { UPLOAD_FILE } from 'app-constants';
import { Typography } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { cloneDeep } from 'lodash-es';

export const FormFileUploader = (props) => {
  const { files, updateFiles, multiple = true, setFilesChangeCounter } = props;
  let { filesChangeCounter } = props;

  const onChange = ({ fileList }: UploadChangeParam<any>) => {
    if (fileList?.length) {
      filesChangeCounter++;

      if (filesChangeCounter === fileList.length) {
        if (!multiple) {
          const files = fileList.slice(-1).pop();

          updateFiles([files]);
          setFilesChangeCounter(filesChangeCounter);
        } else {
          updateFiles([...fileList]);
          setFilesChangeCounter(filesChangeCounter);
        }
      } else {
        setFilesChangeCounter(filesChangeCounter);
      }
    }
  };

  const deleteFile = (index) => {
    let newFiles = cloneDeep(files);

    newFiles = newFiles.filter((_, i) => i !== index);
    updateFiles(newFiles);
    setFilesChangeCounter(newFiles.length);
  };

  return (
    <Fragment>
      <div
        className={classNames(styles.uploadContainer, {
          [styles.uploadContainerNoFiles]: !files?.length,
          [styles.uploadedFilesContainer]: !!files?.length,
        })}
      >
        <UploadDragger
          action={UPLOAD_FILE}
          onChange={onChange}
          multiple={multiple}
          showUploadList={false}
          beforeUpload={() => false}
          accept="application/pdf"
          className={styles.uploadDragger}
          fileList={files}
        >
          <Icons variant={'uploadFile'} className={styles.uploadFileIcon} />

          <p className={styles.uploadHint}>Drag and drop files here</p>
          <p className={styles.uploadSecondHint}>or</p>
          <button className={styles.uploadButton}>
            <Typography className={styles.uploadText}>Choose File</Typography>
          </button>
        </UploadDragger>
      </div>

      {files?.map((file, index) => (
        <div className={styles.fileBox} key={`file-${file.name}-${index}`}>
          <div className={styles.fileNameBox}>
            <Icons variant={'file'} className={styles.fileIcon} />
            <span className={styles.fileName}>{file.name}</span>
          </div>
          <Icons
            variant={'trash'}
            onClick={() => deleteFile(index)}
            className={styles.deleteIcon}
          />
        </div>
      ))}
    </Fragment>
  );
};
