import { NamesContainer } from 'pages/Workshop/Transactions/components/NamesContainer';
import styles from './style.module.scss';
import { Avatar } from 'components';
import classNames from 'classnames';
import { Icons } from 'pages/Workshop/Forms/Icons';

export const FormColumn = ({ row }) => {
  const formName = row?.formName;

  return (
    <div className={classNames(styles.row, styles.name)}>
      <div className={styles.formAvatarIcon}>
        <Icons variant="file" />
      </div>
      <div className={styles.flexCol}>
        <span className={styles.formName}>{formName}</span>
      </div>
    </div>
  );
};
