export const ACCESS_TOKEN = 'accessToken';
export const REFRESH_TOKEN = 'refreshToken';
export const CHAT_ADVICE_STATUS = 'chatAdviceStatus';
export const EXTERNAL_TOKEN = 'mosaikExternalToken';
export const DEVICE_TOKEN = 'mosaikDeviceToken';
export const TOKEN_CONTEXT = 'mosaikTokenContext';
export const SEARCH_CRITERIA = 'searchCriteria';
export const SEARCH_INSTANCE_ID = 'searchINstanceID';
export const CONTEXT_PROPERTY_FEED = 'contextPropertyFeed';
export const MOBILE_REDIRECTION_URL = 'mobileRedirectionUrl';
export const BROKERAGE_LOGO = 'brokerageLogo';
export const CONCIERGE_LOCATIONS = 'conciergeLocations';
