import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { PENDING } from 'settings/constants/apiState';
import { onBoardingWizardEffect } from 'store/effects/onBoarding';
import { getOnBoardingSelector } from 'store/selectors/onBoarding';
import { getClientDefaultSearchSelector } from 'store/selectors/user';

import { convertPrefs, prefsIds, PARKING_GARAGE_TYPES } from 'settings/constants/preferences';
import OnBoardingWizard from 'pages/OnBoardingWizard';
import { getWizardStageValue } from 'pages/OnBoardingWizard/helpers';
import CommutesModal from 'pages/OnBoardingWizard/components/StageParts/StagePrefs/CommutesModal';
import { PropertyType } from 'components';
import { MosaikLogo } from 'components/Icons';
import { getSignUpRoleSelector } from 'store/selectors/loginGroup';
import Options from './Options';
import { Title, NextButton, Wrapper } from '../..';
import ParkingModal from './ParkingModal';
import AmenitiesModal from './AmenitiesModal';
import OutdoorSpaceModal from './OutdoorSpaceModal';

import styles from './styles.module.scss';
import { OnBoardingWrapper } from 'pages/OnBoarding/components/OnBoardingWrapper';
import { Question } from 'pages/RequestQuote/components';
import { OnBoardingContainer } from 'pages/OnBoarding/components/OnBoardingContainer';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { OnboardingContinueButton } from 'pages/OnBoarding/components/Forms/agent/components/OnboardingContinueButton';

const StagePrefs = ({ onNext, Controls, stageIndex }) => {
  const dispatch = useDispatch();
  const onBoarding = useSelector(getOnBoardingSelector);
  const signUpRole = useSelector(getSignUpRoleSelector);
  const defaultClientSearch = useSelector(getClientDefaultSearchSelector);
  const {
    homePrefers,
    amenitiesPrefs,
    parking,
    commutePrefs,
    importantLocationsPrefs,
    outdoorSpacePrefs,
    viewPref,
    commutesPref,
  } = OnBoardingWizard.propNames;
  const {
    HomePrefs: homePrefersDefault,
    OutdoorPrefs: outdoorSpaceDefault,
    AmenitiesPrefs: amenitiesPrefersDefault,
    Parking: parkingDefault,
    CommutePrefs: commutePrefersDefault,
  } = defaultClientSearch || {};
  const common = { obj: onBoarding, stageIndex, signUpRole };
  const isHomeTypeLand =
    defaultClientSearch?.HomeType?.length === 1 &&
    defaultClientSearch.HomeType[0] === PropertyType.LAND;

  const [stageValue, setStageValue] = useState([]);
  const [commutes, setCommutes] = useState([]);
  const [importantLocations, setImportantLocations] = useState([]);
  const [editImportantLocations, setEditImportantLocations] = useState(false);
  const [amenitiesPrefsVal, setAmenitiesPrefsVal] = useState([]);
  const [parkingSpaces, setParkingSpaces] = useState(0);
  const [garageOnly, setGarageOnly] = useState(false);
  const [attachedGarage, setAttachedGarage] = useState(false);
  const [outdoorSpace, setOutdoorSpace] = useState([]);

  useEffect(() => {
    const storedHomePreferences =
      getWizardStageValue({
        ...common,
        propName: homePrefers,
      }) || [];
    const storedOutdoorSpacePrefs =
      getWizardStageValue({
        ...common,
        propName: outdoorSpacePrefs,
      }) || [];
    const storedAmenitiesPrefs =
      getWizardStageValue({
        ...common,
        propName: amenitiesPrefs,
      }) || [];
    const storedParkingSpaces =
      getWizardStageValue({
        ...common,
        propName: parking,
      }) || 0;
    const storedCommutes =
      getWizardStageValue({
        ...common,
        propName: commutePrefs,
      }) || [];

    if (storedParkingSpaces || parkingDefault) {
      const pref = storedParkingSpaces || parkingDefault;
      setStageValue((prevState) => [...prevState, parking]);
      setParkingSpaces(pref?.NumOfParkingSpaces);
      setGarageOnly(!!pref?.GarageOnly);
      setAttachedGarage(pref?.GarageType === PARKING_GARAGE_TYPES.Attached);
    }

    if (storedHomePreferences?.length || homePrefersDefault?.length) {
      const prefs = storedHomePreferences?.length ? storedHomePreferences : homePrefersDefault;
      const convertedHomePrefsVal = prefs.map(({ Preference }) => Preference);
      setStageValue((prevState) => [...prevState, ...convertedHomePrefsVal]);
    }

    if (storedOutdoorSpacePrefs?.length || outdoorSpaceDefault?.length) {
      const prefs = storedOutdoorSpacePrefs?.length ? storedOutdoorSpacePrefs : outdoorSpaceDefault;
      const outdoorSpaceVal = prefs.map(({ Preference }) => Preference);
      setStageValue((prevState) => [...prevState, outdoorSpacePrefs]);
      setOutdoorSpace(outdoorSpaceVal);
    }

    if (storedAmenitiesPrefs?.length || amenitiesPrefersDefault?.length) {
      const prefs = storedAmenitiesPrefs?.length ? storedAmenitiesPrefs : amenitiesPrefersDefault;
      const convertedAmenitiesVal = prefs.reduce(
        (acc, { Preference }) => (Preference ? { ...acc, [Preference]: true } : acc),
        {},
      );
      setStageValue((prevState) => [...prevState, amenitiesPrefs]);
      setAmenitiesPrefsVal({ [amenitiesPrefs]: convertedAmenitiesVal });
    }

    if (storedCommutes?.length || commutePrefersDefault?.length) {
      const prefs = storedCommutes?.length ? storedCommutes : commutePrefersDefault;
      const commutesOnly = prefs.filter((pref) => !pref?.IsImportantLocation);
      const importantLocationsOnly = prefs.filter((pref) => !!pref?.IsImportantLocation);
      if (commutesOnly.length) {
        setStageValue((prevState) => [...prevState, commutesPref]);
        setCommutes(commutesOnly);
      }
      if (importantLocationsOnly.length) {
        setStageValue((prevState) => [...prevState, importantLocationsPrefs]);
        setImportantLocations(importantLocationsOnly);
      }
    }
  }, [defaultClientSearch]); // eslint-disable-line

  const onSave = () => {
    const convertedAmenitiesPrefs = convertPrefs(
      amenitiesPrefsVal[amenitiesPrefs],
      OnBoardingWizard.importances.someWhat,
      'Preference',
      amenitiesPrefersDefault,
    );

    const homePrefs = stageValue.filter((o) => o === viewPref);

    const convertedHomePrefs = convertPrefs(
      homePrefs,
      OnBoardingWizard.importances.someWhat,
      'Preference',
      homePrefersDefault,
    );

    const convertedOutdoorPrefs = convertPrefs(
      outdoorSpace,
      OnBoardingWizard.importances.someWhat,
      'Preference',
      outdoorSpaceDefault,
    );

    const commuteLocations = [...commutes, ...importantLocations];

    const cfg = {
      stageIndex,
      DefaultPropertySearchPreferences: {
        [homePrefers]: convertedHomePrefs?.length ? convertedHomePrefs : undefined,
        ...(parkingSpaces
          ? {
              [parking]: {
                NumOfParkingSpaces: parkingSpaces,
                ...(garageOnly ? { GarageOnly: true } : {}),
                ...(garageOnly && attachedGarage
                  ? { GarageType: PARKING_GARAGE_TYPES.Attached }
                  : garageOnly
                  ? { GarageType: PARKING_GARAGE_TYPES.Detached }
                  : {}),
                ImportanceAndWeight: {
                  Importance:
                    parkingDefault?.ImportanceAndWeight?.Importance ||
                    OnBoardingWizard.importances.someWhat,
                },
              },
            }
          : { [parking]: undefined }),
        [outdoorSpacePrefs]: convertedOutdoorPrefs?.length ? convertedOutdoorPrefs : undefined,
        [amenitiesPrefs]: convertedAmenitiesPrefs?.length ? convertedAmenitiesPrefs : undefined,
        [commutePrefs]: commuteLocations?.length ? commuteLocations : undefined,
      },
    };
    dispatch(
      onBoardingWizardEffect(cfg, {}, (err) => {
        if (!err) {
          onNext(isHomeTypeLand ? 'stageNeighbors' : null);
        }
      }),
    );
  };

  const onSaveOutdoorSpaceValue = (value) => {
    if (!value?.length) {
      const newStageValue = stageValue.filter((o) => o !== outdoorSpacePrefs);
      setStageValue(newStageValue);
    } else {
      setStageValue((prevState) => [...prevState, outdoorSpacePrefs]);
    }

    setOutdoorSpace(value);
  };

  const onSaveAmenitiesValue = (value) => {
    const copyAmenitiesPrefs = { ...amenitiesPrefsVal };

    if (!Object.keys(value).length) {
      const newStageValue = stageValue.filter((o) => o !== amenitiesPrefs);
      setStageValue(newStageValue);
      delete copyAmenitiesPrefs[prefsIds.amenities];
      return setAmenitiesPrefsVal(copyAmenitiesPrefs);
    } else {
      setStageValue((prevState) => [...prevState, amenitiesPrefs]);
    }

    copyAmenitiesPrefs[prefsIds.amenities] = value;
    setAmenitiesPrefsVal(copyAmenitiesPrefs);
  };

  const onSaveParkingValue = (parking) => {
    if (parking.count === 0) {
      const newStageValue = stageValue.filter((o) => o !== parking);
      setStageValue(newStageValue);
    } else {
      setStageValue((prevState) => [...prevState, parking]);
    }
    setParkingSpaces(parking.count);
    setGarageOnly(parking.garageOnly);
    setAttachedGarage(parking.attachedGarage);
  };

  const onSaveCommuteLocationsValues = (values, IsImportantLocation) => {
    if (!values?.length) {
      const newStageValue = stageValue.filter((o) =>
        o !== IsImportantLocation ? importantLocationsPrefs : commutesPref,
      );
      setStageValue(newStageValue);
    } else {
      setStageValue((prevState) => [
        ...prevState,
        IsImportantLocation ? importantLocationsPrefs : commutesPref,
      ]);
    }
    const locations = values?.map((value) => ({
      ...value,
      IsImportantLocation: IsImportantLocation,
    }));
    IsImportantLocation ? setImportantLocations(locations) : setCommutes(locations);
  };

  const onChangePrefs = (val) => {
    if (!val.includes(prefsIds.outdoorPrefs)) {
      setOutdoorSpace([]);
    }

    if (!val.includes(prefsIds.amenities)) {
      setAmenitiesPrefsVal({});
    }

    if (!val.includes(prefsIds.parking)) {
      setParkingSpaces(0);
    }

    if (!val.includes(prefsIds.commutes)) {
      setCommutes([]);
    }

    if (!val.includes(prefsIds.proximityToImportantLocations)) {
      setImportantLocations([]);
    }

    setStageValue(val);
  };

  const isPending = onBoarding.state === PENDING;

  return (
    <OnBoardingWrapper isPending={isPending}>
      <Controls onNext={onSave} className={styles.controls} variant="lightFull" />
      <OnBoardingContainer>
        <Question>Which of the following are important to you?</Question>
        <AnswersContainer className={styles.answersContainer}>
          <Options
            onChange={onChangePrefs}
            value={stageValue}
            outdoorSpaceValue={outdoorSpace}
            amenitiesValue={amenitiesPrefsVal[prefsIds.amenities]}
            parkingSpacesValue={parkingSpaces}
            commutesValue={commutes}
            importantLocationsValue={importantLocations}
            setEditImportantLocations={setEditImportantLocations}
            disabled={isPending}
            isHomeTypeLand={isHomeTypeLand}
            testid="home_features_option"
          />
        </AnswersContainer>
        <ButtonsContainer>
          <OnboardingContinueButton
            testid="home_features_continue"
            onClick={onSave}
            isPending={isPending}
          />
        </ButtonsContainer>
      </OnBoardingContainer>
      <OutdoorSpaceModal onNext={(val) => onSaveOutdoorSpaceValue(val)} value={outdoorSpace} />
      <ParkingModal
        onNext={(val) => onSaveParkingValue(val)}
        value={{
          parkingSpaces,
          garageOnly,
          attachedGarage,
        }}
      />
      <AmenitiesModal
        onNext={(val) => onSaveAmenitiesValue(val)}
        value={amenitiesPrefsVal[prefsIds.amenities]}
      />
      <CommutesModal
        onNext={(val) => onSaveCommuteLocationsValues(val, editImportantLocations)}
        value={editImportantLocations ? importantLocations : commutes}
        title={
          editImportantLocations
            ? 'Which locations do you want to be close to?'
            : 'Where are you commuting to?'
        }
      />
    </OnBoardingWrapper>
  );
};

StagePrefs.propTypes = {
  onNext: PropTypes.func,
  stageIndex: PropTypes.number,
  Controls: PropTypes.elementType,
};

StagePrefs.defaultProps = {
  onNext: () => {},
  stageIndex: undefined,
  Controls: () => null,
};

export default StagePrefs;
