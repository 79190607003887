import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'components';
import { Question } from 'pages/RequestQuote/components';
import { getTransactionPreFormSelector } from 'store/selectors/transactions';
import { TransactionLeadSource, TransactionBuySideCommissionResponsibility } from 'app-constants';
import { setTransactionPreFormQuestionsEffect } from 'store/effects/transactions';
import { transactionPreFormQuestionsIds } from 'settings/constants/transaction';
import { Continue } from '../../Continue';
import { AnswersContainer } from '../../AnswersContainer';
import { ButtonsContainer } from '../../ButtonsContainer';
import styles from './styles.module.scss';
import { AnswerCard } from '../../../AnswerCard';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { Checkbox } from 'components-antd';
import { CommissionField, OtherFees, BuyerCommissionContribution } from './components';
import { useHistory } from 'react-router-dom';
import { NameAutocomplete } from 'pages/Workshop/Transactions/TransactionFinancials/components';
import { getFeeNames, getLeadSourceNames } from 'api/financials';
import {
  calculateGCI,
  calculateRate,
  isCommissionWithinRangeOfPrice,
  isFeesWithinRangeOfPrice,
} from './validationHelpers';

interface FinancingTypeProps {
  className?: string;
  onNext?: (event) => void;
  currentQuestionId?: typeof transactionPreFormQuestionsIds[keyof typeof transactionPreFormQuestionsIds];
  setStage?: (event) => void;
}

const leadSourceOptions = Object.values(TransactionLeadSource).map((type) => ({
  value: type,
  name: type,
}));

interface OtherFee {
  Value: number | '' | null;
  Percent: number | '' | null;
  IsFeeTypePercentage: boolean;
  Description: string;
}

export const initialState: OtherFee = {
  Description: '',
  IsFeeTypePercentage: false,
  Value: null,
  Percent: null,
};

export const FinancingDetails = ({
  className,
  currentQuestionId,
  onNext = () => {},
  setStage,
}: FinancingTypeProps) => {
  const dispatch = useDispatch();
  const { preForm } = useSelector(getTransactionPreFormSelector);
  const transactionPrice =
    preForm?.[transactionPreFormQuestionsIds.purchasePrice] ||
    preForm?.[transactionPreFormQuestionsIds.listingPrice];
  const history = useHistory();
  const isTransactionUnderContractRoute = useMemo(
    () => history.location?.pathname.includes('undercontract'),
    [],
  );
  const [inputField, setInputField] = useState<any>(
    preForm?.[transactionPreFormQuestionsIds.leadSource] ?? '',
  );

  const [autocompleteLeadSourceNames, setAutocompleteLeadSourceNames] = useState<any>(null);
  const [autocompleteFeeNames, setAutocompleteFeeNames] = useState<any>(null);

  const fetchAutocompleteLeadSourceNames = async () => {
    let autocompleteLeadSourceNames = await getLeadSourceNames();
    setAutocompleteLeadSourceNames(autocompleteLeadSourceNames?.LeadSourceTitles);
  };

  const fetchAutocompleteFeeNames = async () => {
    let autocompleteFeeNames = await getFeeNames();
    setAutocompleteFeeNames(autocompleteFeeNames?.FeeTitles);
  };

  useEffect(() => {
    fetchAutocompleteLeadSourceNames();
    fetchAutocompleteFeeNames();
  }, []);

  const [otherFeeData, setOtherFeeData] = useState<OtherFee[]>(
    preForm?.[transactionPreFormQuestionsIds.otherFees] ?? [{ ...initialState }],
  );
  const [otherFee, setOtherFee] = useState<boolean>(false);
  const [referralFee, setReferralFee] = useState<boolean>(false);
  const [validateFees, setValidateFees] = useState(true);
  const [validateCommissions, setValidateCommissions] = useState(true);
  const [validateBuyerCommissionContributions, setValidateBuyerCommissionContributions] =
    useState(true);

  useEffect(() => {
    setReferralFee(!!preForm?.[transactionPreFormQuestionsIds.referralFee]);
    setOtherFee(!!preForm?.[transactionPreFormQuestionsIds.otherFees]?.length);
  }, []);

  useEffect(() => {
    const { Buyer, Seller } = preForm?.[transactionPreFormQuestionsIds.whoAreYouRepresenting] || {};
    if (Buyer && !Seller) {
      if (preForm?.[transactionPreFormQuestionsIds.totalCommission]?.Value > 0) {
        setValidateFees(
          isFeesWithinRangeOfPrice(
            preForm?.[transactionPreFormQuestionsIds.totalCommission],
            preForm?.[transactionPreFormQuestionsIds.referralFee],
            otherFeeData,
          ),
        );
      }
      if (preForm?.[transactionPreFormQuestionsIds.totalCommission]) {
        setValidateCommissions(
          isCommissionWithinRangeOfPrice(
            preForm?.[transactionPreFormQuestionsIds.totalCommission]?.Value,
            transactionPrice,
          ),
        );
      }
    } else {
      if (preForm?.[transactionPreFormQuestionsIds.sellerCommission]?.Value > 0) {
        setValidateFees(
          isFeesWithinRangeOfPrice(
            preForm?.[transactionPreFormQuestionsIds.sellerCommission],
            preForm?.[transactionPreFormQuestionsIds.referralFee],
            otherFeeData,
          ),
        );
      }
      if (
        preForm?.[transactionPreFormQuestionsIds.sellerCommission] ||
        preForm?.[transactionPreFormQuestionsIds.buyerCommission]
      ) {
        setValidateCommissions(
          isCommissionWithinRangeOfPrice(
            preForm?.[transactionPreFormQuestionsIds.sellerCommission]?.Value,
            transactionPrice,
            preForm?.[transactionPreFormQuestionsIds.buyerCommission]?.Value,
          ),
        );
      }
    }

    if (
      preForm?.[transactionPreFormQuestionsIds.buySideCommissionResponsibility] ===
      TransactionBuySideCommissionResponsibility.Both
    ) {
      setValidateBuyerCommissionContributions(
        isCommissionWithinRangeOfPrice(
          preForm?.[transactionPreFormQuestionsIds.buyerCommissionContribution]?.Value,
          isTransactionUnderContractRoute ||
            preForm?.[transactionPreFormQuestionsIds.isPropertyUnderContract]
            ? preForm?.[transactionPreFormQuestionsIds.buyerCommission]?.Value
            : preForm?.[transactionPreFormQuestionsIds.totalCommission]?.Value,
          preForm?.[transactionPreFormQuestionsIds.sellerCommissionContribution]?.Value,
        ),
      );
    }
  }, [
    otherFeeData,
    referralFee,
    preForm?.[transactionPreFormQuestionsIds.referralFee],
    preForm?.[transactionPreFormQuestionsIds.totalCommission],
    preForm?.[transactionPreFormQuestionsIds.buyerCommissionContribution],
    preForm?.[transactionPreFormQuestionsIds.sellerCommissionContribution],
    preForm?.[transactionPreFormQuestionsIds.buyerCommission],
    preForm?.[transactionPreFormQuestionsIds.sellerCommission],
  ]);

  const valid =
    (otherFee
      ? otherFeeData?.every(
          (obj) =>
            obj.Description !== null &&
            obj.Description !== '' &&
            (obj.Value || obj.Value !== 0) &&
            (obj.Percent || obj.Percent !== 0) &&
            Object.values(obj).every((value) => value !== null), // Ensure no value is null
        )
      : true) && (referralFee ? preForm?.[transactionPreFormQuestionsIds.referralFee] : true);

  const onAnswerHandler = (answer, key: string) => {
    dispatch(
      setTransactionPreFormQuestionsEffect({
        [transactionPreFormQuestionsIds[key]]: answer,
      }),
    );
  };

  const onNextHandler = (event) => {
    if (otherFee) {
      dispatch(
        setTransactionPreFormQuestionsEffect({
          [transactionPreFormQuestionsIds.otherFees]: otherFeeData?.map((item: any) => ({
            Description: item?.Description,
            IsFeeTypePercentage: item?.IsFeeTypePercentage,
            Value: item?.IsFeeTypePercentage ? parseFloat(item?.Percent) : parseFloat(item?.Value),
          })),
        }),
      );
    }

    if (setStage) {
      setStage(transactionPreFormQuestionsIds.purchaseAgreement);
    }

    if (currentQuestionId === transactionPreFormQuestionsIds.financingDetails) {
      onNext(event);
    }
  };

  const updateOtherValues = (
    val: any,
    index: number,
    key: string,
    IsFeeTypePercentage: boolean,
    otherFeeData: any[],
  ) => {
    let updated = [...otherFeeData];
    const isToggled = updated && IsFeeTypePercentage !== updated[index]?.IsFeeTypePercentage;

    if (
      key === 'IsFeeTypePercentage' ||
      key === 'Description' ||
      isToggled ||
      (IsFeeTypePercentage && val == updated[index]?.Percent) ||
      (!IsFeeTypePercentage && val == updated[index]?.Value)
    ) {
      updated[index][key] = val;
    } else {
      if (IsFeeTypePercentage) {
        updated[index].Percent = val;
        updated[index].Value = calculateGCI(val, commissionComparePrice);
      } else {
        updated[index].Value = val;
        updated[index].Percent = calculateRate(val, commissionComparePrice);
      }
    }

    return updated;
  };

  const setOtherValues = (val: any, index: number, key: string, IsFeeTypePercentage: boolean) => {
    // Get the updated values
    const updated = updateOtherValues(val, index, key, IsFeeTypePercentage, otherFeeData);

    // Directly set the updated data
    setOtherFeeData(updated);
  };

  const showBuyerSellerContributionFields = useMemo(
    () =>
      preForm?.[transactionPreFormQuestionsIds.buySideCommissionResponsibility] ===
      TransactionBuySideCommissionResponsibility.Both,
    [preForm?.[transactionPreFormQuestionsIds.buySideCommissionResponsibility]],
  );

  const resetFeesSections = () => {
    onAnswerHandler(undefined, 'referralFee');
    onAnswerHandler(undefined, 'otherFees');
    setOtherFeeData([{ ...initialState }]);
    setOtherFee(false);
    setReferralFee(false);
  };

  const commissionComparePrice = useMemo(() => {
    const { Buyer, Seller } = preForm?.[transactionPreFormQuestionsIds.whoAreYouRepresenting] || {};
    const commission =
      Buyer && !Seller
        ? preForm?.[transactionPreFormQuestionsIds.totalCommission]
        : preForm?.[transactionPreFormQuestionsIds.sellerCommission];

    if (commission?.IsFeeTypePercentage) {
      return calculateGCI(commission?.Percent, transactionPrice);
    }

    return commission?.Value;
  }, [
    preForm?.[transactionPreFormQuestionsIds.totalCommission],
    preForm?.[transactionPreFormQuestionsIds.sellerCommission],
  ]);

  const isFeesSectionVisible = useMemo(() => {
    const { Buyer, Seller } = preForm?.[transactionPreFormQuestionsIds.whoAreYouRepresenting] || {};
    if (Buyer && Seller)
      return (
        preForm?.[transactionPreFormQuestionsIds.buyerCommission]?.Value > 0 &&
        preForm?.[transactionPreFormQuestionsIds.sellerCommission]?.Value > 0
      );
    if (Buyer) return preForm?.[transactionPreFormQuestionsIds.totalCommission]?.Value > 0;
    if (Seller) return preForm?.[transactionPreFormQuestionsIds.sellerCommission]?.Value > 0;
  }, [
    preForm?.[transactionPreFormQuestionsIds.totalCommission],
    preForm?.[transactionPreFormQuestionsIds.buyerCommission],
    preForm?.[transactionPreFormQuestionsIds.sellerCommission],
  ]);

  const recalculateBuyerContributions = (updatedObject) => {
    const { buyerCommissionContribution, sellerCommissionContribution } = preForm || {};
    const updatedBuyerContribution = {
      ...buyerCommissionContribution,
      ...(buyerCommissionContribution?.IsFeeTypePercentage
        ? { Value: calculateGCI(buyerCommissionContribution?.Percent, updatedObject?.Value) }
        : {
            Percent: calculateRate(buyerCommissionContribution?.Value, updatedObject?.Value),
          }),
    };
    const updatedSellerContribution = {
      ...sellerCommissionContribution,
      ...(sellerCommissionContribution?.IsFeeTypePercentage
        ? { Value: calculateGCI(sellerCommissionContribution?.Percent, updatedObject?.Value) }
        : {
            Percent: calculateRate(sellerCommissionContribution?.Value, updatedObject?.Value),
          }),
    };

    onAnswerHandler(updatedBuyerContribution, 'buyerCommissionContribution');
    onAnswerHandler(updatedSellerContribution, 'sellerCommissionContribution');
  };

  const recalculateFees = (updatedObject) => {
    const otherFeesUpdated = otherFeeData?.map((item) => ({
      ...item,
      ...(item?.IsFeeTypePercentage
        ? { Value: calculateGCI(item?.Percent, updatedObject?.Value) }
        : {
            Percent: calculateRate(item?.Value, updatedObject?.Value),
          }),
    }));
    setOtherFeeData(otherFeesUpdated);
  };

  const handleCommissionUpdate = (object, key, comparisonPrice) => {
    const previous = preForm?.[transactionPreFormQuestionsIds[key]];
    const isToggled = previous && object?.IsFeeTypePercentage !== previous?.IsFeeTypePercentage;
    const value = object?.Value === '' || null ? null : parseFloat(object?.Value);

    if (
      isToggled ||
      (object?.IsFeeTypePercentage && value == previous?.Percent) ||
      (!object?.IsFeeTypePercentage && value == previous?.Value)
    ) {
      // Handle toggling between $ / % button
      const updatedObject = {
        ...previous,
        IsFeeTypePercentage: object?.IsFeeTypePercentage,
      };
      onAnswerHandler(updatedObject, key);
    } else {
      const value = object?.Value === '' ? null : parseFloat(object?.Value);
      const updatedObject = {
        IsFeeTypePercentage: object?.IsFeeTypePercentage,
        ...(object?.IsFeeTypePercentage
          ? {
              Percent: object?.Value,
              Value: calculateGCI(value, comparisonPrice),
            }
          : {
              Value: object?.Value || '',
              Percent: calculateRate(value, comparisonPrice) || 0,
            }),
      };

      // If commission is entered zero, reset the fees fields
      if (!updatedObject?.Value) {
        if (
          key === transactionPreFormQuestionsIds.sellerCommission ||
          key === transactionPreFormQuestionsIds.totalCommission
        )
          resetFeesSections();
      } else {
        if (
          key === transactionPreFormQuestionsIds.sellerCommission ||
          key === transactionPreFormQuestionsIds.totalCommission
        )
          recalculateFees(updatedObject);

        if (
          (key === transactionPreFormQuestionsIds.buyerCommission ||
            key === transactionPreFormQuestionsIds.totalCommission) &&
          preForm?.[transactionPreFormQuestionsIds.buySideCommissionResponsibility] ===
            TransactionBuySideCommissionResponsibility.Both
        )
          recalculateBuyerContributions(updatedObject);
      }

      onAnswerHandler(updatedObject, key);
    }
  };

  return (
    <div testid="what_financing_type" className={classNames(styles.container, className)}>
      <Question>{"Let's confirm a few financial details."}</Question>
      <AnswersContainer>
        <div className={styles.answerContainer}>
          {preForm?.[transactionPreFormQuestionsIds?.whoAreYouRepresenting]?.Seller ||
          isTransactionUnderContractRoute ? (
            <>
              <AnswerCard>
                <CommissionField
                  label="What is the list-side commission"
                  value={
                    preForm?.[transactionPreFormQuestionsIds.sellerCommission]?.IsFeeTypePercentage
                      ? preForm?.[transactionPreFormQuestionsIds.sellerCommission]?.Percent
                      : preForm?.[transactionPreFormQuestionsIds.sellerCommission]?.Value
                  }
                  IsFeeTypePercentage={
                    preForm?.[transactionPreFormQuestionsIds.sellerCommission]
                      ?.IsFeeTypePercentage ?? true
                  }
                  update={(object) =>
                    handleCommissionUpdate(object, 'sellerCommission', transactionPrice)
                  }
                />
              </AnswerCard>
              <AnswerCard>
                <CommissionField
                  label="What is the buy-side commission?"
                  value={
                    preForm?.[transactionPreFormQuestionsIds.buyerCommission]?.IsFeeTypePercentage
                      ? preForm?.[transactionPreFormQuestionsIds.buyerCommission]?.Percent
                      : preForm?.[transactionPreFormQuestionsIds.buyerCommission]?.Value
                  }
                  IsFeeTypePercentage={
                    preForm?.[transactionPreFormQuestionsIds.buyerCommission]
                      ?.IsFeeTypePercentage ?? true
                  }
                  update={(object) =>
                    handleCommissionUpdate(object, 'buyerCommission', transactionPrice)
                  }
                />
                {(isTransactionUnderContractRoute ||
                  preForm?.[transactionPreFormQuestionsIds.isPropertyUnderContract]) &&
                  preForm?.[transactionPreFormQuestionsIds.buyerCommission]?.Value > 0 && (
                    <BuyerCommissionContribution
                      preForm={preForm}
                      onAnswerHandler={onAnswerHandler}
                      validateBuyerCommissionContributions={validateBuyerCommissionContributions}
                      handleCommissionUpdate={handleCommissionUpdate}
                      isTransactionUnderContractRoute={isTransactionUnderContractRoute}
                      showBuyerSellerContributionFields={showBuyerSellerContributionFields}
                    />
                  )}
              </AnswerCard>
              {!validateCommissions &&
                (preForm?.[transactionPreFormQuestionsIds.buyerCommission] ||
                  preForm?.[transactionPreFormQuestionsIds.sellerCommission]) && (
                  <p className={styles.errorValidationText}>
                    * Total commission can never be greater than price.
                  </p>
                )}
            </>
          ) : (
            <AnswerCard>
              <CommissionField
                label="What is the commission rate?"
                value={
                  preForm?.[transactionPreFormQuestionsIds.totalCommission]?.IsFeeTypePercentage
                    ? preForm?.[transactionPreFormQuestionsIds.totalCommission]?.Percent
                    : preForm?.[transactionPreFormQuestionsIds.totalCommission]?.Value
                }
                IsFeeTypePercentage={
                  preForm?.[transactionPreFormQuestionsIds.totalCommission]?.IsFeeTypePercentage ??
                  true
                }
                update={(object) =>
                  handleCommissionUpdate(object, 'totalCommission', transactionPrice)
                }
              />
              {!validateCommissions &&
                preForm?.[transactionPreFormQuestionsIds.totalCommission] && (
                  <p className={styles.errorValidationText}>
                    * Total commission can never be greater than price.
                  </p>
                )}
              {preForm?.[transactionPreFormQuestionsIds.totalCommission]?.Value > 0 && (
                <BuyerCommissionContribution
                  preForm={preForm}
                  onAnswerHandler={onAnswerHandler}
                  validateBuyerCommissionContributions={validateBuyerCommissionContributions}
                  handleCommissionUpdate={handleCommissionUpdate}
                  isTransactionUnderContractRoute={isTransactionUnderContractRoute}
                  showBuyerSellerContributionFields={showBuyerSellerContributionFields}
                />
              )}
            </AnswerCard>
          )}
          {!isTransactionUnderContractRoute && (
            <AnswerCard>
              <NameAutocomplete
                label={'What is the lead source?'}
                allNames={autocompleteLeadSourceNames?.map((item, idx) => ({
                  label: item,
                  value: idx + 1,
                }))}
                value={inputField}
                onChange={(value) => {
                  setInputField(value), onAnswerHandler(value?.label, 'leadSource');
                }}
                onSelect={(value) => {
                  setInputField(value), onAnswerHandler(value?.label, 'leadSource');
                }}
              />
            </AnswerCard>
          )}
          {!isTransactionUnderContractRoute && isFeesSectionVisible && (
            <Fragment>
              <AnswerCard>
                <p className={styles.label}>Is there a referral fee?</p>
                <div className={styles.checkboxContainer}>
                  <div className={styles.checkbox} onClick={() => setReferralFee(true)}>
                    <Checkbox className="mosaikCheckboxRounded" checked={referralFee} />
                    <p className={styles.checkboxLabel}>Yes</p>
                  </div>
                  <div
                    className={styles.checkbox}
                    onClick={() => {
                      setReferralFee(false);
                      onAnswerHandler(undefined, 'referralFee');
                    }}
                  >
                    <Checkbox className="mosaikCheckboxRounded" checked={!referralFee} />
                    <p className={styles.checkboxLabel}>No</p>
                  </div>
                </div>
                {referralFee && (
                  <Input
                    variant={Input.LIGHT_ROUND}
                    placeholder="0%"
                    isNumberFormat
                    suffix="%"
                    onChange={(e, val) => {
                      const value = val ? Number(val) : '';
                      onAnswerHandler(value, 'referralFee');
                    }}
                    value={
                      isTransactionUnderContractRoute
                        ? preForm?.[transactionPreFormQuestionsIds.referralFee]?.Value || ''
                        : preForm?.[transactionPreFormQuestionsIds.referralFee] ?? ''
                    }
                    label={'What is the fee?'}
                    minNumber={0}
                    maxNumber={100}
                    className={styles.marginTop}
                  />
                )}
              </AnswerCard>
              <AnswerCard>
                <p className={styles.label}>Are there any other fees?</p>
                <div className={styles.checkboxContainer}>
                  <div
                    className={styles.checkbox}
                    onClick={() => {
                      setOtherFee(true);
                    }}
                  >
                    <Checkbox className="mosaikCheckboxRounded" checked={otherFee} />
                    <p className={styles.checkboxLabel}>Yes</p>
                  </div>
                  <div
                    className={styles.checkbox}
                    onClick={() => {
                      setOtherFee(false);
                      setOtherFeeData([{ ...initialState }]);
                      onAnswerHandler(undefined, 'otherFee');
                    }}
                  >
                    <Checkbox className="mosaikCheckboxRounded" checked={!otherFee} />
                    <p className={styles.checkboxLabel}>No</p>
                  </div>
                </div>
                {otherFee && (
                  <OtherFees
                    otherFeeData={otherFeeData}
                    setOtherFeeData={setOtherFeeData}
                    setOtherValues={setOtherValues}
                    autocompleteFeeNames={autocompleteFeeNames}
                    commission={commissionComparePrice}
                  />
                )}
              </AnswerCard>
            </Fragment>
          )}
          {!validateFees && !isTransactionUnderContractRoute && isFeesSectionVisible && (
            <p className={styles.errorValidationText}>
              * The total sum of fees exceeds the commission.
            </p>
          )}
        </div>
      </AnswersContainer>
      <ButtonsContainer>
        <Continue
          onClick={onNextHandler}
          disabled={
            !valid ||
            (!isTransactionUnderContractRoute && isFeesSectionVisible && !validateFees) ||
            !validateCommissions ||
            !validateBuyerCommissionContributions
          }
        />
      </ButtonsContainer>
    </div>
  );
};
