import { createAction } from 'redux-actions';

export const updateTransactionAction = createAction('REQUEST/UPDATE_TRANSACTION');
export const updateKeyDatesAction = createAction('REQUEST/UPDATE_KEY_DATES_ACTION');

export const updateTransactionStatusAction = createAction(
  'REQUEST/UPDATE_TRANSACTION_STATUS_ACTION',
);
export const moveToActiveListingAction = createAction('REQUEST/MOVE_TO_ACTIVE_LISTING');
export const updateTransactionUnderContractAction = () =>
  createAction('REQUEST/UPDATE_TRANSACTION_UNDER_CONTRACT_ACTION');
export const updateTransactionUnderContractAction_ = () =>
  createAction('REQUEST/UPDATE_TRANSACTION_UNDER_CONTRACT_ACTION_');
export const addClientAddressAction = createAction('ADD/CLIENT_ADDRESS');
export const removeClientAddressAction = createAction('REMOVE/CLIENT_ADDRESS');
export const inviteTransactionParticipantAction = createAction(
  'REQUEST/INVITE_TRANSACTION_PARTICIPANT',
);
export const removeTransactionParticipantAction = createAction(
  'REQUEST/REMOVE_TRANSACTION_PARTICIPANT',
);
export const updateTransactionParticipantAccessAction = createAction(
  'UPDATE_TRANSACTION_PARTICIPANT_ACCESS',
);
export const updateTransactionParticipantsAction = createAction('UPDATE_TRANSACTION_PARTICIPANTS');
export const updateTransactionAgentsCoordinatorsAction = createAction(
  'UPDATE_TRANSACTION_AGENTS_COORDINATORS',
);
export const updateTransactionMilestonesAction = createAction('UPDATE_TRANSACTION_MILESTONES');
export const updateMilestoneStatusAction = createAction('UPDATE_MILESTONE_STATUS');
export const updateTransactionDetailAction = createAction('PATCH/UPDATE_TRANSACTION_DETAIL');
export const updateClosingPriceAction = createAction('PUT/UPDATE_CLOSING_PRICE_DETAIL');
export const updateTransactionContactAction = createAction('PUT/UPDATE_TRANSACTION_CONTACT');
export const deleteTransactionContactAction = createAction('PUT/DELETE_TRANSACTION_CONTACT');
export const updateCloseDatePriceAction = createAction('PUT/UPDATE_CLOSE_DATE_PRICE_DETAIL');
export const updateTransactionStatsAction = createAction('UPDATE_TRANSACTION_STATS');

export const updateUnReadNotesForTransactionAction = createAction(
  'UPDATE_UNREAD_NOTES_FOR_TRANSACTION',
);

export const resendParticipantInviteAction = createAction('RESEND_PARTICIPANT_INVITE');
