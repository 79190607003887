import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Spinner } from 'components';
import {
  AnonDataType,
  AuthDataType,
  BundleDataType,
  BundleTemplate,
  FormTemplateWizardParamType,
  SaveAsTemplateType,
} from 'types';
import { AllowedAnonEnum } from 'app-constants';
import { DynamicContainer } from '../DynamicContainer';
import { resetDynamicFormEffect, updateDynamicFormEffect } from 'store/effects/formProcess';
import { dynamicManager } from '../DynamicManager';

import styles from './styles.module.scss';

export interface DynamicFormManagerProps {
  anonData?: AnonDataType;
  authData?: AuthDataType;
  templateData?: FormTemplateWizardParamType & {
    editMode?: boolean;
    allTemplates?: BundleTemplate[];
    templateBundle?: boolean;
  };
  bundleData?: BundleDataType;
}

export const DynamicFormManager = ({
  anonData,
  authData,
  templateData,
  bundleData,
}: DynamicFormManagerProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const ref = useRef<HTMLDivElement>(null);
  const { state: formState } = useLocation<SaveAsTemplateType>();
  const memoizedState = useMemo(() => formState, []);
  const { redirectionLink, ...state } = memoizedState || { redirectionLink: '' };

  const [load, setLoader] = useState(true);

  const validateParams = () => {
    let error = false;
    if (!(authData || anonData || templateData || bundleData)) {
      error = true;
    } else if (anonData) {
      if (!Object.values(AllowedAnonEnum).includes(anonData.type)) {
        error = true;
      }
    } else if (authData) {
      if (!(authData.formDocumentPublicId && authData.formProcessPublicId)) {
        error = true;
      }
    } else if (templateData) {
      error = !templateData.templateId && !templateData.allTemplates;
    } else if (bundleData) {
      error = !bundleData.formProcessPublicId;
    }

    if (error) {
      throw new Error('Invalid param');
    }
  };

  useEffect(() => {
    validateParams();
    setLoader(true);

    dynamicManager.loadDynamicManager({
      authData,
      anonData,
      templateData,
      bundleData,
      history,
      containerRef: ref,
      saveAsTemplateData: state,
      redirectionLink,
    });

    if (templateData) {
      dynamicManager.getTemplateDocument(undefined, () => {
        setLoader(false);
      });
    } else if (state?.isLibraryTemplate || state?.proceedToSignMode) {
      dynamicManager.proceedtoSignMode(undefined, () => {
        setLoader(false);
        dynamicManager.updateLocationState({ isLibraryTemplate: false, proceedToSignMode: false });
      });
    } else {
      dynamicManager.getDocument(undefined, () => {
        setLoader(false);
      });
    }

    return () => {
      dispatch(resetDynamicFormEffect());
    };
  }, []);

  useEffect(() => {
    if (bundleData?.formBundle) {
      dispatch(
        updateDynamicFormEffect({
          allDocuments: bundleData?.allDocuments,
        }),
      );
    } else if (templateData?.allTemplates) {
      dispatch(
        updateDynamicFormEffect({
          allTemplates: templateData?.allTemplates || [],
        }),
      );
    }
  }, []);

  const renderScreen = () => {
    if (load) {
      return <Spinner />;
    } else if (!load) {
      return <DynamicContainer />;
    }
  };

  return (
    <div className={styles.container} ref={ref}>
      {renderScreen()}
    </div>
  );
};
