import { Input } from 'components';
import { Button, Modal } from 'components-antd';
import parentStyles from 'components/ClientsDrawer/ProfileClient/ExpandedSection/Clients/components/AssignClientsModal/styles.module.scss';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestGetClientsListEffect } from 'store/effects/clientsList';
import { getRetainedClientsList } from 'store/selectors/clientsList';
import { AssignClientItem } from '../AssignClientItem/AssignClientItem';
import { CampaignMemberClient } from 'pages/Radar/RadarKits/AssignClientItem/types';
type Props = {
  isOpen: boolean;
  closeDialog: () => void;
  subHeading: string;
  selected: {
    ClientId: number;
    AddressId: number;
  }[];
  setSelected: React.Dispatch<
    React.SetStateAction<
      {
        ClientId: number;
        AddressId: number;
      }[]
    >
  >;
  handleClose: () => void;
};

export const AssignClientsModal: React.FC<Props> = ({
  isOpen,
  closeDialog,
  subHeading,
  selected,
  setSelected,
  handleClose,
}) => {
  const [searchString, setSearchString] = useState<string>('');

  const dispatch = useDispatch();

  const clientsListData = useSelector(getRetainedClientsList);

  useEffect(() => {
    dispatch(requestGetClientsListEffect({}, { silent: clientsListData?.length !== 0 }));
  }, []);

  const onChangeSearchString = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
  };

  const onChangeHandler = (e, ClientId, AddressId) => {
    if (e.target.checked) {
      setSelected((prev) => [...prev, { ClientId, AddressId }]);
    } else {
      setSelected((prev) =>
        prev.filter((item) => !(item.ClientId === ClientId && item.AddressId === AddressId)),
      );
    }
  };

  const flattenedList = useMemo(() => {
    const addressList = clientsListData.flatMap((client) =>
      client.addresses.map((address) => ({
        clientId: client.id,
        clientName: client.name,
        addressId: address.Id,
        address: address.address,
        avatarUrl: client.AvatarUrl,
      })),
    );

    const groupedByClient = addressList.reduce((acc, item) => {
      if (!acc[item.clientId]) {
        acc[item.clientId] = {
          clientId: item.clientId,
          clientName: item.clientName,
          avatarUrl: item.avatarUrl,
          addresses: [],
        };
      }
      acc[item.clientId].addresses.push({
        clientId: item.clientId,
        addressId: item.addressId,
        delivery_line_1: item.address.delivery_line_1,
        last_line: item.address.last_line,
      });
      return acc;
    }, {});

    return Object.values(groupedByClient) as CampaignMemberClient[];
  }, [clientsListData]);

  const clientsList = useMemo(() => {
    const data = flattenedList;
    if (searchString) {
      return data.filter((client: any) => {
        return `${client?.clientName}`.toLowerCase().includes(searchString.toLowerCase());
      });
    }
    return data;
  }, [searchString, flattenedList]);

  const totalAddressesCount = useMemo(
    () => clientsList.reduce((count, client: any) => count + client.addresses.length, 0),
    [clientsList],
  );
  const allAddressesSelected = selected.length === totalAddressesCount && selected.length > 0;

  const handleSelectAllChange = (e) => {
    if (e.target.checked) {
      const allAddressPairs = clientsList.flatMap((client) =>
        client.addresses.map((address) => ({
          ClientId: client.clientId,
          AddressId: address.addressId,
        })),
      );
      setSelected(allAddressPairs);
    } else {
      setSelected([]);
    }
  };

  return (
    <Modal
      open={isOpen}
      width={675}
      footer={null}
      onCancel={closeDialog}
      maskClosable={false}
      destroyOnClose
      className={parentStyles.assignClientModal}
    >
      <div className={parentStyles.header}>
        <h2 className={parentStyles.title}>Assign Clients</h2>
        <p className={parentStyles.subHeading}>{subHeading}</p>
      </div>
      <div className={parentStyles.secondaryHeader}>
        <Input
          className={parentStyles.searchInput}
          placeholder="Search clients..."
          variant={Input.LIGHT_ROUND}
          type="search"
          value={searchString}
          onChange={onChangeSearchString}
        />
      </div>
      <div className={parentStyles.content}>
        <div className={parentStyles.clientsContainer}>
          <AssignClientItem
            key="ALL"
            onChange={handleSelectAllChange}
            all
            allSelected={allAddressesSelected}
          />
          {clientsList?.map((client, idx) => (
            <AssignClientItem
              key={idx}
              client={client}
              selected={selected}
              onChange={onChangeHandler}
            />
          ))}
        </div>
      </div>
      <div className={parentStyles.footer}>
        <Button
          variant="secondary"
          className={parentStyles.doneBtn}
          onClick={(e) => handleClose()}
          disabled={selected?.length === 0}
        >
          Done
        </Button>
      </div>
    </Modal>
  );
};
