import React, { useEffect, useMemo, useState } from 'react';
import { Input } from 'components-antd';
import { RadarHeader } from 'pages/Radar/components/RadarHeader';
import { ChecklistIcon, DeleteIcon, LibraryIcon, PencilSmallIcon } from '../components';
import styles from './styles.module.scss';
import { SequenceAssignedClients } from '../components/SequenceAssignedClients';
import { SequenceCard } from '../components/SequenceCard';
import { Book } from 'components/Icons';
import { AddKitsModal } from '../AddKitsModal';
import { TaskModal } from 'pages/Workshop/Transactions/TransactionTasks/components/TaskModal';
import { useDispatch, useSelector } from 'react-redux';
import { getPublishedKitsSelector } from 'store/selectors/radarKits';
import { requestPostCampaignEffect } from 'store/effects/radarKits';
import { get } from 'lodash-es';

type SequenceDetails = {
  title: string;
  description: string;
};

export const NewSequence: React.FC = () => {
  const dispatch = useDispatch();
  // Start Form Data
  const [sequenceDetails, setSequenceDetails] = useState<SequenceDetails>({
    title: '',
    description: '',
  });
  const [selectedKitsId, setSelectedKitsId] = useState<React.Key[]>([]);
  const [selectedClients, setSelectedClients] = useState<number[]>([]);
  const [isActive, setIsActive] = useState<boolean>(true);
  // End Form Data

  useEffect(() => {
    if (sequenceDetails.title.length >= 4) {
      dispatch(
        requestPostCampaignEffect({
          Name: get(sequenceDetails, 'title', ''),
          Description: get(sequenceDetails, 'description', ''),
          Active: isActive,
        }),
      );
    }
  }, [sequenceDetails, isActive]);

  const [isAddKitsModalOpen, setIsAddKitsModalOpen] = useState<boolean>(false);
  const [isAddTasksModalOpen, setIsAddTasksModalOpen] = useState<boolean>(false);

  const { data: publishedKits } = useSelector(getPublishedKitsSelector);

  const selectedKits = useMemo(() => {
    if (!publishedKits) return [];
    return publishedKits
      .filter((kit) => selectedKitsId.includes(kit.KitId.toString()))
      .map((kit) => ({
        title: kit.Kits.Name,
        dispatchDate: kit.CreatedDate,
        frequency: kit.CurrentFrequency.FrequencyName,
        id: kit.KitId.toString(),
      }));
  }, [selectedKitsId, publishedKits]);

  const handleOpenAddKitsModal = () => setIsAddKitsModalOpen(true);
  const handleCloseAddKitsModal = () => setIsAddKitsModalOpen(false);

  const handleOpenAddTasksModal = () => setIsAddTasksModalOpen(true);
  const handleCloseAddTasksModal = () => setIsAddTasksModalOpen(false);
  return (
    <>
      <div className={styles.pageWrapper}>
        <RadarHeader>
          <div className={styles.titleInputContainer}>
            <div className={styles.titleInput}>
              <Input
                className={styles.input}
                placeholder="New Plan"
                value={sequenceDetails.title}
                onChange={(e) => setSequenceDetails({ ...sequenceDetails, title: e.target.value })}
              />
            </div>
            <div className={styles.descriptionInput}>
              <Input
                className={styles.input}
                placeholder="Add description"
                value={sequenceDetails.description}
                onChange={(e) =>
                  setSequenceDetails({ ...sequenceDetails, description: e.target.value })
                }
              />
            </div>
          </div>
        </RadarHeader>
        <div className={styles.pageContent}>
          <SequenceAssignedClients
            selected={selectedClients}
            setSelected={setSelectedClients}
            isActive={isActive}
            setIsActive={setIsActive}
          />
          <div className={styles.cardsWrapper}>
            <SequenceCard
              title="KITs"
              handleAdd={handleOpenAddKitsModal}
              items={selectedKits}
              placeholder="No KITs added."
              icon={<LibraryIcon />}
              dropdownOptions={[
                {
                  label: 'Preview',
                  onClick: () => {},
                  icon: <Book color="#FF576D" className={styles.center} />,
                },
                {
                  label: 'Delete',
                  onClick: (id: string) =>
                    setSelectedKitsId((prev) => prev.filter((item) => item !== id)),
                  icon: <DeleteIcon />,
                },
              ]}
            />
            <SequenceCard
              title="Tasks"
              handleAdd={handleOpenAddTasksModal}
              placeholder="No tasks added."
              icon={<ChecklistIcon />}
              dropdownOptions={[
                {
                  label: 'Edit',
                  onClick: () => {},
                  icon: <PencilSmallIcon />,
                },
                {
                  label: 'Delete',
                  onClick: () => {},
                  icon: <DeleteIcon />,
                },
              ]}
            />
          </div>
        </div>
      </div>
      {isAddKitsModalOpen && (
        <AddKitsModal
          isOpen={isAddKitsModalOpen}
          closeModal={handleCloseAddKitsModal}
          title="Add KITs"
          selectedKitsId={selectedKitsId}
          setSelectedKitsId={setSelectedKitsId}
        />
      )}
      {isAddTasksModalOpen && (
        <TaskModal
          isOpen={isAddTasksModalOpen}
          onCloseModal={() => handleCloseAddTasksModal()}
          isNew={true}
        />
      )}
    </>
  );
};
