import { useSelector } from 'react-redux';
import { getExpandChart } from 'store/selectors/clarity';
import { Fees } from './Fees';
import { OtherSplits } from './OtherSplits';

export const ExpandedChartsView = () => {
  const expandChart = useSelector(getExpandChart);

  const getContent = () => {
    switch (expandChart) {
      case 'Fees':
        return <Fees />;
      case 'Other Splits':
        return <OtherSplits aggregationType={'Gross'} />;
      case 'Other Net Income':
        return <OtherSplits aggregationType={'Net'} />;
      default:
        return <></>;
    }
  };
  return getContent();
};
