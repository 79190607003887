import { Button } from 'components-antd';
import styles from './styles.module.scss';
import { Icons } from 'pages/ExternalOffer/OfferSubmission/components/Icons';
import { UploadDocuments } from 'components/Transactions';
import classNames from 'classnames';

export const UploadDocumentField = (uploadProps) => {
  const {
    docKey,
    category,
    index,
    custom,
    onSave,
    uploadText,
    disablePending = false,
    uploadDocumentClass,
    showText = true,
  } = uploadProps;

  const uploadButton = custom ? (
    <Button
      className={styles.customDocumentUploadButton}
      testid="upload_new"
      icon={<Icons variant={'cloudUpload'} className={styles.icon} />}
    >
      {showText ? uploadText || 'Upload More Files' : ''}
    </Button>
  ) : (
    <Button
      className={styles.iconButton}
      testid="upload_new"
      icon={<Icons variant={'cloudUpload'} className={styles.icon} />}
    >
      {showText ? uploadText || 'Upload' : ''}
    </Button>
  );

  return (
    <UploadDocuments
      key={docKey}
      docCategoryName={category}
      index={index}
      onSave={onSave}
      withCategory={false}
      addDragInModal={true}
      multiple={false}
      isOfferDocument
      fileCode={index.toString()}
      uploadButton={uploadButton}
      className={classNames(styles.uploadDocuments, uploadDocumentClass)}
      fileNameRequired={true}
      fillDocumentName={true}
      pendingNeeded={!disablePending}
    />
  );
};
