import styles from './style.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { InProgressFormsModal } from './components';
import { getFormMetaEffect } from 'store/effects/formProcess';

import { getTransactionSelector } from 'store/selectors/transaction';
import { Spinner } from 'components';
import classNames from 'classnames';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { useFetchForms } from './components/InPorgressFormsModal/InProgressForms/hooks/useFetchForms';

export const InProgressFormsPill = () => {
  const [openModal, setOpenModal] = useState(false);

  const { transaction } = useSelector(getTransactionSelector);

  const { isAgent } = useSelector(getUserRolesMapSelector);

  const dispatch = useDispatch();
  const { formsDataSource, fetching, getForms } = useFetchForms({});

  useEffect(() => {
    if (transaction?.Id) {
      isAgent && dispatch(getFormMetaEffect({ isProject: transaction?.IsProject }));
    }
  }, [transaction]);

  const toggleModal = () => setOpenModal((prev) => !prev);
  const onClose = () => {
    toggleModal();
    getForms();
  };

  const hasForms = formsDataSource.length > 0;

  const hideFormPill = !hasForms && !openModal && !fetching;

  const inProgressCount = useMemo(() => {
    return formsDataSource.length;
  }, [openModal]);

  return (
    <Fragment>
      <InProgressFormsModal open={openModal} onClose={onClose} />

      {!hideFormPill ? (
        <div className={classNames(styles.inProgressFormPill)} onClick={() => toggleModal()}>
          {fetching ? (
            <Spinner loaderClassName={styles.formsCountSpinner} />
          ) : (
            <span className={styles.text}>
              In Progress{' '}
              <span className={styles.formsCount}>
                ({!openModal ? formsDataSource.length : inProgressCount})
              </span>
            </span>
          )}
        </div>
      ) : (
        <></>
      )}
    </Fragment>
  );
};
