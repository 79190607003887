import PropTypes from 'prop-types';
import fileDownload from 'js-file-download';
import { useDispatch } from 'react-redux';

import { previewDocumentEffect } from 'store/effects/transactions';

import styles from './styles.module.scss';
import JSZip from 'jszip';
import { useState } from 'react';
import Spinner from 'components/Spinner';
import { getFileNameWithExtension } from './helper';

const Download = ({
  document,
  dynamicDocuments = [],
  anonymousUserDetails = {},
  zipFileName = '',
}) => {
  const dispatch = useDispatch();
  const [fileDownloading, setFileDownloading] = useState(false);

  const onDownload = () => {
    if (document.DocumentVaultUUID) {
      dispatch(
        previewDocumentEffect(
          {
            DocumentVaultUUID: document.DocumentVaultUUID,
            ...anonymousUserDetails,
            isDownloading: true,
          },
          {},
          (err, resp) => {
            if (!err) fileDownload(resp.data, getFileNameWithExtension(resp.headers['file-name']));
          },
        ),
      );
    } else if (document?.DocumentBuffer?.data && document?.Filename) {
      fileDownload(
        document.DocumentBuffer.data,
        getFileNameWithExtension(document.Filename),
        document.DocumentBuffer?.headers['content-type'],
      );
    }
  };

  const downloadAllAsZip = async () => {
    setFileDownloading(true);
    const zip = new JSZip();
    const files = {};

    const promiseResults = await Promise.all(
      dynamicDocuments?.map(async (doc) => {
        const result = await dispatch(
          previewDocumentEffect(
            { DocumentVaultUUID: doc.DocumentLink || doc.link, ...anonymousUserDetails },
            {},
          ),
        );
        return result;
      }),
    );

    promiseResults.forEach((result) => {
      let name = result.headers['file-name'].split('.pdf')[0];
      const isExist = files[name];

      let documentName = files[name] ? `${name} (${files[name].length})` : name;

      if (isExist) {
        files[name].push(name);
      } else {
        files[name] = [name];
      }

      zip.file(`${documentName}.pdf`, result.data, { base64: true });
    });

    const zipBlob = await zip.generateAsync({ type: 'blob' });
    fileDownload(zipBlob, `${zipFileName || 'zipFile'}.zip`);
    setFileDownloading(false);
  };

  if (fileDownloading) return <Spinner loaderClassName={styles.loadingSpinner} />;

  return (
    <button
      testid="download"
      onClick={dynamicDocuments.length > 1 ? downloadAllAsZip : onDownload}
      className={styles.download}
    >
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 6.66797V25.3346"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24 17.332L16 25.332"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 17.332L16 25.332"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
};

Download.propTypes = {
  document: PropTypes.object,
};

export default Download;
