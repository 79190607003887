import { createSelector } from 'reselect';
import { get, forEach, flow, map, filter } from 'lodash-es';

import { PENDING, IDLE } from 'settings/constants/apiState';
import { THIRD_PARTY } from 'settings/constants/roles';

import { convertNameToAvatarPlaceholder, formatPhoneNumber } from 'helpers/formatters';

import { getThreadsSelector } from '../sockets/threads';
import { getUserId } from '../user';
import { getCurrentMessagesThreadId } from '../sockets/messages';

const localState = ({ drawers }) => get(drawers, 'addParticipants');

export const getIsParticipantsDrawerOpenSelector = createSelector(localState, ({ open }) => !!open);

export const getParticipantsDrawerInfoSelector = createSelector(localState, ({ info }) => info);

export const getParticipantsDrawerChatTypeSelector = createSelector(localState, ({ type }) => type);

export const getCurrentParticipants = createSelector(
  getCurrentMessagesThreadId,
  getThreadsSelector,
  getUserId,
  (currentThreadId, threads, userId) => {
    const currentThread = threads[currentThreadId];
    const participants = currentThread?.Participants || [];

    const participantsWithoutUser = participants.filter((partic) => partic?.Id !== userId);

    let recipientRole = '';
    if (participantsWithoutUser.length === 1) {
      recipientRole = participantsWithoutUser[0]?.Roles[0];
    }

    const participantsAvatarUrls = [];
    const names = [];
    forEach(participantsWithoutUser, ({ AvatarUrl, FirstName, LastName } = {}) => {
      const name = `${FirstName} ${LastName}`;
      names.push(name);
      participantsAvatarUrls.push({
        url: AvatarUrl,
        placeholder: convertNameToAvatarPlaceholder(name),
      });
    });

    return {
      participants,
      participantsAvatarUrls,
      names,
      isData: !!(names?.length || participants?.length),
      recipientRole,
      isGroupChat: participantsWithoutUser.length > 1,
    };
  },
);

export const getLoadingParticipantsList = createSelector(localState, ({ state, data }) => ({
  state,
  isIdle: state === IDLE,
  isPending: state === PENDING,
  isData: !!data,
}));

export const getParticipantsFormattedList = createSelector(localState, ({ data }) =>
  flow(
    (list) => filter(list, (item) => item?.Id),
    (list) =>
      map(list || [], (item = {}) => {
        const role = get(item, 'Roles.0');

        return {
          id: item.Id,
          name:
            role === THIRD_PARTY
              ? `${item.FirstName} ${item.LastName} (${item.ThirdParty?.BusinessName || ''})`
              : `${item.FirstName} ${item.LastName}`,
          firstName: item.FirstName,
          lastName: item.LastName,
          role,
          avatarUrl: item.AvatarUrl,
          Phones:
            item?.Phones?.map((phone) => ({
              ...phone,
              PhoneNumber: formatPhoneNumber(phone.PhoneNumber),
            })) ?? [],
        };
      }),
  )(data),
);

export const getInviteTransactionParticipantsFormattedList = createSelector(
  localState,
  ({ data }) =>
    flow(
      (list) => filter(list, (item) => item?.Id),
      (list) =>
        map(list || [], (item = {}) => {
          const role = get(item, 'Roles.0');

          return {
            id: item?.Id,
            name: `${item?.FirstName} ${item?.LastName}$/$${item?.Email}`, // don't remove $/$ separator
            firstName: item?.FirstName,
            lastName: item?.LastName,
            role,
            avatarUrl: item?.AvatarUrl,
            email: item?.Email,
            Phones:
              item?.Phones?.map((phone) => ({
                ...phone,
                PhoneNumber: formatPhoneNumber(phone.PhoneNumber),
              })) ?? [],
          };
        }),
    )(data),
);
