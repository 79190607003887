import React from 'react';

export const UsersIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M8.33333 5.33317C8.33333 6.80593 7.13943 7.99984 5.66667 7.99984C4.19391 7.99984 3 6.80593 3 5.33317C3 3.86041 4.19391 2.6665 5.66667 2.6665C7.13943 2.6665 8.33333 3.86041 8.33333 5.33317Z"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M10.3335 7.99984C11.8063 7.99984 13.0002 6.80593 13.0002 5.33317C13.0002 3.86041 11.8063 2.6665 10.3335 2.6665"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M1.6665 12.9998C1.9158 11.7534 3.06194 10.6665 4.33309 10.6665H6.99984C8.27099 10.6665 9.41721 11.7534 9.6665 12.9998"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6665 10.6665C12.9377 10.6665 14.0839 11.7534 14.3332 12.9998"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
