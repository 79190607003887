import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useOutsideClick } from 'hooks';
import Icon from 'pages/Workshop/Transactions/TransactionOverview/Icons';
import { useSelector, useDispatch } from 'react-redux';
import { getTransactionSelector, getTransactionAccessSelector } from 'store/selectors/transaction';
import {
  transactionStatuses,
  listedStatusesArray,
  getStatusValue,
} from 'settings/constants/transaction';
import { ConfirmModal } from 'components';
import { updateTransactionStatusEffect } from 'store/effects/transactions';
import { useState, useRef, useMemo } from 'react';
import List from './List';
import { link } from 'settings/navigation/link';
import styles from './styles.module.scss';
import transactionViewStyles from './../TransactionView/styles.module.scss';
import useIsProjectRoute from 'hooks/use-is-project-route';
import { WorkshopType } from 'app-constants';

const Status = (props) => {
  const { className, editId } = props;
  const { transaction } = useSelector(getTransactionSelector);
  const { fullAccess } = useSelector(getTransactionAccessSelector);
  const [openList, setOpenList] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [newStatusValue, setNewStatusValue] = useState(null);
  const [requestPending, setRequestPending] = useState(false);
  const isProject = useIsProjectRoute();
  const history = useHistory();
  const buttonRef = useRef();
  const modalRef = useRef();
  const listRef = useRef();
  const dispatch = useDispatch();
  const skipListed = !listedStatusesArray.includes(transaction?.Status);

  useOutsideClick([buttonRef, modalRef, listRef], () => setOpenList(false));

  const type = useMemo(() => {
    if (isProject) return WorkshopType.Project;
    return WorkshopType.Transaction;
  }, [isProject]);

  const isAllowedToChangeStatus = useMemo(() => {
    const { Status } = transaction || {};

    return (
      fullAccess && Status !== transactionStatuses.Closed && Status !== transactionStatuses.Canceled
    );
  }, [fullAccess, transaction]);

  const onClick = (event) => {
    event.stopPropagation();

    if (isAllowedToChangeStatus && editId === null) {
      setOpenList(!openList);
    }
  };

  const onCloseModal = () => {
    setOpenModal(false);
    setOpenList(false);
    setTimeout(() => setNewStatusValue(null), 300);
  };

  const onChange = (newStatus) => {
    setNewStatusValue(newStatus);
    setOpenModal(true);
    setOpenList(false);
  };

  const onChangeConfirm = (event) => {
    event.stopPropagation();
    if (newStatusValue?.value === transactionStatuses.Closed) {
      isProject
        ? history.push(link.toProjectClose(String(transaction.Id)))
        : history.push(link.toTransactionClose(String(transaction.Id)));
    } else if (newStatusValue?.value === transactionStatuses.Canceled) {
      isProject
        ? history.push(link.toProjectCancellation(String(transaction.Id)))
        : history.push(link.toTransactionCancellation(String(transaction.Id)));
    } else if (
      newStatusValue?.value === transactionStatuses.UnderContract &&
      ![transactionStatuses.ClearToClose, transactionStatuses.Closed].includes(transaction.Status)
    ) {
      history.push(link.toTransactionUnderContract(String(transaction.Id)));
    } else {
      setRequestPending(true);
      dispatch(
        updateTransactionStatusEffect(
          { id: transaction?.Id, status: newStatusValue?.value },
          {},
          (err) => {
            if (!err) {
              setOpenModal(false);
            }
            setRequestPending(false);
          },
        ),
      );
    }
  };

  const getModalTitle = () => (
    <div testid="modal_title">
      <div>Are you sure you want to change the status of this {type} to:</div>
      <div>{`${newStatusValue?.name}?`}</div>
    </div>
  );

  const getSubmitVariant = () => {
    const archivedStatuses = [transactionStatuses.Canceled, transactionStatuses.Closed];

    if (archivedStatuses.includes(newStatusValue?.value)) {
      return undefined;
    }
    return 'primary';
  };

  return (
    <div
      className={classNames(styles.statusContainer, {
        [transactionViewStyles.disableField]: editId !== null,
      })}
    >
      <div
        className={classNames(
          styles.status,
          { [styles.fullAccess]: isAllowedToChangeStatus },
          className,
        )}
      >
        <div onClick={onClick} ref={buttonRef} className={classNames(styles.inner)}>
          <span testid="status" className={styles.accent}>
            {getStatusValue(transaction?.Status)}
          </span>
          {isAllowedToChangeStatus && (
            <Icon className={styles.arrowIcon} variant={Icon.ARROW_BOTTOM} />
          )}
        </div>
        <List
          ref={listRef}
          open={openList}
          onChange={onChange}
          representingRoles={transaction?.RepresentingRoles}
          skipListed={skipListed}
          isProject={isProject}
          selectedValue={transaction.Status}
        />
        <ConfirmModal
          ref={modalRef}
          title={getModalTitle()}
          isOpen={openModal}
          onClose={onCloseModal}
          onSubmit={onChangeConfirm}
          submitVariant={getSubmitVariant()}
        />
      </div>
      <List
        ref={listRef}
        open={openList}
        onChange={onChange}
        representingRoles={transaction?.RepresentingRoles}
        skipListed={skipListed}
        isProject={isProject}
        selectedValue={transaction.Status}
      />
      <ConfirmModal
        isPending={requestPending}
        ref={modalRef}
        title={getModalTitle()}
        isOpen={openModal}
        onClose={onCloseModal}
        onSubmit={onChangeConfirm}
        submitVariant={getSubmitVariant()}
      />
    </div>
  );
};

Status.propTypes = {
  className: PropTypes.string,
};

Status.defaultProps = {
  className: '',
};

export default Status;
