import { FC, PropsWithChildren, ReactElement, ReactNode, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styles from './styles.module.scss';
import Icons from 'pages/Properties/SearchResults/Icons';
import { goBack } from 'connected-react-router';

interface IntelHeaderProps {
  rightChildren?: ReactNode;
}

export const IntelHeader: FC<PropsWithChildren<IntelHeaderProps>> = (
  props: PropsWithChildren<IntelHeaderProps>,
): ReactElement => {
  const { rightChildren, children } = props;

  const dispatch = useDispatch();

  const handleBack = useCallback(() => dispatch(goBack()), [dispatch]);

  return (
    <div className={styles.header}>
      <div>
        <div className={styles.backWrapper} onClick={handleBack}>
          <Icons className={styles.icon} variant={Icons.BACK} />
        </div>
      </div>
      <div className={styles.title}>{children}</div>

      <div className={styles.filterBtnWrapper}>{rightChildren}</div>
    </div>
  );
};
