import { Wrapper } from 'components';
import { TemplateHeader } from 'pages/Templates/components/TemplateHeader';

import { useHistory, useLocation } from 'react-router-dom';

import styles from './style.module.scss';
import { ClauseForm } from '../ClauseForm';
import { TemplateListItemType } from '../ClausesTemplatesCard';
import { useEffect, useMemo } from 'react';
import { routes } from 'settings/navigation/routes';

type ClauseStateType = {
  clause: TemplateListItemType;
  viewMode: boolean;
  clausesTabKey?: string;
};

export const EditClause = () => {
  const { state: formState } = useLocation<ClauseStateType>();
  const clauseState = useMemo(() => formState, []);
  const history = useHistory();

  useEffect(() => {
    if (!clauseState) {
      history.push(routes.templatesClause);
    }
  }, []);

  if (!clauseState) return <></>;

  return (
    <Wrapper isPending={false} className={styles.clauseWrapper}>
      <TemplateHeader label={'Clauses'} className={styles.clauseHeader} />

      <ClauseForm
        clauseData={clauseState.clause}
        viewMode={clauseState.viewMode}
        clausesTabKey={clauseState.clausesTabKey}
      />
    </Wrapper>
  );
};
