import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import SignatureCanvas from 'react-signature-canvas';
import html2canvas from 'html2canvas';
import { Textfit } from 'react-textfit';
import moment from 'moment';

import {
  Button,
  Input,
  Modal,
  ModalProps,
  Row,
  Select,
  TabPane,
  Tabs,
  Col,
  Option,
} from 'components-antd';
import { DeleteIcon, SignIcon, TypeIcon } from './PDFSignIcons';
import { PDFFields } from 'types';
import { SIGNATURE_MAPPING, SignFormFields } from 'app-constants';
import { Spinner } from 'components';

import styles from './styles.module.scss';
import { hasEmoji } from 'pages/FormProcess/DynamicForm/Containers/DynamicViewer/components/FieldRenderer/Fields/helper';

enum FontTypes {
  Cookie = 'Cookie',
  Just_Me_Again_Down_Here = 'Just Me Again Down Here',
  Kristi = 'Kristi',
  La_Belle_Aurore = 'La Belle Aurore',
  Pinyon_Script = 'Pinyon Script',
}

export const ImageFonts = [] as any[]; // keeping empty for now for future use cases

export enum SignatureResponseType {
  Image = 'image',
  Text = 'text',
}

export interface SignatureResult {
  fullName?: string;
  initials?: string;

  fullNameImage?: string;
  initialsImage?: string;

  signature?: string;
  font: string;
  signModalTab?: string;

  signatureDate?: string;
  type?: SignatureResponseType;
  centerText?: boolean;

  signatureUUID?: string;
}

interface SignModalProps extends ModalProps {
  fields: PDFFields[];
  onResult: (SignatureResult) => void;
  response?: SignatureResult;
  fieldKey?: SignFormFields;
  dynamicForm?: boolean;
}

const DefaultFont = FontTypes.Cookie;
export const DefaultSystemFont = 'Inter';

const FULL_NAME_KEY = 'signature-full-name';
const INITIALS_KEY = 'signature-initials';
const IMAGE_GENERATION_INTERVAL = 250;

export const SignModal = ({
  className,
  fields,
  onResult,
  open,
  response,
  fieldKey = SignFormFields.Signature,
  dynamicForm,
  confirmLoading = false,
  ...props
}: SignModalProps) => {
  const signatureImageData = {
    [FULL_NAME_KEY]: {
      value: 'fullName',
      tag: SIGNATURE_MAPPING.Signature,
      alignItem: 'center',
    },
    [INITIALS_KEY]: {
      value: 'initials',
      tag: SIGNATURE_MAPPING.Initials,
      alignItem: 'left',
    },
  };

  const nameFieldExists = !!fields.find((field) => field.customTag === SIGNATURE_MAPPING.FullName);
  const initialFieldExists = !!fields.find(
    (field) => field.customTag === SIGNATURE_MAPPING.Initials,
  );
  const itemsRef = useRef<any[]>([]);
  const [imageGeneration, setImageGeneration] = useState(false);
  const [activeTab, setActiveTab] = useState('1');

  useEffect(() => {
    clearLocalStorage();
  }, []);

  useEffect(() => {
    if (imageGeneration) {
      const localInterval = setInterval(imageGenerationInterval, IMAGE_GENERATION_INTERVAL);

      return () => {
        if (localInterval) clearInterval(localInterval);
      };
    }
  }, [imageGeneration]);

  const clearLocalStorage = () => {
    localStorage.removeItem(FULL_NAME_KEY);
    localStorage.removeItem(INITIALS_KEY);
  };

  const imageGenerationInterval = () => {
    const fullNameImage = localStorage.getItem(FULL_NAME_KEY);
    const initialsImage = localStorage.getItem(INITIALS_KEY);

    if ((initialFieldExists ? initialsImage : true) && (result.fullName ? fullNameImage : true)) {
      clearLocalStorage();
      handleSubmit({ initialsImage, fullNameImage });
    }
  };

  const fontExists =
    response?.font && Object.values(FontTypes).includes(response.font as FontTypes);

  const [result, setResult] = useState<SignatureResult>({
    ...(response ?? {}),
    font: fontExists ? response.font : DefaultFont,
  });

  const [errors, setErrors] = useState<string[]>([]);

  const handleChange = (key, data?) => {
    if (errors?.length) {
      setErrors([]);
    }
    setResult({
      ...result,
      [key]: data,
    });
  };

  const updateResult = (data) => setResult({ ...result, ...data });

  const handleConfirmSignature = () => {
    const newErrors: string[] = [];

    if (!dynamicForm) {
      if (nameFieldExists && !result.fullName) {
        newErrors.push('Full name is required');
      }
      if (initialFieldExists && !result.initials) {
        newErrors.push('Initials are required');
      }
      if (!result.fullName && !result.signature) {
        newErrors.push('Signature is required');
      }
    }

    if (newErrors.length) {
      setErrors(newErrors);
    } else {
      if (dynamicForm && !ImageFonts.includes(result.font as any)) {
        handleSubmit({} as any);
      } else {
        setImageGeneration(true);
      }
    }
  };

  const handleSubmit = ({ fullNameImage, initialsImage }) => {
    const finalResult = {
      ...result,
      fullNameImage,
      initialsImage,
      ...(dynamicForm && { signModalTab: activeTab === '1' ? 'type' : 'draw' }),
    };
    if (!result.font) {
      finalResult.font = DefaultFont;
    }

    if (!finalResult.signatureDate) {
      finalResult.signatureDate = moment().format('MM/DD/YYYY');
    }

    setImageGeneration(false);

    onResult(finalResult);
  };

  useEffect(() => {
    if (!open) {
      setResult({
        font: DefaultFont,
      });
    }
  }, [open]);
  useEffect(() => {
    if (response) {
      setResult(response);
    }
  }, [response]);

  const renderFitContainer = (key, i) => {
    if (!result[signatureImageData[key].value]) {
      return <></>;
    }
    let width = 80,
      height = 60;

    const field = fields.find((field) => field.customTag === signatureImageData[key].tag);
    if (field?.size) {
      width = (field?.size?.width || 40) * 2;
      height = (field?.size?.height || 30) * 2;
    }

    return (
      <div className={styles.hideFitContainer} key={key}>
        <div
          style={{
            width: width,
            height: height,
            textAlign: signatureImageData[key].alignItem,
          }}
          ref={(e) => (itemsRef.current[i] = e)}
        >
          <Textfit
            style={{
              fontFamily: result.font || DefaultFont,
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'black',
            }}
            mode="single"
            forceSingleModeWidth={false}
            onReady={() => textFitReady(key, i)}
          >
            {result[signatureImageData[key].value]}
          </Textfit>
        </div>
      </div>
    );
  };

  const textFitReady = (key, i) => {
    if (itemsRef.current[i]) {
      html2canvas(itemsRef.current[i], { backgroundColor: null }).then((canvas) => {
        const image = canvas.toDataURL();
        localStorage.setItem(key, image);
      });
    }
  };

  return (
    <Modal
      maskClosable={false}
      className={classNames(className, styles.signModalContainer)}
      title={<span className={styles.headerTitle}>Add your signature</span>}
      footer={null}
      destroyOnClose
      open={open}
      {...props}
    >
      {open ? (
        <>
          {imageGeneration ? (
            <>
              {Object.keys(signatureImageData).map(renderFitContainer)}
              <Spinner />
            </>
          ) : (
            <>
              <Row>
                <Tabs defaultActiveKey={'1'} onChange={setActiveTab} className={styles.tabs}>
                  <TabPane
                    tab={
                      <span className={styles.tabRow}>
                        <TypeIcon /> Type
                      </span>
                    }
                    key="1"
                  >
                    <TypePane
                      fieldKey={fieldKey}
                      defaultName={result.fullName}
                      defaultInitials={result.initials}
                      initialFont={result.font}
                      updateResult={updateResult}
                    />
                  </TabPane>
                  <TabPane
                    tab={
                      <span className={styles.tabRow}>
                        <SignIcon /> Draw
                      </span>
                    }
                    key="2"
                  >
                    <DrawPane defaultSignature={result.signature} onChange={handleChange} />
                  </TabPane>
                </Tabs>
              </Row>

              {errors?.length ? (
                <Row className={styles.errorList}>
                  <ul>
                    {errors.map((error) => (
                      <li className={styles.errorText} key={error}>
                        {error}
                      </li>
                    ))}
                  </ul>
                </Row>
              ) : (
                <></>
              )}
              <Row>
                <p className={styles.legalText}>
                  I understand this is a legal representation of my signature and initials.
                </p>
              </Row>
              <Row>
                <Button
                  className={styles.modalSubmit}
                  variant="secondary"
                  onClick={handleConfirmSignature}
                  loading={confirmLoading}
                  disabled={confirmLoading}
                >
                  Confirm Signature
                </Button>
              </Row>
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </Modal>
  );
};

const TypePane = ({
  defaultName = '',
  defaultInitials = '',
  initialFont = DefaultFont as string,
  updateResult,
  fieldKey,
}) => {
  const [name, setName] = useState(defaultName);
  const [initials, setInitials] = useState(defaultInitials);
  const [font, setFont] = useState<FontTypes>(initialFont as FontTypes);
  const [currentFontSize, setFontSize] = useState(64);
  const [initialFontSize, setInitialFontSize] = useState(64);

  useEffect(() => {
    if (defaultName && defaultInitials && (!name || !initials)) {
      setName(defaultName);
      setInitials(defaultInitials);
    }
  }, [defaultName, defaultInitials]);

  useEffect(() => {
    const input = document.getElementById('nameInput');
    const initialInput = document.getElementById('initialInput');

    if (input) {
      const fontSize = getFontSize(input, false);
      setFontSize(fontSize);
    }

    if (initialInput) {
      const fontSize = getFontSize(initialInput, false, initialFontSize);
      setInitialFontSize(fontSize);
    }
  }, [font, defaultName, defaultInitials]);

  const getFontSize = (elementTarget, isOnInputChange = true, defaultFontSize = 0) => {
    let fontSize = defaultFontSize || currentFontSize;

    const { scrollWidth, clientWidth } = elementTarget;
    const isOverflown = scrollWidth > clientWidth;

    if (isOnInputChange) {
      if (isOverflown) {
        fontSize -= fontSize * 0.2;
      } else if (!elementTarget.value) {
        fontSize = 64;
      }
    } else if (isOverflown) {
      if (font !== initialFont) {
        fontSize -= fontSize * 0.4;
      } else {
        const increment = (scrollWidth / (clientWidth - 30)).toFixed(2);
        fontSize = fontSize / parseFloat(increment);
      }
    }

    return fontSize;
  };

  const handleNameChange = (element) => {
    let name = element.target.value;
    if (!hasEmoji(name)) {
      setName(name);

      const initials = name
        ?.trim()
        .split(' ')
        .map((name) => name.charAt(0))
        .splice(0, 5);

      setInitials(initials.join('').toUpperCase());

      const fontSize = getFontSize(element.target);
      setFontSize(fontSize);
    }
  };

  return (
    <>
      <Row justify="space-between">
        <Col sm={24} md={14}>
          <span className={styles.label}>Full Name</span>

          <Input
            id="nameInput"
            style={{ fontFamily: font, fontSize: currentFontSize }}
            className={styles.input}
            placeholder={'Enter Name'}
            onChange={handleNameChange}
            onBlur={(e) => updateResult({ fullName: e.target.value.trim(), initials })}
            value={name}
            maxLength={50}
            autoFocus={fieldKey === SignFormFields.Name}
            autoComplete="off"
          />
        </Col>
        <Col sm={24} md={8}>
          <span className={styles.label}>Initials</span>
          <Input
            id="initialInput"
            style={{ fontFamily: font, fontSize: initialFontSize }}
            className={styles.input}
            placeholder={'Initials'}
            value={initials}
            onChange={(e) => {
              const { value } = e.target;
              if (!hasEmoji(value)) {
                setInitials(value);
              }
            }}
            onBlur={(e) => updateResult({ initials: e.target.value.trim(), fullName: name })}
            maxLength={5}
            autoFocus={fieldKey === SignFormFields.Initials}
          />
        </Col>
      </Row>
      <Row>
        <span className={styles.label}>Signature Font</span>
        <Select
          value={font}
          className={styles.fontSelect}
          large
          getPopupContainer={(e) => e}
          dropdownRender={(e) => (
            <>
              <span className={styles.dropdownRender}>Choose Signature Font</span>
              {e}
            </>
          )}
          onChange={(e) => {
            setFont(e);
            updateResult({
              font: e,
            });
          }}
          tagRender={(e) => (
            <span style={{ fontFamily: font }} className={styles.nameExample}>
              {name || 'John Doe'}
            </span>
          )}
        >
          {Object.values(FontTypes).map((fontType) => (
            <Option key={fontType} value={fontType}>
              <span className={styles.selectOption}>
                <span style={{ fontFamily: fontType }} className={styles.nameExample}>
                  {name || 'John Doe'}
                </span>
              </span>
            </Option>
          ))}
        </Select>
      </Row>
    </>
  );
};

const DrawPane = ({ onChange, defaultSignature }) => {
  const signCanvas = useRef<any>({});

  useEffect(() => {
    if (signCanvas?.current?.fromDataURL && defaultSignature) {
      signCanvas.current.clear();
      signCanvas.current.fromDataURL(defaultSignature);
    }
  }, [defaultSignature]);

  const handleUserInput = () => {
    if (signCanvas?.current) {
      onChange('signature', signCanvas.current.getCanvas().toDataURL());
    }
  };

  const handleSignatureDelete = () => {
    if (signCanvas?.current?.clear) {
      onChange('signature', undefined);
      signCanvas.current.clear();
    }
  };

  return (
    <Row className={styles.drawPane}>
      <DeleteIcon className={styles.deleteIcon} onClick={handleSignatureDelete} />
      <SignatureCanvas
        ref={signCanvas}
        canvasProps={{
          className: styles.signatureCanvas,
        }}
        onEnd={handleUserInput}
        velocityFilterWeight={1.2}
      />
    </Row>
  );
};
