import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'antd';
import {
  getTransactionRolesEffect,
  setTransactionPreFormQuestionsEffect,
} from 'store/effects/transactions';
import { transactionPreFormQuestionsIds } from 'settings/constants/transaction';
import { getRepresentValueFor } from 'pages/Workshop/Transactions/TransactionCreate/helpers';
import { Question } from 'pages/RequestQuote/components';
import { getTransactionPreFormSelector } from 'store/selectors/transactions';
import { getInvitedClientData } from 'store/selectors/app';
import { transactionRoles } from 'settings/constants/roles';
import { Continue } from '../../Continue';
import { AnswersContainer } from '../../AnswersContainer';
import { ButtonsContainer } from '../../ButtonsContainer';
import { ClientCategory } from 'types';
import { appSetInvitedClientDataAction } from 'store/actions/app';

import styles from './styles.module.scss';
import { requestGetUserParticipantsListEffect } from 'store/effects/drawers/addParticipants';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { getAgentTeamIsActiveSelector } from 'store/selectors/agentTeamDetail';
import { requestGetTeamListEffect } from 'store/effects';
import { getLoadingParticipantsList } from 'store/selectors/drawers/addParticipants';
import { Spinner } from 'components';
import { getTeamListDataArray } from 'store/selectors/teamList';
import { requestGetClientRelatedContactsEffect } from 'store/effects/relatedContacts';
import { requestGetAgentCoordinatorRelatedContactsEffect } from 'store/effects/relatedContacts';
import { getTransactionRolesSelector } from 'store/selectors/transactionRoles';

const WhoAreYouPresenting = (props) => {
  const { className, onNext, currentQuestionId } = props;
  const dispatch = useDispatch();
  const invitedClient = useSelector(getInvitedClientData);
  const { preForm } = useSelector(getTransactionPreFormSelector);
  const isTeamAgentActive = useSelector(getAgentTeamIsActiveSelector);

  const { isPending: loadingRoles } = useSelector(getTransactionRolesSelector);

  const representing = preForm?.whoAreYouRepresenting;

  const { isPending: loadingParticipantsState } = useSelector(getLoadingParticipantsList);
  const onAnswerHandler = (answer, role) => {
    dispatch(
      setTransactionPreFormQuestionsEffect({
        [transactionPreFormQuestionsIds.whoAreYouRepresenting]: {
          [role]: answer,
        },
        [transactionPreFormQuestionsIds.creatorRole]: null,
        [transactionPreFormQuestionsIds.saveDefaultCreatorRole]: false,
        [transactionPreFormQuestionsIds.agents]: [],
      }),
    );
  };

  const { isAgent } = useSelector(getUserRolesMapSelector);

  useEffect(() => {
    if (isAgent) {
      dispatch(
        requestGetClientRelatedContactsEffect({
          includeClients: true,
        }),
      );

      dispatch(requestGetAgentCoordinatorRelatedContactsEffect({ includeAgents: true }));
    }

    dispatch(getTransactionRolesEffect());

    dispatch(requestGetUserParticipantsListEffect());
  }, []);

  useEffect(() => {
    if (isTeamAgentActive) {
      dispatch(requestGetTeamListEffect());
    }
  }, [isTeamAgentActive]);

  const onBothHandler = (answer) => {
    dispatch(
      setTransactionPreFormQuestionsEffect({
        [transactionPreFormQuestionsIds.whoAreYouRepresenting]: {
          Buyer: answer,
          Seller: answer,
        },
        [transactionPreFormQuestionsIds.creatorRole]: null,
        [transactionPreFormQuestionsIds.saveDefaultCreatorRole]: false,
      }),
    );
  };

  const onNextHandler = (event) => {
    if (currentQuestionId === transactionPreFormQuestionsIds.whoAreYouRepresenting) {
      onNext(event);
    }
  };

  useEffect(() => {
    if (invitedClient) {
      onAnswerHandler(true, ClientCategory.Seller);
    }
    return () => {
      dispatch(appSetInvitedClientDataAction(null));
    };
  }, []);

  return (
    <div testid="who_representing" className={classNames(styles.container, className)}>
      <Question>Are you representing the buyer or seller?</Question>
      <AnswersContainer>
        {loadingParticipantsState || loadingRoles ? (
          <>
            <Spinner />
          </>
        ) : (
          <>
            <Button
              type="text"
              className={classNames(styles.answerBtn, {
                [styles.active]: representing?.Buyer && !representing?.Seller,
              })}
              onClick={() => onAnswerHandler(true, transactionRoles.BUYER)}
            >
              Buyer
            </Button>
            <Button
              type="text"
              className={classNames(styles.answerBtn, {
                [styles.active]: !representing?.Buyer && representing?.Seller,
              })}
              onClick={() => onAnswerHandler(true, transactionRoles.SELLER)}
            >
              Seller
            </Button>
            <Button
              type="text"
              className={classNames(styles.answerBtn, {
                [styles.active]: representing?.Buyer && representing?.Seller,
              })}
              onClick={() => onBothHandler(true)}
            >
              Both
            </Button>
          </>
        )}
      </AnswersContainer>
      <ButtonsContainer>
        <Continue
          className={styles.continue}
          onClick={onNextHandler}
          disabled={
            !getRepresentValueFor(preForm, transactionRoles.BUYER) &&
            !getRepresentValueFor(preForm, transactionRoles.SELLER)
          }
        />
      </ButtonsContainer>
    </div>
  );
};

WhoAreYouPresenting.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  currentQuestionId: PropTypes.string,
};

WhoAreYouPresenting.defaultProps = {
  className: '',
  onNext: () => {},
  currentQuestionId: null,
};

export default WhoAreYouPresenting;
