import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Input, Phone, Locations } from 'components';
import { onBoardingSignUpEffect } from 'store/effects/onBoarding';
import { OnboardingSkipButton } from './components/OnboardingSkipButton';
import { PENDING } from 'settings/constants/apiState';

import { getOnBoardingData } from 'pages/OnBoarding/selectors';

import { getUserDataSelector, getAgentTypeSelector } from 'store/selectors/user';

import { ValidationSchemaAgentThirdParty } from './validation';
import { AGENT_TYPE } from 'settings/constants/drawers';
import { OnboardingContinueButton } from './components/OnboardingContinueButton';
import { Question } from 'pages/RequestQuote/components';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';
import { OnBoardingWrapper } from '../../OnBoardingWrapper';
import { OnBoardingContainer } from '../../OnBoardingContainer';

import styles from './styles.module.scss';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { TEAM_OWNER } from 'settings/constants/roles';

import { createAddress } from 'helpers/utilsTS';

const ContactInfoForm = ({ onNext, stageIndex, Controls }) => {
  const dispatch = useDispatch();
  const user = useSelector(getUserDataSelector);
  const agentType = useSelector(getAgentTypeSelector);
  const agentRole = useSelector(getAgentTeamRoleSelector);

  const { onBoarding } = useSelector(getOnBoardingData);

  const onSubmit = (values) => {
    dispatch(
      onBoardingSignUpEffect({ ...values, stageIndex }, { isCommonInfo: true }, (err) => {
        if (!err) {
          onNext();
        }
      }),
    );
  };

  const formik = useFormik({
    initialValues: {
      ...ContactInfoForm.initialValues,
      firstName: user?.FirstName || ContactInfoForm.initialValues.firstName,
      lastName: user?.LastName || ContactInfoForm.initialValues.lastName,
      phone: user?.Phones?.[0]?.PhoneNumber || ContactInfoForm.initialValues.phone,
      email: user?.Email || ContactInfoForm.initialValues.email,
      address: user?.Agent?.Address?.Line1
        ? {
            line1: user.Agent.Address.Line1,
            city: user.Agent.Address.City,
            state: user.Agent.Address.State,
            zip: user.Agent.Address.Zip,
            ProviderPlaceId: user.Agent.Address.Line1,
            PlaceName: user.Agent.Address.Line1,
          }
        : ContactInfoForm.initialValues?.address,
      suiteUnit: user?.Agent?.Address?.Line2 || ContactInfoForm.initialValues?.suiteUnit,
    },
    validationSchema: ValidationSchemaAgentThirdParty(),
    validateOnChange: true,
    onSubmit,
  });

  const onAddressChange = (result) => {
    const location = result?.result?.[0];
    const { data, success } = createAddress(location, result);

    if (success && data) {
      const { city, state, zip, line1, providerPlaceId, placeName } = data;

      formik.setFieldValue('address', {
        city,
        state,
        zip,
        line1,
        ProviderPlaceId: providerPlaceId,
        PlaceName: placeName,
      });
    } else {
      formik.setFieldValue('address', null);
    }
  };

  const isPending = onBoarding.state === PENDING;

  return (
    <OnBoardingWrapper className={styles.wrap}>
      <Controls
        onNext={() => formik.submitForm()}
        className={styles.controls}
        variant="lightFull"
      />

      <OnBoardingContainer>
        <>
          {agentType === AGENT_TYPE.Team && agentRole !== TEAM_OWNER ? (
            <header className={styles.teamheader}>
              <Question className={styles.title}>
                Does this contact information look right?
              </Question>
              <p className={styles.subTitle}>
                This will be visible to your clients, team members, and collaborators.
              </p>
            </header>
          ) : (
            <Question className={classNames(styles.title, styles.formTitle)}>
              What contact information should we <br /> display on your profile?
            </Question>
          )}
        </>
        <AnswersContainer>
          <form onSubmit={formik.handleSubmit}>
            <div className={classNames(styles.formInner, styles.formContent)}>
              {/*
              <div className={styles.inlineFields}>
                <Input
                  className={styles.formInput}
                  type="text"
                  name="firstName"
                  value={formik.values.firstName}
                  placeholder="First Name*"
                  onChange={formik.handleChange}
                  error={formik.touched.firstName ? formik.errors.firstName : ''}
                  testid="name"
                  variant={Input.LIGHT}
                />
                <Input
                  className={styles.formInput}
                  type="text"
                  name="lastName"
                  value={formik.values.lastName}
                  placeholder="Last Name*"
                  onChange={formik.handleChange}
                  error={formik.touched.lastName ? formik.errors.lastName : ''}
                  testid="name"
                  variant={Input.LIGHT}
                />
              </div>
              */}

              <Input
                className={styles.formInput}
                type="text"
                name="email"
                value={formik.values.email}
                placeholder="Email Address*"
                error={formik.touched.email ? formik.errors.email : ''}
                disabled={true}
                testid="email"
                variant={Input.LIGHT}
              />
              <Phone
                className={styles.formInput}
                name="phone"
                onChange={(event, value) => {
                  formik.setFieldValue('phone', value);
                }}
                error={formik.errors.phone}
                disabled={isPending}
                variant={Phone.LIGHT}
                value={formik.values.phone}
                testid="phone"
                placeholder="Phone Number"
              />
              {(agentType !== AGENT_TYPE.Team || agentRole === TEAM_OWNER) && (
                <>
                  <Locations
                    searchIcon={false}
                    multiple={false}
                    placeholder="Office Address"
                    types={['address']}
                    variant={Locations.LIGHT}
                    onResult={onAddressChange}
                    value={formik.values.address ? [formik.values.address] : []}
                    error={formik.touched.address ? formik.errors.address : ''}
                    testid="office_address"
                    className={classNames(styles.formInput, styles.locations)}
                    valuesWrapperClassName={styles.valuesWrapper}
                    searchWrapperClassName={styles.locationsSearchWrapper}
                    placeholderClassName={styles.locationsPlaceholder}
                    activeInputClassName={styles.locationInput}
                  />
                  <Input
                    name="suiteUnit"
                    value={formik.values.suiteUnit}
                    onChange={formik.handleChange}
                    placeholder="Suite / Unit"
                    error={formik.touched.suiteUnit ? formik.errors.suiteUnit : ''}
                    disabled={isPending}
                    testid="suite_unit"
                    variant={Input.LIGHT}
                    className={styles.formInput}
                  />
                </>
              )}
            </div>
            <ButtonsContainer>
              <OnboardingContinueButton
                isPending={isPending}
                disabled={!formik.isValid}
                htmlType="submit"
                className={styles.submit}
              />
              <OnboardingSkipButton
                testid="contact_info_skip"
                onClick={() => onNext()}
                title="Skip"
                className={styles.skip}
              />
            </ButtonsContainer>
          </form>
        </AnswersContainer>
      </OnBoardingContainer>
    </OnBoardingWrapper>
  );
};

ContactInfoForm.initialValues = {
  phone: '',
  email: '',
  address: null,
  suiteUnit: '',
};

ContactInfoForm.propTypes = {
  onNext: PropTypes.func,
  stageIndex: PropTypes.number,
  Controls: PropTypes.elementType,
};

ContactInfoForm.defaultProps = {
  onNext: () => {},
  stageIndex: undefined,
  Controls: () => null,
};

export default ContactInfoForm;
