import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { FC, useEffect, useState } from 'react';
import { CollapsibleTable, WorkshopTableWrapper } from 'pages/Workshop/common';
import { inferRoleCategory, tableColumns } from './tableColumns';
import { Role, TEAM_ADMIN, TEAM_OWNER } from 'settings/constants/roles';
import { link } from 'settings/navigation/link';
import { openNewTab } from 'services/newTab';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash-es';
import { getTransactionEndDate, getTransactionPrice } from 'utils';
import {
  getAggregatePageTransactionsSelector,
  getSearchTransactions,
} from 'store/selectors/transactions';
import { getDateOnly } from 'helpers';
import { Button, DropdownCheck, Wrapper } from 'components';
import { requestGetTransactionsEffect } from 'store/effects';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { Archive } from 'components/Icons';
import classNames from 'classnames';
import { TRANSACTION_STATUSES } from 'tests/ui/constants/ui_const.mjs';

interface ITransactions {
  userId: string;
}

enum filterType {
  All = 'All',
  Listing = 'Listing',
  Purchases = 'Purchase',
}

export const Transactions: FC<ITransactions> = ({ userId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [filter, setFilter] = useState<filterType>(filterType.All);
  const [showArchived, setShowArchived] = useState(false);
  const { transactions, isPending } = useSelector(getAggregatePageTransactionsSelector);
  const agentRole = useSelector(getAgentTeamRoleSelector);

  useEffect(() => {
    dispatch(
      requestGetTransactionsEffect(
        {
          'participantIds[]': userId,
        },
        { silent: true },
      ),
    );
  }, []);

  const transactionsData = getSearchTransactions(
    { data: transactions, search: '', teamTransactions: null },
    Role.Client,
  )
    .map((transaction) => {
      const transactionData = transaction.data;
      const city = get(transactionData, 'Property.Address.City') || '';
      const line1 = get(transactionData, 'Property.Address.Line1') || '';
      const state = get(transactionData, 'Property.Address.State') || '';
      const zip = get(transactionData, 'Property.Address.Zip') || '';
      const address = `${line1 ? `${line1},` : ''} ${
        city ? `${city},` : ''
      } ${state} ${zip}`.trim();

      const status = transactionData?.Status;
      const keyDatesPrices =
        transactionData?.PropertyTransactionKeyDatesPrices || transactionData?.ProjectKeyDates;
      const endDate = getTransactionEndDate(status, keyDatesPrices);
      const price = getTransactionPrice(status, keyDatesPrices);

      // Return enriched transaction
      return {
        ...transactionData,
        status,
        price,
        address: address,
        closingDate: endDate ? getDateOnly(endDate, 'M/D/YYYY') : 'N/A',
        isAlertVisible:
          ([TEAM_OWNER, TEAM_ADMIN].includes(agentRole) && transactionData.IsTeamTransaction) ||
          transactionData.TransactionAccess,
      };
    })
    .filter((transaction) => {
      const isClosedOrCancelled = [
        TRANSACTION_STATUSES.CLOSED,
        TRANSACTION_STATUSES.CANCELED,
      ].includes(transaction.status);
      return (
        (filter !== filterType.All ? filter === inferRoleCategory(transaction) : true) &&
        (showArchived ? isClosedOrCancelled : !isClosedOrCancelled)
      );
    });

  const tableProps = {
    columns: tableColumns(),
    onRow: (record) => {
      return {
        onClick: (e) => {
          const path = link.toTransactionOverview(record.Id);
          if (e.ctrlKey || e.metaKey) {
            openNewTab(path);
          } else {
            history.push(path);
          }
        },
      };
    },
    tableClassName: styles.transactionsTable,
  };

  const options = [
    { id: 0, label: 'All', value: filterType.All },
    { id: 1, label: 'Listing', value: filterType.Listing },
    { id: 2, label: 'Purchases', value: filterType.Purchases },
  ];

  const handleStatusChange = (status) => {
    setFilter(status);
  };

  return (
    <div className={styles.main}>
      <div className={styles.content}>
        <div className={styles.header}>
          <p className={styles.heading}>Transactions</p>
          <div className={styles.row}>
            <DropdownCheck
              className={styles.button}
              dropdownFields={options}
              onChange={handleStatusChange}
              defaultValue={filter}
            />
            <Button
              icon={
                <Archive color={showArchived ? '#fff' : '#252d44'} size={'24'} strokeWidth={'2'} />
              }
              className={classNames(styles.archive, { [styles.active]: showArchived })}
              onClick={() => setShowArchived((prev) => !prev)}
            />
          </div>
        </div>
        <Wrapper className={styles.pageWrapper} isPending={isPending}>
          <WorkshopTableWrapper data={[transactionsData]} emptyText="No Transactions.">
            <CollapsibleTable
              title={''}
              dataSource={transactionsData}
              disableCollapse
              {...tableProps}
            />
          </WorkshopTableWrapper>
        </Wrapper>
      </div>
    </div>
  );
};
