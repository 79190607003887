import {
  setTransactionTaskParamsAction,
  deleteTransactionTaskAction,
  uploadTransactionTaskDocumentsAction,
  updateTransactionTaskAction,
  deleteTransactionTaskDocumentAction,
  setTransactionTasksLastStateAction,
  previewTransactionTaskDocumentAction,
  updateUnReadCommentsForTransactionAction,
  setShowCommentIconForTransactionAction,
  updateTransactionTaskChecklistAction,
  setTransactionsTasksFilterAction,
} from 'store/actions/transactions/tasks/update';
import {
  deleteTransactionTask,
  uploadTransactionTaskDocuments,
  updateTransactionTask,
  deleteTransactionTaskDocument,
  getTransactionTaskDocuments,
  updateTransactionTaskChecklist,
  getNonVoidDocument,
} from 'api/transactions';
import Api from 'store/effects/core/api';
import { getState } from 'store';
import { get } from 'lodash-es';
import { handleData, prepareReviewersIds } from '../helpers';
import { getParticipantsData } from 'utils/workshopHelper';

export const setTransactionTaskParamsEffect = (cfg) => (dispatch) => {
  dispatch(setTransactionTaskParamsAction(cfg));
};

export const updateTransactionTaskEffect = (cfg = {}, options, cb) => {
  const state = getState();
  const transactionId = cfg?.transactionId || get(state, 'transaction.data.Id');
  const requestParams = { action: updateTransactionTaskAction, method: updateTransactionTask };
  const sendRequest = Api.execResult(requestParams);

  const config = handleData(transactionId, cfg);

  return sendRequest(config, options, cb);
};

export const deleteTransactionTaskEffect = (cfg, options, cb) => {
  const transactionData = get(getState(), 'transaction.data');
  const requestParams = { action: deleteTransactionTaskAction, method: deleteTransactionTask };
  const sendRequest = Api.execResult(requestParams);

  const config = {
    transactionId: cfg?.transactionId || transactionData?.Id,
    taskId: cfg?.taskId,
  };

  return sendRequest(config, options, cb);
};

export const deleteTransactionTaskDocumentEffect = (cfg, options, cb) => {
  const transactionData = get(getState(), 'transaction.data');
  const requestParams = {
    action: deleteTransactionTaskDocumentAction,
    method: deleteTransactionTaskDocument,
  };
  const sendRequest = Api.execResult(requestParams);

  const config = {
    transactionId: transactionData?.Id,
    taskId: cfg?.taskId,
    documentId: cfg?.documentId,
  };

  return sendRequest(config, options, cb);
};

export const updateTransactionTaskChecklistEffect = (cfg, options, cb) => {
  const requestParams = {
    action: updateTransactionTaskChecklistAction,
    method: updateTransactionTaskChecklist,
  };
  const sendRequest = Api.execResult(requestParams);

  const config = {
    transactionId: cfg.transactionId,
    taskId: cfg.taskId,
    checklistId: cfg.checklistId,
    checked: cfg.checked,
  };

  return sendRequest(config, options, cb);
};

export const previewDocumentEffect = (cfg, options, cb) => {
  const requestParams = {
    action: previewTransactionTaskDocumentAction,
    method: getTransactionTaskDocuments,
  };
  const sendRequest = Api.execResult(requestParams);

  const { DocumentVaultUUID, token, type, formProcessPublicId, isDownloading } = cfg;

  const config = {
    DocumentVaultUUID,
    ...(token && type
      ? {
          params: {
            token,
            type,
            ...(formProcessPublicId && { formProcessPublicId }),
            ...(isDownloading && { isDownloading }),
          },
        }
      : formProcessPublicId
      ? { params: { formProcessPublicId } }
      : {}),

    responseType: 'arraybuffer',
  };

  return sendRequest(config, options, cb);
};

export const downloadNonVoidDocumentEffect = (cfg, options, cb) => {
  const requestParams = {
    action: previewTransactionTaskDocumentAction,
    method: getNonVoidDocument,
  };
  const sendRequest = Api.execResult(requestParams);

  const { formProcessId, formProcessDocumentId, token, type } = cfg;

  const config = {
    formProcessId,
    formProcessDocumentId,
    ...(token && type ? { params: { token, type } } : {}),
    responseType: 'arraybuffer',
  };

  return sendRequest(config, options, cb);
};

export const uploadTransactionTaskDocumentsEffect = (cfg, options, cb) => {
  const state = getState();
  const transactionData = get(state, 'transaction.data');
  const requestParams = {
    action: uploadTransactionTaskDocumentsAction,
    method: uploadTransactionTaskDocuments,
  };
  const sendRequest = Api.execResult(requestParams);

  const config = {
    transactionId: transactionData?.Id,
    taskId: cfg?.taskId,
    Documents: (cfg?.documents || []).map((document) => {
      const participants = getParticipantsData(document);
      return {
        Title: document?.documentName,
        Category: cfg?.category?.Name,
        File: {
          ContentType: document?.contentType,
          Filename: document?.filename,
          Size: document?.size,
          Data: btoa(document?.data),
        },
        UsersWithAccess: participants.UsersWithAccess,
        UsersEmailWithAccess: participants.UsersEmailWithAccess,
        AccessToEveryone: participants.AccessToEveryone,
      };
    }),
  };

  return sendRequest(config, options, cb);
};

export const setTransactionTasksLastStateEffect = (cfg) => (dispatch) => {
  const transactionData = get(getState(), 'transaction.data');

  const config = {
    transactionId: cfg?.transactionId || transactionData?.Id,
    taskId: cfg?.taskId,
  };

  dispatch(setTransactionTasksLastStateAction(config));
};

export const updateUnReadCommentsForTransactionEffect = (cfg) => (dispatch) => {
  dispatch(updateUnReadCommentsForTransactionAction(cfg));
};

export const setShowCommentIconForTransactionEffect = (cfg) => (dispatch) => {
  dispatch(setShowCommentIconForTransactionAction(cfg));
};

export const setTransactionsTasksFilterEffect = (cfg) => (dispatch) => {
  dispatch(setTransactionsTasksFilterAction(cfg));
};
