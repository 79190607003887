import { useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { Menu, MenuItem, Dropdown, Radio } from 'components-antd';
import { ArrowDown, Check } from 'components/Icons';
import { Icons } from 'pages/Workshop/Icons';
import { setTeamStatsAction } from 'store/actions/clarity';
import { AgentSelectorModal } from '../../Modals/AgentSelectorModal';
import { getAgentTeamStatsSelector } from 'store/selectors/clarity';
import { getUserId } from 'store/selectors/user';

import styles from './styles.module.scss';

export const TeamFilter = () => {
  const dispatch = useDispatch();
  const [openAgentSelectorModal, setOpenAgentSelectorModal] = useState(false);
  const teamStats = useSelector(getAgentTeamStatsSelector);
  const userId = useSelector(getUserId);
  const dropdownFields = [
    { itemValue: 'Just Me', icon: Icons.USER, iconColor: '#04a451', userId: userId },
    { itemValue: 'Team', icon: Icons.TEAM_USER, iconColor: '#04a451' },
    { itemValue: 'Agent', icon: Icons.USER, iconColor: '#04a451', userId: 0 },
  ];
  const [value, setValue] = useState(teamStats ?? dropdownFields[1]);

  const onChange = (e) => {
    const value = e.target.value.itemValue;
    setValue(e.target.value);
    if (value === 'Team') dispatch(setTeamStatsAction({ value: dropdownFields[1] }));
    else if (value === 'Just Me') dispatch(setTeamStatsAction({ value: dropdownFields[0] }));
    else if (value === 'Agent') setOpenAgentSelectorModal(true);
  };

  const onSubmit = (option, selected) => {
    const agent = {
      itemValue: `${selected.FirstName} ${selected.LastName}`,
      icon: Icons.USER,
      iconColor: '#04a451',
      userId: selected.Id,
    };
    dispatch(setTeamStatsAction({ value: agent }));
    setValue(agent);
  };

  const menu = (
    <Radio.Group
      onChange={onChange}
      value={value}
      className={styles.optionsItems}
      defaultValue={value}
    >
      <Menu>
        {dropdownFields.map((item, idx) => {
          return (
            <MenuItem key={idx}>
              <Radio value={{ itemValue: item.itemValue, icon: item.icon }} key={idx}>
                <div className={styles.option}>
                  <Icons variant={item.icon} className={styles.dropdownOptionIcon} />
                  <span className={styles.optionValue}>{item.itemValue}</span>
                </div>
                {value.itemValue === item.itemValue && <Check className={styles.checkIcon} />}
              </Radio>
            </MenuItem>
          );
        })}
      </Menu>
    </Radio.Group>
  );

  return (
    <div className={classNames(styles.filterWrap)}>
      <Dropdown
        overlay={menu}
        placement="bottomRight"
        getPopupContainer={(triggerNode) => triggerNode}
        trigger={['click']}
        overlayClassName={styles.teamFilterDropdown}
      >
        <a
          onClick={(e) => e.preventDefault()}
          className={classNames(styles.filterButton, 'mosaikDropdownButton')}
        >
          <Icons variant={value.icon} className={styles.optionIcon} />
          <span className={styles.optionValue}>{value.itemValue}</span>
          <ArrowDown className={classNames(styles.icon, { [styles.open]: open })} size={16} />
        </a>
      </Dropdown>
      {value.itemValue === 'Agent' && (
        <AgentSelectorModal
          open={openAgentSelectorModal}
          onClose={() => setOpenAgentSelectorModal(false)}
          onSubmit={onSubmit}
        />
      )}
    </div>
  );
};
