import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { Row, Col } from 'antd';
import { Icons } from 'pages/Workshop/Icons';
import { Filter } from '../Filter';
import { OffersFilter } from '../components/OffersFilter';
import {
  setOffersIsArchiveEffect,
  setOffersArchiveIdEffect,
  setOffersFiltersEffect,
} from 'store/effects/offerAggregate';
import {
  getOfferArchiveIdSelector,
  getOfferFiltersSelector,
  getOfferIsArchiveSelector,
} from 'store/selectors/offerAggregate';
import { SearchFieldWithDebounce } from 'components';

import styles from './styles.module.scss';

export const LocalHeader = () => {
  const { isArchive } = useSelector(getOfferIsArchiveSelector);
  const { offersFilter } = useSelector(getOfferFiltersSelector);

  const { offerId } = useSelector(getOfferArchiveIdSelector);
  const dispatch = useDispatch();

  const [isMobile, setIsMobile] = useState(false);

  const onArchiveModeChange = () => {
    dispatch(setOffersIsArchiveEffect(!isArchive));
    dispatch(setOffersFiltersEffect({ allFilters: {}, categoriedFilters: {} }));
  };

  const resetArchiveId = () => dispatch(setOffersArchiveIdEffect(null));

  useEffect(() => {
    function handleWindowSizeChange() {
      if (window.innerWidth <= 992) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
    if (document.readyState === 'complete') {
      handleWindowSizeChange();
    }
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.addEventListener('load', handleWindowSizeChange);
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [isMobile]);

  const renderBackBtn = () => {
    if (!offerId) return;

    return (
      <div
        className={classNames(styles.btn, { [styles.btnText]: !isMobile })}
        onClick={resetArchiveId}
      >
        <Icons className={styles.addIcon} variant={Icons.ARROW_LEFT} />
        {isMobile ? '' : <span className="icon-text">Back</span>}
      </div>
    );
  };

  const renderActions = () => {
    if (offerId) return;

    return <Filter />;
  };

  return (
    <div className={styles.workshopHeader}>
      <div className={styles.actionsLeft}>{renderBackBtn()}</div>
      <Row className={styles.workshopActionsContainer} align="middle" justify="space-between">
        <Col className={styles.workshopHeaderLeft} xs={24} lg={16}>
          <div className={styles.searchWrapper}>
            <SearchFieldWithDebounce
              className={styles.inputWrap}
              classNameInput={styles.searchInput}
              iconClassName={styles.icon}
              resetAction={() => setOffersFiltersEffect({ ...offersFilter, searchString: '' })}
              sendAction={({ search }) =>
                setOffersFiltersEffect({ ...offersFilter, searchString: search })
              }
              isLoading={false}
              placeholder="Search"
              testid="search"
              isTransactionAggregate={true}
              isExpandedVersion
              otherSearchParams={{ offersFilter }}
            />
          </div>
          <div className="seperator" />
          <OffersFilter />
        </Col>
        <Col xs={24} lg={8}>
          <div className={styles.actionsRight}>
            <div className="seperator" />
            {renderActions()}
            <div
              className={classNames(styles.btn, { [styles.selected]: isArchive })}
              onClick={onArchiveModeChange}
            >
              <Icons
                className={styles.btnIcon}
                variant={Icons.ARCHIVE}
                color={isArchive ? '#FFFFFF' : ''}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
