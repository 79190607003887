import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Wrapper as PendingWrapper } from 'components';
import prettyBytes from 'pretty-bytes';
import { FILE_TYPES } from 'settings/constants/common';
import Icon from '../../Icons';

import styles from './styles.module.scss';

const File = (props) => {
  const { className, file, isMessageSavedOnServer, isOwnMessage, onClick } = props;

  const maxFileNameLength = 22;
  const fileName = file?.Filename || '';
  const truncFileName = fileName.slice(0, maxFileNameLength);

  const getText = () => {
    let str = `${prettyBytes(file?.Size || 0)}`;

    const viewableFiles = [FILE_TYPES.PDF];

    return str;
  };

  return (
    <div
      onClick={onClick}
      className={classNames(styles.file, { [styles.isOwnMessage]: isOwnMessage }, className)}
    >
      <div className={styles.fileIconHolder}>
        <PendingWrapper loaderClassName={styles.fileLoader} isPending={!isMessageSavedOnServer}>
          <Icon className={styles.icon} variant={Icon.FILE} />
        </PendingWrapper>
      </div>
      <div>
        <div testid="file_name" className={styles.filename} title={fileName}>
          {fileName.length > maxFileNameLength ? `${truncFileName}...` : truncFileName}
        </div>
        <div testid="file_view" className={styles.size}>
          {getText()}
        </div>
      </div>
    </div>
  );
};

File.propTypes = {
  className: PropTypes.string,
  file: PropTypes.shape({
    Filename: PropTypes.string,
    Url: PropTypes.string,
    Size: PropTypes.number,
    ContentType: PropTypes.string,
    DocumentVaultUUID: PropTypes.number,
  }).isRequired,
  isMessageSavedOnServer: PropTypes.bool.isRequired,
  isOwnMessage: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

File.defaultProps = {
  className: '',
};

export default File;
