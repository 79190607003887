/**
 *
 * @param str
 * @returns {boolean} - return true if string is in float format
 */
export function isFloatStr(str) {
  const floatRegex = /^-?\d+(?:[.]\d*?)?$/;
  if (!floatRegex.test(str)) return false;

  return !Number.isNaN(parseFloat(str));
}

export function isIntStr(str) {
  return !Number.isNaN(str) && Number.isInteger(parseFloat(str));
}

export const addSpaceBeforeCapitalLetters = (str) =>
  str
    ? str
        .replace(/([a-z])([A-Z0-9])/g, '$1 $2')
        .replace(/(\d+)([A-Za-z]+)/g, '$1 $2')
        .replace(/([A-Za-z]+)(\d+)/g, '$1 $2')
        .trim()
        .replace(/, /g, ',')
    : null;
