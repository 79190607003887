import classNames from 'classnames';
import { memo, useMemo } from 'react';

const Transaction = memo(
  ({ className, color = '#51BFE1' }: { className?: string; color?: string }) =>
    useMemo(
      () => (
        <div className={classNames(className)}>
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle opacity="0.12" cx="18" cy="18" r="18" fill={color} />
            <path
              d="M11.1938 13.1401H24.805M24.805 13.1401L21.4022 9.7373M24.805 13.1401L21.4022 16.5429"
              stroke={color}
              strokeWidth="2.1"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M24.8047 22.8608H11.1936M11.1936 22.8608L14.5964 19.458M11.1936 22.8608L14.5964 26.2636"
              stroke={color}
              strokeWidth="2.1"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      ),
      [className],
    ),
);

export default Transaction;
