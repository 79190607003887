import Api from 'store/effects/core/api';
import {
  requestGetClientDetailsAction,
  requestUpdateClientStatusAction,
} from 'store/actions/clientDetail';
import { getClientDetail } from 'api/user';
import { changeClientStatus } from 'api/clientsList';

export const getClientDetailsEffect = (cfg, options, cb) => {
  const requestParams = { action: requestGetClientDetailsAction, method: getClientDetail };
  let sendRequest;
  if (options.silent) {
    sendRequest = Api.execResult(requestParams);
  } else {
    sendRequest = Api.execBase(requestParams);
  }
  return sendRequest(cfg, options, cb);
};

export const updateClientStatusEffect = (
  cfg: { status: boolean; id: number },
  options?: { silent: boolean },
  cb?: any,
) => {
  const requestParams = { action: requestUpdateClientStatusAction, method: changeClientStatus };
  const sendRequest = options?.silent ? Api.execResult(requestParams) : Api.execBase(requestParams);

  return sendRequest(cfg, options, cb);
};
