import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Checkbox, DatePicker, InputNumber } from 'antd';
import { Input } from '../../../../../../../../components';
import { Select } from 'components-antd';
import styles from './styles.module.scss';
import { formatPrice } from 'helpers';
import YesNo from 'pages/ExternalOffer/OfferSubmission/SubmissionForm/StageParts/components/YesNo';
import classNames from 'classnames';
import { ErrorMessage } from 'pages/ExternalOffer/OfferSubmission/SubmissionForm/components/ErrorMessages';

const financingType = [
  {
    label: 'Conventional',
    value: 'Conventional',
  },
  {
    label: 'Nonconforming',
    value: 'NonConforming',
  },
  {
    label: 'FHA',
    value: 'FHA',
  },
  {
    label: 'VA',
    value: 'VA',
  },
  {
    label: 'Cash',
    value: 'Cash',
  },
];

const responsibleParty = [
  {
    label: 'Buyer',
    value: 'Buyer',
  },
  {
    label: 'Seller',
    value: 'Seller',
  },
];

export const CounterOffer = (props) => {
  const {
    iterationData,
    counterOfferFormData,
    setCounterOfferFormData,
    validation,
    contingenciesRef,
  } = props;
  const {
    termiteInspection,
    termiteInspectionResponsibility,
    homeWarranty,
    homeWarrantCost,
    homeWarrantResponsibility,
    occupancy,
    occupancyDays,
    appraisalError,
    financingError,
    inspectionError,
  } = validation;

  useEffect(() => {
    setCounterOfferFormData({
      ...counterOfferFormData,
      ClosingDate: iterationData.ClosingDate,
      DownPayment: iterationData.DownPayment,
      EarnestMoneyDeposit: iterationData.EarnestMoneyDeposit,
      FinancingType: iterationData.FinancingType,
      PurchasePrice: iterationData.PurchasePrice,
      TermiteInspection: iterationData.TermiteInspection,
      TermiteInspectionResponsibility: iterationData.TermiteInspectionResponsibility,
      HomeWarranty: iterationData.HomeWarranty,
      HomeWarrantyCost: iterationData.HomeWarrantyCost,
      HomeWarrantyResponsibility: iterationData.HomeWarrantyResponsibility,
      Occupancy: iterationData.Occupancy,
      OccupancyDays: iterationData.OccupancyDays,
      Appliances: iterationData.Appliances,
      Contingencies: {
        Inspection: {
          days: iterationData?.Contingencies?.Inspection.days,
          selected: iterationData?.Contingencies?.Inspection.selected,
        },
        Appraisal: {
          days: iterationData?.Contingencies?.Appraisal.days,
          selected: iterationData?.Contingencies?.Appraisal.selected,
        },
        Financing: {
          days: iterationData?.Contingencies?.Financing.days,
          selected: iterationData?.Contingencies?.Financing.selected,
        },
      },
    });
  }, []);

  const disabledDate = (current) => {
    let today = moment(new Date()).format('YYYY-MM-DD');
    return current && current < moment(today, 'YYYY-MM-DD');
  };
  const onChangeValue = (val, fieldName) => {
    setCounterOfferFormData({
      ...counterOfferFormData,
      [fieldName]: val,
    });
  };

  const onChange = (data) => {
    setCounterOfferFormData({ ...counterOfferFormData, ...data });
  };

  return (
    <div className={styles.counterOffer}>
      <p className={styles.title}>
        Counter Offer #{iterationData?.OfferCount}-{Number(iterationData.IterationCount) + 1}
      </p>
      <div className={styles.content}>
        <div className={styles.subHeading}>
          Purchase Price
          <span className={styles.inputArea}>
            <Input
              className={styles.input}
              type="number"
              placeholder="$0"
              prefix="$"
              value={
                iterationData?.PurchasePrice ? `$${formatPrice(iterationData?.PurchasePrice)}` : 0
              }
              isNumberFormat
              onChange={(e) => onChangeValue(Number(e.target.value), 'PurchasePrice')}
              name="PurchasePrice"
              id="PurchasePrice"
              testid="PurchasePrice"
            />
          </span>
        </div>
        <div className={styles.subHeading}>
          Down Payment
          <span className={styles.inputArea}>
            <Input
              className={styles.input}
              type="number"
              placeholder="$0"
              prefix="$"
              isNumberFormat
              value={iterationData?.DownPayment ? `$${formatPrice(iterationData?.DownPayment)}` : 0}
              onChange={(e) => onChangeValue(Number(e.target.value), 'DownPayment')}
              name="DownPayment"
              id="DownPayment"
              testid="DownPayment"
            />
          </span>
        </div>
        <div className={styles.subHeading}>
          Earnest Money Deposit
          <span className={styles.inputArea}>
            <Input
              className={styles.input}
              type="number"
              placeholder="$0"
              prefix="$"
              isNumberFormat
              value={
                iterationData?.EarnestMoneyDeposit
                  ? `$${formatPrice(iterationData?.EarnestMoneyDeposit)}`
                  : 0
              }
              onChange={(e) => onChangeValue(Number(e.target.value), 'EarnestMoneyDeposit')}
              name="EarnestMoneyDeposit"
              id="EarnestMoneyDeposit"
              testid="EarnestMoneyDeposit"
            />
          </span>
        </div>
        <div className={styles.subHeading}>
          Closing Date
          <span className={styles.inputArea}>
            <DatePicker
              className={styles.inputHolder}
              defaultValue={iterationData?.ClosingDate && moment(iterationData.ClosingDate)}
              format={'MM/DD/YYYY'}
              placeholder={'MM/DD/YYYY'}
              onChange={(e) => onChangeValue(e, 'ClosingDate')}
              disabledDate={disabledDate}
            />
          </span>
        </div>
        <div className={styles.subHeading}>
          Financing Type
          <Select
            defaultValue={iterationData?.FinancingType}
            className={styles.inputHolder}
            options={financingType}
            placeholder={'Select Financing Type'}
            onChange={(e) => onChangeValue(e, 'FinancingType')}
          />
        </div>

        <div className={styles.inputBlock}>
          <div className={styles.label}>Contingencies</div>
          <div className={styles.contingencies} ref={contingenciesRef}>
            <Checkbox
              className={classNames(styles.checkbox, 'mosaikCheckbox')}
              checked={counterOfferFormData?.Contingencies?.Inspection?.selected}
              onChange={(e) => {
                //setInspectionError(false);
                onChange({
                  ...counterOfferFormData,
                  Contingencies: {
                    ...counterOfferFormData.Contingencies,
                    Inspection: { selected: e.target.checked },
                  },
                });
              }}
            >
              <span className={styles.static}>Inspection</span>

              {!counterOfferFormData?.Contingencies?.Inspection?.selected ? (
                <div className={styles.waived}>WAIVED</div>
              ) : (
                <>
                  <InputNumber
                    className={classNames(styles.daysInputHolder, {
                      [styles.daysinputholdererror]: inspectionError,
                    })}
                    type="number"
                    min={1}
                    placeholder="#"
                    step={1}
                    precision={0}
                    onChange={(e) => {
                      //setInspectionError(false);
                      onChange({
                        ...counterOfferFormData,
                        Contingencies: {
                          ...counterOfferFormData.Contingencies,
                          Inspection: { days: e, selected: true },
                        },
                      });
                    }}
                    value={counterOfferFormData?.Contingencies?.Inspection?.days}
                  />
                  <span className={styles.static}>Days</span>
                </>
              )}
            </Checkbox>
          </div>

          <div className={styles.contingencies}>
            <Checkbox
              className={classNames(styles.checkbox, 'mosaikCheckbox')}
              checked={counterOfferFormData?.Contingencies?.Appraisal?.selected}
              onChange={(e) => {
                //setAppraisalError(false);
                onChange({
                  ...counterOfferFormData,
                  Contingencies: {
                    ...counterOfferFormData.Contingencies,
                    Appraisal: { selected: e.target.checked },
                  },
                });
              }}
            >
              <span className={styles.static}>Appraisal</span>
              {!counterOfferFormData?.Contingencies?.Appraisal?.selected ? (
                <div className={styles.waived}>WAIVED</div>
              ) : (
                <>
                  <InputNumber
                    className={classNames(styles.daysInputHolder, {
                      [styles.daysinputholdererror]: appraisalError,
                    })}
                    type="number"
                    min={1}
                    placeholder="#"
                    step={1}
                    precision={0}
                    onChange={(e) => {
                      onChange({
                        ...counterOfferFormData,
                        Contingencies: {
                          ...counterOfferFormData.Contingencies,
                          Appraisal: { days: e, selected: true },
                        },
                      });
                    }}
                    value={counterOfferFormData?.Contingencies?.Appraisal?.days}
                  />
                  <span className={styles.static}>Days</span>
                </>
              )}
            </Checkbox>
          </div>

          <div className={styles.contingencies}>
            <Checkbox
              className={classNames(styles.checkbox, 'mosaikCheckbox')}
              checked={counterOfferFormData?.Contingencies?.Financing?.selected}
              onChange={(e) => {
                onChange({
                  ...counterOfferFormData,
                  Contingencies: {
                    ...counterOfferFormData.Contingencies,
                    Financing: { selected: e.target.checked },
                  },
                });
              }}
            >
              <span className={styles.static}>Financing</span>

              {!counterOfferFormData?.Contingencies?.Financing?.selected ? (
                <div className={styles.waived}> WAIVED</div>
              ) : (
                <>
                  <InputNumber
                    className={classNames(styles.daysInputHolder, {
                      [styles.daysinputholdererror]: financingError,
                    })}
                    type="number"
                    min={1}
                    placeholder="#"
                    step={1}
                    precision={0}
                    onChange={(e) => {
                      onChange({
                        ...counterOfferFormData,
                        Contingencies: {
                          ...counterOfferFormData.Contingencies,
                          Financing: { days: e, selected: true },
                        },
                      });
                    }}
                    value={counterOfferFormData?.Contingencies?.Financing?.days}
                  />
                  <span className={styles.static}>Days</span>
                </>
              )}
            </Checkbox>
          </div>
        </div>

        <div className={styles.subHeading}>
          Termite Inspection
          <YesNo
            questionHandler={(e) => onChangeValue(e, 'TermiteInspection')}
            objectKey={'TermiteInspection'}
            yes={counterOfferFormData?.TermiteInspection}
            className={styles.yesNoContainer}
            yesClass={styles.yesContainer}
          />
          {counterOfferFormData?.TermiteInspection ? (
            <div className={styles.questionInput}>
              <div className={styles.questionInputLabel}>Responsible Party?</div>
              <Select
                className={styles.inputHolder}
                options={responsibleParty}
                value={
                  counterOfferFormData?.TermiteInspectionResponsibility
                    ? counterOfferFormData?.TermiteInspectionResponsibility
                    : ''
                }
                onSelect={(e) => {
                  onChangeValue(e, 'TermiteInspectionResponsibility');
                }}
                placeholder={'Select Party'}
              />
            </div>
          ) : (
            <></>
          )}
          {termiteInspection ? (
            <ErrorMessage id="termite_inspection" message={'*Select an option'} />
          ) : (
            <></>
          )}
          {termiteInspectionResponsibility ? (
            <ErrorMessage id="termite_inspection" message={'*Select party'} />
          ) : (
            <></>
          )}
        </div>
        <div className={styles.subHeading}>
          Home Warranty
          <YesNo
            questionHandler={(e) => onChangeValue(e, 'HomeWarranty')}
            objectKey={'HomeWarranty'}
            yes={counterOfferFormData?.HomeWarranty}
            className={styles.yesNoContainer}
            yesClass={styles.yesContainer}
          />
          {counterOfferFormData?.HomeWarranty ? (
            <div className={styles.questionInput}>
              <div className={styles.questionInputLabel}>Cost?</div>
              <Input
                className={styles.input}
                type="number"
                placeholder="$0"
                inputHolderClassName={styles.inputHolder}
                variant={Input.LIGHT}
                prefix="$"
                isNumberFormat
                value={
                  counterOfferFormData?.HomeWarrantyCost
                    ? counterOfferFormData?.HomeWarrantyCost
                    : ''
                }
                onChange={(e) => onChangeValue(Number(e.target.value), 'HomeWarrantyCost')}
                name="homeWarranty"
                id="homeWarranty"
                testid="homeWarranty"
              />
              <div
                className={classNames(
                  styles.questionInputLabel,
                  styles.questionInputLabelSecondary,
                )}
              >
                Responsible Party?
              </div>
              <Select
                className={styles.inputHolder}
                options={responsibleParty}
                value={
                  counterOfferFormData?.HomeWarrantyResponsibility
                    ? counterOfferFormData?.HomeWarrantyResponsibility
                    : ''
                }
                onSelect={(e) => {
                  onChangeValue(e, 'HomeWarrantyResponsibility');
                }}
                placeholder={'Select Party'}
              />
            </div>
          ) : (
            <></>
          )}
          {homeWarranty ? <ErrorMessage id="home_warrant" message={'*Select an option'} /> : <></>}
          {homeWarrantCost ? (
            <ErrorMessage id="home_warrant_cost" message={'*Enter cost'} />
          ) : (
            <></>
          )}
          {homeWarrantResponsibility ? (
            <ErrorMessage id="home_warrant_Responsibility" message={'*Select party'} />
          ) : (
            <></>
          )}
        </div>
        <div className={styles.subHeading}>
          Appliances
          <Input
            className={styles.input}
            type={'text'}
            placeholder="Enter appliances"
            inputHolderClassName={styles.inputHolder}
            variant={Input.LIGHT}
            value={counterOfferFormData?.Appliances ? counterOfferFormData?.Appliances : ''}
            maxLength={10000}
            onChange={(e) => onChangeValue(e.target.value, 'Appliances')}
            name="Appliances"
            id="Appliances"
            testid="submission_appliances"
          />
        </div>
        <div className={styles.subHeading}>
          Post-Closing Occupancy/SIP
          <YesNo
            questionHandler={(e) => onChangeValue(e, 'Occupancy')}
            yes={counterOfferFormData?.Occupancy}
            objectKey={'Occupancy'}
            hasNumberInput={true}
            inputLabel={'Days'}
            inputValue={counterOfferFormData?.OccupancyDays}
            inputPlaceholder="#"
            inputHandler={(e) => onChangeValue(Number(e), 'OccupancyDays')}
            className={styles.yesNoContainer}
            inputClass={styles.counterOfferOccupancyInput}
          />
          {occupancy ? <ErrorMessage id="occupancy_days" message={'*Select an option'} /> : <></>}
          {occupancyDays ? <ErrorMessage id="occupancy_days" message={'*Enter days'} /> : <></>}
        </div>
      </div>
    </div>
  );
};
