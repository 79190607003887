import { Button, Modal, TextArea } from 'components-antd';
import { Icons } from '../../../../../Icons';

import styles from './styles.module.scss';
import { useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFormDetailsEffect,
  updateAllFormProcessEffect,
  voidFormDocumentEffect,
} from 'store/effects/formProcess';
import { getAllFormProcessesSelector } from 'store/selectors/requestFormProcess';

export const VoidFormReasonModal = (props) => {
  const {
    isOpen,
    onClose,
    formName,
    formProcessId,
    inProgressForm,
    isWaitingOnOtherForm,
    isWaitingOnYouForm,
    refetchForms,
  } = props;

  const { waitingOnOthers, waitingOnYou, ...restAllFormProcess } = useSelector(
    getAllFormProcessesSelector,
  );

  const [reason, setReason] = useState('');
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const onSubmitReason = () => {
    setLoading(true);

    dispatch(
      voidFormDocumentEffect({ formProcessId, reason }, (error, response) => {
        if (!error) {
          dispatch(
            getFormDetailsEffect(formProcessId, () => {
              setLoading(false);

              if (refetchForms) {
                refetchForms();
              } else if (!inProgressForm && (isWaitingOnYouForm || isWaitingOnOtherForm)) {
                const forms = isWaitingOnYouForm ? waitingOnYou : waitingOnOthers;

                const updatedFormsList = forms.filter(
                  (item) => item.formProcessId !== formProcessId,
                );

                const details = {
                  data: {
                    value: {
                      ...restAllFormProcess,

                      ...(isWaitingOnYouForm
                        ? {
                            waitingOnOthers,
                            waitingOnYou: updatedFormsList,
                          }
                        : { waitingOnYou, waitingOnOthers: updatedFormsList }),
                    },
                  },
                };

                dispatch(updateAllFormProcessEffect(details));
              }

              onClose();
            }),
          );
        } else {
          setLoading(false);
        }
      }),
    );
  };

  return (
    <Modal
      open={isOpen}
      width={675}
      footer={null}
      onCancel={onClose}
      maskClosable={false}
      destroyOnClose
      closeIcon={<Icons variant={'modal-close'} stroke="#262626" />}
      title={`Void ${formName}`}
      className={styles.voidFormReasonModal}
    >
      <>
        <div>
          <div className={styles.label}>Reason for voiding form:</div>
          <TextArea
            rows={4}
            maxLength={250}
            className={styles.textArea}
            placeholder="Enter message..."
            onChange={(e) => setReason(e.target.value)}
            defaultValue={reason}
          />
        </div>

        <div className={styles.voidReasonInformation}>
          By voiding this form, you are canceling all remaining signing activities. Recipients who
          have finished signing will receive an email notification that includes your reason for
          voiding. Recipients who have not yet signed will not be able to view or sign the enclosed
          documents.
        </div>

        <Button
          variant="secondary"
          loading={loading}
          onClick={onSubmitReason}
          disabled={!reason || loading}
          className={classNames(styles.voidButton, { [styles.disabledVoidButton]: !reason })}
        >
          Void
        </Button>
      </>
    </Modal>
  );
};
