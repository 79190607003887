import { useMemo, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { TransactionDetail } from 'types';
import { Lock, Edit } from 'components/Icons';
import { Button, Space } from 'components-antd';
import NumberFormat from 'react-number-format';
import {
  getTransactionDetailsEffect,
  updateTransactionDetailEffect,
} from 'store/effects/transactions';
import { getTransactionAccessSelector } from 'store/selectors/transaction';
import { Input } from 'components';
import { renderValue } from './helper';

import styles from './styles.module.scss';

interface DetailCardProps {
  field: TransactionDetail;
  editMode: boolean;
  setEditDetailId: (editDetailId?: number) => void;
  transactionId?: number;
}

export const DetailCard = ({
  field,
  editMode,
  setEditDetailId,
  transactionId,
}: DetailCardProps) => {
  const dispatch = useDispatch();
  const params: { id?: string } = useParams();

  const [hover, setHover] = useState<boolean>(false);
  const [answer, setAnswer] = useState<string>(field.FieldAnswer);
  const [loading, setLoading] = useState<boolean>(false);
  const { fullAccess } = useSelector(getTransactionAccessSelector);

  const isEarnestMoneyDeposit = useMemo(
    () => field?.FieldName?.includes('Earnest Money Deposit'),
    [],
  );

  const handleUpdate = () => {
    setLoading(true);
    const id = params && params?.id ? params?.id : transactionId;
    dispatch(
      updateTransactionDetailEffect({ id, detailId: field.Id, answer }, (err) => {
        if (!err) {
          dispatch(getTransactionDetailsEffect({ id }));
          setEditDetailId(undefined);
        }
        setLoading(false);
      }),
    );
  };

  const renderDetail = () => (
    <div className={classNames(styles.detail, { [styles.detailInputWrapper]: editMode })}>
      <span className={styles.fieldName}>
        <span>{field.FieldName}</span>
        {field.IsPrivate ? (
          <Lock width="16" height="16" stroke="#AAABAB" className={styles.privateIcon} />
        ) : (
          <></>
        )}
      </span>
      {editMode ? (
        <Space direction="vertical" size="middle">
          {isEarnestMoneyDeposit ? (
            <Input
              variant={Input.LIGHT_ROUNDED}
              placeholder="$0"
              isNumberFormat
              prefix="$"
              onChange={(e, val) => setAnswer(val)}
              className={styles.detailInput}
              value={answer}
            />
          ) : (
            <Input
              variant={Input.LIGHT_ROUND}
              onChange={(e) => (fullAccess ? setAnswer(e.target.value) : null)}
              value={answer}
              className={styles.detailInput}
            />
          )}
          <Space size="middle">
            <Button
              variant="secondary"
              className={classNames(styles.actionBtn, {
                [styles.disabledBtn]: answer === field.FieldAnswer,
              })}
              loading={loading}
              onClick={handleUpdate}
              disabled={answer === field.FieldAnswer}
            >
              Update
            </Button>
            <Button
              variant="secondary-inverted"
              className={classNames(styles.actionBtn, {
                [styles.disabledBtn]: loading,
              })}
              disabled={loading}
              onClick={() => {
                setHover(false);
                setEditDetailId(undefined);
                setAnswer(field.FieldAnswer);
              }}
            >
              Cancel
            </Button>
          </Space>
        </Space>
      ) : isEarnestMoneyDeposit ? (
        <NumberFormat
          thousandSeparator
          displayType="text"
          value={answer}
          prefix="$"
          renderText={(val) => <span className={styles.fieldAnswer}>{val}</span>}
        />
      ) : (
        <span className={styles.fieldAnswer}>{renderValue(answer) || '-'}</span>
      )}
    </div>
  );

  return editMode ? (
    renderDetail()
  ) : (
    <div
      className={styles.cardWrapper}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => (fullAccess ? setEditDetailId(field.Id) : null)}
    >
      {renderDetail()}
      {fullAccess && (
        <Edit
          className={classNames(styles.editIcon, { [styles.hideIcon]: !hover })}
          stroke="#262626"
        />
      )}
    </div>
  );
};
