import { apiServer } from 'settings/web-services/api';
import {
  CREATE_TRANSACTION_CONTACT,
  CREATE_TRANSACTION_CONTACT_PARTICIPANT,
} from 'app-constants/endpoints';

export function createTransaction(cfg) {
  return apiServer.post('/transaction', cfg);
}

export function createTransaction_(cfg) {
  return apiServer.post('/workshop/transaction', cfg);
}

export function createProject_(cfg) {
  return apiServer.post('/workshop/project', cfg);
}

export const createTransactionContact = ({ id, ...cfg }) =>
  apiServer.post(CREATE_TRANSACTION_CONTACT(id), cfg);

export const createTransactionContactParticipant = ({ id, ...cfg }) =>
  apiServer.post(CREATE_TRANSACTION_CONTACT_PARTICIPANT(id), cfg);
