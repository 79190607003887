import { FormsTableStatus, TransactionFormsStatus } from 'app-constants';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllFormProcessEffect } from 'store/effects/formProcess';
import { getAllFormProcessesSelector } from 'store/selectors/requestFormProcess';
import { getTransactionSelector } from 'store/selectors/transaction';
import { AggregatedPageTypes } from 'types/aggregatedPageTypes';
import { addProOrTransFilterParam } from 'utils/aggregatedPageTypeHelper';

export const useFetchForms = (props) => {
  const { filterPayload, initialLoadModal = false } = props;

  const [fetching, setFetching] = useState(false);
  const { transaction } = useSelector(getTransactionSelector);

  const {
    waitingOnYou = [],
    waitingOnOthers = [],
    draftForms = [],
  } = useSelector(getAllFormProcessesSelector);

  const dispatch = useDispatch();

  const getForms = (options?) => {
    !options?.silent && setFetching(true);
    const { statuses } = filterPayload || { statuses: '' };

    dispatch(
      getAllFormProcessEffect(
        {
          filters: {
            ...addProOrTransFilterParam(AggregatedPageTypes, {
              transactions: [transaction?.Id],
              isArchive: false,
              statuses: statuses || Object.keys(TransactionFormsStatus),
            }),
          },
        },
        options,
        (error, response) => {
          if (!error) {
            setFetching(false);
          }
        },
      ),
    );
  };

  useEffect(() => {
    if (transaction?.Id && !initialLoadModal) {
      getForms();
    }
  }, [filterPayload, transaction]);

  let formsDataSource = [
    ...waitingOnYou.map((data) => ({
      ...data,
      key: data.formDocumentId,
      status: FormsTableStatus.WaitingOnYou,
    })),

    ...waitingOnOthers.map((data) => ({
      ...data,
      key: data.formDocumentId,
      status: FormsTableStatus.WaitingOnOthers,
    })),

    ...draftForms.map((data) => ({
      ...data,
      key: data.formDocumentId,
      status: FormsTableStatus.Draft,
    })),
  ];

  return { formsDataSource, fetching, getForms };
};
