import React from 'react';
import moment from 'moment';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import antd_en_US from 'antd/lib/locale-provider/en_US';
import LocaleProvider from 'antd/lib/locale-provider';
import { pdfjs } from 'react-pdf';
import pdfjsWorker from 'react-pdf/dist/esm/pdf.worker.entry';

moment.updateLocale('en', {
  week: {
    dow: 7, // Set Sunday as the first day of the week
  },
});

import { App } from 'components';
import history from 'services/history';
import reportWebVitals from './reportWebVitals';
import store from './store';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

ReactDOM.render(
  <React.StrictMode>
    <LocaleProvider locale={antd_en_US}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </Provider>
    </LocaleProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))

// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
