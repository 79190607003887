import styles from './style.module.scss';

import { ReactNode } from 'react';
import { Icons } from '../Icons';
import classNames from 'classnames';
import { Filter } from './Filter';
import { Input } from 'components-antd';
import SearchGlass from 'components/Icons/SearchGlass';

type FormSearchFilterType = {
  onSearch: (value) => void;
  onShowSelection?: (value) => void;
  showSelection?: boolean;
  filterProps?: any;
  searchText?: string;
  hideSelection?: boolean;
  customFilter?: ReactNode;
  hideFilter?: boolean;
  className?: string;
};

export const FormSearchFilter = (props: FormSearchFilterType) => {
  const {
    onSearch,
    onShowSelection,
    showSelection,
    searchText,
    filterProps = {},
    hideSelection,
    customFilter,
    hideFilter = false,
    className,
  } = props;

  return (
    <div className={classNames(styles.formSearchFilterWrapper, className)}>
      <div className={styles.searchWrapper}>
        <Input
          size="large"
          value={searchText}
          onChange={(event) => {
            const { value } = event.target;

            if (!searchText && value.trim().length) {
              onSearch(value);
            } else if (searchText) {
              onSearch(value);
            }
          }}
          className={styles.inputWrap}
          placeholder="Search"
          prefix={<SearchGlass color="#515151" className={styles.icon} />}
          allowClear={true}
        />
      </div>

      {!hideFilter || !hideSelection ? (
        <div className={styles.filterActions}>
          {hideFilter ? <></> : customFilter ? customFilter : <Filter {...filterProps} />}

          {!hideSelection ? (
            <div
              className={classNames(styles.btn, { [styles.multselectSelected]: showSelection })}
              onClick={() => onShowSelection?.(!showSelection)}
            >
              <Icons className={styles.btnIcon} variant={'multiSelect'} />
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
