import { Button, Input } from 'components';
import styles from './styles.module.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { updateClientDataEffect } from 'store/effects';
import { showSuccessMessage } from 'helpers';
import { requestGetClientsListEffect } from 'store/effects/clientsList';

export const NameForm = ({ value, handleClose, clientId }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    firstName: Yup.string(),
    lastName: Yup.string(),
  });

  const { handleSubmit, handleChange, values, handleBlur } = useFormik({
    initialValues: value,
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      dispatch(
        updateClientDataEffect({ clientId, ...values }, (err) => {
          if (!err) {
            showSuccessMessage('Client name updated');
            dispatch(requestGetClientsListEffect({}, { silent: true }));
          }
          setLoading(false);
          handleClose();
        }),
      );
    },
  });

  return (
    <form className={styles.main} onSubmit={handleSubmit}>
      <Input
        name="firstName"
        onChange={handleChange}
        value={values.firstName}
        onBlur={handleBlur}
        variant={Input.LIGHT_ROUND}
        className={styles.margin}
      />
      <Input
        name="lastName"
        onChange={handleChange}
        value={values.lastName}
        onBlur={handleBlur}
        variant={Input.LIGHT_ROUND}
        className={styles.margin}
      />
      <div className={styles.actionButtons}>
        <Button type="submit" title={'Update'} className={styles.update} isPending={loading} />
        <Button title={'Cancel'} className={styles.cancel} onClick={handleClose} />
      </div>
    </form>
  );
};
