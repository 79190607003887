import { useEffect, useMemo, useState, ReactNode, Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { routes } from 'settings/navigation/routes';
import { PageWrapper, Tabs } from 'components-antd';
import { Wrapper } from 'components';
import {
  LocalHeader,
  DocumentsContent,
  DocumentOptions,
  DateTimeColumn,
  AvatarColumn,
  DocumentNameColumn,
  EmptyState,
} from '../components';
import { Icons } from 'pages/Vault/Icons';
import { DocsContentView } from '../constants';

import { getClientTransactionDocumentsList, getMetaVaultDocuments } from 'store/selectors/vault';
import { getClientTransactionDocumentsEffect, getVaultDocumentsMetaEffect } from 'store/effects';
import { getCurrentContextSelector } from 'store/selectors/context';
import { getClientContextsStateSelector } from 'store/selectors/contexts';

import { READY, PENDING } from 'settings/constants/apiState';

import styles from './styles.module.scss';

const TabTitle = ({ label, count, iconVariant }) => (
  <div className={styles.vaultTabTitle}>
    <Icons variant={iconVariant} className={styles.icon} />
    <span>{label}</span>
    {count > 0 && <span>({count})</span>}
  </div>
);

const DocumentsContenWrapper = ({
  title,
  columns,
  data,
  totalCount,
  viewType,
  Property,
  refetch,
}) => {
  return (
    <Fragment>
      {data?.length ? (
        <DocumentsContent
          title={title}
          columns={columns}
          data={data}
          totalCount={totalCount}
          viewType={viewType}
          Property={Property}
          refetch={refetch}
        />
      ) : (
        <EmptyState />
      )}
    </Fragment>
  );
};

export const VaultPropertyPage = () => {
  const history = useHistory();
  const params: any = useParams();
  const dispatch = useDispatch();
  const searchContext = useSelector(getCurrentContextSelector);
  const contextsState = useSelector(getClientContextsStateSelector);
  const selectedAgentId = searchContext?.Agents?.[0]?.Id;
  const { data: metaData } = useSelector(getMetaVaultDocuments);
  const { categories } = metaData || {};
  const { data, isPending } = useSelector(getClientTransactionDocumentsList);
  const { clientTransactionDocuments } = data || {};
  const clientTransactionDocuments_ = clientTransactionDocuments?.[0]; // API returns array of objects, so taking item at first index.
  const [viewType, setViewType] = useState(DocsContentView.GRID_VIEW);

  const handleBackBtn = () => history.push(routes.vault);

  const fetchPropertyVaultDocuments = () => {
    const isFolder = params?.id?.includes('folder-');

    dispatch(
      getClientTransactionDocumentsEffect({
        id: selectedAgentId,
        ...(isFolder
          ? { folder: params?.id?.replace('folder-', '') }
          : { transactionId: params?.id }),
      }),
    );
  };

  useEffect(() => {
    if (contextsState.state === READY && selectedAgentId) {
      fetchPropertyVaultDocuments();

      if (!metaData) {
        dispatch(
          getVaultDocumentsMetaEffect({
            id: selectedAgentId,
          }),
        );
      }
    }
  }, [contextsState.state, selectedAgentId]);

  const title = useMemo(
    () =>
      clientTransactionDocuments_?.Property?.Address?.Line1 || clientTransactionDocuments_?.Folder,
    [clientTransactionDocuments_],
  );

  const columns = [
    {
      title: 'Name',
      key: 'name',
      render: (_, { Title, Filename, FormName }) => {
        const name = Title || Filename || FormName;
        return <DocumentNameColumn name={name} docIconType={Icons.DOCUMENT_1} />;
      },
      sorter: (a, b) => {
        const aName = a?.Title || a?.Filename || a?.FormName;
        const bName = b?.Title || b?.Filename || b?.FormName;
        return aName?.localeCompare(bName);
      },
    },
    {
      title: 'Uploaded By',
      key: 'uploadedBy',
      render: (_, { UploaderFirstName, UploaderLastName, AvatarUrl }) => {
        return (
          <AvatarColumn name={`${UploaderFirstName} ${UploaderLastName}`} imgSrc={AvatarUrl} />
        );
      },
      sorter: (a, b) => {
        const aName = `${a?.UploaderFirstName} ${a?.UploaderLastName}`;
        const bName = `${b?.UploaderFirstName} ${b?.UploaderLastName}`;
        return aName?.localeCompare(bName);
      },
    },
    {
      title: 'Uploaded',
      key: 'uploaded',
      dataIndex: 'uploaded',
      render: (_, { CreatedDate }) => {
        return <DateTimeColumn date={CreatedDate} />;
      },
      sorter: (a, b) => a?.CreatedDate?.localeCompare(b?.CreatedDate),
    },
    {
      title: '',
      key: '',
      render: (_, file) => {
        return (
          <div className={styles.optionsColumn}>
            <DocumentOptions
              file={file}
              Property={clientTransactionDocuments_?.Property}
              className={styles.bottomOffset}
              refetch={fetchPropertyVaultDocuments}
            />
          </div>
        );
      },
    },
  ];

  const tabItemProps = {
    title: 'Total',
    columns,
    viewType,
    Property: clientTransactionDocuments_?.Property,
    refetch: fetchPropertyVaultDocuments,
  };

  const generateTabItems = (documents) => {
    const tabItems: {
      key: string;
      label: ReactNode;
      children: ReactNode;
    }[] = [];

    // Group documents by category, putting null categories under 'Transaction'
    const documentsByCategory = documents.reduce((acc, doc) => {
      // Find the matching category by Id
      const category =
        categories?.find((cat) => cat.Id === doc.DocumentCategoryId)?.Title || 'Transaction';

      if (!acc[category]) acc[category] = [];
      acc[category].push(doc);
      return acc;
    }, {});

    // Iterate through the categories to create tab items
    categories?.forEach((category, index) => {
      if (category.Title !== 'Pre-Approval' && category.Title !== 'Form') {
        const totalCount = documentsByCategory[category?.Title]?.length;

        tabItems.push({
          key: String(index + 1),
          label: (
            <TabTitle
              label={category.Title}
              iconVariant={Icons[category.Title.toUpperCase()]}
              count={totalCount}
            />
          ),
          children: (
            <DocumentsContenWrapper
              {...tabItemProps}
              data={documentsByCategory[category?.Title]}
              totalCount={totalCount}
            />
          ),
        });
      }
    });

    // 'Transaction' tab: Documents without a valid category
    tabItems.unshift({
      key: '0',
      label: (
        <TabTitle
          label="Transaction"
          iconVariant={Icons.TRANSACTION}
          count={documentsByCategory['Transaction']?.length}
        />
      ),
      children: (
        <DocumentsContenWrapper
          {...tabItemProps}
          data={documentsByCategory['Transaction']}
          totalCount={documentsByCategory['Transaction']?.length}
        />
      ),
    });

    return tabItems;
  };

  return (
    <PageWrapper bgGray mainPageContentStyle={styles.pagecontentStyles} version={2}>
      <Wrapper isPending={isPending || contextsState?.state === PENDING}>
        <LocalHeader
          title={title || params?.id?.replace('folder-', '')}
          handleBackBtn={handleBackBtn}
          viewType={viewType}
          setViewType={setViewType}
          uploadCallback={fetchPropertyVaultDocuments}
        />
        <div className={styles.pageContent}>
          {clientTransactionDocuments_?.Documents?.length ? (
            <Tabs
              defaultActiveKey="0"
              size="middle"
              items={generateTabItems(clientTransactionDocuments_?.Documents) || []}
              className={styles.vaultTabs}
            />
          ) : (
            <EmptyState />
          )}
        </div>
      </Wrapper>
    </PageWrapper>
  );
};
