import { SVGAttributes } from 'react';

export const NewListingIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M13.203 3.77501C13.1477 3.5743 12.8523 3.5743 12.797 3.77501C11.7549 7.55448 10.5545 8.75486 6.77501 9.79696C6.5743 9.85231 6.5743 10.1477 6.77501 10.203C10.5545 11.2451 11.7549 12.4455 12.797 16.225C12.8523 16.4257 13.1477 16.4257 13.203 16.225C14.2451 12.4455 15.4455 11.2451 19.225 10.203C19.4257 10.1477 19.4257 9.85231 19.225 9.79696C15.4455 8.75486 14.2451 7.55448 13.203 3.77501Z"
        fill="#4673D1"
      />
      <path
        d="M4.19967 9.6721C4.1397 9.4801 3.8603 9.4801 3.80033 9.6721C3.17803 11.6645 2.66449 12.178 0.672099 12.8003C0.480099 12.8603 0.480099 13.1397 0.672098 13.1997C2.66449 13.822 3.17803 14.3355 3.80033 16.3279C3.8603 16.5199 4.1397 16.5199 4.19967 16.3279C4.82197 14.3355 5.33551 13.822 7.3279 13.1997C7.5199 13.1397 7.5199 12.8603 7.3279 12.8003C5.33551 12.178 4.82197 11.6645 4.19967 9.6721Z"
        fill="#4673D1"
      />
    </svg>
  );
};
