import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import classNames from 'classnames';
import { Modal } from 'components-antd';
import { useFormik } from 'formik';
import { showErrorMessage, showSuccessMessage } from 'helpers';
import { get } from 'lodash-es';
import useReminderTemplate from 'pages/Dashboard/Kits/hooks/use-reminder-template.hook';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { QuillEditor } from '../QuillEditor';
import styles from './styles.module.scss';

type ReminderModalPropsType = {
  showModal: Function;
  open: boolean;
  targetKit;
};

const validationSchema = Yup.object({
  Id: Yup.number().optional().nullable(),
  Template: Yup.string(),
  AllowedVariables: Yup.array().of(
    Yup.object({
      key: Yup.string().required('Key is required'),
      value: Yup.string(),
    }),
  ),
});

function RemindedModal(props: ReminderModalPropsType) {
  const { open, showModal, targetKit } = props;
  const {
    reminderTemplate,
    getReminderTemplate,
    updateReminderTemplate,
    addReminderTemplate,
    loading,
  } = useReminderTemplate();

  useEffect(() => {
    if (targetKit?.Id) {
      getReminderTemplate(targetKit.Id);
    }
  }, [targetKit, getReminderTemplate]);

  const formik = useFormik({
    initialValues: {
      Id: null,
      Template: '',
      AllowedVariables: [] as { key: string; value: string }[],
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        if (values.Id) {
          const { Id, ...payload } = values;
          await updateReminderTemplate(Id, payload);
        } else {
          const payload = {
            Template: values.Template,
            AllowedVariables: values.AllowedVariables,
            EntityId: targetKit.Id,
            EntityName: 'Kit',
          };
          await addReminderTemplate(payload);
        }
        showSuccessMessage('Reminder template for kit saved successfully');
        showModal(false);
      } catch (e) {
        console.error(e);
        showErrorMessage('Unable to save reminder template for kit, please check logs');
      }
    },
  });

  useEffect(() => {
    if (reminderTemplate && !loading.get) {
      formik.setValues({
        Id: reminderTemplate.Id,
        Template: reminderTemplate.Template,
        AllowedVariables: reminderTemplate.AllowedVariables || [],
      });
    }
  }, [reminderTemplate, loading.get, formik.setValues]);

  return (
    <Modal
      title="Add Reminder Template For Kit"
      open={open}
      onCancel={() => showModal(false)}
      onOk={() => formik.handleSubmit()}
      className={styles.reminderModal}
      okText={'Save'}
      okButtonProps={{ disabled: !formik.isValid }}
      width={750}
    >
      <form>
        <QuillEditor
          value={formik.values.Template}
          onChange={(value) => formik.setFieldValue('Template', value)}
          onBlur={() => formik.setFieldTouched('Template', true)}
          placeholder="Hey {{nameVariable}} this is a reminder."
          editorClass={styles.reminderEditor}
        />
        {formik.touched.Template && formik.errors.Template && (
          <div className={styles.errorText}>{formik.errors.Template}</div>
        )}

        <div className={styles.allowedVariables}>
          <div className={styles.allowedVariablesInner}>
            {formik.values.AllowedVariables.map((variable, index) => (
              <>
                <div
                  key={index}
                  className={classNames(styles.inputField, styles.inputFieldWrapper)}
                >
                  <Input
                    name={`AllowedVariables[${index}].key`}
                    onChange={formik.handleChange}
                    value={variable.key}
                    placeholder="Enter key"
                  />
                  <Input
                    name={`AllowedVariables[${index}].value`}
                    onChange={formik.handleChange}
                    value={variable.value}
                    placeholder="Enter optional default value"
                  />
                  <Button
                    icon={<CloseCircleOutlined />}
                    onClick={() =>
                      formik.setFieldValue(
                        'AllowedVariables',
                        formik.values.AllowedVariables.filter((_, idx) => idx !== index),
                      )
                    }
                    className={`${styles.iconButton} ${styles.remove}`}
                  />
                </div>
                <div>
                  {get(formik.errors, `AllowedVariables[${index}].key`) && (
                    <div className={styles.errorText}>
                      {get(formik.errors, `AllowedVariables[${index}].key`)}
                    </div>
                  )}
                </div>
              </>
            ))}
          </div>
          <Button
            type="dashed"
            onClick={() =>
              formik.setFieldValue('AllowedVariables', [
                ...formik.values.AllowedVariables,
                { key: '', value: '' },
              ])
            }
            icon={<PlusCircleOutlined />}
            className={`${styles.iconButton} ${styles.add}`}
          >
            Add Allowed Variables
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default RemindedModal;
