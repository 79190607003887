import { SVGProps } from 'react';

export const TaskIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle opacity="0.12" cx="16" cy="16" r="16" fill="#51BFE1" />
    <g clipPath="url(#clip0_54422_47468)">
      <path
        d="M8.91699 10.5833L10.167 11.8333L12.2503 9.75"
        stroke="#51BFE1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.91699 15.5833L10.167 16.8333L12.2503 14.75"
        stroke="#51BFE1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.91699 20.5833L10.167 21.8333L12.2503 19.75"
        stroke="#51BFE1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.167 11H22.667"
        stroke="#51BFE1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.167 16H22.667"
        stroke="#51BFE1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.167 21H22.667"
        stroke="#51BFE1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_54422_47468">
        <rect width="20" height="20" fill="white" transform="translate(6 6)" />
      </clipPath>
    </defs>
  </svg>
);
