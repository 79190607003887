import { Fragment, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { SplitItem, EditDynamicSplit } from '..';
import { ConfirmationDialog } from 'components';

import { removeSplitLineItem, updateSplitLineItem } from 'api/financials';
import { getUserId } from 'store/selectors/user';
import { showErrorMessage } from 'helpers';

import styles from './styles.module.scss';

export const DynamicSplitItem = ({
  item,
  autocompleteSplitNames,
  transactionId,
  isTransactionAdminOrOwner,
  isValidate,
  updateSplitValue,
  fetchFinancials,
  isDisabledField,
  setEditFieldKey,
  memberContacts,
  comparePrice,
}) => {
  const [isEditState, setIsEditState] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const loggedInUserId = useSelector(getUserId);

  // Only Transaction Admin/Owner can view Restricted Dynamic Splits.
  if (!isTransactionAdminOrOwner && item?.SplitType?.IsRestricted) return null;

  const toggleRestrict = async () => {
    const { Id, LedgerId, LedgerAccountId, IsRestricted } = item || {};

    const payload = {
      LedgerLineItemId: Id,
      LedgerId,
      LedgerAccountId,
      UpdatedBy: loggedInUserId,
      IsRestricted: !IsRestricted,
    };

    try {
      await updateSplitLineItem(payload);
      fetchFinancials();
    } catch (err) {
      showErrorMessage(err);
    }
  };

  const handleDeleteSplitItem = async () => {
    const { Id } = item || {};

    try {
      setConfirmationLoading(true);
      await removeSplitLineItem(Id);
      fetchFinancials();
    } catch (err) {
      setConfirmationLoading(false);
      showErrorMessage(err);
    } finally {
      setConfirmationLoading(false);
      setShowConfirmationModal(false);
    }
  };

  const accountUser = useMemo(() => {
    if (item?.LedgerAccountUserId)
      return `${item?.LedgerAccountUser?.FirstName} ${item?.LedgerAccountUser?.LastName}`;
    if (item?.LedgerAccountContactId)
      return `${item?.LedgerAccountContact?.FirstName} ${item?.LedgerAccountContact?.LastName}`;
    return '';
  }, [item]);

  return (
    <Fragment>
      {!isEditState ? (
        <SplitItem
          label={item?.SplitType?.SplitTitle}
          amount={item?.SplitValue}
          percentage={item?.SplitPercentage}
          setEditField={() => {
            setIsEditState(true);
            setEditFieldKey(item.Id);
          }}
          toggleRestrict={toggleRestrict}
          handleDelete={() => setShowConfirmationModal(true)}
          valueTypeEntered={item?.defaultSplitValueType} // Property tells which type of value(% or $) user added while adding Split.
          splitType={item?.SplitType?.SplitType}
          isTransactionAdminOrOwner={isTransactionAdminOrOwner}
          isDisabledField={isDisabledField}
          isRestricted={item?.IsRestricted}
          accountUser={accountUser}
          ledgerAccount={item?.LedgerAccountUserId || item?.LedgerAccountContactId}
          isDynamicSplit
        />
      ) : (
        <EditDynamicSplit
          splitItem={item}
          autocompleteSplitNames={autocompleteSplitNames}
          splitType={item?.SplitSide}
          updateSplitValue={updateSplitValue}
          isTransactionAdminOrOwner={isTransactionAdminOrOwner}
          transactionId={transactionId}
          fetchFinancials={fetchFinancials}
          isValidate={isValidate}
          onCancel={() => {
            setIsEditState(false);
            setEditFieldKey(null);
          }}
          memberContacts={memberContacts}
          comparePrice={comparePrice}
        />
      )}

      {/* Delete Confirmation Dialog */}

      <ConfirmationDialog
        onReject={() => setShowConfirmationModal(false)}
        onConfirm={handleDeleteSplitItem}
        isOpen={showConfirmationModal}
        confirmText="Delete"
        isPending={confirmationLoading}
        className={styles.deleteSplitDialog}
      >
        <div className={styles.content}>
          Are you sure you want to delete <b>{item?.SplitType?.SplitTitle}</b>?
        </div>
      </ConfirmationDialog>
    </Fragment>
  );
};
