import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getOfferAggregateByUserIdEffect,
  setOffersFiltersAppliedEffect,
  setOffersFiltersEffect,
} from 'store/effects/offerAggregate';

import { relatedEntitiesCompleteListGetDataEffect } from 'store/effects/relatedEntities';
import { getAllOfferBuyingAgentsEffect } from 'store/effects/offerBuyingAgents';
import { FilterSections } from './FilterSections';
import { requestGetTransactionPersons } from 'store/effects';
import { getOfferFiltersSelector } from 'store/selectors/offerAggregate';
import { getUserId } from 'store/selectors/user';

export const Filter = ({ transactionRoomId }: { transactionRoomId?: string }) => {
  const dispatch = useDispatch();
  const { offersFilter } = useSelector(getOfferFiltersSelector);

  const userId = useSelector(getUserId);

  useEffect(() => {
    dispatch(relatedEntitiesCompleteListGetDataEffect());
    dispatch(getAllOfferBuyingAgentsEffect());
    dispatch(requestGetTransactionPersons());
  }, []);

  const doneHandler = (filters) => {
    dispatch(setOffersFiltersAppliedEffect(true));
    dispatch(setOffersFiltersEffect({ ...offersFilter, allFilters: filters }));
  };

  const resetHandler = (filters) => {
    dispatch(setOffersFiltersAppliedEffect(false));
    dispatch(setOffersFiltersEffect({ ...offersFilter, allFilters: {} }));
  };

  return (
    <FilterSections
      onReset={resetHandler}
      onDone={doneHandler}
      transactionRoomId={transactionRoomId}
    />
  );
};
