import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';
import { textColor } from 'app-constants';

export const ICON_VARIANT_TYPE = {
  LOCATION: 'location',
  AGENT: 'agent',
  CALENDAR: 'calendar',
  UPLOAD: 'upload',
  ARROW_BOTTOM: 'arrowBottom',
  EDIT: 'edit',
  USER: 'user',
  DOCUMENT: 'document',
  INFO: 'info',
  CLOSE: 'close',
  PREVIOUS: 'previous',
} as const;

type IconVariant = typeof ICON_VARIANT_TYPE[keyof typeof ICON_VARIANT_TYPE];

interface IconsProps extends React.SVGProps<SVGSVGElement> {
  variant?: IconVariant;
  testid?: string;
}

export const Icons = ({ variant, testid, ...props }: IconsProps) => {
  const getIcons = () => {
    switch (variant) {
      case ICON_VARIANT_TYPE.LOCATION: {
        return (
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <circle cx="24" cy="24" r="24" fill="#F4F5F6" />
            <circle cx="24" cy="23" r="3" stroke="#262626" strokeWidth="2" />
            <path
              d="M25.0931 33.6734L25.7182 34.454L25.0931 33.6734ZM22.9069 33.6734L22.2818 34.454L22.9069 33.6734ZM31 23C31 24.9761 29.9778 26.9886 28.5776 28.7938C27.1921 30.5801 25.5321 32.0407 24.468 32.8929L25.7182 34.454C26.844 33.5524 28.6375 31.9798 30.1579 30.0196C31.6637 28.0784 33 25.6311 33 23H31ZM23.532 32.8929C22.4678 32.0407 20.8079 30.5801 19.4224 28.7938C18.0222 26.9886 17 24.9761 17 23H15C15 25.6311 16.3363 28.0784 17.8421 30.0196C19.3625 31.9798 21.156 33.5524 22.2818 34.454L23.532 32.8929ZM17 23C17 19.134 20.134 16 24 16V14C19.0294 14 15 18.0294 15 23H17ZM24 16C27.866 16 31 19.134 31 23H33C33 18.0294 28.9706 14 24 14V16ZM24.468 32.8929C24.1869 33.118 23.8131 33.118 23.532 32.8929L22.2818 34.454C23.2936 35.2643 24.7064 35.2643 25.7182 34.454L24.468 32.8929Z"
              fill="#262626"
            />
          </svg>
        );
      }
      case ICON_VARIANT_TYPE.AGENT: {
        return (
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M16 8C16 10.2091 14.2091 12 12 12C9.79086 12 8 10.2091 8 8C8 5.79086 9.79086 4 12 4C14.2091 4 16 5.79086 16 8Z"
              stroke={props.color ?? textColor}
              strokeWidth="2"
            />
            <path
              d="M6 19.5C6.37394 17.6303 8.09315 16 9.99988 16H14C15.9067 16 17.6261 17.6303 18 19.5"
              stroke={props.color ?? textColor}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case ICON_VARIANT_TYPE.CALENDAR: {
        return (
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect opacity="0.08" width="40" height="40" rx="20" fill="#4673D1" />
            <path
              d="M25.25 14H14.75C13.7835 14 13 14.7835 13 15.75V26.25C13 27.2165 13.7835 28 14.75 28H25.25C26.2165 28 27 27.2165 27 26.25V15.75C27 14.7835 26.2165 14 25.25 14Z"
              stroke="#4673D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M23.5 12.5V15.5"
              stroke="#4673D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.5 12.5V15.5"
              stroke="#4673D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13 19H27"
              stroke="#4673D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case ICON_VARIANT_TYPE.UPLOAD: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M2.66797 11.334V12.6673C2.66797 13.0209 2.80844 13.3601 3.05849 13.6101C3.30854 13.8602 3.64768 14.0007 4.0013 14.0007H12.0013C12.3549 14.0007 12.6941 13.8602 12.9441 13.6101C13.1942 13.3601 13.3346 13.0209 13.3346 12.6673V11.334"
              stroke="#303030"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.66797 5.99935L8.0013 2.66602L11.3346 5.99935"
              stroke="#303030"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 2.66602V10.666"
              stroke="#303030"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case ICON_VARIANT_TYPE.ARROW_BOTTOM: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M12 6L8 10L4 6"
              stroke="#303030"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case ICON_VARIANT_TYPE.EDIT: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <circle cx="16" cy="16" r="16" fill="#F4F5F6" />
            <path
              d="M10 22H13.1314L21.3515 13.78C21.7667 13.3647 22 12.8015 22 12.2143C22 11.627 21.7667 11.0638 21.3515 10.6485C20.9362 10.2333 20.373 10 19.7857 10C19.1985 10 18.6353 10.2333 18.22 10.6485L10 18.8686V22Z"
              stroke="#262626"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.4365 11.4319L20.568 14.5633"
              stroke="#262626"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case ICON_VARIANT_TYPE.USER: {
        return (
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect opacity="0.08" width="40" height="40" rx="20" fill="#928CDA" />
            <path
              d="M24 16C24 18.2091 22.2091 20 20 20C17.7909 20 16 18.2091 16 16C16 13.7909 17.7909 12 20 12C22.2091 12 24 13.7909 24 16Z"
              stroke="#928CDA"
              strokeWidth="2"
            />
            <path
              d="M14 27.5C14.3739 25.6303 16.0932 24 17.9999 24H22C23.9067 24 25.6261 25.6303 26 27.5"
              stroke="#928CDA"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case ICON_VARIANT_TYPE.DOCUMENT: {
        return (
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect opacity="0.08" width="40" height="40" rx="20" fill="#51BFE1" />
            <path
              d="M22 11V15C22 15.2652 22.1054 15.5196 22.2929 15.7071C22.4804 15.8946 22.7348 16 23 16H27"
              stroke="#51BFE1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M25 29H15C14.4696 29 13.9609 28.7893 13.5858 28.4142C13.2107 28.0391 13 27.5304 13 27V13C13 12.4696 13.2107 11.9609 13.5858 11.5858C13.9609 11.2107 14.4696 11 15 11H22L27 16V27C27 27.5304 26.7893 28.0391 26.4142 28.4142C26.0391 28.7893 25.5304 29 25 29Z"
              stroke="#51BFE1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17 17H18"
              stroke="#51BFE1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17 21H23"
              stroke="#51BFE1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17 25H23"
              stroke="#51BFE1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case ICON_VARIANT_TYPE.INFO: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <circle cx="16" cy="16" r="16" fill="#F4F5F6" />
            <g clipPath="url(#clip0_39353_42011)">
              <path
                d="M22 16C22 19.3137 19.3137 22 16 22C12.6863 22 10 19.3137 10 16C10 12.6863 12.6863 10 16 10C19.3137 10 22 12.6863 22 16Z"
                stroke="#262626"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16 16C16 17.0414 16 17.4586 16 18.5"
                stroke="#262626"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <circle cx="16" cy="13" r="1" fill="#262626" />
            </g>
            <defs>
              <clipPath id="clip0_39353_42011">
                <rect width="16" height="16" fill="white" transform="translate(8 8)" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case ICON_VARIANT_TYPE.CLOSE: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            {...props}
          >
            <path d="M18 18L6 6" stroke="#262626" strokeWidth="2" strokeLinecap="round" />
            <path d="M6 18L18 6" stroke="#262626" strokeWidth="2" strokeLinecap="round" />
          </svg>
        );
      }
      case ICON_VARIANT_TYPE.PREVIOUS: {
        return (
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <rect opacity="0.08" width="40" height="40" rx="20" fill="#4673D1" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M23.694 26.6943C24.102 26.2867 24.102 25.6259 23.694 25.2184L18.4699 20L23.694 14.7817C24.102 14.3741 24.102 13.7133 23.694 13.3057C23.2859 12.8981 22.6244 12.8981 22.2164 13.3057L16.306 19.2096C16.0886 19.4267 15.987 19.7158 16.0013 20.0002C15.9871 20.2844 16.0887 20.5733 16.306 20.7904L22.2164 26.6943C22.6244 27.1019 23.286 27.1019 23.694 26.6943Z"
              fill="#262626"
            />
          </svg>
        );
      }
      default: {
        return <></>;
      }
    }
  };

  return getIcons();
};
