import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Row, Col, Button } from 'components-antd';
import { CommissionField } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/Questions/FinancingDetails/components';
import { ErrorMessage } from '../../../Error/ErrorMessage';
import CheckboxComponent from 'components/Form/Checkbox';
import { NameAutocomplete } from '../../../';
import { showErrorMessage } from 'helpers/errors';
import { addSplitLineItem, updateSplitLineItem } from 'api/financials';
import { getUserId } from 'store/selectors/user';
import { RemoveFile } from 'components/Icons';

import { SplitData } from '../SplitCard';
import { SplitTabTypes } from 'pages/Workshop/Transactions/TransactionFinancials/TransactionFinancials';

import { getLedgerId } from 'pages/Workshop/Transactions/TransactionFinancials/helpers';
import { LedgerAccountId, LedgerTransactionSideId } from 'settings/constants/ledger';

import styles from './styles.module.scss';
import { AgentCoordinatorField } from 'pages/Workshop/Transactions/TransactionOverview/components/AgentCoordinatorField';
import { convertToDecimalIfNotWhole } from 'helpers';

interface EditDynamicSplitType {
  splitItem?: SplitData;
  autocompleteSplitNames: string[];
  splitType: SplitTabTypes.LISTING | SplitTabTypes.PURCHASE;
  updateSplitValue: Function;
  isTransactionAdminOrOwner: boolean;
  transactionId?: number | string;
  fetchFinancials: Function;
  onCancel: Function;
  isValidate: boolean;
  ledgers?: any[];
  memberContacts: any[];
  comparePrice: number;
}

const initialState = {
  Id: null,
  IsSplitTypePercentage: true,
  SplitPercentage: null,
  SplitValue: null,
  SplitTitle: '',
  IsRestricted: false,
  LedgerAccountId: null,
  LedgerAccountUser: null,
};

export const EditDynamicSplit = ({
  splitItem,
  autocompleteSplitNames,
  splitType,
  updateSplitValue,
  isTransactionAdminOrOwner,
  fetchFinancials,
  onCancel,
  isValidate,
  ledgers,
  memberContacts,
  comparePrice,
}: EditDynamicSplitType) => {
  const [isAddUpdateSplitLoading, setAddUpdateSplitLoading] = useState(false);
  const [isLedgerAccountUserLoaded, setIsLedgerAccountUserLoaded] = useState(false);
  const [splitItemField, setSplitItemField] = useState<any>({
    ...initialState,
    LedgerId: getLedgerId(
      ledgers,
      splitType === SplitTabTypes.LISTING
        ? LedgerTransactionSideId.Listing
        : LedgerTransactionSideId.Purchase,
    ),
    LedgerAccountId: LedgerAccountId.CustomAccountPayment,
  });
  const [ledgerAccount, setLedgerAccount] = useState<any>(null);
  const [defaultAccountItem, setDefaultAccountItem] = useState<any>({
    label: null,
    value: null,
  });
  const [isShowAgentFields, setShowAgentField] = useState<boolean>(false);
  const loggedInUserId = useSelector(getUserId);

  useEffect(() => {
    if (splitItem) {
      setIsLedgerAccountUserLoaded(false);
      setSplitItemField({
        Id: splitItem?.Id,
        LedgerId: splitItem?.LedgerId,
        LedgerAccountId: splitItem?.LedgerAccountId,
        IsSplitTypePercentage: splitItem?.IsSplitTypePercentage,
        SplitPercentage: splitItem?.SplitPercentage || '',
        SplitValue: splitItem?.SplitValue || '',
        SplitTitle: splitItem?.SplitType?.SplitTitle,
        IsRestricted: splitItem?.IsRestricted,
        LedgerAccountUser: splitItem?.LedgerAccountUser,
        LedgerAccountUserId: splitItem?.LedgerAccountUserId,
      });

      const ledgerAccountUser = splitItem?.LedgerAccountUser || splitItem?.LedgerAccountContact;
      const ledgerAccountId = splitItem?.LedgerAccountUserId || splitItem?.LedgerAccountContactId;

      setLedgerAccount(
        splitItem?.LedgerAccountUserId
          ? { LedgerAccountUserId: ledgerAccountId }
          : { LedgerAccountContactId: ledgerAccountId },
      );
      setDefaultAccountItem({
        label: ledgerAccountUser
          ? `${ledgerAccountUser?.FirstName} ${ledgerAccountUser?.LastName}`
          : null,
        value: ledgerAccountId,
      });
      setShowAgentField(!!ledgerAccountUser);
      setIsLedgerAccountUserLoaded(true);
    }
  }, [splitItem]);

  const handleAddOrUpdate = async () => {
    try {
      setAddUpdateSplitLoading(true);
      const {
        Id,
        LedgerId,
        LedgerAccountId,
        SplitTitle,
        IsSplitTypePercentage,
        SplitPercentage = 0,
        SplitValue = 0,
        IsRestricted,
      } = splitItemField || {};

      const basePayload = {
        LedgerId,
        LedgerAccountId,
        ...(IsSplitTypePercentage
          ? { Percent: parseFloat(SplitPercentage) || 0 }
          : { Amount: parseFloat(SplitValue) || 0 }),
        UpdatedBy: loggedInUserId,
        IsRestricted,
        Memo: SplitTitle,
        Reference: SplitTitle,
        ...ledgerAccount,
      };

      if (Id) {
        const updatePayload = {
          ...basePayload,
          LedgerLineItemId: Id,
        };
        await updateSplitLineItem(updatePayload);
      } else {
        const createPayload = {
          ...basePayload,
          CreatedBy: loggedInUserId,
        };
        await addSplitLineItem(createPayload);
      }

      fetchFinancials();
    } catch (err) {
      showErrorMessage(err);
      setAddUpdateSplitLoading(false);
    } finally {
      setAddUpdateSplitLoading(false);
      onCancel();
    }
  };

  const updateAccountId = (account) => {
    if (typeof account?.value === 'number') {
      setLedgerAccount({ [account?.key]: account?.value });
    } else {
      const Id = account?.value?.replace('-contact', '')?.replace('-user');
      const key = account?.value?.includes('-contact')
        ? 'LedgerAccountContactId'
        : 'LedgerAccountUserId';
      setLedgerAccount({ [key]: parseInt(Id) });
    }
  };

  const calculateGCI = (rate) => {
    return (rate / 100) * comparePrice;
  };

  const calculateRate = (GCI) => {
    return convertToDecimalIfNotWhole((GCI * 100) / comparePrice);
  };

  const hasValue = () => {
    const value = splitItemField?.SplitValue;
    return (
      value !== null &&
      ((typeof value === 'number' && value !== 0) ||
        (typeof value === 'string' && value.trim() !== ''))
    );
  };

  return (
    <Row gutter={12} className={styles.dynamicSplit}>
      <Col lg={12}>
        <NameAutocomplete
          label="Split"
          allNames={autocompleteSplitNames?.map((item, idx) => ({ label: item, value: idx + 1 }))}
          value={splitItemField?.SplitTitle}
          onChange={(value) => setSplitItemField({ ...splitItemField, SplitTitle: value?.label })}
          onSelect={(value) => setSplitItemField({ ...splitItemField, SplitTitle: value?.label })}
        />
      </Col>
      <Col lg={12}>
        <CommissionField
          IsFeeTypePercentage={splitItemField?.IsSplitTypePercentage}
          update={(object) => {
            const value = object?.Value === '' ? null : parseFloat(object?.Value);
            const isToggled = object?.IsFeeTypePercentage !== splitItemField?.IsSplitTypePercentage;
            // Handle toggling between $ / % button
            if (
              isToggled ||
              (object?.IsFeeTypePercentage && value == splitItemField?.SplitPercentage) ||
              (!object?.IsFeeTypePercentage && value == splitItemField?.SplitValue)
            ) {
              const updatedObject = {
                IsSplitTypePercentage: object?.IsFeeTypePercentage,
              };
              updateSplitValue(splitItemField?.Id || null, updatedObject, true);
              setSplitItemField((prev) => ({ ...prev, ...updatedObject }));
            } else {
              const updatedObject = {
                SplitValue: value,
                IsSplitTypePercentage: object?.IsFeeTypePercentage,
              };
              const localObjectToUpdate = updatedObject?.IsSplitTypePercentage
                ? {
                    SplitPercentage: updatedObject?.SplitValue,
                    SplitValue: calculateGCI(updatedObject?.SplitValue),
                  }
                : {
                    SplitValue: updatedObject?.SplitValue,
                    SplitPercentage: calculateRate(updatedObject?.SplitValue),
                  };
              // Update the local state and trigger the external update
              setSplitItemField((prev) => ({
                ...prev,
                ...updatedObject,
                ...localObjectToUpdate,
              }));
              updateSplitValue(splitItem?.Id || null, localObjectToUpdate);
            }
          }}
          label="Amount"
          value={
            splitItemField?.IsSplitTypePercentage
              ? splitItemField?.SplitPercentage
              : splitItemField?.SplitValue
          }
        />
      </Col>
      <Col lg={24}>
        {isShowAgentFields ? (
          <>
            <p className={styles.labelSplit}>Agent</p>
            {(!splitItem || isLedgerAccountUserLoaded) && (
              <div className={styles.customAgentWrapper}>
                <AgentCoordinatorField
                  item={defaultAccountItem}
                  memberContacts={memberContacts}
                  onChange={updateAccountId}
                  isAgentInfo
                  isCoordinatorInfo={false}
                  isFinancials
                />
                <RemoveFile
                  className={styles.removeAgentIcon}
                  onClick={() => {
                    setShowAgentField(false);
                    setLedgerAccount({ LedgerAccountUserId: null, LedgerAccountContactId: null });
                  }}
                />
              </div>
            )}
          </>
        ) : (
          <Button
            variant="link"
            className={styles.addAttributeBtn}
            onClick={() => setShowAgentField(true)}
          >
            + Add Attribution
          </Button>
        )}
      </Col>
      <Col lg={12}>
        <div className={styles.buttonsContainer}>
          <Button
            variant="secondary"
            size="large"
            onClick={handleAddOrUpdate}
            loading={isAddUpdateSplitLoading}
            disabled={isAddUpdateSplitLoading || isValidate || !hasValue()}
          >
            {splitItem?.Id ? 'Update' : 'Add'}
          </Button>
          <Button variant="hollow-bordered" size="large" onClick={() => onCancel()}>
            Cancel
          </Button>
        </div>
      </Col>
      {isTransactionAdminOrOwner && (
        <Col lg={12} className={styles.checkboxCont}>
          <CheckboxComponent
            id="restricted-add"
            label="Restricted"
            checked={splitItemField?.IsRestricted}
            onChange={(e, v, checked) =>
              setSplitItemField({ ...splitItemField, IsRestricted: checked })
            }
            direction={CheckboxComponent.DIRECTION_RIGHT}
          />
        </Col>
      )}
      <Col lg={24} className={styles.errorContainer}>
        {isValidate && (
          <ErrorMessage message="The cumulative value of the splits cannot exceed the GCI value." />
        )}
      </Col>
    </Row>
  );
};
