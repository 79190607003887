import { useMemo, useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { split } from 'lodash-es';
import moment from 'moment';
import { useBackPage } from 'hooks';
import { LocalStorage, LocationService } from 'services';
import { getIfExistThread } from 'helpers';
import { ROLES } from 'tests/ui/constants/ui_const.mjs';

import { routes } from 'settings/navigation/routes';
import { SOCKET_STATE, SOCKET_THREAD_TYPES } from 'settings/constants/sockets';
import { PENDING, READY } from 'settings/constants/apiState';

import { setSharePropertyEffect } from 'store/effects';
import { socketsGetMessagesByThreadIdEffect } from 'store/effects/sockets/messages';

import { getListingDetailData, getLoadingListingData } from 'store/selectors/listingDetail';
import {
  propertTimeSpentAuditLogEffect,
  requestGetListingDetailByIdV3Effect,
} from 'store/effects/listingDetail';
import { openCommentsDrawerEffect } from 'store/effects/drawers/comments';
import { getSocketConnectionState } from 'store/selectors/sockets/status';
import { getThreadsListSelector, getThreadsSelector } from 'store/selectors/sockets/threads';
import { getUserId, getUserSelector } from 'store/selectors/user';
import { getIsMessagesDrawerOpenSelector } from 'store/selectors/drawers/messages';
import { getSelectedContextIdSelector } from 'store/selectors/context';

import { ShareDrawer } from 'pages/Properties/Feed/Drawers';
import ListingDetailContent from './components/Content';
import { PageWrapper, BackLink, Wrapper, PageNotFound, CommentsDrawer } from 'components';

import styles from './styles.module.scss';
import { setFeedCurrentPageInfoAction } from '../../../store/actions/feed';

const locationService = new LocationService();

const ListingDetail = () => {
  const location = useLocation();
  const history = useHistory();
  locationService.setLocation(location);
  const query = locationService.setLocation(location).getQuery();
  const { pathname, state: locationState } = location;
  const id = pathname.split('/').pop();
  const activeTab = query?.activeTab === 'undefined' ? 1 : query?.activeTab;
  const searchId = query?.searchId;
  const url = pathname;
  const socketStatus = useSelector(getSocketConnectionState);
  const threads = useSelector(getThreadsSelector);
  const propertyInfo = useSelector(getListingDetailData);
  const threadsList = useSelector(getThreadsListSelector);
  const messagesDrawerIsOpen = useSelector(getIsMessagesDrawerOpenSelector);
  const userId = useSelector(getUserId);
  const loggedInUser = useSelector(getUserSelector);
  const clientAgentID = useSelector(getSelectedContextIdSelector);
  const feedBackUrl = `${routes.feed}${activeTab ? `?activeTab=${activeTab}` : ''}`;
  const searchBackUrl = routes.searchResults;
  const searchQuery = LocalStorage.getSearchCriteria();

  const { backUrl, isFeed } = useMemo(() => {
    const isItFeed = `${routes.properties}/${split(url, '/')[2]}` === routes.feed;
    return {
      isFeed: isItFeed,
      backUrl: locationState?.backUrl
        ? locationState.backUrl
        : isItFeed
        ? feedBackUrl
        : searchBackUrl,
    };
  }, [url]);

  const [onBack] = useBackPage({
    defaultBackUrl: backUrl,
  });

  const onBackClickHandler = () => {
    dispatch(
      setFeedCurrentPageInfoAction({
        currentFEPageInfo: {
          pageNumber: query?.pageNumber || 0,
          renderingIndex: query?.renderingIndex ?? 0,
          fromListingDetailPage: true,
        },
      }),
    );
    onBack();
  };

  const { state, dataId } = useSelector(getLoadingListingData);
  const dispatch = useDispatch();

  const isPending = dataId !== id || state === PENDING;
  const isNotFound = dataId !== id && state === READY;

  const openMessages = useCallback(
    (threadId) => {
      dispatch(setSharePropertyEffect(propertyInfo));
      dispatch(openCommentsDrawerEffect({ open: true }));
      dispatch(socketsGetMessagesByThreadIdEffect({ Id: threadId }));
    },
    [propertyInfo],
  ); // eslint-disable-line

  useEffect(() => {
    const { threadId: queryThreadId, tourRequestedBy } = locationService.getQuery();

    let threadId = queryThreadId;

    if (!threadId) {
      const existThread = getIfExistThread(
        threadsList,
        [tourRequestedBy, userId],
        (thread) =>
          thread?.EntityId === propertyInfo?.Id && thread?.Type === SOCKET_THREAD_TYPES.LISTING,
      );
      threadId = existThread?.Id;
    }

    if (
      threadId &&
      socketStatus === SOCKET_STATE.CONNECTED &&
      Object.keys(threads || {}).length &&
      threads[threadId] &&
      threads[threadId].EntityId === propertyInfo?.Id &&
      propertyInfo &&
      !messagesDrawerIsOpen
    ) {
      openMessages(threadId);
    }
  }, [
    socketStatus,
    threads,
    openMessages,
    propertyInfo,
    location,
    threadsList,
    userId,
    messagesDrawerIsOpen,
  ]);
  const loggedPropertyTimeSpent = async (payload) => {
    try {
      await dispatch(propertTimeSpentAuditLogEffect(payload));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let startTime = moment(new Date());
    let userInfo = loggedInUser;
    let propertyDetails = propertyInfo;
    return () => {
      let endTime = moment(new Date());
      const payload = {
        PropertyId: propertyDetails?.Id,
        Address: propertyDetails?.Address,
        TimeSpent: {
          Hours: endTime.diff(startTime, 'hours'),
          Minutes: endTime.diff(startTime, 'minutes'),
          Seconds: endTime.diff(startTime, 'seconds'),
        },
        UserId: userInfo?.data?.Id,
        AgentId: userInfo?.data?.Roles.includes(ROLES.AGENT)
          ? userInfo?.data?.Agent?.Id
          : clientAgentID,
        FirstName: userInfo?.data?.FirstName,
        LastName: userInfo?.data?.LastName,
        UserEmail: userInfo?.data?.Email,
        Role: userInfo?.data?.Roles[0] || [''],
      };
      if (userInfo && propertyDetails && startTime && payload?.TimeSpent?.Seconds > 1) {
        loggedPropertyTimeSpent(payload);
        userInfo = null;
        propertyDetails = null;
      }
    };
  }, [loggedInUser, propertyInfo]);

  useEffect(() => {
    dispatch(
      requestGetListingDetailByIdV3Effect({
        id,
        ...(searchId
          ? { searchId }
          : {
              searchQuery: JSON.parse(searchQuery) ?? { Locations: [] },
            }),
      }),
    );
  }, [id, searchId, dispatch]);

  return (
    <PageWrapper className={styles.listingDetail}>
      {isNotFound ? (
        <PageNotFound btnLabel={`Back to ${isFeed ? 'Feed' : 'Search'}`} onClick={onBack} />
      ) : (
        <>
          {history.length > 1 && (
            <BackLink
              testid="property_back"
              onClick={onBackClickHandler}
              className={styles.back}
              to={backUrl}
            />
          )}
          <Wrapper className={styles.loading} isPending={isPending}>
            <ListingDetailContent propertyInfo={propertyInfo} />
          </Wrapper>
        </>
      )}
      <CommentsDrawer />
      <ShareDrawer />
    </PageWrapper>
  );
};

ListingDetail.propTypes = {};

export default ListingDetail;
