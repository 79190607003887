import moment from 'moment';
import { priceConverter } from 'helpers';

export type AreaConfigData = { day: string; value: number };

export type TransactionsSerialized = {
  serializedTransactionsStats: {
    numberOfTransactionsInDay: number;
    respectivedate: Date;
    amount: number;
  }[];
  totalTransactions: number;
  totalAmount: number;
};

export type statPoint = {
  date: string;
  count: number;
  sum: number;
};

export enum ClarityTimePeriod {
  YTD = 'Month',
  QTD = 'Week',
  MTD = 'Date',
  WEEK = 'Date',
}

export const setStats = (
  currentStats,
  filter,
  setData,
  setTotalAmount,
  setTotalRequests,
  showAmount,
  showRequests,
) => {
  if (!currentStats) return;
  const data = filter === '' ? currentStats : currentStats[filter];

  const stats =
    data.map(({ date, sum }) => ({
      day: moment(date.slice(0, 10)).format('DD MMM YYYY'),
      value: sum,
    })) || [];
  if (data.length === 1) setData([stats, stats]);
  else setData(stats);

  const latestStat = data[data.length - 1];
  if (showAmount) setTotalAmount(priceConverter(latestStat?.sum || 0, 2));
  if (showRequests) setTotalRequests(latestStat?.count || 0);
};

export const sampleData = [
  {
    date: '2024-07-01T05:00:00.000Z',
    count: 2,
    sum: 1200,
  },
  {
    date: '2024-07-02T05:00:00.000Z',
    count: 5,
    sum: 6000,
  },
  {
    date: '2024-07-03T05:00:00.000Z',
    count: 6,
    sum: 8000,
  },
  {
    date: '2024-07-04T05:00:00.000Z',
    count: 3,
    sum: 15544,
  },
  {
    date: '2024-07-05T05:00:00.000Z',
    count: 8,
    sum: 18544,
  },
  {
    date: '2024-07-06T05:00:00.000Z',
    count: 7,
    sum: 1544,
  },
  {
    date: '2024-07-07T05:00:00.000Z',
    count: 6,
    sum: 17544,
  },
  {
    date: '2024-07-08T05:00:00.000Z',
    count: 3,
    sum: 12544,
  },
  {
    date: '2024-07-09T05:00:00.000Z',
    count: 2,
    sum: 1544,
  },
  {
    date: '2024-07-10T05:00:00.000Z',
    count: 2,
    sum: 4544,
  },
  {
    date: '2024-07-11T05:00:00.000Z',
    count: 5,
    sum: 18544,
  },
  {
    date: '2024-07-12T05:00:00.000Z',
    count: 5,
    sum: 16044,
  },
];

export const sampleFeesData = {
  Agent: [
    {
      date: '2024-07-01T05:00:00.000Z',
      count: 2,
      sum: 13544,
    },
    {
      date: '2024-07-02T05:00:00.000Z',
      count: 2,
      sum: 13544,
    },
    {
      date: '2024-07-03T05:00:00.000Z',
      count: 2,
      sum: 13544,
    },
    {
      date: '2024-07-04T05:00:00.000Z',
      count: 2,
      sum: 13544,
    },
    {
      date: '2024-07-05T05:00:00.000Z',
      count: 2,
      sum: 13544,
    },
    {
      date: '2024-07-06T05:00:00.000Z',
      count: 2,
      sum: 13544,
    },
    {
      date: '2024-07-07T05:00:00.000Z',
      count: 2,
      sum: 13544,
    },
    {
      date: '2024-07-08T05:00:00.000Z',
      count: 2,
      sum: 13544,
    },
    {
      date: '2024-07-09T05:00:00.000Z',
      count: 2,
      sum: 13544,
    },
    {
      date: '2024-07-10T05:00:00.000Z',
      count: 2,
      sum: 13544,
    },
    {
      date: '2024-07-11T05:00:00.000Z',
      count: 2,
      sum: 13544,
    },
    {
      date: '2024-07-12T05:00:00.000Z',
      count: 2,
      sum: 13544,
    },
  ],
  Team: [
    {
      date: '2024-07-01T05:00:00.000Z',
      count: 2,
      sum: 13544,
    },
    {
      date: '2024-07-02T05:00:00.000Z',
      count: 2,
      sum: 13544,
    },
    {
      date: '2024-07-03T05:00:00.000Z',
      count: 2,
      sum: 13544,
    },
    {
      date: '2024-07-04T05:00:00.000Z',
      count: 16,
      sum: 13544,
    },
    {
      date: '2024-07-05T05:00:00.000Z',
      count: 2,
      sum: 13544,
    },
    {
      date: '2024-07-06T05:00:00.000Z',
      count: 11,
      sum: 1500,
    },
    {
      date: '2024-07-07T05:00:00.000Z',
      count: 2,
      sum: 13544,
    },
    {
      date: '2024-07-08T05:00:00.000Z',
      count: 8,
      sum: 13000,
    },
    {
      date: '2024-07-09T05:00:00.000Z',
      count: 10,
      sum: 11500,
    },
    {
      date: '2024-07-10T05:00:00.000Z',
      count: 2,
      sum: 13544,
    },
    {
      date: '2024-07-11T05:00:00.000Z',
      count: 2,
      sum: 13544,
    },
    {
      date: '2024-07-12T05:00:00.000Z',
      count: 8,
      sum: 30000,
    },
  ],
  Brokerage: [
    {
      date: '2024-07-01T05:00:00.000Z',
      count: 2,
      sum: 13544,
    },
    {
      date: '2024-07-02T05:00:00.000Z',
      count: 2,
      sum: 13544,
    },
    {
      date: '2024-07-03T05:00:00.000Z',
      count: 2,
      sum: 13544,
    },
    {
      date: '2024-07-04T05:00:00.000Z',
      count: 2,
      sum: 13544,
    },
    {
      date: '2024-07-05T05:00:00.000Z',
      count: 2,
      sum: 13544,
    },
    {
      date: '2024-07-06T05:00:00.000Z',
      count: 2,
      sum: 1000,
    },
    {
      date: '2024-07-07T05:00:00.000Z',
      count: 2,
      sum: 13544,
    },
    {
      date: '2024-07-08T05:00:00.000Z',
      count: 17,
      sum: 13544,
    },
    {
      date: '2024-07-09T05:00:00.000Z',
      count: 2,
      sum: 1250,
    },
    {
      date: '2024-07-10T05:00:00.000Z',
      count: 5,
      sum: 13544,
    },
    {
      date: '2024-07-11T05:00:00.000Z',
      count: 2,
      sum: 13544,
    },
    {
      date: '2024-07-12T05:00:00.000Z',
      count: 8,
      sum: 14250,
    },
  ],
};
