import styles from '../styles.module.scss';
import { FC, ReactElement, useState } from 'react';
import { IntelHeader } from './components/IntelHeader';
import { Filter } from './components/Filter';
import { RadioChangeEvent } from 'antd';
import { FeedVisibility } from '../../../types/intel';
import { useSelector } from 'react-redux';
import { getAgentTeamRoleSelector } from '../../../store/selectors/agentTeamDetail';
import { TEAM_OWNER } from '../../../settings/constants/roles';
import { ActivityFeed } from './components/ActivityFeed';
import {
  getMembersSelector,
  getTeamMembersSelector,
} from '../../../store/selectors/adminPanel/teamDetails';

export const IntelAgent: FC = (): ReactElement => {
  const [filter, setFilter] = useState<FeedVisibility>(FeedVisibility.User);
  const handleFilterChange = (event: RadioChangeEvent) => {
    setFilter(event.target.value);
  };

  const agentRole = useSelector(getAgentTeamRoleSelector);

  const teamList = useSelector(getMembersSelector);

  console.log(teamList);

  return (
    <div className={styles.pageWrapper}>
      <IntelHeader
        rightChildren={
          agentRole === TEAM_OWNER && <Filter activeValue={filter} onChange={handleFilterChange} />
        }
      >
        Intel
      </IntelHeader>
      <ActivityFeed />
    </div>
  );
};
