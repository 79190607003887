import 'react-quill/dist/quill.snow.css';
import React, { useCallback } from 'react';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import styles from './styles.module.scss';
import classNames from 'classnames';

interface QuillEditorProps extends Omit<ReactQuillProps, 'onBlur'> {
  onChange: (content: string) => void;
  onBlur?: (content: string) => void;
  value: string;
  placeholder?: string;
  label?: string;
  wrapperClass?: string;
  editorClass?: string;
  errorText?: string;
}

const defaultFormats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
];

const defaultModules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', { color: ['red', 'green', 'blue', 'violet', 'orange', 'brown'] }],
  ],
  clipboard: { matchVisual: false },
};

export const QuillEditor: React.FC<QuillEditorProps> = ({
  onChange,
  onBlur,
  value,
  placeholder,
  label,
  errorText,
  formats = defaultFormats,
  modules = defaultModules,
  theme = 'snow',
  wrapperClass = '',
  editorClass = '',
  ...rest
}) => {
  const handleChange = useCallback((v: string) => onChange?.(v), [onChange]);
  const handleBlur = useCallback(
    (_range, _source, editor) => onBlur?.(editor.getHTML() ?? ''),
    [onBlur],
  );

  return (
    <div
      className={`${styles.quillEditorWrapper} ${wrapperClass} ${errorText ? styles.error : ''}`}
    >
      {label && <div className={styles.label}>{label}</div>}
      <ReactQuill
        className={classNames(styles.editor, editorClass)}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        modules={modules}
        formats={formats}
        theme={theme}
        {...rest}
      />
      {errorText && <div className={styles.errorText}>{errorText}</div>}
    </div>
  );
};
