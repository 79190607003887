import PropTypes from 'prop-types';

export const Icons = ({ variant }) => {
  const renderIcon = () => {
    switch (variant) {
      case Icons.CLOSED: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <g clipPath="url(#clip0_51347_26088)">
              <path
                d="M14.9997 4.16406H4.99967C4.0792 4.16406 3.33301 4.91025 3.33301 5.83073V15.8307C3.33301 16.7512 4.0792 17.4974 4.99967 17.4974H14.9997C15.9201 17.4974 16.6663 16.7512 16.6663 15.8307V5.83073C16.6663 4.91025 15.9201 4.16406 14.9997 4.16406Z"
                stroke="#AAABAB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13.333 2.5V5.83333"
                stroke="#AAABAB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.66699 2.5V5.83333"
                stroke="#AAABAB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3.33301 9.16406H16.6663"
                stroke="#AAABAB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.33301 13.3359H11.6663"
                stroke="#AAABAB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_51347_26088">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }

      case Icons.CLOCK: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
              stroke="#AAABAB"
              strokeWidth="1.66667"
            />
            <path
              d="M10 5.83594L10 10.0026"
              stroke="#AAABAB"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 10L13.3333 11.6667"
              stroke="#AAABAB"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }

      case Icons.BEDS: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M3.33301 8.33594L1.16634 11.2248C0.949971 11.5133 0.833008 11.8642 0.833008 12.2248V15.0026C0.833008 15.4628 1.20611 15.8359 1.66634 15.8359H18.333C18.7932 15.8359 19.1663 15.4628 19.1663 15.0026V12.2248C19.1663 11.8642 19.0494 11.5133 18.833 11.2248L16.6663 8.33594V4.58594C16.6663 3.89558 16.1067 3.33594 15.4163 3.33594H4.58301C3.89265 3.33594 3.33301 3.89558 3.33301 4.58594V8.33594Z"
              stroke="#AAABAB"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.41699 7.5026V6.66927C5.41699 6.20903 5.79009 5.83594 6.25033 5.83594H9.16699C9.62723 5.83594 10.0003 6.20903 10.0003 6.66927V7.5026"
              stroke="#AAABAB"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 7.5026V6.66927C10 6.20903 10.3731 5.83594 10.8333 5.83594H13.75C14.2102 5.83594 14.5833 6.20903 14.5833 6.66927V7.5026"
              stroke="#AAABAB"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.33301 8.33594H16.6663"
              stroke="#AAABAB"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1.25 11.6641L18.75 11.6641"
              stroke="#AAABAB"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.5 15.8359V17.5026"
              stroke="#AAABAB"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.5 15.8359V17.5026"
              stroke="#AAABAB"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }

      case Icons.BATHS: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M2.5 11.6641V13.3307C2.5 15.1717 3.99238 16.6641 5.83333 16.6641H14.1667C16.0076 16.6641 17.5 15.1717 17.5 13.3307V11.6641"
              stroke="#AAABAB"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.0837 11.6641H2.91699C2.22664 11.6641 1.66699 11.1044 1.66699 10.4141C1.66699 9.72371 2.22663 9.16406 2.91699 9.16406H17.0837C17.774 9.16406 18.3337 9.72371 18.3337 10.4141C18.3337 11.1044 17.774 11.6641 17.0837 11.6641Z"
              stroke="#AAABAB"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.5 9.16471V4.16401C2.5 2.49734 4.16667 1.66458 5.41667 2.91431"
              stroke="#AAABAB"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.64013 6.08251C4.82654 5.26892 4.82654 3.94983 5.64013 3.13624V3.13624C6.45372 2.32264 7.77282 2.32264 8.58641 3.13624L8.88104 3.43086L5.93476 6.37714L5.64013 6.08251Z"
              stroke="#AAABAB"
              strokeWidth="1.66667"
              strokeLinejoin="round"
            />
            <path
              d="M5.83301 16.6641L4.58301 17.9141"
              stroke="#AAABAB"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.167 16.6641L15.417 17.9141"
              stroke="#AAABAB"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }

      case Icons.SIZE: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M2.55088 14.4527C2.06272 13.9646 2.06272 13.1731 2.55088 12.6849L12.6879 2.54795C13.176 2.05979 13.9675 2.05979 14.4556 2.54795L17.4498 5.54208C17.9379 6.03024 17.9379 6.82169 17.4498 7.30985L7.31278 17.4468C6.82463 17.935 6.03317 17.935 5.54501 17.4468L2.55088 14.4527Z"
              stroke="#AAABAB"
              strokeWidth="1.66667"
            />
            <path
              d="M4.16699 11.2344L5.93476 13.0021"
              stroke="#AAABAB"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.52344 8.88281L8.2912 10.6506"
              stroke="#AAABAB"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.87988 6.52344L10.6476 8.2912"
              stroke="#AAABAB"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.2373 4.16406L13.0051 5.93183"
              stroke="#AAABAB"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }

      case Icons.TYPE: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M6.66699 8.33594V13.013C6.66699 13.1914 6.81157 13.3359 6.98991 13.3359H13.0107C13.1891 13.3359 13.3337 13.1914 13.3337 13.013V8.33594"
              stroke="#AAABAB"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.41699 9.16406L9.79584 5.58137C9.91479 5.48404 10.0859 5.48404 10.2048 5.58137L14.5837 9.16406"
              stroke="#AAABAB"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
              stroke="#AAABAB"
              strokeWidth="1.66667"
            />
          </svg>
        );
      }

      case Icons.LOT_SIZE: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M16.6667 15.8359H5.83333C4.91286 15.8359 4.16667 15.0897 4.16667 14.1693V3.33594M16.6667 15.8359L15 14.1693M16.6667 15.8359L15 17.5026M4.16667 3.33594L2.5 5.0026M4.16667 3.33594L5.83333 5.0026"
              stroke="#AAABAB"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.33301 4.16927C8.33301 3.70903 8.7061 3.33594 9.16634 3.33594H15.833C16.2932 3.33594 16.6663 3.70903 16.6663 4.16927V10.8359C16.6663 11.2962 16.2932 11.6693 15.833 11.6693H9.16634C8.7061 11.6693 8.33301 11.2962 8.33301 10.8359L8.33301 4.16927Z"
              stroke="#AAABAB"
              strokeWidth="1.66667"
              strokeLinejoin="round"
            />
          </svg>
        );
      }

      case Icons.NEIGHBORHOOD: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M4.8577 3.87164C3.9556 4.6286 3.23173 5.57537 2.73778 6.64438C2.24383 7.71339 1.99197 8.87827 2.00019 10.0558C2.00842 11.2334 2.27651 12.3947 2.78534 13.4567C3.29417 14.5187 4.03118 15.4553 4.94376 16.1996"
              stroke="#AAABAB"
              strokeWidth="1.67"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.10746 6.5528C6.56345 7.00927 6.1352 7.58802 5.85773 8.24171C5.58025 8.8954 5.46142 9.60549 5.51096 10.3139C5.5605 11.0223 5.77699 11.709 6.14275 12.3177C6.5085 12.9264 7.01312 13.4399 7.61536 13.8162"
              stroke="#AAABAB"
              strokeWidth="1.67"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.1423 3.87164C16.0444 4.6286 16.7683 5.57537 17.2622 6.64438C17.7562 7.71339 18.008 8.87827 17.9998 10.0558C17.9916 11.2334 17.7235 12.3947 17.2147 13.4567C16.7058 14.5187 15.9688 15.4553 15.0562 16.1996"
              stroke="#AAABAB"
              strokeWidth="1.67"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.182 6.81802C13.5998 7.23588 13.9313 7.73196 14.1575 8.27792C14.3836 8.82389 14.5 9.40905 14.5 10C14.5 10.5909 14.3836 11.1761 14.1575 11.7221C13.9313 12.268 13.5998 12.7641 13.182 13.182"
              stroke="#AAABAB"
              strokeWidth="1.67"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <circle cx="10" cy="10" r="2" fill="#AAABAB" />
          </svg>
        );
      }

      case Icons.MAP: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
          >
            <path
              d="M14.5 19.8868L19.5 17.8893V5.9043L14.5 7.9018M14.5 19.8868L9.5 17.8893M14.5 19.8868V7.9018M9.5 17.8893L4.5 19.8868V7.9018L9.5 5.9043M9.5 17.8893V5.9043M9.5 5.9043L14.5 7.9018"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }

      case Icons.LIST: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path d="M18 7H9" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
            <path d="M18 12H9" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
            <path d="M18 17H9" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
            <circle cx="5" cy="7" r="1" fill="#515151" />
            <circle cx="5" cy="12" r="1" fill="#515151" />
            <circle cx="5" cy="17" r="1" fill="#515151" />
          </svg>
        );
      }

      case Icons.STATUS_ARROWS: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M6 11.5V4M6 4L3 7M6 4L9 7"
              stroke="#FB913A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14 7.5V15M14 15L11 12M14 15L17 12"
              stroke="#FB913A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }

      case Icons.MARKER: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <ellipse cx="8" cy="7.33301" rx="2" ry="2" stroke="#AAABAB" strokeWidth="2" />
            <path
              d="M13.3337 7.33333C13.3337 10.1744 10.6432 12.8472 9.08408 14.1576C8.45004 14.6905 7.55061 14.6905 6.91657 14.1576C5.35748 12.8472 2.66699 10.1744 2.66699 7.33333C2.66699 4.38781 5.05481 2 8.00032 2C10.9458 2 13.3337 4.38781 13.3337 7.33333Z"
              stroke="#AAABAB"
              strokeWidth="2"
            />
          </svg>
        );
      }

      default:
        return null;
    }
  };
  return <>{renderIcon()}</>;
};

Icons.CLOSED = 'closed';
Icons.CLOCK = 'clock';
Icons.BEDS = 'beds';
Icons.BATHS = 'baths';
Icons.SIZE = 'size';
Icons.TYPE = 'type';
Icons.LOT_SIZE = 'lotSize';
Icons.NEIGHBORHOOD = 'neighborhood';
Icons.LIST = 'list';
Icons.MAP = 'map';
Icons.STATUS_ARROWS = 'statusArrows';
Icons.MARKER = 'marker';

Icons.propTypes = {
  variant: PropTypes.string,
};

Icons.defaultProps = {
  variant: '',
};
