import { Wrapper } from 'components';
import { TemplateHeader } from '../components/TemplateHeader';
import { useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';
import { NoTemplatesFound } from '../components/NoTemplates';
import { ClausesList, EmptyClausePlaceholder } from './components';
import { Tabs } from 'components-antd';
import { useDispatch, useSelector } from 'react-redux';
import { getClauseTemplatesSelector } from 'store/selectors/templates';
import { getAllClausesEffect } from 'store/effects/templates';
import { ClausesTabsPanes, getDefaultTab, getFilteredClauses } from './helper';
import classNames from 'classnames';

type ClausesStateType = {
  clausesTabKey?: string;
};

export const Clauses = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [tabKey, setTabKey] = useState<string>('');

  const dispatch = useDispatch();
  const history = useHistory();

  const { state: formState } = useLocation<ClausesStateType>();
  const clauseState = useMemo(() => formState, []);

  const { isPending: isClauseTemplatePending, data: clauses } = useSelector(
    getClauseTemplatesSelector,
  );

  useEffect(() => {
    dispatch(getAllClausesEffect());
  }, []);

  useEffect(() => {
    if (clauses?.length) {
      const defaultTab = getDefaultTab(clauses, clauseState?.clausesTabKey);

      if (clauseState?.clausesTabKey) {
        history.replace(location.pathname);
      }

      setTabKey(defaultTab);
    }
  }, [clauses]);

  const onNewClause = () => {
    history.push(routes.templatesClauseCreate);
  };

  const tabItems = () => {
    const myClauses = getFilteredClauses(clauses);
    const teamClauses = getFilteredClauses(clauses, true);

    return [
      ...(myClauses.length
        ? [
            {
              key: ClausesTabsPanes.MyClauses,
              label: ClausesTabsPanes.MyClauses,
              children: (
                <ClausesList
                  activeKey={ClausesTabsPanes.MyClauses}
                  clausesList={myClauses}
                  clauses={clauses}
                  searchTerm={searchTerm}
                />
              ),
            },
          ]
        : []),

      ...(teamClauses.length
        ? [
            {
              key: ClausesTabsPanes.TeamClauses,
              label: ClausesTabsPanes.TeamClauses,
              children: (
                <ClausesList
                  activeKey={ClausesTabsPanes.TeamClauses}
                  clausesList={teamClauses}
                  clauses={clauses}
                  searchTerm={searchTerm}
                  showTeamClauses={true}
                />
              ),
            },
          ]
        : []),
    ];
  };

  return (
    <Wrapper isPending={isClauseTemplatePending}>
      <TemplateHeader
        label="Clauses"
        onNew={onNewClause}
        onSearch={clauses?.length ? (val) => setSearchTerm(val) : undefined}
        onClear={() => setSearchTerm('')}
        value={searchTerm}
        className={styles.clausesTemplateHeader}
        canAddNewTemplate={true}
      />

      {clauses?.length ? (
        <Tabs
          activeKey={tabKey}
          onTabClick={(key) => {
            searchTerm && setSearchTerm('');
            setTabKey(key);
          }}
          className={styles.clausesTabsPane}
          items={tabItems()}
        />
      ) : (
        <NoTemplatesFound
          className={classNames(styles.noTemplates, styles.clausesEmptyTemplates)}
          placeholderText="You don't have any clauses"
          placeholderIcon={<EmptyClausePlaceholder />}
        />
      )}
    </Wrapper>
  );
};
