import { flatMap } from 'lodash-es';
import { getPrefLabelOptionById, getPrefLabelOption } from './preferences';
import { MATCH_LEVEL } from 'settings/constants/common';

export const matchLevel = {
  NONE: 'NONE',
  FULL: 'FULL',
  PARTIAL: 'PARTIAL',
};

export function getMatchedCriteriasList(prop) {
  const showedLevels = [matchLevel.PARTIAL, matchLevel.FULL];

  const matchScores = flatMap(Object.entries(prop?.MatchedCriterias || {}), ([key, value]) => {
    if (Array.isArray(value)) {
      return value
        .filter((item) => showedLevels.includes(item?.MatchLevel))
        .map(({ Feature }) => getPrefLabelOptionById(Feature) || getPrefLabelOptionById(key));
    }
    if (showedLevels.includes(value?.MatchLevel)) {
      return getPrefLabelOptionById(value?.Feature) || getPrefLabelOptionById(key);
    }
  });

  return matchScores.filter((score) => score);
}

export function getMatchedCriteriasFilteredList(prop) {
  const showedLevels = [
    MATCH_LEVEL.PARTIAL,
    MATCH_LEVEL.FULL,
    MATCH_LEVEL.NONE,
    MATCH_LEVEL.UNKNOWN,
  ];

  const matchScores = flatMap(Object.entries(prop?.MatchedCriterias || {}), ([key, value]) => {
    if (Array.isArray(value)) {
      return value
        .filter((item) => showedLevels.includes(item?.MatchLevel))
        .map(({ Feature, Keyword, Name, MatchLevel }) => {
          return (
            getPrefLabelOption(Feature || Keyword || Name, MatchLevel) ||
            getPrefLabelOption(key, MatchLevel)
          );
        });
    }
    if (showedLevels.includes(value?.MatchLevel)) {
      return (
        getPrefLabelOption(value?.Feature, value?.MatchLevel) ||
        getPrefLabelOption(key, value?.MatchLevel)
      );
    }
  });

  return matchScores.filter((score) => score);
}
