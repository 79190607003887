import { SVGAttributes } from 'react';

export const PriceReductionIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M4 7L8 11L11 8L17 14M17 14H13M17 14V10"
        stroke="#04A451"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
