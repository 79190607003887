import * as Yup from 'yup';

export const ValidationSchema = Yup.object()
  .shape({
    priceMin: Yup.number(),
    priceMax: Yup.number(),
  })
  .test('is-valid-range', 'Invalid range', function (value) {
    const { priceMin: Min, priceMax: Max } = value;
    if ((Min || Min === 0) && (Max || Max === 0) ? (Min < Max && Max > Min) || Min === Max : true) {
      return true;
    } else {
      return this.createError({
        path: 'priceMax',
        message: 'Invalid range',
      });
    }
  });
