import { Moment } from 'moment';
import React, { useState } from 'react';

import { Button, Col, Option, Popover, Row, MultiSelect } from 'components-antd';
import { DateRangePicker } from 'components/Form/DateRangePicker';
import { TagsType } from 'types';

import { Icons } from '../../../../../Icons';

import styles from './styles.module.scss';
import { TransactionFormsStatus } from 'app-constants';

interface FilterPopoverProps {
  filterIcon: React.ReactElement;
  onDone: (filterObj: any) => void;
  onReset: (filterObj: any) => void;

  open: boolean;
  setOpen: (value) => void;
}

export const FilterPopover = ({
  filterIcon,
  onDone,
  onReset,
  open,
  setOpen,
}: FilterPopoverProps) => {
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const [publishByIds, setPublishedByIds] = useState<string[]>([]);

  const [createdFrom, setCreatedFrom] = useState<Moment>();
  const [createdTo, setCreatedTo] = useState<Moment>();

  const [completedFrom, setCompletedFrom] = useState<Moment>();
  const [completedTo, setCompletedTo] = useState<Moment>();

  const [resetDates, setResetDates] = useState(false);

  const submitHandler = (e?) => {
    e.stopPropagation();

    if (!selectedStatuses.length) {
      resetHandler(e);
    } else {
      onDone({
        filters: {
          statuses: selectedStatuses,
        },
      });
      setOpen(false);
    }
  };

  const resetHandler = (e) => {
    e.stopPropagation();
    setOpen(false);
    setSelectedStatuses([]);

    onReset({
      filters: {
        statuses: '',
      },
    });
  };

  const handleOpenChange = (newOpen: boolean) => setOpen(newOpen);

  const renderStatusOptions = () => {
    return Object.keys(TransactionFormsStatus).map((value) => (
      <Option value={value} key={`tag-${value}`}>
        {TransactionFormsStatus[value]}
      </Option>
    ));
  };

  const renderFields = () => (
    <>
      <Row>
        <Col span={24} className={styles.header}>
          <Icons variant={'filterRed'} className={styles.icon} />
          <span className={styles.title}>Filters</span>
        </Col>
      </Row>

      <Row className={styles.container} justify="start" align="stretch">
        <Col span={24}>
          <MultiSelect
            size="large"
            title={'Status'}
            placeholderText="All"
            variant="none"
            onHandleChange={(values) => setSelectedStatuses(values)}
            propClass={styles.multiSelect}
            optionFilterProp="children"
            wrapperClass={styles.multiSelectWrapper}
            values={selectedStatuses}
          >
            {renderStatusOptions()}
          </MultiSelect>
        </Col>
      </Row>

      <Row justify="space-between" align="middle" className={styles.footer}>
        <Col span={6}>
          <Button
            variant="default"
            className={styles.resetButton}
            title="Reset"
            onClick={resetHandler}
          >
            <span className={styles.btnText}>Reset</span>
          </Button>
        </Col>

        <Col span={6}>
          <Button
            variant="secondary"
            className={styles.submitButton}
            title="Done"
            onClick={submitHandler}
          >
            <span className={styles.btnText}>Done</span>
          </Button>
        </Col>
      </Row>
    </>
  );

  return (
    <Popover
      content={renderFields}
      trigger="click"
      placement="bottomRight"
      open={open}
      onOpenChange={handleOpenChange}
      overlayClassName={styles.filesFilterPopover}
      getPopupContainer={(triggerNode) => triggerNode}
    >
      {filterIcon}
    </Popover>
  );
};
