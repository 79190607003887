import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash-es';

import { getSearchCommutesSelector } from 'store/selectors/search';
import {
  getSearchDrawerCommuteIdSelector,
  getSearchResultsFilterDrawerModeSelector,
} from 'store/selectors/searchResults';
import { setSearchCriteriaEffect, setSearchDrawerCommuteIdEffect } from 'store/effects';

import { Locations, InputLabel, Button } from 'components';
import { Input, Switch } from 'components-antd';
import {
  DrawerPaddingWrapper,
  ScrollDrawerWrapper,
  Footer,
} from 'pages/Properties/SearchResults/components';
import { Select } from 'components-antd';

import { getLocationContext } from 'helpers/locations';

import { commuteType } from 'settings/constants/commuteType';
import { POSTAL_CODE } from 'settings/constants/locations';

import styles from './styles.module.scss';
import { SEARCH_CRITERIA_IMPORTANCE } from 'settings/constants/searchCriterias';

const CommuteForm = ({ className, search }) => {
  const commuteId = useSelector(getSearchDrawerCommuteIdSelector);
  const searchCommutes = useSelector(getSearchCommutesSelector);
  const mode = useSelector(getSearchResultsFilterDrawerModeSelector);

  const dispatch = useDispatch();

  const [isPending, setIsPending] = useState(false);
  const [commutes, setCommutes] = useState({
    ImportanceAndWeight: {
      Importance: SEARCH_CRITERIA_IMPORTANCE.UNDEFINED,
    },
  });

  useEffect(() => {
    if (searchCommutes?.[commuteId]) setCommutes(searchCommutes?.[commuteId]);
  }, [searchCommutes, commuteId]);

  const [isDisabled, setIsDisabled] = useState(true);
  const clonedSearchCommutes = cloneDeep(searchCommutes);

  const onChangeLocation = (result) => {
    const { deleteItemId, coordinates, placeName, result: locations } = result;
    const zip = getLocationContext(locations?.[0], POSTAL_CODE);

    const cfg = !deleteItemId
      ? {
          PlaceName: placeName,
          Geolocation: {
            Long: coordinates.lng,
            Lat: coordinates.lat,
          },
          Zip: zip,
        }
      : undefined;

    setCommutes({ ...commutes, DestinationAddress: cfg });
  };

  useEffect(() => {
    if (commuteId === commuteType.NEW_PROXIMITY) {
      setCommutes({ ...commutes, IsImportantLocation: true });
    } else if (commuteId === commuteType.NEW_COMMUTE) {
      setCommutes({ IsImportantLocation: false });
    }
  }, [commuteId]);

  useEffect(() => {
    const { DestinationAddress, MaxCommuteTimeInMinutes, Name, TransportationModePreference } =
      commutes;
    setIsDisabled(
      !(
        DestinationAddress &&
        MaxCommuteTimeInMinutes &&
        Name?.length &&
        TransportationModePreference?.length
      ),
    );
  }, [commutes]);

  const getFormattedData = (cfg) => {
    return {
      ...search,
      CommutePrefs: cfg?.map((commute) => ({
        ...commute,
      })),
    };
  };

  const onSubmit = () => {
    setIsPending(true);

    if (clonedSearchCommutes?.[commuteId]) {
      clonedSearchCommutes[commuteId] = commutes;
    } else {
      clonedSearchCommutes.push(commutes);
    }

    dispatch(setSearchCriteriaEffect(getFormattedData(clonedSearchCommutes)));
    setIsPending(false);
    dispatch(setSearchDrawerCommuteIdEffect(null));
  };
  if (!commuteId && commuteId !== 0) {
    return null;
  }

  return (
    <>
      <ScrollDrawerWrapper className={classNames(styles.scrollWrapper, className)}>
        <DrawerPaddingWrapper>
          <div className={styles.fieldWrap}>
            <Locations
              label="Location"
              multiple={false}
              className={styles.field}
              valuesWrapperClassName={styles.locationsValues}
              onResult={onChangeLocation}
              value={
                commutes?.DestinationAddress?.PlaceName
                  ? [
                      {
                        ProviderPlaceId: 1,
                        PlaceName: commutes?.DestinationAddress?.PlaceName,
                      },
                    ]
                  : []
              }
              testid="address"
              showPrefixIcon={true}
              activeInputClassName={styles.locationsSearchInput}
              placeholderClassName={styles.locationsPlaceholder}
              variant={Locations.SQUARE}
            />
          </div>

          <div className={styles.fieldWrap}>
            <InputLabel label="Preference" />
            <div className={styles.preferenceWrapper}>
              <span className={styles.innerLabel}>Within:</span>
              <Input
                name="minutesInput"
                value={commutes?.MaxCommuteTimeInMinutes || ''}
                onChange={(e) => {
                  setCommutes({ ...commutes, MaxCommuteTimeInMinutes: parseFloat(e.target.value) });
                }}
                placeholder="0"
                inputClassName={styles.inputMaxBorderBottom}
                className={styles.inputFieldMinutes}
                maxLength={'9'}
                type={'number'}
              />
              <Select
                options={CommuteForm.unitTypes}
                // onSelect={(target, val) => setType(val)} // TODO: Make this functional
                placeholder="minutes"
                value="minutes"
                className={styles.timeSelectWrapper}
                disabled={isPending}
                popupClassName="mosaikSelectDropdown"
              />
            </div>
          </div>

          <div className={styles.fieldWrap}>
            <InputLabel label="Transportation Method" />
            <Select
              className={styles.transportOption}
              options={CommuteForm.transportationMethods}
              disabled={isPending}
              testid="transport_option"
              placeholder="Car"
              value={commutes?.TransportationModePreference}
              onChange={(val) => setCommutes({ ...commutes, TransportationModePreference: [val] })}
              popupClassName="mosaikSelectDropdown"
            />
          </div>

          <div className={styles.fieldWrap}>
            <InputLabel label="Label" />
            <Input
              name="Name"
              className={styles.labelField}
              variant={Input.LIGHT_FULL}
              placeholder="e.g. Work, school, daycare, etc"
              label="Label"
              value={commutes?.Name || ''}
              onChange={(e) => {
                setCommutes({ ...commutes, Name: e.target.value });
              }}
              testid="name"
              maxLength={'50'}
            />
          </div>

          <div className={classNames(styles.fieldWrap, styles.rushFieldWrap)}>
            <InputLabel className={styles.rushFieldLabel} label="Account for rush hour?" />
            <Switch
              checked={commutes?.IsRushHour}
              onChange={(checked) => setCommutes({ ...commutes, IsRushHour: checked })}
              disabled={isPending}
              testid="rush_hour"
              className={styles.rushField}
            />
          </div>
        </DrawerPaddingWrapper>
      </ScrollDrawerWrapper>
      <Footer>
        <Button
          testid="commute_done"
          isPending={isPending}
          onClick={onSubmit}
          className={styles.submitButton}
          disabled={isDisabled}
          title="Save"
        />
      </Footer>
    </>
  );
};

CommuteForm.transportationMethods = [
  { id: 1, value: 'Car', label: 'Car' },
  { id: 2, value: 'PublicTransit', label: 'Public Transit' },
  { id: 3, value: 'Bicycle', label: 'Bicycle' },
  { id: 4, value: 'Walking', label: 'Walking' },
];

CommuteForm.unitTypes = [
  { id: 1, name: 'Minutes', value: 'minutes' },
  { id: 2, name: 'Miles', value: 'miles' },
];

CommuteForm.propTypes = {
  className: PropTypes.string,
};

CommuteForm.defaultProps = {
  className: '',
};

export default CommuteForm;
