import { useDispatch, useSelector } from 'react-redux';

import { useFormik } from 'formik';

import { prepareData } from './helpers';
import { ValidationSchema } from './validation';

import { getClientDefaultSearchSelector } from 'store/selectors/user';
import { onBoardingSignUpSearchEffect } from 'store/effects';
import { addSearchInstance } from 'store/effects/clientInstances';

import { MinMax, Input } from 'components';

import styles from './styles.module.scss';
import { useDebounce } from 'hooks/use-debounce';
import { useEffect } from 'react';

const BedsAndBathsForm = (props) => {
  const { stageIndex } = props;
  const dispatch = useDispatch();
  const defaultClientSearch = useSelector(getClientDefaultSearchSelector);
  const { NumBedroomsRange } = defaultClientSearch || {};

  const formik = useFormik({
    initialValues: {
      NumBedroomsRange: {
        Min: NumBedroomsRange?.Min || '',
        Max: NumBedroomsRange?.Max || '',
      },
    },
    validationSchema: ValidationSchema,
    onSubmit(values) {
      const cfg = prepareData(values, stageIndex, defaultClientSearch);
      dispatch(
        onBoardingSignUpSearchEffect(cfg, {}, (err) => {
          if (!err) {
            dispatch(addSearchInstance({ data: cfg.DefaultPropertySearchPreferences }));
          }
        }),
      );
    },
  });

  const debouncedMin = useDebounce(formik.values?.NumBedroomsRange?.Min);
  const debouncedMax = useDebounce(formik.values?.NumBedroomsRange?.Max);

  useEffect(() => {
    if (formik.dirty) {
      formik.handleSubmit();
    }
  }, [debouncedMin, debouncedMax]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <MinMax
        className={styles.field}
        label=""
        nameMin="NumBedroomsRange.Min"
        nameMax="NumBedroomsRange.Max"
        onChangeMin={formik.handleChange}
        onChangeMax={formik.handleChange}
        valueMin={formik.values?.NumBedroomsRange?.Min}
        valueMax={formik.values?.NumBedroomsRange?.Max}
        placeholderMin="No min"
        placeholderMax="No max"
        variant={Input.LIGHT_ROUND}
        separatorClassName={styles.to}
        error={formik.errors.NumBedroomsRange}
      />
    </form>
  );
};

export default BedsAndBathsForm;
