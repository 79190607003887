import React, { FC, ReactElement, useCallback, useMemo, useState } from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { GalleryIcon } from '../Icons';
import PhotosViewModal from '../../../../../components/Messages/MessagesView/PhotosViewModal';

const useImageGrid = (images: string[]) => {
  const displayedImages = useMemo(() => images.slice(0, 4), [images]);

  const [rows, columns] = useMemo(
    () => [
      displayedImages.length < 3 ? '100%' : 'calc(50% - 2px) calc(50% - 2px)',
      displayedImages.length === 1 ? '100%' : 'calc(50% - 2px) calc(50% - 2px)',
    ],
    [displayedImages],
  );

  const getGridRow = useCallback(
    (index: number, imageCount: number) =>
      ({
        [1]: '1 / 2',
        [2]: '1 / 2',
        [3]: index === 0 ? '1 / 3' : index === 1 ? '1 / 2' : '2 / 3',
        [4]: index < 2 ? '1 / 2' : '2 / 3',
      }[imageCount]),
    [],
  );

  const getGridColumn = useCallback(
    (index: number, imageCount: number) =>
      ({
        [1]: '1 / 2',
        [2]: index === 0 ? '1 / 2' : '2 / 3',
        [3]: index === 0 ? '1 / 2' : '2 / 3',
        [4]: index % 2 === 0 ? '1 / 2' : '2 / 3',
      }[imageCount]),
    [],
  );

  const getRoundedClass = useCallback(
    (index, imageCount) =>
      classnames({
        [styles.rounded]: imageCount === 1,
        [styles.roundedLeft]:
          (imageCount === 2 && index === 0) || (imageCount === 3 && index === 0),
        [styles.roundedRight]: imageCount === 2 && index === 1,
        [styles.roundedTopLeft]: imageCount === 4 && index === 0,
        [styles.roundedTopRight]:
          (imageCount === 3 && index === 1) || (imageCount === 4 && index === 1),
        [styles.roundedBottomLeft]: imageCount === 4 && index === 2,
        [styles.roundedBottomRight]:
          (imageCount === 3 && index === 2) || (imageCount === 4 && index === 3),
      }),
    [],
  );

  return {
    displayedImages,
    rows,
    columns,
    getGridRow,
    getGridColumn,
    getRoundedClass,
  };
};

export interface ImageGridProps {
  images: string[];
}

export const ImageGrid: FC<ImageGridProps> = (props: ImageGridProps): ReactElement => {
  const { images } = props;

  const { displayedImages, rows, columns, getGridRow, getGridColumn, getRoundedClass } =
    useImageGrid(images);

  const [showMore, setShowMore] = useState<boolean>(false);

  return (
    <div
      className={styles.imageGrid}
      style={{
        gridTemplateRows: rows,
        gridTemplateColumns: columns,
      }}
    >
      {displayedImages.map((imgSrc, index) => (
        <div
          key={index}
          className={classnames(styles.gridItem, getRoundedClass(index, displayedImages.length))}
          style={{
            gridRow: getGridRow(index, displayedImages.length),
            gridColumn: getGridColumn(index, displayedImages.length),
          }}
        >
          <img className={styles.image} src={imgSrc} alt={`image-${index}`} />
        </div>
      ))}

      {images.length > 4 && (
        <>
          <div className={styles.showMore} onClick={() => setShowMore(true)}>
            <GalleryIcon />
            <span className={styles.length}>{images.length}</span>
          </div>
          <PhotosViewModal
            isOpen={showMore}
            onClose={() => setShowMore(false)}
            photos={images.map((image) => ({ Url: image }))}
            imageId={0}
          />
        </>
      )}
    </div>
  );
};
