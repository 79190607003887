import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestGetInvoiceDataOverviewEffect } from 'store/effects/subscription';
import { getInvoiceDataOverviewSelector } from 'store/selectors/subscription';

export const usePollInvoiceData = () => {
  const dispatch = useDispatch();
  const invoiceDataOverview = useSelector(getInvoiceDataOverviewSelector);
  const intervalIdRef = useRef<any>(null);

  const pollInvoiceData = () => {
    let counter = 0;
    const nextBillingAmout = invoiceDataOverview?.NextInvoice?.Amount;
    intervalIdRef.current = setInterval(() => {
      if (counter >= 3) {
        clearInterval(intervalIdRef.current);
        return;
      }
      counter += 1;
      dispatch(
        requestGetInvoiceDataOverviewEffect(
          (err, res) => {
            if (err) {
              clearInterval(intervalIdRef.current);
              return;
            }
            if (res?.data?.result?.NextInvoice?.Amount !== nextBillingAmout) {
              clearInterval(intervalIdRef.current);
            }
          },
          { showError: false, silent: true },
        ),
      );
    }, 2000);
  };

  return { pollInvoiceData };
};
