import { useEffect, useRef, useState } from 'react';
import { Row, Col, Checkbox, Select, Dropdown, Menu, MenuItem } from 'components-antd';

import { EditAvailability, EditNotify } from '../components';
import { Navigation } from '../../components';
import { TextIconButton } from 'components';
import Icon from 'pages/Workshop/Transactions/TransactionCreate/Icons';

import styles from './styles.module.scss';
import { Icons } from '../../Icons';
import classNames from 'classnames';
import { ArrowDown, Check } from 'components/Icons';
import { cloneDeep } from 'lodash-es';

const RequiredOptionalFields = [
  {
    title: 'Required',
    key: 'required',
  },
  {
    title: 'Optional',
    key: 'optional',
  },
];

const initialState = {
  preApproval: {
    selected: '',
  },
  otherDocument: {
    selected: '',
  },
};

export const Documents = ({
  onNext,
  stageIndex,
  onPrev,
  onUpdate,
  onFinish,
  setCurrentStageIndex,
  data,
  transaction,
  stagesStep,
}) => {
  const { RequireDocumentPreApproval, OtherRequiredDocuments } = data;
  const [state, setState] = useState({
    RequireDocumentPreApproval,
    OtherRequiredDocuments,
  });
  const [addAnotherDocument, setAddAnotherDocument] = useState(false);
  const [isDocumentRequired, setIsDocumentRequired] = useState(false);
  const [requiredDocuments, setRequiredDocuments] = useState(initialState);
  const [document, setDocument] = useState([]);
  const onChange = (data) => {
    setState({ ...state, ...data });
  };

  const onDocumentsChange = (document, isDocumentRequired) => {
    setState({
      ...state,
      OtherRequiredDocuments: [
        ...state.OtherRequiredDocuments,
        {
          name: document,
          required: isDocumentRequired,
          format: '*',
        },
      ],
    });
    setIsDocumentRequired(false);
  };

  const onSubmit = () => {
    const body = { ...state };
    if (data.Id) {
      onUpdate(body);
      onFinish(body);
      setCurrentStageIndex(stagesStep.EnableLink);
    } else {
      onUpdate(body);
      onNext();
    }
  };

  const bottomRef: any = useRef(null);
  const documentRef: any = useRef(null);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [stageIndex]);

  useEffect(() => {
    if (addAnotherDocument) {
      documentRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [addAnotherDocument]);

  const handleAddAnotherDocument = () => {
    setAddAnotherDocument(true);
  };

  const onDelete = () => {
    setAddAnotherDocument(false);
  };

  const menu = (d, index = 0) => (
    <Menu
      className={styles.menuDropdown}
      onClick={(e) => onFieldChange(d, e.key === 'required', index)}
    >
      {RequiredOptionalFields.map(({ key, title }) => {
        const required =
          d === 'preApproval'
            ? state.RequireDocumentPreApproval
            : state.OtherRequiredDocuments[index]?.required;
        const requiredKey = key === 'required';

        return (
          <Menu.Item key={key} className={styles.menuItem}>
            <span className={styles.menuItemSpan}>
              {title}
              {((required && requiredKey) || (!required && key === 'optional')) && (
                <Check color="#00B152" />
              )}
            </span>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const onFieldChange = (field, value, index) => {
    const documents = cloneDeep({ ...state });
    if (field === 'preApproval') {
      documents['RequireDocumentPreApproval'] = value;
    } else {
      documents['OtherRequiredDocuments'][index][field] = value;
      if (field === 'selected') {
        documents['OtherRequiredDocuments'][index]['required'] = value;
      } else if (field === 'required') {
        documents['OtherRequiredDocuments'][index]['selected'] = value;
      }
    }

    setState({
      ...documents,
    });
  };

  return (
    <div className={styles.documents}>
      <div className={styles.contentWrap}>
        <EditAvailability
          data={data}
          onEdit={() => setCurrentStageIndex(stagesStep.Availability)}
        />
        <EditNotify data={data} onEdit={() => setCurrentStageIndex(stagesStep.Notify)} />
        <div ref={bottomRef} className={styles.content}>
          <h4 className={styles.title}>
            <Icons className={styles.docsIcon} variant="document" /> What documents should be
            submitted?
          </h4>
          <div className={styles.checkboxWrapper}>
            <Row className={styles.container}>
              <div className={styles.checkboxContainer}>
                <Col span={18} className={styles.checkBox}>
                  <Checkbox
                    checked={state.RequireDocumentPreApproval}
                    onChange={(e) => onChange({ RequireDocumentPreApproval: e.target.checked })}
                    defaultChecked={state.RequireDocumentPreApproval}
                    className="mosaikCheckbox"
                  >
                    <div className={styles.checkboxTitle}>
                      <Icon variant="documentLight" className={styles.documentIcon} />
                      Pre-Approval Letter
                    </div>
                  </Checkbox>
                </Col>
                <div>
                  <span className={styles.requiredOptions}>
                    <Dropdown overlay={menu('preApproval')} placement="bottom" trigger={['click']}>
                      <a
                        onClick={(e) => e.preventDefault()}
                        className={classNames(
                          styles.filterButton,
                          state.RequireDocumentPreApproval
                            ? styles.requiredButton
                            : styles.optionalButton,
                        )}
                      >
                        <p>{state.RequireDocumentPreApproval ? 'Required' : 'Optional'}</p>
                        <ArrowDown className={classNames(styles.icon, { [styles.open]: open })} />
                      </a>
                    </Dropdown>
                  </span>
                </div>
              </div>
            </Row>
            {state.OtherRequiredDocuments.map((d, index) => (
              <div key={index} className={styles.documentRow}>
                <Row className={styles.container}>
                  <div className={styles.checkboxContainer}>
                    <Col span={18} className={styles.checkBox}>
                      <Checkbox
                        className="mosaikCheckbox"
                        checked={d.selected}
                        onChange={(e) => onFieldChange('selected', e.target.checked, index)}
                        defaultChecked={d.selected}
                      >
                        <div className={styles.checkboxTitle}>
                          <Icon variant="documentLight" className={styles.documentIcon} />
                          <div className={styles.documentInputContainer}>{d.name}</div>
                        </div>
                      </Checkbox>
                    </Col>
                    <div>
                      <span className={styles.requiredOptions}>
                        <Dropdown
                          overlay={menu('required', index)}
                          placement="bottom"
                          trigger={['click']}
                        >
                          <a
                            onClick={(e) => e.preventDefault()}
                            className={classNames(
                              styles.filterButton,
                              d?.required ? styles.requiredButton : styles.optionalButton,
                            )}
                          >
                            <p>{d?.required ? 'Required' : 'Optional'}</p>
                            <ArrowDown
                              className={classNames(styles.icon, { [styles.open]: open })}
                            />
                          </a>
                        </Dropdown>
                      </span>
                    </div>
                  </div>
                </Row>
              </div>
            ))}

            <Row className={styles.container}>
              <Col span={24}>
                {addAnotherDocument ? (
                  <div className={styles.addAnotherWrapper}>
                    <div ref={documentRef} className={styles.addDocumentNameSection}>
                      <Checkbox
                        className="mosaikCheckbox"
                        onChange={() => {
                          setIsDocumentRequired((val) => !val);
                        }}
                        checked={isDocumentRequired}
                      />
                      <Select
                        mode="tags"
                        className={styles.selector}
                        onSelect={(v) => {
                          setDocument([]);
                          onDocumentsChange(v, isDocumentRequired);
                          setAddAnotherDocument(false);
                        }}
                        value={document}
                        placeholder="Enter a document"
                      />
                      <Icon
                        onClick={() => onDelete()}
                        className={styles.iconDelete}
                        variant={Icon.MINUS}
                        testid="close_icon"
                      />
                    </div>
                    <span className={styles.requiredOptions}>
                      <Dropdown
                        overlay={menu('otherDocument')}
                        placement="bottom"
                        trigger={['click']}
                      >
                        <a
                          onClick={(e) => e.preventDefault()}
                          className={classNames(
                            styles.filterButton,
                            requiredDocuments.otherDocument.selected === 'required'
                              ? styles.requiredButton
                              : styles.optionalButton,
                          )}
                        >
                          <p>
                            {requiredDocuments.otherDocument.selected === 'required'
                              ? 'Required'
                              : 'Optional'}
                          </p>
                          <ArrowDown className={classNames(styles.icon, { [styles.open]: open })} />
                        </a>
                      </Dropdown>
                    </span>
                  </div>
                ) : (
                  <TextIconButton text="Add Document" onClick={handleAddAnotherDocument} />
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Navigation
        data={data}
        onNext={onSubmit}
        stageIndex={stageIndex}
        onPrev={onPrev}
        onFinish={onFinish}
        className={styles.footer}
        showBackButton={false}
      />
    </div>
  );
};
