import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Continue } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/Continue';

import styles from './styles.module.scss';
import { AnswersContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/AnswersContainer';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';

import { CollapsibleTable, WorkshopTableWrapper } from 'pages/Workshop/common';
import { tableColumns } from './tableColumns';
import { Actions } from './components/Actions';
import { deleteMultipleTasksEffect, updateMultipleTasksEffect } from 'store/effects/taskAggregate';
import { showErrorMessage } from 'helpers';
import {
  getTransactionEffect,
  setTransactionTaskParamsEffect,
  setTransactionTasksLastStateEffect,
} from 'store/effects';
import { useTaskSelection } from './hooks/useSelection';
import { updateTransactionTask } from 'api/transactions';
import { cloneDeep } from 'lodash-es';
import { ConfirmationWithReasonModal } from 'components-antd';
import { Icon } from 'components/Transactions';

interface PendingTasksProps {
  tasks: any;
  onNext: () => void;
  cancellationTasks?: boolean;
  onUpdateAction?: (tasks) => void;
  hideConfirmationAndProceed?: boolean;
}

export const PendingTasks = ({
  onNext,
  tasks,
  cancellationTasks,
  onUpdateAction,
  hideConfirmationAndProceed,
}: PendingTasksProps) => {
  const params: { id?: string } = useParams();
  const transactionId = params?.id;

  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const { selectedTaskIds, handleSelection, setSelectedTaskIds } = useTaskSelection(tasks);

  const fetchTransactionById = (callback?) => {
    dispatch(getTransactionEffect({ id: transactionId }, { silent: true }, callback));
  };

  const onClickTask = useCallback(
    (record) => {
      if (record) {
        const { TransactionId: transactionId, Id: taskId } = record;

        dispatch(setTransactionTaskParamsEffect({ taskId, transactionId, isTaskDetail: true }));
        dispatch(setTransactionTasksLastStateEffect({ taskId }));
      }
    },
    [dispatch, history],
  );

  const tableProps = {
    columns: tableColumns(
      handleSelection,
      selectedTaskIds,
      tasks.length === selectedTaskIds.length,
      cancellationTasks,
    ),
    ...(!cancellationTasks && {
      onRow: (record) => {
        return {
          onClick: () => {
            onClickTask(record);
          },
        };
      },
    }),
    tableClassName: `${styles.taskTable} ${!cancellationTasks ? styles.pendingTaskTable : ''}`,
  };

  const actionCb = (err, setLoading, setShowModal?: (open: boolean) => void) => {
    if (err) {
      showErrorMessage(err);
      setLoading(false);
    } else {
      fetchTransactionById(() => {
        setSelectedTaskIds([]);
        setShowModal && setShowModal(false);
        setLoading(false);
      });
    }
  };

  const handleUpdateTasks = async (status, setLoading: (loading: boolean) => void) => {
    setLoading(true);
    const bulkTasks = selectedTaskIds?.map((taskId) => ({ Id: taskId, Status: status }));

    if (!cancellationTasks) {
      dispatch(
        updateMultipleTasksEffect({ tasks: bulkTasks }, (err) => {
          actionCb(err, setLoading);
        }),
      );
    } else {
      const statusPromises = bulkTasks.map(({ Id, Status }) =>
        updateTransactionTask({
          taskId: Id,
          transactionId,
          Status,
        }),
      );

      Promise.all(statusPromises)
        .then(() => {
          setSelectedTaskIds([]);
          setLoading(false);

          let statusTasks = cloneDeep(tasks);
          statusTasks.forEach((item) => {
            const taskInAction = bulkTasks.find((bulkItem) => item.Id === bulkItem.Id);
            if (taskInAction) {
              item.Status = taskInAction.Status;
            }
          });

          onUpdateAction && onUpdateAction(statusTasks);
        })
        .catch((err) => {
          showErrorMessage(err);
          setLoading(false);
        });
    }
  };

  const handleDeleteTasks = async (
    setLoading: (loading: boolean) => void,
    setShowModal: (open: boolean) => void,
  ) => {
    setLoading(true);
    dispatch(
      deleteMultipleTasksEffect(selectedTaskIds, (err) => {
        actionCb(err, setLoading, setShowModal);
      }),
    );
  };

  const onClickContinue = () => {
    if (hideConfirmationAndProceed) {
      onNext();
    } else {
      setShowModal(true);
    }
  };

  const onConfirmationCancel = () => {
    setShowModal(false);
  };

  return tasks?.length ? (
    <div className={styles.pendingTasks}>
      <AnswersContainer className={styles.pendingTaskAnswerContainer}>
        <WorkshopTableWrapper data={[tasks]} emptyText="No Tasks.">
          <CollapsibleTable title={''} dataSource={tasks} disableCollapse {...tableProps} />
        </WorkshopTableWrapper>
      </AnswersContainer>
      <ButtonsContainer buttonsContainerClassName={styles.buttonsContainer}>
        <Actions
          selectedIds={selectedTaskIds}
          handleDeleteTasks={!cancellationTasks && handleDeleteTasks}
          handleUpdateTasks={handleUpdateTasks}
        />

        <Continue onClick={onClickContinue} />
      </ButtonsContainer>
      <ConfirmationWithReasonModal
        open={showModal}
        confirmText={
          <span>
            There are tasks that are still open. Are you sure you want to continue? <br />
          </span>
        }
        description={
          'Tasks that are not marked N/A or Done will remain open in your task lists and reminders.'
        }
        showReason={false}
        onOk={onNext}
        okText={'Continue'}
        onCancel={onConfirmationCancel}
        icon={Icon.TASKS_BG}
      />
    </div>
  ) : (
    <></>
  );
};
