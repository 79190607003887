import classNames from 'classnames';
import { FormContainer, FormFooter } from 'pages/OnboardingV2Agent/components';
import parentStyles from 'pages/SignupV2/components/PersonalDetails/styles.module.scss';
import React from 'react';
import styles from './styles.module.scss';
import { useVerifyEmailViaOtp } from './useVerifyEmailViaOtp';

type Props = {
  onNext: () => void;
  onSkip: () => void;
};

export const VerifyEmailViaOtp: React.FC<Props> = ({ onNext }) => {
  const {
    userEmail,
    enteredCode,
    inputRefs,
    errorText,
    handleChange,
    handleKeyDown,
    isVerifying,
    handlePaste,
    successfulVerification,
    handleSubmit,
    getResendText,
  } = useVerifyEmailViaOtp({ onEmailVerified: onNext });

  return (
    <FormContainer>
      <div className={classNames(parentStyles.formContainer, styles.extraPadding)}>
        <div className={styles.headerTag}>
          <span>Thank you!</span> Your payment was successful.
        </div>
        <div className={styles.heading}>Please validate your email.</div>
        <div className={styles.info}>
          Enter the code sent to <span>{userEmail}</span>.
        </div>
        <div className={styles.inputWrapper}>
          {enteredCode.map((code, index) => (
            <input
              key={index}
              ref={(el) => (inputRefs.current[index] = el)}
              type={'number'}
              value={code}
              className={classNames(styles.input, {
                [styles.invalid]: !!errorText,
              })}
              onChange={(e) => handleChange(e, index)}
              maxLength={1}
              onKeyDown={(e) => handleKeyDown(e, index)} // Handle arrow keys and backspace
              disabled={isVerifying}
              onPaste={index === 0 ? handlePaste : undefined} // Only the first input handles paste
            />
          ))}
        </div>
        {errorText && (
          <div style={{ height: 0, width: '100%', position: 'relative' }}>
            <div className={styles.invalidCodeText}>{errorText}</div>
          </div>
        )}
        <div className={styles.info}>
          Didn&apos;t receive it? <span className={styles.actionBtn}>{getResendText()}</span>
        </div>
        <FormFooter
          showContinue={false}
          isDisabled={!successfulVerification || isVerifying}
          isPending={isVerifying}
          onSubmitClick={handleSubmit}
          preButton={
            <div className={styles.btnContainer}>
              <div className={styles.skip} onClick={onNext}>
                Skip
              </div>
            </div>
          }
        />
      </div>
    </FormContainer>
  );
};
