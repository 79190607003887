import { Switch } from 'antd';
import styles from './styles.module.scss';
import { Spinner } from 'components';
import { useState } from 'react';

export const DirectoryPreference = ({ isChecked, onChange, loading }) => {
  const [preferrence, setPreferrence] = useState(isChecked);

  return (
    <div className={styles.directoryPreference}>
      <div className={styles.preferenceDisclaimer}>
        <h4 className={styles.title}>Do you want to be added to the Mosaik vendor directory?</h4>

        {loading ? (
          <Spinner loaderClassName={styles.loadingSpinner} />
        ) : preferrence ? (
          <p className={styles.description}>
            This will make your profile visible to all agents who use Mosaik in your area and who
            are either looking for services that you offer or vendors to recommend to their clients.
            There are no costs or fees associated with inclusion in the directory.
          </p>
        ) : (
          <></>
        )}
      </div>

      <Switch
        className={styles.switch}
        checked={preferrence}
        onChange={(value) => {
          setPreferrence(value);
          onChange(value);
        }}
        disabled={loading}
      />
    </div>
  );
};
