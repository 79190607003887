import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';

import { Button, Input, MinMax } from 'components';
import { getClientDefaultSearchSelector } from 'store/selectors/user';
import { getOnBoardingData } from 'pages/OnBoarding/selectors';
import { onBoardingSignUpSearchEffect } from 'store/effects';
import { addSearchInstance } from 'store/effects/clientInstances';

import { PENDING } from 'settings/constants/apiState';

import { ValidationSchema } from './validation';
import { prepareData } from './helpers';
import styles from './styles.module.scss';
import { useDebounce } from 'hooks/use-debounce';
import { useEffect } from 'react';

const PropertySizeForm = (props) => {
  const { stageIndex } = props;
  const dispatch = useDispatch();
  const defaultClientSearch = useSelector(getClientDefaultSearchSelector);
  const { onBoarding } = useSelector(getOnBoardingData);

  const formik = useFormik({
    initialValues: {
      squareFeetMin: defaultClientSearch?.SquareFeetRange?.Min || '',
      squareFeetMax: defaultClientSearch?.SquareFeetRange?.Max || '',
    },
    validationSchema: ValidationSchema,
    onSubmit(values) {
      const cfg = prepareData(values, stageIndex, defaultClientSearch);
      dispatch(
        onBoardingSignUpSearchEffect(cfg, {}, (err) => {
          if (!err) {
            dispatch(addSearchInstance({ data: cfg.DefaultPropertySearchPreferences }));
          }
        }),
      );
    },
  });

  const debouncedMin = useDebounce(formik.values?.squareFeetMin);
  const debouncedMax = useDebounce(formik.values?.squareFeetMax);

  useEffect(() => {
    if (formik.dirty) {
      formik.handleSubmit();
    }
  }, [debouncedMin, debouncedMax]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={styles.wrap}>
        <MinMax
          className={styles.minMax}
          placeholderMin="No min"
          placeholderMax="No max"
          nameMin="squareFeetMin"
          nameMax="squareFeetMax"
          valueMin={formik.values.squareFeetMin}
          valueMax={formik.values.squareFeetMax}
          onChangeMin={(e, val) => formik.setFieldValue('squareFeetMin', val)}
          onChangeMax={(e, val) => formik.setFieldValue('squareFeetMax', val)}
          testidMin="square_feet_min"
          testidMax="square_feet_max"
          variant={Input.LIGHT_ROUND}
          separatorClassName={styles.to}
          error={formik.errors.squareFeetMax}
        />
        <p className={styles.postfix}>sqft</p>
      </div>
    </form>
  );
};

export default PropertySizeForm;
