import { useState, useRef, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Row, Col } from 'components-antd';
import { PropertyCard } from '../PropertyCard';
import Pagination from 'components/Pagination';
import { Wrapper as PendingWrapper } from 'components';

import styles from './styles.module.scss';
import { EmptyState } from '../EmptyState';
import Sort from 'pages/Properties/Feed/Properties/PropsHeader/Actions/Sort';
import Filter from 'pages/Properties/Feed/Properties/PropsHeader/Actions/Filter';
import Multiple from 'pages/Properties/Feed/Properties/PropsHeader/Actions/Multiple';
import { getMultipleModSelector, getPropertyToScrollToSelector } from 'store/selectors/feed';
import { getFeedCriteriaSelectorV3, getFeedPageInfoSelector } from 'store/selectors/feedv3';
import { appOpenModalEffect, setMultipleModeEffect } from 'store/effects';
import classNames from 'classnames';
import { setFeedV3CurrentPageInfoAction } from 'store/actions/feedv3';
import FilterModal from 'pages/Properties/FilterModal';
import { PropertySkeleton } from '../PropertySkeleton/';
import { cleanSearchQueryObj } from 'store/effects/search/helpers';
import { cloneDeep } from 'lodash-es';

const FE_PAGE_SIZE = 40;
const MAX_PAGES = 25;

const ClientProperties = ({
  isPending,
  properties,
  searchInstanceId,
  isFavorites = false,
  viewType,
  isFavortiesSectionVisible = false,
}) => {
  const propertyListRef = useRef<any>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [view, setView] = useState(viewType);
  const multiple = useSelector(getMultipleModSelector);
  const dispatch = useDispatch();
  const currentPageInfo = useSelector(getFeedPageInfoSelector);
  const propertyToScrollTo = useSelector(getPropertyToScrollToSelector);
  const [renderingIndex, setRenderingIndex] = useState(
    currentPageInfo?.currentFEPageInfo?.renderingIndex || -1,
  );
  const { filter } = useSelector(getFeedCriteriaSelectorV3);

  const [forceRerenderPagination, setForceRerenderPagination] = useState(-1);

  useEffect(() => {
    setView(viewType);
  }, [viewType]);

  useEffect(() => {
    // reset the pagination state for feed after setting component state
    if (currentPageInfo?.currentFEPageInfo?.fromListingDetailPage) {
      dispatch(
        setFeedV3CurrentPageInfoAction({
          currentFEPageInfo: {
            pageNumber: 1,
            renderingIndex: -1,
            fromListingDetailPage: false,
          },
        }),
      );
    }
  }, []);

  useEffect(() => {
    setPageNumber(1);
    setTotalPages(Math.ceil(properties?.length / FE_PAGE_SIZE));
  }, [properties]);

  useEffect(() => {
    if (propertyToScrollTo.propertyId && properties) {
      for (let i = 1; i <= MAX_PAGES; i++) {
        const currentListings = properties.slice((i - 1) * FE_PAGE_SIZE, i * FE_PAGE_SIZE);
        const selectedPropertyIndex = currentListings.findIndex(
          (property) => property?.Id === propertyToScrollTo.propertyId,
        );
        if (selectedPropertyIndex !== -1) {
          setPageNumber(i);
          setRenderingIndex(selectedPropertyIndex);
          setForceRerenderPagination(Math.ceil(Math.random() * 10000));
        }
      }
    }
  }, [propertyToScrollTo.propertyId]);

  const onPageChange = (pageNumber) => {
    setPageNumber(pageNumber + 1);
    propertyListRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const getCurrentListings = () =>
    properties?.slice((pageNumber - 1) * FE_PAGE_SIZE, pageNumber * FE_PAGE_SIZE);

  const onClickMultiple = () => {
    if (!multiple) {
      dispatch(setMultipleModeEffect(true));
    } else {
      dispatch(setMultipleModeEffect(false));
    }
  };

  const shouldScrollToProperty = (index) => {
    const shouldScroll = renderingIndex === index;
    setRenderingIndex(-1);
    return shouldScroll;
  };

  const getFilterCount = () => {
    const countFilters = cloneDeep(filter || {});
    delete countFilters?.NoHOA;
    return countFilters
      ? Object.values(cleanSearchQueryObj(countFilters))?.filter((item) => Boolean(item))?.length
      : 0;
  };

  if (isPending)
    return (
      <PendingWrapper
        isPending={isPending}
        className={styles.pendingWrapper}
        customLoader={<PropertySkeleton />}
      />
    );

  return (
    <Fragment>
      <div className={styles.actionsContainer}>
        <span className={styles.total}>
          {properties?.length}&nbsp;
          {properties?.length > 1 || properties?.length === 0 ? 'Properties' : 'Property'}
        </span>
        <div className={styles.actions}>
          <Sort
            sortCases={Sort?.defaultProps?.sortCases || []}
            customleft={0}
            className={styles.noBorder}
            offsetLeft={-200}
            isFavortiesSectionVisible={isFavortiesSectionVisible}
          />
          <FilterModal />

          <Filter
            className={styles.noBorder}
            onClick={() => {
              dispatch(appOpenModalEffect({ id: FilterModal.id, open: true }));
            }}
            count={getFilterCount()}
          />

          <Multiple
            className={classNames({ [styles.active]: multiple, [styles.multiple]: true })}
            onClick={onClickMultiple}
            popoverContent={() => <span>Multi Select</span>}
          />
        </div>
      </div>
      <div key={forceRerenderPagination} className={styles.clientProperties} ref={propertyListRef}>
        {!isPending && getCurrentListings()?.length > 0 && (
          <>
            <Row gutter={24}>
              {getCurrentListings()?.map((item, idx) => (
                <Col key={idx} xs={24} sm={24} md={12} lg={view === 'List Only' ? 8 : 12}>
                  <PropertyCard
                    data={item}
                    getPageNumber={() => pageNumber}
                    searchInstanceId={searchInstanceId}
                    renderingIndex={idx}
                    shouldScrollIntoView={() => shouldScrollToProperty(idx)}
                    isFavProperty={isFavorites}
                  />
                </Col>
              ))}
            </Row>
            <div className={styles.pagination}>
              {totalPages > 1 && (
                <Pagination
                  key={totalPages}
                  limit={FE_PAGE_SIZE}
                  onPageChange={onPageChange}
                  items={properties}
                  pagesLimit={MAX_PAGES}
                  pageAutoHandle={false}
                  // this component makes pages from 0 index
                  defaultPageNumber={pageNumber - 1}
                />
              )}
            </div>
          </>
        )}
        {!isPending && getCurrentListings()?.length === 0 && (
          <EmptyState isSimpleHeaderTitleVisible={isFavorites} />
        )}
      </div>
    </Fragment>
  );
};

export default ClientProperties;
