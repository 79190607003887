import { Row, Col } from 'components-antd';
import { HOA_TYPE } from 'settings/constants/preferences';

import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveClientSearchInstance } from 'store/selectors/app';
import { formatNumber, formatUsd } from 'helpers';
import { NeedsWants } from '../Sections';
import SearchCriteriaListItem from '../SearchCriteriaListItem/SearchCriteriaListItem';
import {
  Input,
  Locations,
  MinMax,
  PropertyType,
  Select as CustomSelect,
  Checkbox,
  LotSizeMinMax,
  Select,
} from 'components';
import { appSetActiveClientSearchInstanceAction } from 'store/actions/app';
import { getSearchDrawnPolygonSelector } from 'store/selectors/search';
import { LocationType } from 'types';
import { featureFlags } from 'utils/featureFlags';

export const SearchCriteriaSummary = () => {
  const dispatch = useDispatch();
  const activeClientSearchInstance = useSelector(getActiveClientSearchInstance);
  const customDrawing = useSelector(getSearchDrawnPolygonSelector);

  const {
    softCriteria,
    priceMin,
    priceMax,
    bedsMin,
    bedsMax,
    bathsMin,
    bathsMax,
    propertyType,
    squareFeetMin,
    squareFeetMax,
    lotSizeMin,
    lotSizeMax,
    locations,
    hoaMin,
    hoaMax,
    hoaType,
  } = activeClientSearchInstance;

  const userLocationsString = locations
    ?.map(({ Street, City, State, Type, PlaceName }) => {
      if (Type === LocationType.Polygon) {
        return 'Custom Location';
      } else if (Type === 'County') {
        let result = PlaceName;
        if (result) {
          result = result.replace(', USA', '');
        }
        return result;
      } else {
        return `${Street ? `${Street}, ` : ''}${City}, ${State}`;
      }
    })
    .join('; ');

  const getMinMaxValue = (Min, Max, format) => {
    if (Min && !Max) {
      return format ? `Min: ${format(Min)}` : `Min: ${Min}`;
    }
    if (Max && !Min) {
      return format ? `Max: ${format(Max)}` : `Max: ${Max}`;
    }
    if (Min && Max) {
      return format ? `${format(Min)} to ${format(Max)}` : `${Min} to ${Max}`;
    }
    return null;
  };

  const updateSearchCriteria = (values) => {
    dispatch(appSetActiveClientSearchInstanceAction({ ...activeClientSearchInstance, ...values }));
  };

  const renderLocation = () => (
    <Locations
      enableDraw={featureFlags.customSearchPolygonDrawing}
      variant={Locations.ROUND}
      name="locations"
      onResult={(result, preparedData) => {
        updateSearchCriteria({ locations: preparedData });
      }}
      placeholder="Enter city, ZIP, or neighborhood"
      disabled={customDrawing}
      multiple={true}
      value={
        customDrawing !== null
          ? [{ PlaceName: 'custom location', City: 'Custom Location' }]
          : locations || []
      }
      fieldTouched={() => updateSearchCriteria({ locations: true })}
      placeholderClassName={styles.border}
      searchWrapperClassName={styles.border}
      allowedSmartyAddress={true}
      valuesWrapperClassName={styles.border}
      isInviteFlag={true}
      rounded={true}
    />
  );

  const renderPrice = () => (
    <MinMax
      variant={Input.LIGHT_ROUND}
      nameMin="priceMin"
      nameMax="priceMax"
      onChangeMin={(e) => {
        updateSearchCriteria({ priceMin: e.target.value });
      }}
      onChangeMax={(e) => {
        updateSearchCriteria({ priceMax: e.target.value });
      }}
      valueMin={priceMin}
      valueMax={priceMax}
      placeholderMin="No min"
      placeholderMax="No max"
      prefix="$"
    />
  );

  const renderPropertyType = () => (
    <PropertyType
      value={propertyType || []}
      onChange={(type) => {
        updateSearchCriteria({ propertyType: type });
      }}
      type={'dropdown'}
      variant={CustomSelect.LIGHT_ROUND}
      className={styles.wrapper}
      selectWrapperClassname={styles.selectWrapperClassname}
    />
  );

  const renderPropertySize = () => (
    <MinMax
      nameMin="squareFeetMin"
      nameMax="squareFeetMax"
      onChangeMin={(e) => {
        updateSearchCriteria({ squareFeetMin: e.target.value });
      }}
      onChangeMax={(e) => {
        updateSearchCriteria({ squareFeetMax: e.target.value });
      }}
      valueMin={squareFeetMin}
      valueMax={squareFeetMax}
      placeholderMin="No min"
      placeholderMax="No max"
      variant={Input.LIGHT_ROUND}
    />
  );

  const renderBeds = () => (
    <MinMax
      nameMin="bedsMin"
      nameMax="bedsMax"
      onChangeMin={(e) => {
        updateSearchCriteria({ bedsMin: e.target.value });
      }}
      onChangeMax={(e) => {
        updateSearchCriteria({ bedsMax: e.target.value });
      }}
      valueMin={bedsMin}
      valueMax={bedsMax}
      placeholderMin="No min"
      placeholderMax="No max"
      variant={Input.LIGHT_ROUND}
    />
  );

  const renderBaths = () => (
    <MinMax
      nameMin="bathsMin"
      nameMax="bathsMax"
      onChangeMin={(e) => {
        updateSearchCriteria({ bathsMin: e.target.value });
      }}
      onChangeMax={(e) => {
        updateSearchCriteria({ bathsMax: e.target.value });
      }}
      valueMin={bathsMin}
      valueMax={bathsMax}
      placeholderMin="No min"
      placeholderMax="No max"
      variant={Input.LIGHT_ROUND}
    />
  );

  const renderLotSize = () => (
    <LotSizeMinMax
      placeholderMin="No min"
      placeholderMax="No max"
      onChangeMin={(e) => {
        updateSearchCriteria({ lotSizeMin: e.target.value });
      }}
      onChangeMax={(e) => {
        updateSearchCriteria({ lotSizeMax: e.target.value });
      }}
      nameMin="lotSizeMin"
      nameMax="lotSizeMax"
      valueMin={lotSizeMin}
      valueMax={lotSizeMax}
      variant={Select.LIGHT_ROUND}
      separatorClassName={styles.separator}
    />
  );

  const renderHoa = () => (
    <div className={styles.hoaContainer}>
      {(hoaType || HOA_TYPE.Flexible) === HOA_TYPE.Flexible && (
        <MinMax
          nameMin="hoaMin"
          nameMax="hoaMax"
          onChangeMin={(e) => {
            updateSearchCriteria({ hoaMin: e.target.value });
          }}
          onChangeMax={(e) => {
            updateSearchCriteria({ hoaMax: e.target.value });
          }}
          valueMin={hoaMin}
          valueMax={hoaMax}
          placeholderMin="No min"
          placeholderMax="No max"
          variant={Input.LIGHT_ROUND}
        />
      )}
      <div className={styles.checkboxContainer}>
        <Checkbox
          checked={(hoaType || HOA_TYPE.Flexible) === HOA_TYPE.None}
          onChange={(e) => {
            updateSearchCriteria({ hoaType: e.target.checked ? HOA_TYPE.None : HOA_TYPE.Flexible });
          }}
          label="No HOA"
          direction="right"
          labelTextClassName={styles.label}
        />
      </div>
    </div>
  );

  return (
    <div className={styles.searchCriteriaSummary}>
      <Row className={styles.hardCriteria} gutter={24}>
        <SearchCriteriaListItem
          label={'Desired Locations'}
          placeholder={'No Locations'}
          value={userLocationsString}
          component={renderLocation}
          full
        />
      </Row>
      <Row className={styles.hardCriteria} gutter={24}>
        <SearchCriteriaListItem
          label={'Price'}
          placeholder={'No min / No max'}
          value={getMinMaxValue(priceMin, priceMax, formatUsd)}
          component={renderPrice}
        />
        <SearchCriteriaListItem
          label={'Property Type'}
          placeholder={'N/A'}
          value={propertyType ? propertyType?.join(', ') : ''}
          component={renderPropertyType}
        />
      </Row>
      <Row className={styles.hardCriteria} gutter={24}>
        <SearchCriteriaListItem
          label={'Property Size (sqft)'}
          placeholder={'No min / No max'}
          value={getMinMaxValue(formatNumber(squareFeetMin), formatNumber(squareFeetMax))}
          component={renderPropertySize}
        />
        <SearchCriteriaListItem
          label={'Beds'}
          placeholder={'No min / No max'}
          value={getMinMaxValue(bedsMin, bedsMax)}
          component={renderBeds}
        />
      </Row>
      <Row className={styles.hardCriteria} gutter={24}>
        <SearchCriteriaListItem
          label={'Baths'}
          placeholder={'No min / No max'}
          value={getMinMaxValue(bathsMin, bathsMax)}
          component={renderBaths}
        />
        <SearchCriteriaListItem
          label={'Lot Size (sqft)'}
          placeholder={'No min / No max'}
          value={getMinMaxValue(lotSizeMin, lotSizeMax)}
          component={renderLotSize}
        />
      </Row>
      <Row className={styles.hardCriteria} gutter={24}>
        <SearchCriteriaListItem
          label={'HOA'}
          placeholder={'No min / No max'}
          value={getMinMaxValue(formatUsd(hoaMin), formatUsd(hoaMax))}
          component={renderHoa}
          full
        />
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={12}>
          <NeedsWants type="Need" softCriteria={softCriteria} />
        </Col>
        <Col xs={24} sm={12}>
          <NeedsWants type="Want" softCriteria={softCriteria} />
        </Col>
      </Row>
    </div>
  );
};
