import { useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';

import { Button } from 'components';
import { DatePicker } from 'antd';
import styles from './styles.module.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { updateClientDataEffect } from 'store/effects';
import { formatDate, showSuccessMessage } from 'helpers';

export const BirthdayForm = ({ value, handleClose, clientId }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    dob: Yup.string(),
  });

  const { handleSubmit, setFieldValue, values } = useFormik({
    initialValues: value,
    validationSchema,
    onSubmit: async ({ dob }) => {
      if (value.dob !== dob) {
        setLoading(true);
        dispatch(
          updateClientDataEffect({ clientId, dob: formatDate(dob, 'YYYY-MM-DD') }, (err) => {
            if (!err) {
              showSuccessMessage('Client Birthday updated');
            }
            setLoading(false);
            handleClose();
          }),
        );
      }
    },
  });

  return (
    <form className={styles.main} onSubmit={handleSubmit}>
      <DatePicker
        className={classNames(styles.inputHolder, styles.closingDate)}
        format={'MMMM D'}
        placeholder={'MM DD'}
        size="large"
        onChange={(date) => {
          if (date) {
            const dateWithYear2024 = moment({
              year: 2024,
              month: date.month(),
              day: date.date(),
            });
            setFieldValue('dob', dateWithYear2024);
          }
        }}
        inputReadOnly
        value={values.dob}
        getPopupContainer={(node) => node}
        popupClassName={classNames('mosaikCalendar', styles.birthdayCalender)}
        allowClear={false}
        picker="date"
      />
      <div className={styles.actionButtons}>
        <Button type="submit" title={'Update'} className={styles.update} isPending={loading} />
        <Button title={'Cancel'} className={styles.cancel} onClick={handleClose} />
      </div>
    </form>
  );
};
