import Api from 'store/effects/core/api';
import { getUserRelatedContacts } from 'api/relatedContacts';
import {
  requestGetAgentCoordinatorRelatedContactsAction,
  requestGetTransactionRelatedContactsAction,
  requestGetAllRelatedContactsAction,
  requestGetClientRelatedContactsAction,
} from 'store/actions/relatedContacts';

export const requestGetClientRelatedContactsEffect = (cfg, options = {}, cb) => {
  const requestParams = {
    action: requestGetClientRelatedContactsAction,
    method: getUserRelatedContacts,
  };

  let sendRequest = Api.execBase(requestParams);

  if (options.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const requestGetAgentCoordinatorRelatedContactsEffect = (cfg, options = {}, cb) => {
  const requestParams = {
    action: requestGetAgentCoordinatorRelatedContactsAction,
    method: getUserRelatedContacts,
  };

  let sendRequest = Api.execBase(requestParams);

  if (options.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const requestGetTransactionRelatedContactsEffect = (cfg, options = {}, cb) => {
  const requestParams = {
    action: requestGetTransactionRelatedContactsAction,
    method: getUserRelatedContacts,
  };

  let sendRequest = Api.execBase(requestParams);

  if (options.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const requestGetAllRelatedContactsEffect = (cfg, options = {}, cb) => {
  const requestParams = {
    action: requestGetAllRelatedContactsAction,
    method: getUserRelatedContacts,
  };

  let sendRequest = Api.execBase(requestParams);

  if (options.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};
