import classNames from 'classnames';
import { Input } from 'components';
import Plus from 'components/Icons/Plus';
import { Remove } from 'components/Icons';
import { NameAutocomplete } from 'pages/Workshop/Transactions/TransactionFinancials/components';

import { initialState } from '../../FinancingDetails';

import styles from './../../styles.module.scss';
import { useEffect, useState } from 'react';
import { calculateGCI, calculateRate } from '../../validationHelpers';

const CommissionField = ({
  index,
  Value,
  Percent,
  IsFeeTypePercentage,
  setOtherValues,
  setOtherFeeData,
}) => {
  const [isPercentage, setIsPercentage] = useState(IsFeeTypePercentage);

  useEffect(() => {
    setIsPercentage(IsFeeTypePercentage);
  }, [IsFeeTypePercentage]);

  return (
    <Input
      label="Amount"
      numberInputClassName={styles.amountInput}
      labelRightIcon={
        <>
          {index != 0 && (
            <Remove
              className={styles.icon}
              color={'#FF576D'}
              onClick={() => {
                setOtherFeeData((prev) => prev.filter((_, i) => index !== i));
              }}
            />
          )}
        </>
      }
      variant={Input.LIGHT_ROUND}
      placeholder={IsFeeTypePercentage ? '0%' : '$0'}
      isNumberFormat
      suffix={isPercentage ? '%' : undefined}
      prefix={!isPercentage ? '$' : undefined}
      icon={
        <div className={styles.valueTypeContainer}>
          {['%', '$'].map((symbol, i) => {
            const active =
              (IsFeeTypePercentage && symbol === '%') || (!IsFeeTypePercentage && symbol === '$');
            return (
              <div
                key={symbol}
                onClick={() => {
                  setOtherValues(symbol === '%', index, 'IsFeeTypePercentage', IsFeeTypePercentage);
                }}
                className={classNames(
                  styles.button,
                  {
                    [styles.active]: active,
                  },
                  {
                    [styles.border]: i === 0,
                  },
                )}
              >
                {symbol}
              </div>
            );
          })}
        </div>
      }
      onChange={(e, val) => {
        if ((IsFeeTypePercentage && val != Percent) || (!IsFeeTypePercentage && val != Value)) {
          setOtherValues(val, index, 'Value', IsFeeTypePercentage);
        }
      }}
      value={IsFeeTypePercentage ? Percent : Value}
      minNumber={IsFeeTypePercentage ? 0 : undefined}
      maxNumber={IsFeeTypePercentage ? 100 : undefined}
    />
  );
};

export const OtherFees = ({
  otherFeeData,
  setOtherFeeData,
  setOtherValues,
  autocompleteFeeNames,
  commission,
}) => {
  return (
    <div className={styles.otherFeesContainer}>
      {otherFeeData?.map(({ Description, IsFeeTypePercentage, Value, Percent }, index) => (
        <div className={styles.form} key={index}>
          <NameAutocomplete
            label="Fee"
            allNames={autocompleteFeeNames?.map((item, idx) => ({ label: item, value: idx + 1 }))}
            value={Description}
            onChange={(value) =>
              setOtherValues(value?.label, index, 'Description', IsFeeTypePercentage)
            }
            onSelect={(value) =>
              setOtherValues(value?.label, index, 'Description', IsFeeTypePercentage)
            }
            className={styles.feeAutocomplete}
            isHidePopupContainerParent
          />
          <CommissionField
            index={index}
            Percent={Percent}
            Value={Value}
            IsFeeTypePercentage={IsFeeTypePercentage}
            setOtherFeeData={setOtherFeeData}
            setOtherValues={setOtherValues}
          />
        </div>
      ))}
      <div className={styles.addContainer}>
        <Plus className={styles.plus} color={'#515151'} />
        <p
          className={styles.addText}
          onClick={() => {
            setOtherFeeData((prev) => [...prev, { ...initialState }]);
          }}
        >
          Add Another
        </p>
      </div>
    </div>
  );
};
