import { getCtaData } from 'api/kits';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getUserRolesMapSelector } from 'store/selectors/user';

export const useKitsQuoteRedirect = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const partnersIdsParam = queryParams.get('partnersIds');
  const sourceParam = queryParams.get('source');
  const uuidParam = queryParams.get('uuid');
  const categoryId = queryParams.get('categoryId');

  const { isClient } = useSelector(getUserRolesMapSelector);

  const redirectToQuotesForm = () => {
    if (sourceParam === 'kits' && uuidParam) {
      getCtaData(uuidParam);
    }
    if (partnersIdsParam && categoryId) {
      const targetRecommendationsIds = partnersIdsParam.split(',').map(Number);

      if (targetRecommendationsIds.length) {
        const quoteFormRedirectUrl = `/services/category/${categoryId}/partner/${targetRecommendationsIds.join()}/request-quote`;
        history.push(quoteFormRedirectUrl);
      }
    }
  };

  useEffect(() => {
    if (isClient) redirectToQuotesForm();
  }, []);
};
