import classNames from 'classnames';
import { Select } from 'components';
import { TransactionBuySideCommissionResponsibility } from 'app-constants';
import { transactionPreFormQuestionsIds } from 'settings/constants/transaction';
import { Col, Row } from 'components-antd';
import { CommissionField } from './../../components';

import styles from './../../styles.module.scss';

const buySideCommissionResponsibilityOptions = Object.values(
  TransactionBuySideCommissionResponsibility,
).map((type) => ({
  value: type,
  name: type,
}));

export const BuyerCommissionContribution = ({
  preForm,
  onAnswerHandler,
  validateBuyerCommissionContributions,
  handleCommissionUpdate,
  isTransactionUnderContractRoute,
  showBuyerSellerContributionFields,
}) => {
  return (
    <>
      <div className={styles.seperator} />
      <Select
        defaultValue={TransactionBuySideCommissionResponsibility.Buyer}
        value={preForm?.[transactionPreFormQuestionsIds.buySideCommissionResponsibility]}
        onSelect={(_, { value }) => {
          if (value !== TransactionBuySideCommissionResponsibility.Both) {
            onAnswerHandler(null, 'buyerCommissionContribution');
            onAnswerHandler(null, 'sellerCommissionContribution');
          }

          onAnswerHandler(value, 'buySideCommissionResponsibility');
        }}
        options={buySideCommissionResponsibilityOptions}
        className={{
          wrapper: classNames(styles.financingType, styles.marginTop),
        }}
        placeholder={' '}
        isArrowIcon={true}
        arrowIconClassName={styles.arrowdownicon}
        variant={Select.LIGHT_ROUND}
        label={'Who is responsible for the buy-side commission?'}
      />
      {showBuyerSellerContributionFields && (
        <Row gutter={12} className={styles.marginTop}>
          <Col span={12}>
            <CommissionField
              label="Buyer Contribution"
              value={
                preForm?.[transactionPreFormQuestionsIds.buyerCommissionContribution]
                  ?.IsFeeTypePercentage
                  ? preForm?.[transactionPreFormQuestionsIds.buyerCommissionContribution]?.Percent
                  : preForm?.[transactionPreFormQuestionsIds.buyerCommissionContribution]?.Value
              }
              IsFeeTypePercentage={
                preForm?.[transactionPreFormQuestionsIds.buyerCommissionContribution]
                  ?.IsFeeTypePercentage ?? true
              }
              update={(object) =>
                handleCommissionUpdate(
                  object,
                  'buyerCommissionContribution',
                  isTransactionUnderContractRoute ||
                    preForm?.[transactionPreFormQuestionsIds.isPropertyUnderContract]
                    ? preForm?.[transactionPreFormQuestionsIds.buyerCommission]?.Value
                    : preForm?.[transactionPreFormQuestionsIds.totalCommission]?.Value,
                )
              }
            />
          </Col>
          <Col span={12}>
            <CommissionField
              label="Seller Contribution"
              value={
                preForm?.[transactionPreFormQuestionsIds.sellerCommissionContribution]
                  ?.IsFeeTypePercentage
                  ? preForm?.[transactionPreFormQuestionsIds.sellerCommissionContribution]?.Percent
                  : preForm?.[transactionPreFormQuestionsIds.sellerCommissionContribution]?.Value
              }
              IsFeeTypePercentage={
                preForm?.[transactionPreFormQuestionsIds.sellerCommissionContribution]
                  ?.IsFeeTypePercentage ?? true
              }
              update={(object) =>
                handleCommissionUpdate(
                  object,
                  'sellerCommissionContribution',
                  isTransactionUnderContractRoute ||
                    preForm?.[transactionPreFormQuestionsIds.isPropertyUnderContract]
                    ? preForm?.[transactionPreFormQuestionsIds.buyerCommission]?.Value
                    : preForm?.[transactionPreFormQuestionsIds.totalCommission]?.Value,
                )
              }
            />
          </Col>
          {!validateBuyerCommissionContributions &&
            (preForm?.[transactionPreFormQuestionsIds.buyerCommissionContribution] ||
              preForm?.[transactionPreFormQuestionsIds.sellerCommissionContribution]) && (
              <p className={styles.errorValidationText}>
                * Contributions can never be greater than commission.
              </p>
            )}
        </Row>
      )}
    </>
  );
};
