import { useDispatch } from 'react-redux';
import { deleteAIFileSessionEffect, uploadAIFileEffect } from 'store/effects';
import { v4 as uuidv4 } from 'uuid';

export const useUploadFileWithAI = () => {
  const dispatch = useDispatch();

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const saveFileWithAI = async (documentName, file, cb) => {
    const base64: any = await toBase64(file);
    const data = base64.replace('data:application/pdf;base64,', '');
    const fileId = uuidv4();
    const payload = {
      id: fileId,
      file_name: documentName,
      data,
    };

    dispatch(
      uploadAIFileEffect(payload, (error, response) => {
        if (!error) {
          dispatch(deleteAIFileSessionEffect({ id: fileId }, {}));
          cb?.(response.data);
        }
      }),
    );
  };

  return { saveFileWithAI };
};
