import { Option, MultiSelect } from 'components-antd';
import { Icons } from 'pages/Workshop/Icons';

import styles from './styles.module.scss';
import { CheckBox } from 'components/Icons';
import classNames from 'classnames';
import { Avatar } from 'components';
import Pin from 'components/Icons/Pin';

interface MultiSelectSectionProps {
  placeholder: string;
  entityList: { value: any; label: any }[];
  entity: any;
  setEntity: (value: any) => void;
  avatarPrefix?: boolean;
  locationPrefix?: boolean;
  showSelectedEntriesBelow?: boolean;
  multiSelectSectionDropdownClassName?: string;
  multiSelectWrapperClassName?: string;
}

const getPlaceHolder = (fullName) => {
  const nameArray = fullName.split(' ');
  const firstName = nameArray[0];
  const lastName = nameArray[nameArray.length - 1];
  return firstName?.charAt(0)?.toUpperCase() + lastName?.charAt(0)?.toUpperCase();
};

const customFilterOption = (inputValue: string, option) => {
  const value = option?.props?.children?.props?.children?.[2]?.props?.children;
  if (!value) return false;
  return value?.toLowerCase()?.indexOf(inputValue?.toLowerCase()) !== -1;
};

export const MultiSelectSection = ({
  placeholder,
  entityList,
  entity,
  setEntity,
  avatarPrefix,
  locationPrefix,
  showSelectedEntriesBelow = true,
  multiSelectSectionDropdownClassName = '',
  multiSelectWrapperClassName = '',
}: MultiSelectSectionProps) => {
  return (
    <>
      <MultiSelect
        size="large"
        variant={'search'}
        placeholderText={placeholder}
        onHandleChange={(values) => setEntity(values)}
        propClass={classNames(styles.multiSelect, {
          [styles.placeholderForSelected]: entity.length > 0,
        })}
        optionFilterProp="children"
        filterOption={locationPrefix || avatarPrefix ? customFilterOption : undefined}
        wrapperClass={classNames(styles.multiSelectWrapper, multiSelectWrapperClassName)}
        values={entity}
        selectIcon={<Icons variant={'search'} />}
        dropdownClassName={classNames(
          styles.multiSelectSectionDropdown,
          multiSelectSectionDropdownClassName,
        )}
        menuItemSelectedIcon={<CheckBox className={styles.checkBoxIcon} />}
        showClearIcon={true}
        showSearchPlaceholder={false}
        getPopupContainer={(node) => node}
      >
        {entityList?.map(({ value, label }, idx) => (
          <Option key={idx} value={value}>
            {avatarPrefix || locationPrefix ? (
              <span className={styles.optionContainer}>
                {avatarPrefix && (
                  <Avatar
                    placeholder={getPlaceHolder(label)}
                    avatarClassName={styles.avatarCustom}
                  />
                )}
                {locationPrefix && (
                  <div className={styles.pinIcon}>
                    <Pin />
                  </div>
                )}
                <span className={locationPrefix ? styles.labelLength : ''}>{label}</span>
              </span>
            ) : (
              label
            )}
          </Option>
        ))}
      </MultiSelect>

      {showSelectedEntriesBelow && (
        <>
          {entity?.map((item, idx) => (
            <div className={styles.selectedItemsWrapper} key={idx}>
              <div className={styles.leftItemContainer}>
                <CheckBox className={styles.checkBoxIcon} />
                <span className={styles.itemText}>
                  {entityList.find((e) => e.value === item)?.label}
                </span>
              </div>
              <Icons
                variant={Icons.CLOSE}
                className={styles.crossIcon}
                color="#747475"
                onClick={() => {
                  setEntity(entity?.filter((i) => i !== item));
                }}
              />
            </div>
          ))}
        </>
      )}
    </>
  );
};
