import React from 'react';

export const DeleteIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.66748 4.6665H13.3341"
        stroke="#FF576D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66748 7.3335V11.3335"
        stroke="#FF576D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33252 7.3335V11.3335"
        stroke="#FF576D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33252 4.6665L3.99919 12.6665C3.99919 13.0201 4.13966 13.3593 4.38971 13.6093C4.63976 13.8594 4.9789 13.9998 5.33252 13.9998H10.6659C11.0195 13.9998 11.3586 13.8594 11.6087 13.6093C11.8587 13.3593 11.9992 13.0201 11.9992 12.6665L12.6659 4.6665"
        stroke="#FF576D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 4.66667V2.66667C6 2.48986 6.07024 2.32029 6.19526 2.19526C6.32029 2.07024 6.48986 2 6.66667 2H9.33333C9.51014 2 9.67971 2.07024 9.80474 2.19526C9.92976 2.32029 10 2.48986 10 2.66667V4.66667"
        stroke="#FF576D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
