import { useState } from 'react';
import { getNewRole, prepareTemplateUpdatedData, prepareUpdatedRoles } from '../helper';

export const useEditSignatoryMode = (props) => {
  const { templateState, templateFormRoles } = props;
  const [updatedRoles, setUpdatedRoles] = useState<number[]>(
    prepareUpdatedRoles(templateState?.formRoleIds || []),
  );

  const [templateDeletedRoles, setTemplateDeletedRoles] = useState<number[]>([]);

  const handleSelectRole = (index, roleId) => {
    let roles: any[] = [...updatedRoles];

    if (!roles[index]) {
      let newRole = getNewRole(roleId);

      if (templateDeletedRoles.includes(roleId)) {
        let deletedRoles = [...templateDeletedRoles];
        deletedRoles = deletedRoles.filter((id) => id !== roleId);

        setTemplateDeletedRoles(deletedRoles);
        newRole.savedRole = roleId;
      }

      roles.push(newRole);
    } else {
      const { savedRole } = roles[index];
      roles[index].roleId = roleId;

      if (roleId === savedRole) {
        roles[index].oldRoleId = undefined;
        roles[index].isReplaced = false;
      } else if (templateDeletedRoles.includes(roleId)) {
        roles[index].oldRoleId = undefined;
        roles[index].isReplaced = false;
        roles[index].savedRole = roleId;

        let deletedRoles: any = [...templateDeletedRoles];
        let deletedIndex = templateDeletedRoles.indexOf(roleId);

        if (savedRole) {
          deletedRoles[deletedIndex] = savedRole;
        } else {
          deletedRoles = deletedRoles.filter((id) => id !== roleId);
        }

        setTemplateDeletedRoles(deletedRoles);
      } else {
        roles[index].oldRoleId = savedRole;
        roles[index].isReplaced = true;
      }
    }

    setUpdatedRoles(roles);
  };

  const handleDeleteRole = (index) => {
    let templateRoleId = templateFormRoles[index];
    let roles: any[] = [...updatedRoles];

    const existingRole = roles.find(({ roleId, savedRole, oldRoleId }) =>
      oldRoleId ? roleId === templateRoleId : savedRole === templateRoleId,
    );

    templateRoleId = existingRole.isReplaced ? existingRole.savedRole : existingRole.roleId;
    existingRole && setTemplateDeletedRoles((prev) => [...prev, templateRoleId]);

    roles = roles.filter((_, roleIndex) => roleIndex !== index);
    setUpdatedRoles(roles);
  };

  const manageSignatoryEditMode = (index, roleId, actionType) => {
    if (actionType === 'select') {
      handleSelectRole(index, roleId);
    } else if (actionType === 'delete') {
      handleDeleteRole(index);
    }
  };

  const getTemplateUpdatedData = () => {
    return prepareTemplateUpdatedData(updatedRoles, templateDeletedRoles);
  };

  return { manageSignatoryEditMode, getTemplateUpdatedData };
};
