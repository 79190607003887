import { createSelector } from 'reselect';
import { ERROR, IDLE, PENDING } from 'settings/constants/apiState';
import { tasksStatusesOptions } from 'settings/constants/transactionTasks';
import { getUserId } from '../user';

const localState = ({ transactionTask }) => transactionTask;

export const getTransactionTaskSelector = createSelector(localState, (transactionTask) => {
  const task = transactionTask?.data;
  const taskStatusOption = tasksStatusesOptions.find((option) => option?.value === task?.Status);
  return {
    isIdle: transactionTask.state === IDLE,
    isPending: transactionTask.state === PENDING,
    isError: transactionTask.state === ERROR,
    isData: !!transactionTask?.data,
    status: transactionTask?.meta?.status,
    task,
    taskId: transactionTask?.taskId,
    taskStatusOption,
  };
});

export const getTransactionTaskParamsSelector = createSelector(localState, (transactionTasks) => ({
  isTaskForm: !!transactionTasks?.isTaskForm,
  isTaskDetail: !!transactionTasks?.isTaskDetail,
  taskId: transactionTasks?.taskId,
  transactionId: transactionTasks?.transactionId,
  campaignId: transactionTasks?.campaignId,
  formValues: transactionTasks?.formValues,
}));

export const getIsTransactionFormTaskSelector = createSelector(localState, (transactionTask) => ({
  isFormTask: !!transactionTask?.data?.EntityId,
}));

export const getIsBundleFormTaskSelector = createSelector(localState, (transactionTask) => ({
  IsBundle: transactionTask?.data?.IsBundle,
}));

export const getFormTaskDetailsSelector = createSelector(localState, (transactionTask) => ({
  formTask: transactionTask?.data?.formTask,
}));

export const getFormDocumentsSelector = createSelector(localState, (transactionTask) => ({
  formDocuments: transactionTask?.data?.formDocuments,
}));

export const getFormTaskLinkedFormsSelector = createSelector(localState, (transactionTask) => ({
  linkedForms: transactionTask?.data?.linkedForms,
}));

export const getFormTaskPermissions = createSelector(localState, (transactionTask) => ({
  canBeginSigning: transactionTask?.data?.canBeginSigning,
  canResumeSigning: transactionTask?.data?.canResumeSigning,
  canSignForm: transactionTask?.data?.canSignForm,
  canViewForm: transactionTask?.data?.canViewForm,
}));

export const getTransactionTaskAccessSelector = createSelector(
  getTransactionTaskSelector as any,
  getUserId,
  (transactionTask, userId) => {
    const assignorId = transactionTask?.task?.AssignorId;
    return {
      fullAccess: userId === assignorId,
    };
  },
);
