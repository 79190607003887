import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { uniqBy } from 'lodash-es';

import { InputLabel, Select, Autocomplete } from 'components';
import { getAggregateMilestoneTransactionsWithParticipantsSelector } from 'store/selectors/transactions';
import { getProjectsForMilestoneSelector } from 'store/selectors/projects';

import styles from './styles.module.scss';
import { getUserId } from 'store/selectors/user';

export const Transaction = ({ onChangeField, values }) => {
  const { TransactionId: transactionId, DueDate: dueDate } = values || {};
  const transactions = useSelector(getAggregateMilestoneTransactionsWithParticipantsSelector);
  const projects = useSelector(getProjectsForMilestoneSelector);
  const currentUserId = useSelector(getUserId);
  const [searchTerm, setSearchTerm] = useState('');

  const transactionsAndProjects = uniqBy([...transactions, ...projects], 'Id');
  const filteredTransactionsAndProjects = transactionsAndProjects.filter((transaction) => {
    return (
      transaction?.TransactionAccess &&
      transaction.Participants.filter((p) => {
        return p.Id === currentUserId;
      }).length
    );
  });

  const transaction = useMemo(
    () => filteredTransactionsAndProjects.find((t) => t.Id == transactionId),
    [transactionId],
  );

  const getOptions = () => {
    return filteredTransactionsAndProjects?.map((t) => ({ name: t.label, value: t.Id }));
  };

  const options = useMemo(() => {
    const searchedOptions = getOptions();
    if (searchTerm.trim().length > 0) {
      const filtered = searchedOptions.filter(({ name }) =>
        name.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      return filtered.length ? filtered : [];
    }
    return searchedOptions;
  }, [searchTerm]);

  const onTransactionChange = ({ target: { value: val } }) => {
    onChangeField(val.value, 'TransactionId');
    setSearchTerm('');
  };

  // Requested by Sheila
  // const checkMilestoneDateIsValid = useCallback(() => {
  //   if (transactionId && dueDate) {
  //     const transactionClosingDate = closingDate.startOf('day');
  //     const milestoneDueDate = moment(values.DueDate).startOf('day');
  //     if (milestoneDueDate.isSameOrBefore(transactionClosingDate)) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }, [transactionId, dueDate]);

  const getValue = () => {
    return {
      name: transaction?.label || '',
      value: transaction?.Id || '',
    };
  };

  return (
    <div className={styles.transactions}>
      {/* {!checkMilestoneDateIsValid() && closingDate && (
        <div className={styles.disclaimer}>
          <span>
            Milestones cannot be scheduled after the transaction closing date <br />(
            {closingDate?.format('DD/MM/YYYY')}).
          </span>
        </div>
      )} */}

      <div className={styles.fieldWrapper}>
        <InputLabel label="Transaction/Project" className={styles.label} />
        <Select
          search
          className={{
            wrapper: styles.search,
            value: styles.value,
          }}
          variant={Autocomplete.LIGHT}
          options={options}
          placeholder="Select Transaction or Project"
          onSelect={onTransactionChange}
          value={getValue()}
          searchIconClassName={styles.searchIcon}
          emptyMessage={() => null}
          isArrowIcon
          onSearchInputChange={({ target }) => setSearchTerm(target.value)}
        />
      </div>
    </div>
  );
};
