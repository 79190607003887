import { useState } from 'react';
import { Row, Col, Checkbox, DatePicker, InputNumber } from 'antd';
import moment from 'moment';

import { Navigation } from '../../components';

import styles from './styles.module.scss';
import { IconText } from '../components/IconText';
import { InformationText } from '../components/InformationText';

const { RangePicker } = DatePicker;

const initState = {
  isIndefinite: false,
  isAddDays: false,
  isDateRange: false,
};

export const LinkDuration = ({
  onNext,
  stageIndex,
  lastStageIndex,
  onUpdate,
  onFinish,
  data,
  setCurrentStageIndex,
}) => {
  const { SelectedDuration, LinkActiveStartDate, LinkActiveEndDate, LinkActiveDays } = data;

  const [state, setState] = useState(SelectedDuration);
  const [startDate, setStartDate]: any = useState(LinkActiveStartDate);
  const [endDate, setEndDate]: any = useState(
    !LinkActiveDays && LinkActiveEndDate ? LinkActiveEndDate : null,
  );
  const [activeDays, setActiveDays]: any = useState(LinkActiveDays);

  const onChange = (data) => {
    setState({ ...initState, ...data });
  };

  const isDisabled = () => {
    const disabled =
      !state.isIndefinite &&
      (!state.isAddDays || (state.isAddDays && !activeDays)) &&
      (!state.isDateRange || (state.isDateRange && (!startDate || !endDate)));

    return disabled;
  };

  const onSubmit = () => {
    const body = {
      SelectedDuration: state,
      Indefinitely: state.isIndefinite,
      LinkActiveStartDate: moment(startDate).startOf('day').toISOString(),
      LinkActiveEndDate: state.isDateRange ? moment(endDate).endOf('day').toISOString() : null,
      LinkActiveDays: activeDays,
    };

    if (activeDays) {
      body.LinkActiveStartDate = moment().toISOString();
      const activeEndDate = new Date();
      activeEndDate.setDate(activeEndDate.getDate() + activeDays);
      body.LinkActiveEndDate = moment(activeEndDate).toISOString();
    }

    if (data.Id) {
      onUpdate(body);
      onFinish(body);
      setCurrentStageIndex(lastStageIndex);
    } else {
      onUpdate(body);
      onNext();
    }
  };

  const disabledDate = (current) => {
    let today = moment(new Date()).format('YYYY-MM-DD');
    return current && current < moment(today, 'YYYY-MM-DD');
  };

  return (
    <div className={styles.offerLink}>
      <IconText
        variant={'calendar-bg'}
        text={'How long should this link be active?'}
        className={styles.iconText}
      />

      <Row className={styles.content}>
        <Col
          span={24}
          className={styles.container}
          onClick={() => {
            onChange({ isIndefinite: true });
            setStartDate(new Date());
            setEndDate(null);
            setActiveDays(null);
          }}
        >
          <Checkbox
            checked={state?.isIndefinite}
            defaultChecked={state?.isIndefinite}
            className={styles.checkbox}
          >
            <span className={styles.indefinitely}>Indefinitely</span>
          </Checkbox>
        </Col>

        <Col
          span={24}
          className={styles.container}
          onClick={() => {
            onChange({ isAddDays: true });
            setEndDate(null);
          }}
        >
          <Checkbox
            checked={state.isAddDays}
            defaultChecked={state.isAddDays}
            className={styles.checkbox}
          >
            <span className={styles.for}>For</span>

            <InputNumber
              min={1}
              placeholder="#"
              onChange={(val) => {
                setActiveDays(val);
              }}
              value={activeDays}
              step={1}
              precision={0}
              className={styles.activeDays}
            />
            <span className={styles.static}>days (from today)</span>
          </Checkbox>
        </Col>

        <Col
          className={styles.container}
          span={24}
          onClick={() => {
            onChange({ isDateRange: true });
            setActiveDays(null);
          }}
        >
          <Checkbox checked={state.isDateRange} defaultChecked={state.isDateRange}>
            <span className={styles.from}>From</span>

            <RangePicker
              key={startDate + endDate}
              className={styles.rangePicker}
              getPopupContainer={(trigger) => {
                return trigger;
              }}
              separator="to"
              format={'MM/DD/YY'}
              placeholder={['mm/dd/yy', 'mm/dd/yy']}
              onChange={(e: any) => {
                setStartDate(new Date(e[0]));
                setEndDate(new Date(e[1]));
              }}
              defaultValue={state.isDateRange && [moment(startDate), moment(new Date(endDate))]}
              disabledDate={disabledDate}
            />
          </Checkbox>
        </Col>
      </Row>

      <InformationText
        text={
          'Regardless of the duration you select, you can manually deactivate this link at any time.'
        }
      />

      <Navigation
        data={data}
        onNext={onSubmit}
        stageIndex={stageIndex}
        onFinish={onFinish}
        disabled={isDisabled()}
      />
    </div>
  );
};
