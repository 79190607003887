import { Modal } from 'components-antd';
import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import AddKitsStyles from '../AddKitsModal/styles.module.scss';
import { ModalClose } from 'components/Icons';
import {
  HouseIcon,
  InAppTextIcon,
  LibraryIcon,
  LocationIcon,
  PreviewIcon,
  TimerIcon,
} from '../components';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { selectedPreviewKitSelector } from 'store/selectors/radarKits';
import { compact } from 'lodash-es';
import { getEmailTemplateByKit } from 'api/kits';

type CardProps = {
  variant: 'light-blue' | 'purple' | 'blue' | 'green' | 'orange';

  title:
    | 'Category'
    | 'Frequency'
    | 'Eligible Property Type(s)'
    | 'In-App Text'
    | 'Eligible Location(s)';
  items?: string[];
  locationItems?: { eligible: string[]; restricted: string[] };
};

const variantStyles = (variant: CardProps['variant']) => ({
  [styles.lightBlue]: variant === 'light-blue',
  [styles.purple]: variant === 'purple',
  [styles.blue]: variant === 'blue',
  [styles.green]: variant === 'green',
  [styles.orange]: variant === 'orange',
});

const variantIcon = (variant: CardProps['variant']) => {
  switch (variant) {
    case 'light-blue':
      return <LibraryIcon />;
    case 'purple':
      return <TimerIcon />;
    case 'blue':
      return <HouseIcon />;
    case 'green':
      return <LocationIcon />;
    case 'orange':
      return <InAppTextIcon />;
  }
};

const Card: React.FC<CardProps> = ({ variant, title, items, locationItems }) => {
  return (
    <div className={styles.card}>
      <div className={classNames(styles.imgWrapper, variantStyles(variant))}>
        {variantIcon(variant)}
      </div>
      <div className={styles.infoWrapper}>
        <div className={styles.title}>{title}</div>
        <div className={styles.pillsContainer}>
          {items?.map((item) => (
            <div className={classNames(styles.pill, variantStyles(variant))}>{item}</div>
          ))}
          {locationItems && (
            <div className={styles.locationContainer}>
              {locationItems?.eligible.length > 0 ? (
                locationItems.eligible.map((item) => (
                  <div className={classNames(styles.pill, variantStyles(variant))}>{item}</div>
                ))
              ) : (
                <div className={classNames(styles.pill, variantStyles(variant))}>All</div>
              )}
              {locationItems?.restricted?.length > 0 && (
                <>
                  <div className={styles.text}>Except:</div>
                  {locationItems.restricted.map((item) => (
                    <div
                      className={classNames(
                        styles.pillRestricted,
                        styles.outlinedgreen,
                        variantStyles(variant),
                      )}
                    >
                      {item}
                    </div>
                  ))}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  title: string;
  kitId: number;
};

export const PreviewKitsModal: React.FC<Props> = ({ isOpen, closeModal, title, kitId }) => {
  const previewKit = useSelector(selectedPreviewKitSelector(kitId));
  const [htmlString, setHtmlString] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchStandardKits = async () => {
    try {
      const response = await getEmailTemplateByKit(previewKit?.Kits.Slug);
      if (response.status === 200 && response.data.result) {
        setHtmlString(response.data.result);
      } else {
        setError(true);
      }
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (loading) fetchStandardKits();
  }, [loading]);
  return (
    <Modal
      open={isOpen}
      centered
      maskStyle={{
        background: 'rgba(0, 0, 0, 0.60)',
        backdropFilter: 'blur(20px)',
      }}
      closable
      footer={null}
      destroyOnClose
      onCancel={closeModal}
      closeIcon={<ModalClose />}
      title={
        <div className={styles.titleWrapper}>
          <div>{title}</div>
          <div className={styles.modalSubTitle}>{previewKit?.Kits.Name}</div>
        </div>
      }
      wrapClassName={AddKitsStyles.wrapper}
      width={1002}
    >
      <div className={styles.contentWrapper}>
        <div className={styles.leftContainer}>
          {htmlString && <div dangerouslySetInnerHTML={{ __html: htmlString }}></div>}
        </div>
        <div className={styles.rightContainer}>
          <Card
            variant="light-blue"
            title="Category"
            items={compact([previewKit?.Kits?.Category])}
          />
          <Card
            variant="purple"
            title="Frequency"
            items={compact(previewKit?.Kits.EligibleFrequencies.map((freq) => freq.FrequencyName))}
          />
          <Card
            variant="blue"
            title="Eligible Property Type(s)"
            items={compact(
              previewKit?.Kits.EligiblePropertyTypes?.replace(/[{}"]/g, '')?.split(','),
            )}
          />
          <Card
            variant="green"
            title="Eligible Location(s)"
            locationItems={{
              eligible: compact(previewKit?.Kits.EligibleLocations?.map((loc) => loc.PlaceName)),
              restricted: compact(
                previewKit?.Kits.RestrictedLocations?.map((loc) => loc.PlaceName),
              ),
            }}
          />
          {/* <Card variant="orange" title="In-App Text" /> */}
        </div>
      </div>
    </Modal>
  );
};
