import { sendOtpVerificationEmail, verifyOtpCode } from 'api/user';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userGetDataOnBackgroundEffect } from 'store/effects';
import { getUserDataSelector, getUserEmail } from 'store/selectors/user';

import styles from './styles.module.scss';

const CODE_LENGTH = 6;

type HookProps = {
  onEmailVerified: () => void;
};

export const useVerifyEmailViaOtp = ({ onEmailVerified }: HookProps) => {
  const dispatch = useDispatch();
  const user = useSelector(getUserDataSelector);
  const userEmail = useSelector(getUserEmail);

  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

  const [isVerifying, setIsVerifying] = useState<boolean>(false);
  const [enteredCode, setEnteredCode] = useState<string[]>(Array(CODE_LENGTH).fill(''));
  const [successfulVerification, setSuccessfulVerification] = useState<boolean>(false);

  useEffect(() => {
    const verifyCode = async () => {
      const code = enteredCode.join('');
      try {
        setIsVerifying(true);
        await verifyOtpCode({ code: enteredCode.join('') });
        dispatch(userGetDataOnBackgroundEffect());
        setSuccessfulVerification(true);
      } catch (err) {
        setErrorText('Invalid code');
      } finally {
        setIsVerifying(false);
      }
    };
    // if each index of enteredCode is filled, then call on Next
    const isCodeFilled = enteredCode.every((code) => code.length === 1);
    if (isCodeFilled) {
      verifyCode();
    }
  }, [enteredCode]);

  useEffect(() => {
    if (user?.IsEmailVerified) {
      onEmailVerified();
    }
  }, [user]);

  const [errorText, setErrorText] = useState<string | null>(null);
  const [remainingSecondsToResendCode, setRemainingSecondsToResendCode] = useState<number | null>(
    null,
  );
  const [emailVerificationData, setEmailVerificationData] = useState<any | null>(null);

  const runEffect = useCallback(async () => {
    try {
      const response = await sendOtpVerificationEmail();
      setEmailVerificationData(response.data);
    } catch (err) {
      return;
    }
  }, []);

  useEffect(() => {
    void runEffect();
  }, []);

  // Update timer with remaining seconds for next attempt
  useEffect(() => {
    const interval = setInterval(() => {
      const remainingSeconds = moment(
        emailVerificationData?.AllowLinkResendTimestamp ||
          emailVerificationData?.AllowCodeResendTimestamp,
      ).diff(moment(), 'seconds');
      setRemainingSecondsToResendCode(remainingSeconds);

      if (remainingSeconds < 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [emailVerificationData]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target;

    // Ensure only one digit is allowed
    if (value.length === 1) {
      const newCode = [...enteredCode];
      newCode[index] = value;
      setEnteredCode(newCode);
      if (inputRefs.current[index + 1]) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleResendClick = () => {
    runEffect();
  };

  const getResendText = () => {
    if (remainingSecondsToResendCode === null) {
      return null;
    }

    if (remainingSecondsToResendCode > 0) {
      return <span>Resend the code in {remainingSecondsToResendCode} seconds</span>;
    }
    if (remainingSecondsToResendCode <= 0) {
      return (
        <span>
          <a
            onClick={() => {
              handleResendClick();
            }}
            href="#"
            className={styles.resend}
          >
            Resend
          </a>
        </span>
      );
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    // Disable arrow keys
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
    }

    if (e.key === 'Backspace' && inputRefs.current[index - 1]) {
      const newCode = [...enteredCode];
      newCode[index] = '';
      setEnteredCode(newCode);
      inputRefs.current[index - 1]?.focus();
    }
    if (e.key === 'Backspace' && index === 0) {
      const newCode = [...enteredCode];
      newCode[index] = '';
      setEnteredCode(newCode);
    }
  };

  const handleSubmit = () => {
    if (!successfulVerification || isVerifying) return;
    onEmailVerified();
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault(); // Prevent the default paste action
    const pastedData = e.clipboardData.getData('text').slice(0, 6); // Take only the first 6 characters
    const otpDigits = pastedData.split('');

    const updatedOtp = [...enteredCode]; // Create a copy of the otp state
    otpDigits.forEach((digit, index) => {
      updatedOtp[index] = digit;
      if (inputRefs.current[index]) {
        inputRefs.current[index]!.value = digit; // Programmatically set the input value
      }
    });

    setEnteredCode(updatedOtp); // Update the state to reflect the pasted values
  };

  return {
    userEmail,
    enteredCode,
    inputRefs,
    errorText,
    handleChange,
    handleKeyDown,
    isVerifying,
    handlePaste,
    successfulVerification,
    handleSubmit,
    getResendText,
  };
};
