import PropTypes from 'prop-types';

const SplitView = ({ className, size }) => (
  <div className={className}>
    <svg
      width={size || '24'}
      height={size || '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.10371 19.4884C13.2459 23.931 20.5748 17.3999 12.1878 15.0105C4.14561 12.7194 6.8925 9.15983 10.5 8.5"
        stroke="#FF576D"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M15.8963 4.51189C10.7542 0.0686594 3.42521 6.60009 11.8122 8.98977C19.8544 11.2812 17.1075 14.8401 13.5 15.5"
        stroke="#FF576D"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  </div>
);

SplitView.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
};

SplitView.defaultProps = {
  className: '',
  size: '24',
};

export default SplitView;
