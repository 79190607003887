import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { Button, Row, Col } from 'components-antd';
import { Checkbox } from 'components';
import Icons from './../../Icons/Icons';

import styles from './styles.module.scss';
import { prefsIds } from 'settings/constants/preferences';

type CriteriaTypeType = {
  title: string;
  id: string;
  icon?: string | null;
  additionalOptions?: any;
  type: string;
  softCriteria: any;
  addSoftCriteria: Function;
  parking?: Function;
};

export const OptionButton = ({ title, value, onClick, selectedImportance }) => (
  <Button
    variant="default"
    className={classNames(styles.criteriaButton, {
      [styles.selected]: selectedImportance === value,
    })}
    onClick={(e) => onClick(value, e)}
  >
    {title}
  </Button>
);

export const CriteriaType = ({
  title,
  id,
  icon = null,
  additionalOptions = null,
  type,
  softCriteria,
  addSoftCriteria,
  parking,
}: CriteriaTypeType) => {
  const [selectedImportance, setSelectedImportance] = useState<string | null>('N/A');

  useEffect(() => {
    if (
      [
        prefsIds.heaterSource,
        prefsIds.waterSource,
        prefsIds.waste,
        prefsIds.kitchenCabinetColor,
      ].includes(id)
    ) {
      const options = additionalOptions?.options;
      let importance;
      options.forEach(({ id }) => {
        if (id in softCriteria) {
          importance = softCriteria?.[id]?.value;
          return;
        }
      });
      setSelectedImportance(importance || 'N/A');
    } else if (id === prefsIds.parking) {
      const importance = softCriteria?.[id]?.value || 'N/A';
      setSelectedImportance(importance);
    } else {
      const importance = softCriteria?.[id]?.value || 'N/A';
      setSelectedImportance(importance);
    }
  }, [softCriteria]);

  const onClick = (value) => {
    setSelectedImportance(value);
    if (id === prefsIds.parking && softCriteria?.[prefsIds.rvParking]) {
      addSoftCriteria(type, id, value, undefined, undefined, [prefsIds.rvParking]);
    }
    if (additionalOptions && value !== 'N/A') {
      if (additionalOptions) {
        let additionalOptionsToMark: string[] = [];
        additionalOptions?.options?.forEach((item) => {
          if (softCriteria?.[item?.id]) additionalOptionsToMark.push(item?.id);
        });
        addSoftCriteria(type, id, value, undefined, undefined, additionalOptionsToMark);
      } else {
        addSoftCriteria(type, id, value);
      }
    } else if (additionalOptions && value === 'N/A') {
      // If there are additional options & N/A is clicked, remove all the keys
      const removeId = additionalOptions?.options?.map((item) => item?.id) || [];
      if (id === prefsIds.waterFront) removeId.push(id);
      addSoftCriteria(undefined, undefined, undefined, undefined, removeId);
    } else addSoftCriteria(type, id, value);
  };

  const isAdditionalOptionSelected = (addtionalOptionId) => {
    return addtionalOptionId in softCriteria;
  };

  return (
    <div className={styles.criteriaTypeContainer}>
      <div className={styles.basicOptionsContainer}>
        <div className={styles.titleContainer}>
          {icon && <Icons variant={icon} />}
          <span className={styles.title}>{title}</span>
        </div>
        <div className={styles.buttonsContainer}>
          <OptionButton
            title="Need"
            value="Need"
            onClick={(value) => onClick(value)}
            selectedImportance={selectedImportance}
          />
          <OptionButton
            title="Want"
            value="Want"
            onClick={(value) => onClick(value)}
            selectedImportance={selectedImportance}
          />
          <OptionButton
            title="N/A"
            value={'N/A'}
            onClick={(value) => onClick(value)}
            selectedImportance={selectedImportance}
          />
        </div>
      </div>
      {selectedImportance !== 'N/A' && parking ? parking(selectedImportance) : null}
      {additionalOptions && selectedImportance !== 'N/A' && (
        <div className={styles.additionalOptions}>
          <Row gutter={12}>
            {additionalOptions?.label && (
              <Col
                lg={24}
                className={classNames(styles.label, {
                  [styles.full]: additionalOptions?.type === 'button',
                })}
              >
                {additionalOptions?.label}:
              </Col>
            )}
            {additionalOptions?.type === 'checkbox'
              ? additionalOptions?.options?.map((item, idx) => (
                  <Col key={idx} lg={12}>
                    <Checkbox
                      label={item?.label}
                      checked={isAdditionalOptionSelected(item?.id)}
                      direction={Checkbox.DIRECTION_RIGHT}
                      labelClassName={styles.checkboxLabel}
                      variant={Checkbox.CONFETTI_BLUE}
                      className={styles.wrapperCheckbox}
                      onChange={(e) => {
                        const { checked } = e.target;
                        addSoftCriteria(
                          type,
                          id,
                          selectedImportance,
                          checked ? [item] : null,
                          additionalOptions?.options
                            .map(({ id }) => id)
                            .filter((chk) => chk !== item?.id),
                        );
                      }}
                    />
                  </Col>
                ))
              : additionalOptions?.type === 'checkbox-optional'
              ? additionalOptions?.options?.map((item, idx) => (
                  <Col key={idx} lg={12}>
                    <Checkbox
                      label={item?.label}
                      checked={isAdditionalOptionSelected(item?.id)}
                      direction={Checkbox.DIRECTION_RIGHT}
                      labelClassName={styles.checkboxLabel}
                      variant={Checkbox.CONFETTI_BLUE}
                      className={styles.wrapperCheckbox}
                      onChange={(e) => {
                        const { checked } = e.target;
                        addSoftCriteria(
                          type,
                          id,
                          selectedImportance,
                          checked ? [item] : null,
                          !checked ? [item.id] : [],
                        );
                      }}
                    />
                  </Col>
                ))
              : additionalOptions?.options?.map((item, idx) => (
                  <Button
                    variant="default"
                    className={classNames(styles.optionBtn, {
                      [styles.selected]: isAdditionalOptionSelected(item?.id),
                    })}
                    onClick={() => {
                      const selected = isAdditionalOptionSelected(item?.id);
                      addSoftCriteria(
                        type,
                        selected ? null : item?.id,
                        selectedImportance,
                        undefined,
                        selected ? [item?.id] : [],
                      );
                    }}
                  >
                    {item.label}
                  </Button>
                ))}
          </Row>
        </div>
      )}
    </div>
  );
};
