import { SVGAttributes } from 'react';

export const BackOnTheMarketIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M6 11.5V4M6 4L3 7M6 4L9 7"
        stroke="#FB913A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 8.5V16M14 16L11 13M14 16L17 13"
        stroke="#FB913A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
