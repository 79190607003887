import React from 'react';

import { Filter } from './components';

import styles from './styles.module.scss';
import moment from 'moment';
import { icons } from './components/Filter/constants';
import { Categories } from './components/Filter/types';
import { Icons } from 'pages/Radar/RadarProperty/Icons';

const fifteenMinutesAgo = moment().subtract(15, 'minutes');

const morethanThreeStyles = {
  width: '50%',
};

const lessThanThreeStyles = {
  height: '50%',
};

const images = [
  'https://s3-alpha-sig.figma.com/img/9593/649e/3f370a2d511071a70dcacf4d85bbc5ac?Expires=1728259200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=aztDT3PlwR0HYEp6geN41sEJdBPAOIlusy8gmxwt3gZl-6cCq7votbc8rG4-8nq5hqM~bg5iJdcmthCf7rfO3mHPawAINt4PtLEaOIMUuuiEmYjRl0JTj85CM8jIeWeNNi6CTlcuugK~srYNFgIWQib-9rRBEIwIfZ~-w1AJIPZrrqJa317T08VgSC3ed~6LXXNOQx0jw7ieWbFzz1riBzBPg4Ke8lwGSfcxarnAiHNL1LGpoIIk~rz~hgMxHduVxlVoUcyUvKeO5UwQcQAibbyQJTVJxoHkIvA6JGVSK79b-fCaFbzhW0BOMdtGq95Eh9daTkW7JhbMAktR8bjAfw__',
  'https://s3-alpha-sig.figma.com/img/9593/649e/3f370a2d511071a70dcacf4d85bbc5ac?Expires=1728259200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=aztDT3PlwR0HYEp6geN41sEJdBPAOIlusy8gmxwt3gZl-6cCq7votbc8rG4-8nq5hqM~bg5iJdcmthCf7rfO3mHPawAINt4PtLEaOIMUuuiEmYjRl0JTj85CM8jIeWeNNi6CTlcuugK~srYNFgIWQib-9rRBEIwIfZ~-w1AJIPZrrqJa317T08VgSC3ed~6LXXNOQx0jw7ieWbFzz1riBzBPg4Ke8lwGSfcxarnAiHNL1LGpoIIk~rz~hgMxHduVxlVoUcyUvKeO5UwQcQAibbyQJTVJxoHkIvA6JGVSK79b-fCaFbzhW0BOMdtGq95Eh9daTkW7JhbMAktR8bjAfw__',
  'https://s3-alpha-sig.figma.com/img/9593/649e/3f370a2d511071a70dcacf4d85bbc5ac?Expires=1728259200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=aztDT3PlwR0HYEp6geN41sEJdBPAOIlusy8gmxwt3gZl-6cCq7votbc8rG4-8nq5hqM~bg5iJdcmthCf7rfO3mHPawAINt4PtLEaOIMUuuiEmYjRl0JTj85CM8jIeWeNNi6CTlcuugK~srYNFgIWQib-9rRBEIwIfZ~-w1AJIPZrrqJa317T08VgSC3ed~6LXXNOQx0jw7ieWbFzz1riBzBPg4Ke8lwGSfcxarnAiHNL1LGpoIIk~rz~hgMxHduVxlVoUcyUvKeO5UwQcQAibbyQJTVJxoHkIvA6JGVSK79b-fCaFbzhW0BOMdtGq95Eh9daTkW7JhbMAktR8bjAfw__',
  'https://s3-alpha-sig.figma.com/img/9593/649e/3f370a2d511071a70dcacf4d85bbc5ac?Expires=1728259200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=aztDT3PlwR0HYEp6geN41sEJdBPAOIlusy8gmxwt3gZl-6cCq7votbc8rG4-8nq5hqM~bg5iJdcmthCf7rfO3mHPawAINt4PtLEaOIMUuuiEmYjRl0JTj85CM8jIeWeNNi6CTlcuugK~srYNFgIWQib-9rRBEIwIfZ~-w1AJIPZrrqJa317T08VgSC3ed~6LXXNOQx0jw7ieWbFzz1riBzBPg4Ke8lwGSfcxarnAiHNL1LGpoIIk~rz~hgMxHduVxlVoUcyUvKeO5UwQcQAibbyQJTVJxoHkIvA6JGVSK79b-fCaFbzhW0BOMdtGq95Eh9daTkW7JhbMAktR8bjAfw__',
  'https://s3-alpha-sig.figma.com/img/9593/649e/3f370a2d511071a70dcacf4d85bbc5ac?Expires=1728259200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=aztDT3PlwR0HYEp6geN41sEJdBPAOIlusy8gmxwt3gZl-6cCq7votbc8rG4-8nq5hqM~bg5iJdcmthCf7rfO3mHPawAINt4PtLEaOIMUuuiEmYjRl0JTj85CM8jIeWeNNi6CTlcuugK~srYNFgIWQib-9rRBEIwIfZ~-w1AJIPZrrqJa317T08VgSC3ed~6LXXNOQx0jw7ieWbFzz1riBzBPg4Ke8lwGSfcxarnAiHNL1LGpoIIk~rz~hgMxHduVxlVoUcyUvKeO5UwQcQAibbyQJTVJxoHkIvA6JGVSK79b-fCaFbzhW0BOMdtGq95Eh9daTkW7JhbMAktR8bjAfw__',
];

export const NeighborhoodTab: React.FC = () => {
  console.log(images.length);
  return (
    <div className={styles.pageWrapper}>
      <div className={styles.leftWrapper}>
        <div className={styles.card}>
          <div className={styles.heading}>
            <div className={styles.info}>
              <div className={styles.typeWrapper}>
                <div
                  className={styles.iconWrapper}
                  style={{ background: icons['Back on the Market'].bgColor }}
                >
                  {icons['Back on the Market'].icon}
                </div>
                <div className={styles.type}>{Categories.BACK_ON_THE_MARKET}</div>
              </div>
              <div className={styles.timeFromNow}>{moment(fifteenMinutesAgo).fromNow(true)}</div>
            </div>
            <div className={styles.descriptionWrapper}>
              <div className={styles.description}>
                <div className={styles.title}>$670,000</div>
                <div className={styles.subTitle}>350 N Canal St</div>
                <div className={styles.location}>Chicago, IL 60654</div>
              </div>
              <div className={styles.pinpoint}>
                <div className={styles.wrapper}>
                  <div>
                    <Icons variant={Icons.MARKER} />
                  </div>
                  <div>0.9 mi</div>
                </div>
              </div>
            </div>
          </div>
          {images.length && (
            <div className={styles.imageWrapper}>
              <div className={styles.img1}>
                <img src={images[0]} />
              </div>
              {images.length >= 2 && (
                <div className={styles.img2}>
                  {images.length === 2 && <img src={images[1]} />}
                  {images.length > 2 && (
                    <>
                      <div className={styles.innerWrapper}>
                        {images.length > 3 ? (
                          <>
                            <div
                              className={styles.img3}
                              style={images.length > 3 ? morethanThreeStyles : lessThanThreeStyles}
                            >
                              <img src={images[1]} />
                            </div>
                            <div
                              className={styles.img4}
                              style={images.length > 3 ? morethanThreeStyles : lessThanThreeStyles}
                            >
                              <img src={images[2]} />
                            </div>
                          </>
                        ) : (
                          <img src={images[1]} />
                        )}
                      </div>
                      <div className={styles.innerWrapper}>
                        {images.length > 3 ? (
                          <>
                            <div
                              className={styles.img5}
                              style={images.length > 3 ? morethanThreeStyles : lessThanThreeStyles}
                            >
                              <img src={images[3]} />
                            </div>
                            <div
                              className={styles.img6}
                              style={images.length > 3 ? morethanThreeStyles : lessThanThreeStyles}
                            >
                              <img src={images[4]} />
                            </div>
                          </>
                        ) : (
                          <img src={images[2]} />
                        )}
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className={styles.rightWrapper}>
        <Filter />
      </div>
    </div>
  );
};
