import styles from './styles.module.scss';
import Icons from '../../../Icons/Icons';
import { OptionButton } from '../../CriteraType';
import { FC } from 'react';
import { Cross } from 'components/Icons';
import { map } from 'lodash-es';
import classNames from 'classnames';
import { Tooltip } from 'components-antd';

interface FormAttributeProps {
  icon: any;
  label: string;
  list: { title: string; duration: string; icon?: string; importance?: string }[];
  onOpen: (value: any) => void;
  onRmoveItem: (value: any) => void;
  importanceChange: Function;
  disableAddButton?: boolean;
}

export const FormAttribute: FC<FormAttributeProps> = ({
  icon,
  label,
  list,
  onOpen,
  onRmoveItem,
  importanceChange,
  disableAddButton = false,
}) => {
  const handleImportance = (e, val, idx) => {
    e.stopPropagation();
    importanceChange(val, idx);
  };

  const handleRemove = (e, idx) => {
    e.stopPropagation();
    onRmoveItem(idx);
  };

  const onClick = (id) => {
    onOpen(id);
  };

  return (
    <>
      <div className={styles.criteriaTypeContainer}>
        <div className={styles.basicOptionsContainer}>
          <div className={styles.titleContainer}>
            <Icons variant={icon} />
            <span className={styles.title}>{label}</span>
          </div>
          {!disableAddButton && (
            <p
              className={styles.addContainer}
              onClick={() => {
                onOpen(list?.length);
              }}
            >
              <span>+</span> Add
            </p>
          )}
        </div>
      </div>
      {map(list, ({ title, duration, importance }, idx) => {
        return (
          <div className={styles.card} key={idx} onClick={() => onClick(idx)}>
            <div className={styles.remove} onClick={(e) => handleRemove(e, idx)}>
              <Cross color="#fff" size={16} />
            </div>
            <div className={styles.basicOptionsContainer}>
              <div className={styles.titleContainer}>
                <Tooltip
                  placement="top"
                  getPopupContainer={(trigger) => trigger}
                  overlayClassName={classNames(styles.titleTooltip, 'mosaikTooltip')}
                  title={<p>{title}</p>}
                >
                  <p className={styles.title}>{title}</p>
                </Tooltip>

                <div className={styles.subtitleContainer}>
                  <p className={styles.subtitle}>{duration}</p>
                </div>
              </div>
              <div className={styles.buttonsContainer}>
                <OptionButton
                  title="Need"
                  value="Must"
                  onClick={(value, e) => {
                    handleImportance(e, value, idx);
                  }}
                  selectedImportance={importance}
                />
                <OptionButton
                  title="Want"
                  value="Somewhat"
                  onClick={(value, e) => handleImportance(e, value, idx)}
                  selectedImportance={importance}
                />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
