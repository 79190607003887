import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { get } from 'lodash-es';
import { appLogoutAction } from 'store/actions/app';
import {
  requestGetClauseTemplatesAction,
  setClauseTemplatesAction,
  setClauseTemplatesQueryAction,
} from 'store/actions/templates';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
  query: '',
};

export default handleActions(
  {
    [requestGetClauseTemplatesAction]: (state, { payload }) => {
      const result = get(payload, ['data', 'value']);
      return {
        ...state,
        state: payload.state,
        data: result || initialData.data,
        meta: get(payload, 'meta', initialData.meta),
      };
    },
    [setClauseTemplatesAction]: (state, { payload }) => ({
      ...state,
      data: payload,
    }),
    [setClauseTemplatesQueryAction]: (state, { payload }) => ({
      ...state,
      query: payload || initialData.query,
    }),
    [appLogoutAction]: () => initialData,
  },
  initialData,
);
