import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';

import { prepareData } from './helpers';
import { ValidationSchema } from './validation';

import { HOA_TYPE } from 'settings/constants/preferences';
import { getClientDefaultSearchSelector } from 'store/selectors/user';
import { onBoardingSignUpSearchEffect } from 'store/effects';
import { addSearchInstance } from 'store/effects/clientInstances';

import { MinMax, Input, Checkbox } from 'components';

import styles from './styles.module.scss';
import { useDebounce } from 'hooks/use-debounce';
import { useEffect } from 'react';

const HOAForm = (props) => {
  const { stageIndex } = props;
  const dispatch = useDispatch();
  const defaultClientSearch = useSelector(getClientDefaultSearchSelector);

  const formik = useFormik({
    initialValues: {
      hoaType: defaultClientSearch?.HOAType || HOA_TYPE.Flexible,
      hoaMin: defaultClientSearch?.HOARange?.Min || '',
      hoaMax: defaultClientSearch?.HOARange?.Max || '',
    },
    validationSchema: ValidationSchema,
    onSubmit(values) {
      const cfg = prepareData(values, stageIndex, defaultClientSearch);
      dispatch(
        onBoardingSignUpSearchEffect(cfg, {}, (err) => {
          if (!err) {
            dispatch(addSearchInstance({ data: cfg.DefaultPropertySearchPreferences }));
          }
        }),
      );
    },
  });

  const handleHoaType = (e) => {
    const checked = e.target.checked;
    if (checked) {
      formik.setFieldValue('hoaType', HOA_TYPE.None);
    } else {
      formik.setFieldValue('hoaType', HOA_TYPE.Flexible);
    }
  };

  const debouncedMin = useDebounce(formik.values?.hoaMin);
  const debouncedMax = useDebounce(formik.values?.hoaMax);

  console.log('ERRORS', formik.errors);

  useEffect(() => {
    if (formik.dirty) {
      formik.handleSubmit();
    }
  }, [debouncedMin, debouncedMax, formik?.values?.hoaType]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <MinMax
        className={styles.minMax}
        separatorClassName={styles.to}
        placeholderMin="No min"
        placeholderMax="No max"
        nameMin="hoaMin"
        nameMax="hoaMax"
        valueMin={formik.values.hoaMin}
        valueMax={formik.values.hoaMax}
        onChangeMin={(e, val) => formik.setFieldValue('hoaMin', val)}
        onChangeMax={(e, val) => formik.setFieldValue('hoaMax', val)}
        testidMin="hoa_min"
        testidMax="hoa_max"
        variant={Input.LIGHT_ROUND}
        prefix="$"
        error={formik.errors.hoaMax}
      />
      <div className={styles.checkboxContainer}>
        <Checkbox
          checked={formik.values.hoaType === HOA_TYPE.None}
          onChange={handleHoaType}
          label="No HOA"
          direction="right"
          labelTextClassName={styles.label}
        />
      </div>
    </form>
  );
};

export default HOAForm;
