import React from 'react';
import moment from 'moment';
import classNames from 'classnames';

import type { ColumnsType } from 'antd/es/table';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ArrowDownFull } from 'components/Icons';
import Point from 'components/Transactions/ViewTimeline/Point';
import { getTitleByType } from 'utils/aggregatedPageTypeHelper';
import { routes } from 'settings/navigation/routes';
import { Icons } from 'pages/Workshop/Icons';

import { getAggregatePageTypeSelector } from 'store/selectors/workshop';
import { isToday, subtractTimeZone } from 'helpers';
import { displayListedStatus, listedStatusesArray } from 'settings/constants/transaction';
import { transactionRoles } from 'settings/constants/roles';
import { getUserId } from 'store/selectors/user';

import styles from './styles.module.scss';

interface ColumnsDataType {
  key: React.Key;
  Title: string;
  Property: any;
  Participants: any;
  Status: string;
  dataIndex: string;
  Id: string | number;
  isTransactionCreator: boolean;
  TransactionId: number;
  Name: string;
  Transaction: any;
  DueDate: string;
  IsKeyDate?: boolean;
  IsProject?: boolean;
}

export function tableColumns(): ColumnsType<ColumnsDataType> {
  const history = useHistory();
  const aggregatedPageType = useSelector(getAggregatePageTypeSelector);
  const userId = useSelector(getUserId);

  function inferRoleCategory(row): string {
    const roles = row.RepresentingRoles || [];
    return roles.includes(transactionRoles.SELLER) && roles.includes(transactionRoles.BUYER)
      ? 'Dual'
      : roles.includes(transactionRoles.SELLER)
      ? 'Listing'
      : 'Purchase';
  }

  function inferStatus(row): string {
    return listedStatusesArray.includes(row?.Status?.Name)
      ? displayListedStatus[row?.Status?.Name]
      : row?.Status?.Name;
  }

  const sortByParticipantName = (ParticipantsArray) => {
    return ParticipantsArray.sort((a, b) => {
      const firstNameA = a.Participant.FirstName.toUpperCase();
      const firstNameB = b.Participant.FirstName.toUpperCase();

      if (firstNameA < firstNameB) {
        return -1;
      }

      if (firstNameA > firstNameB) {
        return 1;
      }

      return 0;
    });
  };

  const getParticipantName = (participants, role) => {
    const filteredParticipants = participants.filter((participant) => {
      return participant.Participant.Roles.includes(role);
    });

    if (filteredParticipants.length > 0) {
      const sortedParticipants = sortByParticipantName(filteredParticipants);
      let participantsName = `${sortedParticipants[0].Participant.FirstName} ${sortedParticipants[0].Participant.LastName}`;

      if (sortedParticipants.length > 1) {
        participantsName += `<br> ${sortedParticipants[1].Participant.FirstName} ${sortedParticipants[1].Participant.LastName}`;
      }
      return participantsName;
    }
    return '';
  };

  const getDateString = (dueDate, status) => {
    const date = subtractTimeZone(dueDate, 'M/DD/YYYY');
    const today = moment();

    if (!today.isBefore(date) && !isToday(date)) {
      return (
        <div className={styles.inlineRowContent}>
          <Icons variant={Icons.CALENDAR} />
          <span className={classNames(styles.overDueText, styles.dateText)}>
            {moment(dueDate).format('M/D/YYYY')}
          </span>
        </div>
      );
    } else {
      return (
        <div className={styles.inlineRowContent}>
          <Icons variant={Icons.CALENDAR} />
          <span className={styles.dateText}>{moment(dueDate).format('M/D/YYYY')}</span>
        </div>
      );
    }
  };

  const getSorterTitle = (sortColumns, columnKey, label, checkSortOrder = true) => {
    const sortedColumn = sortColumns?.find(({ column }) => column.key === columnKey);
    const sortAsc = sortedColumn?.order === 'ascend';
    return (
      <div className={styles.sortedColumnTitle}>
        {label}
        {!checkSortOrder || sortedColumn?.order ? (
          <ArrowDownFull
            className={classNames(styles.sorterIcon, { [styles.sorterUp]: sortAsc })}
          />
        ) : (
          <></>
        )}
      </div>
    );
  };

  return [
    {
      title: ({ sortColumns }) => getSorterTitle(sortColumns, 'Title', 'Milestone'),
      dataIndex: 'Title',
      key: 'Title',
      width: 300,
      sorter: (a, b) => {
        const titleA = a.Title || '';
        const titleB = b.Title || '';
        return titleA.localeCompare(titleB);
      },
      sortDirections: ['descend', 'ascend', 'descend'],
      render: (text, row) => (
        <>
          <div className={styles.inlineRowContent}>
            <Point
              key={row?.Id}
              milestoneId={row?.Id}
              status={row?.Status}
              isAggregated
              isCreator={row?.isTransactionCreator}
              transactionId={row?.TransactionId}
              participants={row?.Participants}
              IsKeyDate={row?.IsKeyDate}
              isProject={row?.Transaction?.IsProject}
            />
            <span className={styles.text}>{text}</span>
          </div>
        </>
      ),
    },
    {
      title: ({ sortColumns }) =>
        getSorterTitle(sortColumns, 'Property', getTitleByType(aggregatedPageType)),
      dataIndex: 'Property',
      key: 'Property',
      width: 220,
      sorter: (a, b) => {
        const { Property: PropertyA } = a;
        const { Property: PropertyB } = b;

        const titleA = PropertyA?.Address?.Line1 || a.Name || '';
        const titleB = PropertyB?.Address?.Line1 || b.Name || '';

        return titleA.localeCompare(titleB);
      },
      render: (_, { TransactionId, Property, Name }) => {
        return (
          <div
            className={styles.transactionsData}
            onClick={(e) => {
              e.stopPropagation();
              history.push(routes.transactionOverview.replace(':id', TransactionId.toString()));
            }}
          >
            <p className={styles.address}>{Property?.Address?.Line1 || Name}</p>
            {Property && (
              <p className={styles.addressLine2}>
                {Property?.Address?.City} {Property?.Address?.Zip}
              </p>
            )}
          </div>
        );
      },
    },
    // TODO : To be shown when user has Teamview or Admin acess , needs to be discussed
    // {
    //   title: 'Agent',
    //   dataIndex: 'Participants',
    //   key: 'Participants',
    // width: 130,
    //   render: (Participants) => <span>{getParticipantName(Participants, AGENT)}</span>,
    // },
    {
      title: ({ sortColumns }) => getSorterTitle(sortColumns, 'DueDate', 'Date', false),
      dataIndex: 'DueDate',
      key: 'DueDate',
      width: 150,
      defaultSortOrder: 'ascend',
      sorter: (a, b) => moment(a.DueDate).diff(moment(b.DueDate), 'days'),
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (dueDate, row) => getDateString(dueDate, row.Status),
    },
    {
      title: ({ sortColumns }) => getSorterTitle(sortColumns, 'category', 'Category'),
      key: 'category',
      width: 200,
      sorter: (a, b) => {
        const { Transaction: TransactionA } = a;
        const { Transaction: TransactionB } = b;

        const titleA = TransactionA.IsProject ? TransactionA.Name : inferStatus(a) || '';
        const titleB = TransactionB.IsProject ? TransactionB.Name : inferStatus(b) || '';

        return titleA.localeCompare(titleB);
      },
      render: (_, { Transaction }) => {
        const { IsProject, Status, Name } = Transaction;

        return (
          <div>
            <p className={styles.roleCategory}>
              {IsProject ? 'Project' : inferRoleCategory(Transaction)}
            </p>
            <p className={styles.status}>
              {IsProject ? Name || Status.Name : inferStatus(Transaction)}
            </p>
          </div>
        );
      },
    },
  ];
}
