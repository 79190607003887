import { NamesContainer } from 'pages/Workshop/Transactions/components/NamesContainer';
import styles from './style.module.scss';
import { Avatar } from 'components';

export const OwnerColumn = ({ row }) => {
  const ownerName = `${row?.creator?.FirstName} ${row?.creator?.LastName}`;
  return (
    <div className={styles.ownerWrapper}>
      {ownerName && row?.creator ? (
        <>
          <Avatar placeholder={row?.creator?.AvatarUrl} avatarClassName={styles.avatarCustom} />
          <div>
            <NamesContainer names={[ownerName]} maxDisplayCount={1} />
          </div>
        </>
      ) : (
        '-'
      )}
    </div>
  );
};
