import { Options } from '../Options';
import { AvatarColumn, DateTimeColumn, DocumentNameColumn } from './TableColumns';
import { AgentDocumentTypes } from 'types';

const docType = AgentDocumentTypes.PreApprovals;

export const preapprovalColumns = (optionUtils) => [
  {
    key: 'title',
    title: 'Name',
    dataIndex: 'title',
    width: 475,
    render: (title) => <DocumentNameColumn name={title} docIconType={'Ungrouped'} />,
  },
  {
    key: 'uploadedBy',
    title: 'Uploaded By',
    width: 350,
    render: (row) => <AvatarColumn name={row?.preApprovalMeta?.uploadedBy} imgSrc={null} />,
  },
  {
    key: 'uploaded',
    title: 'Uploaded',
    render: (row) => <DateTimeColumn date={row?.preApprovalMeta?.uploadedAt} hideTime />,
  },
  {
    key: 'options',
    title: '',
    render: (row) => <Options document_={row} type={docType} optionUtils={optionUtils} />,
  },
];
