import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getQuoteFormSelector, getRequestQuoteSelector } from 'store/selectors/requestQuote';
import { PreForm, Summary, Partners, FormQuestions } from './components';
import { getUserRoleSelector } from 'store/selectors/user';
import { AGENT, CLIENT } from 'settings/constants/roles';
import { PENDING } from 'settings/constants/apiState';
import { link } from 'settings/navigation/link';
import { Progress } from 'components-antd';
import { QuestionType } from 'types';
import { Spinner } from 'components';
import {
  createQuoteDraftEffect,
  resetRequestQuoteEffect,
  updateQuoteDraftEffect,
  getQuoteFormEffect,
} from 'store/effects/quotes';

import styles from './styles.module.scss';
import { getConciergeSearchSelector } from 'store/selectors/concierge';

export const RequestQuote = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { categoryId, partnerId } = useParams<{ categoryId: string; partnerId: string }>();

  const { quoteData, isPending, isDraft } = useSelector(getRequestQuoteSelector);
  const {
    state: quoteFormState,
    FormQuestions: Questions,
    Id: formVersionId,
  } = useSelector(getQuoteFormSelector);
  const userRole = useSelector(getUserRoleSelector);
  const { areasOfOperation: searchedLocations } = useSelector(getConciergeSearchSelector);

  const [isPreForm, setIsPreForm] = useState(true);
  const [isSummary, setIsSummary] = useState(false);
  const [isGood, setIsGood] = useState(false);

  const [percent, setPercent] = useState(0);
  const [preFormQuestionIndex, setPreFormQuestionIndex] = useState(0);
  const [preFormTotalQuestions, setPreFormTotalQuestions] = useState(0);
  const [quoteFormTotalQuestions, setQuoteFormTotalQuestions] = useState(0);
  const [quoteFormQuestionIndex, setQuoteFormQuestionIndex] = useState(0);

  const [filteredQuestions, setFilteredQuestions] = useState<QuestionType[]>([]);

  const [totalQuestions, setTotalQuestions] = useState(0);

  const isFormQuestion = !isGood && !isSummary && !isPreForm;

  useEffect(() => {
    if (Questions?.length) {
      if (userRole === AGENT) {
        setPreFormTotalQuestions(4);
        setTotalQuestions(4 + (quoteFormTotalQuestions || Questions?.length) + 2);
      } else if (userRole === CLIENT) {
        setPreFormTotalQuestions(2);
        setTotalQuestions(2 + (quoteFormTotalQuestions || Questions?.length) + 2);
      }
    }
  }, [userRole, quoteFormTotalQuestions, Questions?.length]);

  useEffect(() => {
    if (totalQuestions) {
      setPercent(((preFormQuestionIndex + 1) / totalQuestions) * 100);
    }
  }, [preFormQuestionIndex, totalQuestions]);

  useEffect(() => {
    if (isFormQuestion) {
      setPercent(((preFormTotalQuestions + quoteFormQuestionIndex + 1) / totalQuestions) * 100);
    } else if (isPreForm) {
      setPercent(((preFormQuestionIndex + quoteFormQuestionIndex + 1) / totalQuestions) * 100);
    } else if (isSummary) {
      setPercent(((preFormTotalQuestions + quoteFormTotalQuestions + 1) / totalQuestions) * 100);
    } else if (isGood) {
      setPercent(
        ((preFormTotalQuestions + quoteFormTotalQuestions + quoteFormQuestionIndex + 2) /
          totalQuestions) *
          100,
      );
    }
  }, [quoteFormQuestionIndex, isPreForm, isSummary, isGood, quoteFormTotalQuestions]);

  useEffect(() => {
    if (categoryId) {
      dispatch(
        getQuoteFormEffect({ categoryId, searchedLocations }, {}, (error) => {
          if (error) {
            history.push(link.toServices());
          }
        }),
      );
    }
  }, [categoryId]);

  useEffect(() => (): any => dispatch(resetRequestQuoteEffect()), []);

  const onGood = () => {
    setIsSummary(false);
    setIsGood(true);
  };

  const onSubmit = () => {
    setIsSummary(true);
  };

  const onPreFormSubmit = () => {
    if (!isDraft) {
      dispatch(
        createQuoteDraftEffect(
          { partnerIds: partnerId.split(','), categoryId, formVersionId },
          {},
          (err) => {
            if (!err) {
              setIsPreForm(false);
            }
          },
        ),
      );
    } else {
      dispatch(
        updateQuoteDraftEffect({}, {}, (err) => {
          if (!err) {
            setIsPreForm(false);
          }
        }),
      );
    }
  };

  const onStartOver = () => {
    setIsPreForm(true);
    setIsSummary(false);
    setIsGood(false);
  };

  const onSummaryEdit = (index: number) => {
    setQuoteFormQuestionIndex(index);
    setIsSummary(false);
  };

  const getForm = () => {
    if (isPreForm) {
      return <PreForm onSubmit={onPreFormSubmit} setQuestionIndex={setPreFormQuestionIndex} />;
    }

    if (isSummary) {
      return (
        <Summary
          onGood={onGood}
          onStartOver={onStartOver}
          onSummaryEdit={onSummaryEdit}
          filteredQuestions={filteredQuestions}
        />
      );
    }

    if (isGood) {
      return <Partners list={quoteData?.Partners} />;
    }

    return (
      <FormQuestions
        questionIndex={quoteFormQuestionIndex}
        filteredQuestions={filteredQuestions}
        setQuestionIndex={setQuoteFormQuestionIndex}
        handleSubmit={onSubmit}
        setTotalQuestions={setQuoteFormTotalQuestions}
        setIsPreForm={setIsPreForm}
        setFilteredQuestions={setFilteredQuestions}
      />
    );
  };

  return (
    <div className={classnames(styles.requestQuote)}>
      <Progress percent={percent} type="line" className={styles.progressBar} />
      <div
        className={classnames({
          [styles.pendingWrapper]: isFormQuestion,
          [styles.pendingWrapperFlex]: !isPreForm,
          [styles.formEndWrapper]: isSummary || isGood,
          [styles.preFormContainer]: isPreForm,
        })}
      >
        {isPending || quoteFormState === PENDING ? <Spinner /> : getForm()}
      </div>
    </div>
  );
};
