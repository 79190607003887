import {
  getReminderTemplates,
  createReminderTemplate,
  updateReminderTemplateReq,
  deleteReminderTemplate,
  getReminderTemplateByEntity,
} from 'api/reminder';
import { showErrorMessage } from 'helpers';
import { useState, useCallback } from 'react';

export const ReminderEntity = {
  KIT: 'Kit',
};

const useReminderTemplate = () => {
  const [reminderTemplates, setReminderTemplates] = useState<any[]>([]);
  const [reminderTemplate, setReminderTemplate] = useState<any>(null);

  const [loading, setLoading] = useState({
    load: false,
    get: false,
    add: false,
    update: false,
    remove: false,
  });

  const loadReminderTemplates = useCallback(async ({ entityName }) => {
    setLoading((prev) => ({ ...prev, load: true }));
    try {
      const response = await getReminderTemplates(entityName);
      setReminderTemplates(response.data);
    } catch (error) {
      showErrorMessage('Failed to load reminder templates');
    } finally {
      setLoading((prev) => ({ ...prev, load: false }));
    }
  }, []);

  const getReminderTemplate = useCallback(async (id) => {
    setLoading((prev) => ({ ...prev, get: true }));
    try {
      const response = await getReminderTemplateByEntity(ReminderEntity.KIT, id);
      setReminderTemplate(response.data);
      return response.data;
    } catch (error) {
      showErrorMessage('Failed to retrieve reminder template');
    } finally {
      setLoading((prev) => ({ ...prev, get: false }));
    }
  }, []);

  const addReminderTemplate = useCallback(async (reminderTemplateData) => {
    setLoading((prev) => ({ ...prev, add: true }));
    try {
      const response = await createReminderTemplate(reminderTemplateData);
      setReminderTemplates((prevReminderTemplates) => [...prevReminderTemplates, response.data]);
    } catch (error) {
      showErrorMessage('Failed to add reminder template');
    } finally {
      setLoading((prev) => ({ ...prev, add: false }));
    }
  }, []);

  const updateReminderTemplate = useCallback(async (id, reminderTemplateData) => {
    setLoading((prev) => ({ ...prev, update: true }));
    try {
      const response = await updateReminderTemplateReq({ Id: id, ...reminderTemplateData });
      setReminderTemplates((prevReminderTemplates) =>
        prevReminderTemplates.map((reminderTemplate) =>
          reminderTemplate.Id === id ? response.data : reminderTemplate,
        ),
      );
    } catch (error) {
      showErrorMessage('Failed to update reminder template');
    } finally {
      setLoading((prev) => ({ ...prev, update: false }));
    }
  }, []);

  const removeReminderTemplate = useCallback(async (id) => {
    setLoading((prev) => ({ ...prev, remove: true }));
    try {
      await deleteReminderTemplate(id);
      setReminderTemplates((prevReminderTemplates) =>
        prevReminderTemplates.filter((reminderTemplate) => reminderTemplate.id !== id),
      );
    } catch (error) {
      showErrorMessage('Failed to delete reminder template');
    } finally {
      setLoading((prev) => ({ ...prev, remove: false }));
    }
  }, []);

  return {
    reminderTemplate,
    reminderTemplates,
    loading,
    loadReminderTemplates,
    getReminderTemplate,
    addReminderTemplate,
    updateReminderTemplate,
    removeReminderTemplate,
  };
};

export default useReminderTemplate;
