import { Button } from 'components-antd';
import { Plus } from 'components/Icons';
import { TemplateHeaderSearch } from './TemplateHeaderSearch';

import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { getUserRolesMapSelector } from 'store/selectors/user';
import React from 'react';
import CheckboxComponent from 'components/Form/Checkbox';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { TEAM_BASIC } from 'settings/constants/roles';
import classNames from 'classnames';

type Props = {
  isTask?: boolean;
  label?: string;
  value?: string;
  onNew?: () => void;
  onSearch?: (value: string) => void;
  onClear?: () => void;
  hideOptions?: boolean;
  onToggleAgentTemplateView?: () => void;
  showAgentTemplates?: boolean;
  canAddNewTemplate?: boolean;
  className?: string;
};

export const TemplateHeader: React.FC<Props> = (props) => {
  const {
    isTask = false,
    label = '',
    onNew,
    value = '',
    onSearch,
    onClear,
    hideOptions = false,
    onToggleAgentTemplateView,
    showAgentTemplates = false,
    canAddNewTemplate = false,
    className = '',
  } = props;
  const { isSuperUser } = useSelector(getUserRolesMapSelector);
  const agentRole = useSelector(getAgentTeamRoleSelector);

  return (
    <div className={classNames(styles.templateHeader, className)}>
      <h1 className={styles.title}>{label}</h1>
      {!hideOptions && (
        <div className={styles.thRight}>
          {onToggleAgentTemplateView && isSuperUser && (
            <CheckboxComponent
              id="agentTemplates"
              label="Agent Templates"
              checked={showAgentTemplates}
              onChange={onToggleAgentTemplateView}
              direction={CheckboxComponent.DIRECTION_RIGHT}
              name="Agent Templates"
              labelTextClassName={styles.checkboxLabel}
              className={styles.checkbox}
              testid="active_checkbox"
            />
          )}
          {onSearch && (
            <TemplateHeaderSearch
              className={styles.searchHeader}
              value={value}
              onSearch={onSearch}
              onClear={onClear}
              isTask={isTask}
            />
          )}
          {onNew && (agentRole !== TEAM_BASIC || canAddNewTemplate) ? (
            <Button className={styles.newTemplate} onClick={onNew}>
              <Plus className={styles.iconAdd} />
              New
            </Button>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
};
