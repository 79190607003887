import { useEffect } from 'react';
import validator from 'validator';
import classNames from 'classnames';
import { cloneDeep, uniq } from 'lodash-es';
import { useSelector, useDispatch } from 'react-redux';

import { AnswersContainer } from '../../AnswersContainer';
import { ButtonsContainer } from '../../ButtonsContainer';
import { Spinner, PhoneNumber } from 'components';
import { Continue } from '../../Continue';
import Participant from './Participant';
import { ParticipantEmail } from './ParticipantEmail';
import CheckboxComponent from 'components/Form/Checkbox';
import Icon from 'pages/Workshop/Transactions/TransactionCreate/Icons';
import { Question } from 'pages/Workshop/Transactions/TransactionCreate/components';
import { validateFullAccess } from 'pages/Workshop/Transactions/TransactionCreate/helpers';
import {
  setOptionalEmail,
  validateParticipant,
} from 'pages/Workshop/Transactions/TransactionCreate/helpers';

import { splitFirstOccurrence } from 'utils';

import {
  transactionPreFormQuestionsIds as questionId,
  participantEmpty,
  TransactionUserRoleMap,
  TransactionUserRole,
} from 'settings/constants/transaction';
import { getUserId } from 'store/selectors/user';
import { getTransactionPreFormSelector } from 'store/selectors/transactions';

import { getTransactionRolesSelector } from 'store/selectors/transactionRoles';

import { setTransactionPreFormQuestionsEffect } from 'store/effects/transactions';
import { getStateAndDataFlag } from 'store/selectors/teamList';
import { getInvitedClientData } from 'store/selectors/app';

import styles from './styles.module.scss';

interface ParticipantsProps {
  className?: string;
  onNext?: (...args) => void;
  currentQuestionId?: string;
  previousQuestionId?: string;
  setPreviousQuestionId?: (Id: string) => void;
  setCurrentQuestionId?: (Id: string) => void;
  heading?: string;
}

const TransactionParticipants = ({
  className,
  onNext,
  currentQuestionId,
  heading = 'Who is the client you are representing?',
}: ParticipantsProps) => {
  const dispatch = useDispatch();

  const { preForm, isPending } = useSelector(getTransactionPreFormSelector);
  const { transactionRoles } = useSelector(getTransactionRolesSelector);

  const { isPending: isTeamListPending } = useSelector(getStateAndDataFlag);

  const isBuyerFlow =
    preForm?.whoAreYouRepresenting?.Buyer && !preForm?.whoAreYouRepresenting?.Seller;

  const isSellerFlow =
    preForm?.whoAreYouRepresenting?.Seller && !preForm?.whoAreYouRepresenting?.Buyer;

  const inviteClientQuestion = currentQuestionId === questionId.inviteClient;

  let preFormQuestionId = questionId.clients;

  const participantsValues = cloneDeep(preForm?.[preFormQuestionId]) || [];

  const formattedData = (obj) => {
    let type: string | undefined;

    if (isBuyerFlow) type = TransactionUserRole.Buyer;
    if (isSellerFlow) type = TransactionUserRole.Seller;

    if (!obj?.roleId && type) {
      const role = transactionRoles?.find((t) => t.Title === type);
      obj.roleId = role?.Id;
      obj.tagRole = role?.Title;
    }
    if (obj?.phone) {
      obj.Phones = [
        {
          PhoneNumber: obj.phone,
          PhoneType: 'Mobile',
          IsPrimary: true,
          IsVerified: false,
        },
      ];
    }
    return obj;
  };

  const updateStoredParticipants = (values) => {
    dispatch(
      setTransactionPreFormQuestionsEffect({
        [preFormQuestionId]: [...values],
      }),
    );
  };

  const createParticipant = () => ({
    name: '',
    value: '',
    role: '',
    avatarUrl: '',
    firstName: '',
    lastName: '',
    invite: true,
    Phones: [{ PhoneNumber: '', PhoneType: '' }],
    transactionAccess: false,
  });

  useEffect(() => {
    if (!preForm?.clients?.length && inviteClientQuestion) {
      const newParticipant = createParticipant();
      updateStoredParticipants([
        {
          ...(inviteClientQuestion || isBuyerFlow || isSellerFlow
            ? formattedData(newParticipant)
            : participantEmpty),
          ...(inviteClientQuestion ? { invite: false } : undefined),
        },
      ]);
    }
  }, [currentQuestionId, inviteClientQuestion]);

  const onAdd = () => {
    const newParticipant = createParticipant();
    participantsValues.push(
      formattedData({
        ...newParticipant,
        invite: false,
      }),
    );
    updateStoredParticipants(participantsValues);
  };

  const onDeleteParticipant = (index) => {
    participantsValues.splice(index, 1);
    updateStoredParticipants(participantsValues);
  };

  const onRemoveData = (index) => {
    const newParticipant = createParticipant();
    participantsValues[index] = formattedData({
      ...newParticipant,
      invite: false,
      Phones: [{ PhoneNumber: '', PhoneType: '' }],
      tagRole: participantsValues[index]?.tagRole,
      roleId: participantsValues[index]?.roleId,
    });
    updateStoredParticipants(participantsValues);
  };

  const onEmailInput = (value: string, index: number) => {
    const email = value.trim();
    updateParticipantValues(
      {
        customEmail: email.toLowerCase(),
        error: email && !validator.isEmail(email) ? 'Please enter a valid email' : undefined,
      },
      index,
    );
  };

  const onPhoneInput = (value: string, index: number) => {
    const phoneNum = value.trim();
    updateParticipantValues(
      {
        Phones: [
          {
            PhoneNumber: phoneNum,
            PhoneType: 'Mobile',
            IsPrimary: true,
            IsVerified: false,
          },
        ],
      },
      index,
    );
  };

  const onPhoneInputBlur = (value: string, index: number) => {
    const phoneNum = value.trim();
    updateParticipantValues(
      {
        phoneNoError:
          phoneNum && !validator.isMobilePhone(phoneNum, 'en-US')
            ? 'Please enter a valid phone number'
            : undefined,
      },
      index,
    );
  };

  const insertName = (value, index: number) => {
    const inputValue = value?.trim();
    const { first, remainder } = splitFirstOccurrence(inputValue, ' ');

    updateParticipantValues(
      formattedData({
        customName: inputValue,
        customEmail: participantsValues[index].customEmail,
        email: '',
        id: '',
        value: '',
        name: '',
        firstName: first,
        lastName: remainder,
        role: '',
        tagRole: participantsValues[index]?.tagRole,
        roleId: participantsValues[index]?.roleId,
        error: '',
        ContactId: '',
        UserId: '',
        isContact: false,
      }),
      index,
    );
  };

  const insertRole = (event, index) => {
    const inputValue = event.target.value.trim();

    if (inputValue !== '') {
      updateParticipantValues(
        {
          tagRole: inputValue,
        },
        index,
      );
    }
  };

  const onBlurClientInput = (value, index) => {
    insertName(value, index);
  };

  const updateParticipantValues = (obj, index) => {
    participantsValues[index] = {
      ...(participantsValues?.[index] || {}),
      ...obj,
    };
    updateStoredParticipants(participantsValues);
  };

  const onChangeClient = (value, index) => {
    updateParticipantValues(
      {
        ...formattedData(value),
        customEmail: value.email,
        customName: undefined,
        error: undefined,
        roleId: participantsValues[index]?.roleId,
        tagRole: participantsValues[index]?.tagRole,
        isContact: value.isContact,
      },
      index,
    );
  };

  const onRoleKeyPress = (event, index) => {
    if (event.key === 'Enter') {
      insertRole(event, index);
    }
  };

  const onChangeRole = (value, index) => {
    updateParticipantValues(
      {
        tagRole: value?.name || value,
        roleId: value?.roleId,
      },
      index,
    );
  };
  const onInvite = (checked, index) => {
    updateParticipantValues(
      {
        invite: checked,
      },
      index,
    );
  };

  const canContinue = () => validateParticipant(participantsValues);

  const getQuestionTitle = () => {
    if (inviteClientQuestion) return 'Who is the client you are representing?';
    else if (currentQuestionId === questionId.agents)
      return 'Who is the agent in this transaction?';
    else if (currentQuestionId === questionId.coordinators)
      return 'Is there a coordinator in this transaction?';
    else return heading;
  };

  const fullAccess = () =>
    (currentQuestionId === questionId.agents || questionId.coordinators) &&
    validateFullAccess(participantsValues);

  const handleContinue = () => onNext?.();

  return (
    <div
      testid="invite_participants"
      className={classNames(styles.participants, styles.container, className)}
    >
      <Question className={classNames(styles.question)}>{getQuestionTitle()}</Question>
      <AnswersContainer className={styles.answersContainer}>
        {isTeamListPending ? (
          <Spinner />
        ) : (
          <>
            <div>
              {participantsValues.map((participant, index) =>
                inviteClientQuestion || participant?.invite ? (
                  <div className={styles.wrap}>
                    <Participant
                      key={participant?.id || index}
                      onBlurClientInput={(value) => onBlurClientInput(value, index)}
                      onChangeClient={(value) => onChangeClient(value, index)}
                      onRoleKeyPress={(event) => onRoleKeyPress(event, index)}
                      onChangeRole={(e, value) => onChangeRole(value, index)}
                      onDelete={() => onDeleteParticipant(index)}
                      onRemoveData={() => onRemoveData(index)}
                      value={participant}
                      isClientInfo={inviteClientQuestion}
                      isAgentInfo={false}
                      isCoordinatorInfo={false}
                      isWhoIsRepresentingBuyerInfo={false}
                      participants={participantsValues}
                      currentQuestionId={currentQuestionId}
                    />

                    <div>
                      <div className={styles.displayInputLabelWrap}>
                        <p className={styles.inputLabel}>Email</p>
                        {setOptionalEmail(participant) && (
                          <p className={styles.inputLabelOptional}>(Optional)</p>
                        )}
                      </div>
                      <ParticipantEmail
                        customEmail={participant.customEmail}
                        error={participant.error}
                        placeholder=" "
                        onUpdate={() => {}}
                        onBlurInput={(value) => onEmailInput(value, index)}
                        inputHolderClassName={styles.customName}
                        className={styles.clientEmailInput}
                      />
                    </div>

                    <div>
                      <div className={styles.displayInputLabelWrap}>
                        <p className={styles.inputLabel}>Phone</p>
                        <p className={styles.inputLabelOptional}>(Optional)</p>
                      </div>
                      <PhoneNumber
                        className={styles.clientPhoneInput}
                        placeholder="(---) --- -----"
                        value={
                          participant?.Phones && participant.Phones.length > 0
                            ? participant.Phones[0].PhoneNumber
                            : null
                        }
                        error={participant.phoneNoError}
                        errorClassName={styles.errorClass}
                        onUpdate={(value) => onPhoneInput(value, index)}
                        onBlurInput={(value) => onPhoneInputBlur(value, index)}
                      />
                    </div>

                    <CheckboxComponent
                      id="invite-to-transaction"
                      label="Invite to transaction"
                      checked={participant.invite}
                      onChange={(e, v, checked) => onInvite(checked, index)}
                      direction={CheckboxComponent.DIRECTION_RIGHT}
                      labelTextClassName={styles.inviteCheckboxLabel}
                      className={styles.inviteCheckbox}
                    />
                  </div>
                ) : (
                  <></>
                ),
              )}
            </div>
            <div onClick={onAdd} className={styles.add}>
              <Icon className={styles.addIcon} variant={Icon.ADDPLAIN} />
              <div testid="invite_another" className={styles.addText}>
                {participantsValues.length < 1 ? 'Add' : 'Add another'}
              </div>
            </div>
          </>
        )}
      </AnswersContainer>
      <ButtonsContainer buttonsContainerClassName={styles.buttonCon}>
        <Continue
          className={classNames(styles.continue)}
          onClick={handleContinue}
          isPending={isPending}
          disabled={!canContinue() || !fullAccess()}
        />
      </ButtonsContainer>
    </div>
  );
};

export default TransactionParticipants;
