import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';

import { PageWrapper, AsideNavigation, Content } from 'components/Transactions';
import { getofferSelector } from 'store/selectors/offers';
import { OffersList } from './OffersList';
import { getTransactionAcceptedOffer } from 'api/offers';
import { TransactionAcceptedOffer } from './TransactionAcceptedOffer';
import { getKeyDatesEffect, getTransactionEffect } from 'store/effects/transactions';

import styles from './styles.module.scss';

import {
  getOfferAggregateByUserIdEffect,
  setOffersFiltersAppliedEffect,
  setOffersIsArchiveEffect,
} from 'store/effects/offerAggregate';
import { getUserId } from 'store/selectors/user';
import { getOfferFiltersSelector, getOfferIsArchiveSelector } from 'store/selectors/offerAggregate';
import { offerListEffect } from 'store/effects';

let isMounted = false;
export const TransactionOffers = (props) => {
  const { className, match } = props;
  const dispatch = useDispatch();
  const { isError, status, TransactionId, isPending } = useSelector(getofferSelector);
  const {
    offersFilter: { allFilters },
  } = useSelector(getOfferFiltersSelector);
  const { isArchive } = useSelector(getOfferIsArchiveSelector);

  const [holdDisplay, setHoldDisplay] = useState(true);
  const [loading, setLoading] = useState(false);

  const [acceptedOffer, setAcceptedOffer]: any = useState({});
  const [isAcceptedOffer, setIsAcceptedOffer] = useState(false);

  const [isReloadOffer, setReloadOffer] = useState(false);
  const userId = useSelector(getUserId);

  const fetchTransactionById = (callback?) => {
    dispatch(getTransactionEffect({ id: match.params.id }, { silent: true }, callback));
    dispatch(getKeyDatesEffect({ transactionId: match.params.id }, { silent: true }));
    setReloadOffer(false);
  };

  const fetchTransactionOfferById = async () => {
    try {
      const response = await getTransactionAcceptedOffer({ transactionId: match.params.id });

      if (!_.isEmpty(response?.data?.value)) {
        setAcceptedOffer(response?.data?.value);
        setIsAcceptedOffer(true);
      }
      dispatch(offerListEffect({ transactionId: match.params.id }, { silent: true }, (err) => {}));
      getTransactionOffers({ transactionIds: [match.params.id] });
    } catch (error) {
      console.error('Error fetching transaction offer', error);
    }
  };

  const getTransactionOffers = (filters) => {
    dispatch(
      getOfferAggregateByUserIdEffect(
        {
          id: userId,
          filters,
        },
        {},
        () => {
          setLoading(false);
          setHoldDisplay(false);
          isMounted = true;
        },
      ),
    );
  };

  useEffect((): any => {
    setHoldDisplay(true);
    fetchTransactionOfferById();
    fetchTransactionById();

    return () => {
      isMounted = false;
      dispatch(setOffersIsArchiveEffect(false));
      dispatch(setOffersFiltersAppliedEffect(false));
    };
  }, [isAcceptedOffer, isReloadOffer]);

  useEffect(() => {
    if (isMounted) {
      setLoading(true);

      getTransactionOffers({
        ...allFilters,
        transactionIds: [match.params.id],
      });
    }
  }, [allFilters]);

  return (
    <div className={classNames(styles.transactionOffers, className)}>
      <PageWrapper notFound={isError && status === 404}>
        <AsideNavigation />
        <Content isPending={(holdDisplay || isPending) && !loading}>
          {isAcceptedOffer && !isArchive ? (
            <TransactionAcceptedOffer
              transactionId={TransactionId}
              Offer={acceptedOffer}
              isData={setAcceptedOffer}
              setIsAcceptedOffer={setIsAcceptedOffer}
            />
          ) : (
            <OffersList
              transactionId={match.params.id}
              offerUUID={match.params.uuid}
              setReloadOffer={setReloadOffer}
              loading={loading}
              isAcceptedOffer={isAcceptedOffer}
            />
          )}
        </Content>
      </PageWrapper>
    </div>
  );
};

TransactionOffers.propTypes = {
  className: PropTypes.string,
  match: ReactRouterPropTypes.match.isRequired,
};

TransactionOffers.defaultProps = {
  className: '',
};
