import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { FilterDrawer, FilterSection } from 'pages/Workshop/common';
import { GroupCheckBoxesSection } from 'pages/Workshop/common/GroupCheckBoxesSection';
import { MultiSelectSection } from 'pages/Workshop/common/MultiSelectSection';
import { LocationService } from 'services';
import { getProjectsClientsSelector } from 'store/selectors/projects';
import { FormsArchiveFilterTableStatus, FormsFilterTableStatus } from 'app-constants';
import { isEqual } from 'lodash-es';

let defaultFilters = {};

export const FilterSections = ({
  onDone,
  onReset,
  isArchive,
  formNames,
  clientNames,
  taskTransactions,
  projects,
}) => {
  const locationSrv = new LocationService();
  locationSrv.setLocation(location);
  const [previousFilters, setPreviousFilters] = useState({});
  const [forms, setForms] = useState<string[]>([]);
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const query = locationSrv.getQuery();
  const clientsData = useSelector(getProjectsClientsSelector);

  const [initialCache] = useState(taskTransactions);
  const [isFiltersApplied, setIsFiltersApplied] = useState<boolean>(false);
  const [cachedTransactions, setCachedTransactions] = useState<any[]>([]);
  const [cachedProjects, setCachedProjects] = useState<any[]>([]);
  const [cachedForm, setCachedForm] = useState<any[]>([]);
  const [transactions, setTransactions] = useState<string[]>([]);
  const [cachedClientsData, setCachedClientsData] = useState<string[]>([]);
  const [clients, setClients] = useState<string[]>([]);
  const [statuses, setStatuses] = useState<string[]>(
    query.statuses ? query.statuses.split(',') : [],
  );

  const StatusesData = isArchive ? FormsArchiveFilterTableStatus : FormsFilterTableStatus;

  const getFilters = () => ({
    transactions,
    selectedProjects,
    statuses,
    clients,
    forms,
  });

  useEffect(() => {
    defaultFilters = getFilters();
    setPreviousFilters(defaultFilters);
  }, []);

  useEffect(() => {
    if (!cachedTransactions.length && taskTransactions?.length) {
      setCachedTransactions(taskTransactions);
    }

    if (!cachedForm.length && formNames?.length) {
      setCachedForm(formNames);
    }

    if (!cachedProjects.length && projects?.length && !isEqual(taskTransactions, initialCache)) {
      setCachedProjects(projects);
    }

    if (!cachedClientsData.length && clientNames?.length) {
      setCachedClientsData(clientNames);
    }
  }, [taskTransactions, formNames, clientNames, projects, clientsData]);

  useEffect(() => {
    setIsFiltersApplied(
      Boolean(
        transactions.length ||
          selectedProjects.length ||
          statuses.length ||
          clients.length ||
          forms.length,
      ),
    );
  }, [transactions, selectedProjects, statuses, clients, forms]);

  const submitHandler = () => {
    onDone({
      transactions: [...transactions, ...selectedProjects],
      statuses,
      client: [...clients],
      forms,
    });

    setPreviousFilters(getFilters());
  };

  const resetHandler = () => {
    setStatuses([]);
    setTransactions([]);
    setSelectedProjects([]);
    setForms([]);
    setClients([]);
    onReset();

    setPreviousFilters(defaultFilters);
  };

  const discardHandler = () => {
    if (JSON.stringify(previousFilters) === JSON.stringify(getFilters())) {
      return;
    }

    const { transactions, selectedProjects, statuses, clients, forms } = previousFilters as any;

    if (transactions) setTransactions(transactions);
    if (selectedProjects) setSelectedProjects(selectedProjects);
    if (statuses) setStatuses(statuses);
    if (clients) setClients(clients);
    if (forms) setForms(forms);
  };

  return (
    <FilterDrawer
      className={styles.filterBtn}
      isFiltersApplied={isFiltersApplied}
      onDone={submitHandler}
      onReset={resetHandler}
      onDiscard={discardHandler}
    >
      <div className={styles.filterSections}>
        <FilterSection title="Statuses" count={statuses?.length}>
          <GroupCheckBoxesSection
            entityList={Object.keys(StatusesData).map((value) => ({
              name: StatusesData[value],
              value,
            }))}
            entityValues={statuses}
            setEntity={setStatuses}
          />
        </FilterSection>

        <FilterSection title="Form" count={forms?.length}>
          <MultiSelectSection
            placeholder={'Search by Form...'}
            entityList={cachedForm.map((form) => ({
              label: form,
              value: form,
            }))}
            entity={forms}
            setEntity={setForms}
          />
        </FilterSection>

        <FilterSection title="Transactions" count={transactions?.length}>
          <MultiSelectSection
            placeholder={'Search by Transaction...'}
            entityList={cachedTransactions.map(({ Id, address }) => ({
              label: address.split(', ')[0],
              value: Id,
            }))}
            entity={transactions}
            setEntity={setTransactions}
            locationPrefix
          />
        </FilterSection>

        <FilterSection title="Projects" count={selectedProjects?.length}>
          <MultiSelectSection
            placeholder={'Search by Project...'}
            entityList={cachedProjects.map(({ Id, Name }) => ({
              label: Name,
              value: Id,
            }))}
            entity={selectedProjects}
            setEntity={setSelectedProjects}
          />
        </FilterSection>

        <FilterSection title="Clients" count={clients?.length}>
          <MultiSelectSection
            placeholder={'Search by Client...'}
            entityList={cachedClientsData.map((Name) => ({
              label: Name,
              value: Name,
            }))}
            entity={clients}
            setEntity={setClients}
            avatarPrefix
          />
        </FilterSection>
      </div>
    </FilterDrawer>
  );
};
